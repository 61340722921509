import update from 'immutability-helper';
import _ from 'lodash';

import { initialState } from '../../reducer/reducer';

import getCapabilities from './getCapabilities';

/**
 * @name ensureStateConsistency
 * @description Ensures the provided state is up-to-date with the current editor configuration
 *
 * @author Yann Hodiesne
 *
 * @param {object} state The state on which we need to ensure the consistency with the current configuration
 *
 * @returns {object} The provided state, with missing values filled with their defaults
 */
const ensureStateConsistency = (state) => {
	let result = { ...initialState, ...state };

	// Ensure the element's capabilities have their default values available inside the existing elements
	// It ensures the provided elements are compatible with their current configuration, even if capabilities have been added afterwards
	result.elements.forEach((row, i) => {
		result = update(result, {
			elements: {
				[i]: {
					children: (children) => children.map((element) => ({
						// Applying capabilities default values
						...Object.fromEntries(_.flatten(getCapabilities(element).map((capability) => {
							/**
							 * @name collectCapabilities
							 * @description Recursively collects nested capabilities
							 *
							 * @author Yann Hodiesne
							 */
							const collectCapabilities = (cap, array = []) => {
								array.push(cap);

								cap.triggers.forEach((trigger) => trigger.capabilities.forEach((x) => collectCapabilities(x, array)));

								return array;
							};

							const capabilities = collectCapabilities(capability);

							return capabilities.map((cap) => [cap.property, cap.defaultValue]);
						}))),
						// Applying the element's default values
						...element,
					})),
				},
			},
		});
	});

	return result;
};

export default ensureStateConsistency;
