import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchExpense, updateExpense } from 'redux/actions/expenses/expenses';
import { useCurrentExpenseSelector } from 'redux/selectors/expenses/expenses';

import { ExpenseEditionForm } from 'components/expenses';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';
import routes from '../../routes';

/**
 * @name ExpenseEditionPage
 * @description A page used to edit an existing expense.
 *
 * @author Romaric Barthe
 *
 */
const ExpenseEditionPage = () => {
	const { id: expenseId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const expense = useCurrentExpenseSelector(expenseId);

	const canEditExpense = useAccessRight(AccessRights.humanResources.expenses.enhancedRights.CREATE_EXPENSE) && !expense?.archived;

	useEffect(() => {
		dispatch(fetchExpense(expenseId));
	}, [dispatch, expenseId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData	The form data submitted by the user.
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditExpense) {
			dispatch(updateExpense(formData, expenseId));
		} else {
			redirectOnSuccess(routes.humanResources.expenses.list);
		}
	}, [canEditExpense, dispatch, expenseId]);

	return expense
		? <ExpenseEditionForm expense={expense} onSubmit={handleFormSubmit} />
		: <p>{t('expense.edition.loading')}</p>;
};

ExpenseEditionPage.defaultProps = {
	expense: undefined,
};

export default ExpenseEditionPage;
