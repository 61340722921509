import { formatSectorName } from 'lib/sectors/formatSectorData';
import PropTypes from 'prop-types';

/**
 * @name SectorsCell
 * @description Custom cell for the partnerListPage table displaying a partner's relation types.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 */
const SectorsCell = ({ row: { original: { sectors } } }) => (
	<ul className="tag-list">
		{sectors.map((sector) => (
			<li key={sector.id}>
				<span className="tag">
					{formatSectorName(sector)}
				</span>
			</li>
		))}
	</ul>
);

SectorsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			sectors: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				type: PropTypes.shape({
					id: PropTypes.string,
					name: PropTypes.string,
				}),
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default SectorsCell;
