import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchMedias
 * @description Retrieves the medias list from the API.
 *
 * @author Yann Hodiesne
 *
 * @param {object} params	The filter criterias.
 *
 * @returns {Promise}
 */
export const fetchMedias = (params) => post('/api/media-manager', params);

/**
 * @function
 * @name fetchFile
 * @description Retrieves a single file from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {string} fileId	The id to identify the file with.
 *
 * @returns {Promise}
 */
export const fetchFile = (fileId) => get(`/api/media-manager/file/${fileId}`);

/**
 * @function
 * @name uploadFile
 * @description Uploads a new file into the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} fileData	The data to create the file from.
 *
 * @returns {Promise}
 */
export const uploadFile = (fileData) => post('/api/media-manager/file', fileData);

/**
 * @function
 * @name createFolder
 * @description Creates a new folder into the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} folderData	The data to create the folder from.
 *
 * @returns {Promise}
 */
export const createFolder = (folderData) => post('/api/media-manager/folder', folderData);

/**
 * @function
 * @name updateFile
 * @description Updates an existing file from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} fileData		The data to update the file with.
 * @param {string} fileId		The id to identify the file with.
 *
 * @returns {Promise}
 */
export const updateFile = (fileData, fileId) => put(`/api/media-manager/file/${fileId}`, fileData);

/**
 * @function
 * @name updateFolder
 * @description Updates an existing folder from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} folderData	The data to update the folder with.
 * @param {string} folderId		The id to identify the folder with.
 *
 * @returns {Promise}
 */
export const updateFolder = (folderData, folderId) => put(`/api/media-manager/folder/${folderId}`, folderData);

/**
 * @function
 * @name deleteFile
 * @description Removes an existing file from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {string} fileId	The id of the file we want to remove.
 *
 * @returns {Promise}
 */
export const deleteFile = (fileId) => del(`/api/media-manager/file/${fileId}`);

/**
 * @function
 * @name deleteFolder
 * @description Removes an existing folder from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {string} folderId	The id of the folder we want to remove.
 *
 * @returns {Promise}
 */
export const deleteFolder = (folderId) => del(`/api/media-manager/folder/${folderId}`);
