import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useResizeObserver } from 'lib/hooks';
import PropTypes from 'prop-types';

import { DefaultSizes, DragAndDropTypes } from '../../../constants';
import { EMPTY_ROW } from '../../../constants/ElementTypes';
import { PageOrientationDimensions } from '../../../constants/PagesOrientations';
import EditorContext from '../../../EditorContext';
import { getElementType } from '../../../functions/internals';
import { addToSelection, insertElement, insertRow, moveElementById, removeFromSelection, setSelection, updateElement } from '../../../reducer/actions';

import ContextMenu from './ContextMenu';

/**
 * @name Element
 * @description An element to display and drag around
 *
 * @author Yann Hodiesne
 * @author Florian Fornazaric
 *
 * @param {string}	id				Identifier of the current element
 * @param {number}	index			Index of the current element
 * @param {object}	element			The current element
 * @param {number}	rowIndex		Index of the row this element is currently in
 * @param {number}	aspectRatio		Aspect ratio of the current element
 * @param {number}	pageIndex 		Index of the current page
 */
const Element = ({ id, index, element, rowIndex, aspectRatio, pageIndex }) => {
	const { dispatch, selectedElements, isExporting, header, footer } = useContext(EditorContext);

	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
	const [isOpenContextMenu, setIsOpenContextMenu] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [isResizing, setIsResizing] = useState(false);

	const isSelected = useMemo(() => selectedElements.map((e) => e.id).includes(id), [id, selectedElements]);
	const elementType = useMemo(() => getElementType(element), [element]);
	const isEmpty = useMemo(() => (element.content?.length ?? 0) === 0, [element.content?.length]);

	if (elementType === null) {
		throw new Error(`Element type ${element.type} is not supported`);
	}

	const ref = useRef(null);
	const labelRef = useRef(null);

	/**
	 * @function
	 * @name getDragDirection
	 * @description Returns the direction of the drag, depending on the provided client offset (eq. cursor position)
	 *
	 * @author Yann Hodiesne
	 *
	 * @param {object} clientOffset Client offset of the cursor
	 *
	 * @returns {string} The direction of the drag, either 'left' or 'right'
	 */
	const getDragDirection = useCallback((clientOffset) => {
		// Determine rectangle on screen
		const hoverBoundingRect = ref.current?.getBoundingClientRect();

		// If it's on the right side of the row, not touching the element
		if (!hoverBoundingRect) {
			return 'right';
		}

		// Calculate vertical middle
		const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
		// Get pixels to the left of the element
		const hoverClientX = clientOffset.x - hoverBoundingRect.left;

		return hoverClientX < hoverMiddleX ? 'left' : 'right';
	}, []);

	const [, drop] = useDrop({
		accept: [DragAndDropTypes.PDF_ELEMENT, DragAndDropTypes.PDF_ELEMENT_TYPE],
		hover: (item, monitor) => {
			if (!ref.current) {
				return;
			}

			// Don't do anything if the dragged element is an element type
			if (monitor.getItemType() === DragAndDropTypes.PDF_ELEMENT_TYPE) {
				return;
			}

			// Don't do anything if the dragged element or element type is not inline
			if (!item.meta.inline) {
				return;
			}

			// Don't do anything if the current element is not inline
			if (!elementType.meta.inline) {
				return;
			}

			// Don't replace with themselves
			if (item.id === id) {
				return;
			}

			let hoverIndex = index;

			if (getDragDirection(monitor.getClientOffset()) === 'right') {
				hoverIndex++;
			}

			// Perform the move
			dispatch(moveElementById(item.id, rowIndex, hoverIndex));
		},
		drop: (item, monitor) => {
			switch (monitor.getItemType()) {
				case DragAndDropTypes.PDF_ELEMENT_TYPE:
					// If the dropped item is an empty row, insert a new row instead of a new element
					if (item.type === EMPTY_ROW.type) {
						dispatch(insertRow(rowIndex));
					} else {
						dispatch(insertElement(item, rowIndex, getDragDirection(monitor.getClientOffset()) === 'right' ? index + 1 : index));
					}

					return { handled: true };
				default:
					break;
			}

			return undefined;
		},
	}, [ref, id, index, rowIndex]);

	const [, drag, preview] = useDrag({
		type: DragAndDropTypes.PDF_ELEMENT,
		item: () => ({
			id,
			element,
			meta: {
				inline: elementType.meta.inline ?? false,
			},
		}),
		canDrag: !isExporting && elementType.meta.inline,
	}, [id]);

	useEffect(() => {
		preview(getEmptyImage());
	}, [preview]);

	const isMacOS = navigator.platform.toLowerCase().includes('mac');

	const handleClick = useCallback((e) => {
		e.stopPropagation();

		if ((!isMacOS && e.ctrlKey) || (isMacOS && e.metaKey)) {
			if (isSelected) {
				dispatch(removeFromSelection(id));
			} else {
				dispatch(addToSelection(id));
			}
		} else {
			dispatch(setSelection([id]));
		}
	}, [dispatch, id, isMacOS, isSelected]);

	const handleClosingMenu = useCallback(() => {
		setIsOpenContextMenu(false);
	}, []);

	const handleContextMenu = useCallback((e) => {
		if (!isExporting) {
			// We remove the ContextMenu behaviour
			e.preventDefault();
			e.stopPropagation();
			if (!isSelected) {
				dispatch(setSelection([id]));
			}

			// Allows us to set the position of the context menu
			setAnchorPoint({ x: e.pageX, y: e.pageY });
			setIsOpenContextMenu(true);
		}
	}, [dispatch, id, isExporting, isSelected]);

	const handleDoubleClick = useCallback(() => {
		setIsResizing(true);
		if (!isExporting) {
			setIsEditing(true);
		}
	}, [isExporting]);

	const aspectRatioPointer = useRef(aspectRatio);
	aspectRatioPointer.current = aspectRatio;

	const maxHeight = useMemo(() => {
		let { height } = PageOrientationDimensions.portrait;

		if (!header.disabled && !header.excludeFromPages.includes(pageIndex)) {
			height -= header.height;
		}
		if (!footer.disabled && !footer.excludeFromPages.includes(pageIndex)) {
			height -= footer.height;
		}

		return height * aspectRatio;
	}, [aspectRatio, footer.disabled, footer.excludeFromPages, footer.height, header.disabled, header.excludeFromPages, header.height, pageIndex]);

	const heightRef = useRef(element.height);
	const widthRef = useRef(element.width);

	const handleHeightChanges = useCallback((htmlElement) => {
		// Sometimes the height and width are set to 0 without any reason so we want to avoid updating the element with a height or width of 0
		if (htmlElement.offsetHeight === 0 || htmlElement.offsetWidth === 0) {
			return;
		}

		// We get the borderWidth because we need it to calculate the scrollHeight of the element with the size of the border
		// The regex used gets all the numbers with or without decimal
		const borderWidth = parseInt(htmlElement.style.borderWidth.match(/([\d.]+)/)?.[0], 10);
		const borderSize = !Number.isNaN(borderWidth) ? borderWidth * 2 : 0;

		// We check if the size of the element changed or not
		if (element.size?.x !== Math.ceil(htmlElement.offsetWidth / aspectRatioPointer.current)
			|| element.size?.y !== Math.ceil(htmlElement.offsetHeight / aspectRatioPointer.current)
			|| Math.ceil(htmlElement.offsetHeight / aspectRatioPointer.current) !== Math.ceil(htmlElement.scrollHeight / aspectRatioPointer.current) + borderSize) {
			let height = Math.ceil(htmlElement.offsetHeight / aspectRatioPointer.current);

			if (maxHeight < height) {
				height = maxHeight;
			}

			// element.scollHeight corresponds to the full height of the element
			if (htmlElement.scrollHeight >= htmlElement.offsetHeight) {
				height = htmlElement.scrollHeight / aspectRatioPointer.current + 1;

				if (maxHeight < height) {
					height = maxHeight;
				}

				// We +1 the height to avoid a bug where the element is not updated when the scrollHeight doesn't change between resizes
				if (element.size && height === element.size.y + 1) {
					height += 1;
				}
			}

			if (heightRef.current !== height || widthRef.current !== Math.ceil(htmlElement.offsetWidth / aspectRatioPointer.current)) {
				heightRef.current = Math.ceil(height);
				widthRef.current = Math.ceil(htmlElement.offsetWidth / aspectRatioPointer.current);

				dispatch(updateElement(id, { size: { x: Math.ceil(htmlElement.offsetWidth / aspectRatioPointer.current), y: Math.ceil(height) } }));
			}
		}
	}, [dispatch, element.size, id, maxHeight]);

	const handleBlur = useCallback((e) => {
		handleHeightChanges(e.target);
	}, [handleHeightChanges]);

	const handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter' && e.ctrlKey) {
			setIsEditing(false);
		}
	}, []);

	drag(drop(ref));

	const handleResize = useCallback(() => {
		const labelHeight = labelRef.current !== null && labelRef.current !== undefined ? Math.ceil(labelRef.current.offsetHeight / aspectRatioPointer.current) : 0;
		let elementHeight = Math.ceil(ref.current.offsetHeight / aspectRatioPointer.current - labelHeight) > maxHeight
			? maxHeight
			: Math.ceil(ref.current.offsetHeight / aspectRatioPointer.current - labelHeight);

		// We check if the size of the element changed or not
		// If it changed, we dispatch an updateElement action with the new values
		if (element.size?.x !== Math.ceil(ref.current.offsetWidth / aspectRatioPointer.current)
			|| element.size?.y !== elementHeight) {
			if (!isResizing && ref.current.scrollHeight >= ref.current.offsetHeight) {
				elementHeight = ref.current.scrollHeight / aspectRatioPointer.current;

				if (elementHeight === 0) {
					return;
				}

				if (maxHeight < elementHeight) {
					elementHeight = maxHeight;
				}

				// We -1 the height to avoid a bug where the element is not updated when the scrollHeight doesn't change between resizes
				if (elementHeight === ref.current.size?.y) {
					elementHeight += 1;
				}
			}

			widthRef.current = Math.ceil(ref.current.offsetWidth / aspectRatioPointer.current);
			heightRef.current = elementHeight;

			dispatch(updateElement(id, {
				size: {
					x: Math.ceil(ref.current.offsetWidth / aspectRatioPointer.current),
					y: elementHeight,
				},
			}));
		}
	}, [dispatch, element.size?.x, element.size?.y, id, isResizing, maxHeight]);

	// We use the useResizeObserver to monitor the size of the element
	useResizeObserver(handleResize, true, ref);

	// Put in a useEffect otherwise ReactDND would overwrite it
	useEffect(() => {
		if (ref.current) {
			ref.current.setAttribute('draggable', (!isExporting && !isEditing));
		}
	}, [isEditing, isExporting]);

	useEffect(() => {
		if (!isSelected) {
			setIsEditing(false);
			setIsResizing(false);
		}
	}, [isSelected]);

	// TODO: fix a11y

	const textDecoration = useMemo(() => {
		const result = [];
		if (!element.format) {
			return result;
		}

		Object.keys(element.format).forEach((key) => {
			switch (key) {
				case 'underline':
					if (element.format[key]) {
						result.push('underline');
					}
					break;
				case 'lineThrough':
					if (element.format[key]) {
						result.push('line-through');
					}
					break;
				default:
					break;
			}
		});

		return result;
	}, [element.format]);

	// this allows us to initialize the element with the correct fontSize
	useEffect(() => {
		if (element.titleLevel && element.format.fontSize === undefined) {
			dispatch(updateElement(element.id, {
				format: { ...element.format, fontSize: DefaultSizes.HEADERS_FONT_SIZE[element.titleLevel - 1] },
			}));
		} else if (element.format && element.format.fontSize === undefined) {
			dispatch(updateElement(element.id, {
				format: { ...element.format, fontSize: DefaultSizes.FONT_SIZE },
			}));
		}
	}, [dispatch, element.format, element.id, element.titleLevel]);

	const componentStyle = useMemo(() => ({
		width: `${aspectRatio * (element.size?.x ?? 0)}px`,
		height: `${aspectRatio * (element.size?.y ?? 0)}px`,
		color: element.format?.fontColor,
		maxHeight: `${maxHeight}px`,
		maxWidth: '100%',
		fontSize: `${aspectRatio * (element.format?.fontSize ?? DefaultSizes.FONT_SIZE) * 0.85}px`,
		textDecoration: textDecoration.join(' '),
		fontWeight: element.format?.bold ? 'bold' : 'normal',
		fontStyle: element.format?.italic ? 'italic' : 'normal',
		textAlign: element.format?.alignment,
	}), [aspectRatio, element.format?.alignment, element.format?.bold, element.format?.fontColor,
		element.format?.fontSize, element.format?.italic, element.size?.x, element.size?.y, maxHeight, textDecoration]);

	/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
	return (
		<div
			ref={ref}
			className={`element${isSelected ? ' selected' : ''}${isEmpty ? ' empty' : ''}${isEditing ? ' editing' : ''}`}
			onClick={handleClick}
			onDoubleClick={handleDoubleClick}
			onContextMenu={handleContextMenu}
			draggable={!isExporting && !isEditing}
			onBlur={handleBlur}
			onKeyDown={handleKeyDown}
			// This allows us to use the focus-within css pseudo-class to style the element when it is focused
			// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
			tabIndex={0}
		>
			{element.showLabel
			&& element.labelPosition === 'ABOVE'
			&& <span ref={labelRef} className="label" style={{ fontSize: componentStyle.fontSize }}>{element.labelContent}</span>}

			<elementType.meta.component
				aspectRatio={aspectRatio}
				style={componentStyle}
				element={element}
				editing={isEditing}
				resizable={isResizing}
				pageindex={pageIndex}
			/>

			{element.showLabel
			&& element.labelPosition === 'BELOW'
			&& <span ref={labelRef} className="label" style={{ fontSize: componentStyle.fontSize }}>{element.labelContent}</span>}
			<ContextMenu elementId={id} rowIndex={rowIndex} pageIndex={pageIndex} anchorPoint={anchorPoint} isShowing={isOpenContextMenu} handleClose={handleClosingMenu} />
		</div>
	);
};

Element.propTypes = {
	id: PropTypes.string.isRequired,
	element: PropTypes.object.isRequired,
	rowIndex: PropTypes.number.isRequired,
	index: PropTypes.number.isRequired,
	aspectRatio: PropTypes.number.isRequired,
	pageIndex: PropTypes.number.isRequired,
};

export default memo(Element);
