import { useCallback, useRef, useState } from 'react';

export default () => {
	const [isDisplayed, setIsDisplayed] = useState(false);

	const toggleDisplayRef = useRef();
	toggleDisplayRef.current = isDisplayed;

	const toggleDisplay = useCallback(() => setIsDisplayed(!toggleDisplayRef.current), [toggleDisplayRef, setIsDisplayed]);

	return {
		isFilterPanelDisplayed: isDisplayed,
		toggleFilterPanel: toggleDisplay,
	};
};
