import update from 'immutability-helper';
import _ from 'lodash';

import { LinkedEntityContentSetter } from '../constants/Capabilities';
import { IssuableEntityTypes } from '../constants/EntityTypes';
import callSetter from '../utils/callSetter';

/**
 * @function
 * @name updateEntity
 * @description Updates an entity identified by the provided id
 *
 * @author Yann Hodiesne
 *
 * @param {object}	state			The current state.
 * @param {array}	state.entities	The list of entities currently registered in the state.
 * @param {object}	payload			The entity to update.
 * @param {string}	payload.id		The identifier of the entity to update.
 * @param {object}	payload.content	The new content to store inside the entity.
 *
 * @returns {object} The updated state value
 */
const updateEntity = (state, { id, content }) => {
	const { entities } = state;

	const index = _.findIndex(entities, (entity) => entity.id === id);

	if (index === -1) {
		return state;
	}

	const source = entities[index]; // The initial version of the entity.

	let result = update(state, {
		entities: {
			[index]: {
				$set: {
					...source,
					...content,
					id: source.id,
					// If the entity is issuable, keep the shouldIssue property, otherwise remove it
					shouldIssue: IssuableEntityTypes.includes(content.type ?? source.type) // If the new type of the entity (or the old one if unchanged) is issuable
						? (content.shouldIssue ?? source.shouldIssue) // Use the new shouldIssue property if it exists, otherwise use the old one
						: undefined, // Or remove the shouldIssue property
				},
			},
		},
		isDirty: {
			$set: true,
		},
	});

	// If the entity type changed, we want to reset its linked elements' property selections
	if (result.entities[index].type !== source.type) {
		result = update(result, {
			elements: { // Note : elements is an array of rows in the Editor.
				$apply: (elements) => elements.map((row) => update(row, {
					children: {
						$apply: (children) => children.map((element) => {
							if (element.linkedEntity !== id) {
								return element;
							}

							const newElement = {
								...element,
								linkedProperty: null,
							};

							// As we update the linked property, we want to trigger its setter to ensure the LINKABLE capability is aware of the change
							return callSetter(LinkedEntityContentSetter, newElement, { entities });
						}),
					},
				})),
			},
		});
	}

	// Avoid triggering a re-render if the entity properties did not change
	if (_.isEqual(source, result.entities[index])) {
		return state;
	}

	// If shouldIssue is set to true on this entity, we need to set it to false on all other issuable entities
	if (result.entities[index].shouldIssue && result.entities.some((entity) => entity.shouldIssue && entity.id !== id)) {
		result = update(result, {
			entities: {
				$apply: (values) => values.map((entity) => {
					if (IssuableEntityTypes.includes(entity.type) && entity.id !== id) {
						return update(entity, {
							shouldIssue: {
								$set: false,
							},
						});
					}

					return entity;
				}),
			},
		});
	}

	return result;
};

export default updateEntity;
