/* eslint-disable import/prefer-default-export */
import { get } from 'lib/shared/http';

/**
 * @function
 * @name getAccessRights
 * @description Retrieves the current user access rights
 *
 * @author Yann Hodiesne
 *
 * @returns {Promise}
*/
export const getAccessRights = () => get('/api/access-rights');
