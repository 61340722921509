/**
 * @constant
 * @name CapabilitiesNames
 * @description The capabilities names
 *
 * @author Matthieu Schaerlinger
 */
const CapabilitiesNames = Object.freeze({
	EDITABLE: 'EDITABLE',
	SHOW_LABEL: 'SHOW_LABEL',
	LABEL_CONTENT: 'LABEL_CONTENT',
	LABEL_POSITION: 'LABEL_POSITION',
	LINKABLE: 'LINKABLE',
	LINKED_ENTITY: 'LINKED_ENTITY',
	LINKED_PROPERTY: 'LINKED_PROPERTY',
	TITLE_LEVEL: 'TITLE_LEVEL',
	SHOW_TITLE_NUMBER: 'SHOW_TITLE_NUMBER',
	DATE_FORMAT_ADVANCED_MODE: 'DATE_FORMAT_ADVANCED_MODE',
	DATE_FORMAT: 'DATE_FORMAT',
	DATE_FORMAT_ADVANCED: 'DATE_FORMAT_ADVANCED',
	DATE_MODE: 'DATE_MODE',
	DATE_VALUE: 'DATE_VALUE',
	DATE_AUTO_VALUE: 'DATE_AUTO_VALUE',
	IMAGE_SELECTION: 'IMAGE_SELECTION',
	IMAGE_SCALING: 'IMAGE_SCALING',
	TEXT_ALIGN: 'TEXT_ALIGN',
	ELEMENT_ALIGN: 'ELEMENT_ALIGN',
	ELEMENT_LAYOUT: 'ELEMENT_LAYOUT',
	TABLE_LINKED_ENTITY: 'TABLE_LINKED_ENTITY',
	TABLE_LINKED_PROPERTY: 'TABLE_LINKED_PROPERTY',
	TABLE_AUTO_SIZE: 'TABLE_AUTO_SIZE',
	TABLE_NUMBER_OF_ROWS: 'TABLE_NUMBER_OF_ROWS',
	TEXT_OBJECT_LINK: 'TEXT_OBJECT_LINK',
	REMAINING_PAGE_NUMBERING: 'REMAINING_PAGE_NUMBERING',
	FORMAT: 'FORMAT',
	FONT_SIZE: 'FONT_SIZE',
});

export default CapabilitiesNames;
