import { useMemo } from 'react';
import PropTypes from 'prop-types';

/**
 * @name StatusCell
 * @description Custom actions cell for the InvoiceListPage table. (delete, edit and view exports for invoice)
 *
 * @author Romaric Barthe
 *
 * @param {object}		row								The object containing the data to interact with.
 * @param {boolean}		row.original.statusObject		The current status of the object.
 */
const StatusCell = ({ row: { original: { statusObject } } }) => {
	const statusColor = statusObject?.color || statusObject?.status?.defaultColor;

	const style = useMemo(() => ({
		backgroundColor: statusColor,
	}), [statusColor]);

	return (statusObject && statusObject.status ? (
		<div className="stat-pill">
			<div className="container">
				<span className="color-dot" style={style} />
				<span className="stat-label">{statusObject?.status?.name}</span>
			</div>
		</div>
	) : '');
};

StatusCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			statusObject: PropTypes.shape({
				color: PropTypes.string,
				status: PropTypes.shape({
					name: PropTypes.string,
					defaultColor: PropTypes.string,
				}),
			}),
		}).isRequired,
	}).isRequired,
};

export default StatusCell;
