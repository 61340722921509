import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchSectorTypeList } from 'redux/actions/sectorTypes';
import { useSectorTypeListSelector, useSectorTypesLoadingSelector } from 'redux/selectors/sectorTypes';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import { getRules } from './function';

/**
 * @name SectorEditionForm
 * @description A form used to edit an existing sector's information.
 *
 * @author Audrey Clerc
 *
 * @param {function}	onSubmit	The method to trigger upon form submission.
 * @param {object}		sector		The sector object to update information from.
 */
const SectorEditionForm = ({ onSubmit, sector }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [idTypeSelect, setIdTypeSelect] = useState(sector?.type?.id ?? '');

	useEffect(() => {
		dispatch(fetchSectorTypeList());
	}, [dispatch]);

	const sectorTypes = useSectorTypeListSelector();
	const areSectorTypesLoading = useSectorTypesLoadingSelector();

	const canEditSector = useAccessRight(AccessRights.commonModules.sectors.enhancedRights.CREATE_SECTOR) && !sector?.archived;

	const codeFieldValidationRules = useMemo(() => getRules(idTypeSelect, t, sectorTypes), [idTypeSelect, t, sectorTypes]);
	const defaultValues = useMemo(() => ({
		code: sector.code ?? '',
		name: sector.name,
		type: sector.type.id,
	}), [sector]);

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			disabled={!canEditSector}
			{...formProps}
		>
			<Select
				isLoading={areSectorTypesLoading}
				label={t('sector.edition.inputs.type.label')}
				labelKey="name"
				name="type"
				onChange={setIdTypeSelect}
				options={sectorTypes}
				rules={{
					required: Validators.isRequired(t('sector.edition.inputs.type.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<TextInput
				label={t('sector.edition.inputs.name.label')}
				name="name"
				rules={{
					required: Validators.isRequired(t('sector.edition.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextInput
				label={t('sector.edition.inputs.code.label')}
				name="code"
				rules={codeFieldValidationRules}
				type="text"
			/>
			<Button className="primary" type="submit" {...buttonProps}>
				{canEditSector ? t('sector.edition.action') : t('sector.edition.close')}
			</Button>
		</DynamicForm>
	);
};

SectorEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	sector: PropTypes.shape({
		archived: PropTypes.bool,
		code: PropTypes.string,
		name: PropTypes.string.isRequired,
		type: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			official: PropTypes.bool.isRequired,
		}),
	}).isRequired,
};

export default SectorEditionForm;
