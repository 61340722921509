import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import RowRenderer from 'components/templates/pdf/rendering/RowRenderer';

import { getTitleNumbers } from '../editor/functions';

import FooterRenderer from './FooterRenderer';
import HeaderRenderer from './HeaderRenderer';

const watermarkStyle = StyleSheet.create({
	container: {
		display: 'flex',
		position: 'absolute',
		height: '100%',
		width: '100%',
	},
	watermark: {
		zIndex: 9999,
		fontSize: '136px',
		color: '#dedede',
		fontWeight: 600,
		opacity: 0.6,
		transform: 'rotate(-45deg)',
		margin: 'auto',
	},
});

/**
 * @name PdfRenderer
 * @description Renders a PDF document with the elements and entities of the template.
 *
 * @author Yann Hodiesne
 *
 * @param {string}	title					The title of the rendered PDF
 * @param {string}	creator					The creator of the rendered PDF
 * @param {array}	elements				The elements to render inside the template
 * @param {array}	pages					The pages of the template
 * @param {array}	header					The header of the template
 * @param {array}	footer					The footer of the template
 * @param {string}	watermark				The watermark to render on each page of the template
 * @param {number}  pageNumberingStartIndex The index at which the page numbering should start
 */
const PdfRenderer = ({ title, creator, elements, pages, header, footer, margins, pageNumberingStartIndex, watermark }) => {
	const rendererGlobals = {
		titleNumbers: getTitleNumbers(elements),
		numberOfPages: pages.length,
		pageNumberingStartIndex,
	};

	const pageStyle = {
		paddingTop: margins.top,
		paddingBottom: margins.bottom,
		paddingLeft: margins.left,
		paddingRight: margins.right,
	};

	return (
		<Document title={title} creator={creator} producer="Air Solution">
			{pages.map((page, pageIndex) => (
				<Page size="A4" key={page.id} orientation={page.orientation} style={pageStyle}>
					{watermark && (
					<View style={watermarkStyle.container}>
						<Text style={watermarkStyle.watermark}>
							{watermark}
						</Text>
					</View>
					)}

					{ !header.disabled && !header.excludeFromPages.some((excludedPage) => excludedPage === page.id)
					&& <HeaderRenderer elements={elements} header={header} margins={margins} pageIndex={pageIndex} rendererGlobals={rendererGlobals} /> }

					{page.rows.map((row) => (
						<RowRenderer key={elements[row].id} pageIndex={pageIndex} rendererGlobals={rendererGlobals} {...elements[row]} />
					))}

					{ !footer.disabled
					&& !footer.excludeFromPages.some((excludedPage) => excludedPage === page.id)
					&& <FooterRenderer elements={elements} footer={footer} margins={margins} pageIndex={pageIndex} rendererGlobals={rendererGlobals} /> }
				</Page>
			))}
		</Document>
	);
};

PdfRenderer.propTypes = {
	title: PropTypes.string.isRequired,
	creator: PropTypes.string.isRequired,
	elements: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
	})).isRequired,
	pages: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		rows: PropTypes.arrayOf(PropTypes.number.isRequired),
		orientation: PropTypes.string.isRequired,
	})).isRequired,
	header: PropTypes.shape({
		height: PropTypes.number.isRequired,
		numberOfElements: PropTypes.number.isRequired,
		excludeFromPages: PropTypes.arrayOf(PropTypes.string).isRequired,
		disabled: PropTypes.bool.isRequired,
	}).isRequired,
	footer: PropTypes.shape({
		height: PropTypes.number.isRequired,
		numberOfElements: PropTypes.number.isRequired,
		excludeFromPages: PropTypes.arrayOf(PropTypes.string).isRequired,
		disabled: PropTypes.bool.isRequired,
	}).isRequired,
	margins: PropTypes.shape({
		top: PropTypes.number.isRequired,
		right: PropTypes.number.isRequired,
		bottom: PropTypes.number.isRequired,
		left: PropTypes.number.isRequired,
	}).isRequired,
	watermark: PropTypes.string,
	pageNumberingStartIndex: PropTypes.number,
};

PdfRenderer.defaultProps = {
	watermark: undefined,
	pageNumberingStartIndex: 0,
};

export default PdfRenderer;
