import update from 'immutability-helper';

/**
 * @function
 * @name updateHeader
 * @description Updates the content of the header
 *
 * @author Florian Fornazaric
 *
 * @param {object} state					The current state
 * @param {object} payload.content			The new content to store inside the header
 *
 * @returns {object} The updated state value
 */
const updateHeader = (state, { content }) => {
	const { header } = state;

	const source = header;
	const newHeader = update(header, {
		$set: {
			...source,
			...content,
		},
	});

	return {
		...state,
		header: newHeader,
	};
};

export default updateHeader;
