import PropTypes from 'prop-types';

/**
 * @name TargetPartnersCell
 * @description Custom cell for the historyListPage table displaying a history's partners.
 *
 * @author Romaric Barthe
 */
const TargetPartnersCell = ({ row: { original: { targetPartners } } }) => (
	<ul className="tag-list">
		{targetPartners.map(({ id, partner }) => (
			<li key={id}>
				<span className="tag">
					{partner.name}
				</span>
			</li>
		))}
	</ul>
);

TargetPartnersCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			targetPartners: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.string.isRequired,
				partner: PropTypes.shape({
					id: PropTypes.string.isRequired,
					name: PropTypes.string.isRequired,
				}).isRequired,
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default TargetPartnersCell;
