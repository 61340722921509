import { ActionTypes } from '../../actions/quotations/quotations';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_QUOTATION_LIST_REQUEST:
		case ActionTypes.FETCH_ALL_FOR_QUOTATION_REQUEST:
		case ActionTypes.ADD_QUOTATION_REQUEST:
		case ActionTypes.EXPORT_QUOTATION_REQUEST:
		case ActionTypes.UPDATE_QUOTATION_REQUEST:
		case ActionTypes.REMOVE_QUOTATION_REQUEST:
		case ActionTypes.ARCHIVE_QUOTATION_REQUEST:
		case ActionTypes.RESTORE_QUOTATION_REQUEST:
		case ActionTypes.FAVORITE_QUOTATION_REQUEST:
		case ActionTypes.UNFAVORITE_QUOTATION_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.EDIT_QUOTATION_REQUEST:
		case ActionTypes.FETCH_QUOTATION_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_QUOTATION_LIST_FAILURE:
		case ActionTypes.FETCH_ALL_FOR_QUOTATION_FAILURE:
		case ActionTypes.FETCH_QUOTATION_FAILURE:
		case ActionTypes.FETCH_GENERATED_PDF_QUOTATION_FAILURE:
		case ActionTypes.ADD_QUOTATION_FAILURE:
		case ActionTypes.EDIT_QUOTATION_FAILURE:
		case ActionTypes.EXPORT_QUOTATION_FAILURE:
		case ActionTypes.UPDATE_QUOTATION_FAILURE:
		case ActionTypes.REMOVE_QUOTATION_FAILURE:
		case ActionTypes.ARCHIVE_QUOTATION_FAILURE:
		case ActionTypes.RESTORE_QUOTATION_FAILURE:
		case ActionTypes.FAVORITE_QUOTATION_FAILURE:
		case ActionTypes.UNFAVORITE_QUOTATION_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.REMOVE_QUOTATION_SUCCESS:
		case ActionTypes.ADD_QUOTATION_SUCCESS:
		case ActionTypes.EXPORT_QUOTATION_SUCCESS:
		case ActionTypes.UPDATE_QUOTATION_SUCCESS:
		case ActionTypes.ARCHIVE_QUOTATION_SUCCESS:
		case ActionTypes.RESTORE_QUOTATION_SUCCESS:
		case ActionTypes.FAVORITE_QUOTATION_SUCCESS:
		case ActionTypes.UNFAVORITE_QUOTATION_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_QUOTATION_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.quotations,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_ALL_FOR_QUOTATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				allForForm: payload.allForForm,
			};
		case ActionTypes.FETCH_QUOTATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.quotation,
			};
		case ActionTypes.EDIT_QUOTATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.enhancedQuotation,
			};
		case ActionTypes.FETCH_GENERATED_PDF_QUOTATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.generatedPdf,
			};
		default:
			return state;
	}
};
