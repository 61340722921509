import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import getCapabilities from './getCapabilities';

/**
 * @function
 * @name createElement
 * @description Creates an instance of the given element type
 *
 * @author Yann Hodiesne
 */
const createElement = ({ meta, ...element }) => ({
	// Applying capabilities default values
	...Object.fromEntries(_.flatten(getCapabilities(element).map((capability) => {
		/**
		 * @name collectCapabilities
		 * @description Recursively collects nested capabilities
		 *
		 * @author Yann Hodiesne
		 */
		const collectCapabilities = (cap, result = []) => {
			result.push(cap);

			cap.triggers.forEach((trigger) => trigger.capabilities.forEach((x) => collectCapabilities(x, result)));

			return result;
		};

		const capabilities = collectCapabilities(capability);

		return capabilities.map((cap) => [cap.property, cap.defaultValue]);
	}))),
	// Creating an instance of the provided element type
	id: uuid(),
	size: meta.defaultSize,
	content: meta.defaultContent,
	...element,
});

export default createElement;
