import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchPartner, updatePartner } from 'redux/actions/partners/partners';
import { useCurrentPartnerSelector } from 'redux/selectors/partners/partners';
import routes from 'routes';

import { PartnerEditionForm } from 'components/partners';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name PartnerEditionPage
 * @description A page used to edit an existing partner.
 *
 * @author Audrey Clerc
 *
 */
const PartnerEditionPage = () => {
	const { id: partnerId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const partner = useCurrentPartnerSelector(partnerId);

	const canEditPartner = useAccessRight(AccessRights.commonModules.partners.enhancedRights.CREATE_PARTNER);

	useEffect(() => {
		dispatch(fetchPartner(partnerId));
	}, [dispatch, partnerId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData             The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditPartner) {
			dispatch(updatePartner(formData, partnerId));
		} else {
			redirectOnSuccess(routes.commonModules.partners.list);
		}
	}, [canEditPartner, dispatch, partnerId]);

	return (
		partner
			? <PartnerEditionForm partner={partner} onSubmit={handleFormSubmit} />
			: <p>{t('partner.edition.loading')}</p>
	);
};

export default PartnerEditionPage;
