import { toast } from 'react-toastify';
import * as NumberFormatsApi from 'api/numberFormatsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the NumberFormats redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific number format
	FETCH_NUMBER_FORMAT_REQUEST: '@NUMBER_FORMATS/FETCH_REQUEST',
	FETCH_NUMBER_FORMAT_SUCCESS: '@NUMBER_FORMATS/FETCH_SUCCESS',
	FETCH_NUMBER_FORMAT_FAILURE: '@NUMBER_FORMATS/FETCH_FAILURE',

	// Fetch a list of number formats
	FETCH_NUMBER_FORMAT_LIST_REQUEST: '@NUMBER_FORMATS/FETCH_LIST_REQUEST',
	FETCH_NUMBER_FORMAT_LIST_SUCCESS: '@NUMBER_FORMATS/FETCH_LIST_SUCCESS',
	FETCH_NUMBER_FORMAT_LIST_FAILURE: '@NUMBER_FORMATS/FETCH_LIST_FAILURE',

	// Add a new number format to the database
	ADD_NUMBER_FORMAT_REQUEST: '@NUMBER_FORMATS/ADD_REQUEST',
	ADD_NUMBER_FORMAT_SUCCESS: '@NUMBER_FORMATS/ADD_SUCCESS',
	ADD_NUMBER_FORMAT_FAILURE: '@NUMBER_FORMATS/ADD_FAILURE',

	// Update an existing number format from the database
	UPDATE_NUMBER_FORMAT_REQUEST: '@NUMBER_FORMATS/UPDATE_REQUEST',
	UPDATE_NUMBER_FORMAT_SUCCESS: '@NUMBER_FORMATS/UPDATE_SUCCESS',
	UPDATE_NUMBER_FORMAT_FAILURE: '@NUMBER_FORMATS/UPDATE_FAILURE',

	// Remove an existing number format from the database
	REMOVE_NUMBER_FORMAT_REQUEST: '@NUMBER_FORMATS/REMOVE_REQUEST',
	REMOVE_NUMBER_FORMAT_SUCCESS: '@NUMBER_FORMATS/REMOVE_SUCCESS',
	REMOVE_NUMBER_FORMAT_FAILURE: '@NUMBER_FORMATS/REMOVE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Number Format list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchNumberFormatListRequest
 * @description Action triggered anytime a number format list fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchNumberFormatListRequest = () => ({ type: ActionTypes.FETCH_NUMBER_FORMAT_LIST_REQUEST });

/**
 * @function
 * @name fetchNumberFormatListSuccess
 * @description Action triggered as a result to a successful number format list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} numberFormats    The list of retrieved number formats.
 * @param {number} totalCount 		The total amount of number formats available in the database for the current user.
 *
 * @returns {object}
 */
const fetchNumberFormatListSuccess = ({ numberFormats, totalCount }) => ({
	type: ActionTypes.FETCH_NUMBER_FORMAT_LIST_SUCCESS,
	payload: { numberFormats, totalCount },
});

/**
 * @function
 * @name fetchNumberFormatListFailure
 * @description Action triggered as a result to a failed number format list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchNumberFormatListFailure = (error) => ({
	type: ActionTypes.FETCH_NUMBER_FORMAT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific number format fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchNumberFormatRequest
 * @description Action triggered anytime a number format fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchNumberFormatRequest = () => ({ type: ActionTypes.FETCH_NUMBER_FORMAT_REQUEST });

/**
 * @function
 * @name fetchNumberFormatSuccess
 * @description Action triggered as a result to a successful number format fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} numberFormat	The retrieved number format.
 *
 * @returns {object}
 */
const fetchNumberFormatSuccess = ({ numberFormat }) => ({
	type: ActionTypes.FETCH_NUMBER_FORMAT_SUCCESS,
	payload: { numberFormat },
});

/**
 * @function
 * @name fetchNumberFormatFailure
 * @description Action triggered as a result to a failed number format fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchNumberFormatFailure = (error) => ({
	type: ActionTypes.FETCH_NUMBER_FORMAT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Number Format creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addNumberFormatRequest
 * @description Action triggered anytime a number format creation call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const addNumberFormatRequest = () => ({ type: ActionTypes.ADD_NUMBER_FORMAT_REQUEST });

/**
 * @function
 * @name addNumberFormatSuccess
 * @description Action triggered as a result to a successful number format creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} numberFormat		The created number format object.
 *
 * @returns {object}
 */
const addNumberFormatSuccess = ({ numberFormat }) => ({
	type: ActionTypes.ADD_NUMBER_FORMAT_SUCCESS,
	payload: { numberFormat },
});

/**
 * @function
 * @name addNumberFormatFailure
 * @description Action triggered as a result to a failed number format creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addNumberFormatFailure = (error) => ({
	type: ActionTypes.ADD_NUMBER_FORMAT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Number Format update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateNumberFormatRequest
 * @description Action triggered anytime a number format update call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const updateNumberFormatRequest = () => ({ type: ActionTypes.UPDATE_NUMBER_FORMAT_REQUEST });

/**
 * @function
 * @name updateNumberFormatSuccess
 * @description Action triggered as a result to a successful number format update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} numberFormat		The updated number format object.
 *
 * @returns {object}
 */
const updateNumberFormatSuccess = ({ numberFormat }) => ({
	type: ActionTypes.UPDATE_NUMBER_FORMAT_SUCCESS,
	payload: { numberFormat },
});

/**
 * @function
 * @name updateNumberFormatFailure
 * @description Action triggered as a result to a failed number format update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateNumberFormatFailure = (error) => ({
	type: ActionTypes.UPDATE_NUMBER_FORMAT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Number Format removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeNumberFormatRequest
 * @description Action triggered anytime a number format deletion call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const removeNumberFormatRequest = () => ({ type: ActionTypes.REMOVE_NUMBER_FORMAT_REQUEST });

/**
 * @function
 * @name removeNumberFormatSuccess
 * @description Action triggered as a result to a successful number format deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {string} deletedNumberFormatId		The removed number format object id.
 *
 * @returns {object}
 */
const removeNumberFormatSuccess = ({ deletedNumberFormatId }) => ({
	type: ActionTypes.REMOVE_NUMBER_FORMAT_SUCCESS,
	payload: { deletedNumberFormatId },
});

/**
 * @function
 * @name removeNumberFormatFailure
 * @description Action triggered as a result to a failed number format deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeNumberFormatFailure = (error) => ({
	type: ActionTypes.REMOVE_NUMBER_FORMAT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchNumberFormatList
 * @description Method used to update the number format list.
 *
 * @author Audrey Clerc
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchNumberFormatList = (params) => (dispatch) => {
	dispatch(fetchNumberFormatListRequest());

	return NumberFormatsApi.fetchNumberFormats(params)
		.then(({ numberFormats, totalCount }) => dispatch(fetchNumberFormatListSuccess({ numberFormats, totalCount })))
		.catch((error) => dispatch(fetchNumberFormatListFailure(error)));
};

/**
 * @function
 * @name fetchNumberFormat
 * @description Method used to fetch the latest version of a specific number format.
 *
 * @author Audrey Clerc
 *
 * @param {string} numberFormatId	The id of the number format we want to retrieve.
 */
export const fetchNumberFormat = (numberFormatId) => (dispatch) => {
	dispatch(fetchNumberFormatRequest());

	return NumberFormatsApi.fetchNumberFormatById(numberFormatId)
		.then(({ numberFormat }) => dispatch(fetchNumberFormatSuccess({ numberFormat })))
		.catch((error) => dispatch(fetchNumberFormatFailure(error)));
};

/**
 * @function
 * @name addNumberFormat
 * @description Method used to add a new number format instance to the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} numberFormatData		The data to create the new number format from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addNumberFormat = (numberFormatData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addNumberFormatRequest());

	return NumberFormatsApi.createNumberFormat(numberFormatData)
		.then(({ numberFormat }) => {
			toast.success(i18next.t('number_format.creation.toasts.success', { name: `${numberFormat.environment}` }));
			dispatch(addNumberFormatSuccess({ numberFormat }));
			redirectOnSuccess(onSuccessRoute);

			return numberFormat;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('number_format.creation.toasts.conflict'));
				dispatch(addNumberFormatFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('number_format.creation.toasts.error'));
				dispatch(addNumberFormatFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateNumberFormat
 * @description Method used to update an existing number format instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} numberFormatData		The object to update the number format with.
 * @param {string} numberFormatId		The id of the number format we want to update.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateNumberFormat = (numberFormatData, numberFormatId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateNumberFormatRequest());

	return NumberFormatsApi.updateNumberFormat(numberFormatData, numberFormatId)
		.then(({ numberFormat }) => {
			dispatch(updateNumberFormatSuccess({ numberFormat }));
			toast.success(i18next.t('number_format.edition.toasts.success', { name: `${numberFormat.environment}` }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('number_format.edition.toasts.conflict'));
				dispatch(updateNumberFormatFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('number_format.edition.toasts.error'));
				dispatch(updateNumberFormatFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeNumberFormat
 * @description Method used to remove an existing number format instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} numberFormat				The number format we want to remove from the database.
 * @param {string} numberFormat.id			The id of the number format we want to remove from the database.
 * @param {string} numberFormat.environment	The name of the environment from which we want to remove the number format from the database.
 */
export const removeNumberFormat = ({ id, environment }) => (dispatch) => {
	dispatch(removeNumberFormatRequest());

	return NumberFormatsApi.deleteNumberFormat(id)
		.then(({ deletedNumberFormatId }) => {
			dispatch(removeNumberFormatSuccess({ deletedNumberFormatId }));
			toast.success(i18next.t('number_format.deletion.toasts.success', { name: environment }));
		})
		.catch((error) => {
			dispatch(removeNumberFormatFailure(error));
			toast.error(i18next.t('number_format.deletion.toasts.error'));
		});
};
