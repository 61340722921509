import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import camelcase from 'camelcase';
import { Environments, Modules } from 'constants/environmentEnums';
import { addStatusObject } from 'redux/actions/statusObjects';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { StatusObjectCreationForm } from 'components/statusObjects';

/**
 * @name StatusObjectCreationPage
 * @description A page used to create a new status object.
 *
 * @author Romaric Barthe
 */
const StatusObjectCreationPage = () => {
	const [, appSplitUrl] = history.location.pathname.split('/');
	const appName = useMemo(() => camelcase(appSplitUrl), [appSplitUrl]);
	const dispatch = useDispatch();

	const handleFormSubmit = useCallback((formData) => {
		dispatch(addStatusObject(formData, routes[appName].parameters.statusObjects.list));
	}, [appName, dispatch]);

	const defaultEnvironment = useMemo(() => {
		switch (appName) {
			case `${Modules.ACCOUNTING}`:
				return Environments.INVOICE;
			case `${Modules.OPERATIONS}`:
				return Environments.TARGETING;
			case `${Modules.HUMAN_RESOURCES}`:
				return Environments.EXPENSE;
			default:
				return undefined;
		}
	}, [appName]);

	return (
		<StatusObjectCreationForm defaultEnvironment={defaultEnvironment} onSubmit={handleFormSubmit} />
	);
};

export default StatusObjectCreationPage;
