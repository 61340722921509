import { useCallback, useMemo } from 'react';
import { ChevronLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCurrentTargetingSelector } from 'redux/selectors/targetings/targetings';
import { useCurrentTargetSelector } from 'redux/selectors/targets/targets';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import StatusesViewer from 'components/shared/statuses/StatusesViewer';

import 'styles/pages/targetDetailsPages.scss';

/**
 * @name TargetHeader
 * @description A component to display the target's information.
 *
 * @author Romaric Barthe
 *
 * @param {string}	targetingId		The id of the targeting.
 * @param {string}	targetId		The id of the target.
 * @param {object}	targetStats		The target stats.
 */
const TargetHeader = ({ targetingId, targetId, targetStats }) => {
	const { t } = useTranslation();

	const targeting = useCurrentTargetingSelector(targetingId);
	const target = useCurrentTargetSelector(targetId);

	const { mission } = useMemo(() => ({ mission: targeting?.project.name }), [targeting]);
	const { targetName, targetDescription, targetPartnersAmount } = useMemo(() => (
		{ targetName: target?.name, targetDescription: target?.description, targetPartnersAmount: target?.targetPartners.length }
	), [target]);

	/**
	 * @function
	 * @name onBackButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onBackButtonClick = useCallback(() => {
		history.push(generatePath(routes.operations.targetings.targets.list, { targetingId }));
	}, [targetingId]);

	return (
		targeting && target ? (
			<div className="target-header">
				<Button className="icon-only return-button" onClick={onBackButtonClick}>
					<ChevronLeft />
				</Button>
				<div className="identity">
					<div>
						<h4>{mission}</h4>
					</div>
					<h5>{targetName}</h5>
					<p>{targetDescription}</p>
				</div>
				<div className="metadata">
					<p>{t('targeting.target.headers.target_partner', { count: targetPartnersAmount })}</p>
					<StatusesViewer statuses={targetStats} />
				</div>
			</div>
		) : null
	);
};

TargetHeader.propTypes = {
	targetId: PropTypes.string.isRequired,
	targetStats: PropTypes.arrayOf(PropTypes.object),
	targetingId: PropTypes.string.isRequired,
};

TargetHeader.defaultProps = {
	targetStats: [],
};

export default TargetHeader;
