import { v4 as uuid } from 'uuid';

import { DefaultSizes } from '../../constants';

/**
 * @function
 * @name createRow
 * @description Creates a new row with the given elements as the content of the row
 *
 * @author Yann Hodiesne
 */
const createRow = (...elements) => ({
	id: uuid(),
	children: elements,
	height: DefaultSizes.ROW_HEIGHT,
});

export default createRow;
