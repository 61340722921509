import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addSector } from 'redux/actions/sectors/sectors';
import routes from 'routes';

import { SectorCreationForm } from 'components/sectors';

/**
 * @name SectorCreationPage
 * @description A page used to create a new number format.
 *
 * @author Audrey Clerc
*/
const SectorCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addSector(formData, routes.commonModules.sectors.list));
	}, [dispatch]);

	return (
		<SectorCreationForm onSubmit={handleFormSubmit} />
	);
};

export default SectorCreationPage;
