import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSearch } from 'lib/hooks';
import _ from 'lodash';
import { batchEnableCompanies, fetchCompanyList } from 'redux/actions/companies';
import { useCompanyListSelector, useCompanyTotalCountSelector } from 'redux/selectors/companies';
import routes from 'routes';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { ActionsCell, EnabledCell } from 'components/superadmin/companyList';

import 'styles/pages/dataTablePages.scss';

/**
 * @name CompanyListPage
 * @description A list of all companies accessible from the superadmin module.
 *
 * From this page, the user is able to view, edit and enable/disable any company.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 */
const CompanyListPage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const companies = useCompanyListSelector();
	const totalCount = useCompanyTotalCountSelector();

	const [selectedCompanies, setSelectedCompanies] = useState([]);

	const selectedCompaniesChanged = useCallback((pSelectedCompanies) => {
		setSelectedCompanies(pSelectedCompanies);
	}, [setSelectedCompanies]);

	const selectedDisabledCompanies = useMemo(() => _.filter(selectedCompanies, (company) => company.enabled === false), [selectedCompanies]);

	const headers = useMemo(() => [
		{ accessor: 'name', Header: t('super_admin.company.fields.name'), pinColumn: true },
		{ id: 'enabled', Header: t('super_admin.company.fields.enabled'), pinColumn: true, Cell: EnabledCell },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell },
		{
			id: 'representative',
			accessor: (row) => t('formatting.contact.full_name', { ...row.companyOwner.contact }),
			Header: t('super_admin.company.fields.representative'),
			sorting: 'contact.lastName',
		},
		{ accessor: 'address', Header: t('super_admin.company.fields.address'), sorting: 'partner.address' },
		{ accessor: 'postCode', Header: t('super_admin.company.fields.postCode'), sorting: 'partner.postCode' },
		{ accessor: 'city', Header: t('super_admin.company.fields.city'), sorting: 'partner.city' },
		{ accessor: 'country', Header: t('super_admin.company.fields.country'), sorting: 'partner.country' },
		{ accessor: 'emails', Header: t('super_admin.company.fields.emails'), sorting: false },
		{ accessor: 'phones', Header: t('super_admin.company.fields.phones'), sorting: false },
		{ accessor: 'website', Header: t('super_admin.company.fields.website'), sorting: 'partner.website' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchCompanyList(params)));

	const handleEnableCompanies = useCallback(() => {
		dispatch(batchEnableCompanies(selectedDisabledCompanies));
	}, [dispatch, selectedDisabledCompanies]);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				<Link className="button primary" to={routes.superAdmin.companyCreation}>
					{t('super_admin.company.links.create')}
				</Link>
				<Button
					className="primary"
					onClick={handleEnableCompanies}
					disabled={selectedDisabledCompanies.length === 0}
				>
					{t('super_admin.company.enable.action', { count: selectedDisabledCompanies.length })}
				</Button>
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={companies}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="company"
				selectionChanged={selectedCompaniesChanged}
			/>
		</div>
	);
};

export default CompanyListPage;
