import PropTypes from 'prop-types';

/**
 * @name ProjectsCell
 * @description Custom cell for the historyListPage table displaying a history's projects.
 *
 * @author Romaric Barthe
 * @author Audrey Clerc
 */
const ProjectsCell = ({ row: { original: { projects } } }) => (
	<ul className="tag-list">
		{projects.map(({ id, name }) => (
			<li key={id}>
				<span className="tag">
					{name}
				</span>
			</li>
		))}
	</ul>
);

ProjectsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			projects: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default ProjectsCell;
