import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useResizeObserver } from 'lib/hooks';
import PropTypes from 'prop-types';

import { DefaultSizes, DragAndDropTypes } from '../../../constants';
import { EMPTY_ROW } from '../../../constants/ElementTypes';
import EditorContext from '../../../EditorContext';
import { getElementType } from '../../../functions/internals';
import { insertElement, insertRow, moveElementById, moveRowById, resetMovingElement, setMovingElement, updateRow } from '../../../reducer/actions';

import ContextMenu from './ContextMenu';
import Element from './Element';

/**
 * @name Row
 * @description A single row acting as a droppable area for elements and element types
 *
 * @author Yann Hodiesne
 * @author Florian Fornazaric
 *
 * @param {string}	id						Identifier of the current row
 * @param {number}	index					Index of the current row
 * @param {number}	count					Number of elements contained in the current row
 * @param {array}	elements				Elements contained in the current row
 * @param {number}	aspectRatio				Aspect ratio of the template
 * @param {number}  height					Height of the current row
 * @param {boolean}	isMovingBetweenPages	Whether the current row is being moved between pages
 * @param {number} 	pageIndex 				Index of the current page
 * @param {boolean} isShown 				Whether the current row is shown
 */
const Row = ({ id, index, count, elements, aspectRatio, height, isMovingBetweenPages, pageIndex, isShown }) => {
	const { dispatch, isExporting, movingElement, margins } = useContext(EditorContext);

	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
	const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

	const ref = useRef(null);

	// eslint-disable-next-line no-unused-vars
	const dropBehaviour = useCallback((fromContent) => (item, monitor) => {
		// If the drag logic has already been handled by another nested `drop` function, we skip it here
		if (monitor.getDropResult()?.handled === true) {
			return undefined;
		}

		switch (monitor.getItemType()) {
			case DragAndDropTypes.PDF_ELEMENT_TYPE:
				// If the dropped item is an empty row, insert a new row before the current one
				if (item.type === EMPTY_ROW.type) {
					dispatch(insertRow(index));
				} else {
					// If the dropped item is an element type, insert a new element at the end of the row
					dispatch(insertElement(item, index, count));
				}

				return { handled: true };
			default:
				break;
		}

		return undefined;
	}, [count, dispatch, index]);

	const hoverBehaviour = useCallback((fromContent) => (item, monitor) => {
		const hoverBoundingRect = ref.current?.getBoundingClientRect();
		const hoverThreshold = DefaultSizes.ROW_HEIGHT * aspectRatio;

		let isOverThreshold = false;

		// This allows us to only trigger the 'MoveRowById' under those circumstances:
		// If the element is moved downward, we check if the element is moved between the bottom of the row and the base height of a row
		if (monitor.getDifferenceFromInitialOffset().y > 0) {
			isOverThreshold = hoverBoundingRect.bottom - monitor.getClientOffset().y < hoverThreshold;
		// If the element is moved upward, we check if the element is moved between the top of the row and the base height of a row
		} else {
			isOverThreshold = monitor.getClientOffset().y - hoverBoundingRect.top < hoverThreshold;
		}

		switch (monitor.getItemType()) {
			case DragAndDropTypes.PDF_ROW:
				// If the current hovering element is the row that's being hovered, we don't process the hover
				if (isShown && item.id !== id && isOverThreshold) {
					dispatch(moveRowById(item.id, index));
				}
				break;
			case DragAndDropTypes.PDF_ELEMENT:
				if (!fromContent && (elements.length === 0 || (elements.length > 0 && getElementType(elements[0]).meta.inline))) {
					// If an element is hovering over the tail, we move the dragged element at the end of the current row
					dispatch(moveElementById(item.id, index, count));
				}
				break;
			default:
				break;
		}
	}, [aspectRatio, isShown, id, elements, dispatch, index, count]);

	// eslint-disable-next-line no-unused-vars
	const canDropBehaviour = useCallback((fromContent) => (item, monitor) => {
		switch (monitor.getItemType()) {
			case DragAndDropTypes.PDF_ELEMENT_TYPE:
			case DragAndDropTypes.PDF_ELEMENT:
				// If the row contains only one element and the element in not inline, we can't drop
				return elements.length === 0 || (elements.length > 0 && getElementType(elements[0]).meta.inline);
			default:
				return true;
		}
	}, [elements]);

	// Handle drag and drop events on the content part of the row (left side)
	const [{ canDropContent, isOverContent, showSkeletonContent }, rowDrop] = useDrop({
		accept: [DragAndDropTypes.PDF_ELEMENT, DragAndDropTypes.PDF_ELEMENT_TYPE, DragAndDropTypes.PDF_ROW],
		drop: dropBehaviour(true),
		hover: hoverBehaviour(true),
		canDrop: canDropBehaviour(true),
		collect: (monitor) => ({
			canDropContent: monitor.canDrop(),
			isOverContent: monitor.isOver(),
			showSkeletonContent: monitor.getItemType() === DragAndDropTypes.PDF_ELEMENT_TYPE && !monitor.getItem().meta.inline && monitor.isOver(),
		}),
	}, [dropBehaviour, hoverBehaviour]);

	// Handle drag and drop events on the empty part of the row (right side)
	const [{ canDropTail, isOverTail, showSkeletonTail }, tailDrop] = useDrop({
		accept: [DragAndDropTypes.PDF_ELEMENT, DragAndDropTypes.PDF_ELEMENT_TYPE],
		drop: dropBehaviour(false),
		hover: hoverBehaviour(false),
		canDrop: canDropBehaviour(false),
		collect: (monitor) => ({
			canDropTail: monitor.canDrop(),
			isOverTail: monitor.isOver(),
			showSkeletonTail: monitor.getItemType() === DragAndDropTypes.PDF_ELEMENT_TYPE && !monitor.getItem().meta.inline && monitor.isOver(),
		}),
	}, [dropBehaviour, hoverBehaviour]);

	const [{ isDragging }, drag, dragPreview] = useDrag({
		type: DragAndDropTypes.PDF_ROW,
		item: () => ({
			id,
		}),
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		end: () => {
			// If an element is being moved we reset the moving element in the state once it's dropped
			if (movingElement) {
				dispatch(resetMovingElement());
			}
		},
		canDrag: !isExporting,
	}, [id, movingElement, dispatch]);

	useEffect(() => {
		dragPreview(getEmptyImage());
	}, [dragPreview]);

	const handleClosingMenu = useCallback(() => {
		setIsContextMenuOpen(false);
	}, []);

	const handleContextMenu = useCallback((e) => {
		if (!isExporting) {
			// We remove the ContextMenu behaviour
			e.preventDefault();
			e.stopPropagation();
			// Allows us to set the position of the context menu
			setAnchorPoint({ x: e.clientX, y: e.clientY });
			setIsContextMenuOpen(true);
		}
	}, [isExporting]);

	const canDrop = useMemo(() => canDropContent || canDropTail || isContextMenuOpen, [canDropContent, canDropTail, isContextMenuOpen]);
	const isDraggedOver = useMemo(() => isOverContent || isOverTail || isContextMenuOpen, [isContextMenuOpen, isOverContent, isOverTail]);
	// isSkeletonShown is true if a non-inline element type is being dragged over the current row
	const isSkeletonShown = useMemo(() => showSkeletonContent || showSkeletonTail, [showSkeletonContent, showSkeletonTail]);

	const heightPointer = useRef(height);
	heightPointer.current = height;

	const aspectRatioPointer = useRef(aspectRatio);
	aspectRatioPointer.current = aspectRatio;
	const indexPointer = useRef(index);
	indexPointer.current = index;

	// We use the skeletonRef to compute the height of the row without it's height
	const skeletonRef = useRef(null);

	const handleResize = useCallback(() => {
		// We remove the height of the skeleton so that it isn't taken into account when computing the height of the row
		const rowHeight = Math.round(ref.current.offsetHeight / aspectRatioPointer.current) - skeletonRef.current.offsetHeight;
		// We update the height of the row if it has changed
		if (heightPointer.current !== rowHeight && rowHeight > 0) {
			heightPointer.current = rowHeight;
			dispatch(updateRow(indexPointer.current, { height: rowHeight }));
		}
	}, [dispatch]);

	const style = useMemo(() => ({
		minHeight: elements.length === 0 ? `${DefaultSizes.ROW_HEIGHT * aspectRatio}px` : 'auto',
		display: isShown ? '' : 'none',
	}), [aspectRatio, elements.length, isShown]);

	// This allows us to put the drag handle at the right place when the aspect ratio changes, and the margins are updated
	const dragHandlePositionStyle = useMemo(() => ({
		left: `${-(7 + margins.left * aspectRatio)}px`,
	}), [aspectRatio, margins.left]);

	useEffect(() => {
		if (isDragging && !movingElement) {
			// We set the movingElement to the current row
			dispatch(setMovingElement(id));
		}
	}, [isDragging, dispatch, id, movingElement]);

	// We use the useResizeObserver to monitor the height of the row
	useResizeObserver(handleResize, !isMovingBetweenPages, ref);

	dragPreview(rowDrop(ref));

	return (
		<>
			<div
				ref={ref}
				className={`row${!isSkeletonShown && isDraggedOver ? ' drag-over' : ''}${isDragging ? ' dragging' : ''}${canDrop ? ' can-drop' : ''}`}
				onContextMenu={handleContextMenu}
				style={style}
			>
				<div ref={skeletonRef} className={`skeleton${isSkeletonShown ? ' show' : ''}`} />
				<div ref={drag} className="drag-handle-wrapper" style={dragHandlePositionStyle}>
					<div className="drag-handle" />
				</div>
				<div className="content">
					{elements.map((item, itemIndex) => (
						<Element
							key={item.id}
							id={item.id}
							element={item}
							rowIndex={index}
							index={itemIndex}
							aspectRatio={aspectRatio}
							pageIndex={pageIndex}
						/>
					))}
				</div>
				<div ref={tailDrop} className="tail" />
			</div>
			<ContextMenu rowIndex={index} pageIndex={pageIndex} anchorPoint={anchorPoint} isShowing={isContextMenuOpen} handleClose={handleClosingMenu} />
		</>
	);
};

Row.propTypes = {
	id: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	elements: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
	})).isRequired,
	aspectRatio: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	pageIndex: PropTypes.number.isRequired,
	isMovingBetweenPages: PropTypes.bool,
	isShown: PropTypes.bool,
};

Row.defaultProps = {
	isMovingBetweenPages: false,
	isShown: true,
};

export default memo(Row);
