import { toast } from 'react-toastify';
import * as ExpensesApi from 'api/expensesApi';
import download from 'downloadjs';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the expenses redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific expense
	FETCH_EXPENSE_REQUEST: '@EXPENSES/FETCH_REQUEST',
	FETCH_EXPENSE_SUCCESS: '@EXPENSES/FETCH_SUCCESS',
	FETCH_EXPENSE_FAILURE: '@EXPENSES/FETCH_FAILURE',

	// Fetch all for expenses
	FETCH_ALL_FOR_EXPENSE_REQUEST: '@EXPENSES/FETCH_ALL_FOR_REQUEST',
	FETCH_ALL_FOR_EXPENSE_SUCCESS: '@EXPENSES/FETCH_ALL_FOR_SUCCESS',
	FETCH_ALL_FOR_EXPENSE_FAILURE: '@EXPENSES/FETCH_ALL_FOR_FAILURE',

	// Fetch a list of expenses
	FETCH_EXPENSE_LIST_REQUEST: '@EXPENSES/FETCH_LIST_REQUEST',
	FETCH_EXPENSE_LIST_SUCCESS: '@EXPENSES/FETCH_LIST_SUCCESS',
	FETCH_EXPENSE_LIST_FAILURE: '@EXPENSES/FETCH_LIST_FAILURE',

	// Add a new expense to the database
	ADD_EXPENSE_REQUEST: '@EXPENSES/ADD_REQUEST',
	ADD_EXPENSE_SUCCESS: '@EXPENSES/ADD_SUCCESS',
	ADD_EXPENSE_FAILURE: '@EXPENSES/ADD_FAILURE',

	// Update an existing expense from the database
	UPDATE_EXPENSE_REQUEST: '@EXPENSES/UPDATE_REQUEST',
	UPDATE_EXPENSE_SUCCESS: '@EXPENSES/UPDATE_SUCCESS',
	UPDATE_EXPENSE_FAILURE: '@EXPENSES/UPDATE_FAILURE',

	// Remove an existing expense from the database
	REMOVE_EXPENSE_REQUEST: '@EXPENSES/REMOVE_REQUEST',
	REMOVE_EXPENSE_SUCCESS: '@EXPENSES/REMOVE_SUCCESS',
	REMOVE_EXPENSE_FAILURE: '@EXPENSES/REMOVE_FAILURE',

	// Edit an existing invoice from the database
	EDIT_EXPENSE_REQUEST: '@EXPENSES/EDIT_REQUEST',
	EDIT_EXPENSE_SUCCESS: '@EXPENSES/EDIT_SUCCESS',
	EDIT_EXPENSE_FAILURE: '@EXPENSES/EDIT_FAILURE',

	// Export expenses as a file
	EXPORT_EXPENSE_REQUEST: '@EXPENSES/EXPORT_REQUEST',
	EXPORT_EXPENSE_SUCCESS: '@EXPENSES/EXPORT_SUCCESS',
	EXPORT_EXPENSE_FAILURE: '@EXPENSES/EXPORT_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Expense list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchExpenseListRequest
 * @description Action triggered anytime an expense list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchExpenseListRequest = () => ({ type: ActionTypes.FETCH_EXPENSE_LIST_REQUEST });

/**
 * @function
 * @name fetchExpenseListSuccess
 * @description Action triggered as a result to a successful expense list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} expenses		The list of retrieved expenses.
 * @param {number} totalCount	The total amount of expenses available in the database for the current user.
 *
 * @returns {object}
 */
const fetchExpenseListSuccess = ({ expenses, totalCount }) => ({
	type: ActionTypes.FETCH_EXPENSE_LIST_SUCCESS,
	payload: { expenses, totalCount },
});

/**
 * @function
 * @name fetchExpenseListFailure
 * @description Action triggered as a result to a failed expense list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchExpenseListFailure = (error) => ({
	type: ActionTypes.FETCH_EXPENSE_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Fetching all for expenses actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAllForExpenseRequest
 * @description Action triggered anytime an expense fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchAllForExpenseRequest = () => ({ type: ActionTypes.FETCH_ALL_FOR_EXPENSE_REQUEST });

/**
 * @function
 * @name fetchAllForExpenseSuccess
 * @description Action triggered as a result to a successful expense fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} allForForm The retrieved expense.
 *
 * @returns {object}
 */
const fetchAllForExpenseSuccess = ({ allForForm }) => ({
	type: ActionTypes.FETCH_ALL_FOR_EXPENSE_SUCCESS,
	payload: { allForForm },
});

/**
 * @function
 * @name fetchAllForExpenseFailure
 * @description Action triggered as a result to a failed expense fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAllForExpenseFailure = (error) => ({
	type: ActionTypes.FETCH_ALL_FOR_EXPENSE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific expense fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchExpenseRequest
 * @description Action triggered anytime an expense fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchExpenseRequest = () => ({ type: ActionTypes.FETCH_EXPENSE_REQUEST });

/**
 * @function
 * @name fetchExpenseSuccess
 * @description Action triggered as a result to a successful expense fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} expense The retrieved expense.
 *
 * @returns {object}
 */
const fetchExpenseSuccess = ({ expense }) => ({
	type: ActionTypes.FETCH_EXPENSE_SUCCESS,
	payload: { expense },
});

/**
 * @function
 * @name fetchExpenseFailure
 * @description Action triggered as a result to a failed expense fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchExpenseFailure = (error) => ({
	type: ActionTypes.FETCH_EXPENSE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Expense creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addExpenseRequest
 * @description Action triggered anytime an expense creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addExpenseRequest = () => ({ type: ActionTypes.ADD_EXPENSE_REQUEST });

/**
 * @function
 * @name addExpenseSuccess
 * @description Action triggered as a result to a successful expense creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} expense The created expense object.
 *
 * @returns {object}
 */
const addExpenseSuccess = ({ expense }) => ({
	type: ActionTypes.ADD_EXPENSE_SUCCESS,
	payload: { expense },
});

/**
 * @function
 * @name addExpenseFailure
 * @description Action triggered as a result to a failed expense creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const addExpenseFailure = (error) => ({
	type: ActionTypes.ADD_EXPENSE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Expense update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateExpenseRequest
 * @description Action triggered anytime an expense update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateExpenseRequest = () => ({ type: ActionTypes.UPDATE_EXPENSE_REQUEST });

/**
 * @function
 * @name updateExpenseSuccess
 * @description Action triggered as a result to a successful expense update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} expense The updated expense object.
 *
 * @returns {object}
 */
const updateExpenseSuccess = ({ expense }) => ({
	type: ActionTypes.UPDATE_EXPENSE_SUCCESS,
	payload: { expense },
});

/**
 * @function
 * @name updateExpenseFailure
 * @description Action triggered as a result to a failed expense update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const updateExpenseFailure = (error) => ({
	type: ActionTypes.UPDATE_EXPENSE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Expense removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeExpenseRequest
 * @description Action triggered anytime an expense deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeExpenseRequest = () => ({ type: ActionTypes.REMOVE_EXPENSE_REQUEST });

/**
 * @function
 * @name removeExpenseSuccess
 * @description Action triggered as a result to a successful expense deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} deletedExpenseId The removed expense id.
 *
 * @returns {object}
 */
const removeExpenseSuccess = ({ deletedExpenseId }) => ({
	type: ActionTypes.REMOVE_EXPENSE_SUCCESS,
	payload: { deletedExpenseId },
});

/**
 * @function
 * @name removeExpenseFailure
 * @description Action triggered as a result to a failed expense deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const removeExpenseFailure = (error) => ({
	type: ActionTypes.REMOVE_EXPENSE_FAILURE,
	payload: { error },
});

// ///////////////////////////////////////////////////////// //
// ///////////////// Expense edition actions /////////////// //
// ///////////////////////////////////////////////////////// //

/**
 * @function
 * @name editExpenseRequest
 * @description Action triggered anytime an expense edition call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const editExpenseRequest = () => ({ type: ActionTypes.EDIT_EXPENSE_REQUEST });

/**
 * @function
 * @name editExpenseSuccess
 * @description Action triggered as a result to a successful expense edition API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} enhancedExpense The edited expense object.
 *
 * @returns {object}
 */
const editExpenseSuccess = ({ enhancedExpense }) => ({
	type: ActionTypes.EDIT_EXPENSE_SUCCESS,
	payload: { enhancedExpense },
});

/**
 * @function
 * @name editExpenseFailure
 * @description Action triggered as a result to a failed expense edition API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const editExpenseFailure = (error) => ({
	type: ActionTypes.EDIT_EXPENSE_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Expense exporting actions /////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name exportExpenseRequest
 * @description Action triggered anytime a expense exporting call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const exportExpenseRequest = () => ({ type: ActionTypes.EXPORT_EXPENSE_REQUEST });

/**
 * @function
 * @name exportExpenseSuccess
 * @description Action triggered as a result to a successful expense exporting API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const exportExpenseSuccess = () => ({ type: ActionTypes.EXPORT_EXPENSE_SUCCESS });

/**
 * @function
 * @name exportExpenseFailure
 * @description Action triggered as a result to a failed expense exporting API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const exportExpenseFailure = (error) => ({
	type: ActionTypes.EXPORT_EXPENSE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchExpenseList
 * @description Method used to update the expense list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchExpenseList = (params) => (dispatch) => {
	dispatch(fetchExpenseListRequest());

	return ExpensesApi.fetchExpenses(params)
		.then(({ expenses, totalCount }) => dispatch(fetchExpenseListSuccess({ expenses, totalCount })))
		.catch((error) => dispatch(fetchExpenseListFailure(error)));
};

/**
 * @function
 * @name fetchAllForExpense
 * @description Method used to load the expense "all for" information.
 *
 * @author Romaric Barthe
 *
 * @param {string|null} id	The id of the expense.
 */
export const fetchAllForExpense = (id) => (dispatch) => {
	dispatch(fetchAllForExpenseRequest());

	return ExpensesApi.fetchAllForExpenseForm({ expenseId: id })
		.then((allForForm) => dispatch(fetchAllForExpenseSuccess({ allForForm })))
		.catch((error) => dispatch(fetchAllForExpenseFailure(error)));
};

/**
 * @function
 * @name fetchExpense
 * @description Method used to fetch the latest version of a specific expense.
 *
 * @author Romaric Barthe
 *
 * @param {string} expenseId	The id of the expense we want to retrieve.
 */
export const fetchExpense = (expenseId) => (dispatch) => {
	dispatch(fetchExpenseRequest());

	return ExpensesApi.fetchExpenseById(expenseId)
		.then(({ expense }) => dispatch(fetchExpenseSuccess({ expense })))
		.catch((error) => dispatch(fetchExpenseFailure(error)));
};

/**
 * @function
 * @name addExpense
 * @description Method used to add a new expense instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} expenseData		The data to create the new expense from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addExpense = (expenseData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addExpenseRequest());

	return ExpensesApi.createExpense(expenseData)
		.then(({ expense }) => {
			toast.success(i18next.t('expense.creation.toasts.success', { username: expense.user.username }));
			dispatch(addExpenseSuccess({ expense }));
			redirectOnSuccess(onSuccessRoute);

			return expense;
		})
		.catch((error) => {
			toast.error(i18next.t('expense.creation.toasts.error'));
			dispatch(addExpenseFailure(error));
		});
};

/**
 * @function
 * @name updateExpense
 * @description Method used to update an existing expense instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} expenseData		The object to update the expense with.
 * @param {string} expenseId		The id of the expense we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateExpense = (expenseData, expenseId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateExpenseRequest());

	return ExpensesApi.updateExpense(expenseData, expenseId)
		.then(({ expense }) => {
			dispatch(updateExpenseSuccess({ expense }));
			toast.success(i18next.t('expense.edition.toasts.success', { username: expense.user.username }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch((error) => {
			toast.error(i18next.t('expense.edition.toasts.error'));
			dispatch(updateExpenseFailure(error));
		});
};

/**
 * @function
 * @name removeExpense
 * @description Method used to remove an existing expense instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} expense						The expense we want to remove from the database.
 * @param {string} expense.id					The id of the expense we want to remove from the database.
 * @param {number} expense.user					The user of the expense we want to remove (used in success toast).
 * @param {number} expense.user.username		The username of the expense we want to remove (used in success toast).
 */
export const removeExpense = ({ id, user }) => (dispatch) => {
	dispatch(removeExpenseRequest());

	return ExpensesApi.deleteExpense(id)
		.then(({ deletedExpenseId }) => {
			dispatch(removeExpenseSuccess({ deletedExpenseId }));
			toast.success(i18next.t('expense.deletion.toasts.success', { username: user.username }));
		})
		.catch((error) => {
			dispatch(removeExpenseFailure(error));
			toast.error(i18next.t('expense.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name editExpense
 * @description Method used to edit an expense.
 *
 * @author Romaric Barthe
 *
 * @param {string} expenseId		The id of the expense we want to edit.
 * @param {string} editionDate		The edition date of the expense.
 */
export const editExpense = (expenseId, editionDate) => (dispatch) => {
	dispatch(editExpenseRequest());

	return ExpensesApi.editExpense(expenseId, editionDate)
		.then(({ expense, number, date }) => {
			const enhancedExpense = { ...expense, editionNumber: number, generatedPdf: { pdfGenerationDate: date } };
			dispatch(editExpenseSuccess({ enhancedExpense }));
		})
		.catch((error) => {
			toast.error(i18next.t('expense.edition.toasts.error'));
			dispatch(editExpenseFailure(error));
		});
};

/**
 * @function
 * @name exportExpenses
 * @description Method used to export multiple expense instances from the database.
 *
 * @author Romaric Barthe
 *
 * @param {Object}	data				The data to export the expenses.
 * @param {Array}	data.partnerIds		The ids of the expenses to export.
 * @param {string}	data.format			The file format.
 * @param {string}	data.restriction	The scope of the data to export (all, filtered or current selection).
 * @param {string}	data.separator		The separator used in the CSV file.
 *
 * @todo Adapt params when backend will change
 */
export const exportExpenses = (data) => (dispatch) => {
	dispatch(exportExpenseRequest());

	return ExpensesApi.exportExpenses({
		ids: data.partnerIds ?? [],
		format: data.format ?? 'xlsx',
		restriction: data.restriction ?? 'all',
		separator: data.separator ?? 'comma',
		...data,
	})
		.then(({ blob, fileName, fileType }) => {
			dispatch(exportExpenseSuccess());
			toast.success(i18next.t('template.export.toasts.success'));

			download(blob, fileName, fileType);
		})
		.catch((error) => {
			dispatch(exportExpenseFailure(error));
			toast.error(i18next.t('template.export.toasts.error'));
		});
};
