import { AlignLeft, ArrowDownCircle, Calendar, FileText, Hash, Image as ImageIcon, Table as TableIcon, Type } from 'react-feather';
import i18next from 'lib/shared/i18n';

import { DynamicDate, DynamicText, Image, LoremIpsum, PageBreak, PageNumber, SetText, StaticDate, StaticText, Title } from '../components/elements';
import Table from '../components/elements/Table';
import createElementType from '../functions/internals/createElementType';
import { NewLine } from '../utils/icons';

import CapabilitiesNames from './CapabilitiesNames';
import { DefaultTableData, DefaultTableFields } from './DefaultTableContent';

/**
 * @constant
 * @name EMPTY_ROW
 * @description The empty row element type
 *
 * @author Yann Hodiesne
 */
export const EMPTY_ROW = createElementType(
	'EMPTY_ROW',
	undefined,
	'template.pdf.editor.toolbox.empty_row.name',
	'template.pdf.editor.toolbox.empty_row.description',
	NewLine,
	[],
	[],
	undefined,
	{ x: 0, y: 14 },
	false,
	false,
);

/**
 * @constant
 * @name STATIC_TEXT
 * @description The static editable text type
 *
 * @author Yann Hodiesne
 */
export const STATIC_TEXT = createElementType(
	'STATIC_TEXT',
	StaticText,
	'template.pdf.editor.toolbox.static_text.name',
	'template.pdf.editor.toolbox.static_text.description',
	FileText,
	[CapabilitiesNames.EDITABLE, CapabilitiesNames.FORMAT],
	[CapabilitiesNames.LINKABLE],
	'Put some text here',
	{ x: 225, y: 14 },
);

/**
 * @constant
 * @name DYNAMIC_TEXT
 * @description The dynamic editable text type
 *
 * @author Yann Hodiesne
 */
const DYNAMIC_TEXT = createElementType(
	'DYNAMIC_TEXT',
	DynamicText,
	'template.pdf.editor.toolbox.dynamic_text.name',
	'template.pdf.editor.toolbox.dynamic_text.description',
	FileText,
	[CapabilitiesNames.EDITABLE, CapabilitiesNames.FORMAT, CapabilitiesNames.SHOW_LABEL],
	[CapabilitiesNames.LINKABLE],
	'Fill me!',
	{ x: 225, y: 14 },
);

/**
 * @constant
 * @name TITLE
 * @description The title element type
 *
 * @author Matthieu Schaerlinger
 */
export const TITLE = createElementType(
	'TITLE',
	Title,
	'template.pdf.editor.toolbox.title.name',
	'template.pdf.editor.toolbox.title.description',
	Type,
	[CapabilitiesNames.EDITABLE, CapabilitiesNames.FORMAT, CapabilitiesNames.TITLE_LEVEL, CapabilitiesNames.SHOW_TITLE_NUMBER],
	[],
	'Fill me!',
	{ x: 595, y: 21 },
);

/**
 * @constant
 * @name LOREM_IPSUM
 * @description A classic Lorem Ipsum
 *
 * @author Yann Hodiesne
 */
const LOREM_IPSUM = createElementType(
	'LOREM_IPSUM',
	LoremIpsum,
	'template.pdf.editor.toolbox.lorem_ipsum.name',
	'template.pdf.editor.toolbox.lorem_ipsum.description',
	FileText,
	[CapabilitiesNames.TEXT_ALIGN],
	[],
	undefined,
	{ x: 595, y: 110 },
	false,
);

/**
 * @constant
 * @name TABLE
 * @description The table element type
 *
 * @author Yann Hodiesne
 */
const TABLE = createElementType(
	'TABLE',
	Table,
	'template.pdf.editor.toolbox.table.name',
	'template.pdf.editor.toolbox.table.description',
	TableIcon,
	[CapabilitiesNames.FONT_SIZE],
	[CapabilitiesNames.TABLE_LINKED_ENTITY],
	{ fields: Object.keys(DefaultTableFields), ...DefaultTableData },
	{ x: 595, y: 110 },
	false,
);

/**
 * @constant
 * @name IMAGE
 * @description A static image
 *
 * @author Yann Hodiesne
 */
export const IMAGE = createElementType(
	'IMAGE',
	Image,
	'template.pdf.editor.toolbox.image.name',
	'template.pdf.editor.toolbox.image.description',
	ImageIcon,
	[CapabilitiesNames.IMAGE_SELECTION, CapabilitiesNames.IMAGE_SCALING],
	[],
	undefined,
	{ x: 200, y: 200 },
);

/**
 * @constant
 * @name STATIC_DATE
 * @description The static date type
 *
 * @author Matthieu Schaerlinger
 */
export const STATIC_DATE = createElementType(
	'STATIC_DATE',
	StaticDate,
	'template.pdf.editor.toolbox.static_date.name',
	'template.pdf.editor.toolbox.static_date.description',
	Calendar,
	[CapabilitiesNames.DATE_FORMAT_ADVANCED_MODE, CapabilitiesNames.DATE_MODE, CapabilitiesNames.FORMAT],
	[CapabilitiesNames.LINKABLE],
	new Date().toISOString(),
	{ x: 225, y: 14 },
);

/**
 * @constant
 * @name DYNAMIC_DATE
 * @description The dynamic date type
 *
 * @author Matthieu Schaerlinger
 */
export const DYNAMIC_DATE = createElementType(
	'DYNAMIC_DATE',
	DynamicDate,
	'template.pdf.editor.toolbox.dynamic_date.name',
	'template.pdf.editor.toolbox.dynamic_date.description',
	Calendar,
	[CapabilitiesNames.DATE_FORMAT_ADVANCED_MODE, CapabilitiesNames.DATE_VALUE, CapabilitiesNames.FORMAT],
	[],
	new Date().toISOString(),
	{ x: 225, y: 14 },
);

/**
 * @constant
 * @name PAGE_BREAK
 * @description The page break element type
 *
 * @author Florian Fornazaric
 */
export const PAGE_BREAK = createElementType(
	'PAGE_BREAK',
	PageBreak,
	'template.pdf.editor.toolbox.page_break.name',
	'template.pdf.editor.toolbox.page_break.description',
	ArrowDownCircle,
	[],
	[],
	undefined,
	{ x: 0, y: 0 }, // This is set to 0 so it wont be rendered in the final document
	false,
);

/**
 * @constant
 * @name SET_TEXT
 * @description The static non editable text type
 *
 * @author Florian Fornazaric
 */
export const SET_TEXT = createElementType(
	'SET_TEXT',
	SetText,
	'template.pdf.editor.toolbox.set_text.name',
	'template.pdf.editor.toolbox.set_text.description',
	AlignLeft,
	[CapabilitiesNames.TEXT_OBJECT_LINK, CapabilitiesNames.FORMAT],
	[],
	i18next.t('template.pdf.editor.default.link_message'),
	{ x: 225, y: 14 },
	false,
);

/**
 * @constant
 * @name PAGE_NUMBER
 * @description The page number element type
 *
 * @author Florian Fornazaric
 */
export const PAGE_NUMBER = createElementType(
	'PAGE_NUMBER',
	PageNumber,
	'template.pdf.editor.toolbox.page_number.name',
	'template.pdf.editor.toolbox.page_number.description',
	Hash,
	[CapabilitiesNames.REMAINING_PAGE_NUMBERING, CapabilitiesNames.FORMAT],
	[],
	'1',
	{ x: 225, y: 14 },
);

// We still have Dynamic because it is used in Capabilities to determine if the element is dynamic
const DYNAMIC = [DYNAMIC_TEXT, DYNAMIC_DATE];

// Legacy is here so we don't break existing templates using the removed component, the user cannot add those to his template
const LEGACY = [LOREM_IPSUM];
const TEXT = [TITLE, STATIC_TEXT, DYNAMIC_TEXT, SET_TEXT];
const DATE = [STATIC_DATE, DYNAMIC_DATE];
const OTHER = [IMAGE, TABLE];
const LAYOUT = [EMPTY_ROW, PAGE_BREAK, PAGE_NUMBER];

/**
 * @constant
 * @name ElementTypes
 * @description An object containing all the element types handled by the editor along with their display name and default values
 *
 * Note: The provided ID is used to identify the element type, and the display component is used to render the element
 * If a user already created a template containing an existing ID, it should NOT be modified or the existing template will not recognize the new element type
 * In short, NEVER EDIT THE ID OF AN ALREADY EXISTING COMPONENT TYPE, EVEN IF THE COMPONENT HAS BEEN RENAMED
 *
 * @author Yann Hodiesne
 */
const ElementTypes = Object.freeze([
	...LEGACY,
	...TEXT,
	...DATE,
	...OTHER,
	...LAYOUT,
]);

export default ElementTypes;

export {
	DATE,
	DYNAMIC,
	LAYOUT,
	OTHER,
	TEXT,
};
