import { memo, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fetchApplicationList } from 'redux/actions/applications';
import { fetchCompany, updateCompany } from 'redux/actions/companies';
import { useApplicationListSelector } from 'redux/selectors/applications';

import ApplicationManagementForm from 'components/superadmin/applicationManagement/ApplicationManagementForm';

/**
 * @name CompanyApplicationManagementTab
 * @description A tab component used to update a company's enabled applications list.
 *
 * @author Timothée Simon-Franza
 * @author Audrey Clerc
 *
 * @param {string}	companyId					The id of the company we're editing applications of.
 * @param {array}	companyApplications			A list of all enabled applications for the current company.
 */
const CompanyApplicationManagementTab = ({ companyId, companyApplications }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const applications = useApplicationListSelector();

	useEffect(() => {
		dispatch(fetchApplicationList());
	}, [dispatch]);

	/**
	 * @name enrichedApplicationList
	 * @description Memoized list of all applications enriched with an 'enabled' field for company-enabled apps.
	 *
	 * @author Timothée Simon-Franza
	 */
	const enrichedApplicationList = useMemo(() => {
		const companyApplicationsIds = companyApplications.map(({ id }) => id);
		const { enabled, disabled } = _.groupBy(applications, (app) => (companyApplicationsIds.includes(app.id) ? 'enabled' : 'disabled'));
		const result = [...(enabled ?? []).map((app) => ({ ...app, enabled: true })), ...(disabled ?? [])];

		return _.sortBy(result, 'name');
	}, [applications, companyApplications]);

	/**
	 * @function
	 * @name handleApplicationToggle
	 * @description Callback method triggered anytime an application is toggled for the current company.
	 *
	 * @author Timothée Simon-Franza
	 */
	const handleApplicationToggle = useCallback(async (toggledApplicationId) => {
		const toggledApplication = _.find(_.map(applications, ({ id }) => ({ id })), ({ id }) => id === toggledApplicationId);

		const result = {
			applications: _.some(companyApplications, ({ id }) => id === toggledApplicationId)
				? [..._.reject(companyApplications, ({ id }) => id === toggledApplicationId)]
				: [...companyApplications.map(({ id }) => id), toggledApplication.id],
		};

		await dispatch(updateCompany(result, companyId));
		dispatch(fetchCompany(companyId));
	}, [applications, companyApplications, companyId, dispatch]);

	return (
		enrichedApplicationList
			? (
				<ApplicationManagementForm
					applications={enrichedApplicationList}
					onApplicationToggle={handleApplicationToggle}
				/>
			)
			: <p>{t('super_admin.company.applications.loading')}</p>
	);
};

CompanyApplicationManagementTab.propTypes = {
	companyId: PropTypes.string.isRequired,
	companyApplications: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
	})).isRequired,
};

export default memo(CompanyApplicationManagementTab);
