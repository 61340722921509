import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAccessDefinition, updateAccessDefinition } from 'redux/actions/accessDefinitions';
import { useCurrentAccessDefinitionSelector } from 'redux/selectors/accessDefinitions';
import routes from 'routes';

import { AccessDefinitionEditionForm } from 'components/accessDefinitions';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name AccessDefinitionEditionPage
 * @description A page used to edit an existing access definition.
 *
 * @author Matthieu Schaerlinger
 */
const AccessDefinitionEditionPage = () => {
	const { id: accessDefinitionId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const accessDefinition = useCurrentAccessDefinitionSelector(accessDefinitionId);

	const canEditAccessDefinition = useAccessRight(AccessRights.humanResources.roles.enhancedRights.CREATE_ACCESS_DEFINITION);

	useEffect(() => {
		dispatch(fetchAccessDefinition(accessDefinitionId));
	}, [accessDefinitionId, dispatch]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Matthieu Schaerlinger
	 *
	 * @param {object} formData		The updated values from AccessDefinitionEditionForm.
	 *
	 * @returns {Promise}
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditAccessDefinition) {
			dispatch(updateAccessDefinition(formData, accessDefinitionId, routes.humanResources.accessDefinitions.list));
		} else {
			redirectOnSuccess(routes.humanResources.accessDefinitions.list);
		}
	},
	[accessDefinitionId, canEditAccessDefinition, dispatch],);

	return (
		accessDefinition
			? <AccessDefinitionEditionForm accessDefinition={accessDefinition} onSubmit={handleFormSubmit} />
			: <p>{t('access_definition.edition.loading')}</p>
	);
};

export default AccessDefinitionEditionPage;
