import { memo } from 'react';
import { ChevronLeft, Menu } from 'react-feather';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import routes from 'routes';

import { Button } from 'components/shared/buttons';

import Breadcrumbs from './Breadcrumbs';
import UserHeaderSection from './UserHeaderSection';

/**
 * @name Header
 * @description The application's main Header.
 *
 * @author Timothée Simon-Franza
 */
const Header = ({ closeOrOpenMenu, isOpen }) => {
	const user = useCurrentConnectedUserSelector();

	return (
		<header>
			<div className="header">
				<Button type="button" onClick={closeOrOpenMenu}>
					{isOpen ? <ChevronLeft /> : <Menu />}
				</Button>
				<Link className="logo" to={{ pathname: routes.app.default, state: { fromHeader: true } }}>
					{user?.company?.name}
				</Link>
			</div>
			<Breadcrumbs />
			<UserHeaderSection />
		</header>
	);
};

Header.propTypes = {
	closeOrOpenMenu: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};
export default memo(Header);
