import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ProjectTabs } from 'constants/projectEnums';
import { formatContactName } from 'lib/contacts/formatContactData';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { addContact } from 'redux/actions/contacts/contacts';
import { addPartner } from 'redux/actions/partners/partners';
import { fetchAllForProject } from 'redux/actions/projects/projects';
import { addUser } from 'redux/actions/users';
import { useAllForProjectSelector, useProjectsLoadingSelector } from 'redux/selectors/projects/projects';

import ContactCreationForm from 'components/contacts/ContactCreationForm';
import PartnerCreationForm from 'components/partners/PartnerCreationForm';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Tab, TabList, TabPanel } from 'components/shared/layout/tabs';
import UserCreationForm from 'components/users/UserCreationForm';

import { Address, ProjectIdentifier, ProjectLinkedEntityForm, ProjectRights, ProjectTempo } from './projectTabs';

/**
 * @name ProjectCreationForm
 * @description A form used to create a new project.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const ProjectCreationForm = ({ onSubmit }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForInvoiceForm = useAllForProjectSelector();
	const contacts = useMemo(() => allForInvoiceForm?.contacts ?? [], [allForInvoiceForm]);
	const users = useMemo(() => allForInvoiceForm?.enabledUsers ?? [], [allForInvoiceForm]);
	const partners = useMemo(() => allForInvoiceForm?.partners ?? [], [allForInvoiceForm]);

	const isLoading = useProjectsLoadingSelector();

	const canViewInvoice = useAccessRight(AccessRights.accounting.invoices.VIEW);

	const { formProps, buttonProps } = useSubmitButton();

	const userSelectOptions = useMemo(
		() => users?.map(({ id, contact: { firstName, lastName } }) => ({ label: formatContactName({ firstName, lastName }), value: id })) ?? [],
		[users]
	);

	// To display additional information in the tabs
	const tabsToDisplay = useMemo(() => {
		const tabs = [];
		Object.entries(ProjectTabs).forEach(([, value]) => {
			tabs.push(value);
		});

		return tabs;
	}, []);

	const contactProps = useFormModal(ContactCreationForm, t('projects.creation.inputs.contact.creation'), fetchAllForProject, addContact);
	const partnerProps = useFormModal(PartnerCreationForm, t('projects.creation.inputs.partner.creation'), fetchAllForProject, addPartner);
	const userProps = useFormModal(UserCreationForm, t('projects.creation.inputs.collaborators.creation'), fetchAllForProject, addUser);

	const [selectedProjectTabId, setSelectedProjectTabId] = useState('identity-tab');

	const onTabSelectionChanged = useCallback((tabId) => {
		setSelectedProjectTabId(tabId);
	}, []);

	useEffect(() => {
		dispatch(fetchAllForProject());
	}, [dispatch]);

	const defaultValues = useMemo(() => ({
		sameContactPartnerToInvoice: true,
	}), []);

	return (
		<DynamicForm
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			{...formProps}
		>
			<TabList
				defaultSelectedTabId={selectedProjectTabId}
				onTabSelectionChanged={onTabSelectionChanged}
			>
				{tabsToDisplay.map((tabId) => (
					<Tab key={tabId} tabId={`${tabId}-tab`} tabPanelId={`${tabId}-tabpanel`} dontDisplayCloseButton>
						{t(`projects.fields.${tabId}`)}
					</Tab>
				))}
			</TabList>

			<TabPanel
				tabId="identity-tab"
				tabPanelId="identity-tabpanel"
				hidden={selectedProjectTabId !== 'identity-tab'}
			>
				<ProjectIdentifier />
			</TabPanel>

			<TabPanel
				tabId="client-tab"
				tabPanelId="client-tabpanel"
				hidden={selectedProjectTabId !== 'client-tab'}
			>
				<ProjectLinkedEntityForm
					isLoading={isLoading}
					canViewInvoice={canViewInvoice}
					contactProps={contactProps}
					contacts={contacts}
					defaultValues={defaultValues}
					partnerProps={partnerProps}
					partners={partners}
				/>
			</TabPanel>

			<TabPanel
				tabId="rights-tab"
				tabPanelId="rights-tabpanel"
				hidden={selectedProjectTabId !== 'rights-tab'}
			>
				<ProjectRights
					isLoading={isLoading}
					userProps={userProps}
					userSelectOptions={userSelectOptions}
				/>
			</TabPanel>

			<TabPanel
				tabId="address-tab"
				tabPanelId="address-tabpanel"
				hidden={selectedProjectTabId !== 'address-tab'}
			>
				<Address />
			</TabPanel>

			<TabPanel
				tabId="tempo-tab"
				tabPanelId="tempo-tabpanel"
				hidden={selectedProjectTabId !== 'tempo-tab'}
			>
				<ProjectTempo />
			</TabPanel>

			<Button className="primary" type="submit" {...buttonProps}>{t('projects.creation.action')}</Button>
		</DynamicForm>
	);
};

ProjectCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default ProjectCreationForm;
