import { isDate, maxDate, minDate, validateDate } from './dateInput';
import isRequired from './isRequired';
import matchPattern from './matchPattern';
import { maxValue, minValue } from './numberBounds';
import { hasMaxLength, hasMinLength } from './textInput';

export default {
	isDate,
	hasMaxLength,
	hasMinLength,
	isRequired,
	validateDate,
	minDate,
	maxDate,
	maxValue,
	minValue,
	matchPattern,
};
