/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { crimson, tuna } from './colors';

const commonInputStyle = {
	display: 'block',
};

const reactSelectStyle = {
	control: (provided, { isFocused, selectProps: { isInvalid } }) => ({
		...provided,
		boxShadow: 'none',
		boxSizing: 'border-box',
		border: `solid 1px ${isInvalid ? crimson.default : isFocused ? '#4767d6' : tuna.default}`,
		outline: `${isFocused ? 'solid 1px #4767d6' : ''}`,
		outlineOffset: '2px',
		borderRadius: 6,
		height: '100%',
		'&:hover': {
			boxShadow: 'none',
		},
	}),
};

export {
	commonInputStyle,
	reactSelectStyle,
};
