import { useMemo } from 'react';
import PropTypes from 'prop-types';

/**
 * @name NameCell
 * @description Custom actions cell for the InvoiceListPage table. (delete, edit and view exports for invoice)
 *
 * @author Romaric Barthe
 *
 * @param {object}		row								The object containing the data to interact with.
 * @param {boolean}		row.original.color				The current status of the object.
 */
const NameCell = ({ row: { original: { color, status } } }) => {
	const statusColor = color || status?.defaultColor;

	const style = useMemo(() => ({
		backgroundColor: statusColor,
	}), [statusColor]);

	return (status ? (
		<div className="stat-pill">
			<div className="container">
				<span className="color-dot" style={style} />
				<span className="stat-label">{status?.name}</span>
			</div>
		</div>
	) : '');
};

NameCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			color: PropTypes.string,
			status: PropTypes.shape({
				name: PropTypes.string,
				defaultColor: PropTypes.string,
			}),
		}).isRequired,
	}).isRequired,
};

export default NameCell;
