import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addTargeting } from 'redux/actions/targetings/targetings';
import routes from 'routes';

import { TargetingCreationForm } from 'components/targetings';

/**
 * @name TargetingCreationPage
 * @description A page used to create a new targeting.
 *
 * @author Romaric Barthe
 *
 */
const TargetingCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addTargeting(formData, routes.operations.targetings.targets.list));
	}, [dispatch]);

	return (
		<TargetingCreationForm onSubmit={handleFormSubmit} />
	);
};

export default TargetingCreationPage;
