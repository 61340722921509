import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { addPartner } from 'redux/actions/partners/partners';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { PartnerCreationForm } from 'components/partners';

/**
 * @name PartnerCreationPage
 * @description A page used to create a new partner.
 *
 * @author Timothée Simon-Franza
 * @author Audrey Clerc
 *
 */
const PartnerCreationPage = () => {
	const dispatch = useDispatch();

	const handleFormSubmit = useCallback(async (formData) => {
		const newPartner = await dispatch(addPartner(formData));

		if (newPartner) {
			history.push(generatePath(routes.commonModules.partners.partnerEdition, { id: newPartner.id }));
		}
	}, [dispatch]);

	return (
		<PartnerCreationForm onSubmit={handleFormSubmit} />
	);
};

export default PartnerCreationPage;
