import { Redirect, Route, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useCanAccessRoute } from 'lib/hooks';
import session from 'lib/shared/session';
import PropTypes from 'prop-types';

import { history } from './RouterProvider';

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * @name RouteWithSubRoutes
 * @description Renders a route with a potential sub-route.
 *
 * @author Yann Hodiesne
 *
 * @param {object}	currentUser				 If a user is authenticated, this variable contains its information. Undefined otherwise.
 * @param {string}	redirectAnonymous		 path to target if the user should be authenticated
 * @param {string}	redirectAuthenticated	 path to target if the user should be anonymous
 * @param {*}		fallback				 component to render as a fallback
 * @param {string}	accessRight				 access right to check against the current user rights
 * @param {object}	route 					 route to render
 */
const RouteWithSubRoutes = ({ currentUser, redirectAnonymous, redirectAuthenticated, fallback, ...route }) => {
	const isLoggedIn = session.exists();

	// Here we check if the user should be redirected to another route
	const canAccessRoute = useCanAccessRoute(route);

	// If a session exists but the user has not yet been fetched from the server, we do not want to render routes
	if (isLoggedIn && !currentUser) {
		// TODO replace with a loading page
		return null;
	}

	const redirectTarget = isLoggedIn ? redirectAuthenticated : redirectAnonymous;

	return (
		<>
			{canAccessRoute && (
				<SentryRoute
					path={route.path}
					exact={route.exact}
					render={(props) => (route.routes ? (
						<RenderRoutes
							currentUser={currentUser}
							fallback={fallback}
							redirectAnonymous={redirectAnonymous}
							redirectAuthenticated={redirectAuthenticated}
							routes={route.routes}
						/>
					) : <route.component {...props} />)}
				/>
			)}
			{canAccessRoute || (
				<SentryRoute
					path={route.path}
					render={() => (
						<Redirect
							to={{
								pathname: redirectTarget,
								state: { requestedLocation: history.location },
							}}
						/>
					)}
				/>
			)}
		</>
	);
};

RouteWithSubRoutes.propTypes = {
	currentUser: PropTypes.shape({
		id: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
		superadmin: PropTypes.bool.isRequired,
	}),
	redirectAnonymous: PropTypes.string.isRequired,
	redirectAuthenticated: PropTypes.string.isRequired,
	fallback: PropTypes.elementType.isRequired,
	accessRight: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
};

RouteWithSubRoutes.defaultProps = {
	currentUser: undefined,
	routes: undefined,
	accessRight: undefined,
};

/**
 * @name RenderRoutes
 * @description Renders a set of routes with potential sub-routes.
 *
 * @author Yann Hodiesne
 *
 * @param {object}	currentUser				 If a user is authenticated, this variable contains its information. Undefined otherwise.
 * @param {string}	redirectAnonymous		 path to target if the user should be authenticated
 * @param {string}	redirectAuthenticated	 path to target if the user should be anonymous
 * @param {*}		fallback				 component to render as a fallback
 * @param {array}	route					 routes to render
 */
const RenderRoutes = ({ currentUser, redirectAnonymous, redirectAuthenticated, fallback, routes }) => (
	<Switch>
		{routes.map((route) => (
			<RouteWithSubRoutes
				currentUser={currentUser}
				key={route.path}
				fallback={fallback}
				redirectAnonymous={redirectAnonymous}
				redirectAuthenticated={redirectAuthenticated}
				{...route}
			/>
		))}
		<SentryRoute component={fallback} />
	</Switch>
);

RenderRoutes.propTypes = {
	currentUser: PropTypes.shape({
		id: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
		superadmin: PropTypes.bool.isRequired,
	}),
	redirectAnonymous: PropTypes.string.isRequired,
	redirectAuthenticated: PropTypes.string.isRequired,
	fallback: PropTypes.elementType.isRequired,
	routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

RenderRoutes.defaultProps = {
	currentUser: undefined,
};

export default RenderRoutes;
