import { X } from 'react-feather';
import PropTypes from 'prop-types';

import styles from 'styles/layout/_tabLayout.module.scss';

/**
 * @name Tab
 * @description A tab component to be used in conjunction with the TabPanel component. Must be wrapped inside a TabList component.
 *
 * @author Timothée Simon-Franza
 * @author Audrey Clerc
 *
 * @param {string}	children			The text to display in the tab button.
 * @param {func}	onClick				The function to call when the tab is clicked. Passed down by the parent TabList component.
 * @param {bool}	[selected = false]	Whether the tab is selected (can only be one at a time).
 * @param {string}	tabId				The id of the tab.
 * @param {string}	tabPanelId			The id of the tabPanel controlled by the tab.
 * @param {bool}	[onCLose]			The function to call when the tab is clicked.
 * @param {bool}	[dontDisplayCloseButton = false]	Whether the close button is to be displayed.
 */
const Tab = ({ children, onClick, selected, tabId, tabPanelId, onClose, dontDisplayCloseButton }) => (
	<div style={{ display: 'flex', borderBottom: selected ? 'solid 2px var(--clr-primary-500)' : '' }}>
		<button
			type="button"
			role="tab"
			className={styles.tab}
			id={tabId}
			aria-controls={tabPanelId}
			aria-selected={selected}
			onClick={onClick}
			style={{ border: 'none' }}
		>
			{children}
		</button>
		{!dontDisplayCloseButton && (
			<button
				type="button"
				role="tab"
				className={styles.tab}
				id={tabId}
				onClick={onClose}
				style={{ padding: '0px' }}
			>
				<X />
			</button>
		)}
	</div>
);

Tab.propTypes = {
	children: PropTypes.string.isRequired,
	onClick: PropTypes.func, // provided by the parent TabList component
	selected: PropTypes.bool,
	tabId: PropTypes.string.isRequired,
	tabPanelId: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	dontDisplayCloseButton: PropTypes.bool,
};

Tab.defaultProps = {
	selected: false,
	onClick: () => {},
	onClose: () => {},
	dontDisplayCloseButton: false,
};

export default Tab;
