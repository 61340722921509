import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useProjectsSelectLoadingSelector
 * @description A selector to retrieve the loading state from the projectsSelect redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useProjectsSelectLoadingSelector = () => {
	const loading = useSelector((state) => state.projectsSelect?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useProjectsSelectListSelector
 * @description A selector to retrieve the projectsSelect list from the project redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useProjectsSelectListSelector = () => {
	const projectsSelect = useSelector((state) => (state.projectsSelect?.items ?? []), shallowEqual);

	return projectsSelect;
};

/**
 * @function
 * @name useProjectsSelectTotalCountSelector
 * @description A selector to retrieve the totalCount value from the projectsSelect redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useProjectsSelectTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.projectsSelect?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useProjectsSelectActiveListSelector
 * @description A selector to retrieve all non-archived projects from the project redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useProjectsSelectActiveListSelector = () => {
	const projects = useProjectsSelectListSelector();

	const activeProjects = useMemo(() => projects.filter((project) => !project.archived), [projects]);

	return activeProjects;
};

export {
	useProjectsSelectActiveListSelector,
	useProjectsSelectListSelector,
	useProjectsSelectLoadingSelector,
	useProjectsSelectTotalCountSelector,
};
