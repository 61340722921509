import { expenseColumnHeader, ExpenseCreationTypes } from 'constants/expenseEnums';

/**
 * @function
 * @name getExpenseColumnsHeaders
 * @description return the array of expense headers for the grid.
 *
 * @author Romaric Barthe
 *
 * @param {object}	optionAccount		Whether the account column should be displayed.
 * @param {object}	optionDate			Whether the date column should be displayed.
 * @param {object}	optionLocalCurrency	Whether the local currency/ amount should be displayed.
 * @param {object}	optionProject		Whetehr the project should be displayed.
 *
 * @returns {array} array of headers for the grid.
 */
export const getExpenseColumnsHeaders = (optionAccount, optionDate, optionLocalCurrency, optionProject) => {
	const columnsHeaders = [];
	Object.entries(expenseColumnHeader).forEach(([, value]) => {
		if (
			(optionAccount && value === expenseColumnHeader.ACCOUNT)
			|| (optionDate && value === expenseColumnHeader.DATE)
			|| (optionLocalCurrency && (value === expenseColumnHeader.CURRENCY || value === expenseColumnHeader.LOCAL_NET_COST))
			|| (optionProject && value === expenseColumnHeader.PROJECT)
			|| (
				value !== expenseColumnHeader.ACCOUNT && value !== expenseColumnHeader.DATE
				&& value !== expenseColumnHeader.CURRENCY && value !== expenseColumnHeader.LOCAL_NET_COST
				&& value !== expenseColumnHeader.PROJECT
			)
		) {
			columnsHeaders.push({ label: value, show: true });
		}
	});

	return columnsHeaders;
};

/**
 * @function
 * @name getExpenseCreationTypes
 * @description return the array of expense creation type options.
 *
 * @author Romaric Barthe
 *
 * @returns {array} array of options for expense type.
 */
export const getExpenseCreationTypes = () => {
	const creationTypes = [];
	Object.entries(ExpenseCreationTypes).forEach(([, value]) => {
		creationTypes.push({ id: value, label: `expense.creation.creation_type.${value}` });
	});

	return creationTypes;
};

/**
 * @function
 * @name getLinesForVisualisation
 * @description Parses the backend-retrieved data into an object to be used by the form.
 *
 * @author Romaric Barthe
 *
 * @param {object}	expense	the expense we are editing.
 *
 * @returns {object} transformed initial values.
 */
export const getLinesForVisualisation = (expense) => {
	const rows = [];
	Object.values(expense.rows).sort((a, b) => a.line - b.line).forEach((row) => {
		const tempRows = {};
		tempRows.rowId = row.id;
		tempRows.isLabel = row.isLabel;
		tempRows[expenseColumnHeader.ACCOUNT] = row.account ? row.account.name : undefined;
		tempRows[expenseColumnHeader.PROJECT] = row.project ? row.project.name : undefined;
		tempRows[expenseColumnHeader.DESIGNATION] = row.designation || '';
		tempRows[expenseColumnHeader.DATE] = row.date || '';
		tempRows[expenseColumnHeader.LOCAL_NET_COST] = row.localNetCost || 0;
		tempRows[expenseColumnHeader.NET_COST] = row.netCost || 0;
		tempRows[expenseColumnHeader.CURRENCY] = row.currency ? row.currency.name : undefined;
		tempRows[expenseColumnHeader.VAT_RATE] = row.vatRate ? row.vatRate.rate ?? 0 : undefined;
		rows.push(tempRows);
	});

	return rows;
};

/**
 * @function
 * @name getVatRateValue
 * @description return the ISO name of the currency.
 *
 * @author Romaric Barthe
 *
 * @param {object}	vatRates		The list of all vat rates.
 * @param {object}	vatRateId		The id of the vat rate.
 *
 * @returns {number} the rate.
 */
export const getVatRateValue = (vatRates, vatRateId) => {
	if (vatRates.length > 0) {
		const vatRatesFiltered = vatRates.filter((v) => v.id === vatRateId);

		return vatRatesFiltered.length > 0 ? vatRatesFiltered[0].rate / 100 : 0;
	}

	return '';
};

/**
 * @function
 * @name getRowTotals
 * @description create subtotal for each rows in an expense as an array.
 *
 * @author Romaric Barthe
 *
 * @param {object}	rows			the expense rows.
 * @param {object}	vatRates		the available vatRates.
 *
 * @returns {array} transformed initial values.
 */
export const getRowTotals = (rows, vatRates) => (
	rows
		? Object.entries(rows).map(([key, row]) => {
			const rowVatRateArray = row.vatRate && vatRates ? vatRates.filter((vat) => vat.id === row.vatRate) : [{ rate: 0 }];
			const rowVatRate = rowVatRateArray && rowVatRateArray.length > 0 ? rowVatRateArray[0].rate / 100 : 0;
			const rowGrossTotal = (row.netCost ?? 0) / (1 + rowVatRate);
			const rowVatTotal = (row.netCost ?? 0) - rowGrossTotal;

			return ({ id: key, gt: rowGrossTotal ?? 0, vt: rowVatTotal ?? 0, nt: row.netCost ?? 0 });
		}) : []);
