import i18next from 'lib/shared/i18n';

import TableEntityTypes from './TableEntityTypes';

/**
 * @constant
 * @name DefaultTableFields
 * @description An object containing all the fields used in the default table
 *
 * @author Florian Fornazaric
 */
export const DefaultTableFields = Object.freeze({
	field1: {
		name: 'template.pdf.default.field1',
		width: 50,
	},
	field2: {
		name: 'template.pdf.default.field2',
		width: 50,
	},
});

/**
 * @constant
 * @name DefaultTableData
 * @description An object containing all the data used in the default table
 *
 * @author Florian Fornazaric
 */
export const DefaultTableData = Object.freeze({
	data: {
		type: TableEntityTypes.DEFAULT,
		rows: [
			{
				field1: 'Doe',
				field2: 'John',
			},
			{
				field1: 'Doe',
				field2: 'Jane',
			},
		],
	},
});

/**
 * @constant
 * @name InvoiceDefaultValues
 * @description An object containing all the default values used in the invoice table
 *
 * @author Florian Fornazaric
 */
export const InvoiceDefaultValues = Object.freeze({
	data: {
		hasTotalLine: true,
		totalStyle: { fontWeight: 500, backgroundColor: '#eeeeee' },
		type: TableEntityTypes.INVOICE_ADVANCEMENT,
		rows: [{
			designation: i18next.t('template.pdf.default.incoice_designation'),
			description: i18next.t('template.pdf.default.invoice_description'),
			base_amount: [10000, i18next.t('currency.EUR')],
			already_invoiced: [40, 10000 * 0.4, i18next.t('currency.EUR')],
			currently_invoiced: [60, 10000 * 0.6, i18next.t('currency.EUR')],
			total: [(10000 * 0.6), i18next.t('currency.EUR')],
		}, {
			description: i18next.t('total'),
			base_amount: [10000, i18next.t('currency.EUR')],
			already_invoiced: [40, 10000 * 0.4, i18next.t('currency.EUR')],
			currently_invoiced: [60, 10000 * 0.6, i18next.t('currency.EUR')],
		}],
	},
});

/**
 * @constant
 * @name InvoiceTotalsDefaultValues
 * @description An object containing all the default values used in the invoice totals table
 *
 * @author Florian Fornazaric
 */
export const InvoiceTotalsDefaultValues = Object.freeze({
	data: {
		type: TableEntityTypes.INVOICE_TOTALS,
		rows: [{
			grossTotal: [10000 * 0.6, i18next.t('currency.EUR')],
			vatTotal: [20, 10000 * 0.6 * 0.2, i18next.t('currency.EUR')],
			netTotal: [10000 * 0.6 * 1.2, i18next.t('currency.EUR')],
		}],
	},
});

/**
 * @constant
 * @name QuotationDefaultValues
 * @description An object containing all the default values used in the quotation table
 *
 * @author Florian Fornazaric
 */
export const QuotationDefaultValues = Object.freeze({
	data: {
		type: TableEntityTypes.QUOTATION_ARCHITECT,
		companies: [
			{ sector: i18next.t('template.pdf.default.quotation.sector1'), name: i18next.t('template.pdf.default.quotation.entreprise1') },
			{ sector: i18next.t('template.pdf.default.quotation.sector2'), name: i18next.t('template.pdf.default.quotation.entreprise2') },
			{ sector: i18next.t('template.pdf.default.quotation.sector3'), name: i18next.t('template.pdf.default.quotation.entreprise3') },
		],
		rows: [{
			phase: i18next.t('template.pdf.default.quotation.phase1'),
			documents: i18next.t('template.pdf.default.quotation.documents1'),
			values: [
				[10000, i18next.t('currency.EUR')],
				[20000, i18next.t('currency.EUR')],
				[30000, i18next.t('currency.EUR')],
			],
			total: [60000, i18next.t('currency.EUR')],
		},
		{
			phase: i18next.t('template.pdf.default.quotation.phase2'),
			documents: i18next.t('template.pdf.default.quotation.documents2'),
			values: [
				[15000, i18next.t('currency.EUR')],
				[0, i18next.t('currency.EUR')],
				[5000, i18next.t('currency.EUR')],
			],
			total: [20000, i18next.t('currency.EUR')],
		}],
	},
});

/**
 * @constant
 * @name QuotationColumnsDefaultValues
 * @description An object containing all the default values used in the quotation columns table
 *
 * @author Romaric Barthe
 */
export const QuotationColumnsDefaultValues = Object.freeze({
	data: {
		type: TableEntityTypes.QUOTATION_COLUMNS,
		rows: [{
			partnerSector: i18next.t('template.pdf.default.quotation.sector1'),
			partnerName: i18next.t('template.pdf.default.quotation.entreprise1'),
			partnerAmount: [800000, i18next.t('currency.EUR')],
			partnerRatio: [14, '%'],
			partnerDiscount: [3, '%'],
		}],
	},
});

/**
 * @constant
 * @name QuotationTotalsDefaultValues
 * @description An object containing all the default values used in the quotation totals table
 *
 * @author Florian Fornazaric
 */
export const QuotationTotalsDefaultValues = Object.freeze({
	data: {
		type: TableEntityTypes.QUOTATION_TOTALS,
		rows: [{
			grossTotal: [80000, i18next.t('currency.EUR')],
			vatTotal: [20, 80000 * 0.2, i18next.t('currency.EUR')],
			netTotal: [80000 * 1.2, i18next.t('currency.EUR')],
		}],
	},
});

/**
 * @constant
 * @name ExpenseDefaultValues
 * @description An object containing all the default values used in the expense table
 *
 * @author Romaric Barthe
 */
export const ExpenseDefaultValues = Object.freeze({
	data: {
		type: TableEntityTypes.EXPENSE_ROWS,
		rows: [{
			designation: i18next.t('template.pdf.default.expense_designation'),
			project: i18next.t('template.pdf.default.expense_project'),
			vatRate: 20,
			date: i18next.t('template.pdf.default.invoice_date'),
			netCost: [128.32, i18next.t('currency.EUR')],
		}],
	},
});

/**
 * @constant
 * @name ExpenseTotalsDefaultValues
 * @description An object containing all the default values used in the expense totals table
 *
 * @author Romaric Barthe
 */
export const ExpenseTotalsDefaultValues = Object.freeze({
	data: {
		type: TableEntityTypes.EXPENSE_TOTALS,
		rows: [{
			netTotal: [128.32, i18next.t('currency.EUR')],
			vatTotal: [(128.32 / 1.2) * 0.2, i18next.t('currency.EUR')],
			grossTotal: [10000 / 1.2, i18next.t('currency.EUR')],
		}],
	},
});

/**
 * @constant
 * @name InvoiceAdvancementStatusDefaultValues
 * @description An object containing all the default values used in the invoice advancement status table
 *
 * @author Florian Fornazaric
 */
export const InvoiceAdvancementStatusDefaultValues = Object.freeze({
	data: {
		type: TableEntityTypes.INVOICE_ADVANCEMENT_STATUS,
		rows: [{
			totalBase: [10000, i18next.t('currency.EUR')],
			totalAlreadyInvoiced: [40, 10000 * 0.4, i18next.t('currency.EUR')],
			totalAmountToInvoice: [60, 10000 * 0.6, i18next.t('currency.EUR')],
		}],
	},
});
