import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchTextObjectList } from 'redux/actions/textObjects';
import { useTextObjectListSelector } from 'redux/selectors/textObjects';
import { v4 as uuid } from 'uuid';

import { Select } from 'components/shared/inputs';

/**
 * @name TextObjectCapability
 * @description A text object capability, displayed inside the editor's toolbox
 *
 * @author Florian Fornazaric
 *
 * @param {object}		[value]		The current value of the capability
 * @param {string}		label		The label translation key to display
 * @param {function}	setValue	A callback updating the value associated to this capability
 * @param {boolean}		readOnly	Whether the capability can be edited by the user
 */
const TextObjectCapability = ({ value, label, setValue, readOnly, ...otherProps }) => {
	const { t } = useTranslation();
	const inputId = uuid(); // used to tie the label to the input.
	const reduxDispatch = useDispatch();

	const rawTextObjects = useTextObjectListSelector();

	const textObjects = useMemo(() => rawTextObjects.map((entityOption) => ({ label: entityOption.name, value: entityOption })), [rawTextObjects]);

	const selectedValue = useMemo(() => textObjects.find((textObject) => textObject.value.id === value?.id), [textObjects, value]);

	useEffect(() => {
		reduxDispatch(fetchTextObjectList());
	}, [reduxDispatch]);

	return (
		<div className={`input-wrapper${readOnly ? ' disabled' : ''}`}>
			<label htmlFor={inputId}>
				{t(label)}
			</label>
			<Select
				inputId={inputId}
				options={textObjects}
				value={selectedValue}
				openMenuOnFocus
				onChange={({ value: newValue }) => {
					setValue(newValue);
				}}
				isDisabled={readOnly}
				{...otherProps}
			/>
		</div>
	);
};

TextObjectCapability.propTypes = {
	label: PropTypes.string.isRequired,
	readOnly: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.object,
};

TextObjectCapability.defaultProps = {
	readOnly: false,
	value: undefined,
};

export default TextObjectCapability;
