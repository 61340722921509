/**
 * @function
 * @name hasMinLength
 * @description A validation method used to check if the field has a minimal length of n.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {string|number}	value		The input's current value.
 * @param {number}			minLength	The minimal required length.
 * @param {string}			message		The message to return if the validation fails.
 *
 * @returns {string}
 */
export const hasMinLength = (minLength, message) => (value) => ((value ?? '').trim().length >= minLength ? '' : message);

/**
 * @function
 * @name hasMaxLength
 * @description A validation method used to check if the field has a maximal length of n.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {string|number}	value		The input's current value.
 * @param {number}			maxLength	The maximal required length.
 * @param {string}			message		The message to return if the validation fails.
 *
 * @returns {string}
 */
export const hasMaxLength = (maxLength, message) => (value) => ((value ?? '').trim().length <= maxLength ? '' : message);
