import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Environments } from 'constants/environmentEnums';
import { formatExpenseName } from 'lib/expenses/formatExpenseData';
import { formatInvoiceName } from 'lib/invoices/formatInvoiceData';
import { formatQuotationName } from 'lib/quotations/formatQuotationData';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fetchContact } from 'redux/actions/contacts/contacts';
import { fetchExpense } from 'redux/actions/expenses/expenses';
import { fetchInvoice } from 'redux/actions/invoices/invoices';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { fetchPartner } from 'redux/actions/partners/partners';
import { fetchQuotation } from 'redux/actions/quotations/quotations';
import { useCurrentContactSelector } from 'redux/selectors/contacts/contacts';
import { useCurrentExpenseSelector } from 'redux/selectors/expenses/expenses';
import { useCurrentInvoiceSelector } from 'redux/selectors/invoices/invoices';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';
import { useCurrentPartnerSelector } from 'redux/selectors/partners/partners';
import { useCurrentQuotationWithFormattedNamesSelector } from 'redux/selectors/quotations/quotations';

import { Button } from 'components/shared/buttons';
import { Checkbox, Select } from 'components/shared/inputs';

import EntityTypes, { IssuableEntityTypes } from '../../../constants/EntityTypes';
import EditorContext from '../../../EditorContext';
import { linkEntity, updateEntity } from '../../../reducer/actions';

/**
 * @name EntityRow
 *
 * @description A component used to link an entity from the application to a pdf dynamic entity.
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {object}	entity			The template entity to display inside the row.
 * @param {object} 	entitiesList 	The list of entities the user can choose from to link to the template.
 * @param {object}	[initialValue]	The initial value of the entity.
*/
const EntityRow = (({ entity, entitiesList, initialValue }) => {
	const { t } = useTranslation();
	const { dispatch, editionDate } = useContext(EditorContext);
	const globalDispatch = useDispatch();

	const numberFormatExpense = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.EXPENSE)[0];
	const numberFormatInvoice = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.INVOICE)[0];
	const numberFormatQuotation = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.QUOTATION)[0];
	const [expanded, setExpanded] = useState(false);
	const [selectedEntity, setSelectedEntity] = useState(initialValue);

	const fetchFullEntity = useCallback(async (selectedEntityToFetch) => {
		switch (entity.type) {
			case EntityTypes.CONTACT.type:
				await globalDispatch(fetchContact(selectedEntityToFetch.id));
				break;
			case EntityTypes.EXPENSE.type:
				await globalDispatch(fetchExpense(selectedEntityToFetch.id));
				break;
			case EntityTypes.INVOICE.type:
				await globalDispatch(fetchInvoice(selectedEntityToFetch.id));
				break;
			case EntityTypes.PARTNER.type:
				await globalDispatch(fetchPartner(selectedEntityToFetch.id));
				break;
			case EntityTypes.QUOTATION.type:
				await globalDispatch(fetchQuotation(selectedEntityToFetch.id));
				break;
			default:
				break;
		}
	}, [entity.type, globalDispatch]);

	useEffect(() => {
		if (initialValue !== undefined && selectedEntity === undefined) {
			setSelectedEntity(initialValue);
			fetchFullEntity(initialValue.value);
		}
	}, [dispatch, entity.id, fetchFullEntity, initialValue, selectedEntity]);

	useEffect(() => {
		globalDispatch(fetchNumberFormatList());
	}, [globalDispatch]);

	// We get the entityType of the current entity
	const entityType = useMemo(() => Object.values(EntityTypes).find(({ type }) => type === entity.type), [entity.type]);

	const toggleExpansion = useCallback(() => {
		setExpanded((val) => !val);
	}, []);

	const fetchedContact = useCurrentContactSelector(selectedEntity?.value.id);
	const expenseRaw = useCurrentExpenseSelector(selectedEntity?.value.id);
	const invoiceRaw = useCurrentInvoiceSelector(selectedEntity?.value.id);
	const fetchedPartner = useCurrentPartnerSelector(selectedEntity?.value.id);
	const quotationRaw = useCurrentQuotationWithFormattedNamesSelector(selectedEntity?.value.id);

	const fetchedExpense = useMemo(() => (expenseRaw && {
		...expenseRaw,
		fullname: formatExpenseName(expenseRaw, numberFormatExpense, t),
		editionDate: expenseRaw?.generatedPdf?.pdfGenerationDate || editionDate,
	}), [editionDate, expenseRaw, numberFormatExpense, t]);

	const fetchedInvoice = useMemo(() => (invoiceRaw && {
		...invoiceRaw,
		fullname: formatInvoiceName(invoiceRaw, numberFormatInvoice, t),
		editionDate: invoiceRaw?.generatedPdf?.pdfGenerationDate || editionDate,
	}), [editionDate, invoiceRaw, numberFormatInvoice, t]);

	const fetchedQuotation = useMemo(() => (quotationRaw && {
		...quotationRaw,
		fullname: formatQuotationName(quotationRaw, numberFormatQuotation),
		editionDate: quotationRaw?.generatedPdf?.pdfGenerationDate || editionDate,
	}), [editionDate, quotationRaw, numberFormatQuotation]);

	const fetchedEntity = useMemo(() => {
		switch (entity.type) {
			case EntityTypes.CONTACT.type:
				return fetchedContact;
			case EntityTypes.EXPENSE.type:
				return fetchedExpense;
			case EntityTypes.INVOICE.type:
				return fetchedInvoice;
			case EntityTypes.PARTNER.type:
				return fetchedPartner;
			case EntityTypes.QUOTATION.type:
				return fetchedQuotation;
			default:
				return undefined;
		}
	}, [entity.type, fetchedContact, fetchedExpense, fetchedInvoice, fetchedPartner, fetchedQuotation]);

	useEffect(() => {
		if (fetchedEntity && selectedEntity) {
			dispatch(linkEntity(entity.id, fetchedEntity));
		}
	}, [dispatch, entity.id, fetchedEntity, selectedEntity]);

	const onEntitySelected = useCallback((newEntity) => {
		if (!_.isEqual(newEntity, selectedEntity)) {
			fetchFullEntity(newEntity.value);
		}

		setSelectedEntity(newEntity);

		setExpanded(false);
	}, [fetchFullEntity, selectedEntity]);

	const handleShouldIssueChange = useCallback(({ target: { checked } }) => {
		dispatch(updateEntity(entity.id, { shouldIssue: checked }));
	}, [dispatch, entity.id]);

	return (
		<li className="entity-row">
			<div className="icon">
				<entityType.icon />
			</div>
			<div className="info">
				<p className="title">{entity.name}</p>
				<p className="title">{entity.name}</p>
				<div className="issued-indicator">
					{entity?.shouldIssue && (
						<>
							<ReactTooltip id="entity" place="bottom" type="dark" effect="solid" />
							<span data-tooltip-id="entity" data-tooltip-content={t(`template.pdf.entities.issue.${entityType.type.toLowerCase()}`)}>
								{t('template.pdf.entities.issued')}
							</span>
						</>
					)}
				</div>
				<p className="description">{selectedEntity?.label ?? t('template.pdf.export.entities.empty')}</p>
			</div>
			<Button aria-expanded={expanded} controls={`entity-form-${entity.id}`} className="options expand" onClick={toggleExpansion}>
				<ChevronDown />
			</Button>
			<div id={`entity-form-${entity.id}`} className="entity-form" hidden={!expanded}>
				<Select
					placeholder={t('template.pdf.export.entities.select')}
					options={entitiesList}
					defaultValue={selectedEntity}
					value={selectedEntity}
					onChange={onEntitySelected}
				/>
				{IssuableEntityTypes.includes(entityType.type) && (
					<div className="entity-issue">
						<Checkbox
							id={`${entity.id}-issue`}
							checked={entity.shouldIssue ?? false}
							onChange={handleShouldIssueChange}
						/>
						<label htmlFor={`${entity.id}-issue`}>
							{t(`template.pdf.entities.issue.${entityType.type.toLowerCase()}`)}
						</label>
					</div>
				)}
			</div>
		</li>
	);
}
);

EntityRow.propTypes = {
	entity: PropTypes.shape({
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		shouldIssue: PropTypes.bool,
	}).isRequired,
	entitiesList: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.shape({
				id: PropTypes.string.isRequired,
			}).isRequired,
		}),
	).isRequired,
	initialValue: PropTypes.object,
};

EntityRow.defaultProps = {
	initialValue: undefined,
};

export default EntityRow;
