import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useTargetPartnersListLoadingSelector
 * @description A selector to retrieve the loading state from the targetPartnersList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useTargetPartnersListLoadingSelector = () => {
	const loading = useSelector((state) => state.targetPartnersList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useTargetPartnersForListSelector
 * @description A selector to retrieve the targetPartnersList list from the targetPartner redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTargetPartnersForListSelector = () => {
	const targetPartnersList = useSelector((state) => (state.targetPartnersList?.items ?? []), shallowEqual);

	return targetPartnersList;
};

/**
 * @function
 * @name useTargetPartnersListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the targetPartnersList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useTargetPartnersListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.targetPartnersList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useTargetPartnersForListSelector,
	useTargetPartnersListLoadingSelector,
	useTargetPartnersListTotalCountSelector,
};
