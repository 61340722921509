import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentContactSelector
 * @description A selector to retrieve the current contact from the contact redux state
 *
 * @author Audrey Clerc
 *
 * @param {string} id The identifier of the current contact to wait for
 *
 * @returns {object}
 */
const useCurrentContactSelector = (id) => {
	const contact = useSelector((state) => (state.contacts?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (contact?.id === id ? contact : null), [id, contact]);

	return result;
};

/**
 * @function
 * @name useContactsLoadingSelector
 * @description A selector to retrieve the loading state from the contact redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useContactsLoadingSelector = () => {
	const loading = useSelector((state) => state.contacts?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useContactListSelector
 * @description A selector to retrieve the contact list from the contact redux state.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {Array}
 */
const useContactListSelector = () => {
	const contacts = useSelector((state) => (state.contacts?.items ?? []), shallowEqual);

	return contacts;
};

/**
 * @function
 * @name useContactTotalCountSelector
 * @description A selector to retrieve the totalCount value from the contact redux state.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {number|undefined}
 */
const useContactTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.contacts?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useContactListForPartnerSelector
 * @description A selector to retrieve all the contacts linked to the partner identified by the partnerId parameter.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} partnerId The id of the partner to retrieve the contacts for.
 *
 * @returns {Array}
 */
const useContactListForPartnerSelector = (partnerId) => {
	const contacts = useContactListSelector();

	const result = useMemo(() => contacts.filter((contact) => contact.partners.find(({ id }) => id === partnerId)), [contacts, partnerId]);

	return result;
};

/**
 * @function
 * @name useContactByLinkedUserIdSelector
 * @description A selector to retrieve the contact linked to the user identified by the userId parameter.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} userId The id of the user to retrieve the contact for.
 *
 * @returns {object | undefined}
 */
const useContactByLinkedUserIdSelector = (userId) => {
	const contacts = useContactListSelector();

	const result = useMemo(() => {
		if (userId === undefined) {
			return undefined;
		}

		return contacts.find(({ linkedUser }) => linkedUser?.id === userId);
	}, [contacts, userId]);

	return result;
};

/**
 * @function
 * @name useAllForContactSelector
 * @description A selector to retrieve all relevant list for contact's forms from the contact redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAllForContactSelector = () => {
	const allForForm = useSelector((state) => (state.contacts?.allForForm ?? []), shallowEqual);

	return allForForm;
};

export {
	useAllForContactSelector,
	useContactByLinkedUserIdSelector,
	useContactListForPartnerSelector,
	useContactListSelector,
	useContactsLoadingSelector,
	useContactTotalCountSelector,
	useCurrentContactSelector,
};
