import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useTargetsListLoadingSelector
 * @description A selector to retrieve the loading state from the targetsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useTargetsListLoadingSelector = () => {
	const loading = useSelector((state) => state.targetsList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useTargetsForListSelector
 * @description A selector to retrieve the targetsList list from the target redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTargetsForListSelector = () => {
	const targetsList = useSelector((state) => (state.targetsList?.items ?? []), shallowEqual);

	return targetsList;
};

/**
 * @function
 * @name useTargetsListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the targetsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useTargetsListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.targetsList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useTargetsForListSelector,
	useTargetsListLoadingSelector,
	useTargetsListTotalCountSelector,
};
