import { OTHER } from '../../../../constants/ElementTypes';
import ElementType from '../ElementType';

/**
 * @name OtherTypes
 * @description Displays a list of element types inside the "other fields" tab.
 *
 * @author Florian Fornazaric
 */
const OtherTypes = () => (
	OTHER.map((item) => (
		<ElementType key={item.type} elementType={item} />
	))
);

export default OtherTypes;
