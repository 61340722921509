import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name ProjectIdentifier
 * @description A form used to group the identifier fields.
 *
 * @author Romaric Barthe
 *
 * @param {function}	setTitles		The method to update the titles.
 */
const ProjectIdentifier = ({ setTitles }) => {
	const { t } = useTranslation();

	return (
		<>
			<TextInput
				label={t('projects.edition.inputs.name.label')}
				name="name"
				onChange={setTitles}
				placeholder={t('projects.edition.inputs.name.placeholder')}
				rules={{
					required: Validators.isRequired(t('projects.edition.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextArea
				label={t('projects.edition.inputs.description.label')}
				name="description"
				onChange={setTitles}
				placeholder={t('projects.edition.inputs.description.placeholder')}
				type="text"
			/>
		</>
	);
};

ProjectIdentifier.propTypes = {
	setTitles: PropTypes.func,
};

ProjectIdentifier.defaultProps = {
	setTitles: () => {},
};

export default ProjectIdentifier;
