import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import { formatQuotationNameList } from 'lib/quotations/formatQuotationData';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { fetchQuotationsSelectList } from 'redux/actions/quotations/quotationsSelect';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';
import { useQuotationLoadingSelector } from 'redux/selectors/quotations/quotations';
import { useQuotationsSelectListSelector } from 'redux/selectors/quotations/quotationsSelect';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { getQuotationCreationTypes } from './functions';

/**
 * @name QuotationPreCreationForm
 * @description A form used to create a new quotation.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const QuotationPreCreationForm = ({ onSubmit }) => {
	const [quotationCreationTypeSelected, setQuotationCreationTypeSelected] = useState();

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const numberFormat = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.QUOTATION)[0];
	const quotationsList = useQuotationsSelectListSelector();
	const quotations = useMemo(() => quotationsList.map((quotation) => ({
		...quotation,
		fullname: formatQuotationNameList(quotation, numberFormat),
	})).sort((a, b) => a.updatedAt < b.updatedAt).sort((a, b) => a.favorite < b.favorite), [numberFormat, quotationsList]);
	const areQuotationsLoading = useQuotationLoadingSelector();

	useEffect(() => {
		dispatch(fetchNumberFormatList());
		dispatch(fetchQuotationsSelectList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const quotationCreationTypesList = useMemo(() => getQuotationCreationTypes().map(
		(quotationCreationType) => ({ id: quotationCreationType.id, label: t(quotationCreationType.label) })
	), [t]);

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm
			onSubmit={onSubmit}
			{...formProps}
		>
			<Select
				label={t('quotation.creation.creation_type.label')}
				labelKey="label"
				name="creationType"
				onChange={setQuotationCreationTypeSelected}
				options={quotationCreationTypesList}
				rules={{
					required: Validators.isRequired(t('quotation.creation.creation_type.validation_errors.required')),
				}}
				valueKey="id"
			/>
			{(quotationCreationTypeSelected !== 'empty' && !_.isEmpty(quotationCreationTypeSelected)) && (
				<Select
					label={t('quotation.creation.creation_type.quotation.label')}
					labelKey="fullname"
					name="quotation"
					options={quotations}
					rules={{
						required: Validators.isRequired(t('quotation.creation.creation_type.quotation.validation_errors.required')),
					}}
					valueKey="id"
					isLoading={areQuotationsLoading}
				/>
			)}

			<Button className="primary" type="submit" {...buttonProps}>{t('quotation.creation.action')}</Button>
		</DynamicForm>
	);
};

QuotationPreCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default QuotationPreCreationForm;
