import { toast } from 'react-toastify';
import * as TemplateExportsApi from 'api/templateExportsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the TemplateExports redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific template export
	FETCH_TEMPLATE_EXPORT_REQUEST: '@TEMPLATE_EXPORTS/FETCH_REQUEST',
	FETCH_TEMPLATE_EXPORT_SUCCESS: '@TEMPLATE_EXPORTS/FETCH_SUCCESS',
	FETCH_TEMPLATE_EXPORT_FAILURE: '@TEMPLATE_EXPORTS/FETCH_FAILURE',

	// Fetch a list of template exports
	FETCH_TEMPLATE_EXPORT_LIST_REQUEST: '@TEMPLATE_EXPORTS/FETCH_LIST_REQUEST',
	FETCH_TEMPLATE_EXPORT_LIST_SUCCESS: '@TEMPLATE_EXPORTS/FETCH_LIST_SUCCESS',
	FETCH_TEMPLATE_EXPORT_LIST_FAILURE: '@TEMPLATE_EXPORTS/FETCH_LIST_FAILURE',

	// Add a new template export to the database
	ADD_TEMPLATE_EXPORT_REQUEST: '@TEMPLATE_EXPORTS/ADD_REQUEST',
	ADD_TEMPLATE_EXPORT_SUCCESS: '@TEMPLATE_EXPORTS/ADD_SUCCESS',
	ADD_TEMPLATE_EXPORT_FAILURE: '@TEMPLATE_EXPORTS/ADD_FAILURE',

	// Remove an existing template export from the database
	REMOVE_TEMPLATE_EXPORT_REQUEST: '@TEMPLATE_EXPORTS/REMOVE_REQUEST',
	REMOVE_TEMPLATE_EXPORT_SUCCESS: '@TEMPLATE_EXPORTS/REMOVE_SUCCESS',
	REMOVE_TEMPLATE_EXPORT_FAILURE: '@TEMPLATE_EXPORTS/REMOVE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Template list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTemplateExportListRequest
 * @description Action triggered anytime a template export list fetching call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchTemplateExportListRequest = () => ({ type: ActionTypes.FETCH_TEMPLATE_EXPORT_LIST_REQUEST });

/**
 * @function
 * @name fetchTemplateExportListSuccess
 * @description Action triggered as a result to a successful template export list fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} templateExports	The list of retrieved template exports.
 * @param {number} totalCount	The total amount of template exports available in the database for the current user.
 *
 * @returns {object}
 */
const fetchTemplateExportListSuccess = ({ templateExports, totalCount }) => ({
	type: ActionTypes.FETCH_TEMPLATE_EXPORT_LIST_SUCCESS,
	payload: { templateExports, totalCount },
});

/**
 * @function
 * @name fetchTemplateExportListFailure
 * @description Action triggered as a result to a failed template export list fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTemplateExportListFailure = (error) => ({
	type: ActionTypes.FETCH_TEMPLATE_EXPORT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific template fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTemplateExportRequest
 * @description Action triggered anytime a template export fetching call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchTemplateExportRequest = () => ({ type: ActionTypes.FETCH_TEMPLATE_EXPORT_REQUEST });

/**
 * @function
 * @name fetchTemplateExportSuccess
 * @description Action triggered as a result to a successful template export fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} template	The retrieved template export.
 *
 * @returns {object}
 */
const fetchTemplateExportSuccess = ({ templateExport }) => ({
	type: ActionTypes.FETCH_TEMPLATE_EXPORT_SUCCESS,
	payload: { templateExport },
});

/**
 * @function
 * @name fetchTemplateExportFailure
 * @description Action triggered as a result to a failed template export fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTemplateExportFailure = (error) => ({
	type: ActionTypes.FETCH_TEMPLATE_EXPORT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Template creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addTemplateExportRequest
 * @description Action triggered anytime a template export creation call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const addTemplateExportRequest = () => ({ type: ActionTypes.ADD_TEMPLATE_EXPORT_REQUEST });

/**
 * @function
 * @name addTemplateExportSuccess
 * @description Action triggered as a result to a successful template export creation API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} templateExport	The created template export object.
 *
 * @returns {object}
 */
const addTemplateExportSuccess = ({ templateExport }) => ({
	type: ActionTypes.ADD_TEMPLATE_EXPORT_SUCCESS,
	payload: { templateExport },
});

/**
 * @function
 * @name addTemplateExportFailure
 * @description Action triggered as a result to a failed template export creation API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addTemplateExportFailure = (error) => ({
	type: ActionTypes.ADD_TEMPLATE_EXPORT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Template removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeTemplateExportRequest
 * @description Action triggered anytime a template export deletion call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const removeTemplateExportRequest = () => ({ type: ActionTypes.REMOVE_TEMPLATE_EXPORT_REQUEST });

/**
 * @function
 * @name removeTemplateExportSuccess
 * @description Action triggered as a result to a successful template export deletion API call.
 *
 * @author Yann Hodiesne
 *
 * @param { string } deletedTemplateId	The removed template export object id.
 *
 * @returns {object}
 */
const removeTemplateExportSuccess = ({ deletedTemplateExportId }) => ({
	type: ActionTypes.REMOVE_TEMPLATE_SUCCESS,
	payload: { deletedTemplateExportId },
});

/**
 * @function
 * @name removeTemplateExportFailure
 * @description Action triggered as a result to a failed template export deletion API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeTemplateExportFailure = (error) => ({
	type: ActionTypes.REMOVE_TEMPLATE_EXPORT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTemplateExportList
 * @description Method used to fetch the template export list.
 *
 * @author Yann Hodiesne
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchTemplateList = (params) => (dispatch) => {
	dispatch(fetchTemplateExportListRequest());

	return TemplateExportsApi.fetchTemplateExports(params)
		.then(({ templateExports, totalCount }) => dispatch(fetchTemplateExportListSuccess({ templateExports, totalCount })))
		.catch((error) => dispatch(fetchTemplateExportListFailure(error)));
};

/**
 * @function
 * @name fetchTemplateExport
 * @description Method used to fetch the latest version of a specific template export.
 *
 * @author Yann Hodiesne
 *
 * @param {string} templateId	The id of the template export we want to retrieve.
 */
export const fetchTemplateExport = (templateExportId) => (dispatch) => {
	dispatch(fetchTemplateExportRequest());

	return TemplateExportsApi.fetchTemplateExportById(templateExportId)
		.then(({ templateExport }) => dispatch(fetchTemplateExportSuccess({ templateExport })))
		.catch((error) => dispatch(fetchTemplateExportFailure(error)));
};

/**
 * @function
 * @name addTemplateExport
 * @description Method used to add a new template export instance to the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} templateExportData	The data to create the new template export from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addTemplateExport = (templateExportData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addTemplateExportRequest());

	return TemplateExportsApi.createTemplateExport(templateExportData)
		.then(({ templateExport }) => {
			toast.success(i18next.t('template_export.creation.toasts.success', { name: templateExport.name }));
			dispatch(addTemplateExportSuccess({ templateExport }));
			redirectOnSuccess(onSuccessRoute);

			return templateExport;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('template_export.creation.toasts.conflict'));
				dispatch(addTemplateExportFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('template_export.creation.toasts.error'));
				dispatch(addTemplateExportFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeTemplateExport
 * @description Method used to remove an existing template export instance from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} templateExport		The template we want to remove from the database.
 * @param {string} templateExport.id	The id of the template we want to remove from the database.
 * @param {string} templateExport.name	The name of the template we want to remove from the database.
 */
export const removeTemplateExport = ({ id, name }) => (dispatch) => {
	dispatch(removeTemplateExportRequest());

	return TemplateExportsApi.deleteTemplateExport(id)
		.then(({ deletedTemplateExportId }) => {
			dispatch(removeTemplateExportSuccess({ deletedTemplateExportId }));
			toast.success(i18next.t('template_export.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeTemplateExportFailure(error));
			toast.error(i18next.t('template_export.deletion.toasts.error'));
		});
};
