import { useCallback, useContext } from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import EditorContext from '../../EditorContext';
import { updateElement } from '../../reducer/actions';

const css = {
	border: '1px solid #939EA9', // #939EA9 is var(--clr-alabaster-600)
	borderRadius: '4px',
	backgroundColor: '#FAFAFA', // #FAFAFA is var(--clr-alabaster-100)
	minHeight: '1.5em',
	padding: '0px 2px',
};

/**
 * @name DynamicText
 * @description A dynamic text PDF element
 *
 * @author Yann Hodiesne
 * @author Florian Fornazaric
 *
 * @param {number}	[aspectRatio = 1]	The aspect ratio of the PDF editor
 * @param {object}	style				The style of the component
 * @param {string}	element			 	The element attached to the DynamicText
 * @param {boolean} editing				True if the element is being edited
 * @param {boolean} resizable			True if the element is being resized
 */
const DynamicText = ({ aspectRatio, style, element, editing, resizable, ...props }) => {
	const { dispatch, isExporting } = useContext(EditorContext);

	const handleChange = useCallback((e) => {
		dispatch(updateElement(element.id, { content: e.target.value }));
	}, [dispatch, element]);

	return (
		<textarea
			{...props}
			className={`seamless${editing || resizable ? ' editing' : ''}`}
			style={{ ...css, ...style }}
			onChange={handleChange}
			value={element.content}
			readOnly={!isExporting && !editing}
		/>
	);
};

DynamicText.propTypes = {
	aspectRatio: PropTypes.number,
	style: PropTypes.object,
	element: PropTypes.object,
	editing: PropTypes.bool,
	resizable: PropTypes.bool,
};

DynamicText.defaultProps = {
	aspectRatio: 1,
	style: {},
	element: undefined,
	editing: false,
	resizable: false,
};

const styles = StyleSheet.create(css);

DynamicText.PdfElement = ({ style, ...otherprops }) => (
	<Text style={[styles, style]} {...otherprops} />
);

DynamicText.PdfElement.propTypes = {
	style: PropTypes.object,
};

DynamicText.PdfElement.defaultProps = {
	style: {},
};

DynamicText.PdfElement.displayName = 'DynamicText';

export default DynamicText;
