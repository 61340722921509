import { useCallback, useEffect, useMemo, useRef } from 'react';
import { history as globalHistory } from 'routes/components/RouterProvider';

/**
 * @name useNavigationBlock
 * @description A custom hook preventing the user from navigating away.
 *
 * @author Yann Hodiesne
 *
 * @param {string}  text    	 The text to be displayed to ask the user if they want to leave the page.
 * @param {bool}    block   	 Determines if the navigation should be blocked.
 * @param {object}	[history]	 The current history object, for testing purposes.
 *
 * Note: you do not need to call the returned function to clean up the block, except if you need to control the order in which it happens
 *
 * @returns {func} Function used to unblock the navigation manually
 */
export default (text, block, history) => {
	const hist = useMemo(() => history || globalHistory, [history]);

	// Refs used to avoid regenerating the block at every render
	const textRef = useRef();
	const blockRef = useRef();
	const navigationBlock = useRef();

	// Hold on to the latest values
	textRef.current = text;
	blockRef.current = block;

	const unblock = useCallback(() => {
		if (navigationBlock.current) {
			// Unblock the navigation
			navigationBlock.current();
			navigationBlock.current = undefined;
		}
	}, [navigationBlock]);

	useEffect(() => {
		if (!navigationBlock.current) {
			navigationBlock.current = hist.block(() => {
				if (blockRef.current) {
					// Block the navigation
					return textRef.current;
				}

				return undefined;
			});
		}

		return unblock;
	}, [blockRef, hist, navigationBlock, textRef, unblock]);

	return unblock;
};
