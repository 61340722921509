import { v4 as uuid } from 'uuid';

/**
 * @function
 * @name createDuplicateElement
 * @description Duplicates an element giving it a new id
 *
 * @param {object} element	Element that will be duplicated
 *
 * @author Florian Fornazaric
 */
const createDuplicateElement = (element) => ({
	// Creating an duplicate of the provided element with a new id
	...element,
	id: uuid(),
});

export default createDuplicateElement;
