import { createElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Button } from 'components/shared/buttons';

import DynamicFormContext from '../internals/DynamicFormContext';

/**
 * @name FieldArray
 * @description A dynamic component used to generate and manipulate field arrays.
 *
 * @author Yann Hodiesne
 *
 * @param {string}	[appendButtonTranslationKey]	The key to the "append" button i18n string.
 * @param {func}	component						The component to render on each field of this array.
 * @param {string}	name							The field array's name.
 */
const FieldArray = ({ appendButtonTranslationKey, component, name, disabled, readOnly, className, ...props }) => {
	const { t } = useTranslation();

	const { defaultValues, disabled: formDisabled, readOnly: formReadOnly, registerReset } = useContext(DynamicFormContext);

	useEffect(() => registerReset(name), [name, registerReset]);

	const [fields, setFields] = useState(() => {
		const defaultValue = _.get(defaultValues, name);
		const count = _.isArray(defaultValue) ? defaultValue.length : 1;

		return [...Array(count >= 1 ? count : 1)].map(() => uuid());
	});

	const isEditable = useMemo(() => !disabled && !readOnly && !formDisabled && !formReadOnly, [disabled, formDisabled, formReadOnly, readOnly]);

	const appendField = useCallback(() => {
		setFields((value) => update(value, {
			$push: [uuid()],
		}));
	}, []);

	const removeField = useCallback((field) => {
		setFields((value) => {
			if (value.length <= 1) {
				return value;
			}

			return update(value, {
				$splice: [[value.indexOf(field), 1]],
			});
		});
	}, []);

	return (
		<ul className={`field-array${className ? ` ${className}` : ''}`}>
			{fields.map((id, index) => {
				const fieldName = `${name}[${index}]`;

				return (
					<li key={id}>
						{createElement(component, {
							...props,
							name: fieldName,
							disabled,
							readOnly,
							'data-testid': id,
						})}

						{isEditable && (
							<Button type="button" disabled={fields.length < 2 || !isEditable} onClick={() => removeField(id)} data-testid={`remove-${index}`}>
								{t('actions.remove')}
							</Button>
						)}
					</li>
				);
			})}
			{isEditable && (
				<Button className="secondary field-array-append" name={`append-${name}-field`} type="button" onClick={appendField} disabled={!isEditable}>
					<PlusCircle />
					{t(appendButtonTranslationKey)}
				</Button>
			)}
		</ul>
	);
};

FieldArray.propTypes = {
	appendButtonTranslationKey: PropTypes.string,
	component: PropTypes.elementType.isRequired,
	name: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	className: PropTypes.string,
};

FieldArray.defaultProps = {
	appendButtonTranslationKey: 'actions.append',
	disabled: false,
	readOnly: false,
	className: undefined,
};

export default FieldArray;
