import { Canon } from 'components/shared/Typography';

/**
 * @name AdminDashboardPage
 * @description The super-admin dashboard page of the application.
 *
 * @author Yann Hodiesne
 */
const AdminDashboardPage = () => (
	<Canon tag="h1">Admin dashboard page.</Canon>
);

export default AdminDashboardPage;
