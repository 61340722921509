import { useCallback } from 'react';
import { LogOut, Settings } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { PromptModal, useModal } from 'components/shared/modal';

/**
 * @name UserMenu
 * @description The pop up menu displayed when the user clicks their avatar in the header's user section.
 *
 * @author Romaric Barthe
 * @author Timothée Simon-Franza
 *
 * @param {bool} isOpen Whether the menu should be open or not.
 */
const UserMenu = ({ isOpen, toggleDropdown }) => {
	const { isShowing, toggle: toggleModal } = useModal();
	const { t } = useTranslation();

	const onLogoutModalConfirmation = useCallback(() => {
		history.push(routes.auth.logout);
	}, []);

	const onLogoutButtonClick = useCallback(() => {
		toggleDropdown();
		toggleModal();
	}, [toggleDropdown, toggleModal]);

	const onPreferencesButtonClick = useCallback(() => {
		history.push(generatePath(routes.user.preferences));
	}, []);

	return (
		<>
			<ul className={`user-menu ${isOpen ? 'open' : ''}`}>
				<li>
					<button type="button" data-testid="usermenu-parameters" onClick={onPreferencesButtonClick}>
						<Settings />
						<span>{t('navbar.parameters')}</span>
					</button>
				</li>
				<li>
					<button type="button" data-testid="usermenu-logout" onClick={onLogoutButtonClick}>
						<LogOut />
						<span>{t('navbar.logout')}</span>
					</button>
				</li>
			</ul>
			<PromptModal
				isShowing={isShowing}
				message={t('logout.confirmationModal.content')}
				confirm={onLogoutModalConfirmation}
				cancel={toggleModal}
				confirmText={t('logout.confirmationModal.confirm')}
				cancelText={t('logout.confirmationModal.cancel')}
				titleText={t('logout.confirmationModal.title')}
			/>
		</>
	);
};

UserMenu.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggleDropdown: PropTypes.func.isRequired,
};

export default UserMenu;
