import PropTypes from 'prop-types';

/**
 * @name AccessDefinitionsCell
 * @description Custom cell for the userListPage table displaying user's access definitions.
 *
 * @author Romaric Barthe
 */
const AccessDefinitionsCell = ({ row: { original: { accessDefinitions } } }) => (
	<ul className="tag-list">
		{accessDefinitions.map(({ id, name }) => (
			<li key={id}>
				<span className="tag">
					{name}
				</span>
			</li>
		))}
	</ul>
);

AccessDefinitionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			accessDefinitions: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default AccessDefinitionsCell;
