import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import update from 'immutability-helper';
import { getCurrencyName } from 'lib/currencies/formatCurrencyData';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fetchAllForExpense, fetchExpense } from 'redux/actions/expenses/expenses';
import { addUser } from 'redux/actions/users';
import { useAllForExpenseSelector, useExpenseLoadingSelector } from 'redux/selectors/expenses/expenses';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';
import { v4 as uuid } from 'uuid';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Checkbox } from 'components/shared/forms/inputs';
import { Collapsible } from 'components/shared/layout';
import { sumOneObjectPropertyInArray } from 'components/shared/utils/functions';
import TemplateListSelect from 'components/templates/TemplateListSelect';
import { UserCreationForm } from 'components/users';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import ExpenseGrid from './ExpenseGrid';
import ExpenseHeader from './ExpenseHeader';
import ExpenseTitle from './ExpenseTitle';
import ExpenseTotalsTable from './ExpenseTotalsTable';
import { getExpenseColumnsHeaders, getRowTotals } from './functions';

/**
 * @name ExpenseEditionForm
 * @description A form used to edit an existing expense's report.
 *
 * @author Romaric Barthe
 *
 * @param {function}	onSubmit	The method to trigger upon form submission.
 * @param {object}		expense		The expense object to update information from.
 */
const ExpenseEditionForm = ({ onSubmit, expense }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForExpenseForm = useAllForExpenseSelector();
	const accounts = useMemo(() => allForExpenseForm?.unarchivedAccounts ?? [], [allForExpenseForm]);
	const companies = useMemo(() => allForExpenseForm?.companies ?? [], [allForExpenseForm]);
	const currencies = useMemo(() => allForExpenseForm?.currencies ?? [], [allForExpenseForm]);
	const lastEditionDate = useMemo(() => allForExpenseForm?.lastEditionDate ?? '', [allForExpenseForm]);
	const numberFormat = useMemo(() => allForExpenseForm?.numberFormat, [allForExpenseForm]);
	const templates = useMemo(() => allForExpenseForm?.unarchivedTemplates, [allForExpenseForm]);
	const projects = useMemo(() => allForExpenseForm?.unarchivedProjects ?? [], [allForExpenseForm]);
	const users = useMemo(() => allForExpenseForm?.enabledUsers ?? [], [allForExpenseForm]);
	const vatRates = useMemo(() => allForExpenseForm?.unarchivedVatRates ?? [], [allForExpenseForm]);

	const isLoading = useExpenseLoadingSelector();

	const lastEditionDateFormatted = useMemo(() => new Date(new Date(lastEditionDate).setHours(0, 0, 0, 0)), [lastEditionDate]);

	const [optionAccount, setOptionAccount] = useState(false);
	const [optionDate, setOptionDate] = useState(false);
	const [optionLocalCurrency, setOptionLocalCurrency] = useState(false);
	const [optionProject, setOptionProject] = useState(false);

	const columnsHeadersToDisplay = useMemo(() => getExpenseColumnsHeaders(optionAccount, optionDate, optionLocalCurrency, optionProject),
		[optionAccount, optionDate, optionLocalCurrency, optionProject]);

	const [currency, setCurrency] = useState('');
	const currencyName = useMemo(() => getCurrencyName(currencies, currency), [currencies, currency]);
	const currencySymbol = useMemo(() => ((currencyName && currencyName !== '') ? t(`currency.${currencyName}`) : ''), [currencyName, t]);

	const [rows, setRows] = useState([]);
	const sortedRows = useMemo(() => rows.sort((a, b) => a.line - b.line), [rows]);

	const [duplicateRowId, setDuplicateRowId] = useState(); // Use for row duplication
	const [originalRowData, setOriginalRowData] = useState(); // Use for row duplication

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const [grossTotal, setGrossTotal] = useState(0);
	const [vatTotal, setVatTotal] = useState(0);
	const [netTotal, setNetTotal] = useState(0);
	const [rowsTotals, setRowsTotals] = useState([]);

	// !!Only for Header: Props to pass down to the select input so that it will display ancreation modal when clicking on the "+" icon.
	const userCreationModal = useFormModal(UserCreationForm, t('expense.edition.inputs.user.creation'), fetchAllForExpense, addUser);

	/**
	 * @function
	 * @name setTotals
	 * @description A function to refresh Total variables.
	 *
	 * @author Romaric Barthe
	 */
	const setTotals = useCallback(() => {
		const formData = formRef.current?.getFormValues();
		setRowsTotals(getRowTotals(formData?.rows, vatRates));
	}, [formRef, vatRates]);

	/**
	 * @function
	 * @name updateRow
	 * @description A function to add/ move/ delete/ duplicate a row.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	rowId			The id of the row triggering the action.
	 * @param {number}	lineNb			The number of the row triggering the action.
	 * @param {number}	direction		The direction if move: positive is for down, negative is for up.
	 * @param {string}	action			The possible action: 'add', 'move', 'duplicate', 'select'.
	 */
	const updateRow = useCallback((action, rowId = undefined, lineNb = undefined, direction = undefined) => {
		if (action === 'add') {
			const newId = uuid();
			const lines = rows.map((row) => row?.line) ?? [];
			const newRowNumber = _.isEmpty(lines) ? 1 : Math.max(...lines) + 1;
			setRows([...rows, { id: newId, line: newRowNumber }]);
		} else {
			if (rows.findIndex((row) => row.id === rowId) === undefined) {
				throw new Error('An issue occured with the current lines. Please refresh the page.');
			}
			if (action === 'move') {
				const rowIndexToMoveInDirection = rows.findIndex((row) => row.id === rowId);
				const rowIndexToMoveInOtherDirection = rows.findIndex((row) => row.line === lineNb + direction);
				setRows(update(rows, {
					[rowIndexToMoveInDirection]: {
						line: { $set: lineNb + direction },
					},
					[rowIndexToMoveInOtherDirection]: {
						line: { $set: lineNb },
					},
				}));
			}
			if (action === 'delete') {
				const rowIndexToRemove = rows.findIndex((row) => row.id === rowId);
				setRows(update(rows, {
					$splice: [[rowIndexToRemove, 1]],
					$apply: (newRows) => newRows.sort((a, b) => a.line - b.line).map((r, i) => update(r, { line: { $set: i + 1 } })),
				}));
				setRowsTotals(update(rowsTotals, {
					$apply: (newRows) => newRows.filter((row) => row.id !== rowId),
				}));
			}
			if (action === 'duplicate') {
				const newId = uuid();
				setRows(update(rows, {
					$apply: (newRows) => newRows.map((r) => (r.line > lineNb ? update(r, { line: { $set: r.line + 1 } }) : r)),
					$push: [{
						id: newId,
						line: lineNb + 1,
					}],
				}));
				const formData = formRef.current.getFormValues();
				setOriginalRowData(formData.rows[rowId]);
				setDuplicateRowId(newId);
			}
		}
	}, [formRef, rows, rowsTotals]);

	const canEditExpense = useAccessRight(AccessRights.humanResources.expenses.enhancedRights.CREATE_EXPENSE) && !expense?.archived;

	const linkToTemplate = useCallback((templateId, editionDate) => {
		const formattedEditionDate = moment(editionDate).format('YYYYMMDD');
		const formattedLastEditionDate = moment(lastEditionDate).format('YYYYMMDD');
		dispatch(fetchExpense(expense?.id));
		history.push(generatePath(routes.settings.templates.templatePdfExportFromApp, {
			id: templateId,
			entityId: expense?.id,
			editionDate: formattedEditionDate,
			lastEditionDate: formattedLastEditionDate,
		}));
	}, [dispatch, expense?.id, lastEditionDate]);

	// Fetch data
	useEffect(() => {
		dispatch(fetchAllForExpense());
	}, [dispatch]);

	// Display relevant columns
	useEffect(() => {
		if (expense.rows) {
			Object.entries(expense.rows).forEach((row) => {
				if (row[1].account !== null) {
					setOptionAccount(true);
				}
				if (row[1].date !== null) {
					setOptionDate(true);
				}
				if (row[1].currency !== null) {
					setOptionLocalCurrency(true);
				}
				if (row[1].project !== null) {
					setOptionProject(true);
				}
			});
		}
	}, [expense]);

	// Set default values
	const defaultValues = useMemo(() => {
		const flatRows = Object.fromEntries(update(expense.rows, {
			$apply: (newRows) => Object.entries(newRows).map((row) => update(row, {
				$apply: (newRow) => update(newRow, {
					1: {
						account: { $set: newRow[1].account?.id },
						currency: { $set: newRow[1].currency?.id },
						project: { $set: newRow[1].project?.id },
						vatRate: { $set: newRow[1].vatRate?.id },
					},
				}),
			})),
		}));

		return ({
			...expense,
			rows: flatRows,
			currency: expense.currency?.id || '',
			reversedExpense: expense.reversedExpense?.id,
			structure: expense.structure?.id || null,
			user: expense.user?.id || null,
		});
	}, [expense]);

	// Set state's variables
	useEffect(() => {
		setCurrency(defaultValues?.currency);
		setGrossTotal(defaultValues?.grossTotal);
		setNetTotal(defaultValues?.netTotal);
		setVatTotal(defaultValues?.vatTotal);
		setRows(defaultValues?.rows ? Object.values(defaultValues.rows) : []);
		setRowsTotals(getRowTotals(defaultValues?.rows, vatRates));
	}, [defaultValues, vatRates]);

	// Refresh totals
	useEffect(() => {
		setGrossTotal(sumOneObjectPropertyInArray(rowsTotals, 'gt'));
		setVatTotal(sumOneObjectPropertyInArray(rowsTotals, 'vt'));
		setNetTotal(sumOneObjectPropertyInArray(rowsTotals, 'nt'));
	}, [rowsTotals]);

	return (
		<DynamicForm
			ref={formRef}
			cleanFormData
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			disabled={!canEditExpense}
			{...formProps}
		>
			<ExpenseTitle expense={expense} numberFormat={numberFormat} />

			<Collapsible titleKey="expense.edition.collapsed.general_informations" collapsed>
				<ExpenseHeader
					companies={companies}
					currencies={currencies}
					creationType={expense?.creationType}
					defaultValues={defaultValues}
					isLoading={isLoading}
					sourceExpenseId={expense?.reversedExpense?.id}
					userCreationModal={userCreationModal}
					users={users}
					setCurrency={setCurrency}
				/>
			</Collapsible>

			<ExpenseTotalsTable
				currencySymbol={currencySymbol}
				totalExpensesWithVat={netTotal}
				totalExpensesWithoutVat={grossTotal}
				totalVat={vatTotal}
			/>

			<Collapsible titleKey="expense.edition.collapsed.columns_options" collapsed>
				<div className="aside">
					<Checkbox
						label={t('expense.edition.options.local_currency')}
						name="optionLocalCurrency"
						labelFirst={false}
						checked={optionLocalCurrency}
						onChange={setOptionLocalCurrency}
					/>
					<Checkbox
						label={t('expense.edition.options.date')}
						name="optionDate"
						labelFirst={false}
						checked={optionDate}
						onChange={setOptionDate}
					/>
					<Checkbox
						label={t('expense.edition.options.project')}
						name="optionProject"
						labelFirst={false}
						checked={optionProject}
						onChange={setOptionProject}
					/>
					<Checkbox
						label={t('expense.edition.options.account')}
						name="optionAccount"
						labelFirst={false}
						checked={optionAccount}
						onChange={setOptionAccount}
					/>
				</div>
			</Collapsible>

			<Collapsible titleKey="expense.edition.collapsed.grid">
				<ExpenseGrid
					accounts={accounts}
					columnsHeadersToDisplay={columnsHeadersToDisplay}
					currencies={currencies}
					currencyName={currencyName}
					currencySymbol={currencySymbol}
					defaultValues={defaultValues}
					isLoading={isLoading}
					projects={projects}
					readOnly={expense?.archived}
					rows={sortedRows}
					vatRates={vatRates}
					// functions
					updateRow={updateRow}
					setTotals={setTotals}
					// duplicate logic
					duplicateRowId={duplicateRowId}
					originalRowData={originalRowData}
				/>

				{!expense?.archived && canEditExpense && (
					<Button className="secondary" type="button" onClick={() => updateRow('add')}>
						<PlusCircle />
						{t('expense.edition.grid.add_row')}
					</Button>
				)}
			</Collapsible>

			<div className="edition-actions">
				{templates && (
					<TemplateListSelect onChangeCallback={linkToTemplate} lastEditionDate={lastEditionDateFormatted} templates={templates} />
				)}
				<Button className="primary" type="submit" {...buttonProps}>
					{canEditExpense ? t('expense.edition.action') : t('expense.edition.close')}
				</Button>
			</div>

		</DynamicForm>
	);
};

ExpenseEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	expense: PropTypes.shape({
		archived: PropTypes.bool,
		comment: PropTypes.string,
		creationType: PropTypes.string,
		currency: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		grossTotal: PropTypes.number,
		id: PropTypes.string.isRequired,
		netTotal: PropTypes.number,
		user: PropTypes.shape({
			id: PropTypes.string.isRequired,
			username: PropTypes.string.isRequired,
		}).isRequired,
		reversedExpense: PropTypes.shape({
			id: PropTypes.string,
			editionNumber: PropTypes.number,
		}),
		rows: PropTypes.shape({
			id: PropTypes.string,
		}),
		structure: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		vatTotal: PropTypes.number,
	}).isRequired,
};

export default ExpenseEditionForm;
