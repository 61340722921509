import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchRegistrationTypeList } from 'redux/actions/registrationTypes';
import { useRegistrationTypeListSelector, useRegistrationTypesLoadingSelector } from 'redux/selectors/registrationTypes';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name RegistrationEditionForm
 * @description A form used to edit an existing registration's information.
 *
 * @author Romaric Barthe
 *
 * @param {function}	onSubmit			The method to trigger upon form submission.
 * @param {object}		registration		The registration object to update information from.
 */
const RegistrationEditionForm = ({ onSubmit, registration }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchRegistrationTypeList());
	}, [dispatch]);

	const registrationTypes = useRegistrationTypeListSelector();
	const areRegistrationTypesLoading = useRegistrationTypesLoadingSelector();

	const canEditRegistration = useAccessRight(AccessRights.commonModules.registrations.enhancedRights.CREATE_REGISTRATION) && !registration?.archived;

	const defaultValues = useMemo(() => ({
		code: registration.code ?? '',
		name: registration.name,
		type: registration.type.id,
	}), [registration]);

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			disabled={!canEditRegistration}
			{...formProps}
		>
			<Select
				isLoading={areRegistrationTypesLoading}
				label={t('registration.edition.inputs.type.label')}
				labelKey="name"
				name="type"
				options={registrationTypes}
				rules={{
					required: Validators.isRequired(t('registration.edition.inputs.type.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<TextInput
				label={t('registration.edition.inputs.name.label')}
				name="name"
				rules={{
					required: Validators.isRequired(t('registration.edition.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<Button className="primary" type="submit" {...buttonProps}>
				{canEditRegistration ? t('registration.edition.action') : t('registration.edition.close')}
			</Button>
		</DynamicForm>
	);
};

RegistrationEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	registration: PropTypes.shape({
		archived: PropTypes.bool,
		code: PropTypes.string,
		name: PropTypes.string.isRequired,
		type: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	}).isRequired,
};

export default RegistrationEditionForm;
