/**
 * @function
 * @name findPositionById
 * @description Finds an element position by its ID
 *
 * @author Yann Hodiesne
 *
 * @param {array}	elements	The current elements structure
 * @param {string}	id			The identifier of the element to find
 *
 * @returns The element position, in the format { rowIndex, elementIndex }
 */
const findPositionById = (elements, id) => {
	let elementIndex = -1;

	const rowIndex = elements.findIndex((row) => {
		elementIndex = row.children.findIndex((element) => element.id === id);

		return elementIndex !== -1;
	});

	return elementIndex !== -1 ? { rowIndex, elementIndex } : null;
};

export default findPositionById;
