import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Checkbox } from 'components/shared/inputs';

/**
 * @name BooleanCapability
 * @description A boolean capability, displayed inside the editor's toolbox
 *
 * @author Yann Hodiesne
 *
 * @param {boolean}		value		The current value of the capability
 * @param {string}		label		The label translation key to display
 * @param {function}	setValue	A callback updating the value associated to this capability
 * @param {boolean}		readOnly	Whether the capability can be edited by the user
 */
const BooleanCapability = ({ value, label, setValue, readOnly, ...otherProps }) => {
	const { t } = useTranslation();
	const inputId = uuid(); // used to tie the label to the input.

	return (
		<div className="input-wrapper checkbox-wrapper">
			<Checkbox
				id={inputId}
				className="checkbox"
				checked={value}
				onChange={({ target: { checked } }) => setValue(checked)}
				disabled={readOnly}
				{...otherProps}
			/>
			<label htmlFor={inputId}>
				{t(label)}
			</label>
		</div>
	);
};

BooleanCapability.propTypes = {
	value: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

BooleanCapability.defaultProps = {
	readOnly: false,
};

export default BooleanCapability;
