import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnchor } from 'lib/hooks';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button } from '../buttons';

/**
 * @name Calendar
 * @description Calendar component to show when the DateInput component is focused
 *
 * @author Florian Fornazaric
 *
 * @param {Date}	date				The currently selected date
 * @param {boolean}	isOpen				Whether the calendar should be shown to the user
 * @param {boolean}	canStealFocus		Whether the calendar should steal focus from the DateInput
 * @param {func}	closeCallback		A callback called when the calendar should be closed (e.g. when the user clicks on the cancel button)
 * @param {func}	selectedCallback	A callback called when the user selects a date
 */
const Calendar = ({ date, isOpen, canStealFocus, closeCallback, selectedCallback }) => {
	const { t } = useTranslation();

	const [focusedDate, setFocusedDate] = useState(date ? moment(date) : moment(new Date()));
	const [selectedDate, setSelectedDate] = useState(date ? moment(date) : undefined);

	const focusedDay = useRef();
	const cancelButton = useRef();
	const monthSelect = useRef();

	const [anchor, scrollTo] = useAnchor(false, 'end');

	const shortDateList = useMemo(() => moment.weekdaysMin(true), []);
	const monthsList = useMemo(() => moment.months(), []);
	const yearsList = useMemo(() => {
		const start = moment().year() - 100;
		const end = moment().year() + 100;

		// Allows us to get the array populated with the years we need
		return [...Array(end - start + 1).keys()].map((x) => x + start);
	}, []);

	const numberOfDaysInSelectedMonth = useMemo(() => moment(focusedDate).daysInMonth(), [focusedDate]);
	const currentMonth = useMemo(() => moment(focusedDate).month(), [focusedDate]);
	const currentYear = useMemo(() => moment(focusedDate).year(), [focusedDate]);
	const indexOfFirstDayInSelectedMonth = useMemo(() => {
		// Since startOf('month') isn't aware of the locale configuration, if the startOfMonthIndex is -1 we put it at 6 (Sunday)
		let startOfMonthIndex = moment(focusedDate).startOf('month').format('d') - 1;
		if (startOfMonthIndex === -1) {
			startOfMonthIndex = 6;
		}

		return startOfMonthIndex;
	}, [focusedDate]);

	const wasOpen = useRef(isOpen);

	// Scrolls the view directly unto the component when opened and focuses the selected date
	useEffect(() => {
		if (isOpen && !wasOpen.current) {
			scrollTo();
			focusedDay.current.focus();
		}

		wasOpen.current = isOpen;
	}, [isOpen, scrollTo]);

	useEffect(() => {
		setFocusedDate(date ? moment(date) : moment(new Date()));
		setSelectedDate(date ? moment(date) : undefined);
	}, [date, t]);

	const listOfDays = useMemo(() => {
		const days = [];
		const maxNumberOfDays = 6 * 7;

		// Getting all the data about the previous month
		const lastMonthDate = moment(focusedDate).subtract(1, 'months');
		const numberOfDaysInPreviousMonth = lastMonthDate.daysInMonth();
		const lastMonth = lastMonthDate.month();
		const lastMonthYear = lastMonthDate.year();

		// Getting all necessary data about next month
		const nextMonthDate = moment(focusedDate).add(1, 'months');
		const nextMonth = nextMonthDate.month();
		const nextMonthYear = nextMonthDate.year();
		const today = new Date();

		// Adding all the days for the previous month
		for (let dayOfMonth = numberOfDaysInPreviousMonth - (indexOfFirstDayInSelectedMonth - 1); dayOfMonth <= numberOfDaysInPreviousMonth; dayOfMonth++) {
			// Allows us to have unique data for the keys of the table.
			const dayObject = {
				year: lastMonthYear,
				month: lastMonth,
				dayOfMonth,
				currentMonth: false,
				today: dayOfMonth === today.getDate() && lastMonth === today.getMonth() && lastMonthYear === today.getFullYear(),
			};
			days.push(dayObject);
		}

		// Adding all the days for the current month
		for (let dayOfMonth = 1; dayOfMonth <= numberOfDaysInSelectedMonth; dayOfMonth++) {
			// Allows us to have unique data for the keys of the table.
			const dayObject = {
				year: currentYear,
				month: currentMonth,
				dayOfMonth,
				currentMonth: true,
				focused: dayOfMonth === focusedDate.date(),
				selected: dayOfMonth === selectedDate?.date() && currentMonth === selectedDate.month() && currentYear === selectedDate.year(),
				today: dayOfMonth === today.getDate() && currentMonth === today.getMonth() && currentYear === today.getFullYear(),
			};
			days.push(dayObject);
		}

		const actualNumberOfDays = days.length;

		// Adding all the days for the next month
		for (let dayOfMonth = 1; dayOfMonth <= maxNumberOfDays - actualNumberOfDays; dayOfMonth++) {
			// Allows us to have unique data for the keys of the table.
			const dayObject = {
				year: nextMonthYear,
				month: nextMonth,
				dayOfMonth,
				currentMonth: false,
				today: dayOfMonth === today.getDate() && nextMonth === today.getMonth() && nextMonthYear === today.getFullYear(),
			};
			days.push(dayObject);
		}

		return days;
	}, [currentMonth, currentYear, focusedDate, indexOfFirstDayInSelectedMonth, numberOfDaysInSelectedMonth, selectedDate]);

	const handleSelectDate = useCallback((day) => {
		const eventDate = moment();
		eventDate.year(day.year);
		eventDate.month(day.month);
		eventDate.date(day.dayOfMonth);
		eventDate.hour(12);

		setFocusedDate(eventDate);
		setSelectedDate(eventDate);
	}, []);

	// Called when the user selects a month in the Select components
	const handleSelectMonth = useCallback((event) => {
		const tmpDate = moment(focusedDate);
		tmpDate.month(event.target.value);
		setFocusedDate(tmpDate);
	}, [focusedDate]);

	// Called when the user selects a year in the Select components
	const handleSelectYear = useCallback((event) => {
		const tmpDate = moment(focusedDate);
		tmpDate.year(event.target.value);
		setFocusedDate(tmpDate);
	}, [focusedDate]);

	// Called when the user sets a date
	const handleSetDate = useCallback(() => {
		selectedCallback(selectedDate);
	}, [selectedDate, selectedCallback]);

	// Adds or removes X number of days to the focused date
	const changeFocusByDays = useCallback((numberOfDays) => {
		const tmpDate = moment(focusedDate);
		// We change the date by X number of days
		tmpDate.add(numberOfDays, 'day');
		setFocusedDate(tmpDate);
	}, [focusedDate]);

	// Changes the focus to the start of the week
	const changeFocusToStartOfWeek = useCallback(() => {
		const tmpDate = moment(focusedDate).startOf('week');
		setFocusedDate(tmpDate);
	}, [focusedDate]);

	// Changes the focus to the end of the week
	const changeFocusToEndOfWeek = useCallback(() => {
		const tmpDate = moment(focusedDate).endOf('week');
		setFocusedDate(tmpDate);
	}, [focusedDate]);

	// Adds or removes X number of months to the focused date
	const changeFocusByMonths = useCallback((numberOfMonths) => {
		const tmpDate = moment(focusedDate);
		// We change the date by X number of month
		tmpDate.add(numberOfMonths, 'month');
		setFocusedDate(tmpDate);
	}, [focusedDate]);

	// Adds or removes X number of years to the focused date
	const changeFocusByYears = useCallback((numberOfYears) => {
		const tmpDate = moment(focusedDate);
		// We change the date by X number of month
		tmpDate.add(numberOfYears, 'year');
		setFocusedDate(tmpDate);
	}, [focusedDate]);

	// Allows us to handle every keydown of the calendar days components
	const handleDayKeyDown = useCallback((event) => {
		switch (event.key) {
			case 'ArrowRight':
				// Goes to the next day
				changeFocusByDays(1);
				break;
			case 'ArrowLeft':
				// Goes to the previous day
				changeFocusByDays(-1);
				break;
			case 'ArrowDown':
				event.preventDefault();
				// Goes to the next week
				changeFocusByDays(7);
				break;
			case 'ArrowUp':
				event.preventDefault();
				// Goes to the previous week
				changeFocusByDays(-7);
				break;
			case 'Enter':
				// Selects the current focused date
				selectedCallback(focusedDate);
				break;
			case ' ':
				event.preventDefault();
				// Selects the current focused date
				selectedCallback(focusedDate);
				break;
			case 'Home':
				event.preventDefault();
				changeFocusToStartOfWeek();
				break;
			case 'End':
				event.preventDefault();
				changeFocusToEndOfWeek();
				break;
			case 'PageUp':
				event.preventDefault();
				if (event.shiftKey) {
					// Changes the focus to last year
					changeFocusByYears(-1);
				} else {
					// Changes the focus to last month
					changeFocusByMonths(-1);
				}
				break;
			case 'PageDown':
				event.preventDefault();
				if (event.shiftKey) {
					// Changes the focus to next year
					changeFocusByYears(1);
				} else {
					// Changes the focus to next month
					changeFocusByMonths(1);
				}
				break;
			case 'Tab':
				// Allows us to focus the cancel button instead of going through each element of the calendar
				event.preventDefault();
				cancelButton.current.focus();
				break;
			case 'Escape':
				event.preventDefault();
				closeCallback();
				break;
			default:
				break;
		}
	}, [changeFocusByDays, changeFocusByMonths, changeFocusByYears, changeFocusToEndOfWeek, changeFocusToStartOfWeek, closeCallback, focusedDate, selectedCallback]);

	const handleSetDateButtonKeyDown = useCallback((event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			monthSelect.current.focus();
		}
	}, []);

	const handleYearSelectKeyDown = useCallback((event) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			focusedDay.current.focus();
		}
	}, []);

	const handleBackgroundClick = useCallback((e) => {
		if (canStealFocus && isOpen) {
			if (e.stopPropagation) {
				e.stopPropagation(); // W3C model
			} else {
				e.cancelBubble = true; // IE model
			}
			focusedDay.current.focus();
		}
	}, [canStealFocus, isOpen]);

	const stopPropagation = useCallback((e) => {
		e.stopPropagation();
	}, []);

	useEffect(() => {
		if (canStealFocus && isOpen) {
			focusedDay.current.focus();
		}
	}, [canStealFocus, focusedDate, isOpen]);

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div className={`calendar ${isOpen ? 'open' : ''}`} onClick={handleBackgroundClick}>
			<div className="calendar-header">
				<select ref={monthSelect} data-testid="calendar-month-select" value={focusedDate.month()} onChange={handleSelectMonth} onClick={stopPropagation}>
					{monthsList.map((month, index) => (
						<option className="calender-option" value={index} key={month}>{month}</option>
					))}
				</select>
				<select value={focusedDate.year()} data-testid="calendar-year-select" onKeyDown={handleYearSelectKeyDown} onChange={handleSelectYear} onClick={stopPropagation}>
					{yearsList.map((year) => (
						<option className="calender-option" key={year}>{year}</option>
					))}
				</select>
			</div>
			<div data-testid="calendar-wrapper" className="calendar-wrapper">
				{shortDateList.map((weekdayShort) => <p key={weekdayShort} className="calendar-head">{weekdayShort}</p>)}
				{listOfDays.map((day) => (
					<button
						ref={day.focused ? focusedDay : undefined}
						key={`${day.month} ${day.dayOfMonth}`}
						data-testid={day.focused ? 'calendar-focused-day' : undefined}
						data-day={day.dayOfMonth}
						data-month={day.month}
						data-year={day.year}
						className={
							`calendar-day${day.currentMonth ? '' : ' other-month'}${day.focused ? ' focused-day' : ''}`
						}
						type="button"
						onKeyDown={handleDayKeyDown}
						aria-pressed={day.selected || undefined}
						aria-current={day.today ? 'date' : undefined}
						onClick={() => handleSelectDate(day)}
					>
						{day.dayOfMonth}
					</button>
				))}
			</div>
			<div className="calendar-footer">
				<Button ref={cancelButton} className="subtle" onClick={closeCallback}>{t('components.date_picker.cancel')}</Button>
				<Button className="primary" onKeyDown={handleSetDateButtonKeyDown} onClick={handleSetDate}>{t('components.date_picker.set_date')}</Button>
				{anchor}
			</div>
		</div>
	);
};

Calendar.displayName = 'Calendar';

Calendar.propTypes = {
	closeCallback: PropTypes.func.isRequired,
	date: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(Date),
	]),
	isOpen: PropTypes.bool.isRequired,
	selectedCallback: PropTypes.func.isRequired,
	canStealFocus: PropTypes.bool.isRequired,
};

Calendar.defaultProps = {
	date: undefined,
};

export default memo(Calendar);
