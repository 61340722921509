import insertRow from './insertRow';

/**
 * @function
 * @name appendRow
 * @description Appends a new empty row at the end of the current state
 *
 * @author Yann Hodiesne
 *
 * @param {object}	state				The current state
 * @param {array}	payload.elements	The elements used to populate the new row
 *
 * @returns {object} The updated state value
 */
const appendRow = (state, { elements }) => insertRow(state, {
	index: state.elements.length - state.footer.numberOfElements,
	elements,
});

export default appendRow;
