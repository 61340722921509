import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name usePartnersListWithTargetsLoadingSelector
 * @description A selector to retrieve the loading state from the partnersList redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {boolean}
 */
const usePartnersListWithTargetsLoadingSelector = () => {
	const loading = useSelector((state) => state.partnersListWithTargets?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name usePartnersForListWithTargetsSelector
 * @description A selector to retrieve the partnersList list from the partner redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {Array}
 */
const usePartnersForListWithTargetsSelector = () => {
	const partnersList = useSelector((state) => (state.partnersListWithTargets?.items ?? []), shallowEqual);

	return partnersList;
};

/**
 * @function
 * @name usePartnersListWithTargetsTotalCountSelector
 * @description A selector to retrieve the totalCount value from the partnersList redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {number|undefined}
 */
const usePartnersListWithTargetsTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.partnersListWithTargets?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	usePartnersForListWithTargetsSelector,
	usePartnersListWithTargetsLoadingSelector,
	usePartnersListWithTargetsTotalCountSelector,
};
