import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { addProject } from 'redux/actions/projects/projects';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ProjectCreationForm } from 'components/projects';

/**
 * @name ProjectCreationPage
 * @description A page used to create a new project.
 *
 * @author Marin Catel-Guihomat
 *
 */
const ProjectCreationPage = () => {
	const dispatch = useDispatch();

	/**
     * @function
     * @name handleFormSubmit
     * @description Formats the submitted form data in an object that can be understood by the API.
     *
     * @author Marin Catel-Guihomat
     *
     * @param {object} formData	The date from the submitted form.
     */
	const handleFormSubmit = useCallback(async (formData) => {
		const newProject = await dispatch(addProject(formData));

		if (newProject) {
			history.push(generatePath(routes.operations.projects.projectEdition, { id: newProject.id }));
		}
	}, [dispatch]);

	return (
		<ProjectCreationForm onSubmit={handleFormSubmit} />
	);
};

export default ProjectCreationPage;
