import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import { paymentDelayScales, paymentDelayTerms } from './functions';

/**
 * @name PaymentDelayEditionForm
 * @description A form used to edit an existing payment delay's information.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object}	paymentDelay	The payment delay object to update information from.
 * @param {func}	onSubmit		The method to trigger upon form submission.
 */
const PaymentDelayEditionForm = ({ paymentDelay, onSubmit }) => {
	const { t } = useTranslation();

	const canEditPaymentDelay = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING) && !paymentDelay?.archived;

	const scales = useMemo(() => paymentDelayScales(t, 'payment_delay.edition.inputs.scale.options'), [t]);
	const terms = useMemo(() => paymentDelayTerms(t, 'payment_delay.edition.inputs.term.options'), [t]);

	const { formProps, buttonProps } = useSubmitButton();

	const handleSubmit = useCallback((formData) => {
		const { favorite, ...submitData } = formData; // in order not to send favorite in data because otherwise it will update the favorite field
		onSubmit(submitData);
	}, [onSubmit]);

	return (
		<DynamicForm onSubmit={handleSubmit} defaultValues={paymentDelay} disabled={!canEditPaymentDelay} {...formProps}>
			<TextInput
				name="number"
				label={t('payment_delay.edition.inputs.number.label')}
				type="number"
				placeholder={t('payment_delay.creation.inputs.number.placeholder')}
				rules={{
					required: Validators.isRequired(t('payment_delay.edition.inputs.number.validation_errors.required')),
				}}
			/>

			<Select
				label={t('payment_delay.edition.inputs.scale.label')}
				name="scale"
				options={scales}
				labelKey="label"
				valueKey="value"
				rules={{
					required: Validators.isRequired(t('payment_delay.edition.inputs.scale.validation_errors.required')),
				}}
			/>

			<Select
				label={t('payment_delay.edition.inputs.term.label')}
				name="term"
				options={terms}
				labelKey="label"
				valueKey="value"
				rules={{
					required: Validators.isRequired(t('payment_delay.edition.inputs.term.validation_errors.required')),
				}}
			/>

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditPaymentDelay ? t('payment_delay.edition.action') : t('payment_delay.edition.close')}
			</Button>
		</DynamicForm>
	);
};

PaymentDelayEditionForm.propTypes = {
	paymentDelay: PropTypes.shape({
		archived: PropTypes.bool,
		number: PropTypes.number.isRequired,
		scale: PropTypes.string.isRequired,
		term: PropTypes.string.isRequired,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default PaymentDelayEditionForm;
