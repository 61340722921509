import { useCallback } from 'react';
import { InvoiceCreationTypes } from 'constants/invoiceEnums';
import _ from 'lodash';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { InvoicePreCreationForm } from 'components/invoices';

/**
 * @name InvoicePreCreationPage
 * @description A page used to create a new invoice.
 *
 * @author Romaric Barthe
 *
 */
const InvoicePreCreationPage = () => {
	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		const { creationType } = formData;
		const sourceInvoiceId = (formData.sourceInvoiceId && !_.isEmpty(formData.sourceInvoiceId)) ? formData.sourceInvoiceId : undefined;
		const sourceQuotationId = (formData.sourceQuotationId && !_.isEmpty(formData.sourceQuotationId)) ? formData.sourceQuotationId : undefined;

		if ((creationType === InvoiceCreationTypes.FROM_INVOICE || creationType === InvoiceCreationTypes.REVERT) && !sourceInvoiceId) {
			return;
		}
		if (creationType === InvoiceCreationTypes.FROM_QUOTATION && !sourceQuotationId) {
			return;
		}

		history.push({
			pathname: routes.accounting.invoices.invoiceCreation,
			state: { creationType, sourceInvoiceId, sourceQuotationId },
		});
	}, []);

	return (
		<InvoicePreCreationForm onSubmit={handleFormSubmit} />
	);
};

export default InvoicePreCreationPage;
