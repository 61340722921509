import { useCallback, useMemo, useState } from 'react';
import { ChevronDown, Copy, MoreVertical, Trash } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { filterCriteriaTypeEnum, filterOperatorEnum } from 'constants/filterEnums';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

import styles from 'styles/components/dataTables/_filterPanel.module.scss';

/**
 * @name DataFilterFormFieldset
 * @description A component used to specify a filter rule.
 *
 * @author Timothée Simon-Franza
 *
 * @param {Object}		filterRule						The filter rule handled by the current component.
 * @param {bool}		filterRule.canOperatorBeChanged	Whether or not the operator of the current filter rule can be changed.
 * @param {string}		filterRule.column				The accessor of the column being filtered on.
 * @param {string}		filterRule.criterionType		The criterion type used to filter the column.
 * @param {string}		filterRule.operator				The operator used to filter the column.
 * @param {string}		filterRule.value				The value used to filter the column.
 *
 * @param {Object[]}	columns							The list of columns available for filter.
 * @param {func}		deleteFilterRule				The method used to delete the current filter rule.
 * @param {func}		duplicateFilterRule				The method used to duplicate the current filter rule.
 * @param {string}		filterKey						The key of the current filter rule.
 * @param {func}		onFilterRuleChange				The method used to update the current filter rule.
 */
const DataFilterFormFieldset = ({
	columns,
	deleteFilterRule,
	duplicateFilterRule,
	filterKey,
	filterRule,
	onFilterRuleChange,
}) => {
	const { t } = useTranslation();
	const [isMenuToggled, setIsMenuToggled] = useState(false);

	const onMenuButtonClick = useCallback(() => {
		setIsMenuToggled((previousState) => !previousState);
	}, []);

	/**
	 * @function
	 * @name onInputChange
	 * @description Method triggered anytime a filter field's value changes.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @param {Object} event				The event object.
	 * @param {Object} event.target			The event target object.
	 * @param {string} event.target.name	The name of the input that triggered the event.
	 * @param {string} event.target.value	The value of the input that triggered the event.
	 */
	const onInputChange = useCallback(({ target: { name, value } }) => {
		onFilterRuleChange(filterKey, name, value);
	}, [filterKey, onFilterRuleChange]);

	/**
	 * @name operatorSelectOptions
	 * @type {Array}
	 * @description An array containing the different operator select options.
	 */
	const operatorSelectOptions = useMemo(() => (
		Object.values(filterOperatorEnum)
			.filter((operator) => operator !== filterOperatorEnum.IF) // The IF operator is only used for the first filter rule.
			.map((operator) => (
				<option key={operator} value={operator}>{t(`components.table.filters.operators.${operator}`)}</option>
			))
	), [t]);

	/**
	 * @name columnSelectOptions
	 * @type {Array}
	 * @description An array containing the different column select options.
	 */
	const columnSelectOptions = useMemo(() => (
		columns.map(({ accessor, label }) => (<option key={accessor} value={accessor}>{label}</option>))
	), [columns]);

	/**
	 * @name criterionTypeSelectOptions
	 * @type {Array}
	 * @description An array containing the different criterion type select options.
	 */
	const criterionTypeSelectOptions = useMemo(() => (
		Object.values(filterCriteriaTypeEnum).map((type) => (
			<option key={type} value={type}>{t(`components.table.filters.criterion_type.${type}`)}</option>
		))
	), [t]);

	/**
	 * @function
	 * @name onDuplicateFilterRuleButtonClick
	 * @description Method triggered when the duplicate menu button is clicked.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onDuplicateFilterRuleButtonClick = useCallback(() => {
		duplicateFilterRule(filterKey);
		setIsMenuToggled(false);
	}, [duplicateFilterRule, filterKey]);

	/**
	 * @function
	 * @name onDeleteFilterRuleButtonClick
	 * @description Method triggered when the delete menu button is clicked.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onDeleteFilterRuleButtonClick = useCallback(() => {
		deleteFilterRule(filterKey);
		setIsMenuToggled(false);
	}, [deleteFilterRule, filterKey]);

	return (
		<fieldset name={filterKey} className={styles['filter-form-fielset']}>
			{!filterRule.canOperatorBeChanged && (
				<>
					<input type="hidden" name="operator" value={filterRule.operator} />
					<p>{t(`components.table.filters.operators.${filterRule.operator}`)}</p>
				</>
			)}
			{filterRule.canOperatorBeChanged && (
				<div className={styles['select-input-group']}>
					<select name="operator" onChange={onInputChange} value={filterRule.operator}>
						{operatorSelectOptions}
					</select>
					<ChevronDown role="presentation" />
				</div>
			)}

			<div className={styles['select-input-group']}>
				<select name="column" onChange={onInputChange} value={filterRule.column}>
					{columnSelectOptions}
				</select>
				<ChevronDown role="presentation" />
			</div>

			<div className={styles['select-input-group']}>
				<select name="criterionType" onChange={onInputChange} value={filterRule.criterionType}>
					{criterionTypeSelectOptions}
				</select>
				<ChevronDown role="presentation" />
			</div>

			<input
				type="text"
				name="value"
				onChange={onInputChange}
				value={filterRule.value}
				placeholder={t('components.table.filters.value_input_placeholder')}
				disabled={[filterCriteriaTypeEnum.EMPTY, filterCriteriaTypeEnum.NOT_EMPTY].includes(filterRule.criterionType)}
			/>
			<Button
				id={`${filterKey}-menu-button`}
				aria-controls={`${filterKey}-menu`}
				className="icon-only"
				onClick={onMenuButtonClick}
			>
				<MoreVertical />
			</Button>
			<ul
				role="menu"
				id={`${filterKey}-menu`}
				aria-labelledby={`${filterKey}-menu-button`}
				aria-hidden={!isMenuToggled}
			>
				<li role="presentation">
					<Button onClick={onDuplicateFilterRuleButtonClick}>
						<Trash />
						{t('components.table.filters.actions.duplicate')}
					</Button>
				</li>
				<li role="presentation">
					<Button onClick={onDeleteFilterRuleButtonClick}>
						<Copy />
						{t('components.table.filters.actions.delete')}
					</Button>
				</li>
			</ul>
		</fieldset>
	);
};

DataFilterFormFieldset.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape({
		accessor: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	})).isRequired,
	deleteFilterRule: PropTypes.func.isRequired,
	duplicateFilterRule: PropTypes.func.isRequired,
	filterKey: PropTypes.string.isRequired,
	filterRule: PropTypes.shape({
		canOperatorBeChanged: PropTypes.bool.isRequired,
		column: PropTypes.string.isRequired,
		criterionType: PropTypes.string.isRequired,
		operator: PropTypes.oneOf(Object.values(filterOperatorEnum)).isRequired,
		value: PropTypes.string,
	}).isRequired,
	onFilterRuleChange: PropTypes.func.isRequired,
};

export default DataFilterFormFieldset;
