/* eslint-disable no-console */
import { useCallback } from 'react';
import _ from 'lodash';

import EntityTypes from '../constants/EntityTypes';
import { ActionTypes } from '../reducer/actions';

// Store the last result to warn when the state has recovered
const lastResult = {
	current: true,
	lastMovedId: null,
};

export default (reducer) => useCallback((state, action) => {
	const result = reducer(state, action);

	const { elements, entities } = result;

	let group = false;

	/**
	 */
	const ensureGroup = () => {
		if (!group) {
			console.groupCollapsed(`%cEditor state is corrupt ! %cLast action : %c${action.type}`, 'color: red;', 'color: inherit;', 'color: #BF5085; font-weight: normal;');
			console.info('Corruption detected after : ', action);
			console.info('Previous state : ', state);
			console.info('New state : ', result);
			group = true;
		}
	};

	// Store the last moved element ID to check if it is still there
	if (action.type === ActionTypes.MOVE_ELEMENT_BY_ID) {
		lastResult.lastMovedId = action.payload?.sourceId ?? lastResult.lastMovedId;
	}

	// Check if the last moved element is still inside the editor
	if (lastResult.lastMovedId !== null && _.findIndex(elements, (element) => _.findIndex(element.children, (child) => child.id === lastResult.lastMovedId) !== -1) === -1) {
		ensureGroup();
		console.log('Element with id ', lastResult.lastMovedId, ' has disappeared from the elements list');
		lastResult.lastMovedId = null;
	}

	// Check if the entity links are consistent enough to be used
	elements.forEach((row) => {
		row.children.forEach((element) => {
			if (_.has(element, 'linkedEntity') && element.linkedEntity !== null) {
				const entity = entities.find(({ id }) => id === element.linkedEntity);

				// Check if the entity exists
				if (!entity) {
					ensureGroup();
					console.log('Element with id ', element.id, ' is referencing an unknown entity : ', element.linkedEntity);

					return;
				}

				const entityType = Object.values(EntityTypes).find((type) => type.type === entity.type);

				// Check if the linked entity and the linked property are compatible
				if (element.linkedProperty !== null && !_.some(entityType.fields, (field) => field.id === element.linkedProperty)) {
					ensureGroup();
					console.log('Element with id ', element.id, ' is referencing a property incompatible with its linked entity');
				}
			}
		});
	});

	if (group) {
		console.groupEnd();
		lastResult.current = false;
	} else {
		// If all checks were successfull but the last checks were not, warn about the state's recovery and which action recovered it
		if (!lastResult.current) {
			console.groupCollapsed(`%cEditor state is clean again. %cLast action : %c${action.type}`, 'color: green;', 'color: inherit;', 'color: #BF5085; font-weight: normal;');
			console.info('Helpful action : ', action);
			console.info('Previous state : ', state);
			console.info('New state : ', result);
			console.groupEnd();
		}

		lastResult.current = true;
	}

	return result;
}, [reducer]);
