import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchNumberFormats
 * @description Retrieves a list of number formats from the API, specifying filters if present.
 *
 * @author Audrey Clerc
 *
 * @param {object} params The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchNumberFormats = (params) => post('/api/number-formats', params);

/**
 * @function
 * @name fetchNumberFormatById
 * @description Retrieves an existing number format from the API, using the numberFormatId in parameters.
 *
 * @author Audrey Clerc
 *
 * @param {string} numberFormatId The id of the number format we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchNumberFormatById = (numberFormatId) => get(`/api/number-format/${numberFormatId}`);

/**
 * @function
 * @name createNumberFormat
 * @description Creates a new number format into the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} numberFormatData The data to create the number format from.
 *
 * @returns {Promise}
 */
export const createNumberFormat = (numberFormatData) => post('/api/number-formats/create', numberFormatData);

/**
 * @function
 * @name updateNumberFormat
 * @description Updates an existing number format from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} numberFormatData   The data to update the number format with.
 * @param {string} numberFormatId     The id to identify the number format with.
 *
 * @returns {Promise}
 */
export const updateNumberFormat = (numberFormatData, numberFormatId) => put(`/api/number-format/${numberFormatId}`, numberFormatData);

/**
 * @function
 * @name deleteNumberFormat
 * @description Removes an existing number format from the database.
 *
 * @author Audrey Clerc
 *
 * @param {string} numberFormatId The id of the number format we want to remove.
 *
 * @returns {Promise}
 */
export const deleteNumberFormat = (numberFormatId) => del(`/api/number-format/${numberFormatId}`);
