import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Select } from 'components/shared/inputs';

import EditorContext from '../../../EditorContext';
import { updateHeader } from '../../../reducer/actions';

/**
 * @name ExcludeHeaderFromPages
 * @description Component to exclude the header from selected pages of the template
 *
 * @author Florian Fornazaric
 */
const ExcludeHeaderFromPages = () => {
	const { t } = useTranslation();
	const { header, pages, dispatch } = useContext(EditorContext);

	const [selectedValues, setSelectedValues] = useState(header.excludeFromPages.map((pageId) => (
		{
			label: `Page ${pages.indexOf(pages.find((p) => p.id === pageId)) + 1}`,
			value: pageId,
		}
	)));

	const options = useMemo(() => pages.map((p, pageIndex) => ({ label: `Page ${pageIndex + 1}`, value: p.id })), [pages]);

	const handleSelectChange = useCallback((value) => {
		dispatch(updateHeader({ excludeFromPages: value.map((v) => v.value) }));
	}, [dispatch]);

	const handleDisabledChange = useCallback(() => {
		dispatch(updateHeader({ disabled: !header.disabled }));
	}, [dispatch, header.disabled]);

	// Allows us to update the selected values when the pages change
	useEffect(() => {
		setSelectedValues(header.excludeFromPages.map((pageId) => (
			{
				label: `Page ${pages.indexOf(pages.find((p) => p.id === pageId)) + 1}`,
				value: pageId,
			}
		)));
	}, [header.excludeFromPages, pages]);

	// Allows us to remove from the excludeFromPages list pages that have been deleted
	useEffect(() => {
		header.excludeFromPages.forEach((pageId) => {
			if (!pages.some((p) => p.id === pageId)) {
				dispatch(updateHeader({ excludeFromPages: header.excludeFromPages.filter((pId) => pId !== pageId) }));
			}
		});
	}, [dispatch, header.excludeFromPages, pages, pages.length]);

	return (
		<div className="tab-options-list">
			<div className="input-wrapper checkbox-wrapper">
				<Checkbox id="remove-header-from-all" checked={header.disabled} onChange={handleDisabledChange} className="checkbox" />
				<label htmlFor="remove-header-from-all">
					{t('template.pdf.format.header_disabled')}
				</label>
			</div>

			<div>
				<label htmlFor="remove-header-from-pages">
					{t('template.pdf.format.header_exclude_from_pages')}
				</label>
				<Select id="remove-header-from-pages" isMulti options={options} value={selectedValues} onChange={handleSelectChange} isDisabled={header.disabled} />
			</div>
		</div>
	);
};

export default ExcludeHeaderFromPages;
