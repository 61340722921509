import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import routes from 'routes/keys';

import Header from './header';
import { Navbar } from './navigation';

/**
 * @name MainLayout
 * @description The main layout to use for every page in the application.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {*} children The elements to be displayed in the page.
 */
const MainLayout = ({ children }) => {
	const { pathname } = useLocation();
	const isAuthenticationPage = [...Object.values(routes.auth), routes.user.definePassword].includes(pathname);
	const [isMenuOpen, setIsMenuOpen] = useState(true);
	const closeOrOpenMenu = useCallback(() => {
		setIsMenuOpen((prev) => !prev);
	}, [setIsMenuOpen]);

	return (
		<>
			{!isAuthenticationPage && (<Header closeOrOpenMenu={closeOrOpenMenu} isOpen={isMenuOpen} />)}
			{!isAuthenticationPage && isMenuOpen && (<Navbar />)}
			<main className={isAuthenticationPage ? 'authentication-page' : ''}>
				{children}
			</main>
			<ToastContainer />
		</>
	);
};

MainLayout.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.node,
	]).isRequired,
};

export default MainLayout;
