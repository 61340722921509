import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MoreVertical } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { Dropdown } from 'components/shared/dropdown';
import { Checkbox, Select, TextInput } from 'components/shared/inputs';

import EntityTypes, { IssuableEntityTypes } from '../../../../constants/EntityTypes';
import EditorContext from '../../../../EditorContext';
import { removeEntity, updateEntity } from '../../../../reducer/actions';

/**
 * @name EntitiesRow
 * @description Row displaying information about a single entity
 *
 * @author Yann Hodiesne
 *
 * @param {object}	entity			The entity to display inside the row.
 * @param {func} 	onSelect		Function to call when the row wants to be selected.
 * @param {func} 	onUnselect		Function to call when the row wants to be unselected.
 * @param {bool} 	[expand=false]	Whether this row is expanded to show details to the user.
 */
const EntityRow = ({ entity, onSelect, onUnselect, expand }) => {
	const { dispatch } = useContext(EditorContext);
	const { t } = useTranslation();

	// Find the current entity type
	const currentEntityType = useMemo(() => Object.values(EntityTypes).find(({ type }) => type === entity.type), [entity.type]);

	// Handle the dropdown toggle
	const [showDropdown, setShowDropdown] = useState(false);

	const toggleDropdown = useCallback(() => {
		setShowDropdown((value) => !value);
	}, []);

	const closeDropdown = useCallback(() => {
		setShowDropdown(false);
	}, []);

	// Requests the selection of the current entity
	const requestSelection = useCallback(() => {
		closeDropdown();
		onSelect(entity.id);
	}, [closeDropdown, entity.id, onSelect]);

	// Prepare the entity types for the Select component
	const entityTypes = useMemo(() => Object.values(EntityTypes).map(({ type, name }) => ({ label: t(name), value: type })), [t]);

	// Handle the entity updates
	const [entityName, setEntityName] = useState(entity.name);
	const [entityType, setEntityType] = useState(entityTypes.find((type) => type.value === entity.type));
	const [shouldIssue, setShouldIssue] = useState(entity.shouldIssue ?? false);

	useEffect(() => {
		setEntityName(entity.name);
		setEntityType(entityTypes.find((type) => type.value === entity.type));
		setShouldIssue(entity.shouldIssue ?? false);
	}, [entity.name, entity.shouldIssue, entity.type, entityTypes]);

	const saveEntity = useCallback(() => {
		dispatch(updateEntity(entity.id, {
			name: entityName,
			type: entityType.value,
			shouldIssue,
		}));

		onSelect();
	}, [dispatch, entity.id, entityName, entityType.value, onSelect, shouldIssue]);

	const highlightEntity = useCallback(() => {}, []);

	const dispatchRemoveEntity = useCallback(() => {
		dispatch(removeEntity(entity.id));
	}, [dispatch, entity.id]);

	const handleNameChange = useCallback(({ target: { value } }) => {
		setEntityName(value);
	}, []);

	const handleTypeChange = useCallback((value) => {
		setEntityType(value);
	}, []);

	const handleShouldIssueChange = useCallback(({ target: { checked } }) => {
		setShouldIssue(checked);
	}, []);

	const nameRef = useRef();

	useEffect(() => {
		if (expand) {
			nameRef.current.focus();
		}
	}, [expand]);

	return (
		<div className={`entity-row${expand ? ' expanded' : ''}`}>
			<div className="icon">
				<currentEntityType.icon />
			</div>
			<div className="info">
				<p className="title">{entity.name}</p>
				<div className="issued-indicator">
					{entity.shouldIssue && (
						<>
							<ReactTooltip id="entity" place="bottom" type="dark" effect="solid" />
							<span data-tooltip-id="entity" data-tooltip-content={t(`template.pdf.entities.issue.${entityType.value.toLowerCase()}`)}>
								{t('template.pdf.entities.issued')}
							</span>
						</>
					)}
				</div>
				<p className="description">{t(currentEntityType.name)}</p>
			</div>
			{!expand && (
				<div className="options">
					<Button className="options" onClick={toggleDropdown}>
						<MoreVertical />
					</Button>
					<Dropdown isOpen={showDropdown} handleClose={closeDropdown}>
						<Button className="subtle" onClick={requestSelection}>
							{t('template.pdf.entities.menu_options.edit')}
						</Button>
						<Button className="subtle" onClick={highlightEntity}>
							{t('template.pdf.entities.menu_options.highlight')}
						</Button>
						<Button className="subtle" onClick={dispatchRemoveEntity}>
							{t('template.pdf.entities.menu_options.delete')}
						</Button>
					</Dropdown>
				</div>
			)}
			{expand && (
				<div className="entity-form">
					<div className="input-wrapper">
						<label htmlFor={`${entity.id}-name`}>{t('template.pdf.entities.form.inputs.name')}</label>
						<TextInput ref={nameRef} id={`${entity.id}-name`} value={entityName} onChange={handleNameChange} />
					</div>
					<div className="input-wrapper">
						<label htmlFor={`${entity.id}-type`}>{t('template.pdf.entities.form.inputs.type')}</label>
						<Select inputId={`${entity.id}-type`} openMenuOnFocus options={entityTypes} value={entityType} onChange={handleTypeChange} />
					</div>
					{IssuableEntityTypes.includes(entityType.value) && (
						<div className="entity-issue">
							<Checkbox
								id={`${entity.id}-issue`}
								checked={shouldIssue}
								onChange={handleShouldIssueChange}
							/>
							<label htmlFor={`${entity.id}-issue`}>
								{t(`template.pdf.entities.issue.${entityType.value.toLowerCase()}`)}
							</label>
						</div>
					)}
					<div className="actions">
						<Button className="primary" onClick={saveEntity}>{t('template.pdf.entities.form.actions.save')}</Button>
						<Button className="secondary" onClick={onUnselect}>{t('template.pdf.entities.form.actions.cancel')}</Button>
					</div>
				</div>
			)}
		</div>
	);
};

EntityRow.propTypes = {
	entity: PropTypes.shape({
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		shouldIssue: PropTypes.bool,
	}).isRequired,
	onSelect: PropTypes.func.isRequired,
	onUnselect: PropTypes.func.isRequired,
	expand: PropTypes.bool,
};

EntityRow.defaultProps = {
	expand: false,
};

export default EntityRow;
