import { toast } from 'react-toastify';
import * as OffersApi from 'api/offersApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Offers redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific offer
	FETCH_OFFER_REQUEST: '@OFFERS/FETCH_REQUEST',
	FETCH_OFFER_SUCCESS: '@OFFERS/FETCH_SUCCESS',
	FETCH_OFFER_FAILURE: '@OFFERS/FETCH_FAILURE',

	// Fetch a list of offers
	FETCH_OFFER_LIST_REQUEST: '@OFFERS/FETCH_LIST_REQUEST',
	FETCH_OFFER_LIST_SUCCESS: '@OFFERS/FETCH_LIST_SUCCESS',
	FETCH_OFFER_LIST_FAILURE: '@OFFERS/FETCH_LIST_FAILURE',

	// Add a new offer to the database
	ADD_OFFER_REQUEST: '@OFFERS/ADD_REQUEST',
	ADD_OFFER_SUCCESS: '@OFFERS/ADD_SUCCESS',
	ADD_OFFER_FAILURE: '@OFFERS/ADD_FAILURE',

	// Update an existing offer from the database
	UPDATE_OFFER_REQUEST: '@OFFERS/UPDATE_REQUEST',
	UPDATE_OFFER_SUCCESS: '@OFFERS/UPDATE_SUCCESS',
	UPDATE_OFFER_FAILURE: '@OFFERS/UPDATE_FAILURE',

	// Remove an existing offer from the database
	REMOVE_OFFER_REQUEST: '@OFFERS/REMOVE_REQUEST',
	REMOVE_OFFER_SUCCESS: '@OFFERS/REMOVE_SUCCESS',
	REMOVE_OFFER_FAILURE: '@OFFERS/REMOVE_FAILURE',

	// Archive an existing offer from the database
	ARCHIVE_OFFER_REQUEST: '@OFFERS/ARCHIVE_REQUEST',
	ARCHIVE_OFFER_SUCCESS: '@OFFERS/ARCHIVE_SUCCESS',
	ARCHIVE_OFFER_FAILURE: '@OFFERS/ARCHIVE_FAILURE',

	// Restore an existing offer from the database
	RESTORE_OFFER_REQUEST: '@OFFERS/RESTORE_REQUEST',
	RESTORE_OFFER_SUCCESS: '@OFFERS/RESTORE_SUCCESS',
	RESTORE_OFFER_FAILURE: '@OFFERS/RESTORE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Offer list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchOfferListRequest
 * @description Action triggered anytime a offer list fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchOfferListRequest = () => ({ type: ActionTypes.FETCH_OFFER_LIST_REQUEST });

/**
 * @function
 * @name fetchOfferListSuccess
 * @description Action triggered as a result to a successful offer list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} offers		The list of retrieved offers.
 * @param {number} totalCount	The total amount of offers available in the database for the current user.
 *
 * @returns {object}
 */
const fetchOfferListSuccess = ({ offers, totalCount }) => ({
	type: ActionTypes.FETCH_OFFER_LIST_SUCCESS,
	payload: { offers, totalCount },
});

/**
 * @function
 * @name fetchOfferListFailure
 * @description Action triggered as a result to a failed offer list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchOfferListFailure = (error) => ({
	type: ActionTypes.FETCH_OFFER_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific offer fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchOfferRequest
 * @description Action triggered anytime a offer fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchOfferRequest = () => ({ type: ActionTypes.FETCH_OFFER_REQUEST });

/**
 * @function
 * @name fetchOfferSuccess
 * @description Action triggered as a result to a successful offer fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} offer	The retrieved offer.
 *
 * @returns {object}
 */
const fetchOfferSuccess = ({ offer }) => ({
	type: ActionTypes.FETCH_OFFER_SUCCESS,
	payload: { offer },
});

/**
 * @function
 * @name fetchOfferFailure
 * @description Action triggered as a result to a failed offer fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchOfferFailure = (error) => ({
	type: ActionTypes.FETCH_OFFER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Offer creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addOfferRequest
 * @description Action triggered anytime a offer creation call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const addOfferRequest = () => ({ type: ActionTypes.ADD_OFFER_REQUEST });

/**
 * @function
 * @name addOfferSuccess
 * @description Action triggered as a result to a successful offer creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} offer	The created offer object.
 *
 * @returns {object}
 */
const addOfferSuccess = ({ offer }) => ({
	type: ActionTypes.ADD_OFFER_SUCCESS,
	payload: { offer },
});

/**
 * @function
 * @name addOfferFailure
 * @description Action triggered as a result to a failed offer creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The error sent back from the API.
 *
 * @returns {object}
 */
const addOfferFailure = (error) => ({
	type: ActionTypes.ADD_OFFER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Offer update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateOfferRequest
 * @description Action triggered anytime a offer update call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const updateOfferRequest = () => ({ type: ActionTypes.UPDATE_OFFER_REQUEST });

/**
 * @function
 * @name updateOfferSuccess
 * @description Action triggered as a result to a successful offer update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} offer	The updated offer object.
 *
 * @returns {object}
 */
const updateOfferSuccess = ({ offer }) => ({
	type: ActionTypes.UPDATE_OFFER_SUCCESS,
	payload: { offer },
});

/**
 * @function
 * @name updateOfferFailure
 * @description Action triggered as a result to a failed offer update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The error sent back from the API.
 *
 * @returns {object}
 */
const updateOfferFailure = (error) => ({
	type: ActionTypes.UPDATE_OFFER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Offer removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeOfferRequest
 * @description Action triggered anytime a offer deletion call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const removeOfferRequest = () => ({ type: ActionTypes.REMOVE_OFFER_REQUEST });

/**
 * @function
 * @name removeOfferSuccess
 * @description Action triggered as a result to a successful offer deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {string} deletedOfferId	The removed offer id.
 *
 * @returns {object}
 */
const removeOfferSuccess = ({ deletedOfferId }) => ({
	type: ActionTypes.REMOVE_OFFER_SUCCESS,
	payload: { deletedOfferId },
});

/**
 * @function
 * @name removeOfferFailure
 * @description Action triggered as a result to a failed offer deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeOfferFailure = (error) => ({
	type: ActionTypes.REMOVE_OFFER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Offer archiving actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveOfferRequest
 * @description Action triggered anytime an offer archiving call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archiveOfferRequest = () => ({ type: ActionTypes.ARCHIVE_OFFER_REQUEST });

/**
 * @function
 * @name archiveOfferSuccess
 * @description	Action triggered as a result to a successful offer archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archiveOfferSuccess = () => ({
	type: ActionTypes.ARCHIVE_OFFER_SUCCESS,
});

/**
 * @function
 * @name archiveOfferFailure
 * @description Action triggered as a result to a failed offer archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveOfferFailure = (error) => ({
	type: ActionTypes.ARCHIVE_OFFER_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// ///////////////// Offer restoring actions //////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreOfferRequest
 * @description Action triggered anytime an offer restoring call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restoreOfferRequest = () => ({ type: ActionTypes.RESTORE_OFFER_REQUEST });

/**
 * @function
 * @name restoreOfferSuccess
 * @description	Action triggered as a result to a successful offer restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restoreOfferSuccess = () => ({
	type: ActionTypes.RESTORE_OFFER_SUCCESS,
});

/**
 * @function
 * @name restoreOfferFailure
 * @description Action triggered as a result to a failed offer restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreOfferFailure = (error) => ({
	type: ActionTypes.RESTORE_OFFER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchOfferList
 * @description Method used to update the offer list.
 *
 * @author Audrey Clerc
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchOfferList = (params) => (dispatch) => {
	dispatch(fetchOfferListRequest());

	return OffersApi.fetchOffers(params)
		.then(({ offers, totalCount }) => dispatch(fetchOfferListSuccess({ offers, totalCount })))
		.catch((error) => dispatch(fetchOfferListFailure(error)));
};

/**
 * @function
 * @name fetchOffer
 * @description Method used to fetch the latest version of a specific offer.
 *
 * @author Audrey Clerc
 *
 * @param {string} offerId	The id of the offer we want to retrieve.
 */
export const fetchOffer = (offerId) => (dispatch) => {
	dispatch(fetchOfferRequest());

	return OffersApi.fetchOfferById(offerId)
		.then(({ offer }) => dispatch(fetchOfferSuccess({ offer })))
		.catch((error) => dispatch(fetchOfferFailure(error)));
};

/**
 * @function
 * @name addOffer
 * @description Method used to add a new offer instance to the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} offerData			The data to create the new offer from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addOffer = (offerData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addOfferRequest());

	return OffersApi.createOffer(offerData)
		.then(({ offer }) => {
			toast.success(i18next.t('offer.creation.toasts.success', { name: offer.name }));
			dispatch(addOfferSuccess({ offer }));
			redirectOnSuccess(onSuccessRoute);

			return offer;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('offer.creation.toasts.conflict'));
				dispatch(addOfferFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('offer.creation.toasts.error'));
				dispatch(addOfferFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateOffer
 * @description Method used to update an existing offer instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} offerData		The object to update the offer with.
 * @param {string} offerId			The id of the offer we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateOffer = (offerData, offerId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateOfferRequest());

	return OffersApi.updateOffer(offerData, offerId)
		.then(({ offer }) => {
			dispatch(updateOfferSuccess({ offer }));
			toast.success(i18next.t('offer.edition.toasts.success', { name: `${offer.name}` }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('offer.edition.toasts.conflict'));
				dispatch(updateOfferFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('offer.edition.toasts.error'));
				dispatch(updateOfferFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeOffer
 * @description Method used to remove an existing offer instance from the database.
 *
 * @author Audrey Clerc
 * @author Matthieu Schaerlinger
 *
 * @param {object} offer		The offer we want to remove from the database.
 * @param {string} offer.id		The id of the offer we want to remove from the database.
 * @param {string} offer.name	The name of the offer we want to remove from the database (used in success toast).
 */
export const removeOffer = ({ id, name }) => (dispatch) => {
	dispatch(removeOfferRequest());

	return OffersApi.deleteOffer(id)
		.then(({ deletedOfferId }) => {
			dispatch(removeOfferSuccess({ deletedOfferId }));
			toast.success(i18next.t('offer.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeOfferFailure(error));
			toast.error(i18next.t('offer.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveOffer
 * @description	Method used to archive an existing offer instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} offer		The offer we want to archive.
 * @param {string} offer.id		The id of the offer we want to archive.
 * @param {string} offer.name	The name of the offer we want to archive (used in success toast).
 */
export const archiveOffer = ({ id, name }) => (dispatch) => {
	dispatch(archiveOfferRequest());

	return OffersApi.updateOffer({ archived: true }, id)
		.then(() => {
			dispatch(archiveOfferSuccess());
			toast.success(i18next.t('offer.archiving.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(archiveOfferFailure(error));
			toast.error(i18next.t('offer.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreOffer
 * @description	Method used to restore an existing offer instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} offer		The offer we want to restore.
 * @param {string} offer.id		The id of the offer we want to restore.
 * @param {string} offer.name	The name of the offer we want to restore (used in success toast).
 */
export const restoreOffer = ({ id, name }) => (dispatch) => {
	dispatch(restoreOfferRequest());

	return OffersApi.updateOffer({ archived: false }, id)
		.then(() => {
			dispatch(restoreOfferSuccess());
			toast.success(i18next.t('offer.restoring.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(restoreOfferFailure(error));
			toast.error(i18next.t('offer.restoring.toasts.error'));
		});
};
