import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchExpenses
 * @description Retrieves a list of all expenses from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchExpenses = (params) => post('/api/expenses', params);

/**
 * @function
 * @name fetchExpenseById
 * @description Retrieves an existing expense from the API, using the expenseId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} expenseId	The id of the expense we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchExpenseById = (expenseId) => get(`/api/expense/${expenseId}`);

/**
 * @function
 * @name createExpense
 * @description Creates a new expense into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} expenseData	The data to create the expense from.
 *
 * @returns {Promise}
 */
export const createExpense = (expenseData) => post('/api/expenses/create', expenseData);

/**
 * @function
 * @name updateExpense
 * @description Updates an existing expense from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} expenseData	The data to update the expense with.
 * @param {string} expenseId	The id of the expense we wish to update.
 *
 * @returns {Promise}
 */
export const updateExpense = (expenseData, expenseId) => put(`/api/expense/${expenseId}`, expenseData);

/**
 * @function
 * @name deleteExpense
 * @description Removes an existing expense from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} expenseId	The id of the expense we want to remove.
 *
 * @returns {Promise}
 */
export const deleteExpense = (expenseId) => del(`/api/expense/${expenseId}`);

/**
 * @function
 * @name exportExpenses
 * @description Export existing expenses from the database.
 *
 * @author Romaric Barthe
 *
 * @param {Object} data		The data to export the expenses.
 *
 * @returns {Promise}
 */
export const exportExpenses = (data) => post('/api/expenses/export', data, 'attachment');

/**
 * @function
 * @name editExpense
 * @description Prepare edition of an existing expense with the right edition number.
 *
 * @author Romaric Barthe
 *
 * @param {string} expenseId	The id of the expense we wish to edit.
 * @param {string} editionDate	The edition date of the expense.
 *
 * @returns {Promise}
 */
export const editExpense = (expenseId, editionDate) => put(`/api/expense/edit/${expenseId}`, { editionDate });

/**
 * @function
 * @name fetchAllForExpenseForm
 * @description Retrieves all information for expense from the API.
 *
 * @author Romaric Barthe.
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchAllForExpenseForm = (params) => post('/api/expense/form', params);
