import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentTemplateSelector
 * @description A selector to retrieve the current template from the template redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the current template to wait for
 *
 * @returns {object}
 */
const useCurrentTemplateSelector = (id) => {
	const template = useSelector((state) => (state.templates?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (template?.id === id ? template : null), [id, template]);

	return result;
};

/**
 * @function
 * @name useTemplateLoadingSelector
 * @description A selector to retrieve the loading state from the template redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useTemplateLoadingSelector = () => {
	const loading = useSelector((state) => state.templates?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useTemplateListSelector
 * @description A selector to retrieve the template list from the template redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTemplateListSelector = () => {
	const templates = useSelector((state) => (state.templates?.items ?? []), shallowEqual);

	return templates;
};

/**
 * @function
 * @name useTemplateTotalCountSelector
 * @description A selector to retrieve the totalCount value from the template redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useTemplateTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.templates?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useTemplateActiveListSelector
 * @description A selector to retrieve all non-archived templates from the template redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTemplateActiveListSelector = () => {
	const templates = useTemplateListSelector();

	const result = useMemo(() => (templates.filter(({ archived }) => !archived)), [templates]);

	return result;
};

export {
	useCurrentTemplateSelector,
	useTemplateActiveListSelector,
	useTemplateListSelector,
	useTemplateLoadingSelector,
	useTemplateTotalCountSelector,
};
