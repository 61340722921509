import { useCallback, useEffect, useMemo, useState } from 'react';
import { Maximize, Minimize, Minus, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { Select } from 'components/shared/inputs';

/**
 * @constant
 * @name ZoomModes
 * @description An enumeration of zoom modes.
 *
 * @author Matthieu Schaerlinger
 */
const ZoomModes = Object.freeze({
	AUTO: 'AUTO',
	FULL_WIDTH: 'FULL_WIDTH',
	FULL_HEIGHT: 'FULL_HEIGHT',
	NUMBER: 'NUMBER',
});

/**
 * @name EditorFooter
 * @description A footer containing a toolbar
 *
 * @author Florian Fornazaric
 * @author Matthieu Schaerlinger
 *
 * @param {function} 	zoomChangeCallback		Callback function called when the zoom level changes
 * @param {function}	fullWidthZoomCallback	Callback function called when the user clicks the full width zoom button
 * @param {function}	fullHeightZoomCallback	Callback function called when the user clicks the full height zoom button
 */
const EditorFooter = ({ zoomChangeCallback, fullWidthZoomCallback, fullHeightZoomCallback }) => {
	const { t } = useTranslation();

	const numberOptions = Object.freeze([50, 75, 100, 125, 150, 200, 300, 400]);

	const nextNumber = useCallback((currentNumber) => numberOptions[Math.min(
		numberOptions.length - 1,
		numberOptions.indexOf(currentNumber) + 1
	)], [numberOptions]);

	const prevNumber = useCallback((currentOption) => numberOptions[Math.max(
		0,
		numberOptions.indexOf(currentOption) - 1
	)], [numberOptions]);

	const selectOptions = useMemo(() => [
		{ value: ZoomModes.AUTO, label: t('template.pdf.editor.footer.zoom.auto') },
		{ value: ZoomModes.FULL_WIDTH, label: t('template.pdf.editor.footer.zoom.full_width') },
		{ value: ZoomModes.FULL_HEIGHT, label: t('template.pdf.editor.footer.zoom.full_height') },
		...numberOptions.map((value) => ({ value, label: `${value}%` })),
	], [t, numberOptions]);

	const [currentOption, setCurrentOption] = useState({ mode: ZoomModes.AUTO });

	/**
	 * @function
	 * @name setZoom
	 * @description Set the zoom level using a callback function
	 *
	 * @author Matthieu Schaerlinger
	 *
	 * @param {function}	valueUpdater	Function called to get the new zoom level
	 */
	const setZoom = useCallback((valueUpdater) => {
		const { mode, value } = valueUpdater(currentOption);

		setCurrentOption({ mode, value });

		switch (mode) {
			case ZoomModes.AUTO:
				zoomChangeCallback(100);
				break;
			case ZoomModes.FULL_WIDTH:
				fullWidthZoomCallback();
				break;
			case ZoomModes.FULL_HEIGHT:
				fullHeightZoomCallback();
				break;
			default:
				zoomChangeCallback(value);
				break;
		}
	}, [currentOption, fullHeightZoomCallback, fullWidthZoomCallback, zoomChangeCallback]);

	const onZoomIncrementButtonClick = useCallback(
		() => setZoom(({ mode, value }) => ({ mode: ZoomModes.NUMBER, value: mode !== ZoomModes.NUMBER ? 100 : nextNumber(value) })),
		[setZoom, nextNumber]
	);
	const onZoomDecrementButtonClick = useCallback(
		() => setZoom(({ mode, value }) => ({ mode: ZoomModes.NUMBER, value: mode !== ZoomModes.NUMBER ? 100 : prevNumber(value) })),
		[setZoom, prevNumber]
	);

	const onZoomFullWidthButtonClick = useCallback(() => setZoom(() => ({ mode: ZoomModes.FULL_WIDTH })), [setZoom]);
	const onZoomFullHeightButtonClick = useCallback(() => setZoom(() => ({ mode: ZoomModes.FULL_HEIGHT })), [setZoom]);
	const onZoomSelectChange = useCallback(({ value }) => setZoom(() => (
		_.isNumber(value) ? { mode: ZoomModes.NUMBER, value } : { mode: value }
	)), [setZoom]);

	const platform = navigator.platform.toLowerCase().includes('mac') ? 'mac' : 'pc';

	const handleKeyDown = useCallback((e) => {
		if (!((platform === 'mac' && e.metaKey) || (platform === 'pc' && e.ctrlKey))) {
			return;
		}

		if (e.key === '+' || e.key === '=') {
			e.preventDefault();
			onZoomIncrementButtonClick();
		} else if (e.key === '-') {
			e.preventDefault();
			onZoomDecrementButtonClick();
		} else if (e.key === '0') {
			e.preventDefault();
			setZoom(() => ({ mode: ZoomModes.NUMBER, value: 100 }));
		}
	}, [onZoomDecrementButtonClick, onZoomIncrementButtonClick, platform, setZoom]);

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => window.removeEventListener('keydown', handleKeyDown);
	}, [handleKeyDown]);

	return (
		<div className="editor-footer">
			<p>Zoom</p>
			<div className="editor-footer-zoom">
				<Select
					menuPlacement="top"
					onChange={onZoomSelectChange}
					classNamePrefix="editor-footer-zoom-select"
					value={selectOptions.find((option) => option.value === currentOption.mode || option.value === currentOption.value)}
					options={selectOptions}
				/>
			</div>

			<ReactTooltip id="editor-footer-controls" place="top" type="dark" effect="solid" />

			<Button
				className="icon-only"
				onClick={onZoomFullWidthButtonClick}
				data-tooltip-id="editor-footer-controls"
				data-tooltip-content={t('template.pdf.editor.footer.tooltips.full_width')}
			>
				<Maximize />
			</Button>
			<Button
				className="icon-only"
				onClick={onZoomFullHeightButtonClick}
				data-tooltip-id="editor-footer-controls"
				data-tooltip-content={t('template.pdf.editor.footer.tooltips.full_height')}
			>
				<Minimize />
			</Button>
			<Button
				className="icon-only"
				onClick={onZoomDecrementButtonClick}
				data-tooltip-id="editor-footer-controls"
				data-tooltip-content={t(`template.pdf.editor.footer.tooltips.zoom_out.${platform}`)}
			>
				<Minus />
			</Button>
			<Button
				className="icon-only"
				tooltip="Plus"
				onClick={onZoomIncrementButtonClick}
				data-tooltip-id="editor-footer-controls"
				data-tooltip-content={t(`template.pdf.editor.footer.tooltips.zoom_in.${platform}`)}
			>
				<Plus />
			</Button>
		</div>
	);
};

EditorFooter.propTypes = {
	zoomChangeCallback: PropTypes.func.isRequired,
	fullWidthZoomCallback: PropTypes.func.isRequired,
	fullHeightZoomCallback: PropTypes.func.isRequired,
};

export default EditorFooter;
