import keys from './keys';

/**
 * @constant
 * @type {array}
 *
 * @description Routes to display inside breadcrumbs
 *
 * Each route and group of routes can have a name to display inside the breadcrumbs
 * A route can omit the name property to avoid being displayed inside a breadcrumb, for example when a route is the default for its group
 *
 * @author Yann Hodiesne
 */
export default [
	{
		path: keys.root,
	},
	// Landing page
	{
		path: keys.app.default,
	},
	// Auth
	{
		path: keys.auth.default,
		routes: [
			{
				path: keys.auth.login,
			},
			{
				path: keys.auth.logout,
			},
			{
				path: keys.auth.forgottenPassword,
			},
		],
	},
	// Accounting
	{
		path: keys.accounting.default,
		name: 'pages.accounting.default',
		routes: [
			// Invoices
			{
				path: keys.accounting.invoices.default,
				name: 'pages.accounting.invoices.default',
				routes: [
					{
						path: keys.accounting.invoices.dashboard,
						name: 'pages.accounting.invoices.dashboard',
					},
					{
						path: keys.accounting.invoices.list,
						name: 'pages.accounting.invoices.list',
					},
					{
						path: keys.accounting.invoices.invoiceCreation,
						name: 'pages.accounting.invoices.creation',
					},
					{
						path: keys.accounting.invoices.invoiceEdition,
						name: 'pages.accounting.invoices.edition',
					},
					{
						path: keys.accounting.invoices.invoicePreCreation,
						name: 'pages.accounting.invoices.precreation',
					},
					{
						path: keys.accounting.invoices.invoiceVisualisation,
						name: 'pages.accounting.invoices.visualisation',
					},
				],
			},
			// Accounts
			{
				path: keys.accounting.accounts.default,
				name: 'pages.accounting.accounts.default',
				routes: [
					{
						path: keys.accounting.accounts.list,
						name: 'pages.accounting.accounts.list',
					},
					{
						path: keys.accounting.accounts.accountCreation,
						name: 'pages.accounting.accounts.creation',
					},
					{
						path: keys.accounting.accounts.accountEdition,
						name: 'pages.accounting.accounts.edition',
					},
				],
			},
			// Parameters
			{
				path: keys.accounting.parameters.default,
				name: 'pages.accounting.parameters.default',
				routes: [
					// Payment delays
					{
						path: keys.accounting.parameters.paymentDelays.default,
						name: 'pages.accounting.parameters.payment_delays.default',
						routes: [
							{
								path: keys.accounting.parameters.paymentDelays.list,
								name: 'pages.accounting.parameters.payment_delays.list',
							},
							{
								path: keys.accounting.parameters.paymentDelays.paymentDelayCreation,
								name: 'pages.accounting.parameters.payment_delays.creation',
							},
							{
								path: keys.accounting.parameters.paymentDelays.paymentDelayEdition,
								name: 'pages.accounting.parameters.payment_delays.edition',
							},
						],
					},
					// Number formats
					{
						path: keys.accounting.parameters.numberFormats.default,
						name: 'pages.accounting.parameters.number_formats.default',
						routes: [
							{
								path: keys.accounting.parameters.numberFormats.list,
								name: 'pages.accounting.parameters.number_formats.list',
							},
							{
								path: keys.accounting.parameters.numberFormats.numberFormatCreation,
								name: 'pages.accounting.parameters.number_formats.creation',
							},
							{
								path: keys.accounting.parameters.numberFormats.numberFormatEdition,
								name: 'pages.accounting.parameters.number_formats.edition',
							},
						],
					},
					// Status objects
					{
						path: keys.accounting.parameters.statusObjects.default,
						name: 'pages.accounting.parameters.status_objects.default',
						routes: [
							{
								name: 'pages.accounting.parameters.status_objects.list',
								path: keys.accounting.parameters.statusObjects.list,
							},
							{
								name: 'pages.accounting.parameters.status_objects.creation',
								path: keys.accounting.parameters.statusObjects.statusObjectCreation,
							},
							{
								name: 'pages.accounting.parameters.status_objects.edition',
								path: keys.accounting.parameters.statusObjects.statusObjectEdition,
							},
						],
					},
					// Vat rates
					{
						path: keys.accounting.parameters.vatRates.default,
						name: 'pages.accounting.parameters.vat_rates.default',
						routes: [
							{
								path: keys.accounting.parameters.vatRates.list,
								name: 'pages.accounting.parameters.vat_rates.list',
							},
							{
								path: keys.accounting.parameters.vatRates.vatRateCreation,
								name: 'pages.accounting.parameters.vat_rates.creation',
							},
							{
								path: keys.accounting.parameters.vatRates.vatRateEdition,
								name: 'pages.accounting.parameters.vat_rates.edition',
							},
						],
					},
				],
			},
		],
	},
	// Common modules
	{
		name: 'pages.common_modules.default',
		path: keys.commonModules.default,
		routes: [
			// Contacts
			{
				name: 'pages.common_modules.contacts.default',
				path: keys.commonModules.contacts.default,
				routes: [
					{
						name: 'pages.common_modules.contacts.list',
						path: keys.commonModules.contacts.list,
					},
					{
						name: 'pages.common_modules.contacts.creation',
						path: keys.commonModules.contacts.contactCreation,
					},
					{
						name: 'pages.common_modules.contacts.edition',
						path: keys.commonModules.contacts.contactEdition,
					},
				],
			},
			// Partners
			{
				name: 'pages.common_modules.partners.default',
				path: keys.commonModules.partners.default,
				routes: [
					{
						name: 'pages.common_modules.partners.list',
						path: keys.commonModules.partners.list,
					},
					{
						name: 'pages.common_modules.partners.creation',
						path: keys.commonModules.partners.partnerCreation,
					},
					{
						name: 'pages.common_modules.partners.edition',
						path: keys.commonModules.partners.partnerEdition,
					},
				],
			},
			// relationTypes
			{
				name: 'pages.common_modules.relation_types.default',
				path: keys.commonModules.relationTypes.default,
				routes: [
					{
						name: 'pages.common_modules.relation_types.list',
						path: keys.commonModules.relationTypes.list,
					},
					{
						name: 'pages.common_modules.relation_types.creation',
						path: keys.commonModules.relationTypes.relationTypeCreation,
					},
					{
						name: 'pages.common_modules.relation_types.edition',
						path: keys.commonModules.relationTypes.relationTypeEdition,
					},
				],
			},
			// sectors
			{
				name: 'pages.common_modules.sectors.default',
				path: keys.commonModules.sectors.default,
				routes: [
					{
						name: 'pages.common_modules.sectors.list',
						path: keys.commonModules.sectors.list,
					},
					{
						name: 'pages.common_modules.sectors.creation',
						path: keys.commonModules.sectors.sectorCreation,
					},
					{
						name: 'pages.common_modules.sectors.edition',
						path: keys.commonModules.sectors.sectorEdition,
					},
				],
			},
			// registrations
			{
				name: 'pages.common_modules.registrations.default',
				path: keys.commonModules.registrations.default,
				routes: [
					{
						name: 'pages.common_modules.registrations.list',
						path: keys.commonModules.registrations.list,
					},
					{
						name: 'pages.common_modules.registrations.creation',
						path: keys.commonModules.registrations.registrationCreation,
					},
					{
						name: 'pages.common_modules.registrations.edition',
						path: keys.commonModules.registrations.registrationEdition,
					},
				],
			},
		],
	},
	// Human resources
	{
		path: keys.humanResources.default,
		name: 'pages.human_resources.default',
		routes: [
			// Access definition
			{
				path: keys.humanResources.accessDefinitions.default,
				name: 'pages.human_resources.access_definitions.default',
				routes: [
					{
						path: keys.humanResources.accessDefinitions.list,
						name: 'pages.human_resources.access_definitions.list',
					},
					{
						path: keys.humanResources.accessDefinitions.accessDefinitionCreation,
						name: 'pages.human_resources.access_definitions.creation',
					},
					{
						path: keys.humanResources.accessDefinitions.accessDefinitionEdition,
						name: 'pages.human_resources.access_definitions.edition',
					},
				],
			},
			// Users
			{
				path: keys.humanResources.users.default,
				name: 'pages.human_resources.users.default',
				routes: [
					{
						name: 'pages.human_resources.users.list',
						path: keys.humanResources.users.list,
					},
					{
						name: 'pages.human_resources.users.creation',
						path: keys.humanResources.users.userCreation,
					},
					{
						name: 'pages.human_resources.users.edition',
						path: keys.humanResources.users.userEdition,
					},
				],
			},
			// Expenses
			{
				path: keys.humanResources.expenses.default,
				name: 'pages.human_resources.expenses.default',
				routes: [
					{
						path: keys.humanResources.expenses.list,
						name: 'pages.human_resources.expenses.list',
					},
					{
						path: keys.humanResources.expenses.expenseCreation,
						name: 'pages.human_resources.expenses.creation',
					},
					{
						path: keys.humanResources.expenses.expenseEdition,
						name: 'pages.human_resources.expenses.edition',
					},
					{
						path: keys.humanResources.expenses.expensePreCreation,
						name: 'pages.human_resources.expenses.precreation',
					},
					{
						path: keys.humanResources.expenses.expenseVisualisation,
						name: 'pages.human_resources.expenses.visualisation',
					},
				],
			},
			// Timesheet
			{
				path: keys.humanResources.timesheets.default,
				name: 'pages.human_resources.timesheets.default',
				routes: [
					{
						path: keys.humanResources.timesheets.list,
						name: 'pages.human_resources.timesheets.list',
					},
					{
						path: keys.humanResources.timesheets.timesheetCreation,
						name: 'pages.human_resources.timesheets.creation',
					},
					{
						path: keys.humanResources.timesheets.timesheetEdition,
						name: 'pages.human_resources.timesheets.edition',
					},
				],
			},
			// Parameters
			{
				path: keys.humanResources.parameters.default,
				name: 'pages.human_resources.parameters.default',
				routes: [
					// Number formats
					{
						path: keys.humanResources.parameters.numberFormats.default,
						name: 'pages.human_resources.parameters.number_formats.default',
						routes: [
							{
								path: keys.humanResources.parameters.numberFormats.list,
								name: 'pages.human_resources.parameters.number_formats.list',
							},
							{
								path: keys.humanResources.parameters.numberFormats.numberFormatCreation,
								name: 'pages.human_resources.parameters.number_formats.creation',
							},
							{
								path: keys.humanResources.parameters.numberFormats.numberFormatEdition,
								name: 'pages.human_resources.parameters.number_formats.edition',
							},
						],
					},
					// Status objects
					{
						path: keys.humanResources.parameters.statusObjects.default,
						name: 'pages.human_resources.parameters.status_objects.default',
						routes: [
							{
								name: 'pages.human_resources.parameters.status_objects.list',
								path: keys.humanResources.parameters.statusObjects.list,
							},
							{
								name: 'pages.human_resources.parameters.status_objects.creation',
								path: keys.humanResources.parameters.statusObjects.statusObjectCreation,
							},
							{
								name: 'pages.human_resources.parameters.status_objects.edition',
								path: keys.humanResources.parameters.statusObjects.statusObjectEdition,
							},
						],
					},
					// Vat rates
					{
						path: keys.humanResources.parameters.vatRates.default,
						name: 'pages.human_resources.parameters.vat_rates.default',
						routes: [
							{
								path: keys.humanResources.parameters.vatRates.list,
								name: 'pages.human_resources.parameters.vat_rates.list',
							},
							{
								path: keys.humanResources.parameters.vatRates.vatRateCreation,
								name: 'pages.human_resources.parameters.vat_rates.creation',
							},
							{
								path: keys.humanResources.parameters.vatRates.vatRateEdition,
								name: 'pages.human_resources.parameters.vat_rates.edition',
							},
						],
					},
				],
			},
		],
	},
	// Operations
	{
		path: keys.operations.default,
		name: 'pages.operations.default',
		routes: [
			// Projects
			{
				path: keys.operations.projects.default,
				name: 'pages.operations.projects.default',
				routes: [
					{
						path: keys.operations.projects.list,
						name: 'pages.operations.projects.list',
					},
					{
						path: keys.operations.projects.projectCreation,
						name: 'pages.operations.projects.creation',
					},
					{
						path: keys.operations.projects.projectEdition,
						name: 'pages.operations.projects.edition',
					},
				],
			},
			// Targetings
			{
				path: keys.operations.targetings.default,
				name: 'pages.operations.targetings.default',
				routes: [
					{
						name: 'pages.operations.targetings.list',
						path: keys.operations.targetings.list,
					},
					{
						name: 'pages.operations.targetings.creation',
						path: keys.operations.targetings.targetingCreation,
					},
					{
						name: 'pages.operations.targetings.edition',
						path: keys.operations.targetings.targetingEdition,
					},
					{
						name: 'pages.operations.targetings.target_partners.list',
						path: keys.operations.targetings.targetPartners.list,
					},
					{
						name: 'pages.operations.targetings.targets.list',
						path: keys.operations.targetings.targets.list,
					},
					{
						name: 'pages.operations.targetings.targets.creation',
						path: keys.operations.targetings.targets.targetCreation,
					},
					{
						name: 'pages.operations.targetings.targets.edition',
						path: keys.operations.targetings.targets.targetEdition,
					},
					{
						name: 'pages.operations.targetings.targets.load_target_partners',
						path: keys.operations.targetings.targets.targetLoadTargetPartners,
					},
					{
						name: 'pages.operations.targetings.targets.target_partners.edition',
						path: keys.operations.targetings.targets.targetPartners.targetPartnerEdition,
					},
				],
			},
			// Parameters
			{
				path: keys.operations.parameters.default,
				name: 'pages.operations.parameters.default',
				routes: [
					// Status objects
					{
						path: keys.operations.parameters.statusObjects.default,
						name: 'pages.operations.parameters.status_objects.default',
						routes: [
							{
								name: 'pages.operations.parameters.status_objects.list',
								path: keys.operations.parameters.statusObjects.list,
							},
							{
								name: 'pages.operations.parameters.status_objects.creation',
								path: keys.operations.parameters.statusObjects.statusObjectCreation,
							},
							{
								name: 'pages.operations.parameters.status_objects.edition',
								path: keys.operations.parameters.statusObjects.statusObjectEdition,
							},
						],
					},
				],
			},
		],
	},
	// Sales
	{
		path: keys.sales.default,
		name: 'pages.sales.default',
		routes: [
			// Crm
			{
				path: keys.sales.crm.default,
				name: 'pages.sales.crm.default',
				routes: [
					{
						path: keys.sales.crm.list,
						name: 'pages.sales.crm.list',
					},
					{
						path: keys.sales.crm.historyReminder,
						name: 'pages.sales.crm.reminder',
					},
					{
						path: keys.sales.crm.historyCreation,
						name: 'pages.sales.crm.creation',
					},
					{
						path: keys.sales.crm.historyEdition,
						name: 'pages.sales.crm.edition',
					},
				],
			},
			// Offers
			{
				path: keys.sales.offers.default,
				name: 'pages.sales.offers.default',
				routes: [
					{
						path: keys.sales.offers.list,
						name: 'pages.sales.offers.list',
					},
					{
						path: keys.sales.offers.offerCreation,
						name: 'pages.sales.offers.creation',
					},
					{
						path: keys.sales.offers.offerEdition,
						name: 'pages.sales.offers.edition',
					},
				],
			},
			// Quotations
			{
				path: keys.sales.quotations.default,
				name: 'pages.sales.quotations.default',
				routes: [
					{
						path: keys.sales.quotations.list,
						name: 'pages.sales.quotations.list',
					},
					{
						path: keys.sales.quotations.quotationCreation,
						name: 'pages.sales.quotations.creation',
					},
					{
						path: keys.sales.quotations.quotationEdition,
						name: 'pages.sales.quotations.edition',
					},
					{
						path: keys.sales.quotations.quotationPreCreation,
						name: 'pages.sales.quotations.precreation',
					},
					{
						path: keys.sales.quotations.quotationVisualisation,
						name: 'pages.sales.quotations.visualisation',
					},
				],
			},
			// Parameters
			{
				path: keys.sales.parameters.default,
				name: 'pages.sales.parameters.default',
				routes: [
					// Number formats
					{
						path: keys.sales.parameters.numberFormats.default,
						name: 'pages.sales.parameters.number_formats.default',
						routes: [
							{
								path: keys.sales.parameters.numberFormats.list,
								name: 'pages.sales.parameters.number_formats.list',
							},
							{
								path: keys.sales.parameters.numberFormats.numberFormatCreation,
								name: 'pages.sales.parameters.number_formats.creation',
							},
							{
								path: keys.sales.parameters.numberFormats.numberFormatEdition,
								name: 'pages.sales.parameters.number_formats.edition',
							},
						],
					},
					// Vat rates
					{
						path: keys.sales.parameters.vatRates.default,
						name: 'pages.sales.parameters.vat_rates.default',
						routes: [
							{
								path: keys.sales.parameters.vatRates.list,
								name: 'pages.sales.parameters.vat_rates.list',
							},
							{
								path: keys.sales.parameters.vatRates.vatRateCreation,
								name: 'pages.sales.parameters.vat_rates.creation',
							},
							{
								path: keys.sales.parameters.vatRates.vatRateEdition,
								name: 'pages.sales.parameters.vat_rates.edition',
							},
						],
					},
				],
			},
		],
	},
	// Settings
	{
		path: keys.settings.default,
		name: 'pages.settings.default',
		routes: [
			// Log
			{
				path: keys.settings.logs.default,
				name: 'pages.settings.logs.default',
				routes: [
					{
						path: keys.settings.logs.list,
						name: 'pages.settings.logs.list',
					},
				],
			},
			// Medias
			{
				path: keys.settings.medias.default,
				name: 'pages.settings.medias.default',
				routes: [
					{
						path: keys.settings.medias.list,
						name: 'pages.settings.medias.list',
					},
				],
			},
			// Statuses
			{
				path: keys.settings.statuses.default,
				name: 'pages.settings.statuses.default',
				routes: [
					{
						path: keys.settings.statuses.list,
						name: 'pages.settings.statuses.list',
					},
					{
						path: keys.settings.statuses.statusCreation,
						name: 'pages.settings.statuses.creation',
					},
					{
						path: keys.settings.statuses.statusEdition,
						name: 'pages.settings.statuses.edition',
					},
				],
			},
			// Templates
			{
				path: keys.settings.templates.default,
				name: 'pages.settings.templates.default',
				routes: [
					{
						path: keys.settings.templates.list,
						name: 'pages.settings.templates.list',
					},
					{
						path: keys.settings.templates.templatePreCreation,
						name: 'pages.settings.templates.precreation',
					},
					{
						path: keys.settings.templates.templatePdfEdition,
						name: 'pages.settings.templates.pdf.edition',
					},
					{
						path: keys.settings.templates.templatePdfExport,
						name: 'pages.settings.templates.pdf.export',
					},
					{
						path: keys.settings.templates.templatePdfExportFromApp,
						name: 'pages.settings.templates.pdf.export_from_app',
					},
					{
						path: keys.settings.templates.templatePdfView,
						name: 'pages.settings.templates.pdf.view',
					},
					{
						path: keys.settings.templates.templateSpreadSheetView,
						name: 'pages.settings.templates.spreadsheet.view',
					},
					{
						path: keys.settings.templates.export,
						name: 'pages.settings.templates.export',
					},
					{
						path: keys.settings.templates.exportFromApp,
						name: 'pages.settings.templates.export_from_app',
					},
					{
						path: keys.settings.templates.exportList,
						name: 'pages.settings.templates.export_list',
					},
					{
						path: keys.settings.templates.reexport,
						name: 'pages.settings.templates.reexport',
					},
				],
			},
			// Text objects
			{
				path: keys.settings.textObjects.default,
				name: 'pages.settings.text_objects.default',
				routes: [
					{
						path: keys.settings.textObjects.list,
						name: 'pages.settings.text_objects.list',
					},
					{
						path: keys.settings.textObjects.textObjectCreation,
						name: 'pages.settings.text_objects.creation',
					},
					{
						path: keys.settings.textObjects.textObjectEdition,
						name: 'pages.settings.text_objects.edition',
					},
				],
			},
		],
	},
	// Super-admin
	{
		path: keys.superAdmin.default,
		name: 'pages.super_admin.default',
		routes: [
			{
				path: keys.superAdmin.companyList,
				name: 'pages.super_admin.companies.list',
			},
			{
				path: keys.superAdmin.companyCreation,
				name: 'pages.super_admin.companies.creation',
			},
			{
				path: keys.superAdmin.companyEdition,
				name: 'pages.super_admin.companies.edition',
			},
			{
				path: keys.superAdmin.settings,
				name: 'pages.super_admin.settings',
			},
			{
				path: keys.superAdmin.templateList,
				name: 'pages.super_admin.templates.list',
			},
			{
				path: keys.superAdmin.templatePreCreation,
				name: 'pages.super_admin.templates.precreation',
			},
			{
				path: keys.superAdmin.templatePdfEdition,
				name: 'pages.super_admin.templates.pdf.edition',
			},
			{
				path: keys.superAdmin.templatePdfView,
				name: 'pages.super_admin.templates.pdf.duplicate',
			},
			{
				path: keys.superAdmin.templateSpreadSheetView,
				name: 'pages.super_admin.templates.spreadsheet.view',
			},
		],
	},
	// User
	{
		path: keys.user.definePassword,
		name: 'pages.define_password',
	},
	{
		path: keys.user.preferences,
		name: 'pages.preferences',
	},
];
