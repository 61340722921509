import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceColumnHeader } from 'constants/invoiceEnums';
import PropTypes from 'prop-types';

import DynamicFormContext from '../shared/forms/internals/DynamicFormContext';

import InvoiceGridRow from './InvoiceGridRow';

/**
 * @name InvoiceGrid
 * @description A form used to edit an existing invoice's information.
 *
 * @author Romaric Barthe
 *
 * @param {object}	accounts			The list of accounts.
 * @param {array}	columnsHeaders		The array of all possible header.
 * @param {string}	currencyName		The ISO name of the currency for the invoice.
 * @param {string}	currencySymbol		The symbol of the currency used in the expense.
 * @param {object}	defaultValues		The defaultValues of the invoice.
 * @param {bool}	isLoading			Whether data are still loading.
 * @param {object}	offers				The list of available offers.
 * @param {object}	rows				The rows of the invoice.
 * @param {bool}	readOnly			Whether the invoice is read-only.
 * @param {object}	vatRates			The list of available vatRates.
 * @param {func}	updateRow			The method to add/ move/ delete/ duplicate a line.
 * @param {func}	setTotals			The method to set the total of the expenses.
 * @param {func}	duplicateRowId		If set, the id of the row that is duplicated.
 * @param {func}	originalRowData		If set, the data of the row that is duplicated.
 */
const InvoiceGrid = ({
	accounts,
	columnsHeaders,
	currencyName,
	currencySymbol,
	defaultValues,
	isLoading,
	offers,
	readOnly,
	rows,
	vatRates,
	// functions
	updateRow,
	setTotals,
	// duplicate logic
	duplicateRowId,
	originalRowData,
}) => {
	const { t } = useTranslation();

	const offersForCurrency = useMemo(() => {
		if (offers) {
			return offers.filter((o) => o.currency?.name === currencyName);
		}

		return {};
	}, [offers, currencyName]);

	const rowItems = useMemo(() => Math.max(...rows.map((row) => row?.line)), [rows]);

	const { disabled: formDisabled } = useContext(DynamicFormContext);

	return (
		<table className="grid">
			<thead>
				<tr className="single">
					{columnsHeaders.filter((elem) => elem.show).map((header) => {
						switch (header.label) {
							case InvoiceColumnHeader.unitary.ACCOUNT:
								return (
									readOnly ? null : (
										<th key={header.label} className="cell-left">
											{t(`invoice.headers.${header.label}`)}
										</th>
									)
								);
							case InvoiceColumnHeader.advancement.BASE_AMOUNT:
							case InvoiceColumnHeader.unitary.DISCOUNT:
							case InvoiceColumnHeader.advancement.ALREADY_INVOICED:
							case InvoiceColumnHeader.advancement.CURRENTLY_INVOICED:
							case InvoiceColumnHeader.unitary.QUANTITY:
							case InvoiceColumnHeader.unitary.TOTAL:
							case InvoiceColumnHeader.unitary.PRICE:
								return (
									<th key={header.label} className="cell-right">
										{t(`invoice.headers.${header.label}`)}
									</th>
								);
							default:
								return (
									<th key={header.label} className="cell-left">
										{t(`invoice.headers.${header.label}`)}
									</th>
								);
						}
					})}
				</tr>
			</thead>

			<tbody>
				{rows.map((row) => (
					<InvoiceGridRow
						accounts={accounts}
						columnsHeaders={columnsHeaders}
						currencyName={currencyName}
						currencySymbol={currencySymbol}
						defaultValues={defaultValues}
						formDisabled={formDisabled}
						isLoading={isLoading}
						key={`tr.${row.id}`}
						offers={offersForCurrency}
						readOnly={readOnly}
						row={row}
						rowItems={rowItems}
						vatRates={vatRates}
						// functions
						updateRow={updateRow}
						setTotals={setTotals}
						// duplicate logic
						originalRowData={row.id === duplicateRowId ? originalRowData : undefined}
					/>
				))}
			</tbody>
		</table>
	);
};

InvoiceGrid.propTypes = {
	accounts: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	currencyName: PropTypes.string,
	currencySymbol: PropTypes.string,
	columnsHeaders: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			show: PropTypes.bool,
		}),
	).isRequired,
	defaultValues: PropTypes.object,
	isLoading: PropTypes.bool,
	offers: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	readOnly: PropTypes.bool,
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			column: PropTypes.string,
			value: PropTypes.string,
		}),
	).isRequired,
	vatRates: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	// functions
	updateRow: PropTypes.func.isRequired,
	setTotals: PropTypes.func.isRequired,
	// duplicate logic
	duplicateRowId: PropTypes.string,
	originalRowData: PropTypes.object,
};

InvoiceGrid.defaultProps = {
	currencyName: '',
	currencySymbol: '',
	defaultValues: undefined,
	isLoading: true,
	readOnly: false,
	duplicateRowId: undefined,
	originalRowData: undefined,
};

export default InvoiceGrid;
