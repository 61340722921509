export default {
	root: '/',
	app: {
		default: '/',
	},
	auth: {
		default: '/auth',
		login: '/auth/login',
		logout: '/auth/logout',
		forgottenPassword: '/auth/forgotten-password',
	},
	accounting: {
		default: '/accounting',
		accounts: {
			default: '/accounting/accounts',
			list: '/accounting/accounts',
			accountCreation: '/accounting/accounts/create',
			accountEdition: '/accounting/accounts/edit/:id',
		},
		invoices: {
			default: '/accounting/invoices',
			dashboard: '/accounting/invoices/dashboard',
			invoiceCreation: '/accounting/invoices/create',
			invoiceEdition: '/accounting/invoices/edit/:id',
			invoicePreCreation: '/accounting/invoices/pre-create',
			invoiceVisualisation: '/accounting/invoices/view/:id',
			list: '/accounting/invoices',
		},
		parameters: {
			default: '/accounting/parameters',
			statusObjects: {
				default: '/accounting/parameters/status-objects',
				list: '/accounting/parameters/status-objects',
				statusObjectCreation: '/accounting/parameters/status-objects/create',
				statusObjectEdition: '/accounting/parameters/status-objects/edit/:id',
			},
			paymentDelays: {
				default: '/accounting/parameters/payment-delays',
				list: '/accounting/parameters/payment-delays',
				paymentDelayCreation: '/accounting/parameters/payment-delays/create',
				paymentDelayEdition: '/accounting/parameters/payment-delays/edit/:id',
			},
			numberFormats: {
				default: '/accounting/parameters/number-formats',
				list: '/accounting/parameters/number-formats',
				numberFormatCreation: '/accounting/parameters/number-formats/create',
				numberFormatEdition: '/accounting/parameters/number-formats/edit/:id',
			},
			vatRates: {
				default: '/accounting/parameters/vat-rates',
				list: '/accounting/parameters/vat-rates',
				vatRateCreation: '/accounting/parameters/vat-rates/create',
				vatRateEdition: '/accounting/parameters/vat-rates/edit/:id',
			},
		},
	},
	commonModules: {
		default: '/common-modules',
		contacts: {
			default: '/common-modules/contacts',
			list: '/common-modules/contacts',
			contactCreation: '/common-modules/contacts/create',
			contactEdition: '/common-modules/contacts/edit/:id',
		},
		partners: {
			default: '/common-modules/partners',
			list: '/common-modules/partners',
			partnerCreation: '/common-modules/partners/create',
			partnerEdition: '/common-modules/partners/edit/:id',
		},
		relationTypes: {
			default: '/common-modules/relation-types',
			list: '/common-modules/relation-types',
			relationTypeCreation: '/common-modules/relation-types/create',
			relationTypeEdition: '/common-modules/relation-types/edit/:id',
		},
		sectors: {
			default: '/common-modules/sectors',
			list: '/common-modules/sectors',
			sectorCreation: '/common-modules/sectors/create',
			sectorEdition: '/common-modules/sectors/edit/:id',
		},
		registrations: {
			default: '/common-modules/registrations',
			list: '/common-modules/registrations',
			registrationCreation: '/common-modules/registrations/create',
			registrationEdition: '/common-modules/registrations/edit/:id',
		},
	},
	humanResources: {
		default: '/human-resources',
		accessDefinitions: {
			accessDefinitionCreation: '/human-resources/access-definitions/create',
			accessDefinitionEdition: '/human-resources/access-definitions/edit/:id',
			default: '/human-resources/access-definitions',
			list: '/human-resources/access-definitions',
		},
		users: {
			default: '/human-resources/users',
			list: '/human-resources/users',
			userCreation: '/human-resources/users/create',
			userEdition: '/human-resources/users/edit/:id',
		},
		expenses: {
			default: '/human-resources/expenses',
			expenseCreation: '/human-resources/expenses/create',
			expenseEdition: '/human-resources/expenses/edit/:id',
			expensePreCreation: '/human-resources/expenses/pre-create',
			expenseVisualisation: '/human-resources/expenses/view/:id',
			list: '/human-resources/expenses',
		},
		timesheets: {
			default: '/human-resources/timesheets',
			timesheetCreation: '/human-resources/timesheets/create',
			timesheetEdition: '/human-resources/timesheets/edit/:id',
			list: '/human-resources/timesheets',
		},
		parameters: {
			default: '/human-resources/parameters',
			statusObjects: {
				default: '/human-resources/parameters/status-objects',
				list: '/human-resources/parameters/status-objects',
				statusObjectCreation: '/human-resources/parameters/status-objects/create',
				statusObjectEdition: '/human-resources/parameters/status-objects/edit/:id',
			},
			numberFormats: {
				default: '/human-resources/parameters/number-formats',
				list: '/human-resources/parameters/number-formats',
				numberFormatCreation: '/human-resources/parameters/number-formats/create',
				numberFormatEdition: '/human-resources/parameters/number-formats/edit/:id',
			},
			vatRates: {
				default: '/human-resources/parameters/vat-rates',
				list: '/human-resources/parameters/vat-rates',
				vatRateCreation: '/human-resources/parameters/vat-rates/create',
				vatRateEdition: '/human-resources/parameters/vat-rates/edit/:id',
			},
		},
	},
	operations: {
		default: '/operations',
		projects: {
			default: '/operations/projects',
			list: '/operations/projects',
			projectCreation: '/operations/projects/create',
			projectEdition: '/operations/projects/edit/:id',
		},
		targetings: {
			default: '/operations/targetings',
			list: '/operations/targetings',
			targetingCreation: '/operations/targetings/create',
			targetingEdition: '/operations/targetings/edit/:id',
			targetPartners: {
				default: '/operations/targetings/:targetingId/target-partners/',
				list: '/operations/targetings/:targetingId/target-partners/',
			},
			targets: {
				default: '/operations/targetings/:targetingId/targets',
				list: '/operations/targetings/:targetingId/targets',
				targetCreation: '/operations/targetings/:targetingId/targets/create',
				targetEdition: '/operations/targetings/:targetingId/targets/edit/:targetId',
				targetLoadTargetPartners: '/operations/targetings/:targetingId/targets/load/:targetId',
				targetPartners: {
					default: '/operations/targetings/:targetingId/targets/:targetId',
					targetPartnerEdition: '/operations/targetings/:targetingId/targets/:targetId',
				},
			},
		},
		parameters: {
			default: '/operations/parameters',
			statusObjects: {
				default: '/operations/parameters/status-objects',
				list: '/operations/parameters/status-objects',
				statusObjectCreation: '/operations/parameters/status-objects/create',
				statusObjectEdition: '/operations/parameters/status-objects/edit/:id',
			},
		},
	},
	sales: {
		default: '/sales',
		crm: {
			default: '/sales/crm',
			list: '/sales/crm',
			historyReminder: '/sales/crm/reminder/:id',
			historyCreation: '/sales/crm/create',
			historyEdition: '/sales/crm/edit/:id',
		},
		offers: {
			default: '/sales/offers',
			list: '/sales/offers',
			offerCreation: '/sales/offers/create',
			offerEdition: '/sales/offers/edit/:id',
		},
		quotations: {
			default: '/sales/quotations',
			list: '/sales/quotations',
			quotationCreation: '/sales/quotations/create',
			quotationEdition: '/sales/quotations/edit/:id',
			quotationPreCreation: '/sales/quotations/pre-create',
			quotationVisualisation: '/sales/quotations/view/:id',
		},
		parameters: {
			default: '/sales/parameters',
			numberFormats: {
				default: '/sales/parameters/number-formats',
				list: '/sales/parameters/number-formats',
				numberFormatCreation: '/sales/parameters/number-formats/create',
				numberFormatEdition: '/sales/parameters/number-formats/edit/:id',
			},
			vatRates: {
				default: '/sales/parameters/vat-rates',
				list: '/sales/parameters/vat-rates',
				vatRateCreation: '/sales/parameters/vat-rates/create',
				vatRateEdition: '/sales/parameters/vat-rates/edit/:id',
			},
		},
	},
	settings: {
		default: '/settings',
		logs: {
			default: '/settings/logs',
			list: '/settings/logs',
		},
		medias: {
			default: '/settings/medias',
			list: '/settings/medias',
		},
		statuses: {
			default: '/settings/statuses',
			list: '/settings/statuses',
			statusCreation: '/settings/statuses/create',
			statusEdition: '/settings/statuses/edit/:id',
		},
		templates: {
			default: '/settings/templates',
			list: '/settings/templates',
			templatePreCreation: '/settings/templates/pre-create',
			templatePdfEdition: '/settings/templates/pdf/edit/:id',
			templatePdfExport: '/settings/templates/pdf/export/:id',
			templatePdfExportFromApp: '/settings/templates/pdf/export/:id/link-to/:entityId/editionDate/:editionDate/lastEditionDate/:lastEditionDate',
			templatePdfView: '/settings/templates/pdf/view/:id',
			templateSpreadSheetView: '/settings/templates/spreadsheet/view/:id',
			export: '/settings/templates/export',
			exportList: '/settings/templates/exports',
			reexport: '/settings/templates/reexport/:id',
		},
		textObjects: {
			default: '/settings/text-objects',
			list: '/settings/text-objects',
			textObjectCreation: '/settings/text-objects/create',
			textObjectEdition: '/settings/text-objects/edit/:id',
		},
	},
	superAdmin: {
		default: '/superadmin',
		companyList: '/superadmin/companies',
		companyCreation: '/superadmin/companies/create',
		companyEdition: '/superadmin/companies/edit/:id',
		settings: '/superadmin/settings',
		templateList: '/superadmin/templates',
		templatePreCreation: '/superadmin/templates/pre-create',
		templatePdfEdition: '/superadmin/templates/pdf/edit/:id',
		templatePdfView: '/superadmin/templates/pdf/view/:id',
		templateSpreadSheetView: '/superadmin/templates/spreadsheet/view/:id',
		templatePdfExport: '/superadmin/templates/pdf/export/:id',
		templatePdfDuplicate: '/superadmin/templates/pdf/duplicate/:id',
	},
	user: {
		definePassword: '/define-password',
		preferences: '/preferences',
	},
};
