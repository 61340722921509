/* eslint-disable import/prefer-default-export */
import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchUsers
 * @description Retrieves a list of all users from the API.
 *
 * @author Roland Margelidon
 *
 * @returns {Promise}
 */
export const fetchUsers = (params) => post('/api/users', params);

/**
 * @function
 * @name fetchUserById
 * @description Retrieves an existing user from the API, using the userId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} userId       The id of the user we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchUserById = (userId) => get(`/api/user/${userId}`);

/**
 * @function
 * @name createUser
 * @description Creates a new user into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} userData     The data to create the user from.
 *
 * @returns {Promise}
 */
export const createUser = (userData) => post('/api/users/create', userData);

/**
 * @function
 * @name updateUser
 * @description Updates an existing user from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} userData     The data to update the user with.
 * @param {string} userId       The id to identify the user with.
 *
 * @returns {Promise}
 */
export const updateUser = (userData, userId) => put(`/api/user/${userId}`, userData);

/**
 * @function
 * @name deleteUser
 * @description Removes an existing user from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} userId       The id of the user we want to remove.
 *
 * @returns {Promise}
 */
export const deleteUser = (userId) => del(`/api/user/${userId}`);

/**
 * @function
 * @name fetchAllForUserForm
 * @description Retrieves all information for user's form from the API:
 * - titles [{ id, name }]
 * - positions [{ id, name }]
 *
 * @author Romaric Barthe.
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchAllForUserForm = (params) => post('/api/user/form', params);
