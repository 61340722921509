import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'react-feather';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

import styles from 'styles/components/dataTables/_paginationFooter.module.scss';

/**
 * @name DataTableNavigation
 * @description A component used to allow the user to navigate inside a data table component.
 *
 * @author Timothée Simon-Franza
 *
 * @param {bool}	canGoToNextPage			 Determines if the user can navigate to a subsequent page
 * @param {bool}	canGoToPreviousPage		 Determines if the user can navigate to a prior page
 * @param {number}	currentPageIndex		 The current page's index.
 * @param {func}	navigateToNextPage		 Navigates to the next data page of the data table this component is linked to.
 * @param {func}	navigateToPreviousPage	 Navigates to the previous data page of the data table this component is linked to.
 * @param {func}	navigateToPage			 Navigates to a specific page using a provided index value.
 * @param {number}	totalPagesCount			 The total amount of pages available with the current data table display configuration.
 */
const DataTableNavigation = ({
	canGoToNextPage,
	canGoToPreviousPage,
	currentPageIndex,
	navigateToNextPage,
	navigateToPreviousPage,
	navigateToPage,
	totalPagesCount,
}) => (
	<div className="data-list-navigation">
		<Button
			className="icon-only"
			data-testid="table-pagination-first-page"
			onClick={() => navigateToPage(0)}
			disabled={!canGoToPreviousPage}
		>
			<ChevronsLeft />
		</Button>
		<Button
			className="icon-only"
			data-testid="table-pagination-previous-page"
			onClick={navigateToPreviousPage}
			disabled={!canGoToPreviousPage}
		>
			<ChevronLeft />
		</Button>
		<Button
			className={styles['page-indicator']}
			onClick={() => navigateToPreviousPage()}
			hidden={!canGoToPreviousPage}
		>
			{currentPageIndex}
		</Button>
		<Button
			className={styles['page-indicator']}
			aria-current
			onClick={() => navigateToPage(currentPageIndex)}
		>
			{currentPageIndex + 1}
		</Button>
		<Button
			className={styles['page-indicator']}
			onClick={() => navigateToNextPage()}
			hidden={!canGoToNextPage}
		>
			{currentPageIndex + 2}
		</Button>
		<Button
			className="icon-only"
			data-testid="table-pagination-next-page"
			onClick={() => navigateToNextPage()}
			disabled={!canGoToNextPage}
		>
			<ChevronRight />
		</Button>
		<Button
			className="icon-only"
			data-testid="table-pagination-last-page"
			onClick={() => navigateToPage(totalPagesCount - 1)}
			disabled={!canGoToNextPage}
		>
			<ChevronsRight />
		</Button>
	</div>
);

DataTableNavigation.propTypes = {
	canGoToNextPage: PropTypes.bool.isRequired,
	canGoToPreviousPage: PropTypes.bool.isRequired,
	currentPageIndex: PropTypes.number.isRequired,
	navigateToNextPage: PropTypes.func.isRequired,
	navigateToPreviousPage: PropTypes.func.isRequired,
	navigateToPage: PropTypes.func.isRequired,
	totalPagesCount: PropTypes.number.isRequired,
};

export default DataTableNavigation;
