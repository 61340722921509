import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { expenseColumnHeader } from 'constants/expenseEnums';
import { convertStringToFloat } from 'lib/shared/conversion';
import { formatDate, formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import ExpenseActionBar from './ExpenseActionBar';
import ExpenseTitle from './ExpenseTitle';
import { getExpenseColumnsHeaders, getLinesForVisualisation } from './functions';

/**
 * @name ExpenseVisualisation
 * @description A form used to edit an existing expense's report.
 *
 * @author Romaric Barthe
 *
 * @param {object}		expense		The expense object to update information from.
 */
const ExpenseVisualisation = ({ expense }) => {
	const { t } = useTranslation();

	const rows = useMemo(() => getLinesForVisualisation(expense), [expense]);

	const [optionAccount, setOptionAccount] = useState(false);
	const [optionDate, setOptionDate] = useState(false);
	const [optionLocalCurrency, setOptionLocalCurrency] = useState(false);
	const [optionProject, setOptionProject] = useState(false);

	const columnsHeadersToDisplay = useMemo(() => getExpenseColumnsHeaders(optionAccount, optionDate, optionLocalCurrency, optionProject).filter(
		(c) => c.label !== expenseColumnHeader.ACTIONS
	), [optionAccount, optionDate, optionLocalCurrency, optionProject]);

	// Display relevant columns
	useEffect(() => {
		if (expense.rows) {
			Object.entries(expense.rows).forEach((row) => {
				if (row[1].account !== null) {
					setOptionAccount(true);
				}
				if (row[1].date !== null) {
					setOptionDate(true);
				}
				if (row[1].currency !== null) {
					setOptionLocalCurrency(true);
				}
				if (row[1].project !== null) {
					setOptionProject(true);
				}
			});
		}
	}, [expense]);

	return (
		<div className="visualisation">
			<ExpenseTitle expense={expense} />

			<ExpenseActionBar expense={expense} />

			<div className="contents">
				<table>
					<thead>
						<tr>
							{columnsHeadersToDisplay.filter((elem) => elem.show).map((header) => {
								switch (header.label) {
									case expenseColumnHeader.LOCAL_NET_COST:
									case expenseColumnHeader.NET_COST:
									case expenseColumnHeader.TOTAL:
										return (
											<th key={header.label} className="right">
												{t(`expense.headers.${header.label}`)}
											</th>
										);
									default:
										return (
											<th key={header.label} className="left">
												{t(`expense.headers.${header.label}`)}
											</th>
										);
								}
							})}
						</tr>
					</thead>

					<tbody>
						{rows.map((row) => (
							<tr key={`tr.${row.rowId}`}>
								{columnsHeadersToDisplay.map((header) => {
									switch (header.label) {
										case expenseColumnHeader.ACCOUNT:
											return (<td key={`td.${row.rowId}.${header.label}`}>{row.account}</td>);
										case expenseColumnHeader.DATE:
											return (<td key={`td.${header}.${row.rowId}`}>{formatDate(row.date)}</td>);
										case expenseColumnHeader.DESIGNATION:
											return (<td key={`td.${row.rowId}.${header.label}`}>{row.designation}</td>);
										case expenseColumnHeader.PROJECT:
											return (<td key={`td.${row.rowId}.${header.label}`}>{row.project}</td>);
										case expenseColumnHeader.TOTAL:
											return (
												<td key={`td.${row.rowId}.${header.label}`} className="number">
													{row.netCost ? formatNumber(
														(convertStringToFloat(row.netCost) / ((100 + (convertStringToFloat(row.vatRate) ?? 0)) / 100)).toFixed(2),
														{ symbol: t(`currency.${expense.currency?.name}`) },
													) : ''}
												</td>
											);
										case expenseColumnHeader.LOCAL_NET_COST:
											return (
												<td key={`td.${row.rowId}.${header.label}`} className="number">
													{formatNumber(row.localNetCost, { symbol: t(`currency.${row.currency}`) })}
												</td>
											);
										case expenseColumnHeader.NET_COST:
											return (
												<td key={`td.${row.rowId}.${header.label}`} className="subtotal">
													{formatNumber(row.netCost, { symbol: t(`currency.${expense.currency.name}`) })}
												</td>
											);
										case expenseColumnHeader.VAT_RATE:
											return (
												<td key={`td.${row.rowId}.${header.label}`} className="number">
													{formatNumber(row.vatRate, { symbol: '%' })}
												</td>
											);
										default:
											return ('');
									}
								})}
							</tr>
						))}
					</tbody>

					<tfoot>
						<tr>
							{columnsHeadersToDisplay.map((header) => {
								switch (header.label) {
									case expenseColumnHeader.NET_COST:
										return (
											<td key={`total.${header.label}`} className="total">
												{formatNumber(expense.netTotal, { symbol: t(`currency.${expense.currency.name}`) })}
											</td>
										);
									default:
										return (<td key={`total.${header.label}`}> </td>);
								}
							})}
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	);
};

ExpenseVisualisation.propTypes = {
	expense: PropTypes.shape({
		editionNumber: PropTypes.number,
		archived: PropTypes.bool,
		reference: PropTypes.string,
		comment: PropTypes.string,
		currency: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		rows: PropTypes.object.isRequired,
		user: PropTypes.shape({
			id: PropTypes.string.isRequired,
			username: PropTypes.string.isRequired,
		}).isRequired,
		reversedExpense: PropTypes.shape({
			id: PropTypes.string,
			editionNumber: PropTypes.number,
		}),
		structure: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		id: PropTypes.string.isRequired,
		grossTotal: PropTypes.number,
		netTotal: PropTypes.number,
		vatTotal: PropTypes.number,
	}).isRequired,
};

export default ExpenseVisualisation;
