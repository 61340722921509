import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ExpenseCreationTypes } from 'constants/expenseEnums';
import update from 'immutability-helper';
import { getCurrencyName } from 'lib/currencies/formatCurrencyData';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fetchAllForExpense } from 'redux/actions/expenses/expenses';
import { addUser } from 'redux/actions/users';
import { useAllForExpenseSelector, useExpenseLoadingSelector } from 'redux/selectors/expenses/expenses';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import { v4 as uuid } from 'uuid';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Checkbox } from 'components/shared/forms/inputs';
import { Collapsible } from 'components/shared/layout';
import { sumOneObjectPropertyInArray } from 'components/shared/utils/functions';
import { UserCreationForm } from 'components/users';

import ExpenseGrid from './ExpenseGrid';
import ExpenseHeader from './ExpenseHeader';
import ExpenseTotalsTable from './ExpenseTotalsTable';
import { getExpenseColumnsHeaders, getRowTotals } from './functions';

/**
 * @name ExpenseCreationForm
 * @description A form used to create an expense report.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit			The method to trigger upon form submission.
 * @param {string}	creationType		The type of creation (empty, as a copy, as a reversal or from a quotation).
 * @param {string}	sourceExpenseId		The id of the base expense from which to create a new expense..
 */
const ExpenseCreationForm = ({ onSubmit, creationType, sourceExpenseId }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForExpenseForm = useAllForExpenseSelector();
	const accounts = useMemo(() => allForExpenseForm?.unarchivedAccounts ?? [], [allForExpenseForm]);
	const companies = useMemo(() => allForExpenseForm?.companies ?? [], [allForExpenseForm]);
	const currencies = useMemo(() => allForExpenseForm?.currencies ?? [], [allForExpenseForm]);
	const currentUser = useCurrentConnectedUserSelector();
	const expense = useMemo(() => allForExpenseForm?.expense ?? undefined, [allForExpenseForm]);
	const projects = useMemo(() => allForExpenseForm?.unarchivedProjects ?? [], [allForExpenseForm]);
	const users = useMemo(() => allForExpenseForm?.enabledUsers ?? [], [allForExpenseForm]);
	const vatRates = useMemo(() => allForExpenseForm?.unarchivedVatRates ?? [], [allForExpenseForm]);

	const isLoading = useExpenseLoadingSelector();

	const [optionAccount, setOptionAccount] = useState(false);
	const [optionDate, setOptionDate] = useState(false);
	const [optionLocalCurrency, setOptionLocalCurrency] = useState(false);
	const [optionProject, setOptionProject] = useState(false);

	const columnsHeadersToDisplay = useMemo(() => getExpenseColumnsHeaders(optionAccount, optionDate, optionLocalCurrency, optionProject),
		[optionAccount, optionDate, optionLocalCurrency, optionProject]);

	const [currency, setCurrency] = useState('');
	const currencyName = useMemo(() => getCurrencyName(currencies, currency), [currencies, currency]);
	const currencySymbol = useMemo(() => ((currencyName && currencyName !== '') ? t(`currency.${currencyName}`) : ''), [currencyName, t]);

	const [rows, setRows] = useState([]);
	const sortedRows = useMemo(() => rows.sort((a, b) => a.line - b.line), [rows]);

	const [duplicateRowId, setDuplicateRowId] = useState();
	const [originalRowData, setOriginalRowData] = useState();

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const [grossTotal, setGrossTotal] = useState(0);
	const [vatTotal, setVatTotal] = useState(0);
	const [netTotal, setNetTotal] = useState(0);
	const [rowsTotals, setRowsTotals] = useState([]);

	// !!Only for Header: Props to pass down to the select input so that it will display ancreation modal when clicking on the "+" icon.
	const userCreationModal = useFormModal(UserCreationForm, t('expense.edition.inputs.user.creation'), fetchAllForExpense, addUser);

	/**
	 * @function
	 * @name setTotals
	 * @description A function to refresh Total variables.
	 *
	 * @author Romaric Barthe
	 */
	const setTotals = useCallback(() => {
		const formData = formRef.current?.getFormValues();
		setRowsTotals(getRowTotals(formData?.rows, vatRates));
	}, [formRef, vatRates]);

	/**
	 * @function
	 * @name updateRow
	 * @description A function to add/ move/ delete/ duplicate a row.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	rowId			The id of the row triggering the action.
	 * @param {number}	lineNb			The number of the row triggering the action.
	 * @param {number}	direction		The direction if move: positive is for down, negative is for up.
	 * @param {string}	action			The possible action: 'add', 'move', 'duplicate', 'select'.
	 */
	const updateRow = useCallback((action, rowId = undefined, lineNb = undefined, direction = undefined) => {
		if (action === 'add') {
			const newId = uuid();
			const lines = rows.map((row) => row?.line) ?? [];
			const newRowNumber = _.isEmpty(lines) ? 1 : Math.max(...lines) + 1;
			setRows([...rows, { id: newId, line: newRowNumber }]);
		} else {
			if (rows.findIndex((row) => row.id === rowId) === undefined) {
				throw new Error('An issue occured with the current lines. Please refresh the page.');
			}
			if (action === 'move') {
				const rowIndexToMoveInDirection = rows.findIndex((row) => row.id === rowId);
				const rowIndexToMoveInOtherDirection = rows.findIndex((row) => row.line === lineNb + direction);
				setRows(update(rows, {
					[rowIndexToMoveInDirection]: {
						line: { $set: lineNb + direction },
					},
					[rowIndexToMoveInOtherDirection]: {
						line: { $set: lineNb },
					},
				}));
			}
			if (action === 'delete') {
				const rowIndexToRemove = rows.findIndex((row) => row.id === rowId);
				setRows(update(rows, {
					$splice: [[rowIndexToRemove, 1]],
					$apply: (newRows) => newRows.sort((a, b) => a.line - b.line).map((r, i) => update(r, { line: { $set: i + 1 } })),
				}));
				setRowsTotals(update(rowsTotals, {
					$apply: (newRows) => newRows.filter((row) => row.id !== rowId),
				}));
			}
			if (action === 'duplicate') {
				const newId = uuid();
				setRows(update(rows, {
					$apply: (newRows) => newRows.map((r) => (r.line > lineNb ? update(r, { line: { $set: r.line + 1 } }) : r)),
					$push: [{
						id: newId,
						line: lineNb + 1,
					}],
				}));
				const formData = formRef.current.getFormValues();
				setOriginalRowData(formData.rows[rowId]);
				setDuplicateRowId(newId);
			}
		}
	}, [formRef, rows, rowsTotals]);

	// Fetch data
	useEffect(() => {
		dispatch(fetchAllForExpense(sourceExpenseId));
	}, [dispatch, sourceExpenseId]);

	// Set default values
	const defaultValues = useMemo(() => {
		if (!expense || creationType === ExpenseCreationTypes.EMPTY) {
			return ({
				rows: { newLine: { line: 1, id: 'newLine' } },
				currency: currentUser?.company?.mainPartner?.currency?.id || '',
				grossTotal: 0,
				netTotal: 0,
				structure: currentUser?.company?.mainPartner?.id || null,
				user: currentUser?.id || null,
				vatTotal: 0,
			});
		}

		const flatRows = Object.fromEntries(update(expense.rows, {
			$apply: (newRows) => Object.entries(newRows).map((row) => update(row, {
				$apply: (newRow) => update(newRow, {
					1: {
						account: { $set: newRow[1].account?.id },
						currency: { $set: newRow[1].currency?.id },
						project: { $set: newRow[1].project?.id },
						vatRate: { $set: newRow[1].vatRate?.id },
					},
				}),
			})),
		}));

		return ({
			...expense,
			rows: flatRows,
			currency: expense.currency?.id || '',
			reversedExpense: (creationType && creationType !== ExpenseCreationTypes.REVERT && expense.reversedExpense?.id)
				|| creationType === ExpenseCreationTypes.REVERT ? expense.id : '',
			structure: expense.structure?.id || null,
			user: expense.user?.id || null,
		});
	}, [creationType, currentUser, expense]);

	// Display relevant columns
	useEffect(() => {
		if (expense?.rows) {
			Object.entries(expense.rows).forEach((row) => {
				if (row[1].account !== null) {
					setOptionAccount(true);
				}
				if (row[1].date !== null) {
					setOptionDate(true);
				}
				if (row[1].currency !== null) {
					setOptionLocalCurrency(true);
				}
				if (row[1].project !== null) {
					setOptionProject(true);
				}
			});
		}
	}, [expense]);

	// Set state's variables
	useEffect(() => {
		setCurrency(defaultValues?.currency);
		setGrossTotal(defaultValues?.grossTotal);
		setNetTotal(defaultValues?.netTotal);
		setVatTotal(defaultValues?.vatTotal);
		setRows(defaultValues?.rows ? Object.values(defaultValues.rows) : []);
		setRowsTotals(getRowTotals(defaultValues?.rows, vatRates));
	}, [defaultValues, vatRates]);

	// Refresh totals
	useEffect(() => {
		setGrossTotal(sumOneObjectPropertyInArray(rowsTotals, 'gt'));
		setVatTotal(sumOneObjectPropertyInArray(rowsTotals, 'vt'));
		setNetTotal(sumOneObjectPropertyInArray(rowsTotals, 'nt'));
	}, [rowsTotals]);

	return (
		((sourceExpenseId && expense) || !sourceExpenseId) && !isLoading ? (
			<DynamicForm
				ref={formRef}
				cleanFormData
				defaultValues={defaultValues}
				onSubmit={onSubmit}
				{...formProps}
			>

				<Collapsible titleKey="expense.creation.collapsed.general_informations">
					<ExpenseHeader
						companies={companies}
						currencies={currencies}
						creationType={creationType}
						defaultValues={defaultValues}
						isLoading={isLoading}
						sourceExpenseId={sourceExpenseId}
						userCreationModal={userCreationModal}
						users={users}
						setCurrency={setCurrency}
					/>
				</Collapsible>

				<ExpenseTotalsTable
					currencySymbol={currencySymbol}
					totalExpensesWithVat={netTotal}
					totalExpensesWithoutVat={grossTotal}
					totalVat={vatTotal}
				/>

				<Collapsible titleKey="expense.creation.collapsed.columns_options" collapsed>
					<div className="aside">
						<Checkbox
							label={t('expense.creation.options.local_currency')}
							name="navigation.optionLocalCurrency"
							labelFirst={false}
							checked={optionLocalCurrency}
							onChange={setOptionLocalCurrency}
						/>
						<Checkbox
							label={t('expense.creation.options.date')}
							name="navigation.optionDate"
							labelFirst={false}
							checked={optionDate}
							onChange={setOptionDate}
						/>
						<Checkbox
							label={t('expense.creation.options.project')}
							name="navigation.optionProject"
							labelFirst={false}
							checked={optionProject}
							onChange={setOptionProject}
						/>
						<Checkbox
							label={t('expense.creation.options.account')}
							name="navigation.optionAccount"
							labelFirst={false}
							checked={optionAccount}
							onChange={setOptionAccount}
						/>
					</div>
				</Collapsible>

				<Collapsible titleKey="expense.creation.collapsed.grid">
					<ExpenseGrid
						accounts={accounts}
						columnsHeadersToDisplay={columnsHeadersToDisplay}
						currencies={currencies}
						currencyName={currencyName}
						currencySymbol={currencySymbol}
						defaultValues={defaultValues}
						isLoading={isLoading}
						projects={projects}
						rows={sortedRows}
						vatRates={vatRates}
						// functions
						updateRow={updateRow}
						setTotals={setTotals}
						// duplicate logic
						duplicateRowId={duplicateRowId}
						originalRowData={originalRowData}
					/>

					<Button className="secondary" onClick={() => updateRow('add')}>
						<PlusCircle />
						{t('expense.creation.grid.add_row')}
					</Button>
				</Collapsible>

				<Button className="primary" type="submit" {...buttonProps}>{t('expense.creation.action')}</Button>
			</DynamicForm>
		) : t('expense.creation.loading')
	);
};

ExpenseCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	creationType: PropTypes.oneOf(
		Object.values(ExpenseCreationTypes),
	).isRequired,
	sourceExpenseId: PropTypes.string,
};

ExpenseCreationForm.defaultProps = {
	sourceExpenseId: '',
};

export default ExpenseCreationForm;
