import { useCallback, useMemo, useState } from 'react';
import { Plus, Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

import { TargetPartnerContactListItem, TargetPartnerContactListItemSkeleton } from './TargetPartnerContactListItem';

/**
 * @name TargetPartnerContactList
 * @description A component displaying a list of contacts linked to the current target partner.
 *
 * @author Timothée Simon-Franza
 *
 * @param {contact[]}	contacts					The array of contact linked to the current target partner.
 * @param {bool}		isDataFetchPending			Whether the data is being fetched.
 * @param {func}		onCreateContactButtonClick	The function to call when the create contact button is clicked.
 * @param {func}		onEditContactButtonClick	The function to call when the edit contact button is clicked.
 * @param {bool}		inPartnerVisualisation		Whether TargetPartnerContactList is called from partnerVisualisation
 */
const TargetPartnerContactList = ({ contacts, isDataFetchPending, onCreateContactButtonClick, onEditContactButtonClick, inPartnerVisualisation }) => {
	const { t } = useTranslation();
	const [searchString, setSearchString] = useState('');

	const filteredContacts = useMemo(() => (
		contacts.filter(({ firstName, lastName, position }) => (
			firstName?.toLowerCase().includes(searchString.toLowerCase())
			|| lastName?.toLowerCase().includes(searchString.toLowerCase())
			|| position?.name.toLowerCase().includes(searchString.toLowerCase())
		))
	), [contacts, searchString]);

	const onSearchInputChange = useCallback(({ target: { value } }) => {
		setSearchString(value);
	}, []);

	return (
		<section className="contacts">
			<div className="icon-input-wrapper leading-icon">
				<input type="search" placeholder={t('components.search.placeholder')} onChange={onSearchInputChange} disabled={isDataFetchPending} />
				<Search />
			</div>
			{!inPartnerVisualisation && (
				<Button aria-label={t('contact.creation.action')} onClick={onCreateContactButtonClick} disabled={isDataFetchPending}>
					<Plus />
				</Button>
			)}
			<ul style={inPartnerVisualisation ? { display: 'grid' } : {}}>
				{isDataFetchPending && (
					[...Array(5).keys()].map((index) => (<TargetPartnerContactListItemSkeleton key={index} />))
				)}

				{!isDataFetchPending && !contacts.length && (
					<p>{t('pages.operations.targetings.targets.target_partners.details.contacts.no_entry')}</p>
				)}

				{!isDataFetchPending && filteredContacts.length > 0 && (
					filteredContacts.map((contact, index) => (
						<TargetPartnerContactListItem
							key={contact.id}
							contact={contact}
							onEditContactButtonClick={() => onEditContactButtonClick(contact.id)}
							inPartnerVisualisation={inPartnerVisualisation}
							index={index}
						/>
					))
				)}
			</ul>
		</section>
	);
};

TargetPartnerContactList.propTypes = {
	contacts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string,
			lastName: PropTypes.string.isRequired,
			position: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string.isRequired,
			}),
			profilePicture: PropTypes.string,
		})
	).isRequired,
	isDataFetchPending: PropTypes.bool.isRequired,
	onCreateContactButtonClick: PropTypes.func,
	onEditContactButtonClick: PropTypes.func,
	inPartnerVisualisation: PropTypes.bool,
};

TargetPartnerContactList.defaultProps = {
	inPartnerVisualisation: false,
	onCreateContactButtonClick: undefined,
	onEditContactButtonClick: undefined,
};
export default TargetPartnerContactList;
