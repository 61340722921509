import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * @name Collapsible
 * @description A component used to fold content inside a collapsible element.
 *
 * @author Roland Margelidon
 * @author Timothée Simon-Franza
 *
 * @param {node}	children	The children to display inside the collapsible section.
 * @param {string}	[contentId]	The id to set to the collapsible-body div.
 * @param {string}	titleKey	The i18n key for the toggle section's title.
 */
const Collapsible = ({ children, collapsed, contentId, titleKey, ...otherProps }) => {
	const [isCollapsed, setIsCollapsed] = useState(collapsed);
	const { t } = useTranslation();
	const id = useMemo(() => contentId ?? `collapsible-body-${titleKey}`, [contentId, titleKey]);

	const toggleCollapse = useCallback(() => {
		setIsCollapsed((value) => !value);
	}, []);

	useEffect(() => setIsCollapsed(collapsed), [collapsed]);

	return (
		<div className="collapsible" {...otherProps}>
			<button
				className="collapsible-toggle"
				type="button"
				onClick={toggleCollapse}
				aria-expanded={!isCollapsed}
				aria-controls={id}
			>
				{t(titleKey)}
				<ChevronDown />
			</button>
			<div className="collapsible-body" id={id}>
				{children}
			</div>
		</div>
	);
};

Collapsible.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.element,
	]).isRequired,
	collapsed: PropTypes.bool,
	contentId: PropTypes.string,
	titleKey: PropTypes.string.isRequired,
};

Collapsible.defaultProps = {
	collapsed: false,
	contentId: undefined,
};

export default Collapsible;
