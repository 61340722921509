import { memo, useCallback, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isDev } from 'lib/shared/environmentHelper';
import PropTypes from 'prop-types';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { EditorFormat } from './components/format';
import optimizationChecker from './debug/optimizationChecker';
import stateChecker from './debug/stateChecker';
import stateLogger from './debug/stateLogger';
import { padNumber } from './functions/internals';
import ensureStateConsistency from './functions/internals/ensureStateConsistency';
import { resetDirtyFlag } from './reducer/actions';
import reducer from './reducer/reducer';
import { EditorContent, EditorDragLayer, EditorEntities, EditorHeader, EditorToolbox } from './components';
import EditorContext from './EditorContext';
import { getTitleNumbers } from './functions';

import 'styles/components/_templateEditor.scss';

/**
 * @name Editor
 * @description A multi-directional drag and drop document editor
 *
 * @author Yann Hodiesne
 *
 * @param {string}	initialData	 					Template initial data
 * @param {string}	onSubmit	 					Callback used when updating a template
 * @param {string}	saveAsCallback	 				Callback used when saving a template
 * @param {object} 	templateMetadata 				An object describing the template.
 * @param {string}	templateMetadata.description  	Template's description
 * @param {string}	templateMetadata.type			Template's type
 * @param {string}	templateMetadata.name			Template's name
 */
const Editor = ({ initialData, onSubmit, isSuperadmin, saveAsCallback, templateMetadata }) => {
	const { t } = useTranslation();

	const initialState = useMemo(() => {
		const state = ensureStateConsistency(initialData);

		return ({
			...state,
			templateDescription: templateMetadata.description,
			templateName: templateMetadata.name,
		});
	}, [initialData, templateMetadata.description, templateMetadata.name]);

	const [{
		elements,
		entities,
		margins,
		header,
		footer,
		selectedElements,
		pageNumberingStartIndex,
		movingElement,
		templateName,
		templateDescription,
		pages,
		isDirty,
	}, dispatch] = useReducer(
		isDev() ? stateLogger(stateChecker(optimizationChecker(reducer)), false) : reducer,
		initialState
	);

	const save = useCallback(async (isSilent = false) => {
		if (templateName === undefined || templateName === '') {
			toast.error(t('template.edition.toasts.missingname'));

			return Promise.reject({ ignore: true });
		}

		await onSubmit({
			content: {
				elements,
				entities,
				pages,
				margins,
				header,
				footer,
				pageNumberingStartIndex,
			},
			description: templateDescription,
			name: templateName,
			type: templateMetadata.type,
		},
		isSilent);

		dispatch(resetDirtyFlag());

		return Promise.resolve();
	}, [elements, entities, footer, header, margins, onSubmit, pageNumberingStartIndex, pages, t, templateDescription, templateMetadata.type, templateName]);

	const saveDuplicate = useCallback(async () => {
		if (templateName === undefined || templateName === '') {
			toast.error(t('template.edition.toasts.missingname'));

			return;
		}

		/* Creation of the timestamp YYYYMMDDHHMMSS to be added at the end of the duplicated template */
		const dateTimestamp = new Date();
		const dateString = ` ${dateTimestamp.getFullYear().toString()}${padNumber(dateTimestamp.getMonth() + 1, 2)}${padNumber(dateTimestamp.getDate(), 2)}`
			+ `${padNumber(dateTimestamp.getHours(), 2)}${padNumber(dateTimestamp.getMinutes(), 2)}${padNumber(dateTimestamp.getSeconds(), 2)}`;

		/* If the template was already duplicated (hence, having a timestamp), the former timestamp
		 * is cut and replaced by a new one. If it's a first duplicate, name and timestamp are combined */
		let duplicatedTemplateName = templateName + dateString;
		if (templateName.match(/\d{4}(1[0-2]|0[1-9])(3[01]|[12][0-9]|0[1-9])(2[0-4]|1[0-9]|0[1-9])([0-5][0-9])([0-5][0-9])$/)) {
			duplicatedTemplateName = templateName.substring(0, templateName.length - 15) + dateString;
		}

		const templateData = {
			description: templateDescription,
			name: duplicatedTemplateName,
			type: templateMetadata.type,
			content: {
				elements,
				entities,
				pages,
				header,
				footer,
				margins,
				pageNumberingStartIndex,
			},
		};

		const newTemplate = await saveAsCallback(templateData);

		if (newTemplate) {
			history.push(generatePath(routes.settings.templates.templatePdfEdition, { id: newTemplate.id }));
		}
	}, [elements, entities, footer, header, margins, pageNumberingStartIndex, pages, saveAsCallback, t, templateDescription, templateMetadata.type, templateName]);

	const titleNumbers = useMemo(() => getTitleNumbers(elements), [elements]);

	const editorContext = useMemo(() => ({
		dispatch,
		elements,
		entities,
		isDirty,
		isExporting: false,
		movingElement,
		pages,
		save,
		saveDuplicate,
		selectedElements,
		templateDescription,
		templateName,
		templateType: templateMetadata.type,
		header,
		footer,
		pageNumberingStartIndex,
		titleNumbers,
		margins,
		isSuperadmin,
	}), [elements, entities, isDirty, pages, save, saveDuplicate, selectedElements, pageNumberingStartIndex,
		movingElement, templateDescription, templateName, templateMetadata.type, header, footer, titleNumbers, margins, isSuperadmin]);

	return (
		<EditorContext.Provider value={editorContext}>
			<div className="template-editor">
				<EditorDragLayer />
				<EditorContent />
				<aside>
					<EditorHeader />
					<EditorToolbox />
					<EditorFormat />
					<EditorEntities />
				</aside>
			</div>
		</EditorContext.Provider>
	);
};

Editor.propTypes = {
	initialData: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	saveAsCallback: PropTypes.func.isRequired,
	templateMetadata: PropTypes.shape({
		description: PropTypes.string,
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	}).isRequired,
	isSuperadmin: PropTypes.bool.isRequired,
};

Editor.defaultProps = {
	initialData: {},
};

export default memo(Editor);
