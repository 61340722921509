import * as OffersApi from 'api/offersApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Offers redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of offers
	FETCH_OFFER_SELECT_LIST_REQUEST: '@OFFERS_SELECT/FETCH_LIST_REQUEST',
	FETCH_OFFER_SELECT_LIST_SUCCESS: '@OFFERS_SELECT/FETCH_LIST_SUCCESS',
	FETCH_OFFER_SELECT_LIST_FAILURE: '@OFFERS_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Offer list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchOffersSelectListRequest
 * @description Action triggered anytime a offersSelect list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchOffersSelectListRequest = () => ({ type: ActionTypes.FETCH_OFFER_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchOffersSelectListSuccess
 * @description Action triggered as a result to a successful offersSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	offersSelect	The list of retrieved offers.
 * @param {number}	totalCount		The total amount of offers available in the database to the current user.
 *
 * @returns {object}
 */
const fetchOffersSelectListSuccess = ({ offersSelect, totalCount }) => ({
	type: ActionTypes.FETCH_OFFER_SELECT_LIST_SUCCESS,
	payload: { offersSelect, totalCount },
});

/**
 * @function
 * @name fetchOffersSelectListFailure
 * @description Action triggered as a result to a failed offersSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchOffersSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_OFFER_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchOffersSelectList
 * @description Method used to update the offersSelect list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchOffersSelectList = (filters) => (dispatch) => {
	dispatch(fetchOffersSelectListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forSelect',
	};

	return OffersApi.fetchOffers(newFilters)
		.then(({ offersSelect, totalCount }) => dispatch(fetchOffersSelectListSuccess({ offersSelect, totalCount })))
		.catch((error) => dispatch(fetchOffersSelectListFailure(error)));
};
