import _ from 'lodash';

import { ActionTypes } from '../actions/globals';

const initialState = {
	breadcrumbs: [],
	navbar: {
		isExtended: false,
	},
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		// Breadcrumbs related actions
		case ActionTypes.PUSH_BREADCRUMB:
			return {
				...state,
				breadcrumbs: _.find(state.breadcrumbs, (location) => location.pathname === payload.pathname)
					? state.breadcrumbs
					: [...state.breadcrumbs, payload],
			};
		case ActionTypes.POP_TO_BREADCRUMB:
			return {
				...state,
				breadcrumbs: _.find(state.breadcrumbs, (location) => location.pathname === payload.pathname)
					? _.dropRight(state.breadcrumbs, state.breadcrumbs.length - _.findLastIndex(state.breadcrumbs, (location) => location.pathname === payload.pathname) - 1)
					: [...state.breadcrumbs, payload],
			};
		case ActionTypes.REPLACE_BREADCRUMB:
			return {
				...state,
				breadcrumbs: [..._.initial(state.breadcrumbs), payload],
			};
		case ActionTypes.RESET_BREADCRUMBS:
			return {
				...state,
				breadcrumbs: [payload],
			};
		default:
			return state;
	}
};
