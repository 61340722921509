import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Environments } from 'constants/environmentEnums';
import { formatExpenseName } from 'lib/expenses/formatExpenseData';
import { useNavigationBlock } from 'lib/hooks';
import { formatInvoiceName } from 'lib/invoices/formatInvoiceData';
import { formatQuotationName } from 'lib/quotations/formatQuotationData';
import { downloadFile } from 'lib/shared/fileHelper';
import { streamToBase64 } from 'lib/shared/streamHelper';
import _ from 'lodash';
import moment from 'moment';
import { editExpense } from 'redux/actions/expenses/expenses';
import { editInvoice } from 'redux/actions/invoices/invoices';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { editQuotation } from 'redux/actions/quotations/quotations';
import { addTemplateExport } from 'redux/actions/templateExports';
import { useCurrentExpenseSelector } from 'redux/selectors/expenses/expenses';
import { useCurrentInvoiceSelector } from 'redux/selectors/invoices/invoices';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';
import { useCurrentQuotationSelector } from 'redux/selectors/quotations/quotations';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { TextInput } from 'components/shared/inputs';
import PdfRenderer from 'components/templates/pdf/rendering/PdfRenderer';

import PdfBlobRenderer from '../../../rendering/PdfBlobRenderer';
import EntityTypes from '../../constants/EntityTypes';
import EditorContext from '../../EditorContext';
import { linkEntity, updateDescription, updateName } from '../../reducer/actions';

/**
 * @name EditorHeader
 * @description The editor's header, containing the title and actions buttons
 *
 * @author Yann Hodiesne
 * @author Florian Fornazaric
 */
const EditorHeader = () => {
	const { id: templateId } = useParams();
	const { t } = useTranslation();

	const globalDispatch = useDispatch();

	const user = useCurrentConnectedUserSelector();

	const descriptionInputRef = useRef();

	const {
		dispatch,
		editionDate,
		elements,
		pages,
		header,
		footer,
		lastEditionDate,
		margins,
		entities,
		save,
		saveDuplicate,
		templateDescription,
		pageNumberingStartIndex,
		templateName,
		isDirty,
		isExporting,
		fromExport,
		isSuperadmin,
	} = useContext(EditorContext);

	const [isSaving, setIsSaving] = useState(false);
	const [previewRequested, setPreviewRequested] = useState(false);
	const [isExportingPdf, setIsExportingPdf] = useState(false);
	const [uploadRequested, setUploadRequested] = useState(false);
	const [isDataUpdating, setIsDataUpdating] = useState(false);

	useEffect(() => {
		globalDispatch(fetchNumberFormatList({ rowsPerPage: 0 }));
	}, [globalDispatch]);

	const unblockNavigation = useNavigationBlock(t('template.pdf.editor.confirm_exit'), isDirty);

	const title = useMemo(() => templateName, [templateName]);
	const issuedEntity = useMemo(() => (
		entities.find((entity) => entity.shouldIssue && (
			entity.type === EntityTypes.INVOICE.type || entity.type === EntityTypes.EXPENSE.type || entity.type === EntityTypes.QUOTATION.type
		))
	), [entities]);

	const expense = useCurrentExpenseSelector(issuedEntity?.linkedEntity);
	const expenseRef = useRef(expense);

	const invoice = useCurrentInvoiceSelector(issuedEntity?.linkedEntity);
	const invoiceRef = useRef(invoice);

	const quotation = useCurrentQuotationSelector(issuedEntity?.linkedEntity);
	const quotationRef = useRef(quotation);

	const updateData = useCallback(async () => {
		if (issuedEntity) {
			setIsDataUpdating(true);
			if (expense) {
				await globalDispatch(editExpense(issuedEntity.linkedEntity, editionDate));
			}
			if (invoice) {
				await globalDispatch(editInvoice(issuedEntity.linkedEntity, editionDate));
			}
			if (quotation) {
				await globalDispatch(editQuotation(issuedEntity.linkedEntity, editionDate));
			}
		}
	}, [editionDate, expense, globalDispatch, invoice, issuedEntity, quotation]);

	const numberFormatExpense = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.EXPENSE)[0];
	const numberFormatInvoice = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.INVOICE)[0];
	const numberFormatQuotation = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.QUOTATION)[0];

	const getFileName = useCallback(() => {
		if (expense && numberFormatExpense) {
			return `${formatExpenseName(expense, numberFormatExpense, t)}.pdf`;
		}
		if (invoice && numberFormatInvoice) {
			return `${formatInvoiceName(invoice, numberFormatInvoice, t)}.pdf`;
		}
		if (quotation && numberFormatQuotation) {
			return `${formatQuotationName(quotation, numberFormatQuotation)}_${moment().format('YYYYMMDDHHmmss')}.pdf`;
		}

		return `${title}_${moment().format('YYYYMMDDHHmmss')}.pdf`;
	}, [expense, invoice, numberFormatExpense, numberFormatInvoice, numberFormatQuotation, quotation, title, t]);

	const openPreview = useCallback((blob) => {
		// Open the PDF inside a popup
		const url = URL.createObjectURL(blob);
		const win = window.open(url, '_blank');

		// If popups are blocked on this browser
		if (!win || win.closed || typeof win.closed === 'undefined') {
			toast.info(t('template.pdf.export.toasts.popups_blocked'));
		}

		setIsExportingPdf(false);
	}, [t]);

	const uploadExportSuccessRef = useRef(false);
	const uploadExport = useCallback(async (blob) => {
		const pdfContent = await streamToBase64(blob.stream());

		// Create a list of entities linked to this template
		// The backend will link the generated PDF to the expense/ invoices/ quotations in the database,
		// along with storing the linked entities in the export metadata
		const linkedEntities = entities.map(({ id, linkedEntity, type, shouldIssue }) => ({ id, linkedEntity, type, shouldIssue }));

		const templateExport = await globalDispatch(addTemplateExport({
			template: templateId,
			generatedPdf: pdfContent,
			generatedPdfName: getFileName(),
			linkedEntities,
			fromExport,
			editionDate,
		}));

		if (!templateExport) {
			setIsExportingPdf(false);

			return;
		}

		try {
			await downloadFile(blob.stream(), templateExport.generatedPdf.fileName);
			uploadExportSuccessRef.current = true;
		} catch (e) {
			uploadExportSuccessRef.current = false;
		} finally {
			setIsExportingPdf(false);
		}
	}, [editionDate, entities, fromExport, getFileName, globalDispatch, templateId]);

	const onPdfReady = useCallback(async (blob) => {
		if (uploadRequested) {
			setUploadRequested(false);
			await uploadExport(blob);
			if (issuedEntity && uploadExportSuccessRef.current) {
				if (expense) {
					history.push(generatePath(routes.humanResources.expenses.list));
				}
				if (invoice) {
					history.push(generatePath(routes.accounting.invoices.list));
				}
				if (quotation) {
					history.push(generatePath(routes.sales.quotations.list));
				}
			}
		} else if (previewRequested) {
			setPreviewRequested(false);
			openPreview(blob);
		}
	}, [expense, invoice, issuedEntity, openPreview, previewRequested, quotation, uploadExport, uploadRequested]);

	const previewTemplate = useCallback(() => {
		setPreviewRequested(true);
		setIsExportingPdf(true);
	}, []);

	// This allows us to update the expense data of the state + update the isDataUpdating state so the export can take place
	useEffect(() => {
		if (expense && !_.isEqual(expense, expenseRef.current) && isDataUpdating) {
			dispatch(linkEntity(issuedEntity.id, expense));

			setIsDataUpdating(false);
		}
		expenseRef.current = expense;
	}, [dispatch, entities, expense, isDataUpdating, issuedEntity]);

	// This allows us to update the invoice data of the state + update the isDataUpdating state so the export can take place
	useEffect(() => {
		if (invoice && !_.isEqual(invoice, invoiceRef.current) && isDataUpdating) {
			dispatch(linkEntity(issuedEntity.id, invoice));

			setIsDataUpdating(false);
		}
		invoiceRef.current = invoice;
	}, [dispatch, entities, invoice, isDataUpdating, issuedEntity]);

	// This allows us to update the quotation data of the state + update the isDataUpdating state so the export can take place
	useEffect(() => {
		if (quotation && !_.isEqual(quotation, quotationRef.current) && isDataUpdating) {
			dispatch(linkEntity(issuedEntity.id, quotation));

			setIsDataUpdating(false);
		}
		quotationRef.current = quotation;
	}, [dispatch, entities, quotation, isDataUpdating, issuedEntity]);

	const renderExport = useCallback(() => {
		updateData();

		setUploadRequested(true);
		setIsExportingPdf(true);
	}, [updateData]);

	const onNameInputChange = useCallback((event) => {
		dispatch(updateName(event.target.value));
	}, [dispatch]);

	const onNameInputBlur = useCallback(() => {
		if (templateName && templateName.trim() !== templateName) {
			dispatch(updateName(templateName.trim()));
		}
	}, [dispatch, templateName]);

	const onDescriptionInputChange = useCallback((event) => {
		dispatch(updateDescription(event.target.value));
	}, [dispatch]);

	const onDescriptionInputBlur = useCallback(() => {
		if (templateDescription && templateDescription.trim() !== templateDescription) {
			dispatch(updateDescription(templateDescription.trim()));
		}
	}, [dispatch, templateDescription]);

	/**
	 * @function
	 * @name onKeyDown
	 * @description A callback method to handle a keypress on enter for the name and description inputs.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onKeyDown = useCallback((event) => {
		if (event.key === 'Enter') {
			event.target.blur();

			if (event.target.name === 'name') {
				descriptionInputRef.current.focus();
			}
		}
	}, []);

	// Called when in the Edition page
	const saveTemplate = useCallback(async () => {
		setIsSaving(true);

		try {
			await save(false);
			unblockNavigation();
		} finally {
			setIsSaving(false);
		}
	}, [save, unblockNavigation]);

	// Called when in the Edition page
	const exportTemplate = useCallback(async () => {
		setIsSaving(true);

		try {
			// We save the current template state
			await save(true);
			unblockNavigation();
			history.push(generatePath(routes.settings.templates.templatePdfExport, { id: templateId }));
		} catch (error) {
			setIsSaving(false);
		}
	}, [save, templateId, unblockNavigation]);

	// Called when in the Export page
	const cancelExport = useCallback(() => {
		history.goBack();
	}, []);

	// Whether the export button should be enabled or not in the edition page
	const canGoToExportPage = useMemo(() => (
		elements.every((row) => (
			row.children.every((element) => (
				// Ensure every single element is either:
				// - not linked to an entity
				// - linked to an entity with a valid linkedEntity and linkedProperty
				(element.linkable && !!element.linkedEntity && !!element.linkedProperty) || !element.linkable
			))
		))
	), [elements]);

	// Whether the export button should be enabled or not in the export page
	const canExportPdfFile = useMemo(() => !previewRequested && entities.every(({ linkedEntity }) => !!linkedEntity)
		&& (!lastEditionDate || (new Date(editionDate) >= lastEditionDate)), [editionDate, entities, lastEditionDate, previewRequested]);
	// Whether the preview button should be enabled or not in the export page
	const canPreviewPdfFile = useMemo(() => !uploadRequested && entities.every(({ linkedEntity }) => !!linkedEntity)
		&& ((new Date(editionDate) >= lastEditionDate)), [editionDate, entities, lastEditionDate, uploadRequested]);

	return (
		<div className="editor-header">
			<div className="template-information">
				<TextInput
					className="seamless"
					name="name"
					onBlur={onNameInputBlur}
					onChange={onNameInputChange}
					onKeyDown={onKeyDown}
					placeholder={t('template.pdf.editor.header.name')}
					value={templateName ?? ''}
					disabled={isExporting}
				/>
				<TextInput
					ref={descriptionInputRef}
					className="seamless"
					name="description"
					onBlur={onDescriptionInputBlur}
					onChange={onDescriptionInputChange}
					onKeyDown={onKeyDown}
					placeholder={t('template.pdf.editor.header.description')}
					value={templateDescription ?? ''}
					disabled={isExporting}
				/>
			</div>
			<div className="actions">
				<ReactTooltip id="exportButton" place="bottom" type="dark" effect="solid" />
				{isExporting ? (
					<>
						<Button onClick={cancelExport} className="secondary">
							{t('template.pdf.editor.actions.cancel_export')}
						</Button>
						<Button onClick={previewTemplate} className="secondary" isLoading={isExportingPdf && previewRequested} disabled={!canPreviewPdfFile}>
							{t('template.pdf.editor.actions.preview')}
						</Button>
						<Button onClick={renderExport} className="primary" isLoading={isExportingPdf && uploadRequested} disabled={!canExportPdfFile}>
							{t('template.pdf.editor.actions.export')}
						</Button>
					</>
				) : (
					<>
						<Button onClick={saveDuplicate} className="secondary">
							{t('template.pdf.editor.actions.duplicate')}
						</Button>
						<Button
							onClick={saveTemplate}
							className="secondary"
							isLoading={isSaving}
						>
							{t('template.pdf.editor.actions.save')}
						</Button>
						{!isSuperadmin && (
							<Button
								onClick={exportTemplate}
								className="primary"
								data-tooltip-id="exportButton"
								data-tooltip-content={!canGoToExportPage ? t('template.pdf.editor.tooltips.missing_linked_entity') : undefined}
								isLoading={isSaving}
								disabled={!canGoToExportPage}
							>
								{t('template.pdf.editor.actions.export')}
							</Button>
						)}
					</>
				)}
			</div>
			{(previewRequested || uploadRequested) && !isDataUpdating && (
				<PdfBlobRenderer
					document={(
						<PdfRenderer
							title={title}
							creator={user.company.name}
							elements={elements}
							pages={pages}
							header={header}
							footer={footer}
							pageNumberingStartIndex={pageNumberingStartIndex}
							margins={margins}
							watermark={previewRequested ? t('template.pdf.export.preview_watermark') : undefined}
						/>
					)}
					getBlobCallback={onPdfReady}
				/>
			)}
		</div>
	);
};

export default memo(EditorHeader);
