import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useInvoicesSelectLoadingSelector
 * @description A selector to retrieve the loading state from the invoicesSelect redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {boolean}
 */
const useInvoicesSelectLoadingSelector = () => {
	const loading = useSelector((state) => state.invoicesSelect?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useInvoicesSelectListSelector
 * @description A selector to retrieve the invoicesSelect list from the invoicesSelect redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {Array}
 */
const useInvoicesSelectListSelector = () => {
	const invoices = useSelector((state) => (state.invoicesSelect?.items ?? []), shallowEqual);

	return invoices;
};

/**
 * @function
 * @name useInvoicesSelectTotalCountSelector
 * @description A selector to retrieve the totalCount value from the invoicesSelect redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {number|undefined}
 */
const useInvoicesSelectTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.invoicesSelect?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useInvoicesSelectListSelector,
	useInvoicesSelectLoadingSelector,
	useInvoicesSelectTotalCountSelector,
};
