import { useTranslation } from 'react-i18next';
import { formatContactName, formatContactNameWithTitle } from 'lib/contacts/formatContactData';

/**
 * @function
 * @name formatPartnerParent
 * @description Returns the provided partner's parent's name if there is one.
 *
 * @author Romaric Barthe
 *
 * @param {object} partner		The partner serialised.
 *
 * @returns {string}
 */
export const formatPartnerParent = (partner) => (partner.parent?.name ?? '');

/**
 * @function
 * @name formatLegalRepresentantName
 * @description Returns the provided partner's legal representant's name if there is one.
 *
 * @author Romaric Barthe
 *
 * @param {object} partner		The partner serialised.
 *
 * @returns {string}
 */
export const formatLegalRepresentantName = (partner) => ((partner.legalRepresentant && formatContactName(partner.legalRepresentant)) ?? '');

/**
 * @function
 * @name formatLegalRepresentantNameWithTitle
 * @description Returns the provided partner's legal representant's name and title if there is one.
 *
 * @author Romaric Barthe
 *
 * @param {object} partner		The partner serialised.
 *
 * @returns {string}
 */
export const formatLegalRepresentantNameWithTitle = (partner) => ((partner.legalRepresentant && formatContactNameWithTitle(partner.legalRepresentant)) ?? '');

/**
 * @function
 * @name getRegistration
 * @description Returns the provided partner's legal representant's name and title if there is one.
 *
 * @author Romaric Barthe
 *
 * @param {object} partner				The partner serialised.
 * @param {string} registrationType		The registration's type.
 *
 * @returns {string}
 */
export const getRegistration = (partner, registrationType) => {
	const registrations = partner?.registrations;
	if (registrations === undefined) {
		return '';
	}

	const registration = registrations.filter((a) => a.type?.name === registrationType)[0];
	if (registration === undefined) {
		return '';
	}

	return registration.name;
};

/**
 * @function
 * @name formatRelationTypesName
 * @description Returns the provided relationType's name.
 *
 * @author Audrey Clerc
 *
 * @param {object} relationType		The relationType to be formatted.
 *
 * @returns {string}
 */
export const formatRelationTypesName = (relationType) => (relationType.name);

/**
 * @function
 * @name FormatPhoneNumber
 * @description Returns the provided relationType's name.
 *
 * @author Audrey Clerc
 *
 * @param {object} phoneNumber		The phoneNumber to be formatted.
 *
 * @returns {string}
 */
export const FormatPhoneNumber = (phoneNumber) => {
	const { t } = useTranslation();

	return (`${t(`contact.creation.inputs.phone_type.${phoneNumber.type}`)} : ${phoneNumber.number}`);
};
