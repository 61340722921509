import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { BodyCopy } from 'components/shared/Typography';

import { TextInput } from '../inputs';

import Modal from './Modal';
import ModalFooter from './ModalFooter';

/**
 * @name TextInputModal
 * @description Asks the user for confirmation with the given message
 *
 * @author Yann Hodiesne
 *
 * @param {bool}	isShowing	 	Determines if the modal is currently being shown to the user
 * @param {string}	message		 	The message displayed to the user
 * @param {func}	confirm		 	The function to execute when the user clicks on confirm
 * @param {func}	cancel		 	The function to execute when the user clicks on cancel
 * @param {string}	confirmText	 	The text displayed inside the confirmation button
 * @param {string}	cancelText	 	The text displayed inside the cancel button
 * @param {string}	titleText	 	The text displayed inside the confirmation button
 * @param {string}	defaultValue	The default value to populate the text input with
 */
const TextInputModal = ({ isShowing, message, confirm, cancel, confirmText, cancelText, titleText, defaultValue }) => {
	const { t } = useTranslation();

	const textInputRef = useRef();

	const handleConfirm = useCallback(() => {
		confirm(textInputRef.current.value.trim());
	}, [confirm]);

	return (
		<Modal isShowing={isShowing} title={titleText}>
			{message && <BodyCopy ml="1em">{message}</BodyCopy>}
			<TextInput ref={textInputRef} defaultValue={defaultValue} data-testid="modal-text-input" />
			<ModalFooter>
				<Button onClick={cancel}>
					{cancelText || t('components.text_input_modal.cancel')}
				</Button>
				<Button className="primary" onClick={handleConfirm}>
					{confirmText || t('components.text_input_modal.confirm')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

TextInputModal.propTypes = {
	isShowing: PropTypes.bool.isRequired,
	message: PropTypes.string,
	confirm: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	confirmText: PropTypes.string,
	cancelText: PropTypes.string,
	titleText: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
};

TextInputModal.defaultProps = {
	message: undefined,
	confirmText: undefined,
	cancelText: undefined,
	defaultValue: undefined,
};

export default TextInputModal;
