import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTargeting } from 'redux/actions/targetings/targetings';
import { fetchTarget, fetchTargetStats, updateTarget } from 'redux/actions/targets/targets';
import { useCurrentTargetingSelector } from 'redux/selectors/targetings/targetings';
import { useCurrentTargetSelector, useCurrentTargetStatsSelector } from 'redux/selectors/targets/targets';

import { TargetEditionForm } from 'components/targetings/targets';
import TargetHeader from 'components/targetings/targets/TargetHeader';

import { AccessRights, useAccessRight } from '../../../lib/shared/accessRights';

import 'styles/pages/targetingDetailsPages.scss';

/**
 * @name TargetEditionPage
 * @description A page used to edit an existing target.
 *
 * @author Romaric Barthe
 */
const TargetEditionPage = () => {
	const { t } = useTranslation();
	const { targetId, targetingId } = useParams();
	const dispatch = useDispatch();
	const target = useCurrentTargetSelector(targetId);
	const targetStats = useCurrentTargetStatsSelector();
	const targeting = useCurrentTargetingSelector(targetingId);

	const canEditTarget = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING) && !targeting?.archived;

	useEffect(() => {
		dispatch(fetchTarget(targetId));
		dispatch(fetchTargetStats(targetId));
		dispatch(fetchTargeting(targetingId));
	}, [dispatch, targetId, targetingId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditTarget) {
			const targetData = {
				...formData,
				targetingId,
			};

			dispatch(updateTarget(targetData, targetId));
			dispatch(fetchTarget(targetId));
			dispatch(fetchTargetStats(targetId));
		}
	}, [canEditTarget, targetingId, dispatch, targetId]);

	return target && targetingId && targetId
		? (
			<div className="targeting-details-page">
				<div className="targeting-details-targeting-description">
					<TargetHeader targetingId={targetingId} targetId={targetId} targetStats={targetStats} />
				</div>
				<TargetEditionForm target={target} onSubmit={handleFormSubmit} />
			</div>
		)
		: <p>{t('targeting.target.edition.loading')}</p>;
};

export default TargetEditionPage;
