import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Percent } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useForwardedRef } from 'lib/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';

/**
 * @name NumberInput
 * @description A custom number input component
 *
 * @author Yann Hodiesne
 *
 * @param {bool}	[allowNull=false]			Whether the input is initialized empty.
 * @param {bool}	[disabled=false]			Whether the input is disabled.
 * @param {func}	[onChange]					The method to trigger whenever the input's value changes.
 * @param {string}	[icon=undefined]			The input's icon, can be undefined, price or percentage.
 * @param {number}	[min=undefined]				The minimum value.
 * @param {number}	[max=undefined]				The maximum value.
 * @param {number}	[step=1]					The step value.
 * @param {number}	[decimalScale=0]			The number of decimal digits to display.
 * @param {bool}	[fixedDecimalScale=false]	Whether to show trailing zeros or not.
 */
const NumberInput = forwardRef(
	({ allowNull, disabled, icon, step, min, max, decimalScale, fixedDecimalScale, onChange, onBlur, value: valueProp, defaultValue, ...otherProps }, ref) => {
		const resolvedRef = useForwardedRef(ref);

		const { t } = useTranslation();

		const initialValue = useMemo(() => {
			const defValue = defaultValue === '' ? null : defaultValue;

			return valueProp ?? defValue ?? (allowNull ? null : min ?? 0);
		}, [allowNull, defaultValue, min, valueProp]);
		const [value, setValue] = useState(initialValue);

		/**
		 * @name ResolvedIcon
		 * @description The icon to display, can be undefined, price or percentage.
		 *
		 * @author Yann Hodiesne
		 */
		const ResolvedIcon = useMemo(() => {
			switch (icon) {
				case undefined:
					// eslint-disable-next-line react/display-name
					return () => null;
				case 'percentage':
					return Percent;
				case 'CHF':
				case 'EUR':
				case 'GBP':
				case 'USD':
					// eslint-disable-next-line react/display-name
					return () => (<span className="string-currency-icon">{icon && icon !== '' ? t(`currency.${icon}`) : ''}</span>);
				default:
					// eslint-disable-next-line react/display-name
					return () => null;
			}
		}, [icon, t]);

		/**
		 * @function
		 * @name onChangeHandler
		 * @description The method to trigger whenever the input's value changes.
		 *
		 * @author Yann Hodiesne
		 *
		 * @param {number}	event.floatValue	The new value.
		 */
		const onChangeHandler = useCallback(({ floatValue: newValue }) => {
			if (value === newValue) {
				return;
			}

			const result = newValue ?? (allowNull ? null : 0);

			setValue(result);
			onChange?.({ target: { value: result } });
		}, [allowNull, onChange, value]);

		// Set the value to the received value prop when it changes
		const oldValueProp = useRef(valueProp);

		useEffect(() => {
			if (valueProp !== oldValueProp.current) {
				oldValueProp.current = valueProp;
				setValue(valueProp);
			}
		}, [valueProp]);

		/**
		 * @function
		 * @name onKeyDownHandler
		 * @description Increases or decreases the value by the step value when pressing ArrowUp or ArrowDown.
		 *
		 * @author Yann Hodiesne
		 *
		 * @param {object}	event	The event object.
		 */
		const onKeyDownHandler = useCallback((event) => {
			if (event.code === 'ArrowUp' || event.code === 'ArrowDown') {
				event.preventDefault();

				const increase = event.code === 'ArrowUp';
				let newValue = value + (increase ? step : -step);

				if (increase && _.isNumber(max) && newValue > max) {
					newValue = max;
				}

				if (!increase && _.isNumber(min) && newValue < min) {
					newValue = min;
				}

				setValue(newValue);
				onChangeHandler({ floatValue: newValue });
			}
		}, [max, min, onChangeHandler, step, value]);

		/**
		 * @function
		 * @name onBlurHandler
		 * @description Ensures the value is between the maximum and the minimum when losing focus.
		 *
		 * @author Yann Hodiesne
		 */
		const onBlurHandler = useCallback(() => {
			if (_.isNumber(max) && value > max) {
				onChangeHandler({ floatValue: max });
			} else if (_.isNumber(min) && value < min) {
				onChangeHandler({ floatValue: min });
			}

			onBlur?.();
		}, [max, min, onBlur, onChangeHandler, value]);

		return (
			<>
				<NumericFormat
					{...otherProps}
					disabled={disabled}
					value={value}
					onValueChange={onChangeHandler}
					onKeyDown={onKeyDownHandler}
					onBlur={onBlurHandler}
					autoComplete="off"
					thousandsGroupStyle="thousand"
					decimalSeparator={t('components.number_input.decimal_separator')}
					thousandSeparator={t('components.number_input.thousand_separator')}
					decimalScale={decimalScale}
					fixedDecimalScale={fixedDecimalScale}
					displayType="input"
					type="text"
					data-inputtype="number"
					allowNegative={min ? min < 0 : true}
					allowLeadingZeros={false}
				/>
				<input ref={resolvedRef} type="hidden" value={value ?? initialValue ?? ''} readOnly />
				<ResolvedIcon />
			</>
		);
	}
);

NumberInput.displayName = 'NumberInput';

NumberInput.propTypes = {
	allowNull: PropTypes.bool,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	icon: PropTypes.string,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	decimalScale: PropTypes.number,
	fixedDecimalScale: PropTypes.bool,
	value: PropTypes.number,
	defaultValue: PropTypes.number,
};

NumberInput.defaultProps = {
	allowNull: false,
	disabled: false,
	onChange: undefined,
	onBlur: undefined,
	icon: undefined,
	min: undefined,
	max: undefined,
	step: 1,
	decimalScale: 0,
	fixedDecimalScale: false,
	value: undefined,
	defaultValue: undefined,
};

/**
 * @name DefaultNumberInput
 * @description The default exported version of the Number input component.
 * @author Timothée Simon-Franza
 *
 * @param {*} props
 */
const DefaultNumberInput = ({ disabled, icon, leadingIcon, ...otherProps }) => (
	<div
		className={`${icon ? 'icon-' : ''}input-wrapper${icon && leadingIcon ? ' leading-icon' : ''}`}
	>
		<NumberInput disabled={disabled} icon={icon} {...otherProps} />
	</div>
);

DefaultNumberInput.displayName = 'NumberInput';

DefaultNumberInput.propTypes = {
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	leadingIcon: PropTypes.bool,
};

DefaultNumberInput.defaultProps = {
	disabled: false,
	icon: undefined,
	leadingIcon: false,
};

export default DefaultNumberInput;

export {
	NumberInput,
};
