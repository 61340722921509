import * as QuotationsApi from 'api/quotationsApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Quotations redux state
 * @type {object}
 *
 * @author Florian Fornazaric
 */
export const ActionTypes = {
	// Fetch a list of quotations
	FETCH_QUOTATION_FOR_LIST_REQUEST: '@QUOTATIONS_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_QUOTATION_FOR_LIST_SUCCESS: '@QUOTATIONS_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_QUOTATION_FOR_LIST_FAILURE: '@QUOTATIONS_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Quotation list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchQuotationsForListRequest
 * @description Action triggered anytime a quotationsList list fetching call is made to the API.
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
const fetchQuotationsForListRequest = () => ({ type: ActionTypes.FETCH_QUOTATION_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchQuotationsForListSuccess
 * @description Action triggered as a result to a successful quotationsList list fetching API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object}	quotationsList	The list of retrieved quotations.
 * @param {number}	totalCount		The total amount of quotations available in the database to the current user.
 *
 * @returns {object}
 */
const fetchQuotationsForListSuccess = ({ quotationsList, totalCount }) => ({
	type: ActionTypes.FETCH_QUOTATION_FOR_LIST_SUCCESS,
	payload: { quotationsList, totalCount },
});

/**
 * @function
 * @name fetchQuotationsForListFailure
 * @description Action triggered as a result to a failed quotationsList list fetching API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchQuotationsForListFailure = (error) => ({
	type: ActionTypes.FETCH_QUOTATION_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchQuotationsForList
 * @description Method used to update the quotationsList list.
 *
 * @author Florian Fornazaric
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchQuotationsForList = (filters) => (dispatch) => {
	dispatch(fetchQuotationsForListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forList',
	};

	return QuotationsApi.fetchQuotations(newFilters)
		.then(({ quotationsList, totalCount }) => dispatch(fetchQuotationsForListSuccess({ quotationsList, totalCount })))
		.catch((error) => dispatch(fetchQuotationsForListFailure(error)));
};
