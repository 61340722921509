import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addRegistration } from 'redux/actions/registrations';
import routes from 'routes';

import { RegistrationCreationForm } from 'components/registrations';

/**
 * @name RegistrationCreationPage
 * @description A page used to create a new number format.
 *
 * @author Romaric Barthe
*/
const RegistrationCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addRegistration(formData, routes.commonModules.registrations.list));
	}, [dispatch]);

	return (
		<RegistrationCreationForm onSubmit={handleFormSubmit} />
	);
};

export default RegistrationCreationPage;
