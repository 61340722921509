import { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DefaultSizes } from '../editor/constants';
import { extractCapabilitiesProperties, getElementType } from '../editor/functions/internals';

import ShowLabel from './ShowLabel';

const css = {
	wordBreak: 'break-word',
	wordSpacing: 'normal',
	fontWeight: 400,
};

/**
 * @name ElementRenderer
 * @description A component used to dynamically render a pdf version of an element, including its capabilities.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*} param0
 *
 * @returns
 */
const ElementRenderer = ({ element, rendererGlobals, pageIndex }) => {
	const elementType = useMemo(() => getElementType(element), [element]);
	const capabilitiesProperties = extractCapabilitiesProperties(element);
	const ResolvedComponent = getElementType(element)?.meta?.component ?? null;

	const { id, content, type, size } = element;

	if (elementType === null) {
		throw new Error(`Element type ${type} is not supported`);
	}

	if (ResolvedComponent === null) {
		throw new Error(`Element type ${type} is not supported`);
	}

	if (!_.isFunction(ResolvedComponent.PdfElement)) {
		throw new Error(`Element type ${type} does not have a pdfElement`);
	}

	const textDecoration = useMemo(() => {
		const result = [];
		if (!element.format) {
			return result;
		}

		Object.keys(element.format).forEach((key) => {
			switch (key) {
				case 'underline':
					if (element.format[key]) {
						result.push('underline');
					}
					break;
				case 'lineThrough':
					if (element.format[key]) {
						result.push('line-through');
					}
					break;
				default:
					break;
			}
		});

		return result;
	}, [element.format]);

	const alignment = useMemo(() => {
		switch (element.format?.alignment) {
			case 'end':
				return 'right';
			case 'start':
				return 'left';
			default:
				return element.format?.alignment;
		}
	}, [element.format?.alignment]);

	const style = useMemo(() => ({
		...css,
		fontSize: `${(element.format?.fontSize ?? DefaultSizes.FONT_SIZE) * 0.85}px`,
		textDecoration: textDecoration.join(' '),
		fontWeight: element.format?.bold ? 'bold' : 'normal',
		fontStyle: element.format?.italic ? 'italic' : 'normal',
		textAlign: alignment,
		width: size?.x,
		height: size?.y,
		color: element.format?.fontColor,
	}), [alignment, element.format?.bold, element.format?.fontColor, element.format?.fontSize, element.format?.italic, size?.x, size?.y, textDecoration]);

	return (
		<ShowLabel {...capabilitiesProperties}>
			<ResolvedComponent.PdfElement
				element={element}
				key={id}
				rendererGlobals={rendererGlobals}
				pageIndex={pageIndex}
				style={style}
			>
				{!_.isObject(content) && content}
			</ResolvedComponent.PdfElement>
		</ShowLabel>
	);
};

ElementRenderer.propTypes = {
	element: PropTypes.shape({
		id: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		// eslint-disable-next-line react/forbid-prop-types
		content: PropTypes.any,
		size: PropTypes.shape({
			x: PropTypes.number.isRequired,
			y: PropTypes.number.isRequired,
		}),
		format: PropTypes.object,
		titleLevel: PropTypes.string,
	}).isRequired,
	rendererGlobals: PropTypes.object.isRequired,
	pageIndex: PropTypes.number.isRequired,
};

export default ElementRenderer;
