import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, TextInput } from 'components/shared/inputs';

import { MarginsDefaults } from '../../../constants';
import { MarginsOptions } from '../../../constants/MarginsDefaults';
import EditorContext from '../../../EditorContext';
import { updateMargins, updatePageNumberingStartIndex } from '../../../reducer/actions';

/**
 * @name Page
 * @description Component to manage the pages of the PDF editor
 *
 * @author Florian Fornazaric
 */
const Page = () => {
	const { t } = useTranslation();
	const { margins, pages, dispatch, pageNumberingStartIndex } = useContext(EditorContext);

	const [selectedPageNumberingStartValue, setSelectedPageNumberingStartValue] = useState(
		{
			label: `Page ${pageNumberingStartIndex + 1}`,
			value: pageNumberingStartIndex,
		}
	);

	const marginsOptions = useMemo(() => (
		Object.entries(MarginsOptions).map(([value]) => ({
			label: t(`template.pdf.margins.${value}`),
			value,
		}))
	), [t]);

	const pageNumberingOptions = useMemo(() => pages.map((p, pageIndex) => ({ label: `Page ${pageIndex + 1}`, value: p.id })), [pages]);

	const [selectedMarginsValue, setSelectedMarginsValue] = useState(marginsOptions.find((option) => option.value === margins.type));

	useEffect(() => {
		if (selectedMarginsValue.value !== margins.type) {
			setSelectedMarginsValue(marginsOptions.find((option) => option.value === margins.type));
		}
	}, [margins.type, marginsOptions, selectedMarginsValue?.value]);

	const handlePageNumberingChange = useCallback((value) => {
		dispatch(updatePageNumberingStartIndex(pages.findIndex((page) => page.id === value.value)));
	}, [dispatch, pages]);

	// Allows us to update the selected values when the pages change
	useEffect(() => {
		setSelectedPageNumberingStartValue(pageNumberingOptions.find((option) => option.value === pages[pageNumberingStartIndex]?.id));
	}, [pageNumberingOptions, pageNumberingStartIndex, pages]);

	const handleMarginsSelectChange = useCallback((event) => {
		if (event.value !== MarginsOptions.CUSTOM) {
			dispatch(updateMargins({ ...MarginsDefaults[event.value], type: event.value }));
		} else {
			dispatch(updateMargins({ type: event.value }));
		}
	}, [dispatch]);

	const handleSizeChange = useCallback((event) => {
		const updatedValue = {};
		const key = event.target.id;

		const parsedValue = parseInt(event.target.value, 10);

		if (parsedValue < 0 || Number.isNaN(parsedValue)) {
			updatedValue[key] = 0;
		} else {
			updatedValue[key] = Math.min(144, parsedValue);
		}

		if (margins[key] !== updatedValue[key]) {
			dispatch(updateMargins(updatedValue));
		}
	}, [dispatch, margins]);

	return (
		<div className="tab-options-list">
			<div className="input-wrapper">
				<label htmlFor="start-numbering-from-page">
					{t('template.pdf.format.start_numbering_from_page')}
				</label>
				<Select id="start-numbering-from-page" options={pageNumberingOptions} value={selectedPageNumberingStartValue} onChange={handlePageNumberingChange} />
			</div>

			<div className="input-wrapper">
				<label htmlFor="margin-size">{t('template.pdf.margins.type')}</label>
				<Select id="margin-size" options={marginsOptions} value={selectedMarginsValue} onChange={handleMarginsSelectChange} />
			</div>
			{margins.type === 'CUSTOM' && (
				<>
					<div className="input-wrapper">
						<label htmlFor="top">{t('template.pdf.margins.top')}</label>
						<TextInput id="top" value={margins.top} onChange={handleSizeChange} />
					</div>
					<div className="input-wrapper">
						<label htmlFor="right">{t('template.pdf.margins.right')}</label>
						<TextInput id="right" value={margins.right} onChange={handleSizeChange} />
					</div>
					<div className="input-wrapper">
						<label htmlFor="bottom">{t('template.pdf.margins.bottom')}</label>
						<TextInput id="bottom" value={margins.bottom} onChange={handleSizeChange} />
					</div>
					<div className="input-wrapper">
						<label htmlFor="left">{t('template.pdf.margins.left')}</label>
						<TextInput id="left" value={margins.left} onChange={handleSizeChange} />
					</div>
				</>
			)}
		</div>
	);
};

export default Page;
