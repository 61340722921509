import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import { HiddenInput, NumberInput } from 'components/shared/forms/inputs';

/**
 * @name QuotationGridArchiRow
 * @description A form used to edit an existing quotation's information.
 *
 * @author Romaric Barthe
 *
 * @param {string}	currencySymbol			The symbol of the currency.
 * @param {bool}	readOnly				Whether the quotation is read-only.
 * @param {object}	row						The current row of the quotation.
 * @param {object}	stakeholder				The current combinations sector/ partner in column.
 * @param {object}	stakeholderTotals		The current totals.
 * @param {func}	setTotals				The method to set the totals of the quotation.
 * @param {func}	originalRowData			If set, the data of the row that is duplicated.
 */
const QuotationGridArchiRow = ({
	currencySymbol,
	defaultValues,
	readOnly,
	row,
	stakeholder,
	stakeholderTotals,
	// functions
	setTotals,
	// duplicate logic
	originalRowData,
}) => {
	const [offerRate, setOfferRate] = useState(defaultValues?.rows?.[row.id]?.stakeholders?.[stakeholder.id]?.rate ?? 0);

	const isInitialized = useRef(false);

	const rateRef = useRef();

	useEffect(() => {
		const stakeColumn = stakeholder?.id;
		if (!isInitialized.current && stakeColumn && originalRowData && originalRowData.stakeholders[stakeColumn]) {
			isInitialized.current = true;

			if (!originalRowData) {
				return;
			}

			rateRef.current?.setValue(originalRowData.stakeholders[stakeColumn].rate);
		}
	}, [originalRowData, stakeholder.id]);

	const setOfferRateValue = useCallback((value) => {
		setOfferRate(value);
		setTotals();
	}, [setOfferRate, setTotals]);

	const cellValue = useMemo(() => (offerRate / 100 ?? 0) * (stakeholderTotals?.partnerProjectAmount ?? 0)
		* (stakeholderTotals?.partnerRatio ?? 0) * (1 - (stakeholderTotals?.partnerDiscount ?? 0)),
	[offerRate, stakeholderTotals]);

	return (
		<Fragment key={`td.${row.id}.${stakeholder.id}`}>
			<td>
				<NumberInput
					ref={rateRef}
					allowNull
					className="number-input"
					decimalScale={2}
					fixedDecimalScale
					icon="percentage"
					label=""
					max={100}
					min={0}
					name={`rows.${row.id}.stakeholders.${stakeholder.id}.rate`}
					onChange={setOfferRateValue}
					readOnly={readOnly}
					step={0.0001}
				/>
				<HiddenInput
					name={`rows.${row.id}.stakeholders.${stakeholder.id}.columnId`}
					value={stakeholder.id}
				/>
				<HiddenInput
					name={`rows.${row.id}.stakeholders.${stakeholder.id}.rowId`}
					value={row.id}
				/>
				<HiddenInput
					name={`rows.${row.id}.stakeholders.${stakeholder.id}.column`}
					value={stakeholder.column}
				/>
				<HiddenInput
					name={`rows.${row.id}.stakeholders.${stakeholder.id}.line`}
					value={row.line}
				/>
			</td>
			<td>
				<div className="row-total-right">
					{ formatNumber(cellValue, { symbol: currencySymbol }) }
				</div>
			</td>
		</Fragment>
	);
};

QuotationGridArchiRow.propTypes = {
	currencySymbol: PropTypes.string,
	defaultValues: PropTypes.object,
	readOnly: PropTypes.bool,
	row: PropTypes.shape({
		id: PropTypes.string,
		line: PropTypes.number,
	}).isRequired,
	stakeholder: PropTypes.shape({
		id: PropTypes.string,
		rate: PropTypes.number,
		column: PropTypes.number,
	}).isRequired,
	stakeholderTotals: PropTypes.shape({
		id: PropTypes.string,
		partnerProjectAmount: PropTypes.number,
		partnerRatio: PropTypes.number,
		partnerDiscount: PropTypes.number,
	}).isRequired,
	// functions
	setTotals: PropTypes.func.isRequired,
	// duplicate logic,
	originalRowData: PropTypes.object,
};

QuotationGridArchiRow.defaultProps = {
	currencySymbol: '',
	defaultValues: undefined,
	readOnly: false,
	originalRowData: undefined,
};

export default QuotationGridArchiRow;
