/**
 * @function
 * @name sumOneObjectPropertyInArray
 * @description A function to sum one property's value of an object placed in an array.
 *
 * @author Romaric Barthe
 *
 * @param {array}	items				The array listing the object(s).
 * @param {string}	prop				The name of the property to be sumed.
 *
 * @returns {float} returns the total.
 */
export const sumOneObjectPropertyInArray = (items, prop) => items.reduce((a, b) => a + b[prop], 0);

/**
 * @function
 * @name sumOneObjectPropertyGroupByInArray
 * @description A function to sum one property's value of an object placed in an array.
 *
 * @author Romaric Barthe
 *
 * @param {array}	items				The array listing the object(s).
 * @param {string}	groupedProp			The name of the property to be groupBy.
 * @param {string}	summedProp			The name of the property to be sumed.
 *
 * @returns {float} returns the total.
 */
export const sumOneObjectPropertyGroupByInArray = (items, groupedProp, summedProp) => items.reduce((acc, item) => {
	const groupedProperty = item[groupedProp].name;
	const summedProperty = item[summedProp];

	if (!acc[groupedProperty]) {
		acc[groupedProperty] = 0;
	}

	acc[groupedProperty] += summedProperty;

	return acc;
}, {});
