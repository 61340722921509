import { useCallback, useContext } from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import EditorContext from '../../EditorContext';
import { updateElement } from '../../reducer/actions';

const css = {
	padding: '0px',
	minWidth: '2em',
	maxWidth: '100%',
	minHeight: '1.5em',
};

/**
 * @name StaticText
 * @description A static text PDF element
 *
 * @author Florian Fornazaric
 *
 * @param {number}	[aspectRatio = 1]	The aspect ratio of the PDF editor
 * @param {object}	style				The style of the component
 * @param {object}	element				The element to edit
 * @param {boolean}	editing				True if the element is being edited
 * @param {boolean}	resizable			True if the element is being resized
 */
const StaticText = (({ aspectRatio, style, element, editing, resizable, ...otherProps }) => {
	const { dispatch, isExporting } = useContext(EditorContext);
	const handleChange = useCallback((e) => {
		dispatch(updateElement(element.id, { content: e.target.value }));
	}, [dispatch, element.id]);

	return (
		<textarea
			{...otherProps}
			className={`seamless${editing || resizable ? ' editing' : ''}`}
			style={{ ...style }}
			onChange={handleChange}
			readOnly={(isExporting || !editing || element.linkable) ? true : undefined}
			value={element.content}
		/>
	);
});

StaticText.propTypes = {
	aspectRatio: PropTypes.number,
	style: PropTypes.object,
	element: PropTypes.object,
	editing: PropTypes.bool,
	resizable: PropTypes.bool,
};

StaticText.defaultProps = {
	aspectRatio: 1,
	style: undefined,
	element: undefined,
	editing: false,
	resizable: false,
};

const styles = StyleSheet.create(css);

StaticText.PdfElement = ({ style, ...otherprops }) => <Text {...otherprops} style={[styles, style]} break={false} />;

StaticText.PdfElement.propTypes = {
	style: PropTypes.object,
};

StaticText.PdfElement.defaultProps = {
	style: undefined,
};

StaticText.PdfElement.displayName = 'StaticText';

export default StaticText;
