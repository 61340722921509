import { toast } from 'react-toastify';
import * as HistoriesApi from 'api/historiesApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Histories redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific history
	FETCH_HISTORY_REQUEST: '@HISTORIES/FETCH_REQUEST',
	FETCH_HISTORY_SUCCESS: '@HISTORIES/FETCH_SUCCESS',
	FETCH_HISTORY_FAILURE: '@HISTORIES/FETCH_FAILURE',

	// Fetch a list of histories
	FETCH_HISTORY_LIST_REQUEST: '@HISTORIES/FETCH_LIST_REQUEST',
	FETCH_HISTORY_LIST_SUCCESS: '@HISTORIES/FETCH_LIST_SUCCESS',
	FETCH_HISTORY_LIST_FAILURE: '@HISTORIES/FETCH_LIST_FAILURE',

	// Add a new history to the database
	ADD_HISTORY_REQUEST: '@HISTORIES/ADD_REQUEST',
	ADD_HISTORY_SUCCESS: '@HISTORIES/ADD_SUCCESS',
	ADD_HISTORY_FAILURE: '@HISTORIES/ADD_FAILURE',

	// Update an existing history from the database
	UPDATE_HISTORY_REQUEST: '@HISTORIES/UPDATE_REQUEST',
	UPDATE_HISTORY_SUCCESS: '@HISTORIES/UPDATE_SUCCESS',
	UPDATE_HISTORY_FAILURE: '@HISTORIES/UPDATE_FAILURE',

	// Remove an existing history from the database
	REMOVE_HISTORY_REQUEST: '@HISTORIES/REMOVE_REQUEST',
	REMOVE_HISTORY_SUCCESS: '@HISTORIES/REMOVE_SUCCESS',
	REMOVE_HISTORY_FAILURE: '@HISTORIES/REMOVE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// History list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchHistoryListRequest
 * @description Action triggered anytime a history list fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchHistoryListRequest = () => ({ type: ActionTypes.FETCH_HISTORY_LIST_REQUEST });

/**
 * @function
 * @name fetchHistoryListSuccess
 * @description Action triggered as a result to a successful history list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} histories    The list of retrieved histories.
 * @param {number} totalCount   The total amount of histories available in the database for the current user depending on the filter.
 *
 * @returns {object}
 */
const fetchHistoryListSuccess = ({ histories, totalCount }) => ({
	type: ActionTypes.FETCH_HISTORY_LIST_SUCCESS,
	payload: { histories, totalCount },
});

/**
 * @function
 * @name fetchHistoryListFailure
 * @description Action triggered as a result to a failed history list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchHistoryListFailure = (error) => ({
	type: ActionTypes.FETCH_HISTORY_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific history fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchHistoryRequest
 * @description Action triggered anytime a history fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchHistoryRequest = () => ({ type: ActionTypes.FETCH_HISTORY_REQUEST });

/**
 * @function
 * @name fetchHistorySuccess
 * @description Action triggered as a result to a successful history fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} history  The retrieved history.
 *
 * @returns {object}
 */
const fetchHistorySuccess = ({ history }) => ({
	type: ActionTypes.FETCH_HISTORY_SUCCESS,
	payload: { history },
});

/**
 * @function
 * @name fetchHistoryFailure
 * @description Action triggered as a result to a failed history fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchHistoryFailure = (error) => ({
	type: ActionTypes.FETCH_HISTORY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// History creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addHistoryRequest
 * @description Action triggered anytime a history creation call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const addHistoryRequest = () => ({ type: ActionTypes.ADD_HISTORY_REQUEST });

/**
 * @function
 * @name addHistorySuccess
 * @description Action triggered as a result to a successful history creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} history  The created history object.
 *
 * @returns {object}
 */
const addHistorySuccess = ({ history }) => ({
	type: ActionTypes.ADD_HISTORY_SUCCESS,
	payload: { history },
});

/**
 * @function
 * @name addHistoryFailure
 * @description Action triggered as a result to a failed history creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const addHistoryFailure = (error) => ({
	type: ActionTypes.ADD_HISTORY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// History update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateHistoryRequest
 * @description Action triggered anytime a history update call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const updateHistoryRequest = () => ({ type: ActionTypes.UPDATE_HISTORY_REQUEST });

/**
 * @function
 * @name updateHistorySuccess
 * @description Action triggered as a result to a successful history update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} history      The updated history object.
 *
 * @returns {object}
 */
const updateHistorySuccess = ({ history }) => ({
	type: ActionTypes.UPDATE_HISTORY_SUCCESS,
	payload: { history },
});

/**
 * @function
 * @name updateHistoryFailure
 * @description Action triggered as a result to a failed history update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const updateHistoryFailure = (error) => ({
	type: ActionTypes.UPDATE_HISTORY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// History removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeHistoryRequest
 * @description Action triggered anytime a history deletion call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const removeHistoryRequest = () => ({ type: ActionTypes.REMOVE_HISTORY_REQUEST });

/**
 * @function
 * @name removeHistorySuccess
 * @description Action triggered as a result to a successful history deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {string} deletedHistoryId     The removed history object id.
 *
 * @returns {object}
 */
const removeHistorySuccess = ({ deletedHistoryId }) => ({
	type: ActionTypes.REMOVE_HISTORY_SUCCESS,
	payload: { deletedHistoryId },
});

/**
 * @function
 * @name removeHistoryFailure
 * @description Action triggered as a result to a failed history deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const removeHistoryFailure = (error) => ({
	type: ActionTypes.REMOVE_HISTORY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchHistoryList
 * @description Method used to update the history list.
 *
 * @author Audrey Clerc
 *
 * @param {object} params   The parameters used to match user filters.
 */
export const fetchHistoryList = (params) => (dispatch) => {
	dispatch(fetchHistoryListRequest());

	return HistoriesApi.fetchHistories(params)
		.then(({ histories, totalCount }) => dispatch(fetchHistoryListSuccess({ histories, totalCount })))
		.catch((error) => dispatch(fetchHistoryListFailure(error)));
};

/**
 * @function
 * @name fetchHistory
 * @description Method used to fetch the latest version of a specific history.
 *
 * @author Audrey Clerc
 *
 * @param {string} historyId    The id of the history we want to retrieve.
 */
export const fetchHistory = (historyId) => (dispatch) => {
	dispatch(fetchHistoryRequest());

	return HistoriesApi.fetchHistoryById(historyId)
		.then(({ history }) => dispatch(fetchHistorySuccess({ history })))
		.catch((error) => dispatch(fetchHistoryFailure(error)));
};

/**
 * @function
 * @name addHistoriesWithTargetPartners
 * @description Method used to add new histories instance to the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} historiesData        The data to create the new histories from.
 * @param {string} [onSuccessRoute]     The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addHistoriesWithTargetPartners = (historiesData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addHistoryRequest());

	return Promise.all(historiesData?.targetPartners?.map((targetPartner) => {
		const historyData = {
			...historiesData,
			targetPartners: targetPartner,
		};

		return HistoriesApi.createHistory(historyData).then(({ history }) => history);
	}))
		.then((histories) => {
			toast.success(i18next.t('history.creation.toasts.success', { date: `${Intl.DateTimeFormat('fr-FR').format(new Date(histories[0].date))}` }));
			histories.forEach((history) => {
				dispatch(addHistorySuccess({ history }));
				redirectOnSuccess(onSuccessRoute);
			});

			return histories;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('history.creation.toasts.conflict'));
				dispatch(addHistoryFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('history.creation.toasts.error'));
				dispatch(addHistoryFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name addHistoriesWithInvoices
 * @description Method used to add new histories instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} historiesData        The data to create the new histories from.
 * @param {string} [onSuccessRoute]     The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addHistoriesWithInvoices = (historiesData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addHistoryRequest());

	return Promise.all(historiesData?.invoices?.map((invoice) => {
		const historyData = {
			...historiesData,
			invoices: invoice,
		};

		return HistoriesApi.createHistory(historyData).then(({ history }) => history);
	}))
		.then((histories) => {
			toast.success(i18next.t('history.creation.toasts.success', { date: `${histories[0].date.slice(0, 10)} ${histories[0].date.slice(11, 16)}` }));
			histories.forEach((history) => {
				dispatch(addHistorySuccess({ history }));
				redirectOnSuccess(onSuccessRoute);
			});

			return histories;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('history.creation.toasts.conflict'));
				dispatch(addHistoryFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('history.creation.toasts.error'));
				dispatch(addHistoryFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name addHistory
 * @description Method used to add a new history instance to the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} historyData          The data to create the new history from.
 * @param {string} [onSuccessRoute]     The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addHistory = (historyData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addHistoryRequest());

	return HistoriesApi.createHistory(historyData)
		.then(({ history }) => {
			toast.success(i18next.t('history.creation.toasts.success', { date: `${Intl.DateTimeFormat('fr-FR').format(new Date(history.date))}` }));
			dispatch(addHistorySuccess({ history }));
			redirectOnSuccess(onSuccessRoute);

			return history;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('history.creation.toasts.conflict'));
				dispatch(addHistoryFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('history.creation.toasts.error'));
				dispatch(addHistoryFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateHistory
 * @description Method used to update an existing history instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} historyData          The object to update the history with.
 * @param {string} historyId            The id of the history we want to update.
 * @param {string} [onSuccessRoute]     The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateHistory = (historyData, historyId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateHistoryRequest());

	return HistoriesApi.updateHistory(historyData, historyId)
		.then(({ history }) => {
			dispatch(updateHistorySuccess({ history }));
			toast.success(i18next.t('history.edition.toasts.success', { date: `${Intl.DateTimeFormat('fr-FR').format(new Date(history.date))}` }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('history.edition.toasts.conflict'));
				dispatch(updateHistoryFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('history.edition.toasts.error'));
				dispatch(updateHistoryFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeHistory
 * @description Method used to remove an existing history instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} history      The history to remove from the database.
 * @param {string} history.id   The id of the history we want to remove from the database.
 * @param {string} history.date The date of the history we want to remove from the database.
 */
export const removeHistory = ({ id, date }) => (dispatch) => {
	dispatch(removeHistoryRequest());

	return HistoriesApi.deleteHistory(id)
		.then(({ deletedHistoryId }) => {
			dispatch(removeHistorySuccess({ deletedHistoryId }));
			toast.success(i18next.t('history.deletion.toasts.success', { date: `${Intl.DateTimeFormat('fr-FR').format(new Date(date))}` }));
		})
		.catch((error) => {
			dispatch(removeHistoryFailure(error));
			toast.error(i18next.t('history.deletion.toasts.error'));
		});
};
