import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabHeader, TabPanel } from '../tabLayout';

import { DateTypes, LayoutTypes, OtherTypes, TextTypes } from './tabs';

/**
 * @constant
 * @name AvailableTabs
 * @description An enumeration of the tabs avaible to the user when the underlying component is shown.
 *
 * @author Yann Hodiesne
 */
const AvailableTabs = Object.freeze({
	TEXT: 'TEXT',
	DATE: 'DATE',
	OTHER: 'OTHER',
	LAYOUT: 'LAYOUT',
});

/**
 * @name ElementTypes
 * @description Display the element types the user can drag around to create new elements.
 *
 * @author Yann Hodiesne
 */
const ElementTypes = () => {
	const { t } = useTranslation();

	const [selectedTab, setSelectedTab] = useState(AvailableTabs.TEXT);

	return (
		<>
			<div role="tablist">
				<TabHeader
					id="text-fields-tab"
					linkedPanelId="text-fields-panel"
					selected={selectedTab === AvailableTabs.TEXT}
					onClick={() => setSelectedTab(AvailableTabs.TEXT)}
				>
					{t('template.pdf.editor.toolbox.element_types.text')}
				</TabHeader>
				<TabHeader
					id="date-fields-tab"
					linkedPanelId="date-fields-panel"
					selected={selectedTab === AvailableTabs.DATE}
					onClick={() => setSelectedTab(AvailableTabs.DATE)}
				>
					{t('template.pdf.editor.toolbox.element_types.date')}
				</TabHeader>
				<TabHeader
					id="other-fields-tab"
					linkedPanelId="other-fields-panel"
					selected={selectedTab === AvailableTabs.OTHER}
					onClick={() => setSelectedTab(AvailableTabs.OTHER)}
				>
					{t('template.pdf.editor.toolbox.element_types.other')}
				</TabHeader>
				<TabHeader
					id="layout-tab"
					linkedPanelId="layout-panel"
					selected={selectedTab === AvailableTabs.LAYOUT}
					onClick={() => setSelectedTab(AvailableTabs.LAYOUT)}
				>
					{t('template.pdf.editor.toolbox.element_types.layout')}
				</TabHeader>
			</div>
			<TabPanel
				id="text-fields-panel"
				linkedTabId="text-fields-tab"
				hidden={selectedTab !== AvailableTabs.TEXT}
			>
				<TextTypes />
			</TabPanel>
			<TabPanel
				id="date-fields-panel"
				linkedTabId="date-fields-tab"
				hidden={selectedTab !== AvailableTabs.DATE}
			>
				<DateTypes />
			</TabPanel>
			<TabPanel
				id="other-fields-panel"
				linkedTabId="other-fields-tab"
				hidden={selectedTab !== AvailableTabs.OTHER}
			>
				<OtherTypes />
			</TabPanel>
			<TabPanel
				id="layout-panel"
				linkedTabId="layout-tab"
				hidden={selectedTab !== AvailableTabs.LAYOUT}
			>
				<LayoutTypes />
			</TabPanel>
		</>
	);
};

export default ElementTypes;
