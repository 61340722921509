/**
 * @constant
 * @name DateModes
 * @description An enumeration of the different types
 *
 * @author Matthieu Schaerlinger
 */
export const DateModes = Object.freeze({
	/**
	 * @description The date has a fixed value.
	 */
	STATIC: 'STATIC',

	/**
	 * @description The date will be computed automatically on export.
	 */
	AUTOMATIC: 'AUTOMATIC',
});

/**
 * @constant
 * @name AutomaticDateValues
 * @description An enumeration of values for dates with automatic mode
 *
 * @author Matthieu Schaerlinger
 */
export const AutomaticDateValues = Object.freeze({
	/**
	 * The exact date and time of the PDF rendering.
	 */
	EXPORT_DATE: 'EXPORT_DATE',
});
