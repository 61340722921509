import PropTypes from 'prop-types';
import { baseTagStyle } from 'theme/tagStyles';

import { StyledDiv } from 'components/shared/styledElements';

import { Brevier } from '../Typography';

/**
 * @name StyledTag
 * @description A stylable tag element accepting styled-system and styled-components props.
 *
 * @author Timothée Simon-Franza
 *
 * @param {(number | string)}	children	 The element to display as a tag.
 * @param {object}				[textStyle]	 An optional styling object for the text element inside the tag.
 */
const StyledTag = ({ children, textStyle, ...otherProps }) => (
	<StyledDiv {...baseTagStyle} {...otherProps}>
		<Brevier as="span" {...textStyle}>
			{children}
		</Brevier>
	</StyledDiv>
);

StyledTag.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
		PropTypes.node,
	]).isRequired,
	textStyle: PropTypes.object,
};

StyledTag.defaultProps = {
	textStyle: {},
};

export default StyledTag;
