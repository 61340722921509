import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/buttons';

import EditorContext from '../../../EditorContext';
import { addEntity } from '../../../reducer/actions';

import { EntitiesHeader, EntityRow } from './components';

/**
 * @name EditorEntities
 * @description Displays a summary or a list of entities used by the current editor
 *
 * @author Yann Hodiesne
 */
const EditorEntities = () => {
	const { t } = useTranslation();
	const { dispatch, entities } = useContext(EditorContext);

	const [expanded, setExpanded] = useState(false);
	const [selectedEntity, setSelectedEntity] = useState();

	const entitiesRef = useRef(entities);

	useEffect(() => {
		// If a new entity has been created, expand it
		if (entities.length - entitiesRef.current.length === 1) {
			const entity = entities[entities.length - 1];

			setExpanded(true);
			setSelectedEntity(entity.id);
		}

		entitiesRef.current = entities;
	}, [entities]);

	const toggleExpansion = useCallback(() => setExpanded((value) => !value), []);
	const onSelect = useCallback((entity) => setSelectedEntity(entity), []);
	const onUnselect = useCallback(() => setSelectedEntity(null), []);

	const dispatchAddEntity = useCallback(() => {
		dispatch(addEntity());
	}, [dispatch]);

	return (
		<div className={`entities${expanded ? ' expanded' : ''}`}>
			<EntitiesHeader count={entities.length} onClick={toggleExpansion} />
			{expanded && (
				<>
					<div className="content">
						{entities.length > 0 && entities.map((entity) => (
							<EntityRow key={entity.id} entity={entity} expand={entity.id === selectedEntity} onSelect={onSelect} onUnselect={onUnselect} />
						))}
						{entities.length === 0 && (
							<p className="no-entity-message">{t('template.pdf.entities.none')}</p>
						)}
					</div>
					{!selectedEntity && (
						<div className="actions">
							<Button className="primary-outlined" onClick={dispatchAddEntity}>
								{t('template.pdf.entities.add')}
							</Button>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default memo(EditorEntities);
