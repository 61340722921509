/* eslint-disable no-console */
import { useCallback } from 'react';
import { diff } from 'deep-object-diff';
import _ from 'lodash';

export default (reducer) => useCallback((state, action) => {
	const result = reducer(state, action);

	if (result === state) {
		return result;
	}

	if (_.isEmpty(diff(state, result))) {
		console.groupCollapsed('A useless re-render has been triggered !', action);
		console.info('Action : ', action);
		console.info('State : ', state);
		console.log('Previous and next state are equal but a re-render has been triggered\nA little bit of optimization can be achieved there');
		console.groupEnd();
	}

	return result;
}, [reducer]);
