import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import EditorContext from '../../EditorContext';
import { formatDate, isDateValid } from '../../functions/dateUtils';

const dynamicDateCss = {
	border: '1px solid #939EA9', // #939EA9 is var(--clr-alabaster-600)
	borderRadius: '4px',
	backgroundColor: '#FAFAFA', // #FAFAFA is var(--clr-alabaster-100)
	minHeight: '1.5em',
	padding: '0px 2px',
};

/**
 * @name DynamicDate
 * @description A dynamic date PDF element
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object}		style		The style of the component
 * @param {object}		element		The element to edit
 * @param {boolean}		editing		True if the element is being edited
 * @param {boolean}		resizable	True if the element is being resized
 */
const DynamicDate = (({ style, element, editing, resizable }) => {
	const { t } = useTranslation();
	const { isExporting } = useContext(EditorContext);
	const valid = isDateValid(element.content);

	return (
		<textarea
			className={`seamless${editing || resizable ? ' editing' : ''}`}
			style={{ ...dynamicDateCss, ...style }}
			readOnly={(isExporting || !editing) ? true : undefined}
			value={formatDate(valid ? element.content : undefined, element.dateFormat, t)}
		/>
	);
});

DynamicDate.propTypes = {
	style: PropTypes.object,
	element: PropTypes.object,
	editing: PropTypes.bool,
	resizable: PropTypes.bool,
};

DynamicDate.defaultProps = {
	style: undefined,
	element: undefined,
	editing: false,
	resizable: false,
};

/**
 * @name PdfElement
 * @description A dynamic PDF element for the date
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object}	style 				The style of the component
 * @param {object}	element 			The element to render
 */
const PdfElement = ({ style, element, ...otherProps }) => {
	const { t } = useTranslation();
	const valid = isDateValid(element.content);

	return (
		<Text {...otherProps} style={[dynamicDateCss, style]}>
			{formatDate(valid ? element.content : undefined, element.dateFormat, t)}
		</Text>
	);
};

PdfElement.propTypes = {
	element: PropTypes.object.isRequired,
	style: PropTypes.object,
};

PdfElement.defaultProps = {
	style: undefined,
};

PdfElement.displayName = 'DynamicDate';

DynamicDate.PdfElement = PdfElement;

export default DynamicDate;
