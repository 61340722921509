import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Select, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name PartnerIdentifier
 * @description A form used to group the identifier fields.
 *
 * @author Romaric Barthe
 *
 * @param {array}		currencies		The list of all available currencies.
 * @param {bool}		inSuperAdmin	Whether we are in superadmin environment.
 * @param {bool}		isLoading		Whether the information is still loading.
 * @param {array}		parents			The list of all available partners.
 * @param {function}	setTitles		The method to update the titles.
 */
const PartnerIdentifier = ({
	currencies,
	inSuperAdmin,
	isLoading,
	parents,
	setTitles,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<TextInput
				label={t('partner.edition.inputs.name.label')}
				name="name"
				onChange={setTitles}
				placeholder={t('partner.edition.inputs.name.placeholder')}
				rules={{
					required: Validators.isRequired(t('partner.edition.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextArea
				label={t('partner.edition.inputs.activityDescription.label')}
				name="activityDescription"
				onChange={setTitles}
				placeholder={t('partner.edition.inputs.activityDescription.placeholder')}
				type="text"
			/>
			<TextInput
				label={t('partner.edition.inputs.website.label')}
				name="website"
				onChange={setTitles}
				placeholder={t('partner.edition.inputs.website.placeholder')}
				type="text"
			/>
			<Select
				isLoading={isLoading}
				label={t('partner.edition.inputs.currency.label')}
				labelKey="name"
				name="currency"
				options={currencies}
				rules={!inSuperAdmin ? {} : {
					required: Validators.isRequired(t('partner.edition.inputs.currency.validation_errors.required')),
				}}
				valueKey="id"
			/>
			{!inSuperAdmin && (
				<Select
					allowNull
					isLoading={isLoading}
					label={t('partner.edition.inputs.parent.label')}
					labelKey="name"
					name="parent"
					options={parents}
					valueKey="id"
				/>
			)}
		</>
	);
};

PartnerIdentifier.propTypes = {
	currencies: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string,
		})
	),
	inSuperAdmin: PropTypes.bool,
	isLoading: PropTypes.bool,
	parents: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string,
		})
	),
	setTitles: PropTypes.func,
};

PartnerIdentifier.defaultProps = {
	currencies: [],
	inSuperAdmin: true,
	isLoading: true,
	parents: [],
	setTitles: () => {},
};

export default PartnerIdentifier;
