import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchOffers
 * @description Retrieves a list of offers from the API, specifying filters if present.
 *
 * @author Audrey Clerc
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchOffers = (params) => post('/api/offers', params);

/**
 * @function
 * @name fetchOfferById
 * @description Retrieves an existing offer from the API, using the offerId in parameters.
 *
 * @author Audrey Clerc
 *
 * @param {string} offerId	The id of the offer we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchOfferById = (offerId) => get(`/api/offer/${offerId}`);

/**
 * @function
 * @name createOffer
 * @description Creates a new offer into the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} offerData	The data to create the offer from.
 *
 * @returns {Promise}
 */
export const createOffer = (offerData) => post('/api/offers/create', offerData);

/**
 * @function
 * @name updateOffer
 * @description Updates an existing offer from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} offerData	The data to update the offer with.
 * @param {string} offerId		The id to identify the offer with.
 *
 * @returns {Promise}
 */
export const updateOffer = (offerData, offerId) => put(`/api/offer/${offerId}`, offerData);

/**
 * @function
 * @name deleteOffer
 * @description Removes an existing offer from the database.
 *
 * @author Audrey Clerc
 *
 * @param {string} offerId	The id of the offer we want to remove.
 *
 * @returns {Promise}
 */
export const deleteOffer = (offerId) => del(`/api/offer/${offerId}`);
