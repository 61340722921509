/**
* @function
* @name formatProjectDescription
* @description Returns the provided targeting's project's description if there is one.
*
* @author Romaric Barthe
*
* @param {object} targeting		The targeting serialised.
*
* @returns {string}
*/
export const formatProjectDescription = (targeting) => (targeting.project?.description ?? '');

/**
* @function
* @name formatProjectName
* @description Returns the provided targeting's project's name if there is one.
*
* @author Romaric Barthe
*
* @param {object} targeting		The targeting serialised.
*
* @returns {string}
*/
export const formatProjectName = (targeting) => (targeting.project?.name ?? '');
