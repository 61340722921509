import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy } from 'lib/shared/format';
import { fetchTemplateList } from 'redux/actions/templateExports';
import { useTemplateExportListSelector, useTemplateExportLoadingSelector, useTemplateExportTotalCountSelector } from 'redux/selectors/templateExports';

import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import ActionsCell from 'components/templates/templateExportList/ActionsCell';

import 'styles/pages/dataTablePages.scss';

/**
 * @name TemplateExportListPage
 * @description A list of all template exports accessible from the templates app.
 *
 * From this page, the user is able to view, interact and search for the templates exports, seen as PDF files.
 *
 * @author Yann Hodiesne
 *
 */
const TemplateExportListPage = () => {
	const { search } = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const defaultSearch = useMemo(() => new URLSearchParams(search).get('search') ?? '', [search]);

	const templateExports = useTemplateExportListSelector().filter((f) => f.generatedPdf);
	const totalCount = useTemplateExportTotalCountSelector();
	const isLoading = useTemplateExportLoadingSelector();

	const headers = useMemo(() => [
		{ accessor: 'templateName', Header: t('template_export.headers.name'), pinColumn: true },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('template_export.headers.actions') },
		{ accessor: 'generatedPdf.fileName', Header: t('template_export.headers.file_name'), sorting: false },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('template_export.headers.created_at') },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('template_export.headers.created_by') },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchTemplateList(params)));
	const { tableProps, modalProps } = useTableUserPreferences('template_exports');

	// We do not want to use useMemo here because caching its value based on a primitive condition is lighter than caching its value based on a comparison.
	const rows = isLoading ? [] : templateExports;

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} defaultValue={defaultSearch} />
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={rows}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="template_export"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('template.pages.exports')} {...modalProps} />
		</div>
	);
};

export default TemplateExportListPage;
