import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import { formatQuotationName, formatQuotationNameList } from 'lib/quotations/formatQuotationData';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';

/**
 * @function
 * @name useCurrentQuotationSelector
 * @description A selector to retrieve the current quotation from the quotation redux state
 *
 * @author Audrey Clerc
 * @author Yann Hodiesne
 *
 * @param {string} id The identifier of the current quotation to wait for
 *
 * @returns {object}
 */
const useCurrentQuotationSelector = (id) => {
	const quotation = useSelector((state) => (state.quotations?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (quotation?.id === id ? quotation : null), [id, quotation]);

	return result;
};

/**
 * @function
 * @name useCurrentQuotationWithFormattedNamesSelector
 * @description A selector to retrieve the current quotation from the quotation redux state, with an additional formatted "name" field.
 *
 * @author Florian Fornazaric
 *
 * @param {string} id The identifier of the current quotation to wait for
 *
 * @returns {object}
 */
const useCurrentQuotationWithFormattedNamesSelector = (id) => {
	const quotation = useSelector((state) => (state.quotations?.currentItem ?? null), shallowEqual);
	const numberFormat = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.INVOICE)[0];

	const result = useMemo(() => (quotation?.id === id ? quotation : null), [id, quotation]);

	return useMemo(() => (result
		? {
			...result,
			name: formatQuotationName(quotation, numberFormat),
			fullname: formatQuotationNameList(quotation, numberFormat),
		}
		: null
	), [numberFormat, quotation, result]);
};

/**
 * @function
 * @name useQuotationLoadingSelector
 * @description A selector to retrieve the loading state from the quotation redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {boolean}
 */
const useQuotationLoadingSelector = () => {
	const loading = useSelector((state) => state.quotations?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useQuotationListSelector
 * @description A selector to retrieve the quotation list from the quotation redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {Array}
 */
const useQuotationListSelector = () => {
	const quotations = useSelector((state) => (state.quotations?.items ?? []), shallowEqual);

	return quotations;
};

/**
 * @function
 * @name useAllForQuotationSelector
 * @description A selector to retrieve the quotation information from the quotation redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAllForQuotationSelector = () => {
	const allForForm = useSelector((state) => (state.quotations?.allForForm ?? []), shallowEqual);

	return allForForm;
};

/**
 * @function
 * @name useQuotationTotalCountSelector
 * @description A selector to retrieve the totalCount value from the quotation redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {number|undefined}
 */
const useQuotationTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.quotations?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useQuotationListWithFormattedNamesSelector
 * @description A selector to retrieve the quotation list from the quotation redux state, with formated datas.
 *
 * @uses {@link lib/quotations/formatQuotationData:formatQuotationData}
 *
 * @author Roland Margelidon
 *
 * @returns {Array}
 */
const useQuotationListWithFormattedNamesSelector = () => {
	const quotations = useQuotationListSelector();
	const numberFormat = useNumberFormatListSelector().filter((a) => a.environment === Environments.QUOTATION)[0];

	/**
	 * @var
	 * @name result
	 * @description The object returned from this method. Used to manipulate data before sending it back to the caller.
	 */
	const result = useMemo(() => (quotations.map((quotation) => ({
		...quotation,
		name: formatQuotationName(quotation, numberFormat),
		fullname: formatQuotationNameList(quotation, numberFormat),
	}))), [quotations, numberFormat]);

	return result;
};

/**
 * @function
 * @name useQuotationActiveListSelector
 * @description A selector to retrieve all non-archived quotations from the quotation redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {Array}
 */
const useQuotationActiveListSelector = () => {
	const activeRelationTypes = useSelector((state) => (state.quotations?.items ?? []).filter((quotation) => !quotation.archived), shallowEqual);

	return activeRelationTypes;
};

/**
 * @function
 * @name useQuotationSelector
 * @description A selector to retrieve the quotation from the quotation redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the quotation to wait for
 *
 * @returns {object|undefined}
 */
const useQuotationSelector = (id) => {
	const quotations = useQuotationListSelector();

	const result = useMemo(() => quotations.find((quotation) => quotation.id === id), [quotations, id]);

	return result;
};

export {
	useAllForQuotationSelector,
	useCurrentQuotationSelector,
	useCurrentQuotationWithFormattedNamesSelector,
	useQuotationActiveListSelector,
	useQuotationListSelector,
	useQuotationListWithFormattedNamesSelector,
	useQuotationLoadingSelector,
	useQuotationSelector,
	useQuotationTotalCountSelector,
};
