import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatContactName } from 'lib/contacts/formatContactData';
import { formatQuotationName } from 'lib/quotations/formatQuotationData';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import PropTypes from 'prop-types';

/**
 * @name QuotationTitle
 * @description A component to display the quotation's titles.
 *
 * @author Romaric Barthe
 *
 * @param {object}	quotation			The quotation.
 */
const QuotationTitle = ({ quotation, numberFormat }) => {
	const { t } = useTranslation();

	const client = useMemo(() => {
		if (quotation.project?.contact) {
			return formatContactName(quotation.project?.contact);
		}
		const clientName = quotation.project?.partner?.name;
		const clientRepresentant = quotation.project?.partner?.legalRepresentant ? ` (${formatContactName(quotation.project?.partner?.legalRepresentant)})` : '';

		return `${clientName ?? ''}${clientRepresentant ?? ''}`;
	}, [quotation.project]);
	const avenant = useMemo(() => quotation.quotationParent && formatQuotationName(quotation.quotationParent, numberFormat), [numberFormat, quotation.quotationParent]);

	return (
		<div className="data-header">
			<div className="identity">
				<h3>{formatQuotationName(quotation, numberFormat)}</h3>
				<h4>{quotation.project?.name}</h4>
				<h5>{client}</h5>
				<p>{quotation.description}</p>
			</div>
			<div className="metadata">
				{avenant && (<h5>{`${t('quotation.fields.quotation_parent')}: ${avenant}`}</h5>)}
				<p>{`${t('quotation.fields.creation_date')}: ${formatCreatedAt(quotation)}`}</p>
				<p>{`${t('quotation.fields.creation_name')}: ${formatCreatedBy(quotation)}`}</p>
				<p>{`${t('quotation.fields.update_date')}: ${formatUpdatedAt(quotation)}`}</p>
				<p>{`${t('quotation.fields.update_name')}: ${formatUpdatedBy(quotation)}`}</p>
			</div>
		</div>
	);
};

QuotationTitle.propTypes = {
	numberFormat: PropTypes.object,
	quotation: PropTypes.object.isRequired,
};

QuotationTitle.defaultProps = {
	numberFormat: undefined,
};

export default QuotationTitle;
