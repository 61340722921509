import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { HiddenInput, NumberInput, Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name QuotationGridArchiColumn
 * @description A form used to edit an existing quotation's information.
 *
 * @author Romaric Barthe
 *
 * @param {string}	currencyName			The ISO name of the currency.
 * @param {string}	currencySymbol			The symbol of the currency.
 * @param {object}	defaultValues			The default values of the quotation.
 * @param {bool}	isLoading				Whether data is still loading.
 * @param {array}	partner					The list of available partners.
 * @param {number}	projectAmount			The global project amount.
 * @param {bool}	readOnly				Whether the quotation is read-only.
 * @param {array}	sectors					The list of available column in the app.
 * @param {object}	stakeholder				The current combinations sector/ partner in column.
 * @param {func}	setTotals				The method to set the total of the quotation.
 */
const QuotationGridArchiColumn = ({
	currencyName,
	defaultValues,
	isLoading,
	partners,
	projectAmount,
	readOnly,
	sectors,
	stakeholder,
	// functions
	setTotals,
	// duplicate logic
	// originalRowData,
}) => {
	const { t } = useTranslation();

	const [selectedSector, setSelectedSector] = useState(defaultValues?.stakeholders?.[stakeholder.id]?.sector);

	const partnerList = useMemo(() => partners.filter((p) => p.sectors.find((s) => s.id === selectedSector)) ?? [], [partners, selectedSector]);

	const partnerProjectAmountRef = useRef();

	useEffect(() => {
		partnerProjectAmountRef.current?.setValue(projectAmount);
	}, [projectAmount]);

	return (
		<>
			<HiddenInput
				name={`stakeholders.${stakeholder.id}.column`}
				value={stakeholder.column}
			/>
			<HiddenInput
				name={`stakeholders.${stakeholder.id}.id`}
				value={stakeholder.id}
			/>
			<Select
				isLoading={isLoading}
				label=""
				labelKey="name"
				name={`stakeholders.${stakeholder.id}.sector`}
				onChange={setSelectedSector}
				options={sectors}
				readOnly={readOnly}
				rules={{
					required: Validators.isRequired(t('quotation.edition.inputs.sector.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<Select
				isLoading={isLoading}
				label=""
				labelKey="name"
				name={`stakeholders.${stakeholder.id}.partner`}
				options={partnerList}
				readOnly={readOnly}
				rules={{
					required: Validators.isRequired(t('quotation.edition.inputs.partner.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<NumberInput
				ref={partnerProjectAmountRef}
				allowNull
				className="number-input"
				decimalScale={2}
				fixedDecimalScale
				icon={currencyName}
				label=""
				name={`stakeholders.${stakeholder.id}.partnerProjectAmount`}
				onChange={setTotals}
				readOnly={readOnly}
				rules={{
					required: Validators.isRequired(t('quotation.edition.inputs.partnerProjectAmount.validation_errors.required')),
				}}
				step={0.01}
			/>
			<NumberInput
				allowNull
				className="number-input"
				decimalScale={2}
				fixedDecimalScale
				icon="percentage"
				label=""
				name={`stakeholders.${stakeholder.id}.partnerRatio`}
				onChange={setTotals}
				readOnly={readOnly}
				rules={{
					required: Validators.isRequired(t('quotation.edition.inputs.partnerRatio.validation_errors.required')),
				}}
				step={0.01}
			/>
			<NumberInput
				allowNull
				className="number-input"
				decimalScale={2}
				fixedDecimalScale
				icon="percentage"
				label=""
				name={`stakeholders.${stakeholder.id}.partnerDiscount`}
				onChange={setTotals}
				readOnly={readOnly}
				step={0.01}
			/>
		</>
	);
};

QuotationGridArchiColumn.propTypes = {
	currencyName: PropTypes.string,
	defaultValues: PropTypes.object,
	isLoading: PropTypes.bool,
	partners: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.bool,
		}),
	).isRequired,
	projectAmount: PropTypes.number,
	readOnly: PropTypes.bool,
	sectors: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.bool,
		}),
	).isRequired,
	stakeholder: PropTypes.shape({
		id: PropTypes.string,
		column: PropTypes.number,
		rate: PropTypes.number,
	}).isRequired,
	// functions
	setTotals: PropTypes.func.isRequired,
	// duplicate logic,
	// originalRowData: PropTypes.object,
};

QuotationGridArchiColumn.defaultProps = {
	currencyName: '',
	defaultValues: undefined,
	isLoading: false,
	projectAmount: null,
	readOnly: false,
	// originalRowData: undefined,
};

export default QuotationGridArchiColumn;
