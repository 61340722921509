import { ActionTypes } from '../../actions/partners/partners';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_PARTNER_LIST_REQUEST:
		case ActionTypes.FETCH_ALL_FOR_PARTNER_REQUEST:
		case ActionTypes.ADD_PARTNER_REQUEST:
		case ActionTypes.EXPORT_PARTNER_REQUEST:
		case ActionTypes.UPDATE_PARTNER_REQUEST:
		case ActionTypes.REMOVE_PARTNER_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_PARTNER_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_PARTNER_LIST_FAILURE:
		case ActionTypes.FETCH_ALL_FOR_PARTNER_FAILURE:
		case ActionTypes.FETCH_PARTNER_FAILURE:
		case ActionTypes.ADD_PARTNER_FAILURE:
		case ActionTypes.EXPORT_PARTNER_FAILURE:
		case ActionTypes.REMOVE_PARTNER_FAILURE:
		case ActionTypes.UPDATE_PARTNER_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_PARTNER_SUCCESS:
		case ActionTypes.EXPORT_PARTNER_SUCCESS:
		case ActionTypes.REMOVE_PARTNER_SUCCESS:
		case ActionTypes.UPDATE_PARTNER_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_PARTNER_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.partners],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_PARTNER_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.partner,
			};
		case ActionTypes.FETCH_ALL_FOR_PARTNER_SUCCESS:
			return {
				...state,
				isLoading: false,
				allForForm: payload.allForForm,
			};
		default:
			return state;
	}
};
