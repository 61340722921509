import { memo, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Environments } from 'constants/environmentEnums';
import { formatContactName } from 'lib/contacts/formatContactData';
import { formatExpenseNameList } from 'lib/expenses/formatExpenseData';
import { formatInvoiceNameList } from 'lib/invoices/formatInvoiceData';
import { formatQuotationNameList } from 'lib/quotations/formatQuotationData';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fetchContactsSelectList } from 'redux/actions/contacts/contactsSelect';
import { fetchExpensesSelectList } from 'redux/actions/expenses/expensesSelect';
import { fetchInvoicesSelectList } from 'redux/actions/invoices/invoicesSelect';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { fetchPartnersSelectList } from 'redux/actions/partners/partnersSelect';
import { fetchQuotationsSelectList } from 'redux/actions/quotations/quotationsSelect';
import { useContactsSelectListSelector } from 'redux/selectors/contacts/contactsSelect';
import { useExpensesSelectListSelector } from 'redux/selectors/expenses/expensesSelect';
import { useInvoicesSelectListSelector } from 'redux/selectors/invoices/invoicesSelect';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';
import { usePartnersSelectListSelector } from 'redux/selectors/partners/partnersSelect';
import { useQuotationsSelectListSelector } from 'redux/selectors/quotations/quotationsSelect';

import { DatePicker } from 'components/shared/inputs';

import EditorContext from '../../../EditorContext';
import { updateEditionDate } from '../../../reducer/actions';
import { EntitiesHeader } from '../edition/components';

import EntityRow from './EntityRow';

/**
 * @name EntitiesList
 * @description Displays a list of entities used by the current editor
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {string} linkedEntityId The id of the entity linked to the current template
 */
const EntitiesList = ({ linkedEntityId }) => {
	const { t } = useTranslation();
	const reduxDispatch = useDispatch();
	const { entities, linkedEntities, editionDate, lastEditionDate, dispatch } = useContext(EditorContext);

	const numberFormatExpense = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.EXPENSE)[0];
	const numberFormatInvoice = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.INVOICE)[0];
	const numberFormatQuotation = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.QUOTATION)[0];

	const rawContacts = useContactsSelectListSelector();
	const expensesList = useExpensesSelectListSelector();
	const invoicesList = useInvoicesSelectListSelector();
	const rawPartners = usePartnersSelectListSelector();
	const quotationsList = useQuotationsSelectListSelector();

	const rawExpenses = useMemo(() => (expensesList.map((expense) => ({
		...expense,
		fullname: formatExpenseNameList(expense, numberFormatExpense, t),
	}))).sort((a, b) => a.updatedAt < b.updatedAt), [expensesList, numberFormatExpense, t]);

	const rawInvoices = useMemo(() => invoicesList.map((invoice) => ({
		...invoice,
		fullname: formatInvoiceNameList(invoice, numberFormatInvoice, t),
	})).sort((a, b) => a.updatedAt < b.updatedAt), [invoicesList, numberFormatInvoice, t]);

	const rawQuotations = useMemo(() => quotationsList.map((quotation) => ({
		...quotation,
		fullname: formatQuotationNameList(quotation, numberFormatQuotation),
	})), [numberFormatQuotation, quotationsList]);

	const partners = useMemo(() => rawPartners.map((entityOption) => ({ label: entityOption.name, value: entityOption })), [rawPartners]);
	const contacts = useMemo(() => rawContacts.map((entityOption) => ({ label: formatContactName(entityOption), value: entityOption })), [rawContacts]);
	const expenses = useMemo(() => rawExpenses.map((entityOption) => ({ label: entityOption.fullname, value: entityOption })), [rawExpenses]);
	const invoices = useMemo(() => rawInvoices.map((entityOption) => ({ label: entityOption.fullname, value: entityOption })), [rawInvoices]);
	const quotations = useMemo(() => rawQuotations.map((entityOption) => ({ label: entityOption.fullname, value: entityOption })), [rawQuotations]);

	// Fetch the list of partners and contacts
	useEffect(() => {
		reduxDispatch(fetchContactsSelectList({ rowsPerPage: 0 }));
		reduxDispatch(fetchExpensesSelectList({ rowsPerPage: 0 }));
		reduxDispatch(fetchInvoicesSelectList({ rowsPerPage: 0 }));
		reduxDispatch(fetchNumberFormatList({ rowsPerPage: 0 }));
		reduxDispatch(fetchPartnersSelectList({ rowsPerPage: 0 }));
		reduxDispatch(fetchQuotationsSelectList({ rowsPerPage: 0 }));
	}, [reduxDispatch]);

	const onEditionDateChanged = useCallback((value) => {
		dispatch(updateEditionDate(moment(value.date).format('YYYY-MM-DD')));
	}, [dispatch]);

	useEffect(() => {
		if (new Date(editionDate) < lastEditionDate) {
			toast.error(t('template.edition.toasts.date_consistency', { lastEditionDate }));
		}
	}, [editionDate, lastEditionDate, t]);

	return (
		<div className="entities">
			<EntitiesHeader count={entities.length} />
			<ul className="content">
				{entities.map((entity) => {
					let options;

					switch (entity.type) {
						case 'CONTACT':
							options = contacts;
							break;
						case 'EXPENSE':
							if (entity.shouldIssue) {
								options = expenses.filter((expense) => !expense.generatedPdf);
							} else {
								options = expenses;
							}
							break;
						case 'INVOICE':
							if (entity.shouldIssue) {
								options = invoices.filter((invoice) => !invoice.generatedPdf);
							} else {
								options = invoices;
							}
							break;
						case 'PARTNER':
							options = partners;
							break;
						case 'QUOTATION':
							options = quotations;
							break;
						default:
							options = [];
					}

					const linkedEntity = linkedEntities.find((x) => x.id === entity.id)?.linkedEntity;
					let initialValue;

					if (linkedEntityId) {
						initialValue = options.find((option) => option.value.id === linkedEntityId);
					} else {
						initialValue = linkedEntity !== undefined ? options.find((option) => option.value.id === linkedEntity) : undefined;
					}

					return (
						<div key={entity.id}>
							<EntityRow
								entity={entity}
								entitiesList={options}
								initialValue={initialValue}
							/>
							{entity.shouldIssue && (
								<>
									<label htmlFor="edition">{t('template.edition.date_selection')}</label>
									<DatePicker
										id="edition"
										label={t('targeting.target.target_partner.edition.inputs.date.label')}
										name="edition"
										onChange={onEditionDateChanged}
										defaultValue={editionDate}
									/>
								</>
							)}
						</div>
					);
				})}
			</ul>
		</div>
	);
};

EntitiesList.propTypes = {
	linkedEntityId: PropTypes.string,
};

EntitiesList.defaultProps = {
	linkedEntityId: undefined,
};

export default memo(EntitiesList);
