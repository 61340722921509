import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDown, ArrowUp, Copy, XCircle } from 'react-feather';
import { InvoiceColumnHeader } from 'constants/invoiceEnums';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { HiddenInput, NumberInput, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name InvoiceGridRow
 * @description A form used to edit an existing invoice's information.
 *
 * @author Romaric Barthe
 *
 * @param {array}	accounts				The list of accounts.
 * @param {array}	columnsHeaders			The array of the headers to be displayed.
 * @param {array}	currencies				The list of currencies.
 * @param {string}	currencyName			The ISO name of the currency in the invoice.
 * @param {string}	currencySymbol			The symbol of the currency.
 * @param {object}	defaultValues			The default values of the invoice.
 * @param {bool}	formDisabled			Whether the form (actions) should be disable.
 * @param {bool}	isLoading				Whether the data are still loading.
 * @param {array}	projects				The list of projects.
 * @param {bool}	readOnly				Whether the invoice is read-only.
 * @param {object}	row						The rows of the invoice.
 * @param {number}	rowItems				The number of rows.
 * @param {array}	vatRates				The list of vat Rates.
 * @param {func}	updateRow				The method to add/ move/ delete/ duplicate a line.
 * @param {func}	setTotals				The method to set the total of the invoices.
 * @param {func}	originalRowData			If set, the data of the row that is duplicated.
 */
const InvoiceGridRow = ({
	accounts,
	columnsHeaders,
	currencyName,
	currencySymbol,
	defaultValues,
	formDisabled,
	isLoading,
	offers,
	readOnly,
	row,
	rowItems,
	vatRates,
	// functions
	updateRow,
	setTotals,
	// duplicate logic
	originalRowData,
}) => {
	const [baseAmount, setBaseAmount] = useState(defaultValues?.rows?.[row.id]?.baseAmount || 0);
	const [currentlyInvoiced, setCurrentlyInvoiced] = useState(defaultValues?.rows?.[row.id]?.currentlyInvoiced || 0);
	const [discount, setDiscount] = useState(defaultValues?.rows?.[row.id]?.discount || 0);
	const [price, setPrice] = useState(defaultValues?.rows?.[row.id]?.price || 0);
	const [quantity, setQuantity] = useState(defaultValues?.rows?.[row.id]?.quantity || 0);

	const isInitialized = useRef(false);

	const grossTotalForLine = useMemo(() => quantity * price || baseAmount * (currentlyInvoiced / 100), [baseAmount, currentlyInvoiced, price, quantity]);
	const discountTotalForLine = useMemo(() => grossTotalForLine * (discount / 100), [discount, grossTotalForLine]);
	const grossTotalDiscountedForLine = useMemo(() => grossTotalForLine - discountTotalForLine, [discountTotalForLine, grossTotalForLine]);

	const setBaseAmountValue = useCallback((value) => {
		setBaseAmount(value);
		setTotals();
	}, [setBaseAmount, setTotals]);
	const setCurrentlyInvoicedValue = useCallback((value) => {
		setCurrentlyInvoiced(value);
		setTotals();
	}, [setCurrentlyInvoiced, setTotals]);
	const setDiscountValue = useCallback((value) => {
		setDiscount(value);
		setTotals();
	}, [setDiscount, setTotals]);
	const setPriceValue = useCallback((value) => {
		setPrice(value);
		setTotals();
	}, [setPrice, setTotals]);
	const setQuantityValue = useCallback((value) => {
		setQuantity(value);
		setTotals();
	}, [setQuantity, setTotals]);

	const accountRef = useRef();
	const alreadyInvoicedRef = useRef();
	const baseAmountRef = useRef();
	const currentlyInvoicedRef = useRef();
	const designationRef = useRef();
	const discountRef = useRef();
	const offerRef = useRef();
	const priceRef = useRef();
	const quantityRef = useRef();
	const vatRateRef = useRef();

	useEffect(() => {
		if (!isInitialized.current) {
			isInitialized.current = true;

			if (!originalRowData) {
				return;
			}

			if (originalRowData.account && originalRowData.account !== null) {
				accountRef.current?.setValue(originalRowData.account);
			}
			alreadyInvoicedRef.current?.setValue(originalRowData.alreadyInvoiced);
			baseAmountRef.current?.setValue(originalRowData.baseAmount);
			currentlyInvoicedRef.current?.setValue(originalRowData.currentlyInvoiced);
			designationRef.current?.setValue(originalRowData.designation);
			discountRef.current?.setValue(originalRowData.discount);
			priceRef.current?.setValue(originalRowData.price);
			quantityRef.current?.setValue(originalRowData.quantity);
			if (originalRowData.offer && originalRowData.offer !== null) {
				offerRef.current?.setValue(originalRowData.offer);
			}
			if (originalRowData.vatRate && originalRowData.vatRate !== null) {
				vatRateRef.current?.setValue(originalRowData.vatRate);
			}
		}
	}, [originalRowData]);

	const handleOfferChange = useCallback((selectedOffer) => {
		const offerDescription = offers.filter((o) => o.id === selectedOffer);
		if (offerDescription.length !== 1) {
			return;
		}
		designationRef.current.setValue(offerDescription[0].description);
	}, [offers]);

	return (
		<tr key={`tr.${row.id}`}>
			{columnsHeaders.filter((elem) => elem.show).map((header) => {
				switch (header.label) {
					case InvoiceColumnHeader.unitary.ACCOUNT:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<Select
									ref={accountRef}
									allowNull
									isLoading={isLoading}
									label=""
									labelKey="description"
									name={`rows.${row.id}.${header.label}`}
									options={accounts}
									readOnly={readOnly}
									valueKey="id"
								/>
							</td>
						);
					case InvoiceColumnHeader.unitary.OFFER:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<Select
									ref={offerRef}
									allowNull
									isLoading={isLoading}
									label=""
									labelKey="name"
									name={`rows.${row.id}.${header.label}`}
									onChange={handleOfferChange}
									options={offers}
									readOnly={readOnly}
									valueKey="id"
								/>
							</td>
						);
					case InvoiceColumnHeader.unitary.VAT_RATE:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<Select
									ref={vatRateRef}
									allowNull
									isLoading={isLoading}
									label=""
									labelKey="rate"
									name={`rows.${row.id}.${header.label}`}
									onChange={setTotals}
									options={vatRates}
									readOnly={readOnly}
									valueKey="id"
								/>
							</td>
						);
					case InvoiceColumnHeader.advancement.ALREADY_INVOICED:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={alreadyInvoicedRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									icon="percentage"
									label=""
									name={`rows.${row.id}.${header.label}`}
									readOnly={readOnly}
									step={0.01}
									style={{ textAlign: 'end' }}
								/>
							</td>
						);
					case InvoiceColumnHeader.advancement.BASE_AMOUNT:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={baseAmountRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									icon={currencyName}
									label=""
									name={`rows.${row.id}.${header.label}`}
									onChange={setBaseAmountValue}
									readOnly={readOnly}
									step={0.01}
									style={{ textAlign: 'end' }}
								/>
							</td>
						);
					case InvoiceColumnHeader.advancement.CURRENTLY_INVOICED:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={currentlyInvoicedRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									icon="percentage"
									label=""
									name={`rows.${row.id}.${header.label}`}
									onChange={setCurrentlyInvoicedValue}
									readOnly={readOnly}
									step={0.01}
									style={{ textAlign: 'end' }}
								/>
							</td>
						);
					case InvoiceColumnHeader.unitary.DISCOUNT:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={discountRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									icon="percentage"
									label=""
									name={`rows.${row.id}.${header.label}`}
									onChange={setDiscountValue}
									readOnly={readOnly}
									step={0.01}
									style={{ textAlign: 'end' }}
								/>
							</td>
						);
					case InvoiceColumnHeader.unitary.PRICE:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={priceRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									icon={currencyName}
									label=""
									name={`rows.${row.id}.${header.label}`}
									onChange={setPriceValue}
									readOnly={readOnly}
									step={0.01}
									style={{ textAlign: 'end' }}
								/>
							</td>
						);
					case InvoiceColumnHeader.unitary.QUANTITY:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={quantityRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									label=""
									name={`rows.${row.id}.${header.label}`}
									onChange={setQuantityValue}
									readOnly={readOnly}
									step={0.01}
									style={{ textAlign: 'end' }}
								/>
							</td>
						);
					case InvoiceColumnHeader.unitary.DESIGNATION:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<TextInput
									ref={designationRef}
									label=""
									name={`rows.${row.id}.${header.label}`}
									rules={{
										required: Validators.isRequired('trigger_red_borders'),
									}}
									readOnly={readOnly}
								/>
							</td>
						);
					case InvoiceColumnHeader.unitary.TOTAL:
						return (
							<td key={`td.${row.id}.${header.label}`} className="cell-total">
								{formatNumber(grossTotalDiscountedForLine.toFixed(2), { symbol: currencySymbol })}
							</td>
						);
					case InvoiceColumnHeader.unitary.ACTIONS:
						return (
							readOnly ? null : (
								<td key={`td.${row.id}.${header.label}`} className="row-actions">
									<Button
										className="icon-only"
										onClick={() => updateRow('move', row.id, row.line, 1)}
										disabled={row.line === rowItems || formDisabled}
									>
										<ArrowDown />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateRow('move', row.id, row.line, -1)}
										disabled={row.line === 1 || formDisabled}
									>
										<ArrowUp />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateRow('duplicate', row.id, row.line)}
										disabled={formDisabled}
									>
										<Copy />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateRow('delete', row.id)}
										disabled={formDisabled}
									>
										<XCircle />
									</Button>
									<HiddenInput
										name={`rows.${row.id}.line`}
										value={row.line}
									/>
								</td>
							)
						);
					default:
						return (<td key={`td.${row.id}.default`}>##</td>);
				}
			})}
		</tr>
	);
};

InvoiceGridRow.propTypes = {
	accounts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			description: PropTypes.string,
		}),
	).isRequired,
	columnsHeaders: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			show: PropTypes.bool,
		}),
	).isRequired,
	currencyName: PropTypes.string,
	currencySymbol: PropTypes.string,
	defaultValues: PropTypes.shape({
		rows: PropTypes.shape({
			id: PropTypes.string,
			netCost: PropTypes.number,
			vatRate: PropTypes.object,
		}),
	}),
	formDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		}),
	).isRequired,
	readOnly: PropTypes.bool,
	row: PropTypes.shape({
		id: PropTypes.string,
		line: PropTypes.number,
	}).isRequired,
	rowItems: PropTypes.number.isRequired,
	vatRates: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			rate: PropTypes.number,
		}),
	).isRequired,
	// functions
	updateRow: PropTypes.func.isRequired,
	setTotals: PropTypes.func.isRequired,
	// duplicate logic,
	originalRowData: PropTypes.object,
};

InvoiceGridRow.defaultProps = {
	currencyName: '',
	currencySymbol: '',
	defaultValues: undefined,
	formDisabled: false,
	readOnly: false,
	isLoading: true,
	originalRowData: undefined,
};

export default InvoiceGridRow;
