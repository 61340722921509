import styled from 'styled-components';
import {
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	margin,
	padding,
	position,
	shadow,
	typography,
} from 'styled-system';

const StyledDiv = styled('div')(
	{
		visibility: (({ visibility }) => (visibility || 'initial')),
		boxSizing: (({ boxSizing }) => (boxSizing || 'border-box')),
		backdropFilter: (({ backdropFilter }) => (backdropFilter || 'initial')),
		filter: (({ filter }) => (filter || 'initial')),
	},
	background,
	border,
	color,
	flexbox,
	grid,
	layout,
	margin,
	padding,
	position,
	shadow,
	typography,
);

export default StyledDiv;
