/* eslint-disable import/prefer-default-export */
import { parsnip, peppercorn } from 'theme/colors';

const baseTagStyle = {
	display: 'inline-flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '26px',
	borderRadius: '12px',
	bg: parsnip.default,
	color: peppercorn.default,
	paddingX: '12px',
	paddingY: '4px',
};

export {
	baseTagStyle,
};
