import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as TargetingsApi from 'api/targetingsApi';
import download from 'downloadjs';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Targetings redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific targeting
	FETCH_TARGETING_REQUEST: '@TARGETINGS/FETCH_REQUEST',
	FETCH_TARGETING_SUCCESS: '@TARGETINGS/FETCH_SUCCESS',
	FETCH_TARGETING_FAILURE: '@TARGETINGS/FETCH_FAILURE',

	// Fetch a list of targetings
	FETCH_TARGETING_LIST_REQUEST: '@TARGETINGS/FETCH_LIST_REQUEST',
	FETCH_TARGETING_LIST_SUCCESS: '@TARGETINGS/FETCH_LIST_SUCCESS',
	FETCH_TARGETING_LIST_FAILURE: '@TARGETINGS/FETCH_LIST_FAILURE',

	// Add a new targeting to the database
	ADD_TARGETING_REQUEST: '@TARGETINGS/ADD_REQUEST',
	ADD_TARGETING_SUCCESS: '@TARGETINGS/ADD_SUCCESS',
	ADD_TARGETING_FAILURE: '@TARGETINGS/ADD_FAILURE',

	// Update an existing targeting from the database
	UPDATE_TARGETING_REQUEST: '@TARGETINGS/UPDATE_REQUEST',
	UPDATE_TARGETING_SUCCESS: '@TARGETINGS/UPDATE_SUCCESS',
	UPDATE_TARGETING_FAILURE: '@TARGETINGS/UPDATE_FAILURE',

	// Remove an existing targeting from the database
	REMOVE_TARGETING_REQUEST: '@TARGETINGS/REMOVE_REQUEST',
	REMOVE_TARGETING_SUCCESS: '@TARGETINGS/REMOVE_SUCCESS',
	REMOVE_TARGETING_FAILURE: '@TARGETINGS/REMOVE_FAILURE',

	// Archive an existing targeting from the database
	ARCHIVE_TARGETING_REQUEST: '@TARGETINGS/ARCHIVE_REQUEST',
	ARCHIVE_TARGETING_SUCCESS: '@TARGETINGS/ARCHIVE_SUCCESS',
	ARCHIVE_TARGETING_FAILURE: '@TARGETINGS/ARCHIVE_FAILURE',

	// Restore an existing targeting from the database
	RESTORE_TARGETING_REQUEST: '@TARGETINGS/RESTORE_REQUEST',
	RESTORE_TARGETING_SUCCESS: '@TARGETINGS/RESTORE_SUCCESS',
	RESTORE_TARGETING_FAILURE: '@TARGETINGS/RESTORE_FAILURE',

	// Fetch targeting stats
	FETCH_TARGETING_STATS_REQUEST: '@TARGETING_STATS/FETCH_REQUEST',
	FETCH_TARGETING_STATS_SUCCESS: '@TARGETING_STATS/FETCH_SUCCESS',
	FETCH_TARGETING_STATS_FAILURE: '@TARGETING_STATS/FETCH_FAILURE',

	// Export targeting stats as a file
	EXPORT_TARGETING_STATS_REQUEST: '@TARGETING_STATS/EXPORT_REQUEST',
	EXPORT_TARGETING_STATS_SUCCESS: '@TARGETING_STATS/EXPORT_SUCCESS',
	EXPORT_TARGETING_STATS_FAILURE: '@TARGETING_STATS/EXPORT_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Targeting list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetingListRequest
 * @description Action triggered anytime a targeting list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetingListRequest = () => ({ type: ActionTypes.FETCH_TARGETING_LIST_REQUEST });

/**
 * @function
 * @name fetchTargetingListSuccess
 * @description Action triggered as a result to a successful targeting list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetings	The list of retrieved targetings.
 * @param {number} totalCount	The total amount of targetings available in the database for the current user.
 *
 * @returns {object}
 */
const fetchTargetingListSuccess = ({ targetings, totalCount }) => ({
	type: ActionTypes.FETCH_TARGETING_LIST_SUCCESS,
	payload: { targetings, totalCount },
});

/**
 * @function
 * @name fetchTargetingListFailure
 * @description Action triggered as a result to a failed targeting list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetingListFailure = (error) => ({
	type: ActionTypes.FETCH_TARGETING_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific targeting fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetingRequest
 * @description Action triggered anytime a targeting fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetingRequest = () => ({ type: ActionTypes.FETCH_TARGETING_REQUEST });

/**
 * @function
 * @name fetchTargetingSuccess
 * @description Action triggered as a result to a successful targeting fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targeting	The retrieved targeting.
 *
 * @returns {object}
 */
const fetchTargetingSuccess = ({ targeting }) => ({
	type: ActionTypes.FETCH_TARGETING_SUCCESS,
	payload: { targeting },
});

/**
 * @function
 * @name fetchTargetingFailure
 * @description Action triggered as a result to a failed targeting fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetingFailure = (error) => ({
	type: ActionTypes.FETCH_TARGETING_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Targeting creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addTargetingRequest
 * @description Action triggered anytime a targeting creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addTargetingRequest = () => ({ type: ActionTypes.ADD_TARGETING_REQUEST });

/**
 * @function
 * @name addTargetingSuccess
 * @description Action triggered as a result to a successful targeting creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targeting	The created targeting object.
 *
 * @returns {object}
 */
const addTargetingSuccess = ({ targeting }) => ({
	type: ActionTypes.ADD_TARGETING_SUCCESS,
	payload: { targeting },
});

/**
 * @function
 * @name addTargetingFailure
 * @description Action triggered as a result to a failed targeting creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addTargetingFailure = (error) => ({
	type: ActionTypes.ADD_TARGETING_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Targeting update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateTargetingRequest
 * @description Action triggered anytime a targeting update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateTargetingRequest = () => ({ type: ActionTypes.UPDATE_TARGETING_REQUEST });

/**
 * @function
 * @name updateTargetingSuccess
 * @description Action triggered as a result to a successful targeting update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targeting	The updated targeting object.
 *
 * @returns {object}
 */
const updateTargetingSuccess = ({ targeting }) => ({
	type: ActionTypes.UPDATE_TARGETING_SUCCESS,
	payload: { targeting },
});

/**
 * @function
 * @name updateTargetingFailure
 * @description Action triggered as a result to a failed targeting update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateTargetingFailure = (error) => ({
	type: ActionTypes.UPDATE_TARGETING_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Targeting removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeTargetingRequest
 * @description Action triggered anytime a targeting deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeTargetingRequest = () => ({ type: ActionTypes.REMOVE_TARGETING_REQUEST });

/**
 * @function
 * @name removeTargetingSuccess
 * @description Action triggered as a result to a successful targeting deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param { string } deletedTargetingId	The removed targeting object id.
 *
 * @returns {object}
 */
const removeTargetingSuccess = ({ deletedTargetingId }) => ({
	type: ActionTypes.REMOVE_TARGETING_SUCCESS,
	payload: { deletedTargetingId },
});

/**
 * @function
 * @name removeTargetingFailure
 * @description Action triggered as a result to a failed targeting deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeTargetingFailure = (error) => ({
	type: ActionTypes.REMOVE_TARGETING_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Targeting archiving actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveTargetingRequest
 * @description Action triggered anytime an targeting archiving call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveTargetingRequest = () => ({ type: ActionTypes.ARCHIVE_TARGETING_REQUEST });

/**
 * @function
 * @name archiveTargetingSuccess
 * @description	Action triggered as a result to a successful targeting archiving API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targeting		The updated targeting object.
 *
 * @returns {object}
 */
const archiveTargetingSuccess = ({ targeting }) => ({
	type: ActionTypes.ARCHIVE_TARGETING_SUCCESS,
	payload: { targeting },
});

/**
 * @function
 * @name archiveTargetingFailure
 * @description Action triggered as a result to a failed targeting archiving API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveTargetingFailure = (error) => ({
	type: ActionTypes.ARCHIVE_TARGETING_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// /////////////// Targeting restoring actions /////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreTargetingRequest
 * @description Action triggered anytime an targeting restoring call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreTargetingRequest = () => ({ type: ActionTypes.RESTORE_TARGETING_REQUEST });

/**
 * @function
 * @name restoreTargetingSuccess
 * @description	Action triggered as a result to a successful targeting restoring API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targeting		The updated targeting object.
 *
 * @returns {object}
 */
const restoreTargetingSuccess = ({ targeting }) => ({
	type: ActionTypes.RESTORE_TARGETING_SUCCESS,
	payload: { targeting },
});

/**
 * @function
 * @name restoreTargetingFailure
 * @description Action triggered as a result to a failed targeting restoring API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreTargetingFailure = (error) => ({
	type: ActionTypes.RESTORE_TARGETING_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Targeting exporting actions /////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name exportTargetingStatsRequest
 * @description Action triggered anytime a targeting stats exporting call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const exportTargetingStatsRequest = () => ({ type: ActionTypes.EXPORT_TARGETING_STATS_REQUEST });

/**
 * @function
 * @name exportTargetingStatsSuccess
 * @description Action triggered as a result to a successful targeting stats exporting API call.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const exportTargetingStatsSuccess = () => ({ type: ActionTypes.EXPORT_TARGETING_STATS_SUCCESS });

/**
 * @function
 * @name exportTargetingStatsFailure
 * @description Action triggered as a result to a failed targeting stats exporting API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const exportTargetingStatsFailure = (error) => ({
	type: ActionTypes.EXPORT_TARGETING_STATS_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Targeting stats actions ///////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetingStatsRequest
 * @description Action triggered anytime a targeting stats fetch call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const fetchTargetingStatsRequest = () => ({ type: ActionTypes.FETCH_TARGETING_STATS_REQUEST });

/**
 * @function
 * @name fetchTargetingStatsSuccess
 * @description Action triggered as a result to a successful targeting stats fetch API call.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const fetchTargetingStatsSuccess = ({ targetingStats }) => ({
	type: ActionTypes.FETCH_TARGETING_STATS_SUCCESS,
	payload: { targetingStats },
});

/**
 * @function
 * @name fetchTargetingStatsFailure
 * @description Action triggered as a result to a failed targeting stats fetch API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetingStatsFailure = (error) => ({
	type: ActionTypes.FETCH_TARGETING_STATS_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetingList
 * @description Method used to update the targeting list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters.
 *
 * @returns {Promise}
 */
export const fetchTargetingList = (params) => (dispatch) => {
	dispatch(fetchTargetingListRequest());

	return TargetingsApi.fetchTargetings(params)
		.then(({ targetings, totalCount }) => dispatch(fetchTargetingListSuccess({ targetings, totalCount })))
		.catch((error) => dispatch(fetchTargetingListFailure(error)));
};

/**
 * @function
 * @name fetchTargeting
 * @description Method used to fetch the latest version of a specific targeting.
 *
 * @author Romaric Barthe
 *
 * @param {string} targetingId	The id of the targeting we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTargeting = (targetingId) => (dispatch) => {
	dispatch(fetchTargetingRequest());

	return TargetingsApi.fetchTargetingById(targetingId)
		.then(({ targeting }) => dispatch(fetchTargetingSuccess({ targeting })))
		.catch((error) => dispatch(fetchTargetingFailure(error)));
};

/**
 * @function
 * @name addTargeting
 * @description Method used to add a new targeting instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetingData	The data to create the new targeting from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const addTargeting = (targetingData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addTargetingRequest());

	return TargetingsApi.createTargeting(targetingData)
		.then(({ targeting }) => {
			toast.success(i18next.t('targeting.creation.toasts.success', { name: targeting?.project?.name }));
			dispatch(addTargetingSuccess({ targeting }));
			if (onSuccessRoute) {
				redirectOnSuccess(generatePath(onSuccessRoute, { targetingId: targeting.id }));
			}

			return targeting;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('targeting.creation.toasts.conflict'));
				dispatch(addTargetingFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('targeting.creation.toasts.error'));
				dispatch(addTargetingFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateTargeting
 * @description Method used to update an existing targeting instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetingData	The object to update the targeting with.
 * @param {string} targetingId		The id of the targeting we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const updateTargeting = (targetingData, targetingId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateTargetingRequest());

	return TargetingsApi.updateTargeting(targetingData, targetingId)
		.then(({ targeting }) => {
			dispatch(updateTargetingSuccess({ targeting }));
			toast.success(i18next.t('targeting.edition.toasts.success', { name: targeting?.project?.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('targeting.edition.toasts.conflict'));
				dispatch(updateTargetingFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('targeting.edition.toasts.error'));
				dispatch(updateTargetingFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeTargeting
 * @description Method used to remove an existing targeting instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targeting						The targeting we want to remove from the database.
 * @param {string} targeting.id						The id of the targeting we want to remove from the database.
 * @param {string} targeting.project				The project instance the provided targeting is linked to.
 * @param {string} targeting.project.name			The name of the project the provided targeting is linked to.
 *
 * @returns {Promise}
 */
export const removeTargeting = ({ id, project }) => (dispatch) => {
	dispatch(removeTargetingRequest());

	return TargetingsApi.deleteTargeting(id)
		.then(({ deletedTargetingId }) => {
			dispatch(removeTargetingSuccess({ deletedTargetingId }));
			toast.success(i18next.t('targeting.deletion.toasts.success', { name: project?.name }));
		})
		.catch((error) => {
			dispatch(removeTargetingFailure(error));
			toast.error(i18next.t('targeting.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveTargeting
 * @description	Method used to archive an existing targeting instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targeting						The targeting we want to archive.
 * @param {string} targeting.id						The id of the targeting we want to restore.
 * @param {string} targeting.project				The project instance the provided targeting is linked to.
 * @param {string} targeting.project.name			The name of the project the provided targeting is linked to.
 *
 * @returns {Promise}
 */
export const archiveTargeting = ({ id, project }) => (dispatch) => {
	dispatch(archiveTargetingRequest());

	return TargetingsApi.updateTargeting({ archived: true }, id)
		.then(({ targeting }) => {
			dispatch(archiveTargetingSuccess({ targeting }));
			toast.success(i18next.t('targeting.archiving.toasts.success', { name: project?.name }));
		})
		.catch((error) => {
			dispatch(archiveTargetingFailure(error));
			toast.error(i18next.t('targeting.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreTargeting
 * @description	Method used to restore an existing targeting instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targeting						The targeting we want to restore.
 * @param {string} targeting.id						The id of the targeting we want to restore.
 * @param {string} targeting.project				The project instance the provided targeting is linked to.
 * @param {string} targeting.project.name			The name of the project the provided targeting is linked to.
 *
 * @returns {Promise}
 */
export const restoreTargeting = ({ id, project }) => (dispatch) => {
	dispatch(restoreTargetingRequest());

	return TargetingsApi.updateTargeting({ archived: false }, id)
		.then(({ targeting }) => {
			dispatch(restoreTargetingSuccess({ targeting }));
			toast.success(i18next.t('targeting.restoring.toasts.success', { name: project?.name }));
		})
		.catch((error) => {
			dispatch(restoreTargetingFailure(error));
			toast.error(i18next.t('targeting.restoring.toasts.error'));
		});
};

/**
 * @function
 * @name exportTargetingStats
 * @description Method used to export targeting stats from the database.
 *
 * @author Roland Margelidon
 *
 * @param {string} targetingId	The id of the targeting we want to export.
 */
export const exportTargetingStats = (targetingId) => (dispatch) => {
	dispatch(exportTargetingStatsRequest());

	return TargetingsApi.exportTargetingStats(targetingId)
		.then(({ blob, fileName, fileType }) => {
			dispatch(exportTargetingStatsSuccess());
			toast.success(i18next.t('template.export.toasts.success'));

			download(blob, fileName, fileType);
		})
		.catch((error) => {
			dispatch(exportTargetingStatsFailure(error));
			toast.error(i18next.t('template.export.toasts.error'));
		});
};

/**
 * @function
 * @name fetchTargetingStats
 * @description Method used to fetch the latest version of stats on a specific targeting.
 *
 * @author Roland Margelidon
 *
 * @param {string} targetingId	The id of the targeting we want to retrieve stats.
 *
 * @returns {Promise}
 */
export const fetchTargetingStats = (targetingId) => (dispatch) => {
	dispatch(fetchTargetingStatsRequest());

	return TargetingsApi.fetchTargetingStats(targetingId)
		.then(({ targetingStats }) => dispatch(fetchTargetingStatsSuccess({ targetingStats })))
		.catch((error) => dispatch(fetchTargetingStatsFailure(error)));
};
