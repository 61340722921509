import * as ContactsApi from 'api/contactsApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the ContactsList redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a list of contacts
	FETCH_CONTACT_FOR_LIST_REQUEST: '@CONTACTS_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_CONTACT_FOR_LIST_SUCCESS: '@CONTACTS_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_CONTACT_FOR_LIST_FAILURE: '@CONTACTS_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Contact list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchContactsForListRequest
 * @description Action triggered anytime a contactsList list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchContactsForListRequest = () => ({ type: ActionTypes.FETCH_CONTACT_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchContactsForListSuccess
 * @description Action triggered as a result to a successful contactsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} contactsList		The list of retrieved contacts.
 * @param {number} totalCount		The total amount of contacts available in the database for the current user.
 *
 * @returns {object}
 */
const fetchContactsForListSuccess = ({ contactsList, totalCount }) => ({
	type: ActionTypes.FETCH_CONTACT_FOR_LIST_SUCCESS,
	payload: { contactsList, totalCount },
});

/**
 * @function
 * @name fetchContactsForListFailure
 * @description Action triggered as a result to a failed contactsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchContactsForListFailure = (error) => ({
	type: ActionTypes.FETCH_CONTACT_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchContactsForList
 * @description Method used to fetch the contactsList list.
 *
 * @author Romaric Barthe
 * @author Yann Hodiesne
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchContactsForList = (params) => (dispatch) => {
	dispatch(fetchContactsForListRequest());

	const newFilters = {
		...params,
		infoLevel: 'forList',
	};

	return ContactsApi.fetchContacts(newFilters)
		.then(({ contactsList, totalCount }) => dispatch(fetchContactsForListSuccess({ contactsList, totalCount })))
		.catch((error) => dispatch(fetchContactsForListFailure(error)));
};
