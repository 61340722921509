/* eslint-disable import/prefer-default-export */
import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchPartners
 * @description Retrieves a list of all partners from the API, specifying filters if present.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} [params]		The filter criterias.
 *
 * @returns {Promise}
 */
export const fetchPartners = (params = {}) => post('/api/partners', params);

/**
 * @function
 * @name fetchPartnerById
 * @description Retrieves an existing partner from the API, using the partnerId in parameters.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} partnerId	The id of the partner we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchPartnerById = (partnerId) => get(`/api/partner/${partnerId}`);

/**
 * @function
 * @name createPartner
 * @description Creates a new partner in the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} partnerData	The data to create the partner from.
 *
 * @returns {Promise}
 */
export const createPartner = (partnerData) => post('/api/partners/create', partnerData);

/**
 * @function
 * @name updatePartner
 * @description Updates an existing partner from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} partnerData	Data to update the partner with.
 * @param {string} partnerId	Id of the partner we want to update.
 *
 * @returns {Promise}
 */
export const updatePartner = (partnerData, partnerId) => put(`/api/partners/${partnerId}`, partnerData);

/**
 * @function
 * @name deletePartner
 * @description Removes an existing partner from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} partnerId	The id of the partner we want to remove.
 *
 * @returns {Promise}
 */
export const deletePartner = (partnerId) => del(`/api/partners/${partnerId}`);

/**
 * @function
 * @name exportPartners
 * @description Export existing partners from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {Object} data		The data to export the partners.
 *
 * @returns {Promise}
 */
export const exportPartners = (data) => post('/api/partners/export', data, 'attachment');

/**
 * @function
 * @name fetchAllForPartnerForm
 * @description Retrieves all information for partner's form from the API:
 * - contacts [{ id, firstName, lastName, [emails], [phones] }]
 * - currencies [{ id, name }]
 * - partners [{ id, name }]
 * - relationTypes [{ id, name }]
 * - registrations [{ id, name, type: { name } }]
 * - sectors [{ id, name, type: { name } }]
 *
 * @author Romaric Barthe.
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchAllForPartnerForm = (params) => post('/api/partner/form', params);
