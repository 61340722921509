import update from 'immutability-helper';

/**
 * @function
 * @name incrementElementsInFooter
 * @description Increments the number of elements in the footer
 *
 * @author Florian Fornazaric
 *
 * @param {object} state	The current state
 *
 * @returns {object} The updated state value
 */
const incrementElementsInFooter = (state) => {
	const { footer } = state;

	const newFooter = update(footer, {
		numberOfElements: {
			$set: footer.numberOfElements + 1,
		},
	});

	return {
		...state,
		footer: newFooter,
	};
};

export default incrementElementsInFooter;
