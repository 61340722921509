import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceCreationTypes, InvoiceMethods } from 'constants/invoiceEnums';
import { formatPaymentDelayName } from 'lib/paymentDelays/formatPaymentDelayData';
import PropTypes from 'prop-types';

import { Checkbox, HiddenInput, NumberInput, Select, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name InvoiceHeader
 * @description A form used to edit an existing invoice's information.
 *
 * @author Romaric Barthe
 *
 * @param {string}	creationType				If set, the type of creation.
 * @param {object}	currencies					The list of available currencies.
 * @param {bool}	isLoading				True is the data are still not fully loaded.
 * @param {object}	methods						The list of possible invoice methods.
 * @param {object}	paymentDelayCreationModal	The modal to create a user on the fly.
 * @param {object}	paymentDelays				The list of available payment delays.
 * @param {object}	projectCreationModal		The modal to create a project on the fly.
 * @param {object}	projects					The list of available projects.
 * @param {string}	sourceInvoiceId				If set, the id of the invoice to be reversed.
 * @param {object}	structures					The list of subsidiaries (incl the company itself).
 * @param {func}	setCurrency					The method to set the invoice currency.
 * @param {func}	setGlobalDiscount			The method to set the invoice discount.
 * @param {func}	setMethodSelected			The method to set the method.
 * @param {func}	setTotals					The method to calculate totals.
 */
const InvoiceHeader = ({
	creationType,
	currencies,
	isLoading,
	methods,
	paymentDelayCreationModal,
	paymentDelays,
	projectCreationModal,
	projects,
	sourceInvoiceId,
	structures,
	readOnly,
	// functions
	setCurrency,
	setMethodSelected,
	setTotals,
}) => {
	const { t } = useTranslation();

	const descriptionRef = useRef();
	const projectRef = useRef();

	const paymentDelaysSelectOptions = useMemo(() => paymentDelays.map((paymentDelay) => ({
		id: paymentDelay.id,
		preview: formatPaymentDelayName(paymentDelay),
	})), [paymentDelays]);

	const handleProjectChange = useCallback((selectedProject) => {
		const projectDescription = projects.filter((p) => p.id === selectedProject);
		if (projectDescription.length !== 1) {
			return;
		}
		descriptionRef.current.setValue(projectDescription[0].description);
	}, [projects]);

	return (
		<>
			<HiddenInput
				name="creationType"
				value={creationType}
			/>
			<HiddenInput
				name="reversedInvoice"
				value={creationType === InvoiceCreationTypes.REVERT ? sourceInvoiceId : ''}
			/>
			<Select
				ref={projectRef}
				isLoading={isLoading}
				label={t('invoice.edition.inputs.project.label')}
				labelKey="name"
				name="project"
				onChange={handleProjectChange}
				options={projects}
				placeholder={t('invoice.edition.inputs.project.placeholder')}
				readOnly={readOnly}
				rules={{
					required: Validators.isRequired(t('invoice.edition.inputs.project.validation_errors.required')),
				}}
				valueKey="id"
				{...projectCreationModal}
			/>
			<TextArea
				ref={descriptionRef}
				label={t('invoice.edition.inputs.comment.label')}
				name="comment"
				readOnly={readOnly}
			/>
			<TextInput
				name="reference"
				label={t('invoice.edition.inputs.reference.label')}
				readOnly={readOnly}
			/>
			<Select
				isLoading={isLoading}
				label={t('invoice.edition.inputs.currency.label')}
				labelKey="name"
				name="currency"
				onChange={setCurrency}
				options={currencies}
				readOnly={readOnly}
				rules={{
					required: Validators.isRequired(t('invoice.edition.inputs.currency.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<Select
				label={t('invoice.edition.inputs.method.label')}
				labelKey="label"
				name="method"
				onChange={setMethodSelected}
				options={methods}
				readOnly={readOnly}
				rules={{
					required: Validators.isRequired(t('invoice.edition.inputs.method.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<Select
				isLoading={isLoading}
				label={t('invoice.edition.inputs.structure.label')}
				labelKey="name"
				name="structure"
				options={structures}
				readOnly={readOnly}
				rules={{
					required: Validators.isRequired(t('invoice.edition.inputs.structure.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<Select
				label={t('invoice.edition.inputs.payment_delay.label')}
				name="paymentDelay"
				options={paymentDelaysSelectOptions}
				labelKey="preview"
				valueKey="id"
				isLoading={isLoading}
				rules={{
					required: Validators.isRequired(t('invoice.edition.inputs.payment_delay.validation_errors.required')),
				}}
				readOnly={readOnly}
				{...paymentDelayCreationModal}
			/>
			<Checkbox
				key="withoutVat"
				label={t('invoice.edition.inputs.withoutVat.label')}
				name="withoutVat"
				readOnly={readOnly}
			/>
			<NumberInput
				decimalScale={2}
				fixedDecimalScale
				icon="percentage"
				label={t('invoice.creation.inputs.discount.label')}
				name="discount"
				onChange={setTotals}
				readOnly={readOnly}
			/>
		</>
	);
};

InvoiceHeader.propTypes = {
	readOnly: PropTypes.bool,
	creationType: PropTypes.oneOf(
		Object.values(InvoiceCreationTypes)
	),
	currencies: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	methods: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.oneOf(Object.values(InvoiceMethods)).isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	isLoading: PropTypes.bool,
	paymentDelayCreationModal: PropTypes.object.isRequired,
	paymentDelays: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	projectCreationModal: PropTypes.object.isRequired,
	projects: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	sourceInvoiceId: PropTypes.string,
	structures: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	// functions
	setCurrency: PropTypes.func.isRequired,
	setMethodSelected: PropTypes.func.isRequired,
	setTotals: PropTypes.func.isRequired,
};

InvoiceHeader.defaultProps = {
	creationType: undefined,
	isLoading: true,
	readOnly: false,
	sourceInvoiceId: '',
};

export default InvoiceHeader;
