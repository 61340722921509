import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Select } from 'components/shared/forms/inputs';

/**
 * @name PartnerRegistration
 * @description A form used to group the registration fields.
 *
 * @author Romaric Barthe
 */
const PartnerRegistration = ({
	isLoading,
	registrations,
	registrationCreationModal,
	relationTypes,
	relationTypeCreationModal,
	sectors,
	sectorCreationModal,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<Select
				isLoading={isLoading}
				isMulti
				label={t('partner.edition.inputs.relation_type.label')}
				labelKey="name"
				name="relationTypes"
				options={relationTypes}
				valueKey="id"
				{...relationTypeCreationModal}
			/>
			<Select
				isLoading={isLoading}
				isMulti
				label={t('partner.edition.inputs.sector.label')}
				labelKey="fullname"
				name="sectors"
				options={sectors}
				valueKey="id"
				{...sectorCreationModal}
			/>
			<Select
				isLoading={isLoading}
				isMulti
				label={t('partner.edition.inputs.registration.label')}
				labelKey="fullname"
				name="registrations"
				options={registrations}
				valueKey="id"
				{...registrationCreationModal}
			/>
		</>
	);
};

PartnerRegistration.propTypes = {
	isLoading: PropTypes.bool,
	relationTypeCreationModal: PropTypes.object,
	relationTypes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string,
		})
	),
	registrationCreationModal: PropTypes.object,
	registrations: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string,
		})
	),
	sectorCreationModal: PropTypes.object,
	sectors: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string,
		})
	),
};

PartnerRegistration.defaultProps = {
	isLoading: true,
	relationTypeCreationModal: undefined,
	relationTypes: [],
	registrationCreationModal: undefined,
	registrations: [],
	sectorCreationModal: undefined,
	sectors: [],
};

export default PartnerRegistration;
