import { useCallback, useMemo, useRef, useState } from 'react';
import { BarChart2, ChevronDown, Download, Plus, Upload } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { extractOptionsEnums } from 'constants/exportEnums';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import PropTypes from 'prop-types';
import { addHistoriesWithTargetPartners, fetchHistoryList } from 'redux/actions/histories/histories';
import { fetchTargeting, fetchTargetingStats } from 'redux/actions/targetings/targetings';
import { fetchTargetPartnersForList } from 'redux/actions/targetPartners/targetPartnersList';
import { fetchTarget, fetchTargetStats, updateTarget } from 'redux/actions/targets/targets';
import { useHistoryLoadingSelector } from 'redux/selectors/histories/histories';
import { useTargetPartnersListLoadingSelector, useTargetPartnersListTotalCountSelector } from 'redux/selectors/targetPartners/targetPartnersList';
import { useCurrentTargetSelector } from 'redux/selectors/targets/targets';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { HistoryCreationStatusForm } from 'components/histories';
import { Button } from 'components/shared/buttons';
import StatusCell from 'components/shared/cells/StatusCell';
import { Dropdown } from 'components/shared/dropdown';
import { SearchInput } from 'components/shared/inputs';
import { DataListExtractPanel, useDataExtract } from 'components/shared/list/DataListExtracts';
import { Modal, TableUserPreferencesModal, useModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import ActionsCell from 'components/targetings/targetPartners/targetPartnerList/ActionsCell';
import HistoryCell from 'components/targetings/targetPartners/targetPartnerList/HistoryCell';

import { AccessRights, useAccessRight } from '../../../lib/shared/accessRights';

import TargetAddPartnerModalContent from './TargetAddPartnerModalContent';

import 'styles/pages/targetPartnerDetailsPages.scss';
import styles from 'styles/components/targets/_targetPartnerList.module.scss';

/**
 * @name TargetPartnerList
 * @description A component used to display the targeted partner list as an overview.
 *
 * @author Romaric Barthe
 *
 * @param {string}		targetingId				The id of the targeting.
 * @param {string}		targetId				The id of the target.
 * @param {object}		targetPartners			The list of the targetPartners for the target.
 * @param {function}	setTargetPartnerActive	The method to flag one of the target patner as being active.
 */
const TargetPartnerList = ({ targetingId, targetId, targetPartners, setTargetPartnerActive, ...otherProps }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const totalCount = useTargetPartnersListTotalCountSelector();
	const filters = { parentId: targetId };

	const target = useCurrentTargetSelector(targetId);

	const isTargetPartnerFetchPending = useTargetPartnersListLoadingSelector();
	const isHistoryFetchPending = useHistoryLoadingSelector();

	const ActionsCellWithProps = useCallback((props) => <ActionsCell onClickCallback={setTargetPartnerActive} {...props} />, [setTargetPartnerActive]);

	const headers = useMemo(() => {
		const essentialHeaders = [
			{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCellWithProps, name: t('targeting.target.target_partner.headers.actions') },
			{ id: 'name', accessor: (row) => row.partner?.name, Header: t('targeting.target.target_partner.fields.name'), pinColumn: true, sorting: 'partner.name' },
			{ id: 'status', Cell: StatusCell, Header: t('targeting.target.target_partner.fields.status'), sorting: 'status.name' },
			{ id: 'history', Header: t('targeting.target.target_partner.fields.history'), Cell: HistoryCell },
			{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('targeting.target.target_partner.fields.creation_date'), sorting: 'targetPartner.createdAt' },
			{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('targeting.target.target_partner.fields.update_date'), sorting: 'targetPartner.updatedAt' },
			{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('targeting.target.target_partner.fields.creation_name'), sorting: 'targetPartner.createdBy' },
			{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('targeting.target.target_partner.fields.update_name'), sorting: 'targetPartner.updatedBy' },
		];

		if (!targetId) {
			essentialHeaders.push({ id: 'target', accessor: (row) => row.target?.name, Header: t('targeting.target.target_partner.headers.target'), sorting: 'target.name' });
		}

		return essentialHeaders;
	}, [ActionsCellWithProps, targetId, t]);

	const [fetchData, searchRef] = useSearch((params) => {
		const newParams = {
			...params,
			filters: { conditions: [{ column: 'targetId', criterion: 'is', value: targetId }] },
		};
		if (!targetId) {
			newParams.filters = { conditions: [{ column: 'targetingId', criterion: 'is', value: targetingId }] };
		}

		return dispatch(fetchTargetPartnersForList(newParams));
	});

	const [addSearchButtonRef, addSearchTooltipProps] = useTooltip();
	const [chartButtonRef, chartTooltipProps] = useTooltip();
	const [extractButtonRef, extractTooltipProps] = useTooltip();
	const [importButtonRef, importTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('targetPartner');
	const { isShowing: isStatusCreationModalShowing, toggle: toggleStatusCreationModal } = useModal();
	const { isShowing: isAddPartnersModalShowing, toggle: toggleAddPartnersModal } = useModal();
	const canEditTarget = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING);
	const { isExtractPanelDisplayed, toggleExtractPanel } = useDataExtract();

	const [selectedTargetPartnersIds, setSelectedTargetPartnersIds] = useState([]);

	const targetPartnersIndices = useMemo(() => targetPartners.map(
		(targetPartner, index) => (selectedTargetPartnersIds?.includes(targetPartner.id) ? index : null)
	).filter((partnerIndex) => partnerIndex !== null),
	[targetPartners, selectedTargetPartnersIds]);

	const targetPartnersPointer = useRef(targetPartners);
	targetPartnersPointer.current = targetPartners;
	const selectedTargetPartnersIdsPointer = useRef(selectedTargetPartnersIds);
	selectedTargetPartnersIdsPointer.current = selectedTargetPartnersIds;

	const selectedTargetPartnersIdsChanged = useCallback((pSelectedTargetPartners) => {
		const targetPartnersIds = targetPartnersPointer.current.map((targetPartner) => targetPartner.id);
		const pSelectedTargetPartnersIds = pSelectedTargetPartners.map((targetPartner) => targetPartner.id);

		const targetPartnersToAddIds = pSelectedTargetPartnersIds.filter(
			(targetPartnerToAddId) => !selectedTargetPartnersIdsPointer.current.includes(targetPartnerToAddId)
			&& targetPartnersIds.includes(targetPartnerToAddId)
		);

		const targetPartnersNoMoreToAddIds = selectedTargetPartnersIdsPointer.current.filter((targetPartner) => targetPartnersIds.includes(targetPartner))
			.filter((targetPartner) => !pSelectedTargetPartnersIds.includes(targetPartner));

		setSelectedTargetPartnersIds((value) => [...value.filter((targetPartner) => !targetPartnersNoMoreToAddIds.includes(targetPartner)), ...targetPartnersToAddIds]);
	}, []);

	/**
	 * @function
	 * @name onStatusCreationFormSubmit
	 * @description Callback function to call when the status creation form is submitted.
	 *
	 * @author Audrey Clerc
	 */
	const onStatusCreationFormSubmit = useCallback(async (formData) => {
		toggleStatusCreationModal();
		const submitData = {
			...formData,
			targetPartners: JSON.parse(formData.targetPartners),
		};

		const newParams = {
			...formData,
			filters: { conditions: [{ column: 'targetId', criterion: 'is', value: targetId }] },
		};
		if (!targetId) {
			newParams.filters = { conditions: [{ column: 'targetingId', criterion: 'is', value: targetingId }] };
		}

		await dispatch(addHistoriesWithTargetPartners(submitData));
		dispatch(fetchHistoryList({ rowsPerPage: 0, filters: { conditions: [{ column: 'targetPartnersId', criterion: 'is', value: targetPartners[0]?.id }] } }));
		dispatch(fetchTargetPartnersForList(newParams));
		dispatch(fetchTargetStats(targetId));
		dispatch(fetchTargetingStats(targetingId));
	}, [dispatch, targetId, targetPartners, targetingId, toggleStatusCreationModal]);

	// Handle the dropdown toggle
	const [showDropdown, setShowDropdown] = useState(false);

	const toggleDropdown = useCallback(() => {
		setShowDropdown((value) => !value);
	}, []);

	const closeDropdown = useCallback(() => {
		setShowDropdown(false);
	}, []);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback(async (formData, isInModalAddPartner = false) => {
		if (!isInModalAddPartner) {
			toggleAddPartnersModal();
		}
		if (canEditTarget) {
			const targetData = {
				...formData,
				targetingId,
			};
			const newParams = {
				...formData,
				filters: { conditions: [{ column: 'targetId', criterion: 'is', value: targetId }] },
			};
			if (!targetId) {
				newParams.filters = { conditions: [{ column: 'targetingId', criterion: 'is', value: targetingId }] };
			}
			await dispatch(updateTarget(targetData, targetId));
			dispatch(fetchTarget(targetId));
			dispatch(fetchTargetPartnersForList(newParams));
			dispatch(fetchTargetStats(targetId));
			dispatch(fetchTargetingStats(targetingId));
			dispatch(fetchTargeting(targetingId));
		}
	}, [canEditTarget, targetingId, dispatch, targetId, toggleAddPartnersModal]);

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => {
		history.push(generatePath(routes.operations.targetings.targets.targetLoadTargetPartners, { targetId, targetingId }));
	}, [targetId, targetingId]);

	return (
		targetPartners && targetingId ? (
			<div className="targetPartner-details-list" {...otherProps}>
				<TableActionsRow>
					<SearchInput ref={searchRef} onSearch={fetchData} />
					{targetId && (
						<>
							<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={importButtonRef}>
								<Upload />
							</Button>
							<Tooltip {...importTooltipProps}>
								{t('targeting.target.target_partner.import')}
							</Tooltip>
							<Button className="icon-only" onClick={toggleAddPartnersModal} ref={addSearchButtonRef}>
								<Plus />
							</Button>
							<Tooltip {...addSearchTooltipProps}>
								{t('targeting.target.target_partner.add_enterprises')}
							</Tooltip>
							<Modal
								isShowing={isAddPartnersModalShowing}
								title={t('targeting.target.target_partner.add_enterprises')}
								onClose={toggleAddPartnersModal}
								additionalStyle={{ maxWidth: '1000px' }}
							>
								{!isTargetPartnerFetchPending && (
									<TargetAddPartnerModalContent onSubmit={handleFormSubmit} target={target} isInModal />
								)}
							</Modal>
							<Button className="icon-only" onClick={toggleExtractPanel} ref={extractButtonRef}>
								<Download />
							</Button>
							<Tooltip {...extractTooltipProps}>
								{t('template.export.action')}
							</Tooltip>
						</>
					)}
					<div>
						<Button onClick={toggleDropdown} disabled={selectedTargetPartnersIds.length === 0} ref={chartButtonRef}>
							<BarChart2 />
							<ChevronDown />
						</Button>
						<Tooltip {...chartTooltipProps} disabled={selectedTargetPartnersIds.length === 0}>
							{t('targeting.target.actions.title', { count: selectedTargetPartnersIds.length })}
						</Tooltip>
						<Dropdown
							anchorPoint={{ x: 'initial', y: 'initial' }}
							isOpen={showDropdown}
							handleClose={closeDropdown}
							additionalStyle={{ padding: 0 }}
						>
							<Button
								className={styles['dropdown-list-item']}
								onClick={toggleStatusCreationModal}
							>
								{t('targeting.target.actions.status')}
							</Button>
						</Dropdown>
					</div>
					<Modal isShowing={isStatusCreationModalShowing} title={t('targeting.target.target_partner.update_status')} onClose={toggleStatusCreationModal}>
						{!isTargetPartnerFetchPending && !isHistoryFetchPending && selectedTargetPartnersIds && (
							/* After creating a new history, the creationForm has to be cleaned and closed: isLoading is doing this work */
							<HistoryCreationStatusForm
								onSubmit={onStatusCreationFormSubmit}
								targetPartnersIds={selectedTargetPartnersIds}
								targetingId={targetingId}
							/>
						)}
					</Modal>
				</TableActionsRow>
				<DynamicTable
					headers={headers}
					rows={targetPartners}
					rowsCount={totalCount}
					rowHeight={80}
					fetchData={fetchData}
					defaultSortingPrefix="target"
					selectionChanged={selectedTargetPartnersIdsChanged}
					selectedRowsIndices={targetPartnersIndices}
					{...tableProps}
				/>
				<TableUserPreferencesModal headers={headers} tableName={t('targeting.target.target_partner.pages.list')} {...modalProps} />
				<DataListExtractPanel
					entity={extractOptionsEnums.export.dataTypes.TARGET_PARTNERS}
					open={isExtractPanelDisplayed}
					onCloseButtonClick={toggleExtractPanel}
					selectedElementsIds={selectedTargetPartnersIds}
					filters={filters}
				/>
			</div>
		) : <p>{t('targeting.target.edition.loading')}</p>
	);
};

TargetPartnerList.propTypes = {
	targetingId: PropTypes.string.isRequired,
	targetId: PropTypes.string,
	targetPartners: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			partner: PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			}),
		})
	).isRequired,
	setTargetPartnerActive: PropTypes.func.isRequired,
};

TargetPartnerList.defaultProps = {
	targetId: null,
};

export default TargetPartnerList;
