import { useCallback, useMemo, useRef, useState } from 'react';
import { Download, Filter, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { extractOptionsEnums } from 'constants/exportEnums';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatLegalRepresentantName, formatPartnerParent } from 'lib/partners/formatPartnerData';
import { adaptToBackendFilters } from 'lib/shared/collectionsUtils';
import { formatCreatedAt, formatCreatedBy, formatCurrencyName, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchPartnersForList } from 'redux/actions/partners/partnersList';
import { usePartnersForListSelector, usePartnersListTotalCountSelector } from 'redux/selectors/partners/partnersList';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ActionsCell, ContactsCell, RegistrationsCell, RelationTypesCell, SectorsCell } from 'components/partners/partnerList';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { DataListExtractPanel, useDataExtract } from 'components/shared/list/DataListExtracts';
import { DataListFilterPanel, useDataFilter } from 'components/shared/list/DataListFilters';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name PartnerListPage
 * @description A page displaying the list of all partners.
 *
 * From this page, the user is able to view, create and edit any partner.
 *
 * @author Timothée Simon-Franza
 * @author Audrey Clerc
 * @author Romaric Barthe
 */
const PartnerListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const partners = usePartnersForListSelector();
	const totalCount = usePartnersListTotalCountSelector();

	const canCreatePartner = useAccessRight(AccessRights.commonModules.partners.enhancedRights.CREATE_PARTNER);

	const headers = useMemo(() => [
		{ accessor: 'name', Header: t('partner.fields.name'), pinColumn: true, sorting: 'partner.name' },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('partner.headers.actions') },
		{ id: 'parent', accessor: (row) => formatPartnerParent(row), Header: t('partner.fields.parent'), sorting: 'partner.parent' },
		{ id: 'relationTypes', Header: t('partner.fields.relation_types'), Cell: RelationTypesCell },
		{ id: 'sectors', Header: t('partner.fields.sector'), Cell: SectorsCell },
		{ id: 'registrations', Header: t('partner.fields.registration'), Cell: RegistrationsCell },
		{
			id: 'legalRepresentant',
			accessor: (row) => formatLegalRepresentantName(row),
			Header: t('partner.fields.legal_representant'),
			sorting: 'contact.legalRepresentant',
		},
		{ id: 'contacts', Header: t('partner.fields.contact'), Cell: ContactsCell },
		{ accessor: 'address', Header: t('partner.fields.address'), sorting: 'partner.address' },
		{ accessor: 'postCode', Header: t('partner.fields.post_code'), sorting: 'partner.postCode' },
		{ accessor: 'city', Header: t('partner.fields.city'), sorting: 'partner.city' },
		{ accessor: 'country', Header: t('partner.fields.country'), sorting: 'partner.country' },
		{ accessor: 'activityDescription', Header: t('partner.fields.activity_description'), sorting: 'partner.activityDescription' },
		{ accessor: 'workforce', Header: t('partner.fields.workforce'), sorting: 'partner.workforce' },
		{ accessor: 'workforceSlice', Header: t('partner.fields.workforce_slice'), sorting: 'partner.workforceSlice' },
		{ accessor: 'turnover', Header: t('partner.fields.turnover'), sorting: 'partner.turnover' },
		{ accessor: 'turnoverSlice', Header: t('partner.fields.turnover_slice'), sorting: 'partner.turnoverSlice' },
		{ accessor: 'website', Header: t('partner.fields.website'), sorting: 'partner.website' },
		{ id: 'currency', accessor: (row) => formatCurrencyName(row), Header: t('partner.fields.currency'), sorting: 'partner.currency' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('partner.fields.creation_name'), sorting: 'partner.createdBy' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('partner.fields.creation_date'), sorting: 'partner.createdAt' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('partner.fields.update_name'), sorting: 'partner.updatedBy' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('partner.fields.update_date'), sorting: 'partner.updatedAt' },
	], [t]);

	const columns = useMemo(() => [
		{ accessor: 'name', label: t('partner.fields.name'), type: 'text' },
		{ accessor: 'parent', label: t('partner.fields.parent'), type: 'entity' },
		{ accessor: 'relationTypes', label: t('partner.fields.relation_types'), type: 'entity' },
		{ accessor: 'sectors', label: t('partner.fields.sector'), type: 'entity' },
		{ accessor: 'legalRepresentant', label: t('partner.fields.legal_representant'), type: 'entity' },
		{ accessor: 'contacts', label: t('partner.fields.contact'), type: 'entity' },
		{ accessor: 'address', label: t('partner.fields.address'), type: 'text' },
		{ accessor: 'postCode', label: t('partner.fields.post_code'), type: 'text' },
		{ accessor: 'city', label: t('partner.fields.city'), type: 'text' },
		{ accessor: 'country', label: t('partner.fields.country'), type: 'text' },
		{ accessor: 'activityDescription', label: t('partner.fields.activity_description'), type: 'text' },
		{ accessor: 'workforce', label: t('partner.fields.workforce'), type: 'text' },
		{ accessor: 'workforceSlice', label: t('partner.fields.workforce_slice'), type: 'text' },
		{ accessor: 'turnover', label: t('partner.fields.turnover'), type: 'text' },
		{ accessor: 'turnoverSlice', label: t('partner.fields.turnover_slice'), type: 'text' },
		{ accessor: 'website', label: t('partner.fields.website'), type: 'text' },
		{ accessor: 'currency', label: t('partner.fields.currency'), type: 'text' },
		{ accessor: 'createdBy', label: t('partner.fields.creation_name'), type: 'text' },
		{ accessor: 'createdAt', label: t('partner.fields.creation_date'), type: 'date' },
		{ accessor: 'updatedBy', label: t('partner.fields.update_name'), type: 'text' },
		{ accessor: 'updatedAt', label: t('partner.fields.update_date'), type: 'date' },
	], [t]);

	const [filters, setFilters] = useState({});
	const [params, setParams] = useState({});
	const [fetchData, searchRef] = useSearch((param) => {
		setParams(param);
		dispatch(fetchPartnersForList({ ...filters, ...param }));
	});

	const [extractButtonRef, extractTooltipProps] = useTooltip();
	const [filterButtonRef, filterTooltipProps] = useTooltip();
	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('partner');

	const { isExtractPanelDisplayed, toggleExtractPanel } = useDataExtract();
	const { isFilterPanelDisplayed, toggleFilterPanel } = useDataFilter();

	const applyFilter = useCallback((filter) => {
		const newFilters = adaptToBackendFilters(filter);

		setFilters(newFilters);
		dispatch(fetchPartnersForList({ filters: newFilters, ...params }));
	}, [dispatch, params]);

	const [selectedPartnersIds, setSelectedPartnersIds] = useState([]);
	const partnersIndices = useMemo(() => partners.map(
		(partner, index) => (selectedPartnersIds?.includes(partner.id) ? index : null)
	).filter((partnerIndex) => partnerIndex !== null),
	[partners, selectedPartnersIds]);

	const partnersPointer = useRef(partners);
	partnersPointer.current = partners;
	const selectedPartnersIdsPointer = useRef(selectedPartnersIds);
	selectedPartnersIdsPointer.current = selectedPartnersIds;

	const selectedPartnersIdsChanged = useCallback((pSelectedPartners) => {
		const partnersId = partnersPointer.current.map((partner) => partner.id);
		const pSelectedPartnersIds = pSelectedPartners.map((partner) => partner.id);

		const partnersToAddIds = pSelectedPartnersIds.filter(
			(partnerToAddId) => !selectedPartnersIdsPointer.current.includes(partnerToAddId)
			&& partnersId.includes(partnerToAddId)
		);
		const partnersNoMoreToAddIds = selectedPartnersIdsPointer.current.filter((partnerId) => partnersId.includes(partnerId))
			.filter((partnerId) => !pSelectedPartnersIds.includes(partnerId));

		setSelectedPartnersIds((value) => [...value.filter((partnerId) => !partnersNoMoreToAddIds.includes(partnerId)), ...partnersToAddIds]);
	}, []);

	/**
	 *
	 * @name onClickFilterPanel
	 * @description When opening the filter panel, close the extract panel if needed
	 */
	const onClickFilterPanel = () => {
		if (!isFilterPanelDisplayed && isExtractPanelDisplayed) {
			toggleExtractPanel();
		}
		toggleFilterPanel();
	};

	/**
	 *
	 * @name onClickExtractPanel
	 * @description When opening the extract panel, close the filter panel if needed
	 */
	const onClickExtractPanel = () => {
		if (!isExtractPanelDisplayed && isFilterPanelDisplayed) {
			toggleFilterPanel();
		}
		toggleExtractPanel();
	};

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.commonModules.partners.partnerCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreatePartner && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('partner.links.create')}
						</Tooltip>
					</>
				)}
				<Button className="icon-only" onClick={onClickFilterPanel} ref={filterButtonRef}>
					<Filter />
				</Button>
				<Tooltip {...filterTooltipProps}>
					{t('components.table.filters.actions.filter')}
				</Tooltip>
				<Button className="icon-only" onClick={onClickExtractPanel} ref={extractButtonRef}>
					<Download />
				</Button>
				<Tooltip {...extractTooltipProps}>
					{t('template.export.action')}
				</Tooltip>
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={partners}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="partner"
				selectionChanged={selectedPartnersIdsChanged}
				selectedRowsIndices={partnersIndices}
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('partner.pages.list')} {...modalProps} />
			<DataListFilterPanel
				applyFilterCallBack={applyFilter}
				columns={columns}
				open={isFilterPanelDisplayed}
				onCloseButtonClick={toggleFilterPanel}
			/>
			<DataListExtractPanel
				entity={extractOptionsEnums.export.dataTypes.PARTNERS}
				open={isExtractPanelDisplayed}
				onCloseButtonClick={toggleExtractPanel}
				selectedElementsIds={selectedPartnersIds}
				filters={filters}
			/>
		</div>
	);
};

export default PartnerListPage;
