import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * @name PasswordRequirementFeedback
 * @description A component used to display password requirements.
 *
 * @author Timothée Simon-Franza
 *
 * @param {bool}	checked				Whether the user's inputted value matches the requirement displayed by this component.
 * @param {string}	id					The id of this component's checkbox.
 * @param {bool}	hasInputBeenBlurred	Whether the input has been blurred.
 * @param {string}	translationKey		The translation key for the label.
 */
const PasswordRequirementFeedback = ({ checked, id, hasInputBeenBlurred, translationKey }) => {
	const { t } = useTranslation();

	return (
		<>
			<input type="checkbox" id={id} disabled checked={checked} aria-invalid={hasInputBeenBlurred && !checked} />
			<label htmlFor={id}>{t(translationKey)}</label>
		</>
	);
};

PasswordRequirementFeedback.propTypes = {
	checked: PropTypes.bool.isRequired,
	id: PropTypes.string.isRequired,
	hasInputBeenBlurred: PropTypes.bool.isRequired,
	translationKey: PropTypes.string.isRequired,
};

export default PasswordRequirementFeedback;
