import { toast } from 'react-toastify';
import * as MediaManagerApi from 'api/mediaManagerApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the MediaManager redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch the files and folders list
	FETCH_MEDIAS_REQUEST: '@MEDIA_MANAGER/FETCH_REQUEST',
	FETCH_MEDIAS_SUCCESS: '@MEDIA_MANAGER/FETCH_SUCCESS',
	FETCH_MEDIAS_FAILURE: '@MEDIA_MANAGER/FETCH_FAILURE',

	// Fetch a single file
	FETCH_FILE_REQUEST: '@MEDIA_MANAGER/FETCH_FILE_REQUEST',
	FETCH_FILE_SUCCESS: '@MEDIA_MANAGER/FETCH_FILE_SUCCESS',
	FETCH_FILE_FAILURE: '@MEDIA_MANAGER/FETCH_FILE_FAILURE',

	// Add a new file to the database
	ADD_FILE_REQUEST: '@MEDIA_MANAGER/ADD_FILE_REQUEST',
	ADD_FILE_SUCCESS: '@MEDIA_MANAGER/ADD_FILE_SUCCESS',
	ADD_FILE_FAILURE: '@MEDIA_MANAGER/ADD_FILE_FAILURE',

	// Add a new folder to the database
	ADD_FOLDER_REQUEST: '@MEDIA_MANAGER/ADD_FOLDER_REQUEST',
	ADD_FOLDER_SUCCESS: '@MEDIA_MANAGER/ADD_FOLDER_SUCCESS',
	ADD_FOLDER_FAILURE: '@MEDIA_MANAGER/ADD_FOLDER_FAILURE',

	// Update an existing file from the database
	UPDATE_FILE_REQUEST: '@MEDIA_MANAGER/UPDATE_FILE_REQUEST',
	UPDATE_FILE_SUCCESS: '@MEDIA_MANAGER/UPDATE_FILE_SUCCESS',
	UPDATE_FILE_FAILURE: '@MEDIA_MANAGER/UPDATE_FILE_FAILURE',

	// Update an existing folder from the database
	UPDATE_FOLDER_REQUEST: '@MEDIA_MANAGER/UPDATE_FOLDER_REQUEST',
	UPDATE_FOLDER_SUCCESS: '@MEDIA_MANAGER/UPDATE_FOLDER_SUCCESS',
	UPDATE_FOLDER_FAILURE: '@MEDIA_MANAGER/UPDATE_FOLDER_FAILURE',

	// Remove an existing file from the database
	REMOVE_FILE_REQUEST: '@MEDIA_MANAGER/REMOVE_FILE_REQUEST',
	REMOVE_FILE_SUCCESS: '@MEDIA_MANAGER/REMOVE_FILE_SUCCESS',
	REMOVE_FILE_FAILURE: '@MEDIA_MANAGER/REMOVE_FILE_FAILURE',

	// Remove an existing folder from the database
	REMOVE_FOLDER_REQUEST: '@MEDIA_MANAGER/REMOVE_FOLDER_REQUEST',
	REMOVE_FOLDER_SUCCESS: '@MEDIA_MANAGER/REMOVE_FOLDER_SUCCESS',
	REMOVE_FOLDER_FAILURE: '@MEDIA_MANAGER/REMOVE_FOLDER_FAILURE',
};

// //////////////////////////////////////////////////////// //
// /////////////    Medias fetching actions    //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchMediasRequest
 * @description Action triggered anytime a medias fetching call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchMediasRequest = () => ({ type: ActionTypes.FETCH_MEDIAS_REQUEST });

/**
 * @function
 * @name fetchMediasSuccess
 * @description Action triggered as a result to a successful medias fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} files	The retrieved files.
 * @param {object} folders	The retrieved folders.
 *
 * @returns {object}
 */
const fetchMediasSuccess = ({ files, folders }) => ({
	type: ActionTypes.FETCH_MEDIAS_SUCCESS,
	payload: { files, folders },
});

/**
 * @function
 * @name fetchMediasFailure
 * @description Action triggered as a result to a failed medias fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchMediasFailure = (error) => ({
	type: ActionTypes.FETCH_MEDIAS_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////////     File fetching actions     //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchFileRequest
 * @description Action triggered anytime a file fetching call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchFileRequest = () => ({ type: ActionTypes.FETCH_FILE_REQUEST });

/**
  * @function
  * @name fetchFileSuccess
  * @description Action triggered as a result to a successful file fetching API call.
  *
  * @author Yann Hodiesne
  *
  * @param {object} file	The retrieved file.
  *
  * @returns {object}
  */
const fetchFileSuccess = ({ file }) => ({
	type: ActionTypes.FETCH_FILE_SUCCESS,
	payload: { file },
});

/**
  * @function
  * @name fetchFileFailure
  * @description Action triggered as a result to a failed file fetching API call.
  *
  * @author Yann Hodiesne
  *
  * @param {object} error	The exception sent back from the API.
  *
  * @returns {object}
  */
const fetchFileFailure = (error) => ({
	type: ActionTypes.FETCH_FILE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ////////////////  Medias creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name uploadFileRequest
 * @description Action triggered anytime a file upload call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const uploadFileRequest = () => ({ type: ActionTypes.ADD_FILE_REQUEST });

/**
 * @function
 * @name uploadFileSuccess
 * @description Action triggered as a result to a successful file upload API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} file	The created file object.
 *
 * @returns {object}
 */
const uploadFileSuccess = ({ file }) => ({
	type: ActionTypes.ADD_FILE_SUCCESS,
	payload: { file },
});

/**
 * @function
 * @name uploadFileFailure
 * @description Action triggered as a result to a failed file upload API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The error sent back from the API.
 *
 * @returns {object}
 */
const uploadFileFailure = (error) => ({
	type: ActionTypes.ADD_FILE_FAILURE,
	payload: { error },
});

/**
 * @function
 * @name uploadFolderRequest
 * @description Action triggered anytime a folder creation call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const addFolderRequest = () => ({ type: ActionTypes.ADD_FOLDER_REQUEST });

/**
 * @function
 * @name addFolderSuccess
 * @description Action triggered as a result to a successful folder creation API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} folder	The created folder object.
 *
 * @returns {object}
 */
const addFolderSuccess = ({ folder }) => ({
	type: ActionTypes.ADD_FOLDER_SUCCESS,
	payload: { folder },
});

/**
 * @function
 * @name addFolderFailure
 * @description Action triggered as a result to a failed folder creation API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The error sent back from the API.
 *
 * @returns {object}
 */
const addFolderFailure = (error) => ({
	type: ActionTypes.ADD_FOLDER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////////////  Medias update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateFileRequest
 * @description Action triggered anytime a file update call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const updateFileRequest = () => ({ type: ActionTypes.UPDATE_FILE_REQUEST });

/**
 * @function
 * @name updateFileSuccess
 * @description Action triggered as a result to a successful file update API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} file	The updated file object.
 *
 * @returns {object}
 */
const updateFileSuccess = ({ file }) => ({
	type: ActionTypes.UPDATE_FILE_SUCCESS,
	payload: { file },
});

/**
 * @function
 * @name updateFileFailure
 * @description Action triggered as a result to a failed file update API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The error sent back from the API.
 *
 * @returns {object}
 */
const updateFileFailure = (error) => ({
	type: ActionTypes.UPDATE_FILE_FAILURE,
	payload: { error },
});

/**
 * @function
 * @name updateFolderRequest
 * @description Action triggered anytime a folder update call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const updateFolderRequest = () => ({ type: ActionTypes.UPDATE_FOLDER_REQUEST });

/**
 * @function
 * @name updateFolderSuccess
 * @description Action triggered as a result to a successful folder update API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} folder	The updated folder object.
 *
 * @returns {object}
 */
const updateFolderSuccess = ({ folder }) => ({
	type: ActionTypes.UPDATE_FOLDER_SUCCESS,
	payload: { folder },
});

/**
 * @function
 * @name updateFolderFailure
 * @description Action triggered as a result to a failed folder update API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The error sent back from the API.
 *
 * @returns {object}
 */
const updateFolderFailure = (error) => ({
	type: ActionTypes.UPDATE_FOLDER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ////////////////  Medias removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeFileRequest
 * @description Action triggered anytime a file deletion call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const removeFileRequest = () => ({ type: ActionTypes.REMOVE_FILE_REQUEST });

/**
 * @function
 * @name removeFileSuccess
 * @description Action triggered as a result to a successful file deletion API call.
 *
 * @author Yann Hodiesne
 *
 * @param {string} deletedFileId	The removed file id.
 *
 * @returns {object}
 */
const removeFileSuccess = ({ deletedFileId }) => ({
	type: ActionTypes.REMOVE_FILE_SUCCESS,
	payload: { deletedFileId },
});

/**
 * @function
 * @name removeFileFailure
 * @description Action triggered as a result to a failed file deletion API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeFileFailure = (error) => ({
	type: ActionTypes.REMOVE_FILE_FAILURE,
	payload: { error },
});

/**
 * @function
 * @name removeFolderRequest
 * @description Action triggered anytime a folder deletion call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const removeFolderRequest = () => ({ type: ActionTypes.REMOVE_FOLDER_REQUEST });

/**
 * @function
 * @name removeFolderSuccess
 * @description Action triggered as a result to a successful folder deletion API call.
 *
 * @author Yann Hodiesne
 *
 * @param {string} deletedFolderId	The removed folder id.
 *
 * @returns {object}
 */
const removeFolderSuccess = ({ deletedFolderId }) => ({
	type: ActionTypes.REMOVE_FOLDER_SUCCESS,
	payload: { deletedFolderId },
});

/**
 * @function
 * @name removeFolderFailure
 * @description Action triggered as a result to a failed folder deletion API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeFolderFailure = (error) => ({
	type: ActionTypes.REMOVE_FOLDER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchMedias
 * @description Method used to fetch the medias list.
 *
 * @author Yann Hodiesne
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchMedias = (params) => (dispatch) => {
	dispatch(fetchMediasRequest());

	return MediaManagerApi.fetchMedias(params)
		.then(({ files, folders }) => dispatch(fetchMediasSuccess({ files, folders })))
		.catch((error) => dispatch(fetchMediasFailure(error)));
};

/**
 * @function
 * @name fetchFile
 * @description Method used to fetch a single media manager file.
 *
 * @author Yann Hodiesne
 *
 * @param {object} fileId	The id of the file we want to retrieve.
 */
export const fetchFile = (fileId) => (dispatch) => {
	dispatch(fetchFileRequest());

	return MediaManagerApi.fetchFile(fileId)
		.then(({ file }) => {
			dispatch(fetchFileSuccess({ file }));

			return file;
		})
		.catch(({ status, message }) => {
			dispatch(fetchFileFailure({ status, message }));

			return null;
		});
};

/**
 * @function
 * @name uploadFile
 * @description Method used to upload a new file to the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} fileData				The data to create the new file from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const uploadFile = (fileData, onSuccessRoute = null) => (dispatch) => {
	dispatch(uploadFileRequest());

	return MediaManagerApi.uploadFile(fileData)
		.then(({ file }) => {
			toast.success(i18next.t('media_manager.file.creation.toasts.success', { name: file.name }));
			dispatch(uploadFileSuccess({ file }));
			redirectOnSuccess(onSuccessRoute);

			return file;
		})
		.catch(({ status, message }) => {
			toast.error(i18next.t('media_manager.file.creation.toasts.error'));
			dispatch(uploadFileFailure({ status, message }));
		});
};

/**
 * @function
 * @name createFolder
 * @description Method used to upload a new folder to the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} folderData			The data to create the new folder from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const createFolder = (folderData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addFolderRequest());

	return MediaManagerApi.createFolder(folderData)
		.then(({ folder }) => {
			toast.success(i18next.t('media_manager.folder.creation.toasts.success', { name: folder.name }));
			dispatch(addFolderSuccess({ folder }));
			redirectOnSuccess(onSuccessRoute);

			return folder;
		})
		.catch(({ status, message }) => {
			toast.error(i18next.t('media_manager.folder.creation.toasts.error'));
			dispatch(addFolderFailure({ status, message }));
		});
};

/**
 * @function
 * @name updateFile
 * @description Method used to update an existing file from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} fileData			The object to update the file with.
 * @param {string} fileId			The id of the file we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateFile = (fileData, fileId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateFileRequest());

	return MediaManagerApi.updateFile(fileData, fileId)
		.then(({ file }) => {
			dispatch(updateFileSuccess({ file }));
			toast.success(i18next.t('media_manager.file.edition.toasts.success', { name: `${file.name}` }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			toast.error(i18next.t('media_manager.file.edition.toasts.error'));
			dispatch(updateFileFailure({ status, message }));
		});
};

/**
 * @function
 * @name updateFolder
 * @description Method used to update an existing folder from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} folderData		The object to update the folder with.
 * @param {string} folderId			The id of the folder we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateFolder = (folderData, folderId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateFolderRequest());

	return MediaManagerApi.updateFolder(folderData, folderId)
		.then(({ folder }) => {
			dispatch(updateFolderSuccess({ folder }));
			toast.success(i18next.t('media_manager.folder.edition.toasts.success', { name: `${folder.name}` }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			toast.error(i18next.t('media_manager.folder.edition.toasts.error'));
			dispatch(updateFolderFailure({ status, message }));
		});
};

/**
 * @function
 * @name removeFile
 * @description Method used to remove an existing file from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} file			The file we want to remove from the database.
 * @param {string} file.id		The id of the file we want to remove from the database.
 * @param {string} file.name	The name of the file we want to remove from the database (used in success toast).
 */
export const removeFile = ({ id, name }) => (dispatch) => {
	dispatch(removeFileRequest());

	return MediaManagerApi.deleteFile(id)
		.then(({ deletedFileId }) => {
			dispatch(removeFileSuccess({ deletedFileId }));
			toast.success(i18next.t('media_manager.file.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeFileFailure(error));
			toast.error(i18next.t('media_manager.file.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name removeFolder
 * @description Method used to remove an existing folder from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} folder		The folder we want to remove from the database.
 * @param {string} folder.id	The id of the folder we want to remove from the database.
 * @param {string} folder.name	The name of the folder we want to remove from the database (used in success toast).
 */
export const removeFolder = ({ id, name }) => (dispatch) => {
	dispatch(removeFolderRequest());

	return MediaManagerApi.deleteFolder(id)
		.then(({ deletedFolderId }) => {
			dispatch(removeFolderSuccess({ deletedFolderId }));
			toast.success(i18next.t('media_manager.folder.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeFolderFailure(error));
			toast.error(i18next.t('media_manager.folder.deletion.toasts.error'));
		});
};
