import PropTypes from 'prop-types';

import Modal from './Modal';

import 'styles/components/_imagePreviewModal.scss';

/**
 * @name ImagePreviewModal
 * @description Shows an image inside a modal to the user
 *
 * @author Yann Hodiesne
 *
 * @param {bool}	isShowing	 	Determines if the modal is currently being shown to the user
 * @param {func}	close		 	The function to execute when the user closes the modal
 * @param {string}	imageUrl	 	The URL to the image to display inside the modal
 */
const ImagePreviewModal = ({ isShowing, close, imageUrl }) => (
	<Modal className="image-preview-modal" isShowing={isShowing} onClose={close}>
		<img className="image-preview" crossOrigin="anonymous" alt="" src={imageUrl} />
	</Modal>
);

ImagePreviewModal.propTypes = {
	isShowing: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	imageUrl: PropTypes.string.isRequired,
};

export default ImagePreviewModal;
