const PageOrientations = Object.freeze({
	PORTRAIT: 'portrait',
	LANDSCAPE: 'landscape',
});

const PageOrientationDimensions = Object.freeze({
	[PageOrientations.PORTRAIT]: {
		width: 595,
		height: 842,
	},
	[PageOrientations.LANDSCAPE]: {
		width: 842,
		height: 595,
	},
});

export {
	PageOrientationDimensions,
	PageOrientations,
};
