import { createElement, forwardRef, useCallback, useEffect, useId, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';
import { useForwardedRef } from 'lib/hooks';
import { isDev } from 'lib/shared/environmentHelper';
import PropTypes from 'prop-types';
import { reactSelectStyle } from 'theme/inputStyles';

import { Modal, useModal } from 'components/shared/modal';

import useDynamicFormInput from '../useDynamicFormInput';

import { Error, Hint, Label } from './DynamicFormInput';

/**
 * @name Select
 * @description A decorated version of the react-select component to be used inside a dynamic form.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {string}	name					The input's name.
 * @param {string}	label					The string to display as a label for the wrapped input.
 * @param {Array}	options					The list of elements to display as select options.
 * @param {string}	[placeholder]			The value to display as a placeholder in the input.
 * @param {string}	[hint]					A hint message to display below the input (if no error is displayed).
 * @param {object}	[rules={}]				The validation rules to apply to this input.
 * @param {bool}	[isMulti=false]			Whether the user can select several options.
 * @param {string}	[labelKey]				The key to use for the option's label.
 * @param {string}	[valueKey]				The key to use for the option's value.
 * @param {bool}	[searchable=true]		Whether the user can search for options.
 * @param {boolean}	[isLoading]				Whether the options array is being loaded.
 * @param {bool}	[allowNull=false]		Whether the component should display a "null option" to the user.
 * @param {func}	[creationComponent]		For use with {@link useFormModal} hook.
 * @param {string}	[creationTitle]			For use with {@link useFormModal} hook.
 * @param {func}	[dispatchCreate]		For use with {@link useFormModal} hook.
 * @param {func}	[dispatchFetch]			For use with {@link useFormModal} hook.
 */
const Select = forwardRef(({
	name,
	label,
	options,
	placeholder,
	hint,
	rules,
	isMulti,
	labelKey,
	valueKey,
	searchable,
	isLoading,
	allowNull,
	creationComponent,
	creationTitle,
	dispatchCreate,
	dispatchFetch,
	className,
	...props
}, ref) => {
	const resolvedRef = useForwardedRef(ref);
	const { t } = useTranslation();

	const [value, setValue] = useState(null);
	const currentValue = useRef();

	const updateCurrentValue = useCallback((newValue = value) => {
		if (!isMulti) {
			currentValue.current = newValue?.value === 'null' ? null : newValue?.value;
		} else {
			currentValue.current = (newValue?.map((option) => option.value) ?? []).map((val) => (val === 'null' ? null : val));
		}
	}, [isMulti, value]);

	updateCurrentValue();

	// Format the provided options to match react-select's requirements
	const formattedOptions = useMemo(() => {
		const normalizedOptions = options.map((option) => ({
			label: option[labelKey],
			value: option[valueKey],
		}));

		return normalizedOptions;
	}, [labelKey, options, valueKey]);

	const getValue = useCallback(() => currentValue.current, []);

	const {
		defaultValue,
		isDisabled,
		isInvalid,
		isOptional,
		isReadOnly,
		validationError,
		enableValidationOnChange,
		onChangeHandler,
	} = useDynamicFormInput(name, getValue, rules, props);

	const initialized = useRef(false);
	useEffect(() => {
		if (!initialized.current) {
			if (defaultValue) {
				let resolvedDefaultValue;

				if (!isMulti) {
					resolvedDefaultValue = formattedOptions.find((option) => option.value === defaultValue);

					if (!resolvedDefaultValue) { return; }
				} else {
					resolvedDefaultValue = formattedOptions.filter((option) => defaultValue.includes(option.value));

					if (resolvedDefaultValue.length !== defaultValue.length) { return; }
				}

				setValue(resolvedDefaultValue);
			} else {
				setValue(!isMulti ? null : []);
			}

			initialized.current = true;
		}
	}, [defaultValue, formattedOptions, isMulti, name]);

	const onChange = useCallback((newValue) => {
		setValue(newValue);
		updateCurrentValue(newValue);

		onChangeHandler();
	}, [onChangeHandler, updateCurrentValue]);

	useImperativeHandle(resolvedRef, () => ({
		setValue: (newValue) => {
			if (!isMulti) {
				const resolvedValue = formattedOptions.find((option) => option.value === newValue);

				if (!resolvedValue) { throw new Error(`Unable to find value "${newValue}"`); }

				onChange(resolvedValue);
			} else {
				const resolvedValues = formattedOptions.filter((option) => newValue.includes(option.value));

				if (resolvedValues.length !== newValue.length) { throw new Error(`Unable to find values "${newValue}"`); }

				onChange(resolvedValues);
			}
		},
	}), [formattedOptions, isMulti, onChange]);

	// Registers the modal, in case useFormModal has been used on this Select
	const { isShowing, toggle } = useModal();

	// Custom IndicatorSeparator to inject our + button when useFormModal has been used on this Select
	const IndicatorSeparator = useCallback((separatorProps) => (
		<>
			{ /* eslint-disable-next-line react/prop-types */}
			{!isReadOnly && !isDisabled && creationComponent && (
				<button className="icon-only select-action" type="button" onClick={toggle}>
					<Plus />
				</button>
			)}
			<components.IndicatorSeparator {...separatorProps} />
		</>
	), [creationComponent, isDisabled, isReadOnly, toggle]);

	// Keep track of the last submitted entity, to add it automatically when we receive our new options
	const [newValue, setNewValue] = useState(null);

	useEffect(() => {
		// If we created an entity and stored its label, we can try to add it to the selected options
		if (newValue !== null) {
			setValue((oldValue) => {
				if (isMulti) {
					// Try to find the option matching the new entity
					const newOption = formattedOptions.find((option) => newValue === option.value);

					if (newOption) {
						setNewValue(null);

						// If isMulti is set to true, we want to append the new option at the end of the selected values
						return [...oldValue, newOption];
					}
				} else {
					// Try to find the option matching the new entity
					const result = formattedOptions.find((option) => newValue === option.value);

					if (result) {
						setNewValue(null);

						// If isMulti is set to false, we want to replace the selected value
						return result;
					}
				}

				return oldValue;
			});
		}
	}, [formattedOptions, isMulti, newValue]);

	// The modal's submit handler
	const creationSubmitHandler = useCallback((formData, ...params) => {
		dispatchCreate(formData, ...params)
			.then((entity) => {
				if (!entity) {
					if (isDev()) {
						// eslint-disable-next-line no-console
						console.warn(
							`(development message) Select[name="${name}"] component could not select the newly created entity, `
							+ 'please ensure the action creator used to create this entity returns it at the end of its Promise.'
						);
					}

					return Promise.resolve();
				}

				setNewValue(entity[valueKey]);

				return dispatchFetch();
			});

		toggle();
	}, [dispatchCreate, toggle, valueKey, dispatchFetch, name]);

	const id = useId();

	return (
		<div className={`input-wrapper${isDisabled ? ' disabled' : ''}${className ?? ''}`}>
			<Label disabled={isDisabled} inputId={id} isInvalid={isInvalid}>
				{label}
				{isOptional && ` (${t('form.optional')})`}
			</Label>
			<ReactSelect
				inputId={id}
				className={`react-select${isDisabled ? ' disabled' : ''}`}
				components={{ IndicatorSeparator }}
				name={name}
				placeholder={isLoading ? '' : placeholder}
				isMulti={isMulti}
				options={formattedOptions}
				isClearable={allowNull}
				isDisabled={isDisabled || isReadOnly}
				isLoading={isLoading}
				isInvalid={isInvalid}
				isSearchable={searchable}
				styles={reactSelectStyle}
				noOptionsMessage={() => (isLoading ? t('components.select.loading') : t('components.select.no_value'))}
				value={value}
				onChange={onChange}
				onBlur={enableValidationOnChange}
			/>
			{isInvalid && <Error>{validationError ?? ''}</Error>}
			{!isInvalid && <Hint>{hint ?? ''}</Hint>}
			{creationComponent && (
				<Modal isShowing={isShowing} title={creationTitle} onClose={toggle}>
					{createElement(creationComponent, {
						onSubmit: creationSubmitHandler,
					})}
				</Modal>
			)}
		</div>
	);
});

Select.propTypes = {
	allowNull: PropTypes.bool,
	hint: PropTypes.string,
	isMulti: PropTypes.bool,
	label: PropTypes.string.isRequired,
	labelKey: PropTypes.string,
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	placeholder: PropTypes.string,
	rules: PropTypes.object,
	searchable: PropTypes.bool,
	valueKey: PropTypes.string,
	isLoading: PropTypes.bool,
	creationComponent: PropTypes.elementType,
	creationTitle: PropTypes.string,
	dispatchCreate: PropTypes.func,
	dispatchFetch: PropTypes.func,
	className: PropTypes.string,
};

Select.defaultProps = {
	allowNull: false,
	hint: '',
	isMulti: false,
	labelKey: 'label',
	placeholder: '',
	rules: {},
	valueKey: 'value',
	isLoading: false,
	searchable: true,
	creationComponent: null,
	creationTitle: '',
	dispatchCreate: () => {},
	dispatchFetch: () => {},
	className: undefined,
};

Select.displayName = 'Select';

export default Select;
