import { forwardRef } from 'react';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

/**
 * @name SquareColorPreviewComponent
 * @description A component to render in the preview of the colorpicker.
 * This is a forwardRef so we can place the color picker at the right position.
 *
 * @author Florian Fornazaric
 *
 * @param {object} style 	The style to apply to the component.
 * @param {object} children The children is never rendered in the component
 */
const SquareColorPreviewComponent = forwardRef(({ style, children, ...otherProps }, ref) => {
	const resolvedRef = useForwardedRef(ref);

	return (<div ref={resolvedRef} style={{ ...style, height: '100%', width: '100%', borderRadius: '6px' }} {...otherProps} />);
});

SquareColorPreviewComponent.displayName = 'PreviewComponent';

SquareColorPreviewComponent.propTypes = {
	style: PropTypes.object,
	children: PropTypes.node,
};

SquareColorPreviewComponent.defaultProps = {
	style: {},
	children: null,
};

export default SquareColorPreviewComponent;
