import { useCallback, useRef } from 'react';
import _ from 'lodash';

/**
 * @name useSearch
 * @description A custom search input hook, capable of handling both searching and a {@link DynamicTable}'s pagination
 *
 * Note: the returned fetchData function can be called without any arguments to trigger a fetch with the latest received criteria
 *
 * @author Yann Hodiesne
 *
 * @param {func} dispatchFetch function to call the API using given parameters
 *
 * @returns {Array}
 */
export default (dispatchFetch) => {
	// Refs used to merge the search input and the table's pagination
	const searchRef = useRef();
	const filters = useRef({});

	// Event handler listening for the data requests
	const fetchData = useCallback((params) => {
		// If the call comes from a DynamicTable, we need to get its parameters
		if (_.isObject(params)) {
			filters.current = params;
		}

		dispatchFetch({
			...filters.current,
			searchInput: searchRef.current.value.trim(),
		});
	}, [filters, searchRef, dispatchFetch]);

	return [fetchData, searchRef];
};
