import { toast } from 'react-toastify';
import * as InvoicesApi from 'api/invoicesApi';
import download from 'downloadjs';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the invoices redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific invoice
	FETCH_INVOICE_REQUEST: '@INVOICES/FETCH_REQUEST',
	FETCH_INVOICE_SUCCESS: '@INVOICES/FETCH_SUCCESS',
	FETCH_INVOICE_FAILURE: '@INVOICES/FETCH_FAILURE',

	// Fetch all for invoices
	FETCH_ALL_FOR_INVOICE_REQUEST: '@INVOICES/FETCH_ALL_FOR_REQUEST',
	FETCH_ALL_FOR_INVOICE_SUCCESS: '@INVOICES/FETCH_ALL_FOR_SUCCESS',
	FETCH_ALL_FOR_INVOICE_FAILURE: '@INVOICES/FETCH_ALL_FOR_FAILURE',

	// Fetch a list of invoices
	FETCH_INVOICE_LIST_REQUEST: '@INVOICES/FETCH_LIST_REQUEST',
	FETCH_INVOICE_LIST_SUCCESS: '@INVOICES/FETCH_LIST_SUCCESS',
	FETCH_INVOICE_LIST_FAILURE: '@INVOICES/FETCH_LIST_FAILURE',

	// Add a new invoice to the database
	ADD_INVOICE_REQUEST: '@INVOICES/ADD_REQUEST',
	ADD_INVOICE_SUCCESS: '@INVOICES/ADD_SUCCESS',
	ADD_INVOICE_FAILURE: '@INVOICES/ADD_FAILURE',

	// Update an existing invoice from the database
	UPDATE_INVOICE_REQUEST: '@INVOICES/UPDATE_REQUEST',
	UPDATE_INVOICE_SUCCESS: '@INVOICES/UPDATE_SUCCESS',
	UPDATE_INVOICE_FAILURE: '@INVOICES/UPDATE_FAILURE',

	// Remove an existing invoice from the database
	REMOVE_INVOICE_REQUEST: '@INVOICES/REMOVE_REQUEST',
	REMOVE_INVOICE_SUCCESS: '@INVOICES/REMOVE_SUCCESS',
	REMOVE_INVOICE_FAILURE: '@INVOICES/REMOVE_FAILURE',

	// Edit an existing invoice from the database
	EDIT_INVOICE_REQUEST: '@INVOICES/EDIT_REQUEST',
	EDIT_INVOICE_SUCCESS: '@INVOICES/EDIT_SUCCESS',
	EDIT_INVOICE_FAILURE: '@INVOICES/EDIT_FAILURE',

	// Statistics
	FETCH_INVOICES_STATS_REQUEST: '@INVOICES/FETCH_STATS_REQUEST',
	FETCH_INVOICES_STATS_SUCCESS: '@INVOICES/FETCH_STATS_SUCCESS',
	FETCH_INVOICES_STATS_FAILURE: '@INVOICES/FETCH_STATS_FAILURE',

	// Statistics
	FETCH_INVOICES_DASHBOARD_REQUEST: '@INVOICES/FETCH_DASHBOARD_REQUEST',
	FETCH_INVOICES_DASHBOARD_SUCCESS: '@INVOICES/FETCH_DASHBOARD_SUCCESS',
	FETCH_INVOICES_DASHBOARD_FAILURE: '@INVOICES/FETCH_DASHBOARD_FAILURE',

	// Export invoice as a file
	EXPORT_INVOICE_REQUEST: '@INVOICES/EXPORT_REQUEST',
	EXPORT_INVOICE_SUCCESS: '@INVOICES/EXPORT_SUCCESS',
	EXPORT_INVOICE_FAILURE: '@INVOICES/EXPORT_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Invoice list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchInvoiceListRequest
 * @description Action triggered anytime an invoice list fetching call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const fetchInvoiceListRequest = () => ({ type: ActionTypes.FETCH_INVOICE_LIST_REQUEST });

/**
 * @function
 * @name fetchInvoiceListSuccess
 * @description Action triggered as a result to a successful invoice list fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} invoices		The list of retrieved invoices.
 * @param {number} totalCount	The total amount of invoices available in the database for the current user.
 *
 * @returns {object}
 */
const fetchInvoiceListSuccess = ({ invoices, totalCount }) => ({
	type: ActionTypes.FETCH_INVOICE_LIST_SUCCESS,
	payload: { invoices, totalCount },
});

/**
 * @function
 * @name fetchInvoiceListFailure
 * @description Action triggered as a result to a failed invoice list fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchInvoiceListFailure = (error) => ({
	type: ActionTypes.FETCH_INVOICE_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////// Fetching all for invoices actions ///////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAllForInvoiceRequest
 * @description Action triggered anytime a invoice "all for" fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchAllForInvoiceRequest = () => ({ type: ActionTypes.FETCH_ALL_FOR_INVOICE_REQUEST });

/**
 * @function
 * @name fetchAllForInvoiceSuccess
 * @description Action triggered as a result to a successful invoice "all for" fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} allForForm	The object including all relevant information.
 *
 * @returns {object}
 */
const fetchAllForInvoiceSuccess = ({ allForForm }) => ({
	type: ActionTypes.FETCH_ALL_FOR_INVOICE_SUCCESS,
	payload: { allForForm },
});

/**
 * @function
 * @name fetchAllForInvoiceFailure
 * @description Action triggered as a result to a failed invoice "all for" fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAllForInvoiceFailure = (error) => ({
	type: ActionTypes.FETCH_ALL_FOR_INVOICE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific invoice fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchInvoiceRequest
 * @description Action triggered anytime an invoice fetching call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const fetchInvoiceRequest = () => ({ type: ActionTypes.FETCH_INVOICE_REQUEST });

/**
 * @function
 * @name fetchInvoiceSuccess
 * @description Action triggered as a result to a successful invoice fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} invoice The retrieved invoice.
 *
 * @returns {object}
 */
const fetchInvoiceSuccess = ({ invoice }) => ({
	type: ActionTypes.FETCH_INVOICE_SUCCESS,
	payload: { invoice },
});

/**
 * @function
 * @name fetchInvoiceFailure
 * @description Action triggered as a result to a failed invoice fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchInvoiceFailure = (error) => ({
	type: ActionTypes.FETCH_INVOICE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Invoice creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addInvoiceRequest
 * @description Action triggered anytime an invoice creation call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const addInvoiceRequest = () => ({ type: ActionTypes.ADD_INVOICE_REQUEST });

/**
 * @function
 * @name addInvoiceSuccess
 * @description Action triggered as a result to a successful invoice creation API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} invoice The created invoice object.
 *
 * @returns {object}
 */
const addInvoiceSuccess = ({ invoice }) => ({
	type: ActionTypes.ADD_INVOICE_SUCCESS,
	payload: { invoice },
});

/**
 * @function
 * @name addInvoiceFailure
 * @description Action triggered as a result to a failed invoice creation API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const addInvoiceFailure = (error) => ({
	type: ActionTypes.ADD_INVOICE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Invoice update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateInvoiceRequest
 * @description Action triggered anytime an invoice update call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const updateInvoiceRequest = () => ({ type: ActionTypes.UPDATE_INVOICE_REQUEST });

/**
 * @function
 * @name updateInvoiceSuccess
 * @description Action triggered as a result to a successful invoice update API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} invoice The updated invoice object.
 *
 * @returns {object}
 */
const updateInvoiceSuccess = ({ invoice }) => ({
	type: ActionTypes.UPDATE_INVOICE_SUCCESS,
	payload: { invoice },
});

/**
 * @function
 * @name updateInvoiceFailure
 * @description Action triggered as a result to a failed invoice update API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const updateInvoiceFailure = (error) => ({
	type: ActionTypes.UPDATE_INVOICE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Invoice removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeInvoiceRequest
 * @description Action triggered anytime an invoice deletion call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const removeInvoiceRequest = () => ({ type: ActionTypes.REMOVE_INVOICE_REQUEST });

/**
 * @function
 * @name removeInvoiceSuccess
 * @description Action triggered as a result to a successful invoice deletion API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} deletedInvoiceId The removed invoice id.
 *
 * @returns {object}
 */
const removeInvoiceSuccess = ({ deletedInvoiceId }) => ({
	type: ActionTypes.REMOVE_INVOICE_SUCCESS,
	payload: { deletedInvoiceId },
});

/**
 * @function
 * @name removeInvoiceFailure
 * @description Action triggered as a result to a failed invoice deletion API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const removeInvoiceFailure = (error) => ({
	type: ActionTypes.REMOVE_INVOICE_FAILURE,
	payload: { error },
});

// ///////////////////////////////////////////////////////// //
// ///////////////// Invoice edition actions /////////////// //
// ///////////////////////////////////////////////////////// //

/**
 * @function
 * @name editInvoiceRequest
 * @description Action triggered anytime an invoice edition call is made to the API.
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
const editInvoiceRequest = () => ({ type: ActionTypes.EDIT_INVOICE_REQUEST });

/**
 * @function
 * @name editInvoiceSuccess
 * @description Action triggered as a result to a successful invoice edition API call.
 *
 * @author Florian Fornazaric
 * @author Romaric Barthe
 *
 * @param {object} enhancedInvoice The updated invoice object.
 *
 * @returns {object}
 */
const editInvoiceSuccess = ({ enhancedInvoice }) => ({
	type: ActionTypes.EDIT_INVOICE_SUCCESS,
	payload: { enhancedInvoice },
});

/**
 * @function
 * @name editInvoiceFailure
 * @description Action triggered as a result to a failed invoice edition API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const editInvoiceFailure = (error) => ({
	type: ActionTypes.EDIT_INVOICE_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Invoice exporting actions /////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name exportInvoiceRequest
 * @description Action triggered anytime a invoice exporting call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const exportInvoiceRequest = () => ({ type: ActionTypes.EXPORT_INVOICE_REQUEST });

/**
 * @function
 * @name exportInvoiceSuccess
 * @description Action triggered as a result to a successful invoice exporting API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const exportInvoiceSuccess = () => ({ type: ActionTypes.EXPORT_INVOICE_SUCCESS });

/**
 * @function
 * @name exportInvoiceFailure
 * @description Action triggered as a result to a failed invoice exporting API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const exportInvoiceFailure = (error) => ({
	type: ActionTypes.EXPORT_INVOICE_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// /////////////// Invoices stats actions /////////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchInvoicesStatsRequest
 * @description Action triggered anytime a invoices stats fetch call is made to the API.
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
const fetchInvoicesStatsRequest = () => ({ type: ActionTypes.FETCH_INVOICES_STATS_REQUEST });

/**
 * @function
 * @name fetchInvoicesStatsSuccess
 * @description Action triggered as a result to a successful invoices stats fetch API call.
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
const fetchInvoicesStatsSuccess = ({ invoicesStats }) => ({
	type: ActionTypes.FETCH_INVOICES_STATS_SUCCESS,
	payload: { invoicesStats },
});

/**
 * @function
 * @name fetchInvoicesStatsFailure
 * @description Action triggered as a result to a failed invoices stats fetch API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchInvoicesStatsFailure = (error) => ({
	type: ActionTypes.FETCH_INVOICES_STATS_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// /////////////// Invoices dashboard actions /////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchInvoicesDashboardRequest
 * @description Action triggered anytime a invoices dashboard fetch call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchInvoicesDashboardRequest = () => ({ type: ActionTypes.FETCH_INVOICES_DASHBOARD_REQUEST });

/**
 * @function
 * @name fetchInvoicesDashboardSuccess
 * @description Action triggered as a result to a successful invoices dashboard fetch API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchInvoicesDashboardSuccess = ({ dashboard }) => ({
	type: ActionTypes.FETCH_INVOICES_DASHBOARD_SUCCESS,
	payload: { dashboard },
});

/**
 * @function
 * @name fetchInvoicesDashboardFailure
 * @description Action triggered as a result to a failed invoices dashboard fetch API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchInvoicesDashboardFailure = (error) => ({
	type: ActionTypes.FETCH_INVOICES_DASHBOARD_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchInvoiceList
 * @description Method used to update the invoice list.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchInvoiceList = (params) => (dispatch) => {
	dispatch(fetchInvoiceListRequest());

	return InvoicesApi.fetchInvoices(params)
		.then(({ invoices, totalCount }) => dispatch(fetchInvoiceListSuccess({ invoices, totalCount })))
		.catch((error) => dispatch(fetchInvoiceListFailure(error)));
};

/**
 * @function
 * @name fetchAllForInvoice
 * @description Method used to load the invoice "all for" information.
 *
 * @author Romaric Barthe
 *
 * @param {string|null} id	The id of the invoice.
 * @param {string|null} qid	The id of the quotation.
 */
export const fetchAllForInvoice = (id, qid) => (dispatch) => {
	dispatch(fetchAllForInvoiceRequest());

	return InvoicesApi.fetchAllForInvoiceForm({ invoiceId: id, quotationId: qid })
		.then((allForForm) => dispatch(fetchAllForInvoiceSuccess({ allForForm })))
		.catch((error) => dispatch(fetchAllForInvoiceFailure(error)));
};

/**
 * @function
 * @name fetchInvoice
 * @description Method used to fetch the latest version of a specific invoice.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} invoiceId	The id of the invoice we want to retrieve.
 */
export const fetchInvoice = (invoiceId) => (dispatch) => {
	dispatch(fetchInvoiceRequest());

	return InvoicesApi.fetchInvoiceById(invoiceId)
		.then(({ invoice }) => dispatch(fetchInvoiceSuccess({ invoice })))
		.catch((error) => dispatch(fetchInvoiceFailure(error)));
};

/**
 * @function
 * @name addInvoice
 * @description Method used to add a new invoice instance to the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} invoiceData		The data to create the new invoice from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addInvoice = (invoiceData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addInvoiceRequest());

	return InvoicesApi.createInvoice(invoiceData)
		.then(({ invoice }) => {
			toast.success(i18next.t('invoice.creation.toasts.success', { project: invoice.project.name }));
			dispatch(addInvoiceSuccess({ invoice }));
			redirectOnSuccess(onSuccessRoute);

			return invoice;
		})
		.catch((error) => {
			toast.error(i18next.t('invoice.creation.toasts.error'));
			dispatch(addInvoiceFailure(error));
		});
};

/**
 * @function
 * @name updateInvoice
 * @description Method used to update an existing invoice instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} invoiceData		The object to update the invoice with.
 * @param {string} invoiceId		The id of the invoice we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateInvoice = (invoiceData, invoiceId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateInvoiceRequest());

	return InvoicesApi.updateInvoice(invoiceData, invoiceId)
		.then(({ invoice }) => {
			dispatch(updateInvoiceSuccess({ invoice }));
			toast.success(i18next.t('invoice.update.toasts.success', { project: invoice.project.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch((error) => {
			toast.error(i18next.t('invoice.update.toasts.error'));
			dispatch(updateInvoiceFailure(error));
		});
};

/**
 * @function
 * @name removeInvoice
 * @description Method used to remove an existing invoice instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} invoice						The invoice we want to remove from the database.
 * @param {string} invoice.id					The id of the invoice we want to remove from the database.
 */
export const removeInvoice = ({ id }) => (dispatch) => {
	dispatch(removeInvoiceRequest());

	return InvoicesApi.deleteInvoice(id)
		.then(({ deletedInvoiceId }) => {
			dispatch(removeInvoiceSuccess({ deletedInvoiceId }));
			toast.success(i18next.t('invoice.deletion.toasts.success'));
		})
		.catch((error) => {
			dispatch(removeInvoiceFailure(error));
			toast.error(i18next.t('invoice.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name editInvoice
 * @description Method used to edit an invoice.
 *
 * @author Florian Fornazaric
 * @author Romaric Barthe
 *
 * @param {string} invoiceId		The id of the invoice we want to edit.
 * @param {string} editionDate		The edition date of the invoice.
 */
export const editInvoice = (invoiceId, editionDate) => (dispatch) => {
	dispatch(editInvoiceRequest());

	return InvoicesApi.editInvoice(invoiceId, editionDate)
		.then(({ invoice, number, date }) => {
			const enhancedInvoice = { ...invoice, editionNumber: number, generatedPdf: { pdfGenerationDate: date } };
			dispatch(editInvoiceSuccess({ enhancedInvoice }));
		})
		.catch((error) => {
			toast.error(i18next.t('invoice.edition.toasts.error'));
			dispatch(editInvoiceFailure(error));
		});
};

/**
 * @function
 * @name exportInvoices
 * @description Method used to export multiple invoice instances from the database.
 *
 * @author Romaric Barthe
 *
 * @param {Object}	data				The data to export the invoices.
 * @param {Array}	data.partnerIds		The ids of the invoices to export.
 * @param {string}	data.format			The file format.
 * @param {string}	data.restriction	The scope of the data to export (all, filtered or current selection).
 * @param {string}	data.separator		The separator used in the CSV file.
 *
 * @todo Adapt params when backend will change
 */
export const exportInvoices = (data) => (dispatch) => {
	dispatch(exportInvoiceRequest());

	return InvoicesApi.exportInvoices({
		ids: data.partnerIds ?? [],
		format: data.format ?? 'xlsx',
		restriction: data.restriction ?? 'all',
		separator: data.separator ?? 'comma',
		...data,
	})
		.then(({ blob, fileName, fileType }) => {
			dispatch(exportInvoiceSuccess());
			toast.success(i18next.t('template.export.toasts.success'));

			download(blob, fileName, fileType);
		})
		.catch((error) => {
			dispatch(exportInvoiceFailure(error));
			toast.error(i18next.t('template.export.toasts.error'));
		});
};

/**
 * @function
 * @name fetchInvoicesStats
 * @description Method used to fetch the latest version of stats of invoices.
 *
 * @author Florian Fornazaric
 *
 * @returns {Promise}
 */
export const fetchInvoicesStats = () => (dispatch) => {
	dispatch(fetchInvoicesStatsRequest());

	return InvoicesApi.fetchInvoicesStats()
		.then(({ invoicesStats }) => dispatch(fetchInvoicesStatsSuccess({ invoicesStats })))
		.catch((error) => dispatch(fetchInvoicesStatsFailure(error)));
};

/**
 * @function
 * @name fetchInvoicesDashboard
 * @description Method used to retrieve the invoices dashboard from the API.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters
 *
 * @returns {object}
 */
export const fetchInvoicesDashboard = (params) => (dispatch) => {
	dispatch(fetchInvoicesDashboardRequest());

	return InvoicesApi.fetchInvoicesDashboard(params)
		.then((dashboard) => dispatch(fetchInvoicesDashboardSuccess({ dashboard })))
		.catch((error) => dispatch(fetchInvoicesDashboardFailure(error)));
};
