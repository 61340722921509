import _ from 'lodash';

import { ActionTypes } from '../actions/userPreferences';

const initialState = {
	isLoading: false,
	hasBeenFetched: false,
	items: {},
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_USER_PREFERENCES_REQUEST:
		case ActionTypes.UPDATE_USER_PREFERENCE_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_USER_PREFERENCES_FAILURE:
		case ActionTypes.UPDATE_USER_PREFERENCE_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_USER_PREFERENCES_SUCCESS:
			return {
				...state,
				isLoading: false,
				hasBeenFetched: true,
				items: _.fromPairs(_.map(payload.userPreferences, ({ listName, listColumns }) => [listName, listColumns])),
			};
		case ActionTypes.UPDATE_USER_PREFERENCE_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: {
					...state.items,
					[payload.userPreference.listName]: payload.userPreference.listColumns,
				},
			};
		default:
			return state;
	}
};
