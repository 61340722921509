/* eslint-disable import/prefer-default-export */
import i18next from 'i18next';

/**
 * @function
 * @name formatPaymentDelayName
 * @description Concatenate a payment delay's number, scale and term.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {number} number	The payment delay's number.
 * @param {string} scale	The payment delay's scale.
 * @param {string} term		The payment delay's term.
 *
 * @returns {string}
 */
export const formatPaymentDelayName = ({ number, scale, term }) => {
	if (!number) {
		return null;
	}

	return `${number} ${i18next.t(`payment_delay.scale.${scale}`)} ${i18next.t(`payment_delay.term.${term}`)}`;
};
