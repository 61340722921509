import * as PartnersApi from 'api/partnersApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Partners redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of partners
	FETCH_PARTNER_SELECT_LIST_REQUEST: '@PARTNERS_SELECT/FETCH_LIST_REQUEST',
	FETCH_PARTNER_SELECT_LIST_SUCCESS: '@PARTNERS_SELECT/FETCH_LIST_SUCCESS',
	FETCH_PARTNER_SELECT_LIST_FAILURE: '@PARTNERS_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Partner list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnersSelectListRequest
 * @description Action triggered anytime a partnersSelect list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchPartnersSelectListRequest = () => ({ type: ActionTypes.FETCH_PARTNER_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchPartnersSelectListSuccess
 * @description Action triggered as a result to a successful partnersSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	partnersSelect	The list of retrieved partners.
 * @param {number}	totalCount		The total amount of partners available in the database to the current user.
 *
 * @returns {object}
 */
const fetchPartnersSelectListSuccess = ({ partnersSelect, totalCount }) => ({
	type: ActionTypes.FETCH_PARTNER_SELECT_LIST_SUCCESS,
	payload: { partnersSelect, totalCount },
});

/**
 * @function
 * @name fetchPartnersSelectListFailure
 * @description Action triggered as a result to a failed partnersSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchPartnersSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_PARTNER_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnersSelectList
 * @description Method used to update the partnersSelect list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchPartnersSelectList = (filters) => (dispatch) => {
	dispatch(fetchPartnersSelectListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forSelect',
	};

	return PartnersApi.fetchPartners(newFilters)
		.then(({ partnersSelect, totalCount }) => dispatch(fetchPartnersSelectListSuccess({ partnersSelect, totalCount })))
		.catch((error) => dispatch(fetchPartnersSelectListFailure(error)));
};
