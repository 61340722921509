import * as InvoicesApi from 'api/invoicesApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Invoices redux state
 * @type {object}
 *
 * @author Florian Fornazaric
 */
export const ActionTypes = {
	// Fetch a list of invoices
	FETCH_INVOICE_FOR_LIST_REQUEST: '@INVOICES_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_INVOICE_FOR_LIST_SUCCESS: '@INVOICES_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_INVOICE_FOR_LIST_FAILURE: '@INVOICES_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Invoice list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchInvoicesForListRequest
 * @description Action triggered anytime a invoicesList list fetching call is made to the API.
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
const fetchInvoicesForListRequest = () => ({ type: ActionTypes.FETCH_INVOICE_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchInvoicesForListSuccess
 * @description Action triggered as a result to a successful invoicesList list fetching API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object}	invoicesList	The list of retrieved invoices.
 * @param {number}	totalCount		The total amount of invoices available in the database to the current user.
 *
 * @returns {object}
 */
const fetchInvoicesForListSuccess = ({ invoicesList, totalCount }) => ({
	type: ActionTypes.FETCH_INVOICE_FOR_LIST_SUCCESS,
	payload: { invoicesList, totalCount },
});

/**
 * @function
 * @name fetchInvoicesForListFailure
 * @description Action triggered as a result to a failed invoicesList list fetching API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchInvoicesForListFailure = (error) => ({
	type: ActionTypes.FETCH_INVOICE_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchInvoicesForList
 * @description Method used to update the invoicesList list.
 *
 * @author Florian Fornazaric
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchInvoicesForList = (filters) => (dispatch) => {
	dispatch(fetchInvoicesForListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forList',
	};

	return InvoicesApi.fetchInvoices(newFilters)
		.then(({ invoicesList, totalCount }) => dispatch(fetchInvoicesForListSuccess({ invoicesList, totalCount })))
		.catch((error) => dispatch(fetchInvoicesForListFailure(error)));
};
