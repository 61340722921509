import update from 'immutability-helper';

import { createElement, createRow, getElementType } from './internals';

/**
 * @function
 * @name insertElement
 * @description Inserts a new element at the designated location
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {object} 	state					The current state
 * @param {number} 	payload.element			The element type to insert
 * @param {number} 	payload.rowIndex		The index of the row we want to insert the element into
 * @param {number} 	[payload.index]			The index where we want to insert the element at
 * @param {bool} 	[payload.isInHeader]	Whether or not we want to insert the element into the header
 * @param {bool}  	[payload.fromFooter]	Whether or not we want to insert the element into the footer
 *
 * @returns {object} The updated state value
 */
const insertElement = (state, { element, rowIndex, index = 0, isInHeader = false, fromFooter = true }) => {
	const { elements } = state;

	let newElements;

	// When the element cannot be inlined, we insert a row with the element in it
	if (!element.meta.inline || elements[rowIndex] === undefined || elements[rowIndex].children.some((e) => !getElementType(e).meta.inline) || isInHeader || !fromFooter) {
		newElements = update(elements, {
			$splice: [
				[rowIndex, 0, createRow(createElement(element))],
			],
		});
	} else {
		// We add the element at the destination index and destination row
		newElements = update(elements, {
			[rowIndex]: {
				children: {
					$splice: [
						[index, 0, createElement(element)],
					],
				},
			},
		});
	}

	const result = {
		...state,
		elements: newElements,
		isDirty: true,
	};

	return result;
};

export default insertElement;
