import update from 'immutability-helper';

/**
 * @function
 * @name updatePage
 * @description Updates a page identified by the provided index
 *
 * @author Florian Fornazaric
 *
 * @param {object} state					The current state
 * @param {string} payload.index			The index of the page to update
 * @param {object} payload.content			The new content to store inside the page
 *
 * @returns {object} The updated state value
 */
const updatePage = (state, { index, content }) => {
	const { pages } = state;

	const source = pages[index];
	const newPages = update(pages, {
		[index]: {
			$set: {
				...source,
				...content,
				id: source.id,
			},
		},
	});

	return {
		...state,
		pages: newPages,
		isDirty: true,
	};
};

export default updatePage;
