import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
	border,
	color,
	layout,
	position,
	shadow,
	space,
	typography,
} from 'styled-system';

const StyledTooltip = styled('span')(
	{
		visibility: (({ visibility }) => (visibility || 'initial')),
		zIndex: (({ zIndex }) => (zIndex || 'initial')),
		textDecoration: (({ textDecoration }) => textDecoration || 'initial'),
		textTransform: (({ textTransform }) => textTransform || 'initial'),
		opacity: '0.9',
		backdropFilter: 'blur(5px)',

		'&::after': {
			content: "' '",
			position: 'absolute',
			top: '50%',
			right: '100%',
			marginTop: '-5px',
			borderWidth: '5px',
			borderStyle: 'solid',
			borderColor: (({ bg }) => `transparent ${bg} transparent transparent`),
			opacity: '0.9',
			backdropFilter: 'blur(15px)',
		},
	},
	border,
	color,
	layout,
	position,
	shadow,
	space,
	typography,
);

/**
 * @name Tooltip
 * @description A tooltip element to be used inside a relative-positionned parent.
 * Note : You must set the "visibility: 'visible'" rule to this tooltip inside the parent's css rules.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*}		children 	 The element to display inside the tooltip.
 */
const Tooltip = ({ children, ...otherProps }) => (
	<StyledTooltip className="tooltip" {...otherProps}>{children}</StyledTooltip>
);

Tooltip.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.node,
		PropTypes.element,
		PropTypes.elementType,
	]).isRequired,
};

export default Tooltip;
