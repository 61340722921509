import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import useDynamicFormInput from '../useDynamicFormInput';

/**
 * @name HiddenInput
 * @description A hidden input component to be used inside a dynamic form.
 *
 * @author Yann Hodiesne
 *
 * @param {string}	name	The input's name.
 * @param {*}		value	The input's value.
 */
const HiddenInput = ({ name, value, ...props }) => {
	const currentValue = useRef(value);
	currentValue.current = value;

	const getValue = useCallback(() => currentValue.current, []);

	useDynamicFormInput(name, getValue, {}, props);

	return null;
};

HiddenInput.propTypes = {
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array]),
};

HiddenInput.defaultProps = {
	value: undefined,
};

export default HiddenInput;
