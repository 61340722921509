import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import DefaultSizes from '../../constants/DefaultSizes';
import EditorContext from '../../EditorContext';
import { updateElement } from '../../reducer/actions';

const css = {
	padding: '0px',
	minWidth: '2em',
	minHeight: '1.5em',
};

/**
 * @name Title
 * @description A title PDF element
 *
 * @author Matthieu Schaerlinger
 *
 * @param {number}	[aspectRatio = 1]	The aspect ratio of the PDF editor
 * @param {object}	style				The style of the component
 * @param {object}	element				The element to edit
 * @param {boolean}	editing				True if the element is being edited
 * @param {boolean}	resizable			True if the element is being resized
 */
const Title = (({ aspectRatio, style, element, editing, resizable, ...otherProps }) => {
	const { dispatch, isExporting, titleNumbers } = useContext(EditorContext);

	const number = titleNumbers?.[element.id];

	const handleChange = useCallback((e) => {
		dispatch(updateElement(element.id, {
			content: element.showTitleNumber
				? /^(\d+\.)+ (?<content>.*)$/.exec(e.target.value).groups.content
				: e.target.value,
		}));
	}, [dispatch, element.id, element.showTitleNumber]);

	const fontSize = useMemo(() => (
		`${aspectRatio * 0.85 * (element.format?.fontSize ?? DefaultSizes.HEADERS_FONT_SIZE[element.titleLevel - 1])}px`
	), [aspectRatio, element.format?.fontSize, element.titleLevel]);

	const titleLevelRef = useRef(element.titleLevel);

	// This allows us to initialise the fontSize of the element because it differs from the default behavior
	useEffect(() => {
		if (element.titleLevel !== titleLevelRef.current) {
			titleLevelRef.current = element.titleLevel;
			dispatch(updateElement(element.id, {
				format: { ...element.format, fontSize: DefaultSizes.HEADERS_FONT_SIZE[element.titleLevel - 1] },
			}));
			titleLevelRef.current = element.titleLevel;
		}
	}, [dispatch, element.format, element.id, element.titleLevel]);

	return (
		<textarea
			{...otherProps}
			className={`seamless${editing || resizable ? ' editing' : ''}`}
			style={{ ...style, fontSize }}
			onChange={handleChange}
			readOnly={isExporting || !editing || element.linkable ? true : undefined}
			value={element.showTitleNumber ? `${number}. ${element.content}` : element.content}
		/>
	);
});

Title.propTypes = {
	aspectRatio: PropTypes.number,
	style: PropTypes.object,
	element: PropTypes.object,
	editing: PropTypes.bool,
	resizable: PropTypes.bool,
};

Title.defaultProps = {
	aspectRatio: 1,
	style: undefined,
	element: undefined,
	editing: false,
	resizable: false,
};

const styles = StyleSheet.create(css);

Title.PdfElement = ({ children: content, element, rendererGlobals, style, ...otherprops }) => (
	<Text {...otherprops} style={[styles, style]}>
		{element.showTitleNumber
			? `${rendererGlobals.titleNumbers[element.id]}. ${content}`
			: content}
	</Text>
);

Title.PdfElement.propTypes = {
	children: PropTypes.node.isRequired,
	element: PropTypes.object.isRequired,
	rendererGlobals: PropTypes.object.isRequired,
	style: PropTypes.object,
};

Title.PdfElement.defaultProps = {
	style: undefined,
};

Title.PdfElement.displayName = 'Title';

export default Title;
