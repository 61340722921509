import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTargeting, updateTargeting } from 'redux/actions/targetings/targetings';
import { useCurrentTargetingSelector } from 'redux/selectors/targetings/targetings';
import routes from 'routes';

import { TargetingEditionForm } from 'components/targetings';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name TargetingEditionPage
 * @description A page used to edit an existing text object.
 *
 * @author Romaric Barthe
 */
const TargetingEditionPage = () => {
	const { t } = useTranslation();
	const { id: targetingId } = useParams();
	const dispatch = useDispatch();

	const targeting = useCurrentTargetingSelector(targetingId);

	const canEditTargeting = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING) && !targeting?.archived;

	useEffect(() => {
		dispatch(fetchTargeting(targetingId));
	}, [dispatch, targetingId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditTargeting) {
			dispatch(updateTargeting(formData, targetingId, routes.operations.targetings.list));
		} else {
			redirectOnSuccess(routes.operations.targetings.list);
		}
	}, [canEditTargeting, dispatch, targetingId]);

	return (
		targeting
			? <TargetingEditionForm targeting={targeting} onSubmit={handleFormSubmit} />
			: <p>{t('targeting.edition.loading')}</p>
	);
};

export default TargetingEditionPage;
