import { InvoiceColumnHeader, InvoiceCreationTypes, InvoiceMethods } from 'constants/invoiceEnums';

/**
 * @function
 * @name getInvoiceCreationTypes
 * @description return the array of invoice creation type options.
 *
 * @author Romaric Barthe
 *
 * @returns array of options for invoice type.
 */
export const getInvoiceCreationTypes = () => {
	const creationTypes = [];
	Object.entries(InvoiceCreationTypes).forEach(([, value]) => {
		creationTypes.push({ id: value, label: `invoice.creation.creation_type.${value}` });
	});

	return creationTypes;
};

/**
 * @function
 * @name getInvoiceMethodsAsSelectOptions
 * @description Returns the array of invoice methods parsed into a format usable by a select component.
 *
 * @author Romaric Barthe
 *
 * @returns {array} Array of options for invoice methods.
 */
export const getInvoiceMethodsAsSelectOptions = () => Object.values(InvoiceMethods).map((method) => ({
	id: method,
	label: `invoice.creation.inputs.method.${method}`,
}));

/**
 * @function
 * @name invoiceColumnsHeaders
 * @description return the array of invoice headers for the grid, depending of invoiceType.
 *
 * @author Romaric Barthe
 *
 * @param {object}	selectedInvoiceType	The currently selected invoice type.
 *
 * @returns {array} array of headers for the grid.
 */
export const invoiceColumnsHeaders = (selectedInvoiceType) => {
	const columnsHeaders = [];
	if (!selectedInvoiceType) {
		return columnsHeaders;
	}

	Object.entries(InvoiceColumnHeader[selectedInvoiceType]).forEach(([, value]) => {
		columnsHeaders.push({ label: value, show: true });
	});

	return columnsHeaders;
};

/**
 * @function
 * @name getRowTotals
 * @description create subtotal for each rows in an invoice as an array.
 *
 * @author Romaric Barthe
 *
 * @param {object}	rows			the invoice rows.
 * @param {object}	vatRates		the available vatRates.
 * @param {number}	globalDiscount	the global invoice discount if set.
 *
 * @returns {array} transformed initial values.
 */
export const getRowTotals = (rows, vatRates, globalDiscount) => (
	rows
		? Object.entries(rows).map(([key, row]) => {
			const rowVatRateArray = row.vatRate && vatRates ? vatRates.filter((vat) => vat.id === row.vatRate) : [{ rate: 0 }];
			const rowVatRate = rowVatRateArray && rowVatRateArray.length > 0 ? rowVatRateArray[0].rate / 100 : 0;
			const rowGrossTotal = (row.quantity * row.price || row.baseAmount * (row.currentlyInvoiced / 100) || 0);
			const rowDiscountOnTotal = rowGrossTotal * (row.discount / 100);
			const rowDiscountOnTotalWithGlobalDiscount = (rowGrossTotal - rowDiscountOnTotal) * ((globalDiscount ?? 0) / 100) + (rowDiscountOnTotal ?? 0);
			const rowGrossTotalWithDiscountWithGlobalDiscount = rowGrossTotal - rowDiscountOnTotalWithGlobalDiscount;
			const rowVatTotalWithGlobalDiscount = rowGrossTotalWithDiscountWithGlobalDiscount * rowVatRate;
			const rowNetTotalWithGlobalDiscount = rowGrossTotalWithDiscountWithGlobalDiscount + rowVatTotalWithGlobalDiscount;

			return ({
				id: key,
				gt: rowGrossTotal ?? 0,
				dt: rowDiscountOnTotalWithGlobalDiscount ?? 0,
				gtd: rowGrossTotalWithDiscountWithGlobalDiscount ?? 0,
				vt: rowVatTotalWithGlobalDiscount ?? 0,
				nt: rowNetTotalWithGlobalDiscount ?? 0,
			});
		}) : []);
