import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentUserSelector
 * @description A selector to retrieve the current user from the user redux state
 *
 * @author Audrey Clerc
 *
 * @param {string} id The identifier of the current user to wait for
 *
 * @returns {object}
 */
const useCurrentUserSelector = (id) => {
	const user = useSelector((state) => (state.users?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (user?.id === id ? user : null), [id, user]);

	return result;
};

/**
 * @function
 * @name useUsersLoadingSelector
 * @description A selector to retrieve the loading state from the user redux state.
 *
 * @author Romaric Barthe
 * @author Roland Margelidon
 *
 * @returns {boolean}
 */
const useUsersLoadingSelector = () => {
	const loading = useSelector((state) => state.users?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useUserListSelector
 * @description A selector to retrieve the user list from the user redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useUserListSelector = () => {
	const users = useSelector((state) => (state.users?.items ?? []), shallowEqual);

	return users;
};

/**
 * @function
 * @name useUserTotalCountSelector
 * @description A selector to retrieve the totalCount value from the user redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useUserTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.users?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useCurrentConnectedUserSelector
 * @description A selector to retrieve the currentUser data from the user redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Object | undefined}
 */
const useCurrentConnectedUserSelector = () => {
	const currentUser = useSelector((state) => (state.users?.currentUser), shallowEqual);

	return currentUser;
};

/**
 * @function
 * @name useAllForUserSelector
 * @description A selector to retrieve the user information from the user redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAllForUserSelector = () => {
	const allForForm = useSelector((state) => (state.users?.allForForm ?? []), shallowEqual);

	return allForForm;
};

export {
	useAllForUserSelector,
	useCurrentConnectedUserSelector,
	useCurrentUserSelector,
	useUserListSelector,
	useUsersLoadingSelector,
	useUserTotalCountSelector,
};
