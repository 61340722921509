import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchInvoices
 * @description Retrieves a list of all invoices from the API, specifying filters if present.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchInvoices = (params) => post('/api/invoices', params);

/**
 * @function
 * @name fetchInvoiceById
 * @description Retrieves an existing invoice from the API, using the invoiceId in parameters.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} invoiceId	The id of the invoice we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchInvoiceById = (invoiceId) => get(`/api/invoice/${invoiceId}`);

/**
 * @function
 * @name createInvoice
 * @description Creates a new invoice into the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} invoiceData	The data to create the invoice from.
 *
 * @returns {Promise}
 */
export const createInvoice = (invoiceData) => post('/api/invoices/create', invoiceData);

/**
 * @function
 * @name updateInvoice
 * @description Updates an existing invoice from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} invoiceData	The data to update the invoice with.
 * @param {string} invoiceId	The id of the invoice we wish to update.
 *
 * @returns {Promise}
 */
export const updateInvoice = (invoiceData, invoiceId) => put(`/api/invoice/${invoiceId}`, invoiceData);

/**
 * @function
 * @name deleteInvoice
 * @description Removes an existing invoice from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} invoiceId	The id of the invoice we want to remove.
 *
 * @returns {Promise}
 */
export const deleteInvoice = (invoiceId) => del(`/api/invoice/${invoiceId}`);

/**
 * @function
 * @name exportInvoices
 * @description Export existing invoices from the database.
 *
 * @author Romaric Barthe
 *
 * @param {Object} data		The data to export the invoices.
 *
 * @returns {Promise}
 */
export const exportInvoices = (data) => post('/api/invoices/export', data, 'attachment');

/**
 * @function
 * @name editInvoice
 * @description Prepare edition of an existing invoice with the right edition number.
 *
 * @author Florian Fornazaric
 *
 * @param {string} invoiceId	The id of the invoice we wish to update.
 * @param {string} editionDate	The edition date of the invoice.
 *
 * @returns {Promise}
 */
export const editInvoice = (invoiceId, editionDate) => put(`/api/invoice/edit/${invoiceId}`, { editionDate });

/**
 * @function
 * @name fetchAllForInvoiceForm
 * @description Retrieves all information for invoice from the API.
 *
 * @author Romaric Barthe.
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchAllForInvoiceForm = (params) => post('/api/invoice/form', params);

/**
 * @function
 * @name fetchInvoicesStats
 * @description Fetch invoices stats from the database.
 *
 * @author Florian Fornazaric
 *
 * @returns {Promise}
 */
export const fetchInvoicesStats = () => get('/api/invoices/stats');

/**
 * @function
 * @name fetchInvoicesDashboard
 * @description Fetch invoices dashboard from the database.
 *
 * @author Romaric Barthe
 *
 * @returns {Promise}
 */
export const fetchInvoicesDashboard = () => get('/api/invoices/dashboard');
