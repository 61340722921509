import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatContactName } from 'lib/contacts/formatContactData';
import PropTypes from 'prop-types';

import { Select } from 'components/shared/forms/inputs';
import { Checkbox } from 'components/shared/inputs';

/**
 * @name ProjectLinkedEntityForm
 * @description A form used to update an existing project.
 *
 * @author Romaric Barthe
 *
 * @param {bool}		isLoading		Whether the information is still loading.
 * @param {bool}		canViewInvoice	Whether the user has rights to see an invoice.
 * @param {object}		contactProps	The modal to create a contact on the fly.
 * @param {array}		contacts		The list of all available contacts.
 * @param {object}		defaultValues	The default values of the project.
 * @param {object}		partnerProps	The modal to create a partner on the fly.
 * @param {array}		partners		The list of all available partners.
 * @param {function}	setTitles		The method to update the titles.
 */
const ProjectLinkedEntityForm = ({
	isLoading,
	canViewInvoice,
	contactProps,
	contacts,
	defaultValues,
	partnerProps,
	partners,
	setTitles,
}) => {
	const { t } = useTranslation();

	const contactSelectOptions = useMemo(
		() => contacts?.map(({ id, firstName, lastName }) => ({ label: formatContactName({ firstName, lastName }), value: id })) ?? [],
		[contacts]
	);

	const [linkedContact, setLinkedContact] = useState(!!defaultValues?.contact);
	const [linkedPartner, setLinkedPartner] = useState(!!defaultValues?.partner);

	const [isContactPartnerLinkedToInvoice, setIsContactPartnerLinkedToInvoice] = useState(() => {
		if (linkedContact) {
			return defaultValues?.invoicedContact === null;
		}

		if (linkedPartner) {
			return defaultValues?.invoicedPartner === null;
		}

		return true;
	});

	/**
	 * @function
	 * @name onLinkedContactChange
	 * @description A callback method used to switch between linked partner and contact.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {bool} checked 	The checkbox status.
	 */
	const onLinkedContactChange = useCallback(({ target: { checked } }) => {
		setLinkedContact(checked);

		if (linkedPartner) {
			setLinkedPartner(!checked);
		}
	}, [linkedPartner]);

	/**
	 * @function
	 * @name onLinkedPartnerChange
	 * @description A callback method used to switch between linked partner and contact.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {bool} checked 	The checkbox status.
	 */
	const onLinkedPartnerChange = useCallback(({ target: { checked } }) => {
		setLinkedPartner(checked);

		if (linkedContact) {
			setLinkedContact(!checked);
		}
	}, [linkedContact]);

	const onIsContactPartnerLinkedToInvoiceChange = useCallback(({ target: { checked } }) => {
		setIsContactPartnerLinkedToInvoice(checked);
	}, []);

	return (
		<>
			<div className="aside">
				<Checkbox
					label={t('projects.edition.inputs.link_contact.label')}
					labelFirst={false}
					onChange={onLinkedContactChange}
					checked={linkedContact}
				/>
				<Checkbox
					label={t('projects.edition.inputs.link_partner.label')}
					labelFirst={false}
					onChange={onLinkedPartnerChange}
					checked={linkedPartner}
				/>
			</div>
			{(linkedContact || linkedPartner) && (
				<div className="subgroup">
					{linkedContact && (
						<Select
							label={t('projects.edition.inputs.contact.label')}
							placeholder={t('projects.edition.inputs.contact.placeholder')}
							name="contactId"
							onChange={setTitles}
							options={contactSelectOptions}
							allowNull
							labelKey="label"
							valueKey="value"
							isLoading={isLoading}
							{...contactProps}
						/>
					)}
					{linkedPartner && (
						<Select
							label={t('projects.edition.inputs.partner.label')}
							placeholder={t('projects.edition.inputs.partner.placeholder')}
							name="partnerId"
							onChange={setTitles}
							options={partners}
							allowNull
							labelKey="name"
							valueKey="id"
							isLoading={isLoading}
							{...partnerProps}
						/>
					)}
					{canViewInvoice && (
						<Checkbox
							label={t('projects.edition.inputs.invoiced_information.label')}
							labelFirst={false}
							checked={isContactPartnerLinkedToInvoice}
							onChange={onIsContactPartnerLinkedToInvoiceChange}
						/>
					)}
					{canViewInvoice && linkedContact && !isContactPartnerLinkedToInvoice && (
						<Select
							allowNull
							isLoading={isLoading}
							label={t('projects.edition.inputs.invoiced_contact.label')}
							labelKey="label"
							name="invoicedContact"
							onChange={setTitles}
							options={contactSelectOptions}
							valueKey="value"
							{...contactProps}
						/>
					)}
					{canViewInvoice && linkedPartner && !isContactPartnerLinkedToInvoice && (
						<Select
							allowNull
							isLoading={isLoading}
							label={t('projects.edition.inputs.invoiced_partner.label')}
							labelKey="name"
							name="invoicedPartner"
							onChange={setTitles}
							options={partners}
							valueKey="id"
							{...partnerProps}
						/>
					)}
				</div>
			)}
		</>
	);
};

ProjectLinkedEntityForm.propTypes = {
	isLoading: PropTypes.bool,
	canViewInvoice: PropTypes.bool,
	contactProps: PropTypes.object,
	contacts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		})
	),
	defaultValues: PropTypes.object,
	partnerProps: PropTypes.object,
	partners: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string,
		})
	),
	setTitles: PropTypes.func,
};

ProjectLinkedEntityForm.defaultProps = {
	isLoading: true,
	canViewInvoice: false,
	contactProps: undefined,
	contacts: [],
	defaultValues: {},
	partnerProps: undefined,
	partners: [],
	setTitles: () => {},
};

export default ProjectLinkedEntityForm;
