import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { bodyCopy, minion } from 'theme/textStyles';

const customStyles = {
	container: (base) => ({
		...base,
		width: '15%',
	}),
	input: (base) => ({
		...base,
		...bodyCopy,
		fontSize: minion.fontSize,
	}),
	control: (base) => ({
		...base,
	}),
	// Fixes the overlapping problem of the component
	menu: (provided) => ({
		...provided,
		zIndex: 9999,
		...bodyCopy,
		fontSize: minion.fontSize,
	}),
	valueContainer: (base) => ({
		...base,
		...bodyCopy,
		fontSize: minion.fontSize,
	}),
};

/**
 * @name PaginationSelect
 * @description A select component used inside DynamicTable's pagination footer to chose the amount of rows to display.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string}  htmlId		The value to set as HTML id to the component.
 * @param {func}    onChange	The method to trigger when the user clicks an option.
 * @param {array}   options		An array of options to display when the user opens the component.
 * @param {number}	value		The current value to input to this component.
 */
const PaginationSelect = ({ value, htmlId, onChange, options, ...otherProps }) => {
	const { t } = useTranslation();

	return (
		<>
			<label htmlFor={htmlId}>{`${t('components.table.pagination.rowsPerPage')} :`}</label>
			<ReactSelect
				className="react-select"
				data-testid="table-pagination-rows-per-page"
				inputId={htmlId}
				value={value}
				options={options}
				onChange={onChange}
				styles={customStyles}
				{...otherProps}
			/>
		</>
	);
};

PaginationSelect.propTypes = {
	htmlId: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.shape({
		label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		value: PropTypes.number.isRequired,
	}).isRequired,
};

export default PaginationSelect;
