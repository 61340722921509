import { ActionTypes } from '../actions/templateExports';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_TEMPLATE_EXPORT_LIST_REQUEST:
		case ActionTypes.ADD_TEMPLATE_EXPORT_REQUEST:
		case ActionTypes.REMOVE_TEMPLATE_EXPORT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_TEMPLATE_EXPORT_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_TEMPLATE_EXPORT_LIST_FAILURE:
		case ActionTypes.FETCH_TEMPLATE_EXPORT_FAILURE:
		case ActionTypes.ADD_TEMPLATE_EXPORT_FAILURE:
		case ActionTypes.REMOVE_TEMPLATE_EXPORT_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.REMOVE_TEMPLATE_EXPORT_SUCCESS:
		case ActionTypes.ADD_TEMPLATE_EXPORT_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_TEMPLATE_EXPORT_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.templateExports,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_TEMPLATE_EXPORT_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.templateExport,
			};
		default:
			return state;
	}
};
