import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import templateEnums from 'constants/templateEnums';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { useSubmitButton } from 'components/shared/forms';
import DynamicForm from 'components/shared/forms/DynamicForm';
import { Checkbox, DateInput, Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import {
	exportDataTypes as getExportDataTypes,
	exportFormats as getExportFormats,
	exportSeparators as getExportSeparators,
} from './functions';

/**
 * @name ExportForm
 * @description A form used to export entities as a CSV or Excel file.
 *
 * @author Romaric Barthe
 * @author Matthieu Schaerlinger
 *
 * @param {function} onSubmit	The method to trigger upon form submission.
 */
const ExportForm = ({ onSubmit }) => {
	const { t } = useTranslation();

	const { formProps, buttonProps } = useSubmitButton();

	const dataTypes = useMemo(() => getExportDataTypes().map(({ id, label }) => ({ id, label: t(label) })), [t]);
	const formats = useMemo(() => getExportFormats().map(({ id, label }) => ({ id, label: t(label) })), [t]);
	const separators = useMemo(() => getExportSeparators().map(({ id, label }) => ({ id, label: t(label) })), [t]);

	const [dataType, setDataType] = useState(null);
	const [isCsvFormat, setIsCsvFormat] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = useCallback(async (...params) => {
		setIsLoading(true);
		await onSubmit(...params);
		setIsLoading(false);
	}, [onSubmit]);

	/**
	 * @function
	 * @name onFormatChange
	 * @description Function called when the file format select's value changes to make the separator select visible if needed.
	 *
	 * @author Matthieu Schaerlinger
	 */
	const onFormatChange = useCallback((value) => setIsCsvFormat(value === templateEnums.export.formats.CSV), []);

	const defaultValues = useMemo(() => ({
		separator: 'comma',
	}), []);

	return (
		<DynamicForm onSubmit={handleSubmit} defaultValues={defaultValues} {...formProps}>
			<Select
				name="dataType"
				label={t('template.export.inputs.data_type.label')}
				rules={{
					required: Validators.isRequired(t('template.export.inputs.data_type.validation_errors.required')),
				}}
				onChange={setDataType}
				options={dataTypes}
				labelKey="label"
				valueKey="id"
			/>
			{dataType !== null && (
				<>
					<Checkbox
						key="optionalFields"
						label={t('template.export.inputs.information')}
						name="optionalFields"
					/>
					<Checkbox
						key="linkedEntites"
						label={t('template.export.inputs.linked_information')}
						name="linkedEntites"
					/>
					<Checkbox
						key="masterData"
						label={t('template.export.inputs.master_data')}
						name="masterData"
					/>
					<DateInput
						allowNull
						label={t('template.export.inputs.updated_from')}
						name="updatedFrom"
						rules={{
							pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
						}}
					/>
					<DateInput
						allowNull
						label={t('template.export.inputs.updated_to')}
						name="updatedTo"
						rules={{
							pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
						}}
					/>
				</>
			)}

			<Select
				name="format"
				label={t('template.export.inputs.format.label')}
				rules={{
					required: Validators.isRequired(t('template.export.inputs.format.validation_errors.required')),
				}}
				options={formats}
				labelKey="label"
				valueKey="id"
				onChange={onFormatChange}
			/>
			{isCsvFormat && (
				<Select
					name="separator"
					label={t('template.export.inputs.separator.label')}
					rules={{
						required: Validators.isRequired(t('template.export.inputs.separator.validation_errors.required')),
					}}
					options={separators}
					labelKey="label"
					valueKey="id"
				/>
			)}

			<Button type="submit" isLoading={isLoading} {...buttonProps}>{t('template.export.action')}</Button>
		</DynamicForm>
	);
};

ExportForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default ExportForm;
