import PropTypes from 'prop-types';

/**
 * @name TabPanel
 * @description An enhanced div to be used as a tab panel, linked to a TabHeader button.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string}	id			The id of the current tab panel.
 * @param {string}	linkedTabId	The id of the linked TabHeader button.
 * @param {bool}	hidden		True if the current panel is not selected.
 * @param {node}	children	The content to display inside the tab panel.
 */
const TabPanel = ({ id, linkedTabId, hidden, children }) => (
	<div id={id} role="tabpanel" hidden={hidden} aria-labelledby={linkedTabId}>
		{children}
	</div>
);

TabPanel.propTypes = {
	id: PropTypes.string.isRequired,
	linkedTabId: PropTypes.string.isRequired,
	hidden: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired,
};

export default TabPanel;
