import { ActionTypes } from '../../actions/targets/targets';

const initialState = {
	currentItem: null,
	currentItemStats: null,
	isLoading: false,
	items: [],
	logFile: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_TARGETING_TARGET_LIST_REQUEST:
		case ActionTypes.ADD_TARGETING_TARGET_REQUEST:
		case ActionTypes.EXPORT_TARGET_REQUEST:
		case ActionTypes.UPDATE_TARGETING_TARGET_REQUEST:
		case ActionTypes.REMOVE_TARGETING_TARGET_REQUEST:
		case ActionTypes.IMPORT_TARGETING_TARGET_TARGETPARTNERS_REQUEST:
		case ActionTypes.FETCH_TARGET_STATS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_TARGETING_TARGET_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_TARGETING_TARGET_LIST_FAILURE:
		case ActionTypes.FETCH_TARGETING_TARGET_FAILURE:
		case ActionTypes.ADD_TARGETING_TARGET_FAILURE:
		case ActionTypes.EXPORT_TARGET_FAILURE:
		case ActionTypes.UPDATE_TARGETING_TARGET_FAILURE:
		case ActionTypes.REMOVE_TARGETING_TARGET_FAILURE:
		case ActionTypes.IMPORT_TARGETING_TARGET_TARGETPARTNERS_FAILURE:
		case ActionTypes.FETCH_TARGET_STATS_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.REMOVE_TARGETING_TARGET_SUCCESS:
		case ActionTypes.ADD_TARGETING_TARGET_SUCCESS:
		case ActionTypes.EXPORT_TARGET_PARTNER_SUCCESS:
		case ActionTypes.UPDATE_TARGETING_TARGET_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.IMPORT_TARGETING_TARGET_TARGETPARTNERS_SUCCESS:
			return {
				...state,
				logFile: payload.logFile,
				isLoading: false,
			};
		case ActionTypes.FETCH_TARGETING_TARGET_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.targets,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_TARGETING_TARGET_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.target,
			};
		case ActionTypes.FETCH_TARGET_STATS_SUCCESS:
			return {
				...state,
				currentItemStats: payload.targetStats,
				isLoading: false,
			};
		default:
			return state;
	}
};
