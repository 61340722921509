import { ActionTypes } from '../actions/numberFormats';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_NUMBER_FORMAT_LIST_REQUEST:
		case ActionTypes.ADD_NUMBER_FORMAT_REQUEST:
		case ActionTypes.UPDATE_NUMBER_FORMAT_REQUEST:
		case ActionTypes.REMOVE_NUMBER_FORMAT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_NUMBER_FORMAT_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_NUMBER_FORMAT_LIST_FAILURE:
		case ActionTypes.FETCH_NUMBER_FORMAT_FAILURE:
		case ActionTypes.ADD_NUMBER_FORMAT_FAILURE:
		case ActionTypes.UPDATE_NUMBER_FORMAT_FAILURE:
		case ActionTypes.REMOVE_NUMBER_FORMAT_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_NUMBER_FORMAT_SUCCESS:
		case ActionTypes.UPDATE_NUMBER_FORMAT_SUCCESS:
		case ActionTypes.REMOVE_NUMBER_FORMAT_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_NUMBER_FORMAT_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.numberFormats,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_NUMBER_FORMAT_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.numberFormat,
			};
		default:
			return state;
	}
};
