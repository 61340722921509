import { Fragment, useContext, useMemo } from 'react';
import { ArrowLeft, ArrowRight, PlusCircle, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { sumOneObjectPropertyInArray } from 'components/shared/utils/functions';

import DynamicFormContext from '../../shared/forms/internals/DynamicFormContext';

import QuotationGridArchiColumn from './QuotationGridArchiColumn';
import QuotationGridArchiRow from './QuotationGridArchiRow';

/**
 * @name QuotationGridArchi
 * @description The grid for archi quotations.
 *
 * @author Romaric Barthe
 *
 * @param {string}	currencyName							The ISO name of the currency used in the quotation.
 * @param {string}	currencySymbol							The symbol of the currency used in the quotation.
 * @param {object}	defaultValues							The default values of the quotation.
 * @param {bool}	isLoading								Whether some data is still loading.
 * @param {array}	offers									The list of offers in line with the selected currency.
 * @param {array}	partners								The list of partners available within the app.
 * @param {number}	projectAmount							The global amount of the project.
 * @param {bool}	readOnly								Whether the form should be open in readOnly.
 * @param {object}	rows									The rows of the quotation.
 * @param {array}	sectors									The list of sectors available in the app.
 * @param {object}	stakeholders							The stakeholders of the quotation.
 * @param {object}	stakeholdersTotals						The totals for each stakeHolders.
 * @param {func}	updateColumn							The method to add/ move/ delete/ duplicate a column.
 * @param {func}	updateRow								The method to add/ move/ delete/ duplicate a line.
 * @param {func}	setTotals								The method to set the total of the quotation.
 * @param {func}	duplicateRowId							If set, the id of the row that is duplicated.
 * @param {func}	originalRowData							If set, the data of the row that is duplicated.
 */
const QuotationGridArchi = ({
	currencyName,
	currencySymbol,
	defaultValues,
	isLoading,
	offers,
	partners,
	projectAmount,
	readOnly,
	rows,
	sectors,
	stakeholders,
	stakeholdersTotals,
	// functions
	updateColumn,
	updateRow,
	setTotals,
	// duplicate logic
	duplicateRowId,
	originalRowData,
}) => {
	const { t } = useTranslation();

	const columnItems = useMemo(() => Math.max(...stakeholders.map((c) => c?.column)), [stakeholders]);
	const rowItems = useMemo(() => Math.max(...rows.map((r) => r?.line)), [rows]);

	const { disabled: formDisabled } = useContext(DynamicFormContext);

	return (
		<table className="quotation-grid">
			<thead>
				<tr>
					<th width={`${50 / (stakeholders.length + 3)}%`}> </th>
					<th width={`${100 / (stakeholders.length + 3)}%`}> </th>
					<th width={`${100 / (stakeholders.length + 3)}%`}> </th>
					{stakeholders.map((stakeholder) => (
						<Fragment key={`columnTh.${stakeholder.id}`}>
							<th colSpan={2}>
								<div className="column-actions">
									<Button
										className="icon-only"
										onClick={() => updateColumn('move', stakeholder.id, stakeholder.column, -1)}
										disabled={stakeholder.column === 1 || formDisabled}
									>
										<ArrowLeft />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateColumn('move', stakeholder.id, stakeholder.column, 1)}
										disabled={stakeholder.column === columnItems || formDisabled}
									>
										<ArrowRight />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateColumn('delete', stakeholder.id)}
										disabled={formDisabled}
									>
										<XCircle />
									</Button>
								</div>
							</th>
						</Fragment>
					))}
					<th width={`${50 / (stakeholders.length + 3)}%`}>
						{!formDisabled && (
							<Button className="icon-only" onClick={() => updateColumn('add')}>
								<PlusCircle />
							</Button>
						)}
					</th>
				</tr>
				<tr>
					<th colSpan="3">
						<div className="label-right">{t('quotation.headers.sector')}</div>
						<div className="label-right">{t('quotation.headers.partner')}</div>
						<div className="label-right">{t('quotation.headers.partner_amount')}</div>
						<div className="label-right">{t('quotation.headers.partner_ratio')}</div>
						<div className="label-right">{t('quotation.headers.discount')}</div>
					</th>
					{stakeholders.map((stakeholder) => (
						<th colSpan="2" key={`stakeholder.${stakeholder.id}`} width={`${100 / (stakeholders.length + 3)}%`}>
							<QuotationGridArchiColumn
								currencyName={currencyName}
								defaultValues={defaultValues}
								isLoading={isLoading}
								partners={partners}
								projectAmount={projectAmount}
								readOnly={readOnly}
								sectors={sectors}
								stakeholder={stakeholder}
								setTotals={setTotals}
							/>
						</th>
					))}
					<th> </th>
				</tr>
				<tr>
					<th className="bottom-left"> </th>
					<th className="bottom-left">{t('quotation.headers.offer')}</th>
					<th className="bottom-left">{t('quotation.headers.description')}</th>
					{stakeholders.map((stakeholder) => {
						const stakeholderTotals = stakeholdersTotals.find((a) => a.id === stakeholder.id);
						const total = stakeholderTotals ? stakeholderTotals.partnerProjectAmount * stakeholderTotals.partnerRatio * (1 - stakeholderTotals.partnerDiscount) : 0;

						return (
							<th colSpan="2" key={`stakeholder.${stakeholder.id}`}>
								<div className="center">
									{formatNumber(total, { symbol: currencySymbol })}
								</div>
							</th>
						);
					})}
					<th className="bottom-left">{t('quotation.headers.extra')}</th>
				</tr>
			</thead>

			<tbody>
				{rows.map((row) => (
					<QuotationGridArchiRow
						currencySymbol={currencySymbol}
						defaultValues={defaultValues}
						formDisabled={formDisabled}
						isLoading={isLoading}
						key={`tr.${row.id}`}
						offers={offers}
						readOnly={readOnly}
						row={row}
						rowItems={rowItems}
						stakeholders={stakeholders}
						stakeholdersTotals={stakeholdersTotals}
						// functions
						updateRow={updateRow}
						setTotals={setTotals}
						// duplicate logic
						originalRowData={row.id === duplicateRowId ? originalRowData : undefined}
					/>
				))}
				<tr>
					<td colSpan="3" className="subtotal">{t('quotation.headers.total_allocated')}</td>
					{stakeholders.map((stakeholder) => {
						const currentStakeholderTotals = stakeholdersTotals.filter((f) => f.id === stakeholder.id);
						const stakeholderTotals = currentStakeholderTotals.length === 1 ? currentStakeholderTotals[0] : {};
						const subTotalNotExtraPercent = stakeholderTotals.details && stakeholderTotals.details.length >= 0
							? sumOneObjectPropertyInArray(stakeholderTotals.details.filter((a) => a.columnId === stakeholder.id && !a.extra), 'rate') : 0;
						const subTotalNotExtraAllocated = stakeholderTotals.details && stakeholderTotals.details.length >= 0
							? sumOneObjectPropertyInArray(stakeholdersTotals.filter((a) => a.id === stakeholder.id), 'partnerProjectAmount')
								* sumOneObjectPropertyInArray(stakeholdersTotals.filter((a) => a.id === stakeholder.id), 'partnerRatio')
								* (1 - sumOneObjectPropertyInArray(stakeholdersTotals.filter((a) => a.id === stakeholder.id), 'partnerDiscount'))
								* (subTotalNotExtraPercent / 100)
							: 0;

						return (
							<Fragment key={`fragtot.${stakeholder.id}`}>
								<td>
									<div className="column-total-right">
										{formatNumber(subTotalNotExtraPercent, { symbol: '%' })}
									</div>
								</td>
								<td>
									<div className="column-total-right">
										{formatNumber(subTotalNotExtraAllocated, { symbol: currencySymbol })}
									</div>
								</td>
							</Fragment>
						);
					})}
					<td> </td>
				</tr>
				<tr>
					<td colSpan="3"><div className="check-right">{t('quotation.headers.check')}</div></td>
					{stakeholders.map((stakeholder) => {
						const currentStakeholderTotals = stakeholdersTotals.filter((f) => f.id === stakeholder.id);
						const stakeholderTotals = currentStakeholderTotals.length === 1 ? currentStakeholderTotals[0] : {};
						const subTotalNotExtraPercent = stakeholderTotals.details && stakeholderTotals.details.length >= 0
							? sumOneObjectPropertyInArray(stakeholderTotals.details.filter((a) => a.columnId === stakeholder.id && !a.extra), 'rate') : 0;
						const subTotalNotExtraToBeAllocated = stakeholderTotals.details && stakeholderTotals.details.length >= 0
							? sumOneObjectPropertyInArray(stakeholdersTotals.filter((a) => a.id === stakeholder.id), 'partnerProjectAmount')
								* sumOneObjectPropertyInArray(stakeholdersTotals.filter((a) => a.id === stakeholder.id), 'partnerRatio')
								* (1 - sumOneObjectPropertyInArray(stakeholdersTotals.filter((a) => a.id === stakeholder.id), 'partnerDiscount') / 100)
							: 0;
						const subTotalNotExtraAllocated = stakeholderTotals.details && stakeholderTotals.details.length >= 0
							? subTotalNotExtraToBeAllocated * (subTotalNotExtraPercent / 100) : 0;

						return (
							<td colSpan="2" key={`tdtotcheck.${stakeholder.id}`}>
								<div className="check-right">
									{formatNumber((subTotalNotExtraToBeAllocated - subTotalNotExtraAllocated), { symbol: currencySymbol })}
								</div>
							</td>
						);
					})}
					<td> </td>
				</tr>
				<tr>
					<td colSpan="3" className="subtotal">{t('quotation.headers.total_extra')}</td>
					{stakeholders.map((stakeholder) => {
						const currentStakeholderTotals = stakeholdersTotals.filter((f) => f.id === stakeholder.id);
						const stakeholderTotals = currentStakeholderTotals.length === 1 ? currentStakeholderTotals[0] : {};
						const subTotalExtraPercent = stakeholderTotals.details && stakeholderTotals.details.length >= 0
							? sumOneObjectPropertyInArray(stakeholderTotals.details.filter((a) => a.columnId === stakeholder.id && a.extra), 'rate') : 0;
						const subTotalExtraAllocated = stakeholderTotals.details && stakeholderTotals.details.length >= 0
							? sumOneObjectPropertyInArray(stakeholdersTotals.filter((a) => a.id === stakeholder.id), 'partnerProjectAmount')
								* sumOneObjectPropertyInArray(stakeholdersTotals.filter((a) => a.id === stakeholder.id), 'partnerRatio')
								* (1 - sumOneObjectPropertyInArray(stakeholdersTotals.filter((a) => a.id === stakeholder.id), 'partnerDiscount'))
								* (subTotalExtraPercent / 100)
							: 0;

						return (
							<Fragment key={`fragtotextra.${stakeholder.id}`}>
								<td>
									<div className="column-total-right">
										{formatNumber(subTotalExtraPercent, { symbol: '%' }) /* Total extra allocated by partner */ }
									</div>
								</td>
								<td>
									<div className="column-total-right">
										{formatNumber(subTotalExtraAllocated, { symbol: currencySymbol }) /* Total extra allocated by partner */ }
									</div>
								</td>
							</Fragment>
						);
					})}
					<td> </td>
				</tr>
			</tbody>
		</table>
	);
};

QuotationGridArchi.propTypes = {
	currencyName: PropTypes.string,
	currencySymbol: PropTypes.string,
	defaultValues: PropTypes.object,
	isLoading: PropTypes.bool,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.bool,
		}),
	).isRequired,
	partners: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.bool,
		}),
	).isRequired,
	projectAmount: PropTypes.number,
	readOnly: PropTypes.bool,
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			stakeholder: PropTypes.string,
			value: PropTypes.string,
		}),
	).isRequired,
	sectors: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.bool,
		}),
	).isRequired,
	stakeholders: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	stakeholdersTotals: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			details: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
				}),
			),
		}),
	).isRequired,
	// Functions
	updateColumn: PropTypes.func.isRequired,
	updateRow: PropTypes.func.isRequired,
	setTotals: PropTypes.func.isRequired,
	// duplicate logic
	duplicateRowId: PropTypes.string,
	originalRowData: PropTypes.object,
};

QuotationGridArchi.defaultProps = {
	currencyName: '',
	currencySymbol: '',
	defaultValues: undefined,
	isLoading: true,
	projectAmount: null,
	readOnly: false,
	duplicateRowId: undefined,
	originalRowData: undefined,
};

export default QuotationGridArchi;
