import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addTemplate, fetchTemplate, updateTemplate } from 'redux/actions/templates';
import { useCurrentTemplateSelector } from 'redux/selectors/templates';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

import { Editor } from 'components/templates/pdf/editor';

/**
 * @name TemplatePdfEditionPage
 *
 * @author Romaric Barthe
 */
const TemplatePdfEditionPage = () => {
	const { t } = useTranslation();
	const { id: templateId } = useParams();
	const dispatch = useDispatch();

	const template = useCurrentTemplateSelector(templateId);

	const content = useMemo(() => (template?.content ? JSON.parse(template.content) : null), [template?.content]);

	useEffect(() => {
		dispatch(fetchTemplate(templateId));
	}, [dispatch, templateId]);

	const currentUser = useCurrentConnectedUserSelector();
	const isSuperadmin = useMemo(() => currentUser.superadmin, [currentUser.superadmin]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData, isSilent = false) => {
		const templateData = {
			...formData,
			content: JSON.stringify(formData.content),
		};

		return dispatch(updateTemplate(templateData, templateId, isSilent));
	}, [dispatch, templateId]);

	/**
	 * @function
	 * @name saveAsCallback
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const saveAsCallback = useCallback((formData) => {
		const templateData = {
			...formData,
			content: JSON.stringify(formData.content),
		};

		return dispatch(addTemplate(templateData, templateId));
	}, [dispatch, templateId]);

	return (
		template
			? (
				<Editor
					initialData={content}
					isSuperadmin={isSuperadmin}
					templateMetadata={{ description: template.description, name: template.name, type: template.type }}
					onSubmit={handleFormSubmit}
					saveAsCallback={saveAsCallback}
				/>
			)
			: <p>{t('template.edition.loading_template')}</p>
	);
};

export default TemplatePdfEditionPage;
