import { ActionTypes } from '../../actions/histories/histories';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_HISTORY_LIST_REQUEST:
		case ActionTypes.ADD_HISTORY_REQUEST:
		case ActionTypes.UPDATE_HISTORY_REQUEST:
		case ActionTypes.REMOVE_HISTORY_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_HISTORY_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_HISTORY_LIST_FAILURE:
		case ActionTypes.FETCH_HISTORY_FAILURE:
		case ActionTypes.ADD_HISTORY_FAILURE:
		case ActionTypes.UPDATE_HISTORY_FAILURE:
		case ActionTypes.REMOVE_HISTORY_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_HISTORY_SUCCESS:
		case ActionTypes.UPDATE_HISTORY_SUCCESS:
		case ActionTypes.REMOVE_HISTORY_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_HISTORY_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.histories],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_HISTORY_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.history,
			};
		default:
			return state;
	}
};
