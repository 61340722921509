import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addTextObject } from 'redux/actions/textObjects';
import routes from 'routes';

import { TextObjectCreationForm } from 'components/textObjects';

/**
 * @name TextObjectCreationPage
 * @description A page used to create a new text object.
 *
 * @author Romaric Barthe
 */
const TextObjectCreationPage = () => {
	const dispatch = useDispatch();

	const handleFormSubmit = useCallback((formData) => {
		dispatch(addTextObject(formData, routes.settings.textObjects.list));
	}, [dispatch]);

	return (
		<TextObjectCreationForm onSubmit={handleFormSubmit} />
	);
};

export default TextObjectCreationPage;
