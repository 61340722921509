import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { DateInput } from 'components/shared/forms/inputs';
import CustomValidators from 'components/shared/forms/validators';

/**
 * @name HistoryReminderForm
 * @description A form used to update an existing history.
 *
 * @author Audrey Clerc
 *
 * @param {object}	history	        The history object to edit information from.
 * @param {func}	onSubmit	    The method to trigger upon form submission.
 */
const HistoryReminderForm = ({ onSubmit, history }) => {
	const { t } = useTranslation();
	const dateToday = useMemo(() => new Date(), []);
	const { formProps, buttonProps } = useSubmitButton();

	const defaultValues = useMemo(() => ({
		...history,
		remindedDate: history.remindedDate ?? dateToday,
	}), [history, dateToday]);

	return (
		<DynamicForm
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			{...formProps}
		>
			<DateInput
				label={t('history.reminder.inputs.reminded_date.label')}
				name="remindedDate"
				rules={{
					pattern: CustomValidators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
					maxDate: CustomValidators.maxDate(dateToday, t('components.date_picker.format'), t('history.reminder.inputs.date.validation_errors.maxDate')),
					required: CustomValidators.isRequired(t('history.creation.inputs.date.validation_errors.required')),
				}}
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('history.reminder.action')}</Button>
		</DynamicForm>
	);
};

HistoryReminderForm.propTypes = {
	history: PropTypes.shape({
		channel: PropTypes.string.isRequired,
		date: PropTypes.string.isRequired,
		historyType: PropTypes.string.isRequired,
		note: PropTypes.string.isRequired,
		remindedDate: PropTypes.string,
		restriction: PropTypes.string.isRequired,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default HistoryReminderForm;
