import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchRegistrationList } from 'redux/actions/registrations';
import { useRegistrationListSelector, useRegistrationTotalCountSelector } from 'redux/selectors/registrations';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import ActionsCell from 'components/registrations/registrationList/ActionsCell';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name RegistrationListPage
 * @description A list of all registrations accessible from the Partners app in sub part registrations.
 *
 * From this page, the user is able to view, interact and search for registrations.
 *
 * @author Romaric Barthe
 */
const RegistrationListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const registrations = useRegistrationListSelector();
	const totalCount = useRegistrationTotalCountSelector();

	const canCreateRegistration = useAccessRight(AccessRights.commonModules.registrations.enhancedRights.CREATE_REGISTRATION);

	const headers = useMemo(() => [
		{ accessor: 'name', Header: t('registration.fields.name'), pinColumn: true, sorting: 'registration.name' },
		{ id: 'actions', Header: '', Cell: ActionsCell, name: t('registration.fields.actions'), pinColumn: true },
		{ id: 'type', accessor: (row) => row.type?.name, Header: t('registration.fields.type'), sorting: 'registration.type' },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('registration.fields.archived.yes') : t('registration.fields.archived.no')),
			Header: t('registration.fields.archived.header'),
			sorting: 'registration.archived',
		},
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('registration.fields.creation_date'), sorting: 'registration.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('registration.fields.update_date'), sorting: 'registration.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('registration.fields.creation_name'), sorting: 'registration.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('registration.fields.update_name'), sorting: 'registration.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchRegistrationList(params)));
	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('registration');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.commonModules.registrations.registrationCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateRegistration && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('registration.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={registrations}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="registration"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('registration.pages.list')} {...modalProps} />
		</div>
	);
};

export default RegistrationListPage;
