import * as SectorsApi from 'api/sectorsApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Sectors redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of sectors
	FETCH_SECTOR_FOR_LIST_REQUEST: '@SECTORS_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_SECTOR_FOR_LIST_SUCCESS: '@SECTORS_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_SECTOR_FOR_LIST_FAILURE: '@SECTORS_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Sector list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchSectorsForListRequest
 * @description Action triggered anytime a sectorsList list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchSectorsForListRequest = () => ({ type: ActionTypes.FETCH_SECTOR_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchSectorsForListSuccess
 * @description Action triggered as a result to a successful sectorsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	sectorsList	The list of retrieved sectors.
 * @param {number}	totalCount		The total amount of sectors available in the database to the current user.
 *
 * @returns {object}
 */
const fetchSectorsForListSuccess = ({ sectorsList, totalCount }) => ({
	type: ActionTypes.FETCH_SECTOR_FOR_LIST_SUCCESS,
	payload: { sectorsList, totalCount },
});

/**
 * @function
 * @name fetchSectorsForListFailure
 * @description Action triggered as a result to a failed sectorsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchSectorsForListFailure = (error) => ({
	type: ActionTypes.FETCH_SECTOR_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchSectorsForList
 * @description Method used to update the sectorsList list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchSectorsForList = (filters) => (dispatch) => {
	dispatch(fetchSectorsForListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forList',
	};

	return SectorsApi.fetchSectors(newFilters)
		.then(({ sectorsList, totalCount }) => dispatch(fetchSectorsForListSuccess({ sectorsList, totalCount })))
		.catch((error) => dispatch(fetchSectorsForListFailure(error)));
};
