import { Children, cloneElement, memo, useCallback, useEffect, useState } from 'react';
import { Anchor, Eye, EyeOff, Lock } from 'react-feather';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isDev } from 'lib/shared/environmentHelper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { primary } from 'theme/colors';
import { icon, legend, legendItem, rowIconWrapper } from 'theme/usePreferenceModal';

import { Button } from '../buttons';
import { DynamicList } from '../list';
import { StyledDiv } from '../styledElements';
import { BodyCopy, Brevier } from '../Typography';

import Modal from './Modal';
import ModalFooter from './ModalFooter';

/** *
 * @name StyledIcon
 * @description An icon styled for the user preference
 *
 * @author Audrey Clerc
 *
 * @param {node}	children		The content to place inside the icon.
 * @param {bool}	checked			Whether the content is checked.
 * @param {bool}	disabled		Whether the content is disabled.
 */
const StyledIcon = ({ children, checked, disabled, ...otherProps }) => (
	<div
		style={{
			...icon,
			color: (checked ? primary.default : '#a0a0a0'),
			cursor: (disabled ? 'inherit' : 'pointer'),
		}}
		{...otherProps}
	>
		{
			Children.map(children, (child) => cloneElement(child, { ...child.props }))
		}
	</div>
);

/**
 * @name TableUserPreferencesModal
 * @description Display the list of given headers and options to customize the associated table
 *
 * @author Yann Hodiesne
 *
 * @param {bool}	isShowing		Determines if the modal is currently being shown to the user
 * @param {array}	headers			The headers definition for the associated table
 * @param {string}	tableName		The name of the table associated to this prompt
 * @param {func}	confirm			The function to execute when the user clicks on confirm
 * @param {func}	cancel			The function to execute when the user clicks on cancel
 * @param {array}	headersOrder	An array with the current headers order stored in the user preferences
 * @param {array}	pinnedHeaders	An array with the current pinned headers stored in the user preferences
 * @param {array}	hiddenHeaders	An array with the current hidden headers stored in the user preferences
 * @param {func}	t				A translation method provided by the withTranslation HoC
 * @param {func}	columnsChanged	A function called when the internal state changed, for testing purposes
 */
const TableUserPreferencesModal = ({
	// User provided props
	headers,
	tableName,
	// useTableUserPreferences provided props
	isShowing,
	confirm,
	cancel,
	headersOrder,
	pinnedHeaders,
	hiddenHeaders,
	// HoC provided props
	t,
	// Testing callbacks
	columnsChanged,
}) => {
	const [columns, setColumnsState] = useState();

	const setColumns = useCallback((value) => {
		setColumnsState(value);

		if (columnsChanged) {
			columnsChanged(value);
		}
	}, [columnsChanged]);

	/**
	 * @name ensureColumnsOrder
	 * @description Ensures the provided columns matches DynamicTable's ordering constraints
	 *
	 * @author Yann Hodiesne
	 *
	 * @param {array}	newColumns				The new columns order to check
	 * @param {boolean}	displayToastOnChanges	Determines if a toast should be displayed when the provided order cannot be ised as-is
	 *
	 * @returns {array} A fixed columns order matching DynamicTable's constraints
	 */
	const ensureColumnsOrder = useCallback((newColumns, displayToastOnChanges = false) => {
		const order = newColumns.map((column) => column.id);
		const pinned = newColumns.filter((column) => column.pinned).map((column) => column.id);

		// We follow DynamicTable's auto-ordering to warn the user if something must be changed
		const finalOrder = order.filter((key) => pinned.includes(key)) // First, the sticky headers declared in order
			.concat(pinned.filter((key) => !order.includes(key))) // Second, the sticky headers not declared in headersOrder
			.concat(order.filter((key) => !pinned.includes(key))); // Third, the normal headers declared in headersOrder

		const result = _.sortBy(newColumns, (column) => _.indexOf(finalOrder, column.id || column.accessor));

		if (displayToastOnChanges && !_.isEqual(newColumns, result)) {
			toast.warn(t('components.table_user_preferences.toasts.errors.order'));
		}

		return result;
	}, [t]);

	useEffect(() => {
		const computedColumns = _.sortBy(headers, (header) => _.indexOf(headersOrder, header.id || header.accessor))
			.map((header) => {
				const id = header.id || header.accessor;

				return {
					id,
					name: _.isString(header.Header) && !_.isEmpty(header.Header)
						? header.Header
						: header.name,
					canEditPreferences: !header.pinColumn,
					pinned: header.pinColumn || _.includes(pinnedHeaders, id),
					hidden: _.includes(hiddenHeaders, id),
				};
			});

		if (isDev()) {
			const invalidHeaders = _.filter(computedColumns, (column) => _.isUndefined(column.name));

			if (invalidHeaders.length > 0) {
				throw new Error(
					`UserPreferences: these headers definition for table ${tableName} are missing a 'name' on a custom cell :\t
					${invalidHeaders.map((header) => header.id).join(', ')}`
				);
			}
		}

		setColumns(ensureColumnsOrder(computedColumns, false));
	}, [setColumns, ensureColumnsOrder, headers, headersOrder, pinnedHeaders, hiddenHeaders, tableName]);

	const keyResolver = useCallback((row) => row.id, []);

	const updateColumn = useCallback((id, key, value) => {
		setColumns(ensureColumnsOrder(columns.map((column) => (column.id === id ? { ...column, [key]: value } : column))));
	}, [columns, ensureColumnsOrder, setColumns]);

	/**
	 * @name listCell
	 * @description The cell component to render for each row
	 *
	 * @author Yann Hodiesne
	 *
	 * @param {object} row	The current row to display
	 */
	const listCell = useCallback(({ row }) => (
		<>
			<StyledDiv {...rowIconWrapper}>
				<StyledIcon checked={row.pinned} disabled={!row.canEditPreferences} onClick={() => row.canEditPreferences && updateColumn(row.id, 'pinned', !row.pinned)}>
					<Anchor />
				</StyledIcon>
				<StyledIcon checked={!row.hidden} disabled={!row.canEditPreferences} onClick={() => row.canEditPreferences && updateColumn(row.id, 'hidden', !row.hidden)}>
					{row.hidden ? <EyeOff /> : <Eye />}
				</StyledIcon>
				{!row.canEditPreferences && (
					<StyledIcon disabled>
						<Lock />
					</StyledIcon>
				)}
			</StyledDiv>
			<BodyCopy>{row.name}</BodyCopy>
		</>
	), [updateColumn]);

	const onRowsChanged = useCallback((rows) => {
		setColumns(ensureColumnsOrder(rows, true));
	}, [ensureColumnsOrder, setColumns]);

	const handleConfirm = useCallback(() => {
		const order = columns.map((column) => column.id);
		const pinned = columns.filter((column) => column.pinned).map((column) => column.id);
		const hidden = columns.filter((column) => column.hidden).map((column) => column.id);

		confirm(order, pinned, hidden);
	}, [columns, confirm]);

	return (
		<Modal isShowing={isShowing} title={tableName}>
			{columns && (
				<>
					<DynamicList
						rows={columns}
						keyResolver={keyResolver}
						CellComponent={listCell}
						rowsChanged={onRowsChanged}
					/>
					<StyledDiv {...legend}>
						<StyledDiv {...legendItem}>
							<StyledIcon><Lock /></StyledIcon>
							<Brevier>{t('components.table_user_preferences.labels.lock')}</Brevier>
						</StyledDiv>

						<StyledDiv {...legendItem}>
							<StyledIcon><Anchor /></StyledIcon>
							<Brevier>{t('components.table_user_preferences.labels.pin')}</Brevier>
						</StyledDiv>

						<StyledDiv {...legendItem}>
							<StyledIcon><Eye /></StyledIcon>
							<Brevier>{t('components.table_user_preferences.labels.hide')}</Brevier>
						</StyledDiv>
					</StyledDiv>
				</>
			)}
			<ModalFooter>
				<Button onClick={cancel}>
					{t('components.table_user_preferences.cancel')}
				</Button>
				{/* @TODO: Check if "button" is the right type here (submit might be more suited.) */}
				<Button className="primary" type="button" onClick={handleConfirm}>
					{t('components.table_user_preferences.confirm')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

TableUserPreferencesModal.propTypes = {
	isShowing: PropTypes.bool.isRequired,
	headers: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			accessor: PropTypes.func,
		}),
		PropTypes.shape({
			accessor: PropTypes.string.isRequired,
		}),
	])).isRequired,
	tableName: PropTypes.string.isRequired,
	confirm: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
	headersOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
	pinnedHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
	hiddenHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
	t: PropTypes.func.isRequired,
	columnsChanged: PropTypes.func,
};

TableUserPreferencesModal.defaultProps = {
	columnsChanged: undefined,
};

StyledIcon.propTypes = {
	children: PropTypes.node.isRequired,
	disabled: PropTypes.bool,
	checked: PropTypes.bool,
};
StyledIcon.defaultProps = {
	disabled: false,
	checked: false,
};

export default withTranslation()(memo(TableUserPreferencesModal));
