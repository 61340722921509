import * as TargetsApi from 'api/targetsApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Targets redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of targets
	FETCH_TARGET_SELECT_LIST_REQUEST: '@TARGETS_SELECT/FETCH_LIST_REQUEST',
	FETCH_TARGET_SELECT_LIST_SUCCESS: '@TARGETS_SELECT/FETCH_LIST_SUCCESS',
	FETCH_TARGET_SELECT_LIST_FAILURE: '@TARGETS_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Target list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetsSelectListRequest
 * @description Action triggered anytime a targetsSelect list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetsSelectListRequest = () => ({ type: ActionTypes.FETCH_TARGET_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchTargetsSelectListSuccess
 * @description Action triggered as a result to a successful targetsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	targetsSelect	The list of retrieved targets.
 * @param {number}	totalCount		The total amount of targets available in the database to the current user.
 *
 * @returns {object}
 */
const fetchTargetsSelectListSuccess = ({ targetsSelect, totalCount }) => ({
	type: ActionTypes.FETCH_TARGET_SELECT_LIST_SUCCESS,
	payload: { targetsSelect, totalCount },
});

/**
 * @function
 * @name fetchTargetsSelectListFailure
 * @description Action triggered as a result to a failed targetsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetsSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_TARGET_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetsSelectList
 * @description Method used to update the targetsSelect list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchTargetsSelectList = (filters) => (dispatch) => {
	dispatch(fetchTargetsSelectListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forSelect',
	};

	return TargetsApi.fetchTargets(newFilters)
		.then(({ targetsSelect, totalCount }) => dispatch(fetchTargetsSelectListSuccess({ targetsSelect, totalCount })))
		.catch((error) => dispatch(fetchTargetsSelectListFailure(error)));
};
