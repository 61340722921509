/**
 * @function
 * @name templateTypes
 * @description return the array of template type options.
 *
 * @author Romaric Barthe
 *
 * @param {function}	t	translator method.
 *
 * @returns array of options for template type.
 */
export const templateTypes = (t) => [
	{ id: 'csv', label: t('template.creation.inputs.template_type.csv') },
	{ id: 'email', label: t('template.creation.inputs.template_type.email') },
	{ id: 'excel', label: t('template.creation.inputs.template_type.excel') },
	{ id: 'pdf', label: t('template.creation.inputs.template_type.pdf') },
];

/**
 * @function
 * @name templateModes
 * @description return the array of template type options.
 *
 * @author Romaric Barthe
 *
 * @param {function}	t	translator method.
 *
 * @returns array of options for template mode.
 */
export const templateModes = (t) => [
	{ id: 'export', label: t('template.creation.inputs.template_mode.export') },
	{ id: 'import', label: t('template.creation.inputs.template_mode.import') },
];
