import { forwardRef, useEffect } from 'react';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

/**
 * @name IndeterminateCheckbox
 * @description A custom checkbox component with the `indeterminate` state, used for rows selection
 *
 * @author Yann Hodiesne
 * @author Timothée Simon-Franza
 *
 * @param {bool} disabled 		Whether the input is disabled.
 * @param {bool} indeterminate	Determines if the checkbox appears as indeterminate to the user
 */
const IndeterminateCheckbox = forwardRef(
	({ disabled, indeterminate, ...otherProps }, ref) => {
		const resolvedRef = useForwardedRef(ref);

		useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return <input type="checkbox" disabled={disabled} ref={resolvedRef} {...otherProps} />;
	}
);

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

IndeterminateCheckbox.propTypes = {
	disabled: PropTypes.bool,
	indeterminate: PropTypes.bool.isRequired,
};

IndeterminateCheckbox.defaultProps = {
	disabled: false,
};

export default IndeterminateCheckbox;
