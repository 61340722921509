import { useMemo } from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import RowRenderer from 'components/templates/pdf/rendering/RowRenderer';

/**
 * @name FooterRenderer
 * @description Renders the footer of the PDF document.
 *
 * @author Florian Fornazaric
 *
 * @param {array}	elements		All the elements of the template
 * @param {array}	footer			The footer and its contained elements
 * @param {object}	rendererGlobals	The global renderer variables
 * @param {object}	margins			The margins of the document
 * @param {number}	pageIndex		The index of the page
 */
const FooterRenderer = ({ elements, footer, margins, rendererGlobals, pageIndex }) => {
	const rows = useMemo(() => {
		const resultRows = [];
		for (let i = elements.length - footer.numberOfElements; i < elements.length; i++) {
			resultRows.push(elements[i]);
		}

		return resultRows;
	}, [elements, footer.numberOfElements]);

	const style = useMemo(() => ({
		height: footer.height - margins.bottom,
		marginLeft: margins.left,
		marginRight: margins.right,
		overflow: 'hidden',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		position: 'absolute',
		bottom: 0,
	}), [footer.height, margins.bottom, margins.left, margins.right]);

	return (
		<View style={style}>
			{rows.map((row) => (<RowRenderer key={row.id} rendererGlobals={rendererGlobals} pageIndex={pageIndex} {...row} />))}
		</View>
	);
};

FooterRenderer.propTypes = {
	elements: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
	})).isRequired,
	footer: PropTypes.shape({
		height: PropTypes.number.isRequired,
		numberOfElements: PropTypes.number.isRequired,
		excludeFromPages: PropTypes.arrayOf(PropTypes.string),
	}).isRequired,
	rendererGlobals: PropTypes.shape({
		titleNumbers: PropTypes.object,
	}).isRequired,
	margins: PropTypes.shape({
		top: PropTypes.number.isRequired,
		bottom: PropTypes.number.isRequired,
		left: PropTypes.number.isRequired,
		right: PropTypes.number.isRequired,
	}).isRequired,
	pageIndex: PropTypes.number.isRequired,
};

export default FooterRenderer;
