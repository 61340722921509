import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrenciesLoadingSelector
 * @description A selector to retrieve the loading state from the currency redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {boolean}
 */
const useCurrenciesLoadingSelector = () => {
	const loading = useSelector((state) => state.currencies?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useCurrencyListSelector
 * @description A selector to retrieve the currency list from the currency redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {Array}
 */
const useCurrencyListSelector = () => {
	const currencies = useSelector((state) => (state.currencies?.items ?? []), shallowEqual);

	return currencies;
};

/**
 * @function
 * @name useCurrencyTotalCountSelector
 * @description A selector to retrieve the totalCount value from the currency redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {number|undefined}
 */
const useCurrencyTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.currencies?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useCurrenciesLoadingSelector,
	useCurrencyListSelector,
	useCurrencyTotalCountSelector,
};
