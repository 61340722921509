import { useCallback, useEffect, useRef } from 'react';
import { ArrowDown, ArrowUp, Copy, XCircle } from 'react-feather';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { Checkbox, HiddenInput, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import QuotationGridArchiCell from './QuotationGridArchiCell';

/**
 * @name QuotationGridArchiRow
 * @description A form used to edit an existing quotation's information.
 *
 * @author Romaric Barthe
 *
 * @param {array}	columnsHeadersToDisplay	The array of the headers to be displayed.
 * @param {string}	currencySymbol			The symbol of the currency.
 * @param {object}	defaultValues			The default values of the quotation.
 * @param {bool}	formDisabled			Whether the form (actions) should be disable.
 * @param {bool}	isLoading				Whether the data are still loading.
 * @param {array}	offers					The list of offers.
 * @param {bool}	readOnly				Whether the quotation is read-only.
 * @param {object}	row						The rows of the quotation.
 * @param {number}	rowItems				The number of rows.
 * @param {array}	stakeholders			The list of combinations sector/ partner displayed in column.
 * @param {object}	stakeholdersTotals		The totals for each stakeHolders.
 * @param {array}	vatRates				The list of vat Rates.
 * @param {func}	updateRow				The method to add/ move/ delete/ duplicate a line.
 * @param {func}	setTotals				The method to set the total of the quotation.
 * @param {func}	originalRowData			If set, the data of the row that is duplicated.
 */
const QuotationGridArchiRow = ({
	currencySymbol,
	defaultValues,
	formDisabled,
	isLoading,
	offers,
	readOnly,
	row,
	rowItems,
	stakeholders,
	stakeholdersTotals,
	// functions
	updateRow,
	setTotals,
	// duplicate logic
	originalRowData,
}) => {
	const isInitialized = useRef(false);

	const designationRef = useRef();
	const offerRef = useRef();

	useEffect(() => {
		if (!isInitialized.current) {
			isInitialized.current = true;

			if (!originalRowData) {
				return;
			}

			if (originalRowData.offer && originalRowData.offer !== null) {
				offerRef.current?.setValue(originalRowData.offer);
			}
			designationRef.current?.setValue(originalRowData.designation);
		}
	}, [originalRowData]);

	const handleOfferChange = useCallback((selectedOffer) => {
		const offerDescription = offers.filter((o) => o.id === selectedOffer);
		if (offerDescription.length !== 1) {
			return;
		}
		designationRef.current.setValue(offerDescription[0].description);
	}, [offers]);

	return (
		<tr key={`tr.${row.id}`}>
			<td>
				<div className="row-actions">
					<Button
						className="icon-only"
						onClick={() => updateRow('move', row.id, row.line, 1)}
						disabled={row.line === rowItems || formDisabled}
					>
						<ArrowDown />
					</Button>
					<Button
						className="icon-only"
						onClick={() => updateRow('move', row.id, row.line, -1)}
						disabled={row.line === 1 || formDisabled}
					>
						<ArrowUp />
					</Button>
					<Button
						className="icon-only"
						onClick={() => updateRow('duplicate', row.id, row.line)}
						disabled={formDisabled}
					>
						<Copy />
					</Button>
					<Button
						className="icon-only"
						onClick={() => updateRow('delete', row.id)}
						disabled={formDisabled}
					>
						<XCircle />
					</Button>
					<HiddenInput
						name={`rows.${row.id}.line`}
						value={row.line}
					/>
				</div>
			</td>
			<td>
				<Select
					ref={offerRef}
					allowNull
					isLoading={isLoading}
					label=""
					labelKey="name"
					name={`rows.${row.id}.offer`}
					onChange={handleOfferChange}
					options={offers}
					readOnly={readOnly}
					valueKey="id"
				/>
			</td>
			<td>
				<TextInput
					ref={designationRef}
					label=""
					name={`rows.${row.id}.designation`}
					rules={{
						required: Validators.isRequired('trigger_red_borders'),
					}}
					readOnly={readOnly}
				/>
			</td>
			{stakeholders.map((stakeholder) => {
				const stakeholderTotals = stakeholdersTotals.find((s) => s.id === stakeholder.id) ?? {};

				return (
					<QuotationGridArchiCell
						currencySymbol={currencySymbol}
						defaultValues={defaultValues}
						key={`td.${row.id}.${stakeholder.id}`}
						readOnly={readOnly}
						row={row}
						stakeholder={stakeholder}
						stakeholderTotals={stakeholderTotals}
						// functions
						setTotals={setTotals}
						// duplicate logic
						originalRowData={originalRowData}
					/>
				);
			})}
			<td>
				<Checkbox
					label=""
					name={`rows.${row.id}.extra`}
					onChange={setTotals}
				/>
			</td>
		</tr>
	);
};

QuotationGridArchiRow.propTypes = {
	currencySymbol: PropTypes.string,
	defaultValues: PropTypes.shape({
		rows: PropTypes.shape({
			id: PropTypes.string,
			netCost: PropTypes.number,
			vatRate: PropTypes.object,
		}),
	}),
	formDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		}),
	).isRequired,
	readOnly: PropTypes.bool,
	row: PropTypes.shape({
		id: PropTypes.string,
		line: PropTypes.number,
	}).isRequired,
	rowItems: PropTypes.number.isRequired,
	stakeholders: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			rate: PropTypes.number,
		}),
	).isRequired,
	stakeholdersTotals: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	// functions
	updateRow: PropTypes.func.isRequired,
	setTotals: PropTypes.func.isRequired,
	// duplicate logic,
	originalRowData: PropTypes.object,
};

QuotationGridArchiRow.defaultProps = {
	currencySymbol: '',
	defaultValues: undefined,
	formDisabled: false,
	readOnly: false,
	isLoading: true,
	originalRowData: undefined,
};

export default QuotationGridArchiRow;
