import { floatPattern } from '../inputs/constants/validationPatterns';

/**
 * @function
 * @name minValue
 * @description A validation method used to check if the field value is greater than the minimum value.
 *
 * @author Yann Hodiesne
 *
 * @param {number}	minimum 	The minimal value for the input.
 * @param {string}	message		The message to return if the validation fails.
 * @param {number}	value		The input's current value.
 *
 * @returns {string}
 */
export const minValue = (minimum, message = 'minValue') => (value) => (
	(floatPattern.test(value) && value < minimum) ? message : ''
);

/**
 * @function
 * @name maxValue
 * @description A validation method used to check if the field value is smaller than the maximum value.
 *
 * @author Yann Hodiesne
 *
 * @param {number}	maximum 	The maximal value for the input.
 * @param {string}	message		The message to return if the validation fails.
 * @param {number}	value		The input's current value.
 *
 * @returns {string}
 */
export const maxValue = (maximum, message = 'maxValue') => (value) => (
	(floatPattern.test(value) && value > maximum) ? message : ''
);
