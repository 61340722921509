import PropTypes from 'prop-types';

/**
 * @name ResponsiblesCell
 * @description Custom cell for the projectListPage table displaying a project's responsible.
 *
 * @author Marin Catel-Guihomat
 */
const ResponsiblesCell = ({ row: { original: { responsibles } } }) => (
	<ul className="tag-list">
		{responsibles.map(({ username }) => (
			<li key={username}>
				<span className="tag">
					{username}
				</span>
			</li>
		))}
	</ul>
);

ResponsiblesCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			responsibles: PropTypes.arrayOf(PropTypes.shape({
				username: PropTypes.string.isRequired,
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default ResponsiblesCell;
