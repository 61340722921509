import { extractOptionsEnums } from 'constants/exportEnums';
import { airEntities, contactEntityFields, partnerEntityFields } from 'constants/importEnums';
import update from 'immutability-helper';
import { convertStringToFloat } from 'lib/shared/conversion';
import _ from 'lodash';

/**
 * @function
 * @name exportDataTypes
 * @description return the array of template data type options.
 *
 * @author Romaric Barthe
 *
 * @returns array of options for template data type.
 */
export const exportDataTypes = () => {
	const dataTypes = [];
	Object.entries(extractOptionsEnums.export.dataTypes).forEach(([, value]) => {
		dataTypes.push({ id: value, label: `template.export.inputs.data_type.values.${value}` });
	});

	return dataTypes;
};

/**
 * @function
 * @name exportFormats
 * @description return the array of template data format options.
 *
 * @author Romaric Barthe
 *
 * @returns array of options for template data format.
 */
export const exportFormats = () => {
	const formats = [];
	Object.entries(extractOptionsEnums.export.formats).forEach(([, value]) => {
		formats.push({ id: value, label: `template.export.inputs.format.values.${value}` });
	});

	return formats;
};

/**
 * @function
 * @name exportSeparators
 * @description return the array of template data separators' options.
 *
 * @author Romaric Barthe
 *
 * @returns array of options for template data separators.
 */
export const exportSeparators = () => {
	const separators = [];
	Object.entries(extractOptionsEnums.export.separators).forEach(([, value]) => {
		separators.push({ id: value, label: `template.export.inputs.separator.values.${value}` });
	});

	return separators;
};

/**
 * @function
 * @name entityFieldOptions
 * @description return the array of quotation creation type options.
 *
 * @author Romaric Barthe
 *
 * @param {string}		entity	name of the entity.
 * @param {function}	t		translator method.
 *
 * @returns array of fields for the entity name.
 */
export const entityFieldOptions = (entity, t) => {
	switch (entity) {
		case airEntities.ENTITY_CONTACT:
			return Object.values(contactEntityFields).map((field) => ({
				id: field,
				label: t(`contact.fields.${field}`),
			}));

		case airEntities.ENTITY_PARTNER:
			return Object.values(partnerEntityFields).map((field) => ({
				id: field,
				label: t(`partner.fields.${field}`),
			}));

		default:
			return [];
	}
};

/**
 * @function
 * @name defaultValues
 * @description Parses the backend-retrieved data into an object to be used by the form.
 *
 * @author Romaric Barthe
 *
 * @param {object}	rows			the rows defining the imported columns.
 *
 * @returns {object} transformed initial values.
 */
export const defaultValues = (rows, t) => {
	const rowInitialValues = {};

	// Initialisation from a file
	rows.forEach((row) => {
		// rowInitialValues[`number.${row.id}`] = row.offer ? row.offer.id : undefined;
		rowInitialValues[`row.import.${row.rowId}`] = row.import;
		rowInitialValues[`row.entity.${row.rowId}`] = row.entity?.id;
		rowInitialValues[`row.field.${row.rowId}`] = row.field?.id;
		rowInitialValues[`fields.${row.rowId}`] = row.entity ? entityFieldOptions(row.entity.id, t) : [];
		rowInitialValues[`row.rowNumber.${row.id}`] = row.rowNumber || '';
	});

	return rowInitialValues;
};

/**
 * @function
 * @name filterOutUnknownHeaders
 * @description filters out headers not present in knownHeadersId
 *
 * We found out sometimes the headers visible and the headers sent are not in line. This function ensure consistency.
 *
 * @author Romaric Barthe
 *
 * @param {array}	headers			The list of headers to filter.
 * @param {array}	knownHeaderIds	The id of the headers to keep.
 *
 * @returns {array} The sorted headers included in the knownHeaderIds.
 */
const filterOutUnknownHeaders = (headers, knownHeaderIds) => headers.filter((a) => (knownHeaderIds.indexOf(a.split('.')[2]) !== -1));

/**
 * @function
 * @name createFieldObject
 *
 * @author Romaric Barthe
 *
 * @param {object}	returnedObjectStructure	Format of data to be returned.
 * @param {object}	dataToBeRestructured	Flat data to be transformed and returned in the object.
 * @param {object}	headersToBeParsed		The list of headers as concatenate naming 'row.[header].[rowId]'.
 * @param {array}	stringFields			All fields to be handled as strings.
 *
 * @returns {object} The parsed fields to include in the object sent to the backend application.
 */
export const createFieldObject = (returnedObjectStructure, dataToBeRestructured, headersToBeParsed, stringFields) => {
	let fields = returnedObjectStructure;
	let indexField = -1;
	let temporaryRowId = '';

	const fieldParams = headersToBeParsed.sort((a, b) => (a.split('.')[2] < b.split('.')[2] ? -1 : 1));

	fieldParams.forEach((key) => {
		const [, valueKey, rowId] = key.split('.');
		if (!_.isEqual(rowId, temporaryRowId)) {
			fields = update(fields, {
				$push: [{ [valueKey]: (stringFields.indexOf(valueKey) === -1) ? convertStringToFloat(dataToBeRestructured[key]) : dataToBeRestructured[key] }],
			});
			indexField += 1;
			temporaryRowId = rowId;
		} else {
			fields = update(fields, {
				[indexField]: {
					[valueKey]: {
						$set: (stringFields.indexOf(valueKey) === -1) ? convertStringToFloat(dataToBeRestructured[key]) : dataToBeRestructured[key],
					},
				},
			});
		}
	});

	// Sort by lines
	fields.sort((a, b) => parseFloat(a.line) - parseFloat(b.line));

	return fields;
};

/**
 * @function
 * @name parseImportFieldsForBackend
 * @description Parses the form's importRows in a format understandable by the backend.
 *
 * @author Romaric Barthe
 *
 * @param {object}	params			The params we are submitting.
 * @param {array}	fieldsStates	The fields' current states.
 * @param {string}	linkedEntity	The entity.
 * @param {object}	linkedObject	The id of the object to link all the imported data with.
 *
 * @returns {object} The parsed object to send to the backend application.
 */
export const parseImportFieldsForBackend = (params, fieldsStates, linkedEntity, linkedObject) => {
	const newParams = _.cloneDeep(params);

	const unitaryFieldParams = Object.keys(newParams).filter((newParam) => (newParam.match(/^row\./)));

	// Store real ids from the state
	const stateFieldIds = fieldsStates.map((stateField) => stateField.rowId);
	const stringFields = ['import', 'entity', 'field'];
	let fields = [];

	const headers = filterOutUnknownHeaders(unitaryFieldParams, stateFieldIds);

	// Process all fields to be included in the object
	fields = createFieldObject(fields, newParams, headers, stringFields);

	// Return cleaned data
	Object.keys(newParams).forEach((key) => { if (key.match(/^row\./)) { delete newParams[key]; } });
	const importFormatData = {
		linkedEntity,
		linkedObjectId: linkedObject?.id,
		fields,
	};

	return importFormatData;
};
