import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { FilePlus, Grid, List, XSquare } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { formatContactName } from 'lib/contacts/formatContactData';
import { useUserPreference } from 'lib/hooks';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { formatDate } from 'lib/shared/format';
import PropTypes from 'prop-types';
import { fetchMedias, removeFile, updateFile, uploadFile } from 'redux/actions/mediaManager';
import { useMediasSelector } from 'redux/selectors/mediaManager';

import { Button } from 'components/shared/buttons';
import { Dropdown } from 'components/shared/dropdown';
import { SearchInput } from 'components/shared/inputs';
import { Modal, PromptModal, useModal } from 'components/shared/modal';
import TextInputModal from 'components/shared/modal/TextInputModal';

import { readFileToBase64 } from './utils';

const ViewStates = Object.freeze({
	GRID: 'GRID',
	LIST: 'LIST',
});

const UploadModes = Object.freeze({
	CREATE: 'CREATE',
	REPLACE: 'REPLACE',
});

/**
 * @name MediaManager
 * @description A media manager modal usable inside any page
 *
 * @author Yann Hodiesne
 *
 * @param {bool}		isShowing		Determines if the modal is currently being shown to the user
 * @param {function}	toggle			A callback used to let the user close the media manager
 * @param {function}	onImageSelected	Called when the user selected an image, with the image ID as the only parameter
 * @param {string}		selectedValue	The currently selected value to select on opening
 */
const MediaManager = ({ isShowing, toggle, onImageSelected, selectedValue }) => {
	// === SETUP ===
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const searchRef = useRef();
	const fileInputRef = useRef();

	const { isShowing: isPromptShowing, toggle: togglePrompt } = useModal();
	const { isShowing: isEditShowing, toggle: toggleEdit } = useModal();

	// === ACCESS RIGHTS ===
	const canCreate = useAccessRight(AccessRights.commonSettings.mediaManager.enhancedRights.CREATE_MEDIA_MANAGER);
	const canEdit = useAccessRight(AccessRights.commonSettings.mediaManager.enhancedRights.EDIT_MEDIA_MANAGER);
	const canDelete = useAccessRight(AccessRights.commonSettings.mediaManager.enhancedRights.DELETE_MEDIA_MANAGER);

	/**
	 * User preferences schema: [viewState]
	 */
	const [mediaManagerUserPreferences, setMediaManagerUserPreferences] = useUserPreference('media-manager', [ViewStates.GRID]);

	// === FETCHING ===
	// const { files, folders } = useMediasSelector();
	const { files } = useMediasSelector();

	const triggerFetch = useCallback(() => dispatch(fetchMedias({
		searchInput: searchRef.current.value.trim(),
	})), [dispatch]);

	// === IMAGE SELECTION ===
	const [selectedImage, setSelectedImage] = useState(selectedValue);
	const lastSelectedValue = useRef(selectedValue);

	useEffect(() => {
		if (lastSelectedValue.current !== selectedValue && selectedValue !== selectedImage) {
			lastSelectedValue.current = selectedValue;
			setSelectedImage(selectedValue);
		}
	}, [selectedImage, selectedValue]);

	const setSelectedImageOnKeyPress = useCallback((e, id) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			setSelectedImage(id);
		}
	}, []);

	const selectedFile = useMemo(() => files.find(({ id }) => id === selectedImage), [files, selectedImage]);

	// === VIEW STATE ===
	const [viewState, setViewState] = useState(mediaManagerUserPreferences[0]);

	// This useEffect will be triggered automatically whenever the userPreferences needs an update
	useEffect(() => {
		setMediaManagerUserPreferences([viewState]);
	}, [setMediaManagerUserPreferences, viewState]);

	const toggleViewState = useCallback(() => {
		if (viewState === ViewStates.GRID) {
			setViewState(ViewStates.LIST);
		} else {
			setViewState(ViewStates.GRID);
		}
	}, [viewState]);

	// === CONTEXT MENU ===
	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
	const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
	const [rightClickedImage, setRightClickedImage] = useState();

	const rightClickedFile = useMemo(() => files.find(({ id }) => id === rightClickedImage), [files, rightClickedImage]);

	const closeContextMenu = useCallback(() => {
		setRightClickedImage();
		setIsContextMenuOpen(false);
	}, []);

	const openContextMenu = useCallback((e, id) => {
		// We remove the ContextMenu behaviour
		e.preventDefault();
		e.stopPropagation();

		if (!canEdit && !canDelete) {
			return;
		}

		// Allows us to set the position of the context menu
		setAnchorPoint({ x: e.pageX, y: e.pageY });
		setRightClickedImage(id);
		setIsContextMenuOpen(true);
	}, [canDelete, canEdit]);

	// === FILE PICKER ===
	const uploadMode = useRef(UploadModes.CREATE);

	const openFilePicker = useCallback((mode) => {
		if (!canCreate && !canEdit) {
			return;
		}

		uploadMode.current = mode;
		fileInputRef.current.click();
	}, [canCreate, canEdit]);

	const openFilePickerOnClick = useCallback(() => {
		openFilePicker(UploadModes.CREATE);
	}, [openFilePicker]);

	const openFilePickerOnKeyPress = useCallback((e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			openFilePicker(UploadModes.CREATE);
		}
	}, [openFilePicker]);

	// === FILE REPLACING ===
	const fileToReplace = useRef();

	const replaceSelectedImage = useCallback(() => {
		fileToReplace.current = rightClickedFile ?? selectedFile;
		closeContextMenu();

		openFilePicker(UploadModes.REPLACE);
	}, [closeContextMenu, openFilePicker, rightClickedFile, selectedFile]);

	// === FILE UPLOAD ===
	// Upload a new file to the API
	const uploadNewFile = useCallback(async (fileContent, filename) => {
		await dispatch(uploadFile({
			name: filename,
			image: fileContent,
			filename,
			folder: null,
		}));

		triggerFetch();
	}, [dispatch, triggerFetch]);

	// Upload and replace an existing file inside the API
	const uploadReplaceFile = useCallback(async (fileContent, filename) => {
		await dispatch(updateFile({
			...fileToReplace.current,
			image: fileContent,
			filename,
		}, fileToReplace.current.id));

		triggerFetch();
	}, [dispatch, triggerFetch]);

	// Loads the selected file into memory and converts its data to base64
	const onFileSelected = useCallback(async (e) => {
		if (e.target.files.length === 0) {
			return;
		}

		const file = e.target.files[0];
		const fileContent = await readFileToBase64(file);

		switch (uploadMode.current) {
			case UploadModes.CREATE:
				uploadNewFile(fileContent, file.name);
				break;
			case UploadModes.REPLACE:
				uploadReplaceFile(fileContent, file.name);
				break;
			default:
				throw new Error(`Unknown upload mode specified: ${uploadMode}`);
		}
	}, [uploadNewFile, uploadReplaceFile]);

	// === FILE NAME EDITION ===
	const fileToRename = useRef();

	const renameSelectedImage = useCallback(() => {
		if (!canEdit) {
			return;
		}

		fileToRename.current = rightClickedFile ?? selectedFile;
		closeContextMenu();

		if (!fileToRename.current) {
			return;
		}

		toggleEdit();
	}, [canEdit, closeContextMenu, rightClickedFile, selectedFile, toggleEdit]);

	const openRenameOnKeyPress = useCallback((e) => {
		if (e.key === 'Enter' || e.keyCode === 13) {
			renameSelectedImage();
		}
	}, [renameSelectedImage]);

	const confirmEdit = useCallback(async (name) => {
		toggleEdit();
		await dispatch(updateFile({ ...fileToRename.current, name }, fileToRename.current.id));

		triggerFetch();
	}, [dispatch, toggleEdit, triggerFetch]);

	// === FILE DELETION ===
	const fileToDelete = useRef();

	const deleteSelectedImage = useCallback(async () => {
		fileToDelete.current = rightClickedFile ?? selectedFile;
		closeContextMenu();
		togglePrompt();
	}, [closeContextMenu, rightClickedFile, selectedFile, togglePrompt]);

	const confirmPrompt = useCallback(async () => {
		togglePrompt();
		await dispatch(removeFile(fileToDelete.current));

		await triggerFetch();

		if (fileToDelete.current.id === selectedImage) {
			setSelectedImage(undefined);
		}
	}, [dispatch, selectedImage, togglePrompt, triggerFetch]);

	useEffect(() => {
		if (isShowing) {
			triggerFetch();
		}
	}, [dispatch, isShowing, triggerFetch]);

	// === DRAG AND DROP ===
	const [{ canDropFile }, fileDrop] = useDrop(
		() => ({
			accept: [NativeTypes.FILE],
			canDrop: (item) => item.items.length !== 0 && item.items[0].type.match(/image\/jpeg|image\/png/),
			drop: (item) => {
				if (!canCreate) {
					return;
				}

				const file = item.files[0];

				readFileToBase64(file)
					.then((content) => uploadNewFile(content, file.name));
			},
			collect: (monitor) => ({
				canDropFile: monitor.canDrop() && monitor.isOver() && canCreate,
			}),
		}),
		[canCreate, uploadNewFile],
	);

	// === FILE SELECTION ===
	const confirmFileSelection = useCallback(() => {
		toggle();
		onImageSelected(selectedImage);
	}, [onImageSelected, selectedImage, toggle]);

	return (
		<Modal
			className="media-manager"
			isShowing={isShowing}
		>
			<div className="mm-content">
				<div className="mm-header">
					<div className="mm-path">
						<button
							type="button"
							aria-label="folder"
						>
							{t('media_manager.modal.title')}
						</button>
						{/*
						<ChevronRight aria-hidden="true" />
						<button
							type="button"
							aria-label="folder"
						>
							Communication
						</button>
						*/}
					</div>
					<div className="mm-options">
						{viewState === ViewStates.LIST && (
							<button type="button" aria-label="grid view" onClick={toggleViewState}>
								<Grid />
							</button>
						)}
						{viewState === ViewStates.GRID && (
							<button type="button" aria-label="list view" onClick={toggleViewState}>
								<List />
							</button>
						)}
					</div>
					<div className="mm-search">
						<SearchInput ref={searchRef} onSearch={triggerFetch} />
					</div>
				</div>
				{/* the last class will be either mm-list or mm-grid */}
				<div ref={fileDrop} className={`mm-body mm-${viewState.toLowerCase()}${canDropFile ? ' mm-file-drop' : ''}`}>
					<div className="mm-folders">
						{/*
						{folders.map(({ id, name, updatedAt, updatedBy }) => (
							<div key={id} className="mm-folder">
								<Folder />
								<div>{name}</div>
								{viewState === ViewStates.LIST && (
									<>
										<div className="mm-folder-author">
											{formatContactName(updatedBy.contact)}
										</div>
										<div className="mm-folder-date">
											{formatDate(updatedAt)}
										</div>
									</>
								)}
							</div>
						))}
						{canCreate && viewState === ViewStates.GRID && (
							<div className="mm-new-folder">
								<FolderPlus />
								<div>Add a folder</div>
							</div>
						)}
						*/}
					</div>
					<div className="mm-files">
						{files.map(({ id, name, createdAt, createdBy, image: { presignedUrl } }) => (
							<div
								key={id}
								role="button"
								tabIndex={0}
								className={`mm-file${selectedImage === id ? ' mm-file-active' : ''}`}
								onClick={() => setSelectedImage(id)}
								onKeyUp={(e) => setSelectedImageOnKeyPress(e, id)}
								onContextMenu={(e) => openContextMenu(e, id)}
							>
								<img src={presignedUrl} crossOrigin="anonymous" alt="" className="mm-file-preview" />
								<div className="mm-file-name">
									{name}
								</div>
								{viewState === ViewStates.LIST && (
									<>
										<div className="mm-file-author">
											{formatContactName(createdBy.contact)}
										</div>
										<div className="mm-file-date">
											{formatDate(createdAt)}
										</div>
									</>
								)}
							</div>
						))}
						{canCreate && (
							<div
								role="button"
								tabIndex={0}
								className={`mm-new-file${canDropFile ? ' mm-can-drop' : ''}`}
								onClick={openFilePickerOnClick}
								onKeyUp={openFilePickerOnKeyPress}
							>
								<FilePlus />
								<div>{t('media_manager.modal.actions.add_file')}</div>
							</div>
						)}
						{/*
						{viewState === ViewStates.LIST && (
							<div className="mm-new-folder">
								<FolderPlus />
								<div>Add a folder</div>
							</div>
						)}
						*/}
					</div>
				</div>
				<div className="mm-sidebar">
					<div className="mm-close">
						<button type="button" className="icon-only" aria-label="close" tabIndex={-1} onClick={toggle}>
							<XSquare />
						</button>
					</div>
					<img src={selectedFile?.image.presignedUrl} crossOrigin="anonymous" alt="" className="mm-preview" />
					<div className="mm-details">
						<div className="mm-details-title">
							{t('media_manager.modal.labels.name')}
						</div>
						<div role="button" tabIndex={0} className="mm-details-content mm-details-name" onClick={renameSelectedImage} onKeyUp={openRenameOnKeyPress}>
							{selectedFile?.name}
						</div>
						<div className="mm-details-title">
							{t('media_manager.modal.labels.uploaded_on')}
						</div>
						<div className="mm-details-content">
							{selectedFile && formatDate(selectedFile?.createdAt)}
						</div>
						<div className="mm-details-title">
							{t('media_manager.modal.labels.uploaded_by')}
						</div>
						<div className="mm-details-content">
							{selectedFile && (selectedFile?.createdBy.contact ? formatContactName(selectedFile?.createdBy.contact) : selectedFile.createdBy.username)}
						</div>
					</div>
					<div className="mm-actions">
						<button type="button" className="danger-outlined" aria-label="remove" onClick={deleteSelectedImage} disabled={!canDelete || selectedImage === undefined}>
							{t('media_manager.modal.actions.remove_file')}
						</button>
						<button type="button" className="primary" aria-label="confirm" onClick={confirmFileSelection} disabled={selectedImage === undefined}>
							{t('media_manager.modal.actions.use_file')}
						</button>
					</div>
				</div>
			</div>
			<input ref={fileInputRef} type="file" className="mm-file-input" accept=".png,.jpg,.jpeg,image/jpeg,image/png" onChange={onFileSelected} />
			<PromptModal isShowing={isPromptShowing} message={t('media_manager.modal.actions.confirm_remove')} confirm={confirmPrompt} cancel={togglePrompt} />
			<TextInputModal
				isShowing={isEditShowing}
				titleText={t('media_manager.modal.labels.edit_modal')}
				confirm={confirmEdit}
				cancel={toggleEdit}
				defaultValue={fileToRename.current?.name}
			/>
			<Dropdown anchorPoint={anchorPoint} isOpen={isContextMenuOpen} handleClose={closeContextMenu}>
				{canEdit && (
					<Button className="subtle" onClick={renameSelectedImage}>
						{t('media_manager.modal.actions.rename_file')}
					</Button>
				)}
				{canEdit && (
					<Button className="subtle" onClick={replaceSelectedImage}>
						{t('media_manager.modal.actions.replace_file')}
					</Button>
				)}
				{canDelete && (
					<Button className="subtle" onClick={deleteSelectedImage}>
						{t('media_manager.modal.actions.delete_file')}
					</Button>
				)}
			</Dropdown>
		</Modal>
	);
};

MediaManager.propTypes = {
	isShowing: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	onImageSelected: PropTypes.func.isRequired,
	selectedValue: PropTypes.string,
};

MediaManager.defaultProps = {
	selectedValue: undefined,
};

export default memo(MediaManager);
