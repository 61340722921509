import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatDate } from 'lib/shared/format';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DatePicker, Select } from 'components/shared/inputs';
import { Collapsible } from 'components/shared/layout';

/**
 * @name TemplateListSelect
 * @description A select used to select a template.
 *
 * @author Florian Fornazaric
 *
 * @param {func}	onChangeCallback	The method to trigger upon template selection.
 */
const TemplateListSelect = ({ onChangeCallback, lastEditionDate, templates }) => {
	const { t } = useTranslation();
	const [selectedTemplate, setSelectedTemplate] = useState(undefined);
	const [editionDate, setEditionDate] = useState({ date: new Date() });

	const onClick = useCallback(() => {
		if (!selectedTemplate) {
			toast.error(t('template.edition.toasts.template_selection'));
		} else if (editionDate?.date < lastEditionDate) {
			toast.error(t('template.edition.toasts.date_consistency', { lastEditionDate }));
		} else {
			onChangeCallback(selectedTemplate?.value, editionDate?.date);
		}
	}, [selectedTemplate, editionDate, lastEditionDate, t, onChangeCallback]);

	const options = useMemo(() => templates.map((template) => ({ label: template.name, value: template.id })), [templates]);

	// TODO: afficher manuellement un label sur ces inputs

	return (
		<Collapsible titleKey="template.edition.title" collapsed>
			<div>
				{`${t('template.edition.last_edition')} : `}
				{formatDate(lastEditionDate)}
			</div>
			<Select
				options={options}
				onChange={setSelectedTemplate}
			/>
			<DatePicker
				label={t('template.edition.date_selection')}
				name="edition"
				onChange={setEditionDate}
			/>
			<Button className="secondary" type="button" onClick={onClick}>
				{t('template.link.export_with_template')}
			</Button>
		</Collapsible>
	);
};

TemplateListSelect.propTypes = {
	onChangeCallback: PropTypes.func.isRequired,
	lastEditionDate: PropTypes.instanceOf(Date).isRequired,
	templates: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
};

export default TemplateListSelect;
