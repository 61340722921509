/**
 * @constant
 * @name emailPattern
 * @description An email field validation pattern.
 * @type {RegExp}
 */
const emailPattern = /^[a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&’*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

/**
 * @constant
 * @name citiPattern
 * @description A citi field validation pattern
 * @type {RegExp}
 */
const citiPattern = /^(\d){4}$/;

/**
 * @constant
 * @name nacePattern
 * @description A nace field validation pattern
 * @type {RegExp}
 */
const nacePattern = /^(\d){4}$/;

/**
 * @constant
 * @name nafPattern
 * @description A naf field validation pattern
 * @type {RegExp}
 */
const nafPattern = /^(\d){4}([A-Z]){1}$/;

/**
 * @constant
 * @name floatPattern
 * @description A floating point number validation pattern
 * @type {RegExp}
 */
const floatPattern = /^[+-]?(\d*[.])?\d+$/;

/**
 * @constant
 * @name numberPattern
 * @description A number validation pattern
 * @type {RegExp}
 */
const numberPattern = /^[+-]?\d+$/;

/**
 * @constant
 * @name rangePattern
 * @description A range validation pattern
 * @type {RegExp}
 */
const rangePattern = /^[+-]?\d+(( )?-( )?\d+)?$/;

export {
	citiPattern,
	emailPattern,
	floatPattern,
	nacePattern,
	nafPattern,
	numberPattern,
	rangePattern,
};
