import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import PropTypes from 'prop-types';
import { addStatus, fetchStatusList } from 'redux/actions/statuses';
import { useStatusActiveListSelector, useStatusLoadingSelector } from 'redux/selectors/statuses';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Checkbox, ColorPicker, NumberInput, Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';
import { TagColorPreviewComponent } from 'components/shared/previewComponents';
import { StatusCreationForm } from 'components/statuses';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name StatusObjectEditionForm
 * @description A form used to edit an existing status object's information.
 *
 * @author Romaric Barthe
 *
 * @param {object}		statusObject	The status object object to update information from.
 * @param {function}	onSubmit		The method to trigger upon form submission.
 */
const StatusObjectEditionForm = ({ statusObject, onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const statuses = useStatusActiveListSelector();
	const areStatusesLoading = useStatusLoadingSelector();

	const environments = useMemo(() => Object.values(Environments).map((value) => ({ id: value, label: t(`status_object.environment.${value}`) })), [t]);

	useEffect(() => {
		dispatch(fetchStatusList({ rowsPerPage: 0 }));
	}, [dispatch]);

	// Props to pass down to the select input so that it will display a status creation modal when clicking on the "+" icon.
	const statusCreationModal = useFormModal(StatusCreationForm, t('status_object.edition.inputs.status.creation'), fetchStatusList, addStatus);

	const defaultValues = useMemo(() => ({
		...statusObject,
		status: statusObject?.status?.id,
	}), [statusObject]);

	const { formProps, buttonProps } = useSubmitButton();

	const canEditStatusObject = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING);

	return (
		<DynamicForm onSubmit={onSubmit} defaultValues={defaultValues} disabled={!canEditStatusObject} {...formProps}>
			<Select
				label={t('status_object.edition.inputs.status.label')}
				labelKey="name"
				name="status"
				options={statuses}
				readOnly
				rules={{
					required: Validators.isRequired(t('status_object.edition.inputs.status.validation_errors.required')),
				}}
				valueKey="id"
				data-testid="status-edition-form-status"
				isLoading={areStatusesLoading}
				{...statusCreationModal}
			/>
			<Select
				label={t('status_object.edition.inputs.environment.label')}
				labelKey="label"
				name="environment"
				options={environments}
				rules={{
					required: Validators.isRequired(t('status_object.edition.inputs.environment.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<Checkbox
				name="inDashboard"
				label={t('status_object.edition.inputs.inDashboard.label')}
				labelFirst={false}
			/>
			<Checkbox
				name="favorite"
				label={t('status_object.edition.inputs.favorite.label')}
				labelFirst={false}
			/>
			<Checkbox
				name="crossApplication"
				label={t('status_object.edition.inputs.crossApplication.label')}
				labelFirst={false}
			/>
			<Checkbox
				name="automatic"
				label={t('status_object.edition.inputs.automatic.label')}
				labelFirst={false}
			/>
			<ColorPicker
				name="color"
				label={t('status_object.creation.inputs.color.label')}
				type="circle"
				previewComponent={TagColorPreviewComponent}
			/>
			<NumberInput
				allowNull
				label={t('status_object.edition.inputs.rank.label')}
				name="rank"
				step={1}
			/>

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditStatusObject ? t('status_object.edition.action') : t('status_object.edition.close')}
			</Button>
		</DynamicForm>
	);
};

StatusObjectEditionForm.propTypes = {
	statusObject: PropTypes.shape({
		environment: PropTypes.oneOf(Object.values(Environments)).isRequired,
		status: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
		}).isRequired,
		inDashboard: PropTypes.bool.isRequired,
		favorite: PropTypes.bool.isRequired,
		color: PropTypes.string.isRequired,
		rank: PropTypes.number,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default StatusObjectEditionForm;
