import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCreatedAt, formatCreatedBy, formatFullName, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { isValidHttpUrl } from 'lib/shared/http';
import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * @name PartnerTitle
 * @description A component to display the partner's titles.
 *
 * @author Audrey Clerc
 *
 * @param {object}	currentUser					The current user connected to the application.
 * @param {object}	partner						The partner.
 * @param {string}	partnerActivity				The description of the activity of the partner.
 * @param {object}	partnerLegalRepresentant	The legal representant {contact} of the partner.
 * @param {string}	partnerName					The name of the partner.
 * @param {string}	partnerWebsite				The website of the partner.
 */
const PartnerTitle = ({
	currentUser,
	partner,
	partnerActivity,
	partnerLegalRepresentant,
	partnerName,
	partnerWebsite,
}) => {
	const { t } = useTranslation();

	// To prepare the website link
	const linkToWebsite = useMemo(() => {
		if (partnerWebsite === '') {
			return undefined;
		}

		return (isValidHttpUrl(partnerWebsite) ? partnerWebsite : 'https://'.concat(partnerWebsite));
	}, [partnerWebsite]);

	// To display "live" the last update (if there are some update)
	const objUpdated = useMemo(() => {
		if (partner?.activityDescription !== partnerActivity
			|| partner?.name !== partnerName
			|| partner?.website !== partnerWebsite
			|| partner?.legalRepresentant !== partnerLegalRepresentant
		) {
			return { updatedBy: currentUser, updatedAt: moment() };
		}

		return partner;
	}, [currentUser, partner, partnerActivity, partnerLegalRepresentant, partnerName, partnerWebsite]);

	return (
		<div className="data-header">
			<div className="identity">
				<h3>{partnerName}</h3>
				<h5>{formatFullName(partnerLegalRepresentant)}</h5>
				{linkToWebsite && (
					<h5>
						<a href={linkToWebsite} target="_blank" rel="noreferrer">
							{partnerWebsite}
						</a>
					</h5>
				)}
				<p>{partnerActivity}</p>
			</div>
			<div className="metadata">
				<p>{`${t('partner.fields.creation_date')}: ${formatCreatedAt(partner)}`}</p>
				<p>{`${t('partner.fields.creation_name')}: ${formatCreatedBy(partner)}`}</p>
				<p>{`${t('partner.fields.update_date')}: ${formatUpdatedAt(objUpdated)}`}</p>
				<p>{`${t('partner.fields.update_name')}: ${formatUpdatedBy(objUpdated)}`}</p>
			</div>
		</div>
	);
};

PartnerTitle.propTypes = {
	currentUser: PropTypes.shape({
		id: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
	}).isRequired,
	partner: PropTypes.shape({
		legalRepresentant: PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string,
			lastName: PropTypes.string.isRequired,
		}),
		activityDescription: PropTypes.string,
		name: PropTypes.string,
		website: PropTypes.string,
	}).isRequired,
	partnerActivity: PropTypes.string,
	partnerLegalRepresentant: PropTypes.shape({
		id: PropTypes.string.isRequired,
		firstName: PropTypes.string,
		lastName: PropTypes.string.isRequired,
	}),
	partnerName: PropTypes.string,
	partnerWebsite: PropTypes.string,
};

PartnerTitle.defaultProps = {
	partnerActivity: '',
	partnerLegalRepresentant: undefined,
	partnerName: '',
	partnerWebsite: '',
};

export default PartnerTitle;
