/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

/**
 * @function
 * @name convertStringToFloat
 * @description Convert any string to a float value
 *
 * @author Romaric Barthe
 *
 * @param {string}	value		The string to be converted into float.
 * @param {number}	nbDecimal	Max number of digits after the decimal separator.
 *
 * @returns The converted string into float or undefined otherwise.
 */
export const convertStringToFloat = (value, nbDecimal = null) => {
	// TODO: include the following variables into translation.
	const decimalSeparator = ',';
	const thousandSeparator = ' ';

	if (_.isNumber(value)) {
		return nbDecimal === null ? value : parseFloat(value.toFixed(nbDecimal));
	}

	if (!_.isString(value) || _.isEmpty(value)) {
		return undefined;
	}

	const floatValue = parseFloat(value.replace(new RegExp(thousandSeparator, 'g'), '').replace(decimalSeparator, '.'));

	if (nbDecimal !== null) {
		return parseFloat(floatValue.toFixed(nbDecimal));
	}

	return floatValue;
};
