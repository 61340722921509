import { useCallback, useRef } from 'react';
import { ensurePluginOrder } from 'react-table';
import { useVirtual } from 'react-virtual';

import RenderRow from '../components/internal/RenderRow';

const pluginName = 'useVirtualization';

/**
 * @name getTableProps
 * @description Setup the props needed for the table container
 *
 * @author Yann Hodiesne
 *
 * @param {array}	props		 a list of objects to merge to get the final props
 * @param {object}	tableRef	 a reference to give to the table container
 */
const getTableProps = (props, { instance: { tableRef } }) => [
	props,
	{
		ref: tableRef,
		style: {
			// Uncomment if the Table component does not define its own height
			// height: '100%',
			overflow: 'auto',
		},
	},
];

/**
 * @name getTableBodyProps
 * @description Setup the props needed for the table body
 *
 * @author Yann Hodiesne
 *
 * @param {array}	props		 a list of objects to merge to get the final props
 * @param {object}	virtualizer	 the virtualizer currently in use
 */
const getTableBodyProps = (props, { instance: { virtualizer } }) => [
	props,
	{
		style: {
			maxHeight: `${virtualizer.totalSize}px`,
		},
	},
];

/**
 * @name useInstance
 * @description The plugin initialization hook, called when the useTable hook is called on the table component
 *
 * @author Yann Hodiesne
 *
 * @param {object} instance  the current table instance, returned by the useTable hook
 */
const useInstance = (instance) => {
	const {
		rows,
		plugins,
		rowHeight,
		rowOverscan,
	} = instance;

	ensurePluginOrder(plugins,
		['useBlockLayout', 'usePagination'],
		pluginName);

	const parentRef = useRef();

	const virtualizer = useVirtual({
		size: rows.length,
		parentRef,
		estimateSize: useCallback(() => rowHeight || 50, [rowHeight]),
		overscan: rowOverscan || 5,
	});

	const renderVirtualRows = useCallback((RowComponent, CellComponent, internalRows, page, prepareRow, props) => (
		virtualizer.virtualItems.map((row) => (
			<RenderRow
				key={row.index}
				// `page` contains the rows for the current page
				// `rows` contains all rows in the data set, we use it if page does not exist
				row={(page || internalRows)[row.index]}
				style={{
					position: 'absolute',
					top: row.start,
					left: 0,
					height: `${row.size}px`,
				}}
				prepareRow={prepareRow}
				RowComponent={RowComponent}
				CellComponent={CellComponent}
				{...props}
			/>
		))
	), [virtualizer.virtualItems]);

	Object.assign(instance, {
		virtualizer,
		tableRef: parentRef,
		renderVirtualRows,
	});
};

/**
 * @name useVirtualization
 * @description A react-table plugin used to virtualize the table content to improve performance
 *
 * @author Yann Hodiesne
 *
 * @param {object} hooks	 contains the internal react-table events on which we can add our hooks
 */
const useVirtualization = (hooks) => {
	hooks.useInstance.push(useInstance);
	hooks.getTableProps.push(getTableProps);
	hooks.getTableBodyProps.push(getTableBodyProps);
};

useVirtualization.pluginName = pluginName;

export default useVirtualization;
