import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import camelcase from 'camelcase';
import { fetchHistory, updateHistory } from 'redux/actions/histories/histories';
import { useCurrentHistorySelector } from 'redux/selectors/histories/histories';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { HistoryEditionForm } from 'components/histories';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name HistoryEditionPage
 * @description A page used to update a edition date in history.
 *
 * @author Audrey Clerc
 *
 */
const HistoryEditionPage = () => {
	const { t } = useTranslation();
	const [, appSplitUrl] = history.location.pathname.split('/');
	const appName = useMemo(() => camelcase(appSplitUrl), [appSplitUrl]);
	const { id: historyId } = useParams();
	const dispatch = useDispatch();
	const crmlog = useCurrentHistorySelector(historyId);

	const canEditHistory = useAccessRight(AccessRights.commonSettings.history.enhancedRights.CREATE_HISTORY) && crmlog?.channel !== 'workflow';

	useEffect(() => {
		dispatch(fetchHistory(historyId));
	}, [dispatch, historyId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditHistory) {
			dispatch(updateHistory(formData, historyId, routes[appName].crm.list));
		} else {
			redirectOnSuccess(routes[appName].crm.list);
		}
	}, [appName, canEditHistory, dispatch, historyId]);

	return (
		crmlog
			? <HistoryEditionForm history={crmlog} onSubmit={handleFormSubmit} />
			: <p>{t('history.edition.loading')}</p>
	);
};

export default HistoryEditionPage;
