import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import templateEnums from 'constants/templateEnums';
import { exportContacts } from 'redux/actions/contacts/contacts';
import { exportExpenses } from 'redux/actions/expenses/expenses';
import { exportPartners } from 'redux/actions/partners/partners';

import ExportForm from 'components/templates/export/ExportForm';

/**
 * @name ExportPage
 * @description A page used to export entities as a CSV or Excel file.
 *
 * @author Matthieu Schaerlinger
 */
const ExportPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Matthieu Schaerlinger
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleSubmit = useCallback((formData) => {
		if (formData.dataType === templateEnums.export.dataTypes.PARTNERS) {
			return dispatch(exportPartners(formData));
		}
		if (formData.dataType === templateEnums.export.dataTypes.CONTACTS) {
			return dispatch(exportContacts(formData));
		}
		if (formData.dataType === templateEnums.export.dataTypes.EXPENSES) {
			return dispatch(exportExpenses(formData));
		}

		return Promise.resolve();
	}, [dispatch]);

	return (
		<ExportForm onSubmit={handleSubmit} />
	);
};

export default ExportPage;
