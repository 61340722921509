import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { addQuotation } from 'redux/actions/quotations/quotations';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { QuotationCreationForm } from 'components/quotations';

/**
 * @name QuotationCreationPage
 * @description A page used to create a new quotation.
 *
 * @author Roland Margelidon
 * @author Florian Fornazaric
 *
 */
const QuotationCreationPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		state: {
			creationType,
			quotationParent,
		} = {
			creationType: undefined,
			quotationParent: undefined,
		},
	} = useLocation();

	/**
	 * Checks if the required location state data are properly provided. If not, redirects the user to the pre-creation page.
	 */
	useEffect(() => {
		if (!creationType) {
			history.push(routes.sales.quotations.quotationPreCreation);
		}
		if (creationType === 'initialisation' && !quotationParent) {
			history.push(routes.sales.quotations.quotationPreCreation);
		}
		if (creationType === 'version' && !quotationParent) {
			history.push(routes.sales.quotations.quotationPreCreation);
		}
	}, [creationType, quotationParent]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Roland Margelidon
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleFormSubmit = useCallback(async (formData) => {
		const newQuotation = await dispatch(addQuotation(formData));

		if (newQuotation) {
			history.push(generatePath(routes.sales.quotations.quotationEdition, { id: newQuotation.id }));
		}
	}, [dispatch]);

	return (
		(creationType === 'empty' || quotationParent)
			? (
				<QuotationCreationForm
					onSubmit={handleFormSubmit}
					creationType={creationType}
					quotationParent={quotationParent}
				/>
			)
			: <p>{t('quotation.edition.loading')}</p>
	);
};

export default QuotationCreationPage;
