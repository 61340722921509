import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDown, ArrowUp, Copy, XCircle } from 'react-feather';
import { QuotationColumnHeader } from 'constants/quotationEnums';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { HiddenInput, NumberInput, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name QuotationGridStandardRow
 * @description A form used to edit an existing quotation's information.
 *
 * @author Romaric Barthe
 *
 * @param {array}	columnsHeadersToDisplay	The array of the headers to be displayed.
 * @param {string}	currencyName			The ISO name of the currency in the invoice.
 * @param {string}	currencySymbol			The symbol of the currency.
 * @param {object}	defaultValues			The default values of the quotation.
 * @param {bool}	formDisabled			Whether the form (actions) should be disable.
 * @param {bool}	isLoading				Whether the data are still loading.
 * @param {array}	offers					The list of offers.
 * @param {bool}	readOnly				Whether the quotation is read-only.
 * @param {object}	row						The rows of the quotation.
 * @param {number}	rowItems				The number of rows.
 * @param {array}	vatRates				The list of vat Rates.
 * @param {func}	updateRow				The method to add/ move/ delete/ duplicate a line.
 * @param {func}	setTotals				The method to set the total of the quotation.
 * @param {func}	originalRowData			If set, the data of the row that is duplicated.
 */
const QuotationGridStandardRow = ({
	columnsHeadersToDisplay,
	currencyName,
	currencySymbol,
	defaultValues,
	formDisabled,
	isLoading,
	offers,
	readOnly,
	row,
	rowItems,
	vatRates,
	// functions
	updateRow,
	setTotals,
	// duplicate logic
	originalRowData,
}) => {
	const [discount, setDiscount] = useState(defaultValues?.rows?.[row.id]?.discount || 0);
	const [quantity, setQuantity] = useState(defaultValues?.rows?.[row.id]?.quantity || 0);
	const [unitaryCost, setUnitaryCost] = useState(defaultValues?.rows?.[row.id]?.unitaryCost || 0);

	const isInitialized = useRef(false);

	const grossTotalForLine = useMemo(() => (quantity * unitaryCost) * ((100 - discount) / 100), [discount, quantity, unitaryCost]);

	const setDiscountValue = useCallback((value) => {
		setDiscount(value);
		setTotals();
	}, [setDiscount, setTotals]);
	const setQuantityValue = useCallback((value) => {
		setQuantity(value);
		setTotals();
	}, [setQuantity, setTotals]);
	const setUnitaryCostValue = useCallback((value) => {
		setUnitaryCost(value);
		setTotals();
	}, [setUnitaryCost, setTotals]);
	const setVatChange = useCallback(() => setTotals(), [setTotals]);

	const designationRef = useRef();
	const discountRef = useRef();
	const offerRef = useRef();
	const quantityRef = useRef();
	const unitaryCostRef = useRef();
	const vatRateRef = useRef();

	useEffect(() => {
		if (!isInitialized.current) {
			isInitialized.current = true;

			if (!originalRowData) {
				return;
			}

			if (originalRowData.offer && originalRowData.offer !== null) {
				offerRef.current?.setValue(originalRowData.offer);
			}
			designationRef.current?.setValue(originalRowData.designation);
			discountRef.current?.setValue(originalRowData.discount);
			quantityRef.current?.setValue(originalRowData.quantity);
			unitaryCostRef.current?.setValue(originalRowData.unitaryCost);
			if (originalRowData.vatRate && originalRowData.vatRate !== null) {
				vatRateRef.current?.setValue(originalRowData.vatRate);
			}
		}
	}, [originalRowData]);

	const handleOfferChange = useCallback((selectedOffer) => {
		const offerDescription = offers.filter((o) => o.id === selectedOffer);
		if (offerDescription.length !== 1) {
			return;
		}
		designationRef.current.setValue(offerDescription[0].description);
	}, [offers]);

	return (
		<tr key={`tr.${row.id}`}>
			{columnsHeadersToDisplay.filter((elem) => elem.show).map((header) => {
				switch (header.label) {
					case QuotationColumnHeader.standard.OFFER:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<Select
									ref={offerRef}
									allowNull
									isLoading={isLoading}
									label=""
									labelKey="name"
									name={`rows.${row.id}.${header.label}`}
									onChange={handleOfferChange}
									options={offers}
									readOnly={readOnly}
									valueKey="id"
								/>
							</td>
						);
					case QuotationColumnHeader.standard.VAT_RATE:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<Select
									ref={vatRateRef}
									allowNull
									isLoading={isLoading}
									label=""
									labelKey="rate"
									name={`rows.${row.id}.${header.label}`}
									onChange={setVatChange}
									options={vatRates}
									readOnly={readOnly}
									valueKey="id"
								/>
							</td>
						);
					case QuotationColumnHeader.standard.DISCOUNT:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={discountRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									icon="percentage"
									label=""
									name={`rows.${row.id}.${header.label}`}
									onChange={setDiscountValue}
									readOnly={readOnly}
									step={0.01}
								/>
							</td>
						);
					case QuotationColumnHeader.standard.QUANTITY:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={quantityRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									label=""
									name={`rows.${row.id}.${header.label}`}
									onChange={setQuantityValue}
									readOnly={readOnly}
									step={0.01}
								/>
							</td>
						);
					case QuotationColumnHeader.standard.UNITARY_COST:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={unitaryCostRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									icon={currencyName}
									label=""
									name={`rows.${row.id}.${header.label}`}
									onChange={setUnitaryCostValue}
									readOnly={readOnly}
									step={0.01}
									style={{ textAlign: 'end' }}
								/>
							</td>
						);
					case QuotationColumnHeader.standard.DESIGNATION:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<TextInput
									ref={designationRef}
									label=""
									name={`rows.${row.id}.${header.label}`}
									rules={{
										required: Validators.isRequired('trigger_red_borders'),
									}}
									readOnly={readOnly}
								/>
							</td>
						);
					case QuotationColumnHeader.standard.TOTAL:
						return (
							<td key={`td.${row.id}.${header.label}`} className="cell-total">
								{formatNumber(grossTotalForLine.toFixed(2), { symbol: currencySymbol })}
							</td>
						);
					case QuotationColumnHeader.standard.ACTIONS:
						return (
							readOnly ? null : (
								<td key={`td.${row.id}.${header.label}`} className="row-actions">
									<Button
										className="icon-only"
										onClick={() => updateRow('move', row.id, row.line, 1)}
										disabled={row.line === rowItems || formDisabled}
									>
										<ArrowDown />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateRow('move', row.id, row.line, -1)}
										disabled={row.line === 1 || formDisabled}
									>
										<ArrowUp />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateRow('duplicate', row.id, row.line)}
										disabled={formDisabled}
									>
										<Copy />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateRow('delete', row.id)}
										disabled={formDisabled}
									>
										<XCircle />
									</Button>
									<HiddenInput
										name={`rows.${row.id}.line`}
										value={row.line}
									/>
								</td>
							)
						);
					default:
						return (<td key={`td.${row.id}.default`}>##</td>);
				}
			})}
		</tr>
	);
};

QuotationGridStandardRow.propTypes = {
	columnsHeadersToDisplay: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			show: PropTypes.bool,
		}),
	).isRequired,
	currencyName: PropTypes.string,
	currencySymbol: PropTypes.string,
	defaultValues: PropTypes.shape({
		rows: PropTypes.shape({
			id: PropTypes.string,
			netCost: PropTypes.number,
			vatRate: PropTypes.object,
		}),
	}),
	formDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		}),
	).isRequired,
	readOnly: PropTypes.bool,
	row: PropTypes.shape({
		id: PropTypes.string,
		line: PropTypes.number,
	}).isRequired,
	rowItems: PropTypes.number.isRequired,
	vatRates: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			rate: PropTypes.number,
		}),
	).isRequired,
	// functions
	updateRow: PropTypes.func.isRequired,
	setTotals: PropTypes.func.isRequired,
	// duplicate logic,
	originalRowData: PropTypes.object,
};

QuotationGridStandardRow.defaultProps = {
	currencyName: '',
	currencySymbol: '',
	defaultValues: undefined,
	formDisabled: false,
	readOnly: false,
	isLoading: true,
	originalRowData: undefined,
};

export default QuotationGridStandardRow;
