import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from 'routes';

/**
 * @name ActionsCell
 * @description Custom actions cell for the companyListPage table.
 *
 * @param {object}	row					The object containing the data to interact with.
 * @param {string}	row.original.id		The id of the company.
 *
 * @author Timothée Simon-Franza
 */
const ActionsCell = ({ row: { original: { id } } }) => {
	const { t } = useTranslation();

	return (
		<Link className="button primary" to={routes.superAdmin.companyEdition.replace(':id', id)}>{t('super_admin.company.links.edit')}</Link>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

export default ActionsCell;
