import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useExpensesListLoadingSelector
 * @description A selector to retrieve the loading state from the expensesList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useExpensesListLoadingSelector = () => {
	const loading = useSelector((state) => state.expensesList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useExpensesForListSelector
 * @description A selector to retrieve the expensesList list from the expense redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useExpensesForListSelector = () => {
	const expensesList = useSelector((state) => (state.expensesList?.items ?? []), shallowEqual);

	return expensesList;
};

/**
 * @function
 * @name useExpensesListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the expensesList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useExpensesListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.expensesList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useExpensesForListSelector,
	useExpensesListLoadingSelector,
	useExpensesListTotalCountSelector,
};
