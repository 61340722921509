/* eslint-disable import/prefer-default-export */
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useAccessRightListSelector
 * @description A selector to retrieve the accessRights from their dedicated redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {Array}
 */
const useAccessRightsListSelector = () => {
	const accessRights = useSelector((state) => (state?.accessRights?.items ?? []), shallowEqual);

	return accessRights;
};

export {
	useAccessRightsListSelector,
};
