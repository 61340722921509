import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Download, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import { extractOptionsEnums } from 'constants/exportEnums';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchTargeting, fetchTargetingStats } from 'redux/actions/targetings/targetings';
import { fetchTargetsForList } from 'redux/actions/targets/targetsList';
import { useCurrentTargetingSelector, useCurrentTargetingStatsSelector } from 'redux/selectors/targetings/targetings';
import { useTargetsForListSelector, useTargetsListTotalCountSelector } from 'redux/selectors/targets/targetsList';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { DataListExtractPanel, useDataExtract } from 'components/shared/list/DataListExtracts';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import TargetingHeader from 'components/targetings/TargetingHeader';
import ActionsCell from 'components/targetings/targets/targetList/ActionsCell';

import { AccessRights, useAccessRight } from '../../../lib/shared/accessRights';

import 'styles/pages/targetingDetailsPages.scss';

/**
 * @name TargetListPage
 * @description A page displaying the list of the current targeting's targets.
 *
 * From this page, the user is able to view, interact and search for targets.
 *
 * @author Romaric Barthe
 *
 */
const TargetListPage = () => {
	const dispatch = useDispatch();
	const { targetingId } = useParams();
	const { t } = useTranslation();

	const targeting = useCurrentTargetingSelector(targetingId);
	const targetingStats = useCurrentTargetingStatsSelector();
	const targets = useTargetsForListSelector();
	const totalCount = useTargetsListTotalCountSelector();

	const canCreateTarget = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING) && !targeting?.archived;
	const filters = { parentId: targetingId };

	const { isExtractPanelDisplayed, toggleExtractPanel } = useDataExtract();

	const headers = useMemo(() => [
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('targeting.target.headers.actions') },
		{ id: 'name', accessor: 'name', Header: t('targeting.target.fields.name'), pinColumn: true, sorting: 'target.name' },
		{ id: 'description', accessor: 'description', Header: t('targeting.target.fields.description'), sorting: 'target.description' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('targeting.target.fields.creation_date'), sorting: 'target.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('targeting.target.fields.update_date'), sorting: 'target.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('targeting.target.fields.creation_name'), sorting: 'target.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('targeting.target.fields.update_name'), sorting: 'target.updatedBy' },
	], [t]);

	useEffect(() => {
		dispatch(fetchTargeting(targetingId));
		dispatch(fetchTargetingStats(targetingId));
	}, [dispatch, targetingId]);

	const [fetchData, searchRef] = useSearch((params) => {
		const newParams = {
			...params,
			filters: { conditions: [{ column: 'targetingId', criterion: 'is', value: targetingId }] },
		};

		return dispatch(fetchTargetsForList(newParams));
	});

	const [extractButtonRef, extractTooltipProps] = useTooltip();
	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('target');

	const [selectedTargetsIds, setSelectedTargetsIds] = useState([]);
	const targetsIndices = useMemo(() => targets.map(
		(target, index) => (selectedTargetsIds?.includes(target.id) ? index : null)
	).filter((targetIndex) => targetIndex !== null),
	[targets, selectedTargetsIds]);

	const targetsPointer = useRef(targets);
	targetsPointer.current = targets;
	const selectedTargetsIdsPointer = useRef(selectedTargetsIds);
	selectedTargetsIdsPointer.current = selectedTargetsIds;

	const selectedTargetsIdsChanged = useCallback((pSelectedTargets) => {
		const targetsId = targetsPointer.current.map((target) => target.id);
		const pSelectedTargetsIds = pSelectedTargets.map((target) => target.id);

		const targetsToAddIds = pSelectedTargetsIds.filter(
			(targetToAddId) => !selectedTargetsIdsPointer.current.includes(targetToAddId)
			&& targetsId.includes(targetToAddId)
		);
		const targetsNoMoreToAddIds = selectedTargetsIdsPointer.current.filter((targetId) => targetsId.includes(targetId))
			.filter((targetId) => !pSelectedTargetsIds.includes(targetId));

		setSelectedTargetsIds((value) => [...value.filter((targetId) => !targetsNoMoreToAddIds.includes(targetId)), ...targetsToAddIds]);
	}, []);

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.operations.targetings.targets.targetCreation, { targetingId })); }, [targetingId]);

	return (
		<div className="targeting-details-page">
			<div className="targeting-details-header">
				{targeting
					? <TargetingHeader targeting={targeting} targetingStats={targetingStats} />
					: <h5>{t('targeting.target.headers.target_partner')}</h5>}
			</div>
			<div className="targeting-details-content">
				<TableActionsRow>
					<SearchInput ref={searchRef} onSearch={fetchData} />
					{canCreateTarget && (
						<>
							<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
								<Plus />
							</Button>
							<Tooltip {...newTooltipProps}>
								{t('targeting.target.links.create')}
							</Tooltip>
						</>
					)}
					<Button className="icon-only" onClick={toggleExtractPanel} ref={extractButtonRef}>
						<Download />
					</Button>
					<Tooltip {...extractTooltipProps}>
						{t('template.export.action')}
					</Tooltip>
				</TableActionsRow>
				<DynamicTable
					headers={headers}
					rows={targets}
					rowsCount={totalCount}
					rowHeight={80}
					fetchData={fetchData}
					defaultSortingPrefix="target"
					selectionChanged={selectedTargetsIdsChanged}
					selectedRowsIndices={targetsIndices}
					{...tableProps}
				/>
				<TableUserPreferencesModal headers={headers} tableName={t('targeting.target.pages.list')} {...modalProps} />
				<DataListExtractPanel
					entity={extractOptionsEnums.export.dataTypes.TARGETS}
					open={isExtractPanelDisplayed}
					onCloseButtonClick={toggleExtractPanel}
					selectedElementsIds={selectedTargetsIds}
					filters={filters}
				/>
			</div>
		</div>
	);
};

export default TargetListPage;
