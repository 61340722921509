import { memo, useMemo, useReducer } from 'react';
import { isDev } from 'lib/shared/environmentHelper';
import PropTypes from 'prop-types';

import optimizationChecker from './debug/optimizationChecker';
import stateChecker from './debug/stateChecker';
import ensureStateConsistency from './functions/internals/ensureStateConsistency';
import reducer from './reducer/reducer';
import { EditorContent, EditorDragLayer, EditorDynamicToolbox, EditorHeader, EntitiesList } from './components';
import EditorContext from './EditorContext';
import { getTitleNumbers } from './functions';

/**
 * @name TemplateExport
 * @description A view of the template where the user can establish links with entities and fill dynamic fields
 *
 * @author Florian Fornazaric
 *
 * @param {string}	initialData	 					Template initial data
 * @param {object} 	templateMetadata 				An object describing the template.
 * @param {string}	templateMetadata.description  	Template's description
 * @param {string}	templateMetadata.type  			Template's type
 * @param {string}	templateMetadata.name  			Template's name
 * @param {array}	[linkedEntities=[]]  			An array of objects describing the entities linked to the template.
 * @param {boolean}	[fromExport=false]				Whether the current template comes from an existing export (true) or from a new one (false).
 * @param {string}  [linkedEntityId=undefined]		The id of the entity that is linked to the template.
 * @param {string}  [editionDate]					The edition date if submitted.
 * @param {string}  [lastEditionDate]				The last edition date if submitted.
 */
const TemplateExport = ({ initialData, templateMetadata, linkedEntities, fromExport, linkedEntityId, forwardedEditionDate, forwardedLastEditionDate }) => {
	const initialState = useMemo(() => {
		const state = ensureStateConsistency(initialData);

		return ({
			...state,
			templateDescription: templateMetadata.description,
			templateName: templateMetadata.name,
			isExporting: true,
			editionDate: forwardedEditionDate,
			lastEditionDate: forwardedLastEditionDate,
		});
	}, [forwardedEditionDate, forwardedLastEditionDate, initialData, templateMetadata.description, templateMetadata.name]);

	const [{
		editionDate,
		elements,
		entities,
		header,
		footer,
		lastEditionDate,
		margins,
		isExporting,
		pages,
		pageNumberingStartIndex,
		selectedElements,
		templateName,
		templateDescription,
	}, dispatch] = useReducer(
		isDev() ? stateChecker(optimizationChecker(reducer)) : reducer,
		initialState
	);

	const titleNumbers = useMemo(() => getTitleNumbers(elements), [elements]);

	const editorContext = useMemo(() => ({
		dispatch,
		editionDate,
		elements,
		entities,
		fromExport,
		header,
		lastEditionDate,
		margins,
		isExporting,
		linkedEntities,
		pages,
		selectedElements,
		templateDescription,
		pageNumberingStartIndex,
		templateName,
		footer,
		titleNumbers,
	}), [editionDate, elements, entities, fromExport, header, lastEditionDate, margins, isExporting,
		linkedEntities, pages, selectedElements, templateDescription, pageNumberingStartIndex,
		templateName, footer, titleNumbers]);

	return (
		<EditorContext.Provider value={editorContext}>
			<div className="template-editor export">
				<EditorDragLayer />
				<EditorContent />
				<aside>
					<EditorHeader />
					<EntitiesList linkedEntityId={linkedEntityId} forwardEditionDate={editionDate} forwardLastEditionDate={lastEditionDate} />
					<EditorDynamicToolbox />
				</aside>
			</div>
		</EditorContext.Provider>
	);
};

TemplateExport.propTypes = {
	initialData: PropTypes.object,
	templateMetadata: PropTypes.shape({
		description: PropTypes.string,
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	}).isRequired,
	linkedEntities: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		linkedEntity: PropTypes.string,
		type: PropTypes.string.isRequired,
	})),
	fromExport: PropTypes.bool,
	linkedEntityId: PropTypes.string,
	forwardedEditionDate: PropTypes.instanceOf(Date),
	forwardedLastEditionDate: PropTypes.instanceOf(Date),
};

TemplateExport.defaultProps = {
	initialData: {},
	linkedEntities: [],
	fromExport: false,
	linkedEntityId: undefined,
	forwardedEditionDate: undefined,
	forwardedLastEditionDate: undefined,
};

export default memo(TemplateExport);
