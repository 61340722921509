import { toast } from 'react-toastify';
import * as RelationTypesApi from 'api/relationTypesApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the RelationTypes redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific relation type
	FETCH_RELATION_TYPE_REQUEST: '@RELATION_TYPES/FETCH_REQUEST',
	FETCH_RELATION_TYPE_SUCCESS: '@RELATION_TYPES/FETCH_SUCCESS',
	FETCH_RELATION_TYPE_FAILURE: '@RELATION_TYPES/FETCH_FAILURE',

	// Fetch a list of relation types
	FETCH_RELATION_TYPE_LIST_REQUEST: '@RELATION_TYPES/FETCH_LIST_REQUEST',
	FETCH_RELATION_TYPE_LIST_SUCCESS: '@RELATION_TYPES/FETCH_LIST_SUCCESS',
	FETCH_RELATION_TYPE_LIST_FAILURE: '@RELATION_TYPES/FETCH_LIST_FAILURE',

	// Add a new relation type to the database
	ADD_RELATION_TYPE_REQUEST: '@RELATION_TYPES/ADD_REQUEST',
	ADD_RELATION_TYPE_SUCCESS: '@RELATION_TYPES/ADD_SUCCESS',
	ADD_RELATION_TYPE_FAILURE: '@RELATION_TYPES/ADD_FAILURE',

	// Update an existing relation type from the database
	UPDATE_RELATION_TYPE_REQUEST: '@RELATION_TYPES/UPDATE_REQUEST',
	UPDATE_RELATION_TYPE_SUCCESS: '@RELATION_TYPES/UPDATE_SUCCESS',
	UPDATE_RELATION_TYPE_FAILURE: '@RELATION_TYPES/UPDATE_FAILURE',

	// Remove an existing relation type from the database
	REMOVE_RELATION_TYPE_REQUEST: '@RELATION_TYPES/REMOVE_REQUEST',
	REMOVE_RELATION_TYPE_SUCCESS: '@RELATION_TYPES/REMOVE_SUCCESS',
	REMOVE_RELATION_TYPE_FAILURE: '@RELATION_TYPES/REMOVE_FAILURE',

	// Archive a relation type from the database
	ARCHIVE_RELATION_TYPE_REQUEST: '@RELATION_TYPES/ARCHIVE_REQUEST',
	ARCHIVE_RELATION_TYPE_SUCCESS: '@RELATION_TYPES/ARCHIVE_SUCCESS',
	ARCHIVE_RELATION_TYPE_FAILURE: '@RELATION_TYPES/ARCHIVE_FAILURE',

	// Restore a relation type from the database
	RESTORE_RELATION_TYPE_REQUEST: '@RELATION_TYPES/RESTORE_REQUEST',
	RESTORE_RELATION_TYPE_SUCCESS: '@RELATION_TYPES/RESTORE_SUCCESS',
	RESTORE_RELATION_TYPE_FAILURE: '@RELATION_TYPES/RESTORE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Relation type list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchRelationTypeListRequest
 * @description Action triggered anytime a relation type list fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchRelationTypeListRequest = () => ({ type: ActionTypes.FETCH_RELATION_TYPE_LIST_REQUEST });

/**
 * @function
 * @name fetchRelationTypeListSuccess
 * @description Action triggered as a result to a successful relation type list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} relationTypes	The list of retrieved relation types.
 * @param {number} totalCount		The total amount of relation types available in the database for the current user.
 *
 * @returns {object}
 */
const fetchRelationTypeListSuccess = ({ relationTypes, totalCount }) => ({
	type: ActionTypes.FETCH_RELATION_TYPE_LIST_SUCCESS,
	payload: { relationTypes, totalCount },
});

/**
 * @function
 * @name fetchRelationTypeListFailure
 * @description Action triggered as a result to a failed relation type list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchRelationTypeListFailure = (error) => ({
	type: ActionTypes.FETCH_RELATION_TYPE_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific relation type fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchRelationTypeRequest
 * @description Action triggered anytime a relation type fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchRelationTypeRequest = () => ({ type: ActionTypes.FETCH_RELATION_TYPE_REQUEST });

/**
 * @function
 * @name fetchRelationTypeSuccess
 * @description Action triggered as a result to a successful relation type fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} relationType	The retrieved relation type.
 *
 * @returns {object}
 */
const fetchRelationTypeSuccess = ({ relationType }) => ({
	type: ActionTypes.FETCH_RELATION_TYPE_SUCCESS,
	payload: { relationType },
});

/**
 * @function
 * @name fetchRelationTypeFailure
 * @description Action triggered as a result to a failed relation type fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchRelationTypeFailure = (error) => ({
	type: ActionTypes.FETCH_RELATION_TYPE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Relation type creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addRelationTypeRequest
 * @description Action triggered anytime a relation type creation call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const addRelationTypeRequest = () => ({ type: ActionTypes.ADD_RELATION_TYPE_REQUEST });

/**
 * @function
 * @name addRelationTypeSuccess
 * @description Action triggered as a result to a successful relation type creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} relationType	The created relation type object.
 *
 * @returns {object}
 */
const addRelationTypeSuccess = ({ relationType }) => ({
	type: ActionTypes.ADD_RELATION_TYPE_SUCCESS,
	payload: { relationType },
});

/**
 * @function
 * @name addRelationTypeFailure
 * @description Action triggered as a result to a failed relation type creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addRelationTypeFailure = (error) => ({
	type: ActionTypes.ADD_RELATION_TYPE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Relation type update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateRelationTypeRequest
 * @description Action triggered anytime a relation type update call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const updateRelationTypeRequest = () => ({ type: ActionTypes.UPDATE_RELATION_TYPE_REQUEST });

/**
 * @function
 * @name updateRelationTypeSuccess
 * @description Action triggered as a result to a successful relation type update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} relationType	The updated relation type object.
 *
 * @returns {object}
 */
const updateRelationTypeSuccess = ({ relationType }) => ({
	type: ActionTypes.UPDATE_RELATION_TYPE_SUCCESS,
	payload: { relationType },
});

/**
 * @function
 * @name updateRelationTypeFailure
 * @description Action triggered as a result to a failed relation type update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateRelationTypeFailure = (error) => ({
	type: ActionTypes.UPDATE_RELATION_TYPE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Relation type removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeRelationTypeRequest
 * @description Action triggered anytime a relation type deletion call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const removeRelationTypeRequest = () => ({ type: ActionTypes.REMOVE_RELATION_TYPE_REQUEST });

/**
 * @function
 * @name removeRelationTypeSuccess
 * @description Action triggered as a result to a successful relation type deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {string} deletedRelationTypeId	The removed relation type object id.
 *
 * @returns {object}
 */
const removeRelationTypeSuccess = ({ deletedRelationTypeId }) => ({
	type: ActionTypes.REMOVE_RELATION_TYPE_SUCCESS,
	payload: { deletedRelationTypeId },
});

/**
 * @function
 * @name removeRelationTypeFailure
 * @description Action triggered as a result to a failed relation type deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeRelationTypeFailure = (error) => ({
	type: ActionTypes.REMOVE_RELATION_TYPE_FAILURE,
	payload: { error },
});

// /////////////////////////////////////////////////////////// //
// //////////////// Relation type archiving actions /////////////// //
// /////////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveRelationTypeRequest
 * @description Action triggered anytime a relation type archiving call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const archiveRelationTypeRequest = () => ({ type: ActionTypes.ARCHIVE_RELATION_TYPE_REQUEST });

/**
 * @function
 * @name archiveRelationTypeSuccess
 * @description Action triggered as a result to a successful relation type archiving API call.
 *
 * @author Audrey Clerc
 *
 * @param {array} relationType		The updated relation type object.
 *
 * @returns {object}
 */
const archiveRelationTypeSuccess = ({ relationType }) => ({
	type: ActionTypes.ARCHIVE_RELATION_TYPE_SUCCESS,
	payload: { relationType },
});

/**
 * @function
 * @name archiveRelationTypeFailure
 * @description Action triggered as a result to a failed relation type archiving API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveRelationTypeFailure = (error) => ({
	type: ActionTypes.ARCHIVE_RELATION_TYPE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// //////////////// Relation type restoring actions /////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreRelationTypeRequest
 * @description Action triggered anytime a relation type restoring call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const restoreRelationTypeRequest = () => ({ type: ActionTypes.RESTORE_RELATION_TYPE_REQUEST });

/**
 * @function
 * @name restoreRelationTypeSuccess
 * @description Action triggered as a result to a successful relation type restoring API call.
 *
 * @author Audrey Clerc
 *
 * @param {array} relationType		The updated relation type object.
 *
 * @returns {object}
 */
const restoreRelationTypeSuccess = ({ relationType }) => ({
	type: ActionTypes.RESTORE_RELATION_TYPE_SUCCESS,
	payload: { relationType },
});

/**
 * @function
 * @name restoreRelationTypeFailure
 * @description Action triggered as a result to a failed relation type restoring API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreRelationTypeFailure = (error) => ({
	type: ActionTypes.RESTORE_RELATION_TYPE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchRelationTypeList
 * @description Method used to update the relation type list.
 *
 * @author Audrey Clerc
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchRelationTypeList = (params) => (dispatch) => {
	dispatch(fetchRelationTypeListRequest());

	return RelationTypesApi.fetchRelationTypes(params)
		.then(({ relationTypes, totalCount }) => dispatch(fetchRelationTypeListSuccess({ relationTypes, totalCount })))
		.catch((error) => dispatch(fetchRelationTypeListFailure(error)));
};

/**
 * @function
 * @name fetchRelationType
 * @description Method used to fetch the latest version of a specific relation type.
 *
 * @author Audrey Clerc
 *
 * @param {string} relationTypeId	The id of the relation type we want to retrieve.
 */
export const fetchRelationType = (relationTypeId) => (dispatch) => {
	dispatch(fetchRelationTypeRequest());

	return RelationTypesApi.fetchRelationTypeById(relationTypeId)
		.then(({ relationType }) => dispatch(fetchRelationTypeSuccess({ relationType })))
		.catch((error) => dispatch(fetchRelationTypeFailure(error)));
};

/**
 * @function
 * @name addRelationType
 * @description Method used to add a new relation type instance to the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} relationTypeData	The data to create the new relation type from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addRelationType = (relationTypeData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addRelationTypeRequest());

	return RelationTypesApi.createRelationType(relationTypeData)
		.then(({ relationType }) => {
			toast.success(i18next.t('relation_type.creation.toasts.success', { name: relationType.name }));
			dispatch(addRelationTypeSuccess({ relationType }));
			redirectOnSuccess(onSuccessRoute);

			return relationType;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('relation_type.creation.toasts.conflict'));
				dispatch(addRelationTypeFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('relation_type.creation.toasts.error'));
				dispatch(addRelationTypeFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateRelationType
 * @description Method used to update an existing relation type instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} relationTypeData	The object to update the relation type with.
 * @param {string} relationTypeId	The id of the relation type we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateRelationType = (relationTypeData, relationTypeId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateRelationTypeRequest());

	return RelationTypesApi.updateRelationType(relationTypeData, relationTypeId)
		.then(({ relationType }) => {
			dispatch(updateRelationTypeSuccess({ relationType }));
			toast.success(i18next.t('relation_type.edition.toasts.success', { name: relationType.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('relation_type.edition.toasts.conflict'));
				dispatch(updateRelationTypeFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('relation_type.edition.toasts.error'));
				dispatch(updateRelationTypeFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeRelationType
 * @description Method used to remove an existing relation type instance from the database.
 *
 * @author Audrey Clerc
 * @author Timothée Simon-Franza
 *
 * @param {object} relationType			The relation type we want to remove from the database.
 * @param {string} relationType.id		The id of the relation type we want to remove from the database.
 * @param {string} relationType.name	The name of the relation type we want to remove from the database.
 */
export const removeRelationType = ({ id, name }) => (dispatch) => {
	dispatch(removeRelationTypeRequest());

	return RelationTypesApi.deleteRelationType(id)
		.then(({ deletedRelationTypeId }) => {
			dispatch(removeRelationTypeSuccess({ deletedRelationTypeId }));
			toast.success(i18next.t('relation_type.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeRelationTypeFailure(error));
			toast.error(i18next.t('relation_type.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveRelationType
 * @description Method used to archive an existing relation type instance from the database.
 *
 * @author Audrey Clerc
 * @author Timothée Simon-Franza
 *
 * @param {object} relationType			The relation type we want to archive.
 * @param {string} relationType.id		The id of the relation type we want to archive.
 * @param {string} relationType.name	The name of the relation type we want to archive.
 */
export const archiveRelationType = ({ id, name }) => (dispatch) => {
	dispatch(archiveRelationTypeRequest());

	return RelationTypesApi.updateRelationType({ archived: true }, id)
		.then(({ relationType }) => {
			dispatch(archiveRelationTypeSuccess({ relationType }));
			toast.success(i18next.t('relation_type.archiving.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(archiveRelationTypeFailure(error));
			toast.error(i18next.t('relation_type.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreRelationType
 * @description Method used to restore an existing relation type instance from the database.
 *
 * @author Audrey Clerc
 * @author Timothée Simon-Franza
 *
 * @param {object} relationType			The relation type we want to restore.
 * @param {string} relationType.id		The id of the relation type we want to restore.
 * @param {string} relationType.name	The name of the relation type we want to restore.
 */
export const restoreRelationType = ({ id, name }) => (dispatch) => {
	dispatch(restoreRelationTypeRequest());

	return RelationTypesApi.updateRelationType({ archived: false }, id)
		.then(({ relationType }) => {
			dispatch(restoreRelationTypeSuccess({ relationType }));
			toast.success(i18next.t('relation_type.restoring.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(restoreRelationTypeFailure(error));
			toast.error(i18next.t('relation_type.restoring.toasts.error'));
		});
};
