import * as RegistrationTypesApi from 'api/registrationTypesApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the RegistrationTypes redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a list of registration types
	FETCH_REGISTRATION_TYPE_LIST_REQUEST: '@REGISTRATION_TYPES/FETCH_LIST_REQUEST',
	FETCH_REGISTRATION_TYPE_LIST_SUCCESS: '@REGISTRATION_TYPES/FETCH_LIST_SUCCESS',
	FETCH_REGISTRATION_TYPE_LIST_FAILURE: '@REGISTRATION_TYPES/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////////////// //
// //////////// RegistrationType list fetching actions //////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchRegistrationTypeListRequest
 * @description Action triggered anytime a registration type list call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchRegistrationTypeListRequest = () => ({ type: ActionTypes.FETCH_REGISTRATION_TYPE_LIST_REQUEST });

/**
 * @function
 * @name fetchRegistrationTypeListSuccess
 * @description Action triggered as a result to a successful registration type list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} registrationTypes	The list of retrieved registration types.
 * @param {number} totalCount	 		The total amount of registration types available in the database to the current user.
 *
 * @returns {object}
 */
const fetchRegistrationTypeListSuccess = ({ registrationTypes, totalCount }) => ({
	type: ActionTypes.FETCH_REGISTRATION_TYPE_LIST_SUCCESS,
	payload: { registrationTypes, totalCount },
});

/**
 * @function
 * @name fetchRegistrationTypeListFailure
 * @description Action triggered as a result to a failed registration type list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchRegistrationTypeListFailure = (error) => ({
	type: ActionTypes.FETCH_REGISTRATION_TYPE_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////////// Exported action creatiors ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchRegistrationTypeList
 * @description Method used to update the registration type list.
 *
 * @author Romaric Barthe
 */
export const fetchRegistrationTypeList = () => (dispatch) => {
	dispatch(fetchRegistrationTypeListRequest());

	return RegistrationTypesApi.fetchRegistrationTypes()
		.then(({ registrationTypes, totalCount }) => {
			dispatch(fetchRegistrationTypeListSuccess({ registrationTypes, totalCount }));
		})
		.catch((error) => dispatch(fetchRegistrationTypeListFailure(error)));
};
