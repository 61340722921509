import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentTextObjectSelector
 * @description A selector to retrieve the current text object from the text object redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the current text object to wait for
 *
 * @returns {object}
 */
const useCurrentTextObjectSelector = (id) => {
	const textObject = useSelector((state) => (state.textObjects?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (textObject?.id === id ? textObject : null), [id, textObject]);

	return result;
};

/**
 * @function
 * @name useTextObjectListSelector
 * @description A selector to retrieve the text object list from the text object redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTextObjectListSelector = () => {
	const textObjects = useSelector((state) => (state.textObjects?.items ?? []), shallowEqual);

	return textObjects;
};

/**
 * @function
 * @name useTextObjectActiveListSelector
 * @description A selector to retrieve all non-archived text objects from the text object redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTextObjectActiveListSelector = () => {
	const textObjects = useTextObjectListSelector();

	const result = useMemo(() => textObjects.filter(({ archived }) => !archived), [textObjects]);

	return result;
};

/**
 * @function
 * @name useTextObjectTotalCountSelector
 * @description A selector to retrieve the totalCount value from the text object redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useTextObjectTotalCountSelector = () => useSelector((state) => (state.textObjects?.totalCount ?? undefined), shallowEqual);

/**
 * @function
 * @name useTextObjectLoadingSelector
 * @description A selector to retrieve the loading state from the text object redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useTextObjectLoadingSelector = () => useSelector((state) => state.textObjects?.isLoading ?? false);

export {
	useCurrentTextObjectSelector,
	useTextObjectActiveListSelector,
	useTextObjectListSelector,
	useTextObjectLoadingSelector,
	useTextObjectTotalCountSelector,
};
