import update from 'immutability-helper';

import findPositionById from './internals/findPositionById';
import { getElementType } from './internals';

/**
 * @function
 * @name removeElements
 * @description Removes multiple elements from the list.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object}		state	The current state.
 * @param {string[]}	ids		The identifiers of the elements to remove.
 *
 * @returns {object} The updated state value
 */
const removeElements = (state, { ids }) => {
	const { elements } = state;

	// Allows us to iterate over every element and remove them from the element list one by one
	const result = ids.reduce((newElements, id) => {
		const sourcePosition = findPositionById(newElements, id);

		if (!sourcePosition) {
			return newElements;
		}

		const source = elements[sourcePosition.rowIndex].children[sourcePosition.elementIndex];
		const elementType = getElementType(source);

		// If the element is a non-inline element, or the row is about to be emptied (in multi-removal mode), remove the entire row
		if (!elementType.meta.inline || (newElements[sourcePosition.rowIndex].children.length === 1 && ids.length > 1)) {
			return update(newElements, {
				$splice: [
					[sourcePosition.rowIndex, 1],
				],
			});
		}

		// Otherwise, remove only the element
		return update(newElements, {
			[sourcePosition.rowIndex]: {
				children: {
					$splice: [
						[sourcePosition.elementIndex, 1],
					],
				},
			},
		});
	}, elements);

	// Return original if no elements were deleted
	if (result === elements) {
		return state;
	}

	// Return new elements and clear selection
	return {
		...state,
		elements: result,
		isDirty: true,
		selectedElements: [],
	};
};

export default removeElements;
