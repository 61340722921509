import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import { historyChannels, historyRestrictions, historyTypes } from 'constants/historyEnums';
import PropTypes from 'prop-types';
import { fetchStatusObjectList } from 'redux/actions/statusObjects';
import { useStatusObjectListSelector, useStatusObjectLoadingSelector } from 'redux/selectors/statusObjects';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { DateInput, HiddenInput, Select, TextArea } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name HistoryCreationStatusForm
 * @description A form used to create a new history.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit			The method to trigger upon form submission.
 * @param {array}	[targetPartnersIds]	The list of targetPartnerId to pre-fill information from.
 * @param {array}	[targetingId]		The id of the targeting on which depends the targetPartners.
 * @param {array}	[invoicesIds]		The list of invoiceId to pre-fill information from.
 */
const HistoryCreationStatusForm = ({ onSubmit, targetPartnersIds, targetingId, invoicesIds }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const dateToday = useMemo(() => new Date(), []);
	dateToday.setUTCHours(23, 59, 59, 999);

	useEffect(() => {
		if (targetingId) {
			dispatch(fetchStatusObjectList({
				rowsPerPage: 0,
				filters: {
					conditions: [
						{ column: 'environment', criterion: 'is', value: Environments.TARGETING },
						{ column: 'targetings', criterion: 'is', value: targetingId },
					],
				},
			}));
		} else if (invoicesIds && invoicesIds.length > 0) {
			dispatch(fetchStatusObjectList({
				rowsPerPage: 0,
				filters: {
					conditions: [{ column: 'environment', criterion: 'is', value: Environments.INVOICE }],
				},
			}));
		}
	}, [dispatch, invoicesIds, targetingId]);

	const statusObjects = useStatusObjectListSelector(targetingId);
	const areStatusObjectsLoading = useStatusObjectLoadingSelector();

	const statusObjectsSelectOptions = useMemo(() => (statusObjects.map((statusObject) => ({ id: statusObject.id, fullname: statusObject.status?.name }))), [statusObjects]);

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm onSubmit={onSubmit} {...formProps}>
			<DateInput
				label={t('targeting.target.target_partner.edition.inputs.date.label')}
				name="date"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
					maxDate: Validators.maxDate(dateToday, t('components.date_picker.format'), t('history.edition.inputs.date.validation_errors.maxDate')),
				}}
			/>
			<HiddenInput
				name="targetPartners"
				value={JSON.stringify(targetPartnersIds)}
			/>
			<HiddenInput
				name="invoices"
				value={JSON.stringify(invoicesIds)}
			/>
			<HiddenInput
				name="historyType"
				value={historyTypes.HISTORY_TYPE_STANDARD}
			/>
			<HiddenInput
				name="channel"
				value={historyChannels.HISTORY_CHANNEL_STATUS}
			/>
			<HiddenInput
				name="restriction"
				value={historyRestrictions.HISTORY_RESTRICTION_PUBLIC}
			/>
			<Select
				isLoading={areStatusObjectsLoading}
				label={t('targeting.target.target_partner.edition.inputs.status.label')}
				labelKey="fullname"
				name="statusObject"
				options={statusObjectsSelectOptions}
				valueKey="id"
				rules={{
					required: Validators.isRequired(t('targeting.target.target_partner.edition.inputs.status.validation_errors.required')),
				}}
			/>
			<TextArea
				label={t('targeting.target.target_partner.edition.inputs.note.label')}
				name="note"
				placeholder={t('targeting.target.target_partner.edition.inputs.note.placeholder')}
				type="text"
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('history.creation.action')}</Button>
		</DynamicForm>
	);
};

HistoryCreationStatusForm.propTypes = {
	targetPartnersIds: PropTypes.arrayOf(PropTypes.string),
	targetingId: PropTypes.string,
	invoicesIds: PropTypes.arrayOf(PropTypes.string),
	onSubmit: PropTypes.func.isRequired,
};

HistoryCreationStatusForm.defaultProps = {
	targetPartnersIds: [],
	targetingId: null,
	invoicesIds: [],
};

export default HistoryCreationStatusForm;
