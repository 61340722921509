import { useCallback, useMemo, useState } from 'react';
import conf from 'conf';
import { useAccessRightsListSelector } from 'redux/selectors/accessRights';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import { getAvailableRoutingForUser } from 'routes/routing';

import { SvgIcon } from 'components/shared/utils';

import NavbarMenuItem from './NavbarMenuItem';

/**
 * @name Navbar
 * @description The main navbar component.
 *
 * @author Timothée Simon-Franza
 */
const Navbar = () => {
	const [activeModule, setActiveModule] = useState(null);
	const currentUser = useCurrentConnectedUserSelector();

	const accessRights = useAccessRightsListSelector();
	const activatedModules = useMemo(() => Object.entries(accessRights).map((right) => right[0]), [accessRights]);

	const onModuleClick = useCallback((moduleName) => {
		setActiveModule(activeModule === moduleName ? null : moduleName);
	}, [activeModule]);

	const availableRoutes = getAvailableRoutingForUser(currentUser, activatedModules);

	return (
		<nav>
			<ul>
				{Object.values(availableRoutes).map(({ path, iconPath, name, routes, submenus }) => (
					<NavbarMenuItem
						key={path}
						path={path}
						iconPath={iconPath}
						isActive={activeModule === name}
						name={name}
						setCurrentActiveModule={onModuleClick}
						routes={routes}
						submenus={submenus}
					/>
				))}
			</ul>
			<SvgIcon className="appSvg" path={`${conf.iconsPath}logo.svg`} />
		</nav>
	);
};

export default Navbar;
