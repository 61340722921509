import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchFile } from 'redux/actions/mediaManager';
import { useMediaFileSelector } from 'redux/selectors/mediaManager';

/**
 * @function
 * @name useMedia
 * @description Retrieves a single media manager file instance, along with its presigned URL.
 *
 * Note: this hook is the only way to access superadmin medias from a regular user account.
 *
 * @author Yann Hodiesne
 *
 * @param {string} id	The identifier of the file to retrieve.
 *
 * @returns {object|null} The media manager file instance, or null while waiting for the backend to respond.
 */
export default (id) => {
	const dispatch = useDispatch();
	const media = useMediaFileSelector(id);
	const returnValue = useMemo(() => (media ? { ...media, error: false } : null), [media]);

	const [error, setError] = useState(false);
	const loadedId = useRef();

	useEffect(() => {
		setError(false);
	}, [id]);

	useEffect(() => {
		if (id && loadedId.current !== id) {
			loadedId.current = id;

			// eslint-disable-next-line require-jsdoc
			const triggerFetch = async () => {
				const result = await dispatch(fetchFile(id));

				if (result === null) {
					setError(true);
				}
			};

			triggerFetch();
		}
	}, [dispatch, id]);

	return !error ? returnValue : { error };
};
