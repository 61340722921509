import update from 'immutability-helper';

import { PageOrientations } from '../constants/PagesOrientations';

import { createPage } from './internals';

/**
 * @function
 * @name insertPage
 * @description Inserts a new page at the given index
 *
 * @author Florian Fornazaric
 *
 * @param {object}	state				The current state
 * @param {number}	payload.index		The index where to insert the new page
 * @param {array}	payload.orientation	The orientation of the new page (portrait, landscape)
 *
 * @returns {object} The updated state value
 */
const insertPage = (state, { index, orientation = PageOrientations.PORTRAIT }) => {
	const { pages } = state;

	const newPages = update(pages, {
		$splice: [
			[index, 0, createPage(orientation)],
		],
	});

	const result = {
		...state,
		pages: newPages,
		isDirty: true,
	};

	return result;
};

export default insertPage;
