import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuotationColumnHeader } from 'constants/quotationEnums';
import PropTypes from 'prop-types';

import DynamicFormContext from '../../shared/forms/internals/DynamicFormContext';

import QuotationGridStandardRow from './QuotationGridStandardRow';

/**
 * @name QuotationGridStandard
 * @description A form used to edit an existing quotation's information.
 *
 * @author Romaric Barthe
 *
 * @param {array}	columnsHeaders		The array of all possible header.
 * @param {string}	currencyName		The ISO name of the currency used in the quotation.
 * @param {string}	currencySymbol		The symbol of the currency used in the quotation.
 * @param {object}	defaultValues		The default values of the quotation.
 * @param {bool}	isLoading			Whether the list are still loading.
 * @param {array}	offers				The list of offers in line with the selected currency.
 * @param {bool}	readOnly			Whether the quotation is read-only.
 * @param {object}	rows				The rows of the quotation.
 * @param {array}	vatRates			The list of vatRates.
 * @param {func}	updateRow			The method to add/ move/ delete/ duplicate a line.
 * @param {func}	setTotals			The method to set the total of the quotation.
 * @param {func}	duplicateRowId		If set, the id of the row that is duplicated.
 * @param {func}	originalRowData		If set, the data of the row that is duplicated.
 */
const QuotationGridStandard = ({
	columnsHeaders,
	currencyName,
	currencySymbol,
	defaultValues,
	isLoading,
	offers,
	readOnly,
	rows,
	vatRates,
	// functions
	updateRow,
	setTotals,
	// duplicate logic
	duplicateRowId,
	originalRowData,
}) => {
	const { t } = useTranslation();

	const rowItems = useMemo(() => Math.max(...rows.map((r) => r?.line)), [rows]);

	const { disabled: formDisabled } = useContext(DynamicFormContext);

	return (
		<table className="grid">
			<thead>
				<tr>
					{columnsHeaders.filter((elem) => elem.show).map((header) => {
						switch (header.label) {
							case QuotationColumnHeader.standard.ACTIONS:
							case QuotationColumnHeader.standard.DISCOUNT:
							case QuotationColumnHeader.standard.QUANTITY:
							case QuotationColumnHeader.standard.TOTAL:
							case QuotationColumnHeader.standard.UNITARY_COST:
								return (
									<th key={header.label} className="cell-right">
										{t(`quotation.headers.${header.label}`)}
									</th>
								);
							default:
								return (
									<th key={header.label} className="cell-left">
										{t(`quotation.headers.${header.label}`)}
									</th>
								);
						}
					})}
				</tr>
			</thead>

			<tbody>
				{rows.map((row) => (
					<QuotationGridStandardRow
						columnsHeadersToDisplay={columnsHeaders}
						currencyName={currencyName}
						currencySymbol={currencySymbol}
						defaultValues={defaultValues}
						formDisabled={formDisabled}
						isLoading={isLoading}
						key={`tr.${row.id}`}
						offers={offers}
						readOnly={readOnly}
						row={row}
						rowItems={rowItems}
						vatRates={vatRates}
						// functions
						updateRow={updateRow}
						setTotals={setTotals}
						// duplicate logic
						originalRowData={row.id === duplicateRowId ? originalRowData : undefined}
					/>
				))}
			</tbody>
		</table>
	);
};

QuotationGridStandard.propTypes = {
	columnsHeaders: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			show: PropTypes.bool,
		}),
	).isRequired,
	currencyName: PropTypes.string,
	currencySymbol: PropTypes.string,
	defaultValues: PropTypes.object,
	isLoading: PropTypes.bool,
	offers: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.bool,
		}),
	).isRequired,
	readOnly: PropTypes.bool,
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			column: PropTypes.string,
			value: PropTypes.string,
		}),
	).isRequired,
	vatRates: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	// functions
	updateRow: PropTypes.func.isRequired,
	setTotals: PropTypes.func.isRequired,
	// duplicate logic
	duplicateRowId: PropTypes.string,
	originalRowData: PropTypes.object,
};

QuotationGridStandard.defaultProps = {
	currencyName: '',
	currencySymbol: '',
	defaultValues: undefined,
	isLoading: true,
	readOnly: false,
	duplicateRowId: undefined,
	originalRowData: undefined,
};

export default QuotationGridStandard;
