import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import { InvoiceColumnHeader, InvoiceMethods } from 'constants/invoiceEnums';
import { convertStringToFloat } from 'lib/shared/conversion';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';

import { invoiceColumnsHeaders as getColumnHeader } from './functions';
import InvoiceActionBar from './InvoiceActionBar';
import InvoiceTitle from './InvoiceTitle';

/**
 * @name InvoiceVisualisation
 * @description A form used to edit an existing invoice's report.
 *
 * @author Romaric Barthe
 *
 * @param {object}		invoice		The invoice object to update information from.
 */
const InvoiceVisualisation = ({ invoice }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const columnsHeaders = useMemo(() => getColumnHeader(invoice.method), [invoice.method]);

	useEffect(() => {
		dispatch(fetchNumberFormatList());
	}, [dispatch]);

	const numberFormat = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.INVOICE);
	const numberFormatForInvoice = useMemo(() => (numberFormat.length === 1 ? numberFormat[0] : {}), [numberFormat]);

	const rows = useMemo(() => Object.keys(invoice.rows).map((id) => invoice.rows[id]).sort((a, b) => a.line - b.line), [invoice.rows]);

	return (
		<div className="visualisation">
			<InvoiceTitle invoice={invoice} numberFormat={numberFormatForInvoice} />

			<InvoiceActionBar invoice={invoice} />

			<div className="contents">
				<table>
					<thead>
						<tr>
							{columnsHeaders.filter(
								(elem) => elem.show
									&& elem.label !== InvoiceColumnHeader[invoice.method].ACTIONS
									&& elem.label !== InvoiceColumnHeader[invoice.method].LINE
							).map((header) => {
								switch (header.label) {
									case InvoiceColumnHeader[invoice.method].ALREADY_INVOICED:
									case InvoiceColumnHeader[invoice.method].BASE_AMOUNT:
									case InvoiceColumnHeader[invoice.method].CURRENTLY_INVOICED:
									case InvoiceColumnHeader[invoice.method].QUANTITY:
									case InvoiceColumnHeader[invoice.method].TOTAL:
									case InvoiceColumnHeader[invoice.method].UNITARY_COST:
										return (
											<th key={header.label} className="right">
												{t(`invoice.headers.${header.label}`)}
											</th>
										);
									default:
										return (
											<th key={header.label} className="left">
												{t(`invoice.headers.${header.label}`)}
											</th>
										);
								}
							})}
						</tr>
					</thead>

					<tbody>
						{rows.map((row) => (
							<tr key={`tr.${row.id}`}>
								{columnsHeaders.filter(
									(elem) => elem.show
									&& elem.label !== InvoiceColumnHeader[invoice.method].ACTIONS
								).map((header) => {
									switch (header.label) {
										case InvoiceColumnHeader[invoice.method].ACCOUNT:
											return (<td key={`td.${row.id}.${header.label}`}>{(row.account?.name ?? '')}</td>);
										case InvoiceColumnHeader[invoice.method].DESIGNATION:
											return (<td key={`td.${row.id}.${header.label}`}>{row.designation ?? ''}</td>);
										case InvoiceColumnHeader[invoice.method].OFFER:
											return (<td key={`td.${row.id}.${header.label}`}>{row.offer?.name ?? ''}</td>);
										case InvoiceColumnHeader[invoice.method].TOTAL:
											// eslint-disable-next-line no-case-declarations
											let totalRow = 0;
											if (invoice.method === InvoiceMethods.UNITARY) {
												totalRow = row.quantity && row.price && row.quantity !== null && row.price !== null ? (
													convertStringToFloat(row.quantity) * convertStringToFloat(row.price))
													* (1 - ((convertStringToFloat(row.discount) ?? 0) / 100)) : '';
											}
											if (invoice.method === InvoiceMethods.ADVANCEMENT) {
												totalRow = row.baseAmount && row.baseAmount !== null ? (
													convertStringToFloat(row.baseAmount) * (convertStringToFloat(row.currentlyInvoiced) / 100))
													* (1 - ((convertStringToFloat(row.discount) ?? 0) / 100)) : '';
											}

											return (
												<td key={`td.${row.id}.${header.label}`} className="subtotal">
													{totalRow !== ''
														? formatNumber(totalRow.toFixed(2), { symbol: t(`currency.${invoice.currency?.name}`) })
														: ''}
												</td>
											);
										case InvoiceColumnHeader[invoice.method].QUANTITY:
											return (
												<td key={`td.${row.id}.${header.label}`} className="number">
													{row.quantity !== null ? formatNumber(row.quantity) : ''}
												</td>
											);
										case InvoiceColumnHeader[invoice.method].BASE_AMOUNT:
											return (
												<td key={`td.${row.id}.${header.label}`} className="number">
													{row.baseAmount !== null ? formatNumber(row.baseAmount, { symbol: t(`currency.${invoice.currency.name}`) }) : ''}
												</td>
											);
										case InvoiceColumnHeader[invoice.method].PRICE:
											return (
												<td key={`td.${row.id}.${header.label}`} className="number">
													{row.price !== null ? formatNumber(row.price, { symbol: t(`currency.${invoice.currency.name}`) }) : ''}
												</td>
											);
										case InvoiceColumnHeader[invoice.method].DISCOUNT:
											return (
												<td key={`td.${row.id}.${header.label}`} className="number">
													{row.discount !== 0 ? formatNumber(row.discount, { symbol: '%' }) : ''}
												</td>
											);
										case InvoiceColumnHeader[invoice.method].ALREADY_INVOICED:
											return (
												<td key={`td.${row.id}.${header.label}`} className="number">
													{row.alreadyInvoiced !== null ? formatNumber(row.alreadyInvoiced, { symbol: '%' }) : ''}
												</td>
											);
										case InvoiceColumnHeader[invoice.method].CURRENTLY_INVOICED:
											return (
												<td key={`td.${row.id}.${header.label}`} className="number">
													{row.currentlyInvoiced !== null ? formatNumber(row.currentlyInvoiced, { symbol: '%' }) : ''}
												</td>
											);
										case InvoiceColumnHeader[invoice.method].VAT_RATE:
											return (
												<td key={`td.${row.id}.${header.label}`} className="number">
													{row.vatRate !== null ? formatNumber(row.vatRate?.rate, { symbol: '%' }) : ''}
												</td>
											);
										default:
											return ('');
									}
								})}
							</tr>
						))}
					</tbody>

					<tfoot>
						<tr>
							{columnsHeaders.filter(
								(elem) => elem.show
									&& elem.label !== InvoiceColumnHeader[invoice.method].ACTIONS
							).map((header) => {
								switch (header.label) {
									case InvoiceColumnHeader[invoice.method].TOTAL:
										return (
											<td key={`total.${header.label}`} className="total">
												{invoice.grossTotal && formatNumber(invoice.grossTotal, { symbol: t(`currency.${invoice.currency.name}`) })}
											</td>
										);
									default:
										return (<td key={`total.${header.label}`}> </td>);
								}
							})}
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	);
};

InvoiceVisualisation.propTypes = {
	invoice: PropTypes.shape({

		archived: PropTypes.bool,
		editionNumber: PropTypes.number,
		comment: PropTypes.string,
		currency: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		discount: PropTypes.number,
		discountOnTotal: PropTypes.number,
		id: PropTypes.string.isRequired,
		grossTotal: PropTypes.number,
		grossTotalWithDiscount: PropTypes.number,
		method: PropTypes.oneOf(
			Object.values(InvoiceMethods)
		).isRequired,
		netTotal: PropTypes.number,
		reference: PropTypes.string,
		reversedInvoice: PropTypes.shape({
			id: PropTypes.string,
			editionNumber: PropTypes.number,
		}),
		rows: PropTypes.shape({
			id: PropTypes.string,
			offer: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
			vatRate: PropTypes.shape({
				id: PropTypes.string,
				rate: PropTypes.number,
			}),
		}).isRequired,
		structure: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		vatTotal: PropTypes.number,
	}).isRequired,
};

export default InvoiceVisualisation;
