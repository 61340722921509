import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TabHeader, TabPanel } from '../toolbox/tabLayout';

import { ExcludeFooterFromPages, ExcludeHeaderFromPages, FormatHeader, Page } from './components';

/**
 * @constant
 * @name AvailableTabs
 * @description An enumeration of the tabs avaible to the user when the underlying component is shown.
 *
 * @author Florian Fornazaric
 */
const AvailableTabs = Object.freeze({
	HEADER: 'HEADER',
	FOOTER: 'FOOTER',
	PAGE: 'PAGE',
});

/**
 * @name EditorFormat
 * @description A component to edit the format of a template
 *
 * @author Florian Fornazaric
 */
const EditorFormat = () => {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState(false);

	const [selectedTab, setSelectedTab] = useState(AvailableTabs.PAGE);

	const toggleExpansion = useCallback(() => setExpanded((value) => !value), []);

	return (
		<div className={`format${expanded ? ' expanded' : ''}`}>
			<FormatHeader onClick={toggleExpansion} />
			{expanded && (
				<div className="content">
					<div role="tablist">
						<TabHeader
							id="page-tab"
							linkedPanelId="page-panel"
							selected={selectedTab === AvailableTabs.PAGE}
							onClick={() => setSelectedTab(AvailableTabs.PAGE)}
						>
							{t('template.pdf.format.page_tab')}
						</TabHeader>
						<TabHeader
							id="regular-tab"
							linkedPanelId="header-panel"
							selected={selectedTab === AvailableTabs.HEADER}
							onClick={() => setSelectedTab(AvailableTabs.HEADER)}
						>
							{t('template.pdf.format.header_tab')}
						</TabHeader>
						<TabHeader
							id="footer-tab"
							linkedPanelId="footer-panel"
							selected={selectedTab === AvailableTabs.FOOTER}
							onClick={() => setSelectedTab(AvailableTabs.FOOTER)}
						>
							{t('template.pdf.format.footer_tab')}
						</TabHeader>
					</div>
					<TabPanel
						id="page-panel"
						linkedTabId="page-tab"
						hidden={selectedTab !== AvailableTabs.PAGE}
					>
						<Page />
					</TabPanel>
					<TabPanel
						id="header-panel"
						linkedTabId="header-tab"
						hidden={selectedTab !== AvailableTabs.HEADER}
					>
						<ExcludeHeaderFromPages />
					</TabPanel>
					<TabPanel
						id="footer-panel"
						linkedTabId="footer-tab"
						hidden={selectedTab !== AvailableTabs.FOOTER}
					>
						<ExcludeFooterFromPages />
					</TabPanel>
				</div>
			)}
		</div>
	);
};

export default EditorFormat;
