import { forwardRef } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

/**
 * @name Link
 * @description Renders a link to an internal URL, by providing keys path to the route.
 *
 * @author Yann Hodiesne
 *
 * @param {string} to  key of the route to target with this Link
 */
const Link = forwardRef(({ to, ...props }, ref) => (
	<ReactRouterLink
		className="styled-link"
		ref={useForwardedRef(ref)}
		to={to}
		{...props}
	/>
));

Link.propTypes = {
	to: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
		PropTypes.func,
	]),
};

Link.defaultProps = {
	to: '',
};

Link.displayName = 'Link';

export default Link;
