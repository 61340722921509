import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchProject, updateProject } from 'redux/actions/projects/projects';
import { useCurrentProjectSelector } from 'redux/selectors/projects/projects';
import routes from 'routes';

import { ProjectEditionForm } from 'components/projects';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name ProjectEditionPage
 * @description A page used to edit an existing project.
 *
 * @author Marin Catel-Guihomat
 *
 */
const ProjectEditionPage = () => {
	const { id: projectId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const project = useCurrentProjectSelector(projectId);

	const canEditProject = useAccessRight(AccessRights.operations.projects.enhancedRights.CREATE_PROJECT) && !project?.archived;

	useEffect(() => {
		dispatch(fetchProject(projectId));
	}, [dispatch, projectId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Marin Catel-Guihomat
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditProject) {
			dispatch(updateProject(formData, projectId));
		} else {
			redirectOnSuccess(routes.operations.projects.list);
		}
	}, [canEditProject, dispatch, projectId]);

	return (
		project
			? <ProjectEditionForm project={project} onSubmit={handleFormSubmit} />
			: <p>{t('projects.edition.loading')}</p>
	);
};

export default ProjectEditionPage;
