import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchOffer, updateOffer } from 'redux/actions/offers/offers';
import { useCurrentOfferSelector } from 'redux/selectors/offers/offers';
import routes from 'routes';

import { OfferEditionForm } from 'components/offers';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name OfferEditionPage
 * @description A page used to edit an existing offer.
 *
 * @author Audrey Clerc
 * @author Timothée Simon-Franza
 * @author Matthieu Schaerlinger
 */
const OfferEditionPage = () => {
	const { id: offerId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const offer = useCurrentOfferSelector(offerId);

	const canEditOffer = useAccessRight(AccessRights.sales.offers.enhancedRights.CREATE_OFFER) && !offer?.archived;

	useEffect(() => {
		dispatch(fetchOffer(offerId));
	}, [dispatch, offerId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData             The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditOffer) {
			const offerData = {
				...formData,
				rawMaterials: [],
				finalProducts: [],
			};
			dispatch(updateOffer(offerData, offerId, routes.sales.offers.list));
		} else {
			redirectOnSuccess(routes.sales.offers.list);
		}
	}, [canEditOffer, dispatch, offerId]);

	return (
		offer
			? <OfferEditionForm offer={offer} onSubmit={handleFormSubmit} />
			: <p>{t('offer.edition.loading')}</p>
	);
};

export default OfferEditionPage;
