import { useTranslation } from 'react-i18next';
import { tagGroupments } from 'constants/tagEnums';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { HiddenInput, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name ContactTitleTagCreationForm
 * @description A form used to create a new contact title tag.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit        The method to trigger upon form submission.
 */
const ContactTitleTagCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm onSubmit={onSubmit} {...formProps}>
			<HiddenInput
				name="groupment"
				value={tagGroupments.CONTACT_TITLE}
			/>
			<TextInput
				label={t('contact.tag.creation.inputs.title.label')}
				name="name"
				rules={{
					required: Validators.isRequired(t('contact.tag.creation.inputs.title.validation_errors.required')),
				}}
				type="text"
			/>

			<Button className="primary" type="submit" {...buttonProps}>{t('contact.tag.creation.title.action')}</Button>
		</DynamicForm>
	);
};

ContactTitleTagCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default ContactTitleTagCreationForm;
