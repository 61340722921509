import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { QuotationCreationTypes, QuotationTypes } from 'constants/quotationEnums';
import update from 'immutability-helper';
import { getCurrencyName } from 'lib/currencies/formatCurrencyData';
import { formatQuotationName } from 'lib/quotations/formatQuotationData';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { addProject } from 'redux/actions/projects/projects';
import { fetchAllForQuotation } from 'redux/actions/quotations/quotations';
import { addUser } from 'redux/actions/users';
import { addVatRate } from 'redux/actions/vatRates';
import { useAllForQuotationSelector, useQuotationLoadingSelector } from 'redux/selectors/quotations/quotations';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import { v4 as uuid } from 'uuid';

import { ProjectCreationForm } from 'components/projects';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Collapsible } from 'components/shared/layout';
import { sumOneObjectPropertyInArray } from 'components/shared/utils/functions';
import { UserCreationForm } from 'components/users';
import { VatRateCreationForm } from 'components/vatRates';

import QuotationGridArchi from './quotationArchi/QuotationGridArchi';
import QuotationHeader from './quotationCommon/QuotationHeader';
import QuotationTotalsTable from './quotationCommon/QuotationTotalsTable';
import QuotationGridStandard from './quotationStandard/QuotationGridStandard';
import {
	getEnrichedStakeholdersInfo,
	getOfferTotals,
	getQuotationTypes,
	quotationColumnsHeaders as getColumnsHeaders,
} from './functions';

/**
 * @name QuotationCreationForm
 * @description A form used to create a quotation.
 *
 * @author Roland Margelidon
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit		The method to trigger upon form submission.
 * @param {string}	creationType	The type of creation.
 * @param {string}	quotationParent	The quotation from which data could be initialized.
 */
const QuotationCreationForm = ({ onSubmit, creationType, quotationParent }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForQuotationForm = useAllForQuotationSelector();
	const companies = useMemo(() => allForQuotationForm?.companies ?? [], [allForQuotationForm]);
	const currencies = useMemo(() => allForQuotationForm?.currencies ?? [], [allForQuotationForm]);
	const currentUser = useCurrentConnectedUserSelector();
	const enabledUsers = useMemo(() => allForQuotationForm?.enabledUsers ?? [], [allForQuotationForm]);
	const numberFormat = useMemo(() => allForQuotationForm?.numberFormat, [allForQuotationForm]);
	const offers = useMemo(() => allForQuotationForm?.unarchivedOffers ?? [], [allForQuotationForm]);
	const partnersWithSector = useMemo(() => allForQuotationForm?.partnersWithSector ?? [], [allForQuotationForm]);
	const projects = useMemo(() => allForQuotationForm?.unarchivedProjects ?? [], [allForQuotationForm]);
	const quotation = useMemo(() => allForQuotationForm?.quotation, [allForQuotationForm]);
	const quotationTypes = useMemo(() => getQuotationTypes(t), [t]);
	const sectors = useMemo(() => allForQuotationForm?.unarchivedSectors ?? [], [allForQuotationForm]);
	const vatRates = useMemo(() => allForQuotationForm?.unarchivedVatRates ?? [], [allForQuotationForm]);

	const isLoading = useQuotationLoadingSelector();

	const [currency, setCurrency] = useState();
	const currencyName = useMemo(() => getCurrencyName(currencies, currency), [currencies, currency]);
	const currencySymbol = useMemo(() => ((currencyName && currencyName !== '') ? t(`currency.${currencyName}`) : ''), [currencyName, t]);

	const [quotationTypeSelected, setQuotationTypeSelected] = useState();
	const columnsHeaders = useMemo(() => getColumnsHeaders(quotationTypeSelected), [quotationTypeSelected]);

	const favoriteVatRate = useMemo(() => {
		if (vatRates && vatRates.length > 0) {
			const favoriteVatRateArray = vatRates.filter((vat) => vat.favorite);

			return favoriteVatRateArray.length === 1 ? favoriteVatRateArray[0] : {};
		}

		return {};
	}, [vatRates]);

	const [rows, setRows] = useState([]);
	const sortedRows = useMemo(() => rows.sort((a, b) => a.line - b.line), [rows]);

	const [stakeholders, setStakeholders] = useState([]);
	const sortedStakeholders = useMemo(() => stakeholders.sort((a, b) => a.column - b.column), [stakeholders]);

	const [duplicateRowId, setDuplicateRowId] = useState();
	const [originalRowData, setOriginalRowData] = useState();
	const [projectAmount, setProjectAmount] = useState(0);
	const [globalVatRate, setGlobalVatRate] = useState(0);
	const globalVatRateValue = vatRates ? vatRates.filter((v) => v.id === globalVatRate)[0]?.rate : 0;

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const [discountTotal, setDiscountTotal] = useState(0);
	const [globalRate, setGlobalRate] = useState(0);
	const [globalRateWithExtra, setGlobalRateWithExtra] = useState(0);
	const [grossTotal, setGrossTotal] = useState(0);
	const [grossTotalWithDiscount, setGrossTotalWithDiscount] = useState(0);
	const [netTotal, setNetTotal] = useState(0);
	const [vatTotal, setVatTotal] = useState(0);

	const [offersTotals, setOffersTotals] = useState([]);
	const [stakeholdersTotals, setStakeholdersTotals] = useState([]);

	const avenant = useMemo(() => (quotation && creationType === QuotationCreationTypes.VERSION
		? formatQuotationName(quotation, numberFormat)
		: undefined), [creationType, numberFormat, quotation]);

	// !!Only for Header: Props to pass down to the select input so that it will display ancreation modal when clicking on the "+" icon.
	const projectCreationModal = useFormModal(ProjectCreationForm, t('quotation.edition.inputs.project.creation'), fetchAllForQuotation, addProject);
	const userCreationModal = useFormModal(UserCreationForm, t('expense.edition.inputs.user.creation'), fetchAllForQuotation, addUser);
	const vatRateCreationModal = useFormModal(VatRateCreationForm, t('quotation.edition.inputs.vat_rate.creation'), fetchAllForQuotation, addVatRate);

	/**
	 * @function
	 * @name setTotals
	 * @description A function to refresh Total variables.
	 *
	 * @author Romaric Barthe
	 */
	const setTotals = useCallback(() => {
		const formData = formRef.current?.getFormValues();
		if (quotationTypeSelected === QuotationTypes.ARCHI) {
			setStakeholdersTotals(getEnrichedStakeholdersInfo(formData.stakeholders, formData.rows));
		} else if (quotationTypeSelected === QuotationTypes.STANDARD) {
			setOffersTotals(getOfferTotals(formData?.rows, vatRates));
		}
	}, [quotationTypeSelected, formRef, vatRates]);

	/**
	 * @function
	 * @name updateColumn
	 * @description A function to add/ move/ delete/ duplicate a row.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	columnId		The id of the column triggering the action.
	 * @param {number}	columnNb		The number of the column triggering the action.
	 * @param {number}	direction		The direction if move: positive is for down, negative is for up.
	 * @param {string}	action			The possible action: 'add', 'move', 'duplicate', 'select'.
	 */
	const updateColumn = useCallback((action, columnId = undefined, columnNb = undefined, direction = undefined) => {
		if (action === 'add') {
			const newId = uuid();
			const columns = stakeholders.map((stakeholder) => stakeholder?.column) ?? [];
			const newColumnNumber = _.isEmpty(columns) ? 1 : Math.max(...columns) + 1;
			setStakeholders([...stakeholders, { id: newId, column: newColumnNumber, partnerProjectAmount: projectAmount }]);
		} else {
			if (stakeholders.findIndex((stakeholder) => stakeholder.id === columnId) === undefined) {
				throw new Error('An issue occured with the current lines. Please refresh the page.');
			}
			if (action === 'move') {
				const columnIndexToMoveInDirection = stakeholders.findIndex((stakeholder) => stakeholder.id === columnId);
				const columnIndexToMoveInOtherDirection = stakeholders.findIndex((stakeholder) => stakeholder.column === columnNb + direction);
				setStakeholders(update(stakeholders, {
					[columnIndexToMoveInDirection]: {
						column: { $set: columnNb + direction },
					},
					[columnIndexToMoveInOtherDirection]: {
						column: { $set: columnNb },
					},
				}));
			}
			if (action === 'delete') {
				const columnIndexToRemove = stakeholders.findIndex((stakeholder) => stakeholder.id === columnId);
				setStakeholders(update(stakeholders, {
					$splice: [[columnIndexToRemove, 1]],
					$apply: (newColumns) => newColumns.sort((a, b) => a.column - b.column).map((r, i) => update(r, { column: { $set: i + 1 } })),
				}));
				setStakeholdersTotals(update(stakeholdersTotals, {
					$apply: (newStakeholders) => newStakeholders.filter((stakeholder) => stakeholder.id !== columnId),
				}));
			}
		}
	}, [projectAmount, stakeholders, stakeholdersTotals]);

	/**
	 * @function
	 * @name updateRow
	 * @description A function to add/ move/ delete/ duplicate a row.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	rowId			The id of the row triggering the action.
	 * @param {number}	lineNb			The number of the row triggering the action.
	 * @param {number}	direction		The direction if move: positive is for down, negative is for up.
	 * @param {string}	action			The possible action: 'add', 'move', 'duplicate', 'select'.
	 */
	const updateRow = useCallback((action, rowId = undefined, lineNb = undefined, direction = undefined) => {
		if (action === 'add') {
			const newId = uuid();
			const lines = rows.map((row) => row?.line) ?? [];
			const newRowNumber = _.isEmpty(lines) ? 1 : Math.max(...lines) + 1;
			setRows([...rows, { id: newId, line: newRowNumber }]);
		} else {
			if (rows.findIndex((row) => row.id === rowId) === undefined) {
				throw new Error('An issue occured with the current lines. Please refresh the page.');
			}
			if (action === 'move') {
				const rowIndexToMoveInDirection = rows.findIndex((row) => row.id === rowId);
				const rowIndexToMoveInOtherDirection = rows.findIndex((row) => row.line === lineNb + direction);
				setRows(update(rows, {
					[rowIndexToMoveInDirection]: {
						line: { $set: lineNb + direction },
					},
					[rowIndexToMoveInOtherDirection]: {
						line: { $set: lineNb },
					},
				}));
			}
			if (action === 'delete') {
				const rowIndexToRemove = rows.findIndex((row) => row.id === rowId);
				setRows(update(rows, {
					$splice: [[rowIndexToRemove, 1]],
					$apply: (newRows) => newRows.sort((a, b) => a.line - b.line).map((r, i) => update(r, { line: { $set: i + 1 } })),
				}));
				setStakeholdersTotals(update(stakeholdersTotals, {
					$apply: (newStakeholders) => newStakeholders.map((stakeholder) => {
						const filteredDetails = stakeholder.details.filter((detail) => detail.rowId !== rowId);

						return update((stakeholder), {
							details: { $set: filteredDetails },
						});
					}),
				}));
			}
			if (action === 'duplicate') {
				const newId = uuid();
				setRows(update(rows, {
					$apply: (newRows) => newRows.map((r) => (r.line > lineNb ? update(r, { line: { $set: r.line + 1 } }) : r)),
					$push: [{
						id: newId,
						line: lineNb + 1,
					}],
				}));
				const formData = formRef.current.getFormValues();
				setOriginalRowData(formData.rows[rowId]);
				setDuplicateRowId(newId);
			}
		}
	}, [formRef, rows, stakeholdersTotals]);

	// Fetch data
	useEffect(() => {
		dispatch(fetchAllForQuotation(quotationParent));
	}, [dispatch, quotationParent]);

	// Set default values
	const defaultValues = useMemo(() => {
		if (!quotation || creationType === QuotationCreationTypes.EMPTY) {
			if (quotationTypeSelected === QuotationTypes.ARCHI) {
				const mainPartner = currentUser?.company?.mainPartner ?? null;
				const mainSectors = mainPartner?.sectors ?? null;
				const mainSector = mainSectors.length > 0 ? mainSectors[0] : null;

				return ({
					rows: { newLine: { line: 1, id: 'newLine', stakeholders: { newStakeholder: { column: 1, id: 'newStakeholder' } } } },
					stakeholders: {
						newStakeholder: {
							column: 1,
							id: 'newStakeholder',
							partner: mainPartner?.id,
							sector: mainSector?.id,
						},
					},
					currency: currentUser?.company?.mainPartner?.currency?.id || '',
					structure: currentUser?.company?.mainPartner?.id || null,
					discountTotal: 0,
					grossTotal: 0,
					globalRate: 0,
					globalRateWithExtra: 0,
					grossTotalWithDiscount: 0,
					netTotal: 0,
					quotationType: '',
					vatTotal: 0,
					vatRate: favoriteVatRate?.id || null,
				});
			}

			return ({
				rows: { newLine: { line: 1, id: 'newLine' } },
				currency: currentUser?.company?.mainPartner?.currency?.id || '',
				structure: currentUser?.company?.mainPartner?.id || null,
				discountTotal: 0,
				grossTotal: 0,
				globalRate: 0,
				globalRateWithExtra: 0,
				grossTotalWithDiscount: 0,
				netTotal: 0,
				quotationType: '',
				vatTotal: 0,
				vatRate: favoriteVatRate?.id || null,
			});
		}

		const flatStakeholders = quotation.stakeholders ? Object.fromEntries(update(quotation.stakeholders, {
			$apply: (newStakeholders) => Object.entries(newStakeholders).map((stakeholder) => update(stakeholder, {
				$apply: (newStakeholder) => update(newStakeholder, {
					1: {
						partner: { $set: newStakeholder[1].partner?.id },
						sector: { $set: newStakeholder[1].sector?.id },
					},
				}),
			})),
		})) : undefined;

		const flatRows = quotation.rows ? Object.fromEntries(update(quotation.rows, {
			$apply: (newRows) => Object.entries(newRows).map((row) => update(row, {
				$apply: (newRow) => update(newRow, {
					1: {
						offer: { $set: newRow[1].offer?.id },
						vatRate: { $set: newRow[1].vatRate?.id },
					},
				}),
			})),
		})) : undefined;

		return ({
			...quotation,
			currency: quotation.currency?.id ?? null,
			discountTotal: quotation.discountTotal ?? 0,
			grossTotalWithDiscount: quotation.grossTotalWithDiscount ?? quotation.grossTotal,
			project: creationType === QuotationCreationTypes.VERSION && quotation.project ? quotation.project.id : null,
			quotationParent: creationType === QuotationCreationTypes.VERSION ? quotation.id : null,
			rows: flatRows,
			seller: quotation.seller?.id ?? null,
			stakeholders: flatStakeholders,
			structure: quotation.structure?.id ?? null,
			vatRate: quotationTypeSelected === QuotationTypes.ARCHI ? quotation.vatRate?.id : null,
		});
	}, [creationType, currentUser, quotation, quotationTypeSelected, favoriteVatRate]);

	// Set state's variables
	useEffect(() => {
		setCurrency(defaultValues?.currency);
		setDiscountTotal(defaultValues?.discountTotal);
		setGlobalRate(defaultValues?.globalRate);
		setGlobalRateWithExtra(defaultValues?.globalRateWithExtra);
		setGlobalVatRate(defaultValues?.vatRate);
		setGrossTotal(defaultValues?.grossTotal);
		setGrossTotalWithDiscount(defaultValues?.grossTotalWithDiscount);
		setNetTotal(defaultValues?.netTotal);
		setProjectAmount(defaultValues?.projectAmount);
		setVatTotal(defaultValues?.vatTotal);
		setRows(defaultValues?.rows ? Object.values(defaultValues.rows) : []);
		setStakeholders(defaultValues?.stakeholders ? Object.values(defaultValues?.stakeholders) : []);
		setStakeholdersTotals(getEnrichedStakeholdersInfo(defaultValues?.stakeholders, defaultValues?.rows));
		setOffersTotals(getOfferTotals(defaultValues?.rows, vatRates));
	}, [defaultValues, vatRates]);
	useEffect(() => {
		setQuotationTypeSelected(quotation?.quotationType);
	}, [quotation]);

	// Refresh totals for archi quotation
	useEffect(() => {
		if (quotationTypeSelected === QuotationTypes.ARCHI) {
			const columnTotals = stakeholdersTotals.map((stakeholderTotals) => {
				const stakeholderDetails = stakeholderTotals.details && stakeholderTotals.details.filter(
					(f) => f.columnId === stakeholderTotals.id && f.extra && f.rate !== null && f.rate !== 0
				);
				const extra = stakeholderDetails && stakeholderDetails.length >= 0 ? sumOneObjectPropertyInArray(stakeholderDetails, 'rate') : 0;
				const grossTotalWithoutExtraOnTheFly = stakeholderTotals.partnerProjectAmount * stakeholderTotals.partnerRatio;
				const grossTotalOnTheFly = grossTotalWithoutExtraOnTheFly * (1 + extra / 100);
				const discountTotalOnTheFly = grossTotalOnTheFly * stakeholderTotals.partnerDiscount;
				const grossTotalWithDiscountOnTheFly = grossTotalOnTheFly - discountTotalOnTheFly;

				return ({
					id: stakeholderTotals.id,
					gtwe: grossTotalWithoutExtraOnTheFly ?? 0,
					gt: grossTotalOnTheFly ?? 0,
					dt: discountTotalOnTheFly ?? 0,
					gtd: grossTotalWithDiscountOnTheFly ?? 0,
				});
			});
			setGrossTotal(sumOneObjectPropertyInArray(columnTotals, 'gt'));
			setDiscountTotal(sumOneObjectPropertyInArray(columnTotals, 'dt'));
			setGrossTotalWithDiscount(sumOneObjectPropertyInArray(columnTotals, 'gtd'));
			setVatTotal(sumOneObjectPropertyInArray(columnTotals, 'gtd') * (globalVatRateValue / 100));
			setNetTotal(sumOneObjectPropertyInArray(columnTotals, 'gtd') * (1 + (globalVatRateValue / 100)));
			setGlobalRate(projectAmount !== 0 ? (sumOneObjectPropertyInArray(columnTotals, 'gtwe') * 100) / projectAmount : 0);
			setGlobalRateWithExtra(projectAmount ? (sumOneObjectPropertyInArray(columnTotals, 'gt') * 100) / projectAmount : 0);
		} else if (quotationTypeSelected === QuotationTypes.STANDARD) {
			setDiscountTotal(sumOneObjectPropertyInArray(offersTotals, 'dt'));
			setGrossTotal(sumOneObjectPropertyInArray(offersTotals, 'gt'));
			setGrossTotalWithDiscount(sumOneObjectPropertyInArray(offersTotals, 'gtd'));
			setNetTotal(sumOneObjectPropertyInArray(offersTotals, 'nt'));
			setVatTotal(sumOneObjectPropertyInArray(offersTotals, 'vt'));
		}
	}, [globalVatRateValue, offersTotals, projectAmount, quotationTypeSelected, stakeholdersTotals]);

	return (
		((quotationParent && quotation && defaultValues) || !quotationParent) && !isLoading
			? (
				<DynamicForm
					ref={formRef}
					cleanFormData
					defaultValues={defaultValues}
					onSubmit={onSubmit}
					{...formProps}
				>
					{avenant && (<h5>{`${t('quotation.fields.quotation_parent')}: ${avenant}`}</h5>)}

					<Collapsible titleKey="quotation.creation.collapsed.general_informations">
						<QuotationHeader
							creationType={creationType}
							currencies={currencies}
							currencyName={currencyName}
							isLoading={isLoading}
							projectCreationModal={projectCreationModal}
							projects={projects}
							quotationParent={quotationParent}
							quotationTypes={quotationTypes}
							quotationTypeSelected={quotationTypeSelected}
							structures={companies}
							userCreationModal={userCreationModal}
							users={enabledUsers}
							vatRateCreationModal={vatRateCreationModal}
							vatRates={vatRates}
							setCurrency={setCurrency}
							setGlobalVatRate={setGlobalVatRate}
							setProjectAmount={setProjectAmount}
							setQuotationTypeSelected={setQuotationTypeSelected}
						/>
					</Collapsible>

					{quotationTypeSelected !== null && quotationTypeSelected !== undefined && quotationTypeSelected !== '' && (
						<>
							<QuotationTotalsTable
								currencySymbol={currencySymbol}
								discountTotal={discountTotal}
								globalRate={globalRate}
								globalRateWithExtra={globalRateWithExtra}
								grossTotal={grossTotal}
								grossTotalWithDiscount={grossTotalWithDiscount}
								netTotal={netTotal}
								quotationTypeSelected={quotationTypeSelected}
								vatTotal={vatTotal}
							/>

							<Collapsible titleKey="quotation.creation.collapsed.grid">
								{quotationTypeSelected === 'standard' && (
									<QuotationGridStandard
										columnsHeaders={columnsHeaders}
										currencyName={currencyName}
										currencySymbol={currencySymbol}
										defaultValues={defaultValues}
										isLoading={isLoading}
										offers={offers}
										rows={sortedRows}
										vatRates={vatRates}
										// functions
										updateRow={updateRow}
										setTotals={setTotals}
										// duplicate logic
										duplicateRowId={duplicateRowId}
										originalRowData={originalRowData}
									/>
								)}

								{quotationTypeSelected === 'archi' && (
									<QuotationGridArchi
										currencyName={currencyName}
										currencySymbol={currencySymbol}
										defaultValues={defaultValues}
										isLoading={isLoading}
										offers={offers}
										partners={partnersWithSector}
										projectAmount={projectAmount}
										rows={sortedRows}
										sectors={sectors}
										stakeholders={sortedStakeholders}
										stakeholdersTotals={stakeholdersTotals}
										// functions
										updateColumn={updateColumn}
										updateRow={updateRow}
										setTotals={setTotals}
										// duplicate logic
										duplicateRowId={duplicateRowId}
										originalRowData={originalRowData}
									/>
								)}
								<Button className="secondary" type="button" onClick={() => updateRow('add')}>
									<PlusCircle />
									{t('quotation.creation.grid.add_row')}
								</Button>
							</Collapsible>

							<Button className="primary" type="submit" {...buttonProps}>{t('quotation.creation.action')}</Button>
						</>
					)}
				</DynamicForm>
			) : <p>{t('quotation.edition.loading')}</p>
	);
};

QuotationCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	creationType: PropTypes.string,
	quotationParent: PropTypes.string,
};

QuotationCreationForm.defaultProps = {
	quotationParent: null,
	creationType: undefined,
};

export default QuotationCreationForm;
