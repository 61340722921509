import { useMemo } from 'react';
import PropTypes from 'prop-types';

/**
 * @name TargetsCell
 * @description Custom cell for the partnerListPage table displaying a partner's relation types.
 *
 * @author Audrey Clerc
 */
const TargetsCell = ({ row: { original: { targetPartners } } }) => {
	const targets = useMemo(() => targetPartners.map((targetPartner) => targetPartner.target), [targetPartners]);

	return (
		<ul className="tag-list">
			{targets.map(({ id, name }) => (
				<li key={id}>
					<span>
						{name}
					</span>
				</li>
			))}
		</ul>
	);
};

TargetsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			targetPartners: PropTypes.arrayOf(PropTypes.shape({
				target: PropTypes.shape({
					id: PropTypes.string.isRequired,
					name: PropTypes.string,
				}),
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default TargetsCell;
