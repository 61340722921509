import * as ExpensesApi from 'api/expensesApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Expenses redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of expenses
	FETCH_EXPENSE_SELECT_LIST_REQUEST: '@EXPENSES_SELECT/FETCH_LIST_REQUEST',
	FETCH_EXPENSE_SELECT_LIST_SUCCESS: '@EXPENSES_SELECT/FETCH_LIST_SUCCESS',
	FETCH_EXPENSE_SELECT_LIST_FAILURE: '@EXPENSES_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Expense list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchExpensesSelectListRequest
 * @description Action triggered anytime a expensesSelect list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchExpensesSelectListRequest = () => ({ type: ActionTypes.FETCH_EXPENSE_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchExpensesSelectListSuccess
 * @description Action triggered as a result to a successful expensesSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	expensesSelect	The list of retrieved expenses.
 * @param {number}	totalCount		The total amount of expenses available in the database to the current user.
 *
 * @returns {object}
 */
const fetchExpensesSelectListSuccess = ({ expensesSelect, totalCount }) => ({
	type: ActionTypes.FETCH_EXPENSE_SELECT_LIST_SUCCESS,
	payload: { expensesSelect, totalCount },
});

/**
 * @function
 * @name fetchExpensesSelectListFailure
 * @description Action triggered as a result to a failed expensesSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchExpensesSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_EXPENSE_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchExpensesSelectList
 * @description Method used to update the expensesSelect list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchExpensesSelectList = (filters) => (dispatch) => {
	dispatch(fetchExpensesSelectListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forSelect',
	};

	return ExpensesApi.fetchExpenses(newFilters)
		.then(({ expensesSelect, totalCount }) => dispatch(fetchExpensesSelectListSuccess({ expensesSelect, totalCount })))
		.catch((error) => dispatch(fetchExpensesSelectListFailure(error)));
};
