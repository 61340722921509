import update from 'immutability-helper';
import _ from 'lodash';

/**
 * @function
 * @name removeEntity
 * @description Removes an existing entity by its ID
 *
 * @author Yann Hodiesne
 *
 * @param {object}	state			The current state
 * @param {object}	payload			The payload of the action
 * @param {string}	payload.id		The ID of the entity to remove
 *
 * @returns {object} The updated state value
 */
const removeEntity = (state, { id }) => {
	const { entities } = state;

	const index = _.findIndex(entities, (entity) => entity.id === id);

	if (index === -1) {
		return state;
	}

	const result = {
		...state,
		entities: update(entities, {
			$splice: [
				[index, 1],
			],
		}),
		isDirty: true,
	};

	return result;
};

export default removeEntity;
