import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentTargetPartnerSelector
 * @description A selector to retrieve the current target partner from the target partner redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the current target partner to wait for
 *
 * @returns {object | null}
 */
const useCurrentTargetPartnerSelector = (id) => {
	const targetPartner = useSelector((state) => (state.targetPartners?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (targetPartner?.id === id ? targetPartner : null), [id, targetPartner]);

	return result;
};

/**
 * @function
 * @name useTargetPartnerListSelector
 * @description A selector to retrieve the target partner list from the target partner redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTargetPartnerListSelector = () => {
	const targetPartners = useSelector((state) => (state.targetPartners?.items ?? []), shallowEqual);

	return targetPartners;
};

/**
 * @function
 * @name useTargetPartnerTotalCountSelector
 * @description A selector to retrieve the totalCount value from the target partner redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useTargetPartnerTotalCountSelector = () => useSelector((state) => (state.targetPartners?.totalCount ?? undefined), shallowEqual);

/**
 * @function
 * @name useTargetPartnerLoadingSelector
 * @description A selector to retrieve the loading state from the target partner redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useTargetPartnerLoadingSelector = () => useSelector((state) => state.targetPartners?.isLoading ?? false);

export {
	useCurrentTargetPartnerSelector,
	useTargetPartnerListSelector,
	useTargetPartnerLoadingSelector,
	useTargetPartnerTotalCountSelector,
};
