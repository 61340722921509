import { useTranslation } from 'react-i18next';

import { DateInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name ProjectTempo
 * @description A form used to group rhe time range fields.
 *
 * @author Romaric Barthe
 */
const ProjectTempo = () => {
	const { t } = useTranslation();

	return (
		<>
			<DateInput
				allowNull
				label={t('projects.edition.inputs.valid_from.label')}
				name="from"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
			<DateInput
				allowNull
				label={t('projects.edition.inputs.valid_until.label')}
				name="to"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
			<DateInput
				allowNull
				label={t('projects.edition.inputs.valid_on.label')}
				name="on"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
		</>
	);
};

export default ProjectTempo;
