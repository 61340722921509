/* eslint-disable import/prefer-default-export */
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useDashboardValues
 * @description A selector to retrieve the dashboard values from the dashboard redux state
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const useDashboardValues = () => useSelector((state) => (state.dashboard?.items ?? {}), shallowEqual);

export {
	useDashboardValues,
};
