/**
 * @constant
 * @name EntityFieldTypes
 * @description An enumeration of all the entity field types handled by the editor
 *
 * @author Matthieu Schaerlinger
 * @author Yann Hodiesne
 */
const EntityFieldTypes = Object.freeze({
	STRING: 'string',
	NUMBER: 'number',
	DATE: 'date',
	ARRAY: 'array',
});

export const ContentFieldTypes = [EntityFieldTypes.STRING, EntityFieldTypes.NUMBER, EntityFieldTypes.DATE];
export const TableFieldTypes = [EntityFieldTypes.ARRAY];

export default EntityFieldTypes;
