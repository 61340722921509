import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentRegistrationSelector
 * @description A selector to retrieve the current registration from the registration redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the current registration to wait for
 *
 * @returns {object}
 */
const useCurrentRegistrationSelector = (id) => {
	const registration = useSelector((state) => (state.registrations?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (registration?.id === id ? registration : null), [id, registration]);

	return result;
};

/**
 * @function
 * @name useRegistrationsLoadingSelector
 * @description A selector to retrieve the loading state from the registration redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useRegistrationsLoadingSelector = () => {
	const loading = useSelector((state) => state.registrations?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useRegistrationListSelector
 * @description A selector to retrieve the registration list from the registration redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useRegistrationListSelector = () => {
	const registrations = useSelector((state) => (state.registrations?.items ?? []), shallowEqual);

	return registrations;
};

/**
 * @function
 * @name useRegistrationTotalCountSelector
 * @description A selector to retrieve the totalCount value from the registration redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useRegistrationTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.registrations?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useRegistrationActiveListSelector
 * @description A selector to retrieve all non-archived registrations from the registration redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useRegistrationActiveListSelector = () => {
	const registrations = useRegistrationListSelector();

	const activeRegistrations = useMemo(() => registrations.filter(({ archived }) => !archived), [registrations]);

	return activeRegistrations;
};

export {
	useCurrentRegistrationSelector,
	useRegistrationActiveListSelector,
	useRegistrationListSelector,
	useRegistrationsLoadingSelector,
	useRegistrationTotalCountSelector,
};
