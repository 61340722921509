import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatContactNameWithTitle } from 'lib/contacts/formatContactData';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * @name UserTitle
 * @description A component to display the user's titles.
 *
 * @author Romaric Barthe
 *
 * @param {object}	contact			The contact.
 */
const UserTitle = ({
	contactFullname,
	contactPosition,
	currentUser,
	userToBeUpdated,
}) => {
	const { t } = useTranslation();

	const contact = useMemo(() => userToBeUpdated?.contact, [userToBeUpdated]);

	// To display "live" the last update (if there are some update)
	const objUpdated = useMemo(() => {
		if (formatContactNameWithTitle(contact) !== contactFullname
			|| contact?.position?.name !== contactPosition
		) {
			return { updatedBy: currentUser, updatedAt: moment() };
		}

		return contact;
	}, [contact, contactFullname, contactPosition, currentUser]);

	return (
		<div className="data-header">
			<div className="identity">
				<h3>{contactFullname}</h3>
				<h5>{contactPosition}</h5>
			</div>
			<div className="metadata">
				<p>{`${t('contact.fields.creation_date')}: ${formatCreatedAt(userToBeUpdated)}`}</p>
				<p>{`${t('contact.fields.creation_name')}: ${formatCreatedBy(userToBeUpdated)}`}</p>
				<p>{`${t('contact.fields.update_date')}: ${formatUpdatedAt(objUpdated)}`}</p>
				<p>{`${t('contact.fields.update_name')}: ${formatUpdatedBy(objUpdated)}`}</p>
			</div>
		</div>
	);
};

UserTitle.propTypes = {
	userToBeUpdated: PropTypes.shape({
		contact: PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
			classification: PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			}),
			position: PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			}),
		}).isRequired,
	}).isRequired,
	contactFullname: PropTypes.string,
	contactPosition: PropTypes.string,
	currentUser: PropTypes.shape({
		id: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
	}).isRequired,
};

UserTitle.defaultProps = {
	contactFullname: '',
	contactPosition: '',
};

export default UserTitle;
