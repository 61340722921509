import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/actions/users';
import routes from 'routes';

/**
 * @name LogoutPage
 * @description Renders a waiting page and destroys the user session
 *
 * @author Yann Hodiesne
 */
const LogoutPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(logout(routes.auth.login));
	}, [dispatch]);

	return <h1>Logging out...</h1>;
};

export default LogoutPage;
