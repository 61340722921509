const overflowWrapper = {
	maxHeight: '40vh',
	overflow: 'auto',
};

const list = {
	listStyle: 'none',
	paddingLeft: 0,
	paddingRight: '.5em',
};

const dragHandleWrapper = {
	display: 'flex',
	alignItems: 'center',
};

const dragHandle = {
	color: '#808080',
	marginRight: '.5em',

	'* svg': {
		width: '.5em',
		height: '.5em',
	},
};

export {
	dragHandle,
	dragHandleWrapper,
	list,
	overflowWrapper,
};
