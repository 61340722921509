import { ActionTypes } from '../../actions/targetPartners/targetPartners';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_TARGET_PARTNER_LIST_REQUEST:
		case ActionTypes.ADD_TARGET_PARTNER_REQUEST:
		case ActionTypes.EXPORT_TARGET_PARTNER_REQUEST:
		case ActionTypes.UPDATE_TARGET_PARTNER_REQUEST:
		case ActionTypes.REMOVE_TARGET_PARTNER_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_TARGET_PARTNER_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_TARGET_PARTNER_LIST_FAILURE:
		case ActionTypes.FETCH_TARGET_PARTNER_FAILURE:
		case ActionTypes.ADD_TARGET_PARTNER_FAILURE:
		case ActionTypes.EXPORT_TARGET_PARTNER_FAILURE:
		case ActionTypes.UPDATE_TARGET_PARTNER_FAILURE:
		case ActionTypes.REMOVE_TARGET_PARTNER_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_TARGET_PARTNER_SUCCESS:
		case ActionTypes.EXPORT_TARGET_PARTNER_SUCCESS:
		case ActionTypes.UPDATE_TARGET_PARTNER_SUCCESS:
		case ActionTypes.REMOVE_TARGET_PARTNER_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_TARGET_PARTNER_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.targetPartners],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_TARGET_PARTNER_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.targetPartner,
			};
		default:
			return state;
	}
};
