import { useTranslation } from 'react-i18next';
import conf from 'conf';
import PropTypes from 'prop-types';

import { ContactCardModal } from 'components/contacts';
import { Button } from 'components/shared/buttons';
import { useModal } from 'components/shared/modal';

import styles from 'styles/components/targets/_targetPartnerContactListItem.module.scss';

/**
 * @name TargetPartnerContactListItemSkeleton
 * @description The skeleton for the TargetPartnerContactListItem component.
 *
 * @author Timothée Simon-Franza
 */
const TargetPartnerContactListItemSkeleton = () => (
	<li className={styles['contact-list-item--skeleton']}>
		<Button className="subtle" disabled onClick={() => {}}>
			<div role="img" height="24" width="24" />
			<address>
				<p />
				<p />
			</address>
		</Button>
	</li>
);

/**
 * @name TargetPartnerContactListItem
 * @description A UI component used to display a contact in the TargetPartnerContactList component.
 *
 * @author Timothée Simon-Franza
 *
 * @param {Object}	contact						The contact to display.
 * @param {string}	contact.firstNname			The contact's first name.
 * @param {string}	contact.lastNname			The contact's last name.
 * @param {object}	[contact.position]			The position on the contact in the targetPartner's company.
 * @param {string}	[contact.position.id]		The position's id.
 * @param {string}	[contact.position.name]		The position's name.
 * @param {string}	[contact.profilePicture]	The url of the contact's profile picture file.
 * @param {func}	onEditContactButtonClick	The callback to trigger when the edit contact button is clicked.
 * @param {bool}	inPartnerVisualisation		Whether TargetPartnerContactList is called from partnerVisualisation
*/
const TargetPartnerContactListItem = ({ contact, onEditContactButtonClick, inPartnerVisualisation, index }) => {
	const { t } = useTranslation();

	const { isShowing: isContactDetailsModalShowing, toggle: toggleContactDetailsModal } = useModal();

	return (
		<li className={styles['contact-list-item']} style={inPartnerVisualisation ? { gridColumn: (index % 2) + 1 } : {}}>
			<Button className="subtle" onClick={toggleContactDetailsModal}>
				<img
					src={contact.linkedUser?.profilePicture ?? conf.defaultUserAvatar}
					alt={t('formatting.contact.full_name', { ...contact })}
					height="24"
					width="24"
				/>
				<address>
					<p>{t('formatting.contact.full_name', { ...contact })}</p>
					<p>{contact.position?.name ?? ''}</p>
				</address>
			</Button>
			<ContactCardModal
				contact={contact}
				onCloseButtonClick={toggleContactDetailsModal}
				onEditContactButtonClick={onEditContactButtonClick}
				isShowing={isContactDetailsModalShowing}
				inPartnerVisualisation={inPartnerVisualisation}
			/>
		</li>
	);
};

TargetPartnerContactListItem.propTypes = {
	contact: PropTypes.shape({
		profilePicture: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string.isRequired,
		position: PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string.isRequired,
		}),
		linkedUser: PropTypes.shape({
			id: PropTypes.string.isRequired,
			profilePicture: PropTypes.string,
		}),
	}).isRequired,
	onEditContactButtonClick: PropTypes.func.isRequired,
	inPartnerVisualisation: PropTypes.bool,
	index: PropTypes.number,
};

TargetPartnerContactListItem.defaultProps = {
	inPartnerVisualisation: false,
	index: undefined,
};

export {
	TargetPartnerContactListItem,
	TargetPartnerContactListItemSkeleton,
};
