import { toast } from 'react-toastify';
import * as StatusObjectsApi from 'api/statusObjectsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the status objects redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific status object
	FETCH_STATUS_OBJECT_REQUEST: '@STATUS_OBJECTS/FETCH_REQUEST',
	FETCH_STATUS_OBJECT_SUCCESS: '@STATUS_OBJECTS/FETCH_SUCCESS',
	FETCH_STATUS_OBJECT_FAILURE: '@STATUS_OBJECTS/FETCH_FAILURE',

	// Fetch a list of status objects
	FETCH_STATUS_OBJECT_LIST_REQUEST: '@STATUS_OBJECTS/FETCH_LIST_REQUEST',
	FETCH_STATUS_OBJECT_LIST_SUCCESS: '@STATUS_OBJECTS/FETCH_LIST_SUCCESS',
	FETCH_STATUS_OBJECT_LIST_FAILURE: '@STATUS_OBJECTS/FETCH_LIST_FAILURE',

	// Add a new status object to the database
	ADD_STATUS_OBJECT_REQUEST: '@STATUS_OBJECTS/ADD_REQUEST',
	ADD_STATUS_OBJECT_SUCCESS: '@STATUS_OBJECTS/ADD_SUCCESS',
	ADD_STATUS_OBJECT_FAILURE: '@STATUS_OBJECTS/ADD_FAILURE',

	// Update an existing status object from the database
	UPDATE_STATUS_OBJECT_REQUEST: '@STATUS_OBJECTS/UPDATE_REQUEST',
	UPDATE_STATUS_OBJECT_SUCCESS: '@STATUS_OBJECTS/UPDATE_SUCCESS',
	UPDATE_STATUS_OBJECT_FAILURE: '@STATUS_OBJECTS/UPDATE_FAILURE',

	// Remove an existing status object from the database
	REMOVE_STATUS_OBJECT_REQUEST: '@STATUS_OBJECTS/REMOVE_REQUEST',
	REMOVE_STATUS_OBJECT_SUCCESS: '@STATUS_OBJECTS/REMOVE_SUCCESS',
	REMOVE_STATUS_OBJECT_FAILURE: '@STATUS_OBJECTS/REMOVE_FAILURE',

	// Mark an existing status object from the database as favorite
	FAVORITE_STATUS_OBJECT_REQUEST: '@STATUS_OBJECTS/FAVORITE_REQUEST',
	FAVORITE_STATUS_OBJECT_SUCCESS: '@STATUS_OBJECTS/FAVORITE_SUCCESS',
	FAVORITE_STATUS_OBJECT_FAILURE: '@STATUS_OBJECTS/FAVORITE_FAILURE',
};

// ////////////////////////////////////////////////////////////// //
// ///////////// Status object list fetching actions //////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchStatusObjectListRequest
 * @description Action triggered anytime a status object list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchStatusObjectListRequest = () => ({ type: ActionTypes.FETCH_STATUS_OBJECT_LIST_REQUEST });

/**
 * @function
 * @name fetchStatusObjectListSuccess
 * @description Action triggered as a result to a successful status object list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObjects	The list of retrieved status objects.
 * @param {number} totalCount		The total amount of status objects available in the database for the current user.
 *
 * @returns {object}
 */
const fetchStatusObjectListSuccess = ({ statusObjects, totalCount }) => ({
	type: ActionTypes.FETCH_STATUS_OBJECT_LIST_SUCCESS,
	payload: { statusObjects, totalCount },
});

/**
 * @function
 * @name fetchStatusObjectListFailure
 * @description Action triggered as a result to a failed status object list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchStatusObjectListFailure = (error) => ({
	type: ActionTypes.FETCH_STATUS_OBJECT_LIST_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// /////////// Specific status object fetching actions ////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchStatusObjectRequest
 * @description Action triggered anytime a status object fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchStatusObjectRequest = () => ({ type: ActionTypes.FETCH_STATUS_OBJECT_REQUEST });

/**
 * @function
 * @name fetchStatusObjectSuccess
 * @description Action triggered as a result to a successful status object fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObject The retrieved status object.
 *
 * @returns {object}
 */
const fetchStatusObjectSuccess = ({ statusObject }) => ({
	type: ActionTypes.FETCH_STATUS_OBJECT_SUCCESS,
	payload: { statusObject },
});

/**
 * @function
 * @name fetchStatusObjectFailure
 * @description Action triggered as a result to a failed status object fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchStatusObjectFailure = (error) => ({
	type: ActionTypes.FETCH_STATUS_OBJECT_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Status object creation actions ////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name addStatusObjectRequest
 * @description Action triggered anytime a status object creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addStatusObjectRequest = () => ({ type: ActionTypes.ADD_STATUS_OBJECT_REQUEST });

/**
 * @function
 * @name addStatusObjectSuccess
 * @description Action triggered as a result to a successful status object creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObject The created status object object.
 *
 * @returns {object}
 */
const addStatusObjectSuccess = ({ statusObject }) => ({
	type: ActionTypes.ADD_STATUS_OBJECT_SUCCESS,
	payload: { statusObject },
});

/**
 * @function
 * @name addStatusObjectFailure
 * @description Action triggered as a result to a failed status object creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const addStatusObjectFailure = (error) => ({
	type: ActionTypes.ADD_STATUS_OBJECT_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// ///////////////// Status object update actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateStatusObjectRequest
 * @description Action triggered anytime a status object update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateStatusObjectRequest = () => ({ type: ActionTypes.UPDATE_STATUS_OBJECT_REQUEST });

/**
 * @function
 * @name updateStatusObjectSuccess
 * @description Action triggered as a result to a successful status object update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObject The updated status object object.
 *
 * @returns {object}
 */
const updateStatusObjectSuccess = ({ statusObject }) => ({
	type: ActionTypes.UPDATE_STATUS_OBJECT_SUCCESS,
	payload: { statusObject },
});

/**
 * @function
 * @name updateStatusObjectFailure
 * @description Action triggered as a result to a failed status object update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const updateStatusObjectFailure = (error) => ({
	type: ActionTypes.UPDATE_STATUS_OBJECT_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Status object removal actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeStatusObjectRequest
 * @description Action triggered anytime a status object deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeStatusObjectRequest = () => ({ type: ActionTypes.REMOVE_STATUS_OBJECT_REQUEST });

/**
 * @function
 * @name removeStatusObjectSuccess
 * @description Action triggered as a result to a successful status object deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {string} deletedStatusObjectId The removed statusObject object id.
 *
 * @returns {object}
 */
const removeStatusObjectSuccess = ({ deletedStatusObjectId }) => ({
	type: ActionTypes.REMOVE_STATUS_OBJECT_SUCCESS,
	payload: { deletedStatusObjectId },
});

/**
 * @function
 * @name removeStatusObjectFailure
 * @description Action triggered as a result to a failed status object deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const removeStatusObjectFailure = (error) => ({
	type: ActionTypes.REMOVE_STATUS_OBJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// /////////////////// Vat Rate favorite actions ////////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name favoriteStatusObjectRequest
 * @description Action triggered anytime a status object favorite call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const favoriteStatusObjectRequest = () => ({ type: ActionTypes.FAVORITE_STATUS_OBJECT_REQUEST });

/**
 * @function
 * @name favoriteStatusObjectSuccess
 * @description	Action triggered as a result to a successful status object favorite API call.
 *
 * @author Romaric Barthe
 *
 * @param {array} statusObject		The updated status object object.
 *
 * @returns {object}
 */
const favoriteStatusObjectSuccess = () => ({ type: ActionTypes.FAVORITE_STATUS_OBJECT_SUCCESS });

/**
 * @function
 * @name favoriteStatusObjectFailure
 * @description Action triggered as a result to a failed status object favorite API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error		The exception sent back from the API.
 *
 * @returns {object}
 */
const favoriteStatusObjectFailure = (error) => ({
	type: ActionTypes.FAVORITE_STATUS_OBJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchStatusObjectList
 * @description Method used to update the status object list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters.
 *
 * @returns {Promise}
 */
export const fetchStatusObjectList = (params) => (dispatch) => {
	dispatch(fetchStatusObjectListRequest());

	return StatusObjectsApi.fetchStatusObjects(params)
		.then(({ statusObjects, totalCount }) => dispatch(fetchStatusObjectListSuccess({ statusObjects, totalCount })))
		.catch((error) => dispatch(fetchStatusObjectListFailure(error)));
};

/**
 * @function
 * @name fetchStatusObject
 * @description Method used to fetch the latest version of a specific status object.
 *
 * @author Romaric Barthe
 *
 * @param {string} statusObjectId		The id of the status object we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchStatusObject = (statusObjectId) => (dispatch) => {
	dispatch(fetchStatusObjectRequest());

	return StatusObjectsApi.fetchStatusObjectById(statusObjectId)
		.then(({ statusObject }) => dispatch(fetchStatusObjectSuccess({ statusObject })))
		.catch((error) => dispatch(fetchStatusObjectFailure(error)));
};

/**
 * @function
 * @name addStatusObject
 * @description Method used to add a new status object instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObjectData		The data to create the new status object from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const addStatusObject = (statusObjectData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addStatusObjectRequest());

	return StatusObjectsApi.createStatusObject(statusObjectData)
		.then(({ statusObject }) => {
			dispatch(addStatusObjectSuccess({ statusObject }));
			toast.success(i18next.t('status_object.creation.toasts.success', { name: statusObject?.status?.name, element: statusObject?.environment }));
			redirectOnSuccess(onSuccessRoute);

			return statusObject;
		})
		.catch((error) => {
			dispatch(addStatusObjectFailure(error));
			toast.error(i18next.t('status_object.creation.toasts.error'));
		});
};

/**
 * @function
 * @name updateStatusObject
 * @description Method used to update an existing status object instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObjectData		The object to update the status object with.
 * @param {string} statusObjectId		The id of the status object we want to update.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const updateStatusObject = (statusObjectData, statusObjectId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateStatusObjectRequest());

	return StatusObjectsApi.updateStatusObject(statusObjectData, statusObjectId)
		.then(({ statusObject }) => {
			dispatch(updateStatusObjectSuccess({ statusObject }));
			toast.success(i18next.t('status_object.edition.toasts.success', { name: statusObject?.status?.name, element: statusObject?.environment }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch((error) => {
			dispatch(updateStatusObjectFailure(error));
			toast.error(i18next.t('status_object.edition.toasts.error'));
		});
};

/**
 * @function
 * @name removeStatusObject
 * @description Method used to remove an existing status object instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObject				The status object we want to remove.
 * @param {string} statusObject.id			The id of the status object we want to remove.
 * @param {string} statusObject.status		The status of the status object we want to remove.
 * @param {string} statusObject.status.name	The status' name of the status object we want to remove.
 * @param {string} statusObject.environment	The environment of the status object we want to remove.
 *
 * @returns {Promise}
 */
export const removeStatusObject = ({ id, status, environment }) => (dispatch) => {
	dispatch(removeStatusObjectRequest());

	return StatusObjectsApi.deleteStatusObject(id)
		.then(({ deletedStatusObjectId }) => {
			dispatch(removeStatusObjectSuccess({ deletedStatusObjectId }));
			toast.success(i18next.t('status_object.deletion.toasts.success', { name: status.name, element: environment }));
		})
		.catch((error) => {
			dispatch(removeStatusObjectFailure(error));
			if (error.messageKey !== undefined) {
				toast.error(i18next.t(error.messageKey));
			} else {
				toast.error(i18next.t('status_object.deletion.toasts.error'));
			}
		});
};

/**
 * @function
 * @name favoriteStatusObject
 * @description	Method used to mark an existing status object instance from the database as favorite.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObject				The status object we want to mark as favorite.
 * @param {string} statusObject.id			The id of the status object we want to mark as favorite.
 * @param {string} statusObject.status		The status of the status object we want to mark as favorite.
 * @param {string} statusObject.status.name	The status' name of the status object we want to mark as favorite.
 *
 * @returns {Promise}
 */
export const favoriteStatusObject = ({ id, status, environment }) => (dispatch) => {
	dispatch(favoriteStatusObjectRequest());

	return StatusObjectsApi.updateStatusObject({ favorite: true }, id)
		.then(() => {
			dispatch(favoriteStatusObjectSuccess());
			toast.success(i18next.t('status_object.edition.toasts.success', { name: status.name, element: environment }));
		})
		.catch((error) => {
			dispatch(favoriteStatusObjectFailure(error));
			toast.error(i18next.t('status_object.edition.toasts.error'));
		});
};
