import _ from 'lodash';

/**
 * @function
 * @name createEntityField
 * @description Helper to create entity fields for entity types on the fly
 *
 * @author Yann Hodiesne
 *
 * @param {string}			id					The entity field identifier
 * @param {string}			name				The name of the entity field
 * @param {string}			type				The type of the entity field data
 * @param {string|function}	accessor			The entity field accessor
 * @param {object}			[scheme]			A scheme object defining the objects we can find inside an array
 * @param {object}			[defaultValues]		The default values for the entity field
 * @param {function}		[format] 			A function to format the data of an entity
 *
 * @returns {object} A new entity field according to provided params
 */
const createEntityField = (
	id,
	name,
	type,
	accessor,
	scheme,
	defaultValues,
	format,
) => Object.freeze({
	id,
	name,
	type,
	accessor: _.isFunction(accessor) ? accessor : (entity) => entity[accessor],
	scheme,
	defaultValues,
	format,
});

export default createEntityField;
