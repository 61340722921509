import { Children, cloneElement, memo, useCallback } from 'react';
import { Menu } from 'react-feather';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import PropTypes from 'prop-types';
import { dragHandle, dragHandleWrapper, list, overflowWrapper } from 'theme/listStyle';

/** */
const StyledOverflowWrapper = ({ children, ...props }) => (
	<div style={{ ...overflowWrapper }} {...props}>
		{Children.map(children, (child) => cloneElement(child, { ...child.props }))}
	</div>
);

/** */
const StyledHandleWrapper = ({ children, ...props }) => (
	<div style={{ ...dragHandleWrapper }} {...props}>
		{Children.map(children, (child) => cloneElement(child, { ...child.props }))}
	</div>
);

/** */
const StyledDragHandle = () => (
	<Menu style={{ ...dragHandle }} />
);

/**
 * @name DynamicList
 * @description A dynamic list component autowiring given data with drag-and-drop capabilities.
 *
 * @link https://github.com/arimorc/airsolution-frontend/wiki/DynamicList-component
 *
 * @author Yann Hodiesne
 *
 * @param {array}	rows			An array of the entities to display inside the list
 * @param {func}	keyResolver		A function used to resolve the unique key for an individual row
 * @param {func}	CellComponent	The component to display inside each row of the list
 * @param {func}	rowsChanged		A callback function called when the rows order has been changed by the user, it is supposed to update the rows prop when called
 */
const DynamicList = ({ rows, keyResolver, CellComponent, rowsChanged }) => {
	const onDragEnd = useCallback((result) => {
		if (!result.destination) {
			return;
		}

		const items = Array.from(rows);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		rowsChanged(items);
	}, [rows, rowsChanged]);

	return (
		<StyledOverflowWrapper>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="dynamic-list">
					{(droppableProvided) => (
						<ul style={{ ...list }} {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
							{rows.map((row, index) => {
								const key = keyResolver(row);

								return (
									<Draggable key={key} draggableId={key} index={index}>
										{(draggableProvided) => (
											<li className="styled-list-item" ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
												<StyledHandleWrapper {...draggableProvided.dragHandleProps} data-testid={`draghandle-${key}`}>
													<StyledDragHandle />
												</StyledHandleWrapper>
												<CellComponent row={row} />
											</li>
										)}
									</Draggable>
								);
							})}
							{droppableProvided.placeholder}
						</ul>
					)}
				</Droppable>
			</DragDropContext>
		</StyledOverflowWrapper>
	);
};

DynamicList.propTypes = {
	rows: PropTypes.arrayOf(PropTypes.object).isRequired,
	keyResolver: PropTypes.func.isRequired,
	CellComponent: PropTypes.elementType.isRequired,
	rowsChanged: PropTypes.func.isRequired,
};

StyledHandleWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};
StyledOverflowWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};
export default memo(DynamicList);
