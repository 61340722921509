import { forwardRef, useCallback, useId, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

import useDynamicFormInput from '../useDynamicFormInput';

import { Error, Hint, Label } from './DynamicFormInput';

/**
 * @name TextInput
 * @description A text input component to be used inside a dynamic form.
 *
 * @author Yann Hodiesne
 *
 * @param {string}	name			The input's name.
 * @param {string}	label			The label for the input.
 * @param {string}	[placeholder]	The value to display as a placeholder inside the input.
 * @param {string}	[hint]			A hint message to display below the input (if no error is displayed).
 * @param {string}	[type]			The input's type ('text', 'email', 'password', 'search', 'tel', 'url').
 * @param {object}	[rules={}]		The validation rules used on this input.
 */
const TextInput = forwardRef(({ name, label, placeholder, hint, type, rules, className, ...props }, ref) => {
	const resolvedRef = useForwardedRef(ref);
	const inputRef = useRef();
	const { t } = useTranslation();

	const getValue = useCallback(() => inputRef.current.value, []);

	const {
		defaultValue,
		isDisabled,
		isInvalid,
		isOptional,
		isReadOnly,
		validationError,
		enableValidationOnChange,
		onChangeHandler,
	} = useDynamicFormInput(name, getValue, rules, props);

	const [value, setValue] = useState(defaultValue ?? '');

	const onChange = useCallback(({ target: { value: newValue } }) => {
		setValue(newValue);
		inputRef.current.value = newValue;

		onChangeHandler();
	}, [onChangeHandler]);

	useImperativeHandle(resolvedRef, () => ({
		setValue: (newValue) => onChange({ target: { value: newValue } }),
	}), [onChange]);

	const id = useId();

	return (
		<div className={`input-wrapper${isDisabled ? ' disabled' : ''}${className ?? ''}`}>
			<Label disabled={isDisabled} inputId={id} isInvalid={isInvalid}>
				{label}
				{isOptional && ` (${t('form.optional')})`}
			</Label>
			<input
				ref={inputRef}
				id={id}
				name={name}
				disabled={isDisabled}
				readOnly={isReadOnly}
				placeholder={placeholder}
				aria-invalid={isInvalid}
				aria-label={label}
				type={type}
				value={value}
				onChange={onChange}
				onBlur={enableValidationOnChange}
			/>
			{isInvalid && <Error>{validationError ?? ''}</Error>}
			{!isInvalid && <Hint>{hint ?? ''}</Hint>}
		</div>
	);
});

TextInput.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['text', 'email', 'password', 'search', 'tel', 'url']),
	label: PropTypes.string.isRequired,
	hint: PropTypes.string,
	placeholder: PropTypes.string,
	rules: PropTypes.object,
	className: PropTypes.string,
};

TextInput.defaultProps = {
	type: 'text',
	hint: '',
	placeholder: '',
	rules: {},
	className: undefined,
};

TextInput.displayName = 'TextInput';

export default TextInput;
