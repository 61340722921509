/* eslint-disable import/prefer-default-export */
import { toast } from 'react-toastify';
import i18next from 'i18next';

/**
 * @function
 * @name showApiErrorToast
 * @description A helper method extracting and displaying the received error inside an error toast.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error			The error received from the API.
 * @param {object} error.errors		The errors translation keys retrieved from the API.
 * @param {string} fallbackKey		The translation key to use if the API did not provide one.
 */
export const showApiErrorToast = (error, fallbackKey) => {
	// Try to extract the content of the first object found inside error.errors, or return undefined if it does not exist
	const message = error?.errors?.[Object.keys(error?.errors ?? { $: undefined })[0]];

	toast.error(i18next.t(message ?? fallbackKey));
};
