import { Fragment, useCallback, useMemo } from 'react';
import { ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { collect } from 'lib/shared/collectionsUtils';
import { filter, find, first, has, isEmpty } from 'lodash';
import { BreadcrumbsActions, updateBreadcrumbs } from 'redux/actions/globals';
import { useBreadcrumbSelector } from 'redux/selectors/breadcrumbs';
import { history } from 'routes/components/RouterProvider';
import names from 'routes/names';

/**
 * @name Breadcrumbs
 * @description Displays a breadcrumbs trail based on the current user's navigation.
 *
 * @author Yann Hodiesne
 * @author Timothée Simon-Franza
 */
const Breadcrumbs = () => {
	const resolvedBreadcrumbs = useBreadcrumbSelector();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	/**
	 * @var
	 * @name formattedBreadcrumbs
	 * @description The list of breadcrumbs retrieved from the redux state, enriched with their names.
	 *
	 * @author Yann Hodiesne
	 *
	 * @returns {Array}
	 */
	const formattedBreadcrumbs = useMemo(() => {
		const flatNames = collect(names, 'routes');

		return filter(resolvedBreadcrumbs.map((breadcrumb, index) => ({
			name: find(flatNames, (route) => matchPath(breadcrumb.pathname, route.path)?.isExact)?.name ?? null,
			location: breadcrumb,
			isCurrent: index + 1 === resolvedBreadcrumbs.length,
		})), (breadcrumb) => breadcrumb.name !== null);
	}, [resolvedBreadcrumbs]);

	/**
	 * @var
	 * @name rootBreadcrumb
	 * @description Returns the first breadcrumb in the list, if any.
	 *
	 * @author Yann Hodiesne
	 *
	 * @returns {object || null}
	 */
	const rootBreadcrumb = useMemo(() => {
		if (isEmpty(resolvedBreadcrumbs)) {
			return null;
		}

		const firstPath = first(resolvedBreadcrumbs).pathname;

		return find(names, (route) => has(route, 'name') && matchPath(firstPath, route.path));
	}, [resolvedBreadcrumbs]);

	/**
	 * @function
	 * @name onBreadcrumbClick
	 * @description Method to trigger whenever the user clicks on a breadcrumb.
	 *
	 * @author Yann Hodiesne
	 * @author Timothée Simon-Franza
	 *
	 * @param {Object}	location		The location linked to the clicked breadcrumb.
	 * @param {Bool}	[reset = false]	Whether the breadcrumbs trail should be reset or not.
	 */
	const onBreadcrumbClick = useCallback((location, reset = false) => {
		dispatch(updateBreadcrumbs(reset ? BreadcrumbsActions.RESET : BreadcrumbsActions.POP, location));
		history.push(location);
	}, [dispatch]);

	return (
		<nav className="breadcrumbs" aria-label="Breadcrumbs">
			{rootBreadcrumb && (
				<Fragment key={rootBreadcrumb.name}>
					<button
						type="button"
						aria-label="breadcrumb"
						onClick={() => onBreadcrumbClick({ pathname: rootBreadcrumb.path }, true)}
					>
						{t(rootBreadcrumb.name)}
					</button>
					{formattedBreadcrumbs.length > 0 && <ChevronRight aria-hidden="true" />}
				</Fragment>
			)}
			{formattedBreadcrumbs.map((breadcrumb) => (
				<Fragment key={breadcrumb.location.key ?? breadcrumb.location.pathname}>
					<button
						type="button"
						aria-label="breadcrumb"
						{...(breadcrumb.isCurrent ? { 'aria-current': 'page' } : {})}
						onClick={() => onBreadcrumbClick(breadcrumb.location)}
					>
						{t(breadcrumb.name)}
					</button>
					{!breadcrumb.isCurrent && <ChevronRight aria-hidden="true" />}
				</Fragment>
			))}
		</nav>
	);
};

export default Breadcrumbs;
