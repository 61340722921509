import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentTargetingSelector
 * @description A selector to retrieve the current targeting from the targeting redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the current targeting to wait for
 *
 * @returns {object | null}
 */
const useCurrentTargetingSelector = (id) => {
	const targeting = useSelector((state) => (state.targetings?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (targeting?.id === id ? targeting : null), [id, targeting]);

	return result;
};

/**
 * @function
 * @name useTargetingLoadingSelector
 * @description A selector to retrieve the loading state from the targeting redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useTargetingLoadingSelector = () => {
	const loading = useSelector((state) => state.targetings?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useTargetingListSelector
 * @description A selector to retrieve the targeting list from the targeting redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTargetingListSelector = () => {
	const targetings = useSelector((state) => (state.targetings?.items ?? []), shallowEqual);

	return targetings;
};

/**
 * @function
 * @name useTargetingTotalCountSelector
 * @description A selector to retrieve the totalCount value from the targeting redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useTargetingTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.targetings?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useTargetingActiveListSelector
 * @description A selector to retrieve all non-archived targetings from the targeting redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTargetingActiveListSelector = () => {
	const targetings = useTargetingListSelector();

	const activeTargetings = useMemo(() => targetings.filter((targeting) => !targeting.archived), [targetings]);

	return activeTargetings;
};

/**
 * @function
 * @name useCurrentTargetingStatsSelector
 * @description A selector to retrieve the current targeting stats from the targeting redux state
 *
 * @author Roland Margelidon
 *
 * @returns {object | null}
 */
const useCurrentTargetingStatsSelector = () => {
	const targetingStats = useSelector((state) => (state.targetings?.currentItemStats ?? null), shallowEqual);

	const result = useMemo(() => (targetingStats || null), [targetingStats]);

	return result;
};

export {
	useCurrentTargetingSelector,
	useCurrentTargetingStatsSelector,
	useTargetingActiveListSelector,
	useTargetingListSelector,
	useTargetingLoadingSelector,
	useTargetingTotalCountSelector,
};
