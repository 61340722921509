import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useSectorTypesLoadingSelector
 * @description A selector to retrieve the loading state from the SectorType redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {boolean}
 */
const useSectorTypesLoadingSelector = () => {
	const loading = useSelector((state) => state.sectorTypes?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useSectorTypeListSelector
 * @description A selector to retrieve the access definitions list from the access definition redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {Array}
 */
const useSectorTypeListSelector = () => {
	const sectorTypes = useSelector((state) => (state.sectorTypes?.items ?? []), shallowEqual);

	return sectorTypes;
};

/**
 * @function
 * @name useSectorTypeTotalCountSelector
 * @description A selector to retrieve the totalCount value from the access definition redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {number|undefined}
 */
const useSectorTypeTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.sectorTypes?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useSectorTypeListSelector,
	useSectorTypesLoadingSelector,
	useSectorTypeTotalCountSelector,
};
