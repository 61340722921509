/* eslint-disable import/prefer-default-export */

/**
 * @function
 * @name getCurrencyName
 * @description return the ISO name of the currency.
 *
 * @author Romaric Barthe
 *
 * @param {object}	currencies		The list of all currency.
 * @param {object}	currencyId		The id of the currency.
 *
 * @returns {string} the ISO name of the currency.
 */
export const getCurrencyName = (currencies, currencyId) => {
	if (currencies.length > 0) {
		const currenciesFiltered = currencies.filter((c) => c.id === currencyId);

		return currenciesFiltered.length > 0 ? currenciesFiltered[0]?.name : '';
	}

	return '';
};
