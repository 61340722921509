import { toast } from 'react-toastify';
import * as ProjectsApi from 'api/projectsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * The various action types used to interact with the Projects redux state.
 */
export const ActionTypes = {
	// Fetch a specific project
	FETCH_PROJECT_REQUEST: '@PROJECTS/FETCH_REQUEST',
	FETCH_PROJECT_SUCCESS: '@PROJECTS/FETCH_SUCCESS',
	FETCH_PROJECT_FAILURE: '@PROJECTS/FETCH_FAILURE',

	// Fetch all for projects
	FETCH_ALL_FOR_PROJECT_REQUEST: '@PROJECTS/FETCH_ALL_FOR_REQUEST',
	FETCH_ALL_FOR_PROJECT_SUCCESS: '@PROJECTS/FETCH_ALL_FOR_SUCCESS',
	FETCH_ALL_FOR_PROJECT_FAILURE: '@PROJECTS/FETCH_ALL_FOR_FAILURE',

	// Fetch a list of projects
	FETCH_PROJECT_LIST_REQUEST: '@PROJECTS/FETCH_LIST_REQUEST',
	FETCH_PROJECT_LIST_SUCCESS: '@PROJECTS/FETCH_LIST_SUCCESS',
	FETCH_PROJECT_LIST_FAILURE: '@PROJECTS/FETCH_LIST_FAILURE',

	// Add a new project to the database
	ADD_PROJECT_REQUEST: '@PROJECTS/ADD_REQUEST',
	ADD_PROJECT_SUCCESS: '@PROJECTS/ADD_SUCCESS',
	ADD_PROJECT_FAILURE: '@PROJECTS/ADD_FAILURE',

	// Update an existing project from the database
	UPDATE_PROJECT_REQUEST: '@PROJECTS/UPDATE_REQUEST',
	UPDATE_PROJECT_SUCCESS: '@PROJECTS/UPDATE_SUCCESS',
	UPDATE_PROJECT_FAILURE: '@PROJECTS/UPDATE_FAILURE',

	// Remove an existing project from the database
	REMOVE_PROJECT_REQUEST: '@PROJECTS/REMOVE_REQUEST',
	REMOVE_PROJECT_SUCCESS: '@PROJECTS/REMOVE_SUCCESS',
	REMOVE_PROJECT_FAILURE: '@PROJECTS/REMOVE_FAILURE',

	// Remove an existing project from the database
	ARCHIVE_PROJECT_REQUEST: '@PROJECTS/ARCHIVE_REQUEST',
	ARCHIVE_PROJECT_SUCCESS: '@PROJECTS/ARCHIVE_SUCCESS',
	ARCHIVE_PROJECT_FAILURE: '@PROJECTS/ARCHIVE_FAILURE',

	// Remove an existing project from the database
	RESTORE_PROJECT_REQUEST: '@PROJECTS/RESTORE_REQUEST',
	RESTORE_PROJECT_SUCCESS: '@PROJECTS/RESTORE_SUCCESS',
	RESTORE_PROJECT_FAILURE: '@PROJECTS/RESTORE_FAILURE',

	// Deactivate an existing project in the database
	DEACTIVATE_PROJECT_REQUEST: '@PROJECTS/DEACTIVATE_REQUEST',
	DEACTIVATE_PROJECT_SUCCESS: '@PROJECTS/DEACTIVATE_SUCCESS',
	DEACTIVATE_PROJECT_FAILURE: '@PROJECTS/DEACTIVATE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Project list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchProjectListRequest
 * @description Action triggered anytime a project list fetching call is made to the API.
 *
 * @author Marin Catel-Guihomat
 *
 * @returns {object}
 */
const fetchProjectListRequest = () => ({ type: ActionTypes.FETCH_PROJECT_LIST_REQUEST });

/**
 * @function
 * @name fetchProjectListSuccess
 * @description Action triggered as a result to a successful project list fetching API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} projects		The list of retrieved projects.
 * @param {number} totalCount	The total amount of projects available in the database for the current user.
 *
 * @returns {object}
 */
const fetchProjectListSuccess = ({ projects, totalCount }) => ({
	type: ActionTypes.FETCH_PROJECT_LIST_SUCCESS,
	payload: { projects, totalCount },
});

/**
 * @function
 * @name fetchCompanyListFailure
 * @description Action triggered as a result to a failed project list fetching API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchProjectListFailure = (error) => ({
	type: ActionTypes.FETCH_PROJECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Fetching all for projects actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAllForProjectRequest
 * @description Action triggered anytime an project fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchAllForProjectRequest = () => ({ type: ActionTypes.FETCH_ALL_FOR_PROJECT_REQUEST });

/**
 * @function
 * @name fetchAllForProjectSuccess
 * @description Action triggered as a result to a successful project fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} allForForm The retrieved project.
 *
 * @returns {object}
 */
const fetchAllForProjectSuccess = ({ allForForm }) => ({
	type: ActionTypes.FETCH_ALL_FOR_PROJECT_SUCCESS,
	payload: { allForForm },
});

/**
 * @function
 * @name fetchAllForProjectFailure
 * @description Action triggered as a result to a failed project fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAllForProjectFailure = (error) => ({
	type: ActionTypes.FETCH_ALL_FOR_PROJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific project fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchProjectRequest
 * @description Action triggered anytime a project fetching call is made to the API.
 *
 * @author Marin Catel-Guihomat
 *
 * @returns {object}
 */
const fetchProjectRequest = () => ({ type: ActionTypes.FETCH_PROJECT_REQUEST });

/**
 * @function
 * @name fetchProjectSuccess
 * @description Action triggered as a result to a successful project fetching API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} project	The retrieved project object.
 *
 * @returns {object}
 */
const fetchProjectSuccess = ({ project }) => ({
	type: ActionTypes.FETCH_PROJECT_SUCCESS,
	payload: { project },
});

/**
 * @function
 * @name fetchProjectFailure
 * @description Action triggered as a result to a failed project fetching API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchProjectFailure = (error) => ({
	type: ActionTypes.FETCH_PROJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Project creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addProjectRequest
 * @description Action triggered anytime a project creation call is made to the API.
 *
 * @author Marin Catel-Guihomat
 *
 * @returns {object}
 */
const addProjectRequest = () => ({ type: ActionTypes.ADD_PROJECT_REQUEST });

/**
 * @function
 * @name addProjectSuccess
 * @description Action triggered as a result to a successful project creation API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} project	The created project object.
 *
 * @returns {object}
 */
const addProjectSuccess = ({ project }) => ({
	type: ActionTypes.ADD_PROJECT_SUCCESS,
	payload: { project },
});

/**
 * @function
 * @name addProjectFailure
 * @description Action triggered as a result to a failed project creation API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addProjectFailure = (error) => ({
	type: ActionTypes.ADD_PROJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Project update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateProjectRequest
 * @description Action triggered anytime a project update call is made to the API.
 *
 * @author Marin Catel-Guihomat
 *
 * @returns {object}
 */
const updateProjectRequest = () => ({ type: ActionTypes.UPDATE_PROJECT_REQUEST });

/**
 * @function
 * @name updateProjectSuccess
 * @description Action triggered as a result to a successful project update API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} project	The updated project object.
 *
 * @returns {object}
 */
const updateProjectSuccess = ({ project }) => ({
	type: ActionTypes.UPDATE_PROJECT_SUCCESS,
	payload: { project },
});

/**
 * @function
 * @name updateProjectFailure
 * @description Action triggered as a result to a failed project update API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateProjectFailure = (error) => ({
	type: ActionTypes.UPDATE_PROJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Project removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeProjectRequest
 * @description Action triggered anytime a project deletion call is made to the API.
 *
 * @author Marin Catel-Guihomat
 *
 * @returns {object}
 */
const removeProjectRequest = () => ({ type: ActionTypes.REMOVE_PROJECT_REQUEST });

/**
 * @function
 * @name removeProjectSuccess
 * @description Action triggered as a result to a successful project deletion API call.
 *
 * @author Marin Catel-Guihomat
 * @author Matthieu Schaerlinger
 *
 * @param {string} deletedProjectId The removed project id.
 *
 * @returns {object}
 */
const removeProjectSuccess = ({ deletedProjectId }) => ({
	type: ActionTypes.REMOVE_PROJECT_SUCCESS,
	payload: { deletedProjectId },
});

/**
 * @function
 * @name removeProjectFailure
 * @description Action triggered as a result to a failed project deletion API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeProjectFailure = (error) => ({
	type: ActionTypes.REMOVE_PROJECT_FAILURE,
	payload: { error },
});

// ///////////////////////////////////////////////////////// //
// /////////////// Project archiving actions /////////////// //
// ///////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveProjectRequest
 * @description Action triggered anytime a project archiving call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archiveProjectRequest = () => ({ type: ActionTypes.ARCHIVE_PROJECT_REQUEST });

/**
 * @function
 * @name archiveProjectSuccess
 * @description Action triggered as a result to a successful project archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archiveProjectSuccess = () => ({
	type: ActionTypes.ARCHIVE_PROJECT_SUCCESS,
});

/**
 * @function
 * @name archiveProjectFailure
 * @description Action triggered as a result to a failed project archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveProjectFailure = (error) => ({
	type: ActionTypes.ARCHIVE_PROJECT_FAILURE,
	payload: { error },
});

// /////////////////////////////////////////////////////////// //
// //////////////// Project restoring actions //////////////// //
// /////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreProjectRequest
 * @description Action triggered anytime a project restoring call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restoreProjectRequest = () => ({ type: ActionTypes.RESTORE_PROJECT_REQUEST });

/**
 * @function
 * @name restoreProjectSuccess
 * @description Action triggered as a result to a successful project restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restoreProjectSuccess = () => ({
	type: ActionTypes.RESTORE_PROJECT_SUCCESS,
});

/**
 * @function
 * @name restoreProjectFailure
 * @description Action triggered as a result to a failed project restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreProjectFailure = (error) => ({
	type: ActionTypes.RESTORE_PROJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////// Project deactivation actions ///////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name deactivateProjectRequest
 * @description Action triggered anytime a project deletion call is made to the API.
 *
 * @author Marin Catel-Guihomat
 *
 * @returns {object}
 */
const deactivateProjectRequest = () => ({ type: ActionTypes.DEACTIVATE_PROJECT_REQUEST });

/**
 * @function
 * @name deactivateProjectSuccess
 * @description Action triggered as a result to a successful project deletion API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} project	The deactivated project object.
 *
 * @returns {object}
 */
const deactivateProjectSuccess = ({ project }) => ({
	type: ActionTypes.DEACTIVATE_PROJECT_SUCCESS,
	payload: { project },
});

/**
 * @function
 * @name deactivateProjectFailure
 * @description Action triggered as a result to a failed project deletion API call.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const deactivateProjectFailure = (error) => ({
	type: ActionTypes.DEACTIVATE_PROJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchProjectList
 * @description Method used to update the project list.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} params	The parameters used to match user filters
 *
 * @returns {object}
 */
export const fetchProjectList = (params) => (dispatch) => {
	dispatch(fetchProjectListRequest());

	return ProjectsApi.fetchProjects(params)
		.then(({ projects, totalCount }) => dispatch(fetchProjectListSuccess({ projects, totalCount })))
		.catch((error) => dispatch(fetchProjectListFailure(error)));
};

/**
 * @function
 * @name fetchProject
 * @description Method used to fetch the last version of a specific project object.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {string} projectId	The id of the project object we want to retrieve the latest version of.
 */
export const fetchProject = (projectId) => (dispatch) => {
	dispatch(fetchProjectRequest());

	return ProjectsApi.fetchProjectById(projectId)
		.then(({ project }) => dispatch(fetchProjectSuccess({ project })))
		.catch((error) => dispatch(fetchProjectFailure(error)));
};

/**
 * @function
 * @name fetchAllForProject
 * @description Method used to load the project "all for" information.
 *
 * @author Romaric Barthe
 *
 * @param {string|null} id	The id of the project.
 */
export const fetchAllForProject = (id) => (dispatch) => {
	dispatch(fetchAllForProjectRequest());

	return ProjectsApi.fetchAllForProjectForm({ projectId: id })
		.then((allForForm) => dispatch(fetchAllForProjectSuccess({ allForForm })))
		.catch((error) => dispatch(fetchAllForProjectFailure(error)));
};

/**
 * @function
 * @name addProject
 * @description Method used to add a new project instance to the database.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} projectData			The data to create the new project from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addProject = (projectData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addProjectRequest());

	return ProjectsApi.createProject(projectData)
		.then(({ project }) => {
			toast.success(i18next.t('projects.creation.toasts.success', { name: project.name }));
			dispatch(addProjectSuccess({ project }));
			redirectOnSuccess(onSuccessRoute);

			return project;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('projects.creation.toasts.conflict'));
				dispatch(addProjectFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('projects.creation.toasts.error'));
				dispatch(addProjectFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateProject
 * @description Method used to update an existing project instance from the database.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} projectData			The object to update the project with.
 * @param {string} projectId			The id of the project we want to update.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateProject = (projectData, projectId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateProjectRequest());

	return ProjectsApi.updateProject(projectData, projectId)
		.then(({ project }) => {
			toast.success(i18next.t('projects.edition.toasts.success', { name: project.name }));
			dispatch(updateProjectSuccess({ project }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('projects.edition.toasts.conflict'));
				dispatch(updateProjectFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('projects.edition.toasts.error'));
				dispatch(updateProjectFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeProject
 * @description Method used to remove an existing project instance from the database.
 *
 * @author Marin Catel-Guihomat
 * @author Matthieu Schaerlinger
 * @author Timothée Simon-Franza
 *
 * @param {object} project		The project we want to remove from the database.
 * @param {string} project.id	The id of the project we want to remove from the database.
 * @param {string} project.name	The name of the project we want to remove from the database (used in success toast).
 */
export const removeProject = ({ id, name }) => (dispatch) => {
	dispatch(removeProjectRequest());

	return ProjectsApi.deleteProject(id)
		.then(({ deletedProjectId }) => {
			dispatch(removeProjectSuccess({ deletedProjectId }));
			toast.success(i18next.t('projects.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeProjectFailure(error));
			toast.error(i18next.t('projects.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveProject
 * @description Method used to archive an existing project instance from the database.
 *
 * @author Matthieu Schaerlinger
 * @author Timothée Simon-Franza
 *
 * @param {object} project		The project we want to archive.
 * @param {string} project.id	The id of the project we want to archive.
 * @param {string} project.name	The name of the project we want to archive (used in success toast).
 */
export const archiveProject = ({ id, name }) => (dispatch) => {
	dispatch(archiveProjectRequest());

	return ProjectsApi.updateProject({ archived: true }, id)
		.then(() => {
			dispatch(archiveProjectSuccess());
			toast.success(i18next.t('projects.archiving.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(archiveProjectFailure(error));
			toast.error(i18next.t('projects.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreProject
 * @description Method used to restore an existing project instance from the database.
 *
 * @author Matthieu Schaerlinger
 * @author Timothée Simon-Franza
 *
 * @param {object} project		The project we want to restore.
 * @param {string} project.id	The id of the project we want to restore.
 * @param {string} project.name	The name of the project we want to restore (used in success toast).
 */
export const restoreProject = ({ id, name }) => (dispatch) => {
	dispatch(restoreProjectRequest());

	return ProjectsApi.updateProject({ archived: false }, id)
		.then(() => {
			dispatch(restoreProjectSuccess());
			toast.success(i18next.t('projects.restoring.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(restoreProjectFailure(error));
			toast.error(i18next.t('projects.restoring.toasts.error'));
		});
};

/**
 * @function
 * @name deactivateProject
 * @description Method used to deactivate an existing project instance in the database.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {string} projectId	The id of the project we want to deactivate in the database.
 */
export const deactivateProject = (projectId) => (dispatch) => {
	dispatch(deactivateProjectRequest());

	return ProjectsApi.deleteProject(projectId)
		.then(({ project }) => dispatch(deactivateProjectSuccess({ project })))
		.catch((error) => dispatch(deactivateProjectFailure(error)));
};
