import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatExpenseName } from 'lib/expenses/formatExpenseData';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { formatCreatedAt, formatCreatedBy, formatNumber, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import PropTypes from 'prop-types';
import routes from 'routes';

import ActionsCell from 'components/expenses/expenseList/ActionsCell';
import StatusCell from 'components/shared/cells/StatusCell';
import { DynamicTable } from 'components/shared/tables';
import { sumOneObjectPropertyGroupByInArray } from 'components/shared/utils/functions';

/**
 * @name ProjectExpenses
 * @description A form used to group the rights fields.
 *
 * @author Romaric Barthe
 */
const ProjectExpenses = ({
	expenseRows,
	numberFormat,
}) => {
	const { t } = useTranslation();
	const canCreateExpense = useAccessRight(AccessRights.humanResources.expenses.enhancedRights.CREATE_EXPENSE);
	const expenses = useMemo(() => expenseRows.map((row) => (
		{
			...row,
			grossCost: row.netCost / (1 + (row.vatRate?.rate ?? 0) / 100),
			vatCost: (row.netCost / (1 + (row.vatRate?.rate ?? 0) / 100)) * ((row.vatRate?.rate ?? 0) / 100),
			editionNumber: row.line,
			generatedPdf: row.line ? { pdfGenerationDate: row.date } : undefined,
		}
	)), [expenseRows]);

	const headers = useMemo(() => [
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('expense.headers.actions') },
		{ id: 'name', accessor: (row) => formatExpenseName(row, numberFormat, t), Header: t('expense.fields.number'), pinColumn: true, sorting: 'expense.editionNumber' },
		{ id: 'status', Cell: StatusCell, Header: t('targeting.target.target_partner.fields.status'), sorting: 'status.name' },
		{
			id: 'grossTotal',
			accessor: (row) => formatNumber(row.grossCost, { nbDecimalMin: 2, symbol: row.currency.name }),
			Header: t('expense.fields.gross_total'),
			sorting: 'expense.netCost',
		},
		{
			id: 'vatTotal',
			accessor: (row) => formatNumber(row.vatCost, { nbDecimalMin: 2, symbol: row.currency.name }),
			Header: t('expense.fields.vat_total'),
			sorting: 'expense.netCost',
		},
		{
			id: 'netTotal',
			accessor: (row) => formatNumber(row.netCost, { nbDecimalMin: 2, symbol: row.currency.name }),
			Header: t('expense.fields.net_total'),
			sorting: 'expense.netCost',
		},
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('expense.fields.creation_date'), sorting: 'targetPartner.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('expense.fields.update_date'), sorting: 'targetPartner.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('expense.fields.creation_name'), sorting: 'targetPartner.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('expense.fields.update_name'), sorting: 'targetPartner.updatedBy' },
	], [numberFormat, t]);

	return (
		<>
			{Object.entries(sumOneObjectPropertyGroupByInArray(expenses, 'currency', 'grossCost')).map(
				([currency, value]) => (
					<div key={currency} className="table">
						<div className="header">{t('expense.fields.gross_total')}</div>
						<div className="data">{formatNumber(value, { symbol: t(`currency.${currency}`) })}</div>
					</div>
				)
			)}
			{Object.entries(sumOneObjectPropertyGroupByInArray(expenses, 'currency', 'netCost')).map(
				([currency, value]) => (
					<div key={currency} className="table">
						<div className="header">{t('expense.fields.net_total')}</div>
						<div className="data">{formatNumber(value, { symbol: t(`currency.${currency}`) })}</div>
					</div>
				)
			)}
			<div className="detailed-information">{t('expense.fields.detailed_information')}</div>
			{canCreateExpense && (
				<Link className="button secondary" to={routes.humanResources.expenses.expensePreCreation}>
					{t('expense.links.create')}
				</Link>
			)}
			<DynamicTable
				disableFetch
				disablePagination
				disableSelection
				headers={headers}
				rows={expenses}
				rowsCount={expenses.length}
				rowHeight={80}
				defaultSortingPrefix="name"
			/>
		</>
	);
};

ProjectExpenses.propTypes = {
	expenseRows: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
		})
	),
	numberFormat: PropTypes.object,
};

ProjectExpenses.defaultProps = {
	expenseRows: [],
	numberFormat: undefined,
};

export default ProjectExpenses;
