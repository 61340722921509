import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useProjectsListLoadingSelector
 * @description A selector to retrieve the loading state from the projectsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useProjectsListLoadingSelector = () => {
	const loading = useSelector((state) => state.projectsList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useProjectsForListSelector
 * @description A selector to retrieve the projectsList list from the project redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useProjectsForListSelector = () => {
	const projectsList = useSelector((state) => (state.projectsList?.items ?? []), shallowEqual);

	return projectsList;
};

/**
 * @function
 * @name useProjectsListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the projectsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useProjectsListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.projectsList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useProjectsForListActiveListSelector
 * @description A selector to retrieve all non-archived projects from the project redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useProjectsForListActiveListSelector = () => {
	const projects = useProjectsForListSelector();

	const activeProjects = useMemo(() => projects.filter((project) => !project.archived), [projects]);

	return activeProjects;
};

export {
	useProjectsForListActiveListSelector,
	useProjectsForListSelector,
	useProjectsListLoadingSelector,
	useProjectsListTotalCountSelector,
};
