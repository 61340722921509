import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { InvoiceCreationTypes } from 'constants/invoiceEnums';
import { addInvoice } from 'redux/actions/invoices/invoices';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { InvoiceCreationForm } from 'components/invoices';

/**
 * @name InvoiceCreationPage
 * @description A page used to create a new invoice.
 *
 * @author Romaric Barthe
 */
const InvoiceCreationPage = () => {
	const dispatch = useDispatch();

	const {
		state: {
			creationType,
			sourceInvoiceId,
			sourceQuotationId,
		} = {
			creationType: undefined,
			sourceInvoiceId: undefined,
			sourceQuotationId: undefined,
		},
	} = useLocation();

	/**
	 * Checks if the required location state data are properly provided. If not, redirects the user to the pre-creation page.
	 */
	useEffect(() => {
		if (!creationType) {
			history.push(routes.accounting.invoices.invoicePreCreation);
		}
		if (creationType === InvoiceCreationTypes.FROM_INVOICE && !sourceInvoiceId) {
			history.push(routes.accounting.invoices.invoicePreCreation);
		}
		if (creationType === InvoiceCreationTypes.REVERT && !sourceInvoiceId) {
			history.push(routes.accounting.invoices.invoicePreCreation);
		}
		if (creationType === InvoiceCreationTypes.FROM_QUOTATION && !sourceQuotationId) {
			history.push(routes.accounting.invoices.invoicePreCreation);
		}
	}, [creationType, sourceInvoiceId, sourceQuotationId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback(async (formData) => {
		const newInvoice = await dispatch(addInvoice(formData));

		if (newInvoice) {
			history.push(generatePath(routes.accounting.invoices.invoiceEdition, { id: newInvoice.id }));
		}
	}, [dispatch]);

	return (
		creationType ? (
			<InvoiceCreationForm
				onSubmit={handleFormSubmit}
				creationType={creationType}
				sourceInvoiceId={sourceInvoiceId}
				sourceQuotationId={sourceQuotationId}
			/>
		) : null
	);
};

export default InvoiceCreationPage;
