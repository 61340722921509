import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchTags
 * @description Retrieves a list of tags from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchTags = (params) => post('/api/tags', params);

/**
 * @function
 * @name fetchTagById
 * @description Retrieves an existing tag from the API, using the tagId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} tagId      The id of the tag we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTagById = (tagId) => get(`/api/tags/${tagId}`);

/**
 * @function
 * @name createTag
 * @description Creates a new tag into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} tagData    The data to create the tag from.
 *
 * @returns {Promise}
 */
export const createTag = (tagData) => post('/api/tags/create', tagData);

/**
 * @function
 * @name updateTag
 * @description Updates an existing tag from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} tagData    The data to update the tag with.
 * @param {string} tagId      The id to identify the tag with.
 *
 * @returns {Promise}
 */
export const updateTag = (tagData, tagId) => put(`/api/tags/${tagId}`, tagData);

/**
 * @function
 * @name deleteTag
 * @description Removes an existing tag from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} tagId      The id of the tag we want to remove.
 *
 * @returns {Promise}
 */
export const deleteTag = (tagId) => del(`/api/tags/${tagId}`);
