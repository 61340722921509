/**
 * @enum {string}
 * @readonly
 *
 * @name Environments
 * @description The allowed environments used over the applications.
 * @author Romaric Barthe
 */
const Environments = Object.freeze({
	EXPENSE: 'expense',
	INVOICE: 'invoice',
	QUOTATION: 'quotation',
	TARGETING: 'targeting',
	TIME: 'time',
});

/**
 * @enum {string}
 * @readonly
 *
 * @name Modules
 * @description The allowed modules used over the applications.
 * @author Romaric Barthe
 */
const Modules = Object.freeze({
	ACCOUNTING: 'accounting',
	HUMAN_RESOURCES: 'humanResources',
	OPERATIONS: 'operations',
	SALES: 'sales',
	SETTINGS: 'settings',
});

export {
	Environments,
	Modules,
};
