import { ActionTypes } from '../../actions/targetings/targetings';

const initialState = {
	currentItem: null,
	currentItemStats: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_TARGETING_LIST_REQUEST:
		case ActionTypes.ADD_TARGETING_REQUEST:
		case ActionTypes.UPDATE_TARGETING_REQUEST:
		case ActionTypes.REMOVE_TARGETING_REQUEST:
		case ActionTypes.ARCHIVE_TARGETING_REQUEST:
		case ActionTypes.RESTORE_TARGETING_REQUEST:
		case ActionTypes.FETCH_TARGETING_STATS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_TARGETING_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_TARGETING_LIST_FAILURE:
		case ActionTypes.FETCH_TARGETING_FAILURE:
		case ActionTypes.ADD_TARGETING_FAILURE:
		case ActionTypes.UPDATE_TARGETING_FAILURE:
		case ActionTypes.REMOVE_TARGETING_FAILURE:
		case ActionTypes.ARCHIVE_TARGETING_FAILURE:
		case ActionTypes.RESTORE_TARGETING_FAILURE:
		case ActionTypes.FETCH_TARGETING_STATS_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.REMOVE_TARGETING_SUCCESS:
		case ActionTypes.ADD_TARGETING_SUCCESS:
		case ActionTypes.UPDATE_TARGETING_SUCCESS:
		case ActionTypes.ARCHIVE_TARGETING_SUCCESS:
		case ActionTypes.RESTORE_TARGETING_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_TARGETING_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.targetings,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_TARGETING_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.targeting,
			};
		case ActionTypes.FETCH_TARGETING_STATS_SUCCESS:
			return {
				...state,
				currentItemStats: payload.targetingStats,
				isLoading: false,
			};
		default:
			return state;
	}
};
