import update from 'immutability-helper';

/**
 * @function
 * @name updateRow
 * @description Updates a row identified by the provided index
 *
 * @author Florian Fornazaric
 *
* @param {object} state					The current state
 * @param {string} payload.index		The index of the row to update
 * @param {object} payload.content		The new content to store inside the row
 *
 * @returns {object} The updated state value
 */
const updateRow = (state, { index, content }) => {
	const { elements } = state;

	const source = elements[index];
	const newElements = update(elements, {
		[index]: {
			$set: {
				...source,
				...content,
				id: source.id,
			},
		},
	});

	return {
		...state,
		elements: newElements,
		isDirty: true,
	};
};

export default updateRow;
