import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useHistoryLoadingSelector
 * @description A selector to retrieve the loading state from the history redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useHistoryLoadingSelector = () => {
	const loading = useSelector((state) => state.histories?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useCurrentHistorySelector
 * @description A selector to retrieve the current history from the history redux state
 *
 * @author Audrey Clerc
 *
 * @param {string} id The identifier of the current history to wait for
 *
 * @returns {object}
 */
const useCurrentHistorySelector = (id) => {
	const history = useSelector((state) => (state.histories?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (history?.id === id ? history : null), [id, history]);

	return result;
};

/**
 * @function
 * @name useHistoryListSelector
 * @description A selector to retrieve the history list from the history redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {Array}
 */
const useHistoryListSelector = () => {
	const histories = useSelector((state) => (state.histories?.items ?? []), shallowEqual);

	return histories;
};

/**
 * @function
 * @name useHistoryListForTargetPartnerSelector
 * @description A selector to retrieve the history list from the history redux state for a specific targetPartner.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} targetPartnerId The id of the targetPartner to filter the history list on.
 *
 * @returns {Array}
 */
const useHistoryListForTargetPartnerSelector = (targetPartnerId) => {
	const historyList = useHistoryListSelector();

	const result = useMemo(() => historyList.filter(({ targetPartners }) => targetPartners.find(({ id }) => targetPartnerId === id)), [historyList, targetPartnerId]);

	return result;
};

/**
 * @function
 * @name useHistoryTotalCountSelector
 * @description A selector to retrieve the totalCount value from the history redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {number|undefined}
 */
const useHistoryTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.histories?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useHistoryListForTargetPartnerSelector
 * @description A selector to retrieve the history list from the history redux state for one targetPartner.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useHistoryStatusesListForTargetPartnerSelector = (targetPartnerId) => {
	const historiesForTargetPartner = useHistoryListForTargetPartnerSelector(targetPartnerId);

	/**
	 * @var
	 * @name result
	 * @description The object returned from this method. Used to manipulate data before sending it back to the caller.
	 */
	const result = useMemo(() => historiesForTargetPartner.filter(
		(history) => history.statusObject
	).sort((a, b) => a.date > b.date), [historiesForTargetPartner]);

	return result;
};

export {
	useCurrentHistorySelector,
	useHistoryListForTargetPartnerSelector,
	useHistoryListSelector,
	useHistoryLoadingSelector,
	useHistoryStatusesListForTargetPartnerSelector,
	useHistoryTotalCountSelector,
};
