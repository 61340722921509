import i18next from 'i18next';
import update from 'immutability-helper';

/**
 * @name callSetter
 * @description Calls a setter function outside of the capabilities component, returning its result
 *
 * @author Yann Hodiesne
 *
 * @param {object} setter			The setter object to call
 * @param {object} element			The element to pass to the setter
 * @param {object} editorContext	An object as close as possible to the current editor state
 *
 * @returns {object} The provided element with the setter applied
 */
export default (setter, element, editorContext) => update(element, {
	[setter.property]: {
		$set: setter.setter({ element, editorContext, t: i18next.t }),
	},
});
