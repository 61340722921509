import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchQuotation, updateQuotation } from 'redux/actions/quotations/quotations';
import { useCurrentQuotationSelector } from 'redux/selectors/quotations/quotations';

import { QuotationEditionForm } from 'components/quotations';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';
import routes from '../../routes';

/**
 * @name QuotationEditionPage
 * @description A page used to edit an existing quotation.
 *
 * @author Audrey Clerc
 * @author Roland Margelidon
 *
 */
const QuotationEditionPage = () => {
	const { id: quotationId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const quotation = useCurrentQuotationSelector(quotationId);

	const canEditQuotation = useAccessRight(AccessRights.sales.quotations.enhancedRights.CREATE_QUOTATION) && !quotation?.archived;

	useEffect(() => {
		dispatch(fetchQuotation(quotationId));
	}, [dispatch, quotationId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Roland Margelidon
	 *
	 * @param {object} formData             The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditQuotation) {
			const quotationData = {
				...formData,
			};

			dispatch(updateQuotation(quotationData, quotationId));
		} else {
			redirectOnSuccess(routes.sales.quotations.list);
		}
	}, [canEditQuotation, dispatch, quotationId]);

	return (
		quotation
			? (
				<QuotationEditionForm quotation={quotation} onSubmit={handleFormSubmit} />
			)
			: <p>{t('quotation.edition.loading')}</p>
	);
};

QuotationEditionPage.defaultProps = {
	quotation: undefined,
};

export default QuotationEditionPage;
