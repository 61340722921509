import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import { ProjectInfos, ProjectTabs } from 'constants/projectEnums';
import { formatContactName } from 'lib/contacts/formatContactData';
import { formatProjectClientData } from 'lib/projects/formatProjectData';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { addContact } from 'redux/actions/contacts/contacts';
import { addPartner } from 'redux/actions/partners/partners';
import { fetchAllForProject } from 'redux/actions/projects/projects';
import { addUser } from 'redux/actions/users';
import { useAllForProjectSelector, useProjectsLoadingSelector } from 'redux/selectors/projects/projects';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

import ContactCreationForm from 'components/contacts/ContactCreationForm';
import PartnerCreationForm from 'components/partners/PartnerCreationForm';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Tab, TabList, TabPanel } from 'components/shared/layout/tabs';
import UserCreationForm from 'components/users/UserCreationForm';

import { Address, ProjectIdentifier, ProjectLinkedEntityForm, ProjectRights, ProjectTempo } from './projectTabs';
import { ProjectExpenses, ProjectInvoices, ProjectQuotations } from './projectTabsInfo';
import ProjectTitle from './ProjectTitle';

/**
 * @name ProjectEditionForm
 * @description A form used to update an existing project.
 *
 * @author Romaric Barthe
 *
 * @param {object}		project		The project object to edit information from.
 * @param {function}	onSubmit	The method to trigger upon form submission.
 */
const ProjectEditionForm = ({ onSubmit, project }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForInvoiceForm = useAllForProjectSelector();
	const contacts = useMemo(() => allForInvoiceForm?.contacts ?? [], [allForInvoiceForm]);
	const currentUser = useCurrentConnectedUserSelector();
	const expenses = useMemo(() => allForInvoiceForm?.expenses ?? [], [allForInvoiceForm]);
	const invoices = useMemo(() => allForInvoiceForm?.invoices ?? [], [allForInvoiceForm]);
	const numberFormats = useMemo(() => allForInvoiceForm?.numberFormats ?? [], [allForInvoiceForm]);
	const quotations = useMemo(() => allForInvoiceForm?.quotations ?? [], [allForInvoiceForm]);
	const users = useMemo(() => allForInvoiceForm?.enabledUsers ?? [], [allForInvoiceForm]);
	const partners = useMemo(() => allForInvoiceForm?.partners ?? [], [allForInvoiceForm]);

	const isLoading = useProjectsLoadingSelector();

	const canEditProject = useAccessRight(AccessRights.operations.projects.enhancedRights.CREATE_PROJECT) && !project?.archived;
	const canViewInvoice = useAccessRight(AccessRights.accounting.invoices.VIEW);

	const [projectClient, setProjectClient] = useState();
	const [projectClientInvoice, setProjectClientInvoice] = useState();
	const [projectDescription, setProjectDescription] = useState();
	const [projectName, setProjectName] = useState();

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const numberFormatInvoice = useMemo(() => {
		const nbi = numberFormats.filter((n) => n.environment === Environments.INVOICE);

		if (nbi.length === 1) {
			return nbi[0];
		}

		return undefined;
	}, [numberFormats]);
	const userSelectOptions = useMemo(
		() => users?.map(({ id, contact: { firstName, lastName } }) => ({ label: formatContactName({ firstName, lastName }), value: id })) ?? [],
		[users]
	);

	// To display additional information in the tabs
	const tabsToDisplay = useMemo(() => {
		const tabs = [];
		Object.entries(ProjectTabs).forEach(([, value]) => {
			tabs.push(value);
		});
		Object.entries(ProjectInfos).forEach(([, value]) => {
			if ((ProjectInfos.EXPENSE === value && expenses.length > 0)
				|| (ProjectInfos.INVOICE === value && invoices.length > 0)
				|| (ProjectInfos.QUOTATION === value && quotations.length > 0)) {
				tabs.push(value);
			}
		});

		return tabs;
	}, [expenses, invoices, quotations]);

	const contactProps = useFormModal(ContactCreationForm, t('projects.edition.inputs.contact.creation'), fetchAllForProject, addContact);
	const partnerProps = useFormModal(PartnerCreationForm, t('projects.edition.inputs.partner.creation'), fetchAllForProject, addPartner);
	const userProps = useFormModal(UserCreationForm, t('projects.edition.inputs.collaborators.creation'), fetchAllForProject, addUser);

	const [selectedProjectTabId, setSelectedProjectTabId] = useState('identity-tab');

	/**
	 * @function
	 * @name setTitles
	 * @description A function to refresh Title variables.
	 *
	 * @author Romaric Barthe
	 */
	const setTitles = useCallback(() => {
		const formData = formRef.current?.getFormValues();

		// Take back object from the id
		const contact = contacts.filter((c) => (c.id === (formData?.contactId ?? undefined)).length === 1)
			? contacts.filter((c) => (c.id === formData.contactId))[0]
			: undefined;
		const partner = partners.filter((p) => (p.id === (formData?.partnerId ?? undefined)).length === 1)
			? partners.filter((p) => (p.id === formData.partnerId))[0]
			: undefined;
		const invoicedContact = contacts.filter((c) => (c.id === (formData?.invoicedContact ?? undefined)).length === 1)
			? contacts.filter((c) => (c.id === formData.invoicedContact))[0]
			: undefined;
		const invoicedPartner = partners.filter((p) => (p.id === (formData?.invoicedPartner ?? undefined)).length === 1)
			? partners.filter((p) => (p.id === formData.invoicedPartner))[0]
			: undefined;
		const resolvedFormData = { ...formData, contact, partner, invoicedContact, invoicedPartner };

		setProjectClient(formatProjectClientData(resolvedFormData).name);
		setProjectClientInvoice(formatProjectClientData(resolvedFormData).invoicing_name);
		setProjectDescription(resolvedFormData?.description);
		setProjectName(resolvedFormData?.name);
	}, [contacts, formRef, partners]);

	/**
	 * @function
	 * @name onTabSelectionChanged
	 * @description A function to switch between tabs.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	tabId		The id of the tab triggering the action.
	 */
	const onTabSelectionChanged = useCallback((tabId) => {
		setSelectedProjectTabId(tabId);
	}, []);

	useEffect(() => {
		dispatch(fetchAllForProject(project?.id));
	}, [dispatch, project?.id]);

	const defaultValues = useMemo(() => ({
		...project,
		collaborators: project.collaborators?.map(({ id }) => (id ?? '')) ?? [],
		responsibles: project.responsibles?.map(({ id }) => (id ?? '')) ?? [],
		contactId: project.contact?.id ?? null,
		partnerId: project.partner?.id ?? null,
		invoicedContact: project.invoicedContact?.id ?? null,
		invoicedPartner: project.invoicedPartner?.id ?? null,
		sameContactPartnerToInvoice: (project.contact && !project.invoicedContact) || (project.partner && !project.invoicedPartner),
	}), [project]);

	// Set state's variables
	useEffect(() => {
		setProjectClient(formatProjectClientData(defaultValues).name);
		setProjectClientInvoice(formatProjectClientData(defaultValues).invoicing_name);
		setProjectDescription(defaultValues?.description);
		setProjectName(defaultValues?.name);
	}, [defaultValues]);

	return (
		<DynamicForm
			ref={formRef}
			onSubmit={onSubmit}
			cleanFormData
			defaultValues={defaultValues}
			disabled={!canEditProject}
			{...formProps}
		>

			<ProjectTitle
				currentUser={currentUser}
				project={project}
				projectClient={projectClient}
				projectClientInvoice={projectClientInvoice}
				projectDescription={projectDescription}
				projectName={projectName}
			/>

			<TabList
				defaultSelectedTabId={selectedProjectTabId}
				onTabSelectionChanged={onTabSelectionChanged}
			>
				{tabsToDisplay.map((tabId) => (
					<Tab key={tabId} tabId={`${tabId}-tab`} tabPanelId={`${tabId}-tabpanel`} dontDisplayCloseButton>
						{t(`projects.fields.${tabId}`)}
					</Tab>
				))}
			</TabList>

			<TabPanel
				tabId="identity-tab"
				tabPanelId="identity-tabpanel"
				hidden={selectedProjectTabId !== 'identity-tab'}
			>
				<ProjectIdentifier setTitles={setTitles} />
			</TabPanel>

			<TabPanel
				tabId="client-tab"
				tabPanelId="client-tabpanel"
				hidden={selectedProjectTabId !== 'client-tab'}
			>
				<ProjectLinkedEntityForm
					isLoading={isLoading}
					canViewInvoice={canViewInvoice}
					contactProps={contactProps}
					contacts={contacts}
					defaultValues={defaultValues}
					partnerProps={partnerProps}
					partners={partners}
					setTitles={setTitles}
				/>
			</TabPanel>

			<TabPanel
				tabId="rights-tab"
				tabPanelId="rights-tabpanel"
				hidden={selectedProjectTabId !== 'rights-tab'}
			>
				<ProjectRights
					isLoading={isLoading}
					userProps={userProps}
					userSelectOptions={userSelectOptions}
				/>
			</TabPanel>

			<TabPanel
				tabId="address-tab"
				tabPanelId="address-tabpanel"
				hidden={selectedProjectTabId !== 'address-tab'}
			>
				<Address />
			</TabPanel>

			<TabPanel
				tabId="tempo-tab"
				tabPanelId="tempo-tabpanel"
				hidden={selectedProjectTabId !== 'tempo-tab'}
			>
				<ProjectTempo />
			</TabPanel>

			<TabPanel
				tabId="invoice-tab"
				tabPanelId="invoice-tabpanel"
				hidden={selectedProjectTabId !== 'invoice-tab'}
			>
				<ProjectInvoices invoices={invoices} numberFormat={numberFormatInvoice} />
			</TabPanel>

			<TabPanel
				tabId="quotation-tab"
				tabPanelId="quotation-tabpanel"
				hidden={selectedProjectTabId !== 'quotation-tab'}
			>
				<ProjectQuotations quotations={quotations} numberFormat={numberFormatInvoice} />
			</TabPanel>

			<TabPanel
				tabId="expense-tab"
				tabPanelId="expense-tabpanel"
				hidden={selectedProjectTabId !== 'expense-tab'}
			>
				<ProjectExpenses expenseRows={expenses} numberFormat={numberFormatInvoice} />
			</TabPanel>

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditProject ? t('projects.edition.action') : t('projects.edition.close')}
			</Button>
		</DynamicForm>
	);
};

ProjectEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	project: PropTypes.shape({
		address: PropTypes.string,
		archived: PropTypes.bool,
		city: PropTypes.string,
		collaborators: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				username: PropTypes.string.isRequired,
			}),
		),
		contact: PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
		}),
		country: PropTypes.string,
		description: PropTypes.string,
		id: PropTypes.string,
		invoicedContact: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		invoicedPartner: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		name: PropTypes.string.isRequired,
		partner: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		postCode: PropTypes.string,
		responsibles: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				username: PropTypes.string.isRequired,
			}),
		),
		validFrom: PropTypes.string,
		validUntil: PropTypes.string,
	}).isRequired,
};

export default ProjectEditionForm;
