import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchHistory, updateHistory } from 'redux/actions/histories/histories';
import { useCurrentHistorySelector } from 'redux/selectors/histories/histories';
import routes from 'routes';

import { HistoryReminderForm } from 'components/histories';

/**
 * @name HistoryReminderPage
 * @description A page used to update a reminder date in history.
 *
 * @author Audrey Clerc
 *
 */
const HistoryReminderPage = () => {
	const { id: historyId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useCurrentHistorySelector(historyId);

	useEffect(() => {
		dispatch(fetchHistory(historyId));
	}, [dispatch, historyId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		const historyData = {
			...history,
			...formData,
			reminderDate: '',
			date: `${history.date.substr(0, 10)} ${history.date.substr(11, 8)}`,
			contactId: history.contacts?.map(({ id }) => id) ?? [],
			partnerId: history.partners?.map(({ id }) => id) ?? [],
			projectId: history.projects?.map(({ id }) => id) ?? [],
			collaboratorId: history.collaborators?.map(({ id }) => id) ?? [],
		};
		dispatch(updateHistory(historyData, historyId, routes.sales.crm.list));
	}, [dispatch, history, historyId]);

	return (
		history
			? <HistoryReminderForm history={history} onSubmit={handleFormSubmit} />
			: <p>{t('history.edition.loading')}</p>
	);
};

export default HistoryReminderPage;
