/* eslint-disable import/prefer-default-export */
/**
 * @enum {string}
 * @readonly
 *
 * @name ContactTabs
 * @description The differents tabs used in the form
 *
 * @author Romaric Barthe
 */
const ContactTabs = Object.freeze({
	IDENTITY: 'identity',
	ADDRESS: 'address',
	PHONE_MAIL: 'phone_mail',
});

export {
	ContactTabs,
};
