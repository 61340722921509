import PropTypes from 'prop-types';

import { Checkbox } from 'components/shared/inputs';

/**
 * @name EnabledCell
 * @description Custom cell indicating whether or not a company is enabled.
 *
 * @author Timothée Simon-Franza
 */
const EnabledCell = ({ row: { original: { enabled } } }) => <Checkbox checked={enabled} readOnly />;

EnabledCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			enabled: PropTypes.bool.isRequired,
		}).isRequired,
	}).isRequired,
};

export default EnabledCell;
