import { useCallback, useMemo } from 'react';
import { Archive, Edit2, Eye, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { archiveTemplate, removeTemplate, restoreTemplate } from 'redux/actions/templates';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the TemplateListPage table. (archive, delete and edit template)
 *
 * @author Romaric Barthe
 *
 * @param {func}	fetchData				The method to call when we need to refresh the parent data list.
 * @param {object}	row						The object containing the data to interact with.
 * @param {boolean}	row.original.archived	The flag to know whether the template is historized or not.
 * @param {string}	row.original.id			The id of the template.
 * @param {string}	row.original.name		The name of the template.
 * @param {object}	row.original.company	The company for which the template is created. If empty, it's part of the starter kit.
 */
const ActionsCell = ({ row: { original: { archived, id, name, company, type } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const currentUser = useCurrentConnectedUserSelector();

	const { isShowing: isShowingArchiveModal, toggle: toggleArchiveModal } = useModal();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditTemplate = useAccessRight(AccessRights.commonSettings.templates.enhancedRights.CREATE_TEMPLATE);
	const canDeleteTemplate = useAccessRight(AccessRights.commonSettings.templates.enhancedRights.DELETE_TEMPLATE);
	const isCompanyTemplate = useMemo(() => !!company, [company]);
	const isSuperadmin = useMemo(() => currentUser.superadmin, [currentUser.superadmin]);

	const [archiveButtonRef, archiveTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	const [viewButtonRef, viewTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onViewButtonClick
	 * @description Method triggered as a result to an onClick event from the Eye button.
	 *
	 * @author Romaric Barthe
	 */
	const onViewButtonClick = useCallback(() => {
		// TODO: generatePath According to the Type
		history.push(generatePath(routes.settings.templates.templatePdfView, { id }));
	}, [id]);

	/**
	 * @function
	 * @name onViewButtonClick
	 * @description Method triggered as a result to an onClick event from the Eye button.
	 *
	 * @author Audrey Clerc
	 */
	const onViewButtonClickForSpreadSheet = useCallback(() => {
		history.push(generatePath(routes.settings.templates.templateSpreadSheetView, { id }));
	}, [id]);

	/**
	 * @function
	 * @name onViewButtonClickForSuperadmin
	 * @description Method triggered as a result to an onClick event from the Eye button.
	 *
	 * @author Romaric Barthe
	 */
	const onViewButtonClickForSuperadmin = useCallback(() => {
		// TODO: generatePath According to the Type
		history.push(generatePath(routes.superAdmin.templatePdfView, { id }));
	}, [id]);

	/**
	 * @function
	 * @name onEditButtonClickForSuperadmin
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onEditButtonClickForSuperadmin = useCallback(() => {
		// TODO: generatePath According to the Type
		history.push(generatePath(routes.superAdmin.templatePdfEdition, { id }));
	}, [id]);

	/**
	 * @function
	 * @name onArchiveModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the archive button.
	 *
	 * @author Romaric Barthe
	 */
	const onArchiveModalConfirmationButtonClick = useCallback(async () => {
		toggleArchiveModal();

		if (archived) {
			await dispatch(restoreTemplate({ id, name }));
		} else {
			await dispatch(archiveTemplate({ id, name }));
		}

		fetchData();
	}, [archived, dispatch, fetchData, id, name, toggleArchiveModal]);

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Romaric Barthe
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeTemplate({ id, name }));
		fetchData();
	}, [dispatch, fetchData, id, name, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onEditButtonClick = useCallback(() => {
		history.push(generatePath(routes.settings.templates.templatePdfEdition, { id }));
	}, [id]);

	return (
		<>
			{canEditTemplate && !archived && isCompanyTemplate && type === 'pdf' && (
				<>
					<Button className="icon-only" onClick={onEditButtonClick} ref={editButtonRef}>
						<Edit2 className="primary" />
					</Button>
					<Tooltip {...editTooltipProps}>
						{t('actions.edit')}
					</Tooltip>
				</>
			)}
			{!isCompanyTemplate && isSuperadmin && (
				<>
					<Button className="icon-only" onClick={onEditButtonClickForSuperadmin} ref={editButtonRef}>
						<Edit2 className="primary" />
					</Button>
					<Tooltip {...editTooltipProps}>
						{t('actions.edit')}
					</Tooltip>
				</>
			)}
			{canEditTemplate && !isSuperadmin && type === 'pdf' && (
				<>
					<Button className="icon-only" onClick={onViewButtonClick} ref={viewButtonRef}>
						<Eye />
					</Button>
					<Tooltip {...viewTooltipProps}>
						{t('actions.visualisation')}
					</Tooltip>
				</>
			)}
			{canEditTemplate && !isSuperadmin && type !== 'pdf' && (
				<>
					<Button className="icon-only" onClick={onViewButtonClickForSpreadSheet} ref={viewButtonRef}>
						<Eye />
					</Button>
					<Tooltip {...viewTooltipProps}>
						{t('actions.visualisation')}
					</Tooltip>
				</>
			)}
			{isSuperadmin && (
				<>
					<Button className="icon-only" onClick={onViewButtonClickForSuperadmin} ref={viewButtonRef}>
						<Eye />
					</Button>
					<Tooltip {...viewTooltipProps}>
						{t('actions.visualisation')}
					</Tooltip>
				</>
			)}
			{canEditTemplate && isCompanyTemplate && type === 'pdf' && (
				<>
					<Button className="icon-only" onClick={toggleArchiveModal} ref={archiveButtonRef}>
						<Archive />
					</Button>
					<Tooltip {...archiveTooltipProps}>
						{t('actions.archived')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingArchiveModal}
						message={t(`template.${archived ? 'restoring' : 'archiving'}.confirmation_modal.content`)}
						confirm={onArchiveModalConfirmationButtonClick}
						cancel={toggleArchiveModal}
						confirmText={t(`template.${archived ? 'restoring' : 'archiving'}.confirmation_modal.confirm`)}
						cancelText={t(`template.${archived ? 'restoring' : 'archiving'}.confirmation_modal.cancel`)}
						titleText={t(`template.${archived ? 'restoring' : 'archiving'}.confirmation_modal.title`)}
					/>
				</>
			)}
			{((canDeleteTemplate && !archived && isCompanyTemplate) || (!isCompanyTemplate && isSuperadmin)) && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('template.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('template.deletion.confirmation_modal.confirm')}
						cancelText={t('template.deletion.confirmation_modal.cancel')}
						titleText={t('template.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			archived: PropTypes.bool,
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			company: PropTypes.shape({
				id: PropTypes.string,
			}),
			type: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
