import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import conf from 'conf';
import { definePassword } from 'redux/actions/users';

import { SvgIcon } from 'components/shared/utils';
import { DefinePasswordForm } from 'components/users';

/**
 * @name DefinePasswordPage
 * @description A page used by users to set their initial password.
 *
 * This page is accessible by a link provided inside an automatic email sent by the backend application.
 * 		Said email is sent whenever the company is enabled or a new user is created.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 */
const DefinePasswordPage = () => {
	const { hash: token } = useLocation();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleSubmit = useCallback(({ password, checkPassword }) => {
		dispatch(definePassword({
			passwordToken: token ? token.substr(1) : '',
			password,
			checkPassword,
		}));
	}, [dispatch, token]);

	return (
		<>
			<div className="logo-container">
				<SvgIcon path={`${conf.iconsPath}logo_white.svg`} />
			</div>
			<div>
				<h1>{t('define_password.title')}</h1>
				<p>{t('define_password.subtitle')}</p>
				<DefinePasswordForm onSubmit={handleSubmit} />
			</div>
		</>
	);
};

export default DefinePasswordPage;
