import ElementTypes from '../../constants/ElementTypes';

/**
 * @function
 * @name getElementType
 * @description Resolves and returns the element type associated to the provided element
 *
 * @author Yann Hodiesne
 *
 * @param {object} element  The element to resolve the type for
 *
 * @returns {object} The element type associated with the provided element
 */
const getElementType = (element) => (
	ElementTypes.find(({ type }) => type === element.type) ?? null
);

export default getElementType;
