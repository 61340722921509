/* eslint-disable import/prefer-default-export */

/**
 * @function
 * @name locales
 * @description return the array of available languages.
 *
 * @author Romaric Barthe
 *
 * @param {func}	t 		A translation method provided by the withTranslation HoC.
 *
 * @returns array of options for available languages.
 */
export const locales = (t) => [
	{ value: 'de', label: t('user.creation.inputs.locale.de') },
	{ value: 'en', label: t('user.creation.inputs.locale.en') },
	{ value: 'fr', label: t('user.creation.inputs.locale.fr') },
];
