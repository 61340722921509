import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchExpense } from 'redux/actions/expenses/expenses';
import { useCurrentExpenseSelector } from 'redux/selectors/expenses/expenses';

import { ExpenseVisualisation } from 'components/expenses';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name ExpenseVisualisationPage
 * @description A page used to edit an existing expense.
 *
 * @author Romaric Barthe
 *
 */
const ExpenseVisualisationPage = () => {
	const { id: expenseId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const expense = useCurrentExpenseSelector(expenseId);
	const canViewExpense = useAccessRight(AccessRights.humanResources.expenses.VIEW);

	useEffect(() => {
		dispatch(fetchExpense(expenseId));
	}, [dispatch, expenseId]);

	return (expense && canViewExpense)
		? <ExpenseVisualisation expense={expense} />
		: <p>{t('expense.edition.loading')}</p>;
};

ExpenseVisualisationPage.defaultProps = {
	expense: undefined,
};

export default ExpenseVisualisationPage;
