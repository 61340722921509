/* eslint-disable import/prefer-default-export */

import conf from 'conf';

import { SvgIcon } from 'components/shared/utils';

/**
 * @name NewLine
 * @description The new line icon
 *
 * @author Florian Fornazaric
 */
export const NewLine = () => (
	<SvgIcon path={`${conf.iconsPath}temp_new_line.svg`} />
);
