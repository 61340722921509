import * as ExpensesApi from 'api/expensesApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Expenses redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of expenses
	FETCH_EXPENSE_FOR_LIST_REQUEST: '@EXPENSES_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_EXPENSE_FOR_LIST_SUCCESS: '@EXPENSES_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_EXPENSE_FOR_LIST_FAILURE: '@EXPENSES_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Expense list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchExpensesForListRequest
 * @description Action triggered anytime a expensesList list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchExpensesForListRequest = () => ({ type: ActionTypes.FETCH_EXPENSE_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchExpensesForListSuccess
 * @description Action triggered as a result to a successful expensesList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	expensesList	The list of retrieved expenses.
 * @param {number}	totalCount		The total amount of expenses available in the database to the current user.
 *
 * @returns {object}
 */
const fetchExpensesForListSuccess = ({ expensesList, totalCount }) => ({
	type: ActionTypes.FETCH_EXPENSE_FOR_LIST_SUCCESS,
	payload: { expensesList, totalCount },
});

/**
 * @function
 * @name fetchExpensesForListFailure
 * @description Action triggered as a result to a failed expensesList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchExpensesForListFailure = (error) => ({
	type: ActionTypes.FETCH_EXPENSE_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchExpensesForList
 * @description Method used to update the expensesList list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchExpensesForList = (filters) => (dispatch) => {
	dispatch(fetchExpensesForListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forList',
	};

	return ExpensesApi.fetchExpenses(newFilters)
		.then(({ expensesList, totalCount }) => dispatch(fetchExpensesForListSuccess({ expensesList, totalCount })))
		.catch((error) => dispatch(fetchExpensesForListFailure(error)));
};
