import PropTypes from 'prop-types';

/**
 * @name CollaboratorsCell
 * @description Custom cell for the projectListPage table displaying a project's collaborator.
 *
 * @author Marin Catel-Guihomat
 */
const CollaboratorsCell = ({ row: { original: { collaborators } } }) => (
	<ul className="tag-list">
		{collaborators.map(({ username }) => (
			<li key={username}>
				<span className="tag">
					{username}
				</span>
			</li>
		))}
	</ul>
);

CollaboratorsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			collaborators: PropTypes.arrayOf(PropTypes.shape({
				username: PropTypes.string.isRequired,
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default CollaboratorsCell;
