import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { historyChannelsForSelect, historyRestrictions, historyTypes } from 'constants/historyEnums';
import { formatContactName } from 'lib/contacts/formatContactData';
import PropTypes from 'prop-types';
import { addContact } from 'redux/actions/contacts/contacts';
import { fetchContactsSelectList } from 'redux/actions/contacts/contactsSelect';
import { addPartner } from 'redux/actions/partners/partners';
import { fetchPartnersSelectList } from 'redux/actions/partners/partnersSelect';
import { addProject } from 'redux/actions/projects/projects';
import { fetchProjectsSelectList } from 'redux/actions/projects/projectsSelect';
import { addUser, fetchUserList } from 'redux/actions/users';
import { useContactsSelectListSelector, useContactsSelectLoadingSelector } from 'redux/selectors/contacts/contactsSelect';
import { usePartnersSelectListSelector, usePartnersSelectLoadingSelector } from 'redux/selectors/partners/partnersSelect';
import { useProjectsSelectActiveListSelector, useProjectsSelectLoadingSelector } from 'redux/selectors/projects/projectsSelect';
import { useCurrentConnectedUserSelector, useUserListSelector, useUsersLoadingSelector } from 'redux/selectors/users';

import { ContactCreationForm } from 'components/contacts';
import { PartnerCreationForm } from 'components/partners';
import { ProjectCreationForm } from 'components/projects';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { DateInput, HiddenInput, Select, TextArea } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';
import { UserCreationForm } from 'components/users';

import { getHistoryChannelForSelectTypes, getHistoryRestrictionTypes } from './function';

/**
 * @name HistoryCreationForm
 * @description A form used to create a new history.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit		The method to trigger upon form submission.
 * @param {object}	[targetPartner]	The targetPartner object to pre-fill information from.
 */
const HistoryCreationForm = ({ onSubmit, targetPartner }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const dateToday = useMemo(() => new Date(), []);
	dateToday.setUTCHours(23, 59, 59, 999);

	const channelsList = useMemo(() => getHistoryChannelForSelectTypes().map(
		(channel) => ({ value: channel.value, label: t(channel.label) })
	), [t]);
	const restrictionsTypes = useMemo(() => getHistoryRestrictionTypes().map(
		(restriction) => ({ value: restriction.value, label: t(restriction.label) })
	), [t]);

	useEffect(() => {
		dispatch(fetchContactsSelectList({ rowsPerPage: 0 }));
		dispatch(fetchPartnersSelectList({ rowsPerPage: 0 }));
		dispatch(fetchProjectsSelectList({ rowsPerPage: 0 }));
		dispatch(fetchUserList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const contacts = useContactsSelectListSelector();
	const currentUser = useCurrentConnectedUserSelector();
	const partners = usePartnersSelectListSelector();
	const projects = useProjectsSelectActiveListSelector();
	const users = useUserListSelector();
	const areContactsLoading = useContactsSelectLoadingSelector();
	const arePartnersLoading = usePartnersSelectLoadingSelector();
	const areProjectsLoading = useProjectsSelectLoadingSelector();
	const areUsersLoading = useUsersLoadingSelector();

	const contactsSelectOptions = useMemo(() => (contacts.map((contact) => ({ id: contact.id, name: formatContactName(contact) }))), [contacts]);

	const { formProps, buttonProps } = useSubmitButton();

	// Props to pass down to the select input so that it will display a contact creation modal when clicking on the "+" icon.
	const contactCreationModal = useFormModal(ContactCreationForm, t('pages.sales.crm.contacts.creation'), fetchContactsSelectList, addContact);
	// Props to pass down to the select input so that it will display a partner creation modal when clicking on the "+" icon.
	const partnerCreationModal = useFormModal(PartnerCreationForm, t('pages.sales.crm.partners.creation'), fetchPartnersSelectList, addPartner);
	// Props to pass down to the select input so that it will display a project creation modal when clicking on the "+" icon.
	const projectCreationModal = useFormModal(ProjectCreationForm, t('pages.sales.crm.projects.creation'), fetchProjectsSelectList, addProject);
	// Props to pass down to the select input so that it will display a user creation modal when clicking on the "+" icon.
	const userCreationModal = useFormModal(UserCreationForm, t('pages.sales.crm.collaborators.creation'), fetchUserList, addUser);

	const defaultValues = useMemo(() => ({
		channel: historyChannelsForSelect.HISTORY_CHANNEL_INPUT_PHONE,
		restriction: historyRestrictions.HISTORY_RESTRICTION_PUBLIC,
		collaboratorId: currentUser?.id,
		reminderDate: null,
	}), [currentUser?.id]);

	const handleSubmit = useCallback((formData) => {
		const newData = {
			...formData,
			history: formData.date.setUTCHours(12, 0, 0),
		};
		onSubmit(newData);
	}, [onSubmit]);

	return (
		<DynamicForm
			onSubmit={handleSubmit}
			defaultValues={defaultValues}
			{...formProps}
		>
			<HiddenInput
				name="historyType"
				value={historyTypes.HISTORY_TYPE_STANDARD}
			/>
			<DateInput
				label={t('history.creation.inputs.date.label')}
				name="date"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
					maxDate: Validators.maxDate(dateToday, t('components.date_picker.format'), t('history.creation.inputs.date.validation_errors.maxDate')),
					required: Validators.isRequired(t('history.creation.inputs.date.validation_errors.required')),
				}}
			/>
			<TextArea
				label={t('history.creation.inputs.note.label')}
				name="note"
				placeholder={t('history.creation.inputs.note.placeholder')}
				rules={{
					required: Validators.isRequired(t('history.creation.inputs.note.validation_errors.required')),
				}}
				type="text"
			/>
			<Select
				label={t('history.creation.inputs.channel.label')}
				labelKey="label"
				name="channel"
				rules={{
					required: Validators.isRequired(t('history.creation.inputs.channel.validation_errors.required')),
				}}
				options={channelsList}
				valueKey="value"
			/>
			<DateInput
				allowNull
				label={t('history.creation.inputs.reminder_date.label')}
				name="reminderDate"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
			<Select
				label={t('history.creation.inputs.restriction.label')}
				labelKey="label"
				name="restriction"
				options={restrictionsTypes}
				valueKey="value"
			/>
			<Select
				label={t('history.creation.inputs.contacts.label')}
				name="contacts"
				options={contactsSelectOptions}
				labelKey="name"
				valueKey="id"
				isMulti
				isLoading={areContactsLoading}
				{...contactCreationModal}
			/>
			{!targetPartner && (
				<>
					<Select
						label={t('history.creation.inputs.partners.label')}
						name="partners"
						options={partners}
						labelKey="name"
						valueKey="id"
						isMulti
						isLoading={arePartnersLoading}
						{...partnerCreationModal}
					/>
					<Select
						label={t('history.creation.inputs.projects.label')}
						name="projects"
						options={projects}
						labelKey="name"
						valueKey="id"
						isMulti
						isLoading={areProjectsLoading}
						{...projectCreationModal}
					/>
					<Select
						label={t('history.creation.inputs.collaborators.label')}
						name="collaborators"
						options={users}
						labelKey="username"
						valueKey="id"
						isMulti
						isLoading={areUsersLoading}
						{...userCreationModal}
					/>
				</>
			)}
			{targetPartner && (
				<HiddenInput
					name="targetPartners"
					value={targetPartner.id}
				/>
			)}
			<Button className="primary" type="submit" {...buttonProps}>{t('history.creation.action')}</Button>
		</DynamicForm>
	);
};

HistoryCreationForm.propTypes = {
	targetPartner: PropTypes.shape({
		id: PropTypes.string.isRequired,
		partner: PropTypes.shape({
			name: PropTypes.string.isRequired,
		}),
	}),
	onSubmit: PropTypes.func.isRequired,
};

HistoryCreationForm.defaultProps = {
	targetPartner: undefined,
};

export default HistoryCreationForm;
