import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { addContact } from 'redux/actions/contacts/contacts';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ContactCreationForm } from 'components/contacts';

/**
 * @name ContactCreationPage
 * @description A page used to create a new contact.
 *
 * @author Romaric Barthe
 */
const ContactCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback(async (formData) => {
		const newContact = await dispatch(addContact(formData));

		if (newContact) {
			history.push(generatePath(routes.commonModules.contacts.contactEdition, { id: newContact.id }));
		}
	}, [dispatch]);

	return (
		<ContactCreationForm onSubmit={handleFormSubmit} />
	);
};

export default ContactCreationPage;
