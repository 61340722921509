import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addPaymentDelay } from 'redux/actions/paymentDelays';
import routes from 'routes';

import { PaymentDelayCreationForm } from 'components/paymentDelays';

/**
 * @name PaymentDelayCreationPage
 * @description A page used to create a new payment delay.
 *
 * @author Matthieu Schaerlinger
 */
const PaymentDelayCreationPage = () => {
	const dispatch = useDispatch();

	const handleFormSubmit = useCallback((formData) => {
		dispatch(addPaymentDelay(formData, routes.accounting.parameters.paymentDelays.list));
	}, [dispatch]);

	return (
		<PaymentDelayCreationForm onSubmit={handleFormSubmit} />
	);
};

export default PaymentDelayCreationPage;
