import { toast } from 'react-toastify';
import * as TagsApi from 'api/tagsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Tags redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific tag
	FETCH_TAG_REQUEST: '@TAGS/FETCH_REQUEST',
	FETCH_TAG_SUCCESS: '@TAGS/FETCH_SUCCESS',
	FETCH_TAG_FAILURE: '@TAGS/FETCH_FAILURE',

	// Fetch a list of tags
	FETCH_TAG_LIST_REQUEST: '@TAGS/FETCH_LIST_REQUEST',
	FETCH_TAG_LIST_SUCCESS: '@TAGS/FETCH_LIST_SUCCESS',
	FETCH_TAG_LIST_FAILURE: '@TAGS/FETCH_LIST_FAILURE',

	// Add a new tag to the database
	ADD_TAG_REQUEST: '@TAGS/ADD_REQUEST',
	ADD_TAG_SUCCESS: '@TAGS/ADD_SUCCESS',
	ADD_TAG_FAILURE: '@TAGS/ADD_FAILURE',

	// Update an existing tag from the database
	UPDATE_TAG_REQUEST: '@TAGS/UPDATE_REQUEST',
	UPDATE_TAG_SUCCESS: '@TAGS/UPDATE_SUCCESS',
	UPDATE_TAG_FAILURE: '@TAGS/UPDATE_FAILURE',

	// Remove an existing tag from the database
	REMOVE_TAG_REQUEST: '@TAGS/REMOVE_REQUEST',
	REMOVE_TAG_SUCCESS: '@TAGS/REMOVE_SUCCESS',
	REMOVE_TAG_FAILURE: '@TAGS/REMOVE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Tag list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTagListRequest
 * @description Action triggered anytime a tag list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTagListRequest = () => ({ type: ActionTypes.FETCH_TAG_LIST_REQUEST });

/**
 * @function
 * @name fetchTagListSuccess
 * @description Action triggered as a result to a successful tag list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} tags	The list of retrieved tags.
 * @param {number} totalCount	The total amount of tags available in the database for the current user.
 *
 * @returns {object}
 */
const fetchTagListSuccess = ({ tags, totalCount }) => ({
	type: ActionTypes.FETCH_TAG_LIST_SUCCESS,
	payload: { tags, totalCount },
});

/**
 * @function
 * @name fetchTagListFailure
 * @description Action triggered as a result to a failed tag list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTagListFailure = (error) => ({
	type: ActionTypes.FETCH_TAG_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific tag fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTagRequest
 * @description Action triggered anytime a tag fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTagRequest = () => ({ type: ActionTypes.FETCH_TAG_REQUEST });

/**
 * @function
 * @name fetchTagSuccess
 * @description Action triggered as a result to a successful tag fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} tag	The retrieved tag.
 *
 * @returns {object}
 */
const fetchTagSuccess = ({ tag }) => ({
	type: ActionTypes.FETCH_TAG_SUCCESS,
	payload: { tag },
});

/**
 * @function
 * @name fetchTagFailure
 * @description Action triggered as a result to a failed tag fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTagFailure = (error) => ({
	type: ActionTypes.FETCH_TAG_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Tag creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addTagRequest
 * @description Action triggered anytime a tag creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addTagRequest = () => ({ type: ActionTypes.ADD_TAG_REQUEST });

/**
 * @function
 * @name addTagSuccess
 * @description Action triggered as a result to a successful tag creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} tag	The created tag object.
 *
 * @returns {object}
 */
const addTagSuccess = ({ tag }) => ({
	type: ActionTypes.ADD_TAG_SUCCESS,
	payload: { tag },
});

/**
 * @function
 * @name addTagFailure
 * @description Action triggered as a result to a failed tag creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addTagFailure = (error) => ({
	type: ActionTypes.ADD_TAG_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Tag update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateTagRequest
 * @description Action triggered anytime a tag update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateTagRequest = () => ({ type: ActionTypes.UPDATE_TAG_REQUEST });

/**
 * @function
 * @name updateTagSuccess
 * @description Action triggered as a result to a successful tag update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} tag	The updated tag object.
 *
 * @returns {object}
 */
const updateTagSuccess = ({ tag }) => ({
	type: ActionTypes.UPDATE_TAG_SUCCESS,
	payload: { tag },
});

/**
 * @function
 * @name updateTagFailure
 * @description Action triggered as a result to a failed tag update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateTagFailure = (error) => ({
	type: ActionTypes.UPDATE_TAG_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Tag removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeTagRequest
 * @description Action triggered anytime a tag deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeTagRequest = () => ({ type: ActionTypes.REMOVE_TAG_REQUEST });

/**
 * @function
 * @name removeTagSuccess
 * @description Action triggered as a result to a successful tag deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param { string } deletedTagId	The removed tag object id.
 *
 * @returns {object}
 */
const removeTagSuccess = ({ deletedTagId }) => ({
	type: ActionTypes.REMOVE_TAG_SUCCESS,
	payload: { deletedTagId },
});

/**
 * @function
 * @name removeTagFailure
 * @description Action triggered as a result to a failed tag deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeTagFailure = (error) => ({
	type: ActionTypes.REMOVE_TAG_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTagList
 * @description Method used to update the tag list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters.
 *
 * @returns {Promise}
 */
export const fetchTagList = (params) => (dispatch) => {
	dispatch(fetchTagListRequest());

	return TagsApi.fetchTags(params)
		.then(({ tags, totalCount }) => dispatch(fetchTagListSuccess({ tags, totalCount })))
		.catch((error) => dispatch(fetchTagListFailure(error)));
};

/**
 * @function
 * @name fetchTag
 * @description Method used to fetch the latest version of a specific tag.
 *
 * @author Romaric Barthe
 *
 * @param {string} tagId	The id of the tag we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTag = (tagId) => (dispatch) => {
	dispatch(fetchTagRequest());

	return TagsApi.fetchTagById(tagId)
		.then(({ tag }) => dispatch(fetchTagSuccess({ tag })))
		.catch((error) => dispatch(fetchTagFailure(error)));
};

/**
 * @function
 * @name addTag
 * @description Method used to add a new tag instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} tagData	The data to create the new tag from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const addTag = (tagData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addTagRequest());

	return TagsApi.createTag(tagData)
		.then(({ tag }) => {
			toast.success(i18next.t('targeting.tag.creation.toasts.success', { name: tag.name }));
			dispatch(addTagSuccess({ tag }));
			redirectOnSuccess(onSuccessRoute);

			return tag;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('targeting.tag.creation.toasts.conflict'));
				dispatch(addTagFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('targeting.tag.creation.toasts.error'));
				dispatch(addTagFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateTag
 * @description Method used to update an existing tag instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} tagData	The object to update the tag with.
 * @param {string} tagId		The id of the tag we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const updateTag = (tagData, tagId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateTagRequest());

	return TagsApi.updateTag(tagData, tagId)
		.then(({ tag }) => {
			dispatch(updateTagSuccess({ tag }));
			toast.success(i18next.t('targeting.tag.edition.toasts.success', { name: tag.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('targeting.tag.edition.toasts.conflict'));
				dispatch(updateTagFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('targeting.tag.edition.toasts.error'));
				dispatch(updateTagFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeTag
 * @description Method used to remove an existing tag instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} tag					The tag we want to remove from the database.
 * @param {string} tag.id				The id of the tag we want to remove from the database.
 * @param {string} tag.name			The name of the tag we want to remove from the database.
 *
 * @returns {Promise}
 */
export const removeTag = ({ id, name }) => (dispatch) => {
	dispatch(removeTagRequest());

	return TagsApi.deleteTag(id)
		.then(({ deletedTagId }) => {
			dispatch(removeTagSuccess({ deletedTagId }));
			toast.success(i18next.t('targeting.tag.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeTagFailure(error));
			toast.error(i18next.t('targeting.tag.deletion.toasts.error'));
		});
};
