import { memo, useCallback, useContext, useMemo } from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/buttons';

import EditorContext from '../../EditorContext';
import { getCapabilities, getElementType } from '../../functions/internals';
import { clearSelection } from '../../reducer/actions';

import { ElementProperties } from './properties';

/**
 * @name EditorExportToolbox
 * @description The editor's dynamic toolbox for editing elements properties when exporting
 *
 * @author Matthieu Schaerlinger
 */
const EditorExportToolbox = () => {
	const { t } = useTranslation();

	const { dispatch, selectedElements } = useContext(EditorContext);

	// Determines which content type to display inside the toolbox
	const shouldDisplay = useMemo(() => {
		if (!selectedElements || selectedElements.length !== 1) {
			return false;
		}

		// Whether the element has dynamic capabilities
		return getCapabilities(selectedElements[0])
			.reduce((acc, capability) => acc || capability.dynamic(selectedElements[0]), false);
	}, [selectedElements]);

	// Used when an element is selected inside the editor, to display its type inside the header
	const selectedElementType = useMemo(() => {
		if (selectedElements && selectedElements.length === 1) {
			return getElementType(selectedElements[0]);
		}

		return null;
	}, [selectedElements]);

	// Handle unselecting when clicking on the arrow button
	const handleArrowClick = useCallback(() => dispatch(clearSelection()), [dispatch]);

	if (!shouldDisplay) {
		return null;
	}

	return (
		<div className="toolbox">
			<div className="header properties">
				<div className="bar">
					<div className="element-properties">
						<div className="icon">
							<selectedElementType.meta.icon size={18} />
						</div>
						<p className="name">
							{t(selectedElementType.meta.name)}
						</p>
					</div>
				</div>
				<Button className="icon-only close-button" onClick={handleArrowClick}>
					<X />
				</Button>
			</div>
			<ElementProperties />
		</div>
	);
};

export default memo(EditorExportToolbox);
