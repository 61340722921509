import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
	AreaChart,
	Block,
	Card,
	ColGrid,
	Flex,
	Metric,
	SelectBox,
	SelectBoxItem,
	Text,
	Title,
} from '@tremor/react';
import conf from 'conf';
import { Environments } from 'constants/environmentEnums';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { formatNumber } from 'lib/shared/format';
import moment from 'moment';
import { fetchCurrencyList } from 'redux/actions/currencies';
import { fetchInvoicesDashboard } from 'redux/actions/invoices/invoices';
import { fetchStatusObjectList } from 'redux/actions/statusObjects';
import { useCurrenciesLoadingSelector, useCurrencyListSelector } from 'redux/selectors/currencies';
import { useInvoicesDashboardValues } from 'redux/selectors/invoices/invoices';
import { useStatusObjectListByEnvironmentSelector, useStatusObjectLoadingSelector } from 'redux/selectors/statusObjects';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

import { SvgIcon } from 'components/shared/utils';

/**
 * @name InvoiceDashboard
 * @description The dashboard page of the module.
 *
 * Reference for CSS classNames and Tremor components: https://www.tremor.so/docs/getting-started/demo-dashboard
 *
 * @author Romaric Barthe
 */
const InvoiceDashboard = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchCurrencyList({ rowsPerPage: 0 }));
		dispatch(fetchInvoicesDashboard());
		dispatch(fetchStatusObjectList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const values = useInvoicesDashboardValues();
	const currencies = useCurrencyListSelector();
	const tempStatusObjects = useStatusObjectListByEnvironmentSelector(Environments.INVOICE);
	const isCurrenciesLoading = useCurrenciesLoadingSelector();
	const isStatusObjectsLoading = useStatusObjectLoadingSelector();
	const currentUser = useCurrentConnectedUserSelector();

	const statusObjects = useMemo(() => {
		if (tempStatusObjects.find((a) => a.id === 'statusObjectUndefined')) {
			return tempStatusObjects;
		}
		const statusObjectWithUndefined = tempStatusObjects;
		statusObjectWithUndefined.push({ environment: Environments.INVOICE, status: { name: undefined }, id: 'statusObjectUndefined' });

		return statusObjectWithUndefined;
	}, [tempStatusObjects]);

	const defaultCurrencyId = useMemo(() => currentUser?.company?.mainPartner?.currency?.id, [currentUser]);

	const [selectedCurrencyId, setSelectedCurrencyId] = useState(defaultCurrencyId);

	const selectedCurrencyName = useMemo(() => currencies.find((x) => x.id === selectedCurrencyId)?.name, [currencies, selectedCurrencyId]);
	const currencySymbol = useMemo(() => ((selectedCurrencyName && selectedCurrencyName !== '') ? t(`currency.${selectedCurrencyName}`) : ''), [selectedCurrencyName, t]);

	const showInvoice = useAccessRight(AccessRights.accounting.invoices.VIEW);

	const currentYear = useMemo(() => new Date().getFullYear(), []);
	const lastYear = useMemo(() => currentYear - 1, [currentYear]);

	const formatAmount = useCallback((amount) => formatNumber(amount, { symbol: currencySymbol }), [currencySymbol]);

	const mapByMonthToGraphProps = useCallback((byMonth, accessor, format) => {
		const currentYearCategory = currentYear.toString();
		const lastYearCategory = lastYear.toString();
		const dataKey = 'date';

		const data = moment.monthsShort().map((monthName) => {
			const currentMonthNumber = moment().month(monthName).month() + 1;

			const currentYearRow = byMonth?.find((row) => parseInt(row.year, 10) === currentYear
				&& parseInt(row.month, 10) === currentMonthNumber
				&& row.currency === selectedCurrencyId) ?? {};

			const lastYearRow = byMonth?.find((row) => parseInt(row.year, 10) === lastYear
				&& parseInt(row.month, 10) === currentMonthNumber
				&& row.currency === selectedCurrencyId) ?? {};

			return {
				[dataKey]: monthName,
				[lastYearCategory]: parseFloat(lastYearRow?.[accessor] ?? 0),
				[currentYearCategory]: parseFloat(currentYearRow?.[accessor] ?? 0),
			};
		});

		const minValue = Math.floor(
			(Math.min(...data.map((row) => row[currentYearCategory]), ...data.map((row) => row[lastYearCategory])) - 100) / 100
		) * 100;

		const maxValue = Math.ceil(
			(Math.max(...data.map((row) => row[currentYearCategory]), ...data.map((row) => row[lastYearCategory])) + 100) / 100
		) * 100;

		return {
			data,
			categories: [currentYearCategory, lastYearCategory],
			dataKey,
			colors: ['green', 'blue'],
			minValue: minValue > 0 ? minValue : 0,
			maxValue,
			valueFormatter: format || undefined,
		};
	}, [currentYear, lastYear, selectedCurrencyId]);

	const invoiceValues = useMemo(() => {
		const currentYearValues = values?.invoices?.byYear?.find((row) => parseInt(row.year, 10) === currentYear && row.currency === selectedCurrencyId);
		const lastYearValues = values?.invoices?.byYear?.find((row) => parseInt(row.year, 10) === lastYear && row.currency === selectedCurrencyId);

		return {
			totalCurrentYear: parseFloat(currentYearValues?.amount ?? 0),
			totalLastYear: parseFloat(lastYearValues?.amount ?? 0),
			averageCurrentYear: parseFloat(currentYearValues?.average ?? 0),
			averageLastYear: parseFloat(lastYearValues?.average ?? 0),
			graphProps: mapByMonthToGraphProps(values?.invoices?.byMonth, 'amount', formatAmount),
		};
	}, [currentYear, formatAmount, lastYear, mapByMonthToGraphProps, selectedCurrencyId, values?.invoices?.byYear, values?.invoices?.byMonth]);

	const invoiceValuesByStatus = useMemo(() => (
		statusObjects.map((statusObject) => {
			const currentYearValues = values?.invoices?.byYearByStatus?.find(
				(row) => parseInt(row.year, 10) === currentYear && row.currency === selectedCurrencyId && row.status_name === statusObject.status?.name
			);
			const lastYearValues = values?.invoices?.byYearByStatus?.find(
				(row) => parseInt(row.year, 10) === lastYear && row.currency === selectedCurrencyId && row.status_name === statusObject.status?.name
			);
			const byMonthForStatus = values?.invoices?.byMonthByStatus?.filter((row) => row.status_name === statusObject.status?.name);

			return {
				[statusObject.status.name]: {
					totalCurrentYear: parseFloat(currentYearValues?.amount ?? 0),
					totalLastYear: parseFloat(lastYearValues?.amount ?? 0),
					averageCurrentYear: parseFloat(currentYearValues?.average ?? 0),
					averageLastYear: parseFloat(lastYearValues?.average ?? 0),
					graphProps: mapByMonthToGraphProps(byMonthForStatus, 'amount', formatAmount),
				},
			};
		})
	), [currentYear, formatAmount, lastYear, mapByMonthToGraphProps, selectedCurrencyId, statusObjects, values?.invoices?.byMonthByStatus, values?.invoices?.byYearByStatus]);

	/**
	 * @name usedCurrencies
	 * @description Organizing the queries by currency so that, if there is more than one currency, one can be selected so the graphs are updated.
	 *
	 * @author Romaric Barthe
	 */
	const usedCurrencies = useMemo(() => {
		// eslint-disable-next-line require-jsdoc
		const getCurrencies = (valuesArray) => [...new Set(valuesArray?.map((row) => row.currency)?.filter(Boolean) ?? [])];

		return [...new Set([
			...getCurrencies(values?.invoices?.byYear),
			...getCurrencies(values?.invoices?.byMonth),
		])];
	}, [values?.invoices?.byMonth, values?.invoices?.byYear]);

	return (
		<div className="dashboard p-6 sm:p-10">
			<h3>
				{t('invoice.dashboard.title')}
			</h3>

			{showInvoice && !isCurrenciesLoading && usedCurrencies.length > 1 && (
				<SelectBox
					value={selectedCurrencyId}
					onValueChange={setSelectedCurrencyId}
					placeholder={t('dashboard.loading')}
				>
					{usedCurrencies.map((currency) => (
						<SelectBoxItem key={currency} value={currency} text={currencies.find((x) => x.id === currency)?.name} />
					))}
				</SelectBox>
			)}

			{showInvoice && (
				<ColGrid numColsMd={1} numColsLg={1} gapX="gap-x-6" gapY="gap-y-6" marginTop="mt-4">
					<Card>
						<div className="tr-h-full tr-grid" style={{ alignContent: 'space-between' }}>
							<div className="tr-flex tr-w-full tr-items-center tr-mt-0">
								<span className="tr-inline-flex tr-flex-shrink-0 tr-items-center tr-pr-2.5 tr-pt-1.5 tr-pb-1.5">
									<SvgIcon
										className="card-icon tr-h-8 tr-w-8"
										path={`${conf.iconsPath}app_invoices.svg`}
									/>
								</span>
								<Title>{t('invoice.dashboard.graph.title')}</Title>
							</div>
							<div className="tr-mt-3" />
							<Flex alignItems="items-start">
								<Block>
									<Text>{t('invoice.dashboard.graph.total_current_year')}</Text>
									<Metric>{formatAmount(invoiceValues.totalCurrentYear)}</Metric>
								</Block>
								<Block>
									<Text>{t('invoice.dashboard.graph.total_last_year')}</Text>
									<Metric>{formatAmount(invoiceValues.totalLastYear)}</Metric>
								</Block>
							</Flex>
							<Flex alignItems="items-start" marginTop="mt-3">
								<Block>
									<Text>{t('invoice.dashboard.graph.average_current_year')}</Text>
									<Metric>{formatAmount(invoiceValues.averageCurrentYear)}</Metric>
								</Block>
								<Block>
									<Text>{t('invoice.dashboard.graph.average_last_year')}</Text>
									<Metric>{formatAmount(invoiceValues.averageLastYear)}</Metric>
								</Block>
							</Flex>
							<AreaChart
								{...invoiceValues.graphProps}
								height="h-72"
								marginTop="mt-4"
							/>
						</div>
					</Card>
				</ColGrid>
			)}

			{showInvoice && !isStatusObjectsLoading && statusObjects.length > 1 && (
				<ColGrid numColsMd={1} numColsLg={2} gapX="gap-x-6" gapY="gap-y-6" marginTop="mt-4">
					{statusObjects.map((statusObject) => {
						const invoiceValuesForStatus = Object.fromEntries(invoiceValuesByStatus.map((object) => Object.entries(object)[0]));

						return (
							<Card key={statusObject.id}>
								<div className="tr-h-full tr-grid" style={{ alignContent: 'space-between' }}>
									<div className="tr-flex tr-w-full tr-items-center tr-mt-0">
										<span className="tr-inline-flex tr-flex-shrink-0 tr-items-center tr-pr-2.5 tr-pt-1.5 tr-pb-1.5">
											<div className="stat-pill">
												<div className="container">
													<span className="color-dot" style={{ backgroundColor: statusObject?.color }} />
													<span className="stat-label">{statusObject?.status?.name}</span>
												</div>
											</div>
										</span>
									</div>
									<div className="tr-mt-3" />
									<Flex alignItems="items-start">
										<Block>
											<Text>{t('invoice.dashboard.graph.total_current_year')}</Text>
											<Metric>{formatAmount(invoiceValuesForStatus[statusObject.status?.name]?.totalCurrentYear)}</Metric>
										</Block>
										<Block>
											<Text>{t('invoice.dashboard.graph.total_last_year')}</Text>
											<Metric>{formatAmount(invoiceValuesForStatus[statusObject.status?.name]?.totalLastYear)}</Metric>
										</Block>
									</Flex>
									<Flex alignItems="items-start" marginTop="mt-3">
										<Block>
											<Text>{t('invoice.dashboard.graph.average_current_year')}</Text>
											<Metric>{formatAmount(invoiceValuesForStatus[statusObject.status?.name]?.averageCurrentYear)}</Metric>
										</Block>
										<Block>
											<Text>{t('invoice.dashboard.graph.average_last_year')}</Text>
											<Metric>{formatAmount(invoiceValuesForStatus[statusObject.status?.name]?.averageLastYear)}</Metric>
										</Block>
									</Flex>
									<AreaChart
										{...invoiceValuesForStatus[statusObject.status?.name]?.graphProps}
										height="h-72"
										marginTop="mt-4"
									/>
								</div>
							</Card>
						);
					})}
				</ColGrid>
			)}
		</div>
	);
};

export default InvoiceDashboard;
