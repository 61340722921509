import { ActionTypes } from '../actions/statuses';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_STATUS_LIST_REQUEST:
		case ActionTypes.ADD_STATUS_REQUEST:
		case ActionTypes.UPDATE_STATUS_REQUEST:
		case ActionTypes.REMOVE_STATUS_REQUEST:
		case ActionTypes.ARCHIVE_STATUS_REQUEST:
		case ActionTypes.RESTORE_STATUS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_STATUS_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_STATUS_LIST_FAILURE:
		case ActionTypes.FETCH_STATUS_FAILURE:
		case ActionTypes.ADD_STATUS_FAILURE:
		case ActionTypes.UPDATE_STATUS_FAILURE:
		case ActionTypes.REMOVE_STATUS_FAILURE:
		case ActionTypes.ARCHIVE_STATUS_FAILURE:
		case ActionTypes.RESTORE_STATUS_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_STATUS_SUCCESS:
		case ActionTypes.UPDATE_STATUS_SUCCESS:
		case ActionTypes.ARCHIVE_STATUS_SUCCESS:
		case ActionTypes.REMOVE_STATUS_SUCCESS:
		case ActionTypes.RESTORE_STATUS_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_STATUS_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.statuses],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_STATUS_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.status,
			};
		default:
			return state;
	}
};
