import { forwardRef, useCallback, useId, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

import useDynamicFormInput from '../useDynamicFormInput';

import { Error, Hint, Label } from './DynamicFormInput';

/**
 * @name TextArea
 * @description A textarea input component to be used inside a dynamic form.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {string}	name				The input's name.
 * @param {string}	label				The string to display as a label for the wrapped input.
 * @param {string}	[placeholder]		The value to display as a placeholder in the input.
 * @param {string}	[hint]				A hint message to display below the input (if no error is displayed).
 * @param {object}	[rules]				The validation rules to apply to this input.
 */
const TextArea = forwardRef(({ name, label, placeholder, hint, rules, className, ...props }, ref) => {
	const resolvedRef = useForwardedRef(ref);
	const inputRef = useRef();
	const { t } = useTranslation();

	const getValue = useCallback(() => inputRef.current.value, [inputRef]);

	const {
		defaultValue,
		isDisabled,
		isInvalid,
		isOptional,
		isReadOnly,
		validationError,
		enableValidationOnChange,
		onChangeHandler,
	} = useDynamicFormInput(name, getValue, rules, props);

	const [value, setValue] = useState(defaultValue ?? '');

	const onChange = useCallback(({ target: { value: newValue } }) => {
		setValue(newValue);
		inputRef.current.value = newValue;

		onChangeHandler();
	}, [onChangeHandler]);

	useImperativeHandle(resolvedRef, () => ({
		setValue: (newValue) => onChange({ target: { value: newValue } }),
	}), [onChange]);

	const id = useId();

	return (
		<div className={`input-wrapper${isDisabled ? ' disabled' : ''}${className ?? ''}`}>
			<Label disabled={isDisabled} inputId={id} isInvalid={isInvalid}>
				{label}
				{isOptional && ` (${t('form.optional')})`}
			</Label>
			<textarea
				ref={inputRef}
				id={id}
				name={name}
				disabled={isDisabled}
				readOnly={isReadOnly}
				placeholder={placeholder}
				aria-invalid={isInvalid}
				value={value}
				onChange={onChange}
				onBlur={enableValidationOnChange}
			/>
			{isInvalid && <Error>{validationError ?? ''}</Error>}
			{!isInvalid && <Hint>{hint ?? ''}</Hint>}
		</div>
	);
});

TextArea.propTypes = {
	hint: PropTypes.string,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	rules: PropTypes.object,
	className: PropTypes.string,
};

TextArea.defaultProps = {
	hint: '',
	placeholder: '',
	rules: {},
	className: undefined,
};

TextArea.displayName = 'TextArea';

export default TextArea;
