import update from 'immutability-helper';

import setSelection from './setSelection';

/**
 * @function
 * @name moveElement
 * @description Moves an element to the provided destination index, and selects the moved element
 *
 * @author Yann Hodiesne
 *
 * @param {object}	state						The current state
 * @param {object}	payload						The payload of the action
 * @param {number}	payload.sourceRowIndex		The source row index
 * @param {number}	payload.sourceIndex			The index of the source element inside of the provided source row
 * @param {number}	payload.destinationRowIndex	The destination row index
 * @param {number}	payload.destinationIndex	The destination index inside of the provided destination row
 *
 * @returns {object} The updated state value
 */
const moveElement = (state, { sourceRowIndex, sourceIndex, destinationRowIndex, destinationIndex }) => {
	if (sourceRowIndex === destinationRowIndex && sourceIndex === destinationIndex) {
		return state;
	}

	const { elements } = state;
	const source = elements[sourceRowIndex]?.children[sourceIndex];
	const destinationRow = elements[destinationRowIndex];

	if (!source || !destinationRow) {
		return state;
	}

	let newElements;

	if (sourceRowIndex === destinationRowIndex) {
		newElements = update(elements, {
			[sourceRowIndex]: {
				children: {
					$splice: [
						[sourceIndex, 1],
						[destinationIndex, 0, source],
					],
				},
			},
		});
	} else {
		newElements = update(elements, {
			[sourceRowIndex]: {
				children: {
					$splice: [
						[sourceIndex, 1],
					],
				},
			},
			[destinationRowIndex]: {
				children: {
					$splice: [
						[destinationIndex, 0, source],
					],
				},
			},
		});
	}

	return setSelection({
		...state,
		elements: newElements,
		isDirty: true,
	}, { ids: [source.id] });
};

export default moveElement;
