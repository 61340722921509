import { useCallback, useMemo } from 'react';
import { Calendar, Edit2, Layers, Layout, Mail, Paperclip, Phone, Trash2, Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import conf from 'conf';
import { historyChannels } from 'constants/historyEnums';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { fetchHistoryList, removeHistory } from 'redux/actions/histories/histories';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import { StatusTag } from 'components/statuses';

import styles from 'styles/components/targets/_targetPartnerHistoryListItem.module.scss';

/**
 * @name TargetPartnerHistoryListItemSkeleton
 * @description The skeleton for the TargetPartnerHistoryListItem component.
 *
 * @author Timothée Simon-Franza
 */
const TargetPartnerHistoryListItemSkeleton = () => (
	<li className={styles['history-list-item--skeleton']}>
		<div role="img" height="36" width="36" />
		<div role="button" disabled>
			<p />
			<div>
				<span />
				<span />
				<span />
				<time />
			</div>
		</div>
	</li>
);

/**
 * @name HistoryChannelTag
 * @description A map of the different history channels and their associated icon.
 */
const HistoryChannelTag = {
	[historyChannels.HISTORY_CHANNEL_EMAIL]: <Mail width="14px" height="14px" />,
	[historyChannels.HISTORY_CHANNEL_FORM]: <Layout width="14px" height="14px" />,
	[historyChannels.HISTORY_CHANNEL_MEETING]: <Users width="14px" height="14px" />,
	[historyChannels.HISTORY_CHANNEL_PHONE]: <Phone width="14px" height="14px" />,
	[historyChannels.HISTORY_CHANNEL_STATUS]: <Layers width="14px" height="14px" />,
};

/**
 * @name TargetPartnerHistoryListItem
 * @description A UI component used to display a history in the TargetPartnerHistoryList component.
 *
 * @author Timothée Simon-Franza
 *
 * @param {Object}		history					The history to display.
 * @param {string}	    history.channel			The channel through which the exchange happened.
 * @param {Object[]}	history.collaborators	The list of collaborators involved in this history.
 * @param {Object}		history.createdBy		The user who created the history.
 * @param {string}	    history.note			The note summing up the history.
 * @param {Date}		history.date			The date of the history.
 * @param {Object[]}	history.attachedFiles	The list of files attached to this history.
 * @param {Object[]}	history.contacts		The list of contacts involved in this history.
 * @param {string}		history.remindedDate	The date at which the history was reminded.
 * @param {string}		history.reminderDate	The date at which the history must be reminded.
 * @param {Object}		history.statusObject	The status of the history.
 * @param {func}		onEditClick				The callback to call when the user clicks the edit history button.
 */
const TargetPartnerHistoryListItem = ({
	id: historyId,
	channel,
	createdBy,
	note,
	date,
	attachedFiles,
	contacts,
	remindedDate,
	reminderDate,
	statusObject,
	onEditClick,
	targetPartnerId,
}) => {
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();
	const dispatch = useDispatch();

	const canEditHistory = useAccessRight(AccessRights.crm.CREATE_HISTORY);
	const canDeleteHistory = useAccessRight(AccessRights.crm.DELETE_HISTORY);

	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();

	const status = useMemo(() => {
		if (statusObject?.status === undefined) {
			return undefined;
		}

		return {
			color: statusObject.color ?? statusObject.status.defaultColor ?? '#000000',
			name: statusObject.status.name,
		};
	}, [statusObject]);

	const formattedDate = useMemo(() => (Intl.DateTimeFormat('fr-FR').format(new Date(date))), [date]);
	const formattedReminderDate = useMemo(() => (Intl.DateTimeFormat('fr-FR').format(new Date(reminderDate))), [reminderDate]);

	const historyUsername = useMemo(() => createdBy?.username, [createdBy]);

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Grégoire Wartner
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();
		await dispatch(removeHistory({ id: historyId, date }));
		dispatch(fetchHistoryList(
			{
				rowsPerPage: 0,
				filters: {
					conditions: [
						{ column: 'targetPartnersId', criterion: 'is', value: targetPartnerId },
					],
				},
			}
		));
	}, [toggleDeleteModal, dispatch, historyId, date, targetPartnerId]);

	return (
		<li className={styles['history-list-item']}>
			<ReactTooltip id="profilePicture" place="bottom" />
			<img
				src={createdBy?.profilePicture ?? conf.defaultUserAvatar}
				height="36"
				width="36"
				alt={historyUsername}
				data-tooltip-id="profilePicture"
				data-tooltip-content={historyUsername}
			/>

			<div className={styles['history-list-item-content']}>
				<p>{note}</p>
				<div>
					{status && (<StatusTag label={status.name} dotColor={status.color} />)}
					{contacts.length > 0 && (
						<span>
							<img
								src={contacts[0].profilePicture ?? contacts[0].linkedUser?.profilePicture ?? conf.defaultUserAvatar}
								height="32"
								width="32"
								alt={t('formatting.contact.full_name', { ...contacts[0] })}
							/>
							{t('formatting.contact.full_name', { ...contacts[0] })}
						</span>
					)}
					<span className={styles['tag-with-icon']}>
						{HistoryChannelTag[channel]}
						{t(`history.channels.${channel}`)}
					</span>
					{reminderDate && !remindedDate && (
						<span className={styles['tag-with-icon']}>
							<Calendar width="14px" height="14px" />
							<time dateTime={reminderDate}>{formattedReminderDate}</time>
						</span>
					)}
					{/* @TODO: Switch spans with buttons / <a> that trigger a download of the file */}
					{attachedFiles.length > 0 && attachedFiles.map(({ id, fileName }) => (
						<span key={id}>
							<Paperclip />
							{fileName}
						</span>
					))}
					<time dateTime={date}>{formattedDate}</time>
					{ canEditHistory && (
						<>
							<Button className="icon-only" type="button" onClick={onEditClick} ref={editButtonRef}>
								<Edit2 className="primary" />
							</Button>
							<Tooltip {...editTooltipProps}>
								{t('actions.edit')}
							</Tooltip>
						</>
					)}
					{ canDeleteHistory && (
						<>
							<Button className="icon-only" type="button" onClick={toggleDeleteModal} ref={deleteButtonRef}>
								<Trash2 className="red" />
							</Button>
							<PromptModal
								isShowing={isShowingDeleteModal}
								message={t('history.deletion.confirmation_modal.content')}
								confirm={onDeleteModalConfirmationButtonClick}
								cancel={toggleDeleteModal}
								confirmText={t('history.deletion.confirmation_modal.confirm')}
								cancelText={t('history.deletion.confirmation_modal.cancel')}
								titleText={t('history.deletion.confirmation_modal.title')}
							/>
							<Tooltip {...deleteTooltipProps}>
								{t('actions.delete')}
							</Tooltip>
						</>
					)}
				</div>
			</div>
		</li>
	);
};

TargetPartnerHistoryListItem.propTypes = {
	attachedFiles: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		fileName: PropTypes.string.isRequired,
	})).isRequired,
	channel: PropTypes.oneOf(Object.values(historyChannels)).isRequired,
	createdBy: PropTypes.shape({
		id: PropTypes.string.isRequired,
		username: PropTypes.string,
		profilePicture: PropTypes.string,
	}).isRequired,
	contacts: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		profilePicture: PropTypes.string,
		linkedUser: PropTypes.shape({
			profilePicture: PropTypes.string,
		}),
	})).isRequired,
	date: PropTypes.string.isRequired,
	id: PropTypes.string.isRequired,
	note: PropTypes.string,
	onEditClick: PropTypes.func.isRequired,
	remindedDate: PropTypes.string,
	reminderDate: PropTypes.string,
	statusObject: PropTypes.shape({
		id: PropTypes.string.isRequired,
		color: PropTypes.string,
		status: PropTypes.shape({
			name: PropTypes.string,
			defaultColor: PropTypes.string,
		}),
	}),
	targetPartnerId: PropTypes.string,
};

TargetPartnerHistoryListItem.defaultProps = {
	note: '',
	remindedDate: undefined,
	reminderDate: undefined,
	statusObject: undefined,
	targetPartnerId: '',
};

export {
	TargetPartnerHistoryListItem,
	TargetPartnerHistoryListItemSkeleton,
};
