import { historyChannelsForSelect, historyRestrictions } from 'constants/historyEnums';

/**
 * @function
 * @name getHistoryChannelForSelectTypes
 * @description return the array of channel type options.
 *
 * @author Audrey Clerc
 *
 * @param {func}	t 		A translation method provided by the withTranslation HoC.
 *
 * @returns array of options for channel type.
 */
export const getHistoryChannelForSelectTypes = () => {
	const historyChannelsTypes = [];
	Object.entries(historyChannelsForSelect).forEach(([, value]) => {
		historyChannelsTypes.push({ value, label: `history.fields.channel_type.${value}` });
	});

	return historyChannelsTypes;
};

/**
 * @function
 * @name getHistoryRestrictionTypes
 * @description return the array of restriction type options.
 *
 * @author Romaric Barthe
 *
 * @returns array of options for restricion type.
 */
export const getHistoryRestrictionTypes = () => {
	const historyRestrictionsTypes = [];
	Object.entries(historyRestrictions).forEach(([, value]) => {
		historyRestrictionsTypes.push({ value, label: `history.fields.restriction_type.${value}` });
	});

	return historyRestrictionsTypes;
};
