import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchAccessDefinitionList } from 'redux/actions/accessDefinitions';
import { useAccessDefinitionListSelector, useAccessDefinitionTotalCountSelector } from 'redux/selectors/accessDefinitions';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ActionsCell } from 'components/accessDefinitions';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name AccessDefinitionListPage
 * @description A list of all access definitions accessible from the Setting app in sub part access definitions.
 *
 * From this page, the user is able to view, interact and search for access definitions.
 *
 * @author Matthieu Schaerlinger
 */
const AccessDefinitionListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const accessDefinitions = useAccessDefinitionListSelector();
	const totalCount = useAccessDefinitionTotalCountSelector();

	const canCreateAccessDefinition = useAccessRight(AccessRights.humanResources.roles.enhancedRights.CREATE_ACCESS_DEFINITION);

	const headers = useMemo(() => [
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('access_definition.headers.actions') },
		{ accessor: 'name', Header: t('access_definition.fields.name'), sorting: 'accessDefinitions.name' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('access_definition.fields.creation_date'), sorting: 'accessDefinitions.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('access_definition.fields.update_date'), sorting: 'accessDefinitions.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('access_definition.fields.creation_name'), sorting: 'accessDefinitions.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('access_definition.fields.update_name'), sorting: 'accessDefinitions.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchAccessDefinitionList(params)));

	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('accessDefinition');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.humanResources.accessDefinitions.accessDefinitionCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateAccessDefinition && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('access_definition.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={accessDefinitions}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="accessDefinitions"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('access_definition.pages.list')} {...modalProps} />
		</div>
	);
};

export default AccessDefinitionListPage;
