import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchAccessDefinitions
 * @description Retrieves a list of access definitions from the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {Promise}
 */
export const fetchAccessDefinitions = () => get('/api/access-definitions');

/**
 * @function
 * @name fetchAccessDefinitionById
 * @description Retrieves an existing access definition from the API, using the accessDefinitionId in parameters.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} accessDefinitionId   The id of the access definition we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchAccessDefinitionById = (accessDefinitionId) => get(`/api/access-definition/${accessDefinitionId}`);

/**
 * @function
 * @name createAccessDefinition
 * @description Creates a new access definition into the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} accessDefinitionData     The data to create the access definition from.
 *
 * @returns {Promise}
 */
export const createAccessDefinition = (accessDefinitionData) => post('/api/access-definitions/create', accessDefinitionData);

/**
 * @function
 * @name updateAccessDefinition
 * @description Updates an existing access definition from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} accessDefinitionData     The data to update the access definition with.
 * @param {string} accessDefinitionId       The id to identify the access definition with.
 *
 * @returns {Promise}
 */
export const updateAccessDefinition = (accessDefinitionData, accessDefinitionId) => put(`/api/access-definition/${accessDefinitionId}`, accessDefinitionData);

/**
 * @function
 * @name deleteAccessDefinition
 * @description Removes an existing access definition from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} accessDefinitionId   The id of the access definition we want to remove.
 *
 * @returns {Promise}
 */
export const deleteAccessDefinition = (accessDefinitionId) => del(`/api/access-definitions/${accessDefinitionId}`);
