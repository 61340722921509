/**
 * @function
 * @name collect
 * @description Recursively flatten an array of objects containing arrays of the same object structures
 *
 * @author Yann Hodiesne
 *
 * @param {array}	array	 The array of objects to flatten recursively.
 * @param {string}	key		 The key to look for recursive arrays inside each object.
 * @param {array}	result	 Internal recursive parameter, do not use.
 *
 * @returns The flattened array.
 */
export const collect = (array, key, result) => {
	let res = result ?? [];

	array.forEach((el) => {
		if (el[key]) {
			res = collect(el[key], key, res);
			const { items, ...groupItem } = el;
			res.push(groupItem);
		} else {
			res.push(el);
		}
	});

	return res;
};

/**
 * @function
 * @name adaptToBackendFilters
 * @description Transform the current filter's component output into an array easily readable for the backend.
 *
 * @author Romaric Barthe
 *
 * @param {object}	filter	 The object of filter's lines to be transformed for the backend.
 *
 * @returns The flattened array.
 */
export const adaptToBackendFilters = (filter) => {
	const filtersKeys = Object.keys(filter).sort((a, b) => a.localeCompare(b, undefined, {
		numeric: true,
		sensitivity: 'base',
	}));

	const newFilters = {
		operator: filtersKeys.length > 1 ? filter[filtersKeys[1]].operator : null,
		conditions: Object.keys(filter).map((key) => ({
			column: filter[key].column,
			criterion: filter[key].criterionType,
			value: filter[key].value,
		})),
	};

	return newFilters;
};
