import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentNumberFormatSelector
 * @description A selector to retrieve the current number format from the number format redux state
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} id The identifier of the current number format to wait for
 *
 * @returns {object}
 */
const useCurrentNumberFormatSelector = (id) => {
	const numberFormat = useSelector((state) => (state.numberFormats?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (numberFormat?.id === id ? numberFormat : null), [id, numberFormat]);

	return result;
};

/**
 * @function
 * @name useNumberFormatsLoadingSelector
 * @description A selector to retrieve the loading state from the partner redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {boolean}
 */
const useNumberFormatsLoadingSelector = () => {
	const loading = useSelector((state) => state.numberFormats?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useNumberFormatListSelector
 * @description A selector to retrieve the numberFormat list from the numberFormat redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {Array}
 */
const useNumberFormatListSelector = () => {
	const numberFormats = useSelector((state) => (state.numberFormats?.items ?? []), shallowEqual);

	return numberFormats;
};

/**
 * @function
 * @name useNumberFormatTotalCountSelector
 * @description A selector to retrieve the totalCount value from the numberFormat redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {number|undefined}
 */
const useNumberFormatTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.numberFormats?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useCurrentNumberFormatSelector,
	useNumberFormatListSelector,
	useNumberFormatsLoadingSelector,
	useNumberFormatTotalCountSelector,
};
