/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { Mail, Phone, X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import conf from 'conf';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

import styles from 'styles/components/contacts/_contactCard.module.scss';

/**
 * @name ContactCardModal
 * @description A modal component display's the provided contact's information.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object}	contact						The contact to display.
 * @param {bool}	isShowing					Whether or not the modal should be displayed.
 * @param {func}	onCloseButtonClick			The callback to trigger when the modal's close button is clicked.
 * @param {func}	onEditContactButtonClick	The callback to trigger when the modal's edit contact button is clicked.
 */
const ContactCardModal = ({ contact, isShowing, onCloseButtonClick, onEditContactButtonClick, inPartnerVisualisation }) => {
	const { t } = useTranslation();
	const phones = contact?.phones;

	const onEmailClick = useCallback(() => {
		if (contact.emails.length > 0) {
			window.open(`mailto:${contact.emails[0]}`, '_blank');
		}
	}, [contact.emails]);

	const onPhoneClick = useCallback(() => {
		if (phones.length > 0) {
			window.open(`tel:${phones[0]}`, '_blank');
		}
	}, [phones]);

	const onEditButtonClick = useCallback(() => {
		onCloseButtonClick();
		onEditContactButtonClick();
	}, [onCloseButtonClick, onEditContactButtonClick]);

	if (!isShowing) {
		return null;
	}

	return createPortal(
		<div className={styles['modal-wrapper']}>
			<ReactTooltip id="emailPhoneButton" place="bottom" />
			<dialog
				aria-labelledby="dialog-title"
				aria-modal="true"
				className={styles['contact-card']}
			>
				<header>
					<img
						src={contact.linkedUser?.profilePicture ?? conf.defaultUserAvatar}
						alt={t('formatting.contact.full_name', { ...contact })}
						height="124"
						width="124"
					/>
					<h2 id="dialog-title">{t('formatting.contact.full_name', { ...contact })}</h2>
					<a href={`mailto:${contact.emails[0]}`} target="_blank" rel="noopener noreferrer" disabled={contact.emails.length === 0}>
						{contact.emails?.[0]}
					</a>
					<a href={`tel:${phones?.[0]?.number}`} target="_blank" rel="noopener noreferrer" disabled={phones.length === 0}>
						{phones?.[0]?.number}
					</a>
					<div className={styles.actions}>
						<Button
							className="icon-only"
							name="phone"
							onClick={onPhoneClick}
							disabled={phones.length === 0}
							data-tooltip-id="emailPhoneButton"
							data-tooltip-content={phones.length === 0 ? t('contact.details_card_modal.no_phone') : ''}
						>
							<Phone />
						</Button>

						<Button
							className="icon-only"
							name="email"
							onClick={onEmailClick}
							disabled={contact.emails.length === 0}
							data-tooltip-id="emailPhoneButton"
							data-tooltip-content={contact.emails.length === 0 ? t('contact.details_card_modal.no_email') : ''}
						>
							<Mail />
						</Button>
					</div>
					<Button className={`icon-only ${styles['close-button']}`} onClick={onCloseButtonClick}>
						<X />
					</Button>
				</header>
				<main>
					{!inPartnerVisualisation && (
						<div>
							<p>{t('contact.fields.partners')}</p>
							<p>{contact.partners[0].name}</p>
						</div>
					)}
					{contact.position && (
						<div>
							<p>{t('contact.fields.position')}</p>
							<p>{contact.position.name}</p>
						</div>
					)}
					{!inPartnerVisualisation && contact.address && contact.postCode && contact.city && contact.country && (
						<div>
							<p>{t('contact.fields.address')}</p>
							<p>{t('formatting.contact.full_address', { ...contact })}</p>
						</div>
					)}
				</main>
				{!inPartnerVisualisation && (
					<footer>
						<Button className="secondary" onClick={onEditButtonClick}>
							{t('contact.links.edit')}
						</Button>
					</footer>
				)}
			</dialog>
		</div>,
		document.body
	);
};

ContactCardModal.propTypes = {
	contact: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string.isRequired,
		emails: PropTypes.arrayOf(PropTypes.string),
		phones: PropTypes.arrayOf(PropTypes.shape({
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			type: PropTypes.string,
		})),
		address: PropTypes.string,
		postCode: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		linkedUser: PropTypes.shape({
			id: PropTypes.string,
			profilePicture: PropTypes.string,
		}),
		partners: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		})),
		position: PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		}),
		relationTypes: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		})),
		title: PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		}),
	}).isRequired,
	isShowing: PropTypes.bool.isRequired,
	onCloseButtonClick: PropTypes.func.isRequired,
	onEditContactButtonClick: PropTypes.func.isRequired,
	inPartnerVisualisation: PropTypes.bool,
};
ContactCardModal.defaultProps = {
	inPartnerVisualisation: false,
};

export default ContactCardModal;
