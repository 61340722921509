import { t } from 'i18next';
import _ from 'lodash';

import { InvoiceMethods } from '../../../../../../constants/invoiceEnums';
import { QuotationTypes } from '../../../../../../constants/quotationEnums';
import TableEntityTypes from '../../constants/TableEntityTypes';
import {
	ExpenseRowsTableFields,
	ExpenseTotalsTableFields,
	InvoiceAdvancementStatusTableFields,
	InvoiceAdvancementTableFields,
	InvoiceTotalsTableFields,
	InvoiceUnitaryTableFields,
	QuotationArchitectColumnFields,
	QuotationArchitectTableFields,
	QuotationStandardTableFields,
	QuotationTotalsTableFields,
} from '../../constants/TableFields';

/**
 * @function
 * @name padNumber
 * @description Pads the given number with 0s to the provided number of digits
 *
 * @author Romaric Barthe
 *
 * @param {number} number	The number to pad
 * @param {number} digits	The size of the string to be created
 *
 * @returns {string} A string containing the padded number
 */
export const padNumber = (number, digits) => String(number).padStart(digits, '0');

/**
 * @function
 * @name formatExpenseForTemplates
 * @description Returns the provided expense's data formatted for templates following these tableFields:
 * 				{@link ExpenseRowsTableFields}
 *
 * @author Romaric Barthe
 *
 * @param {object} expense		The expense serialised.
 *
 * @returns {object}
 */
export const formatExpenseForTemplates = (expense) => ({
	data: {
		type: TableEntityTypes.EXPENSE_ROWS,
		rows: Object.values(expense.rows).map((row) => ({
			line: row.line,
			account: row.account?.name,
			designation: row.designation,
			date: row.date,
			project: row.project?.name,
			vatRate: row?.vatRate?.rate,
			netCost: row ? [row.netCost, t(`currency.${expense.currency?.name}`)] : '',
		})).sort((a, b) => a.line > b.line),
	},
	fields: Object.keys(ExpenseRowsTableFields),
});

/**
 * @function
 * @name formatExpenseTotalsForTemplates
 * @description Returns the provided expense's totals data formatted for templates following these tableFields:
 * 				{@link ExpenseTotalsTableFields}
 *
 * @author Romaric Barthe
 *
 * @param {object} expense		The invoice serialised.
 *
 * @returns {object}
 */
export const formatExpenseTotalsForTemplates = (expense) => ({
	data: {
		type: TableEntityTypes.EXPENSE_TOTALS,
		rows: [{
			grossTotal: [expense.grossTotal, t(`currency.${expense.currency.name}`)],
			vatTotal: [expense.vatTotal, t(`currency.${expense.currency.name}`)],
			netTotal: [expense.netTotal, t(`currency.${expense.currency.name}`)],
		}],
	},
	fields: Object.keys(ExpenseTotalsTableFields),
});

/**
 * @function
 * @name formatInvoiceForTemplates
 * @description Returns the provided invoice's data formatted for templates following these tableFields:
 * 				{@link InvoiceAdvancementTableFields} or {@link InvoiceUnitaryTableFields}
 *
 * @author Fornazaric Florian
 *
 * @param {object} invoice		The invoice serialised.
 *
 * @returns {object}
 */
export const formatInvoiceForTemplates = (invoice) => {
	const totals = { baseAmount: 0, alreadyInvoiced: 0, currentlyInvoiced: 0 };

	// 1. We loop on rows
	Object.values(invoice?.rows).sort((a, b) => a.line - b.line).forEach((row) => {
		totals.baseAmount += row.baseAmount;
		totals.alreadyInvoiced += ((row.baseAmount * row.alreadyInvoiced)
			- ((row.baseAmount * row.alreadyInvoiced * row.discount) / 100)) / 100;
		totals.currentlyInvoiced += ((row.baseAmount * row.currentlyInvoiced)) / 100;
	});

	// we calculate total amount already invoiced and total amount to invoice
	const percentageAlreadyInvoiced = _.round((totals.alreadyInvoiced * 100) / totals.baseAmount, 2);
	const percentageToInvoice = _.round((totals.currentlyInvoiced * 100) / totals.baseAmount, 2);

	const rows = Object.values(invoice?.rows).sort((a, b) => a.line - b.line).map((row) => {
		// ADVANCEMENT //
		if (invoice.method === InvoiceMethods.ADVANCEMENT) {
			const currentlyInvoiced = (row.baseAmount ?? 0) * ((row.currentlyInvoiced ?? 0) / 100);
			const alreadyInvoiced = (row.baseAmount ?? 0) * ((row.alreadyInvoiced ?? 0) / 100);

			// We calculate the total & discount beforehand for some clarity in the returned data
			const alreadyInvoicedWithDiscount = alreadyInvoiced - (alreadyInvoiced * (row?.discount ?? 0)) / 100;
			const discount = (currentlyInvoiced * (row?.discount ?? 0)) / 100;
			const total = currentlyInvoiced - discount;

			return {
				designation: row.offer?.name,
				description: row.designation,
				base_amount: row?.baseAmount ? [row.baseAmount, t(`currency.${invoice.currency.name}`)] : undefined,
				already_invoiced: row.alreadyInvoiced ? [row.alreadyInvoiced,
					alreadyInvoicedWithDiscount, t(`currency.${invoice.currency.name}`)] : undefined,
				currently_invoiced: row.currentlyInvoiced ? [row.currentlyInvoiced,
					currentlyInvoiced, t(`currency.${invoice.currency.name}`)] : undefined,
				discount: row?.discount ? [row.discount, discount, t(`currency.${invoice.currency.name}`)] : undefined,
				total: [total, t(`currency.${invoice.currency.name}`)],
			};
		}

		// UNITARY //
		// We calculate the total & discount beforehand for some clarity in the returned data
		const total = row.discount ? row.price * row.quantity - (row.price * row.quantity * row.discount) / 100
			: row.price * row.quantity;
		const discount = row?.discount ? (row.price * row.quantity * row.discount) / 100 : undefined;

		return {
			designation: row.offer?.name,
			description: row.designation,
			price: row ? [row.price, t(`currency.${invoice.currency.name}`)] : undefined,
			quantity: row?.quantity,
			discount: row?.discount ? [row.discount, discount, t(`currency.${invoice.currency.name}`)] : undefined,
			total: total ? [total, t(`currency.${invoice.currency.name}`)] : undefined,
		};
	});

	if (invoice.method === InvoiceMethods.ADVANCEMENT) {
		rows.push({
			description: t('total'),
			base_amount: [totals.baseAmount, t(`currency.${invoice.currency.name}`)],
			already_invoiced: [percentageAlreadyInvoiced, totals.alreadyInvoiced, t(`currency.${invoice.currency.name}`)],
			currently_invoiced: [percentageToInvoice, totals.currentlyInvoiced, t(`currency.${invoice.currency.name}`)],
		});
	}

	return {
		data: {
			type: invoice.method,
			totalStyle: { fontWeight: 500, backgroundColor: '#eeeeee' },
			hasTotalLine: invoice.method === InvoiceMethods.ADVANCEMENT,
			rows,
		},
		fields: Object.keys(invoice.method === InvoiceMethods.ADVANCEMENT ? InvoiceAdvancementTableFields : InvoiceUnitaryTableFields),
	};
};

/**
 * @function
 * @name formatInvoiceAdvancementStatusForTemplates
 * @description Returns the provided invoice's advancement status data formatted for templates following these tableFields:
 *
 * @author Fornazaric Florian
 *
 * @param {object} invoice		The invoice serialised.
 *
 * @returns {object}
 */
export const formatInvoiceAdvancementStatusForTemplates = (invoice) => {
	if (invoice.method !== InvoiceMethods.ADVANCEMENT) {
		return null;
	}

	const totals = { baseAmount: 0, alreadyInvoicedIncludingThis: 0, restToBeInvoiced: 0 };
	// 1. We loop on rows
	Object.values(invoice?.rows).sort((a, b) => a.line - b.line).forEach((row) => {
		totals.baseAmount += (row.baseAmount ?? 0);
		totals.alreadyInvoicedIncludingThis += (row.baseAmount ?? 0)
			* (((row.alreadyInvoiced ?? 0) + (row.currentlyInvoiced ?? 0)) / 100)
			* (1 - (row.discount ?? 0) / 100);
		totals.restToBeInvoiced += (row.baseAmount ?? 0) - (row.baseAmount ?? 0)
			* (((row.alreadyInvoiced ?? 0) + (row.currentlyInvoiced ?? 0)) / 100)
			* (1 - (row.discount ?? 0) / 100);
	});

	// we calculate total amount already invoiced and total amount to invoice
	const percentageAlreadyInvoicedIncludingThis = _.round((totals.alreadyInvoicedIncludingThis * 100) / totals.baseAmount, 2);
	const percentageRestToInvoice = _.round((totals.restToBeInvoiced * 100) / totals.baseAmount, 2);

	return ({
		data: {
			type: TableEntityTypes.INVOICE_ADVANCEMENT_STATUS,
			rows: [{
				totalBase: [totals.baseAmount, t(`currency.${invoice.currency.name}`)],
				totalAlreadyInvoiced: [percentageAlreadyInvoicedIncludingThis, totals.alreadyInvoicedIncludingThis, t(`currency.${invoice.currency.name}`)],
				totalAmountToInvoice: [percentageRestToInvoice, totals.restToBeInvoiced, t(`currency.${invoice.currency.name}`)],
			}],
		},
		fields: Object.keys(InvoiceAdvancementStatusTableFields),
	});
};

/**
 * @function
 * @name formatInvoiceTotalsForTemplates
 * @description Returns the provided invoice's totals data formatted for templates following these tableFields:
 * 				{@link InvoiceTotalsTableFields}
 *
 * @author Fornazaric Florian
 *
 * @param {object} invoice		The invoice serialised.
 *
 * @returns {object}
 */
export const formatInvoiceTotalsForTemplates = (invoice) => {
	const percentageVat = _.round((invoice.vatTotal * 100) / (invoice.grossTotalWithDiscount), 2);

	return ({
		data: {
			type: TableEntityTypes.INVOICE_TOTALS,
			rows: [{
				grossTotal: [invoice.grossTotal, t(`currency.${invoice.currency.name}`)],
				discount: invoice.discount ? [(invoice.discount * invoice.grossTotal) / 100, t(`currency.${invoice.currency.name}`)] : undefined,
				discountedTotal: invoice.grossTotalWithDiscount === invoice.grossTotal ? undefined : [invoice.grossTotalWithDiscount, t(`currency.${invoice.currency.name}`)],
				vatTotal: !invoice.withoutVat ? [percentageVat, invoice.vatTotal, t(`currency.${invoice.currency.name}`)] : undefined,
				netTotal: !invoice.withoutVat ? [invoice.netTotal, t(`currency.${invoice.currency.name}`)] : undefined,
			}],
		},
		fields: Object.keys(InvoiceTotalsTableFields),
	});
};

/**
 * @function
 * @name formatQuotationForTemplates
 * @description Returns the provided quotation's data formatted for templates following these tableFields:
 * 				{@link QuotationStandardTableFields} or {@link QuotationArchitectTableFields}
 *
 * @author Fornazaric Florian
 *
 * @param {object} quotation		The quotation serialised.
 *
 * @returns {object}
 */
export const formatQuotationForTemplates = (quotation) => {
	// ARCHITECT FORMAT //
	if (quotation.quotationType === QuotationTypes.ARCHI) {
		const totalColumns = [];
		const totalRows = [];
		const rowsValues = [];
		const totalForRows = { };

		// 1. We loop on rows
		Object.values(quotation.rows).sort((a, b) => a.line - b.line).forEach((row) => {
			// We loop on Stakeholders to generate values
			const values = [];
			Object.values(quotation.stakeholders).sort((a, b) => a.column - b.column).forEach((stakeholder) => {
				// Calculation of the stakeholder basis
				const stakeholderBasis = stakeholder.partnerProjectAmount * (stakeholder.partnerRatio / 100) * (1 - (stakeholder.partnerDiscount ?? 0) / 100);

				// We find the linked content
				const contentObj = Object.values(row.stakeholders).filter((rowStakeholder) => rowStakeholder.row === row.line && rowStakeholder.column === stakeholder.column);

				// If the value is not found, we add an 0, otherwise we add the calculated value with the ratio applied
				const content = contentObj.length > 0 ? stakeholderBasis * (contentObj[0].rate / 100) : 0;

				// The value is stored: column.colNumber - 1 because the colNumber starts at 1
				values[stakeholder.column - 1] = [content, t(`currency.${quotation.currency.name}`)];

				if (!totalColumns[stakeholder.column - 1]) {
					totalColumns[stakeholder.column - 1] = [0, t(`currency.${quotation.currency.name}`)];
				}

				if (!totalRows[row.line - 1]) {
					totalRows[row.line - 1] = 0;
				}

				totalColumns[stakeholder.column - 1][0] += content;
				totalRows[row.line - 1] += content;
			});
			rowsValues[row.line - 1] = values;
		});

		// 2. We loop on rows
		const rows = Object.values(quotation.rows).sort((a, b) => a.line - b.line).map((row) => ({
			phase: row.offer?.name,
			documents: row.designation,
			values: rowsValues[row.line - 1],
			total: [totalRows[row.line - 1], t(`currency.${quotation.currency.name}`)],
		}));

		// 3. We create totals
		totalForRows.documents = t('total');
		totalForRows.values = totalColumns;

		rows.push(totalForRows);

		return {
			data: {
				hasTotalLine: true,
				type: QuotationTypes.ARCHI,
				totalStyle: { fontWeight: 500, backgroundColor: '#eeeeee' },
				companies: Object.values(quotation.stakeholders).sort((a, b) => a.column - b.column).map((stakeholder) => ({
					sector: stakeholder.sector.name,
					name: stakeholder.partner.name,
				})),
				rows,
			},
			fields: Object.keys(QuotationArchitectTableFields),
		};
	}

	// NORMAL FORMAT //
	return {
		data: {
			type: QuotationTypes.STANDARD,
			rows: Object.values(quotation.rows).sort((a, b) => a.line - b.line).map((row) => {
				// We calculate the total & discount beforehand for some clarity in the returned data
				const discount = row.discount
					? (row.unitaryCost * row.quantity * row.ratio) / 100
					: undefined;
				const total = row.discount
					? row.unitaryCost * row.quantity - discount
					: row.unitaryCost * row.quantity;

				return {
					description: row.designation,
					unitaryCost: row ? [row.unitaryCost, t(`currency.${quotation.currency.name}`)] : undefined,
					quantity: row?.quantity,
					discount: row?.discount ? [row.discount, discount, t(`currency.${quotation.currency.name}`)] : undefined,
					total: [total, t(`currency.${quotation.currency.name}`)],
					vat: row?.vatRate?.rate,
				};
			}),
		},
		fields: Object.keys(QuotationStandardTableFields),
	};
};

/**
 * @function
 * @name formatQuotationTotalsForTemplates
 * @description Returns the provided quotation's totals data formatted for templates following these tableFields:
 * 				{@link QuotationTotalsTableFields}
 *
 * @author Fornazaric Florian
 *
 * @param {object} quotation		The quotation serialised.
 *
 * @returns {object}
 */
export const formatQuotationTotalsForTemplates = (quotation) => ({
	data: {
		type: 'QuotationTotals',
		rows: [{
			grossTotal: quotation.grossTotalWithDiscount ? [quotation.grossTotalWithDiscount, t(`currency.${quotation.currency.name}`)] : undefined,
			discount: quotation.discountTotal !== 0 && quotation.discountTotal !== null ? [quotation.discountTotal, t(`currency.${quotation.currency.name}`)] : undefined,
			baseTotal: [quotation.grossTotal, t(`currency.${quotation.currency.name}`)],
			vatTotal: [quotation.vatRate?.rate, quotation.vatTotal, t(`currency.${quotation.currency.name}`)],
			netTotal: [quotation.netTotal, t(`currency.${quotation.currency.name}`)],
		}],
	},
	fields: Object.keys(QuotationTotalsTableFields),
});

/**
 * @function
 * @name formatQuotationColumnsForTemplates
 * @description Returns the provided quotation's totals data formatted for templates following these tableFields:
 * 				{@link QuotationArchitectColumnFields}
 *
 * @author Romaric Barthe
 *
 * @param {object} quotation		The quotation serialised.
 *
 * @returns {object}
 */
export const formatQuotationColumnsForTemplates = (quotation) => {
	if (quotation.quotationType !== QuotationTypes.ARCHI) {
		return null;
	}

	if (!quotation.stakeholders) {
		return null;
	}

	return {
		data: {
			type: 'QuotationColumns',
			rows: Object.values(quotation.stakeholders).sort((a, b) => a.column - b.column).map((stakeholder) => ({
				partnerSector: stakeholder.sector ? stakeholder.sector.name : undefined,
				partnerName: stakeholder.partner ? stakeholder.partner.name : undefined,
				partnerAmount: [stakeholder.partnerProjectAmount, t(`currency.${quotation.currency.name}`)],
				partnerRatio: [stakeholder.partnerRatio, '%'],
				partnerDiscount: [stakeholder.partnerDiscount, '%'],
			})),
		},
		fields: Object.keys(QuotationArchitectColumnFields),
	};
};
