import { useTranslation } from 'react-i18next';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

/**
 * @name PageBreak
 * @description A page break PDF element
 *
 * @author Florian Fornazaric
 */
const PageBreak = ({ style }) => {
	const { t } = useTranslation();

	return (<span style={{ fontSize: style?.fontSize }}>{t('template.pdf.editor.components.page_break.content')}</span>);
};

PageBreak.propTypes = {
	style: PropTypes.object,
};

PageBreak.defaultProps = {
	style: undefined,
};

PageBreak.PdfElement = ({ style }) => <View style={{ ...style }} />;

PageBreak.PdfElement.propTypes = {
	style: PropTypes.object.isRequired,
};

PageBreak.PdfElement.displayName = 'PageBreak';

export default PageBreak;
