import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessRights } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';

import { Checkbox } from 'components/shared/forms/inputs';

import SingleRights from './SingleRights';

/**
 * @name SubModule
 * @description A form used to edit access rights.
 *
 * @author Romaric Barthe
 *
 * @param {object}	defaultValues		The loaded access rights on initialisation.
 * @param {string}	module				The current module.
 * @param {string}	subModule			The current submodule.
 * @param {array}	userAccessRights	The allocated rights (from the back).
 */
const SubModule = ({
	defaultValues,
	module,
	subModule,
	userAccessRights,
}) => {
	const { t } = useTranslation();

	const subModuleAccess = useMemo(() => AccessRights[module][subModule].VIEW, [module, subModule]);
	const subModuleRows = useMemo(() => {
		const enhancedRightsForSubModule = AccessRights[module][subModule].enhancedRights;
		const accessRightsAllowed = Object.values(userAccessRights).map((a) => a.name);
		const possibleAccessRights = enhancedRightsForSubModule && Object.values(enhancedRightsForSubModule);

		if (possibleAccessRights) {
			const accessRightsToDisplay = [];
			possibleAccessRights.forEach((a) => {
				if (accessRightsAllowed.includes(a) && !accessRightsToDisplay.includes(a)) {
					return accessRightsToDisplay.push(a);
				}

				return accessRightsToDisplay;
			});

			return accessRightsToDisplay;
		}

		return undefined;
	}, [module, subModule, userAccessRights]);

	const subModuleRef = useRef();

	const [isSubModuleChecked, setIsSubModuleChecked] = useState(false);

	/**
	 * @function
	 * @name onChangeSubModule
	 * @description A callback method used to switch between administrator and standard.
	 *
	 * @author Romaric Barthe
	 */
	const onChangeSubModule = useCallback((checked) => {
		setIsSubModuleChecked(checked);
	}, []);

	useEffect(() => {
		subModuleRef.current.setValue(isSubModuleChecked);
	}, [isSubModuleChecked]);

	useEffect(() => {
		if (defaultValues && defaultValues[subModuleAccess]) {
			subModuleRef.current.setValue(true);
		}
	}, [defaultValues, subModuleAccess]);

	return (
		<>
			{subModuleAccess && (
				<div className="sub-module">
					<Checkbox
						ref={subModuleRef}
						label={t(`access_definition.creation.inputs.${subModuleAccess}.label`)}
						name={subModuleAccess}
						labelFirst={false}
						onChange={onChangeSubModule}
					/>
				</div>
			)}
			{isSubModuleChecked && subModuleRows && (
				<SingleRights rows={Object.values(subModuleRows)} />
			)}
		</>
	);
};

SubModule.propTypes = {
	defaultValues: PropTypes.object,
	module: PropTypes.string.isRequired,
	subModule: PropTypes.string.isRequired,
	userAccessRights: PropTypes.object,
};

SubModule.defaultProps = {
	defaultValues: undefined,
	userAccessRights: undefined,
};

export default SubModule;
