import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { concatUserEmails } from 'lib/users/formatUserData';
import { fetchUserList } from 'redux/actions/users';
import { useUserListSelector, useUserTotalCountSelector } from 'redux/selectors/users';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import { AccessDefinitionsCell, ActionsCell } from 'components/users/userList';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name UserListPage
 * @description A list of all users accessible from the Setting app in sub part users.
 *
 * From this page, the user is able to view, interact and search for users.
 *
 * @author Romaric Barthe
 */
const UserListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const users = useUserListSelector();
	const totalCount = useUserTotalCountSelector();

	const canCreateUser = useAccessRight(AccessRights.humanResources.users.enhancedRights.CREATE_USER);

	const headers = useMemo(() => [
		{ accessor: 'username', Header: t('user.fields.user_name'), pinColumn: true, sorting: 'user.username' },
		{
			id: 'enabled',
			accessor: (row) => (row.enabled ? t('user.fields.enabled.yes') : t('user.fields.enabled.no')),
			Header: t('user.fields.enabled.header'),
			sorting: 'user.enabled',
		},
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('user.headers.actions') },
		{ id: 'accessDefinitions', Header: t('user.fields.access_definition'), Cell: AccessDefinitionsCell },
		{ accessor: 'historyRestriction', Header: t('user.fields.history_restriction'), sorting: 'user.historyRestriction' },
		{ accessor: 'locale', Header: t('user.fields.locale'), sorting: 'user.locale' },
		{ id: 'fullName', accessor: (row) => t('formatting.contact.full_name', { ...row.contact }), Header: t('user.fields.full_name'), sorting: 'contact.lastName' },
		{ id: 'emails', accessor: (row) => concatUserEmails(row), Header: t('user.fields.email'), sorting: 'contact.emails' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('user.fields.creation_date'), sorting: 'user.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('user.fields.update_date'), sorting: 'user.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('user.fields.creation_name'), sorting: 'user.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('user.fields.update_name'), sorting: 'user.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchUserList(params)));
	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('user');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.humanResources.users.userCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateUser && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('user.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={users}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="user"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('user.pages.list')} {...modalProps} />
		</div>
	);
};

export default UserListPage;
