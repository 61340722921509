import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';

import TargetIdentifier from './targetTabs/TargetIdentifier';
/**
 * @name TargetCreationForm
 * @description A form used to create a new target.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit        The method to trigger upon form submission.
 */
const TargetCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();
	const { formProps, buttonProps } = useSubmitButton();

	return (
		<div>
			<DynamicForm onSubmit={onSubmit} cleanFormData {...formProps}>
				<TargetIdentifier />
				<Button className="primary" type="submit" {...buttonProps}>{t('targeting.target.creation.action')}</Button>
			</DynamicForm>
		</div>
	);
};

TargetCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default TargetCreationForm;
