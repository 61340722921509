import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchQuotations
 * @description Retrieves a list of quotations from the API, specifying filters if present.
 *
 * @author Roland Margelidon
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchQuotations = (params) => post('/api/quotations', params);

/**
 * @function
 * @name fetchQuotationById
 * @description Retrieves an existing quotation from the API, using the quotationId in parameters.
 *
 * @author Roland Margelidon
 *
 * @param {string} quotationId      The id of the quotation we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchQuotationById = (quotationId) => get(`/api/quotation/${quotationId}`);

/**
 * @function
 * @name createQuotation
 * @description Creates a new quotation into the database.
 *
 * @author Roland Margelidon
 *
 * @param {object} quotationData    The data to create the quotation from.
 *
 * @returns {Promise}
 */
export const createQuotation = (quotationData) => post('/api/quotations/create', quotationData);

/**
 * @function
 * @name updateQuotation
 * @description Updates an existing quotation from the database.
 *
 * @author Roland Margelidon
 *
 * @param {object} quotationData    The data to update the quotation with.
 * @param {string} quotationId      The id to identify the quotation with.
 *
 * @returns {Promise}
 */
export const updateQuotation = (quotationData, quotationId) => put(`/api/quotation/${quotationId}`, quotationData);

/**
 * @function
 * @name deleteQuotation
 * @description Removes an existing quotation from the database.
 *
 * @author Roland Margelidon
 *
 * @param {string} quotationId      The id of the quotation we want to remove.
 *
 * @returns {Promise}
 */
export const deleteQuotation = (quotationId) => del(`/api/quotation/${quotationId}`);

/**
 * @function
 * @name exportQuotations
 * @description Export existing quotations from the database.
 *
 * @author Romaric Barthe
 *
 * @param {Object} data		The data to export the quotations.
 *
 * @returns {Promise}
 */
export const exportQuotations = (data) => post('/api/quotations/export', data, 'attachment');

/**
 * @function
 * @name editQuotation
 * @description Prepare edition of an existing quotation with the right edition number.
 *
 * @author Romaric Barthe
 *
 * @param {string} quotationId	The id of the quotation we wish to edit.
 * @param {string} editionDate	The edition date of the quotation.
 *
 * @returns {Promise}
 */
export const editQuotation = (quotationId, editionDate) => put(`/api/quotation/edit/${quotationId}`, { editionDate });

/**
 * @function
 * @name fetchAllForQuotationForm
 * @description Retrieves all information for quotation from the API.
 *
 * @author Romaric Barthe.
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchAllForQuotationForm = (params) => post('/api/quotation/form', params);
