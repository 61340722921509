import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { TextInput } from 'components/shared/inputs';

/**
 * @name TextCapability
 * @description A text capability, displayed inside the editor's toolbox
 *
 * @author Yann Hodiesne
 *
 * @param {string}		value		The current value of the capability
 * @param {string}		label		The label translation key to display
 * @param {function}	setValue	A callback updating the value associated to this capability
 * @param {boolean}		readOnly	Whether the capability can be edited by the user
 */
const TextCapability = ({ value, label, setValue, readOnly, ...otherProps }) => {
	const { t } = useTranslation();
	const inputId = uuid(); // used to tie the label to the input.

	return (
		<div className={`input-wrapper${readOnly ? ' disabled' : ''}`}>
			<label htmlFor={inputId}>
				{t(label)}
			</label>
			<TextInput
				id={inputId}
				value={value}
				onChange={({ target: { value: newValue } }) => setValue(newValue)}
				disabled={readOnly}
				{...otherProps}
			/>
		</div>
	);
};

TextCapability.propTypes = {
	value: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	readOnly: PropTypes.bool,
};

TextCapability.defaultProps = {
	readOnly: false,
};

export default TextCapability;
