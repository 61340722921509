import { forwardRef, useMemo } from 'react';
import { useForwardedRef } from 'lib/hooks';
import { contrast, hexToRgb } from 'lib/shared/contrastHelper';
import PropTypes from 'prop-types';

/**
 * @name TagColorPreviewComponent
 * @description A component to render in the preview of the colorpicker.
 * This is a forwardRef so we can place the color picker at the right position.
 *
 * @author Florian Fornazaric
 *
 * @param {object} style The style to apply to the component.
 */
const TagColorPreviewComponent = forwardRef(({ style, ...otherProps }, ref) => {
	const resolvedRef = useForwardedRef(ref);

	const componentStyle = useMemo(() => {
		const rgb = hexToRgb(style.backgroundColor);
		const contratValue = contrast([255, 255, 255], rgb);

		if (contratValue > 4.5) {
			return {
				...style,
				color: 'white',
				width: 'fit-content',
			};
		}

		return {
			...style,
			color: 'black',
			width: 'fit-content',
		};
	}, [style]);

	return (<span ref={resolvedRef} className="tag" style={{ ...componentStyle }} {...otherProps} />);
});

TagColorPreviewComponent.displayName = 'PreviewComponent';

TagColorPreviewComponent.propTypes = {
	style: PropTypes.object,
};

TagColorPreviewComponent.defaultProps = {
	style: {},
};

export default TagColorPreviewComponent;
