import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import camelcase from 'camelcase';
import { Modules } from 'constants/environmentEnums';
import { historyChannels } from 'constants/historyEnums';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatDateTime, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchHistoryList } from 'redux/actions/histories/histories';
import { useHistoryListSelector, useHistoryTotalCountSelector } from 'redux/selectors/histories/histories';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ActionsCell, ContactsCell, PartnersCell, ProjectsCell, TargetPartnersCell, UsersCell } from 'components/histories/historyList';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name HistoryListPage
 * @description A page displaying the list of all relation Types.
 *
 * From this page, the user is able to view, create and edit any relation Type.
 *
 * @author Audrey Clerc
 */
const HistoryListPage = () => {
	const [, appSplitUrl] = history.location.pathname.split('/');
	const appName = useMemo(() => camelcase(appSplitUrl), [appSplitUrl]);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const histories = useHistoryListSelector();
	const totalCount = useHistoryTotalCountSelector();

	const canCreateHistory = useAccessRight(AccessRights.commonSettings.history.enhancedRights.CREATE_HISTORY);

	const headers = useMemo(() => [
		{
			id: 'channel',
			accessor: (row) => t(`history.fields.channel_type.${row.channel}`),
			Header: t('history.fields.channel'),
			sorting: 'history.channel',
		},
		{ id: 'actions', Header: '', Cell: ActionsCell, name: t('history.headers.actions'), pinColumn: true },
		{ id: 'date', accessor: (row) => formatDateTime(row.date), Header: t('history.fields.date'), sorting: 'history.date' },
		{
			id: 'historyType',
			accessor: (row) => t(`history.fields.history_types.${row.historyType}`),
			Header: t('history.fields.history_type'),
			sorting: 'history.historyType',
		},
		{ accessor: 'note', Header: t('history.fields.note'), sorting: 'history.note' },
		{ id: 'statusObject', accessor: (row) => row.statusObject?.status.name, Header: t('history.fields.statusObject'), sorting: 'history.statusObject.status.name' },
		{ id: 'contacts', Header: t('history.fields.contacts'), Cell: ContactsCell },
		{ id: 'partners', Header: t('history.fields.partners'), Cell: PartnersCell },
		{ id: 'projects', Header: t('history.fields.projects'), Cell: ProjectsCell },
		{ id: 'collaborators', Header: t('history.fields.users'), Cell: UsersCell },
		{ id: 'targetPartners', Header: t('history.fields.target_partners'), Cell: TargetPartnersCell },
		{ id: 'remindedDate', accessor: (row) => formatDateTime(row.remindedDate), Header: t('history.fields.reminded_date'), sorting: 'history.remindedDate' },
		{ id: 'reminderDate', accessor: (row) => formatDateTime(row.reminderDate), Header: t('history.fields.reminder_date'), sorting: 'history.reminderDate' },
		{
			id: 'restriction',
			accessor: (row) => t(`history.fields.restriction_type.${row.restriction}`),
			Header: t('history.fields.restriction'),
			sorting: 'history.restriction',
		},
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('history.fields.creation_date'), sorting: 'history.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('history.fields.update_date'), sorting: 'history.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('history.fields.creation_name'), sorting: 'history.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('history.fields.update_name'), sorting: 'history.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => {
		if (appName === `${Modules.SALES}`) {
			const paramFiltered = {
				...params,
				filters: {
					conditions: [
						{ column: 'channel', criterion: 'is_not', value: historyChannels.HISTORY_CHANNEL_WORKFLOW },
						{ column: 'channel', criterion: 'is_not', value: historyChannels.HISTORY_CHANNEL_STATUS },
					],
					operator: 'and',
				},
			};
			dispatch(fetchHistoryList(paramFiltered));
		} else if (appName === `${Modules.SETTINGS}`) {
			const paramFiltered = {
				...params,
				filters: {
					conditions: [
						{ column: 'channel', criterion: 'is', value: historyChannels.HISTORY_CHANNEL_WORKFLOW },
						{ column: 'channel', criterion: 'is', value: historyChannels.HISTORY_CHANNEL_STATUS },
					],
					operator: 'or',
				},
			};
			dispatch(fetchHistoryList(paramFiltered));
		} else {
			dispatch(fetchHistoryList(params));
		}
	});

	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('history');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.sales.crm.historyCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateHistory && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('history.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				disableSelection
				headers={headers}
				rows={histories}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="history"
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('history.pages.list')} {...modalProps} />
		</div>
	);
};

export default HistoryListPage;
