/**
 * @constant
 * @name MarginsDefaults
 * @description An object containing all the default margins used by the editor based on word default margins
 *
 * @author Florian Fornazaric
 */
const MarginsDefaults = Object.freeze({
	LARGE: { top: 72, right: 144, bottom: 72, left: 144 }, // Corresponding to WORD large margins: 2.54cm, 5.08cm, 2.54cm, 5.08cm
	MEDIUM: { top: 72, right: 54, bottom: 72, left: 54 }, // Corresponding to WORD medium margins: 2.54cm, 1.91cm, 2.54cm, 1.91cm
	NORMAL: { top: 71, right: 71, bottom: 71, left: 71 }, // Corresponding to WORD normal margins: 2.5cm, 2.5cm, 2.5cm, 2.5cm
	SMALL: { top: 36, right: 36, bottom: 36, left: 36 }, // Corresponding to WORD small margins: 1.27cm, 1.27cm, 1.27cm, 1.27cm
});

export const MarginsOptions = Object.freeze({
	LARGE: 'LARGE',
	MEDIUM: 'MEDIUM',
	NORMAL: 'NORMAL',
	SMALL: 'SMALL',
	CUSTOM: 'CUSTOM',
});

export default MarginsDefaults;
