import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

import DataTableNavigation from './DataTableNavigation';
import PaginationSelect from './PaginationSelect';
import SelectionDataDisplayer from './SelectionDataDisplayer';

/**
 * @field DEFAULT_PAGINATION_ROWS_OPTIONS
 * @description A default array of options for the 'rows per page' select component.
 * @type {array}
 */
const DEFAULT_PAGINATION_ROWS_OPTIONS = [
	{ value: 5, label: 5 },
	{ value: 10, label: 10 },
	{ value: 25, label: 25 },
	{ value: 50, label: 50 },
	{ value: 100, label: 100 },
	{ value: 500, label: 500 },
];

/**
 * @name PaginationFooter
 * @description The pagination controls used by the table
 *
 * @author Yann Hodiesne
 *
 * @param {integer}	pageIndex					The current page index
 * @param {integer} pageCount					The total page count
 * @param {integer} rowsPerPage					The displayed rows count
 * @param {bool}	canGoToPreviousPage			Determines if the user can navigate to a prior page
 * @param {bool}	canGoToNextPage				Determines if the user can navigate to a subsequent page
 * @param {func}	gotoPage					Navigate to the page at given index
 * @param {func}	previousPage				Navigate to the previous page
 * @param {func}	nextPage					Navigate to the next page
 * @param {func}	setRowsPerPage				Change the displayed rows count
 * @param {bool}	selectionEnabled			Determines if the user can select rows in this table
 * @param {integer}	selectedRowsCount			The selected rows count
 * @param {func}	toggleUserPreferencesModal	A function meant to toggle the user preferences modal
 */
const PaginationFooter = ({
	pageIndex,
	pageCount,
	rowsPerPage,
	canGoToPreviousPage,
	canGoToNextPage,
	gotoPage,
	previousPage,
	nextPage,
	setRowsPerPage,
	selectionEnabled,
	selectedRowsCount,
	toggleUserPreferencesModal,
}) => {
	const { t } = useTranslation();

	// A curated version of pagination's rows per page options, without any illogical options (eg: 500 rows per page when we only have 12 in the database).
	const paginationOptions = useMemo(() => (
		[
			{ value: 0, label: t('components.table.pagination.show_all') },
			...DEFAULT_PAGINATION_ROWS_OPTIONS,
		]
	), [t]);

	return (
		<div aria-label="data-list-footer" className="data-list-footer">
			<DataTableNavigation
				canGoToPreviousPage={canGoToPreviousPage}
				canGoToNextPage={canGoToNextPage}
				currentPageIndex={pageIndex}
				navigateToNextPage={nextPage}
				navigateToPreviousPage={previousPage}
				navigateToPage={gotoPage}
				totalPagesCount={pageCount}
			/>
			<PaginationSelect
				htmlId="pagination-select"
				value={_.find(paginationOptions, (option) => (option.value === rowsPerPage)) ?? { value: rowsPerPage, label: rowsPerPage }}
				menuPlacement="auto"
				openMenuOnFocus
				options={paginationOptions}
				onChange={(selectedOption) => setRowsPerPage(Number(selectedOption.value))}
			/>
			{toggleUserPreferencesModal && (
				<Button className="subtle" data-testid="toggle_userpreferences" onClick={toggleUserPreferencesModal}>{t('components.table.user_preferences.show')}</Button>
			)}
			{selectionEnabled && (<SelectionDataDisplayer selectedRowsCount={selectedRowsCount} />)}
		</div>
	);
};

PaginationFooter.propTypes = {
	pageIndex: PropTypes.number.isRequired,
	pageCount: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
	canGoToPreviousPage: PropTypes.bool.isRequired,
	canGoToNextPage: PropTypes.bool.isRequired,
	gotoPage: PropTypes.func.isRequired,
	previousPage: PropTypes.func.isRequired,
	nextPage: PropTypes.func.isRequired,
	setRowsPerPage: PropTypes.func.isRequired,
	selectionEnabled: PropTypes.bool.isRequired,
	selectedRowsCount: PropTypes.number.isRequired,
	toggleUserPreferencesModal: PropTypes.func,
};

PaginationFooter.defaultProps = {
	toggleUserPreferencesModal: undefined,
};

export default PaginationFooter;
