import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * @name ComponentCapability
 * @description A component capability, displayed inside the editor's toolbox
 *
 * @author Yann Hodiesne
 *
 * @param {string}		value						The current value of the capability
 * @param {string}		label						The label translation key to display
 * @param {function}	setValue					A callback updating the value associated to this capability
 * @param {function}	component					The component to display inside this capability
 * @param {boolean}		[readOnly]					Whether the capability can be edited by the user, defaults to false
 */
const ComponentCapability = ({ value, label, setValue, readOnly, ...otherProps }) => {
	const { t } = useTranslation();

	return (
		<div className="component-capability">
			{label && (
				<div className="label">
					{t(label)}
				</div>
			)}
			<otherProps.component
				readOnly={readOnly}
				setValue={setValue}
				value={value}
				{...otherProps}
			/>
		</div>
	);
};

ComponentCapability.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
		PropTypes.number,
	]),
	label: PropTypes.string,
	setValue: PropTypes.func.isRequired,
	component: PropTypes.elementType.isRequired,
	readOnly: PropTypes.bool,
	onAddClick: PropTypes.func,
	disableOptionsTranslation: PropTypes.bool,
};

ComponentCapability.defaultProps = {
	value: null,
	label: null,
	readOnly: false,
	onAddClick: undefined,
	disableOptionsTranslation: false,
};

export default ComponentCapability;
