/**
 * @enum {string}
 * @readonly
 *
 * @name InvoiceColumnHeader
 * @description The column headers for unitary and advancement invoices.
 * @author Romaric Barthe
 */
const InvoiceColumnHeader = Object.freeze({
	unitary: Object.freeze({
		ACTIONS: 'actions',
		OFFER: 'offer',
		DESIGNATION: 'designation',
		ACCOUNT: 'account',
		QUANTITY: 'quantity',
		PRICE: 'price',
		VAT_RATE: 'vatRate',
		DISCOUNT: 'discount',
		TOTAL: 'total',
	}),
	advancement: Object.freeze({
		ACTIONS: 'actions',
		OFFER: 'offer',
		DESIGNATION: 'designation',
		ACCOUNT: 'account',
		BASE_AMOUNT: 'baseAmount',
		ALREADY_INVOICED: 'alreadyInvoiced',
		CURRENTLY_INVOICED: 'currentlyInvoiced',
		VAT_RATE: 'vatRate',
		DISCOUNT: 'discount',
		TOTAL: 'total',
	}),
});

/**
 * @enum {string}
 * @readonly
 *
 * @name InvoiceCreationTypes
 * @description The various creation types allowed for invoice creation.
 * @author Romaric Barthe
 */
const InvoiceCreationTypes = Object.freeze({
	EMPTY: 'empty',
	FROM_INVOICE: 'invoice',
	FROM_QUOTATION: 'quotation',
	REVERT: 'revert',
});

/**
 * @enum {string}
 * @readonly
 *
 * @name InvoiceMethods
 * @description The allowed methods for invoice creation.
 * @author Romaric Barthe
 */
const InvoiceMethods = Object.freeze({
	UNITARY: 'unitary',
	ADVANCEMENT: 'advancement',
});

export {
	InvoiceColumnHeader,
	InvoiceCreationTypes,
	InvoiceMethods,
};
