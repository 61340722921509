import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNumberFormatsLoadingSelector } from 'redux/selectors/numberFormats';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Checkbox, NumberInput, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { useUnusedEnvironmentTypes } from './function';

/**
 * @name NumberFormatCreationForm
 * @description A form used to create a new number format.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 * @author Matthieu Schaerlinger
 *
 * @param {func}	onSubmit		The method to trigger upon form submission.
 */
const NumberFormatCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();
	const { formProps, buttonProps } = useSubmitButton();

	const getNotUsedEnvironments = useUnusedEnvironmentTypes();
	const areNumberFormatsLoading = useNumberFormatsLoadingSelector();

	return (
		<DynamicForm onSubmit={onSubmit} {...formProps}>
			<Select
				isLoading={areNumberFormatsLoading}
				label={t('number_format.creation.inputs.environment.label')}
				labelKey="label"
				name="environment"
				options={getNotUsedEnvironments}
				placeholder={t('number_format.creation.inputs.environment.placeholder')}
				rules={{
					required: Validators.isRequired(t('number_format.creation.inputs.environment.validation_errors.required')),
				}}
				valueKey="value"
			/>
			<TextInput
				label={t('number_format.creation.inputs.root_name.label')}
				name="rootName"
				rules={{
					required: Validators.isRequired(t('number_format.creation.inputs.root_name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextInput
				label={t('number_format.creation.inputs.starting_number.label')}
				name="startingNumber"
				rules={{
					required: Validators.isRequired(t('number_format.creation.inputs.starting_number.validation_errors.required')),
				}}
				step="1"
				type="number"
			/>
			<NumberInput
				label={t('number_format.creation.inputs.digit_number.label')}
				name="digitNumber"
				min={0}
				max={20}
			/>
			<Checkbox
				key="yearPrefix"
				label={t('number_format.creation.inputs.year_prefix.label')}
				name="yearPrefix"
			/>
			<Checkbox
				key="monthPrefix"
				label={t('number_format.creation.inputs.month_prefix.label')}
				name="monthPrefix"
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('number_format.creation.action')}</Button>
		</DynamicForm>
	);
};

NumberFormatCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default NumberFormatCreationForm;
