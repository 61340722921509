import { memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { enableCompany } from 'redux/actions/companies';

import { ToggleSwitch } from 'components/shared/forms/inputs';
import { useModal } from 'components/shared/modal';
import { Pica } from 'components/shared/Typography';

import CompanyDisableModal from './CompanyDisableModal';

/**
 * @name CompanyActivationToggle
 * @description A toggle switch used to (de)activate a company inside its edition page.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {string}	companyId	The id of the company.
 * @param {bool}	enabled		Whether the company is currently enabled.
 * @param {string}	[label]		The label to display next to the toggle switch.
 */
const CompanyActivationToggle = ({ companyId, enabled, label }) => {
	const dispatch = useDispatch();

	const [enabledToggle, setEnabledToggle] = useState(enabled);
	const { isShowing, toggle: toggleModal } = useModal();

	const toggleCompany = useCallback(() => {
		// If we want to disable the company, ask for confirmation first
		if (enabledToggle) {
			toggleModal();
		} else {
			// Enabling the company
			dispatch(enableCompany({ enabled: true }, companyId));
			setEnabledToggle(true);
		}
	}, [toggleModal, dispatch, companyId, enabledToggle, setEnabledToggle]);

	const confirmDisable = useCallback(() => {
		// The user confirmed the disabling of the company
		toggleModal();
		dispatch(enableCompany({ enabled: false }, companyId));
		setEnabledToggle(false);
	}, [toggleModal, dispatch, companyId]);

	return (
		<>
			<div className="styled-activation-toggle">
				<ToggleSwitch id="enabled" name="enabled" checked={enabledToggle} disabled={!companyId} onChange={toggleCompany} display="inline-block" />
				<Pica display="inline-block" ml="1em">{label}</Pica>
			</div>
			<CompanyDisableModal isShowing={isShowing} confirm={confirmDisable} cancel={toggleModal} />
		</>
	);
};

CompanyActivationToggle.propTypes = {
	companyId: PropTypes.string,
	enabled: PropTypes.bool,
	label: PropTypes.string,
};

CompanyActivationToggle.defaultProps = {
	companyId: null,
	enabled: false,
	label: 'active',
};

export default memo(CompanyActivationToggle);
