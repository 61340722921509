import { memo, useContext, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DragAndDropTypes from '../../../constants/DragAndDropTypes';
import EditorContext from '../../../EditorContext';
/**
 * @name ElementType
 * @description An element type to drag onto the editor to create a new element
 *
 * @author Yann Hodiesne
 *
 * @param {object} elementType	The element type to display, from the constants inside {@link constants/ElementTypes}
 */
const ElementType = ({ elementType }) => {
	const { t } = useTranslation();

	const { readOnly } = useContext(EditorContext);

	const [{ isDragging }, drag, preview] = useDrag(() => ({
		type: DragAndDropTypes.PDF_ELEMENT_TYPE,
		item: {	...elementType },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
		canDrag: !readOnly,
	}));

	useEffect(() => {
		preview(getEmptyImage(), { captureDraggingState: true });
	}, [preview]);

	return (
		<div ref={drag} className={`element-type${isDragging ? ' dragging' : ''}`}>
			<div className="handle" />
			<div className="icon">
				<elementType.meta.icon size={24} />
			</div>
			<p className="title">
				{t(elementType.meta.name)}
			</p>
			<p className="description">
				{t(elementType.meta.description)}
			</p>
		</div>
	);
};

ElementType.propTypes = {
	elementType: PropTypes.shape({
		meta: PropTypes.shape({
			name: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

export default memo(ElementType);
