import { LAYOUT } from '../../../../constants/ElementTypes';
import ElementType from '../ElementType';

/**
 * @name LayoutTypes
 * @description Displays a list of element types inside the "layout" tab.
 *
 * @author Yann Hodiesne
 */
const LayoutTypes = () => (
	LAYOUT.map((item) => (
		<ElementType key={item.type} elementType={item} />
	))
);

export default LayoutTypes;
