import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatNumber, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchVatRateList } from 'redux/actions/vatRates';
import { useVatRateListSelector, useVatRateTotalCountSelector } from 'redux/selectors/vatRates';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import ActionsCell from 'components/vatRates/vatRateList/ActionsCell';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name VatRateListPage
 * @description A list of all vat rates accessible from the Setting app in sub part vat rates.
 *
 * From this page, the user is able to view, interact and search for vat rates.
 *
 * @author Romaric Barthe
 */
const VatRateListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const vatRates = useVatRateListSelector();
	const totalCount = useVatRateTotalCountSelector();

	const canCreateVatRate = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);

	const headers = useMemo(() => [
		{ id: 'rate', accessor: (row) => (`${formatNumber(row.rate, 2, 2)} %`), Header: t('vat_rate.fields.rate'), pinColumn: true, sorting: 'vatRate.rate' },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('vat_rate.fields.archived.yes') : t('vat_rate.fields.archived.no')),
			Header: t('vat_rate.fields.archived.header'),
			sorting: 'vatRate.archived',
		},
		{
			id: 'favorite',
			accessor: (row) => (row.favorite ? t('vat_rate.fields.favorite.yes') : t('vat_rate.fields.favorite.no')),
			Header: t('vat_rate.fields.favorite.header'),
			sorting: 'vatRate.favorite',
		},
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('vat_rate.headers.actions') },
		{ accessor: 'description', Header: t('vat_rate.fields.description'), sorting: 'vatRate.description' },
		{ id: 'account', accessor: (row) => row.account?.name, Header: t('vat_rate.fields.account'), sorting: 'vatRate.account' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('vat_rate.fields.creation_date'), sorting: 'vatRate.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('vat_rate.fields.update_date'), sorting: 'vatRate.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('vat_rate.fields.creation_name'), sorting: 'vatRate.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('vat_rate.fields.update_name'), sorting: 'vatRate.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchVatRateList(params)));
	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('vatRate');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.accounting.parameters.vatRates.vatRateCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateVatRate && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('vat_rate.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={vatRates}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="vat_rate"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('vat_rate.pages.list')} {...modalProps} />
		</div>
	);
};

export default VatRateListPage;
