/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Globals redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Breadcrumbs related actions
	PUSH_BREADCRUMB: '@GLOBALS/PUSH_BREADCRUMB',
	POP_TO_BREADCRUMB: '@GLOBALS/POP_TO_BREADCRUMB',
	REPLACE_BREADCRUMB: '@GLOBALS/REPLACE_BREADCRUMB',
	RESET_BREADCRUMBS: '@GLOBALS/RESET_BREADCRUMBS',
};

// //////////////////////////////////////////////////////// //
// ///////////// Breadcrumbs stacking actions ///////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name pushBreadcrumb
 * @description Action triggered anytime a breadcrumb needs to be added to the end of the stack.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const pushBreadcrumb = (location) => ({ type: ActionTypes.PUSH_BREADCRUMB, payload: location });

/**
 * @function
 * @name popToBreadcrumb
 * @description Action triggered anytime the breadcrumb stack needs to pop one or several values.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const popToBreadcrumb = (location) => ({ type: ActionTypes.POP_TO_BREADCRUMB, payload: location });

/**
 * @function
 * @name replaceBreadcrumb
 * @description Action triggered anytime the last breadcrumb of the stack needs to be replaced.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const replaceBreadcrumb = (location) => ({ type: ActionTypes.REPLACE_BREADCRUMB, payload: location });

/**
 * @function
 * @name resetBreadcrumbs
 * @description Action triggered anytime the breadcrumbs stack needs to be emptied.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const resetBreadcrumbs = (location) => ({ type: ActionTypes.RESET_BREADCRUMBS, payload: location });

// //////////////////////////////////////////////////////// //
// //////////////// Exported enumerations ///////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @name BreadcrumbsActions
 * @description Actions available on the breadcrumbs stack.
 *
 * Note: the values are compatible with history's actions (PUSH, POP, REPLACE)
 *
 * @author Yann Hodiesne
 */
export const BreadcrumbsActions = Object.freeze({
	PUSH: 'PUSH',
	POP: 'POP',
	REPLACE: 'REPLACE',
	RESET: 'RESET',
});

// //////////////////////////////////////////////////////// //
// /////////////// Exported action creators /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateBreadcrumbs
 * @description Method used to update the breadcrumbs stack.
 *
 * @author Yann Hodiesne
 *
 * @param {string} action	 The action associated to the breadcrumbs stack update, can be either PUSH, POP or REPLACE.
 * @param {object} location	 The location object used by the given action.
 */
export const updateBreadcrumbs = (action, location) => (dispatch) => {
	if (location?.state?.fromNavbar || location?.state?.fromHeader) {
		dispatch(resetBreadcrumbs(location));

		return;
	}
	switch (action) {
		case BreadcrumbsActions.PUSH:
			dispatch(pushBreadcrumb(location));
			break;
		case BreadcrumbsActions.POP:
			dispatch(popToBreadcrumb(location));
			break;
		case BreadcrumbsActions.REPLACE:
			dispatch(replaceBreadcrumb(location));
			break;
		case BreadcrumbsActions.RESET:
			dispatch(resetBreadcrumbs(location));
			break;
		default:
			break;
	}
};
