import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { Modal, ModalFooter } from 'components/shared/modal';
import { BodyCopy } from 'components/shared/Typography';

/**
 * @name CompanyDisableModal
 * @description Asks the user for confirmation before disabling a company
 *
 * @author Yann Hodiesne
 *
 * @param {bool} isShowing	Determines if the modal is currently being shown to the user
 * @param {func} confirm	The function to execute when the user clicks on confirm
 * @param {func} cancel		The function to execute when the user clicks on cancel
 */
const CompanyDisableModal = ({ isShowing, confirm, cancel }) => {
	const { t } = useTranslation();

	return (
		<Modal isShowing={isShowing} title={t('super_admin.company.removal.modal.title')}>
			<BodyCopy ml="1em">{t('super_admin.company.removal.modal.content')}</BodyCopy>
			<ModalFooter>
				<Button onClick={cancel}>
					{t('super_admin.company.removal.modal.cancel')}
				</Button>
				<Button className="danger" onClick={confirm}>
					{t('super_admin.company.removal.modal.confirm')}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

CompanyDisableModal.propTypes = {
	isShowing: PropTypes.bool.isRequired,
	confirm: PropTypes.func.isRequired,
	cancel: PropTypes.func.isRequired,
};

export default CompanyDisableModal;
