import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addStatus } from 'redux/actions/statuses';
import routes from 'routes';

import { StatusCreationForm } from 'components/statuses';

/**
 * @name StatusCreationPage
 * @description A page used to create a new status.
 *
 * @author Romaric Barthe
 */
const StatusCreationPage = () => {
	const dispatch = useDispatch();

	const handleFormSubmit = useCallback((formData) => {
		dispatch(addStatus(formData, routes.settings.statuses.list));
	}, [dispatch]);

	return (
		<StatusCreationForm onSubmit={handleFormSubmit} />
	);
};

export default StatusCreationPage;
