import { useAccessRight } from 'lib/shared/accessRights';
import { isDev } from 'lib/shared/environmentHelper';
import session from 'lib/shared/session';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

/**
 * @name useCanAccessRoute
 * @description A hook checking if the current user can access given route
 *
 * @author Yann Hodiesne
 *
 * @param {object} route	The route to check the access for
 *
 * @returns {bool}	Wheter the user can access given route
 */
const useCanAccessRoute = (route) => {
	// Here we check if the user can access the given route
	// The route can have restriction properties, instructing us to check if the user is authenticated or not before rendering
	// As sub-routes can contain routes with such restriction properties, we must always render the sub-routes if there are any
	// As such, canAccessRoute checks for presence of sub-routes, the `allowAnonymous` and `allowAuthenticated` properties, and the user session

	const hasAccessRight = useAccessRight(route.accessRight);

	const isLoggedIn = session.exists();
	const currentUser = useCurrentConnectedUserSelector();

	// If we are in a development environment, don't check for staging components
	// If we are in a production environment, we do not want to render a route for a staging component
	let canAccessRoute = hasAccessRight && (isDev() || !route.component?.staging);

	// If there are sub-routes, skip the redirection logic
	if (canAccessRoute && route.routes === undefined) {
		const allowAnonymous = route.allowAnonymous === true; // Default value is false
		const allowAuthenticated = route.allowAuthenticated !== false; // Default value is true
		const requireSuperAdmin = route.superAdmin === true; // Default value is false

		canAccessRoute = (isLoggedIn ? allowAuthenticated : allowAnonymous) && (requireSuperAdmin ? !!(currentUser?.superadmin) : true);
	}

	return canAccessRoute;
};

export default useCanAccessRoute;
