import { ActionTypes } from '../actions/paymentDelays';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_PAYMENT_DELAY_LIST_REQUEST:
		case ActionTypes.ADD_PAYMENT_DELAY_REQUEST:
		case ActionTypes.UPDATE_PAYMENT_DELAY_REQUEST:
		case ActionTypes.REMOVE_PAYMENT_DELAY_REQUEST:
		case ActionTypes.ARCHIVE_PAYMENT_DELAY_REQUEST:
		case ActionTypes.RESTORE_PAYMENT_DELAY_REQUEST:
		case ActionTypes.FAVORITE_PAYMENT_DELAY_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_PAYMENT_DELAY_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_PAYMENT_DELAY_LIST_FAILURE:
		case ActionTypes.FETCH_PAYMENT_DELAY_FAILURE:
		case ActionTypes.ADD_PAYMENT_DELAY_FAILURE:
		case ActionTypes.UPDATE_PAYMENT_DELAY_FAILURE:
		case ActionTypes.REMOVE_PAYMENT_DELAY_FAILURE:
		case ActionTypes.ARCHIVE_PAYMENT_DELAY_FAILURE:
		case ActionTypes.RESTORE_PAYMENT_DELAY_FAILURE:
		case ActionTypes.FAVORITE_PAYMENT_DELAY_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_PAYMENT_DELAY_SUCCESS:
		case ActionTypes.UPDATE_PAYMENT_DELAY_SUCCESS:
		case ActionTypes.ARCHIVE_PAYMENT_DELAY_SUCCESS:
		case ActionTypes.REMOVE_PAYMENT_DELAY_SUCCESS:
		case ActionTypes.RESTORE_PAYMENT_DELAY_SUCCESS:
		case ActionTypes.FAVORITE_PAYMENT_DELAY_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_PAYMENT_DELAY_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.paymentDelays],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_PAYMENT_DELAY_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.paymentDelay,
			};
		default:
			return state;
	}
};
