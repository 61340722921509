/**
 * @enum {string}
 * @readonly
 *
 * @name ProjectTabs
 * @description The different mandatory tabs
 *
 * @author Romaric Barthe
 */
const ProjectTabs = Object.freeze({
	IDENTITY: 'identity',
	CLIENT: 'client',
	RIGHTS: 'rights',
	ADDRESS: 'address',
	TEMPO: 'tempo',
});

/**
 * @enum {string}
 * @readonly
 *
 * @name ProjectInfos
 * @description The different optional tabs
 *
 * @author Romaric Barthe
 */
const ProjectInfos = Object.freeze({
	INVOICE: 'invoice',
	EXPENSE: 'expense',
	QUOTATION: 'quotation',
});

export {
	ProjectInfos,
	ProjectTabs,
};
