import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useBreadcrumbSelector
 * @description A selector to return the breadcrumbs from the global redux state.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {Array}
 */
const useBreadcrumbSelector = () => {
	const breadcrumbs = useSelector((state) => state?.globals?.breadcrumbs ?? [], shallowEqual);

	return breadcrumbs;
};

export {
	// eslint-disable-next-line import/prefer-default-export
	useBreadcrumbSelector,
};
