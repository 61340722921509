/* eslint-disable import/prefer-default-export */
import { get } from 'lib/shared/http';

/**
 * @function
 * @name fetchSectorTypes
 * @description Retrieves a list of sector types from the API.
 *
 * @author Audrey Clerc
 *
 * @returns {Promise}
 */
export const fetchSectorTypes = () => get('/api/sector-types');
