import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBlockLayout, useColumnOrder, usePagination, useResizeColumns, useRowSelect, useTable } from 'react-table';
import { useSticky } from 'react-table-sticky';
import _ from 'lodash';
import PropTypes from 'prop-types';

import RenderRow from './components/internal/RenderRow';
import TableHead from './components/TableHead';
import { Cell, PaginationFooter, Row } from './components';
import { useColumnSorting, usePaginationFooter, useRowSelectColumn } from './hooks';

/**
 * @name DynamicTable
 * @description A dynamic table component autowiring given data with sorting and advanced display capabilities.
 *
 * Note: rows is an array of the data you want to display to the user.
 *
 * Note: fetchData and selectionChanged must be wrapped with React.useCallback when used, or the browser will be stuck in an infinite loop!
 *
 * @author Yann Hodiesne
 *
 * @param {array}	headers						An array of the headers to display. Must be memoized.
 * @param {array}	rows						An array of the entities to display inside the table, needs to be updated when `fetchData` is called.
 * @param {integer}	rowsCount					The total number of rows available (in the whole set of data).
 * @param {string}	defaultSortingPrefix		The default prefix to apply to headers definitions without a custom sorting key.
 * @param {func}	[fetchData]					The function called when the display criteria changed and the rows needs to be updated.
 * @param {integer} [rowHeight]					The height of one row, in pixels (defaults to 50).
 * @param {integer} [rowOverscan]				How much rows to render outside of the view, to avoid blanks when scrolling (defaults to 5).
 * @param {integer} [defaultRowsPerPage]		The number of rows to display in each page (defaults to 50).
 * @param {func}	[selectionChanged]			The function called when the row selection changed
 * @param {array}	[headersOrder]				An array of the headers ID ordering.
 * @param {array}	[pinnedHeaders]				An array of the headers ID to be pinned to the left.
 * @param {array}	[hiddenHeaders]				An array of headers ID to hide from the table.
 * @param {func}	[showUserPreferencesModal]	A function used to toggle the user preferences modal.
 * @param {boolean}	[disableFetch]				A boolean disabling the fetching mechanism if set (will break pagination and sorting).
 * @param {boolean}	[disableSelection]			A boolean disabling the rows selection mechanism if set.
 * @param {boolean}	[disablePagination]			A boolean disabling the pagination mechanism if set.
 * @param {boolean} [loading]					A boolean indicating if a loading indicator should be displayed.
 */
const DynamicTable = ({
	headers,
	rows,
	fetchData,
	rowsCount,
	rowHeight,
	rowOverscan,
	defaultRowsPerPage,
	selectionChanged,
	headersOrder,
	pinnedHeaders,
	hiddenHeaders,
	defaultSortingPrefix,
	showUserPreferencesModal,
	disableFetch,
	disableSelection,
	disablePagination,
	selectedRowsIndices,
	// TODO: remove this exception once the prop is used
	// eslint-disable-next-line no-unused-vars
	loading,
}) => {
	// Generate the final columns definitions, turning `pinColumn` and `pinnedHeaders` into an acceptable parameter for react-table
	const columnsDefinition = useMemo(() => {
		const newHeaders = headers.map((header) => {
			const newHeader = { ...header };

			if (header.pinColumn === true || pinnedHeaders.includes(header.accessor) || pinnedHeaders.includes(header.id)) {
				newHeader.sticky = 'left';
			}

			if (header?.id === 'actions') {
				newHeader.width = newHeader.width ?? 200;
				newHeader.minWidth = newHeader.minWidth ?? 50;
			}

			return newHeader;
		});

		return newHeaders;
	}, [headers, pinnedHeaders]);

	// Get the final columns order, depending on pinned columns and given order
	const columnOrder = useMemo(() => {
		// Get the list of pinned headers ids
		const stickyHeaders = columnsDefinition.filter((header) => header.sticky === 'left').map((stickyHeader) => stickyHeader.accessor || stickyHeader.id);

		// Get the final columns order
		// Pinned columns are always displayed before the others, hence the rather convoluted code
		// This logic tries to prioritize pinned columns and to keep the given columns order at the same time
		const order = (
			headersOrder.filter((key) => stickyHeaders.includes(key)) // First, the sticky headers declared in headersOrder
				.concat(stickyHeaders.filter((key) => !headersOrder.includes(key))) // Second, the sticky headers not declared in headersOrder
				.concat(headersOrder.filter((key) => !stickyHeaders.includes(key))) // Third, the normal headers declared in headersOrder
				// Then, the normal headers not declared in headersOrder (we let react-table do them for us)
		);

		return order;
	}, [columnsDefinition, headersOrder]);

	// Used by usePaginationFooter
	// This variable cannot be stored inside the useTable's internal state because of the way react-table's pagination retrieves its parameters
	const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);

	// Store the latest params given to fetchData, to avoid unnecessary calls
	const fetchParams = useRef();

	const fetchDataWithParams = useCallback(() => {
		fetchData(fetchParams.current);
	}, [fetchData]);

	// List of the objects from the selected rows, based on indices given in params
	const selectedRowsObject = useMemo(() => Object.fromEntries(selectedRowsIndices.map((index) => [index, true])), [selectedRowsIndices]);

	// Initializes the table instance
	const {
		// rows: internalRows,
		page,
		prepareRow,
		getTableProps,
		getTableBodyProps,
		getPaginationFooterProps,
		headerGroups,
		// Rows selection
		selectedFlatRows,
		// Columns order
		setColumnOrder,
		// Hidden columns
		setHiddenColumns,
		// Virtualizer
		// renderVirtualRows,
		// Get the state from the table instance
		state: {
			pageIndex,
			sorting,
		},
	} = useTable(
		{
			columns: columnsDefinition,
			data: rows,
			initialState: {
				// Pagination state
				pageIndex: 0,
				pageSize: rowsPerPage !== 0 ? rowsPerPage : rows.length,
				// Columns order state
				columnOrder,
				// Hidden columns state
				hiddenColumns: hiddenHeaders,
				selectedRowIds: selectedRowsObject,
			},
			// Pagination options
			manualPagination: true,
			pageCount: Math.ceil(rowsPerPage !== 0 ? rowsCount / rowsPerPage : 1),
			// Virtualization options
			rowHeight,
			rowOverscan,
			// Rows selection options
			disableSelection,
			// Pagination footer options
			rowsPerPage,
			setRowsPerPage,
			// Columns sorting options
			defaultSortingPrefix,
			// Custom props
			fetchData: fetchDataWithParams,
		},
		// Native plugins hooks
		useBlockLayout,
		usePagination,
		useResizeColumns,
		useRowSelect,
		useColumnOrder,
		useSticky,
		// Custom plugins hooks
		// useVirtualization,
		useRowSelectColumn,
		usePaginationFooter,
		useColumnSorting,
	);

	// Listen for changes in pagination/sorting to request new data from the server
	useEffect(() => {
		// If fetching has been disabled, do nothing
		if (disableFetch) {
			return;
		}

		// Safe guard against oddities caused by the pagination
		if (pageIndex * rowsPerPage > rowsCount) {
			return;
		}

		const newParams = {
			skip: pageIndex * rowsPerPage,
			rowsPerPage,
			order: sorting,
		};

		if (!_.isEqual(fetchParams.current, newParams)) {
			fetchParams.current = newParams;

			// Trigger an update request for the displayed rows
			fetchDataWithParams();
		}
	}, [disableFetch, fetchParams, pageIndex, rowsPerPage, sorting, rowsCount, fetchDataWithParams]);

	// Listen for changes in rows selection
	useEffect(() => {
		if (selectionChanged) {
			// Send the currently selected rows to the parent component
			selectionChanged(selectedFlatRows.map((row) => row.original));
		}
	}, [selectionChanged, selectedFlatRows]);

	// Listen for changes in headers order and hidden headers
	useEffect(() => {
		setColumnOrder(columnOrder);
		setHiddenColumns(hiddenHeaders);
	}, [setColumnOrder, columnOrder, setHiddenColumns, hiddenHeaders]);

	// @TODO: set a dynamic aria-label depending on data we're displaying.
	// @TODO: add a check to assess whether rows can be expanded. If so, replace role="grid" with role="treegrid" dynamically.
	return (
		<>
			<div aria-label="data-list" className="data-list" {...getTableProps()} role="grid">
				<TableHead headerGroups={headerGroups} />
				<div role="rowgroup" {...getTableBodyProps()}>
					{page.map((row) => (
						<RenderRow
							key={row.id}
							row={row}
							prepareRow={prepareRow}
							RowComponent={Row}
							CellComponent={Cell}
						/>
					))}
					{/* {renderVirtualRows(Row, Cell, internalRows, page, prepareRow, {})} */}
				</div>
			</div>
			{disablePagination || <PaginationFooter {...getPaginationFooterProps()} toggleUserPreferencesModal={showUserPreferencesModal} />}
		</>
	);
};

DynamicTable.propTypes = {
	headers: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		accessor: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.func,
		]),
		Header: PropTypes.string.isRequired,
		pinColumn: PropTypes.bool,
		Cell: PropTypes.elementType,
	})).isRequired,
	rows: PropTypes.arrayOf(PropTypes.object).isRequired,
	fetchData: PropTypes.func,
	rowsCount: PropTypes.number.isRequired,
	rowHeight: PropTypes.number,
	rowOverscan: PropTypes.number,
	defaultRowsPerPage: PropTypes.number,
	selectionChanged: PropTypes.func,
	headersOrder: PropTypes.arrayOf(PropTypes.string),
	pinnedHeaders: PropTypes.arrayOf(PropTypes.string),
	hiddenHeaders: PropTypes.arrayOf(PropTypes.string),
	defaultSortingPrefix: PropTypes.string.isRequired,
	showUserPreferencesModal: PropTypes.func,
	disableFetch: PropTypes.bool,
	disableSelection: PropTypes.bool,
	disablePagination: PropTypes.bool,
	selectedRowsIndices: PropTypes.arrayOf(PropTypes.number),
	loading: PropTypes.bool,
};

DynamicTable.defaultProps = {
	fetchData: () => { throw new Error('DynamicTable: missing required prop `fetchData` (did you forget to add a `disableFetch` prop?)'); },
	rowHeight: 66, // 50px of actual height + 16px of padding
	rowOverscan: 5,
	defaultRowsPerPage: 50,
	selectionChanged: undefined,
	headersOrder: [],
	pinnedHeaders: [],
	hiddenHeaders: [],
	showUserPreferencesModal: undefined,
	disableFetch: false,
	disableSelection: false,
	disablePagination: false,
	selectedRowsIndices: [],
	loading: false,
};

export default memo(DynamicTable);
