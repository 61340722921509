import update from 'immutability-helper';

import { createElement, createRow } from './internals';
import setSelection from './setSelection';

/**
 * @function
 * @name appendElement
 * @description Appends a new row with a new element of the provided element type and focuses said element
 *
 * @author Yann Hodiesne
 *
 * @param {object} state					The current state
 * @param {object} payload					The payload of the action
 * @param {number} payload.elementType		The element type to use to populate the new row
 *
 * @returns {object} The updated state value
 */
const appendElement = (state, { elementType }) => {
	const result = {
		...state,
		elements: update(state.elements, {
			$push: [createRow(createElement(elementType))],
		}),
		isDirty: true,
	};

	return setSelection(result, { ids: [result.elements[result.elements.length - 1].children[0].id] });
};

export default appendElement;
