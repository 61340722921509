import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentPaymentDelaySelector
 * @description A selector to retrieve the current payment delay from the payment delay redux state
 *
 * @author Audrey Clerc
 * @author Yann Hodiesne
 *
 * @param {string} id The identifier of the current payment delay to wait for
 *
 * @returns {object}
 */
const useCurrentPaymentDelaySelector = (id) => {
	const paymentDelay = useSelector((state) => (state.paymentDelays?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (paymentDelay?.id === id ? paymentDelay : null), [id, paymentDelay]);

	return result;
};

/**
 * @function
 * @name usePaymentDelayListSelector
 * @description A selector to retrieve the payment delay list from the payment delay redux state.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {Array}
 */
const usePaymentDelayListSelector = () => {
	const paymentDelays = useSelector((state) => (state.paymentDelays?.items ?? []), shallowEqual);

	return paymentDelays;
};

/**
 * @function
 * @name useActivePaymentDelayList
 * @description A selector to retrieve the active payment delay list from the payment delay redux state.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {Array}
*/
const useActivePaymentDelayListSelector = () => {
	const paymentDelays = usePaymentDelayListSelector();

	const activePaymentDelays = useMemo(() => paymentDelays.filter((paymentDelay) => !paymentDelay.archived), [paymentDelays]);

	return activePaymentDelays;
};

/**
 * @function
 * @name usePaymentDelayTotalCountSelector
 * @description A selector to retrieve the totalCount value from the payment delay redux state.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {number|undefined}
 */
const usePaymentDelayTotalCountSelector = () => useSelector((state) => (state.paymentDelays?.totalCount ?? undefined), shallowEqual);

/**
 * @function
 * @name usePaymentDelayLoadingSelector
 * @description A selector to retrieve the loading state from the payment delay redux state.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {boolean}
 */
const usePaymentDelayLoadingSelector = () => useSelector((state) => state.paymentDelays?.isLoading ?? false);

/**
 * @function
 * @name useFavoritePaymentDelaySelector
 * @description A selector to retrieve the favorite paymentDelay from the payment delay redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useFavoritePaymentDelaySelector = () => {
	const paymentDelays = usePaymentDelayListSelector();

	const result = useMemo(() => paymentDelays.filter(({ favorite }) => favorite), [paymentDelays]);

	return result.length > 0 ? result[0] : undefined;
};

export {
	useActivePaymentDelayListSelector,
	useCurrentPaymentDelaySelector,
	useFavoritePaymentDelaySelector,
	usePaymentDelayListSelector,
	usePaymentDelayLoadingSelector,
	usePaymentDelayTotalCountSelector,
};
