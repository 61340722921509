import { useCallback } from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { extractOptionsEnums } from 'constants/exportEnums';
import PropTypes from 'prop-types';
import { exportContacts } from 'redux/actions/contacts/contacts';
import { exportExpenses } from 'redux/actions/expenses/expenses';
import { exportPartners } from 'redux/actions/partners/partners';
import { exportTargetPartners } from 'redux/actions/targetPartners/targetPartners';
import { exportTargets } from 'redux/actions/targets/targets';

import { Button } from 'components/shared/buttons';
import ExportForm from 'components/shared/etl/ExportForm';

import styles from 'styles/components/dataTables/_filterPanel.module.scss';

/**
 * @name DataListExtractPanel
 * @description A component used to handle extration on data.
 *
 * @author Romaric Barthe
 *
 * @param {function} 	onCloseButtonClick				The method to close the extract form.
 * @param {string} 		entity							The entity to retrieve the data from.
 * @param {boolean}		open							Whether the extraction form is open or not.
 * @param {array} 		selectedElementsIds				The selected elements.
 * @param {filters} 	filters							The filters.
 */
const DataListExtractPanel = ({ entity, onCloseButtonClick, open, selectedElementsIds, filters }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleSubmit = useCallback((formData) => {
		if (entity === extractOptionsEnums.export.dataTypes.PARTNERS) {
			return dispatch(exportPartners(formData));
		}
		if (entity === extractOptionsEnums.export.dataTypes.CONTACTS) {
			return dispatch(exportContacts(formData));
		}
		if (entity === extractOptionsEnums.export.dataTypes.EXPENSES) {
			return dispatch(exportExpenses(formData));
		}
		if (entity === extractOptionsEnums.export.dataTypes.TARGETS) {
			return dispatch(exportTargets(formData));
		}
		if (entity === extractOptionsEnums.export.dataTypes.TARGET_PARTNERS) {
			return dispatch(exportTargetPartners(filters?.parentId, formData));
		}

		return Promise.resolve();
	}, [dispatch, entity, filters]);

	return (
		<aside className={styles['filter-panel']} aria-hidden={!open}>
			<h4>{t('components.table.filters.actions.extract')}</h4>
			<Button className="icon-only" onClick={onCloseButtonClick}>
				<X />
			</Button>
			<ExportForm entity={entity} onSubmit={handleSubmit} selectedElementsIds={selectedElementsIds} filters={filters} />
		</aside>
	);
};

DataListExtractPanel.propTypes = {
	entity: PropTypes.string.isRequired,
	filters: PropTypes.object,
	onCloseButtonClick: PropTypes.func.isRequired,
	open: PropTypes.bool,
	selectedElementsIds: PropTypes.arrayOf(PropTypes.string),
};

DataListExtractPanel.defaultProps = {
	filters: {},
	open: false,
	selectedElementsIds: undefined,
};

export default DataListExtractPanel;
