import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { fetchTemplate } from 'redux/actions/templates';
import { useCurrentTemplateSelector } from 'redux/selectors/templates';

import { TemplateExport } from 'components/templates/pdf/editor';

/**
 * @name TemplatePdfExportPage
 * @description Page shown when a user wants to fill and export a PDF document
 *
 * @author Florian Fornazaric
 */
const TemplatePdfExportPage = () => {
	const { t } = useTranslation();
	const { id: templateId, entityId, editionDate, lastEditionDate } = useParams();
	const dispatch = useDispatch();

	const template = useCurrentTemplateSelector(templateId);

	const content = useMemo(() => (template?.content ? JSON.parse(template.content) : null), [template?.content]);
	const checkEditionDate = useMemo(() => editionDate ?? moment(new Date()).format('YYYYMMDD'), [editionDate]);
	const checkLastEditionDate = useMemo(() => lastEditionDate ?? '20000101', [lastEditionDate]);
	const formatEditionDate = useMemo(() => checkEditionDate && new Date(checkEditionDate.replace(/^(\d{0,4})(\d{0,2})(\d{0,2})/, '$1-$2-$3')), [checkEditionDate]);
	const formatLastEditionDate = useMemo(() => checkLastEditionDate && new Date(checkLastEditionDate.replace(/^(\d{0,4})(\d{0,2})(\d{0,2})/, '$1-$2-$3')), [checkLastEditionDate]);

	useEffect(() => {
		dispatch(fetchTemplate(templateId));
	}, [dispatch, templateId]);

	return (
		template
			? (
				<TemplateExport
					initialData={content}
					templateMetadata={{ description: template.description, name: template.name, type: template.type }}
					isExporting
					linkedEntityId={entityId}
					forwardedEditionDate={formatEditionDate}
					forwardedLastEditionDate={formatLastEditionDate}
				/>
			)
			: <p>{t('template.export.loading_template')}</p>
	);
};

export default TemplatePdfExportPage;
