import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { convertStringToFloat } from 'lib/shared/conversion';
import PropTypes from 'prop-types';
import { addAccount, fetchAccountList } from 'redux/actions/accounts';
import { useAccountActiveListSelector, useAccountsLoadingSelector } from 'redux/selectors/accounts';

import { AccountCreationForm } from 'components/accounts';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Select, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name VatRateCreationForm
 * @description A form used to create a new vat rate.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const VatRateCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAccountList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const accounts = useAccountActiveListSelector();
	const areAccountsLoading = useAccountsLoadingSelector();

	const { formProps, buttonProps } = useSubmitButton();
	// Props to pass down to the select input so that it will display an account creation modal when clicking on the "+" icon.
	const accountFormModal = useFormModal(AccountCreationForm, t('pages.settings.vat_rates.accounts.creation'), fetchAccountList, addAccount);

	const handleSubmit = useCallback((formData) => {
		const vatRateData = {
			...formData,
			rate: convertStringToFloat(formData.rate),
		};

		onSubmit(vatRateData);
	}, [onSubmit]);

	return (
		<DynamicForm onSubmit={handleSubmit} {...formProps}>
			<TextInput
				label={t('vat_rate.creation.inputs.rate.label')}
				name="rate"
				placeholder={t('vat_rate.creation.inputs.rate.placeholder')}
				rules={{
					required: Validators.isRequired(t('vat_rate.creation.inputs.rate.validation_errors.required')),
				}}
				step=".01"
				type="number"
			/>
			<TextArea
				label={t('vat_rate.creation.inputs.description.label')}
				name="description"
				placeholder={t('vat_rate.creation.inputs.description.placeholder')}
				type="text"
			/>
			<Select
				allowNull
				label={t('vat_rate.creation.inputs.account.label')}
				labelKey="name"
				name="account"
				options={accounts}
				valueKey="id"
				isLoading={areAccountsLoading}
				{...accountFormModal}
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('vat_rate.creation.action')}</Button>
		</DynamicForm>
	);
};

VatRateCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default VatRateCreationForm;
