import { toast } from 'react-toastify';
import * as VatRatesApi from 'api/vatRatesApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the VatRates redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific vat rate
	FETCH_VAT_RATE_REQUEST: '@VAT_RATES/FETCH_REQUEST',
	FETCH_VAT_RATE_SUCCESS: '@VAT_RATES/FETCH_SUCCESS',
	FETCH_VAT_RATE_FAILURE: '@VAT_RATES/FETCH_FAILURE',

	// Fetch a list of vat rates
	FETCH_VAT_RATE_LIST_REQUEST: '@VAT_RATES/FETCH_LIST_REQUEST',
	FETCH_VAT_RATE_LIST_SUCCESS: '@VAT_RATES/FETCH_LIST_SUCCESS',
	FETCH_VAT_RATE_LIST_FAILURE: '@VAT_RATES/FETCH_LIST_FAILURE',

	// Add a new vat rate to the database
	ADD_VAT_RATE_REQUEST: '@VAT_RATES/ADD_REQUEST',
	ADD_VAT_RATE_SUCCESS: '@VAT_RATES/ADD_SUCCESS',
	ADD_VAT_RATE_FAILURE: '@VAT_RATES/ADD_FAILURE',

	// Update an existing vat rate from the database
	UPDATE_VAT_RATE_REQUEST: '@VAT_RATES/UPDATE_REQUEST',
	UPDATE_VAT_RATE_SUCCESS: '@VAT_RATES/UPDATE_SUCCESS',
	UPDATE_VAT_RATE_FAILURE: '@VAT_RATES/UPDATE_FAILURE',

	// Remove an existing vat rate from the database
	REMOVE_VAT_RATE_REQUEST: '@VAT_RATES/REMOVE_REQUEST',
	REMOVE_VAT_RATE_SUCCESS: '@VAT_RATES/REMOVE_SUCCESS',
	REMOVE_VAT_RATE_FAILURE: '@VAT_RATES/REMOVE_FAILURE',

	// Archive a vat rate from the database
	ARCHIVE_VAT_RATE_REQUEST: '@VAT_RATES/ARCHIVE_REQUEST',
	ARCHIVE_VAT_RATE_SUCCESS: '@VAT_RATES/ARCHIVE_SUCCESS',
	ARCHIVE_VAT_RATE_FAILURE: '@VAT_RATES/ARCHIVE_FAILURE',

	// Restore a vat rate from the database
	RESTORE_VAT_RATE_REQUEST: '@VAT_RATES/RESTORE_REQUEST',
	RESTORE_VAT_RATE_SUCCESS: '@VAT_RATES/RESTORE_SUCCESS',
	RESTORE_VAT_RATE_FAILURE: '@VAT_RATES/RESTORE_FAILURE',

	// Mark an existing payment delay from the database as favorite
	FAVORITE_VAT_RATE_REQUEST: '@VAT_RATES/FAVORITE_REQUEST',
	FAVORITE_VAT_RATE_SUCCESS: '@VAT_RATES/FAVORITE_SUCCESS',
	FAVORITE_VAT_RATE_FAILURE: '@VAT_RATES/FAVORITE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Vat Rate list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchVatRateListRequest
 * @description Action triggered anytime a vat rate list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchVatRateListRequest = () => ({ type: ActionTypes.FETCH_VAT_RATE_LIST_REQUEST });

/**
 * @function
 * @name fetchVatRateListSuccess
 * @description Action triggered as a result to a successful vat rate list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} vatRates   The list of retrieved vat rates.
 * @param {number} totalCount The total amount of vat rates available in the database for the current user.
 *
 * @returns {object}
 */
const fetchVatRateListSuccess = ({ vatRates, totalCount }) => ({
	type: ActionTypes.FETCH_VAT_RATE_LIST_SUCCESS,
	payload: { vatRates, totalCount },
});

/**
 * @function
 * @name fetchVatRateListFailure
 * @description Action triggered as a result to a failed vat rate list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error 	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchVatRateListFailure = (error) => ({
	type: ActionTypes.FETCH_VAT_RATE_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific vat rate fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchVatRateRequest
 * @description Action triggered anytime a vat rate fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchVatRateRequest = () => ({ type: ActionTypes.FETCH_VAT_RATE_REQUEST });

/**
 * @function
 * @name fetchVatRateSuccess
 * @description Action triggered as a result to a successful vat rate fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} vatRate 		The retrieved vat rate.
 *
 * @returns {object}
 */
const fetchVatRateSuccess = ({ vatRate }) => ({
	type: ActionTypes.FETCH_VAT_RATE_SUCCESS,
	payload: { vatRate },
});

/**
 * @function
 * @name fetchVatRateFailure
 * @description Action triggered as a result to a failed vat rate fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error		The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchVatRateFailure = (error) => ({
	type: ActionTypes.FETCH_VAT_RATE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Vat Rate creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addVatRateRequest
 * @description Action triggered anytime a vat rate creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addVatRateRequest = () => ({ type: ActionTypes.ADD_VAT_RATE_REQUEST });

/**
 * @function
 * @name addVatRateSuccess
 * @description Action triggered as a result to a successful vat rate creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} vatRate		The created vat rate object.
 *
 * @returns {object}
 */
const addVatRateSuccess = ({ vatRate }) => ({
	type: ActionTypes.ADD_VAT_RATE_SUCCESS,
	payload: { vatRate },
});

/**
 * @function
 * @name addVatRateFailure
 * @description Action triggered as a result to a failed vat rate creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error		The exception sent back from the API.
 *
 * @returns {object}
 */
const addVatRateFailure = (error) => ({
	type: ActionTypes.ADD_VAT_RATE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Vat Rate update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateVatRateRequest
 * @description Action triggered anytime a vat rate update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateVatRateRequest = () => ({ type: ActionTypes.UPDATE_VAT_RATE_REQUEST });

/**
 * @function
 * @name updateVatRateSuccess
 * @description Action triggered as a result to a successful vat rate update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} vatRate		The updated vat rate object.
 *
 * @returns {object}
 */
const updateVatRateSuccess = ({ vatRate }) => ({
	type: ActionTypes.UPDATE_VAT_RATE_SUCCESS,
	payload: { vatRate },
});

/**
 * @function
 * @name updateVatRateFailure
 * @description Action triggered as a result to a failed vat rate update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error		The exception sent back from the API.
 *
 * @returns {object}
 */
const updateVatRateFailure = (error) => ({
	type: ActionTypes.UPDATE_VAT_RATE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Vat Rate removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeVatRateRequest
 * @description Action triggered anytime a vat rate deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeVatRateRequest = () => ({ type: ActionTypes.REMOVE_VAT_RATE_REQUEST });

/**
 * @function
 * @name removeVatRateSuccess
 * @description Action triggered as a result to a successful vat rate deletion API call.
 *
 * @author Romaric Barthe
 * @author Audrey Clerc
 *
 * @param {string} deletedVatRateId		The removed vat rate object id.
 *
 * @returns {object}
 */
const removeVatRateSuccess = ({ deletedVatRateId }) => ({
	type: ActionTypes.REMOVE_VAT_RATE_SUCCESS,
	payload: { deletedVatRateId },
});

/**
 * @function
 * @name removeVatRateFailure
 * @description Action triggered as a result to a failed vat rate deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error		The exception sent back from the API.
 *
 * @returns {object}
 */
const removeVatRateFailure = (error) => ({
	type: ActionTypes.REMOVE_VAT_RATE_FAILURE,
	payload: { error },
});

// /////////////////////////////////////////////////////////// //
// //////////////// Vat Rate archiving actions /////////////// //
// /////////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveVatRateRequest
 * @description Action triggered anytime a vat rate archiving call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archiveVatRateRequest = () => ({ type: ActionTypes.ARCHIVE_VAT_RATE_REQUEST });

/**
 * @function
 * @name archiveVatRateSuccess
 * @description Action triggered as a result to a successful vat rate archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archiveVatRateSuccess = () => ({
	type: ActionTypes.ARCHIVE_VAT_RATE_SUCCESS,
});

/**
 * @function
 * @name archiveVatRateFailure
 * @description Action triggered as a result to a failed vat rate archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveVatRateFailure = (error) => ({
	type: ActionTypes.ARCHIVE_VAT_RATE_FAILURE,
	payload: { error },
});

// /////////////////////////////////////////////////////////// //
// //////////////// Vat Rate restoring actions /////////////// //
// /////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreVatRateRequest
 * @description Action triggered anytime a vat rate restoring call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restoreVatRateRequest = () => ({ type: ActionTypes.RESTORE_VAT_RATE_REQUEST });

/**
 * @function
 * @name restoreVatRateSuccess
 * @description Action triggered as a result to a successful vat rate restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restoreVatRateSuccess = () => ({
	type: ActionTypes.RESTORE_VAT_RATE_SUCCESS,
});

/**
 * @function
 * @name restoreVatRateFailure
 * @description Action triggered as a result to a failed vat rate restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreVatRateFailure = (error) => ({
	type: ActionTypes.RESTORE_VAT_RATE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// /////////////////// Vat Rate favorite actions ////////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name favoriteVatRateRequest
 * @description Action triggered anytime a vat rate favorite call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const favoriteVatRateRequest = () => ({ type: ActionTypes.FAVORITE_VAT_RATE_REQUEST });

/**
  * @function
  * @name favoriteVatRateSuccess
  * @description	Action triggered as a result to a successful vat rate favorite API call.
  *
  * @author Romaric Barthe
  *
  * @param {array} vatRate		The updated vat rate object.
  *
  * @returns {object}
  */
const favoriteVatRateSuccess = () => ({ type: ActionTypes.FAVORITE_VAT_RATE_SUCCESS });

/**
  * @function
  * @name favoriteVatRateFailure
  * @description Action triggered as a result to a failed vat rate favorite API call.
  *
  * @author Romaric Barthe
  *
  * @param {object} error		The exception sent back from the API.
  *
  * @returns {object}
  */
const favoriteVatRateFailure = (error) => ({
	type: ActionTypes.FAVORITE_VAT_RATE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchVatRateList
 * @description Method used to update the vat rate list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params		The parameters used to match user filters.
 */
export const fetchVatRateList = (params) => (dispatch) => {
	dispatch(fetchVatRateListRequest());

	return VatRatesApi.fetchVatRates(params)
		.then(({ vatRates, totalCount }) => dispatch(fetchVatRateListSuccess({ vatRates, totalCount })))
		.catch((error) => dispatch(fetchVatRateListFailure(error)));
};

/**
 * @function
 * @name fetchVatRate
 * @description Method used to fetch the latest version of a specific vat rate.
 *
 * @author Romaric Barthe
 *
 * @param {string} vatRateId	The id of the vat rate we want to retrieve.
 */
export const fetchVatRate = (vatRateId) => (dispatch) => {
	dispatch(fetchVatRateRequest());

	return VatRatesApi.fetchVatRateById(vatRateId)
		.then(({ vatRate }) => dispatch(fetchVatRateSuccess({ vatRate })))
		.catch((error) => dispatch(fetchVatRateFailure(error)));
};

/**
 * @function
 * @name addVatRate
 * @description Method used to add a new vat rate instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} vatRateData 			The data to create the new vat rate from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addVatRate = (vatRateData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addVatRateRequest());

	return VatRatesApi.createVatRate(vatRateData)
		.then(({ vatRate }) => {
			toast.success(i18next.t('vat_rate.creation.toasts.success', { rate: vatRate.rate }));
			dispatch(addVatRateSuccess({ vatRate }));
			redirectOnSuccess(onSuccessRoute);

			return vatRate;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('vat_rate.creation.toasts.conflict'));
				dispatch(addVatRateFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('vat_rate.creation.toasts.error'));
				dispatch(addVatRateFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateVatRate
 * @description Method used to update an existing vat rate instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} vatRateData  		The object to update the vat rate with.
 * @param {string} vatRateId    		The id of the vat rate we want to update.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateVatRate = (vatRateData, vatRateId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateVatRateRequest());

	return VatRatesApi.updateVatRate(vatRateData, vatRateId)
		.then(({ vatRate }) => {
			dispatch(updateVatRateSuccess({ vatRate }));
			toast.success(i18next.t('vat_rate.edition.toasts.success', { rate: vatRate.rate }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('vat_rate.edition.toasts.conflict'));
				dispatch(updateVatRateFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('vat_rate.edition.toasts.error'));
				dispatch(updateVatRateFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeVatRate
 * @description Method used to remove an existing vat rate instance from the database.
 *
 * @author Romaric Barthe
 * @author Audrey Clerc
 *
 * @param {object} vatRate		The vat rate we want to remove from the database.
 * @param {string} vatRate.id	The id of the vat rate we want to remove from the database.
 * @param {string} vatRate.rate	The rate of the vat rate we want to remove from the database.
 */
export const removeVatRate = ({ id, rate }) => (dispatch) => {
	dispatch(removeVatRateRequest());

	return VatRatesApi.deleteVatRate(id)
		.then(({ deletedVatRateId }) => {
			dispatch(removeVatRateSuccess({ deletedVatRateId }));
			toast.success(i18next.t('vat_rate.deletion.toasts.success', { rate }));
		})
		.catch((error) => {
			dispatch(removeVatRateFailure(error));
			toast.error(i18next.t('vat_rate.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveVatRate
 * @description Method used to archive an existing vat rate instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} vatRate		The vat rate we want to archive.
 * @param {string} vatRate.id	The id of the vat rate we want to archive.
 * @param {string} vatRate.rate	The rate of the vat rate we want to archive.
 */
export const archiveVatRate = ({ id, rate }) => (dispatch) => {
	dispatch(archiveVatRateRequest());

	return VatRatesApi.updateVatRate({ archived: true }, id)
		.then(() => {
			dispatch(archiveVatRateSuccess());
			toast.success(i18next.t('vat_rate.archiving.toasts.success', { rate }));
		})
		.catch((error) => {
			dispatch(archiveVatRateFailure(error));
			toast.error(i18next.t('vat_rate.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreVatRate
 * @description Method used to restore an archived vat rate instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} vatRate		The vat rate we want to restore.
 * @param {string} vatRate.id	The id of the vat rate we want to restore.
 * @param {string} vatRate.rate	The rate of the vat rate we want to restore.
 */
export const restoreVatRate = ({ id, rate }) => (dispatch) => {
	dispatch(restoreVatRateRequest());

	return VatRatesApi.updateVatRate({ archived: false }, id)
		.then(() => {
			dispatch(restoreVatRateSuccess());
			toast.success(i18next.t('vat_rate.restoring.toasts.success', { rate }));
		})
		.catch((error) => {
			dispatch(restoreVatRateFailure(error));
			toast.error(i18next.t('vat_rate.restoring.toasts.error'));
		});
};

/**
 * @function
 * @name favoriteVatRate
 * @description	Method used to mark an existing vat rate instance from the database as favorite.
 *
 * @author Romaric Barthe
 *
 * @param {object} vatRate		The vat rate we want to mark as favorite.
 * @param {string} vatRate.id	The id of the vat rate we want to mark as favorite.
 * @param {string} vatRate.rate	The rate of the vat rate we want to mark as favorite.
 */
export const favoriteVatRate = ({ id, rate }) => (dispatch) => {
	dispatch(favoriteVatRateRequest());

	return VatRatesApi.updateVatRate({ favorite: true }, id)
		.then(() => {
			dispatch(favoriteVatRateSuccess());
			toast.success(i18next.t('vat_rate.edition.toasts.success', { rate }));
		})
		.catch((error) => {
			dispatch(favoriteVatRateFailure(error));
			toast.error(i18next.t('vat_rate.edition.toasts.error'));
		});
};
