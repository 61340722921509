import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addUser } from 'redux/actions/users';
import routes from 'routes';

import { UserCreationForm } from 'components/users';

/**
 * @name UserCreationPage
 * @description A page used to create a new user.
 *
 * @author Romaric Barthe
*/
const UserCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addUser(formData, routes.humanResources.users.list));
	}, [dispatch]);

	return (
		<UserCreationForm onSubmit={handleFormSubmit} />
	);
};

export default UserCreationPage;
