import { useCallback } from 'react';
import { Archive, Edit2, Eye, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { archiveOffer, removeOffer, restoreOffer } from 'redux/actions/offers/offers';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the OfferListPage table. (archive, delete and edit offer)
 *
 * @author Audrey Clerc
 * @author Matthieu Schaerlinger
 * @author Timothée Simon-Franza
 *
 * @param {object}		row						The object containing the data to interact with.
 * @param {boolean}		row.original.archived	The archived status of the offer.
 * @param {string}		row.original.id			The id of the offer.
 * @param {string}		row.original.name		The name of the offer.
 * @param {function}	fetchData				The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { archived, id, name } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingArchiveModal, toggle: toggleArchiveModal } = useModal();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditOffer = useAccessRight(AccessRights.sales.offers.enhancedRights.CREATE_OFFER);
	const canDeleteOffer = useAccessRight(AccessRights.sales.offers.enhancedRights.DELETE_OFFER);

	const [archiveButtonRef, archiveTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onArchiveModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the archive button.
	 *
	 * @author Audrey Clerc
	 */
	const onArchiveModalConfirmationButtonClick = useCallback(async () => {
		toggleArchiveModal();

		if (archived) {
			await dispatch(restoreOffer({ id, name }));
		} else {
			await dispatch(archiveOffer({ id, name }));
		}

		fetchData();
	}, [archived, dispatch, fetchData, id, name, toggleArchiveModal]);

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Audrey Clerc
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeOffer({ id, name }));
		fetchData();
	}, [dispatch, fetchData, id, name, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onEditPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.sales.offers.offerEdition, { id }));
	}, [id]);

	return (
		<>
			<Button className="icon-only" onClick={onEditPageRedirectionButtonClick} ref={editButtonRef}>
				{canEditOffer && !archived ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditOffer && !archived ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			{canEditOffer && (
				<>
					<Button className="icon-only" onClick={toggleArchiveModal} ref={archiveButtonRef}>
						<Archive />
					</Button>
					<Tooltip {...archiveTooltipProps}>
						{t('actions.archived')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingArchiveModal}
						message={t(`offer.${archived ? 'restoring' : 'archiving'}.confirmation_modal.content`)}
						confirm={onArchiveModalConfirmationButtonClick}
						cancel={toggleArchiveModal}
						confirmText={t(`offer.${archived ? 'restoring' : 'archiving'}.confirmation_modal.confirm`)}
						cancelText={t(`offer.${archived ? 'restoring' : 'archiving'}.confirmation_modal.cancel`)}
						titleText={t(`offer.${archived ? 'restoring' : 'archiving'}.confirmation_modal.title`)}
					/>
				</>
			)}
			{canDeleteOffer && !archived && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('offer.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('offer.deletion.confirmation_modal.confirm')}
						cancelText={t('offer.deletion.confirmation_modal.cancel')}
						titleText={t('offer.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			archived: PropTypes.bool,
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
