import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAnchor } from 'lib/hooks';
import { addCompany } from 'redux/actions/companies';
import routes from 'routes';

import ContactCreationForm from 'components/contacts/ContactCreationForm';
import { PartnerCreationForm } from 'components/partners';
import { Canon, DoublePica } from 'components/shared/Typography';

/**
 * @name CompanyCreationPage
 * @description	A page used to create a new company.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @requires NPM:react-redux
 * @requires redux/actions/companies:addCompany
 * @requires /components/contacts:ContactCreationForm
 * @requires /components/partners:PartnerCreationForm
 * @requires /routes:keys
 */
const CompanyCreationPage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [topAnchor, scrollToTop] = useAnchor();

	const [step, setStep] = useState(0);
	const [companyData, setCompanyData] = useState({ contact: {}, partner: {}, readyForSubmit: false });

	// Used to automatically call the API endpoint responsible for company creation whenever the form state is done updating.
	// Since the useState hook doesn't provide any callback, we need to use this workaround.
	useEffect(() => {
		if (companyData.readyForSubmit) {
			const { readyForSubmit, ...usefulData } = companyData;
			dispatch(addCompany(usefulData, routes.superAdmin.companyList));
		}
	}, [companyData, dispatch]);

	/**
	 * Method used to handle each form submission for this multi-step creation form.
	 *
	 * @param {object} formData		The data submitted by the form.
	 */
	const handleFormSubmit = (formData) => {
		if (step === 0) {
			const partnerData = {
				...formData,
				emails: formData.emails.map(({ value }) => value),
				// @TODO: update this line once we have implemented a way to choose between phone, mobile and fax phone tynes.
				phones: formData.phones.map(({ value }) => ({ number: value, type: 'phone' })),
			};
			setCompanyData({ ...companyData, partner: partnerData });
			setStep(step + 1);
			scrollToTop();
		} else if (step === 1) {
			const contactData = {
				...formData,
				// @TODO: update this line once we have implemented a way to choose between phone, mobile and fax phone tynes.
				phones: formData.phones.map((value) => ({ number: value, type: 'phone' })),
			};
			setCompanyData({ ...companyData, contact: contactData, readyForSubmit: true });
		}
	};

	return (
		<>
			{topAnchor}
			<Canon tag="h1" lineHeight="1em">{t('super_admin.company.creation.action')}</Canon>
			<DoublePica tag="h2" lineHeight="1em">{t('super_admin.company.creation.step', { currentStepIndex: step + 1, totalStepAmount: 2 })}</DoublePica>
			{step === 0 && <PartnerCreationForm onSubmit={(formData) => handleFormSubmit(formData)} inSuperAdmin />}
			{step === 1 && <ContactCreationForm onSubmit={(formData) => handleFormSubmit(formData)} inSuperAdmin />}
		</>
	);
};

export default CompanyCreationPage;
