import { TITLE } from '../constants/ElementTypes';

/**
 * @function
 * @name getTitleNumbers
 * @description Creates titleNumbers object for editor context.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {array} elements An array of PDF elements.
 *
 * @returns {object} An object mapping element id to a title number string.
 */
const getTitleNumbers = (elements) => {
	// Stores the value of each level of title
	const numberStack = [];
	const result = {};

	elements
		.flatMap((e) => e.children)
		.filter((e) => e.type === TITLE.type && e.showTitleNumber)
		.forEach((e) => {
			// If the title is less important than the previous one (deeper in the hierarchy)
			if (e.titleLevel > numberStack.length) {
				// Push enough numbers to reach the new level
				// The last number is 0 because it will be incremented below
				numberStack.push(...Array(e.titleLevel - numberStack.length - 1).fill(1), 0);
			} else if (e.titleLevel < numberStack.length) {
				numberStack.splice(e.titleLevel, numberStack.length - e.titleLevel);
			}

			// Increment top number and write current stack to the result
			numberStack[numberStack.length - 1]++;

			// Joined stack corresponds to the displayed number
			result[e.id] = numberStack.join('.');
		});

	return result;
};

export default getTitleNumbers;
