import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import PropTypes from 'prop-types';
import { addProject } from 'redux/actions/projects/projects';
import { fetchProjectsSelectList } from 'redux/actions/projects/projectsSelect';
import { addStatusObject, fetchStatusObjectList } from 'redux/actions/statusObjects';
import { fetchTargetingsForList } from 'redux/actions/targetings/targetingsList';
import { useProjectsSelectActiveListSelector, useProjectsSelectLoadingSelector } from 'redux/selectors/projects/projectsSelect';
import { useStatusObjectListByEnvironmentSelector } from 'redux/selectors/statusObjects';
import { useTargetingsForListSelector, useTargetingsListLoadingSelector } from 'redux/selectors/targetings/targetingsList';

import { ProjectCreationForm } from 'components/projects';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Checkbox, Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';
import { Checkbox as ClassicCheckbox } from 'components/shared/inputs';
import { Modal, useModal } from 'components/shared/modal';
import { StatusObjectCreationForm } from 'components/statusObjects';

/**
 * @name TargetingCreationForm
 * @description A form used to create a new targeting.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit        The method to trigger upon form submission.
 */
const TargetingCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const projects = useProjectsSelectActiveListSelector();
	const statusObjects = useStatusObjectListByEnvironmentSelector(Environments.TARGETING);
	const [statusObjectsIdBool, setStatusObjectsIdBool] = useState([]);
	const refDictionary = useMemo(() => Object.fromEntries(statusObjects.map((statusObject) => [statusObject.id, createRef()])), [statusObjects]);
	const { formProps, buttonProps } = useSubmitButton();

	const onChangeOne = useCallback((id, checked) => {
		if (statusObjectsIdBool.find((statusObject) => statusObject[0] === id)[1] !== checked) {
			setStatusObjectsIdBool((prev) => prev.map((statusObjectArray) => (statusObjectArray.includes(id) ? [statusObjectArray[0], checked] : statusObjectArray)));
		}
	}, [statusObjectsIdBool]);
	useEffect(() => {
		const statusObjectSelectorIds = statusObjects.map((statusObject) => statusObject.id);
		const statusObjectPageIds = statusObjectsIdBool.map((statusObject) => statusObject[0]);
		const statusObjectIdsNotInPage = statusObjectSelectorIds.filter((statusId) => !statusObjectPageIds.includes(statusId));
		if (statusObjects.length > 0) {
			if (statusObjectIdsNotInPage.length === statusObjects.length) {
				setStatusObjectsIdBool(statusObjectSelectorIds.map((statusObjectId) => [statusObjectId, false]));
			} else {
				setStatusObjectsIdBool((prec) => {
					statusObjectIdsNotInPage.forEach((statusObjectId) => {
						if (!prec.map((elt) => elt[0]).includes(statusObjectId)) {
							prec.push([statusObjectId, true]);
							refDictionary[statusObjectId].current.setValue(true);
						}
					});

					return prec;
				});
			}
		}
	}, [statusObjects, statusObjectsIdBool, refDictionary]);
	const targetings = useTargetingsForListSelector();
	const areProjectsLoading = useProjectsSelectLoadingSelector();
	const areTargetingsLoading = useTargetingsListLoadingSelector();

	const { isShowing: isCreateStatusObjectModalShowing, toggle: toggleCreateStatusObjectModal } = useModal();

	const projectsFiltered = useMemo(() => {
		let filtered = [];
		if (!areProjectsLoading && !areTargetingsLoading) {
			const targetingProjectsIds = targetings.map((targeting) => targeting.project).map((project) => project.id);
			filtered = projects.filter((project) => !targetingProjectsIds.includes(project.id));
		}

		return filtered;
	}, [areProjectsLoading, areTargetingsLoading, projects, targetings]);

	useEffect(() => {
		dispatch(fetchProjectsSelectList({ rowsPerPage: 0 }));
		dispatch(fetchStatusObjectList({ rowsPerPage: 0 }));
		dispatch(fetchTargetingsForList({ rowsPerPage: 0 }));
	}, [dispatch]);

	// Props to pass down to the select input so that it will display a project creation modal when clicking on the "+" icon.
	const projectCreationModal = useFormModal(ProjectCreationForm, t('targeting.creation.inputs.project.creation'), fetchProjectsSelectList, addProject);

	const onChangeAll = useCallback((checked) => {
		setStatusObjectsIdBool((prev) => prev.map(((statusObjectArray) => [statusObjectArray[0], checked])));
		statusObjects.forEach((elt) => {
			refDictionary[elt.id].current.setValue(checked);
		});
	}, [statusObjects, refDictionary]);

	const isAllChecked = useMemo(() => (statusObjectsIdBool.length > 0 ? !statusObjectsIdBool.map((statusObjectId) => statusObjectId[1]).includes(false) : false),
		[statusObjectsIdBool]);

	/**
	 * @function
	 * @name handleSubmit
	 * @description A function to submit the form.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object}	formData			The data of the forms.
	 */
	const handleSubmit = useCallback((formData) => {
		const params = {
			project: formData.project,
			// map rights for backend keeping only keys when right is checked
			statusObjects: statusObjectsIdBool.map((statusObject) => (statusObject[1] ? statusObject[0] : null)).filter((elt) => !!elt),
		};
		onSubmit(params);
	}, [onSubmit, statusObjectsIdBool]);

	const handleFormSubmit = useCallback(async (formData) => {
		await dispatch(addStatusObject(formData));
		toggleCreateStatusObjectModal();
		dispatch(fetchStatusObjectList({ rowsPerPage: 0 }));
	}, [dispatch, toggleCreateStatusObjectModal]);

	return (
		<DynamicForm onSubmit={handleSubmit} {...formProps}>
			<Select
				isLoading={areProjectsLoading && areTargetingsLoading}
				label={t('targeting.creation.inputs.project.label')}
				labelKey="name"
				name="project"
				options={projectsFiltered}
				rules={{
					required: Validators.isRequired(t('targeting.creation.inputs.project.validation_errors.required')),
				}}
				valueKey="id"
				{...projectCreationModal}
			/>
			<label>{t('targeting.edition.inputs.status_object.label')}</label>
			<div>
				<ClassicCheckbox
					label={t('targeting.edition.inputs.status_object_all.label')}
					labelFirst={false}
					checked={isAllChecked}
					onChange={({ target: { checked } }) => onChangeAll(checked)}
				/>
			</div>
			<Modal
				isShowing={isCreateStatusObjectModalShowing}
				title={t('targeting.creation.inputs.status_object.creation')}
				onClose={toggleCreateStatusObjectModal}
				additionalStyle={{ maxWidth: '1000px' }}
			>
				<StatusObjectCreationForm onSubmit={handleFormSubmit} defaultEnvironment={Environments.TARGETING} />
			</Modal>
			{statusObjects?.map((statusObject) => (
				<div key={statusObject.id} style={{ display: 'flex', alignItems: 'center' }}>
					<Checkbox
						label={statusObject.status.name}
						name={`statusObjects.${statusObject.id}`}
						labelFirst={false}
						ref={refDictionary[statusObject.id]}
						onChange={(checked) => onChangeOne(statusObject.id, checked)}
					/>
					<span
						className="color-dot-alone"
						style={{ backgroundColor: statusObject?.color || statusObject?.status?.defaultColor, marginLeft: '5px' }}
					/>
				</div>
			))}
			<Button className="secondary field-array-append" onClick={toggleCreateStatusObjectModal}>
				<PlusCircle />
				{t('targeting.creation.inputs.status_object.creation')}
			</Button>
			<Button className="primary" type="submit" {...buttonProps}>{t('targeting.creation.action')}</Button>
		</DynamicForm>
	);
};

TargetingCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default TargetingCreationForm;
