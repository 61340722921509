import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useAccessRightsListSelector } from 'redux/selectors/accessRights';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import { Module } from './accessDefinitionComponents';

/**
 * @name AccessDefinitionEditionForm
 * @description A form used to update an existing access definition.
 *
 * @author Romaric Barthe
 *
 * @param {object}		accessDefinition	The access definition object to edit information from.
 * @param {function}	onSubmit			The method to trigger upon form submission.
 */
const AccessDefinitionEditionForm = ({ accessDefinition, onSubmit }) => {
	const { t } = useTranslation();

	const userAccessRights = useAccessRightsListSelector();
	const modules = Object.keys(AccessRights);

	const canEditAccessDefinition = useAccessRight(AccessRights.humanResources.roles.enhancedRights.CREATE_ACCESS_DEFINITION);

	const defaultValues = useMemo(() => ({
		name: accessDefinition.name,
		...(
			accessDefinition.accessRights.reduce((rights, right) => {
				const rightsCopy = rights;
				rightsCopy[right.name] = true;

				return rightsCopy;
			}, {})
		),
	}), [accessDefinition]);

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const handleSubmit = useCallback((formData) => {
		if (canEditAccessDefinition) {
			const { name, ...rights } = formData;
			onSubmit({ name, accessRights: _.keys(_.pickBy(rights)) });
		} else {
			onSubmit(formData);
		}
	}, [canEditAccessDefinition, onSubmit]);

	return (
		<DynamicForm
			ref={formRef}
			cleanFormData
			defaultValues={defaultValues}
			disabled={!canEditAccessDefinition}
			onSubmit={handleSubmit}
			{...formProps}
		>
			<TextInput
				label={t('access_definition.edition.inputs.name.label')}
				name="name"
				placeholder={t('access_definition.edition.inputs.name.placeholder')}
				rules={{
					required: Validators.isRequired(t('access_definition.edition.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>

			{modules.map((module) => (
				<Module
					key={module}
					defaultValues={defaultValues}
					module={module}
					userAccessRights={userAccessRights}
				/>
			))}

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditAccessDefinition ? t('access_definition.edition.action') : t('access_definition.edition.close')}
			</Button>
		</DynamicForm>
	);
};

AccessDefinitionEditionForm.propTypes = {
	accessDefinition: PropTypes.shape({
		name: PropTypes.string.isRequired,
		accessRights: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
		})).isRequired,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default AccessDefinitionEditionForm;
