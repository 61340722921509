import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchRegistrations
 * @description Retrieves a list of registrations from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchRegistrations = (params) => post('/api/registrations', params);

/**
 * @function
 * @name fetchRegistrationById
 * @description Retrieves an existing registration from the API, using the registrationId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} registrationId	The id of the registration we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchRegistrationById = (registrationId) => get(`/api/registration/${registrationId}`);

/**
 * @function
 * @name createRegistration
 * @description Creates a new registration into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} registrationData	The data to create the registration from.
 *
 * @returns {Promise}
 */
export const createRegistration = (registrationData) => post('/api/registrations/create', registrationData);

/**
 * @function
 * @name updateRegistration
 * @description Updates an existing registration from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} registrationData	The data to update the registration with.
 * @param {string} registrationId		The id to identify the registration with.
 *
 * @returns {Promise}
 */
export const updateRegistration = (registrationData, registrationId) => put(`/api/registration/${registrationId}`, registrationData);

/**
 * @function
 * @name deleteRegistration
 * @description Removes an existing registration from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} registrationId	The id of the registration we want to remove.
 *
 * @returns {Promise}
 */
export const deleteRegistration = (registrationId) => del(`/api/registration/${registrationId}`);
