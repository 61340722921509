import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { filterCriteriaTypeEnum, filterOperatorEnum } from 'constants/filterEnums';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

import DataFilterFormFieldset from './DataFilterFormFieldset';

/**
 * @name DataFilterForm
 * @description A component used to handle rules in a filter.
 *
 * @author Timothée Simon-Franza
 *
 * @param {function} 	applyFilterCallBack				The method used to update the current filter rule.
 * @param {array} 		columns							The list of columns available to filter.
 */
const DataFilterForm = ({ applyFilterCallBack, columns }) => {
	const { t } = useTranslation();
	const [filter, setFilter] = useState({
		[uniqueId('filter-form-fielset-')]: {
			column: columns[0].accessor,
			criterionType: Object.values(filterCriteriaTypeEnum)[0],
			value: '',
			canOperatorBeChanged: false,
			operator: filterOperatorEnum.IF,
		},
	});

	/**
	 * @function
	 * @name onAddFilterRuleButtonClick
	 * @description Adds a new filter rule to the filter form.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onAddFilterRuleButtonClick = useCallback(() => {
		setFilter((previousState) => {
			// If there was no filter rule in the filter form, we add the first one.
			if (Object.keys(previousState).length === 0) {
				return {
					[uniqueId('filter-form-fielset-')]: {
						column: columns[0].accessor,
						criterionType: Object.values(filterCriteriaTypeEnum)[0],
						value: '',
						canOperatorBeChanged: false,
						operator: filterOperatorEnum.IF,
					},
				};
			}

			return {
				...previousState,
				[uniqueId('filter-form-fielset-')]: {
					column: columns[0].accessor,
					criterionType: Object.values(filterCriteriaTypeEnum)[0],
					value: '',
					canOperatorBeChanged: Object.values(previousState).length === 1,
					operator: Object.values(previousState).length > 1
						? Object.values(previousState)[1].operator
						: filterOperatorEnum.AND,
				},
			};
		});
	}, [columns]);

	/**
	 * @function
	 * @name duplicateFilterRule
	 * @description Duplicates the filter rule linked to the filterKey in parameter.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @param {string} filterKey The key of the filter rule to duplicate.
	 */
	const duplicateFilterRule = useCallback((filterKey) => {
		setFilter((previousState) => {
			const newFilterKey = uniqueId('filter-form-fielset-');

			const result = {
				...previousState,
				[newFilterKey]: {
					...previousState[filterKey],
				},
			};

			// We're duplicating the first rule as a second rule.
			// The operator is set to AND by default and can be changed by the user.
			if (Object.keys(previousState).length === 1) {
				result[newFilterKey] = {
					...result[newFilterKey],
					canOperatorBeChanged: true,
					operator: filterOperatorEnum.AND,
				};
			}

			// If there was already at least 2 rules, we duplicate set the
			// operator to match the second rule's operator and prevent
			// the user from changing it.
			if (Object.keys(previousState).length > 1) {
				result[newFilterKey] = {
					...result[newFilterKey],
					operator: Object.values(previousState)[1].operator,
					canOperatorBeChanged: false,
				};
			}

			return result;
		});
	}, []);

	/**
	 * @function
	 * @name deleteFilterRule
	 * @description Deletes the filter rule linked to the filterKey in parameter.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @param {string} filterKey The key of the filter rule to delete.
	 */
	const deleteFilterRule = useCallback((filterKey) => {
		setFilter((previousState) => {
			// Remove the key.
			const { [filterKey]: removedKey, ...remainingFilters } = previousState;

			// If the rule to be deleted is the last one, don't do anything
			if (Object.keys(previousState).length === 1) {
				return previousState;
			}

			// If first rule is removed, we set the new first rule's operator to IF and canOperatorBeChanged to false
			if (Object.keys(previousState).indexOf(filterKey) === 0) {
				remainingFilters[Object.keys(remainingFilters)[0]] = {
					...remainingFilters[Object.keys(remainingFilters)[0]],
					canOperatorBeChanged: false,
					operator: filterOperatorEnum.IF,
				};
			}

			// If the first or second rule is removed, we set the new second rule's canOperatorBeChanged to true.
			if ([0, 1].includes(Object.keys(previousState).indexOf(filterKey)) && Object.keys(remainingFilters)[1]) {
				remainingFilters[Object.keys(remainingFilters)[1]] = {
					...remainingFilters[Object.keys(remainingFilters)[1]],
					canOperatorBeChanged: true,
				};
			}

			return remainingFilters;
		});
	}, []);

	/**
	 * @function
	 * @name onFilterRuleChange
	 * @description Method triggered when a filter rule is changed.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @param {string} filterKey	The key of the filter rule to update.
	 * @param {string} fieldKey		The key of the field to update.
	 * @param {string} value		The new value to update the rule's field with.
	 */
	const onFilterRuleChange = useCallback((filterKey, fieldKey, value) => {
		setFilter((previousState) => {
			const newState = {
				...previousState,
				[filterKey]: {
					...previousState[filterKey],
					[fieldKey]: value,
				},
			};

			const isSecondFilterRule = Object.keys(previousState).indexOf(filterKey) === 1;

			// If the second filter rule's operator changes, we need to update all following rules' operators.
			if (fieldKey === 'operator' && Object.keys(previousState).length > 2 && isSecondFilterRule) {
				const result = Object.entries(newState).map(([key, filterRule], index) => {
					if (index < 2) {
						return [key, filterRule];
					}

					return [key, { ...filterRule, operator: value }];
				});

				return Object.fromEntries(result);
			}

			return newState;
		});
	}, []);

	return (
		<form name="filter">
			{Object.entries(filter).map(([filterRuleKey, filterRule]) => (
				<DataFilterFormFieldset
					key={filterRuleKey}
					columns={columns}
					filterKey={filterRuleKey}
					filterRule={filterRule}
					duplicateFilterRule={duplicateFilterRule}
					deleteFilterRule={deleteFilterRule}
					onFilterRuleChange={onFilterRuleChange}
				/>
			))}

			<button type="button" onClick={onAddFilterRuleButtonClick}>{t('components.table.filters.actions.add_rule')}</button>

			<Button type="button" onClick={() => applyFilterCallBack(filter)}>
				{t('components.table.filters.actions.filter')}
			</Button>
		</form>
	);
};

DataFilterForm.propTypes = {
	applyFilterCallBack: PropTypes.func.isRequired,
	columns: PropTypes.arrayOf(PropTypes.shape({
		accessor: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	})).isRequired,
};

export default DataFilterForm;
