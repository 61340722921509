import { ActionTypes } from '../actions/vatRates';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_VAT_RATE_LIST_REQUEST:
		case ActionTypes.ADD_VAT_RATE_REQUEST:
		case ActionTypes.UPDATE_VAT_RATE_REQUEST:
		case ActionTypes.REMOVE_VAT_RATE_REQUEST:
		case ActionTypes.ARCHIVE_VAT_RATE_REQUEST:
		case ActionTypes.RESTORE_VAT_RATE_REQUEST:
		case ActionTypes.FAVORITE_VAT_RATE_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_VAT_RATE_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_VAT_RATE_LIST_FAILURE:
		case ActionTypes.FETCH_VAT_RATE_FAILURE:
		case ActionTypes.ADD_VAT_RATE_FAILURE:
		case ActionTypes.UPDATE_VAT_RATE_FAILURE:
		case ActionTypes.REMOVE_VAT_RATE_FAILURE:
		case ActionTypes.ARCHIVE_VAT_RATE_FAILURE:
		case ActionTypes.RESTORE_VAT_RATE_FAILURE:
		case ActionTypes.FAVORITE_VAT_RATE_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_VAT_RATE_SUCCESS:
		case ActionTypes.UPDATE_VAT_RATE_SUCCESS:
		case ActionTypes.REMOVE_VAT_RATE_SUCCESS:
		case ActionTypes.ARCHIVE_VAT_RATE_SUCCESS:
		case ActionTypes.RESTORE_VAT_RATE_SUCCESS:
		case ActionTypes.FAVORITE_VAT_RATE_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_VAT_RATE_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.vatRates,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_VAT_RATE_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.vatRate,
			};
		default:
			return state;
	}
};
