// import { formatQuotationForTemplates } from 'lib/quotations/formatQuotationData';
import i18next from 'lib/shared/i18n';
import _ from 'lodash';

import { ImageSelectionButton } from '../components/customCapabilities';
import { formatPlaceholderDate } from '../functions/dateUtils';
import { addEntity } from '../reducer/actions';

import CapabilitiesNames from './CapabilitiesNames';
import { AutomaticDateValues, DateModes } from './Dates';
import { DefaultTableData, DefaultTableFields } from './DefaultTableContent';
import { DYNAMIC, STATIC_DATE } from './ElementTypes';
import EntityFieldTypes, { ContentFieldTypes, TableFieldTypes } from './EntityFieldTypes';
import EntityTypes from './EntityTypes';

/**
 * @constant
 * @name CapabilityType
 * @description An enumeration of the available types for a capability
 *
 * @author Yann Hodiesne
 */
export const CapabilityType = Object.freeze({
	TEXT: 'TEXT',
	BOOLEAN: 'BOOLEAN',
	OPTIONS: 'OPTIONS',
	DATE: 'DATE',
	COMPONENT: 'COMPONENT',
	TEXT_OBJECT: 'TEXT_OBJECT',
	FORMAT: 'FORMAT',
});

/**
 * @constant
 * @name CapabilityValue
 * @description An enumeration of the available values matchers for a capability
 *
 * @author Yann Hodiesne
 */
export const CapabilityValue = Object.freeze({
	NOT_NULL: (value) => !!(value ?? false),
	NULL: (value) => !(value ?? false),
});

/**
 * @function
 * @name createCapability
 * @description Helper to create capabilities on the fly
 *
 * @author Yann Hodiesne
 *
 * @param {string}				name					The internal name of the capability
 * @param {string}				label					The translation key used to display a label on top of its input
 * @param {string}				type					The type of the capability's value
 * @param {string}				property				The element property linked to this capability's value
 * @param {*}					defaultValue			The initial value of the capability
 * @param {boolean|function}	[readOnly=false]		Whether the capability can be edited by the user
 * @param {boolean|function}	[dynamic=false]			Whether the capability's value can be changed on export
 * @param {object}				[others={}]				The other properties to include inside the capability
 *
 * @returns {object} An object defining a capability with the provided properties
 */
const createCapability = (name, label, type, property, defaultValue, readOnly = false, dynamic = false, others = {}) => Object.freeze({
	name,
	label,
	type,
	property,
	defaultValue,
	readOnly: _.isFunction(readOnly) ? readOnly : () => readOnly,
	dynamic: _.isFunction(dynamic) ? dynamic : () => dynamic,
	props: {},
	...others,
});

/**
 * @name createTrigger
 * @description Helper to create a trigger defining capabilities to add when the current value matches the provided value or value matcher
 *
 * @author Yann Hodiesne
 *
 * @param {any|func}	matcher			The value or value matcher toggling the trigger
 * @param {array}		capabilities	The capabilities to add below the current one when the trigger is toggled
 *
 * @returns {object} An object defining a trigger with the provided properties
 */
const createTrigger = (matcher, capabilities) => Object.freeze({
	matcher: _.isFunction(matcher) ? matcher : (value) => value === matcher,
	capabilities,
});

/**
 * @name createSetter
 * @description Helper to create a setter defining updates to apply the provided property based on the element's current value
 *
 * @author Yann Hodiesne
 *
 * @param {string}		property	The property to set
 * @param {func}		matcher		The function used to check if the element should trigger the setter
 * @param {any|func}	setter		The value or setter function
 *
 * @returns {object} An object defining a setter with the provided properties
 */
const createSetter = (property, matcher, setter) => Object.freeze({
	property,
	matcher,
	setter: _.isFunction(setter) ? setter : () => setter,
});

/**
 * @function
 * @name createTextCapability
 * @description Helper to create text capabilities on the fly
 *
 * @author Yann Hodiesne
 *
 * @param {string}				name				The internal name of the capability
 * @param {string}				label				The translation key used to display a label on top of its input
 * @param {string}				property			The element property linked to this capability's value
 * @param {*}					[defaultValue='']	The initial value of the capability
 * @param {object}				[triggers=[]]		An array of triggers to use on this capability
 * @param {boolean|function}	[readOnly=false]	Whether the capability can be edited by the user
 * @param {boolean|function}	[dynamic=false]		Whether the capability's value can be changed on export
 *
 * @returns {object} An object defining a text capability with the provided properties
 */
const createTextCapability = (name, label, property, defaultValue = '', triggers = [], readOnly = false, dynamic = false) => (
	createCapability(name, label, CapabilityType.TEXT, property, defaultValue, readOnly, dynamic, { triggers })
);

/**
 * @function
 * @name createBooleanCapability
 * @description Helper to create boolean capabilities on the fly
 *
 * @author Yann Hodiesne
 *
 * @param {string} 				name					The internal name of the capability
 * @param {string}				label					The translation key used to display a label on top of its checkbox
 * @param {string}				property				The element property linked to this capability's value
 * @param {*}					[defaultValue=false]	The initial value of the capability
 * @param {object}				[triggers=[]]			An array of triggers to use on this capability
 * @param {boolean|function}	[readOnly=false]		Whether the capability can be edited by the user
 * @param {boolean|function}	[dynamic=false]			Whether the capability's value can be changed on export
 *
 * @returns {object} An object defining a boolean capability with the provided properties
 */
const createBooleanCapability = (name, label, property, defaultValue = false, triggers = [], readOnly = false, dynamic = false) => (
	createCapability(name, label, CapabilityType.BOOLEAN, property, defaultValue, readOnly, dynamic, { triggers })
);

/**
 * @function
 * @name createOptionsCapability
 * @description Helper to create options capabilities on the fly
 *
 * @author Yann Hodiesne
 *
 * @param {string} 				name				The internal name of the capability
 * @param {string}				label				The translation key used to display a label on top of its select
 * @param {string}				property			The element property linked to this capability's value
 * @param {*}					defaultValue		The initial value of the capability
 * @param {array|function}		options				An array of options or a function to generate one for the select
 * @param {array}				[triggers=[]]		An array of triggers to use on this capability
 * @param {array}				[setters=[]]		An array of setters to use on this capability
 * @param {boolean|function}	[readOnly=false]	Whether the capability can be edited by the user
 * @param {boolean|function}	[dynamic=false]		Whether the capability's value can be changed on export
 * @param {object}				[props={}]			The other properties to include inside the select
 *
 * @returns {object} An object defining an options capability with the provided properties
 */
const createOptionsCapability = (name, label, property, defaultValue, options, triggers = [], setters = [], readOnly = false, dynamic = false, props = {}) => (
	createCapability(name, label, CapabilityType.OPTIONS, property, defaultValue, readOnly, dynamic, {
		options: _.isFunction(options) ? options : () => options,
		setters,
		triggers,
		props,
	})
);

/**
 * @function
 * @name createComponentCapability
 * @description Helper to create component capabilities on the fly
 *
 * @author Yann Hodiesne
 *
 * @param {string} 				name				The internal name of the capability
 * @param {string}				label				The translation key used to display a label on top of its component
 * @param {string}				property			The element property linked to this capability's value
 * @param {*}					defaultValue		The initial value of the capability
 * @param {array|function}		component			A component to render for the capability
 * @param {array}				[triggers=[]]		An array of triggers to use on this capability
 * @param {array}				[setters=[]]		An array of setters to use on this capability
 * @param {boolean|function}	[readOnly=false]	Whether the capability can be edited by the user
 * @param {boolean|function}	[dynamic=false]		Whether the capability's value can be changed on export
 * @param {object}				[props={}]			The other properties to include for its component
 *
 * @returns {object} An object defining an component capability with the provided properties
 */
const createComponentCapability = (name, label, property, defaultValue, component, triggers = [], setters = [], readOnly = false, dynamic = false, props = {}) => (
	createCapability(name, label, CapabilityType.COMPONENT, property, defaultValue, readOnly, dynamic, {
		component,
		setters,
		triggers,
		props,
	})
);

/**
 * @function
 * @name createDateCapability
 * @description Helper to create date capabilities on the fly
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string}				name				The internal name of the capability
 * @param {string}				label				The translation key used to display a label on top of its input
 * @param {string}				property			The element property linked to this capability's value
 * @param {*}					[defaultValue='']	The initial value of the capability
 * @param {object}				[triggers=[]]		An array of triggers to use on this capability
 * @param {boolean|function}	[readOnly=false]	Whether the capability can be edited by the user
 * @param {boolean|function}	[dynamic=false]		Whether the capability's value can be changed on export
 *
 * @returns {object} An object defining a date capability with the provided properties
 */
const createDateCapability = (name, label, property, defaultValue = '', triggers = [], readOnly = false, dynamic = false) => (
	createCapability(name, label, CapabilityType.DATE, property, defaultValue, readOnly, dynamic, { triggers })
);

/**
 * @function
 * @name createTextObjectCapability
 * @description Helper to create text object capabilities on the fly
 *
 * @author Florian Fornazaric
 *
 * @param {string}				name				The internal name of the capability
 * @param {string}				label				The translation key used to display a label on top of its input
 * @param {string}				property			The element property linked to this capability's value
 * @param {object}				[triggers=[]]		An array of triggers to use on this capability
 * @param {array}				[setters=[]]		An array of setters to use on this capability
 * @param {boolean|function}	[readOnly=false]	Whether the capability can be edited by the user
 * @param {boolean|function}	[dynamic=false]		Whether the capability's value can be changed on export
 *
 * @returns {object} An object defining a text object capability with the provided properties
 */
const createTextObjectCapability = (name, label, property, triggers = [], setters = [], readOnly = false, dynamic = false) => (
	createCapability(name, label, CapabilityType.TEXT_OBJECT, property, undefined, readOnly, dynamic, { triggers, setters })
);

/**
 * @function
 * @name createFormatCapability
 * @description Helper to create format capabilities on the fly
 *
 * @author Florian Fornazaric
 *
 * @param {string}				name					The internal name of the capability
 * @param {string}				label					The translation key used to display a label on top of its input
 * @param {string}				property				The element property linked to this capability's value
 * @param {string}				defaultValue			The initial value of the capability
 * @param {object}				[triggers=[]]			An array of triggers to use on this capability
 * @param {boolean}				[fontSizeOnly=false]	Whether the capability can only change the font size
 *
 * @returns {object} An object defining a text object capability with the provided properties
 */
const createFormatCapability = (name, label, property, triggers = [], fontSizeOnly = false) => (
	createCapability(name, label, CapabilityType.FORMAT, property, { alignment: 'start' }, false, false, { triggers, fontSizeOnly })
);

/**
 * @constant
 * @name TextObjectLinkContentSetter
 * @description The setter to use when the linked text object content is updated
 *
 * @author Florian Fornazaric
 */
const TextObjectLinkContentSetter = createSetter(
	'content',
	() => true,
	({ element }) => {
		if (element.textObjectLink) {
			return element.textObjectLink.text;
		}

		return i18next.t('template.pdf.editor.default.link_message');
	}
);

/**
 * @constant
 * @name LinkedEntityContentSetter
 * @description The setter to use when the linked entity content is updated
 *
 * @author Yann Hodiesne
 */
export const LinkedEntityContentSetter = createSetter(
	'content',
	(element) => element.linkable,
	({ element, editorContext: { entities }, t }) => {
		const entity = entities.find(({ id }) => id === element.linkedEntity);

		if (!entity) {
			return '(-) -> (-)';
		}

		const property = Object.values(EntityTypes)
			.find(({ type }) => type === entity.type)?.fields
			.find(({ id }) => id === element.linkedProperty);

		if (!property) {
			return `${entity.name} -> (-)`;
		}

		return `${entity.name} -> ${t(property.name)}`;
	}
);

/**
 * @constant
 * @name LinkedTableEntityContentSetter
 * @description The setter to use when the linked table entity content is updated
 *
 * @author Florian Fornazaric
 */
export const LinkedTableEntityContentSetter = createSetter(
	'content',
	(element) => element.tableLinkedEntity,
	({ element, editorContext: { entities } }) => {
		const entity = entities.find(({ id }) => id === element.tableLinkedEntity);
		if (!entity) {
			return { fields: Object.keys(DefaultTableFields), ...DefaultTableData };
		}
		const entityType = EntityTypes[entity.type].fields.find(({ id }) => id === element.tableLinkedProperty);

		if (!entityType) {
			return { fields: Object.keys(DefaultTableFields), ...DefaultTableData };
		}

		const scheme = Object.keys(entityType?.scheme);

		return ({
			fields: scheme,
			...entityType?.defaultValues,
		});
	}
);

/**
 * @constant
 * @name Capabilities
 * @description An enumeration used to define the capabilities each element type can handle.
 *
 * @author Yann Hodiesne
 */
const Capabilities = Object.freeze({
	// The user can manually edit its content using a text box
	// Read-only if the element is linked to an entity
	EDITABLE: createTextCapability(
		CapabilitiesNames.EDITABLE,
		'template.pdf.capabilities.editable.label',
		'content',
		undefined,
		[],
		({ linkable }) => linkable,
		({ type }) => DYNAMIC.map((dynamicType) => dynamicType.type).includes(type),
	),
	// A label can be displayed above or below the element
	SHOW_LABEL: createBooleanCapability(CapabilitiesNames.SHOW_LABEL, 'template.pdf.capabilities.show_label.label', 'showLabel', false, [
		createTrigger(true, [
			// The label content
			createTextCapability(CapabilitiesNames.LABEL_CONTENT, 'template.pdf.capabilities.label_content.label', 'labelContent', 'Mon label'),
			// The label position
			createOptionsCapability(CapabilitiesNames.LABEL_POSITION, 'template.pdf.capabilities.label_position.label', 'labelPosition', 'ABOVE', [
				{ label: 'template.pdf.capabilities.label_position.options.above', value: 'ABOVE' },
				{ label: 'template.pdf.capabilities.label_position.options.below', value: 'BELOW' },
			]),
		]),
	]),
	// The element can be linked to an entity's property
	LINKABLE: createBooleanCapability(CapabilitiesNames.LINKABLE, 'template.pdf.capabilities.linkable.label', 'linkable', false, [
		createTrigger(true, [
			// The entity to link to the current element
			createOptionsCapability(
				CapabilitiesNames.LINKED_ENTITY,
				'template.pdf.capabilities.linked_entity.label',
				'linkedEntity',
				null,
				// Generates the entity list as options for this capability
				({ editorContext }) => editorContext.entities.map((entity) => ({ label: entity.name, value: entity.id })),
				// Triggers
				[
					createTrigger(CapabilityValue.NOT_NULL, [
						// The property of the linked entity to use for the current element's content
						createOptionsCapability(
							CapabilitiesNames.LINKED_PROPERTY,
							'template.pdf.capabilities.linked_property.label',
							'linkedProperty',
							null,
							// Generates the entity properties list as options for this capability
							({ element, editorContext, updateElement, t }) => {
								const entity = editorContext.entities.find(({ id }) => id === element.linkedEntity);

								// If the entity cannot be found, we want to clear the linkedEntity and linkedProperty properties
								if (!entity) {
									updateElement({
										linkedEntity: null,
										linkedProperty: null,
									});

									return [];
								}

								let fields = Object.values(EntityTypes)
									.find(({ type }) => type === entity.type)
									?.fields;

								if (element.type === STATIC_DATE.type) {
									fields = fields.filter((f) => f.type === EntityFieldTypes.DATE) ?? [];
								}

								return fields.filter(({ type }) => ContentFieldTypes.includes(type)).map(({ name, id }) => ({ label: t(name), value: id }));
							},
							[],
							[],
							false,
							false,
							{
								disableOptionsTranslation: true,
							}
						),
					]),
				],
				// Setters
				[
					LinkedEntityContentSetter,
				],
				false,
				false,
				{
					onAddClick: (dispatch) => {
						dispatch(addEntity());
					},
					disableOptionsTranslation: true,
				}
			),
		]),
	]),

	TEXT_OBJECT_LINK: createTextObjectCapability(CapabilitiesNames.TEXT_OBJECT_LINK,
		'template.pdf.capabilities.text_object_link.label',
		'textObjectLink',
		[],
		[TextObjectLinkContentSetter],
		false,
		false),

	// The element can have multiple levels of hierarchy
	TITLE_LEVEL: createOptionsCapability(CapabilitiesNames.TITLE_LEVEL, 'template.pdf.capabilities.title_level.label', 'titleLevel', '1', [
		{ label: 'template.pdf.capabilities.title_level.options.1', value: '1' },
		{ label: 'template.pdf.capabilities.title_level.options.2', value: '2' },
		{ label: 'template.pdf.capabilities.title_level.options.3', value: '3' },
		{ label: 'template.pdf.capabilities.title_level.options.4', value: '4' },
		{ label: 'template.pdf.capabilities.title_level.options.5', value: '5' },
		{ label: 'template.pdf.capabilities.title_level.options.6', value: '6' },
	], [], [], false),
	// The element is numbered
	SHOW_TITLE_NUMBER: createBooleanCapability(CapabilitiesNames.SHOW_TITLE_NUMBER, 'template.pdf.capabilities.show_title_number.label', 'showTitleNumber', true, []),

	// The user can choose between preset or custom date formats
	DATE_FORMAT_ADVANCED_MODE: createBooleanCapability(
		CapabilitiesNames.DATE_FORMAT_ADVANCED_MODE,
		'template.pdf.capabilities.date_format_advanced_mode.label',
		'dateFormatAdvancedMode',
		false,
		[
			createTrigger(false, [createOptionsCapability(
				CapabilitiesNames.DATE_FORMAT,
				'template.pdf.capabilities.date_format.label',
				'dateFormat',
				'day/month/year(4)',
				({ t }) => [
					{ label: '30/06/2022', value: 'day/month/year(4)' },
					{ label: '30/06/22', value: 'day/month/year(2)' },
					{ label: '06/30/2022', value: 'month/day/year(4)' },
					{ label: '06/30/22', value: 'month/day/year(2)' },
					{ label: '30/06/2022 15:30', value: 'day/month/year(4) hour(24):minute' },
					{ label: '30/06/22 15:30', value: 'day/month/year(2) hour(24):minute' },
					{ label: '06/30/2022 15:30', value: 'month/day/year(4) hour(24):minute' },
					{ label: '06/30/22 15:30', value: 'month/day/year(2) hour(24):minute' },
					{ label: '30/06/2022 3:30 PM', value: 'day/month/year(4) hour(12):minute ampm' },
					{ label: '30/06/22 3:30 PM', value: 'day/month/year(2) hour(12):minute ampm' },
					{ label: '06/30/2022 3:30 PM', value: 'month/day/year(4) hour(12):minute ampm' },
					{ label: '06/30/22 3:30 PM', value: 'month/day/year(2) hour(12):minute ampm' },
				].map(({ label, value }) => ({ label: `${label} - ${formatPlaceholderDate(value, t)}`, value })),
				[],
				[],
				false,
				false,
				{ disableOptionsTranslation: true }
			)]),
			createTrigger(true, [createTextCapability(
				CapabilitiesNames.DATE_FORMAT_ADVANCED,
				'template.pdf.capabilities.date_format_advanced.label',
				'dateFormat',
				'day/month/year(4) hour(24):minute',
				[],
				false,
			)]),
		]
	),
	// The element has a single way of choosing a date
	DATE_VALUE: createDateCapability(
		CapabilitiesNames.DATE_VALUE,
		'template.pdf.capabilities.date_value.label',
		'content',
		new Date().toISOString(),
		[],
		(e) => e.linkable,
		({ type }) => DYNAMIC.map((dynamicType) => dynamicType.type).includes(type),
	),
	// The element has multiple ways of choosing a date
	DATE_MODE: createOptionsCapability(CapabilitiesNames.DATE_MODE, 'template.pdf.capabilities.date_mode.label', 'dateMode', DateModes.STATIC, [
		{
			info: 'template.pdf.capabilities.date_mode.options.static.info',
			label: 'template.pdf.capabilities.date_mode.options.static.label',
			value: DateModes.STATIC,
		},
		{
			info: 'template.pdf.capabilities.date_mode.options.automatic.info',
			label: 'template.pdf.capabilities.date_mode.options.automatic.label',
			value: DateModes.AUTOMATIC,
		},
	], [
		createTrigger(DateModes.STATIC, [createDateCapability(
			CapabilitiesNames.DATE_VALUE,
			'template.pdf.capabilities.date_value.label',
			'content',
			new Date().toISOString(),
			[],
			(e) => e.linkable,
		)]),
		createTrigger(DateModes.AUTOMATIC, [createOptionsCapability(
			CapabilitiesNames.DATE_AUTO_VALUE,
			'template.pdf.capabilities.date_auto_value.label',
			'content',
			AutomaticDateValues.EXPORT_DATE,
			[
				{ label: 'template.pdf.capabilities.date_auto_value.options.export_date', value: AutomaticDateValues.EXPORT_DATE },
			],
			[],
			[],
			false,
		)]),
	], [
		createSetter(
			'dateMode',
			(e) => e.linkable,
			DateModes.STATIC,
		),
	], (e) => e.linkable),

	// Capabilities for the Image element
	IMAGE_SELECTION: createComponentCapability(CapabilitiesNames.IMAGE_SELECTION, undefined, 'file', undefined, ImageSelectionButton),
	IMAGE_SCALING: createOptionsCapability(CapabilitiesNames.IMAGE_SCALING, 'template.pdf.capabilities.image_scaling.label', 'scaling', 'EXPAND', [
		{ label: 'template.pdf.capabilities.image_scaling.options.expand', value: 'EXPAND' },
		{ label: 'template.pdf.capabilities.image_scaling.options.fit', value: 'FIT' },
		{ label: 'template.pdf.capabilities.image_scaling.options.stretch', value: 'STRETCH' },
	]),
	FORMAT: createFormatCapability(CapabilitiesNames.FORMAT, 'template.pdf.capabilities.format.label', 'format'),
	FONT_SIZE: createFormatCapability(CapabilitiesNames.FONT_SIZE, 'template.pdf.capabilities.font_size.label', 'format', [], true),
	// Placeholder capabilities for layout and formatting handling
	TEXT_ALIGN: createOptionsCapability(CapabilitiesNames.TEXT_ALIGN, 'template.pdf.capabilities.text_align.label', 'textAlign', 'LEFT', [
		{ label: 'template.pdf.capabilities.text_align.options.left', value: 'LEFT' },
		{ label: 'template.pdf.capabilities.text_align.options.center', value: 'CENTER' },
		{ label: 'template.pdf.capabilities.text_align.options.right', value: 'RIGHT' },
	], [], [], true),
	ELEMENT_ALIGN: createOptionsCapability(CapabilitiesNames.ELEMENT_ALIGN, 'template.pdf.capabilities.element_align.label', 'elementAlign', 'LEFT', [
		{ label: 'template.pdf.capabilities.element_align.options.left', value: 'LEFT' },
		{ label: 'template.pdf.capabilities.element_align.options.center', value: 'CENTER' },
		{ label: 'template.pdf.capabilities.element_align.options.right', value: 'RIGHT' },
	], [], [], true),
	ELEMENT_LAYOUT: createOptionsCapability(CapabilitiesNames.ELEMENT_LAYOUT, 'template.pdf.capabilities.element_layout.label', 'elementLayout', 'SHRINK', [
		{ label: 'template.pdf.capabilities.element_layout.options.shrink', value: 'SHRINK' },
		{ label: 'template.pdf.capabilities.element_layout.options.fill', value: 'FILL' },
	], [], [], true),

	// Capabilities for the Table element
	TABLE_LINKED_ENTITY: createOptionsCapability(
		'TABLE_LINKED_ENTITY',
		'template.pdf.capabilities.table_linked_entity.label',
		'tableLinkedEntity',
		null,
		({ editorContext }) => editorContext.entities.map((entity) => ({ label: entity.name, value: entity.id })),
		[createTrigger(CapabilityValue.NOT_NULL, [
			// The property of the linked entity to use for the current element's content
			createOptionsCapability(
				'TABLE_LINKED_PROPERTY',
				'template.pdf.capabilities.linked_property.label',
				'tableLinkedProperty',
				null,
				// Generates the entity properties list as options for this capability
				({ element, editorContext, updateElement, t }) => {
					const entity = editorContext.entities.find(({ id }) => id === element.tableLinkedEntity);

					// If the entity cannot be found, we want to clear the linkedEntity and linkedProperty properties
					if (!entity) {
						updateElement({
							tableLinkedEntity: null,
							tableLinkedProperty: null,
						});

						return [];
					}

					const fields = Object.values(EntityTypes).find(({ type }) => type === entity.type)?.fields ?? [];

					return fields.filter(({ type }) => TableFieldTypes.includes(type)).map(({ name, id }) => ({ label: t(name), value: id }));
				},
				[],
				[
					LinkedTableEntityContentSetter,
				],
				false,
				false,
				{
					disableOptionsTranslation: true,
				}
			),
		])],
		[],
		false,
		false,
		{
			disableOptionsTranslation: true,
		}
	),
	// TODO: TABLE_ROW_COUNT: createNumberCapability('TABLE_ROW_COUNT', 'template.pdf.capabilities.table_row_count.label', 'tableRowCount', 1, [
	// TODO: Need to really implement the TABLE_AUTO_SIZE capability
	// TABLE_AUTO_SIZE: createBooleanCapability('TABLE_AUTO_SIZE', 'template.pdf.capabilities.table_auto_size.label', 'tableAutoSize', true, [createTrigger(false, [
	// 	createTextCapability('TABLE_NUMBER_OF_ROWS', 'template.pdf.capabilities.table_number_of_rows.label', 'tableNumberOfRows', 5),
	// ])]),
	REMAINING_PAGE_NUMBERING: createBooleanCapability(
		CapabilitiesNames.REMAINING_PAGE_NUMBERING,
		'template.pdf.capabilities.remaining_page_numbering.label',
		'remainingPageNumbering',
		false,
		[createTrigger(true, [
			createTextCapability('REMAINING_PAGE_SEPARATOR', 'template.pdf.capabilities.remaining_page_separator.label', 'remainingPageSeparator', '/')]),
		],
	),
});

export default Capabilities;
