import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InvoiceCreationTypes, InvoiceMethods } from 'constants/invoiceEnums';
import { QuotationTypes } from 'constants/quotationEnums';
import update from 'immutability-helper';
import { getCurrencyName } from 'lib/currencies/formatCurrencyData';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { fetchAllForInvoice } from 'redux/actions/invoices/invoices';
import { addPaymentDelay } from 'redux/actions/paymentDelays';
import { addProject } from 'redux/actions/projects/projects';
import { useAllForInvoiceSelector, useInvoiceLoadingSelector } from 'redux/selectors/invoices/invoices';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import { v4 as uuid } from 'uuid';

import { PaymentDelayCreationForm } from 'components/paymentDelays';
import { ProjectCreationForm } from 'components/projects';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Collapsible } from 'components/shared/layout';
import { sumOneObjectPropertyInArray } from 'components/shared/utils/functions';

import {
	getInvoiceMethodsAsSelectOptions,
	getRowTotals,
	invoiceColumnsHeaders as getColumnsHeaders,
} from './functions';
import InvoiceGrid from './InvoiceGrid';
import InvoiceHeader from './InvoiceHeader';
import InvoiceTotalsTable from './InvoiceTotalsTable';

/**
 * @name InvoiceCreationForm
 * @description A form used to create a invoice.
 *
 * @author Roland Margelidon
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit			The method to trigger upon form submission.
 * @param {string}	creationType		The type of creation (empty, as a copy, as a reversal or from a quotation).
 * @param {string}	sourceInvoiceId		The id of the base invoice from which to create a new invoice..
 * @param {string}	sourceQuotationId	The id of the quotation to retrieve data from to create the new invoice.
 */
const InvoiceCreationForm = ({ onSubmit, creationType, sourceInvoiceId, sourceQuotationId }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForInvoiceForm = useAllForInvoiceSelector();
	const accounts = useMemo(() => allForInvoiceForm?.unarchivedAccounts ?? [], [allForInvoiceForm]);
	const companies = useMemo(() => allForInvoiceForm?.companies ?? [], [allForInvoiceForm]);
	const currencies = useMemo(() => allForInvoiceForm?.currencies ?? [], [allForInvoiceForm]);
	const currentUser = useCurrentConnectedUserSelector();
	const invoice = useMemo(() => allForInvoiceForm?.invoice, [allForInvoiceForm]);
	const offers = useMemo(() => allForInvoiceForm?.unarchivedOffers ?? [], [allForInvoiceForm]);
	const paymentDelays = useMemo(() => allForInvoiceForm?.unarchivedPaymentDelays ?? [], [allForInvoiceForm]);
	const projects = useMemo(() => allForInvoiceForm?.unarchivedProjects ?? [], [allForInvoiceForm]);
	const quotation = useMemo(() => allForInvoiceForm?.quotation, [allForInvoiceForm]);
	const vatRates = useMemo(() => allForInvoiceForm?.unarchivedVatRates ?? [], [allForInvoiceForm]);

	const isLoading = useInvoiceLoadingSelector();

	const [currency, setCurrency] = useState();
	const [selectedMethod, setSelectedMethod] = useState();

	const currencyName = useMemo(() => getCurrencyName(currencies, currency), [currencies, currency]);
	const currencySymbol = useMemo(() => ((currencyName && currencyName !== '') ? t(`currency.${currencyName}`) : ''), [currencyName, t]);

	const [rows, setRows] = useState([]);
	const sortedRows = useMemo(() => rows.sort((a, b) => a.line - b.line), [rows]);

	const [duplicateRowId, setDuplicateRowId] = useState();
	const [originalRowData, setOriginalRowData] = useState();

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const [discountOnTotal, setDiscountOnTotal] = useState(0);
	const [grossTotal, setGrossTotal] = useState(0);
	const [grossTotalWithDiscount, setGrossTotalWithDiscount] = useState(0);
	const [vatTotal, setVatTotal] = useState(0);
	const [netTotal, setNetTotal] = useState(0);
	const [rowsTotals, setRowsTotals] = useState([]);

	const favoritePaymentDelay = useMemo(() => {
		if (allForInvoiceForm?.unarchivedPaymentDelays) {
			const favoritePaymentDelayArray = allForInvoiceForm.unarchivedPaymentDelays.filter((pd) => pd.favorite);

			return favoritePaymentDelayArray.length === 1 ? favoritePaymentDelayArray[0] : {};
		}

		return {};
	}, [allForInvoiceForm]);
	const favoriteVatRate = useMemo(() => {
		if (allForInvoiceForm?.unarchivedVatRates) {
			const favoriteVatRateArray = allForInvoiceForm.unarchivedVatRates.filter((vat) => vat.favorite);

			return favoriteVatRateArray.length === 1 ? favoriteVatRateArray[0] : {};
		}

		return {};
	}, [allForInvoiceForm]);

	// !!Only for Header: Props to pass down to the select input so that it will display ancreation modal when clicking on the "+" icon.
	const paymentDelayCreationModal = useFormModal(PaymentDelayCreationForm, t('invoice.edition.inputs.payment_delay.creation'), fetchAllForInvoice, addPaymentDelay);
	const projectCreationModal = useFormModal(ProjectCreationForm, t('invoice.edition.inputs.project.creation'), fetchAllForInvoice, addProject);

	/**
	 * @function
	 * @name setTotals
	 * @description A function to refresh Total variables.
	 *
	 * @author Romaric Barthe
	 */
	const setTotals = useCallback(() => {
		const formData = formRef.current?.getFormValues();
		setRowsTotals(getRowTotals(formData?.rows, vatRates, formData?.discount));
	}, [formRef, vatRates]);

	/**
	 * @function
	 * @name updateRow
	 * @description A function to add/ move/ delete/ duplicate a row.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	rowId			The id of the row triggering the action.
	 * @param {number}	lineNb			The number of the row triggering the action.
	 * @param {number}	direction		The direction if move: positive is for down, negative is for up.
	 * @param {string}	action			The possible action: 'add', 'move', 'duplicate', 'select'.
	 */
	const updateRow = useCallback((action, rowId = undefined, lineNb = undefined, direction = undefined) => {
		if (action === 'add') {
			const newId = uuid();
			const lines = rows.map((row) => row?.line) ?? [];
			const newRowNumber = _.isEmpty(lines) ? 1 : Math.max(...lines) + 1;
			setRows([...rows, { id: newId, line: newRowNumber, vatRate: favoriteVatRate?.id }]);
		} else {
			if (rows.findIndex((row) => row.id === rowId) === undefined) {
				throw new Error('An issue occured with the current lines. Please refresh the page.');
			}
			if (action === 'move') {
				const rowIndexToMoveInDirection = rows.findIndex((row) => row.id === rowId);
				const rowIndexToMoveInOtherDirection = rows.findIndex((row) => row.line === lineNb + direction);
				setRows(update(rows, {
					[rowIndexToMoveInDirection]: {
						line: { $set: lineNb + direction },
					},
					[rowIndexToMoveInOtherDirection]: {
						line: { $set: lineNb },
					},
				}));
			}
			if (action === 'delete') {
				const rowIndexToRemove = rows.findIndex((row) => row.id === rowId);
				setRows(update(rows, {
					$splice: [[rowIndexToRemove, 1]],
					$apply: (newRows) => newRows.sort((a, b) => a.line - b.line).map((r, i) => update(r, { line: { $set: i + 1 } })),
				}));
				setRowsTotals(update(rowsTotals, {
					$apply: (newRows) => newRows.filter((row) => row.id !== rowId),
				}));
			}
			if (action === 'duplicate') {
				const newId = uuid();
				setRows(update(rows, {
					$apply: (newRows) => newRows.map((r) => (r.line > lineNb ? update(r, { line: { $set: r.line + 1 } }) : r)),
					$push: [{
						id: newId,
						line: lineNb + 1,
					}],
				}));
				const formData = formRef.current.getFormValues();
				setOriginalRowData(formData.rows[rowId]);
				setDuplicateRowId(newId);
			}
		}
	}, [favoriteVatRate?.id, formRef, rows, rowsTotals]);

	// Fetch data
	useEffect(() => {
		dispatch(fetchAllForInvoice(sourceInvoiceId, sourceQuotationId));
	}, [dispatch, sourceInvoiceId, sourceQuotationId]);

	// Set default values
	const defaultValues = useMemo(() => {
		// Initalisation from scratch
		if (!invoice && (!quotation || creationType === InvoiceCreationTypes.EMPTY)) {
			return {
				rows: { newLine: { line: 1, id: 'newLine', vatRate: favoriteVatRate?.id || '' } },
				currency: currentUser?.company?.mainPartner?.currency?.id || '',
				discountOnTotal: 0,
				grossTotal: 0,
				grossTotalWithDiscount: 0,
				netTotal: 0,
				structure: currentUser?.company?.mainPartner?.id || '',
				paymentDelay: favoritePaymentDelay?.id || '',
				vatTotal: 0,
			};
		}

		// Initialisation from a quotation
		if (!invoice && quotation && creationType === InvoiceCreationTypes.FROM_QUOTATION) {
			// For archi quotation, we need to find the column belonging to the user's company
			const relevantStakeholder = quotation.stakeholders
				? Object.values(quotation.stakeholders).find((stakeholder) => stakeholder?.partner?.id === currentUser?.company?.mainPartner?.id)
				: undefined;

			// Calculate basis for calculation
			const calculBasis = relevantStakeholder
				? relevantStakeholder.partnerProjectAmount * (relevantStakeholder.partnerRatio / 100)
				: undefined;

			// Define the id of the column and flatten the rows
			const columnId = relevantStakeholder ? relevantStakeholder.id : undefined;
			const flatRows = quotation.rows ? Object.fromEntries(update(quotation.rows, {
				$apply: (newRows) => Object.entries(newRows).map((row) => update(row, {
					$apply: (newRow) => update(newRow, {
						1: {
							offer: { $set: newRow[1].offer?.id },
							vatRate: { $set: quotation?.quotationType === QuotationTypes.ARCHI ? quotation?.vatRate?.id : newRow[1].vatRate?.id },
							stakeholders: {
								$set: newRow[1].stakeholders
									? Object.values(newRow[1].stakeholders).find((s) => s.columnId === columnId)
									: undefined,
							},
							baseAmount: {
								$set: calculBasis && newRow[1].stakeholders
									? (Object.values(newRow[1].stakeholders).find((s) => s.columnId === columnId).rate * calculBasis) / 100
									: undefined,
							},
							discount: { $set: quotation?.quotationType === QuotationTypes.ARCHI ? 0 : (newRow[1].discount ?? 0) },
							price: { $set: quotation?.quotationType === QuotationTypes.ARCHI ? undefined : (newRow[1].unitaryCost ?? 0) },
						},
					}),
				})),
			})) : undefined;

			// Exclude line with 0 values
			const archiKeys = Object.keys(flatRows);
			archiKeys.forEach((key) => {
				if (flatRows[key].baseAmount === 0 && flatRows[key].baseAmount !== undefined) {
					delete flatRows[key];
				}
			});

			return {
				rows: flatRows,
				currency: quotation.currency?.id || '',
				comment: quotation.description || '',
				method: quotation?.quotationType === QuotationTypes.ARCHI ? InvoiceMethods.ADVANCEMENT : InvoiceMethods.UNITARY,
				project: quotation.project?.id || '',
				structure: quotation.structure?.id || null,
				paymentDelay: favoritePaymentDelay?.id || '',
				discount: relevantStakeholder?.partnerDiscount ?? 0,
				discountOnTotal: quotation?.quotationType === QuotationTypes.ARCHI ? 0 : quotation?.discountTotal,
				grossTotal: quotation?.quotationType === QuotationTypes.ARCHI ? 0 : quotation?.grossTotal,
				grossTotalWithDiscount: quotation?.quotationType === QuotationTypes.ARCHI ? 0 : quotation?.grossTotalWithDiscount,
				netTotal: quotation?.quotationType === QuotationTypes.ARCHI ? 0 : quotation?.netTotal,
				vatTotal: quotation?.quotationType === QuotationTypes.ARCHI ? 0 : quotation?.vatTotal,
			};
		}

		// Initialisation from an invoice
		const flatRows = Object.fromEntries(update(invoice.rows, {
			$apply: (newRows) => Object.entries(newRows).map((row) => update(row, {
				$apply: (newRow) => update(newRow, {
					1: {
						account: { $set: newRow[1].account?.id },
						alreadyInvoiced: {
							$set: creationType === InvoiceCreationTypes.FROM_INVOICE
								? ((newRow[1].alreadyInvoiced ?? 0) + (newRow[1].currentlyInvoiced ?? 0))
								: (newRow[1].alreadyInvoiced ?? 0),
						},
						baseAmount: { $set: creationType !== InvoiceCreationTypes.REVERT ? (newRow[1].baseAmount ?? 0) : -1 * (newRow[1].baseAmount ?? 0) },
						currentlyInvoiced: { $set: creationType === InvoiceCreationTypes.FROM_INVOICE ? 0 : (newRow[1].currentlyInvoiced ?? 0) },
						designation: { $set: newRow[1].designation || '' },
						discount: { $set: newRow[1].discount ?? 0 },
						offer: { $set: newRow[1].offer?.id },
						price: { $set: creationType !== InvoiceCreationTypes.REVERT ? newRow[1].price : -1 * newRow[1].price ?? 0 },
						quantity: { $set: newRow[1].quantity ?? 0 },
						vatRate: { $set: newRow[1].vatRate?.id },
					},
				}),
			})),
		}));

		return {
			...invoice,
			rows: flatRows,
			currency: invoice.currency?.id ?? '',
			paymentDelay: invoice.paymentDelay?.id ?? '',
			project: invoice.project?.id ?? '',
			reversedInvoice: (creationType && creationType !== InvoiceCreationTypes.REVERT && invoice.reversedInvoice?.id)
				|| creationType === InvoiceCreationTypes.REVERT ? invoice.id : '',
			seller: invoice.seller?.id ?? null,
			structure: invoice.structure?.id,
			discountOnTotal: (creationType && creationType !== InvoiceCreationTypes.REVERT) ? (invoice.discountOnTotal ?? 0) : -1 * invoice.discountOnTotal,
			grossTotal: (creationType && creationType !== InvoiceCreationTypes.REVERT) ? (invoice.grossTotal ?? 0) : -1 * (invoice.grossTotal ?? 0),
			grossTotalWithDiscount: (creationType && creationType !== InvoiceCreationTypes.REVERT)
				? (invoice.grossTotalWithDiscount ?? 0)
				: -1 * (invoice.grossTotalWithDiscount ?? 0),
			netTotal: (creationType && creationType !== InvoiceCreationTypes.REVERT) ? (invoice.netTotal ?? 0) : -1 * (invoice.netTotal ?? 0),
			vatTotal: (creationType && creationType !== InvoiceCreationTypes.REVERT) ? (invoice.vatTotal ?? 0) : -1 * (invoice.vatTotal ?? 0),
		};
	}, [invoice, quotation, creationType, favoriteVatRate?.id, currentUser, favoritePaymentDelay?.id]);

	const methods = useMemo(() => getInvoiceMethodsAsSelectOptions().map((method) => ({ id: method.id, label: t(method.label) })), [t]);
	const columnsHeaders = useMemo(() => getColumnsHeaders(selectedMethod), [selectedMethod]);

	// Set state's variables
	useEffect(() => {
		setCurrency(defaultValues?.currency);
		setDiscountOnTotal(defaultValues?.discountOnTotal);
		setGrossTotal(defaultValues?.grossTotal);
		setGrossTotalWithDiscount(defaultValues?.grossTotalWithDiscount);
		setNetTotal(defaultValues?.netTotal);
		setSelectedMethod(defaultValues?.method);
		setVatTotal(defaultValues?.vatTotal);
		setRows(defaultValues?.rows ? Object.values(defaultValues.rows) : []);
		setRowsTotals(getRowTotals(defaultValues?.rows, vatRates, defaultValues?.discount));
	}, [defaultValues, vatRates]);

	// Refresh totals
	useEffect(() => {
		setGrossTotal(sumOneObjectPropertyInArray(rowsTotals, 'gt'));
		setDiscountOnTotal(sumOneObjectPropertyInArray(rowsTotals, 'dt'));
		setGrossTotalWithDiscount(sumOneObjectPropertyInArray(rowsTotals, 'gtd'));
		setVatTotal(sumOneObjectPropertyInArray(rowsTotals, 'vt'));
		setNetTotal(sumOneObjectPropertyInArray(rowsTotals, 'nt'));
	}, [rowsTotals]);

	return (
		(((sourceQuotationId && quotation) || (sourceInvoiceId && invoice) || (!sourceInvoiceId && !sourceQuotationId)) && !isLoading) ? (
			<DynamicForm
				ref={formRef}
				cleanFormData
				defaultValues={defaultValues}
				onSubmit={onSubmit}
				{...formProps}
			>

				<Collapsible titleKey="invoice.creation.collapsed.general_informations">
					<InvoiceHeader
						creationType={creationType}
						currencies={currencies}
						defaultValues={defaultValues}
						isLoading={isLoading}
						methods={methods}
						projects={projects}
						paymentDelayCreationModal={paymentDelayCreationModal}
						paymentDelays={paymentDelays}
						projectCreationModal={projectCreationModal}
						sourceInvoiceId={sourceInvoiceId}
						structures={companies}
						setMethodSelected={setSelectedMethod}
						setCurrency={setCurrency}
						setTotals={setTotals}
					/>
				</Collapsible>

				{(creationType !== InvoiceCreationTypes.EMPTY || selectedMethod) && (
					<>
						<InvoiceTotalsTable
							currencySymbol={currencySymbol}
							discountOnTotal={discountOnTotal}
							grossTotal={grossTotal}
							grossTotalWithDiscount={grossTotalWithDiscount}
							netTotal={netTotal}
							vatTotal={vatTotal}
						/>

						<Collapsible titleKey="invoice.creation.collapsed.grid">
							<InvoiceGrid
								accounts={accounts}
								columnsHeaders={columnsHeaders}
								currencyName={currencyName}
								currencySymbol={currencySymbol}
								defaultValues={defaultValues}
								isLoading={isLoading}
								offers={offers}
								rows={sortedRows}
								vatRates={vatRates}
								// functions
								updateRow={updateRow}
								setTotals={setTotals}
								// duplicate logic
								duplicateRowId={duplicateRowId}
								originalRowData={originalRowData}
							/>

							<Button className="secondary" onClick={() => updateRow('add')}>
								<PlusCircle />
								{t('invoice.creation.grid.add_row')}
							</Button>
						</Collapsible>
					</>
				)}

				<Button className="primary" type="submit" {...buttonProps}>{t('invoice.creation.action')}</Button>
			</DynamicForm>
		) : t('invoice.creation.loading')
	);
};

InvoiceCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	creationType: PropTypes.oneOf(
		Object.values(InvoiceCreationTypes),
	).isRequired,
	sourceQuotationId: PropTypes.string,
	sourceInvoiceId: PropTypes.string,
};

InvoiceCreationForm.defaultProps = {
	sourceQuotationId: '',
	sourceInvoiceId: '',
};

export default InvoiceCreationForm;
