import update from 'immutability-helper';

import { ActionTypes } from '../actions/mediaManager';

const initialState = {
	isLoading: false,
	files: [],
	folders: [],
	fileCache: {},
	errors: null,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_MEDIAS_REQUEST:
		case ActionTypes.FETCH_FILE_REQUEST:
		case ActionTypes.ADD_FILE_REQUEST:
		case ActionTypes.ADD_FOLDER_REQUEST:
		case ActionTypes.UPDATE_FILE_REQUEST:
		case ActionTypes.UPDATE_FOLDER_REQUEST:
		case ActionTypes.REMOVE_FILE_REQUEST:
		case ActionTypes.REMOVE_FOLDER_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.ADD_FILE_FAILURE:
		case ActionTypes.ADD_FOLDER_FAILURE:
		case ActionTypes.UPDATE_FILE_FAILURE:
		case ActionTypes.UPDATE_FOLDER_FAILURE:
			return {
				...state,
				isLoading: false,
				errors: payload.errors,
			};
		case ActionTypes.FETCH_MEDIAS_FAILURE:
		case ActionTypes.FETCH_FILE_FAILURE:
		case ActionTypes.REMOVE_FILE_FAILURE:
		case ActionTypes.REMOVE_FOLDER_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_FILE_SUCCESS:
		case ActionTypes.ADD_FOLDER_SUCCESS:
		case ActionTypes.UPDATE_FILE_SUCCESS:
		case ActionTypes.UPDATE_FOLDER_SUCCESS:
		case ActionTypes.REMOVE_FILE_SUCCESS:
		case ActionTypes.REMOVE_FOLDER_SUCCESS:
			return {
				...state,
				isLoading: false,
				errors: null,
			};
		case ActionTypes.FETCH_MEDIAS_SUCCESS:
			return {
				...state,
				isLoading: false,
				files: [...payload.files],
				folders: [...payload.folders],
			};
		case ActionTypes.FETCH_FILE_SUCCESS:
			return {
				...state,
				isLoading: false,
				fileCache: update(state.fileCache, {
					[payload.file.id]: {
						$set: payload.file,
					},
				}),
			};
		default:
			return state;
	}
};
