import { Font, StyleSheet, View } from '@react-pdf/renderer';
import Proptypes from 'prop-types';

import IBMPlexSansBold from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-Bold.woff';
import IBMPlexSansBoldItalic from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-BoldItalic.woff';
import IBMPlexSansExtraLight from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-ExtraLight.woff';
import IBMPlexSansExtraLightItalic from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-ExtraLightItalic.woff';
import IBMPlexSansItalic from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-Italic.woff';
import IBMPlexSansLight from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-Light.woff';
import IBMPlexSansLightItalic from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-LightItalic.woff';
import IBMPlexSansMedium from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-Medium.woff';
import IBMPlexSansMediumItalic from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-MediumItalic.woff';
import IBMPlexSansRegular from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-Regular.woff';
import IBMPlexSansSemiBold from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-SemiBold.woff';
import IBMPlexSansSemiBoldItalic from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-SemiBoldItalic.woff';
import IBMPlexSansThin from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-Thin.woff';
import IBMPlexSansThinItalic from '../../../../styles/font/IBM_Plex_Sans/IBMPlexSans-ThinItalic.woff';

import ElementRenderer from './ElementRenderer';

Font.register({
	family: 'IBM Plex Sans',
	fonts: [
		{
			src: IBMPlexSansRegular,
			fontStyle: 'normal',
			fontWeight: 400,
		},
		{
			src: IBMPlexSansBold,
			fontStyle: 'normal',
			fontWeight: 700,
		},
		{
			src: IBMPlexSansBoldItalic,
			fontStyle: 'italic',
			fontWeight: 700,
		},
		{
			src: IBMPlexSansItalic,
			fontStyle: 'italic',
			fontWeight: 400,
		},
		{
			src: IBMPlexSansLight,
			fontStyle: 'normal',
			fontWeight: 300,
		},
		{
			src: IBMPlexSansLightItalic,
			fontStyle: 'italic',
			fontWeight: 300,
		},
		{
			src: IBMPlexSansExtraLight,
			fontStyle: 'normal',
			fontWeight: 200,
		},
		{
			src: IBMPlexSansExtraLightItalic,
			fontStyle: 'italic',
			fontWeight: 200,
		},
		{
			src: IBMPlexSansMedium,
			fontStyle: 'normal',
			fontWeight: 500,
		},
		{
			src: IBMPlexSansMediumItalic,
			fontStyle: 'italic',
			fontWeight: 500,
		},
		{
			src: IBMPlexSansSemiBold,
			fontStyle: 'normal',
			fontWeight: 600,
		},
		{
			src: IBMPlexSansSemiBoldItalic,
			fontStyle: 'italic',
			fontWeight: 600,
		},
		{
			src: IBMPlexSansThin,
			fontStyle: 'normal',
			fontWeight: 100,
		},
		{
			src: IBMPlexSansThinItalic,
			fontStyle: 'italic',
			fontWeight: 100,
		},
	],
});

Font.registerHyphenationCallback((word) => [word]);

const viewStyle = StyleSheet.create({
	display: 'flex',
	flexDirection: 'row',
	wordBreak: 'break-word',
	fontFamily: 'IBM Plex Sans',
	flexWrap: 'wrap',
	width: '100%',
});

/**
 * @name RowRenderer
 * @description A component used to render a row in the pdf preview.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string}	id				The current row's id.
 * @param {array}	children		The elements contained by the current row.
 * @param {string}  height 			The current row's height.
 * @param {object}	rendererGlobals	The global renderer's variables.
 * @param {number}  pageIndex		The current page's index.
 *
 * @returns
 */
const RowRenderer = ({ id, children, height, rendererGlobals, pageIndex }) => (
	children.length === 0
		? (
			<View style={[viewStyle, { height: `${height}px` }]} />
		)
		: (
			<View style={[viewStyle, { height: `${height}px` }]} key={id}>
				{children.map((element) => <ElementRenderer key={element.id} element={element} pageIndex={pageIndex} rendererGlobals={rendererGlobals} />)}
			</View>
		)
);

RowRenderer.propTypes = {
	id: Proptypes.string.isRequired,
	height: Proptypes.number.isRequired,
	children: Proptypes.arrayOf(
		Proptypes.object.isRequired,
	).isRequired,
	rendererGlobals: Proptypes.object.isRequired,
	pageIndex: Proptypes.number.isRequired,
};

export default RowRenderer;
