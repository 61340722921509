import { useCallback, useEffect, useMemo, useState } from 'react';
import { Copy, Download, Edit2, ExternalLink, RotateCcw, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Environments } from 'constants/environmentEnums';
import { ExpenseCreationTypes } from 'constants/expenseEnums';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { downloadS3File } from 'lib/shared/fileHelper';
import PropTypes from 'prop-types';
import { exportExpenses, removeExpense } from 'redux/actions/expenses/expenses';
import { fetchTemplateList } from 'redux/actions/templateExports';
import { useTemplateExportListSelector, useTemplateExportLoadingSelector } from 'redux/selectors/templateExports';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ExpenseActionBar
 * @description A component to display the expense's titles.
 *
 * @author Romaric Barthe
 *
 * @param {object}	expense			The expense.
 */
const ExpenseActionBar = ({ expense }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [downloadButtonRef, downloadTooltipProps] = useTooltip();
	const [reverseButtonRef, reverseTooltipProps] = useTooltip();
	const [extractButtonRef, extractTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	const [copyButtonRef, copyTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();

	useEffect(() => {
		dispatch(fetchTemplateList({
			rowsPerPage: 0,
			filters: {
				conditions: [
					{ column: 'linkedEntities', criterion: 'contains', value: Environments.EXPENSE.toUpperCase() },
					{ column: 'linkedEntities', criterion: 'contains', value: expense.id },
				],
			},
		}));
	}, [dispatch, expense.id]);

	const templateExports = useTemplateExportListSelector();
	const isLoading = useTemplateExportLoadingSelector();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const currentRow = useMemo(() => {
		if (isLoading) {
			return undefined;
		}

		const templateExportsFiltered = templateExports.filter(
			(f) => f.linkedEntities
				&& f.linkedEntities.length > 0
				&& f.linkedEntities[0].type === Environments.EXPENSE.toUpperCase()
				&& f.linkedEntities[0].linkedEntity === expense.id
		);

		return templateExportsFiltered && templateExportsFiltered.length > 0 ? templateExportsFiltered[0] : undefined;
	}, [expense.id, isLoading, templateExports]);
	const pdf = useMemo(() => currentRow?.generatedPdf?.pdf, [currentRow]);

	const canDeleteExpense = useAccessRight(AccessRights.humanResources.expenses.enhancedRights.DELETE_EXPENSE) && !expense.editionNumber && !expense.archived;
	const canEditExpense = useAccessRight(AccessRights.humanResources.expenses.enhancedRights.CREATE_EXPENSE) && !expense.editionNumber && !expense.archived;
	const canExtractExpense = useAccessRight(AccessRights.humanResources.expenses.enhancedRights.EXPORT_EXPENSE);

	const canDownload = useMemo(() => pdf && pdf.presignedUrl, [pdf]);
	const [isDownloading, setIsDownloading] = useState(false);

	const onCopyButtonClick = useCallback(() => {
		history.push({
			pathname: routes.humanResources.expenses.expenseCreation,
			state: { creationType: ExpenseCreationTypes.FROM_EXPENSE, sourceExpenseId: expense.id },
		});
	}, [expense.id]);

	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeExpense({ id: expense.id }));
		history.push(generatePath(routes.humanResources.expenses.list));
	}, [dispatch, expense.id, toggleDeleteModal]);

	const onDownloadButtonClick = useCallback(async () => {
		if (!isDownloading) {
			setIsDownloading(true);
			await downloadS3File(pdf, 'pdf');
			setIsDownloading(false);
		}
	}, [isDownloading, pdf]);

	const onEditButtonClick = useCallback(() => {
		history.push(generatePath(routes.humanResources.expenses.expenseEdition, { id: expense.id }));
	}, [expense.id]);

	const onExtractButtonClick = useCallback(() => {
		const exportData = {
			id: expense.id,
			optionalFields: true,
			linkedEntites: true,
			masterData: true,
		};

		return dispatch(exportExpenses(exportData));
	}, [dispatch, expense.id]);

	const onReverseButtonClick = useCallback(() => {
		history.push({
			pathname: routes.humanResources.expenses.expenseCreation,
			state: { creationType: ExpenseCreationTypes.REVERT, sourceExpenseId: expense.id },
		});
	}, [expense.id]);

	return (
		<div className="actions">
			{expense.editionNumber && canDownload && (
				<>
					<Button className="icon-only" onClick={onDownloadButtonClick} isLoading={isDownloading} ref={downloadButtonRef}>
						<Download />
					</Button>
					<Tooltip {...downloadTooltipProps}>{t('expense.links.document.download')}</Tooltip>
					<Button className="icon-only" onClick={onReverseButtonClick} ref={reverseButtonRef}>
						<RotateCcw />
					</Button>
					<Tooltip {...reverseTooltipProps}>{t('expense.links.reverse')}</Tooltip>
				</>
			)}
			{canExtractExpense && (
				<>
					<Button className="icon-only" onClick={onExtractButtonClick} ref={extractButtonRef}>
						<ExternalLink />
					</Button>
					<Tooltip {...extractTooltipProps}>{t('expense.links.export.current')}</Tooltip>
				</>
			)}
			{canEditExpense && (
				<>
					<Button className="icon-only" onClick={onEditButtonClick} ref={editButtonRef}>
						<Edit2 />
					</Button>
					<Tooltip {...editTooltipProps}>{t('expense.links.update')}</Tooltip>
				</>
			)}
			<Button className="icon-only" onClick={onCopyButtonClick} ref={copyButtonRef}>
				<Copy />
			</Button>
			<Tooltip {...copyTooltipProps}>{t('expense.links.duplicate')}</Tooltip>
			{canDeleteExpense && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>{t('expense.links.delete')}</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('expense.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('expense.deletion.confirmation_modal.confirm')}
						cancelText={t('expense.deletion.confirmation_modal.cancel')}
						titleText={t('expense.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</div>
	);
};

ExpenseActionBar.propTypes = {
	expense: PropTypes.object.isRequired,
};

export default ExpenseActionBar;
