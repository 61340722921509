import { useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

/**
 * @name Cell
 * @description The component to use as table cell in the virtualized table component.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*}		children	The children to pass down to the row.
 * @param {object}	row			The props related to the table's row, passed down from react-table. Destructured to avoid passing it as an HTML argument.
 */
const Cell = ({ children, row, ...otherProps }) => {
	const spanWrap = useMemo(() => (
		_.isString(children?.props?.value)
		|| _.isDate(children?.props?.value)
		|| _.isNumber(children?.props?.value)
	), [children?.props?.value]);

	return (
		<div role="cell" {...otherProps}>
			{spanWrap ? (
				<span>
					{children}
				</span>
			) : (
				children
			)}
		</div>
	);
};

Cell.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	row: PropTypes.object,
};

Cell.defaultProps = {
	row: {},
};

export default Cell;
