import { ActionTypes } from '../actions/registrations';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_REGISTRATION_LIST_REQUEST:
		case ActionTypes.ADD_REGISTRATION_REQUEST:
		case ActionTypes.UPDATE_REGISTRATION_REQUEST:
		case ActionTypes.REMOVE_REGISTRATION_REQUEST:
		case ActionTypes.ARCHIVE_REGISTRATION_REQUEST:
		case ActionTypes.RESTORE_REGISTRATION_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_REGISTRATION_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_REGISTRATION_LIST_FAILURE:
		case ActionTypes.FETCH_REGISTRATION_FAILURE:
		case ActionTypes.ADD_REGISTRATION_FAILURE:
		case ActionTypes.UPDATE_REGISTRATION_FAILURE:
		case ActionTypes.REMOVE_REGISTRATION_FAILURE:
		case ActionTypes.ARCHIVE_REGISTRATION_FAILURE:
		case ActionTypes.RESTORE_REGISTRATION_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_REGISTRATION_SUCCESS:
		case ActionTypes.UPDATE_REGISTRATION_SUCCESS:
		case ActionTypes.REMOVE_REGISTRATION_SUCCESS:
		case ActionTypes.ARCHIVE_REGISTRATION_SUCCESS:
		case ActionTypes.RESTORE_REGISTRATION_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_REGISTRATION_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.registrations,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_REGISTRATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.registration,
			};
		default:
			return state;
	}
};
