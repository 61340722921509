import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchPaymentDelays
 * @description Retrieves a list of payment delays from the API, specifying filters if present.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchPaymentDelays = (params) => post('/api/payment-delays', params);

/**
 * @function
 * @name fetchPaymentDelayById
 * @description Retrieves an existing payment delay from the API, using the paymentDelayId in parameters.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} paymentDelayId	The id of the payment delay we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchPaymentDelayById = (paymentDelayId) => get(`/api/payment-delay/${paymentDelayId}`);

/**
 * @function
 * @name createPaymentDelay
 * @description Creates a new payment delay into the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} paymentDelayData	The data to create the payment delay from.
 *
 * @returns {Promise}
 */
export const createPaymentDelay = (paymentDelayData) => post('/api/payment-delays/create', paymentDelayData);

/**
 * @function
 * @name updatePaymentDelay
 * @description Updates an existing payment delay from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} paymentDelayData	The data to update the payment delay with.
 * @param {string} paymentDelayId	The id to identify the payment delay with.
 *
 * @returns {Promise}
 */
export const updatePaymentDelay = (paymentDelayData, paymentDelayId) => put(`/api/payment-delay/${paymentDelayId}`, paymentDelayData);

/**
 * @function
 * @name deletePaymentDelay
 * @description Removes an existing payment delay from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} paymentDelayId	The id of the payment delay we want to remove.
 *
 * @returns {Promise}
 */
export const deletePaymentDelay = (paymentDelayId) => del(`/api/payment-delay/${paymentDelayId}`);
