import { toast } from 'react-toastify';
import * as PaymentDelaysApi from 'api/paymentDelaysApi';
import i18next from 'i18next';
import { formatPaymentDelayName } from 'lib/paymentDelays/formatPaymentDelayData';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the payment delays redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific payment delay
	FETCH_PAYMENT_DELAY_REQUEST: '@PAYMENT_DELAYS/FETCH_REQUEST',
	FETCH_PAYMENT_DELAY_SUCCESS: '@PAYMENT_DELAYS/FETCH_SUCCESS',
	FETCH_PAYMENT_DELAY_FAILURE: '@PAYMENT_DELAYS/FETCH_FAILURE',

	// Fetch a list of payment delays
	FETCH_PAYMENT_DELAY_LIST_REQUEST: '@PAYMENT_DELAYS/FETCH_LIST_REQUEST',
	FETCH_PAYMENT_DELAY_LIST_SUCCESS: '@PAYMENT_DELAYS/FETCH_LIST_SUCCESS',
	FETCH_PAYMENT_DELAY_LIST_FAILURE: '@PAYMENT_DELAYS/FETCH_LIST_FAILURE',

	// Add a new payment delay to the database
	ADD_PAYMENT_DELAY_REQUEST: '@PAYMENT_DELAYS/ADD_REQUEST',
	ADD_PAYMENT_DELAY_SUCCESS: '@PAYMENT_DELAYS/ADD_SUCCESS',
	ADD_PAYMENT_DELAY_FAILURE: '@PAYMENT_DELAYS/ADD_FAILURE',

	// Update an existing payment delay from the database
	UPDATE_PAYMENT_DELAY_REQUEST: '@PAYMENT_DELAYS/UPDATE_REQUEST',
	UPDATE_PAYMENT_DELAY_SUCCESS: '@PAYMENT_DELAYS/UPDATE_SUCCESS',
	UPDATE_PAYMENT_DELAY_FAILURE: '@PAYMENT_DELAYS/UPDATE_FAILURE',

	// Remove an existing payment delay from the database
	REMOVE_PAYMENT_DELAY_REQUEST: '@PAYMENT_DELAYS/REMOVE_REQUEST',
	REMOVE_PAYMENT_DELAY_SUCCESS: '@PAYMENT_DELAYS/REMOVE_SUCCESS',
	REMOVE_PAYMENT_DELAY_FAILURE: '@PAYMENT_DELAYS/REMOVE_FAILURE',

	// Archive an existing payment delay from the database
	ARCHIVE_PAYMENT_DELAY_REQUEST: '@PAYMENT_DELAYS/ARCHIVE_REQUEST',
	ARCHIVE_PAYMENT_DELAY_SUCCESS: '@PAYMENT_DELAYS/ARCHIVE_SUCCESS',
	ARCHIVE_PAYMENT_DELAY_FAILURE: '@PAYMENT_DELAYS/ARCHIVE_FAILURE',

	// Restore an existing payment delay from the database
	RESTORE_PAYMENT_DELAY_REQUEST: '@PAYMENT_DELAYS/RESTORE_REQUEST',
	RESTORE_PAYMENT_DELAY_SUCCESS: '@PAYMENT_DELAYS/RESTORE_SUCCESS',
	RESTORE_PAYMENT_DELAY_FAILURE: '@PAYMENT_DELAYS/RESTORE_FAILURE',

	// Mark an existing payment delay from the database as favorite
	FAVORITE_PAYMENT_DELAY_REQUEST: '@PAYMENT_DELAYS/FAVORITE_REQUEST',
	FAVORITE_PAYMENT_DELAY_SUCCESS: '@PAYMENT_DELAYS/FAVORITE_SUCCESS',
	FAVORITE_PAYMENT_DELAY_FAILURE: '@PAYMENT_DELAYS/FAVORITE_FAILURE',
};

// ////////////////////////////////////////////////////////////// //
// ///////////// Payment delay list fetching actions //////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPaymentDelayListRequest
 * @description Action triggered anytime a payment delay list fetching call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const fetchPaymentDelayListRequest = () => ({ type: ActionTypes.FETCH_PAYMENT_DELAY_LIST_REQUEST });

/**
 * @function
 * @name fetchPaymentDelayListSuccess
 * @description Action triggered as a result to a successful payment delay list fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} paymentDelays	The list of retrieved payment delays.
 * @param {number} totalCount		The total amount of payment delays available in the database for the current user.
 *
 * @returns {object}
 */
const fetchPaymentDelayListSuccess = ({ paymentDelays, totalCount }) => ({
	type: ActionTypes.FETCH_PAYMENT_DELAY_LIST_SUCCESS,
	payload: { paymentDelays, totalCount },
});

/**
 * @function
 * @name fetchPaymentDelayListFailure
 * @description Action triggered as a result to a failed payment delay list fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchPaymentDelayListFailure = (error) => ({
	type: ActionTypes.FETCH_PAYMENT_DELAY_LIST_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// /////////// Specific payment delay fetching actions ////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPaymentDelayRequest
 * @description Action triggered anytime a payment delay fetching call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const fetchPaymentDelayRequest = () => ({ type: ActionTypes.FETCH_PAYMENT_DELAY_REQUEST });

/**
 * @function
 * @name fetchPaymentDelaySuccess
 * @description Action triggered as a result to a successful payment delay fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} paymentDelay The retrieved payment delay.
 *
 * @returns {object}
 */
const fetchPaymentDelaySuccess = ({ paymentDelay }) => ({
	type: ActionTypes.FETCH_PAYMENT_DELAY_SUCCESS,
	payload: { paymentDelay },
});

/**
 * @function
 * @name fetchPaymentDelayFailure
 * @description Action triggered as a result to a failed payment delay fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchPaymentDelayFailure = (error) => ({
	type: ActionTypes.FETCH_PAYMENT_DELAY_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Payment delay creation actions ////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name addPaymentDelayRequest
 * @description Action triggered anytime a payment delay creation call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const addPaymentDelayRequest = () => ({ type: ActionTypes.ADD_PAYMENT_DELAY_REQUEST });

/**
 * @function
 * @name addPaymentDelaySuccess
 * @description Action triggered as a result to a successful payment delay creation API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} paymentDelay The created payment delay object.
 *
 * @returns {object}
 */
const addPaymentDelaySuccess = ({ paymentDelay }) => ({
	type: ActionTypes.ADD_PAYMENT_DELAY_SUCCESS,
	payload: { paymentDelay },
});

/**
 * @function
 * @name addPaymentDelayFailure
 * @description Action triggered as a result to a failed payment delay creation API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const addPaymentDelayFailure = (error) => ({
	type: ActionTypes.ADD_PAYMENT_DELAY_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// ///////////////// Payment delay update actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name updatePaymentDelayRequest
 * @description Action triggered anytime a payment delay update call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const updatePaymentDelayRequest = () => ({ type: ActionTypes.UPDATE_PAYMENT_DELAY_REQUEST });

/**
 * @function
 * @name updatePaymentDelaySuccess
 * @description Action triggered as a result to a successful payment delay update API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} paymentDelay The updated payment delay object.
 *
 * @returns {object}
 */
const updatePaymentDelaySuccess = ({ paymentDelay }) => ({
	type: ActionTypes.UPDATE_PAYMENT_DELAY_SUCCESS,
	payload: { paymentDelay },
});

/**
 * @function
 * @name updatePaymentDelayFailure
 * @description Action triggered as a result to a failed payment delay update API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const updatePaymentDelayFailure = (error) => ({
	type: ActionTypes.UPDATE_PAYMENT_DELAY_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Payment delay removal actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name removePaymentDelayRequest
 * @description Action triggered anytime a payment delay deletion call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const removePaymentDelayRequest = () => ({ type: ActionTypes.REMOVE_PAYMENT_DELAY_REQUEST });

/**
 * @function
 * @name removePaymentDelaySuccess
 * @description Action triggered as a result to a successful payment delay deletion API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} paymentDelay The removed payment delay object.
 *
 * @returns {object}
 */
const removePaymentDelaySuccess = ({ deletedPaymentDelayId }) => ({
	type: ActionTypes.REMOVE_PAYMENT_DELAY_SUCCESS,
	payload: { deletedPaymentDelayId },
});

/**
 * @function
 * @name removePaymentDelayFailure
 * @description Action triggered as a result to a failed payment delay deletion API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const removePaymentDelayFailure = (error) => ({
	type: ActionTypes.REMOVE_PAYMENT_DELAY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// //////////////// Payment delay archiving actions /////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name archivePaymentDelayRequest
 * @description Action triggered anytime an payment delay archiving call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archivePaymentDelayRequest = () => ({ type: ActionTypes.ARCHIVE_PAYMENT_DELAY_REQUEST });

/**
 * @function
 * @name archivePaymentDelaySuccess
 * @description	Action triggered as a result to a successful payment delay archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archivePaymentDelaySuccess = () => ({
	type: ActionTypes.ARCHIVE_PAYMENT_DELAY_SUCCESS,
});

/**
 * @function
 * @name archivePaymentDelayFailure
 * @description Action triggered as a result to a failed payment delay archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const archivePaymentDelayFailure = (error) => ({
	type: ActionTypes.ARCHIVE_PAYMENT_DELAY_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////////// //
// ///////////////// Payment delay restoring actions //////////////// //
// ////////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restorePaymentDelayRequest
 * @description Action triggered anytime an payment delay restoring call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restorePaymentDelayRequest = () => ({ type: ActionTypes.RESTORE_PAYMENT_DELAY_REQUEST });

/**
 * @function
 * @name restorePaymentDelaySuccess
 * @description	Action triggered as a result to a successful payment delay restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restorePaymentDelaySuccess = () => ({
	type: ActionTypes.RESTORE_PAYMENT_DELAY_SUCCESS,
});

/**
 * @function
 * @name restorePaymentDelayFailure
 * @description Action triggered as a result to a failed payment delays restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restorePaymentDelayFailure = (error) => ({
	type: ActionTypes.RESTORE_PAYMENT_DELAY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// //////////////// Payment delay favorite actions //////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name favoritePaymentDelayRequest
 * @description Action triggered anytime an payment delay favorite call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const favoritePaymentDelayRequest = () => ({ type: ActionTypes.FAVORITE_PAYMENT_DELAY_REQUEST });

/**
 * @function
 * @name favoritePaymentDelaySuccess
 * @description	Action triggered as a result to a successful payment delay favorite API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const favoritePaymentDelaySuccess = () => ({
	type: ActionTypes.FAVORITE_PAYMENT_DELAY_SUCCESS,
});

/**
 * @function
 * @name favoritePaymentDelayFailure
 * @description Action triggered as a result to a failed payment delay favorite API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const favoritePaymentDelayFailure = (error) => ({
	type: ActionTypes.FAVORITE_PAYMENT_DELAY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPaymentDelayList
 * @description Method used to update the payment delay list.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchPaymentDelayList = (params) => (dispatch) => {
	dispatch(fetchPaymentDelayListRequest());

	return PaymentDelaysApi.fetchPaymentDelays(params)
		.then(({ paymentDelays, totalCount }) => dispatch(fetchPaymentDelayListSuccess({ paymentDelays, totalCount })))
		.catch((error) => dispatch(fetchPaymentDelayListFailure(error)));
};

/**
 * @function
 * @name fetchPaymentDelay
 * @description Method used to fetch the latest version of a specific payment delay.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} paymentDelayId	The id of the payment delay we want to retrieve.
 */
export const fetchPaymentDelay = (paymentDelayId) => (dispatch) => {
	dispatch(fetchPaymentDelayRequest());

	return PaymentDelaysApi.fetchPaymentDelayById(paymentDelayId)
		.then(({ paymentDelay }) => dispatch(fetchPaymentDelaySuccess({ paymentDelay })))
		.catch((error) => dispatch(fetchPaymentDelayFailure(error)));
};

/**
 * @function
 * @name addPaymentDelay
 * @description Method used to add a new payment delay instance to the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} paymentDelayData		The data to create the new payment delay from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addPaymentDelay = (paymentDelayData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addPaymentDelayRequest());

	return PaymentDelaysApi.createPaymentDelay(paymentDelayData)
		.then(({ paymentDelay }) => {
			toast.success(i18next.t('payment_delay.creation.toasts.success', { name: formatPaymentDelayName(paymentDelay) }));
			dispatch(addPaymentDelaySuccess({ paymentDelay }));
			redirectOnSuccess(onSuccessRoute);

			return paymentDelay;
		})
		.catch((error) => {
			toast.error(i18next.t('payment_delay.creation.toasts.error'));
			dispatch(addPaymentDelayFailure(error));
		});
};

/**
 * @function
 * @name updatePaymentDelay
 * @description Method used to update an existing payment delay instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} paymentDelayData	The object to update the payment delay with.
 * @param {string} paymentDelayId	The id of the payment delay we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updatePaymentDelay = (paymentDelayData, paymentDelayId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updatePaymentDelayRequest());

	return PaymentDelaysApi.updatePaymentDelay(paymentDelayData, paymentDelayId)
		.then(({ paymentDelay }) => {
			dispatch(updatePaymentDelaySuccess({ paymentDelay }));
			toast.success(i18next.t('payment_delay.edition.toasts.success', { name: formatPaymentDelayName(paymentDelay) }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch((error) => {
			toast.error(i18next.t('payment_delay.edition.toasts.error'));
			dispatch(updatePaymentDelayFailure(error));
		});
};

/**
 * @function
 * @name removePaymentDelay
 * @description Method used to remove an existing payment delay instance from the database.
 *
 * @author Matthieu Schaerlinger
 * @author Timothée Simon-Franza
 *
 * @param {object} paymentDelay			The payment delay we want to remove from the database.
 * @param {string} paymentDelay.id		The id of the payment delay we want to remove from the database.
 * @param {string} paymentDelay.number	The number of the payment delay we want to remove from the database.
 * @param {string} paymentDelay.scale	The scale of the payment delay we want to remove from the database.
 * @param {string} paymentDelay.term	The term of the payment delay we want to remove from the database.
 */
export const removePaymentDelay = ({ id, number, scale, term }) => (dispatch) => {
	dispatch(removePaymentDelayRequest());

	return PaymentDelaysApi.deletePaymentDelay(id)
		.then(({ deletedPaymentDelayId }) => {
			dispatch(removePaymentDelaySuccess({ deletedPaymentDelayId }));
			toast.success(i18next.t('payment_delay.deletion.toasts.success', { name: formatPaymentDelayName({ number, scale, term }) }));
		})
		.catch((error) => {
			dispatch(removePaymentDelayFailure(error));
			toast.error(i18next.t('payment_delay.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archivePaymentDelay
 * @description	Method used to archive an existing payment delay instance from the database.
 *
 * @author Matthieu Schaerlinger
 * @author Timothée Simon-Franza
 *
 * @param {object} paymentDelay			The payment delay we want to archive.
 * @param {string} paymentDelay.id		The id of the payment delay we want to archive.
 * @param {string} paymentDelay.number	The number of the payment delay we want to archive.
 * @param {string} paymentDelay.scale	The scale of the payment delay we want to archive.
 * @param {string} paymentDelay.term	The term of the payment delay we want to archive.
 */
export const archivePaymentDelay = ({ id, number, scale, term }) => (dispatch) => {
	dispatch(archivePaymentDelayRequest());

	return PaymentDelaysApi.updatePaymentDelay({ archived: true }, id)
		.then(() => {
			dispatch(archivePaymentDelaySuccess());
			toast.success(i18next.t('payment_delay.archiving.toasts.success', { name: formatPaymentDelayName({ number, scale, term }) }));
		})
		.catch((error) => {
			dispatch(archivePaymentDelayFailure(error));
			toast.error(i18next.t('payment_delay.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restorePaymentDelay
 * @description	Method used to restore an existing payment delay instance from the database.
 *
 * @author Matthieu Schaerlinger
 * @author Timothée Simon-Franza
 *
 * @param {object} paymentDelay			The payment delay we want to restore.
 * @param {string} paymentDelay.id		The id of the payment delay we want to restore.
 * @param {string} paymentDelay.number	The number of the payment delay we want to restore.
 * @param {string} paymentDelay.scale	The scale of the payment delay we want to restore.
 * @param {string} paymentDelay.term	The term of the payment delay we want to restore.
 */
export const restorePaymentDelay = ({ id, number, scale, term }) => (dispatch) => {
	dispatch(restorePaymentDelayRequest());

	return PaymentDelaysApi.updatePaymentDelay({ archived: false }, id)
		.then(() => {
			dispatch(restorePaymentDelaySuccess());
			toast.success(i18next.t('payment_delay.restoring.toasts.success', { name: formatPaymentDelayName({ number, scale, term }) }));
		})
		.catch((error) => {
			dispatch(restorePaymentDelayFailure(error));
			toast.error(i18next.t('payment_delay.restoring.toasts.error'));
		});
};

/**
 * @function
 * @name favoritePaymentDelay
 * @description	Method used to mark an existing payment delay instance from the database as favorite.
 *
 * @author Matthieu Schaerlinger
 * @author Timothée Simon-Franza
 *
 * @param {object} paymentDelay			The payment delay we want to remove from the database.
 * @param {string} paymentDelay.id		The id of the payment delay we want to remove from the database.
 * @param {number} paymentDelay.number	The number of the payment delay we want to remove from the database.
 * @param {string} paymentDelay.scale	The scale of the payment delay we want to remove from the database.
 * @param {string} paymentDelay.term	The term of the payment delay we want to remove from the database.
 */
export const favoritePaymentDelay = ({ id, number, scale, term }) => (dispatch) => {
	dispatch(favoritePaymentDelayRequest());

	return PaymentDelaysApi.updatePaymentDelay({ favorite: true }, id)
		.then(() => {
			dispatch(favoritePaymentDelaySuccess());
			toast.success(i18next.t('payment_delay.edition.toasts.success', { name: formatPaymentDelayName({ number, scale, term }) }));
		})
		.catch((error) => {
			dispatch(favoritePaymentDelayFailure(error));
			toast.error(i18next.t('payment_delay.edition.toasts.error'));
		});
};
