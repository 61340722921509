import update from 'immutability-helper';

/**
 * @function
 * @name clearSelection
 * @description Clears the current selection
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object}	state	The current state
 *
 * @returns {object} The updated state value
 */
const clearSelection = (state) => {
	const { selectedElements } = state;

	return {
		...state,
		selectedElements: update(selectedElements, {
			$set: [],
		}),
	};
};

export default clearSelection;
