import { forwardRef, useCallback, useEffect, useId, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import { useForwardedRef } from 'lib/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { reactSelectStyle } from 'theme/inputStyles';

import useDynamicFormInput from '../useDynamicFormInput';

import { Error, Hint, Label } from './DynamicFormInput';

/**
 * @name SelectInput
 * @description A select component and a text input, inside the same row, inside a dynamic form.
 *
 * @author Audrey Clerc
 * @author Yann Hodiesne
 *
 * @param {string}	selectName			The select's name.
 * @param {string}	inputName			The input's name.
 * @param {string}	label				The string to display as a label for the wrapped input.
 * @param {Array}	options				The list of elements to display as select options.
 * @param {string}	[name]				A global name prefix, only for internal use.
 * @param {string}	[hint]				A hint message to display below the input (if no error is displayed).
 * @param {string}	[inputType]			The input's type ('text', 'email', 'password', 'search', 'tel', 'url').
 * @param {string}	[inputPlaceholder]	The value to display as a placeholder in the input.
 * @param {object}	[inputRules={}]		The validation rules to apply to this input.
 * @param {string}	[selectPlaceholder]	The value to display as a placeholder in the select.
 * @param {object}	[selectRules={}]	The validation rules to apply to this select.
 * @param {bool}	[isMulti=false]		Whether the user can select several options.
 * @param {string}	[labelKey]			The key to use for the option's label.
 * @param {string}	[valueKey]			The key to use for the option's value.
 * @param {bool}	[searchable=true]	Whether the user can search for options.
 * @param {boolean}	[isLoading]			Whether the options array is being loaded.
 * @param {bool}	[allowNull=false]	Whether the component should display a "null option" to the user.
 */
const SelectInput = forwardRef(({
	selectName,
	inputName,
	name,
	label,
	options,
	hint,
	inputType,
	inputPlaceholder,
	inputRules,
	selectPlaceholder,
	selectRules,
	isMulti,
	labelKey,
	valueKey,
	searchable,
	isLoading,
	allowNull,
	className,
	...props
}, ref) => {
	const resolvedRef = useForwardedRef(ref);
	const { t } = useTranslation();

	// === SELECT ===

	// This is the 'null' option displayed either when allowNull is set to true, or when the provided options array is empty
	const nullValue = useMemo(() => ({ label: '\u00a0', value: 'null', isDisabled: !allowNull }), [allowNull]);

	const [selectValue, setSelectValue] = useState();
	const currentSelectValue = useRef();

	const updateCurrentValue = useCallback((newValue = selectValue) => {
		if (!isMulti) {
			currentSelectValue.current = newValue?.value === 'null' ? null : newValue?.value;
		} else {
			currentSelectValue.current = (newValue?.map((option) => option.value) ?? []).map((val) => (val === 'null' ? null : val));
		}
	}, [isMulti, selectValue]);

	updateCurrentValue();

	// Format the provided options to match react-select's requirements
	const formattedOptions = useMemo(() => {
		const normalizedOptions = options.map((option) => ({
			label: option[labelKey],
			value: option[valueKey],
		}));

		if (allowNull) {
			return [nullValue, ...normalizedOptions];
		}

		return normalizedOptions;
	}, [allowNull, labelKey, nullValue, options, valueKey]);

	const getSelectValue = useCallback(() => currentSelectValue.current, []);

	const {
		defaultValue: defaultSelectValue,
		isDisabled: isSelectDisabled,
		isInvalid: isSelectInvalid,
		isOptional: isSelectOptional,
		isReadOnly: isSelectReadOnly,
		validationError: selectValidationError,
		enableValidationOnChange: enableSelectValidationOnChange,
		onChangeHandler: onSelectChangeHandler,
	} = useDynamicFormInput(name ? `${name}.${selectName}` : selectName, getSelectValue, selectRules, props);

	const initialized = useRef(false);
	useEffect(() => {
		if (!initialized.current) {
			if (defaultSelectValue) {
				let resolvedDefaultValue;

				if (!isMulti) {
					resolvedDefaultValue = formattedOptions.find((option) => option.value === defaultSelectValue || (option.value === 'null' && defaultSelectValue === null));

					if (!resolvedDefaultValue) { return; }
				} else {
					resolvedDefaultValue = formattedOptions.filter((option) => defaultSelectValue.includes(option.value));

					if (resolvedDefaultValue.length !== defaultSelectValue.length) { return; }
				}

				setSelectValue(resolvedDefaultValue);
			} else {
				setSelectValue(!isMulti ? nullValue : [nullValue]);
			}

			initialized.current = true;
		}
	}, [defaultSelectValue, formattedOptions, isMulti, nullValue]);

	const onSelectChange = useCallback((newValue) => {
		setSelectValue(newValue);
		updateCurrentValue(newValue);

		onSelectChangeHandler();
	}, [onSelectChangeHandler, updateCurrentValue]);

	// === INPUT ===

	const inputRef = useRef();

	const getInputValue = useCallback(() => inputRef.current.value, []);

	const {
		defaultValue: defaultInputValue,
		isDisabled: isInputDisabled,
		isInvalid: isInputInvalid,
		isOptional: isInputOptional,
		isReadOnly: isInputReadOnly,
		validationError: inputValidationError,
		enableValidationOnChange: enableInputValidationOnChange,
		onChangeHandler: onInputChangeHandler,
	} = useDynamicFormInput(name ? `${name}.${inputName}` : inputName, getInputValue, inputRules, props);

	const [inputValue, setInputValue] = useState(defaultInputValue ?? '');

	const onInputChange = useCallback(({ target: { value: newValue } }) => {
		setInputValue(newValue);
		onInputChangeHandler();
	}, [onInputChangeHandler]);

	/// === REF ===

	useImperativeHandle(resolvedRef, () => ({
		setValue: (newValue) => {
			const newSelectValue = _.get(newValue, selectName);

			if (!isMulti) {
				const resolvedValue = formattedOptions.find((option) => option.value === newSelectValue || (option.value === 'null' && newSelectValue === null));

				if (!resolvedValue) { throw new Error(`Unable to find value "${newSelectValue}"`); }

				onSelectChange(resolvedValue);
			} else {
				const resolvedValues = formattedOptions.filter((option) => newSelectValue.includes(option.value));

				if (resolvedValues.length !== newSelectValue.length) { throw new Error(`Unable to find values "${newSelectValue}"`); }

				onSelectChange(resolvedValues);
			}

			const newInputValue = _.get(newValue, inputName);
			onInputChange({ target: { value: newInputValue } });
		},
	}), [formattedOptions, inputName, isMulti, onInputChange, onSelectChange, selectName]);

	// === RENDER ===

	const id = useId();

	const isDisabled = isInputDisabled && isSelectDisabled;
	const isInvalid = isInputInvalid || isSelectInvalid;
	const isOptional = isInputOptional && isSelectOptional;
	const validationError = selectValidationError || inputValidationError;

	return (
		<div className={`input-wrapper${isDisabled ? ' disabled' : ''}${className ?? ''}`}>
			<Label disabled={isDisabled} inputId={id} isInvalid={isInvalid}>
				{label}
				{isOptional && ` (${t('form.optional')})`}
			</Label>
			<div className="select-input">
				<ReactSelect
					className={`react-select${isSelectDisabled ? ' disabled' : ''}`}
					name={selectName}
					placeholder={isLoading ? t('components.select.loading') : selectPlaceholder}
					isMulti={isMulti}
					options={formattedOptions}
					isDisabled={isSelectDisabled || isSelectReadOnly}
					isLoading={isLoading}
					isInvalid={isSelectInvalid}
					isSearchable={searchable}
					styles={reactSelectStyle}
					noOptionsMessage={() => (isLoading ? t('components.select.loading') : t('components.select.no_value'))}
					value={selectValue}
					onChange={onSelectChange}
					onBlur={enableSelectValidationOnChange}
				/>
				<input
					ref={inputRef}
					id={id}
					name={inputName}
					disabled={isInputDisabled}
					readOnly={isInputReadOnly}
					placeholder={inputPlaceholder}
					aria-invalid={isInputInvalid}
					type={inputType}
					value={inputValue}
					onChange={onInputChange}
					onBlur={enableInputValidationOnChange}
					className="second"
				/>
			</div>
			{isInvalid && <Error>{validationError ?? ''}</Error>}
			{!isInvalid && <Hint>{hint ?? ''}</Hint>}
		</div>
	);
});

SelectInput.propTypes = {
	selectName: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
	name: PropTypes.string,
	label: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.object).isRequired,
	hint: PropTypes.string,
	inputType: PropTypes.oneOf(['text', 'email', 'password', 'search', 'tel', 'url']),
	inputPlaceholder: PropTypes.string,
	inputRules: PropTypes.object,
	selectPlaceholder: PropTypes.string,
	selectRules: PropTypes.object,
	isMulti: PropTypes.bool,
	labelKey: PropTypes.string,
	valueKey: PropTypes.string,
	searchable: PropTypes.bool,
	isLoading: PropTypes.bool,
	allowNull: PropTypes.bool,
	className: PropTypes.string,
};

SelectInput.defaultProps = {
	name: undefined,
	hint: '',
	inputType: 'text',
	inputPlaceholder: '',
	inputRules: {},
	selectPlaceholder: '',
	selectRules: {},
	isMulti: false,
	labelKey: 'label',
	valueKey: 'value',
	searchable: true,
	isLoading: false,
	allowNull: false,
	className: undefined,
};

SelectInput.displayName = 'SelectInput';

export default SelectInput;
