import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { formatContactName, formatContactNameWithTitle } from 'lib/contacts/formatContactData';
import PropTypes from 'prop-types';
import { addAccessDefinition } from 'redux/actions/accessDefinitions';
import { addContact } from 'redux/actions/contacts/contacts';
import { fetchAllForUser } from 'redux/actions/users';
import { useAllForUserSelector, useCurrentConnectedUserSelector, useUsersLoadingSelector } from 'redux/selectors/users';

import { AccessDefinitionCreationForm } from 'components/accessDefinitions';
import { ContactCreationForm } from 'components/contacts';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Checkbox, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import { locales } from './function';
import UserTitle from './UserTitle';

/**
 * @name UserEditionForm
 * @description A form used to edit a new user.
 *
 * @author Romaric Barthe
 *
 * @param {function}	onSubmit	The method to trigger upon form submission.
 * @param {object}		user		The user object to edit information from.
 */
const UserEditionForm = ({ onSubmit, user }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const allForUserForm = useAllForUserSelector();
	const currentUser = useCurrentConnectedUserSelector();
	const accessDefinitions = useMemo(() => allForUserForm?.accessDefinitions ?? [], [allForUserForm]);
	const companyRoot = useMemo(() => allForUserForm?.root, [allForUserForm]);
	const contacts = useMemo(() => allForUserForm?.contacts ?? [], [allForUserForm]);
	const userToBeUpdated = useMemo(() => allForUserForm?.user ?? user, [allForUserForm?.user, user]);

	const isLoading = useUsersLoadingSelector();

	const contactWithEmailOnly = useMemo(() => (contacts
		.filter((contact) => contact.emails && contact.emails.length > 0)
	), [contacts]);
	const contactsSelectOptions = useMemo(() => (contactWithEmailOnly.filter((contact) => !contact.user || (contact.id) === userToBeUpdated?.contact.id)
		.map((contact) => ({ id: contact.id, name: formatContactName(contact) }))
	), [userToBeUpdated, contactWithEmailOnly]);

	const [selectedContact, setSelectedContact] = useState(userToBeUpdated.contact.id);
	const emailContact = useMemo(() => (contactWithEmailOnly && contactWithEmailOnly.length > 0 && selectedContact
		? contactWithEmailOnly.filter((contact) => contact.id === selectedContact)[0].emails[0]
		: undefined), [contactWithEmailOnly, selectedContact]);

	const canEditUser = useAccessRight(AccessRights.humanResources.users.enhancedRights.CREATE_USER);

	const disabledActivation = useMemo(() => userToBeUpdated?.id === currentUser?.id, [currentUser, userToBeUpdated]);
	const getLocales = useMemo(() => locales(t), [t]);

	const defaultValues = useMemo(() => ({
		...userToBeUpdated,
		accessDefinitions: userToBeUpdated.accessDefinitions?.map(({ id }) => (id ?? '')) ?? [],
		contact: userToBeUpdated.contact.id ?? '',
		username: userToBeUpdated.username.includes('-') ? userToBeUpdated.username.split('-')[1] : userToBeUpdated.username,
	}), [userToBeUpdated]);

	const contactFullname = useMemo(() => formatContactNameWithTitle(user.contact), [user]);
	const contactPosition = useMemo(() => user.contact?.position?.name, [user]);

	const { formProps, buttonProps } = useSubmitButton();

	// Props to pass down to the select input so that it will display an access definition creation modal when clicking on the "+" icon.
	const accessDefinitionCreationModal = useFormModal(
		AccessDefinitionCreationForm, t('pages.human_resources.users.access_definition.creation'), fetchAllForUser, addAccessDefinition
	);
	// Props to pass down to the select input so that it will display a contact creation modal when clicking on the "+" icon.
	const contactCreationModal = useFormModal(ContactCreationForm, t('pages.human_resources.users.contact.creation'), fetchAllForUser, addContact);

	const administratorRef = useRef();
	const companyRef = useRef();
	const standardRef = useRef();

	const [isAdministrator, setIsAdministrator] = useState(user.companyOwner);
	const [isStandard, setIsStandard] = useState(!user.companyOwner);

	/**
	 * @function
	 * @name onChangeContact
	 * @description A callback method used to set the selected Contact.
	 *
	 * @author Romaric Barthe
	 */
	const onChangeContact = useCallback((contact) => {
		setSelectedContact(contact);
	}, []);

	/**
	 * @function
	 * @name onChangeAdmin
	 * @description A callback method used to switch between administrator and standard.
	 *
	 * @author Romaric Barthe
	 */
	const onChangeAdmin = useCallback((checked) => {
		setIsAdministrator(checked);
		setIsStandard(!checked);
	}, []);

	/**
	 * @function
	 * @name onChangeAdmin
	 * @description A callback method used to switch between administrator and standard.
	 *
	 * @author Romaric Barthe
	 */
	const onChangeStandard = useCallback((checked) => {
		setIsAdministrator(!checked);
		setIsStandard(checked);
	}, []);

	useEffect(() => {
		administratorRef.current.setValue(isAdministrator);
		standardRef.current.setValue(isStandard);
	}, [isAdministrator, isStandard]);

	useEffect(() => {
		dispatch(fetchAllForUser());
	}, [dispatch]);

	useEffect(() => { companyRef.current.setValue(`${companyRoot}-`); }, [companyRoot]);

	return (
		<DynamicForm
			cleanFormData
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			disabled={!canEditUser}
			{...formProps}
		>
			<UserTitle
				contactFullname={contactFullname}
				contactPosition={contactPosition}
				currentUser={currentUser}
				userToBeUpdated={userToBeUpdated}
			/>

			<Checkbox
				label={t('user.edition.inputs.enabled.label')}
				name="enabled"
				disabled={disabledActivation}
				labelFirst={false}
			/>

			<Select
				isLoading={isLoading}
				label={t('user.edition.inputs.contact.label')}
				labelKey="name"
				name="contact"
				onChange={onChangeContact}
				options={contactsSelectOptions}
				rules={{
					required: Validators.isRequired(t('user.edition.inputs.contact.validation_errors.required')),
				}}
				valueKey="id"
				{...contactCreationModal}
			/>

			{emailContact && (
				<div className="user-email">
					<div>{t('user.creation.inputs.email.label')}</div>
					<div className="email">{emailContact}</div>
				</div>
			)}
			<div className="user-username">
				<TextInput
					ref={companyRef}
					label={t('user.creation.inputs.root.label')}
					name="root"
					type="text"
					rules={{
						required: Validators.isRequired(t('user.creation.inputs.username.validation_errors.required')),
					}}
					disabled
				/>
				<TextInput
					label={t('user.edition.inputs.username.label')}
					name="username"
					rules={{
						minLength: Validators.hasMinLength(6, t('user.edition.inputs.username.validation_errors.length')),
						required: Validators.isRequired(t('user.edition.inputs.username.validation_errors.required')),
					}}
					type="text"
				/>
			</div>
			<Select
				label={t('user.edition.inputs.locale.label')}
				labelKey="label"
				name="locale"
				options={getLocales}
				rules={{
					required: Validators.isRequired(t('user.edition.inputs.locale.validation_errors.required')),
				}}
				valueKey="value"
			/>

			<Checkbox
				ref={administratorRef}
				label={t('access_definition.creation.inputs.administrator.label')}
				name="administrator"
				labelFirst={false}
				onChange={onChangeAdmin}
			/>

			<Checkbox
				ref={standardRef}
				label={t('access_definition.creation.inputs.standard.label')}
				name="standard"
				labelFirst={false}
				onChange={onChangeStandard}
			/>

			{isStandard && (
				<Select
					isLoading={isLoading}
					isMulti
					label={t('user.edition.inputs.access_definition.label')}
					labelKey="name"
					name="accessDefinitions"
					options={accessDefinitions}
					valueKey="id"
					{...accessDefinitionCreationModal}
				/>
			)}

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditUser ? t('user.edition.action') : t('user.edition.close')}
			</Button>
		</DynamicForm>
	);
};

UserEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	user: PropTypes.shape({
		accessDefinitions: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
		})).isRequired,
		companyOwner: PropTypes.bool.isRequired,
		contact: PropTypes.shape({
			id: PropTypes.string.isRequired,
			position: PropTypes.shape({
				name: PropTypes.string,
			}),
		}).isRequired,
		enabled: PropTypes.bool.isRequired,
		id: PropTypes.string.isRequired,
		locale: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
	}).isRequired,
};

export default UserEditionForm;
