import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

import DataFilterForm from './DataFilterForm';

import styles from 'styles/components/dataTables/_filterPanel.module.scss';

/**
 * @name DataListFilterPanel
 * @description A component used to handle filtering on a data list.
 *
 * @author Timothée Simon-Franza
 *
 * @param {function} 	applyFilterCallBack				The method used to update the current filter rule.
 * @param {function} 	onCloseButtonClick				The method to close the filter form.
 * @param {array} 		columns							The list of columns available to filter.
 * @param {boolean}		open							Whether the filter form is open or not.
 */
const DataListFilterPanel = ({ columns, onCloseButtonClick, open, applyFilterCallBack }) => {
	const { t } = useTranslation();

	return (
		<aside className={styles['filter-panel']} aria-hidden={!open}>
			<h4>{t('components.table.filters.actions.filter')}</h4>
			<Button className="icon-only" onClick={onCloseButtonClick}>
				<X />
			</Button>
			<DataFilterForm columns={columns} applyFilterCallBack={applyFilterCallBack} />
		</aside>
	);
};

DataListFilterPanel.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.shape({
		accessor: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
	})),
	onCloseButtonClick: PropTypes.func.isRequired,
	open: PropTypes.bool,
	applyFilterCallBack: PropTypes.func.isRequired,
};

DataListFilterPanel.defaultProps = {
	columns: [],
	open: false,
};

export default DataListFilterPanel;
