import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useQuotationsSelectLoadingSelector
 * @description A selector to retrieve the loading state from the quotationsSelect redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {boolean}
 */
const useQuotationsSelectLoadingSelector = () => {
	const loading = useSelector((state) => state.quotationsSelect?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useQuotationsSelectListSelector
 * @description A selector to retrieve the quotationsSelect list from the quotationsSelect redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {Array}
 */
const useQuotationsSelectListSelector = () => {
	const quotations = useSelector((state) => (state.quotationsSelect?.items ?? []), shallowEqual);

	return quotations;
};

/**
 * @function
 * @name useQuotationsSelectTotalCountSelector
 * @description A selector to retrieve the totalCount value from the quotationsSelect redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {number|undefined}
 */
const useQuotationsSelectTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.quotationsSelect?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useQuotationsSelectListSelector,
	useQuotationsSelectLoadingSelector,
	useQuotationsSelectTotalCountSelector,
};
