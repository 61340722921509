import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { convertStringToFloat } from 'lib/shared/conversion';
import PropTypes from 'prop-types';
import { addAccount, fetchAccountList } from 'redux/actions/accounts';
import { useAccountActiveListSelector, useAccountsLoadingSelector } from 'redux/selectors/accounts';

import { AccountCreationForm } from 'components/accounts';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Select, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name VatRateEditionForm
 * @description A form used to update an existing vat rate.
 *
 * @author Romaric Barthe
 *
 * @param {function}	onSubmit	The method to trigger upon form submission.
 * @param {object}		vatRate		The vat rate object to edit information from.
 */
const VatRateEditionForm = ({ onSubmit, vatRate }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAccountList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const accounts = useAccountActiveListSelector();
	const areAccountsLoading = useAccountsLoadingSelector();

	const canEditVatRate = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING) && !vatRate?.archived;

	const { formProps, buttonProps } = useSubmitButton();
	// Props to pass down to the select input so that it will display an account creation modal when clicking on the "+" icon.
	const accountFormModal = useFormModal(AccountCreationForm, t('pages.settings.vat_rates.accounts.creation'), fetchAccountList, addAccount);

	const defaultValues = useMemo(() => ({
		...vatRate,
		account: vatRate.account?.id ?? undefined,
	}), [vatRate]);

	const handleSubmit = useCallback((formData) => {
		const { favorite, ...submitData } = formData; // in order not to send favorite in data because otherwise it will update the favorite field
		onSubmit(canEditVatRate ? { ...submitData, rate: convertStringToFloat(submitData.rate) } : submitData);
	}, [canEditVatRate, onSubmit]);

	return (
		<DynamicForm
			onSubmit={handleSubmit}
			defaultValues={defaultValues}
			disabled={!canEditVatRate}
			{...formProps}
		>
			<TextInput
				label={t('vat_rate.edition.inputs.rate.label')}
				name="rate"
				placeholder={t('vat_rate.edition.inputs.rate.placeholder')}
				rules={{
					required: Validators.isRequired(t('vat_rate.edition.inputs.rate.validation_errors.required')),
				}}
				step=".01"
				type="number"
			/>
			<TextArea
				label={t('vat_rate.edition.inputs.description.label')}
				name="description"
				placeholder={t('vat_rate.edition.inputs.description.placeholder')}
				type="text"
			/>
			<Select
				allowNull
				label={t('vat_rate.edition.inputs.account.label')}
				labelKey="name"
				name="account"
				options={accounts}
				valueKey="id"
				isLoading={areAccountsLoading}
				{...accountFormModal}
			/>
			<Button className="primary" type="submit" {...buttonProps}>
				{canEditVatRate ? t('vat_rate.edition.action') : t('vat_rate.edition.close')}
			</Button>
		</DynamicForm>
	);
};

VatRateEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	vatRate: PropTypes.shape({
		account: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		archived: PropTypes.bool,
		description: PropTypes.string,
		rate: PropTypes.number.isRequired,
	}).isRequired,
};

export default VatRateEditionForm;
