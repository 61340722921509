import { formatPaymentDelayName } from 'lib/paymentDelays/formatPaymentDelayData';
import { formatDateTime } from 'lib/shared/format';
import moment from 'moment';

/**
 * @function
 * @name formatInvoiceName
 * @description Concatenate number format values to provide a full name for the invoice.
 *
 * @author Romaric Barthe
 *
 * @param {object} invoice  		The invoice to be named.
 * @param {object} numberFormat   	The number format.
 * @param {func} t   				The translation function.
 *
 * @returns {string}
 */
export const formatInvoiceName = (invoice, numberFormat, t) => {
	if (!invoice) {
		return undefined;
	}

	if (!invoice.editionNumber || (!invoice?.generatedPdf && !invoice?.generatedPdf?.pdfGenerationDate)) {
		return t('invoice.creation.from_not_edited');
	}

	if (!numberFormat) {
		return (invoice.editionNumber ?? '').toString();
	}

	const editionDate = invoice.generatedPdf?.pdfGenerationDate;
	const monthString = numberFormat.monthPrefix ? `${moment(editionDate).format('MM')}-` : '';
	const yearString = numberFormat.yearPrefix ? `${moment(editionDate).format('YYYY')}-` : '';
	let numberString = (invoice.editionNumber ?? '').toString();
	if (numberFormat.digitNumber && numberString.length < numberFormat.digitNumber) {
		numberString = numberString.padStart(numberFormat.digitNumber, '0');
	}

	return `${numberFormat.rootName}-${yearString}${monthString}${numberString}`;
};

/**
 * @function
 * @name formatInvoiceNameList
 * @description Concatenate number format values to provide a full name and reference for the invoice.
 *
 * @author Romaric Barthe
 *
 * @param {object} invoice  		The invoice to be named.
 * @param {object} numberFormat   	The number format.
 * @param {func} t   				The translation function.
 *
 * @returns {string}
 */
export const formatInvoiceNameList = (invoice, numberFormat, t) => {
	if (!invoice) {
		return undefined;
	}

	return formatInvoiceName(invoice, numberFormat, t)
		.concat(' [').concat(invoice.project?.name).concat('] > ')
		.concat(formatDateTime(invoice.updatedAt));
};

/**
 * @function
 * @name formatProjectName
 * @description Returns the provided invoice's project's name if there is one.
 *
 * @author Romaric Barthe
 *
 * @param {object} invoice		The invoice serialised.
 *
 * @returns {string}
 */
export const formatProjectName = (invoice) => ((invoice.project?.name) ?? '');

/**
 * @function
 * @name formatStructureName
 * @description Returns the provided invoice's structure's name if there is one.
 *
 * @author Romaric Barthe
 *
 * @param {object} invoice		The invoice serialised.
 *
 * @returns {string}
 */
export const formatStructureName = (invoice) => ((invoice.structure?.name) ?? '');

/**
 * @function
 * @name formatPaymentDelay
 * @description Returns the provided payment delay's "name" if there is one.
 *
 * @author Romaric Barthe
 *
 * @param {object} invoice			The invoice serialised.
 *
 * @returns {string}
 */
export const formatPaymentDelay = (invoice) => {
	if (!invoice.paymentDelay) {
		return null;
	}

	return formatPaymentDelayName(invoice.paymentDelay);
};
