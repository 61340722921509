import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addAccount } from 'redux/actions/accounts';
import routes from 'routes';

import { AccountCreationForm } from 'components/accounts';

/**
 * @name AccountCreationPage
 * @description A page used to create a new account.
 *
 * @author Romaric Barthe
 */
const AccountCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addAccount(formData, routes.accounting.accounts.list));
	}, [dispatch]);

	return (
		<AccountCreationForm onSubmit={handleFormSubmit} />
	);
};

export default AccountCreationPage;
