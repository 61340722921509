import { useCallback } from 'react';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { QuotationPreCreationForm } from 'components/quotations';

/**
 * @name QuotationPreCreationPage
 * @description A page used to create a new quotation.
 *
 * @author Roland Margelidon
 *
 */
const QuotationPreCreationPage = () => {
	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Roland Margelidon
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		if ((formData.creationType === 'initialisation' || formData.creationType === 'version') && !formData.quotation) {
			return;
		}
		history.push({
			pathname: routes.sales.quotations.quotationCreation,
			state: {
				creationType: formData.creationType,
				quotationParent: formData.creationType !== 'empty' ? formData?.quotation : undefined,
			},
		});
	}, []);

	return (
		<QuotationPreCreationForm onSubmit={handleFormSubmit} />
	);
};

export default QuotationPreCreationPage;
