/* eslint-disable import/prefer-default-export */
import { get } from 'lib/shared/http';

/**
 * @function
 * @name fetchRegistrationTypes
 * @description Retrieves a list of registration types from the API.
 *
 * @author Romaric Barthe
 *
 * @returns {Promise}
 */
export const fetchRegistrationTypes = () => get('/api/registration-types');
