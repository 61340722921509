import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addHistory } from 'redux/actions/histories/histories';
import routes from 'routes';

import { HistoryCreationForm } from 'components/histories';

/**
 * @name HistoryCreationPage
 * @description A page used to create a new history.
 *
 * @author Audrey Clerc
 */
const HistoryCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addHistory(formData, routes.sales.crm.list));
	}, [dispatch]);

	return (
		<HistoryCreationForm onSubmit={handleFormSubmit} />
	);
};

export default HistoryCreationPage;
