import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchCurrencyList } from 'redux/actions/currencies';
import { useCurrenciesLoadingSelector, useCurrencyListSelector } from 'redux/selectors/currencies';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { DateInput, NumberInput, Select, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name OfferCreationForm
 * @description A form used to create a new offer.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const OfferCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const currentUser = useCurrentConnectedUserSelector();
	const dateToday = useMemo(() => new Date(), []);

	useEffect(() => {
		dispatch(fetchCurrencyList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const currencies = useCurrencyListSelector();
	const areCurrenciesLoading = useCurrenciesLoadingSelector();

	const [validFrom, setValidFrom] = useState(dateToday);
	const [currency, setCurrency] = useState(currentUser?.company?.mainPartner?.currency?.id);
	const currencyName = useMemo(() => (currencies.length > 0 ? currencies.filter((c) => c.id === currency)[0].name : ''), [currencies, currency]);

	const { formProps, buttonProps } = useSubmitButton();

	const defaultValues = useMemo(() => ({
		currency: currentUser?.company?.mainPartner?.currency?.id || '',
		validFrom: dateToday,
	}), [currentUser?.company?.mainPartner?.currency?.id, dateToday]);

	const handleSubmit = useCallback((formData) => {
		const historyData = {
			...formData,
			validFrom,
			rawMaterials: [],
			finalProducts: [],
		};

		onSubmit(historyData);
	}, [onSubmit, validFrom]);

	return (
		<DynamicForm onSubmit={handleSubmit} defaultValues={defaultValues} {...formProps}>
			<TextInput
				label={t('offer.creation.inputs.code.label')}
				name="code"
				type="text"
			/>
			<TextInput
				label={t('offer.creation.inputs.name.label')}
				placeholder={t('offer.creation.inputs.name.placeholder')}
				name="name"
				rules={{
					required: Validators.isRequired(t('offer.creation.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextArea
				label={t('offer.creation.inputs.description.label')}
				placeholder={t('offer.creation.inputs.description.placeholder')}
				name="description"
			/>
			<DateInput
				label={t('offer.creation.inputs.valid_from.label')}
				name="validFrom"
				onChange={setValidFrom}
				required
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
			<NumberInput
				allowNull
				className="number-input"
				decimalScale={2}
				fixedDecimalScale
				icon={currencyName}
				label={t('offer.creation.inputs.price.label')}
				name="price"
				step={0.01}
			/>
			<Select
				isLoading={areCurrenciesLoading}
				label={t('offer.creation.inputs.currency.label')}
				labelKey="name"
				name="currency"
				onChange={setCurrency}
				options={currencies}
				rules={{
					required: Validators.isRequired(t('offer.creation.inputs.currency.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<DateInput
				allowNull
				label={t('offer.creation.inputs.valid_until.label')}
				name="validTo"
				rules={{
					minDate: Validators.minDate(validFrom, t('components.date_picker.format'), t('offer.creation.inputs.date.validation_errors.minDate')),
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('offer.creation.action')}</Button>
		</DynamicForm>
	);
};

OfferCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default OfferCreationForm;
