import { forwardRef, useId } from 'react';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

/**
 * @name Checkbox
 * @description A styled version of the checkbox input
 *
 * @author Romaric Barthe
 * @author Timothée Simon-Franza
 *
 * @param {string}	label				The string to display as a label for the wrapped checkbox.
 * @param {bool}	labelFirst			Whether the label should be placed first.
 * @param {bool} 	disabled 			Whether the input is disabled.
 */
const Checkbox = forwardRef(({ label, labelFirst, disabled, ...otherProps }, ref) => {
	const id = useId();

	return (
		<div className="input-wrapper checkbox-wrapper">
			{labelFirst && label && (
				<label htmlFor={id}>
					{label}
				</label>
			)}
			<input ref={useForwardedRef(ref)} id={id} type="checkbox" disabled={disabled} {...otherProps} />
			{!labelFirst && label && (
				<label htmlFor={id}>
					{label}
				</label>
			)}
		</div>
	);
});

Checkbox.propTypes = {
	disabled: PropTypes.bool,
	label: PropTypes.string,
	labelFirst: PropTypes.bool,
};

Checkbox.defaultProps = {
	disabled: false,
	label: undefined,
	labelFirst: false,
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
