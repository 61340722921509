import PropTypes from 'prop-types';

/**
 * @name RelationTypesCell
 * @description Custom cell for the partnerListPage table displaying a partner's relation types.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 */
const RelationTypesCell = ({ row: { original: { relationTypes } } }) => (
	<ul className="tag-list">
		{relationTypes.map(({ id, name }) => (
			<li key={id}>
				<span className="tag">
					{name}
				</span>
			</li>
		))}
	</ul>
);

RelationTypesCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			relationTypes: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default RelationTypesCell;
