import _ from 'lodash';
import moment from 'moment';

/**
 * @function
 * @name formatDate
 * @description Format a date to a human readable string (e.g. 'June 2nd, 2022')
 *
 * @author Yann Hodiesne
 *
 * @param {date}	date	The date to format.
 *
 * @returns {string}	The formatted date string.
 */
export const formatDate = (date) => (date ? moment(date).format('LL') : '');

/**
 * @function
 * @name formatDateTime
 * @description Format date to 'DD/MM/YYYY - HH:mm:ss'.
 *
 * @author Romaric Barthe
 *
 * @param {date}	date			The date to be formatted.
 *
 * @returns {string}				The converted date format.
 */
export const formatDateTime = (date) => (date ? `${moment(date).format('L')} - ${moment(date).format('HH:mm:ss')}` : '');

/**
 * @function
 * @name formatNumber
 * @description Format any number.
 *
 * @author Romaric Barthe
 *
 * @param {string}	value							The number to be formatted.
 * @param {number}	nbDecimalMax					Maximum number of digits after the decimal separator.
 * @param {number}	nbDecimalMin					Minimum number of digits after the decimal separator.
 * @param {boolean}	allowNegative					Allow negative number.
 * @param {boolean}	displaySign						Display plus sign if number > 0.
 * @param {boolean}	displayThousandSeparator		Display the thousand separator.
 * @param {string}	thousandSeparator				The thousand separator.
 * @param {string}	decimalSeparator				The decimal separator.
 * @param {string}	symbol							Symbol to display at the end.
 *
 * @returns {string}				The converted string into float.
 */
export const formatNumber = (
	value,
	{
		nbDecimal = 2,
		nbDecimalMin = 2,
		allowNegative = true,
		displaySign = false,
		displayThousandSeparator = true,
		thousandSeparator = ' ',
		decimalSeparator = ',',
		symbol = '',
	} = {}
) => {
	if (value === null) {
		return '';
	}

	if (value === 0) {
		return `${displaySign ? '+' : ''}0${nbDecimal > 0 ? `${decimalSeparator}${_.repeat(0, Math.min(nbDecimalMin, nbDecimal))}${symbol !== '' ? ` ${symbol}` : ''}` : ''}`;
	}

	if (value) {
		// TODO: include the following variables into translation.
		const regex = new RegExp(`[^\\d.,${allowNegative ? '-' : ''}]`, 'g');

		// In case, value is a number written in scientific notation.
		let valueToFormat = value;
		if (_.isString(value)) {
			valueToFormat = value
				.replace(regex, '')
				.replace(',', '.')
				.replace(/(.*)\./, (x) => `${x.replace(/\./g, '')}.`);
		}

		let formattedValue = parseFloat(valueToFormat)
			.toFixed(nbDecimal)
			.toString()
			.replace(regex, '')
			.replace(',', decimalSeparator)
			.replace('.', decimalSeparator);

		let indexOfDecSep = formattedValue.indexOf(decimalSeparator);

		// Format decimal part
		if (indexOfDecSep === -1) {
			indexOfDecSep = formattedValue.length;
			if (nbDecimal > 0) {
				// Add trailing zeros
				formattedValue = `${formattedValue}${decimalSeparator}${_.repeat(0, nbDecimal)}`;
			}
		} else if (nbDecimal > 0) {
			const maxEndIndex = indexOfDecSep + nbDecimal + 1;
			if (maxEndIndex < formattedValue.length) {
				// Remove decimals after authorized nbDecimal
				formattedValue = formattedValue.substring(0, maxEndIndex);
			} else {
				const nbZeros = Math.min(maxEndIndex - formattedValue.length, (indexOfDecSep + nbDecimalMin + 1) - formattedValue.length);
				formattedValue = `${formattedValue}${_.repeat(0, nbZeros)}`;
			}
		} else if (nbDecimal === 0) {
			formattedValue = formattedValue.substring(0, indexOfDecSep);
		}

		// Remove trailing zeros if necessary
		let allZeros = true;
		for (let i = formattedValue.length - 1; i >= indexOfDecSep + nbDecimalMin && allZeros; i--) {
			if (formattedValue[i] !== '0' || i === indexOfDecSep + nbDecimalMin) {
				formattedValue = formattedValue.substring(0, i + 1);
				allZeros = false;
			}
		}

		// Add thousand separators
		if (displayThousandSeparator) {
			for (let i = indexOfDecSep - 3; i > 0; i -= 3) {
				formattedValue = `${formattedValue.slice(0, i)}${thousandSeparator}${formattedValue.slice(i)}`;
			}
		}

		formattedValue = _.trimEnd(formattedValue, ',.');

		return displaySign && value >= 0 ? `+${formattedValue} ${symbol}` : `${formattedValue} ${symbol}`;
	}

	return '';
};

/**
 * @function
 * @name formatCreatedBy
 * @description Format the username of the user who created the object.
 *
 * @author Romaric Barthe
 *
 * @param {object} object		The object serialised.
 *
 * @returns {string}
 */
export const formatCreatedBy = (object) => (object.createdBy?.username ?? '');

/**
 * @function
 * @name formatUpdatedBy
 * @description Format the username of the user who updated the object.
 *
 * @author Romaric Barthe
 *
 * @param {object} object		The object serialised.
 *
 * @returns {string}
 */
export const formatUpdatedBy = (object) => (object.updatedBy?.username ?? '');

/**
 * @function
 * @name formatCreatedAt
 * @description Format the object's creation date and time.
 *
 * @author Romaric Barthe
 *
 * @param {object} object		The object serialised.
 *
 * @returns {string}
 */
export const formatCreatedAt = (object) => (formatDateTime(object.createdAt));

/**
 * @function
 * @name formatUpdatedAt
 * @description Format the object's updating date and time.
 *
 * @author Romaric Barthe
 *
 * @param {object} object		The object serialised.
 *
 * @returns {string}
 */
export const formatUpdatedAt = (object) => (formatDateTime(object.updatedAt));

/**
 * @function
 * @name formatCurrencyName
 * @description Format the object's currency.
 *
 * @author Romaric Barthe
 *
 * @param {object} object		The object serialised.
 *
 * @returns {string}
 */
export const formatCurrencyName = (object) => (object.currency?.name ?? '');

/**
 * @function
 * @name concatPostcodeAndCity
 * @description concat postcode and city of an entity if the entity is set.
 *
 * @author Romaric Barthe
 *
 * @param {object} entity   The serialized entity to concat postCode and city.
 *
 * @returns {string}
 */
export const concatPostcodeAndCity = (entity) => {
	if (entity) {
		return (`${entity.postCode ?? ''} ${entity.city ?? ''}`);
	}

	return '';
};

/**
 * @function
 * @name formatFullName
 * @description Format name to 'firstName lastName'
 *
 * @author Audrey Clerc
 *
 * @param {object} field   The field to be formatted
 *
 * @returns
 */
export const formatFullName = (field) => (field ? `${field.firstName} ${field.lastName}` : '');
