/**
 * @function
 * @name readFile
 * @description Promisify a FileReader to read a file asynchronously
 *
 * @author Yann Hodiesne
 *
 * @param {File} file The file to read
 *
 * @returns {Promise}
 */
export const readFile = (file) => new Promise((resolve, reject) => {
	const reader = new FileReader();

	reader.onload = (event) => {
		resolve(event.target.result);
	};

	reader.onerror = reject;
	reader.readAsArrayBuffer(file);
});

/**
 * @function
 * @name readFileToBase64
 * @description Converts the given file's data to base64 and calls the onLoaded callback
 *
 * @author Yann Hodiesne
 *
 * @param {File} file	The file to read the content from.
 */
export const readFileToBase64 = (file) => readFile(file)
	.then((result) => {
		let binary = '';
		const bytes = new Uint8Array(result);
		const len = bytes.byteLength;

		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}

		const fileContent = window.btoa(binary);

		return fileContent;
	});
