import { ActionTypes } from '../../actions/contacts/contactsList';

const initialState = {
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_CONTACT_FOR_LIST_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_CONTACT_FOR_LIST_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_CONTACT_FOR_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.contactsList],
				totalCount: payload.totalCount,
			};
		default:
			return state;
	}
};
