/* eslint-disable import/prefer-default-export */

/**
 * @enum {string}
 * @readonly
 *
 * @name tagGroupments
 * @description The allowed groupments of tags used over the applications.
 * @author Romaric Barthe
 */
const tagGroupments = {
	CONTACT_TITLE: 'contact_title',
	CONTACT_POSITION: 'contact_position',
	TARGET_PHASE: 'target_phase',
};

export {
	tagGroupments,
};
