/* eslint-disable import/prefer-default-export */
import { formatContactNameWithTitle } from 'lib/contacts/formatContactData';

/**
 * @function
 * @name formatProjectClientData
 * @description Returns the provided project's client's data if there is one.
 *
 * @author Romaric Barthe
 *
 * @param {object} project		The project serialised.
 *
 * @returns {string}
 */
export const formatProjectClientData = (project) => {
	const projectClient = project.partner ?? project.contact;
	const projectInvoicedContact = project.invoicedContact ?? project.contact;
	const projectInvoicedPartner = project.invoicedPartner ?? project.partner;
	const projectInvoicedClient = projectInvoicedPartner ?? projectInvoicedContact;

	if (!projectClient) {
		return '';
	}

	return {
		name: projectClient?.name ?? formatContactNameWithTitle(projectClient),
		legalRepresentant: formatContactNameWithTitle(projectClient?.legalRepresentant ?? projectClient),
		legalRepresentantPosition: projectClient?.legalRepresentant?.position?.name ?? projectClient?.position?.name,
		address: projectClient.address ?? '',
		postCode: projectClient.postCode ?? '',
		city: projectClient.city ?? '',
		country: projectClient.country ?? '',
		email: (projectClient.emails && projectClient.emails.length > 0) ? projectClient.emails[0] : '',
		phone: (projectClient.phones && projectClient.phones.length > 0) ? projectClient.phones[0].number : '',
		invoicing_name: projectInvoicedPartner?.name ?? formatContactNameWithTitle(projectInvoicedContact),
		invoicing_legalRepresentant: projectInvoicedPartner
			? (projectInvoicedPartner?.legalRepresentant && formatContactNameWithTitle(projectInvoicedPartner?.legalRepresentant)) ?? ''
			: formatContactNameWithTitle(projectInvoicedContact),
		invoicing_legalRepresentantPosition: projectInvoicedPartner
			? projectInvoicedPartner?.legalRepresentant?.position?.name ?? '' : projectInvoicedContact?.position?.name ?? '',
		invoicing_address: projectInvoicedClient.address ?? '',
		invoicing_postCode: projectInvoicedClient.postCode ?? '',
		invoicing_city: projectInvoicedClient.city ?? '',
		invoicing_country: projectInvoicedClient.country ?? '',
		invoicing_email: (projectInvoicedClient.emails && projectInvoicedClient.emails.length > 0) ? projectInvoicedClient.emails[0] : '',
		invoicing_phone: (projectInvoicedClient.phones && projectInvoicedClient.phones.length > 0) ? projectInvoicedClient.phones[0].number : '',
		projectClient,
		projectInvoicedClient,
	};
};
