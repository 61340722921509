import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDown, ArrowRight, ArrowUp, HelpCircle, PlusCircle, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { airEntities } from 'constants/importEnums';
import update from 'immutability-helper';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { addTemplate } from 'redux/actions/templates';
import { v4 as uuid } from 'uuid';
import { read, utils } from 'xlsx';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { HiddenInput, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';
import { Modal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import { TemplateList, TemplatePreCreationForm } from 'components/templates';

import { FileInput } from '../inputs';

import {
	defaultValues as getDefaultValues,
	entityFieldOptions as getEntityFieldOptions,
	parseImportFieldsForBackend,
} from './functions';

/**
 * @name ImportForm
 * @description A form used to edit an existing target's information.
 *
 * @author Romaric Barthe
 * @author Audrey Clerc
 *
 * @param {object}			bundledUserPermissions			Include all permissions linked to the user and used in the import.
 * @param {linkedEntity}	[linkedEntity = undefined]		The entity's name to create a link with (if there is one). Undefined as default.
 * @param {object}			[linkedObject = undefined]		The object to update information from (if there is one). Undefined as default.
 * @param {function}		onSubmit						The method to trigger upon form submission.
 */
const ImportForm = ({ bundledUserPermissions, linkedEntity, linkedObject, onSubmit }) => {
	const { t } = useTranslation();

	const labelTemp = useMemo(() => ({
		contact: ['contact'],
	}), []);
	const [rows, setRows] = useState([]);
	const rowCount = useMemo(() => Math.max(...rows.map((row) => row?.rowNumber)), [rows]);
	const sortedRows = useMemo(() => rows.sort((a, b) => a.rowNumber - b.rowNumber), [rows]);
	const [isFileUploaded, setIsFileUploaded] = useState(false);
	const areRowsEmpty = useMemo(() => {
		let empty = true;
		rows.forEach((row) => {
			if (row.entity || row.field) {
				empty = false;
			}
		});

		return empty;
	}, [rows]);

	const fileRef = useRef(undefined);
	const fileNameRef = useRef(undefined);

	const databaseEntityOptions = useMemo(() => {
		const options = [];

		if (bundledUserPermissions.canCreateContact) {
			options.push({ id: airEntities.ENTITY_CONTACT, label: t(`etl.import.entity.${airEntities.ENTITY_CONTACT}`) });
		}
		if (bundledUserPermissions.canCreatePartner) {
			options.push({ id: airEntities.ENTITY_PARTNER, label: t(`etl.import.entity.${airEntities.ENTITY_PARTNER}`) });
		}

		return options;
	}, [bundledUserPermissions, t]);

	const defaultValues = useMemo(() => getDefaultValues(rows, t), [rows, t]);

	const listKeys = Object.values(airEntities);

	const createObjectWithEntitiesKeys = useCallback((func) => Object.fromEntries(listKeys.map((key) => [key, func(key)])), [listKeys]);
	const fieldsList = useMemo(() => createObjectWithEntitiesKeys((key) => getEntityFieldOptions(key, t)), [createObjectWithEntitiesKeys, t]);
	const fieldsObjectWithHelp = [
		{ id: 'emails', tooltip: t('etl.import.tooltip.emails') },
		{ id: 'website', tooltip: t('etl.import.tooltip.website') },
		{ id: 'workforce', tooltip: t('etl.import.tooltip.workforce') },
		{ id: 'turnover', tooltip: t('etl.import.tooltip.turnover') },
	];
	const listFieldsUsedId = useMemo(() => createObjectWithEntitiesKeys(
		(key) => rows.filter((row) => (row?.entity && row?.field ? row?.entity.id === key : false)).map((row) => row?.field.id)
	), [createObjectWithEntitiesKeys, rows]);

	const partnerNameOrIdValidation = useMemo(() => listFieldsUsedId.partner.includes('id') || listFieldsUsedId.partner.includes('partner_name'), [listFieldsUsedId]);
	const [submitButtonRef, submitTooltipProps] = useTooltip();

	const listFieldsFiltered = useMemo(() => createObjectWithEntitiesKeys(
		(key) => fieldsList[key].filter((field) => !listFieldsUsedId[key].includes(field.id))
	), [createObjectWithEntitiesKeys, fieldsList, listFieldsUsedId]);

	const fieldsFilteredForRow = useCallback((row) => {
		const fieldFiltered = listFieldsFiltered[row.entity.id];

		return row.field ? [row.field, ...fieldFiltered] : fieldFiltered;
	}, [listFieldsFiltered]);

	/**
	 * @function
	 * @name hasDuplicates
	 * @description A function to verify if an array have duplicate elements
	 *
	 * @param {object} fieldList
	 */
	const hasDuplicates = (fieldList) => {
		const fieldListRenamed = [];
		Object.keys(fieldList).forEach((key) => {
			fieldListRenamed.push(listFieldsUsedId[key].map((field) => `${key}.${field}`));
		});
		const fieldFlattened = [...fieldListRenamed[0], ...fieldListRenamed[1]];

		return new Set(fieldFlattened).size !== fieldFlattened.length;
	};

	/**
	 * @function
	 * @name addRow
	 * @description A function to add a row to the import.
	 *
	 * @author Romaric Barthe
	 */
	const addRow = useCallback(({ target: { header } }) => {
		const newId = uuid();
		const numberRows = rows.map((row) => row?.rowNumber) ?? [];
		const newRowNumber = _.isEmpty(numberRows) ? 1 : Math.max(...numberRows) + 1;
		setRows([...rows, { rowId: newId, import: header, entity: undefined, field: null, fields: [], rowNumber: newRowNumber }]);
	}, [rows]);

	/**
	 * @function
	 * @name loadBase64
	 * @description A function to prepare the file as base64.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {event}	e			The event triggered on file's selection.
	 */
	const loadBase64 = useCallback((e) => {
		if (e.target.files.length === 0) {
			fileRef.current = undefined;
			fileNameRef.current = undefined;

			return;
		}

		const fileToLoad = e.target.files[0];

		const reader = new FileReader();

		reader.onload = (event) => {
			let binary = '';
			const bytes = new Uint8Array(event.target.result);

			for (let i = 0; i < bytes.byteLength; i++) {
				binary += String.fromCharCode(bytes[i]);
			}

			fileRef.current = window.btoa(binary);
			fileNameRef.current = fileToLoad.name;
		};

		reader.readAsArrayBuffer(fileToLoad);
	}, []);

	/**
	 * @function
	 * @name deleteRow
	 * @description A function to delete a row from the import.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {number}	rowId			The id of the row to be deleted.
	 */
	const deleteRow = useCallback((rowId) => {
		const rowIndexToRemove = rows.findIndex((row) => row.rowId === rowId);
		const updatedObject = {
			$splice: [[rowIndexToRemove, 1]],
			$apply: (newRows) => newRows.sort((a, b) => a.rowNumber - b.rowNumber).map((r, i) => update(r, { rowNumber: { $set: i + 1 } })),
		};
		// update other fields
		const rowDeleted = rows.filter((row) => row.rowId === rowId)[0];
		if (rowDeleted.field) {
			const rowsToUpdate = rows.filter((row) => row.rowId !== rowId && row?.entity && row.entity.id === rowDeleted.entity.id);
			rowsToUpdate.forEach((rowToUpdate) => {
				const rowIndexToUpdateNew = rows.findIndex((row) => row.rowId === rowToUpdate.rowId);
				updatedObject[rowIndexToUpdateNew] = {
					fields: { $set: [...fieldsFilteredForRow(rowToUpdate), rowDeleted.field] },
				};
			});
		}
		setRows(update(rows, updatedObject));
	}, [rows, fieldsFilteredForRow]);
	const [shouldUpdate, setShouldUpdate] = useState(false);

	const prefillMatch = useCallback((rowsToFill) => {
		const correspondance = {
			contact: [],
			partner: [],
		};
		const updatedObject = {};
		const fieldsUsedId = {
			contact: [],
			partner: [],
		};
		rowsToFill.forEach((row) => {
			if (labelTemp.contact.map((label) => row.import.toLowerCase().includes(label)).includes(true)) {
				correspondance.contact.push(row);
			} else {
				correspondance.partner.push(row);
			}
		});

		listKeys.forEach((key) => {
			correspondance[key].forEach((row) => {
				const rowIndex = rowsToFill.findIndex((row2) => row2.rowId === row.rowId);
				updatedObject[rowIndex] = {
					entity: databaseEntityOptions.filter((dbentity) => dbentity.id === key)[0],
					fields: fieldsList[key],
				};
				fieldsList[key].forEach((field) => {
					if (!fieldsUsedId[key].includes(field.id)
						&& _.deburr(row.import.toLowerCase().replace(/['’ʼ]/g, ' ')).includes(_.deburr(field.label.toLowerCase().replace(/['’ʼ]/g, ' ')))) {
						updatedObject[rowIndex] = {
							...updatedObject[rowIndex],
							field,
						};
						fieldsUsedId[key].push(field.id);
					}
				});
			});
		});

		return { updatedObject, fieldsUsedId };
	}, [databaseEntityOptions, fieldsList, labelTemp, listKeys]);

	/**
	 * @function
	 * @name handleFile
	 * @description A function to process the file to be imported.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {event}	e				The event trigered on file's selection.
	 * @param {object}	fileTobeLoaded	The file to be loaded.
	 * @param {bool}	isCsv			Wether the file is *.csv type.
	 * @param {bool}	isXlsx			Wether the file is *.xlsx type.
	 */
	const handleFile = useCallback((e, fileTobeLoaded, isCsv, isXlsx) => {
		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;

		reader.onload = (event) => {
			let content = event.target.result;
			if (isXlsx) {
				const workbook = read(content, { type: rABS ? 'binary' : 'array' });
				content = utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
			}

			const headerLines = content.match(/(?:[^\n"]+|"[^"]*")+/g)[0];
			const delimiters = [',', ';', '\t'];
			const delimiter = _.maxBy(delimiters, (d) => _.size(headerLines.split(d)));

			const headers = headerLines.match(new RegExp(`(?:[^${delimiter}"]+|"[^"]*")+`, 'g'))
				.filter((header) => header !== '')
				.map((header) => (
					_.replace(header, /\n/g, ' ')
				));

			// Check if the file is having more than one header row
			if (_.max(_.map(_.groupBy(headers, _.lowerCase), (group) => _.size(group))) > 1) {
				toast.error(t('targeting.target.load.error.headers'));

				return;
			}

			let rowNumber = 1;
			const headerFields = _.map(headers, (header) => ({
				rowId: uuid(),
				import: _.trim(header, '"\''),
				entity: undefined,
				field: undefined,
				rowNumber: rowNumber++,
			}));

			const headerFiltered = headerFields.filter((header) => !rows.map(
				(row) => row.import.toLowerCase().replace(/['’ʼ]/g, ' ')
			).includes(header.import.toLowerCase().replace(/['’ʼ]/g, ' ')));

			const { updatedObject: rowsPreFilled, fieldsUsedId } = prefillMatch(headerFiltered);
			const indexRowsToUpdate = Object.keys(rowsPreFilled);
			indexRowsToUpdate.forEach((index) => {
				const recentObject = headerFiltered[index];
				const rowPrefilled = rowsPreFilled[index];
				headerFiltered[index] = {
					...recentObject,
					...rowPrefilled,
					fields: rowPrefilled.fields.filter((field) => !fieldsUsedId[rowPrefilled.entity.id].includes(field.id)
						|| (rowPrefilled?.field && field.id === rowPrefilled.field.id)),
				};
			});
			setRows((value) => [...value, ...headerFiltered]);

			loadBase64(e);
		};

		if (isCsv) {
			reader.readAsText(fileTobeLoaded);
		} else {
			reader.readAsBinaryString(fileTobeLoaded);
		}
	}, [prefillMatch, loadBase64, t, rows]);

	/**
	 * @function
	 * @name handleSubmit
	 * @description A function to submit the form.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object}	formData	The data of the forms.
	 */
	const handleSubmit = useCallback((formData) => {
		const importForm = parseImportFieldsForBackend(formData, rows, linkedEntity, linkedObject);
		const importData = {
			...importForm,
			file: fileRef.current,
			fileName: fileNameRef.current,
		};
		onSubmit(importData);
	}, [linkedEntity, linkedObject, onSubmit, rows]);

	/**
	 * @function
	 * @name moveRow
	 * @description A function used to move a row in the provided direction.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {number}	rowId			The id of the row to be moved down.
	 * @param {number}	rowNumber		The current number of the row to be moved down.
	 * @param {number}	direction		positive is for down, negative is for up.
	 */
	const moveRow = useCallback((rowId, rowNumber, direction) => {
		if (rows.findIndex((row) => row.rowId === rowId) === undefined) {
			throw new Error('An issue occured with the current lines. Please refresh the page.');
		}

		const rowIndexToMoveInDirection = rows.findIndex((row) => row.rowId === rowId);
		const rowIndexToMoveInOtherDirection = rows.findIndex((row) => row.rowNumber === rowNumber + direction);
		setRows(update(rows, {
			[rowIndexToMoveInDirection]: {
				rowNumber: { $set: rowNumber + direction },
			},
			[rowIndexToMoveInOtherDirection]: {
				rowNumber: { $set: rowNumber },
			},
		}));
	}, [rows]);

	/**
	 * @function
	 * @name onSelectFileButtonClick
	 * @description A function to read the file to be imported.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object}	e			The event on file's selection.
	 */
	const onSelectFileButtonClick = useCallback((e) => {
		const { files } = e.target;

		if (files && files[0]) {
			const fileTobeLoaded = files[0];
			const isCsv = files[0].type === 'text/csv';
			const isXlsx = files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

			if (!isCsv && !isXlsx) {
				toast.error(t('etl.import.error.format'));

				return;
			}

			handleFile(e, fileTobeLoaded, isCsv, isXlsx);
			setIsFileUploaded(true);
		}
	}, [handleFile, t]);

	/**
	 * @function
	 * @name setRowEntity
	 * @description Updates the vat rate of a row inside the local rowVatRate state.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	rowId			The id of the row the entity is to be set.
	 * @param {string}	entity			The id of the entity to be set.
	 */
	const setRowEntity = useCallback((rowId, entity) => {
		const entityToUpdate = databaseEntityOptions.filter((dbentity) => dbentity.id === entity);
		const rowIndexToUpdate = rows.findIndex((row) => row.rowId === rowId);
		const updatedObject = {};
		updatedObject[rowIndexToUpdate] = {
			entity: { $set: entityToUpdate?.length > 0 ? entityToUpdate[0] : '' },
			fields: { $set: listFieldsFiltered[entity] },
		};
		// when modifying an already selected row
		const rowUpdated = rows.filter((row) => row.rowId === rowId)[0];
		if (rowUpdated.field) {
			const rowsToUpdate = rows.filter((row) => row.rowId !== rowId && row?.entity && row.entity.id === rowUpdated.entity.id);
			rowsToUpdate.forEach((rowToUpdate) => {
				const rowIndexToUpdateNew = rows.findIndex((row) => row.rowId === rowToUpdate.rowId);
				updatedObject[rowIndexToUpdateNew] = {
					fields: { $set: [...fieldsFilteredForRow(rowToUpdate), rowUpdated.field] },
				};
			});
		}
		setRows(update(rows, updatedObject));
	}, [databaseEntityOptions, fieldsFilteredForRow, listFieldsFiltered, rows]);

	const setRowField = useCallback((rowId, field) => {
		const rowIndexToUpdate = rows.findIndex((row) => row.rowId === rowId);
		const fieldToUpdate = rows[rowIndexToUpdate].fields.filter((fieldObject) => fieldObject.id === field);
		const updatedObject = {};
		updatedObject[rowIndexToUpdate] = {
			field: { $set: fieldToUpdate?.length > 0 ? fieldToUpdate[0] : '' },
		};

		// filter other fields
		const rowUpdated = rows.filter((row) => row.rowId === rowId)[0];
		const rowUpdatedLastField = rowUpdated?.field ? [rowUpdated.field] : [];
		const rowsToUpdate = rows.filter((row) => row.rowId !== rowId && row?.entity && row.entity.id === rowUpdated.entity.id);
		rowsToUpdate.forEach((rowToUpdate) => {
			const rowIndexToUpdateNew = rows.findIndex((row) => row.rowId === rowToUpdate.rowId);
			updatedObject[rowIndexToUpdateNew] = {
				fields: { $set: [...fieldsFilteredForRow(rowToUpdate), ...rowUpdatedLastField].filter((fieldObject) => fieldObject.id !== field) },
			};
		});

		setRows(update(rows, updatedObject));
	}, [fieldsFilteredForRow, rows]);

	const setRowsImport = useCallback((rowsImported) => {
		const updatedObject = {};
		// the list of fields in the importTemplate (to delete from the selectlist of fields)
		const listFieldsToDeleteIds = createObjectWithEntitiesKeys(
			(key) => rowsImported.filter((row) => row?.entity && row?.field && row.entity.id === key).map((row) => row.field.id)
		);
		// the new select list of fields without field in import template
		const listFieldsFilteredImport = createObjectWithEntitiesKeys(
			(key) => fieldsList[key].filter((field) => !listFieldsToDeleteIds[key].includes(field.id))
		);

		rowsImported.forEach((elt) => {
			const rowToUpdate = rows.find((row) => _.deburr(elt.import.toLowerCase().replace(/['’ʼ]/g, ' ')) === _.deburr(row.import.toLowerCase().replace(/['’ʼ]/g, ' ')));
			if (rowToUpdate) {
				const rowIndexToUpdate = rows.findIndex((row) => row.rowId === rowToUpdate.rowId);
				const entityToUpdate = elt?.entity ? databaseEntityOptions.filter((dbentity) => dbentity.id === elt?.entity.id) : [];
				const fieldsEntity = entityToUpdate?.length > 0 ? getEntityFieldOptions(entityToUpdate[0].id, t) : [];
				const fieldToUpdate = elt?.field ? fieldsEntity.filter((fieldObject) => fieldObject.id === elt?.field.id) : [];
				updatedObject[rowIndexToUpdate] = {
					entity: { $set: entityToUpdate?.length > 0 ? entityToUpdate[0] : undefined },
					fields: { $set: entityToUpdate?.length > 0 ? [...fieldToUpdate, ...listFieldsFilteredImport[entityToUpdate[0].id]] : undefined },
					field: { $set: fieldToUpdate?.length > 0 ? fieldToUpdate[0] : undefined },
				};
			}
		});
		if (Object.keys(updatedObject).length === 0) {
			toast.error(t('etl.import.template.error'));
		}
		setRows(update(rows, updatedObject));
		setShouldUpdate(true);
	}, [createObjectWithEntitiesKeys, databaseEntityOptions, fieldsList, rows, t]);

	const { isShowing: isSaveTemplateModalShowing, toggle: toggleSaveTemplateModal } = useModal();
	const { isShowing: isLoadTemplateModalShowing, toggle: toggleLoadTemplateModal } = useModal();

	const dispatch = useDispatch();

	const onImportTemplate = useCallback((template) => {
		const content = JSON.parse(template.content);
		setRowsImport(content);

		toggleLoadTemplateModal();
	}, [setRowsImport, toggleLoadTemplateModal]);

	useEffect(() => {
		if (shouldUpdate) {
			setShouldUpdate(false);
		}
	}, [shouldUpdate]);

	const onTemplatePreCreationFormSubmit = useCallback(async (formData) => {
		const importObject = rows.map((row) => {
			const returnValue = {
				import: row.import,
				entity: row.entity,
				field: row.field,
			};

			return returnValue;
		});

		const templateData = {
			description: formData?.templateDescription,
			name: formData?.templateName,
			type: formData?.templateType,
			content: JSON.stringify(importObject),
		};

		await dispatch(addTemplate(templateData));

		toggleSaveTemplateModal();
	}, [dispatch, rows, toggleSaveTemplateModal]);

	const { formProps, buttonProps } = useSubmitButton();
	const handleImportChange = useCallback((value, id) => {
		const rowIndexToUpdate = rows.findIndex((row) => row.rowId === id);
		setRows(update(rows, {
			[rowIndexToUpdate]: {
				import: { $set: value },
			},
		}));
	}, [rows]);

	return (
		<DynamicForm onSubmit={handleSubmit} defaultValues={defaultValues} disabled={!bundledUserPermissions.canEditlinkedEntity} {...formProps}>
			<div style={{ display: 'flex' }}>
				<div style={{ width: '100%', flexShrink: '0' }}>
					<FileInput placeholder={t('etl.import.file_select')} onChange={onSelectFileButtonClick} />
				</div>
				<div style={{ margin: 'auto 10px', zIndex: '1' }}>
					<ReactTooltip id="help" type="dark" effect="solid">
						<ReactMarkdown>
							{t('etl.import.tooltip.general_verification.text')}
						</ReactMarkdown>
						<ReactMarkdown>
							{t('etl.import.tooltip.match_import.text')}
						</ReactMarkdown>
						<ReactMarkdown>
							{t('etl.import.tooltip.syntax_verifiction.text')}
						</ReactMarkdown>
					</ReactTooltip>
					<HelpCircle style={{ color: 'var(--clr-primary-500)' }} data-tooltip-id="help" />
				</div>
			</div>

			<table className="import-form">
				<thead>
					<tr>
						<th>{t('etl.import.table.actions')}</th>
						<th>{t('etl.import.table.import')}</th>
						<th><ArrowRight /></th>
						<th colSpan="2">{t('etl.import.table.database')}</th>
					</tr>
				</thead>
				<tbody>
					{sortedRows.map((row) => (
						<tr key={`tr.${row.rowId}`}>
							<td>
								<div className="row-actions">
									<Button
										className="icon-only"
										onClick={() => moveRow(row.rowId, row.rowNumber, 1)}
										disabled={row.rowNumber === rowCount}
									>
										<ArrowDown />
									</Button>
									<Button
										className="icon-only"
										onClick={() => moveRow(row.rowId, row.rowNumber, -1)}
										disabled={row.rowNumber === 1}
									>
										<ArrowUp />
									</Button>
									<Button
										className="icon-only"
										onClick={() => deleteRow(row.rowId)}
									>
										<XCircle />
									</Button>
									<HiddenInput
										key={`row.line.${row.rowId}`}
										name={`row.line.${row.rowId}`}
										value={row.rowNumber}
									/>
								</div>
							</td>
							<td>
								<TextInput
									key={`row.import.${row.rowId}`}
									label=""
									name={`row.import.${row.rowId}`}
									rules={{
										required: Validators.isRequired(t('etl.import.validation_errors.required.header')),
									}}
									onChange={(value) => handleImportChange(value, row.rowId)}
									placeholder="Nom"
								/>
							</td>
							<td><ArrowRight /></td>
							{!shouldUpdate && (
								<>
									<td>
										<Select
											key={`row.entity.${row.rowId}`}
											label=""
											labelKey="label"
											name={`row.entity.${row.rowId}`}
											onChange={(entity) => setRowEntity(row.rowId, entity)}
											options={databaseEntityOptions}
											rules={{
												required: Validators.isRequired(t('etl.import.validation_errors.required.entity')),
											}}
											valueKey="id"
										/>
									</td>
									<td>
										<Select
											key={`row.field.${row.rowId}`}
											label=""
											labelKey="label"
											name={`row.field.${row.rowId}`}
											onChange={(field) => setRowField(row.rowId, field)}
											options={row?.fields ?? []}
											rules={{
												required: Validators.isRequired(t('etl.import.validation_errors.required.field')),
											}}
											valueKey="id"
										/>
									</td>
									<td>
										{row?.field && fieldsObjectWithHelp.map((field) => field.id).includes(row.field?.id) && (
											<HelpCircle
												style={{ color: 'var(--clr-primary-500)' }}
												data-tooltip-id="rowHelp"
												data-tooltip-content={fieldsObjectWithHelp.find((field) => field.id === row.field.id).tooltip}
											/>
										)}
										<ReactTooltip id="rowHelp" place="top" type="dark" effect="solid" />
									</td>
								</>
							)}
						</tr>
					))}
				</tbody>
			</table>

			<Button className="secondary" onClick={addRow}>
				<PlusCircle />
				{t('etl.import.add_row')}
			</Button>
			<div className="div-flex">
				<Button className="primary" type="button" onClick={toggleSaveTemplateModal} disabled={areRowsEmpty}>
					{t('etl.import.template.save')}
				</Button>
				<Modal
					isShowing={isSaveTemplateModalShowing}
					title={t('etl.import.template.save')}
					onClose={toggleSaveTemplateModal}
				>
					<TemplatePreCreationForm onSubmit={onTemplatePreCreationFormSubmit} isTemplateImport />
				</Modal>

				<Button className="primary" type="button" onClick={toggleLoadTemplateModal} disabled={!isFileUploaded}>
					{t('etl.import.template.load')}
				</Button>
				<Modal
					isShowing={isLoadTemplateModalShowing}
					title={t('etl.import.template.load')}
					onClose={toggleLoadTemplateModal}
				>
					<TemplateList onChangeCallback={onImportTemplate} />
				</Modal>
				<Button
					className="primary"
					type="submit"
					style={{ marginLeft: '0px' }}
					disabled={hasDuplicates(fieldsList) || !partnerNameOrIdValidation}
					ref={submitButtonRef}
					{...buttonProps}
				>
					{bundledUserPermissions.canEditlinkedEntity ? t('etl.import.action') : t('etl.import.close')}
				</Button>
				{!partnerNameOrIdValidation
				&& (
				<Tooltip {...submitTooltipProps}>
					{t('etl.import.template.submit_tooltip')}
				</Tooltip>
				)}
			</div>
		</DynamicForm>
	);
};

ImportForm.propTypes = {
	bundledUserPermissions: PropTypes.shape({
		canCreateContact: PropTypes.bool.isRequired,
		canCreatePartner: PropTypes.bool.isRequired,
		canEditlinkedEntity: PropTypes.bool,
	}).isRequired,
	linkedEntity: PropTypes.string,
	linkedObject: PropTypes.shape({
		id: PropTypes.string.isRequired,
	}),
	onSubmit: PropTypes.func.isRequired,
};

ImportForm.defaultProps = {
	linkedEntity: undefined,
	linkedObject: undefined,
};

export default ImportForm;
