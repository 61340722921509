import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addVatRate } from 'redux/actions/vatRates';
import routes from 'routes';

import { VatRateCreationForm } from 'components/vatRates';

/**
 * @name VatRateCreationPage
 * @description A page used to create a new vat rate.
 *
 * @author Romaric Barthe
 */
const VatRateCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addVatRate(formData, routes.accounting.parameters.vatRates.list));
	}, [dispatch]);

	return (
		<VatRateCreationForm onSubmit={handleFormSubmit} />
	);
};

export default VatRateCreationPage;
