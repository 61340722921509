/**
 * @enum {string}
 * @readonly
 *
 * @name expenseColumnHeader
 * @description The column headers for expenses.
 * @author Romaric Barthe
 */
const expenseColumnHeader = Object.freeze({
	ACTIONS: 'actions',
	DATE: 'date',
	DESIGNATION: 'designation',
	ACCOUNT: 'account',
	PROJECT: 'project',
	CURRENCY: 'currency',
	LOCAL_NET_COST: 'localNetCost',
	TOTAL: 'total',
	NET_COST: 'netCost',
	VAT_RATE: 'vatRate',
});

/**
 * @enum {string}
 * @readonly
 *
 * @name ExpenseCreationTypes
 * @description The various creation types allowed for expense creation.
 * @author Romaric Barthe
 */
const ExpenseCreationTypes = Object.freeze({
	EMPTY: 'empty',
	FROM_EXPENSE: 'expense',
	REVERT: 'revert',
});

export {
	expenseColumnHeader,
	ExpenseCreationTypes,
};
