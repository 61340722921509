import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import conf from 'conf';
import { forgotPassword } from 'redux/actions/users';
import routes from 'routes';

import { SvgIcon } from 'components/shared/utils';
import { ForgotPasswordForm } from 'components/users';

/**
 * @name ForgotPasswordPage
 * @description Renders a forgotten password page.
 *
 * @author Yann Hodiesne
 * @author Timothée Simon-Franza
 */
const ForgotPasswordPage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleSubmit = useCallback((formData) => {
		dispatch(forgotPassword(formData, routes.auth.login));
	}, [dispatch]);

	return (
		<>
			<div className="logo-container">
				<SvgIcon path={`${conf.iconsPath}logo_white.svg`} />
			</div>
			<div>
				<h1>{t('forgot_password.title')}</h1>
				<p>{t('forgot_password.subtitle')}</p>
				<ForgotPasswordForm onSubmit={handleSubmit} />
				<Link to={routes.auth.login} className="subtle back-to-login">{t('forgot_password.links.login')}</Link>
			</div>
		</>
	);
};

export default ForgotPasswordPage;
