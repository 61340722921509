import { ActionTypes } from '../actions/accessDefinitions';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_ACCESS_DEFINITION_LIST_REQUEST:
		case ActionTypes.ADD_ACCESS_DEFINITION_REQUEST:
		case ActionTypes.UPDATE_ACCESS_DEFINITION_REQUEST:
		case ActionTypes.REMOVE_ACCESS_DEFINITION_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_ACCESS_DEFINITION_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_ACCESS_DEFINITION_LIST_FAILURE:
		case ActionTypes.FETCH_ACCESS_DEFINITION_FAILURE:
		case ActionTypes.ADD_ACCESS_DEFINITION_FAILURE:
		case ActionTypes.UPDATE_ACCESS_DEFINITION_FAILURE:
		case ActionTypes.REMOVE_ACCESS_DEFINITION_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_ACCESS_DEFINITION_SUCCESS:
		case ActionTypes.UPDATE_ACCESS_DEFINITION_SUCCESS:
		case ActionTypes.REMOVE_ACCESS_DEFINITION_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_ACCESS_DEFINITION_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.accessDefinitions,
				totalCount: payload.accessDefinitions.length,
			};
		case ActionTypes.FETCH_ACCESS_DEFINITION_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.accessDefinition,
			};
		default:
			return state;
	}
};
