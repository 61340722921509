import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * @name SelectionDataDisplayer
 * @description A component responsible for displaying current selection information inside a data table.
 *
 * @author Timothée Simon-Franza
 *
 * @param {integer}	selectedRowsCount	 The selected rows count.
 */
const SelectionDataDisplayer = ({ selectedRowsCount }) => {
	const { t } = useTranslation();

	return (
		<p className="caption data-list-selected-lines-indicator">
			{t('components.table.pagination.selected_count', { count: selectedRowsCount })}
		</p>
	);
};

SelectionDataDisplayer.propTypes = {
	selectedRowsCount: PropTypes.number.isRequired,
};

export default SelectionDataDisplayer;
