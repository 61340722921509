import PropTypes from 'prop-types';

import { StatusTag, StatusTagSkeleton } from 'components/statuses';

import styles from 'styles/components/targets/_targetPartnerStatusEvolutionItem.module.scss';

/**
 * @name TargetPartnerStatusEvolutionItemSkeleton
 * @description The skeleton for the TargetPartnerStatusEvolutionItem component.
 *
 * @author Timothée Simon-Franza
 */
const TargetPartnerStatusEvolutionItemSkeleton = () => (
	<li className={styles['status-evolution-item--skeleton']}>
		<StatusTagSkeleton />
		<time role="presentation" />
	</li>
);

/**
 * @name TargetPartnerStatusEvolutionItem
 * @description A component used to display a targetPartner's status.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} name				The status' name to use as a label.
 * @param {string} [color='#000']	The status' color to use as a dot color.
 * @param {string} date				The status' date.
 */
const TargetPartnerStatusEvolutionItem = ({ name, color, date }) => (
	<li className={styles['status-evolution-item']}>
		<StatusTag label={name} dotColor={color} />
		<time>{`${Intl.DateTimeFormat('fr-FR').format(new Date(date))}`}</time>
	</li>
);

TargetPartnerStatusEvolutionItem.propTypes = {
	name: PropTypes.string.isRequired,
	color: PropTypes.string,
	date: PropTypes.string.isRequired,
};

TargetPartnerStatusEvolutionItem.defaultProps = {
	color: '#000000',
};

export {
	TargetPartnerStatusEvolutionItem,
	TargetPartnerStatusEvolutionItemSkeleton,
};
