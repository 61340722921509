import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchSectorTypeList } from 'redux/actions/sectorTypes';
import { useSectorTypeListSelector, useSectorTypesLoadingSelector } from 'redux/selectors/sectorTypes';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { getRules } from './function';

/**
 * @name SectorCreationForm
 * @description A form used to create a new sector.
 *
 * @author Audrey Clerc
 *
 * @param {func}	onSubmit        The method to trigger upon form submission.
 */
const SectorCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [idTypeSelect, setIdTypeSelect] = useState('');

	useEffect(() => {
		dispatch(fetchSectorTypeList());
	}, [dispatch]);

	const sectorTypes = useSectorTypeListSelector();
	const areSectorTypesLoading = useSectorTypesLoadingSelector();

	const codeFieldValidationRules = useMemo(() => getRules(idTypeSelect, t, sectorTypes), [idTypeSelect, t, sectorTypes]);

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm onSubmit={onSubmit} {...formProps}>
			<Select
				isLoading={areSectorTypesLoading}
				label={t('sector.creation.inputs.type.label')}
				labelKey="name"
				name="type"
				onChange={setIdTypeSelect}
				options={sectorTypes}
				rules={{
					required: Validators.isRequired(t('sector.creation.inputs.type.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<TextInput
				label={t('sector.creation.inputs.name.label')}
				name="name"
				rules={{
					required: Validators.isRequired(t('sector.creation.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextInput
				label={t('sector.creation.inputs.code.label')}
				name="code"
				rules={codeFieldValidationRules}
				type="text"
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('sector.creation.action')}</Button>
		</DynamicForm>
	);
};

SectorCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default SectorCreationForm;
