import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentAccessDefinitionSelector
 * @description A selector to retrieve the current access definition from the access definition redux state
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} id The identifier of the current access definition to wait for
 *
 * @returns {object}
 */
const useCurrentAccessDefinitionSelector = (id) => {
	const accessDefinition = useSelector((state) => (state.accessDefinitions?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (accessDefinition?.id === id ? accessDefinition : null), [accessDefinition, id]);

	return result;
};

/**
 * @function
 * @name useAccessDefinitionsLoadingSelector
 * @description A selector to retrieve the loading state from the AccessDefinition redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useAccessDefinitionsLoadingSelector = () => {
	const loading = useSelector((state) => state.accessDefinitions?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useAccessDefinitionListSelector
 * @description A selector to retrieve the access definitions list from the access definition redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAccessDefinitionListSelector = () => {
	const accessDefinitions = useSelector((state) => (state.accessDefinitions?.items ?? []), shallowEqual);

	return accessDefinitions;
};

/**
 * @function
 * @name useAccessDefinitionTotalCountSelector
 * @description A selector to retrieve the totalCount value from the access definition redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useAccessDefinitionTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.accessDefinitions?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useAccessDefinitionListSelector,
	useAccessDefinitionsLoadingSelector,
	useAccessDefinitionTotalCountSelector,
	useCurrentAccessDefinitionSelector,
};
