import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatContactName } from 'lib/contacts/formatContactData';
import PropTypes from 'prop-types';

import { Select } from 'components/shared/forms/inputs';
import TargetPartnerContactList from 'components/targetings/targetPartners/targetPartnerDetails/targetPartnerContactList/TargetPartnerContactList';

/**
 * @name PartnerLinkedContacts
 * @description A form used to group the identifier fields.
 *
 * @author Romaric Barthe
 */
const PartnerLinkedContacts = ({
	contactCreationModal,
	contacts,
	isLoading,
	linkedContacts,
	setTitles,
}) => {
	const { t } = useTranslation();

	const [resolvedLinkedContacts, setResolvedLinkedContacts] = useState(linkedContacts);
	const setResolvedLinkedContactsId = useCallback((contactIds) => {
		const contactsFiltered = [];
		contactIds.forEach((value) => {
			const currContact = contacts.filter((f) => f.id === value)[0];
			contactsFiltered.push({ id: currContact.id, firstName: currContact.firstName, lastName: currContact.lastName, emails: currContact.emails });
		});
		setResolvedLinkedContacts(contactsFiltered);
	}, [contacts]);

	const possibleLegalRepresentants = useMemo(() => (resolvedLinkedContacts.map((contact) => ({ id: contact.id, name: formatContactName(contact) }))), [resolvedLinkedContacts]);

	const contactsSelectOptions = useMemo(() => (contacts.map((contact) => ({ id: contact.id, name: formatContactName(contact) }))), [contacts]);

	return (
		<>
			<div>
				{resolvedLinkedContacts.length !== 0 ? (
					<TargetPartnerContactList
						contacts={resolvedLinkedContacts}
						isDataFetchPending={isLoading}
						inPartnerVisualisation
					/>
				) : <p>{t('partner.visualisation.no_contact')}</p>}
			</div>
			<Select
				isLoading={isLoading}
				isMulti
				label={t('partner.edition.inputs.linked_contacts.label')}
				labelKey="name"
				name="contacts"
				onChange={setResolvedLinkedContactsId}
				options={contactsSelectOptions}
				valueKey="id"
				{...contactCreationModal}
			/>
			{resolvedLinkedContacts.length > 0 && (
				<Select
					allowNull
					isLoading={isLoading}
					label={t('partner.creation.inputs.legal_representant.label')}
					labelKey="name"
					name="legalRepresentant"
					onChange={setTitles}
					options={possibleLegalRepresentants}
					valueKey="id"
				/>
			)}
		</>
	);
};

PartnerLinkedContacts.propTypes = {
	contactCreationModal: PropTypes.object,
	contacts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		})
	),
	isLoading: PropTypes.bool,
	linkedContacts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		})
	),
	setTitles: PropTypes.func,
};

PartnerLinkedContacts.defaultProps = {
	contactCreationModal: {},
	contacts: [],
	isLoading: true,
	linkedContacts: [],
	setTitles: () => {},
};

export default PartnerLinkedContacts;
