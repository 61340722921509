import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

/**
 * @name RenderRow
 * @description Internal component rendering a row inside the table
 *
 * @author Yann Hodiesne
 *
 * @param {object}	row				 current row to render
 * @param {object}	style			 functional style to apply to the row
 * @param {func}	prepareRow		 ensures the row is managed and ready to be used
 * @param {func}	RowComponent	 component used to render a single row
 * @param {func}	CellComponent	 component used to render a single cell inside a row
 */
const RenderRow = ({ row, style, prepareRow, RowComponent, CellComponent, ...otherProps }) => {
	if (row && prepareRow) {
		prepareRow(row);
	}

	/**
	 * @name rowProps
	 * @description An updated value of the row.getRowProps method call to apply integration fixes.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @returns {object} The updated row props object generated by react-table.
	 */
	const rowProps = useMemo(() => {
		const result = row?.getRowProps();
		if (result?.style) {
			result.style.minWidth = result?.style?.width;
			result.style.width = '100%';
			delete result.style.zIndex;
		}

		return result;
	}, [row]);

	/**
	 * @function
	 * @name cleanCellProps
	 * @description Removes unwanted props from the cell.getCellProps method retrieved from react-table.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @param {*}	cell	The cell we want to clean up props from.
	 *
	 * @returns {object}	The cleaned up version of its props.
	 */
	const cleanCellProps = useCallback((cell) => {
		const initialCellProps = cell.getCellProps();
		const { display, zIndex, ...cellStyle } = initialCellProps.style;

		const result = {
			...initialCellProps,
			style: cellStyle ?? {},
		};

		return result;
	}, []);

	// If the row does not exist or prepareRow is not ready, renders nothing
	// Useful when the user is switching from a lower to a higher rows per page count and we are waiting for data from the API
	if (!row || !prepareRow) {
		return null;
	}

	return (
		<RowComponent {...rowProps} {...otherProps} isSelected={row.isSelected}>
			{row.cells.map((cell) => (
				// react-table is auto-generating a key for the cell component, given by `cell.getCellProps`
				// eslint-disable-next-line react/jsx-key
				<CellComponent className={cell.column.cellClassName ?? ''} {...cleanCellProps(cell)} row={cell.row}>
					{cell.render('Cell')}
				</CellComponent>
			))}
		</RowComponent>
	);
};

RenderRow.propTypes = {
	row: PropTypes.object,
	prepareRow: PropTypes.func,
	style: PropTypes.object,
	RowComponent: PropTypes.elementType.isRequired,
	CellComponent: PropTypes.elementType.isRequired,
};

RenderRow.defaultProps = {
	row: undefined,
	prepareRow: undefined,
	style: {},
};

export default RenderRow;
