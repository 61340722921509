import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Calendar as CalendarIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useForwardedRef, useOnClickOutside } from 'lib/hooks';
import moment from 'moment';
import PropTypes from 'prop-types';

import Calendar from './Calendar';
import MaskedInput from './MaskedInput';

/**
 * @name DatePicker
 * @description A date picker input component
 *
 * @author Florian Fornazaric
 *
 * @param {bool}		[allowNull=false]	Whether the component should be load empty.
 * @param {Date|string} [defaultValue]		The default value of the date picker
 * @param {Date|string}	[value]				The value of the date picker
 * @param {func} 		[onChange]			Method triggered when the value in the input changes
 * @param {func} 		[onFocus]			Method triggered when the component is focused
 */
const DatePicker = forwardRef(({ allowNull, defaultValue, value, onChange, onFocus, ...otherProps }, ref) => {
	const resolvedRef = useForwardedRef(ref);

	const { t } = useTranslation();
	const [date, setDate] = useState(() => {
		if (value) {
			return moment(value);
		}

		return (allowNull && !defaultValue) ? undefined : moment(defaultValue ?? new Date());
	});

	const [isOpen, setIsOpen] = useState(false);
	const [canStealFocus, setCanStealFocus] = useState(true);

	const divWrapper = useRef();

	const dateRegex = useMemo(() => new RegExp(t('components.date_picker.date_regex')), [t]);

	const handleValidation = useCallback((newValue, isValid) => {
		setCanStealFocus(false);

		if (!isValid || newValue === undefined) {
			setDate(undefined);
			// Date set to undefined so date format validation is not triggered when field is empty
			onChange?.({ date: undefined, dateString: newValue });

			return;
		}

		const momentDate = moment(newValue, t('components.date_picker.format'));

		if (momentDate.isValid()) {
			setDate(momentDate.hours(12));
			onChange?.({ date: momentDate.toDate(), dateString: momentDate.format(t('components.date_picker.format')) });
		} else {
			setDate(undefined);
			onChange?.({ date: null, dateString: newValue });
		}
	}, [onChange, t]);

	const lastValue = useRef(value);

	useEffect(() => {
		if (lastValue.current !== value) {
			lastValue.current = value;

			const momentDate = moment(value, t('components.date_picker.format'));

			if (momentDate.isValid()) {
				setDate(momentDate);
			} else {
				setDate(undefined);
			}
		}
	}, [handleValidation, onChange, t, value]);

	const closeCalendar = useCallback(() => {
		if (isOpen) {
			resolvedRef.current.focus();
			setIsOpen(false);
			setCanStealFocus(true);
		}
	}, [isOpen, resolvedRef]);

	const handleCalendarDate = useCallback((newValue) => {
		handleValidation(newValue, true);
		closeCalendar();
	}, [closeCalendar, handleValidation]);

	const onFocusHandler = useCallback((e) => {
		onFocus?.(e);
	}, [onFocus]);

	const onIconClickHandler = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const onBlurHandler = useCallback(() => {
		setCanStealFocus(true);
	}, []);

	useOnClickOutside(closeCalendar, divWrapper);

	return (
		<div ref={divWrapper} className="date-input date-picker-wrapper">
			<div className="icon-input-wrapper trailing-icon">
				<MaskedInput
					{...otherProps}
					ref={resolvedRef}
					placeholder={t('components.date_picker.format')}
					mask={t('components.date_picker.replacement_mask')}
					maskRegex={dateRegex}
					onFocus={onFocusHandler}
					onBlur={onBlurHandler}
					onChange={handleValidation}
					value={(date === undefined) ? undefined : date.format(t('components.date_picker.format'))}
				/>
				<CalendarIcon aria-label="calendar" onClick={onIconClickHandler} />
			</div>
			<Calendar
				isOpen={isOpen}
				date={(date === undefined) ? undefined : date.format('YYYY-MM-DDTHH:mm:ss.sssZ')}
				canStealFocus={canStealFocus}
				closeCallback={closeCalendar}
				selectedCallback={handleCalendarDate}
			/>
		</div>
	);
});

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
	allowNull: PropTypes.bool,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
};

DatePicker.defaultProps = {
	allowNull: false,
	defaultValue: undefined,
	value: undefined,
	onChange: undefined,
	onFocus: undefined,
};

export default memo(DatePicker);
