import session from 'lib/shared/session';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

/** @module lib/http */

/**
 * @constant
 * @name baseUrl
 * @description The base url to append to in order to target and API endpoint.
 * @type {string}
 *
 * Note : Its value is set using environment variables set defined in this .env file and forwarded by docker-compose.
 */
export const baseUrl = process.env.REACT_APP_API_URL ?? '';

export const HANDLED_HTTP_CLIENT_ERROR_STATUS_CODES = [400, 401, 403, 409, 413];

/**
 * @function
 * @name getHeader
 * @description Generates and returns a header bundle from the session's data.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @returns {Headers} The generated header bundle.
 */
export const getHeaders = () => {
	const headers = new Headers();

	headers.append('Content-Type', 'application/json');

	if (session.exists()) {
		headers.append('Authorization', `Bearer ${session.get()}`);
	}

	return headers;
};

/**
  * @function
  * @name objectToQs
  * @description Converts the object in parameter to query string format.
  *
  * @author Timothée Simon-Franza
  * @author Yann Hodiesne
  *
  * @param {object} params	The object to convert.
  */
export const objectToQS = (params) => Object
	.entries(params)
	.map(([key, value]) => {
		if (value !== undefined && value !== null) {
			return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
		}

		return `${encodeURIComponent(key)}`;
	})
	.join('&');

/**
* Automatically deserializes and handles the received response object.
*/

/**
 * @function
 * @name handleResponse
 * @description Automatically deserializes and handles the received response object
 *
 * Note : If the response has a 401 status and a session exists, we redirect the user to the logout page.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {object} response			The response to handle.
 * @param {string} responseType		The type of the response.
 *
 * @return {Promise}
 */
export const handleResponse = (response, responseType = 'json') => {
	if (response.status === 204) {
		return Promise.resolve();
	}

	if (response.status === 401 && session.exists()) {
		history.push(routes.auth.logout);
	}

	if (HANDLED_HTTP_CLIENT_ERROR_STATUS_CODES.includes(response.status) || response.status >= 500) {
		return Promise.reject(response);
	}

	if (responseType === 'attachment') {
		return response.blob().then((blob) => ({
			blob,
			fileName: response.headers.get('content-disposition').split('"')[1],
			fileType: response.headers.get('content-type'),
		}));
	}

	return response.json();
};

/**
 * @function
 * @name handleError
 * @description Automatically handles the received error to return a formatted object.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The error to handle.
 *
 * @returns {Promise.reject}
 */
export const handleError = async (error) => {
	let errors;
	let messageKey;

	try {
		const body = await error.json();
		errors = body?.errors;
		messageKey = body?.message;
	} catch {
		errors = undefined;
		messageKey = undefined;
	}

	const {
		status,
		statusText: message,
	} = error;

	return Promise.reject({ status, message, messageKey, errors });
};

/**
 * @function
 * @name post
 * @description Creates a POST method http call.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @requires {@link getHeaders}
 * @requires {@link handleResponse}
 * @requires {@link handleError}
 *
 * @param {string} url				The url to target.
 * @param {object} body				The body to include.
 * @param {string} [responseType]	The type of the response.
 */
export const post = (url, body, responseType) => fetch(`${baseUrl}${url}`, {
	method: 'POST',
	body: JSON.stringify(body),
	headers: getHeaders(),
}).then((response) => handleResponse(response, responseType)).catch(handleError);

/**
 * @function
 * @name get
 * @description Creates a GET method http call.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @requires {@link objectToQS}
 * @requires {@link getHeaders}
 * @requires {@link handleResponse}
 * @requires {@link handleError}
 *
 * @param {string} url			The url to target.
 * @param {string} responseType	The type of the response.
 */
export const get = (url, responseType) => fetch(`${baseUrl}${url}`, {
	method: 'GET',
	headers: getHeaders(),
}).then((response) => handleResponse(response, responseType)).catch(handleError);

/**
 * @function
 * @name put
 * @description Creates a PUT method http call.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @requires {@link getHeaders}
 * @requires {@link handleResponse}
 * @requires {@link handleError}
 *
 * @param {string} url		The url to target.
 * @param {object} body		The body to include.
 */
export const put = (url, body) => fetch(`${baseUrl}${url}`, {
	method: 'PUT',
	body: JSON.stringify(body),
	headers: getHeaders(),
}).then(handleResponse).catch(handleError);

/**
 * @function
 * @name del
 * @description Creates a DELETE method http call.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @requires {@link getHeaders}
 * @requires {@link handleResponse}
 * @requires {@link handleError}
 *
 * @param {string} url	The url to target.
 */
export const del = (url) => fetch(`${baseUrl}${url}`, {
	method: 'DELETE',
	headers: getHeaders(),
}).then(handleResponse).catch(handleError);

/**
	 * @name isValidHttpUrl
	 * @description Custom actions cell for the PartnerListPage table. (mail, phone, delete and edit partner)
	 *
	 * @author Yann Hosdiene
	 *
	 * @param {string}		websiteUrl						The object containing the data to interact with.
	 */
export const isValidHttpUrl = (websiteUrl) => {
	let url;
	try {
		url = new URL(websiteUrl);
	} catch (_) {
		return false;
	}

	return url.protocol === 'http:' || url.protocol === 'https:';
};
