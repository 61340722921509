import _ from 'lodash';

import { findPositionById } from './internals';

/**
 * @function
 * @name setSelection
 * @description Sets the current selection
 *
 * @author Yann Hodiesne
 * @author Matthieu Schaerlinger
 *
 * @param {object}		state			The current state
 * @param {object}		payload			The payload of the action
 * @param {string[]}	payload.ids		The identifiers of the elements to select
 *
 * @returns {object} The updated state value
 */
const setSelection = (state, { ids }) => {
	if (!state?.selectedElements || !ids) {
		return state;
	}

	if (_.isEqual(state.selectedElements.map((element) => element.id), ids)) {
		return state;
	}

	const { elements } = state;

	return {
		...state,
		selectedElements: ids
			.map((id) => findPositionById(elements, id))
			.filter((position) => position !== null)
			.map((position) => elements[position.rowIndex].children[position.elementIndex]),
	};
};

export default setSelection;
