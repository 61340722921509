import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { QuotationTypes } from 'constants/quotationEnums';
import update from 'immutability-helper';
import { getCurrencyName } from 'lib/currencies/formatCurrencyData';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { addProject } from 'redux/actions/projects/projects';
import { fetchAllForQuotation, fetchQuotation } from 'redux/actions/quotations/quotations';
import { addUser } from 'redux/actions/users';
import { addVatRate } from 'redux/actions/vatRates';
import { useAllForQuotationSelector, useQuotationLoadingSelector } from 'redux/selectors/quotations/quotations';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';
import { v4 as uuid } from 'uuid';

import { ProjectCreationForm } from 'components/projects';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Collapsible } from 'components/shared/layout';
import { sumOneObjectPropertyInArray } from 'components/shared/utils/functions';
import TemplateListSelect from 'components/templates/TemplateListSelect';
import { UserCreationForm } from 'components/users';
import { VatRateCreationForm } from 'components/vatRates';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import QuotationGridArchi from './quotationArchi/QuotationGridArchi';
import QuotationHeader from './quotationCommon/QuotationHeader';
import QuotationTitle from './quotationCommon/QuotationTitle';
import QuotationTotalsTable from './quotationCommon/QuotationTotalsTable';
import QuotationGridStandard from './quotationStandard/QuotationGridStandard';
import {
	getEnrichedStakeholdersInfo,
	getOfferTotals,
	getQuotationTypes,
	quotationColumnsHeaders as getColumnsHeaders,
} from './functions';

/**
 * @name QuotationEditionForm
 * @description A form used to edit an existing quotation's information.
 *
 * @author Roland Margelidon
 * @author Romaric Barthe
 *
 * @param {function}	onSubmit	The method to trigger upon form submission.
 * @param {object}		quotation	The quotation object to update information from.
 */
const QuotationEditionForm = ({ onSubmit, quotation }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForQuotationForm = useAllForQuotationSelector();
	const companies = useMemo(() => allForQuotationForm?.companies ?? [], [allForQuotationForm]);
	const currencies = useMemo(() => allForQuotationForm?.currencies ?? [], [allForQuotationForm]);
	const enabledUsers = useMemo(() => allForQuotationForm?.enabledUsers ?? [], [allForQuotationForm]);
	const lastEditionDate = useMemo(() => allForQuotationForm?.lastEditionDate ?? '', [allForQuotationForm]);
	const numberFormat = useMemo(() => allForQuotationForm?.numberFormat, [allForQuotationForm]);
	const offers = useMemo(() => allForQuotationForm?.unarchivedOffers ?? [], [allForQuotationForm]);
	const partnersWithSector = useMemo(() => allForQuotationForm?.partnersWithSector ?? [], [allForQuotationForm]);
	const projects = useMemo(() => allForQuotationForm?.unarchivedProjects ?? [], [allForQuotationForm]);
	const quotationTypes = useMemo(() => getQuotationTypes(t), [t]);
	const sectors = useMemo(() => allForQuotationForm?.unarchivedSectors ?? [], [allForQuotationForm]);
	const templates = useMemo(() => allForQuotationForm?.unarchivedTemplates, [allForQuotationForm]);
	const vatRates = useMemo(() => allForQuotationForm?.unarchivedVatRates ?? [], [allForQuotationForm]);

	const isLoading = useQuotationLoadingSelector();

	const lastEditionDateFormatted = useMemo(() => new Date(new Date(lastEditionDate).setHours(0, 0, 0, 0)), [lastEditionDate]);

	const [currency, setCurrency] = useState();
	const currencyName = useMemo(() => getCurrencyName(currencies, currency), [currencies, currency]);
	const currencySymbol = useMemo(() => ((currencyName && currencyName !== '') ? t(`currency.${currencyName}`) : ''), [currencyName, t]);

	const [quotationTypeSelected, setQuotationTypeSelected] = useState();
	const columnsHeaders = useMemo(() => getColumnsHeaders(quotationTypeSelected), [quotationTypeSelected]);

	const [rows, setRows] = useState([]);
	const sortedRows = useMemo(() => rows.sort((a, b) => a.line - b.line), [rows]);

	const [stakeholders, setStakeholders] = useState([]);
	const sortedStakeholders = useMemo(() => stakeholders.sort((a, b) => a.column - b.column), [stakeholders]);

	const [duplicateRowId, setDuplicateRowId] = useState();
	const [originalRowData, setOriginalRowData] = useState();
	const [projectAmount, setProjectAmount] = useState(0);
	const [globalVatRate, setGlobalVatRate] = useState(0);
	const globalVatRateValue = vatRates ? vatRates.filter((v) => v.id === globalVatRate)[0]?.rate : 0;

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const [discountTotal, setDiscountTotal] = useState(0);
	const [globalRate, setGlobalRate] = useState(0);
	const [globalRateWithExtra, setGlobalRateWithExtra] = useState(0);
	const [grossTotal, setGrossTotal] = useState(0);
	const [grossTotalWithDiscount, setGrossTotalWithDiscount] = useState(0);
	const [netTotal, setNetTotal] = useState(0);
	const [vatTotal, setVatTotal] = useState(0);

	const [offersTotals, setOffersTotals] = useState([]);
	const [stakeholdersTotals, setStakeholdersTotals] = useState([]);

	// !!Only for Header: Props to pass down to the select input so that it will display ancreation modal when clicking on the "+" icon.
	const projectCreationModal = useFormModal(ProjectCreationForm, t('quotation.edition.inputs.project.creation'), fetchAllForQuotation, addProject);
	const userCreationModal = useFormModal(UserCreationForm, t('expense.edition.inputs.user.creation'), fetchAllForQuotation, addUser);
	const vatRateCreationModal = useFormModal(VatRateCreationForm, t('quotation.edition.inputs.vat_rate.creation'), fetchAllForQuotation, addVatRate);

	/**
	 * @function
	 * @name setTotals
	 * @description A function to refresh Total variables.
	 *
	 * @author Romaric Barthe
	 */
	const setTotals = useCallback(() => {
		const formData = formRef.current?.getFormValues();
		if (quotationTypeSelected === QuotationTypes.ARCHI) {
			setStakeholdersTotals(getEnrichedStakeholdersInfo(formData.stakeholders, formData.rows));
		} else if (quotationTypeSelected === QuotationTypes.STANDARD) {
			setOffersTotals(getOfferTotals(formData?.rows, vatRates));
		}
	}, [quotationTypeSelected, formRef, vatRates]);

	/**
	 * @function
	 * @name updateColumn
	 * @description A function to add/ move/ delete/ duplicate a row.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	columnId		The id of the column triggering the action.
	 * @param {number}	columnNb		The number of the column triggering the action.
	 * @param {number}	direction		The direction if move: positive is for down, negative is for up.
	 * @param {string}	action			The possible action: 'add', 'move', 'duplicate', 'select'.
	 */
	const updateColumn = useCallback((action, columnId = undefined, columnNb = undefined, direction = undefined) => {
		if (action === 'add') {
			const newId = uuid();
			const columns = stakeholders.map((stakeholder) => stakeholder?.column) ?? [];
			const newColumnNumber = _.isEmpty(columns) ? 1 : Math.max(...columns) + 1;
			setStakeholders([...stakeholders, { id: newId, column: newColumnNumber, partnerProjectAmount: projectAmount }]);
		} else {
			if (stakeholders.findIndex((stakeholder) => stakeholder.id === columnId) === undefined) {
				throw new Error('An issue occured with the current lines. Please refresh the page.');
			}
			if (action === 'move') {
				const columnIndexToMoveInDirection = stakeholders.findIndex((stakeholder) => stakeholder.id === columnId);
				const columnIndexToMoveInOtherDirection = stakeholders.findIndex((stakeholder) => stakeholder.column === columnNb + direction);
				setStakeholders(update(stakeholders, {
					[columnIndexToMoveInDirection]: {
						column: { $set: columnNb + direction },
					},
					[columnIndexToMoveInOtherDirection]: {
						column: { $set: columnNb },
					},
				}));
			}
			if (action === 'delete') {
				const columnIndexToRemove = stakeholders.findIndex((stakeholder) => stakeholder.id === columnId);
				setStakeholders(update(stakeholders, {
					$splice: [[columnIndexToRemove, 1]],
					$apply: (newColumns) => newColumns.sort((a, b) => a.column - b.column).map((r, i) => update(r, { column: { $set: i + 1 } })),
				}));
				setStakeholdersTotals(update(stakeholdersTotals, {
					$apply: (newStakeholders) => newStakeholders.filter((stakeholder) => stakeholder.id !== columnId),
				}));
			}
		}
	}, [projectAmount, stakeholders, stakeholdersTotals]);

	/**
	 * @function
	 * @name updateRow
	 * @description A function to add/ move/ delete/ duplicate a row.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	rowId			The id of the row triggering the action.
	 * @param {number}	lineNb			The number of the row triggering the action.
	 * @param {number}	direction		The direction if move: positive is for down, negative is for up.
	 * @param {string}	action			The possible action: 'add', 'move', 'duplicate', 'select'.
	 */
	const updateRow = useCallback((action, rowId = undefined, lineNb = undefined, direction = undefined) => {
		if (action === 'add') {
			const newId = uuid();
			const lines = rows.map((row) => row?.line) ?? [];
			const newRowNumber = _.isEmpty(lines) ? 1 : Math.max(...lines) + 1;
			setRows([...rows, { id: newId, line: newRowNumber }]);
		} else {
			if (rows.findIndex((row) => row.id === rowId) === undefined) {
				throw new Error('An issue occured with the current lines. Please refresh the page.');
			}
			if (action === 'move') {
				const rowIndexToMoveInDirection = rows.findIndex((row) => row.id === rowId);
				const rowIndexToMoveInOtherDirection = rows.findIndex((row) => row.line === lineNb + direction);
				setRows(update(rows, {
					[rowIndexToMoveInDirection]: {
						line: { $set: lineNb + direction },
					},
					[rowIndexToMoveInOtherDirection]: {
						line: { $set: lineNb },
					},
				}));
			}
			if (action === 'delete') {
				const rowIndexToRemove = rows.findIndex((row) => row.id === rowId);
				setRows(update(rows, {
					$splice: [[rowIndexToRemove, 1]],
					$apply: (newRows) => newRows.sort((a, b) => a.line - b.line).map((r, i) => update(r, { line: { $set: i + 1 } })),
				}));
				setStakeholdersTotals(update(stakeholdersTotals, {
					$apply: (newStakeholders) => newStakeholders.map((stakeholder) => {
						const filteredDetails = stakeholder.details.filter((detail) => detail.rowId !== rowId);

						return update((stakeholder), {
							details: { $set: filteredDetails },
						});
					}),
				}));
			}
			if (action === 'duplicate') {
				const newId = uuid();
				setRows(update(rows, {
					$apply: (newRows) => newRows.map((r) => (r.line > lineNb ? update(r, { line: { $set: r.line + 1 } }) : r)),
					$push: [{
						id: newId,
						line: lineNb + 1,
					}],
				}));
				const formData = formRef.current.getFormValues();
				setOriginalRowData(formData.rows[rowId]);
				setDuplicateRowId(newId);
			}
		}
	}, [formRef, rows, stakeholdersTotals]);

	const linkToTemplate = useCallback((templateId, editionDate) => {
		const formattedEditionDate = moment(editionDate).format('YYYYMMDD');
		const formattedLastEditionDate = moment(lastEditionDate).format('YYYYMMDD');
		dispatch(fetchQuotation(quotation?.id));
		history.push(generatePath(routes.settings.templates.templatePdfExportFromApp, {
			id: templateId,
			entityId: quotation?.id,
			editionDate: formattedEditionDate,
			lastEditionDate: formattedLastEditionDate,
		}));
	}, [lastEditionDate, dispatch, quotation?.id]);

	// Fetch data
	useEffect(() => {
		dispatch(fetchAllForQuotation());
	}, [dispatch]);

	// Set default values
	const defaultValues = useMemo(() => {
		const flatStakeholders = quotation.stakeholders ? Object.fromEntries(update(quotation.stakeholders, {
			$apply: (newStakeholders) => Object.entries(newStakeholders).map((stakeholder) => update(stakeholder, {
				$apply: (newStakeholder) => update(newStakeholder, {
					1: {
						partner: { $set: newStakeholder[1].partner?.id },
						sector: { $set: newStakeholder[1].sector?.id },
					},
				}),
			})),
		})) : undefined;

		const flatRows = quotation.rows ? Object.fromEntries(update(quotation.rows, {
			$apply: (newRows) => Object.entries(newRows).map((row) => update(row, {
				$apply: (newRow) => update(newRow, {
					1: {
						offer: { $set: newRow[1].offer?.id },
						vatRate: { $set: newRow[1].vatRate?.id },
					},
				}),
			})),
		})) : undefined;

		return ({
			...quotation,
			currency: quotation.currency?.id ?? null,
			discountTotal: quotation.discountTotal ?? 0,
			grossTotalWithDiscount: quotation.grossTotalWithDiscount ?? quotation.grossTotal,
			project: quotation.project?.id ?? null,
			rows: flatRows,
			seller: quotation.seller?.id ?? null,
			stakeholders: flatStakeholders,
			structure: quotation.structure?.id ?? null,
			vatRate: quotationTypeSelected === QuotationTypes.ARCHI ? quotation.vatRate?.id : null,
		});
	}, [quotation, quotationTypeSelected]);

	// Set state's variables
	useEffect(() => {
		setCurrency(defaultValues?.currency);
		setDiscountTotal(defaultValues?.discountTotal);
		setGlobalRate(defaultValues?.globalRate);
		setGlobalRateWithExtra(defaultValues?.globalRateWithExtra);
		setGlobalVatRate(defaultValues?.vatRate);
		setGrossTotal(defaultValues?.grossTotal);
		setGrossTotalWithDiscount(defaultValues?.grossTotalWithDiscount);
		setNetTotal(defaultValues?.netTotal);
		setProjectAmount(defaultValues?.projectAmount);
		setVatTotal(defaultValues?.vatTotal);
		setRows(defaultValues?.rows ? Object.values(defaultValues.rows) : []);
		setStakeholders(defaultValues?.stakeholders ? Object.values(defaultValues?.stakeholders) : []);
		setStakeholdersTotals(getEnrichedStakeholdersInfo(defaultValues?.stakeholders, defaultValues?.rows));
		setOffersTotals(getOfferTotals(defaultValues?.rows, vatRates));
	}, [defaultValues, vatRates]);
	useEffect(() => {
		setQuotationTypeSelected(quotation?.quotationType);
	}, [quotation]);

	// Refresh totals for archi quotation
	useEffect(() => {
		if (quotationTypeSelected === QuotationTypes.ARCHI) {
			const columnTotals = stakeholdersTotals.map((stakeholderTotals) => {
				const stakeholderDetails = stakeholderTotals.details && stakeholderTotals.details.filter(
					(f) => f.columnId === stakeholderTotals.id && f.extra && f.rate !== null && f.rate !== 0
				);
				const extra = stakeholderDetails && stakeholderDetails.length >= 0 ? sumOneObjectPropertyInArray(stakeholderDetails, 'rate') : 0;
				const grossTotalWithoutExtraOnTheFly = stakeholderTotals.partnerProjectAmount * stakeholderTotals.partnerRatio;
				const grossTotalOnTheFly = grossTotalWithoutExtraOnTheFly * (1 + extra / 100);
				const discountTotalOnTheFly = grossTotalOnTheFly * stakeholderTotals.partnerDiscount;
				const grossTotalWithDiscountOnTheFly = grossTotalOnTheFly - discountTotalOnTheFly;

				return ({
					id: stakeholderTotals.id,
					gtwe: grossTotalWithoutExtraOnTheFly ?? 0,
					gt: grossTotalOnTheFly ?? 0,
					dt: discountTotalOnTheFly ?? 0,
					gtd: grossTotalWithDiscountOnTheFly ?? 0,
				});
			});
			setGrossTotal(sumOneObjectPropertyInArray(columnTotals, 'gt'));
			setDiscountTotal(sumOneObjectPropertyInArray(columnTotals, 'dt'));
			setGrossTotalWithDiscount(sumOneObjectPropertyInArray(columnTotals, 'gtd'));
			setVatTotal(sumOneObjectPropertyInArray(columnTotals, 'gtd') * (globalVatRateValue / 100));
			setNetTotal(sumOneObjectPropertyInArray(columnTotals, 'gtd') * (1 + (globalVatRateValue / 100)));
			setGlobalRate(projectAmount !== 0 ? (sumOneObjectPropertyInArray(columnTotals, 'gtwe') * 100) / projectAmount : 0);
			setGlobalRateWithExtra(projectAmount ? (sumOneObjectPropertyInArray(columnTotals, 'gt') * 100) / projectAmount : 0);
		} else if (quotationTypeSelected === QuotationTypes.STANDARD) {
			setDiscountTotal(sumOneObjectPropertyInArray(offersTotals, 'dt'));
			setGrossTotal(sumOneObjectPropertyInArray(offersTotals, 'gt'));
			setGrossTotalWithDiscount(sumOneObjectPropertyInArray(offersTotals, 'gtd'));
			setNetTotal(sumOneObjectPropertyInArray(offersTotals, 'nt'));
			setVatTotal(sumOneObjectPropertyInArray(offersTotals, 'vt'));
		}
	}, [globalVatRateValue, offersTotals, projectAmount, quotationTypeSelected, stakeholdersTotals]);

	const canEditQuotation = useAccessRight(AccessRights.sales.quotations.enhancedRights.CREATE_QUOTATION) && !quotation?.archived;

	return (
		<DynamicForm
			ref={formRef}
			cleanFormData
			defaultValues={defaultValues}
			disabled={!canEditQuotation}
			onSubmit={onSubmit}
			{...formProps}
		>

			<QuotationTitle quotation={quotation} numberFormat={numberFormat} />

			<Collapsible titleKey="quotation.edition.collapsed.general_informations" collapsed>
				<QuotationHeader
					currencies={currencies}
					currencyName={currencyName}
					isLoading={isLoading}
					projectCreationModal={projectCreationModal}
					projects={projects}
					// quotationParent={quotationParent}
					quotationTypes={quotationTypes}
					quotationTypeSelected={quotationTypeSelected}
					structures={companies}
					userCreationModal={userCreationModal}
					users={enabledUsers}
					vatRateCreationModal={vatRateCreationModal}
					vatRates={vatRates}
					setCurrency={setCurrency}
					setGlobalVatRate={setGlobalVatRate}
					setProjectAmount={setProjectAmount}
					setQuotationTypeSelected={setQuotationTypeSelected}
				/>
			</Collapsible>

			<QuotationTotalsTable
				currencySymbol={currencySymbol}
				discountTotal={discountTotal}
				globalRate={globalRate}
				globalRateWithExtra={globalRateWithExtra}
				grossTotal={grossTotal}
				grossTotalWithDiscount={grossTotalWithDiscount}
				netTotal={netTotal}
				quotationTypeSelected={quotationTypeSelected}
				vatTotal={vatTotal}
			/>

			<Collapsible titleKey="quotation.edition.collapsed.grid">
				{quotationTypeSelected === 'standard' && (
					<QuotationGridStandard
						columnsHeaders={columnsHeaders}
						currencyName={currencyName}
						currencySymbol={currencySymbol}
						defaultValues={defaultValues}
						isLoading={isLoading}
						offers={offers}
						rows={sortedRows}
						vatRates={vatRates}
						// functions
						updateRow={updateRow}
						setTotals={setTotals}
						// duplicate logic
						duplicateRowId={duplicateRowId}
						originalRowData={originalRowData}
					/>
				)}

				{quotationTypeSelected === 'archi' && (
					<QuotationGridArchi
						currencyName={currencyName}
						currencySymbol={currencySymbol}
						defaultValues={defaultValues}
						isLoading={isLoading}
						offers={offers}
						partners={partnersWithSector}
						projectAmount={projectAmount}
						rows={sortedRows}
						sectors={sectors}
						stakeholders={sortedStakeholders}
						stakeholdersTotals={stakeholdersTotals}
						// functions
						updateColumn={updateColumn}
						updateRow={updateRow}
						setTotals={setTotals}
						// duplicate logic
						duplicateRowId={duplicateRowId}
						originalRowData={originalRowData}
					/>
				)}
				{canEditQuotation && (
					<Button className="secondary" type="button" onClick={() => updateRow('add')}>
						<PlusCircle />
						{t('quotation.edition.grid.add_row')}
					</Button>
				)}
			</Collapsible>

			<div className="edition-actions">
				{templates && (
					<TemplateListSelect onChangeCallback={linkToTemplate} lastEditionDate={lastEditionDateFormatted} templates={templates} />
				)}
				<Button className="primary" type="submit" {...buttonProps}>
					{canEditQuotation ? t('quotation.edition.action') : t('quotation.edition.close')}
				</Button>
			</div>
		</DynamicForm>
	);
};

QuotationEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	quotation: PropTypes.shape({
		archived: PropTypes.bool,
		currency: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		description: PropTypes.string,
		discountTotal: PropTypes.number,
		grossTotal: PropTypes.number,
		grossTotalWithDiscount: PropTypes.number,
		quotationType: PropTypes.string.isRequired,
		project: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		projectAmount: PropTypes.number,
		rows: PropTypes.object,
		seller: PropTypes.shape({
			id: PropTypes.string.isRequired,
			username: PropTypes.string.isRequired,
		}),
		stakeholders: PropTypes.object,
		structure: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		validityDate: PropTypes.string,
		vatRate: PropTypes.shape({
			id: PropTypes.string.isRequired,
			rate: PropTypes.number.isRequired,
		}),
		id: PropTypes.string,
	}).isRequired,
};

export default QuotationEditionForm;
