import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useInvoicesListLoadingSelector
 * @description A selector to retrieve the loading state from the invoicesList redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {boolean}
 */
const useInvoicesListLoadingSelector = () => {
	const loading = useSelector((state) => state.invoicesList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useInvoicesForListSelector
 * @description A selector to retrieve the invoicesList list from the invoice redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {Array}
 */
const useInvoicesForListSelector = () => {
	const invoicesList = useSelector((state) => (state.invoicesList?.items ?? []), shallowEqual);

	return invoicesList;
};

/**
 * @function
 * @name useInvoicesListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the invoicesList redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {number|undefined}
 */
const useInvoicesListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.invoicesList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useInvoicesForListSelector,
	useInvoicesListLoadingSelector,
	useInvoicesListTotalCountSelector,
};
