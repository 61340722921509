/* eslint-disable no-console */
import { useCallback } from 'react';

export default (reducer, enabled) => useCallback((state, action) => {
	const result = reducer(state, action);

	if (enabled) {
		console.groupCollapsed('Executed action ', action.type, action.payload);
		console.info('Action : ', action);
		console.info('Previous state : ', state);
		console.info('New state : ', result);
		console.groupEnd();
	}

	return result;
}, [enabled, reducer]);
