import { useCallback } from 'react';
import { Check, Edit2, Eye, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { removeHistory } from 'redux/actions/histories/histories';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the HistoryListPage table. (delete, edit and set reminder date for history).
 *
 * @author Audrey Clerc
 *
 * @param {object}		row							The object containing the data to interact with.
 * @param {string}		row.original.id				The id of the history.
 * @param {string}		row.original.channel		The channel of the history.
 * @param {string}		row.original.date			The date of the history.
 * @param {string}		row.original.remindedDate	The reminded date of the history.
 * @param {string}		row.original.reminderDate	The reminder date of the history.
 * @param {function}	fetchData					The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { id, channel, date, remindedDate, reminderDate } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditHistory = useAccessRight(AccessRights.commonSettings.history.enhancedRights.CREATE_HISTORY);
	const canDeleteHistory = useAccessRight(AccessRights.commonSettings.history.enhancedRights.DELETE_HISTORY);

	const hasNoRemindedDate = remindedDate === null;
	const hasReminderDate = reminderDate !== null;

	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editOrViewButtonRef, editOrViewTooltipProps] = useTooltip();
	const [checkButtonRef, checkTooltipProps] = useTooltip();
	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Audrey Clerc
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();
		await dispatch(removeHistory({ id, date }));
		fetchData();
	}, [toggleDeleteModal, dispatch, id, date, fetchData]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Audrey Clerc
	 */
	const onEditPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.sales.crm.historyEdition, { id }));
	}, [id]);

	/**
	 * @function
	 * @name onReminderPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection check button.
	 *
	 * @author Audrey Clerc
	 */
	const onReminderPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.sales.crm.historyReminder, { id }));
	}, [id]);

	return (
		<>
			<Button className="icon-only" onClick={onEditPageRedirectionButtonClick} ref={editOrViewButtonRef}>
				{canEditHistory && channel !== 'workflow' ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editOrViewTooltipProps}>
				{canEditHistory && channel !== 'workflow' ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			{canEditHistory && hasReminderDate && hasNoRemindedDate && (
				<>
					<Button className="icon-only" onClick={onReminderPageRedirectionButtonClick} ref={checkButtonRef}>
						<Check />
					</Button>
					<Tooltip {...checkTooltipProps}>
						{t('actions.check')}
					</Tooltip>
				</>
			)}
			{canDeleteHistory && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('history.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('history.deletion.confirmation_modal.confirm')}
						cancelText={t('history.deletion.confirmation_modal.cancel')}
						titleText={t('history.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
			channel: PropTypes.string.isRequired,
			date: PropTypes.string.isRequired,
			remindedDate: PropTypes.string,
			reminderDate: PropTypes.string,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
