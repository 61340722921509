import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchRegistrationTypeList } from 'redux/actions/registrationTypes';
import { useRegistrationTypeListSelector, useRegistrationTypesLoadingSelector } from 'redux/selectors/registrationTypes';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name RegistrationCreationForm
 * @description A form used to create a new registration.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit        The method to trigger upon form submission.
 */
const RegistrationCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchRegistrationTypeList());
	}, [dispatch]);

	const registrationTypes = useRegistrationTypeListSelector();
	const areRegistrationTypesLoading = useRegistrationTypesLoadingSelector();

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm onSubmit={onSubmit} {...formProps}>
			<Select
				isLoading={areRegistrationTypesLoading}
				label={t('registration.creation.inputs.type.label')}
				labelKey="name"
				name="type"
				options={registrationTypes}
				rules={{
					required: Validators.isRequired(t('registration.creation.inputs.type.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<TextInput
				label={t('registration.creation.inputs.name.label')}
				name="name"
				rules={{
					required: Validators.isRequired(t('registration.creation.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('registration.creation.action')}</Button>
		</DynamicForm>
	);
};

RegistrationCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default RegistrationCreationForm;
