import { InvoiceMethods } from 'constants/invoiceEnums';
import { QuotationTypes } from 'constants/quotationEnums';

export default Object.freeze({
	EXPENSE_TOTALS: 'ExpenseTotals',
	EXPENSE_ROWS: 'ExpenseRows',
	QUOTATION_COLUMNS: 'QuotationColumns',
	QUOTATION_TOTALS: 'QuotationTotals',
	QUOTATION_ARCHITECT: QuotationTypes.ARCHI,
	QUOTATION_STANDARD: QuotationTypes.STANDARD,
	INVOICE_TOTALS: 'InvoiceTotals',
	INVOICE_UNITARY: InvoiceMethods.UNITARY,
	INVOICE_ADVANCEMENT: InvoiceMethods.ADVANCEMENT,
	INVOICE_ADVANCEMENT_STATUS: 'InvoiceAdvancementStatus',
	DEFAULT: 'Default',
});
