import PropTypes from 'prop-types';

/**
 * @name ContactsCell
 * @description Custom cell for the historyListPage table displaying a contact's history.
 *
 * @author Romaric Barthe
 * @author Audrey Clerc
 */
const ContactsCell = ({ row: { original: { contacts } } }) => (
	<ul className="tag-list">
		{contacts.map(({ id, firstName, lastName }) => (
			<li key={id}>
				<span className="tag">
					{`${firstName} ${lastName}`}
				</span>
			</li>
		))}
	</ul>
);

ContactsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			contacts: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.string.isRequired,
				firstName: PropTypes.string.isRequired,
				lastName: PropTypes.string.isRequired,
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default ContactsCell;
