/**
 * @enum {string}
 * @readonly
 *
 * @name historyChannels
 * @description The allowed channel of history used over the applications.
 * @author Romaric Barthe
 */
const historyChannels = {
	HISTORY_CHANNEL_EMAIL: 'email',
	HISTORY_CHANNEL_FORM: 'form',
	HISTORY_CHANNEL_MEETING: 'meeting',
	HISTORY_CHANNEL_PHONE: 'phone',
	HISTORY_CHANNEL_STATUS: 'status',
	HISTORY_CHANNEL_WORKFLOW: 'workflow',
};

/**
 * @enum {string}
 * @readonly
 *
 * @name historyChannelsForSelect
 * @description The allowed channel of history used over the applications in a select.
 * @author Romaric Barthe
 */
const historyChannelsForSelect = {
	HISTORY_CHANNEL_INPUT_EMAIL: historyChannels.HISTORY_CHANNEL_EMAIL,
	HISTORY_CHANNEL_INPUT_FORM: historyChannels.HISTORY_CHANNEL_FORM,
	HISTORY_CHANNEL_INPUT_MEETING: historyChannels.HISTORY_CHANNEL_MEETING,
	HISTORY_CHANNEL_INPUT_PHONE: historyChannels.HISTORY_CHANNEL_PHONE,
	HISTORY_CHANNEL_INPUT_STATUS: historyChannels.HISTORY_CHANNEL_STATUS,
};

/**
 * @enum {string}
 * @readonly
 *
 * @name historyRestrictions
 * @description The allowed types of history used over the applications.
 * @author Romaric Barthe
 */
const historyRestrictions = {
	HISTORY_RESTRICTION_PRIVATE: 'private',
	HISTORY_RESTRICTION_PUBLIC: 'public',
	HISTORY_RESTRICTION_SHARED: 'shared',
};

/**
 * @enum {string}
 * @readonly
 *
 * @name historyTypes
 * @description The allowed types of history used over the applications.
 * @author Romaric Barthe
 */
const historyTypes = {
	HISTORY_TYPE_AUTO: 'automatic',
	HISTORY_TYPE_STANDARD: 'standard',
};

export {
	historyChannels,
	historyChannelsForSelect,
	historyRestrictions,
	historyTypes,
};
