/**
 * @enum {string}
 * @readonly
 *
 * @name QuotationColumnHeader
 * @description The column headers for unitary and advancement quotations.
 * @author Romaric Barthe
 */
const QuotationColumnHeader = Object.freeze({
	standard: Object.freeze({
		ACTIONS: 'actions',
		OFFER: 'offer',
		DESIGNATION: 'designation',
		QUANTITY: 'quantity',
		UNITARY_COST: 'unitaryCost',
		VAT_RATE: 'vatRate',
		DISCOUNT: 'discount',
		TOTAL: 'total',
	}),
	archi: Object.freeze({
		ACTIONS: 'actions',
		OFFER: 'offer',
		DESIGNATION: 'designation',
	}),
});

/**
 * @enum {string}
 * @readonly
 *
 * @name QuotationCreationTypes
 * @description The various creation types allowed for quotation creation.
 * @author Romaric Barthe
 */
const QuotationCreationTypes = Object.freeze({
	EMPTY: 'empty',
	INITIALISATION: 'initialisation',
	VERSION: 'version',
});

/**
 * @enum {string}
 * @readonly
 *
 * @name QuotationTypes
 * @description The various allowed quotation types.
 * @author Romaric Barthe
 */
const QuotationTypes = Object.freeze({
	ARCHI: 'archi',
	STANDARD: 'standard',
});

export {
	QuotationColumnHeader,
	QuotationCreationTypes,
	QuotationTypes,
};
