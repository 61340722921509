import { baseUrl, getHeaders, handleError, post } from 'lib/shared/http';

/**
 * @function
 * @name tryLogin
 * @description Tries to authenticate the current user, with given login and password.
 *
 * @author Yann Hodiesne
 *
 * @param {string} username
 * @param {string} password
 *
 * @returns {Promise}
 */
export const tryLogin = (username, password) => {
	const params = {
		username,
		password,
	};

	return post('/api/login', params);
};

/**
 * @function
 * @name checkTocken
 * @description Checks the current authentication token validity by issuing an empty request to the server
 *
 * @author Yann Hodiesne
 *
 * @returns {Promise}
 */
export const checkToken = () => fetch(`${baseUrl}/api/whoami`, { method: 'POST', headers: getHeaders() })
	.then((res) => {
		if (res.status !== 200) {
			return Promise.reject(res);
		}

		return res.json();
	}).catch(handleError);

/**
  * @function
  * @name forgottenPassword
  * @description Tries to send a "forgotten-password" email to the user.
  *
  * @author Romaric Barthe
  * @author Yann Hodiesne
  *
  * @param {string} data 			The data to associated to the user.
  *
  * @returns {Promise}
  */
export const forgottenPassword = (data) => post('/api/forgot-password', data);

/**
 * @function
 * @name definePassword
 * @description Sends a password to the API to set to the user identified by the provided token.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} token			The token used to identify the user to set the password for.
 * @param {string} password			The password value to set.
 * @param {string} checkPassword	A password value verification.
 *
 * @returns {Promise}
 */
export const definePassword = (token, password, checkPassword) => {
	const params = {
		token,
		password,
		'check-password': checkPassword,
	};

	return post('/api/define-password', params);
};
