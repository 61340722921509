/**
 * @function
 * @name formatContactName
 * @description Concatenate a contact's firstname and lastname fields' values to provide a full name.
 *
 * @author Romaric Barthe
 * @author Timothée Simon-Franza
 *
 * @param {object} contact   The serialized contact.
 *
 * @returns {string}
 */
export const formatContactName = (contact) => {
	if (!contact) {
		return undefined;
	}
	const { firstName = '', lastName = '' } = contact;

	return `${firstName} ${lastName}`;
};

/**
 * @function
 * @name formatContactNameWithTitle
 * @description Concatenate a contact's firstname and lastname fields' values with it's title, if set, to provide a full name.
 *
 * @author Romaric Barthe
 *
 * @param {object} contact   The contact to format the title and name from.
 *
 * @returns {string}
 */
export const formatContactNameWithTitle = (contact) => (contact?.title ? `${contact.title.name} ${formatContactName(contact)}` : formatContactName(contact));

/**
 * @function
 * @name formatContactPosition
 * @description get the position if one is given.
 *
 * @author Romaric Barthe
 *
 * @param {object} contact   The contact to format the position's from.
 *
 * @returns {string}
 */
export const formatContactPosition = (contact) => (contact?.position ? `${contact.position.name}` : '');
