import { ActionTypes } from '../../actions/invoices/invoices';

const initialState = {
	currentItem: null,
	dashboard: [],
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_INVOICE_LIST_REQUEST:
		case ActionTypes.FETCH_ALL_FOR_INVOICE_REQUEST:
		case ActionTypes.ADD_INVOICE_REQUEST:
		case ActionTypes.EXPORT_INVOICE_REQUEST:
		case ActionTypes.UPDATE_INVOICE_REQUEST:
		case ActionTypes.REMOVE_INVOICE_REQUEST:
		case ActionTypes.FETCH_INVOICES_STATS_REQUEST:
		case ActionTypes.FETCH_INVOICES_DASHBOARD_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_INVOICE_REQUEST:
		case ActionTypes.EDIT_INVOICE_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_INVOICE_LIST_FAILURE:
		case ActionTypes.FETCH_ALL_FOR_INVOICE_FAILURE:
		case ActionTypes.FETCH_INVOICE_FAILURE:
		case ActionTypes.FETCH_GENERATED_PDF_INVOICE_FAILURE:
		case ActionTypes.ADD_INVOICE_FAILURE:
		case ActionTypes.EDIT_INVOICE_FAILURE:
		case ActionTypes.EXPORT_INVOICE_FAILURE:
		case ActionTypes.UPDATE_INVOICE_FAILURE:
		case ActionTypes.REMOVE_INVOICE_FAILURE:
		case ActionTypes.FETCH_INVOICES_STATS_FAILURE:
		case ActionTypes.FETCH_INVOICES_DASHBOARD_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_INVOICE_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.invoices],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_ALL_FOR_INVOICE_SUCCESS:
			return {
				...state,
				isLoading: false,
				allForForm: payload.allForForm,
			};
		case ActionTypes.FETCH_INVOICE_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.invoice,
			};
		case ActionTypes.EDIT_INVOICE_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.enhancedInvoice,
			};
		case ActionTypes.ADD_INVOICE_SUCCESS:
		case ActionTypes.EXPORT_INVOICE_SUCCESS:
		case ActionTypes.UPDATE_INVOICE_SUCCESS:
		case ActionTypes.REMOVE_INVOICE_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_INVOICES_STATS_SUCCESS:
			return {
				...state,
				stats: payload.invoicesStats,
				isLoading: false,
			};
		case ActionTypes.FETCH_INVOICES_DASHBOARD_SUCCESS:
			return {
				...state,
				isLoading: false,
				dashboard: payload.dashboard,
			};
		case ActionTypes.FETCH_GENERATED_PDF_INVOICE_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.generatedPdf,
			};
		default:
			return state;
	}
};
