import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchStatus, updateStatus } from 'redux/actions/statuses';
import { useCurrentStatusSelector } from 'redux/selectors/statuses';
import routes from 'routes';

import { StatusEditionForm } from 'components/statuses';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name StatusEditionPage
 * @description A page used to edit an existing status.
 *
 * @author Romaric Barthe
 */
const StatusEditionPage = () => {
	const { id: statusId } = useParams();
	const dispatch = useDispatch();

	const status = useCurrentStatusSelector(statusId);

	const canEditStatus = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING) && !status?.archived;

	useEffect(() => {
		dispatch(fetchStatus(statusId));
	}, [dispatch, statusId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditStatus) {
			dispatch(updateStatus(formData, statusId, routes.settings.statuses.list));
		} else {
			redirectOnSuccess(routes.settings.statuses.list);
		}
	}, [canEditStatus, dispatch, statusId]);

	return (
		status
			? <StatusEditionForm status={status} onSubmit={handleFormSubmit} />
			: <p>Loading status information</p>
	);
};

export default StatusEditionPage;
