import * as QuotationsApi from 'api/quotationsApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the QuotationsSelect redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a list of quotations
	FETCH_QUOTATION_SELECT_LIST_REQUEST: '@QUOTATIONS_SELECT/FETCH_LIST_REQUEST',
	FETCH_QUOTATION_SELECT_LIST_SUCCESS: '@QUOTATIONS_SELECT/FETCH_LIST_SUCCESS',
	FETCH_QUOTATION_SELECT_LIST_FAILURE: '@QUOTATIONS_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Quotation list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchQuotationsSelectListRequest
 * @description Action triggered anytime a quotationsSelect list fetching call is made to the API.
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
const fetchQuotationsSelectListRequest = () => ({ type: ActionTypes.FETCH_QUOTATION_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchQuotationsSelectListSuccess
 * @description Action triggered as a result to a successful quotationsSelect list fetching API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object} quotationsSelect		The list of retrieved quotations.
 * @param {number} totalCount			The total amount of quotations available in the database for the current user.
 *
 * @returns {object}
 */
const fetchQuotationsSelectListSuccess = ({ quotationsSelect, totalCount }) => ({
	type: ActionTypes.FETCH_QUOTATION_SELECT_LIST_SUCCESS,
	payload: { quotationsSelect, totalCount },
});

/**
 * @function
 * @name fetchQuotationsSelectListFailure
 * @description Action triggered as a result to a failed quotationsSelect list fetching API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchQuotationsSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_QUOTATION_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchQuotationsSelectList
 * @description Method used to fetch the quotationsSelect list.
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchQuotationsSelectList = (params) => (dispatch) => {
	dispatch(fetchQuotationsSelectListRequest());

	const newFilters = {
		...params,
		infoLevel: 'forSelect',
	};

	return QuotationsApi.fetchQuotations(newFilters)
		.then(({ quotationsSelect, totalCount }) => dispatch(fetchQuotationsSelectListSuccess({ quotationsSelect, totalCount })))
		.catch((error) => dispatch(fetchQuotationsSelectListFailure(error)));
};
