import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { formatContactName } from 'lib/contacts/formatContactData';
import PropTypes from 'prop-types';
import { addAccessDefinition, fetchAccessDefinitionList } from 'redux/actions/accessDefinitions';
import { addContact } from 'redux/actions/contacts/contacts';
import { fetchContactsSelectWithMailsUserList } from 'redux/actions/contacts/contactsSelectWithMailsUser';
import { fetchAllForUser } from 'redux/actions/users';
import { useAllForUserSelector, useUsersLoadingSelector } from 'redux/selectors/users';

import { AccessDefinitionCreationForm } from 'components/accessDefinitions';
import { ContactCreationForm } from 'components/contacts';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Checkbox, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { locales } from './function';

/**
 * @name UserCreationForm
 * @description A form used to create a new user.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit       	The method to trigger upon form submission.
 */
const UserCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const allForUserForm = useAllForUserSelector();
	const accessDefinitions = useMemo(() => allForUserForm?.accessDefinitions ?? [], [allForUserForm]);
	const companyRoot = useMemo(() => allForUserForm?.root, [allForUserForm]);
	const contacts = useMemo(() => allForUserForm?.contacts ?? [], [allForUserForm]);

	const isLoading = useUsersLoadingSelector();

	const contactWithEmailOnly = useMemo(() => (contacts
		.filter((contact) => contact.emails && contact.emails.length > 0)
	), [contacts]);
	const contactsSelectOptions = useMemo(() => (contactWithEmailOnly.filter((contact) => !contact.user)
		.map((contact) => ({ id: contact.id, name: formatContactName(contact) }))
	), [contactWithEmailOnly]);

	const [selectedContact, setSelectedContact] = useState();
	const emailContact = useMemo(() => (contactWithEmailOnly && contactWithEmailOnly.length > 0 && selectedContact
		? contactWithEmailOnly.filter((contact) => contact.id === selectedContact)[0].emails[0]
		: undefined), [contactWithEmailOnly, selectedContact]);

	const { formProps, buttonProps } = useSubmitButton();

	// Props to pass down to the select input so that it will display an access definition creation modal when clicking on the "+" icon.
	const accessDefinitionCreationModal = useFormModal(
		AccessDefinitionCreationForm, t('pages.human_resources.users.access_definition.creation'), fetchAccessDefinitionList, addAccessDefinition
	);
	const contactCreationModal = useFormModal(ContactCreationForm, t('pages.human_resources.users.contact.creation'), fetchContactsSelectWithMailsUserList, addContact);

	const getLocales = useMemo(() => locales(t), [t]);

	const administratorRef = useRef();
	const companyRef = useRef();
	const standardRef = useRef();

	const [isAdministrator, setIsAdministrator] = useState(false);
	const [isStandard, setIsStandard] = useState(true);

	/**
	 * @function
	 * @name onChangeContact
	 * @description A callback method used to set the selected Contact.
	 *
	 * @author Romaric Barthe
	 */
	const onChangeContact = useCallback((contact) => {
		setSelectedContact(contact);
	}, []);

	/**
	 * @function
	 * @name onChangeAdmin
	 * @description A callback method used to switch between administrator and standard.
	 *
	 * @author Romaric Barthe
	 */
	const onChangeAdmin = useCallback((checked) => {
		setIsAdministrator(checked);
		setIsStandard(!checked);
	}, []);

	/**
	 * @function
	 * @name onChangeAdmin
	 * @description A callback method used to switch between administrator and standard.
	 *
	 * @author Romaric Barthe
	 */
	const onChangeStandard = useCallback((checked) => {
		setIsAdministrator(!checked);
		setIsStandard(checked);
	}, []);

	useEffect(() => {
		administratorRef.current.setValue(isAdministrator);
		standardRef.current.setValue(isStandard);
	}, [isAdministrator, isStandard]);

	useEffect(() => {
		dispatch(fetchAllForUser());
	}, [dispatch]);

	useEffect(() => { companyRef.current.setValue(`${companyRoot}-`); }, [companyRoot]);

	return (
		<DynamicForm onSubmit={onSubmit} {...formProps}>
			<Checkbox
				label={t('user.creation.inputs.enabled.label')}
				name="enabled"
				labelFirst={false}
			/>

			<Select
				isLoading={isLoading}
				label=" "
				labelKey="name"
				name="contact"
				onChange={onChangeContact}
				options={contactsSelectOptions}
				rules={{
					required: Validators.isRequired(t('user.creation.inputs.contact.validation_errors.required')),
				}}
				valueKey="id"
				{...contactCreationModal}
			/>
			{emailContact && (
				<div className="user-email">
					<div>{t('user.creation.inputs.email.label')}</div>
					<div className="email">{emailContact}</div>
				</div>
			)}
			<div className="user-username">
				<TextInput
					ref={companyRef}
					label={t('user.creation.inputs.root.label')}
					name="root"
					type="text"
					rules={{
						required: Validators.isRequired(t('user.creation.inputs.username.validation_errors.required')),
					}}
					disabled
				/>
				<TextInput
					label={t('user.creation.inputs.username.label')}
					name="username"
					rules={{
						minLength: Validators.hasMinLength(6, t('user.creation.inputs.username.validation_errors.length')),
						required: Validators.isRequired(t('user.creation.inputs.username.validation_errors.required')),
					}}
					type="text"
				/>
			</div>
			<Select
				label={t('user.creation.inputs.locale.label')}
				labelKey="label"
				name="locale"
				options={getLocales}
				rules={{
					required: Validators.isRequired(t('user.creation.inputs.locale.validation_errors.required')),
				}}
				valueKey="value"
			/>

			<Checkbox
				ref={administratorRef}
				label={t('access_definition.creation.inputs.administrator.label')}
				name="administrator"
				labelFirst={false}
				onChange={onChangeAdmin}
			/>

			<Checkbox
				ref={standardRef}
				label={t('access_definition.creation.inputs.standard.label')}
				name="standard"
				labelFirst={false}
				onChange={onChangeStandard}
			/>

			{isStandard && (
				<Select
					isLoading={isLoading}
					isMulti
					label={t('user.creation.inputs.access_definition.label')}
					labelKey="name"
					name="accessDefinitions"
					options={accessDefinitions}
					valueKey="id"
					{...accessDefinitionCreationModal}
				/>
			)}

			<Button className="primary" type="submit" {...buttonProps}>{t('user.creation.action')}</Button>
		</DynamicForm>
	);
};

UserCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default UserCreationForm;
