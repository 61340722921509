import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTemplate } from 'redux/actions/templates';
import { useCurrentTemplateSelector } from 'redux/selectors/templates';

/**
 * @name TemplateSpreadSheetViewPage
 */
const TemplateSpreadSheetViewPage = () => {
	const { id: templateId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const template = useCurrentTemplateSelector(templateId);

	useEffect(() => {
		dispatch(fetchTemplate(templateId));
	}, [dispatch, templateId]);

	const content = useMemo(() => (template?.content ? JSON.parse(template.content) : null), [template?.content]);

	return (
		template
			? (
				<div className="partnervisualisation">
					<table className="table" style={{ width: '100%' }}>
						<tbody>
							<tr>
								<th scope="row" className="right">{t('etl.import.table.import')}</th>
								<th scope="row" className="right">{t('etl.import.table.database')}</th>
							</tr>
							{content.map((row) => (
								<tr key={row.import} className="nolastchild">
									<td className="padded">
										<span>{row.import}</span>
									</td>
									<td className="padded">
										<span>{row.entity.label}</span>
									</td>
									<td className="padded">
										<span>{row.field.label}</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)
			: <p>{t('template.pdf.editor.loading')}</p>
	);
};

export default TemplateSpreadSheetViewPage;
