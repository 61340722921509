import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import { AutomaticDateValues, DateModes } from '../../constants/Dates';
import EditorContext from '../../EditorContext';
import { formatDate, isDateValid } from '../../functions/dateUtils';

const staticDateCss = {
	padding: '0px',
	minWidth: '2em',
	minHeight: '1.5em',
};

/**
 * @name StaticDate
 * @description A static date PDF element
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object}		style		The style of the component
 * @param {object}		element		The element to edit
 * @param {boolean}		editing		True if the element is being edited
 * @param {boolean}		resizable	True if the element is being resized
 */
const StaticDate = (({ style, element, editing, resizable }) => {
	const { t } = useTranslation();
	const { isExporting } = useContext(EditorContext);
	const valid = isDateValid(element.content);

	let value;

	if (element.dateMode === DateModes.AUTOMATIC) {
		if (!Object.keys(AutomaticDateValues).includes(element.content)) {
			value = t('template.pdf.editor.elements.date.errors.date');
		} else if (isExporting) {
			value = formatDate(new Date().toISOString(), element.dateFormat, t);
		} else {
			value = t(`template.pdf.capabilities.date_auto_value.options.${element.content?.toLowerCase()}`);
		}
	} else if (element.linkable && !valid) { // Contains entity placeholder (Entity -> Property)
		value = element.content;
	} else {
		value = formatDate(valid ? element.content : undefined, element.dateFormat, t);
	}

	return (
		<textarea
			className={`seamless${editing || resizable ? ' editing' : ''}`}
			style={{ ...staticDateCss, ...style }}
			readOnly={(isExporting || !editing || element.linkable) ? true : undefined}
			value={value}
		/>
	);
});

StaticDate.propTypes = {
	style: PropTypes.object,
	element: PropTypes.object,
	editing: PropTypes.bool,
	resizable: PropTypes.bool,
};

StaticDate.defaultProps = {
	style: undefined,
	element: undefined,
	editing: false,
	resizable: false,
};

/**
 * @name PdfElement
 * @description A PDF element for the date
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object}	style 				The style of the component
 * @param {object}	element 			The element to render
 */
const PdfElement = ({ style, element, ...otherProps }) => {
	const { t } = useTranslation();
	let dateStr = element.content;

	if (element.dateMode === DateModes.AUTOMATIC) {
		if (element.content === AutomaticDateValues.EXPORT_DATE) {
			dateStr = new Date().toISOString();
		} else {
			return (
				<Text {...otherProps} style={[staticDateCss, style]}>
					{t('template.pdf.editor.elements.date.errors.date')}
				</Text>
			);
		}
	}

	const valid = isDateValid(dateStr);

	return (
		<Text {...otherProps} style={[staticDateCss, style]}>
			{formatDate(valid ? dateStr : undefined, element.dateFormat, t)}
		</Text>
	);
};

PdfElement.propTypes = {
	element: PropTypes.object.isRequired,
	style: PropTypes.object,
};

PdfElement.defaultProps = {
	style: undefined,
};

PdfElement.displayName = 'StaticDate';

StaticDate.PdfElement = PdfElement;

export default StaticDate;
