import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { addTemplate } from 'redux/actions/templates';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { TemplatePreCreationForm } from 'components/templates';

/**
 * @name TemplatePreCreationPage
 * @description A page used to create a new template.
 *
 * @author Romaric Barthe
 *
 */
const TemplatePreCreationPage = () => {
	const dispatch = useDispatch();
	const user = useCurrentConnectedUserSelector();

	const templateListRoute = useMemo(() => (user.superadmin ? routes.superAdmin.templateList : routes.settings.templates.templateList), [user.superadmin]);
	const templateEditionRoute = useMemo(() => (user.superAdmin ? routes.superAdmin.templatePdfEdition : routes.settings.templates.templatePdfEdition), [user.superAdmin]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleFormSubmit = useCallback(async (formData) => {
		const templateData = {
			description: formData?.templateDescription,
			name: formData?.templateName,
			type: formData?.templateType,
			content: '{}',
		};

		const newTemplate = await dispatch(addTemplate(templateData));

		if (newTemplate) {
			if (formData?.templateType === 'pdf') {
				history.push(generatePath(templateEditionRoute, { id: newTemplate.id }));
			} else {
				history.push(generatePath(templateListRoute));
			}
		}
	}, [dispatch, templateEditionRoute, templateListRoute]);

	return (
		<TemplatePreCreationForm onSubmit={handleFormSubmit} isTemplateImport={false} />
	);
};

export default TemplatePreCreationPage;
