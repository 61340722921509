import { primary } from 'theme/colors';

const rowIconWrapper = {
	display: 'flex',
	alignItems: 'center',
	marginRight: '.3em',
};

const icon = {
	display: 'flex',
	alignItems: 'center',
	color: ((props) => (props.checked ? primary.default : '#a0a0a0')),
	cursor: ((props) => (props.disabled ? 'inherit' : 'pointer')),
	marginRight: '.5em',

	'* > svg': {
		width: '.5em',
		height: '.5em',
	},

	'&:hover span': {
		visibility: 'visible',
	},
};

const legend = {
	display: 'flex',
	justifyContent: 'space-evenly',
	alignItems: 'center',
	marginTop: '1em',
};

const legendItem = {
	display: 'flex',
	alignItems: 'center',

	'* svg': {
		width: '.1em',
		height: '.1em',
	},
};

export {
	icon,
	legend,
	legendItem,
	rowIconWrapper,
};
