import { useTranslation } from 'react-i18next';

import { TextInput } from 'components/shared/forms/inputs';
import { floatPattern, numberPattern, rangePattern } from 'components/shared/forms/inputs/constants/validationPatterns';
import Validators from 'components/shared/forms/validators';

/**
 * @name PartnerKPI
 * @description A form used to group the KPI fields.
 *
 * @author Romaric Barthe
 */
const PartnerKPI = () => {
	const { t } = useTranslation();

	return (
		<>
			<TextInput
				label={t('partner.edition.inputs.workforce.label')}
				name="workforce"
				placeholder={t('partner.edition.inputs.workforce.placeholder')}
				rules={{
					pattern: Validators.matchPattern(numberPattern, t('partner.edition.inputs.workforce.validation_errors.required')),
				}}
			/>
			<TextInput
				label={t('partner.edition.inputs.workforce_slice.label')}
				name="workforceSlice"
				placeholder={t('partner.edition.inputs.workforce_slice.placeholder')}
				rules={{
					pattern: Validators.matchPattern(rangePattern, t('partner.edition.inputs.workforce_slice.validation_errors.required')),
				}}
			/>
			<TextInput
				label={t('partner.edition.inputs.turnover.label')}
				name="turnover"
				placeholder={t('partner.edition.inputs.turnover.placeholder')}
				rules={{
					pattern: Validators.matchPattern(floatPattern, t('partner.edition.inputs.turnover.validation_errors.required')),
				}}
			/>
			<TextInput
				label={t('partner.edition.inputs.turnover_slice.label')}
				name="turnoverSlice"
				placeholder={t('partner.edition.inputs.turnover_slice.placeholder')}
				rules={{
					pattern: Validators.matchPattern(rangePattern, t('partner.edition.inputs.turnover_slice.validation_errors.required')),
				}}
			/>
		</>
	);
};

export default PartnerKPI;
