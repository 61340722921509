import update from 'immutability-helper';

import { createElement, createRow } from './internals';

/**
 * @function
 * @name insertRow
 * @description Inserts a new row at the given index
 *
 * @author Yann Hodiesne
 *
 * @param {object}	state				The current state
 * @param {number}	payload.index		The index where to insert the new row
 * @param {array}	payload.elements	The elements to insert inside the new row
 *
 * @returns {object} The updated state value
 */
const insertRow = (state, { index, elements: elementsToInsert }) => {
	const { elements } = state;

	const newElements = update(elements, {
		$splice: [
			[index, 0, createRow(...(elementsToInsert.map((element) => createElement(element))))],
		],
	});

	const result = {
		...state,
		elements: newElements,
		isDirty: true,
	};

	return result;
};

export default insertRow;
