import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchStatuses
 * @description Retrieves a list of statuses from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchStatuses = (params) => post('/api/statuses', params);

/**
 * @function
 * @name fetchStatusById
 * @description Retrieves an existing status from the API, using the statusId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} statusId	The id of the status we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchStatusById = (statusId) => get(`/api/statuses/${statusId}`);

/**
 * @function
 * @name createStatus
 * @description Creates a new status into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusData	The data to create the status from.
 *
 * @returns {Promise}
 */
export const createStatus = (statusData) => post('/api/statuses/create', statusData);

/**
 * @function
 * @name updateStatus
 * @description Updates an existing status from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusData 	The data to update the status with.
 * @param {string} statusId   	The id to identify the status with.
 *
 * @returns {Promise}
 */
export const updateStatus = (statusData, statusId) => put(`/api/statuses/${statusId}`, statusData);

/**
 * @function
 * @name deleteStatus
 * @description Removes an existing status from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} statusId	The id of the status we want to remove.
 *
 * @returns {Promise}
 */
export const deleteStatus = (statusId) => del(`/api/statuses/${statusId}`);
