import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { DatePicker } from 'components/shared/inputs';
import { isDateValid } from 'components/templates/pdf/editor/functions/dateUtils';

/**
 * @name DateCapability
 * @description A date capability, displayed inside the editor's toolbox
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string}		[value]		The current value of the capability (current date by default)
 * @param {string}		label		The label translation key to display
 * @param {function}	setValue	A callback updating the value associated to this capability
 * @param {boolean}		readOnly	Whether the capability can be edited by the user
 */
const DateCapability = ({ value, label, setValue, readOnly, ...otherProps }) => {
	const { t } = useTranslation();
	const inputId = uuid(); // used to tie the label to the input.

	const valid = isDateValid(value);

	useEffect(() => {
		// if value is null or not a valid date, and the date can be changed
		// (i.e the date element doesn't have a linked entity), set value to current date
		if ((!value || !valid) && !readOnly) {
			setValue(new Date().toISOString());
		}
	}, [readOnly, setValue, valid, value]);

	return (
		<div className={`input-wrapper${readOnly ? ' disabled' : ''}`}>
			<label htmlFor={inputId}>
				{t(label)}
			</label>
			<DatePicker
				id={inputId}
				defaultValue={valid ? new Date(value) : new Date()}
				onChange={({ date }) => setValue((date ?? new Date()).toISOString())}
				disabled={readOnly}
				{...otherProps}
			/>
		</div>
	);
};

DateCapability.propTypes = {
	label: PropTypes.string.isRequired,
	readOnly: PropTypes.bool,
	setValue: PropTypes.func.isRequired,
	value: PropTypes.string,
};

DateCapability.defaultProps = {
	readOnly: false,
	value: new Date().toISOString(),
};

export default DateCapability;
