import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
	container: {
		display: 'flex',
	},
	label: {
		fontSize: '12px',
	},
});

/**
 * @name ShowLabel
 * @description A component used to display the label of an element, in case its SHOW_LABEL capability is toggled.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*}		children		The element's rendered component.
 * @param {object}	[SHOW_LABEL]	The element's SHOW_LABEL capability's data.
 */
const ShowLabel = ({ children, SHOW_LABEL }) => {
	if (!SHOW_LABEL?.showLabel || !SHOW_LABEL.labelContent) {
		return children;
	}
	const flexDirection = ['ABOVE', 'BELOW'].includes(SHOW_LABEL.labelPosition) ? 'column' : 'row';

	return (
		<View style={[styles.container, { flexDirection }]}>
			{['ABOVE', 'LEFT'].includes(SHOW_LABEL.labelPosition) && (
				<>
					<Text className="label" style={styles.label}>{SHOW_LABEL.labelContent}</Text>
					{children}
				</>
			)}
			{['BELOW', 'RIGHT'].includes(SHOW_LABEL.labelPosition) && (
				<>
					{children}
					<Text className="label" style={styles.label}>{SHOW_LABEL.labelContent}</Text>
				</>
			)}
		</View>
	);
};

ShowLabel.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	SHOW_LABEL: PropTypes.shape({
		labelPosition: PropTypes.string.isRequired,
		labelContent: PropTypes.string.isRequired,
		showLabel: PropTypes.bool.isRequired,
	}),
};

ShowLabel.defaultProps = {
	SHOW_LABEL: undefined,
};

export default ShowLabel;
