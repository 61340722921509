import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { addTemplate, fetchTemplate } from 'redux/actions/templates';
import { useCurrentTemplateSelector } from 'redux/selectors/templates';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

import { EditorViewer } from 'components/templates/pdf/editor';

/**
 * @name TemplatePdfViewPage
 */
const TemplatePdfViewPage = () => {
	const { id: templateId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const template = useCurrentTemplateSelector(templateId);

	useEffect(() => {
		dispatch(fetchTemplate(templateId));
	}, [dispatch, templateId]);

	const content = useMemo(() => (template?.content ? JSON.parse(template.content) : null), [template?.content]);

	const currentUser = useCurrentConnectedUserSelector();
	const isCompanyTemplate = useMemo(
		() => template?.company?.id === currentUser?.company?.id, [currentUser?.company, template?.company]
	);
	const isSuperadmin = useMemo(() => currentUser.superadmin, [currentUser.superadmin]);

	/**
	 * @function
	 * @name saveAsCallback
	 * @description Formats the data in an object that can be understood by the API so it can be saved as a new template.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const saveAsCallback = useCallback((formData) => {
		const templateData = {
			...formData,
			content: JSON.stringify(formData.content),
		};

		return dispatch(addTemplate(templateData, templateId));
	}, [dispatch, templateId]);

	return (
		template
			? (
				<EditorViewer
					initialData={content}
					isCompanyTemplate={isCompanyTemplate}
					isSuperadmin={isSuperadmin}
					templateMetadata={{ description: template.description, name: template.name, type: template.type, id: template.id }}
					saveAsCallback={saveAsCallback}
				/>
			)
			: <p>{t('template.pdf.editor.loading')}</p>
	);
};

export default TemplatePdfViewPage;
