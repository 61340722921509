/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useRef } from 'react';
import { ArrowDownRight, ArrowUpRight } from 'react-feather';
import PropTypes from 'prop-types';

/**
 * @name ResizeHandle
 * @description A component containing a custom resize handle that can be placed on top or bottom of a component
 *
 * @author Florian Fornazaric
 *
 * @param {('top':'bottom')}  	position			The position of the resize handle
 * @param {function} 			resizeHandler 		The function called when the handle is moved, using the delta between the old and new position
 */
const ResizeHandle = (({ position, resizeHandler }) => {
	const y = useRef(0);
	const dy = useRef(0);

	const startResize = useCallback((event) => {
		y.current = event.screenY;
	}, []);

	/**
	 * @name onResize
	 * @description A callback function that is called when the resize handle is dragged,
	 * allowing us to get the difference in pixels between the current and the previous position of the handle
	 *
	 * @author Florian Fornazaric
	 *
	 * @param {object} event 	The event object
	 */
	const resize = useCallback((event) => {
		dy.current = event.screenY - y.current;
		y.current = event.screenY;

		if (dy.current !== 0) {
			resizeHandler(dy.current);
		}
	}, [resizeHandler]);

	const handleMouseDown = useCallback((event) => {
		startResize(event);
		document.body.style.cursor = 'ns-resize';
		document.body.addEventListener('mousemove', resize);
		document.body.addEventListener('mouseup', () => {
			document.body.style.cursor = 'auto';
			document.body.removeEventListener('mousemove', resize);
		});
	}, [resize, startResize]);

	return (
		<div
			className={`resize-handle ${position === 'bottom' ? 'bottom-handle' : 'top-handle'}`}
			onMouseDown={handleMouseDown}
		>
			{position === 'bottom' ? <ArrowDownRight /> : <ArrowUpRight /> }
		</div>
	);
});

ResizeHandle.propTypes = {
	resizeHandler: PropTypes.func.isRequired,
	position: PropTypes.oneOf(['top', 'bottom']).isRequired,
};

export default ResizeHandle;
