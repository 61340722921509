import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchContact, updateContact } from 'redux/actions/contacts/contacts';
import { useCurrentContactSelector } from 'redux/selectors/contacts/contacts';
import routes from 'routes';

import { ContactEditionForm } from 'components/contacts';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name ContactEditionPage
 * @description A page used to edit an existing contact.
 *
 * @author Yann Hodiesne
 * @author Timothée Simon-Franza
 */
const ContactEditionPage = () => {
	const { id: contactId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const contact = useCurrentContactSelector(contactId);

	const canEditContact = useAccessRight(AccessRights.commonModules.contacts.enhancedRights.CREATE_CONTACT);

	useEffect(() => {
		dispatch(fetchContact(contactId));
	}, [dispatch, contactId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Sends the form data to the server if the user is allowed to edit the contact, otherwise redirects to the contact list.
	 *
	 * @author Yann Hodiesne
	 * @author Matthieu Schaerlinger
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback(async (formData) => {
		if (canEditContact) {
			dispatch(updateContact(formData, contactId));
		} else {
			redirectOnSuccess(routes.commonModules.contacts.list);
		}
	}, [canEditContact, contactId, dispatch]);

	return (
		contact
			? <ContactEditionForm contact={contact} onSubmit={handleFormSubmit} />
			: <p>{t('contact.edition.loading')}</p>
	);
};

export default ContactEditionPage;
