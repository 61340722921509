import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentRelationTypeSelector
 * @description A selector to retrieve the current relation type from the relationType redux state
 *
 * @author Audrey Clerc
 *
 * @param {string} id The identifier of the current relation type to wait for
 *
 * @returns {object}
 */
const useCurrentRelationTypeSelector = (id) => {
	const relationType = useSelector((state) => (state.relationTypes?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (relationType?.id === id ? relationType : null), [id, relationType]);

	return result;
};

/**
 * @function
 * @name useRelationTypesLoadingSelector
 * @description A selector to retrieve the loading state from the relationType redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {boolean}
 */
const useRelationTypesLoadingSelector = () => {
	const loading = useSelector((state) => state.relationTypes?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useRelationTypeListSelector
 * @description A selector to retrieve the relationType list from the relationType redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {Array}
 */
const useRelationTypeListSelector = () => {
	const relationTypes = useSelector((state) => state.relationTypes?.items ?? [], shallowEqual);

	return relationTypes;
};

/**
 * @function
 * @name useRelationTypeTotalCountSelector
 * @description A selector to retrieve the totalCount value from the relationType redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {number|undefined}
 */
const useRelationTypeTotalCountSelector = () => {
	const totalCount = useSelector((state) => state.relationTypes?.totalCount ?? undefined, shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useRelationTypeActiveListSelector
 * @description A selector to retrieve all non-archived relationTypes from the relationType redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {Array}
 */
const useRelationTypeActiveListSelector = () => {
	const relationTypes = useRelationTypeListSelector();

	const result = useMemo(() => relationTypes.filter(({ archived }) => !archived), [relationTypes]);

	return result;
};

export {
	useCurrentRelationTypeSelector,
	useRelationTypeActiveListSelector,
	useRelationTypeListSelector,
	useRelationTypesLoadingSelector,
	useRelationTypeTotalCountSelector,
};
