import { forwardRef, useCallback, useId, useImperativeHandle, useRef } from 'react';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

import useDynamicFormInput from '../useDynamicFormInput';

import { Error, Label } from './DynamicFormInput';

/**
 * @name Checkbox
 * @description A checkbox component to be used inside a dynamic form.
 *
 * @author Yann Hodiesne
 *
 * @param {string}	label				The string to display as a label for the wrapped checkbox.
 * @param {string}	name				The checkbox's name.
 * @param {func}	[onChange]			The method to trigger whenever the input's value changes.
 * @param {bool}	[disabled=false]	Whether the input is disabled.
 * @param {boolean}	[readOnly=false]	Whether the input is read only.
 */
const Checkbox = forwardRef(({ name, label, labelFirst, rules, className, ...props }, ref) => {
	const resolvedRef = useForwardedRef(ref);
	const inputRef = useRef();

	const getValue = useCallback(() => inputRef.current.checked, []);

	const {
		defaultValue,
		isDisabled,
		isInvalid,
		isReadOnly,
		validationError,
		enableValidationOnChange,
		onChangeHandler,
	} = useDynamicFormInput(name, getValue, rules, props);

	useImperativeHandle(resolvedRef, () => ({
		setValue: (newValue) => {
			inputRef.current.checked = newValue;
			onChangeHandler();
		},
	}));

	const id = useId();

	return (
		<div className={`input-wrapper checkbox-wrapper${isDisabled ? ' disabled' : ''}${className ?? ''}`}>
			{labelFirst && (
				<Label disabled={isDisabled} inputId={id} isInvalid={isInvalid}>
					{label}
				</Label>
			)}
			<input
				ref={inputRef}
				id={id}
				disabled={isDisabled || isReadOnly}
				readOnly={isReadOnly}
				type="checkbox"
				aria-invalid={isInvalid}
				defaultChecked={defaultValue}
				onChange={onChangeHandler}
				onBlur={enableValidationOnChange}
			/>
			{!labelFirst && (
				<Label disabled={isDisabled} inputId={id} isInvalid={isInvalid}>
					{label}
				</Label>
			)}
			{isInvalid && <Error>{validationError ?? ''}</Error>}
		</div>
	);
});

Checkbox.propTypes = {
	label: PropTypes.string.isRequired,
	labelFirst: PropTypes.bool,
	name: PropTypes.string.isRequired,
	rules: PropTypes.object,
	className: PropTypes.string,
};

Checkbox.defaultProps = {
	labelFirst: true,
	rules: {},
	className: undefined,
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
