/* eslint-disable no-nested-ternary */
import { QuotationColumnHeader, QuotationCreationTypes, QuotationTypes } from 'constants/quotationEnums';

/**
 * @function
 * @name getQuotationCreationTypes
 * @description return the array of quotation creation type options.
 *
 * @author Romaric Barthe
 *
 * @param {function}	t	translator method.
 *
 * @returns array of options for quotation type.
 */
export const getQuotationCreationTypes = () => {
	const creationTypes = [];
	Object.entries(QuotationCreationTypes).forEach(([, value]) => {
		creationTypes.push({ id: value, label: `quotation.creation.creation_type.${value}` });
	});

	return creationTypes;
};

/**
 * @function
 * @name getQuotationTypes
 * @description return the array of quotation type options.
 *
 * @author Roland Margelidon
 *
 * @param {function}	t	translator method.
 *
 * @returns array of options for quotation type.
 */
export const getQuotationTypes = (t) => [
	{ id: QuotationTypes.STANDARD, label: t('quotation.creation.inputs.quotation_type.standard') },
	{ id: QuotationTypes.ARCHI, label: t('quotation.creation.inputs.quotation_type.archi') },
];

/**
 * @function
 * @name quotationColumnsHeaders
 * @description return the array of quotation headers for the grid, depending of quotationType.
 *
 * @author Romaric Barthe
 *
 * @param {object}	selectedQuotationType	the quotation typed currently selected.
 *
 * @returns array of headers for the grid.
 */
export const quotationColumnsHeaders = (selectedQuotationType) => {
	const columnsHeaders = [];
	if (!selectedQuotationType) {
		return columnsHeaders;
	}

	Object.entries(QuotationColumnHeader[selectedQuotationType]).forEach(([, value]) => {
		columnsHeaders.push({ label: value, show: true });
	});

	return columnsHeaders;
};

/**
 * @function
 * @name getLinesForVisualisation
 * @description Parses the backend-retrieved data into an object to be used by the form.
 *
 * @author Romaric Barthe
 *
 * @param {object}	quotation	the quotation we are visualizing.
 *
 * @returns {object} transformed initial values.
 */
export const getLinesForVisualisation = (quotation) => {
	const rows = [];
	Object.values(quotation.rows).sort((a, b) => a.line - b.line).forEach((row) => {
		const tempRows = {};
		tempRows.rowId = row.id;
		tempRows.isLabel = row.isLabel;
		tempRows.isExtra = row.extra;
		tempRows[QuotationColumnHeader[quotation.quotationType].OFFER] = row.offer?.name ?? undefined;
		tempRows[QuotationColumnHeader[quotation.quotationType].DESIGNATION] = row.designation || '';
		tempRows[QuotationColumnHeader[quotation.quotationType].UNITARY_COST] = row.unitaryCost ?? '';
		tempRows[QuotationColumnHeader[quotation.quotationType].QUANTITY] = row.quantity ?? '';
		tempRows[QuotationColumnHeader[quotation.quotationType].DISCOUNT] = row.discount ?? '';
		tempRows[QuotationColumnHeader[quotation.quotationType].VAT_RATE] = row.vatRate?.rate ?? undefined;
		if (row.stakeholders) {
			Object.values(row.stakeholders).sort((a, b) => a.line - b.line).forEach((stakeholder) => {
				tempRows[stakeholder.columnId] = {};
				tempRows[stakeholder.columnId].rate = stakeholder.rate ?? '';
				tempRows[stakeholder.columnId].base = (quotation.stakeholders[stakeholder.columnId]?.partnerProjectAmount ?? 0)
					* ((quotation.stakeholders[stakeholder.columnId]?.partnerRatio ?? 0) / 100)
					* (1 - (quotation.stakeholders[stakeholder.columnId]?.partnerDiscount ?? 0) / 100);
			});
		}
		rows.push(tempRows);
	});

	return rows;
};

/**
 * @function
 * @name getStakeholdersFromRowsAsArray
 * @description Parses the backend-retrieved data into an array to be used for calculation purposes.
 *
 * @author Romaric Barthe
 *
 * @param {object}	rows	the quotation rows.
 *
 * @returns {array} transformed initial values.
 */
export const getStakeholdersFromRowsAsArray = (rows) => (
	rows
		? Object.entries(rows).map(([, lineObj]) => {
			const transformedStakeholders = lineObj && lineObj.stakeholders
				? Object.entries(lineObj.stakeholders).reduce((acc, [k, value]) => {
					acc[k] = {
						...value,
						extra: lineObj.extra,
					};

					return acc;
				}, {})
				: {};

			return transformedStakeholders ? { ...transformedStakeholders } : {};
		}).flatMap((obj) => (Object.entries(obj).map(([, value]) => value)))
		: []);

/**
 * @function
 * @name getEnrichedStakeholdersInfo
 * @description Enrich stakeholders info with a details array giving ratio and extra.
 *
 * @author Romaric Barthe
 *
 * @param {object}	stakeholders	the quotation stakeholders.
 * @param {object}	rows			the quotation rows.
 *
 * @returns {array} transformed initial values.
 */
export const getEnrichedStakeholdersInfo = (stakeholders, rows) => (
	stakeholders
		? Object.entries(stakeholders).map(([key, column]) => (
			{
				id: key,
				partnerProjectAmount: column?.partnerProjectAmount ?? 0,
				partnerRatio: (column?.partnerRatio ?? 0) / 100,
				partnerDiscount: (column?.partnerDiscount ?? 0) / 100,
				details: getStakeholdersFromRowsAsArray(rows).filter((f) => f?.columnId === key && f?.rate !== null && f?.rate !== 0),
			}
		))
		: []);

/**
 * @function
 * @name getOfferTotals
 * @description create subtotal for each rows in a standard quotation as an array.
 *
 * @author Romaric Barthe
 *
 * @param {object}	rows		the quotation rows.
 * @param {object}	vatRates	the available vatRates.
 *
 * @returns {array} transformed initial values.
 */
export const getOfferTotals = (rows, vatRates) => (
	rows
		? Object.entries(rows).map(([key, row]) => {
			const rowVatRateArray = row.vatRate && vatRates ? vatRates.filter((vat) => vat.id === row.vatRate) : [{ rate: 0 }];
			const rowVatRate = rowVatRateArray && rowVatRateArray.length > 0 ? rowVatRateArray[0].rate / 100 : 0;
			const rowGrossTotal = (row.unitaryCost ?? 0) * (row.quantity ?? 0);
			const rowDiscountTotal = rowGrossTotal * ((row.discount ?? 0) / 100);
			const rowGrossTotalDiscounted = rowGrossTotal - rowDiscountTotal;
			const rowVatTotal = rowGrossTotalDiscounted * rowVatRate;
			const rowNetTotal = rowGrossTotalDiscounted + rowVatTotal;

			return ({ id: key, gt: rowGrossTotal ?? 0, vt: rowVatTotal ?? 0, nt: rowNetTotal ?? 0, dt: rowDiscountTotal ?? 0, gtd: rowGrossTotalDiscounted ?? 0 });
		})
		: []);
