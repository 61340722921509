/**
 * @enum {string}
 * @readonly
 *
 * @name airEntities
 * @description The entities to be populated over the applications.
 * @author Romaric Barthe
 */
const airEntities = Object.freeze({
	ENTITY_CONTACT: 'contact',
	ENTITY_PARTNER: 'partner',
});

/**
 * @enum {string}
 * @readonly
 *
 * @name contactEntityFields
 * @description The fields for contact entity.
 * @author Romaric Barthe
 */
const contactEntityFields = Object.freeze({
	ID: 'id',
	FIRSTNAME: 'first_name',
	LASTNAME: 'last_name',
	TITLE: 'title',
	EMAILS: 'emails',
	PHONES: 'phones',
	FAX: 'fax',
	MOBILES: 'mobiles',
	ADDRESS: 'address',
	POST_CODES: 'post_code',
	CITY: 'city',
	COUNTRY: 'country',
	POSITION: 'position',
	RESTRICTION: 'restriction',
	RELATION_TYPES: 'relation_types',
	OWNER: 'owner',
	NOTES: 'notes',
	PARTNERS: 'partners',
});

/**
 * @enum {string}
 * @readonly
 *
 * @name partnerEntityFields
 * @description The fields for entity.
 * @author Romaric Barthe
 */
const partnerEntityFields = Object.freeze({
	ID: 'id',
	NAME: 'partner_name',
	LEGAL_REPRESENTANT: 'legal_representant',
	ACTIVITY_DESCRIPTION: 'activity_description',
	EMAILS: 'emails',
	PHONES: 'phones',
	FAX: 'fax',
	MOBILES: 'mobiles',
	ADDRESS: 'address',
	POST_CODES: 'post_code',
	CITY: 'city',
	COUNTRY: 'country',
	WEBSITE: 'website',
	TURNOVER: 'turnover',
	TURNOVER_SLICE: 'turnover_slice',
	CURRENCY: 'currency',
	WORKFORCE: 'workforce',
	WORKFORCE_SLICE: 'workforce_slice',
	REGISTRATION_APE: 'registration_type.ape',
	REGISTRATION_RCS: 'registration_type.rcs',
	REGISTRATION_SIRET: 'registration_type.siret',
	REGISTRATION_VAT: 'registration_type.vat',
	SECTOR_NAF_NAME: 'naf_name',
	SECTOR_NAF_CODE: 'naf_code',
	SECTOR_NACE_NAME: 'nace_name',
	SECTOR_NACE_CODE: 'nace_code',
	SECTOR_CITI_NAME: 'citi_name',
	SECTOR_CITI_CODE: 'citi_code',
	SECTOR_CUSTOM_CODE: 'custom_code',
	SECTOR_CUSTOM_NAME: 'custom_name',
	RELATION_TYPES: 'relation_types',
	NOTES: 'notes',
});

/**
 * @enum {string}
 * @readonly
 *
 * @name linkedEntity
 * @description The link to be established on import.
 * @author Romaric Barthe
 */
const linkedEntity = Object.freeze({
	LINKED_TARGET: 'target',
});

export {
	airEntities,
	contactEntityFields,
	linkedEntity,
	partnerEntityFields,
};
