import * as TargetPartnersApi from 'api/targetPartnersApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the TargetPartners redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of targetPartners
	FETCH_TARGET_PARTNER_SELECT_LIST_REQUEST: '@TARGET_PARTNERS_SELECT/FETCH_LIST_REQUEST',
	FETCH_TARGET_PARTNER_SELECT_LIST_SUCCESS: '@TARGET_PARTNERS_SELECT/FETCH_LIST_SUCCESS',
	FETCH_TARGET_PARTNER_SELECT_LIST_FAILURE: '@TARGET_PARTNERS_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ////////// TargetPartner list fetching actions ///////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetPartnersSelectListRequest
 * @description Action triggered anytime a targetPartnersSelect list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetPartnersSelectListRequest = () => ({ type: ActionTypes.FETCH_TARGET_PARTNER_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchTargetPartnersSelectListSuccess
 * @description Action triggered as a result to a successful targetPartnersSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	targetPartnersSelect	The list of retrieved targetPartners.
 * @param {number}	totalCount				The total amount of targetPartners available in the database to the current user.
 *
 * @returns {object}
 */
const fetchTargetPartnersSelectListSuccess = ({ targetPartnersSelect, totalCount }) => ({
	type: ActionTypes.FETCH_TARGET_PARTNER_SELECT_LIST_SUCCESS,
	payload: { targetPartnersSelect, totalCount },
});

/**
 * @function
 * @name fetchTargetPartnersSelectListFailure
 * @description Action triggered as a result to a failed targetPartnersSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetPartnersSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_TARGET_PARTNER_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetPartnersSelectList
 * @description Method used to update the targetPartnersSelect list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchTargetPartnersSelectList = (filters) => (dispatch) => {
	dispatch(fetchTargetPartnersSelectListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forSelect',
	};

	return TargetPartnersApi.fetchTargetPartners(newFilters)
		.then(({ targetPartnersSelect, totalCount }) => dispatch(fetchTargetPartnersSelectListSuccess({ targetPartnersSelect, totalCount })))
		.catch((error) => dispatch(fetchTargetPartnersSelectListFailure(error)));
};
