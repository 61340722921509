import { v4 as uuid } from 'uuid';

import EntityTypes from '../../constants/EntityTypes';

/**
 * @function
 * @name createEntity
 * @description Creates an empty instance of an entity
 *
 * @author Yann Hodiesne
 */
const createEntity = () => ({
	id: uuid(),
	name: 'Nouvelle entitée',
	type: EntityTypes.NONE.type,
});

export default createEntity;
