/**
 * @constant
 * @type {Object}
 * @name filterOperatorEnum
 * @description An object containing the different filter operators.
 *
 * @author Timothée Simon-Franza
 */
const filterOperatorEnum = Object.freeze({
	IF: 'if',
	AND: 'and',
	OR: 'or',
});

/**
 * @constant
 * @type {Object}
 * @name filterCriteriaTypeEnum
 * @description An object containing the different filter criteria types.
 *
 * @author Timothée Simon-Franza
 */
const filterCriteriaTypeEnum = Object.freeze({
	STARTS_WITH: 'starts_with',
	ENDS_WITH: 'ends_with',
	CONTAINS: 'contains',
	NOT_CONTAINS: 'not_contains',
	IS: 'is',
	IS_NOT: 'is_not',
	GREATHER_THAN: 'greater_than',
	GREATHER_THAN_OR_EQUAL: 'greater_than_or_equal',
	LESS_THAN: 'less_than',
	LESS_THAN_OR_EQUAL: 'less_than_or_equal',
	EMPTY: 'empty',
	NOT_EMPTY: 'not_empty',
});

export {
	filterCriteriaTypeEnum,
	filterOperatorEnum,
};
