import { MarginsDefaults } from '../constants';
import { MarginsOptions } from '../constants/MarginsDefaults';
import {
	addEntity,
	addToSelection,
	appendElement,
	appendPage,
	appendRow,
	clearSelection,
	decrementElementsInFooter,
	decrementElementsInHeader,
	duplicateElements,
	incrementElementsInFooter,
	incrementElementsInHeader,
	insertElement,
	insertRow,
	linkEntity,
	moveElement,
	moveElementById,
	moveRow,
	moveRowById,
	removeElements,
	removeEntity,
	removeFromSelection,
	removePage,
	removeRow,
	resetDirtyFlag,
	resetMovingElement,
	setMovingElement,
	setSelection,
	updateDescription,
	updateEditionDate,
	updateElement,
	updateEntity,
	updateFooter,
	updateHeader,
	updateMargins,
	updateName,
	updatePage,
	updatePageNumberingStartIndex,
	updateRow,
} from '../functions';

import { ActionTypes } from './actions';

export const initialState = {
	elements: [],
	entities: [],
	pages: [],
	header: { height: 80, numberOfElements: 0, excludeFromPages: [], disabled: false },
	footer: { height: 80, numberOfElements: 0, excludeFromPages: [], disabled: false },
	margins: { ...MarginsDefaults.NORMAL, type: MarginsOptions.NORMAL },
	selectedElement: null,
	movingElement: undefined,
	pageNumberingStartIndex: 0,
	selectedElements: [],
	templateName: '',
	templateDescription: '',
	isDirty: false,
};

/**
 * @function
 * @name reducer
 * @description The Editor component reducer, used to manage its internal state
 *
 * @param {object} state	The current state
 * @param {object} action	The dispatched action to take care of
 *
 * @returns The new state value
 */
const reducer = (state, { type, payload }) => {
	switch (type) {
		case ActionTypes.ADD_ENTITY:
			return addEntity(state, payload);
		case ActionTypes.ADD_TO_SELECTION:
			return addToSelection(state, payload);
		case ActionTypes.APPEND_ELEMENT:
			return appendElement(state, payload);
		case ActionTypes.APPEND_PAGE:
			return appendPage(state, payload);
		case ActionTypes.APPEND_ROW:
			return appendRow(state, payload);
		case ActionTypes.CLEAR_SELECTION:
			return clearSelection(state);
		case ActionTypes.DECREMENT_ELEMENTS_IN_HEADER:
			return decrementElementsInHeader(state);
		case ActionTypes.DUPLICATE_ELEMENTS:
			return duplicateElements(state, payload);
		case ActionTypes.INCREMENT_ELEMENTS_IN_HEADER:
			return incrementElementsInHeader(state);
		case ActionTypes.INSERT_ELEMENT:
			return insertElement(state, payload);
		case ActionTypes.INSERT_ROW:
			return insertRow(state, payload);
		case ActionTypes.LINK_ENTITY:
			return linkEntity(state, payload);
		case ActionTypes.MOVE_ELEMENT:
			return moveElement(state, payload);
		case ActionTypes.MOVE_ELEMENT_BY_ID:
			return moveElementById(state, payload);
		case ActionTypes.MOVE_ROW:
			return moveRow(state, payload);
		case ActionTypes.MOVE_ROW_BY_ID:
			return moveRowById(state, payload);
		case ActionTypes.REMOVE_ELEMENTS:
			return removeElements(state, payload);
		case ActionTypes.REMOVE_ENTITY:
			return removeEntity(state, payload);
		case ActionTypes.REMOVE_FROM_SELECTION:
			return removeFromSelection(state, payload);
		case ActionTypes.REMOVE_PAGE:
			return removePage(state, payload);
		case ActionTypes.REMOVE_ROW:
			return removeRow(state, payload);
		case ActionTypes.RESET_DIRTY_FLAG:
			return resetDirtyFlag(state, payload);
		case ActionTypes.RESET_MOVING_ELEMENT:
			return resetMovingElement(state);
		case ActionTypes.SET_MOVING_ELEMENT:
			return setMovingElement(state, payload);
		case ActionTypes.SET_SELECTION:
			return setSelection(state, payload);
		case ActionTypes.UPDATE_DESCRIPTION:
			return updateDescription(state, payload);
		case ActionTypes.UPDATE_EDITION_DATE:
			return updateEditionDate(state, payload);
		case ActionTypes.UPDATE_ELEMENT:
			return updateElement(state, payload);
		case ActionTypes.UPDATE_ENTITY:
			return updateEntity(state, payload);
		case ActionTypes.UPDATE_HEADER:
			return updateHeader(state, payload);
		case ActionTypes.UPDATE_NAME:
			return updateName(state, payload);
		case ActionTypes.UPDATE_PAGE:
			return updatePage(state, payload);
		case ActionTypes.UPDATE_ROW:
			return updateRow(state, payload);
		case ActionTypes.UPDATE_FOOTER:
			return updateFooter(state, payload);
		case ActionTypes.INCREMENT_ELEMENTS_IN_FOOTER:
			return incrementElementsInFooter(state);
		case ActionTypes.DECREMENT_ELEMENTS_IN_FOOTER:
			return decrementElementsInFooter(state);
		case ActionTypes.UPDATE_MARGINS:
			return updateMargins(state, payload);
		case ActionTypes.UPDATE_PAGE_NUMBERING_START_INDEX:
			return updatePageNumberingStartIndex(state, payload);
		default:
			throw new Error(`[Editor] Invalid action: ${type}`);
	}
};

export default reducer;
