import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useContactsSelectLoadingSelector
 * @description A selector to retrieve the loading state from the contactsSelect redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useContactsSelectLoadingSelector = () => {
	const loading = useSelector((state) => state.contactsSelect?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useContactsSelectListSelector
 * @description A selector to retrieve the contactsSelect list from the contactsSelect redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useContactsSelectListSelector = () => {
	const contacts = useSelector((state) => (state.contactsSelect?.items ?? []), shallowEqual);

	return contacts;
};

/**
 * @function
 * @name useContactsSelectTotalCountSelector
 * @description A selector to retrieve the totalCount value from the contactsSelect redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useContactsSelectTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.contactsSelect?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useContactsSelectListSelector,
	useContactsSelectLoadingSelector,
	useContactsSelectTotalCountSelector,
};
