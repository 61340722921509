import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Checkbox } from 'components/shared/forms/inputs';

/**
 * @name SingleRights
 * @description A form used to edit access rights.
 *
 * @author Romaric Barthe
 *
 * @param {array}	rows	The list of access rights.
 */
const SingleRights = ({ rows }) => {
	const { t } = useTranslation();

	return (
		rows.map((accessRight) => (
			<div key={accessRight} className="access-right">
				<Checkbox
					label={t(`access_definition.creation.inputs.${accessRight}.label`)}
					name={accessRight}
					labelFirst={false}
				/>
			</div>
		))
	);
};

SingleRights.propTypes = {
	rows: PropTypes.arrayOf(
		PropTypes.string,
	),
};

SingleRights.defaultProps = {
	rows: [],
};

export default SingleRights;
