/**
 * @function
 * @name paymentDelayScales
 * @description Return the array of payment delays scale options.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {func}	t		Translator method.
 * @param {string}	prefix	Translation prefix.
 *
 * @returns {array} Array of options for payment delay scale.
 */
export const paymentDelayScales = (t, prefix) => [
	{ value: 'days', label: t(`${prefix}.days`) },
	{ value: 'weeks', label: t(`${prefix}.weeks`) },
	{ value: 'months', label: t(`${prefix}.months`) },
];

/**
 * @function
 * @name paymentDelayTerms
 * @description Return the array of payment delay term options.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {func}	t		Translator method.
 * @param {string}	prefix	Translation prefix.
 *
 * @returns Array of options for payment delay term.
 */
export const paymentDelayTerms = (t, prefix) => [
	{ value: 'exact', label: t(`${prefix}.exact`) },
	{ value: 'end-of-month', label: t(`${prefix}.end_of_month`) },
];
