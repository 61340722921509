/**
 * @name hexToRgb
 * @description Converts a hex color to rgb.
 * Source: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 *
 * @author Florian Fornazaric
 *
 * @param {string} hex The hex color.
 *
 * @returns {object} The rgb color.
 */
export const hexToRgb = (hex) => {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	const parsedHex = hex?.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(parsedHex);

	return result ? [
		parseInt(result[1], 16),
		parseInt(result[2], 16),
		parseInt(result[3], 16),
	] : null;
};

/**
 * @name luminance
 * @description Calculates the luminance of a color.
 * Source: https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
 *
 * @author Florian Fornazaric
 *
 * @param {number} r The red value.
 * @param {number} g The green value.
 * @param {number} b The blue value.
 *
 * @returns {number} The luminance of the color.
 */
export const luminance = (r, g, b) => {
	const a = [r, g, b].map((v) => {
		const res = v / 255;

		return res <= 0.03928
			? res / 12.92
			: ((res + 0.055) / 1.055) ** 2.4;
	});

	return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

/**
 * @name contrast
 * @description Calculates the contrast between two colors.
 * Source: https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
 *
 * @author Florian Fornazaric
 */
export const contrast = (rgb1, rgb2) => {
	if (!rgb1 || !rgb2) {
		return 0;
	}

	const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
	const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
	const brightest = Math.max(lum1, lum2);
	const darkest = Math.min(lum1, lum2);

	return (brightest + 0.05) / (darkest + 0.05);
};

/**
 * @name padZero
 * @description Pad each component with zeros and output.
 * Source: https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color/35970186#35970186
 *
 * @author Romaric Barthe
 */
export const padZero = (str, len) => {
	const defaultLength = len || 2;
	const zeros = new Array(defaultLength).join('0');

	return (zeros + str).slice(-defaultLength);
};

/**
 * @name invertColor
 * @description Find from an heximal color the corresponding inverted color.
 * Source: https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color/35970186#35970186
 *
 * @author Romaric Barthe
 */
export const invertColor = (hex) => {
	const parsedHexaColor = hex.indexOf('#') === 0 ? hex.slice(1) : hex;

	if (parsedHexaColor.length !== 6 && parsedHexaColor.length !== 3) {
		return '#ffffff';
	}

	// convert 3-digit hex to 6-digits.
	const parsed6DigitHexaColor = parsedHexaColor.length === 3
		? parsedHexaColor[0].concat(parsedHexaColor[0], parsedHexaColor[1], parsedHexaColor[1], parsedHexaColor[2], parsedHexaColor[2])
		: parsedHexaColor;

	// invert color components
	const r = (255 - parseInt(parsed6DigitHexaColor.slice(0, 2), 16)).toString(16);
	const g = (255 - parseInt(parsed6DigitHexaColor.slice(2, 4), 16)).toString(16);
	const b = (255 - parseInt(parsed6DigitHexaColor.slice(4, 6), 16)).toString(16);

	// pad each with zeros and return
	return '#'.concat(padZero(r), padZero(g), padZero(b));
};
