import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchVatRates
 * @description Retrieves a list of vat rates from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchVatRates = (params) => post('/api/vat-rates', params);

/**
 * @function
 * @name fetchVatRateById
 * @description Retrieves an existing vat rate from the API, using the vatRateId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} vatRateId	The id of the vat rate we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchVatRateById = (vatRateId) => get(`/api/vat-rate/${vatRateId}`);

/**
 * @function
 * @name createVatRate
 * @description Creates a new vat rate into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} vatRateData	The data to create the vat rate from.
 *
 * @returns {Promise}
 */
export const createVatRate = (vatRateData) => post('/api/vat-rates/create', vatRateData);

/**
 * @function
 * @name updateVatRate
 * @description Updates an existing vat rate from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} vatRateData 	The data to update the vat rate with.
 * @param {string} vatRateId   	The id to identify the vat rate with.
 *
 * @returns {Promise}
 */
export const updateVatRate = (vatRateData, vatRateId) => put(`/api/vat-rate/${vatRateId}`, vatRateData);

/**
 * @function
 * @name deleteVatRate
 * @description Removes an existing vat rate from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} vatRateId	The id of the vat rate we want to remove.
 *
 * @returns {Promise}
 */
export const deleteVatRate = (vatRateId) => del(`/api/vat-rate/${vatRateId}`);
