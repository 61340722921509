import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as TargetsApi from 'api/targetsApi';
import download from 'downloadjs';
import i18next from 'i18next';
import { downloadServerFile } from 'lib/shared/fileHelper';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Targets redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific target
	FETCH_TARGETING_TARGET_REQUEST: '@TARGETING/@TARGETS/FETCH_REQUEST',
	FETCH_TARGETING_TARGET_SUCCESS: '@TARGETING/@TARGETS/FETCH_SUCCESS',
	FETCH_TARGETING_TARGET_FAILURE: '@TARGETING/@TARGETS/FETCH_FAILURE',

	// Fetch a list of targets
	FETCH_TARGETING_TARGET_LIST_REQUEST: '@TARGETING/@TARGETS/FETCH_LIST_REQUEST',
	FETCH_TARGETING_TARGET_LIST_SUCCESS: '@TARGETING/@TARGETS/FETCH_LIST_SUCCESS',
	FETCH_TARGETING_TARGET_LIST_FAILURE: '@TARGETING/@TARGETS/FETCH_LIST_FAILURE',

	// Add a new target to the database
	ADD_TARGETING_TARGET_REQUEST: '@TARGETING/@TARGETS/ADD_REQUEST',
	ADD_TARGETING_TARGET_SUCCESS: '@TARGETING/@TARGETS/ADD_SUCCESS',
	ADD_TARGETING_TARGET_FAILURE: '@TARGETING/@TARGETS/ADD_FAILURE',

	// Update an existing target from the database
	UPDATE_TARGETING_TARGET_REQUEST: '@TARGETING/@TARGETS/UPDATE_REQUEST',
	UPDATE_TARGETING_TARGET_SUCCESS: '@TARGETING/@TARGETS/UPDATE_SUCCESS',
	UPDATE_TARGETING_TARGET_FAILURE: '@TARGETING/@TARGETS/UPDATE_FAILURE',

	// Remove an existing target from the database
	REMOVE_TARGETING_TARGET_REQUEST: '@TARGETING/@TARGETS/REMOVE_REQUEST',
	REMOVE_TARGETING_TARGET_SUCCESS: '@TARGETING/@TARGETS/REMOVE_SUCCESS',
	REMOVE_TARGETING_TARGET_FAILURE: '@TARGETING/@TARGETS/REMOVE_FAILURE',

	// Import target partners in an existing target from the database
	IMPORT_TARGETING_TARGET_TARGETPARTNERS_REQUEST: '@TARGETING/@TARGETS/IMPORT_TARGETPARTNERS_REQUEST',
	IMPORT_TARGETING_TARGET_TARGETPARTNERS_SUCCESS: '@TARGETING/@TARGETS/IMPORT_TARGETPARTNERS_SUCCESS',
	IMPORT_TARGETING_TARGET_TARGETPARTNERS_FAILURE: '@TARGETING/@TARGETS/IMPORT_TARGETPARTNERS_FAILURE',

	// Fetch target stats
	FETCH_TARGET_STATS_REQUEST: '@TARGET_STATS/FETCH_REQUEST',
	FETCH_TARGET_STATS_SUCCESS: '@TARGET_STATS/FETCH_SUCCESS',
	FETCH_TARGET_STATS_FAILURE: '@TARGET_STATS/FETCH_FAILURE',

	// Export target partners as a file
	EXPORT_TARGET_REQUEST: '@TARGETS/EXPORT_REQUEST',
	EXPORT_TARGET_SUCCESS: '@TARGETS/EXPORT_SUCCESS',
	EXPORT_TARGET_FAILURE: '@TARGETS/EXPORT_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Target list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetListRequest
 * @description Action triggered anytime a target list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetListRequest = () => ({ type: ActionTypes.FETCH_TARGETING_TARGET_LIST_REQUEST });

/**
 * @function
 * @name fetchTargetListSuccess
 * @description Action triggered as a result to a successful target list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targets	The list of retrieved targets.
 * @param {number} totalCount	The total amount of targets available in the database for the current user.
 *
 * @returns {object}
 */
const fetchTargetListSuccess = ({ targets, totalCount }) => ({
	type: ActionTypes.FETCH_TARGETING_TARGET_LIST_SUCCESS,
	payload: { targets, totalCount },
});

/**
 * @function
 * @name fetchTargetListFailure
 * @description Action triggered as a result to a failed target list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetListFailure = (error) => ({
	type: ActionTypes.FETCH_TARGETING_TARGET_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific target fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetRequest
 * @description Action triggered anytime a target fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetRequest = () => ({ type: ActionTypes.FETCH_TARGETING_TARGET_REQUEST });

/**
 * @function
 * @name fetchTargetSuccess
 * @description Action triggered as a result to a successful target fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} target	The retrieved target.
 *
 * @returns {object}
 */
const fetchTargetSuccess = ({ target }) => ({
	type: ActionTypes.FETCH_TARGETING_TARGET_SUCCESS,
	payload: { target },
});

/**
 * @function
 * @name fetchTargetFailure
 * @description Action triggered as a result to a failed target fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetFailure = (error) => ({
	type: ActionTypes.FETCH_TARGETING_TARGET_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Target creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addTargetRequest
 * @description Action triggered anytime a target creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addTargetRequest = () => ({ type: ActionTypes.ADD_TARGETING_TARGET_REQUEST });

/**
 * @function
 * @name addTargetSuccess
 * @description Action triggered as a result to a successful target creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} target	The created target object.
 *
 * @returns {object}
 */
const addTargetSuccess = ({ target }) => ({
	type: ActionTypes.ADD_TARGETING_TARGET_SUCCESS,
	payload: { target },
});

/**
 * @function
 * @name addTargetFailure
 * @description Action triggered as a result to a failed target creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addTargetFailure = (error) => ({
	type: ActionTypes.ADD_TARGETING_TARGET_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Target update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateTargetRequest
 * @description Action triggered anytime a target update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateTargetRequest = () => ({ type: ActionTypes.UPDATE_TARGETING_TARGET_REQUEST });

/**
 * @function
 * @name updateTargetSuccess
 * @description Action triggered as a result to a successful target update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} target	The updated target object.
 *
 * @returns {object}
 */
const updateTargetSuccess = ({ target }) => ({
	type: ActionTypes.UPDATE_TARGETING_TARGET_SUCCESS,
	payload: { target },
});

/**
 * @function
 * @name updateTargetFailure
 * @description Action triggered as a result to a failed target update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateTargetFailure = (error) => ({
	type: ActionTypes.UPDATE_TARGETING_TARGET_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Target removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeTargetRequest
 * @description Action triggered anytime a target deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeTargetRequest = () => ({ type: ActionTypes.REMOVE_TARGETING_TARGET_REQUEST });

/**
 * @function
 * @name removeTargetSuccess
 * @description Action triggered as a result to a successful target deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param { string } deletedTargetId	The removed target object id.
 *
 * @returns {object}
 */
const removeTargetSuccess = ({ deletedTargetId }) => ({
	type: ActionTypes.REMOVE_TARGETING_TARGET_SUCCESS,
	payload: { deletedTargetId },
});

/**
 * @function
 * @name removeTargetFailure
 * @description Action triggered as a result to a failed target deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeTargetFailure = (error) => ({
	type: ActionTypes.REMOVE_TARGETING_TARGET_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Target import actions //////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name importTargetPartnersForTargetRequest
 * @description Action triggered anytime a target update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const importTargetPartnersForTargetRequest = () => ({ type: ActionTypes.IMPORT_TARGETING_TARGET_TARGETPARTNERS_REQUEST });

/**
 * @function
 * @name importTargetPartnersForTargetSuccess
 * @description Action triggered as a result to a successful target update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} target	The updated target object.
 *
 * @returns {object}
 */
const importTargetPartnersForTargetSuccess = ({ logFile }) => ({
	type: ActionTypes.IMPORT_TARGETING_TARGET_TARGETPARTNERS_SUCCESS,
	payload: { logFile },
});

/**
 * @function
 * @name importTargetPartnersForTargetFailure
 * @description Action triggered as a result to a failed target update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const importTargetPartnersForTargetFailure = (error) => ({
	type: ActionTypes.IMPORT_TARGETING_TARGET_TARGETPARTNERS_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Target stats actions //////////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetStatsRequest
 * @description Action triggered anytime a target stats fetch call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const fetchTargetStatsRequest = () => ({ type: ActionTypes.FETCH_TARGET_STATS_REQUEST });

/**
 * @function
 * @name fetchTargetingStatsSuccess
 * @description Action triggered as a result to a successful target stats fetch API call.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const fetchTargetStatsSuccess = ({ targetStats }) => ({
	type: ActionTypes.FETCH_TARGET_STATS_SUCCESS,
	payload: { targetStats },
});

/**
 * @function
 * @name fetchTargetStatsFailure
 * @description Action triggered as a result to a failed target stats fetch API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetStatsFailure = (error) => ({
	type: ActionTypes.FETCH_TARGET_STATS_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Target exporting actions //////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name exportTargetRequest
 * @description Action triggered anytime a target exporting call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const exportTargetRequest = () => ({ type: ActionTypes.EXPORT_TARGET_REQUEST });

/**
 * @function
 * @name exportTargetSuccess
 * @description Action triggered as a result to a successful target exporting API call.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const exportTargetSuccess = () => ({ type: ActionTypes.EXPORT_TARGET_SUCCESS });

/**
 * @function
 * @name exportTargetFailure
 * @description Action triggered as a result to a failed partner exporting API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const exportTargetFailure = (error) => ({
	type: ActionTypes.EXPORT_TARGET_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetList
 * @description Method used to update the target list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params			The parameters used to match user filters.
 * @param {string} [targetingId]	The id of the targeting linked with the target.
 *
 * @returns {Promise}
 */
export const fetchTargetList = (params, targetingId = null) => (dispatch) => {
	dispatch(fetchTargetListRequest());
	const requestParams = {
		...params,
		...(targetingId ? { filters: { targeting: targetingId } } : {}),
	};

	return TargetsApi.fetchTargets(requestParams)
		.then(({ targets, totalCount }) => dispatch(fetchTargetListSuccess({ targets, totalCount })))
		.catch((error) => dispatch(fetchTargetListFailure(error)));
};

/**
 * @function
 * @name fetchTarget
 * @description Method used to fetch the latest version of a specific target.
 *
 * @author Romaric Barthe
 *
 * @param {string} [targetId]	The id of the target we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTarget = (targetId) => (dispatch) => {
	dispatch(fetchTargetRequest());

	return TargetsApi.fetchTargetById(targetId)
		.then(({ target }) => dispatch(fetchTargetSuccess({ target })))
		.catch((error) => dispatch(fetchTargetFailure(error)));
};

/**
 * @function
 * @name addTarget
 * @description Method used to add a new target instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetData		The data to create the new target from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const addTarget = (targetData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addTargetRequest());

	return TargetsApi.createTarget(targetData)
		.then(({ target }) => {
			toast.success(i18next.t('targeting.target.creation.toasts.success', { name: target.name }));
			dispatch(addTargetSuccess({ target }));
			if (onSuccessRoute) {
				redirectOnSuccess(generatePath(onSuccessRoute, { targetingId: target.targeting?.id, targetId: target?.id }));
			}

			return target;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('targeting.target.creation.toasts.conflict'));
				dispatch(addTargetFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('targeting.target.creation.toasts.error'));
				dispatch(addTargetFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateTarget
 * @description Method used to update an existing target instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetData		The object to update the target with.
 * @param {string} targetId			The id of the target we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const updateTarget = (targetData, targetId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateTargetRequest());

	return TargetsApi.updateTarget(targetData, targetId)
		.then(({ target }) => {
			dispatch(updateTargetSuccess({ target }));
			toast.success(i18next.t('targeting.target.edition.toasts.success', { name: target.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('targeting.target.edition.toasts.conflict'));
				dispatch(updateTargetFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('targeting.target.edition.toasts.error'));
				dispatch(updateTargetFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeTarget
 * @description Method used to remove an existing target instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} target					The target we want to remove from the database.
 * @param {string} target.id				The id of the target we want to remove from the database.
 * @param {string} target.name			The name of the target we want to remove from the database.
 *
 * @returns {Promise}
 */
export const removeTarget = ({ id, name }) => (dispatch) => {
	dispatch(removeTargetRequest());

	return TargetsApi.deleteTarget(id)
		.then(({ deletedTargetId }) => {
			dispatch(removeTargetSuccess({ deletedTargetId }));
			toast.success(i18next.t('targeting.target.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeTargetFailure(error));
			toast.error(i18next.t('targeting.target.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name importTargetPartnersForTarget
 * @description Method used to update an existing target instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetData		The object to update the target with.
 * @param {string} targetId			The id of the target we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const importTargetPartnersForTarget = (targetData, targetId, onSuccessRoute = null) => (dispatch) => {
	dispatch(importTargetPartnersForTargetRequest());

	return TargetsApi.importTargetPartnersForTarget(targetData, targetId)
		.then((logFile) => {
			if (logFile.filename) {
				downloadServerFile(`${process.env.REACT_APP_API_URL}/api/import/${logFile.filename}`, logFile.filename, 'xlsx');
				throw new Error({ status: -1, message: '' });
			} else {
				dispatch(importTargetPartnersForTargetSuccess({ logFile }));
				redirectOnSuccess(onSuccessRoute);
			}
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('targeting.target.edition.toasts.conflict'));

				return dispatch(importTargetPartnersForTargetFailure({ code: 'conflict' }));
			}

			if (status === -1) {
				toast.error(i18next.t('targeting.target.import.toasts.error'));
			} else {
				toast.error(i18next.t('targeting.target.edition.toasts.error'));
			}

			return dispatch(importTargetPartnersForTargetFailure({ status, message }));
		});
};

/**
 * @function
 * @name fetchTargetStats
 * @description Method used to fetch the latest version of stats on a specific target.
 *
 * @author Roland Margelidon
 *
 * @param {string} targetId	The id of the target we want to retrieve stats.
 *
 * @returns {Promise}
 */
export const fetchTargetStats = (targetId) => (dispatch) => {
	dispatch(fetchTargetStatsRequest());

	return TargetsApi.fetchTargetStats(targetId)
		.then(({ targetStats }) => dispatch(fetchTargetStatsSuccess({ targetStats })))
		.catch((error) => dispatch(fetchTargetStatsFailure(error)));
};

/**
 * @function
 * @name exportTargetPartners
 * @description Method used to export multiple target partner instances from the database.
 *
 * @author Audrey Clerc
 *
 * @param {Object}	data				The data to export the target partners.
 * @param {Array}	data.partnerIds		The ids of the target partners to export.
 * @param {string}	data.format			The file format.
 * @param {string}	data.restriction	The scope of the data to export (all, filtered or current selection).
 * @param {string}	data.separator		The separator used in the CSV file.
 *
 * @todo Adapt params when backend will change
 */
export const exportTargets = (data) => (dispatch) => {
	dispatch(exportTargetRequest());

	// TODO: adapt these parameters along with backend changes
	return TargetsApi.exportTargets({
		ids: data.selectedElementsIds ?? [],
		format: data.format ?? 'xlsx',
		restriction: data.restriction ?? 'all',
		separator: data.separator ?? 'comma',
		...data,
	})
		.then(({ blob, fileName, fileType }) => {
			dispatch(exportTargetSuccess());
			toast.success(i18next.t('template.export.toasts.success'));

			download(blob, fileName, fileType);
		})
		.catch((error) => {
			dispatch(exportTargetFailure(error));
			toast.error(i18next.t('template.export.toasts.error'));
		});
};
