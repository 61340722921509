import { useMemo } from 'react';
import PropTypes from 'prop-types';
/**
 * @name TargetingsCell
 * @description Custom cell for the partnerListPage table displaying a partner's relation types.
 *
 * @author Audrey Clerc
 */
const TargetingsCell = ({ row: { original: { targetPartners } } }) => {
	const filteredTargetings = useMemo(() => {
		const jsonTargeting = targetPartners.map((targetPartner) => targetPartner.targeting).map(JSON.stringify);
		const uniqueSet = new Set(jsonTargeting);

		return Array.from(uniqueSet).map(JSON.parse);
	}, [targetPartners]);

	return (
		<ul className="tag-list">
			{filteredTargetings.map((filteredTargeting) => (
				<li key={filteredTargeting.id}>
					<span>
						{filteredTargeting.project.name}
					</span>
				</li>
			))}
		</ul>
	);
};

TargetingsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			targetPartners: PropTypes.arrayOf(PropTypes.shape({
				targeting: PropTypes.shape({
					id: PropTypes.string.isRequired,
					project: PropTypes.shape({
						name: PropTypes.string,
					}),
				}),
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default TargetingsCell;
