import { Fragment, useCallback, useContext, useMemo, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { ResizeHandle } from 'components/shared/utils';

import { DefaultSizes, DragAndDropTypes } from '../../../constants';
import { EMPTY_ROW, PAGE_BREAK } from '../../../constants/ElementTypes';
import EditorContext from '../../../EditorContext';
import { incrementElementsInFooter, insertElement, insertRow, moveRowById, updateFooter } from '../../../reducer/actions';

import { Row } from '.';

/**
 * @name Footer
 * @description A component containing the footer components
 *
 * @author Florian Fornazaric
 *
 * @param {number} aspectRatio 	The aspect ratio of the template
 * @param {number} pageIndex 	The page index
 */
const Footer = ({ aspectRatio, pageIndex }) => {
	const { t } = useTranslation();
	const { footer, dispatch, elements, movingElement, margins } = useContext(EditorContext);
	const footerRef = useRef();

	const footerElements = useMemo(() => elements.slice(elements.length - footer.numberOfElements, elements.length), [elements, footer.numberOfElements]);

	// Allows us to get the combined height of all header elements
	const footerElementsHeight = useMemo(() => footerElements.reduce((acc, element) => acc + element.height, 0), [footerElements]);

	const [, drop] = useDrop(() => ({
		accept: [DragAndDropTypes.PDF_ELEMENT_TYPE, DragAndDropTypes.PDF_ROW],
		drop: (item, monitor) => {
			// If the draggable has already been handled by another nested `drop` function
			if (monitor.getDropResult()?.handled === true) {
				return;
			}

			// If the drop was over another droppable element, we don't process the drop
			if (!monitor.isOver({ shallow: true })) {
				return;
			}

			switch (monitor.getItemType()) {
				case DragAndDropTypes.PDF_ELEMENT_TYPE:
					// We don't allow Page Breaks in the footer
					if (item.type === PAGE_BREAK.type) {
						toast.error(t('template.pdf.editor.components.footer.pagebreak_error'));

						return;
					}

					if (item.meta.defaultSize.y + footerElementsHeight > DefaultSizes.HEADER_MAX_HEIGHT) {
						toast.error(t('template.pdf.editor.components.footer.fit_error'));

						return;
					}

					if (item.type === EMPTY_ROW.type) {
						// Append a new empty row
						dispatch(incrementElementsInFooter());
						dispatch(insertRow(elements.length));
					} else {
						// Append a new row with the requested element type populating it
						dispatch(incrementElementsInFooter());
						dispatch(insertElement(item, elements.length, elements.length, true));
					}
					break;
				default:
					break;
			}
		},
		hover: (item, monitor) => {
			switch (monitor.getItemType()) {
				case DragAndDropTypes.PDF_ROW:
					// We don't allow Page Breaks in the footer
					if (elements.find((e) => e.id === item.id)?.children[0]?.type === PAGE_BREAK.type) {
						return;
					}

					// Allows us to add the row to the header when moved into it
					if (monitor.isOver({ shallow: true })) {
						if (elements.findIndex((row) => row.id === item.id) < elements.length - footer.numberOfElements
						&& movingElement?.elementIndex <= elements.length - footer.numberOfElements - 1) {
							// If the element is inside another page, we move it to the last index contained in the header
							if (elements.findIndex((row) => row.id === item.id) !== elements.length - footer.numberOfElements - 1) {
								dispatch(moveRowById(item.id, elements.length - footer.numberOfElements - 1));
							}
							dispatch(incrementElementsInFooter());
						}
					}
					break;
				default:
					break;
			}
		},
		collect: (monitor) => ({
			isShallowOver: monitor.isOver({ shallow: true }),
			canDrop: monitor.canDrop(),
			type: monitor.getItemType(),
		}),
	}), [footer.numberOfElements, elements, movingElement]);

	const heightPointer = useRef(footer.height);

	const footerStyle = useMemo(() => ({
		height: `${footer.height * aspectRatio}px`,
		maxHeight: `${(DefaultSizes.FOOTER_MAX_HEIGHT + margins.bottom) * aspectRatio}px`,
		paddingBottom: `${margins.bottom * aspectRatio}px`,
		paddingLeft: `${margins.left * aspectRatio}px`,
		paddingRight: `${margins.right * aspectRatio}px`,
	}), [aspectRatio, footer.height, margins.bottom, margins.left, margins.right]);

	/**
	 * @name onResize
	 * @description A callback function that is called when the resize handle is dragged
	 *
	 * @author Florian Fornazaric
	 *
	 * @param {number} deltaY 	The difference in pixels between the current and the previous position of the handle
	 */
	const resizeHandler = useCallback((deltaY) => {
		const newHeight = heightPointer.current - Math.round(deltaY / aspectRatio);

		const currentFooterMaxHeight = DefaultSizes.FOOTER_MAX_HEIGHT + margins.bottom;
		const currentHeaderMinHeight = DefaultSizes.FOOTER_MIN_HEIGHT + margins.bottom;

		// We check if the new height is within the allowed range, and is not already the same as the footer's height
		if ((heightPointer.current === currentFooterMaxHeight && newHeight >= currentFooterMaxHeight)
			|| (heightPointer.current === currentHeaderMinHeight && newHeight <= currentHeaderMinHeight)
			|| newHeight === heightPointer.current) {
			return;
		}

		// If the new height is outside the allowed range, we set it to the closest allowed value
		if (newHeight > currentFooterMaxHeight) {
			heightPointer.current = currentFooterMaxHeight;
		} else if (newHeight < currentHeaderMinHeight) {
			heightPointer.current = currentHeaderMinHeight;
		} else {
			heightPointer.current = newHeight;
		}

		dispatch(updateFooter({ height: heightPointer.current }));
	}, [aspectRatio, dispatch, margins.bottom]);

	drop(footerRef);

	return (
		<div
			ref={footerRef}
			className="page-footer"
			style={footerStyle}
		>
			<ResizeHandle resizeHandler={resizeHandler} position="top" />
			{footerElements.map((row, rowIndex) => (
				<Fragment key={row?.id}>
					<Row
						key={`${row.id}${movingElement?.element.id === row.id ? 'moving' : ''}`}
						id={row.id}
						index={elements.findIndex((e) => e.id === row.id)}
						count={row.children.length}
						elements={row.children}
						aspectRatio={aspectRatio}
						height={row.height}
						pageIndex={pageIndex}
					/>
					{/* If the moving element starting index is at the current index and the moving element isn't being moved to the same row,
					we generate a dummy row to avoid a React-DND bug */}
					{(movingElement?.elementIndex === rowIndex && movingElement?.element.id !== row.id && (
					<Row
						key={movingElement.element.id}
						id={movingElement.element.id}
						index={elements.findIndex((e) => e.id === movingElement.element.id)}
						count={movingElement.element.children.length}
						elements={movingElement.element.children}
						aspectRatio={aspectRatio}
						height={movingElement.element.height}
						pageIndex={pageIndex}
						isMovingBetweenPages={false}
						isShown={false}
					/>
					))}
				</Fragment>
			))}
		</div>
	);
};

Footer.propTypes = {
	aspectRatio: PropTypes.number.isRequired,
	pageIndex: PropTypes.number.isRequired,
};

export default Footer;
