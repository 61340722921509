import update from 'immutability-helper';

/**
 * @function
 * @name updateEditionDate
 * @description Updates template's description with provided value
 *
 * @author Romaric Barthe
 *
 * @param {object} state					The current state
 * @param {string} payload.editionDate		The new template's edition date
 *
 * @returns {object} The updated state value
 */
const updateEditionDate = (state, { editionDate }) => {
	const result = update(state, {
		editionDate: {
			$set: editionDate,
		},
	});

	return result;
};

export default updateEditionDate;
