import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchRelationTypes
 * @description Retrieves a list of relation types from the API, specifying filters if present.
 *
 * @author Audrey Clerc
 *
 * @param {object} params The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchRelationTypes = (params) => post('/api/relation-types', params);

/**
 * @function
 * @name fetchRelationTypeById
 * @description Retrieves an existing relation type from the API, using the relationTypeId in parameters.
 *
 * @author Audrey Clerc
 *
 * @param {string} relationTypeId The id of the relation type we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchRelationTypeById = (relationTypeId) => get(`/api/relation-type/${relationTypeId}`);

/**
 * @function
 * @name createRelationType
 * @description Creates a new relation type into the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} relationTypeData     The data to create the relation type from.
 *
 * @returns {Promise}
 */
export const createRelationType = (relationTypeData) => post('/api/relation-types/create', relationTypeData);

/**
 * @function
 * @name updateRelationType
 * @description Updates an existing relation type from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} relationTypeData  The data to update the relation type with.
 * @param {string} relationTypeId    The id to identify the relation type with.
 *
 * @returns {Promise}
 */
export const updateRelationType = (relationTypeData, relationTypeId) => put(`/api/relation-type/${relationTypeId}`, relationTypeData);

/**
 * @function
 * @name deleteRelationType
 * @description Removes an existing relation type from the database.
 *
 * @author Audrey Clerc
 *
 * @param {string} relationTypeId   The id of the relation type we want to remove.
 *
 * @returns {Promise}
 */
export const deleteRelationType = (relationTypeId) => del(`/api/relation-type/${relationTypeId}`);
