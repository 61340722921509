import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name usePartnersListLoadingSelector
 * @description A selector to retrieve the loading state from the partnersList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const usePartnersListLoadingSelector = () => {
	const loading = useSelector((state) => state.partnersList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name usePartnersForListSelector
 * @description A selector to retrieve the partnersList list from the partner redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const usePartnersForListSelector = () => {
	const partnersList = useSelector((state) => (state.partnersList?.items ?? []), shallowEqual);

	return partnersList;
};

/**
 * @function
 * @name usePartnersListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the partnersList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const usePartnersListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.partnersList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	usePartnersForListSelector,
	usePartnersListLoadingSelector,
	usePartnersListTotalCountSelector,
};
