import { v4 as uuid } from 'uuid';

import { PageOrientations } from '../../constants/PagesOrientations';

/**
 * @function
 * @name createPage
 * @description Creates a new page with the given orientation
 *
 * @author Florian Fornazaric
 *
 * @param {number} orientation 	The new page's orientation (portrait, landscape)
 */
const createPage = (orientation = PageOrientations.PORTRAIT) => ({
	id: uuid(),
	orientation,
	rows: [],
});

export default createPage;
