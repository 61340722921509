/* eslint-disable import/prefer-default-export */
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';

/**
 * @function
 * @name environmentTypes
 * @description return the array of environment type options.
 *
 * @author Audrey Clerc
 *
 * @param {func}	t 		A translation method provided by the withTranslation HoC.
 *
 * @returns array of options for environment type.
 */
export const environmentTypes = (t) => [
	{ value: 'expense', label: t('number_format.fields.environment_type.expense') },
	{ value: 'invoice', label: t('number_format.fields.environment_type.invoice') },
	{ value: 'quotation', label: t('number_format.fields.environment_type.quotation') },
];

/**
 * @function useUnusedEnvironmentTypes
 * @description return the array of environment type options that are not already used in numberFormats
 *
 * @author Audrey Clerc
 *
 * @param {object}	numberFormat	The numberFormat object (used for the edition form)
 *
 * @returns {array} array of options for environment type.
 */
export const useUnusedEnvironmentTypes = (numberFormat = null) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchNumberFormatList());
	}, [dispatch]);

	const numberFormats = useNumberFormatListSelector();

	const unusedEnvironments = useMemo(() => {
		const filteredNumberFormats = numberFormat ? numberFormats.filter((nbr) => nbr.id !== numberFormat.id) : [...numberFormats];

		const usedEnvironments = filteredNumberFormats.map((element) => element.environment);

		return environmentTypes(t).filter((element) => !usedEnvironments.includes(element.value));
	}, [numberFormat, numberFormats, t]);

	return unusedEnvironments;
};
