import { actions, makePropGetter, useGetLatest } from 'react-table';
import _ from 'lodash';

const pluginName = 'useColumnSorting';

/**
 * Custom reducer actions
 */
actions.setSorting = 'SORTING_SET';

/**
 * @name onHeaderContentClick
 * @description Handles the column sorting logic
 *
 * @author Yann Hodiesne
 *
 * @param {object}	header		 the current header definition
 * @param {func}	dispatch	 the current table dispatcher
 * @param {object}	sorting		 the current table sorting value
 */
const onHeaderContentClick = (header, { defaultSortingPrefix, dispatch, state: { sorting } }) => {
	// If sorting on this header is disabled, do nothing
	if (header.sorting === false) {
		return;
	}

	let sortingKey;

	// If a custom sorting key is not present, we must check if we can auto-generate one
	if (!_.isString(header.sorting)) {
		// If the header does not have an accessor, we do not want to trigger sorting key generation as it is coming from a custom cell
		if (!header.accessor) {
			return;
		}

		sortingKey = `${defaultSortingPrefix}.${header.id}`;
	} else {
		sortingKey = header.sorting;
	}

	// Sort in ascending order by default
	let direction = 'ASC';

	// If we want to sort on an already sorted column, we should invert the sorting
	if (sorting !== null && sorting.key === sortingKey) {
		direction = sorting.direction === 'ASC' ? 'DESC' : 'ASC';
	}

	dispatch({ type: actions.setSorting, value: { key: sortingKey, direction } });
};

/**
 * @name defaultGetHeaderProps
 * @description Updates the Header props creation methods to provide an aria-sort attribute to the header's HTML tag.
 *
 * @author Timothée Simon-Franza
 *
 * @param {array}	props		 A list of objects to merge to get the final props
 * @param {object}	instance	 The current table instance
 * @param {object}	column		 The current managed column's header.
 */
const defaultGetHeaderProps = (props, { instance, column }) => {
	const additionalProps = {};

	if (instance.state.sorting && instance.state.sorting?.key === column.sorting) {
		additionalProps['aria-sort'] = instance.state.sorting.direction === 'DESC' ? 'descending' : 'ascending';
	}

	return [
		// Returns the received props, plus our onClick handler
		{ ...props, ...additionalProps },
	];
};

/**
 * @name defaultGetHeaderContentProps
 * @description Setup the props needed for column sorting on click
 *
 * @author Yann Hodiesne
 *
 * @param {array}	props		 A list of objects to merge to get the final props
 * @param {object}	instance	 The current table instance
 * @param {object}	header		 The current managed header
 */
const defaultGetHeaderContentProps = (props, { instance, header }) => [
	// Returns the received props, plus our onClick handler
	{ ...props, className: 'sortable' },
	{ onClick: () => onHeaderContentClick(header, instance) },
];

/**
 * @name reducer
 * @description Handles changes for the custom actions in the table's state
 *
 * @author Yann Hodiesne
 *
 * @param {object}	state	 the new state, can already be modified by other reducers
 * @param {object}	action	 the dispatched action
 */
const reducer = (state, action) => {
	switch (action.type) {
		case actions.init:
			return {
				sorting: null,
				...state,
			};
		case actions.setSorting:
			return {
				...state,
				sorting: action.value,
			};
		default:
			return state;
	}
};

/**
 * @name useInstance
 * @description The plugin initialization hook, called when the useTable hook is called on the table component
 *
 * @author Yann Hodiesne
 *
 * @param {object} instance : the current table instance, returned by the useTable hook
 */
const useInstance = (instance) => {
	const {
		flatHeaders,
		getHooks,
	} = instance;

	const getInstance = useGetLatest(instance);

	flatHeaders.forEach((header) => {
		// eslint-disable-next-line no-param-reassign
		header.getHeaderContentProps = makePropGetter(
			getHooks().getHeaderContentProps,
			{ instance: getInstance(), header },
		);
	});
};

/**
 * @name useColumnSorting
 * @description A react-table plugin to add support for columns sorting on click
 *
 * @author Yann Hodiesne
 *
 * @param {object} hooks	 contains the internal react-table events on which we can add our hooks
 */
const useColumnSorting = (hooks) => {
	hooks.getHeaderProps.push(defaultGetHeaderProps);
	// eslint-disable-next-line no-param-reassign
	hooks.getHeaderContentProps = [defaultGetHeaderContentProps];
	hooks.stateReducers.push(reducer);
	hooks.useInstanceBeforeDimensions.push(useInstance);
};

useColumnSorting.pluginName = pluginName;

export default useColumnSorting;
