import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

/**
 * @name TabHeader
 * @description A tab header for the toolbox component
 *
 * @author Yann Hodiesne
 * @author Timothée Simon-Franza
 *
 * @param {string}	id				The current tab header's id.
 * @param {string}	linkedPanelId	The id of the panel linked to this tab header.
 * @param {boolean}	selected		Whether the tab header is currently selected.
 * @param {func}	onClick			A callback to execute when clicking on the tab header.
 * @param {string}	children		The text to display inside the tab header.
 */
const TabHeader = ({ id, linkedPanelId, selected, onClick, children }) => (
	<Button id={id} aria-controls={linkedPanelId} role="tab" aria-selected={selected} onClick={onClick}>
		{children}
	</Button>
);

TabHeader.propTypes = {
	id: PropTypes.string.isRequired,
	linkedPanelId: PropTypes.string.isRequired,
	selected: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
	children: PropTypes.string.isRequired,
};

export default TabHeader;
