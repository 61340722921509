import { ActionTypes } from '../../actions/offers/offers';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	errors: null,
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_OFFER_LIST_REQUEST:
		case ActionTypes.ADD_OFFER_REQUEST:
		case ActionTypes.UPDATE_OFFER_REQUEST:
		case ActionTypes.REMOVE_OFFER_REQUEST:
		case ActionTypes.ARCHIVE_OFFER_REQUEST:
		case ActionTypes.RESTORE_OFFER_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_OFFER_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.ADD_OFFER_FAILURE:
		case ActionTypes.UPDATE_OFFER_FAILURE:
			return {
				...state,
				isLoading: false,
				errors: payload.errors,
			};
		case ActionTypes.FETCH_OFFER_LIST_FAILURE:
		case ActionTypes.FETCH_OFFER_FAILURE:
		case ActionTypes.REMOVE_OFFER_FAILURE:
		case ActionTypes.ARCHIVE_OFFER_FAILURE:
		case ActionTypes.RESTORE_OFFER_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_OFFER_SUCCESS:
		case ActionTypes.UPDATE_OFFER_SUCCESS:
		case ActionTypes.REMOVE_OFFER_SUCCESS:
		case ActionTypes.ARCHIVE_OFFER_SUCCESS:
		case ActionTypes.RESTORE_OFFER_SUCCESS:
			return {
				...state,
				isLoading: false,
				errors: null,
			};
		case ActionTypes.FETCH_OFFER_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.offers],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_OFFER_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.offer,
			};
		default:
			return state;
	}
};
