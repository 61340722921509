import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

/**
 * @name useFormModal
 * @description A custom hook providing props to embed a form modal inside a Select component
 *
 * @author Yann Hodiesne
 *
 * @param {func}	formComponent		Form component to display inside the modal
 * @param {string}	formTitle			Title to display at the top of the modal
 * @param {func}	fetchActionCreator	Action creator to dispatch when the Select component needs to refresh its options
 * @param {func}	createActionCreator	Action creator to dispatch when the provided form needs to submit its data
 * @param {func}	[handleSubmitData]	Method used to manipulate the data returned from the embedded form before sending it to the API
 *
 * @returns {object}
 */
const useFormModal = (formComponent, formTitle, fetchActionCreator, createActionCreator, handleSubmitData = (formData) => formData) => {
	const dispatch = useDispatch();

	const dispatchCreate = useCallback((formData, ...params) => {
		const result = handleSubmitData(formData);

		return dispatch(createActionCreator(result, ...params));
	}, [createActionCreator, dispatch, handleSubmitData]);

	const dispatchFetch = useCallback((...params) => dispatch(fetchActionCreator(...params)), [dispatch, fetchActionCreator]);

	const selectProps = useMemo(() => ({
		creationComponent: formComponent,
		creationTitle: formTitle,
		dispatchCreate,
		dispatchFetch,
	}), [formComponent, formTitle, dispatchCreate, dispatchFetch]);

	return selectProps;
};

export default useFormModal;
