import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
	border,
	color,
	colorStyle,
	display,
	layout,
	space,
	textStyle,
	typography,
} from 'styled-system';

const StyledDynamicTextComponent = styled('div')(
	{
		textDecoration: (({ textDecoration }) => textDecoration || 'initial'),
		textTransform: (({ textTransform }) => textTransform || 'initial'),
	},
	border,
	color,
	colorStyle,
	display,
	layout,
	space,
	textStyle,
	typography,
);

/**
 * @name DynamicTextComponent
 * @description A dynamic component used to display styled typography component inside the HTML tag provided in props.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string}  [tag = 'div']    The HTML tag we want the text to be displayed in.
 * @param {*}       children         The content to be displayed inside the element.
 * @param {*}       otherProps       The various style rules to override with.
 */
const DynamicTextComponent = ({ tag, children, ...props }) => (
	<StyledDynamicTextComponent as={tag} {...props}>{children}</StyledDynamicTextComponent>
);

DynamicTextComponent.propTypes = {
	tag: PropTypes.string,
	children: PropTypes.node,
};

DynamicTextComponent.defaultProps = {
	tag: 'div',
	children: undefined,
};

export default DynamicTextComponent;
