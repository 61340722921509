import update from 'immutability-helper';
import _ from 'lodash';

import { findPositionById } from './internals';

/**
 * @function
 * @name updateElement
 * @description Updates an element identified by the provided id
 *
 * @author Yann Hodiesne
 *
 * @param {object}	state				The current state
 * @param {object}	payload				The payload of the action
 * @param {string}	payload.id			The identifier of the element to select
 * @param {object}	payload.content		The new content to store inside the element
 *
 * @returns {object} The updated state value
 */
const updateElement = (state, { id, content }) => {
	const { elements, selectedElements } = state;
	const position = findPositionById(elements, id);
	if (position === null) {
		return state;
	}

	const source = elements[position.rowIndex].children[position.elementIndex];

	const newElements = update(elements, {
		[position.rowIndex]: {
			children: {
				[position.elementIndex]: {
					$set: {
						...source,
						...content,
						id: source.id,
						name: source.name,
						type: source.type,
						inline: source.inline,
					},
				},
			},
		},
	});

	if (_.isEqual(newElements[position.rowIndex].children[position.elementIndex], source)) {
		return state;
	}

	let newSelectedElements = selectedElements;
	const sourceIndex = selectedElements.indexOf(source);
	if (sourceIndex > -1) {
		newSelectedElements = update(selectedElements, { $splice: [[sourceIndex, 1, newElements[position.rowIndex].children[position.elementIndex]]] });
	}

	return {
		...state,
		elements: newElements,
		selectedElements: newSelectedElements,
		isDirty: true,
	};
};

export default updateElement;
