import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useOffersListLoadingSelector
 * @description A selector to retrieve the loading state from the offersList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useOffersListLoadingSelector = () => {
	const loading = useSelector((state) => state.offersList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useOffersForListSelector
 * @description A selector to retrieve the offersList list from the offer redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useOffersForListSelector = () => {
	const offersList = useSelector((state) => (state.offersList?.items ?? []), shallowEqual);

	return offersList;
};

/**
 * @function
 * @name useOffersListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the offersList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useOffersListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.offersList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useOffersForListSelector,
	useOffersListLoadingSelector,
	useOffersListTotalCountSelector,
};
