import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { fetchTextObjectList } from 'redux/actions/textObjects';
import { useTextObjectListSelector } from 'redux/selectors/textObjects';

import { DragAndDropTypes } from '../../constants';
import { EMPTY_ROW, PAGE_BREAK, SET_TEXT } from '../../constants/ElementTypes';
import { PageOrientationDimensions, PageOrientations } from '../../constants/PagesOrientations';
import EditorContext from '../../EditorContext';
import { appendElement, appendPage, appendRow, clearSelection, removePage, updateElement, updatePage } from '../../reducer/actions';

import EditorFooter from './content/EditorFooter';
import Page from './content/Page';
import { DropHere } from './content';

/**
 * @function
 * @name calculateAspectRatio
 * @description Calculates the aspect ratio of a page using offsetSize and size properties
 * We do a -30 so we can see the borders of the pages without them being cut off
 *
 * @author Florian Fornazaric
 *
 * @param {number} offsetSize 		The offset width/height of the page
 * @param {number} size 			The width/height of the page
 *
 * @returns {number} 				The aspect ratio of the page
 */
const calculateAspectRatio = (offsetSize, size) => _.round((offsetSize - 30) / size, 2);

/**
 * @name EditorContent
 * @description A droppable area containing a visualization of a PDF template
 *
 * @author Yann Hodiesne
 * @author Florian Fornazaric
 */
const EditorContent = () => {
	const { dispatch, elements, pages, header, footer, margins, selectedElements, isExporting } = useContext(EditorContext);
	const [aspectRatio, setAspectRatio] = useState(1);
	const editorRef = useRef();
	const reduxDispatch = useDispatch();

	const [{ isShallowOver, canDrop, type }, drop] = useDrop(() => ({
		accept: [DragAndDropTypes.PDF_ELEMENT_TYPE, DragAndDropTypes.PDF_ELEMENT],
		drop: (item, monitor) => {
			// If the draggable has already been handled by another nested `drop` function
			if (monitor.getDropResult()?.handled === true) {
				return;
			}

			// If the drop was over another droppable element, we don't process the drop
			if (!monitor.isOver({ shallow: true })) {
				return;
			}

			// If the dropped item is an element type
			if (monitor.getItemType() === DragAndDropTypes.PDF_ELEMENT_TYPE) {
				if (item.type === EMPTY_ROW.type) {
					// Append a new empty row
					dispatch(appendRow());
				} else {
					// Append a new row with the requested element type populating it
					dispatch(appendElement(item));
				}
			}
		},
		collect: (monitor) => ({
			isShallowOver: monitor.isOver({ shallow: true }),
			canDrop: monitor.canDrop(),
			type: monitor.getItemType(),
		}),
	}), []);

	const showDropHere = useMemo(() => {
		if (elements.length === 0) {
			return true;
		}

		if (canDrop && type === DragAndDropTypes.PDF_ELEMENT_TYPE) {
			return true;
		}

		return false;
	}, [canDrop, elements.length, type]);

	const unselectElement = useCallback(() => {
		if (selectedElements.length > 0) {
			dispatch(clearSelection());
		}
	}, [dispatch, selectedElements.length]);

	const handleZoomChange = useCallback((zoom) => {
		setAspectRatio(zoom / 100);
	}, []);

	const handleFullWidthZoom = useCallback(() => {
		let { width } = PageOrientationDimensions[PageOrientations.PORTRAIT];
		if (pages.some((page) => page.orientation === PageOrientations.LANDSCAPE)) {
			width = PageOrientationDimensions[PageOrientations.LANDSCAPE].width;
		}

		setAspectRatio(calculateAspectRatio(editorRef.current.offsetWidth, width));
	}, [pages]);

	const handleFullHeightZoom = useCallback(() => {
		let { height } = PageOrientationDimensions[PageOrientations.LANDSCAPE];

		if (pages.some((page) => page.orientation === PageOrientations.PORTRAIT)) {
			height = PageOrientationDimensions[PageOrientations.PORTRAIT].height;
		}

		setAspectRatio(calculateAspectRatio(editorRef.current.offsetHeight, height));
	}, [pages]);

	// Those refs contain a list of elements and their associated pages to check if an element is being moved between pages by comparing lastPages and futureLastPages values
	const lastPages = useRef({});
	const futureLastPages = useRef(lastPages.current);

	const pagesList = useMemo(() => {
		/**
		 * @function
		 * @name newPage
		 * @description Creates a new page
		 *
		 * @author Florian Fornazaric
		 * @author Yann Hodiesne
		 *
		 * @returns {object}
		 */
		const newPage = (index, orientation) => ({
			index,
			rows: [],
			orientation,
			id: pages[index]?.id || `page-${index}`, // This allows us to temporarly give a page an id before it's given by the dispatcher
		});

		const result = [];

		lastPages.current = futureLastPages.current;
		futureLastPages.current = {};

		let currentPageIndex = 0;

		// We take the margins of the page into account for our calculations
		let currentHeight = 0;

		// We check if the current page includes the header or not. If it does, we add the height of the header to the current height
		if (!header.disabled && header.excludeFromPages !== undefined && !header.excludeFromPages.includes(pages[currentPageIndex]?.id)) {
			currentHeight += header.height;
		} else {
			currentHeight += margins.top;
		}
		// We check if the current page includes the footer or not. If it does, we add the height of the header to the current height
		if (!footer.disabled && footer.excludeFromPages !== undefined && !footer.excludeFromPages.includes(pages[currentPageIndex]?.id)) {
			currentHeight += footer.height;
		} else {
			currentHeight += margins.bottom;
		}

		let pageOrientation = pages[currentPageIndex]?.orientation === PageOrientations.LANDSCAPE ? PageOrientations.LANDSCAPE : PageOrientations.PORTRAIT;

		// If there's elements in the header or the footer, we add a first page to be able to see the elements
		if (header.numberOfElements !== 0 || footer.numberOfElements !== 0) {
			result.push(newPage(currentPageIndex, pageOrientation));
		}

		// We iterate on each element to calculate the cumulative height of the element in the page
		for (let i = header.numberOfElements; i < elements.length - footer.numberOfElements; i += 1) {
			// We get the page orientation
			pageOrientation = pages[currentPageIndex]?.orientation === PageOrientations.LANDSCAPE ? PageOrientations.LANDSCAPE : PageOrientations.PORTRAIT;
			// If the page doesn't exist, we create it with the state page's orientation
			if (result[currentPageIndex] === undefined) {
				result.push(newPage(currentPageIndex, pageOrientation));
			}

			const element = elements[i];
			if (element.children[0]?.type !== PAGE_BREAK.type) {
				currentHeight += element.height;
			}

			// We get the height value of the current page orientation
			const expectedHeight = PageOrientationDimensions[result[currentPageIndex].orientation].height;

			// We check if the cumulative height of the element is greater than the height of the page, if so we create a new page
			// We also check if the element is a page break, if so, we jump to a new page
			if ((currentHeight > expectedHeight && element.children[0]?.type !== PAGE_BREAK.type) || element.children[0]?.type === PAGE_BREAK.type) {
				currentPageIndex += 1;
				currentHeight = element.height;

				// We check if the current page includes the header or not. If it does, we add the height of the header to the current height
				if (!header.disabled && header.excludeFromPages !== undefined && !header.excludeFromPages.includes(pages[currentPageIndex]?.id)) {
					currentHeight += header.height;
				} else {
					currentHeight += margins.top;
				}

				// We check if the current page includes the footer or not. If it does, we add the height of the footer to the current height
				if (!footer.disabled && footer.excludeFromPages !== undefined && !footer.excludeFromPages.includes(pages[currentPageIndex]?.id)) {
					currentHeight += footer.height;
				} else {
					currentHeight += margins.bottom;
				}

				pageOrientation = pages[currentPageIndex]?.orientation === PageOrientations.PORTRAIT ? PageOrientations.PORTRAIT : PageOrientations.LANDSCAPE;
				if (pages[currentPageIndex] === undefined) {
					pageOrientation = result[currentPageIndex - 1].orientation; // If the page didn't exist earlier, we copy its predecessor's orientation.
				}
				result.push(newPage(currentPageIndex, pageOrientation));
			}

			// If the element is a pagebreak we add it to the previous page,
			// so it show up at the end ot the end of the previous one and not in the newly generated one
			if (element.children[0]?.type === PAGE_BREAK.type) {
				result[currentPageIndex - 1].rows.push({ row: element, index: i, height: element.height });
			} else {
				result[currentPageIndex].rows.push({ row: element, index: i, height: element.height });
			}

			futureLastPages.current[element.id] = currentPageIndex;
		}

		return result;
	}, [elements, footer.disabled, footer.excludeFromPages, footer.height, footer.numberOfElements,
		header.disabled, header.excludeFromPages, header.height, header.numberOfElements, pages, margins]);

	const textObjects = useTextObjectListSelector();

	const textObjectsRef = useRef(textObjects);

	// Used to update elemnnts linked to text objects
	useEffect(() => {
		// We don't update it if we're exporting or if the value Text Objects didn't change
		if (_.isEqual(textObjects, textObjectsRef.current) || isExporting) {
			return;
		}

		textObjectsRef.current = textObjects;

		elements.forEach((row) => {
			row.children.forEach((element) => {
				if (element.type === SET_TEXT.type) {
					const textObject = textObjects.find((textObj) => textObj.id === element.textObjectLink?.id);

					if (textObject && textObject.text !== element.content) {
						// We update the textObjectLink and the content, because the contentSetter of the capability isn't triggered before clicking on the element
						dispatch(updateElement(element.id, { textObjectLink: textObject, content: textObject.text }));
					}
				}
			});
		});
	}, [dispatch, elements, isExporting, textObjects]);

	useEffect(() => {
		reduxDispatch(fetchTextObjectList());
	}, [reduxDispatch]);

	// Page dispatching put in a useEffect to avoid cyclic rerenders
	useEffect(() => {
		// there are more pages in the state than in the calculated list page, it means the state's data is not up to date.
		// We remove every extra pages from the state.
		if (pages.length > pagesList.length) {
			for (let i = pages.length - 1; i >= pagesList.length; i -= 1) {
				dispatch(removePage(i));
			}
		}

		for (let i = 0; i < pagesList.length; i++) {
			// We check if every row inside the pages are the same as the ones in the calculated pagesList
			// If not, we update the pages with their new content
			// This also adds new pages if they are missing
			if (pages[i] === undefined) {
				dispatch(appendPage(pagesList[i].orientation));
			}

			if (pages[i]?.rows.length !== pagesList[i].rows.length
					|| !pages[i]?.rows.every((elementIndex, index) => pagesList[i].rows[index]?.index === elementIndex)) {
				dispatch(updatePage(i, { rows: pagesList[i].rows.map((row) => row.index), orientation: pagesList[i].orientation }));
			}
		}
	}, [dispatch, pages, pagesList]);

	drop(editorRef);

	// TODO: fix a11y
	/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

	return (
		<div className="editor-container" onClick={unselectElement}>
			<div className="editor" ref={editorRef}>
				{pages.map(({ rows, id, orientation }, pageIndex) => (
					<Page
						key={id}
						aspectRatio={aspectRatio}
						orientation={orientation}
						rows={rows}
						futureLastPages={futureLastPages.current}
						pageIndex={pageIndex}
						lastPages={lastPages.current}
						pageId={id}
					/>
				))}
				{showDropHere && elements.length === 0 && <DropHere isOver={isShallowOver} canDrop={canDrop} isEmpty />}
			</div>
			<EditorFooter
				zoomChangeCallback={handleZoomChange}
				fullHeightZoomCallback={handleFullHeightZoom}
				fullWidthZoomCallback={handleFullWidthZoom}
			/>
		</div>
	);
};

export default memo(EditorContent);
