import { Base64 } from '@i-xi-dev/base64';
import { createReadableStreamWrapper } from '@mattiasbuelens/web-streams-adapter';
import { ReadableStream as PolyfillReadableStream, TransformStream as PolyfillTransformStream } from 'web-streams-polyfill/ponyfill';

const toPolyfillReadable = createReadableStreamWrapper(PolyfillReadableStream);

/**
 * @function
 * @name getAsyncIterator
 * @description Retrieves or creates an async iterator for the given stream.
 *
 * @author Yann Hodiesne
 *
 * @param {ReadableStream} stream	A readable stream.
 *
 * @returns {AsyncIterableIterator<Uint8Array>}	The async iterator.
 */
export const getAsyncIterator = (stream) => {
	if (
		typeof stream[Symbol.asyncIterator]?.next === 'function'
		&& typeof stream[Symbol.asyncIterator]?.return === 'function'
		&& typeof stream[Symbol.asyncIterator]?.[Symbol.asyncIterator] === 'function'
	) {
		return stream[Symbol.asyncIterator];
	}

	const reader = stream.getReader();

	return {
		[Symbol.asyncIterator]: () => ({
			next: () => reader.read(),
			return: () => reader.releaseLock(),
			[Symbol.asyncIterator]: () => this,
		}),
	};
};

/**
 * @function
 * @name streamToString
 * @description Converts the content of a stream to a string.
 *
 * @author Yann Hodiesne
 *
 * @param {ReadableStream}	stream	The readable stream to read from.
 *
 * @returns {Promise<string>} A promise resolving to the stream content as a string.
 */
export const streamToString = async (stream) => {
	const chunks = [];
	const iterator = getAsyncIterator(stream);

	// eslint-disable-next-line no-restricted-syntax
	for await (const chunk of iterator) {
		chunks.push(Buffer.from(chunk));
	}

	return Buffer.concat(chunks).toString('utf8');
};

/**
 * @function
 * @name streamToBase64
 * @description Converts the content of a stream to a base64 string.
 *
 * @author Yann Hodiesne
 *
 * @param {ReadableStream} stream	The readable stream to read from.
 *
 * @returns {Promise<string>} A promise resolving to the stream content converted to a base64 string.
 */
export const streamToBase64 = async (stream) => {
	let readable = stream;

	if (!stream.pipeThrough) {
		if (!window.TransformStream) {
			window.TransformStream = PolyfillTransformStream;
		}

		readable = toPolyfillReadable(stream);
	}

	return streamToString(readable.pipeThrough(new Base64.EncoderStream()));
};
