/**
 * @name TimesheetListPage
 * @description A list of all timesheet accessible from the timesheets app.
 *
 * From this page, the user is able to view, interact and search for timesheets.
 *
 * @author Romaric Barthe
 */
const TimesheetListPage = () => null;

export default TimesheetListPage;
