import { Canon } from 'components/shared/Typography';

/**
 * @name AdminSettingsPage
 * @description The super-admin settings page of the application.
 *
 * @author Yann Hodiesne
 */
const AdminSettingsPage = () => (
	<Canon tag="h1">Admin settings page.</Canon>
);

export default AdminSettingsPage;
