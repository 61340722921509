import { useMemo } from 'react';
import PropTypes from 'prop-types';

/**
 * @name Label
 * @description A component used to displayed the label of an input
 *
 * @author Timothée Simon-Franza
 *
 * @param {node} children			The text to display in the input's label.
 * @param {bool} [disabled=false]	Whether the label's linked input is disabled.
 * @param {node} inputId			The id of the element this label's linked to.
 * @param {bool} isInvalid			Whether the linked input is invalid or not.
 */
const Label = ({ children, disabled, inputId, isInvalid }) => {
	const className = useMemo(() => {
		if (disabled) {
			return 'disabled';
		}

		return isInvalid ? 'invalid' : '';
	}, [disabled, isInvalid]);

	return (
		<label
			htmlFor={inputId}
			className={className}
		>
			{children}
		</label>
	);
};

Label.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.node,
	]).isRequired,
	disabled: PropTypes.bool,
	inputId: PropTypes.node.isRequired,
	isInvalid: PropTypes.bool.isRequired,
};

Label.defaultProps = {
	disabled: false,
};

export default Label;
