import { useContext, useEffect } from 'react';
import { AlertCircle, Image as ImageIcon, Loader } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Image as PdfImage, StyleSheet, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import useMedia from 'components/mediaManager/useMedia';

import EditorContext from '../../EditorContext';
import { updateElement } from '../../reducer/actions';

const css = {
	padding: '0px',
	minWidth: '2em',
	minHeight: '1.5em',
	maxWidth: '100%',
	overflow: 'hidden',
};

const imageCss = {
	height: '100%',
	width: '100%',
};

const iconContainerCss = {
	height: '100%',
	width: '100%',
	display: 'grid',
	gridTemplateRows: '1fr 1fr',
	justifyItems: 'center',
	border: '1px solid #9EA9BE',
	borderRadius: '0.25em',
};

const iconCss = {
	alignSelf: 'end',
};

const iconLabelCss = {
	alignSelf: 'start',
	textAlign: 'center',
};

const scaling = Object.freeze({
	EXPAND: 'cover',
	FIT: 'contain',
	STRETCH: 'fill',
});

/**
 * @name Image
 * @description A static image PDF element
 *
 * @author Yann Hodiesne
 *
 * @param {number}	[aspectRatio = 1]	The aspect ratio of the PDF editor
 * @param {object}	style				The style of the component
 * @param {object}	element				The element to edit
 * @param {boolean}	resizable			True if the element is being resized
 */
const Image = ({ aspectRatio, style, element, resizable, editing, ...otherProps }) => {
	const { t } = useTranslation();
	const { dispatch, isExporting } = useContext(EditorContext);
	const file = useMedia(element.file);

	const displayLoading = file === null && element.file;
	const displayBlank = !file?.error && !file?.image?.presignedUrl;
	const displayError = file?.error;

	const url = file?.image?.presignedUrl ?? '';

	// Store the presigned URL for the PDF element to use on export
	useEffect(() => {
		if (isExporting && element.url !== url) {
			dispatch(updateElement(element.id, { url }));
		}
	}, [dispatch, element.id, element.url, isExporting, url]);

	let content;

	if (displayLoading) {
		content = (
			<div style={iconContainerCss}>
				<Loader style={iconCss} />
				<p style={iconLabelCss}>
					{t('template.pdf.editor.components.image.loading')}
				</p>
			</div>
		);
	} else if (displayBlank) {
		content = (
			<div style={iconContainerCss}>
				<ImageIcon style={iconCss} />
				<p style={iconLabelCss}>
					{t('template.pdf.editor.components.image.blank')}
				</p>
			</div>
		);
	} else if (displayError) {
		content = (
			<div style={iconContainerCss}>
				<AlertCircle style={iconCss} />
				<p style={iconLabelCss}>
					{t('template.pdf.editor.components.image.error')}
				</p>
			</div>
		);
	} else {
		content = (
			<img
				{...otherProps}
				style={{
					...imageCss,
					objectFit: scaling[element.scaling],
				}}
				crossOrigin="anonymous"
				alt=""
				src={url}
			/>
		);
	}

	return (
		<div
			style={{
				...css,
				...style,
				resize: resizable ? 'both' : 'none',
			}}
		>
			{content}
		</div>
	);
};

Image.propTypes = {
	aspectRatio: PropTypes.number,
	style: PropTypes.object,
	element: PropTypes.object,
	editing: PropTypes.bool,
	resizable: PropTypes.bool,
};

Image.defaultProps = {
	aspectRatio: 1,
	style: undefined,
	element: undefined,
	editing: false,
	resizable: false,
};

const styles = StyleSheet.create(css);
const imageStyles = StyleSheet.create(imageCss);

// eslint-disable-next-line require-jsdoc
const PdfElement = ({ style, element, ...otherProps }) => (
	<View style={[styles, style]}>
		{element.url ? (
			<PdfImage
				{...otherProps}
				style={[imageStyles, { objectFit: scaling[element.scaling] }]}
				src={element.url}
				cache={false}
			/>
		) : (
			<View
				{...otherProps}
				style={[imageStyles]}
			/>
		)}
	</View>
);

PdfElement.propTypes = {
	style: PropTypes.object,
	element: PropTypes.object,
};

PdfElement.defaultProps = {
	style: undefined,
	element: undefined,
};

Image.PdfElement = PdfElement;

Image.PdfElement.displayName = 'Image';

export default Image;
