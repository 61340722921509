const templateEnums = {
	export: {
		dataTypes: {
			PARTNERS: 'partners',
			CONTACTS: 'contacts',
			EXPENSES: 'expenses',
		},
		formats: {
			CSV: 'csv',
			XLSX: 'xlsx',
		},
		separators: {
			COMMA: 'comma',
			SEMICOLON: 'semicolon',
			TAB: 'tabulation',
		},
	},
};

export default templateEnums;
