import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatInvoiceName } from 'lib/invoices/formatInvoiceData';
import { formatProjectClientData } from 'lib/projects/formatProjectData';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import PropTypes from 'prop-types';

/**
 * @name InvoiceTitle
 * @description A component to display the invoice's titles.
 *
 * @author Romaric Barthe
 *
 * @param {object}	invoice			The invoice.
 */
const InvoiceTitle = ({ invoice, numberFormat }) => {
	const { t } = useTranslation();

	const avoir = useMemo(() => invoice.reversedInvoice && formatInvoiceName(invoice.reversedInvoice, numberFormat, t), [invoice.reversedInvoice, numberFormat, t]);

	return (
		<div className="data-header">
			<div className="identity">
				<h3>{formatInvoiceName(invoice, numberFormat, t)}</h3>
				<h4>{invoice.project?.name}</h4>
				<h5>{`${t('invoice.headers.addressed_to')} ${formatProjectClientData(invoice.project).invoicing_name}`}</h5>
				<p>{invoice.description}</p>
			</div>
			<div className="metadata">
				{avoir && (<h5>{`${t('invoice.fields.reversedInvoice')}: ${avoir}`}</h5>)}
				<p>{`${t('invoice.fields.creation_date')}: ${formatCreatedAt(invoice)}`}</p>
				<p>{`${t('invoice.fields.creation_name')}: ${formatCreatedBy(invoice)}`}</p>
				<p>{`${t('invoice.fields.update_date')}: ${formatUpdatedAt(invoice)}`}</p>
				<p>{`${t('invoice.fields.update_name')}: ${formatUpdatedBy(invoice)}`}</p>
			</div>
		</div>
	);
};

InvoiceTitle.propTypes = {
	invoice: PropTypes.object.isRequired,
	numberFormat: PropTypes.object,
};

InvoiceTitle.defaultProps = {
	numberFormat: undefined,
};

export default InvoiceTitle;
