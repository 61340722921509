import { useCallback } from 'react';
import { Globe } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { isValidHttpUrl } from 'lib/shared/http';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the PartnerListPage table. (mail, phone, delete and edit partner)
 *
 * @author Audrey Clerc
 *
 * @param {object}		row						The object containing the data to interact with.
 * @param {string}	row.original.website	The website of the partner.
 */
const ActionsCell = ({ row: { original: { website } } }) => {
	const [websiteButtonRef, websiteTooltipProps] = useTooltip();
	const { t } = useTranslation();

	/**
	 * @function
	 * @name onWebsiteButtonClick
	 * @description Method triggered as a result to an onClick event from the website button.
	 *
	 * @author Audrey Clerc
	 */
	const onWebsiteButtonClick = useCallback(() => {
		const isUrlWebsiteValid = isValidHttpUrl(website);
		window.open(isUrlWebsiteValid ? website : 'http://'.concat(website), '_blank', 'noreferrer');
	}, [website]);

	return (
		<>
			<Button className="icon-only" onClick={onWebsiteButtonClick} disabled={website === ''} ref={websiteButtonRef}>
				<Globe />
			</Button>
			<Tooltip disabled={website === ''} {...websiteTooltipProps}>{t('actions.website')}</Tooltip>
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			website: PropTypes.string,
		}).isRequired,
	}).isRequired,
};

export default ActionsCell;
