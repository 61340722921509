import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentOfferSelector
 * @description A selector to retrieve the current offer from the offer redux state
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} id The identifier of the current offer to wait for
 *
 * @returns {object}
 */
const useCurrentOfferSelector = (id) => {
	const offer = useSelector((state) => (state.offers?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (offer?.id === id ? offer : null), [id, offer]);

	return result;
};

/**
 * @function
 * @name useOfferLoadingSelector
 * @description A selector to retrieve the loading state from the offer redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {boolean}
 */
const useOfferLoadingSelector = () => {
	const loading = useSelector((state) => state.offers?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useOfferListSelector
 * @description A selector to retrieve the offer list from the sector redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {Array}
 */
const useOfferListSelector = () => {
	const sectors = useSelector((state) => (state.offers?.items ?? []), shallowEqual);

	return sectors;
};

/**
 * @function
 * @name useOfferTotalCountSelector
 * @description A selector to retrieve the totalCount value from the sector redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {number|undefined}
 */
const useOfferTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.offers?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useOfferActiveListSelector
 * @description A selector to retrieve all non-archived offers from the offer redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {Array}
 */
const useOfferActiveListSelector = () => {
	const activeRelationTypes = useSelector((state) => (state.offers?.items ?? []).filter((offer) => !offer.archived), shallowEqual);

	return activeRelationTypes;
};

/**
 * @function
 * @name useOfferActiveListWithCurrencySelector
 * @description A selector to retrieve all non-archived offers from the offer redux state according to a given currency.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useOfferActiveListWithCurrencySelector = (currencyId) => {
	let activeOffers = useOfferActiveListSelector();

	activeOffers = useMemo(() => {
		if (currencyId) {
			return activeOffers.filter((offer) => offer.currency?.id === currencyId);
		}

		return activeOffers;
	}, [activeOffers, currencyId]);

	return activeOffers;
};

export {
	useCurrentOfferSelector,
	useOfferActiveListSelector,
	useOfferActiveListWithCurrencySelector,
	useOfferListSelector,
	useOfferLoadingSelector,
	useOfferTotalCountSelector,
};
