import { toast } from 'react-toastify';
import * as TemplatesApi from 'api/templatesApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Templates redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific template
	FETCH_TEMPLATE_REQUEST: '@TEMPLATES/FETCH_REQUEST',
	FETCH_TEMPLATE_SUCCESS: '@TEMPLATES/FETCH_SUCCESS',
	FETCH_TEMPLATE_FAILURE: '@TEMPLATES/FETCH_FAILURE',

	// Fetch a list of templates
	FETCH_TEMPLATE_LIST_REQUEST: '@TEMPLATES/FETCH_LIST_REQUEST',
	FETCH_TEMPLATE_LIST_SUCCESS: '@TEMPLATES/FETCH_LIST_SUCCESS',
	FETCH_TEMPLATE_LIST_FAILURE: '@TEMPLATES/FETCH_LIST_FAILURE',

	// Add a new template to the database
	ADD_TEMPLATE_REQUEST: '@TEMPLATES/ADD_REQUEST',
	ADD_TEMPLATE_SUCCESS: '@TEMPLATES/ADD_SUCCESS',
	ADD_TEMPLATE_FAILURE: '@TEMPLATES/ADD_FAILURE',

	// Update an existing template from the database
	UPDATE_TEMPLATE_REQUEST: '@TEMPLATES/UPDATE_REQUEST',
	UPDATE_TEMPLATE_SUCCESS: '@TEMPLATES/UPDATE_SUCCESS',
	UPDATE_TEMPLATE_FAILURE: '@TEMPLATES/UPDATE_FAILURE',

	// Remove an existing template from the database
	REMOVE_TEMPLATE_REQUEST: '@TEMPLATES/REMOVE_REQUEST',
	REMOVE_TEMPLATE_SUCCESS: '@TEMPLATES/REMOVE_SUCCESS',
	REMOVE_TEMPLATE_FAILURE: '@TEMPLATES/REMOVE_FAILURE',

	// Archive an existing template from the database
	ARCHIVE_TEMPLATE_REQUEST: '@TEMPLATES/ARCHIVE_REQUEST',
	ARCHIVE_TEMPLATE_SUCCESS: '@TEMPLATES/ARCHIVE_SUCCESS',
	ARCHIVE_TEMPLATE_FAILURE: '@TEMPLATES/ARCHIVE_FAILURE',

	// Restore an existing template from the database
	RESTORE_TEMPLATE_REQUEST: '@TEMPLATES/RESTORE_REQUEST',
	RESTORE_TEMPLATE_SUCCESS: '@TEMPLATES/RESTORE_SUCCESS',
	RESTORE_TEMPLATE_FAILURE: '@TEMPLATES/RESTORE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Template list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTemplateListRequest
 * @description Action triggered anytime a template list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTemplateListRequest = () => ({ type: ActionTypes.FETCH_TEMPLATE_LIST_REQUEST });

/**
 * @function
 * @name fetchTemplateListSuccess
 * @description Action triggered as a result to a successful template list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} templates	The list of retrieved templates.
 * @param {number} totalCount	The total amount of templates available in the database for the current user.
 *
 * @returns {object}
 */
const fetchTemplateListSuccess = ({ templates, totalCount }) => ({
	type: ActionTypes.FETCH_TEMPLATE_LIST_SUCCESS,
	payload: { templates, totalCount },
});

/**
 * @function
 * @name fetchTemplateListFailure
 * @description Action triggered as a result to a failed template list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTemplateListFailure = (error) => ({
	type: ActionTypes.FETCH_TEMPLATE_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific template fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTemplateRequest
 * @description Action triggered anytime a template fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTemplateRequest = () => ({ type: ActionTypes.FETCH_TEMPLATE_REQUEST });

/**
 * @function
 * @name fetchTemplateSuccess
 * @description Action triggered as a result to a successful template fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} template	The retrieved template.
 *
 * @returns {object}
 */
const fetchTemplateSuccess = ({ template }) => ({
	type: ActionTypes.FETCH_TEMPLATE_SUCCESS,
	payload: { template },
});

/**
 * @function
 * @name fetchTemplateFailure
 * @description Action triggered as a result to a failed template fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTemplateFailure = (error) => ({
	type: ActionTypes.FETCH_TEMPLATE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Template creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addTemplateRequest
 * @description Action triggered anytime a template creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addTemplateRequest = () => ({ type: ActionTypes.ADD_TEMPLATE_REQUEST });

/**
 * @function
 * @name addTemplateSuccess
 * @description Action triggered as a result to a successful template creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} template	The created template object.
 *
 * @returns {object}
 */
const addTemplateSuccess = ({ template }) => ({
	type: ActionTypes.ADD_TEMPLATE_SUCCESS,
	payload: { template },
});

/**
 * @function
 * @name addTemplateFailure
 * @description Action triggered as a result to a failed template creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addTemplateFailure = (error) => ({
	type: ActionTypes.ADD_TEMPLATE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Template update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateTemplateRequest
 * @description Action triggered anytime a template update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateTemplateRequest = () => ({ type: ActionTypes.UPDATE_TEMPLATE_REQUEST });

/**
 * @function
 * @name updateTemplateSuccess
 * @description Action triggered as a result to a successful template update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} template	The updated template object.
 *
 * @returns {object}
 */
const updateTemplateSuccess = ({ template }) => ({
	type: ActionTypes.UPDATE_TEMPLATE_SUCCESS,
	payload: { template },
});

/**
 * @function
 * @name updateTemplateFailure
 * @description Action triggered as a result to a failed template update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateTemplateFailure = (error) => ({
	type: ActionTypes.UPDATE_TEMPLATE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Template removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeTemplateRequest
 * @description Action triggered anytime a template deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeTemplateRequest = () => ({ type: ActionTypes.REMOVE_TEMPLATE_REQUEST });

/**
 * @function
 * @name removeTemplateSuccess
 * @description Action triggered as a result to a successful template deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param { string } deletedTemplateId	The removed template object id.
 *
 * @returns {object}
 */
const removeTemplateSuccess = ({ deletedTemplateId }) => ({
	type: ActionTypes.REMOVE_TEMPLATE_SUCCESS,
	payload: { deletedTemplateId },
});

/**
 * @function
 * @name removeTemplateFailure
 * @description Action triggered as a result to a failed template deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeTemplateFailure = (error) => ({
	type: ActionTypes.REMOVE_TEMPLATE_FAILURE,
	payload: { error },
});
// //////////////////////////////////////////////////////// //
// //////////////// Template archiving actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveTemplateRequest
 * @description Action triggered anytime an template archiving call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveTemplateRequest = () => ({ type: ActionTypes.ARCHIVE_TEMPLATE_REQUEST });

/**
 * @function
 * @name archiveTemplateSuccess
 * @description	Action triggered as a result to a successful template archiving API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} template		The updated template object.
 *
 * @returns {object}
 */
const archiveTemplateSuccess = ({ template }) => ({
	type: ActionTypes.ARCHIVE_TEMPLATE_SUCCESS,
	payload: { template },
});

/**
 * @function
 * @name archiveTemplateFailure
 * @description Action triggered as a result to a failed template archiving API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveTemplateFailure = (error) => ({
	type: ActionTypes.ARCHIVE_TEMPLATE_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// /////////////// Template restoring actions /////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreTemplateRequest
 * @description Action triggered anytime an template restoring call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreTemplateRequest = () => ({ type: ActionTypes.RESTORE_TEMPLATE_REQUEST });

/**
 * @function
 * @name restoreTemplateSuccess
 * @description	Action triggered as a result to a successful template restoring API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} template		The updated template object.
 *
 * @returns {object}
 */
const restoreTemplateSuccess = ({ template }) => ({
	type: ActionTypes.RESTORE_TEMPLATE_SUCCESS,
	payload: { template },
});

/**
 * @function
 * @name restoreTemplateFailure
 * @description Action triggered as a result to a failed template restoring API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreTemplateFailure = (error) => ({
	type: ActionTypes.RESTORE_TEMPLATE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTemplateList
 * @description Method used to update the template list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchTemplateList = (params) => (dispatch) => {
	dispatch(fetchTemplateListRequest());

	return TemplatesApi.fetchTemplates(params)
		.then(({ templates, totalCount }) => dispatch(fetchTemplateListSuccess({ templates, totalCount })))
		.catch((error) => dispatch(fetchTemplateListFailure(error)));
};

/**
 * @function
 * @name fetchTemplate
 * @description Method used to fetch the latest version of a specific template.
 *
 * @author Romaric Barthe
 *
 * @param {string} templateId	The id of the template we want to retrieve.
 */
export const fetchTemplate = (templateId) => (dispatch) => {
	dispatch(fetchTemplateRequest());

	return TemplatesApi.fetchTemplateById(templateId)
		.then(({ template }) => dispatch(fetchTemplateSuccess({ template })))
		.catch((error) => dispatch(fetchTemplateFailure(error)));
};

/**
 * @function
 * @name addTemplate
 * @description Method used to add a new template instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} templateData	The data to create the new template from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addTemplate = (templateData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addTemplateRequest());

	return TemplatesApi.createTemplate(templateData)
		.then(({ template }) => {
			toast.success(i18next.t('template.creation.toasts.success', { name: template.name }));
			dispatch(addTemplateSuccess({ template }));
			redirectOnSuccess(onSuccessRoute);

			return template;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('template.creation.toasts.conflict'));
				dispatch(addTemplateFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('template.creation.toasts.error'));
				dispatch(addTemplateFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateTemplate
 * @description Method used to update an existing template instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} templateData		The object to update the template with.
 * @param {string} templateId		The id of the template we want to update.
 * @param {string} [silentSuccess]	The boolean determining if a toast is shown when a save is successful
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateTemplate = (templateData, templateId, silentSuccess = false, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateTemplateRequest());

	return TemplatesApi.updateTemplate(templateData, templateId)
		.then(({ template }) => {
			dispatch(updateTemplateSuccess({ template }));
			if (!silentSuccess) {
				toast.success(i18next.t('template.edition.toasts.success', { name: template.name }));
			}
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('template.edition.toasts.conflict'));
				dispatch(updateTemplateFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('template.edition.toasts.error'));
				dispatch(updateTemplateFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeTemplate
 * @description Method used to remove an existing template instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} template					The template we want to remove from the database.
 * @param {string} template.id				The id of the template we want to remove from the database.
 * @param {string} template.name			The name of the template we want to remove from the database.
 */
export const removeTemplate = ({ id, name }) => (dispatch) => {
	dispatch(removeTemplateRequest());

	return TemplatesApi.deleteTemplate(id)
		.then(({ deletedTemplateId }) => {
			dispatch(removeTemplateSuccess({ deletedTemplateId }));
			toast.success(i18next.t('template.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeTemplateFailure(error));
			toast.error(i18next.t('template.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveTemplate
 * @description	Method used to archive an existing template instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} template					The template we want to archive.
 * @param {string} template.id				The id of the template we want to archive.
 * @param {string} template.name			The name of the template we want to archive.
 */
export const archiveTemplate = ({ id, name }) => (dispatch) => {
	dispatch(archiveTemplateRequest());

	return TemplatesApi.updateTemplate({ archived: true }, id)
		.then(({ template }) => {
			dispatch(archiveTemplateSuccess({ template }));
			toast.success(i18next.t('template.archiving.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(archiveTemplateFailure(error));
			toast.error(i18next.t('template.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreTemplate
 * @description	Method used to restore an existing template instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} template					The template we want to restore.
 * @param {string} template.id				The id of the template we want to restore.
 * @param {string} template.name			The name of the template we want to restore.
 */
export const restoreTemplate = ({ id, name }) => (dispatch) => {
	dispatch(restoreTemplateRequest());

	return TemplatesApi.updateTemplate({ archived: false }, id)
		.then(({ template }) => {
			dispatch(restoreTemplateSuccess({ template }));
			toast.success(i18next.t('template.restoring.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(restoreTemplateFailure(error));
			toast.error(i18next.t('template.restoring.toasts.error'));
		});
};
