import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { HiddenInput, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name ContactIdentifier
 * @description A form used to group the identifier fields.
 *
 * @author Romaric Barthe
 */
const ContactIdentifier = ({
	inSuperAdmin,
	isLoading,
	partners,
	positionCreationModal,
	positions,
	relationTypeCreationModal,
	relationTypes,
	targetPartner,
	titleCreationModal,
	titles,
	setTitles,
}) => {
	const { t } = useTranslation();

	return (
		<>
			{!inSuperAdmin && (
				<Select
					allowNull
					label={t('contact.edition.inputs.title.label')}
					name="title"
					onChange={setTitles}
					options={titles}
					labelKey="name"
					valueKey="id"
					isLoading={isLoading}
					{...titleCreationModal}
				/>
			)}
			<TextInput
				label={t('contact.edition.inputs.firstname.label')}
				name="firstName"
				onChange={setTitles}
				placeholder={t('contact.edition.inputs.firstname.placeholder')}
				rules={{
					required: Validators.isRequired(t('contact.edition.inputs.firstname.validation_errors.required')),
				}}
				type="text"
			/>
			<TextInput
				label={t('contact.edition.inputs.lastname.label')}
				name="lastName"
				onChange={setTitles}
				placeholder={t('contact.edition.inputs.lastname.placeholder')}
				rules={{
					required: Validators.isRequired(t('contact.edition.inputs.lastname.validation_errors.required')),
				}}
				type="text"
			/>

			{!inSuperAdmin && !targetPartner && (
				<>
					<Select
						allowNull
						label={t('contact.edition.inputs.position.label')}
						name="position"
						onChange={setTitles}
						options={positions}
						labelKey="name"
						valueKey="id"
						isLoading={isLoading}
						{...positionCreationModal}
					/>
					<Select
						label={t('contact.edition.inputs.partners.label')}
						name="partners"
						options={partners}
						labelKey="name"
						valueKey="id"
						isMulti
						isLoading={isLoading}
					/>
					<Select
						label={t('contact.edition.inputs.relation_type.label')}
						name="relationTypes"
						options={relationTypes}
						labelKey="name"
						valueKey="id"
						isMulti
						isLoading={isLoading}
						{...relationTypeCreationModal}
					/>
				</>
			)}

			{targetPartner && (
				<>
					<Select
						allowNull
						label={t('contact.edition.inputs.position.label')}
						name="position"
						onChange={setTitles}
						options={positions}
						labelKey="name"
						valueKey="id"
						isLoading={isLoading}
						{...positionCreationModal}
					/>
					<HiddenInput
						name="targetPartner"
						value={targetPartner?.id}
					/>
				</>
			)}
		</>
	);
};

ContactIdentifier.propTypes = {
	inSuperAdmin: PropTypes.bool,
	isLoading: PropTypes.bool,
	partners: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		})
	),
	positionCreationModal: PropTypes.object,
	positions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		})
	),
	relationTypeCreationModal: PropTypes.object,
	relationTypes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		})
	),
	targetPartner: PropTypes.shape({
		id: PropTypes.string,
	}),
	titleCreationModal: PropTypes.object,
	titles: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		})
	),
	setTitles: PropTypes.func,
};

ContactIdentifier.defaultProps = {
	inSuperAdmin: true,
	isLoading: true,
	partners: [],
	positionCreationModal: {},
	positions: [],
	relationTypeCreationModal: {},
	relationTypes: [],
	targetPartner: undefined,
	titleCreationModal: {},
	titles: [],
	setTitles: () => {},
};

export default ContactIdentifier;
