import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { ColorPicker, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';
import { TagColorPreviewComponent } from 'components/shared/previewComponents';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name StatusEditionForm
 * @description A form used to edit an existing status' information.
 *
 * @author Romaric Barthe
 *
 * @param {object}		status			The status object to update information from.
 * @param {function}	onSubmit		The method to trigger upon form submission.
 */
const StatusEditionForm = ({ status, onSubmit }) => {
	const { t } = useTranslation();

	const canEditStatus = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING) && !status?.archived;

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm onSubmit={onSubmit} defaultValues={status} disabled={!canEditStatus} {...formProps}>
			<TextInput
				name="name"
				label={t('status.edition.inputs.name.label')}
				type="text"
				placeholder={t('status.edition.inputs.name.placeholder')}
				rules={{
					required: Validators.isRequired(t('status.edition.inputs.name.validation_errors.required')),
				}}
			/>
			<TextArea
				name="description"
				label={t('status.edition.inputs.description.label')}
			/>
			<ColorPicker
				name="defaultColor"
				label={t('status.creation.inputs.defaultColor.label')}
				type="circle"
				previewComponent={TagColorPreviewComponent}
			/>
			<Button className="primary" type="submit" {...buttonProps}>
				{canEditStatus ? t('status.edition.action') : t('status.edition.close')}
			</Button>
		</DynamicForm>
	);
};

StatusEditionForm.propTypes = {
	status: PropTypes.shape({
		archived: PropTypes.bool,
		defaultColor: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default StatusEditionForm;
