import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentPartnerSelector
 * @description A selector to retrieve the current partner from the partner redux state
 *
 * @author Audrey Clerc
 * @author Yann Hodiesne
 *
 * @param {string} id The identifier of the current partner to wait for
 *
 * @returns {object}
 */
const useCurrentPartnerSelector = (id) => {
	const partner = useSelector((state) => (state.partners?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (partner?.id === id ? partner : null), [id, partner]);

	return result;
};

/**
 * @function
 * @name usePartnersLoadingSelector
 * @description A selector to retrieve the loading state from the partner redux state.
 *
 * @author Audrey Clerc
 * @author Roland Margelidon
 *
 * @returns {boolean}
 */
const usePartnersLoadingSelector = () => {
	const loading = useSelector((state) => state.partners?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name usePartnerListSelector
 * @description A selector to retrieve the partner list from the partner redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {Array}
 */
const usePartnerListSelector = () => {
	const partners = useSelector((state) => (state.partners?.items ?? []), shallowEqual);

	return partners;
};

/**
 * @function
 * @name usePartnerTotalCountSelector
 * @description A selector to retrieve the totalCount value from the partner redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {number|undefined}
 */
const usePartnerTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.partners?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useAllForPartnerSelector
 * @description A selector to retrieve all relevant list for partner's forms from the partner redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAllForPartnerSelector = () => {
	const allForForm = useSelector((state) => (state.partners?.allForForm ?? []), shallowEqual);

	return allForForm;
};

export {
	useAllForPartnerSelector,
	useCurrentPartnerSelector,
	usePartnerListSelector,
	usePartnersLoadingSelector,
	usePartnerTotalCountSelector,
};
