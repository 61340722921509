import { useMemo } from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import RowRenderer from 'components/templates/pdf/rendering/RowRenderer';

/**
 * @name HeaderRenderer
 * @description Renders the header of the PDF document.
 *
 * @author Florian Fornazaric
 *
 * @param {array}	elements		All the elements of the template
 * @param {array}	header			The header and its contained elements
 * @param {object}	rendererGlobals	The global renderer variables
 * @param {object}	margins			The margins of the document
 * @param {number}	pageIndex		The index of the page
 */
const HeaderRenderer = ({ elements, header, margins, rendererGlobals, pageIndex }) => {
	const rows = useMemo(() => {
		const resultRows = [];
		for (let i = 0; i < header.numberOfElements; i++) {
			resultRows.push(elements[i]);
		}

		return resultRows;
	}, [elements, header.numberOfElements]);

	return (
		<View style={{ height: header.height - margins.top, overflow: 'hidden', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
			{rows.map((row) => (<RowRenderer key={row.id} rendererGlobals={rendererGlobals} pageIndex={pageIndex} {...row} />))}
		</View>
	);
};

HeaderRenderer.propTypes = {
	elements: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
	})).isRequired,
	header: PropTypes.shape({
		height: PropTypes.number.isRequired,
		numberOfElements: PropTypes.number.isRequired,
		excludeFromPages: PropTypes.arrayOf(PropTypes.string),
	}).isRequired,
	rendererGlobals: PropTypes.shape({
		titleNumbers: PropTypes.object,
	}).isRequired,
	margins: PropTypes.shape({
		top: PropTypes.number.isRequired,
		bottom: PropTypes.number.isRequired,
		left: PropTypes.number.isRequired,
		right: PropTypes.number.isRequired,
	}).isRequired,
	pageIndex: PropTypes.number.isRequired,
};

export default HeaderRenderer;
