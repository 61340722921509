import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchTargetPartners
 * @description Retrieves a list of target partners from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchTargetPartners = (params) => post('/api/target-partners', params);

/**
 * @function
 * @name fetchTargetPartnerById
 * @description Retrieves an existing target partner from the API, using the targetPartnerId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} targetPartnerId	The id of the target partner we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTargetPartnerById = (targetPartnerId) => get(`/api/target-partners/${targetPartnerId}`);

/**
 * @function
 * @name createTargetPartner
 * @description Creates a new target partner into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetPartnerData	The data to create the target partner from.
 *
 * @returns {Promise}
 */
export const createTargetPartner = (targetPartnerData) => post('/api/target-partners/create', targetPartnerData);

/**
 * @function
 * @name updateTargetPartner
 * @description Updates an existing target partner from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetPartnerData 	The data to update the target partner with.
 * @param {string} targetPartnerId   	The id to identify the target partner with.
 *
 * @returns {Promise}
 */
export const updateTargetPartner = (targetPartnerData, targetPartnerId) => put(`/api/target-partners/${targetPartnerId}`, targetPartnerData);

/**
 * @function
 * @name deleteTargetPartner
 * @description Removes an existing target partner from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} targetPartnerId	The id of the target partner we want to remove.
 *
 * @returns {Promise}
 */
export const deleteTargetPartner = (targetPartnerId) => del(`/api/target-partners/${targetPartnerId}`);

/**
 * @function
 * @name exportTargetPartners
 * @description Export existing target partners from the database.
 *
 * @author Audrey Clerc
 *
 * @param {Object} data		The data to export the target partners.
 *
 * @returns {Promise}
 */
export const exportTargetPartners = (targetId, data) => post(`/api/target/${targetId}/target-partners/export`, data, 'attachment');
