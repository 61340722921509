import { useCallback } from 'react';
import { ExpenseCreationTypes } from 'constants/expenseEnums';
import _ from 'lodash';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ExpensePreCreationForm } from 'components/expenses';

/**
 * @name ExpensePreCreationPage
 * @description A page used to create a new expense.
 *
 * @author Romaric Barthe
 *
 */
const ExpensePreCreationPage = () => {
	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		const { creationType } = formData;
		const sourceExpenseId = (formData.sourceExpenseId && !_.isEmpty(formData.sourceExpenseId)) ? formData.sourceExpenseId : undefined;
		const sourceQuotationId = (formData.sourceQuotationId && !_.isEmpty(formData.sourceQuotationId)) ? formData.sourceQuotationId : undefined;

		if ((creationType === ExpenseCreationTypes.FROM_EXPENSE || creationType === ExpenseCreationTypes.REVERT) && !sourceExpenseId) {
			return;
		}

		history.push({
			pathname: routes.humanResources.expenses.expenseCreation,
			state: { creationType, sourceExpenseId, sourceQuotationId },
		});
	}, []);

	return (
		<ExpensePreCreationForm onSubmit={handleFormSubmit} />
	);
};

export default ExpensePreCreationPage;
