import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useMediaManagerLoadingSelector
 * @description A selector to retrieve the loading state from the media manager redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {boolean}
 */
const useMediaManagerLoadingSelector = () => {
	const loading = useSelector((state) => state.mediaManager?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useMediasSelector
 * @description A selector to retrieve the medias from the media manager redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {object} { files, folders }
 */
const useMediasSelector = () => {
	const files = useSelector((state) => (state.mediaManager?.files ?? []), shallowEqual);
	const folders = useSelector((state) => (state.mediaManager?.folders ?? []), shallowEqual);

	const result = useMemo(() => ({ files, folders }), [files, folders]);

	return result;
};

/**
 * @function
 * @name useMediasTotalCountSelector
 * @description A selector to retrieve the total count of medias from the media manager redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {number}
 */
const useMediasTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.mediaManager?.files?.length), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useMediaFileSelector
 * @description A selector to retrieve a single file from the media manager redux state.
 *
 * @author Yann Hodiesne
 *
 * @param {string} id	The ID of the file to retrieve from the state.
 *
 * @returns {object}
 */
const useMediaFileSelector = (id) => {
	const file = useSelector((state) => (state.mediaManager?.fileCache ?? {})[id], shallowEqual);

	return file;
};

export {
	useMediaFileSelector,
	useMediaManagerLoadingSelector,
	useMediasSelector,
	useMediasTotalCountSelector,
};
