import { useCallback, useEffect, useMemo, useState } from 'react';
import { Copy, Download, Edit2, ExternalLink, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Environments } from 'constants/environmentEnums';
import { QuotationCreationTypes } from 'constants/quotationEnums';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { downloadS3File } from 'lib/shared/fileHelper';
import PropTypes from 'prop-types';
import { exportQuotations, removeQuotation } from 'redux/actions/quotations/quotations';
import { fetchTemplateList } from 'redux/actions/templateExports';
import { useTemplateExportListSelector, useTemplateExportLoadingSelector } from 'redux/selectors/templateExports';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name QuotationActionBar
 * @description A component to display the quotation's titles.
 *
 * @author Romaric Barthe
 *
 * @param {object}	quotation			The quotation.
 */
const QuotationActionBar = ({ quotation }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [downloadButtonRef, downloadTooltipProps] = useTooltip();
	const [extractButtonRef, extractTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	const [copyButtonRef, copyTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();

	useEffect(() => {
		dispatch(fetchTemplateList({
			rowsPerPage: 0,
			filters: {
				conditions: [
					{ column: 'linkedEntities', criterion: 'contains', value: Environments.QUOTATION.toUpperCase() },
					{ column: 'linkedEntities', criterion: 'contains', value: quotation.id },
				],
			},
		}));
	}, [dispatch, quotation.id]);

	const templateExports = useTemplateExportListSelector();
	const isLoading = useTemplateExportLoadingSelector();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const currentRow = useMemo(() => {
		if (isLoading) {
			return undefined;
		}

		const templateExportsFiltered = templateExports.filter(
			(f) => f.linkedEntities
				&& f.linkedEntities.length > 0
				&& f.linkedEntities[0].type === Environments.QUOTATION.toUpperCase()
				&& f.linkedEntities[0].linkedEntity === quotation.id
		);

		return templateExportsFiltered && templateExportsFiltered.length > 0 ? templateExportsFiltered : undefined;
	}, [quotation.id, isLoading, templateExports]);
	const pdf = useMemo(() => (currentRow && currentRow.length > 0 ? currentRow[0]?.generatedPdf?.pdf : undefined), [currentRow]);

	const canDeleteQuotation = useAccessRight(AccessRights.sales.quotations.enhancedRights.DELETE_QUOTATION) && !quotation.archived;
	const canEditQuotation = useAccessRight(AccessRights.sales.quotations.enhancedRights.CREATE_QUOTATION) && !quotation.archived;
	const canExtractQuotation = useAccessRight(AccessRights.sales.quotations.enhancedRights.EXPORT_QUOTATION);

	const canDownload = useMemo(() => pdf && pdf.presignedUrl, [pdf]);
	const [isDownloading, setIsDownloading] = useState(false);

	const onCopyButtonClick = useCallback(() => {
		history.push({
			pathname: routes.sales.quotations.quotationCreation,
			state: { creationType: QuotationCreationTypes.INITIALISATION, sourceQuotationId: quotation.id },
		});
	}, [quotation.id]);

	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeQuotation({ id: quotation.id, temporaryNumber: quotation.temporaryNumber }));
		history.push(generatePath(routes.sales.quotations.list));
	}, [dispatch, quotation.id, quotation.temporaryNumber, toggleDeleteModal]);

	const onDownloadButtonClick = useCallback(async (editedPdf) => {
		if (!isDownloading) {
			const exportedPdfFiltered = currentRow.filter((f) => f.generatedPdf.id === editedPdf)[0].generatedPdf.pdf;
			setIsDownloading(true);
			await downloadS3File(exportedPdfFiltered, 'pdf');
			setIsDownloading(false);
		}
	}, [currentRow, isDownloading]);

	const onEditButtonClick = useCallback(() => {
		history.push(generatePath(routes.sales.quotations.quotationEdition, { id: quotation.id }));
	}, [quotation.id]);

	const onExtractButtonClick = useCallback(() => {
		const exportData = {
			id: quotation.id,
			optionalFields: true,
			linkedEntites: true,
			masterData: true,
		};

		return dispatch(exportQuotations(exportData));
	}, [dispatch, quotation.id]);

	return (
		<div className="actions">
			{quotation.generatedPdfs && quotation.generatedPdfs.length > 0 && canDownload && quotation.generatedPdfs.map(
				(editedQuotation) => (
					<>
						<Button
							key={editedQuotation.fileName}
							className="icon-only"
							onClick={() => onDownloadButtonClick(editedQuotation.id)}
							isLoading={isDownloading}
							ref={downloadButtonRef}
						>
							<Download />
						</Button>
						<Tooltip {...downloadTooltipProps}>{t('quotation.links.document.download', { editedQuotation: editedQuotation.fileName })}</Tooltip>
					</>
				)
			)}
			{canExtractQuotation && (
				<>
					<Button className="icon-only" onClick={onExtractButtonClick} ref={extractButtonRef}>
						<ExternalLink />
					</Button>
					<Tooltip {...extractTooltipProps}>{t('quotation.links.export.current')}</Tooltip>
				</>
			)}
			{canEditQuotation && (
				<>
					<Button className="icon-only" onClick={onEditButtonClick} ref={editButtonRef}>
						<Edit2 />
					</Button>
					<Tooltip {...editTooltipProps}>{t('quotation.links.update')}</Tooltip>
				</>
			)}
			<Button className="icon-only" onClick={onCopyButtonClick} ref={copyButtonRef}>
				<Copy />
			</Button>
			<Tooltip {...copyTooltipProps}>{t('quotation.links.duplicate')}</Tooltip>
			{canDeleteQuotation && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>{t('quotation.links.delete')}</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('quotation.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('quotation.deletion.confirmation_modal.confirm')}
						cancelText={t('quotation.deletion.confirmation_modal.cancel')}
						titleText={t('quotation.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</div>
	);
};

QuotationActionBar.propTypes = {
	quotation: PropTypes.object.isRequired,
};

export default QuotationActionBar;
