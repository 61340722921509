import * as TargetsApi from 'api/targetsApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Targets redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of targets
	FETCH_TARGET_FOR_LIST_REQUEST: '@TARGETS_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_TARGET_FOR_LIST_SUCCESS: '@TARGETS_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_TARGET_FOR_LIST_FAILURE: '@TARGETS_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Target list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetsForListRequest
 * @description Action triggered anytime a targetsList list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetsForListRequest = () => ({ type: ActionTypes.FETCH_TARGET_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchTargetsForListSuccess
 * @description Action triggered as a result to a successful targetsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	targetsList	The list of retrieved targets.
 * @param {number}	totalCount		The total amount of targets available in the database to the current user.
 *
 * @returns {object}
 */
const fetchTargetsForListSuccess = ({ targetsList, totalCount }) => ({
	type: ActionTypes.FETCH_TARGET_FOR_LIST_SUCCESS,
	payload: { targetsList, totalCount },
});

/**
 * @function
 * @name fetchTargetsForListFailure
 * @description Action triggered as a result to a failed targetsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetsForListFailure = (error) => ({
	type: ActionTypes.FETCH_TARGET_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetsForList
 * @description Method used to update the targetsList list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchTargetsForList = (filters) => (dispatch) => {
	dispatch(fetchTargetsForListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forList',
	};

	return TargetsApi.fetchTargets(newFilters)
		.then(({ targetsList, totalCount }) => dispatch(fetchTargetsForListSuccess({ targetsList, totalCount })))
		.catch((error) => dispatch(fetchTargetsForListFailure(error)));
};
