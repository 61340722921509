import { PageOrientations } from '../constants/PagesOrientations';

import insertPage from './insertPage';

/**
 * @function
 * @name appendPage
 * @description Adds a new page
 *
 * @author Florian Fornazaric
 *
 * @param {object} state				The current state
 * @param {string} payload.orientation 	The new page's orientation (portrait, landscape)
 *
 * @returns {object} The updated state value
 */
const appendPage = (state, { orientation = PageOrientations.PORTRAIT }) => {
	const newState = insertPage(state, { index: state.pages.length, orientation });

	return newState;
};

export default appendPage;
