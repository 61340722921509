import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { historyTypes } from 'constants/historyEnums';
import { formatContactName } from 'lib/contacts/formatContactData';
import PropTypes from 'prop-types';
import { addContact } from 'redux/actions/contacts/contacts';
import { fetchContactsSelectList } from 'redux/actions/contacts/contactsSelect';
import { addPartner } from 'redux/actions/partners/partners';
import { fetchPartnersSelectList } from 'redux/actions/partners/partnersSelect';
import { addProject } from 'redux/actions/projects/projects';
import { fetchProjectsSelectList } from 'redux/actions/projects/projectsSelect';
import { fetchTargetPartnerList } from 'redux/actions/targetPartners/targetPartners';
import { addUser, fetchUserList } from 'redux/actions/users';
import { useContactsSelectListSelector, useContactsSelectLoadingSelector } from 'redux/selectors/contacts/contactsSelect';
import { usePartnersSelectListSelector, usePartnersSelectLoadingSelector } from 'redux/selectors/partners/partnersSelect';
import { useProjectsSelectListSelector, useProjectsSelectLoadingSelector } from 'redux/selectors/projects/projectsSelect';
import { useTargetPartnerListSelector, useTargetPartnerLoadingSelector } from 'redux/selectors/targetPartners/targetPartners';
import { useUserListSelector, useUsersLoadingSelector } from 'redux/selectors/users';

import { ContactCreationForm } from 'components/contacts';
import { PartnerCreationForm } from 'components/partners';
import { ProjectCreationForm } from 'components/projects';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { DateInput, HiddenInput, Select, TextArea } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';
import { UserCreationForm } from 'components/users';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import { getHistoryChannelForSelectTypes, getHistoryRestrictionTypes } from './function';

/**
 * @name HistoryEditionForm
 * @description A form used to update an existing history.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 *
 * @param {function}	onSubmit	    			The method to trigger upon form submission.
 * @param {object}		history	        			The history object to edit information from.
 * @param {boolean}		[editTargetPartnerHistory]	Whether the history is edited from a targetPartner.
 */
const HistoryEditionForm = ({ onSubmit, history, editTargetPartnerHistory }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const dateToday = useMemo(() => new Date(), []);
	dateToday.setUTCHours(23, 59, 59, 999);

	const channelsList = useMemo(() => getHistoryChannelForSelectTypes().map(
		(channel) => ({ value: channel.value, label: t(channel.label) })
	), [t]);
	const restrictionsTypes = useMemo(() => getHistoryRestrictionTypes().map(
		(restriction) => ({ value: restriction.value, label: t(restriction.label) })
	), [t]);

	useEffect(() => {
		dispatch(fetchContactsSelectList({ rowsPerPage: 0 }));
		dispatch(fetchPartnersSelectList({ rowsPerPage: 0 }));
		dispatch(fetchProjectsSelectList({ rowsPerPage: 0 }));
		if (!editTargetPartnerHistory) {
			dispatch(fetchTargetPartnerList({ rowsPerPage: 0 }));
		}
		dispatch(fetchUserList({ rowsPerPage: 0 }));
	}, [dispatch, editTargetPartnerHistory]);

	const contacts = useContactsSelectListSelector();
	const partners = usePartnersSelectListSelector();
	const projects = useProjectsSelectListSelector();
	const targetPartners = useTargetPartnerListSelector();
	const users = useUserListSelector();
	const areContactsLoading = useContactsSelectLoadingSelector();
	const arePartnersLoading = usePartnersSelectLoadingSelector();
	const areProjectsLoading = useProjectsSelectLoadingSelector();
	const areTargetPartnersLoading = useTargetPartnerLoadingSelector();
	const areUsersLoading = useUsersLoadingSelector();

	const contactsSelectOptions = useMemo(() => (contacts.map((contact) => ({ id: contact.id, name: formatContactName(contact) }))), [contacts]);

	const canEditHistory = useAccessRight(AccessRights.commonSettings.history.enhancedRights.CREATE_HISTORY) && history.channel !== 'workflow';

	const targetPartnersSelectOptions = useMemo(() => targetPartners.map(
		(targetPartner) => ({ id: targetPartner.id, fullname: targetPartner.partner?.name })
	), [targetPartners]);

	const { formProps, buttonProps } = useSubmitButton();

	// Props to pass down to the select input so that it will display a contact creation modal when clicking on the "+" icon.
	const contactCreationModal = useFormModal(ContactCreationForm, t('pages.sales.crm.contacts.creation'), fetchContactsSelectList, addContact);
	// Props to pass down to the select input so that it will display a partner creation modal when clicking on the "+" icon.
	const partnerCreationModal = useFormModal(PartnerCreationForm, t('pages.sales.crm.partners.creation'), fetchPartnersSelectList, addPartner);
	// Props to pass down to the select input so that it will display a project creation modal when clicking on the "+" icon.
	const projectCreationModal = useFormModal(ProjectCreationForm, t('pages.sales.crm.projects.creation'), fetchProjectsSelectList, addProject);
	// Props to pass down to the select input so that it will display a user creation modal when clicking on the "+" icon.
	const userCreationModal = useFormModal(UserCreationForm, t('pages.sales.crm.collaborators.creation'), fetchUserList, addUser);

	const defaultValues = useMemo(() => ({
		...history,
		collaborators: history.collaborators?.map(({ id }) => (id ?? '')) ?? [],
		contacts: history.contacts?.map(({ id }) => (id ?? '')) ?? [],
		partners: history.partners?.map(({ id }) => (id ?? '')) ?? [],
		projects: history.projects?.map(({ id }) => (id ?? '')) ?? [],
		targetPartners: history.targetPartners?.map(({ id }) => (id ?? '')) ?? [],
		statusObject: history.statusObject?.id || undefined,
	}), [history]);

	const handleSubmit = useCallback((formData) => {
		const newData = {
			...formData,
			history: formData.date.setUTCHours(12, 0, 0),
		};
		onSubmit(newData);
	}, [onSubmit]);

	return (
		<DynamicForm onSubmit={handleSubmit} defaultValues={defaultValues} disabled={!canEditHistory} {...formProps}>
			<HiddenInput
				name="historyType"
				value={historyTypes.HISTORY_TYPE_STANDARD}
			/>
			<HiddenInput
				name="statusObject"
			/>
			<DateInput
				label={t('history.edition.inputs.date.label')}
				name="date"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
					maxDate: Validators.maxDate(dateToday, t('components.date_picker.format'), t('history.edition.inputs.date.validation_errors.maxDate')),
					required: Validators.isRequired(t('history.edition.inputs.date.validation_errors.required')),
				}}
			/>
			<Select
				label={t('history.edition.inputs.channel.label')}
				labelKey="label"
				name="channel"
				rules={{
					required: Validators.isRequired(t('history.edition.inputs.channel.validation_errors.required')),
				}}
				options={channelsList}
				valueKey="value"
			/>
			<TextArea
				label={t('history.edition.inputs.note.label')}
				name="note"
				placeholder={t('history.edition.inputs.note.placeholder')}
				rules={{
					required: Validators.isRequired(t('history.edition.inputs.note.validation_errors.required')),
				}}
				type="text"
			/>
			<DateInput
				allowNull
				label={t('history.edition.inputs.reminder_date.label')}
				name="reminderDate"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
			<Select
				label={t('history.edition.inputs.restriction.label')}
				labelKey="label"
				name="restriction"
				options={restrictionsTypes}
				valueKey="value"
			/>
			<Select
				label={t('history.edition.inputs.contacts.label')}
				name="contacts"
				options={contactsSelectOptions}
				labelKey="name"
				valueKey="id"
				isMulti
				isLoading={areContactsLoading}
				{...contactCreationModal}
			/>
			<Select
				label={t('history.edition.inputs.partners.label')}
				name="partners"
				options={partners}
				labelKey="name"
				valueKey="id"
				isMulti
				isLoading={arePartnersLoading}
				{...partnerCreationModal}
			/>
			<Select
				label={t('history.edition.inputs.projects.label')}
				name="projects"
				options={projects}
				labelKey="name"
				valueKey="id"
				isMulti
				isLoading={areProjectsLoading}
				{...projectCreationModal}
			/>
			<Select
				label={t('history.edition.inputs.collaborators.label')}
				name="collaborators"
				options={users}
				labelKey="username"
				valueKey="id"
				isMulti
				isLoading={areUsersLoading}
				{...userCreationModal}
			/>
			{!editTargetPartnerHistory && (
				<Select
					label={t('history.edition.inputs.target_partners.label')}
					name="targetPartners"
					options={targetPartnersSelectOptions}
					labelKey="fullname"
					valueKey="id"
					isMulti
					isLoading={areTargetPartnersLoading}
				/>
			)}

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditHistory ? t('history.edition.action') : t('history.edition.close')}
			</Button>
		</DynamicForm>
	);
};

HistoryEditionForm.propTypes = {
	history: PropTypes.shape({
		channel: PropTypes.string.isRequired,
		collaborators: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
		})),
		contacts: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
		})),
		date: PropTypes.string.isRequired,
		historyType: PropTypes.string.isRequired,
		note: PropTypes.string,
		partners: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
		})),
		projects: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
		})),
		reminderDate: PropTypes.string,
		restriction: PropTypes.string.isRequired,
		statusObject: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		targetPartners: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
			partner: PropTypes.shape({
				name: PropTypes.string.isRequired,
			}),
		})),
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
	editTargetPartnerHistory: PropTypes.bool,
};

HistoryEditionForm.defaultProps = {
	editTargetPartnerHistory: false,
};

export default HistoryEditionForm;
