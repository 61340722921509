import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useApplicationsLoadingSelector
 * @description A selector to retrieve the loading state from the applications redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {boolean}
 */
const useApplicationsLoadingSelector = () => {
	const loading = useSelector((state) => state.applications?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useApplicationListSelector
 * @description A selector to retrieve the company list from the applications redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {Array}
 */
const useApplicationListSelector = () => {
	const applications = useSelector((state) => (state.applications?.items ?? []), shallowEqual);

	return applications;
};

/**
 * @function
 * @name useApplicationTotalCountSelector
 * @description A selector to retrieve the totalCount value from the applications redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {number|undefined}
 */
const useApplicationTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.applications?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useApplicationListSelector,
	useApplicationsLoadingSelector,
	useApplicationTotalCountSelector,
};
