import { toast } from 'react-toastify';
import * as AccountsApi from 'api/accountsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Accounts redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific account
	FETCH_ACCOUNT_REQUEST: '@ACCOUNTS/FETCH_REQUEST',
	FETCH_ACCOUNT_SUCCESS: '@ACCOUNTS/FETCH_SUCCESS',
	FETCH_ACCOUNT_FAILURE: '@ACCOUNTS/FETCH_FAILURE',

	// Fetch a list of accounts
	FETCH_ACCOUNT_LIST_REQUEST: '@ACCOUNTS/FETCH_LIST_REQUEST',
	FETCH_ACCOUNT_LIST_SUCCESS: '@ACCOUNTS/FETCH_LIST_SUCCESS',
	FETCH_ACCOUNT_LIST_FAILURE: '@ACCOUNTS/FETCH_LIST_FAILURE',

	// Add a new account to the database
	ADD_ACCOUNT_REQUEST: '@ACCOUNTS/ADD_REQUEST',
	ADD_ACCOUNT_SUCCESS: '@ACCOUNTS/ADD_SUCCESS',
	ADD_ACCOUNT_FAILURE: '@ACCOUNTS/ADD_FAILURE',

	// Update an existing account from the database
	UPDATE_ACCOUNT_REQUEST: '@ACCOUNTS/UPDATE_REQUEST',
	UPDATE_ACCOUNT_SUCCESS: '@ACCOUNTS/UPDATE_SUCCESS',
	UPDATE_ACCOUNT_FAILURE: '@ACCOUNTS/UPDATE_FAILURE',

	// Remove an existing account from the database
	REMOVE_ACCOUNT_REQUEST: '@ACCOUNTS/REMOVE_REQUEST',
	REMOVE_ACCOUNT_SUCCESS: '@ACCOUNTS/REMOVE_SUCCESS',
	REMOVE_ACCOUNT_FAILURE: '@ACCOUNTS/REMOVE_FAILURE',

	// Archive an existing account from the database
	ARCHIVE_ACCOUNT_REQUEST: '@ACCOUNTS/ARCHIVE_REQUEST',
	ARCHIVE_ACCOUNT_SUCCESS: '@ACCOUNTS/ARCHIVE_SUCCESS',
	ARCHIVE_ACCOUNT_FAILURE: '@ACCOUNTS/ARCHIVE_FAILURE',

	// Restore an existing account from the database
	RESTORE_ACCOUNT_REQUEST: '@ACCOUNTS/RESTORE_REQUEST',
	RESTORE_ACCOUNT_SUCCESS: '@ACCOUNTS/RESTORE_SUCCESS',
	RESTORE_ACCOUNT_FAILURE: '@ACCOUNTS/RESTORE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Account list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAccountListRequest
 * @description Action triggered anytime a account list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchAccountListRequest = () => ({ type: ActionTypes.FETCH_ACCOUNT_LIST_REQUEST });

/**
 * @function
 * @name fetchAccountListSuccess
 * @description Action triggered as a result to a successful account list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {array} accounts		The list of retrieved accounts.
 * @param {number} totalCount	The total amount of accounts available in the database for the current user.
 *
 * @returns {object}
 */
const fetchAccountListSuccess = ({ accounts, totalCount }) => ({
	type: ActionTypes.FETCH_ACCOUNT_LIST_SUCCESS,
	payload: { accounts, totalCount },
});

/**
 * @function
 * @name fetchAccountListFailure
 * @description Action triggered as a result to a failed account list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error		The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAccountListFailure = (error) => ({
	type: ActionTypes.FETCH_ACCOUNT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific account fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAccountRequest
 * @description Action triggered anytime a account fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchAccountRequest = () => ({ type: ActionTypes.FETCH_ACCOUNT_REQUEST });

/**
 * @function
 * @name fetchAccountSuccess
 * @description Action triggered as a result to a successful account fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} account		The retrieved account.
 *
 * @returns {object}
 */
const fetchAccountSuccess = ({ account }) => ({
	type: ActionTypes.FETCH_ACCOUNT_SUCCESS,
	payload: { account },
});

/**
 * @function
 * @name fetchAccountFailure
 * @description Action triggered as a result to a failed account fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error 	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAccountFailure = (error) => ({
	type: ActionTypes.FETCH_ACCOUNT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Account creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addAccountRequest
 * @description Action triggered anytime a account creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addAccountRequest = () => ({ type: ActionTypes.ADD_ACCOUNT_REQUEST });

/**
 * @function
 * @name addAccountSuccess
 * @description Action triggered as a result to a successful account creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} account		The created account object.
 *
 * @returns {object}
 */
const addAccountSuccess = ({ account }) => ({
	type: ActionTypes.ADD_ACCOUNT_SUCCESS,
	payload: { account },
});

/**
 * @function
 * @name addAccountFailure
 * @description Action triggered as a result to a failed account creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error 	The exception sent back from the API.
 *
 * @returns {object}
 */
const addAccountFailure = (error) => ({
	type: ActionTypes.ADD_ACCOUNT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Account update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateAccountRequest
 * @description Action triggered anytime a account update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateAccountRequest = () => ({ type: ActionTypes.UPDATE_ACCOUNT_REQUEST });

/**
 * @function
 * @name updateAccountSuccess
 * @description Action triggered as a result to a successful account update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} account 	The updated account object.
 *
 * @returns {object}
 */
const updateAccountSuccess = ({ account }) => ({
	type: ActionTypes.UPDATE_ACCOUNT_SUCCESS,
	payload: { account },
});

/**
 * @function
 * @name updateAccountFailure
 * @description Action triggered as a result to a failed account update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error 	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateAccountFailure = (error) => ({
	type: ActionTypes.UPDATE_ACCOUNT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Account removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeAccountRequest
 * @description Action triggered anytime a account deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeAccountRequest = () => ({ type: ActionTypes.REMOVE_ACCOUNT_REQUEST });

/**
 * @function
 * @name removeAccountSuccess
 * @description Action triggered as a result to a successful account deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} deletedAccountId 	The removed account object.
 *
 * @returns {object}
 */
const removeAccountSuccess = ({ deletedAccountId }) => ({
	type: ActionTypes.REMOVE_ACCOUNT_SUCCESS,
	payload: { deletedAccountId },
});

/**
 * @function
 * @name removeAccountFailure
 * @description Action triggered as a result to a failed account deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error 	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeAccountFailure = (error) => ({
	type: ActionTypes.REMOVE_ACCOUNT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Account archiving actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveAccountRequest
 * @description Action triggered anytime an account archiving call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archiveAccountRequest = () => ({ type: ActionTypes.ARCHIVE_ACCOUNT_REQUEST });

/**
 * @function
 * @name archiveAccountSuccess
 * @description	Action triggered as a result to a successful account archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const archiveAccountSuccess = () => ({
	type: ActionTypes.ARCHIVE_ACCOUNT_SUCCESS,
});

/**
 * @function
 * @name archiveAccountFailure
 * @description Action triggered as a result to a failed account archiving API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error 		The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveAccountFailure = (error) => ({
	type: ActionTypes.ARCHIVE_ACCOUNT_FAILURE,
	payload: { error },
});

// ///////////////////////////////////////////////////////////// //
// ///////////////// Account restoring actions ///////////////// //
// ///////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreAccountRequest
 * @description Action triggered anytime an account restoring call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restoreAccountRequest = () => ({ type: ActionTypes.RESTORE_ACCOUNT_REQUEST });

/**
 * @function
 * @name restoreAccountSuccess
 * @description	Action triggered as a result to a successful account restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const restoreAccountSuccess = () => ({
	type: ActionTypes.RESTORE_ACCOUNT_SUCCESS,
});

/**
 * @function
 * @name restoreAccountFailure
 * @description Action triggered as a result to a failed account restoring API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreAccountFailure = (error) => ({
	type: ActionTypes.RESTORE_ACCOUNT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAccountList
 * @description Method used to update the account list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params 				The parameters used to match user filters.
 */
export const fetchAccountList = (params) => (dispatch) => {
	dispatch(fetchAccountListRequest());

	return AccountsApi.fetchAccounts(params)
		.then(({ accounts, totalCount }) => dispatch(fetchAccountListSuccess({ accounts, totalCount })))
		.catch((error) => dispatch(fetchAccountListFailure(error)));
};

/**
 * @function
 * @name fetchAccount
 * @description Method used to fetch the latest version of a specific account.
 *
 * @author Romaric Barthe
 *
 * @param {string} accountId 	The id of the account we want to retrieve.
 */
export const fetchAccount = (accountId) => (dispatch) => {
	dispatch(fetchAccountRequest());

	return AccountsApi.fetchAccountById(accountId)
		.then(({ account }) => dispatch(fetchAccountSuccess({ account })))
		.catch((error) => dispatch(fetchAccountFailure(error)));
};

/**
 * @function
 * @name addAccount
 * @description Method used to add a new account instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} accountData 		The data to create the new account from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addAccount = (accountData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addAccountRequest());

	return AccountsApi.createAccount(accountData)
		.then(({ account }) => {
			toast.success(i18next.t('account.creation.toasts.success', { name: `${account.name}` }));
			dispatch(addAccountSuccess({ account }));
			redirectOnSuccess(onSuccessRoute);

			return account;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('account.creation.toasts.conflict'));
				dispatch(addAccountFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('account.creation.toasts.error'));
				dispatch(addAccountFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateAccount
 * @description Method used to update an existing account instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} accountData  	The object to update the account with.
 * @param {string} accountId    	The id of the account we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateAccount = (accountData, accountId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateAccountRequest());

	return AccountsApi.updateAccount(accountData, accountId)
		.then(({ account }) => {
			dispatch(updateAccountSuccess({ account }));
			toast.success(i18next.t('account.edition.toasts.success', { name: `${account.name}` }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('account.edition.toasts.conflict'));
				dispatch(updateAccountFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('account.edition.toasts.error'));
				dispatch(updateAccountFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeAccount
 * @description Method used to remove an existing account instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} account		The account we want to archive
 * @param {string} account.id	The id of the account we want to archive.
 * @param {string} account.name	The name of the account we want to archive (used in success toast).
 */
export const removeAccount = ({ id, name }) => (dispatch) => {
	dispatch(removeAccountRequest());

	return AccountsApi.deleteAccount(id)
		.then(({ deletedAccountId }) => {
			dispatch(removeAccountSuccess({ deletedAccountId }));
			toast.success(i18next.t('account.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeAccountFailure(error));
			toast.error(i18next.t('account.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveAccount
 * @description	Method used to archive an existing account instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} account		The account we want to archive
 * @param {string} account.id	The id of the account we want to archive.
 * @param {string} account.name	The name of the account we want to archive (used in success toast).
 */
export const archiveAccount = ({ id, name }) => (dispatch) => {
	dispatch(archiveAccountRequest());

	return AccountsApi.updateAccount({ archived: true }, id)
		.then(() => {
			dispatch(archiveAccountSuccess());
			toast.success(i18next.t('account.archiving.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(archiveAccountFailure(error));
			toast.error(i18next.t('account.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreAccount
 * @description	Method used to restore an existing account instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} account		The account we want to restore
 * @param {string} account.id	The id of the account we want to restore.
 * @param {string} account.name	The name of the account we want to restore (used in success toast).
 */
export const restoreAccount = ({ id, name }) => (dispatch) => {
	dispatch(restoreAccountRequest());

	return AccountsApi.updateAccount({ archived: false }, id)
		.then(() => {
			dispatch(restoreAccountSuccess());
			toast.success(i18next.t('account.restoring.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(restoreAccountFailure(error));
			toast.error(i18next.t('account.restoring.toasts.error'));
		});
};
