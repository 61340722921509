import { useTranslation } from 'react-i18next';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import { HiddenInput } from 'components/shared/forms/inputs';

/**
 * @name QuotationTotalsTable
 * @description A form used to display the quotation totals as an overview.
 *
 * @author Romaric Barthe
 *
 * @param {string}	quotationTypeSelected		The quotation type selected in the header.
 * @param {number}	globalRate					Archi only: the rate without extra.
 * @param {number}	globalRateWithExtra			Archi only: the rate with extra.
 * @param {number}	discountTotal				The total quoted with VAT.
 * @param {number}	grossTotalWithDiscount		The total quoted with VAT.
 * @param {number}	netTotal					The total quoted with VAT.
 * @param {number}	grossTotal					The total quoted without VAT.
 * @param {number}	vatTotal					The total VAT quoted.
 * @param {string}	currencyName				The currency ISO used in the quotation.
 */
const QuotationTotalsTable = ({
	currencySymbol,
	discountTotal,
	globalRate,
	globalRateWithExtra,
	grossTotal,
	grossTotalWithDiscount,
	netTotal,
	quotationTypeSelected,
	vatTotal,
}) => {
	const { t } = useTranslation();

	return (
		<table className="total-overview">
			<thead>
				<tr>
					{quotationTypeSelected === 'archi' && (
						<>
							{globalRate !== '0.00' && <th>{t('quotation.headers.global_rate')}</th>}
							{globalRateWithExtra !== '0.00' && <th>{t('quotation.headers.global_rate_extra')}</th>}
						</>
					)}
					<th>{t('quotation.headers.total_without_vat')}</th>
					{discountTotal !== 0 && (
						<>
							<th>{t('quotation.headers.total_discount')}</th>
							<th>{t('quotation.headers.total_without_vat_discounted')}</th>
						</>
					)}
					<th>{t('quotation.headers.total_vat')}</th>
					<th>{t('quotation.headers.total_with_vat')}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					{quotationTypeSelected === 'archi' && (
						<>
							{globalRate !== '0.00' && (
								<td>
									{formatNumber(globalRate, { symbol: '%' })}
									<HiddenInput
										name="globalRate"
										value={globalRate}
									/>
								</td>
							)}
							{globalRateWithExtra !== '0.00' && (
								<td>
									{formatNumber(globalRateWithExtra, { symbol: '%' })}
									<HiddenInput
										name="globalRateWithExtra"
										value={globalRateWithExtra}
									/>
								</td>
							)}
						</>
					)}
					<td>
						{formatNumber(grossTotal, { symbol: currencySymbol })}
						<HiddenInput
							name="grossTotal"
							value={grossTotal}
						/>
					</td>
					{discountTotal !== 0 && (
						<>
							<td>
								{formatNumber(discountTotal, { symbol: currencySymbol })}
								<HiddenInput
									name="discountTotal"
									value={discountTotal}
								/>
							</td>
							<td>
								{formatNumber(grossTotalWithDiscount, { symbol: currencySymbol })}
								<HiddenInput
									name="grossTotalWithDiscount"
									value={grossTotalWithDiscount}
								/>
							</td>
						</>
					)}
					<td>
						{formatNumber(vatTotal, { symbol: currencySymbol })}
						<HiddenInput
							name="vatTotal"
							value={vatTotal}
						/>
					</td>
					<td>
						{formatNumber(netTotal, { symbol: currencySymbol })}
						<HiddenInput
							name="netTotal"
							value={netTotal}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

QuotationTotalsTable.propTypes = {
	globalRate: PropTypes.number,
	globalRateWithExtra: PropTypes.number,
	quotationTypeSelected: PropTypes.string,
	discountTotal: PropTypes.number.isRequired,
	grossTotalWithDiscount: PropTypes.number.isRequired,
	netTotal: PropTypes.number.isRequired,
	grossTotal: PropTypes.number.isRequired,
	vatTotal: PropTypes.number,
	currencySymbol: PropTypes.string,
};

QuotationTotalsTable.defaultProps = {
	globalRate: 0,
	globalRateWithExtra: 0,
	quotationTypeSelected: undefined,
	vatTotal: 0,
	currencySymbol: '',
};

export default QuotationTotalsTable;
