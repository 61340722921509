import { useContext } from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import EditorContext from '../../EditorContext';

const css = {
	padding: '0px',
	minWidth: '2em',
	minHeight: '1.5em',
};

/**
 * @name PageNumber
 * @description A page number PDF element
 *
 * @author Florian Fornazaric
 *
 * @param {object}	style				The style of the component
 * @param {object}  element 			The linked element
 * @param {boolean}	resizable			True if the element is being resized
 * @param {boolean}	pageindex			The index of the page
 */
const PageNumber = (({ style, element, resizable, pageindex }) => {
	const { pageNumberingStartIndex, pages } = useContext(EditorContext);

	const shownValue = `${pageindex - pageNumberingStartIndex + 1}${element?.remainingPageNumbering
		? `${element?.remainingPageSeparator}${pages.length - pageNumberingStartIndex}`
		: ''}`;

	return (
		<textarea
			className={`seamless${resizable ? ' editing' : ''}`}
			style={{ ...style }}
			readOnly
			value={pageindex >= pageNumberingStartIndex
				? shownValue
				: ''}
		/>
	);
});

PageNumber.propTypes = {
	style: PropTypes.object,
	element: PropTypes.object,
	resizable: PropTypes.bool,
	pageindex: PropTypes.number,
};

PageNumber.defaultProps = {
	style: undefined,
	element: undefined,
	resizable: false,
	pageindex: 0,
};

const styles = StyleSheet.create(css);

PageNumber.PdfElement = ({ style, element, pageIndex, rendererGlobals, ...otherprops }) => {
	const { pageNumberingStartIndex } = rendererGlobals;

	const shownValue = `${pageIndex - pageNumberingStartIndex + 1}${element?.remainingPageNumbering
		? `${element?.remainingPageSeparator}${rendererGlobals.numberOfPages - pageNumberingStartIndex}`
		: ''}`;

	return (
		<Text {...otherprops} style={[styles, style]}>
			{pageIndex >= pageNumberingStartIndex
				? shownValue
				: ''}
		</Text>
	);
};

PageNumber.PdfElement.propTypes = {
	style: PropTypes.object,
	pageIndex: PropTypes.number.isRequired,
	rendererGlobals: PropTypes.shape({
		numberOfPages: PropTypes.number.isRequired,
		pageNumberingStartIndex: PropTypes.number.isRequired,
	}).isRequired,
	element: PropTypes.object,
};

PageNumber.PdfElement.defaultProps = {
	style: undefined,
	element: undefined,
};

PageNumber.PdfElement.displayName = 'PageNumber';

export default PageNumber;
