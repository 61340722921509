import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * @name DropHere
 * @description The "drop here" element, displayed when the user is dragging an element type around
 *
 * @author Yann Hodiesne
 *
 * @param {bool} canDrop	True if an item is being dragged and can be dropped in this component.
 * @param {bool} isOver		True if an item is being dragged over this component.
 */
const DropHere = ({ canDrop, isOver }) => {
	const { t } = useTranslation();

	return (
		<div className={`drop-here${canDrop ? ' can-drop' : ''}${isOver ? ' drag-over' : ''}`}>
			{t('template.pdf.editor.components.body.drop_here')}
		</div>
	);
};

DropHere.propTypes = {
	canDrop: PropTypes.bool.isRequired,
	isOver: PropTypes.bool.isRequired,
};

export default memo(DropHere);
