/* eslint-disable import/prefer-default-export */
import { useTranslation } from 'react-i18next';

/**
 * @function
 * @name formatRegistrationName
 * @description Concatenate registration name with its type.
 *
 * @author Romaric Barthe
 *
 * @param {object} registration  			The registration to be named.
 * @param {string} registration.name		The registration name to extract.
 * @param {Object} registration.type		The registration type to extract the name from.
 * @param {string} registration.type.name	The registration type name to extract.
 *
 * @returns {string}
 */
export const formatRegistrationName = (registration, t) => (registration.type
	? t(`registration.fields.category.${registration.type.name.toLowerCase()}`).concat(' :: ').concat(registration.name)
	: registration.name);

/**
 * @function
 * @name FormatRegistrationName
 * @description Concatenate registration name with its type.
 *
 * @author Audrey Clerc
 *
 * @param {object} registration  			The registration to be named.
 * @param {string} registration.name		The registration name to extract.
 * @param {Object} registration.type		The registration type to extract the name from.
 * @param {string} registration.type.name	The registration type name to extract.
 *
 * @returns {string}
 */
export const FormatRegistrationName = (registration) => {
	const { t } = useTranslation();

	return (registration.type
		? t(`registration.fields.category.${registration.type.name.toLowerCase()}`).concat(' :: ').concat(registration.name)
		: registration.name);
};
