import { forwardRef, useCallback, useId, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForwardedRef } from 'lib/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DatePicker } from 'components/shared/inputs';

import useDynamicFormInput from '../useDynamicFormInput';

import { Error, Hint, Label } from './DynamicFormInput';

/**
 * @name DateInput
 * @description A date input component to be used inside a dynamic form.
 *
 * @author Yann Hodiesne
 *
 * @param {string}	label				The string to display as a label for the wrapped input.
 * @param {string}	name				The input's name.
 * @param {string}	[hint]				A hint message to display below the input (if no error is displayed).
 * @param {object}	[rules={}]			The validation rules to apply to this input.
 * @param {bool}	[allowNull=false]	Whether the component should be allow to be empty.
 */
const DateInput = forwardRef(({ name, label, hint, rules, allowNull, className, ...props }, ref) => {
	const resolvedRef = useForwardedRef(ref);
	const { t } = useTranslation();

	const currentDateValue = useRef();

	const getValue = useCallback(() => currentDateValue.current, []);

	const {
		defaultValue,
		isDisabled,
		isInvalid,
		isOptional,
		isReadOnly,
		validationError,
		enableValidationOnChange,
		onChangeHandler,
	} = useDynamicFormInput(name, getValue, rules, props);

	const [value, setValue] = useState(() => {
		if (defaultValue) {
			return new Date(defaultValue);
		}

		return !allowNull ? new Date() : undefined;
	});

	currentDateValue.current = value;

	const onChange = useCallback(({ date }) => {
		setValue(date);
		currentDateValue.current = date;

		enableValidationOnChange();
		onChangeHandler();
	}, [enableValidationOnChange, onChangeHandler]);

	useImperativeHandle(resolvedRef, () => ({
		setValue: (newValue) => {
			if (!_.isDate(newValue)) {
				throw new Error(`Provided value must be of type Date: "${newValue}"`);
			}

			onChange({ date: newValue });
		},
	}), [onChange]);

	const id = useId();

	return (
		<div className={`input-wrapper${isDisabled ? ' disabled' : ''}${className ?? ''}`}>
			<Label disabled={isDisabled} inputId={id} isInvalid={isInvalid}>
				{label}
				{isOptional && ` (${t('form.optional')})`}
			</Label>
			<DatePicker
				id={id}
				name={name}
				allowNull={allowNull}
				disabled={isDisabled}
				readOnly={isReadOnly}
				aria-invalid={isInvalid}
				value={value}
				onChange={onChange}
				onBlur={enableValidationOnChange}
			/>
			{isInvalid && <Error>{validationError ?? ''}</Error>}
			{!isInvalid && <Hint>{hint ?? ''}</Hint>}
		</div>
	);
});

DateInput.propTypes = {
	allowNull: PropTypes.bool,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	hint: PropTypes.string,
	rules: PropTypes.object,
	className: PropTypes.string,
};

DateInput.defaultProps = {
	allowNull: false,
	hint: '',
	rules: {},
	className: undefined,
};

DateInput.displayName = 'DateInput';

export default DateInput;
