import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import { DefaultTableFields } from '../../constants/DefaultTableContent';
import getTableHeaders from '../../functions/internals/getTableHeaders';

const BORDER_STYLE = 'solid';
const BORDER_COLOR = '#FFF';

/**
 * @name Table
 * @description A table PDF element
 *
 * @author Yann Hodiesne
 *
 * @param {number}  aspectRatio 		The aspect ratio of the template
 * @param {object}	style				The style of the component
 * @param {object}	element				The element to edit
 * @param {boolean} resizable			True if the element is being resized
 */
const Table = ({ aspectRatio, style, element, resizable }) => {
	const { t } = useTranslation();

	// This is needed because react-pdf does not support the flex property the same way as normal CSS.
	const css = useMemo(() => ({
		column: {
			display: 'flex',
			flexDirection: 'column',
			flex: 1, // TODO: This is untill the user can resize columns himself
		},
		table: {
			minWidth: `${200 * aspectRatio}px`,
			minHeight: '15px',
			borderStyle: BORDER_STYLE,
			borderColor: BORDER_COLOR,
			borderWidth: 0.1,
			maxWidth: '100%',
		},
		tableHeaderRow: {
			display: 'flex',
			margin: 'auto',
			height: 'auto',
			flexDirection: 'row',
		},
		tableRow: {
			display: 'flex',
			margin: 'auto',
			height: 'auto',
			flexDirection: 'row',
			borderBottom: 'solid 1px #cccccc',
		},
		totalTableRow: {
			display: 'flex',
			margin: 'auto',
			height: 'auto',
			flexDirection: 'row',
		},
		tableCellHeader: {
			padding: '4px',
			fontSize: style.fontSize,
			borderStyle: BORDER_STYLE,
			borderWidth: '.5px',
			flexGrow: 1,
			color: '#FFF',
			backgroundColor: '#666666',
			borderColor: BORDER_COLOR,
		},
		tableCellSubHeader: {
			padding: '4px',
			fontSize: style.fontSize,
			borderStyle: BORDER_STYLE,
			borderWidth: '.5px',
			flexGrow: 1,
			color: '#FFF',
			backgroundColor: '#666666',
			borderColor: BORDER_COLOR,
		},
		tableCell: {
			padding: '2px',
			fontSize: style.fontSize,
			borderStyle: BORDER_STYLE,
			borderColor: '#FFF',
			borderWidth: 0,
			flexGrow: 1,
			color: '#000',
		},
		cellText: {
			fontSize: style.fontSize,
		},
		totalCellText: {
			fontSize: style.fontSize,
			fontWeight: '500',
		},
	}), [aspectRatio, style.fontSize]);

	const scheme = useMemo(() => {
		if (!element.tableLinkedEntity || !element.tableLinkedProperty) {
			return DefaultTableFields;
		}

		const field = getTableHeaders(element);

		return field;
	}, [element]);

	const hasTotalLine = useMemo(() => element.content?.data?.hasTotalLine, [element.content?.data?.hasTotalLine]);

	return (
		!element.content ? <div style={{ ...style, resize: resizable ? 'both' : 'none' }} /> : (
			<div role="table" style={{ width: style.width, maxHeight: style.maxHeight, ...css.table, resize: resizable ? 'horizontal' : 'none' }}>
				<div role="row" style={{ ...css.tableHeaderRow }}>
					{element.content?.fields.map((fieldKey) => {
						const fieldContent = scheme[fieldKey];

						// If every row is empty, don't display the column
						if ((!fieldContent || !element.content.data.rows.some((item) => item[fieldKey])) && element.content.data.rows.length !== 0) {
							return null;
						}

						return (
						// We check if there's customHeaders to display the custom columns
							fieldContent?.customHeaders ? (
								element.content.data[fieldContent.customHeaders].map((customHeaderData, index) => (
									<div
										key={hash({ item: customHeaderData, index, field: fieldKey })}
										style={{ ...css.column }}
									>
										{/* We use the customHeadersFields to iterate over every subcolumn we need to show */}
										{fieldContent.customHeadersFields.map((customFieldKey) => (
											<div
												role="columnheader"
												key={hash({ value: customHeaderData[customFieldKey], item: customHeaderData })}
												style={css.tableCellHeader}
											>
												{customHeaderData[customFieldKey]}
											</div>
										))}
									</div>
								))
							)
								: (
									<div
										key={fieldKey}
										style={{ ...css.column }}
									>
										<div
											role="columnheader"
											style={css.tableCellHeader}
										>
											{t(fieldContent.name)}
										</div>
									</div>
								)
						);
					})}
				</div>
				{element.content.data?.rows?.map(
					(dataRow, rowIndex) => {
						if (!dataRow) {
							return null;
						}

						const isTotalLine = hasTotalLine && rowIndex === element.content.data.rows.length - 1;

						return (
							<div role="row" key={hash(dataRow)} style={isTotalLine ? css.totalTableRow : css.tableRow}>
								{element.content?.fields.map((fieldKey) => {
									const fieldContent = scheme[fieldKey];
									// If every row is empty, don't display the column
									if (!fieldContent || !element.content.data.rows.some((item) => item[fieldKey])) {
										return null;
									}

									const { totalStyle } = element.content.data;
									const emptyTotalCellStyle = { backgroundColor: 'transparent' };

									// This allows us to take customHeaders into considerations for quotations mainly
									if (fieldContent.customHeaders) {
										return (
											element.content.data[fieldContent.customHeaders].map((column, index) => (
												<div
													key={hash({ column, item: dataRow[fieldKey], index })}
													style={{
														...css.column,
													}}
												>
													<div
														role="cell"
														style={{
															...css.tableCell,
															...(isTotalLine && dataRow[fieldKey][index] ? totalStyle : emptyTotalCellStyle),
															...(fieldContent.style && dataRow[fieldKey][index] ? fieldContent.style : {}),
														}}
													>
														{/* This allows us to apply the format to the cell */}
														{!dataRow[fieldKey][index] && isTotalLine ? '' : fieldContent.format?.(dataRow[fieldKey][index])?.map((value) => (
															<p style={!isTotalLine ? css.cellText : css.totalCellText} key={value}>{value}</p>
														)) ?? <p style={!isTotalLine ? css.cellText : css.totalCellText}>{dataRow[fieldKey][index]}</p> ?? '-'}
													</div>
												</div>
											))
										);
									}

									return (
										<div
											key={hash({ value: dataRow[fieldKey], field: fieldContent })}
											style={{
												...css.column,
											}}
										>
											<div
												role="cell"
												style={{
													...css.tableCell,
													...(isTotalLine && dataRow[fieldKey] ? totalStyle : emptyTotalCellStyle),
													...(fieldContent.style && dataRow[fieldKey] ? fieldContent.style : {}),
												}}
											>
												{/* This allows us to apply the format to the cell */}
												{!dataRow[fieldKey] && isTotalLine ? '' : fieldContent.format?.(dataRow[fieldKey])?.map((value) => (
													<p style={!isTotalLine ? css.cellText : css.totalCellText} key={value}>{value}</p>
												)) ?? <p style={!isTotalLine ? css.cellText : css.totalCellText}>{dataRow[fieldKey]}</p> ?? '-'}
											</div>
										</div>
									);
								})}
							</div>
						);
					}
				)}
			</div>
		));
};

Table.propTypes = {
	aspectRatio: PropTypes.number.isRequired,
	style: PropTypes.object,
	element: PropTypes.shape({
		content: PropTypes.shape({
			fields: PropTypes.arrayOf(PropTypes.string).isRequired,
			data: PropTypes.object.isRequired,
		}).isRequired,
		tableLinkedEntity: PropTypes.string,
		format: PropTypes.object,
		tableLinkedProperty: PropTypes.string,
	}),
	resizable: PropTypes.bool.isRequired,
};

Table.defaultProps = {
	style: undefined,
	element: undefined,
};

Table.PdfElement = ({ style, element }) => {
	// This is needed because react-pdf does not support the flex property the same way as normal CSS.
	const globalCss = {
		column: {
			display: 'flex',
			flexDirection: 'column',
		},
		table: {
			minWidth: '16px',
			minHeight: '16px',
			borderStyle: BORDER_STYLE,
			borderColor: BORDER_COLOR,
			borderWidth: 1,
			maxWidth: '100%',
		},
		tableHeaderRow: {
			display: 'flex',
			margin: 'auto',
			height: 'auto',
			flexDirection: 'row',
		},
		tableRow: {
			display: 'flex',
			flexDirection: 'row',
			margin: 'auto',
			height: 'auto',
			borderBottom: '1px solid #cccccc',
		},
		totalTableRow: {
			display: 'flex',
			margin: 'auto',
			height: 'auto',
			flexDirection: 'row',
		},
		tableCellHeader: {
			padding: '4px',
			fontSize: `${element.format.fontSize * 0.85}px`,
			flexGrow: 1,
			margin: 0.5,
			color: '#FFF',
			backgroundColor: '#666666',
		},
		tableCellSubHeader: {
			padding: '4px',
			fontSize: `${element.format.fontSize * 0.85}px`,
			borderStyle: BORDER_STYLE,
			borderWidth: '1px',
			color: '#FFF',
			backgroundColor: '#666666',
			borderColor: BORDER_COLOR,
		},
		tableCell: {
			padding: '2px',
			fontSize: `${element.format.fontSize * 0.85}px`,
			borderStyle: BORDER_STYLE,
			borderColor: '#FFF',
			borderWidth: 0,
			flexGrow: 1,
			color: '#000',
		},
		cellText: {
			fontSize: `${element.format.fontSize * 0.85}px`,
		},
		totalCellText: {
			fontSize: `${element.format.fontSize * 0.85}px`,
			fontWeight: '500',
		},
	};

	const styles = StyleSheet.create(globalCss);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { t } = useTranslation();

	// We search the scheme within the EntityTypes's fields using the tableLinkedProperty
	const scheme = getTableHeaders(element);

	// We need to calculate the number of columns to calculate the width of each columns due to ReactPDF's limitations
	let numberOfColumns = element.content?.fields.filter((field) => !scheme[field]?.customHeaders
	&& element.content.data.rows.some((item) => item[field])).length;

	const customHeaders = element.content?.fields.filter((field) => scheme[field]?.customHeaders);
	customHeaders?.forEach((header) => {
		const col = scheme[header];
		numberOfColumns += element.content.data[col.customHeaders].length;
	});

	// This allows us to show headers even if there is no data
	if (numberOfColumns === 0) {
		numberOfColumns = element.content?.fields.length;
	}

	const hasTotalLine = element.content?.data?.hasTotalLine;

	if (!element.content) {
		return <View style={style} />;
	}

	return (
		<View style={[styles.table, style]}>
			<View style={[styles.tableHeaderRow]}>
				{element.content?.fields.map((fieldKey) => {
					const fieldContent = scheme[fieldKey];

					// If every row is empty, don't display the column
					if ((!fieldContent || !element.content.data.rows.some((item) => item[fieldKey])) && element.content.data.rows.length !== 0) {
						return null;
					}

					return (
						// We check if there's customHeaders to display the custom columns
						scheme[fieldKey].customHeaders ? (
							// If there's customHeaders, we use the customHeaders value as the key from the data object
							element.content.data[fieldContent.customHeaders].map((customHeaderData, index) => (
								<View
									key={hash({ item: customHeaderData, index, field: fieldKey })}
									style={[styles.column, { width: `${100 / numberOfColumns}%` }]}
								>
									{/* We use the customHeadersFields to iterate over every subcolumn we need to show */}
									{fieldContent.customHeadersFields.map((customFieldKey) => (
										<View
											key={hash({ value: customHeaderData[customFieldKey], item: customHeaderData })}
											style={styles.tableCellHeader}
										>
											<Text>
												{customHeaderData[customFieldKey]}
											</Text>
										</View>
									))}
								</View>
							))
						)
							: (
								<View
									key={hash(fieldContent)}
									style={[styles.column, { width: `${100 / numberOfColumns}%` }]}
								>
									<Text style={styles.tableCellHeader}>
										{t(fieldContent.name)}
									</Text>
								</View>
							));
				})}
			</View>
			{element.content.data?.rows?.map(
				(dataRow, rowIndex) => {
					if (!dataRow) {
						return null;
					}

					const isTotalLine = hasTotalLine && rowIndex === element.content.data.rows.length - 1;

					return (
						<View style={isTotalLine ? styles.totalTableRow : styles.tableRow} key={hash(dataRow)}>
							{element.content?.fields.map((fieldKey) => {
								const fieldContent = scheme[fieldKey];

								// If every row is empty, don't display the column
								if (!fieldContent || !element.content.data.rows.some((item) => item[fieldKey])) {
									return null;
								}

								const { totalStyle } = element.content.data;
								const emptyTotalCellStyle = { backgroundColor: 'transparent' };

								// This allows us to take customHeaders into considerations for quotations mainly
								return fieldContent.customHeaders
									? element.content.data[fieldContent.customHeaders].map((column, index) => (
										<View
											key={hash({ column, item: dataRow[fieldKey], index })}
											style={[styles.column, { width: `${100 / numberOfColumns}%` }]}
										>
											<View
												style={[
													styles.tableCell,
													(isTotalLine && dataRow[fieldKey][index] ? totalStyle : emptyTotalCellStyle),
													fieldContent.style && dataRow[fieldKey][index] ? fieldContent.style : {},
												]}
											>
												{/* This allows us to apply the format to the cell */}
												{fieldContent.format?.(dataRow[fieldKey][index])?.map((value) => (
													<Text style={!isTotalLine ? styles.cellText : styles.totalCellText} key={hash({ value, fieldContent })}>{value}</Text>
												)) ?? <Text style={!isTotalLine ? styles.cellText : styles.totalCellText}>{dataRow[fieldKey][index]}</Text> ?? '-'}
											</View>
										</View>
									))
									: (
										<View
											key={[dataRow[fieldKey], hash(fieldContent)]}
											style={[styles.column, { width: `${100 / numberOfColumns}%` }]}
										>
											<View
												style={[
													styles.tableCell,
													(isTotalLine && dataRow[fieldKey] ? totalStyle : emptyTotalCellStyle),
													fieldContent.style && dataRow[fieldKey] ? fieldContent.style : {},
												]}
											>
												{/* This allows us to apply the format to the cell */}
												{fieldContent.format?.(dataRow[fieldKey])?.map((value) => (
													<Text style={!isTotalLine ? styles.cellText : styles.totalCellText} key={hash({ value, fieldContent })}>{value}</Text>
												))
										?? <Text style={!isTotalLine ? styles.cellText : styles.totalCellText}>{dataRow[fieldKey]}</Text>
										?? <Text style={!isTotalLine ? styles.cellText : styles.totalCellText}>-</Text>}
											</View>
										</View>
									);
							})}
						</View>
					);
				}
			)}
		</View>
	);
};

Table.PdfElement.propTypes = {
	style: PropTypes.object,
	element: PropTypes.shape({
		content: PropTypes.shape({
			fields: PropTypes.arrayOf(PropTypes.string).isRequired,
			data: PropTypes.object.isRequired,
		}).isRequired,
		format: PropTypes.object,
		tableLinkedEntity: PropTypes.string,
	}).isRequired,
};

Table.PdfElement.defaultProps = {
	style: undefined,
};

Table.PdfElement.displayName = 'Table';

export default Table;
