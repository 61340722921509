import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { extractOptionsEnums } from 'constants/exportEnums';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { useSubmitButton } from 'components/shared/forms';
import DynamicForm from 'components/shared/forms/DynamicForm';
import { Checkbox, DateInput, HiddenInput, Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import {
	exportFormats as getExportFormats,
	exportSeparators as getExportSeparators,
} from './functions';

/**
 * @name ExportForm
 * @description A form used to export entities as a CSV or Excel file.
 *
 * @author Romaric Barthe
 *
 * @param {string} 		entity					The entity to retrieve the data from.
 * @param {function} 	onSubmit				The method to trigger upon form submission.
 * @param {array} 		selectedElementsIds		The array of selected elements ids.
 * @param {filters} 	filters					The filters.
 */
const ExportForm = ({ entity, onSubmit, selectedElementsIds, filters }) => {
	const { t } = useTranslation();

	const { formProps, buttonProps } = useSubmitButton();

	const formats = useMemo(() => getExportFormats().map(({ id, label }) => ({ id, label: t(label) })), [t]);
	const separators = useMemo(() => getExportSeparators().map(({ id, label }) => ({ id, label: t(label) })), [t]);

	const [isCsvFormat, setCsvFormat] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { parentId, ...newFilters } = filters;

	const handleSubmit = useCallback(async (...params) => {
		const submitData = {
			selectedElementsIds: params[0].elementsSelected ? selectedElementsIds : null,
			filters: params[0].elementsFiltered && !params[0].elementsSelected ? filters : {},
			parentId,
			...params[0],
		};
		setIsLoading(true);
		await onSubmit(submitData);
		setIsLoading(false);
	}, [onSubmit, selectedElementsIds, filters, parentId]);

	/**
	 * @function
	 * @name onFormatChange
	 * @description Function called when the file format select's value changes to make the separator select visible if needed.
	 *
	 * @author Romaric Barthe
	 */
	const onFormatChange = useCallback((value) => setCsvFormat(value === extractOptionsEnums.export.formats.CSV), []);

	const defaultValues = useMemo(() => ({
		optionalFields: true,
	}), []);

	return (
		<DynamicForm onSubmit={handleSubmit} defaultValues={defaultValues} {...formProps}>
			<HiddenInput
				name="dataType"
				value={entity}
			/>

			<Checkbox
				key="optionalFields"
				label={t('template.export.inputs.information')}
				name="optionalFields"
				labelFirst={false}
			/>
			<Checkbox
				key="linkedEntites"
				label={t('template.export.inputs.linked_information')}
				name="linkedEntites"
				labelFirst={false}
			/>
			{[extractOptionsEnums.export.dataTypes.PARTNERS, extractOptionsEnums.export.dataTypes.TARGET_PARTNERS].includes(entity) && (
				<Checkbox
					key="contactsForPartners"
					id="contactsForPartners"
					label={t('template.export.inputs.contact')}
					name="contactsForPartners"
					labelFirst={false}
				/>
			)}
			<Checkbox
				key="masterData"
				label={t('template.export.inputs.master_data')}
				name="masterData"
				labelFirst={false}
			/>

			{selectedElementsIds?.length > 0 && (
				<Checkbox
					key="elementsSelected"
					label={t('template.export.inputs.selected_elements', { count: selectedElementsIds?.length })}
					name="elementsSelected"
					labelFirst={false}
				/>
			)}

			{Object.keys(newFilters).length > 0 && !selectedElementsIds?.length > 0 && (
				<Checkbox
					key="elementsFiltered"
					label={t('template.export.inputs.filtered_elements')}
					name="elementsFiltered"
					labelFirst={false}
				/>
			)}

			<DateInput
				allowNull
				label={t('template.export.inputs.updated_from')}
				name="updatedFrom"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
			<DateInput
				allowNull
				label={t('template.export.inputs.updated_to')}
				name="updatedTo"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>

			<Select
				name="format"
				label={t('template.export.inputs.format.label')}
				rules={{
					required: Validators.isRequired(t('template.export.inputs.format.validation_errors.required')),
				}}
				options={formats}
				labelKey="label"
				valueKey="id"
				onChange={onFormatChange}
			/>

			{isCsvFormat && (
				<Select
					name="separator"
					label={t('template.export.inputs.separator.label')}
					rules={{
						required: Validators.isRequired(t('template.export.inputs.separator.validation_errors.required')),
					}}
					options={separators}
					labelKey="label"
					valueKey="id"
				/>
			)}

			<Button type="submit" isLoading={isLoading} {...buttonProps}>{t('template.export.action')}</Button>
		</DynamicForm>
	);
};

ExportForm.propTypes = {
	entity: PropTypes.string.isRequired,
	filters: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	selectedElementsIds: PropTypes.arrayOf(PropTypes.string),
};

ExportForm.defaultProps = {
	filters: {},
	selectedElementsIds: undefined,
};

export default ExportForm;
