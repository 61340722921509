import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchStatusList } from 'redux/actions/statuses';
import { useStatusListSelector, useStatusTotalCountSelector } from 'redux/selectors/statuses';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import { ActionsCell } from 'components/statuses/statusList';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name StatusListPage
 * @description A list of all statuses accessible from the settings app in sub part statuses.
 *
 * From this page, the user is able to view, interact and search for statuses.
 *
 * @author Romaric Barthe
 */
const StatusListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const statuses = useStatusListSelector();
	const totalCount = useStatusTotalCountSelector();

	const canCreateStatus = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);

	const headers = useMemo(() => [
		{ id: 'name', accessor: 'name', Header: t('status.fields.name'), pinColumn: true, sorting: 'status.name' },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('status.headers.actions') },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('status.fields.archived.yes') : t('status.fields.archived.no')),
			Header: t('status.fields.archived.header'),
			sorting: 'status.archived',
		},
		{ id: 'description', accessor: 'description', Header: t('status.fields.description'), sorting: 'status.description' },
		{ id: 'defaultColor', accessor: 'defaultColor', Header: t('status.fields.defaultColor'), sorting: 'status.defaultColor' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('status.fields.creation_date'), sorting: 'status.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('status.fields.update_date'), sorting: 'status.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('status.fields.creation_name'), sorting: 'status.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('status.fields.update_name'), sorting: 'status.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchStatusList(params)));
	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('status');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.settings.statuses.statusCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateStatus && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('status.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={statuses}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="status"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('status.pages.list')} {...modalProps} />
		</div>
	);
};

export default StatusListPage;
