import * as CurrenciesApi from 'api/currenciesApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Currencies redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a list of currencies
	FETCH_CURRENCY_LIST_REQUEST: '@CURRENCIES/FETCH_LIST_REQUEST',
	FETCH_CURRENCY_LIST_SUCCESS: '@CURRENCIES/FETCH_LIST_SUCCESS',
	FETCH_CURRENCY_LIST_FAILURE: '@CURRENCIES/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// //////////// Currency list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchCurrencyListRequest
 * @description Action triggered anytime a currency list call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const fetchCurrencyListRequest = () => ({ type: ActionTypes.FETCH_CURRENCY_LIST_REQUEST });

/**
 * @function
 * @name fetchCurrencyListSuccess
 * @description Action triggered as a result to a successful currency list fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} companies	 The list of retrieved currencies.
 * @param {number} totalCount	 The total amount of currencies available in the database to the current user.
 *
 * @returns {object}
 */
const fetchCurrencyListSuccess = ({ currencies, totalCount }) => ({
	type: ActionTypes.FETCH_CURRENCY_LIST_SUCCESS,
	payload: { currencies, totalCount },
});

/**
 * @function
 * @name fetchCurrencyListFailure
 * @description Action triggered as a result to a failed currency list fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchCurrencyListFailure = (error) => ({
	type: ActionTypes.FETCH_CURRENCY_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////////// Exported action creatiors ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchCurrencyList
 * @description Method used to update the company list.
 *
 * @author Timothée Simon-Franza
 */
export const fetchCurrencyList = () => (dispatch) => {
	dispatch(fetchCurrencyListRequest());

	return CurrenciesApi.fetchCurrencies()
		.then(({ currencies, totalCount }) => dispatch(fetchCurrencyListSuccess({ currencies, totalCount })))
		.catch((error) => dispatch(fetchCurrencyListFailure(error)));
};
