import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowDown, ArrowUp, Copy, XCircle } from 'react-feather';
import { expenseColumnHeader } from 'constants/expenseEnums';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DateInput, HiddenInput, NumberInput, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { getVatRateValue } from './functions';

/**
 * @name ExpenseGridRow
 * @description A form used to edit an existing expense's information.
 *
 * @author Romaric Barthe
 *
 * @param {array}	accounts				The list of accounts.
 * @param {array}	columnsHeadersToDisplay	The array of the headers to be displayed.
 * @param {array}	currencies				The list of currencies.
 * @param {string}	currencyName			The ISO name of the currency in the invoice.
 * @param {string}	currencySymbol			The symbol of the currency.
 * @param {object}	defaultValues			The default values of the expense.
 * @param {bool}	formDisabled			Whether the form (actions) should be disable.
 * @param {bool}	isLoading				Whether the data are still loading.
 * @param {array}	projects				The list of projects.
 * @param {bool}	readOnly				Whether the expense is read-only.
 * @param {object}	row						The rows of the expense.
 * @param {number}	rowItems				The number of rows.
 * @param {array}	vatRates				The list of vat Rates.
 * @param {func}	updateRow				The method to add/ move/ delete/ duplicate a line.
 * @param {func}	setTotals				The method to set the total of the expenses.
 * @param {func}	originalRowData			If set, the data of the row that is duplicated.
 */
const ExpenseGridRow = ({
	accounts,
	columnsHeadersToDisplay,
	currencies,
	currencyName,
	currencySymbol,
	defaultValues,
	formDisabled,
	isLoading,
	projects,
	readOnly,
	row,
	rowItems,
	vatRates,
	// functions
	updateRow,
	setTotals,
	// duplicate logic
	originalRowData,
}) => {
	const [netCost, setNetCost] = useState(defaultValues?.rows?.[row.id]?.netCost || 0);
	const [vatRateId, setVatRateId] = useState(defaultValues?.rows?.[row.id]?.vatRate || undefined);

	const isInitialized = useRef(false);

	const vatRateValue = useMemo(() => getVatRateValue(vatRates, vatRateId), [vatRates, vatRateId]);
	const grossTotalForLine = useMemo(() => netCost / (1 + vatRateValue), [netCost, vatRateValue]);

	const setNetCostValue = useCallback((value) => {
		setNetCost(value);
		setTotals();
	}, [setNetCost, setTotals]);
	const setVatRate = useCallback((value) => {
		setVatRateId(value);
		setTotals();
	}, [setVatRateId, setTotals]);

	const accountRef = useRef();
	const currencyRef = useRef();
	const dateRef = useRef();
	const designationRef = useRef();
	const localNetCostRef = useRef();
	const netCostRef = useRef();
	const projectRef = useRef();
	const vatRateRef = useRef();

	useEffect(() => {
		if (!isInitialized.current) {
			isInitialized.current = true;

			if (!originalRowData) {
				return;
			}

			if (originalRowData.account && originalRowData.account !== null) {
				accountRef.current?.setValue(originalRowData.account);
			}
			if (originalRowData.currency && originalRowData.currency !== null) {
				currencyRef.current?.setValue(originalRowData.currency);
			}
			if (originalRowData.date && originalRowData.date !== undefined) {
				dateRef.current?.setValue(originalRowData.date);
			}
			designationRef.current?.setValue(originalRowData.designation);
			localNetCostRef.current?.setValue(originalRowData.localNetCost);
			netCostRef.current?.setValue(originalRowData.netCost);
			if (originalRowData.project && originalRowData.project !== null) {
				projectRef.current?.setValue(originalRowData.project);
			}
			if (originalRowData.vatRate && originalRowData.vatRate !== null) {
				vatRateRef.current?.setValue(originalRowData.vatRate);
			}
		}
	}, [originalRowData]);

	return (
		<tr key={`tr.${row.id}`}>
			{columnsHeadersToDisplay.filter((elem) => elem.show).map((header) => {
				switch (header.label) {
					case expenseColumnHeader.ACCOUNT:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<Select
									ref={accountRef}
									allowNull
									isLoading={isLoading}
									label=""
									labelKey="description"
									name={`rows.${row.id}.${header.label}`}
									options={accounts}
									readOnly={readOnly}
									valueKey="id"
								/>
							</td>
						);
					case expenseColumnHeader.CURRENCY:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<Select
									ref={currencyRef}
									allowNull
									isLoading={isLoading}
									label=""
									labelKey="name"
									name={`rows.${row.id}.${header.label}`}
									options={currencies}
									readOnly={readOnly}
									valueKey="id"
								/>
							</td>
						);
					case expenseColumnHeader.PROJECT:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<Select
									ref={projectRef}
									allowNull
									isLoading={isLoading}
									label=""
									labelKey="name"
									name={`rows.${row.id}.${header.label}`}
									options={projects}
									readOnly={readOnly}
									valueKey="id"
								/>
							</td>
						);
					case expenseColumnHeader.VAT_RATE:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<Select
									ref={vatRateRef}
									allowNull
									isLoading={isLoading}
									label=""
									labelKey="rate"
									name={`rows.${row.id}.${header.label}`}
									onChange={setVatRate}
									options={vatRates}
									readOnly={readOnly}
									valueKey="id"
								/>
							</td>
						);
					case expenseColumnHeader.LOCAL_NET_COST:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={localNetCostRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									label=""
									name={`rows.${row.id}.${header.label}`}
									readOnly={readOnly}
									step={0.01}
								/>
							</td>
						);
					case expenseColumnHeader.NET_COST:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<NumberInput
									ref={netCostRef}
									className="number-input"
									decimalScale={2}
									fixedDecimalScale
									icon={currencyName}
									label=""
									name={`rows.${row.id}.${header.label}`}
									onChange={setNetCostValue}
									readOnly={readOnly}
									step={0.01}
									style={{ textAlign: 'end' }}
								/>
							</td>
						);
					case expenseColumnHeader.DATE:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<DateInput
									ref={dateRef}
									allowNull
									label=""
									name={`rows.${row.id}.${header.label}`}
									readOnly={readOnly}
								/>
							</td>
						);
					case expenseColumnHeader.DESIGNATION:
						return (
							<td key={`td.${row.id}.${header.label}`}>
								<TextInput
									ref={designationRef}
									label=""
									name={`rows.${row.id}.${header.label}`}
									rules={{
										required: Validators.isRequired('trigger_red_borders'),
									}}
									readOnly={readOnly}
								/>
							</td>
						);
					case expenseColumnHeader.TOTAL:
						return (
							<td key={`td.${row.id}.${header.label}`} className="cell-total">
								{formatNumber(grossTotalForLine.toFixed(2), { symbol: currencySymbol })}
							</td>
						);
					case expenseColumnHeader.ACTIONS:
						return (
							readOnly ? null : (
								<td key={`td.${row.id}.${header.label}`} className="row-actions">
									<Button
										className="icon-only"
										onClick={() => updateRow('move', row.id, row.line, 1)}
										disabled={row.line === rowItems || formDisabled}
									>
										<ArrowDown />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateRow('move', row.id, row.line, -1)}
										disabled={row.line === 1 || formDisabled}
									>
										<ArrowUp />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateRow('duplicate', row.id, row.line)}
										disabled={formDisabled}
									>
										<Copy />
									</Button>
									<Button
										className="icon-only"
										onClick={() => updateRow('delete', row.id)}
										disabled={formDisabled}
									>
										<XCircle />
									</Button>
									<HiddenInput
										name={`rows.${row.id}.line`}
										value={row.line}
									/>
								</td>
							)
						);
					default:
						return (<td key={`td.${row.id}.default`}>##</td>);
				}
			})}
		</tr>
	);
};

ExpenseGridRow.propTypes = {
	accounts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			description: PropTypes.string,
		}),
	).isRequired,
	columnsHeadersToDisplay: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			show: PropTypes.bool,
		}),
	).isRequired,
	currencyName: PropTypes.string,
	currencySymbol: PropTypes.string,
	currencies: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		}),
	).isRequired,
	defaultValues: PropTypes.shape({
		rows: PropTypes.shape({
			id: PropTypes.string,
			netCost: PropTypes.number,
			vatRate: PropTypes.object,
		}),
	}),
	formDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			name: PropTypes.string,
		}),
	).isRequired,
	readOnly: PropTypes.bool,
	row: PropTypes.shape({
		id: PropTypes.string,
		line: PropTypes.number,
	}).isRequired,
	rowItems: PropTypes.number.isRequired,
	vatRates: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			rate: PropTypes.number,
		}),
	).isRequired,
	// functions
	updateRow: PropTypes.func.isRequired,
	setTotals: PropTypes.func.isRequired,
	// duplicate logic,
	originalRowData: PropTypes.object,
};

ExpenseGridRow.defaultProps = {
	currencyName: '',
	currencySymbol: '',
	defaultValues: undefined,
	formDisabled: false,
	readOnly: false,
	isLoading: true,
	originalRowData: undefined,
};

export default ExpenseGridRow;
