import { formatNumber } from 'lib/shared/format';
import moment from 'moment';

/** Guide:
 * Each entry can have these properties:
 * - name: the i18n key for the field name
 * - width: the width of the column in % (default: 100)
 * - format: a function to format the value always returning an array
 * - style: an object with the style of the cell
 * - customHeaders: The name of the value key to use as header
 * - customHeadersFields: The fields composing the header & subheader
 *
 * For example:
headerKey: {
  	name: 'pdfEditor.tableFields.name',
	[width]: 10,
	[format]: (value) => [value, '€'],
	[style]: { alignment: 'right' },
}
 *
 * If a Table needs to have a variable number of columns we add those in the definition:
headerKey: {
  	name: 'pdfEditor.customTableFields.name',
	customHeaders: 'valueKey',
	customHeadersFields: ['colHeaderName', 'subHeaderName'],
}

* in the data object this will be stored as:
valueKey: [{
	colHeaderName: 'colName1',
	subHeaderName: 'subColName1',
},
{
	colHeaderName: 'colName2',
	subHeaderName: 'subColName2',
}]
* allowing us to have 2 variable columns defined in the data object
*/

/**
 * @constant
 * @name ExpenseRowsTableFields
 * @description An object containing all the fields used in the expense table
 *
 * Note: when adding or editing a default table fields, remember to wire it to the {@link formatExpenseForTemplates} logic
 *
 * @author Romaric Barthe
 */
export const ExpenseRowsTableFields = Object.freeze({
	designation: {
		name: 'expense.headers.designation',
		width: '40',
	},
	account: {
		name: 'expense.headers.account',
		width: '16',
	},
	date: {
		name: 'expense.headers.date',
		width: '8',
		format: (value) => [(value ? moment(value).format('L') : '')],
	},
	project: {
		name: 'expense.headers.project',
		width: '16',
		format: (value) => [(value ? [value] : '')],
	},
	vatRate: {
		name: 'expense.headers.vatRate',
		width: '10',
		format: (value) => [(value ? [`${value} %`] : '')],
		style: { textAlign: 'right' },
	},
	netCost: {
		name: 'expense.headers.netCost',
		width: '10',
		format: (value) => (value ? [`${formatNumber(value[0])} ${value[1]}`] : ['']),
		style: { textAlign: 'right', backgroundColor: '#eeeeee' },
	},
});

/**
 * @constant
 * @name ExpenseTotalsTableFields
 * @description An object containing all the fields used in the expense totals table
 *
 * Note: when adding or editing a default table fields, remember to wire it to the {@link formatExpenseTotalsForTemplates} logic
 *
 * @author Romaric Barthe
 */
export const ExpenseTotalsTableFields = Object.freeze({
	grossTotal: {
		name: 'expense.headers.total_without_vat',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	vatTotal: {
		name: 'expense.headers.total_vat',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	netTotal: {
		name: 'expense.headers.total_with_vat',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
});

/**
 * @constant
 * @name InvoiceAdvancementTableFields
 * @description An object containing all the fields used in the advancement invoice table
 *
 * Note: when adding or editing an default table fields, remember to wire it to the {@link formatInvoiceForTemplates} logic
 *
 * @author Florian Fornazaric
 */
export const InvoiceAdvancementTableFields = Object.freeze({
	designation: {
		name: 'invoice.headers.designation',
		width: '15',
	},
	description: {
		name: 'invoice.headers.description',
		width: '14',
	},
	base_amount: {
		name: 'invoice.headers.baseAmount',
		width: '14',
		format: (value) => (value ? [`${formatNumber(value[0])} ${value[1]}`] : undefined),
		style: { textAlign: 'right' },
	},
	already_invoiced: {
		name: 'invoice.headers.alreadyInvoiced',
		width: '14',
		format: (value) => (value ? [`${value[0]} %`, `${formatNumber(value[1])}${value[2]}`] : undefined),
		style: { textAlign: 'right' },
	},
	currently_invoiced: {
		name: 'invoice.headers.currentlyInvoiced',
		width: '14',
		format: (value) => (value ? [`${value[0]} %`, `${formatNumber(value[1])}${value[2]}`] : undefined),
		style: { textAlign: 'right' },
	},
	discount: {
		name: 'invoice.headers.discount',
		width: '14',
		format: (value) => (value ? [`${value[0]} %`, `${formatNumber(value[1])}${value[2]}`] : undefined),
		style: { textAlign: 'right' },
	},
	total: {
		name: 'invoice.headers.total',
		width: '14',
		format: (value) => (value ? [`${formatNumber(value[0])}${value[1]}`] : undefined),
		style: { textAlign: 'right', backgroundColor: '#eeeeee' },
	},
});

/**
 * @constant
 * @name InvoiceUnitaryTableFields
 * @description An object containing all the fields used in the unitary invoice table
 *
 * Note: when adding or editing an default table fields, remember to wire it to the {@link formatInvoiceForTemplates} logic
 *
 * @author Florian Fornazaric
 */
export const InvoiceUnitaryTableFields = Object.freeze({
	designation: {
		name: 'invoice.headers.designation',
		width: '15',
	},
	description: {
		name: 'invoice.headers.description',
		width: '14',
	},
	price: {
		name: 'invoice.headers.unitaryCost',
		width: '14',
		format: (value) => (value ? [`${formatNumber(value[0])} ${value[1]}`] : undefined),
		style: { textAlign: 'right' },
	},
	quantity: {
		name: 'invoice.headers.quantity',
		width: '14',
		style: { textAlign: 'right' },
	},
	discount: {
		name: 'invoice.headers.discount',
		width: '14',
		format: (value) => (value ? [`${value[0]} %`, `${formatNumber(value[1])} ${value[2]}`] : undefined),
		style: { textAlign: 'right' },
	},
	total: {
		name: 'invoice.headers.total',
		width: '14',
		format: (value) => (value ? [`${formatNumber(value[0])}${value[1]}`] : undefined),
		style: { textAlign: 'right', backgroundColor: '#eeeeee' },
	},
});

/**
 * @constant
 * @name InvoiceTotalsTableFields
 * @description An object containing all the fields used in the invoice totals table
 *
 * Note: when adding or editing an default table fields, remember to wire it to the {@link formatInvoiceTotalsForTemplates} logic
 *
 * @author Florian Fornazaric
 */
export const InvoiceTotalsTableFields = Object.freeze({
	grossTotal: {
		name: 'invoice.headers.grossTotal',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	discount: {
		name: 'invoice.headers.discount',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	discountedTotal: {
		name: 'invoice.headers.discountedTotal',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	vatTotal: {
		name: 'invoice.headers.vatTotal',
		format: (value) => (value ? [`${value[0]} %`, `${formatNumber(value[1])} ${value[2]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	netTotal: {
		name: 'invoice.headers.netTotal',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
});

/**
 * @constant
 * @name QuotationArchitectTableFields
 * @description An object containing all the fields used in the architect quotation table
 *
 * Note: when adding or editing an default table fields, remember to wire it to the {@link formatQuotationForTemplates} logic
 *
 * @author Florian Fornazaric
 */
export const QuotationArchitectTableFields = Object.freeze({
	phase: {
		name: 'quotation.headers.phase',
	},
	documents: {
		name: 'quotation.headers.documents',
	},
	values: {
		customHeaders: 'companies',
		customHeadersFields: ['sector', 'name'],
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'right' },
	},
	total: {
		name: 'quotation.headers.total',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'right', backgroundColor: '#eeeeee' },
	},
});

/**
 * @constant
 * @name QuotationArchitectColumnFields
 * @description An object containing all the fields used in the architect quotation table
 *
 * Note: when adding or editing an default table fields, remember to wire it to the {@link formatQuotationColumnsForTemplates} logic
 *
 * @author Romaric Barthe
 */
export const QuotationArchitectColumnFields = Object.freeze({
	partnerSector: {
		name: 'quotation.headers.sector',
	},
	partnerName: {
		name: 'quotation.headers.partner',
	},
	partnerAmount: {
		name: 'quotation.headers.partner_amount',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'right' },
	},
	partnerRatio: {
		name: 'quotation.headers.partner_ratio',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'right' },
	},
	partnerDiscount: {
		name: 'quotation.headers.discount',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'right' },
	},
});

/**
 * @constant
 * @name QuotationStandardTableFields
 * @description An object containing all the fields used in the normal quotation table
 *
 * Note: when adding or editing an default table fields, remember to wire it to the {@link formatQuotationForTemplates} logic
 *
 * @author Florian Fornazaric
 */
export const QuotationStandardTableFields = Object.freeze({
	description: {
		name: 'quotation.headers.description',
	},
	unitaryCost: {
		name: 'quotation.headers.unitaryCost',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'right' },
	},
	quantity: {
		name: 'quotation.headers.quantity',
		style: { textAlign: 'right' },
	},
	discount: {
		name: 'quotation.headers.discount',
		format: (value) => (value ? [`${value[0]} %`, `${value[1] ? formatNumber(value[1]) : '-'} ${value[2]}`] : undefined),
		style: { textAlign: 'right' },
	},
	total: {
		name: 'quotation.headers.total',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'right', backgroundColor: '#eeeeee' },
	},
	vat: {
		name: 'quotation.headers.vatRate',
		format: (value) => (value ? [`${value} %`] : undefined),
		style: { textAlign: 'right' },
	},
});

/**
 * @constant
 * @name QuotationTotalsTableFields
 * @description An object containing all the fields used in the quotation totals table
 *
 * Note: when adding or editing an default table fields, remember to wire it to the {@link formatQuotationTotalsForTemplates} logic
 *
 * @author Florian Fornazaric
 */
export const QuotationTotalsTableFields = Object.freeze({
	baseTotal: {
		name: 'quotation.headers.grossTotalBeforeDiscount',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	discount: {
		name: 'quotation.headers.totalDiscount',
		format: (value) => (value && value[0] !== 0 ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	grossTotal: {
		name: 'quotation.headers.grossTotal',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	vatTotal: {
		name: 'quotation.headers.vatTotal',
		// This ternary is in case the vatTotal isn't set globally for an invoice
		format: (value) => {
			if (value) {
				return (value[0] ? [`${value[0]}%`, `${value[1] ? formatNumber(value[1]) : '-'} ${value[2]}`] : [`${value[1] ? formatNumber(value[1]) : '-'} ${value[2]}`]);
			}

			return undefined;
		},
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
	netTotal: {
		name: 'quotation.headers.netTotal',
		format: (value) => (value ? [`${value[0] ? formatNumber(value[0]) : '-'} ${value[1]}`] : undefined),
		style: { textAlign: 'center', backgroundColor: '#eeeeee' },
	},
});

/**
 * @constant
 * @name InvoiceAdvancementTableFields
 * @description An object containing all the fields used in the invoice advancement table
 *
 * Note: when adding or editing an default table fields, remember to wire it to the {@link formatInvoiceAdvancementForTemplates} logic
 *
 * @author Florian Fornazaric
 */
export const InvoiceAdvancementStatusTableFields = Object.freeze({
	totalBase: {
		name: 'invoice.headers.totalBase',
		format: (value) => (value ? [`${formatNumber(value[0])} ${value[1]}`] : undefined),
		style: { textAlign: 'right', backgroundColor: '#eeeeee' },
	},
	totalAlreadyInvoiced: {
		name: 'invoice.headers.totalAmountAlreadyInvoiced',
		format: (value) => (value ? [`${value[0] ? value[0] : '-'}%`, `${formatNumber(value[1])} ${value[2]}`] : undefined),
		style: { textAlign: 'right', backgroundColor: '#eeeeee' },
	},
	totalAmountToInvoice: {
		name: 'invoice.headers.totalAmountToInvoice',
		format: (value) => (value !== undefined ? [`${value[0] !== undefined ? value[0] : '-'}%`, `${formatNumber(value[1])} ${value[2]}`] : undefined),
		style: { textAlign: 'right', backgroundColor: '#eeeeee' },
	},
});
