import { StrictMode, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { getCurrentEnvironment, isLocalDev, isProduction } from 'lib/shared/environmentHelper';
import moment from 'moment';
import store from 'redux/store';
import RouteProvider from 'routes/components/RouteProvider';
import RouterProvider, { history } from 'routes/components/RouterProvider';

import { MainLayout } from 'components/shared/layout';

import 'pace-js';
import 'lib/shared/i18n';

import 'pace-js/themes/blue/pace-theme-minimal.css';
import '@tremor/react/dist/esm/tremor.css';
import 'styles/index.scss';
import 'styles/globals.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';

require('es6-promise').polyfill();
require('isomorphic-fetch');

Sentry.init({
	dsn: 'https://3bb30e8c62914510b378c6973586a4d0@o4504457702277120.ingest.sentry.io/4504457707061249',

	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
		}),
		new HttpClientIntegration(),
		new Sentry.Replay(),
	],

	enabled: !isLocalDev(),
	environment: getCurrentEnvironment(),
	release: process.env.COMMIT_HASH,
	tunnel: '/diagnostics',

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: isProduction() ? 0.3 : 1.0,
	// Ensure we capture the replay when an error occurs
	replaysOnErrorSampleRate: 1.0,
	// This option is required for capturing headers and cookies.
	sendDefaultPii: true,
});

document.body.addEventListener(
	'error',
	(event) => {
		if (!event.target) { return; }

		if (event.target.tagName === 'IMG') {
			Sentry.captureMessage(
				`Failed to load image: ${event.target.src}`,
				'warning'
			);
		} else if (event.target.tagName === 'LINK') {
			Sentry.captureMessage(
				`Failed to load css: ${event.target.href}`,
				'warning'
			);
		}
	},
	true // useCapture - necessary for resource loading errors
);

/**
* The application's Root component.
* Triggers Redux store initialization and serves the topmost layer of the client app.
*/
const Root = () => {
	const { t } = useTranslation();
	useEffect(() => {
		moment.updateLocale(t('lang'),
			{
				week: {
					dow: 1,
					doy: 4, // First week of year must contain 1 January (7 + 1 - 1)
				},
			});
	}, [t]);

	return (
		<Sentry.ErrorBoundary showDialog={!isLocalDev()}>
			<DndProvider backend={HTML5Backend}>
				<Provider store={store}>
					<RouterProvider>
						<MainLayout>
							<RouteProvider />
						</MainLayout>
					</RouterProvider>
				</Provider>
			</DndProvider>
		</Sentry.ErrorBoundary>
	);
};

const ProfiledRoot = Sentry.withProfiler(Root);

const root = createRoot(document.getElementById('root'));

root.render(
	<StrictMode>
		<ProfiledRoot />
	</StrictMode>
);
