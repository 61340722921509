/**
 * @function
 * @name setMovingElement
 * @description Sets the moving element
 *
 * @author Florian Fornazaric
 *
 * @param {object} state		The current state
 * @param {number} payload.id	The source id of the moving element
 *
 * @returns {object} The updated state value
 */
const setMovingElement = (state, { id }) => {
	const { elements } = state;
	const { movingElement } = state;

	const elementIndex = elements.findIndex((element) => element.id === id);

	// When moving, we store inside the source index and the element being moved
	let newMovingElement = movingElement;
	if (!movingElement) {
		newMovingElement = {
			element: state.elements[elementIndex],
			elementIndex,
		};
	}

	return {
		...state,
		movingElement: newMovingElement,
	};
};

export default setMovingElement;
