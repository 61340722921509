import PropTypes from 'prop-types';

/**
 * @name Row
 * @description The component to use as row in the virtualized table component.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*}		children			The children to pass down to the row.
 * @param {bool}	[isSelected=false]	Whether the row is selected using the useRowSelectColumn hook.
 */
const Row = ({ children, isSelected, ...otherProps }) => (
	<div role="row" className={isSelected ? 'selected' : ''} {...otherProps}>
		{children}
	</div>
);

Row.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	isSelected: PropTypes.bool,
};

Row.defaultProps = {
	isSelected: false,
};

export default Row;
