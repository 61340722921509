import * as InvoicesApi from 'api/invoicesApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the InvoicesSelect redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a list of invoices
	FETCH_INVOICE_SELECT_LIST_REQUEST: '@INVOICES_SELECT/FETCH_LIST_REQUEST',
	FETCH_INVOICE_SELECT_LIST_SUCCESS: '@INVOICES_SELECT/FETCH_LIST_SUCCESS',
	FETCH_INVOICE_SELECT_LIST_FAILURE: '@INVOICES_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Invoice list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchInvoicesSelectListRequest
 * @description Action triggered anytime a invoicesSelect list fetching call is made to the API.
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
const fetchInvoicesSelectListRequest = () => ({ type: ActionTypes.FETCH_INVOICE_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchInvoicesSelectListSuccess
 * @description Action triggered as a result to a successful invoicesSelect list fetching API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object} invoicesSelect		The list of retrieved invoices.
 * @param {number} totalCount			The total amount of invoices available in the database for the current user.
 *
 * @returns {object}
 */
const fetchInvoicesSelectListSuccess = ({ invoicesSelect, totalCount }) => ({
	type: ActionTypes.FETCH_INVOICE_SELECT_LIST_SUCCESS,
	payload: { invoicesSelect, totalCount },
});

/**
 * @function
 * @name fetchInvoicesSelectListFailure
 * @description Action triggered as a result to a failed invoicesSelect list fetching API call.
 *
 * @author Florian Fornazaric
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchInvoicesSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_INVOICE_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchInvoicesSelectList
 * @description Method used to fetch the invoicesSelect list.
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchInvoicesSelectList = (params) => (dispatch) => {
	dispatch(fetchInvoicesSelectListRequest());

	const newFilters = {
		...params,
		infoLevel: 'forSelect',
	};

	return InvoicesApi.fetchInvoices(newFilters)
		.then(({ invoicesSelect, totalCount }) => dispatch(fetchInvoicesSelectListSuccess({ invoicesSelect, totalCount })))
		.catch((error) => dispatch(fetchInvoicesSelectListFailure(error)));
};
