import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, matchPath, useLocation, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SvgIcon } from 'components/shared/utils';

/**
 * @name NavbarMenuItem
 * @description A component used inside the main navbar to list a module and its sub-modules.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string}	iconPath				The path to the menu's linked icon.
 * @param {bool}	isActive				Whether the menu is currently expanded.
 * @param {string}	name					The menu's name translation key.
 * @param {string}	[path=undefined]		The default path of the current item.
 * @param {object}	parentRef				The reference to the parent menu. Necessary to provide smooth max-height related animations.
 * @param {object}	routes					The current menu item's direct routes.
 * @param {func}	setCurrentActiveModule	Sets the current menu as active.
 * @param {object}	submenus				The current menu item's submenus.
 */
const NavbarMenuItem = ({ iconPath, isActive, name, parentRef, path, routes, setCurrentActiveModule, submenus }) => {
	const { t } = useTranslation();
	const submenuRef = useRef(null);
	const { pathname: currentPathname } = useLocation();
	const containsCurrentRoute = !!useRouteMatch({ path, strict: false, sensitive: false });
	const [open, setOpen] = useState(isActive || containsCurrentRoute);

	/**
	 * @function
	 * @name toggleParentExpansion
	 * @description Sets the parent component's maxHeight css property when expanding the current element. Used for animation purposes.
	 *
	 * @author Timothée Simon-Franza
	 */
	const toggleParentExpansion = useCallback(() => {
		submenuRef.current.style.maxHeight = open ? '0px' : `${submenuRef.current.scrollHeight}px`;

		if (parentRef?.current) {
			// eslint-disable-next-line no-param-reassign
			parentRef.current.style.maxHeight = open
				? `${parentRef.current.scrollHeight - submenuRef.current.scrollHeight}px`
				: `${parentRef.current.scrollHeight + submenuRef.current.scrollHeight}px`;
		}
	}, [open, parentRef]);

	/**
	 * Automatically expands the submenu if it contains the link to the current url.
	 */
	useEffect(() => {
		if (containsCurrentRoute) {
			submenuRef.current.style.maxHeight = `${submenuRef.current.scrollHeight}px`;
		}
	}, [containsCurrentRoute]);

	const onButtonClick = useCallback(() => {
		setCurrentActiveModule(name);
		toggleParentExpansion();
		setOpen(!open);
	}, [name, open, setCurrentActiveModule, toggleParentExpansion]);

	return (
		<li className={open ? 'open' : ''}>
			<button type="button" onClick={onButtonClick}>
				{iconPath && (<SvgIcon path={iconPath} />)}
				{t(name)}
			</button>
			<ul ref={submenuRef} className="submenu">
				{routes?.map(({ path: routePath, name: subrouteName }) => (
					<li key={routePath}>
						<Link
							to={{ pathname: routePath, state: { fromNavbar: true } }}
							{... matchPath(currentPathname, { path: routePath, strict: true, exact: true }) ? { 'aria-current': 'page' } : {}}
						>
							{t(subrouteName)}
						</Link>
					</li>
				))}
				{Object.values(submenus ?? {}).map((submenu) => (
					<NavbarMenuItem
						key={submenu.path}
						isActive={!!matchPath(currentPathname, { path: submenu.path, strict: false, sensitive: false })}
						setCurrentActiveModule={setCurrentActiveModule}
						parentRef={submenuRef}
						{...submenu}
					/>
				))}
			</ul>
		</li>
	);
};

NavbarMenuItem.propTypes = {
	iconPath: PropTypes.string,
	isActive: PropTypes.bool,
	name: PropTypes.string.isRequired,
	path: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		path: PropTypes.string.isRequired,
	})),
	submenus: PropTypes.objectOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		path: PropTypes.string.isRequired,
		routes: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
			path: PropTypes.string.isRequired,
		})),
	})),
	parentRef: PropTypes.oneOfType([
		PropTypes.shape({
			current: PropTypes.instanceOf(Element),
		}),
		PropTypes.object,
	]),
	setCurrentActiveModule: PropTypes.func.isRequired,
};

NavbarMenuItem.defaultProps = {
	iconPath: undefined,
	isActive: false,
	path: undefined,
	routes: undefined,
	submenus: undefined,
	parentRef: undefined,
};

export default NavbarMenuItem;
