import update from 'immutability-helper';

import { createEntity } from './internals';

/**
 * @function
 * @name addEntity
 * @description Adds a new entity
 *
 * @author Yann Hodiesne
 *
 * @param {object} state	The current state
 *
 * @returns {object} The updated state value
 */
const addEntity = (state) => {
	const result = {
		...state,
		entities: update(state.entities, {
			$push: [createEntity()],
		}),
		isDirty: true,
	};

	return result;
};

export default addEntity;
