import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchAccounts
 * @description Retrieves a list of accounts from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchAccounts = (params) => post('/api/accounts', params);

/**
 * @function
 * @name fetchAccountById
 * @description Retrieves an existing account from the API, using the accountId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} accountId        The id of the account we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchAccountById = (accountId) => get(`/api/account/${accountId}`);

/**
 * @function
 * @name createAccount
 * @description Creates a new account into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} accountData      The data to create the account from.
 *
 * @returns {Promise}
 */
export const createAccount = (accountData) => post('/api/accounts/create', accountData);

/**
 * @function
 * @name updateAccount
 * @description Updates an existing account from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} accountData      The data to update the account with.
 * @param {string} accountId        The id to identify the account with.
 *
 * @returns {Promise}
 */
export const updateAccount = (accountData, accountId) => put(`/api/account/${accountId}`, accountData);

/**
 * @function
 * @name deleteAccount
 * @description Removes an existing account from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} accountId        The id of the account we want to remove.
 *
 * @returns {Promise}
 */
export const deleteAccount = (accountId) => del(`/api/account/${accountId}`);
