import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import conf from 'conf';
import { login } from 'redux/actions/users';

import { SvgIcon } from 'components/shared/utils';
import { SignInForm } from 'components/users';

/**
 * @name SignInPage
 * @description A page used by users to authenticate themselves.
 *
 * @author Timothée Simon-Franza
 *
 */
const SignInPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleSubmit = useCallback(({ username, password }) => {
		dispatch(login({ username, password }));
	}, [dispatch]);

	return (
		<>
			<div>
				<SvgIcon path={`${conf.iconsPath}logo_white.svg`} />
			</div>
			<div>
				<h1>{t('login.title')}</h1>
				<p>{t('login.subtitle')}</p>
				<SignInForm onSubmit={handleSubmit} />
			</div>
		</>
	);
};

export default SignInPage;
