import PropTypes from 'prop-types';

/**
 * @name TableHead
 * @description Component use to display the dynamic table's header elements.
 *
 * @author Timothée Simon-Franza
 *
 * @param {Array} headerGroups	An array containing all headers and their characteristics. Retrieved from react-table.
 */
const TableHead = ({ headerGroups }) => (
	<div role="rowgroup" className="header-row">
		{headerGroups.map((headerGroup) => (
			// `react-table` already handles the key props, but eslint expects them to be explicit
			// eslint-disable-next-line react/jsx-key
			<div role="row" {...headerGroup.getHeaderGroupProps()}>
				{headerGroup.headers.map((header) => (
					// `react-table` already handles the key props, but eslint expects them to be explicit
					// eslint-disable-next-line react/jsx-key
					<div
						role="columnheader"
						{...header.getHeaderProps()}
						data-header-id={header.id}
						data-testid={`table-header-${header.id}`}
						className={header.headerCellClassName ?? ''}
					>
						<span data-testid={`table-header-span-${header.id}`} {...header.getHeaderContentProps()}>
							{header.render('Header')}
						</span>
						<div className="column-resizer" {...header.getResizerProps()} />
					</div>
				))}
			</div>
		))}
	</div>
);

TableHead.propTypes = {
	headerGroups: PropTypes.arrayOf(PropTypes.shape({
		headers: PropTypes.arrayOf(PropTypes.shape({
			Header: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.func,
			]),
			id: PropTypes.string,
			sorting: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.bool,
			]),
			canResize: PropTypes.bool,
			pinColumn: PropTypes.bool,
		})).isRequired,
		getHeaderGroupProps: PropTypes.func.isRequired,
		getFooterGroupProps: PropTypes.func.isRequired,
	})).isRequired,
};

export default TableHead;
