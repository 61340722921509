import update from 'immutability-helper';

/**
 * @function
 * @name updateMargins
 * @description Updates the content of the margins
 *
 * @author Florian Fornazaric
 *
 * @param {object} state					The current state
 * @param {object} payload.content			The new content to store inside the margins
 *
 * @returns {object} The updated state value
 */
const updateMargins = (state, { content }) => {
	const { margins } = state;

	const newMargins = update(margins, {
		$set: {
			...margins,
			...content,
		},
	});

	return {
		...state,
		margins: newMargins,
	};
};

export default updateMargins;
