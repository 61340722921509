import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import PropTypes from 'prop-types';
import { addStatus, fetchStatusList } from 'redux/actions/statuses';
import { fetchStatusObjectList } from 'redux/actions/statusObjects';
import { useStatusActiveListSelector, useStatusLoadingSelector } from 'redux/selectors/statuses';
import { useStatusObjectListSelector, useStatusObjectLoadingSelector } from 'redux/selectors/statusObjects';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Checkbox, ColorPicker, NumberInput, Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';
import { TagColorPreviewComponent } from 'components/shared/previewComponents';
import { StatusCreationForm } from 'components/statuses';

/**
 * @name StatusObjectCreationForm
 * @description A form used to create a new status object.
 *
 * @author Romaric Barthe
 *
 * @param {string}	[defaultEnvironment]	The default environment to use when creating a new status object.
 * @param {func}	onSubmit				The method to trigger upon form submission.
 */
const StatusObjectCreationForm = ({ defaultEnvironment, onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [environment, setEnvironment] = useState(defaultEnvironment);

	const statuses = useStatusActiveListSelector();
	const statusObjects = useStatusObjectListSelector();

	const areStatusesLoading = useStatusLoadingSelector();
	const areStatusObjectsLoading = useStatusObjectLoadingSelector();

	/**
	 * @name statusSelectOptions
	 * @description Filters the status list to only show statuses that are not already associated with a status object of the current environment.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @returns {Array}
	 */
	const statusSelectOptions = useMemo(() => {
		if (!environment) {
			return statuses;
		}

		const environmentStatusObjects = statusObjects.filter((statusObject) => statusObject.environment === environment);

		return statuses.filter((status) => !environmentStatusObjects.some((statusObject) => statusObject.status.id === status.id));
	}, [environment, statusObjects, statuses]);

	const environments = useMemo(() => Object.values(Environments).map((value) => ({ id: value, label: t(`status_object.environment.${value}`) })), [t]);

	// Used to update the list of available statuses.
	const onEnvironmentChange = useCallback((newValue) => {
		setEnvironment(newValue);
	}, [setEnvironment]);

	useEffect(() => {
		dispatch(fetchStatusList({ rowsPerPage: 0 }));
		dispatch(fetchStatusObjectList());
	}, [dispatch]);

	// Props to pass down to the select input so that it will display a status creation modal when clicking on the "+" icon.
	const statusCreationModal = useFormModal(StatusCreationForm, t('status_object.creation.inputs.status.creation'), fetchStatusList, addStatus);

	const { formProps, buttonProps } = useSubmitButton();

	/**
	 * @function
	 * @name handleSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleSubmit = useCallback((formData) => {
		onSubmit(formData);
	}, [onSubmit]);

	const defaultValues = useMemo(() => ({
		environment: defaultEnvironment,
	}), [defaultEnvironment]);

	return (
		<DynamicForm onSubmit={handleSubmit} defaultValues={defaultValues} {...formProps}>
			<Select
				label={t('status_object.creation.inputs.status.label')}
				labelKey="name"
				name="status"
				options={statusSelectOptions}
				rules={{
					required: Validators.isRequired(t('status_object.creation.inputs.status.validation_errors.required')),
				}}
				valueKey="id"
				isLoading={areStatusesLoading || areStatusObjectsLoading}
				{...statusCreationModal}
			/>
			<Select
				label={t('status_object.creation.inputs.environment.label')}
				labelKey="label"
				name="environment"
				options={environments}
				onChange={onEnvironmentChange}
				rules={{
					required: Validators.isRequired(t('status_object.creation.inputs.environment.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<Checkbox
				name="inDashboard"
				label={t('status_object.creation.inputs.inDashboard.label')}
				labelFirst={false}
			/>
			<Checkbox
				name="favorite"
				label={t('status_object.creation.inputs.favorite.label')}
				labelFirst={false}
			/>
			<Checkbox
				name="crossApplication"
				label={t('status_object.creation.inputs.crossApplication.label')}
				labelFirst={false}
			/>
			<Checkbox
				name="automatic"
				label={t('status_object.creation.inputs.automatic.label')}
				labelFirst={false}
			/>
			<ColorPicker
				name="color"
				label={t('status_object.creation.inputs.color.label')}
				type="circle"
				previewComponent={TagColorPreviewComponent}
			/>
			<NumberInput
				allowNull
				label={t('status_object.creation.inputs.rank.label')}
				name="rank"
				step={1}
			/>

			<Button className="primary" type="submit" {...buttonProps}>{t('status_object.creation.action')}</Button>
		</DynamicForm>
	);
};

StatusObjectCreationForm.propTypes = {
	defaultEnvironment: PropTypes.oneOf(Object.values(Environments)),
	onSubmit: PropTypes.func.isRequired,
};

StatusObjectCreationForm.defaultProps = {
	defaultEnvironment: undefined,
};

export default StatusObjectCreationForm;
