import * as PartnersApi from 'api/partnersApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Partners redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of partners
	FETCH_PARTNER_FOR_LIST_REQUEST: '@PARTNERS_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_PARTNER_FOR_LIST_SUCCESS: '@PARTNERS_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_PARTNER_FOR_LIST_FAILURE: '@PARTNERS_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Partner list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnersForListRequest
 * @description Action triggered anytime a partnersList list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchPartnersForListRequest = () => ({ type: ActionTypes.FETCH_PARTNER_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchPartnersForListSuccess
 * @description Action triggered as a result to a successful partnersList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	partnersList	The list of retrieved partners.
 * @param {number}	totalCount		The total amount of partners available in the database to the current user.
 *
 * @returns {object}
 */
const fetchPartnersForListSuccess = ({ partnersList, totalCount }) => ({
	type: ActionTypes.FETCH_PARTNER_FOR_LIST_SUCCESS,
	payload: { partnersList, totalCount },
});

/**
 * @function
 * @name fetchPartnersForListFailure
 * @description Action triggered as a result to a failed partnersList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchPartnersForListFailure = (error) => ({
	type: ActionTypes.FETCH_PARTNER_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnersForList
 * @description Method used to update the partnersList list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchPartnersForList = (filters) => (dispatch) => {
	dispatch(fetchPartnersForListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forList',
	};

	return PartnersApi.fetchPartners(newFilters)
		.then(({ partnersList, totalCount }) => dispatch(fetchPartnersForListSuccess({ partnersList, totalCount })))
		.catch((error) => dispatch(fetchPartnersForListFailure(error)));
};
