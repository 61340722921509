/* eslint-disable no-nested-ternary */
import templateEnums from 'constants/templateEnums';

/**
 * @function
 * @name exportDataTypes
 * @description return the array of template data type options.
 *
 * @author Romaric Barthe
 *
 * @returns array of options for template data type.
 */
export const exportDataTypes = () => {
	const dataTypes = [];
	Object.entries(templateEnums.export.dataTypes).forEach(([, value]) => {
		dataTypes.push({ id: value, label: `template.export.inputs.data_type.values.${value}` });
	});

	return dataTypes;
};

/**
 * @function
 * @name exportFormats
 * @description return the array of template data format options.
 *
 * @author Romaric Barthe
 *
 * @returns array of options for template data format.
 */
export const exportFormats = () => {
	const formats = [];
	Object.entries(templateEnums.export.formats).forEach(([, value]) => {
		formats.push({ id: value, label: `template.export.inputs.format.values.${value}` });
	});

	return formats;
};

/**
 * @function
 * @name exportSeparators
 * @description return the array of template data separators' options.
 *
 * @author Romaric Barthe
 *
 * @returns array of options for template data separators.
 */
export const exportSeparators = () => {
	const separators = [];
	Object.entries(templateEnums.export.separators).forEach(([, value]) => {
		separators.push({ id: value, label: `template.export.inputs.separator.values.${value}` });
	});

	return separators;
};
