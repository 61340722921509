import { toast } from 'react-toastify';
import * as StatusesApi from 'api/statusesApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the statuses redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific status
	FETCH_STATUS_REQUEST: '@STATUSES/FETCH_REQUEST',
	FETCH_STATUS_SUCCESS: '@STATUSES/FETCH_SUCCESS',
	FETCH_STATUS_FAILURE: '@STATUSES/FETCH_FAILURE',

	// Fetch a list of statuses
	FETCH_STATUS_LIST_REQUEST: '@STATUSES/FETCH_LIST_REQUEST',
	FETCH_STATUS_LIST_SUCCESS: '@STATUSES/FETCH_LIST_SUCCESS',
	FETCH_STATUS_LIST_FAILURE: '@STATUSES/FETCH_LIST_FAILURE',

	// Add a new status to the database
	ADD_STATUS_REQUEST: '@STATUSES/ADD_REQUEST',
	ADD_STATUS_SUCCESS: '@STATUSES/ADD_SUCCESS',
	ADD_STATUS_FAILURE: '@STATUSES/ADD_FAILURE',

	// Update an existing status from the database
	UPDATE_STATUS_REQUEST: '@STATUSES/UPDATE_REQUEST',
	UPDATE_STATUS_SUCCESS: '@STATUSES/UPDATE_SUCCESS',
	UPDATE_STATUS_FAILURE: '@STATUSES/UPDATE_FAILURE',

	// Remove an existing status from the database
	REMOVE_STATUS_REQUEST: '@STATUSES/REMOVE_REQUEST',
	REMOVE_STATUS_SUCCESS: '@STATUSES/REMOVE_SUCCESS',
	REMOVE_STATUS_FAILURE: '@STATUSES/REMOVE_FAILURE',

	// Archive an existing status from the database
	ARCHIVE_STATUS_REQUEST: '@STATUSES/ARCHIVE_REQUEST',
	ARCHIVE_STATUS_SUCCESS: '@STATUSES/ARCHIVE_SUCCESS',
	ARCHIVE_STATUS_FAILURE: '@STATUSES/ARCHIVE_FAILURE',

	// Restore an existing status from the database
	RESTORE_STATUS_REQUEST: '@STATUSES/RESTORE_REQUEST',
	RESTORE_STATUS_SUCCESS: '@STATUSES/RESTORE_SUCCESS',
	RESTORE_STATUS_FAILURE: '@STATUSES/RESTORE_FAILURE',
};

// ////////////////////////////////////////////////////////////// //
// ///////////// Status list fetching actions //////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchStatusListRequest
 * @description Action triggered anytime a status list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchStatusListRequest = () => ({ type: ActionTypes.FETCH_STATUS_LIST_REQUEST });

/**
 * @function
 * @name fetchStatusListSuccess
 * @description Action triggered as a result to a successful status list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} statuses	The list of retrieved statuses.
 * @param {number} totalCount		The total amount of statuses available in the database for the current user.
 *
 * @returns {object}
 */
const fetchStatusListSuccess = ({ statuses, totalCount }) => ({
	type: ActionTypes.FETCH_STATUS_LIST_SUCCESS,
	payload: { statuses, totalCount },
});

/**
 * @function
 * @name fetchStatusListFailure
 * @description Action triggered as a result to a failed status list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchStatusListFailure = (error) => ({
	type: ActionTypes.FETCH_STATUS_LIST_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// /////////// Specific status fetching actions ////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchStatusRequest
 * @description Action triggered anytime a status fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchStatusRequest = () => ({ type: ActionTypes.FETCH_STATUS_REQUEST });

/**
 * @function
 * @name fetchStatusSuccess
 * @description Action triggered as a result to a successful status fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} status The retrieved status.
 *
 * @returns {object}
 */
const fetchStatusSuccess = ({ status }) => ({
	type: ActionTypes.FETCH_STATUS_SUCCESS,
	payload: { status },
});

/**
 * @function
 * @name fetchStatusFailure
 * @description Action triggered as a result to a failed status fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchStatusFailure = (error) => ({
	type: ActionTypes.FETCH_STATUS_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Status creation actions ////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name addStatusRequest
 * @description Action triggered anytime a status creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addStatusRequest = () => ({ type: ActionTypes.ADD_STATUS_REQUEST });

/**
 * @function
 * @name addStatusSuccess
 * @description Action triggered as a result to a successful status creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} status The created status object.
 *
 * @returns {object}
 */
const addStatusSuccess = ({ status }) => ({
	type: ActionTypes.ADD_STATUS_SUCCESS,
	payload: { status },
});

/**
 * @function
 * @name addStatusFailure
 * @description Action triggered as a result to a failed status creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const addStatusFailure = (error) => ({
	type: ActionTypes.ADD_STATUS_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// ///////////////// Status update actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateStatusRequest
 * @description Action triggered anytime a status update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateStatusRequest = () => ({ type: ActionTypes.UPDATE_STATUS_REQUEST });

/**
 * @function
 * @name updateStatusSuccess
 * @description Action triggered as a result to a successful status update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} status The updated status object.
 *
 * @returns {object}
 */
const updateStatusSuccess = ({ status }) => ({
	type: ActionTypes.UPDATE_STATUS_SUCCESS,
	payload: { status },
});

/**
 * @function
 * @name updateStatusFailure
 * @description Action triggered as a result to a failed status update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const updateStatusFailure = (error) => ({
	type: ActionTypes.UPDATE_STATUS_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Status removal actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeStatusRequest
 * @description Action triggered anytime a status deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeStatusRequest = () => ({ type: ActionTypes.REMOVE_STATUS_REQUEST });

/**
 * @function
 * @name removeStatusSuccess
 * @description Action triggered as a result to a successful status deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} status The removed status object.
 *
 * @returns {object}
 */
const removeStatusSuccess = ({ deletedStatusId }) => ({
	type: ActionTypes.REMOVE_STATUS_SUCCESS,
	payload: { deletedStatusId },
});

/**
 * @function
 * @name removeStatusFailure
 * @description Action triggered as a result to a failed status deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const removeStatusFailure = (error) => ({
	type: ActionTypes.REMOVE_STATUS_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// //////////////// Status archiving actions /////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveStatusRequest
 * @description Action triggered anytime an status archiving call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveStatusRequest = () => ({ type: ActionTypes.ARCHIVE_STATUS_REQUEST });

/**
 * @function
 * @name archiveStatusSuccess
 * @description	Action triggered as a result to a successful status archiving API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveStatusSuccess = () => ({
	type: ActionTypes.ARCHIVE_STATUS_SUCCESS,
});

/**
 * @function
 * @name archiveStatusFailure
 * @description Action triggered as a result to a failed status archiving API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveStatusFailure = (error) => ({
	type: ActionTypes.ARCHIVE_STATUS_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////////// //
// ///////////////// Status restoring actions //////////////// //
// ////////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreStatusRequest
 * @description Action triggered anytime an status restoring call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreStatusRequest = () => ({ type: ActionTypes.RESTORE_STATUS_REQUEST });

/**
 * @function
 * @name restoreStatusSuccess
 * @description	Action triggered as a result to a successful status restoring API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreStatusSuccess = () => ({
	type: ActionTypes.RESTORE_STATUS_SUCCESS,
});

/**
 * @function
 * @name restoreStatusFailure
 * @description Action triggered as a result to a failed statuses restoring API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreStatusFailure = (error) => ({
	type: ActionTypes.RESTORE_STATUS_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchStatusList
 * @description Method used to update the status list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchStatusList = (params) => (dispatch) => {
	dispatch(fetchStatusListRequest());

	return StatusesApi.fetchStatuses(params)
		.then(({ statuses, totalCount }) => dispatch(fetchStatusListSuccess({ statuses, totalCount })))
		.catch((error) => dispatch(fetchStatusListFailure(error)));
};

/**
 * @function
 * @name fetchStatus
 * @description Method used to fetch the latest version of a specific status.
 *
 * @author Romaric Barthe
 *
 * @param {string} statusId	The id of the status we want to retrieve.
 */
export const fetchStatus = (statusId) => (dispatch) => {
	dispatch(fetchStatusRequest());

	return StatusesApi.fetchStatusById(statusId)
		.then(({ status }) => dispatch(fetchStatusSuccess({ status })))
		.catch((error) => dispatch(fetchStatusFailure(error)));
};

/**
 * @function
 * @name addStatus
 * @description Method used to add a new status instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusData		The data to create the new status from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addStatus = (statusData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addStatusRequest());

	return StatusesApi.createStatus(statusData)
		.then(({ status }) => {
			toast.success(i18next.t('status.creation.toasts.success', { name: status.name }));
			dispatch(addStatusSuccess({ status }));
			redirectOnSuccess(onSuccessRoute);

			return status;
		})
		.catch((error) => {
			toast.error(i18next.t('status.creation.toasts.error'));
			dispatch(addStatusFailure(error));
		});
};

/**
 * @function
 * @name updateStatus
 * @description Method used to update an existing status instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusData	The object to update the status with.
 * @param {string} statusId	The id of the status we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateStatus = (statusData, statusId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateStatusRequest());

	return StatusesApi.updateStatus(statusData, statusId)
		.then(({ status }) => {
			dispatch(updateStatusSuccess({ status }));
			toast.success(i18next.t('status.edition.toasts.success', { name: status.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch((error) => {
			toast.error(i18next.t('status.edition.toasts.error'));
			dispatch(updateStatusFailure(error));
		});
};

/**
 * @function
 * @name removeStatus
 * @description Method used to remove an existing status instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} status			The status we want to remove from the database.
 * @param {string} status.id		The id of the status we want to remove from the database.
 * @param {string} status.name		The name of the status we want to remove from the database.
 */
export const removeStatus = ({ id, name }) => (dispatch) => {
	dispatch(removeStatusRequest());

	return StatusesApi.deleteStatus(id)
		.then(({ deletedStatusId }) => {
			dispatch(removeStatusSuccess({ deletedStatusId }));
			toast.success(i18next.t('status.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeStatusFailure(error));
			toast.error(i18next.t('status.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveStatus
 * @description	Method used to archive an existing status instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} status			The status we want to archive.
 * @param {string} status.id		The id of the status we want to archive.
 * @param {string} status.name		The name of the status we want to archive.
 */
export const archiveStatus = ({ id, name }) => (dispatch) => {
	dispatch(archiveStatusRequest());

	return StatusesApi.updateStatus({ archived: true }, id)
		.then(() => {
			dispatch(archiveStatusSuccess());
			toast.success(i18next.t('status.disabling.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(archiveStatusFailure(error));
			toast.error(i18next.t('status.disabling.toasts.error'));
		});
};

/**
 * @function
 * @name restoreStatus
 * @description	Method used to restore an existing status instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} status			The status we want to restore.
 * @param {string} status.id		The id of the status we want to restore.
 * @param {string} status.name		The name of the status we want to restore.
 */
export const restoreStatus = ({ id, name }) => (dispatch) => {
	dispatch(restoreStatusRequest());

	return StatusesApi.updateStatus({ archived: false }, id)
		.then(() => {
			dispatch(restoreStatusSuccess());
			toast.success(i18next.t('status.enabling.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(restoreStatusFailure(error));
			toast.error(i18next.t('status.enabling.toasts.error'));
		});
};
