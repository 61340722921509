/**
 * @function
 * @name resetMovingElement
 * @description Resets the moving element
 *
 * @author Florian Fornazaric
 *
 * @param {object} state	The current state
 *
 * @returns {object} The updated state value
 */
const resetMovingElement = (state) => ({
	...state,
	movingElement: undefined,
});

export default resetMovingElement;
