import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name TextObjectEditionForm
 * @description A form used to edit an existing text object's information.
 *
 * @author Romaric Barthe
 *
 * @param {object}		textObject		The text object object to update information from.
 * @param {function}	onSubmit		The method to trigger upon form submission.
 */
const TextObjectEditionForm = ({ textObject, onSubmit }) => {
	const { t } = useTranslation();

	const canEditTextObject = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING) && !textObject?.archived;

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm onSubmit={onSubmit} defaultValues={textObject} disabled={!canEditTextObject} {...formProps}>
			<TextInput
				name="name"
				label={t('text_object.edition.inputs.name.label')}
				type="text"
				placeholder={t('text_object.edition.inputs.name.placeholder')}
				rules={{
					required: Validators.isRequired(t('text_object.edition.inputs.name.validation_errors.required')),
				}}
			/>

			<TextArea
				name="text"
				label={t('text_object.edition.inputs.text.label')}
				type="text"
			/>

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditTextObject ? t('text_object.edition.action') : t('text_object.edition.close')}
			</Button>
		</DynamicForm>
	);
};

TextObjectEditionForm.propTypes = {
	textObject: PropTypes.shape({
		archived: PropTypes.bool,
		name: PropTypes.string.isRequired,
		text: PropTypes.string.isRequired,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default TextObjectEditionForm;
