import { ActionTypes } from '../actions/accounts';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_ACCOUNT_LIST_REQUEST:
		case ActionTypes.ADD_ACCOUNT_REQUEST:
		case ActionTypes.UPDATE_ACCOUNT_REQUEST:
		case ActionTypes.REMOVE_ACCOUNT_REQUEST:
		case ActionTypes.ARCHIVE_ACCOUNT_REQUEST:
		case ActionTypes.RESTORE_ACCOUNT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_ACCOUNT_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_ACCOUNT_LIST_FAILURE:
		case ActionTypes.FETCH_ACCOUNT_FAILURE:
		case ActionTypes.ADD_ACCOUNT_FAILURE:
		case ActionTypes.UPDATE_ACCOUNT_FAILURE:
		case ActionTypes.REMOVE_ACCOUNT_FAILURE:
		case ActionTypes.ARCHIVE_ACCOUNT_FAILURE:
		case ActionTypes.RESTORE_ACCOUNT_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_ACCOUNT_SUCCESS:
		case ActionTypes.UPDATE_ACCOUNT_SUCCESS:
		case ActionTypes.REMOVE_ACCOUNT_SUCCESS:
		case ActionTypes.ARCHIVE_ACCOUNT_SUCCESS:
		case ActionTypes.RESTORE_ACCOUNT_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_ACCOUNT_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.accounts,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_ACCOUNT_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.account,
			};
		default:
			return state;
	}
};
