import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';

import { AccessRights, useAccessRight } from '../../../lib/shared/accessRights';

import { TargetIdentifier } from './targetTabs';

/**
 * @name TargetEditionForm
 * @description A form used to edit an existing target's information.
 *
 * @author Romaric Barthe
 *
 * @param {object}		target		The target object to update information from.
 * @param {function}	onSubmit	The method to trigger upon form submission.
 */
const TargetEditionForm = ({ target, onSubmit }) => {
	const { t } = useTranslation();
	const { formProps, buttonProps } = useSubmitButton();
	const canEditTarget = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING) && !target?.targeting?.archived;

	const defaultValues = useMemo(() => ({
		...target,
		partners: target.targetPartners?.map(({ partner }) => (partner.id ?? '')) ?? [],
		phases: target.phases?.map(({ id }) => (id ?? '')) ?? [],
		responsibles: target.responsibles?.map(({ id }) => (id ?? '')) ?? [],
	}), [target]);

	return (
		<div>
			<DynamicForm
				onSubmit={onSubmit}
				defaultValues={defaultValues}
				disabled={!canEditTarget}
				{...formProps}
			>
				<TargetIdentifier />
				<Button className="primary" style={{ marginTop: '15px' }} type="submit" {...buttonProps}>
					{canEditTarget ? t('targeting.target.edition.action') : t('targeting.target.edition.close')}
				</Button>
			</DynamicForm>
		</div>
	);
};

TargetEditionForm.propTypes = {
	target: PropTypes.shape({
		id: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		targetPartners: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				partner: PropTypes.shape({
					id: PropTypes.string.isRequired,
				}),
			}),
		),
		phases: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
			}),
		),
		responsibles: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
			}),
		),
		targeting: PropTypes.shape({
			id: PropTypes.string.isRequired,
			archived: PropTypes.bool,
		}),
	}),
	onSubmit: PropTypes.func.isRequired,
};

TargetEditionForm.defaultProps = {
	target: {},
};

export default TargetEditionForm;
