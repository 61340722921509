import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentTemplateExportSelector
 * @description A selector to retrieve the current template export from the template exports redux state
 *
 * @author Yann Hodiesne
 *
 * @param {string} id The identifier of the current template export to wait for
 *
 * @returns {object|null}
 */
const useCurrentTemplateExportSelector = (id) => {
	const templateExport = useSelector((state) => (state.templateExports?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (templateExport?.id === id ? templateExport : null), [id, templateExport]);

	return result;
};

/**
 * @function
 * @name useTemplateExportLoadingSelector
 * @description A selector to retrieve the loading state from the template exports redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {boolean}
 */
const useTemplateExportLoadingSelector = () => {
	const loading = useSelector((state) => state.templateExports?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useTemplateExportListSelector
 * @description A selector to retrieve the template export list from the template exports redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {Array}
 */
const useTemplateExportListSelector = () => {
	const templateExports = useSelector((state) => (state.templateExports?.items ?? []), shallowEqual);

	return templateExports;
};

/**
 * @function
 * @name useTemplateExportTotalCountSelector
 * @description A selector to retrieve the totalCount value from the template exports redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {number|undefined}
 */
const useTemplateExportTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.templateExports?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useCurrentTemplateExportSelector,
	useTemplateExportListSelector,
	useTemplateExportLoadingSelector,
	useTemplateExportTotalCountSelector,
};
