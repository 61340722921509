import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchCompanies
 * @description Retrieves a list of all companies from the API, specifying filters if present.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {object} params  The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchCompanies = (params) => post('/api/companies', params);

/**
 * @function
 * @name fetchCompanyById
 * @description Retrieves an existing company from the API, using the companyId in paramters.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} companyId  The id of the company we want to retireve.
 *
 * @returns {Promise}
 */
export const fetchCompanyById = (companyId) => get(`/api/company/${companyId}`);

/**
 * @function
 * @name createCompany
 * @description Creates a new company into the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} companyData  The data to create the company from.
 *
 * @returns {Promise}
 */
export const createCompany = (companyData) => post('/api/company', companyData);

/**
 * @function
 * @name enableCompany
 * @description Enables an existing company.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string}	companyId	 The id of the company we wish to enable.
 * @param {bool}	enable		 if set to true, the company will be enabled, disabled otherwise.
 *
 * @returns {Promise}
 */
export const enableCompany = (enable, companyId) => put(`/api/company/enable/${companyId}`, enable);

/**
 * @function
 * @name updateCompany
 * @description Updates an existing company from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} companyData	 The data to update the company with.
 * @param {string} companyId	 The id of the company we wish to update.
 *
 * @returns {Promise}
 */
export const updateCompany = (companyData, companyId) => put(`/api/company/${companyId}`, companyData);

/**
 * @function
 * @name removeCompany
 * @description Deactivates an existing company from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} companyId  The id of the company we want to deactivate.
 *
 * @returns {Promise}
 */
export const removeCompany = (companyId) => del(`/api/company/${companyId}`);
