import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { ExpenseCreationTypes } from 'constants/expenseEnums';
import { addExpense } from 'redux/actions/expenses/expenses';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ExpenseCreationForm } from 'components/expenses';

/**
 * @name ExpenseCreationPage
 * @description A page used to create a new expense.
 *
 * @author Romaric Barthe
 */
const ExpenseCreationPage = () => {
	const dispatch = useDispatch();

	const {
		state: {
			creationType,
			sourceExpenseId,
		} = {
			creationType: undefined,
			sourceExpenseId: undefined,
		},
	} = useLocation();

	/**
	 * Checks if the required location state data are properly provided. If not, redirects the user to the pre-creation page.
	 */
	useEffect(() => {
		if (!creationType) {
			history.push(routes.humanResources.expenses.expensePreCreation);
		}
		if (creationType === ExpenseCreationTypes.FROM_EXPENSE && !sourceExpenseId) {
			history.push(routes.humanResources.expenses.expensePreCreation);
		}
		if (creationType === ExpenseCreationTypes.REVERT && !sourceExpenseId) {
			history.push(routes.humanResources.expenses.expensePreCreation);
		}
	}, [creationType, sourceExpenseId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback(async (formData) => {
		const newExpense = await dispatch(addExpense(formData));

		if (newExpense) {
			history.push(generatePath(routes.humanResources.expenses.expenseEdition, { id: newExpense.id }));
		}
	}, [dispatch]);

	return (
		creationType ? (
			<ExpenseCreationForm
				onSubmit={handleFormSubmit}
				creationType={creationType}
				sourceExpenseId={sourceExpenseId}
			/>
		) : null
	);
};

export default ExpenseCreationPage;
