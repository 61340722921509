import { useCallback, useEffect, useMemo, useState } from 'react';
import { Copy, Download, Edit2, ExternalLink, RotateCcw, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Environments } from 'constants/environmentEnums';
import { InvoiceCreationTypes } from 'constants/invoiceEnums';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { downloadS3File } from 'lib/shared/fileHelper';
import PropTypes from 'prop-types';
import { exportInvoices, removeInvoice } from 'redux/actions/invoices/invoices';
import { fetchTemplateList } from 'redux/actions/templateExports';
import { useTemplateExportListSelector, useTemplateExportLoadingSelector } from 'redux/selectors/templateExports';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name InvoiceActionBar
 * @description A component to display the invoice's titles.
 *
 * @author Romaric Barthe
 *
 * @param {object}	invoice			The invoice.
 */
const InvoiceActionBar = ({ invoice }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const [downloadButtonRef, downloadTooltipProps] = useTooltip();
	const [reverseButtonRef, reverseTooltipProps] = useTooltip();
	const [extractButtonRef, extractTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	const [copyButtonRef, copyTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();

	useEffect(() => {
		dispatch(fetchTemplateList({
			rowsPerPage: 0,
			filters: {
				conditions: [
					{ column: 'linkedEntities', criterion: 'contains', value: Environments.INVOICE.toUpperCase() },
					{ column: 'linkedEntities', criterion: 'contains', value: invoice.id },
				],
			},
		}));
	}, [dispatch, invoice.id]);

	const templateExports = useTemplateExportListSelector();
	const isLoading = useTemplateExportLoadingSelector();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const currentRow = useMemo(() => {
		if (isLoading) {
			return undefined;
		}

		const templateExportsFiltered = templateExports.filter(
			(f) => f.linkedEntities
				&& f.linkedEntities.length > 0
				&& f.linkedEntities[0].type === Environments.INVOICE.toUpperCase()
				&& f.linkedEntities[0].linkedEntity === invoice.id
		);

		return templateExportsFiltered && templateExportsFiltered.length > 0 ? templateExportsFiltered[0] : undefined;
	}, [invoice.id, isLoading, templateExports]);
	const pdf = useMemo(() => currentRow?.generatedPdf?.pdf, [currentRow]);

	const canDeleteInvoice = useAccessRight(AccessRights.accounting.invoices.enhancedRights.DELETE_INVOICE) && !invoice.editionNumber && !invoice.archived;
	const canEditInvoice = useAccessRight(AccessRights.accounting.invoices.enhancedRights.CREATE_INVOICE) && !invoice.editionNumber && !invoice.archived;
	const canExtractInvoice = useAccessRight(AccessRights.accounting.invoices.enhancedRights.EXPORT_INVOICE);

	const canDownload = useMemo(() => pdf && pdf.presignedUrl, [pdf]);
	const [isDownloading, setIsDownloading] = useState(false);

	const onCopyButtonClick = useCallback(() => {
		history.push({
			pathname: routes.accounting.invoices.invoiceCreation,
			state: { creationType: InvoiceCreationTypes.FROM_INVOICE, sourceInvoiceId: invoice.id },
		});
	}, [invoice.id]);

	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeInvoice({ id: invoice.id }));
		history.push(generatePath(routes.accounting.invoices.list));
	}, [dispatch, invoice.id, toggleDeleteModal]);

	const onDownloadButtonClick = useCallback(async () => {
		if (!isDownloading) {
			setIsDownloading(true);
			await downloadS3File(pdf, 'pdf');
			setIsDownloading(false);
		}
	}, [isDownloading, pdf]);

	const onEditButtonClick = useCallback(() => {
		history.push(generatePath(routes.accounting.invoices.invoiceEdition, { id: invoice.id }));
	}, [invoice.id]);

	const onExtractButtonClick = useCallback(() => {
		const exportData = {
			id: invoice.id,
			optionalFields: true,
			linkedEntites: true,
			masterData: true,
		};

		return dispatch(exportInvoices(exportData));
	}, [dispatch, invoice.id]);

	const onReverseButtonClick = useCallback(() => {
		history.push({
			pathname: routes.accounting.invoices.invoiceCreation,
			state: { creationType: InvoiceCreationTypes.REVERT, sourceInvoiceId: invoice.id },
		});
	}, [invoice.id]);

	return (
		<div className="actions">
			{invoice.editionNumber && canDownload && (
				<>
					<Button className="icon-only" onClick={onDownloadButtonClick} isLoading={isDownloading} ref={downloadButtonRef}>
						<Download />
					</Button>
					<Tooltip {...downloadTooltipProps}>{t('invoice.links.document.download')}</Tooltip>
					<Button className="icon-only" onClick={onReverseButtonClick} ref={reverseButtonRef}>
						<RotateCcw />
					</Button>
					<Tooltip {...reverseTooltipProps}>{t('invoice.links.reverse')}</Tooltip>
				</>
			)}
			{canExtractInvoice && (
				<>
					<Button className="icon-only" onClick={onExtractButtonClick} ref={extractButtonRef}>
						<ExternalLink />
					</Button>
					<Tooltip {...extractTooltipProps}>{t('invoice.links.export.current')}</Tooltip>
				</>
			)}
			{canEditInvoice && (
				<>
					<Button className="icon-only" onClick={onEditButtonClick} ref={editButtonRef}>
						<Edit2 />
					</Button>
					<Tooltip {...editTooltipProps}>{t('invoice.links.update')}</Tooltip>
				</>
			)}
			<Button className="icon-only" onClick={onCopyButtonClick} ref={copyButtonRef}>
				<Copy />
			</Button>
			<Tooltip {...copyTooltipProps}>{t('invoice.links.duplicate')}</Tooltip>
			{canDeleteInvoice && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>{t('invoice.links.delete')}</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('invoice.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('invoice.deletion.confirmation_modal.confirm')}
						cancelText={t('invoice.deletion.confirmation_modal.cancel')}
						titleText={t('invoice.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</div>
	);
};

InvoiceActionBar.propTypes = {
	invoice: PropTypes.object.isRequired,
};

export default InvoiceActionBar;
