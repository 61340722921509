import { useCallback, useMemo } from 'react';
import { Edit2, Eye, List, Trash2, Upload } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { removeTarget } from 'redux/actions/targets/targets';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the TargetListPage. (remove)
 *
 * @author Romaric Barthe
 *
 * @param {object}		row			The object containing the data to interact with.
 * @param {string}		row.original.id			The id of the target.
 * @param {string}		row.original.name		The name of the target.
 * @param {object}		row.original.targeting	The targeting this target belongs to.
 * @param {function}	fetchData	The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { id, name, targeting } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditTargeting = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING);
	const canDeleteTargeting = useAccessRight(AccessRights.operations.targetings.enhancedRights.DELETE_TARGETING);

	const isTargetingArchived = useMemo(() => targeting?.archived ?? false, [targeting]);

	const [importButtonRef, importTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	const [partnerButtonRef, partnerTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onDeletionModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Romaric Barthe
	 */
	const onDeletionModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeTarget({ id, name }));
		fetchData();
	}, [dispatch, fetchData, id, name, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditButtonClick
	 * @description Method triggered as a result to an onClick event from the edit button.
	 *
	 * @author Romaric Barthe
	 */
	const onEditButtonClick = useCallback(() => {
		history.push(generatePath(routes.operations.targetings.targets.targetEdition, { targetId: id, targetingId: targeting?.id }));
	}, [id, targeting?.id]);

	/**
	 * @function
	 * @name onLoadTargetPartnersButtonClick
	 * @description Method triggered as a result to an onClick event from the upload button.
	 *
	 * @author Romaric Barthe
	 */
	const onLoadTargetPartnersButtonClick = useCallback(() => {
		history.push(generatePath(routes.operations.targetings.targets.targetLoadTargetPartners, { targetId: id, targetingId: targeting?.id }));
	}, [id, targeting?.id]);

	/**
	 * @function
	 * @name onViewButtonClick
	 * @description Method triggered as a result to an onClick event from the view button.
	 *
	 * @author Romaric Barthe
	 */
	const onViewButtonClick = useCallback(() => {
		history.push(generatePath(routes.operations.targetings.targets.targetPartners.targetPartnerEdition, { targetId: id, targetingId: targeting.id }));
	}, [id, targeting?.id]);

	return (
		<>
			{canEditTargeting && !isTargetingArchived && (
				<>
					<Button className="icon-only" onClick={onViewButtonClick} ref={partnerButtonRef}>
						<List className="primary" />
					</Button>
					<Tooltip {...partnerTooltipProps}>
						{t('actions.partner')}
					</Tooltip>
				</>
			)}
			<Button className="icon-only" onClick={onEditButtonClick} ref={editButtonRef}>
				{canEditTargeting && !isTargetingArchived ? <Edit2 /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditTargeting && !isTargetingArchived ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			{canEditTargeting && !isTargetingArchived && (
				<>
					<Button className="icon-only" onClick={onLoadTargetPartnersButtonClick} ref={importButtonRef}>
						<Upload />
					</Button>
					<Tooltip {...importTooltipProps}>
						{t('actions.import')}
					</Tooltip>
				</>
			)}
			{canDeleteTargeting && !isTargetingArchived && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('targeting.target.deletion.confirmation_modal.content')}
						confirm={onDeletionModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('targeting.target.deletion.confirmation_modal.confirm')}
						cancelText={t('targeting.target.deletion.confirmation_modal.cancel')}
						titleText={t('targeting.target.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			targeting: PropTypes.shape({
				id: PropTypes.string.isRequired,
				archived: PropTypes.bool,
			}).isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
