import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchSector, updateSector } from 'redux/actions/sectors/sectors';
import { useCurrentSectorSelector } from 'redux/selectors/sectors/sectors';
import routes from 'routes';

import { SectorEditionForm } from 'components/sectors';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name SectorEditionPage
 * @description A page used to edit an existing sector.
 *
 * @author Audrey Clerc
 */
const SectorEditionPage = () => {
	const { id: sectorId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const sector = useCurrentSectorSelector(sectorId);

	const canEditSector = useAccessRight(AccessRights.commonModules.sectors.enhancedRights.CREATE_SECTOR) && !sector?.archived;

	useEffect(() => {
		dispatch(fetchSector(sectorId));
	}, [dispatch, sectorId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData	The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditSector) {
			dispatch(updateSector(formData, sectorId, routes.commonModules.sectors.list));
		} else {
			redirectOnSuccess(routes.commonModules.sectors.list);
		}
	}, [canEditSector, dispatch, sectorId]);

	return (
		sector
			? <SectorEditionForm sector={sector} onSubmit={handleFormSubmit} />
			: <p>{t('sector.edition.loading')}</p>
	);
};

export default SectorEditionPage;
