import { useCallback, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTargeting } from 'redux/actions/targetings/targetings';
import { fetchTarget, fetchTargetStats } from 'redux/actions/targets/targets';
import { useCurrentTargetingSelector } from 'redux/selectors/targetings/targetings';
import { useTargetPartnersForListSelector } from 'redux/selectors/targetPartners/targetPartnersList';
import { useCurrentTargetStatsSelector } from 'redux/selectors/targets/targets';

import { Tab, TabList, TabPanel } from 'components/shared/layout/tabs';
import TargetPartnerDetails from 'components/targetings/targetPartners/targetPartnerDetails/TargetPartnerDetails';
import TargetPartnerList from 'components/targetings/targetPartners/TargetPartnerList';
import TargetHeader from 'components/targetings/targets/TargetHeader';

import 'styles/pages/targets/details/_targetDetailsPage.scss';

/**
 * @name TargetDetailsPage
 * @description A component used to display a target's information.
 *
 * @author Timothée Simon-Franza
 */
const TargetDetailsPage = () => {
	const { targetId, targetingId } = useParams();
	const dispatch = useDispatch();
	const focusRef = useRef();
	const targetPartners = useTargetPartnersForListSelector();
	const targetStats = useCurrentTargetStatsSelector();
	const targeting = useCurrentTargetingSelector(targetingId);

	const [selectedTargetPartnerTabId, setSelectedTargetPartnerTabId] = useState(null);
	const [openTargetPartnerTabs, setOpenTargetPartnerTabs] = useState([]);

	useEffect(() => {
		dispatch(fetchTarget(targetId));
		dispatch(fetchTargetStats(targetId));
		dispatch(fetchTargeting(targetingId));
		// No need to fetchTargetPartnersList cause it's fetched in the TargetPartnerList component
	}, [dispatch, targetId, targetingId]);

	/**
	 * @function
	 * @name focusOrOpenTargetPartnerTab
	 * @description Focuses the tab corresponding to the given target partner id, or opens it if it is not open.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @param {string} targetPartnerId The id of the target partner to focus or open the tab for.
	 */
	const focusOrOpenTargetPartnerTab = useCallback((targetPartnerId) => {
		if (!targetPartnerId) {
			return;
		}

		setOpenTargetPartnerTabs((prevState) => {
			if (!prevState.includes(targetPartnerId)) {
				return [...prevState, targetPartnerId];
			}

			return prevState;
		});

		setSelectedTargetPartnerTabId(`${targetPartnerId}-tab`);
		flushSync(() => focusRef.current?.focus());
	}, []);

	const onTargetPartnerTabClose = useCallback((closedTabId) => {
		setOpenTargetPartnerTabs((prevState) => prevState.filter((tabId) => !closedTabId.includes(tabId)));
		const closedTabIndex = openTargetPartnerTabs.findIndex((element) => closedTabId.includes(element));
		if (closedTabId === selectedTargetPartnerTabId && openTargetPartnerTabs.length > 1) {
			const newSelectedTabIndex = closedTabIndex === 0 ? closedTabIndex + 1 : closedTabIndex - 1;
			setSelectedTargetPartnerTabId(`${openTargetPartnerTabs[newSelectedTabIndex]}-tab`);
		}
	}, [openTargetPartnerTabs, selectedTargetPartnerTabId]);

	const onTabSelectionChanged = useCallback((tabId) => {
		focusRef.current?.focus();
		setSelectedTargetPartnerTabId(tabId);
	}, []);

	const onKeyDown = useCallback((key) => {
		const targetPartnerId = selectedTargetPartnerTabId.slice(0, -4);
		const index = targetPartners.findIndex((elt) => elt.id === targetPartnerId);
		let newIndex = index;
		if (key === 'ArrowDown') {
			newIndex = index + 1 < targetPartners.length ? index + 1 : index;
		} else if (key === 'ArrowUp') {
			newIndex = index - 1 >= 0 ? index - 1 : index;
		} else if (key === 'Escape') {
			setOpenTargetPartnerTabs([]);
		}
		if (newIndex !== index && index !== -1) {
			const newTargetPartnerId = targetPartners[newIndex].id;
			setOpenTargetPartnerTabs((prevState) => {
				const newState = prevState.filter((stateId) => stateId !== newTargetPartnerId);
				newState[newState.findIndex((id) => targetPartnerId === id)] = newTargetPartnerId;

				return newState;
			});
			setSelectedTargetPartnerTabId(`${newTargetPartnerId}-tab`);
		}
	}, [targetPartners, selectedTargetPartnerTabId]);

	return (
		<div className="target-details-page">
			<div className="page-header">
				<TargetHeader targetingId={targetingId} targetId={targetId} targetStats={targetStats} />
			</div>
			<TargetPartnerList
				targetingId={targetingId}
				targetId={targetId}
				targetPartners={targetPartners}
				setTargetPartnerActive={focusOrOpenTargetPartnerTab}
				data-tabs-open={openTargetPartnerTabs.length > 0}
			/>
			{openTargetPartnerTabs.length > 0 && (
			<aside>
				<TabList
					defaultSelectedTabId={selectedTargetPartnerTabId}
					onTabSelectionChanged={onTabSelectionChanged}
					onTabClosed={onTargetPartnerTabClose}
					haveKeyNavigation
				>
					{openTargetPartnerTabs.map((targetPartnerId) => (
						<Tab key={targetPartnerId} tabId={`${targetPartnerId}-tab`} tabPanelId={`${targetPartnerId}-tabpanel`}>
							{targeting?.targetPartners.filter(({ id }) => id === targetPartnerId)?.[0]?.partner?.name}
						</Tab>
					))}
				</TabList>

				<div tabIndex={0} role="button" onKeyDown={(evt) => onKeyDown(evt.key)} ref={focusRef}>
					{openTargetPartnerTabs.map((targetPartnerId) => (
						<TabPanel
							key={targetPartnerId}
							tabId={`${targetPartnerId}-tab`}
							tabPanelId={`${targetPartnerId}-tabpanel`}
							hidden={selectedTargetPartnerTabId !== `${targetPartnerId}-tab`}
						>
							<TargetPartnerDetails
								targetId={targetId}
								targetingId={targetingId}
								targetPartnerId={targetPartnerId}
							/>
						</TabPanel>
					))}
				</div>
			</aside>
			)}
		</div>
	);
};

export default TargetDetailsPage;
