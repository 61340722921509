import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useSectorsListLoadingSelector
 * @description A selector to retrieve the loading state from the sectorsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useSectorsListLoadingSelector = () => {
	const loading = useSelector((state) => state.sectorsList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useSectorsForListSelector
 * @description A selector to retrieve the sectorsList list from the sector redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useSectorsForListSelector = () => {
	const sectorsList = useSelector((state) => (state.sectorsList?.items ?? []), shallowEqual);

	return sectorsList;
};

/**
 * @function
 * @name useSectorsListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the sectorsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useSectorsListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.sectorsList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useSectorsForListActiveListSelector
 * @description A selector to retrieve all non-archived sectors from the sector redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useSectorsForListActiveListSelector = () => {
	const sectors = useSectorsForListSelector();

	const activeSectors = useMemo(() => sectors.filter((sector) => !sector.archived), [sectors]);

	return activeSectors;
};

export {
	useSectorsForListActiveListSelector,
	useSectorsForListSelector,
	useSectorsListLoadingSelector,
	useSectorsListTotalCountSelector,
};
