import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchRelationTypeList } from 'redux/actions/relationTypes';
import { useRelationTypeListSelector, useRelationTypeTotalCountSelector } from 'redux/selectors/relationTypes';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import ActionsCell from 'components/relationTypes/relationTypeList/ActionsCell';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name RelationTypeListPage
 * @description A page displaying the list of all relation Types.
 *
 * From this page, the user is able to view, create and edit any relation Type.
 *
 * @author Audrey Clerc
 */
const RelationTypeListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const relationTypes = useRelationTypeListSelector();
	const totalCount = useRelationTypeTotalCountSelector();

	const canCreateRelationType = useAccessRight(AccessRights.commonModules.relationTypes.enhancedRights.CREATE_RELATION_TYPE);

	const headers = useMemo(() => [
		{ accessor: 'name', Header: t('relation_type.fields.name'), pinColumn: true, sorting: 'relationType.name' },
		{ id: 'actions', Header: '', Cell: ActionsCell, name: t('relation_type.fields.actions'), pinColumn: true },
		{
			id: 'archived',
			accessor: (row) => (t(`relation_type.fields.archived.${row.archived ? 'yes' : 'no'}`)),
			Header: t('relation_type.fields.archived.header'),
			sorting: 'relationType.archived',
		},
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('relation_type.fields.creation_date'), sorting: 'relationType.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('relation_type.fields.update_date'), sorting: 'relationType.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('relation_type.fields.creation_name'), sorting: 'relationType.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('relation_type.fields.update_name'), sorting: 'relationType.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchRelationTypeList(params)));
	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('relationType');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.commonModules.relationTypes.relationTypeCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateRelationType && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('relation_type.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				disableSelection
				headers={headers}
				rows={relationTypes}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="relation_type"
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('relation_type.pages.list')} {...modalProps} />
		</div>
	);
};

export default RelationTypeListPage;
