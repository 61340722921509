import { Fragment, memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

import { DragAndDropTypes } from '../../../constants';
import { EMPTY_ROW } from '../../../constants/ElementTypes';
import { PageOrientationDimensions } from '../../../constants/PagesOrientations';
import EditorContext from '../../../EditorContext';
import { decrementElementsInHeader, insertElement, insertRow } from '../../../reducer/actions';

import ContextMenu from './ContextMenu';
import Footer from './Footer';
import Header from './Header';
import { Row } from '.';

/**
 * @name Page
 * @description A droppable area containing elements of a PDF template
 *
 * @author Florian Fornazaric
 *
 * @param {number} orientation  		The orientation of the page
 * @param {array}  lastPages    		The n-2 page list of the template
 * @param {array}  futureLastPages    	The n-1 page list of the template
 * @param {number} aspectRatio	     	The aspect ratio of the template
 * @param {array}  rows 	  			The rows indexes contained in the page
 * @param {number} pageIndex 			The page index
 * @param {string} pageId				The page id
 */
const Page = ({ orientation, lastPages, futureLastPages, aspectRatio, rows, pageIndex, pageId }) => {
	const { dispatch, isExporting, header, footer, elements, movingElement, margins } = useContext(EditorContext);
	const pageRef = useRef();
	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
	const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

	const [, drop] = useDrop(() => ({
		accept: [DragAndDropTypes.PDF_ELEMENT_TYPE, DragAndDropTypes.PDF_ELEMENT, DragAndDropTypes.PDF_ROW],
		drop: (item, monitor) => {
			// If the draggable has already been handled by another nested `drop` function
			if (monitor.getDropResult()?.handled === true) {
				return;
			}

			// If the drop was over another droppable element, we don't process the drop
			if (!monitor.isOver({ shallow: true })) {
				return;
			}

			// If the dropped item is an element type
			if (monitor.getItemType() === DragAndDropTypes.PDF_ELEMENT_TYPE) {
				let index = 0;

				// The following instructions allow us to consider the possibilities that: the page is empty but the header contains something
				// And if the page is empty because it's generated after a page break.

				// If we are on the first page and the page is empty, we add it to the content of the page
				if (pageIndex === 0 && !rows[rows.length - 1]) {
					index = header.numberOfElements;
				// If the page is empty but we are not on the first page, it means we are on a page generated after a page break,
				// so we add the element to the end of the document
				} else if (!rows[rows.length - 1]) {
					index = elements.length - 1 - footer.numberOfElements;
				// Else if the page is not empty, we add it to the provided index
				} else {
					index = rows[rows.length - 1];
				}

				if (item.type === EMPTY_ROW.type) {
					// Append a new empty row
					dispatch(insertRow(index + 1));
				} else {
					// Append a new row with the requested element type populating it
					dispatch(insertElement(item, index + 1, undefined, undefined, false));
				}
			}
		},
		hover: (item, monitor) => {
			switch (monitor.getItemType()) {
				case DragAndDropTypes.PDF_ROW:
					if (monitor.isOver({ shallow: true })) {
						// Allows us to add the row to the page when moved from the header
						if (elements.findIndex((row) => row.id === item.id) > header.numberOfElements && movingElement?.elementIndex < header.numberOfElements) {
							dispatch(decrementElementsInHeader());
						}
					}
					break;
				default:
					break;
			}
		},
		collect: (monitor) => ({
			isShallowOver: monitor.isOver({ shallow: true }),
			canDrop: monitor.canDrop(),
			type: monitor.getItemType(),
		}),
	}), [rows, header.numberOfElements, movingElement]);

	drop(pageRef);

	const handleClosingMenu = useCallback(() => {
		setIsContextMenuOpen(false);
	}, []);

	const handleContextMenu = useCallback((e) => {
		if (!isExporting) {
			// We remove the ContextMenu behaviour
			e.preventDefault();
			e.stopPropagation();
			// Allows us to set the position of the context menu
			setAnchorPoint({ x: e.clientX, y: e.clientY });
			setIsContextMenuOpen(true);
		}
	}, [isExporting]);

	// pageStyle uses paddings to simulate margins because we dont want to interfer with the calculations of the header and footer sizes.
	const pageStyle = useMemo(() => ({
		// This allows us to take into account the margins when there's no header or footer
		paddingTop: header.excludeFromPages.includes(pageId) ? margins.top : 0,
		paddingBottom: footer.excludeFromPages.includes(pageId) ? margins.bottom : 0,

		height: PageOrientationDimensions[orientation].height * aspectRatio,
		width: PageOrientationDimensions[orientation].width * aspectRatio,
	}), [header.excludeFromPages, pageId, margins.top, margins.bottom, footer.excludeFromPages, orientation, aspectRatio]);

	// Here we use paddings to be consistent with the pageStyle
	const contentStyle = useMemo(() => ({
		paddingLeft: `${margins.left * aspectRatio}px`,
		paddingRight: `${margins.right * aspectRatio}px`,
	}), [aspectRatio, margins.left, margins.right]);

	return (
		<div
			ref={pageRef}
			className="page"
			style={pageStyle}
			onContextMenu={handleContextMenu}
		>
			{/* if the header isn't removed from the current page, we render it */}
			{!header.disabled
			&& header.excludeFromPages !== undefined
			&& !header.excludeFromPages.includes(pageId)
			&& (
				<Header key={`${pageIndex}-header`} aspectRatio={aspectRatio} pageIndex={pageIndex} />
			)}
			{/* This allows us to put paddings on the page content */}
			<div style={contentStyle} className="page-content">
				{rows.map((rowIndex) => {
					const row = elements[rowIndex];
					const isMovingBetweenPages = lastPages[row?.id] !== undefined && lastPages[row?.id] !== futureLastPages[row?.id];

					return (
						row && (
							<Fragment key={row?.id}>
								<Row
									key={`${row.id}${movingElement?.element.id === row.id ? 'moving' : ''}`}
									id={row.id}
									index={rowIndex}
									count={row.children.length}
									elements={row.children}
									aspectRatio={aspectRatio}
									height={row.height}
									pageIndex={pageIndex}
									isMovingBetweenPages={isMovingBetweenPages}
								/>
								{/* When an element is moving, we create a "dummy" row that isn't shown so we avoid a React DND bug */}
								{(movingElement?.elementIndex === rowIndex && movingElement?.element.id !== row.id && (
								<Row
									key={movingElement.element.id}
									id={movingElement.element.id}
									index={rowIndex}
									count={movingElement.element.children.length}
									elements={movingElement.element.children}
									aspectRatio={aspectRatio}
									height={movingElement.element.height}
									pageIndex={pageIndex}
									isMovingBetweenPages={false}
									isShown={false}
								/>
								))}
							</Fragment>
						)
					);
				})}
			</div>
			{!footer.disabled
			&& footer.excludeFromPages !== undefined
			&& !footer.excludeFromPages.includes(pageId)
			&& (
				<Footer key={`${pageIndex}-footer`} aspectRatio={aspectRatio} pageIndex={pageIndex} />
			)}
			<ContextMenu pageIndex={pageIndex} anchorPoint={anchorPoint} isShowing={isContextMenuOpen} handleClose={handleClosingMenu} />
		</div>
	);
};

Page.propTypes = {
	orientation: PropTypes.string.isRequired,
	lastPages: PropTypes.objectOf(PropTypes.number).isRequired,
	futureLastPages: PropTypes.objectOf(PropTypes.number).isRequired,
	aspectRatio: PropTypes.number.isRequired,
	rows: PropTypes.arrayOf(PropTypes.number).isRequired,
	pageIndex: PropTypes.number.isRequired,
	pageId: PropTypes.string.isRequired,
};

export default memo(Page);
