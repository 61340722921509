import { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import update from 'immutability-helper';
import { useForwardedRef, useNavigationBlock } from 'lib/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DynamicFormContext from './internals/DynamicFormContext';

/**
 * @name DynamicForm
 * @description A dynamic form component.
 *
 * @author Yann Hodiesne
 *
 * @param {node}	children				The components to render inside the form.
 * @param {object}	defaultValues			The default values to pass to the form's inputs.
 * @param {func}	onSubmit				The method to trigger whenever the form is submitted.
 * @param {bool}	[disabled=false]		Whether the form is disabled or not.
 * @param {bool}	[readOnly=false]		Whether the form is read-only or not.
 * @param {bool}	[blockNavigation=true]	A boolean used to block navigation when the form is edited by the user. Defaults to true.
 * @param {bool}	[cleanFormData=false]	Disables merging the form data with its default values when submitting.
 */
const DynamicForm = forwardRef(({ children, defaultValues, onSubmit, disabled, readOnly, blockNavigation, cleanFormData, ...otherProps }, ref) => {
	const resolvedRef = useForwardedRef(ref);
	const { t } = useTranslation();

	// Contains all callbacks used to retrieve the values
	const getters = useRef({});

	// Contains all validators for the current form
	const validators = useRef({});

	// Contains all paths to remove before generating the form values
	const resets = useRef([]);

	// Contains all dirty flags for the current form
	const [dirtyInputs, setDirtyInputs] = useState([]);

	// Get the form values as expected by the onSubmit callback
	const getFormValues = useCallback(() => {
		const emptyResult = _.isArray(defaultValues) ? [] : {};
		const result = cleanFormData ? emptyResult : _.cloneDeep(defaultValues);

		resets.current.forEach((name) => _.set(result, name, undefined));

		Object.entries(getters.current).forEach(([name, getter]) => _.set(result, name, getter()));

		return result;
	}, [cleanFormData, defaultValues]);

	// Register an input against the form
	const registerInput = useCallback((name, getter, validate) => {
		getters.current[name] = getter;
		validators.current[name] = validate;

		return () => {
			delete getters.current[name];
			delete validators.current[name];
		};
	}, []);

	// Register a path as to be emptied before computing form values
	const registerReset = useCallback((name) => {
		// eslint-disable-next-line require-jsdoc
		const removal = () => resets.current.splice(resets.current.indexOf(name));

		if (resets.current.includes(name)) {
			return removal;
		}

		resets.current.push(name);

		return removal;
	}, []);

	// Register the dirty state of an input
	const setDirty = useCallback((name, value) => {
		if (value) {
			setDirtyInputs((currValue) => update(currValue, {
				$push: [name],
			}));
		} else {
			setDirtyInputs((currValue) => update(currValue, {
				$splice: [[currValue.indexOf(name), 1]],
			}));
		}
	}, []);

	const isDirty = useMemo(() => dirtyInputs.length !== 0, [dirtyInputs.length]);

	// Validates all inputs inside this form, return true if everything is good
	const validateAll = useCallback(() => {
		const results = Object.values(validators.current).map((validator) => !validator());

		return results.filter(Boolean).length === 0;
	}, []);

	const unblockNavigation = useNavigationBlock(t('components.form.confirm_exit'), blockNavigation && isDirty);

	// Submits the form if its validation passed
	const handleSubmit = useCallback(() => {
		const formValues = getFormValues();

		if (disabled || readOnly) {
			onSubmit(formValues);

			return;
		}

		if (validateAll()) {
			unblockNavigation();
			onSubmit(formValues);
		} else {
			// If fields are not ok for validation, a toast is displaying the fileds' name
			const errorKeys = [];
			Object.entries(validators.current).forEach(([key, validator]) => {
				if (!validator()) {
					errorKeys.push(t(`form.fields.${key.split('[')[0]}`));
				}
			});
			toast.error(t('form.fields.list_validation_error').concat(': ').concat(errorKeys.join(', ')));
		}
	}, [getFormValues, disabled, readOnly, validateAll, onSubmit, unblockNavigation, t]);

	// Register an imperative handle to add a function to the provided ref
	// This function is needed for the useSubmitButton hook
	useImperativeHandle(resolvedRef, () => ({
		triggerSubmit: handleSubmit,
		getFormValues,
	}), [getFormValues, handleSubmit]);

	// Cancels all 'submit' events coming from the DOM, to avoid having multiple forms submitting when a single button has been clicked
	const submitCanceler = useCallback((e) => {
		e?.preventDefault();

		// If useSubmitButton has not been used on this form, submit the form manually
		if (!ref) {
			handleSubmit();
		}
	}, [handleSubmit, ref]);

	// Register the current form's context, to let the inputs register their form hooks and retrieve their default values
	const dynamicFormContext = useMemo(() => ({
		defaultValues,
		disabled,
		readOnly,
		registerInput,
		registerReset,
		setDirty,
	}), [defaultValues, disabled, readOnly, registerInput, registerReset, setDirty]);

	return (
		<DynamicFormContext.Provider value={dynamicFormContext}>
			<form {...otherProps} ref={resolvedRef} className="styled-dynamic-form" noValidate onSubmit={submitCanceler}>
				{children}
			</form>
		</DynamicFormContext.Provider>
	);
});

DynamicForm.displayName = 'DynamicForm';

DynamicForm.propTypes = {
	children: PropTypes.node.isRequired,
	defaultValues: PropTypes.object,
	blockNavigation: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	cleanFormData: PropTypes.bool,
};

DynamicForm.defaultProps = {
	defaultValues: {},
	disabled: false,
	readOnly: false,
	blockNavigation: true,
	cleanFormData: false,
};

export default memo(DynamicForm);
