import { useCallback } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';
import PropTypes from 'prop-types';
import { reactSelectStyle } from 'theme/inputStyles';

/**
 * @name Select
 * @description A decorated version of the react-select component to be used without a air-react-forms context.
 *
 * @author Romaric Barthe
 *
 * @param {func} [onAddClick]	The method to trigger whenever the input's add button "+" is clicked.
 */
const Select = ({ onAddClick, ...otherProps }) => {
	const { t } = useTranslation();

	/**
	 * @function
	 * @name onAddClickHandler
	 * @description Function called when the "plus" button is pressed in order to remove the focus from the select.
	 *
	 * @author Matthieu Schaerlinger
	 */
	const onAddClickHandler = useCallback(() => {
		document.activeElement.blur();
		onAddClick();
	}, [onAddClick]);

	// Custom IndicatorSeparator to inject our + button when onAddClick has been given on this Select
	const IndicatorSeparator = useCallback((props) => (
		<>
			{onAddClick && (
				<button className="icon-only select-action" type="button" onClick={onAddClickHandler}>
					<Plus />
				</button>
			)}
			<components.IndicatorSeparator {...props} />
		</>
	), [onAddClick, onAddClickHandler]);

	return (
		<ReactSelect
			className="react-select"
			components={{ IndicatorSeparator }}
			styles={reactSelectStyle}
			menuPlacement="auto"
			noOptionsMessage={() => t('components.select.no_value')}
			isOptionDisabled={(option) => option.isDisabled}
			{...otherProps}
		/>
	);
};

Select.propTypes = {
	onAddClick: PropTypes.func,
};

Select.defaultProps = {
	onAddClick: undefined,
};

export default Select;
