import { useCallback, useState } from 'react';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

import UserMenu from './UserMenu';

/**
 * @name UserHeaderSection
 * @description The user-related section used inside the header.
 *
 * @author Timothée Simon-Franza
 */
const UserHeaderSection = () => {
	const currentUser = useCurrentConnectedUserSelector();
	const [isOpen, setIsOpen] = useState(false);

	const toggleDropdown = useCallback(() => {
		setIsOpen((value) => !value);
	}, [setIsOpen]);

	return (
		<div className="user-section">
			{/* TODO: Activate when avatar is properly setup
			<img src={currentUser?.avatar ?? conf.defaultUserAvatar} alt={t('components.header.user_avatar')} />
			*/}
			<button type="button" onClick={toggleDropdown}>
				<div className="username">{currentUser?.username}</div>
			</button>
			<UserMenu isOpen={isOpen} toggleDropdown={toggleDropdown} />
		</div>
	);
};

export default UserHeaderSection;
