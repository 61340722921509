import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { fetchTemplateList } from 'redux/actions/templates';

import { Button } from 'components/shared/buttons';
import { Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

const { useTemplateListSelector } = require('redux/selectors/templates');

/**
 * @name TemplateList
 * @description A select used to select a template.
 *
 * @author Audrey Clerc
 *
 * @param {func}	onChangeCallback	The method to trigger upon template selection.
 */
const TemplateList = ({ onChangeCallback }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [selectedTemplate, setSelectedTemplate] = useState(undefined);

	const templates = useTemplateListSelector().filter((template) => template.type === 'csv' || template.type === 'excel');
	useEffect(() => {
		dispatch(fetchTemplateList());
	}, [dispatch]);

	const onClick = useCallback(() => {
		if (!selectedTemplate) {
			toast.error(t('template.edition.toasts.template_selection'));
		} else {
			const template = templates.find((tplt) => tplt.id === selectedTemplate);
			onChangeCallback(template);
		}
	}, [selectedTemplate, t, templates, onChangeCallback]);

	return (
		<>
			<Select
				allowNull
				name="template"
				label={t('template.edition.name_selection')}
				options={templates}
				onChange={(value) => setSelectedTemplate(value)}
				labelKey="name"
				valueKey="id"
				rules={{
					required: Validators.isRequired(t('template.edition.toasts.template_selection')),
				}}
			/>
			<Button className="secondary" type="button" onClick={onClick}>
				{t('template.link.export_with_template')}
			</Button>
		</>
	);
};

TemplateList.propTypes = {
	onChangeCallback: PropTypes.func.isRequired,
};

export default TemplateList;
