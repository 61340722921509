import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentAccountSelector
 * @description A selector to retrieve the current account from the account redux state
 *
 * @author Audrey Clerc
 *
 * @param {string} id The identifier of the current account to wait for
 *
 * @returns {object}
 */
const useCurrentAccountSelector = (id) => {
	const account = useSelector((state) => (state.accounts?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (account?.id === id ? account : null), [id, account]);

	return result;
};

/**
 * @function
 * @name useAccountsLoadingSelector
 * @description A selector to retrieve the loading state from the account redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useAccountsLoadingSelector = () => {
	const loading = useSelector((state) => state.accounts?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useAccountListSelector
 * @description A selector to retrieve the account list from the account redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAccountListSelector = () => {
	const accounts = useSelector((state) => state.accounts?.items ?? [], shallowEqual);

	return accounts;
};

/**
 * @function
 * @name useAccountTotalCountSelector
 * @description A selector to retrieve the totalCount value from the account redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useAccountTotalCountSelector = () => {
	const totalCount = useSelector((state) => state.accounts?.totalCount ?? undefined, shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useAccountActiveListSelector
 * @description A selector to retrieve all non-archived accounts from the account redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAccountActiveListSelector = () => {
	const activeAccounts = useSelector((state) => (state.accounts?.items ?? []).filter((account) => !account.archived), shallowEqual);

	return activeAccounts;
};

export {
	useAccountActiveListSelector,
	useAccountListSelector,
	useAccountsLoadingSelector,
	useAccountTotalCountSelector,
	useCurrentAccountSelector,
};
