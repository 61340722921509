/**
 * @function
 * @name shouldMatchRegex
 * @description A validation method that returns the errorMessage parameter if the input's value doesn't match the provided regex.
 *
 * @param {string} regex The regex to compare the input's value against.
 * @param {string} errorMessage The error message to return if the input's value doesn't match the regex.
 *
 * @returns {string} The provided error message if validation fails, an empty string otherwise.
 */
const shouldMatchRegex = (regex, errorMessage) => (inputValue) => (regex.test(inputValue.trim()) ? '' : errorMessage);

export default shouldMatchRegex;
