import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchUser, updateUser } from 'redux/actions/users';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import routes from 'routes';

import { UserPreferencesForm } from 'components/users';

/**
 * @name UserPreferencesPage
 * @description A page used to edit user's preferences.
 *
 * @author Romaric Barthe
*/
const UserPreferencesPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const user = useCurrentConnectedUserSelector();

	useEffect(() => {
		dispatch(fetchUser(user?.id));
	}, [dispatch, user?.id]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(updateUser(formData, user.id, routes.user.params));
	}, [dispatch, user]);

	return (
		user
			? <UserPreferencesForm user={user} onSubmit={handleFormSubmit} />
			: <p>{t('user.edition.preferences.loading')}</p>
	);
};

export default UserPreferencesPage;
