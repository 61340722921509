import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name RelationTypeEditionForm
 * @description A form used to update an existing relation type.
 *
 * @author Audrey Clerc
 *
 * @param {function}	onSubmit	    The method to trigger upon form submission.
 * @param {object}		relationType	The relation type object to edit information from.
 */
const RelationTypeEditionForm = ({ onSubmit, relationType }) => {
	const { t } = useTranslation();

	const { formProps, buttonProps } = useSubmitButton();

	const canEditRelationType = useAccessRight(AccessRights.commonModules.relationTypes.enhancedRights.CREATE_RELATION_TYPE) && !relationType?.archived;

	return (
		<DynamicForm onSubmit={onSubmit} defaultValues={relationType} disabled={!canEditRelationType} {...formProps}>
			<TextInput
				label={t('relation_type.edition.inputs.name.label')}
				rules={{
					required: Validators.isRequired(t('relation_type.edition.inputs.name.validation_errors.required')),
				}}
				name="name"
				type="text"
			/>
			<Button className="primary" type="submit" {...buttonProps}>
				{canEditRelationType ? t('relation_type.edition.action') : t('relation_type.edition.close')}
			</Button>
		</DynamicForm>
	);
};

RelationTypeEditionForm.propTypes = {
	relationType: PropTypes.shape({
		name: PropTypes.string.isRequired,
		archived: PropTypes.bool,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default RelationTypeEditionForm;
