/* eslint-disable import/prefer-default-export */
import { get } from 'lib/shared/http';

/**
 * @function
 * @name fetchDashboard
 * @description Retrieves all values of the current user's dashboard from the API.
 *
 * @author Yann Hodiesne
 * @author Romaric Barthe
 *
 * @param {object} params  The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchDashboard = (params) => get('/api/dashboard', params);
