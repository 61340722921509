import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatQuotationName } from 'lib/quotations/formatQuotationData';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { formatCreatedAt, formatCreatedBy, formatNumber, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import PropTypes from 'prop-types';
import routes from 'routes';

import ActionsCell from 'components/quotations/quotationList/ActionsCell';
import StatusCell from 'components/shared/cells/StatusCell';
import { DynamicTable } from 'components/shared/tables';
import { sumOneObjectPropertyGroupByInArray } from 'components/shared/utils/functions';

/**
 * @name ProjectQuotations
 * @description A form used to group the rights fields.
 *
 * @author Romaric Barthe
 */
const ProjectQuotations = ({
	quotations,
	numberFormat,
}) => {
	const { t } = useTranslation();
	const canCreateQuotation = useAccessRight(AccessRights.sales.quotations.enhancedRights.CREATE_QUOTATION);

	const headers = useMemo(() => [
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('quotation.headers.actions') },
		{ id: 'name', accessor: (row) => formatQuotationName(row, numberFormat, t), Header: t('quotation.fields.number'), pinColumn: true, sorting: 'quotation.editionNumber' },
		{ id: 'status', Cell: StatusCell, Header: t('targeting.target.target_partner.fields.status'), sorting: 'status.name' },
		{
			id: 'grossTotal',
			accessor: (row) => formatNumber(row.grossTotal, { nbDecimalMin: 2, symbol: row.currency.name }),
			Header: t('quotation.fields.gross_total'),
			sorting: 'quotation.grossTotal',
		},
		{
			id: 'discountTotal',
			accessor: (row) => formatNumber(row.discountTotal, { nbDecimalMin: 2, symbol: row.currency.name }),
			Header: t('quotation.fields.discount_total'),
			sorting: 'quotation.discountTotal',
		},
		{
			id: 'grossTotalWithDiscount',
			accessor: (row) => formatNumber(row.grossTotalWithDiscount, { nbDecimalMin: 2, symbol: row.currency.name }),
			Header: t('quotation.fields.gross_total_with_discount'),
			sorting: 'quotation.grossTotalWithDiscount',
		},
		{
			id: 'vatTotal',
			accessor: (row) => formatNumber(row.vatTotal, { nbDecimalMin: 2, symbol: row.currency.name }),
			Header: t('quotation.fields.vat_total'),
			sorting: 'quotation.vatTotal',
		},
		{
			id: 'netTotal',
			accessor: (row) => formatNumber(row.netTotal, { nbDecimalMin: 2, symbol: row.currency.name }),
			Header: t('quotation.fields.net_total'),
			sorting: 'quotation.netTotal',
		},
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('quotation.fields.creation_date'), sorting: 'targetPartner.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('quotation.fields.update_date'), sorting: 'targetPartner.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('quotation.fields.creation_name'), sorting: 'targetPartner.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('quotation.fields.update_name'), sorting: 'targetPartner.updatedBy' },
	], [numberFormat, t]);

	return (
		<>
			{Object.entries(sumOneObjectPropertyGroupByInArray(quotations, 'currency', 'grossTotal')).map(
				([currency, value]) => (
					<div key={currency} className="table">
						<div className="header">{t('quotation.fields.gross_total')}</div>
						<div className="data">{formatNumber(value, { symbol: t(`currency.${currency}`) })}</div>
					</div>
				)
			)}
			{Object.entries(sumOneObjectPropertyGroupByInArray(quotations, 'currency', 'discountTotal')).map(
				([currency, value]) => (
					<div key={currency} className="table">
						<div className="header">{t('quotation.fields.discount_total')}</div>
						<div className="data">{formatNumber(value, { symbol: t(`currency.${currency}`) })}</div>
					</div>
				)
			)}
			{Object.entries(sumOneObjectPropertyGroupByInArray(quotations, 'currency', 'grossTotalWithDiscount')).map(
				([currency, value]) => (
					<div key={currency} className="table">
						<div className="header">{t('quotation.fields.gross_total_with_discount')}</div>
						<div className="data">{formatNumber(value, { symbol: t(`currency.${currency}`) })}</div>
					</div>
				)
			)}
			{Object.entries(sumOneObjectPropertyGroupByInArray(quotations, 'currency', 'netTotal')).map(
				([currency, value]) => (
					<div key={currency} className="table">
						<div className="header">{t('quotation.fields.net_total')}</div>
						<div className="data">{formatNumber(value, { symbol: t(`currency.${currency}`) })}</div>
					</div>
				)
			)}
			<div className="detailed-information">{t('quotation.fields.detailed_information')}</div>
			{canCreateQuotation && (
				<Link className="button secondary" to={routes.sales.quotations.quotationPreCreation}>
					{t('quotation.links.create')}
				</Link>
			)}
			<DynamicTable
				disableFetch
				disablePagination
				disableSelection
				headers={headers}
				rows={quotations}
				rowsCount={quotations.length}
				rowHeight={80}
				defaultSortingPrefix="name"
			/>
		</>
	);
};

ProjectQuotations.propTypes = {
	quotations: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			username: PropTypes.string,
		})
	),
	numberFormat: PropTypes.object,
};

ProjectQuotations.defaultProps = {
	quotations: [],
	numberFormat: undefined,
};

export default ProjectQuotations;
