import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearch } from 'lib/hooks';
import PropTypes from 'prop-types';
import { fetchPartnersForListWithTargets } from 'redux/actions/partners/partnersListWithTargets';

import { PartnerCreationForm } from 'components/partners';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { Modal, TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';

/**
 *
 * @param {} param0
 * @returns
 */
const TargetPartner = ({
	togglePartnersModal,
	isPartnersModalShowing,
	handlePartnerSubmit,
	headers,
	partners,
	totalCount,
	selectedTargetPartnersChanged,
	partnersIndices,
	isPartnerLoading,
	tableProps,
	modalProps,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [fetchData, searchRef] = useSearch((param) => {
		dispatch(fetchPartnersForListWithTargets({ ...param }));
	});

	return (
		<>
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				<Button onClick={togglePartnersModal}>
					{t('targeting.target.target_partner.create_entreprise')}
				</Button>
				<Modal isShowing={isPartnersModalShowing} title={t('targeting.target.target_partner.create_entreprise')} onClose={togglePartnersModal}>
					<PartnerCreationForm onSubmit={handlePartnerSubmit} />
				</Modal>
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={partners}
				rowsCount={totalCount}
				rowHeight={80}
				defaultSortingPrefix="partner"
				selectionChanged={selectedTargetPartnersChanged}
				fetchData={fetchData}
				selectedRowsIndices={partnersIndices}
				loading={isPartnerLoading}
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('partner.pages.list')} {...modalProps} />
		</>
	);
};

TargetPartner.propTypes = {
	togglePartnersModal: PropTypes.func.isRequired,
	isPartnersModalShowing: PropTypes.bool.isRequired,
	handlePartnerSubmit: PropTypes.func.isRequired,
	headers: PropTypes.arrayOf(PropTypes.object).isRequired,
	partners: PropTypes.arrayOf(PropTypes.object).isRequired,
	totalCount: PropTypes.number.isRequired,
	selectedTargetPartnersChanged: PropTypes.func.isRequired,
	partnersIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
	isPartnerLoading: PropTypes.bool.isRequired,
	tableProps: PropTypes.object.isRequired,
	modalProps: PropTypes.object.isRequired,
};

export default TargetPartner;
