/* eslint-disable import/prefer-default-export */
// @TODO: remove eslint-disable once there are more functions
import _ from 'lodash';

import { citiPattern, nacePattern, nafPattern } from 'components/shared/forms/inputs/constants/validationPatterns';
import Validators from 'components/shared/forms/validators';

/**
 * @function
 * @name getRules
 * @description formats the rule of the code pattern depending on the sector type
 *
 * @param {func} 	t 			A translation method provided by the withTranslation HoC.
 * @param {string} 	id			The id of the type code we want to format
 * @param {array}	sectorTypes	The array of all sectorTypes
 * @returns {object}
 */
export const getRules = (id, t, sectorTypes) => {
	if (sectorTypes.length === 0 || id === '') {
		return {};
	}
	const value = _.find(sectorTypes, (type) => type.id === id).name;
	switch (value) {
		case 'CITI':
			return {
				required: Validators.isRequired(t('sector.creation.inputs.code.validation_errors.required')),
				pattern: Validators.matchPattern(citiPattern, t('sector.creation.inputs.code.validation_errors.citi_pattern')),
			};
		case 'NACE':
			return {
				required: Validators.isRequired(t('sector.creation.inputs.code.validation_errors.required')),
				pattern: Validators.matchPattern(nacePattern, t('sector.creation.inputs.code.validation_errors.nace_pattern')),
			};
		case 'NAF':
			return {
				required: Validators.isRequired(t('sector.creation.inputs.code.validation_errors.required')),
				pattern: Validators.matchPattern(nafPattern, t('sector.creation.inputs.code.validation_errors.naf_pattern')),
			};
		default:
			return {};
	}
};
