import { Capabilities } from '../components';

/**
 * @name BasicProperties
 * @description Displays and let the user edit the basic properties of the selected element.
 *
 * @author Yann Hodiesne
 */
const BasicProperties = () => <Capabilities category="basic" />;

export default BasicProperties;
