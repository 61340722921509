import { useSelector } from 'react-redux';

import session from './session';

/**
 * @function
 * @name checkUserForAccessRight
 * @description Checks if the passed user has the required accessRight.
 *
 * @author Timothée Simon-Franza
 * @author Matthieu Schaerlinger
 *
 * @param {object}			user			The user to check.
 * @param {string|null}		accessRight		The access right to assert.
 *
 * @returns {boolean} Whether the user is superadmin, is companyOwner or has the given access right in its access definitions.
 */
export const checkUserForAccessRight = (user, accessRight) => {
	if (accessRight === undefined) {
		return true;
	}

	if (accessRight === null) {
		return false;
	}

	if (!session.exists()) {
		return false;
	}

	if (user?.superadmin) {
		return true;
	}

	if (!user?.accessDefinitions) {
		return false;
	}

	if (user.companyOwner) {
		return true;
	}

	return user.accessDefinitions
		.flatMap((accessDefinition) => accessDefinition.accessRights)
		.map((right) => right.name)
		.includes(accessRight);
};

/**
 * @function
 * @name useAccessRight
 * @description A helper method used to determine whether the current user has a given access right.
 *
 * @note This function is a hook, as such it must be used inside a React component
 *
 * @author Yann Hodiesne
 * @author Matthieu Schaerlinger
 *
 * @param {string}	accessRight		Access right to check against the current user rights.
 *
 * @returns {boolean} True if the current user has the given access right, false otherwise.
 */
export const useAccessRight = (accessRight) => checkUserForAccessRight(useSelector((state) => state.users.currentUser), accessRight);

/**
 * @constant
 * @name AccessRights
 * @description An object containing all access rights the user can own, organized by application.
 *
 * @author Romaric Barthe
 */
export const AccessRights = Object.freeze({
	commonModules: Object.freeze({
		VIEW: 'view_common_modules',
		contacts: Object.freeze({
			VIEW: 'view_contact',
			enhancedRights: Object.freeze({
				CREATE_CONTACT: 'create_contact',
				DELETE_CONTACT: 'delete_contact',
				EXPORT_CONTACT: 'export_contact',
			}),
		}),
		partners: Object.freeze({
			VIEW: 'view_partner',
			enhancedRights: Object.freeze({
				CREATE_PARTNER: 'create_partner',
				DELETE_PARTNER: 'delete_partner',
				EXPORT_PARTNER: 'export_partner',
			}),
		}),
		registrations: Object.freeze({
			VIEW: 'view_registration',
			enhancedRights: Object.freeze({
				CREATE_REGISTRATION: 'create_registration',
				DELETE_REGISTRATION: 'delete_registration',
			}),
		}),
		sectors: Object.freeze({
			VIEW: 'view_sector',
			enhancedRights: Object.freeze({
				CREATE_SECTOR: 'create_sector',
				DELETE_SECTOR: 'delete_sector',
			}),
		}),
		relationTypes: Object.freeze({
			VIEW: 'view_relation_type',
			enhancedRights: Object.freeze({
				CREATE_RELATION_TYPE: 'create_relation_type',
				DELETE_RELATION_TYPE: 'delete_relation_type',
			}),
		}),
	}),
	humanResources: Object.freeze({
		VIEW: 'view_human_resources',
		roles: Object.freeze({
			VIEW: 'view_access_definition',
			enhancedRights: Object.freeze({
				CREATE_ACCESS_DEFINITION: 'create_access_definition',
				DELETE_ACCESS_DEFINITION: 'delete_access_definition',
			}),
		}),
		users: Object.freeze({
			VIEW: 'view_user',
			enhancedRights: Object.freeze({
				CREATE_USER: 'create_user',
				DELETE_USER: 'delete_user',
				CHANGE_RIGHTS: 'change_rights',
				PROVIDE_ACCESS: 'provide_access',
			}),
		}),
		expenses: Object.freeze({
			VIEW: 'view_expense',
			enhancedRights: Object.freeze({
				CREATE_EXPENSE: 'create_expense',
				DELETE_EXPENSE: 'delete_expense',
				EXPORT_EXPENSE: 'export_expense',
			}),
		}),
		timesheets: Object.freeze({
			VIEW: 'view_timesheet',
			enhancedRights: Object.freeze({
				CREATE_TIMESHEET: 'create_timesheet',
				DELETE_TIMESHEET: 'delete_timesheet',
				EXPORT_TIMESHEET: 'export_timesheet',
			}),
		}),
		parameters: Object.freeze({
			VIEW: 'view_human_resources_parameters',
			enhancedRights: Object.freeze({
				CREATE_PARAMETER: 'create_human_resources_parameters',
				DELETE_PARAMETER: 'delete_human_resources_parameters',
			}),
		}),
	}),
	operations: Object.freeze({
		VIEW: 'view_operations',
		projects: Object.freeze({
			VIEW: 'view_project',
			enhancedRights: Object.freeze({
				CREATE_PROJECT: 'create_project',
				DELETE_PROJECT: 'delete_project',
			}),
		}),
		targetings: Object.freeze({
			VIEW: 'view_targeting',
			enhancedRights: Object.freeze({
				CREATE_TARGETING: 'create_targeting',
				DELETE_TARGETING: 'delete_targeting',
				EXPORT_TARGETING: 'export_targeting',
			}),
		}),
		parameters: Object.freeze({
			VIEW: 'view_operations_parameters',
			enhancedRights: Object.freeze({
				CREATE_PARAMETER: 'create_operations_parameters',
				DELETE_PARAMETER: 'delete_operations_parameters',
			}),
		}),
	}),
	accounting: Object.freeze({
		VIEW: 'view_accounting',
		accounts: Object.freeze({
			VIEW: 'view_account',
			enhancedRights: Object.freeze({
				CREATE_ACCOUNT: 'create_account',
				DELETE_ACCOUNT: 'delete_account',
			}),
		}),
		invoices: Object.freeze({
			VIEW: 'view_invoice',
			enhancedRights: Object.freeze({
				CREATE_INVOICE: 'create_invoice',
				DELETE_INVOICE: 'delete_invoice',
				EXPORT_INVOICE: 'export_invoice',
			}),
		}),
		parameters: Object.freeze({
			VIEW: 'view_accounting_parameters',
			enhancedRights: Object.freeze({
				CREATE_PARAMETER: 'create_accounting_parameters',
				DELETE_PARAMETER: 'delete_accounting_parameters',
			}),
		}),
	}),
	sales: Object.freeze({
		VIEW: 'view_sales',
		crm: Object.freeze({
			VIEW: 'view_crm',
			enhancedRights: Object.freeze({
				CREATE_CRM: 'create_crm',
				DELETE_CRM: 'delete_crm',
			}),
		}),
		offers: Object.freeze({
			VIEW: 'view_offer',
			enhancedRights: Object.freeze({
				CREATE_OFFER: 'create_offer',
				DELETE_OFFER: 'delete_offer',
			}),
		}),
		quotations: Object.freeze({
			VIEW: 'view_quotation',
			enhancedRights: Object.freeze({
				CREATE_QUOTATION: 'create_quotation',
				DELETE_QUOTATION: 'delete_quotation',
				EXPORT_QUOTATION: 'export_quotation',
			}),
		}),
		parameters: Object.freeze({
			VIEW: 'view_sales_parameters',
			enhancedRights: Object.freeze({
				CREATE_PARAMETER: 'create_sales_parameters',
				DELETE_PARAMETER: 'delete_sales_parameters',
			}),
		}),
	}),
	commonSettings: Object.freeze({
		VIEW: 'view_common_settings',
		settings: Object.freeze({
			VIEW: 'view_setting',
			enhancedRights: Object.freeze({
				CREATE_SETTING: 'create_setting',
				DELETE_SETTING: 'delete_setting',
			}),
		}),
		mediaManager: Object.freeze({
			VIEW: 'view_media_manager',
			enhancedRights: Object.freeze({
				CREATE_MEDIA_MANAGER: 'create_media_manager',
				DELETE_MEDIA_MANAGER: 'delete_media_manager',
				EDIT_MEDIA_MANAGER: 'edit_media_manager',
			}),
		}),
		templates: Object.freeze({
			VIEW: 'view_template',
			enhancedRights: Object.freeze({
				CREATE_TEMPLATE: 'create_template',
				DELETE_TEMPLATE: 'delete_template',
			}),
		}),
		history: Object.freeze({
			VIEW: 'view_history',
			enhancedRights: Object.freeze({
				CREATE_HISTORY: 'create_history',
				DELETE_HISTORY: 'delete_history',
			}),
		}),
	}),
});
