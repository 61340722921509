import PropTypes from 'prop-types';
import { applicationManagementForm } from 'theme/pages/superadmin/companyEditionPage/applicationManagementForm';

import ApplicationCard from './ApplicationCard';

/**
 * @name ApplicationManagementForm
 * @description A form used to set access rights to application modules for a specific company.
 *
 * @author Timothée Simon-Franza
 *
 * @param {array}	applications		 An array of all available applications enriched with an 'enabled' field for those who are enabled for the current company.
 * @param {func}	onApplicationToggle	 Method to call whenever an application is toggled on or off.
 */
const ApplicationManagementForm = ({ applications, onApplicationToggle }) => (
	<div style={{ display: 'grid', ...applicationManagementForm }}>
		{applications.map(({ id, name, active, description, enabled, iconPath }) => (
			<ApplicationCard
				key={id}
				id={id}
				name={name}
				enabled={enabled}
				active={active}
				description={description}
				iconPath={iconPath}
				onToggle={onApplicationToggle}
			/>
		))}
	</div>
);

ApplicationManagementForm.propTypes = {
	applications: PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		active: PropTypes.bool.isRequired,
		description: PropTypes.string.isRequired,
		enabled: PropTypes.bool,
		iconPath: PropTypes.string.isRequired,
	})).isRequired,
	onApplicationToggle: PropTypes.func.isRequired,
};

export default ApplicationManagementForm;
