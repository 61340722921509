/* eslint-disable import/prefer-default-export */
import { getHeaders } from 'lib/shared/http';
import streamSaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill/ponyfill';

/**
 * @function
 * @name downloadFile
 * @description Downloads a file from the provided ReadableStream.
 *
 * @author Yann Hodiesne
 *
 * @param {ReadableStream}	stream	The stream containing the file to download.
 * @param {string}	fileName		The file name to use inside the Downloads browser tab.
 */
export const downloadFile = (stream, fileName) => {
	// Use the ponyfills to ensure browser support
	streamSaver.WritableStream = streamSaver.WritableStream ?? WritableStream;

	streamSaver.mitm = '/streamDownload.html';

	// Create the writable stream
	const fileStream = streamSaver.createWriteStream(fileName);

	// If the browser supports it, use the native stream piping to save the file
	if (window.WritableStream && stream.pipeTo) {
		return stream.pipeTo(fileStream);
	}

	// Fallback to write the file manually
	const writer = fileStream.getWriter();
	// Get the readable stream
	const reader = stream.getReader();

	/**
	 * @function
	 * @name pump
	 * @description A helper function used to pump the readable stream into the writable stream.
	 *
	 * @author Yann Hodiesne
	 */
	const pump = () => reader.read()
		.then((result) => (result.done
			? writer.close()
			: writer.write(result.value).then(pump)
		));

	return pump();
};

/**
 * @function
 * @name downloadS3File
 * @description Downloads a file using the provided AttachedFile instance provided by the API (S3).
 *
 * @author Yann Hodiesne
 *
 * @param {object}	file				The attached file to download, provided by the API.
 * @param {string}	file.presignedUrl	The presigned url to download the file.
 * @param {string}	file.clientFileName	The client file name.
 * @param {string}	[extension=null]	The default extension of the file.
 */
export const downloadS3File = ({ presignedUrl, clientFileName }, extension = null) => fetch(presignedUrl)
	.then((res) => {
		// Construct the file name with its extension
		const fileName = !extension || clientFileName.endsWith(`.${extension}`) ? clientFileName : `${clientFileName}.${extension}`;

		return downloadFile(res.body, fileName);
	});

/**
 * @function
 * @name downloadServerFile
 * @description Downloads a file using the current user credentials.
 *
 * @author Yann Hodiesne
 *
 * @param {object}	file				The attached file to download, provided by the API.
 * @param {string}	file.presignedUrl	The presigned url to download the file.
 * @param {string}	file.clientFileName	The client file name.
 * @param {string}	[extension=null]	The default extension of the file.
 */
export const downloadServerFile = (url, fileName, extension = null) => fetch(url, {
	method: 'GET',
	headers: getHeaders(),
})
	.then((res) => {
		// Construct the file name with its extension
		const finalFileName = !extension || fileName.endsWith(`.${extension}`) ? fileName : `${fileName}.${extension}`;

		return downloadFile(res.body, finalFileName);
	});
