import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { fetchMedias, uploadFile as uploadFileAction } from 'redux/actions/mediaManager';
import { useMediaManagerLoadingSelector, useMediasSelector } from 'redux/selectors/mediaManager';

import { useModal } from 'components/shared/modal';

import { readFileToBase64 } from './utils';

/**
 * @function
 * @name useMediaManager
 * @description The companion hook to the MediaManager component.
 *
 * @author Yann Hodiesne
 *
 * @returns {object} {
		isShowing,
		toggle,
		files,
		areFilesLoading,
		uploadFile
	};
 */
export default () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();
	const { isShowing, toggle } = useModal();
	const { files } = useMediasSelector();
	const areFilesLoading = useMediaManagerLoadingSelector();

	const canOpenModal = useAccessRight(AccessRights.commonSettings.mediaManager.VIEW);
	const canUploadFile = useAccessRight(AccessRights.commonSettings.mediaManager.enhancedRights.CREATE_MEDIA_MANAGER);

	useEffect(() => {
		dispatch(fetchMedias());
	}, [dispatch]);

	const dispatchUpload = useCallback(async (fileContent, filename) => {
		const result = await dispatch(uploadFileAction({
			name: filename,
			image: fileContent,
			filename,
			folder: null,
		}));

		dispatch(fetchMedias());

		return result?.id;
	}, [dispatch]);

	/**
	 * @function
	 * @name uploadFile
	 * @description
	 *
	 * @author Yann Hodiesne
	 *
	 * @param {File} file The File instance to upload and add to the media manager
	 *
	 * @returns {Promise} A promise resolving to the new file ID.
	 */
	const uploadFile = useCallback(async (file) => {
		if (!canUploadFile) {
			return Promise.reject(`Missing access right: ${AccessRights.commonSettings.mediaManager.enhancedRights.CREATE_MEDIA_MANAGER}`);
		}

		const fileContent = await readFileToBase64(file);

		return dispatchUpload(fileContent, file.name);
	}, [canUploadFile, dispatchUpload]);

	const openMediaManager = useCallback(() => {
		if (!canOpenModal) {
			toast.error(t('media_manager.errors.missing_access_right'));

			return;
		}

		toggle();
	}, [canOpenModal, t, toggle]);

	return {
		isShowing,
		toggle: openMediaManager,
		files,
		areFilesLoading,
		uploadFile,
	};
};
