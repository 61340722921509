import * as ApplicationsApi from 'api/applicationsApi';

/**
 * The various action types used to interact with the Companies redux state.
 */
export const ActionTypes = {
	FETCH_APPLICATION_LIST_REQUEST: '@APPLICATIONS/FETCH_LIST_REQUEST',
	FETCH_APPLICATION_LIST_SUCCESS: '@APPLICATIONS/FETCH_LIST_SUCCESS',
	FETCH_APPLICATION_LIST_FAILURE: '@APPLICATIONS/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// /////////// Application list fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchApplicationListRequest
 * @description Action triggered anytime an application list call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const fetchApplicationListRequest = () => ({ type: ActionTypes.FETCH_APPLICATION_LIST_REQUEST });

/**
 * @function
 * @name fetchApplicationListSuccess
 * @description Action triggered as a result to a successfult application list fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} applications	The list of retrieved applications.
 * @param {number} totalCount	The total amount of applications available in the database.
 *
 * @returns {object}
 */
const fetchApplicationListSuccess = ({ applications, totalCount }) => ({
	type: ActionTypes.FETCH_APPLICATION_LIST_SUCCESS,
	payload: { applications, totalCount },
});

/**
 * @function
 * @name fetchApplicationListFailure
 * @description Action triggered as a result to a failed application list fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchApplicationListFailure = (error) => ({
	type: ActionTypes.FETCH_APPLICATION_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchApplicationList
 * @description Method used to update the applications list.
 *
 * @author Timothée Simon-Franza
 */
export const fetchApplicationList = () => (dispatch) => {
	dispatch(fetchApplicationListRequest());

	return ApplicationsApi.fetchApplications()
		.then(({ applications, totalCount }) => dispatch(fetchApplicationListSuccess({ applications, totalCount })))
		.catch((error) => dispatch(fetchApplicationListFailure(error)));
};
