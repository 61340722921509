import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchTextObjects
 * @description Retrieves a list of text objects from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchTextObjects = (params) => post('/api/text-objects', params);

/**
 * @function
 * @name fetchTextObjectById
 * @description Retrieves an existing text object from the API, using the textObjectId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} textObjectId	The id of the text object we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTextObjectById = (textObjectId) => get(`/api/text-object/${textObjectId}`);

/**
 * @function
 * @name createTextObject
 * @description Creates a new text object into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObjectData	The data to create the text object from.
 *
 * @returns {Promise}
 */
export const createTextObject = (textObjectData) => post('/api/text-objects/create', textObjectData);

/**
 * @function
 * @name updateTextObject
 * @description Updates an existing text object from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObjectData 	The data to update the text object with.
 * @param {string} textObjectId   	The id to identify the text object with.
 *
 * @returns {Promise}
 */
export const updateTextObject = (textObjectData, textObjectId) => put(`/api/text-object/${textObjectId}`, textObjectData);

/**
 * @function
 * @name deleteTextObject
 * @description Removes an existing text object from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} textObjectId	The id of the text object we want to remove.
 *
 * @returns {Promise}
 */
export const deleteTextObject = (textObjectId) => del(`/api/text-object/${textObjectId}`);
