/**
 * @constant
 * @name DragAndDropTypes
 * @description An enumeration used to define the drag and drop types, to handle separate behaviors for each type.
 *
 * @author Yann Hodiesne
 */
const DragAndDropTypes = Object.freeze({
	// Element type, used to create elements from the elements types inside the sidebar
	PDF_ELEMENT_TYPE: 'PDF_ELEMENT_TYPE',
	// Element, used to handle moving an element inside of/between rows
	PDF_ELEMENT: 'PDF_ELEMENT',
	// Elements row, used to handle moving an entire row and its children elements
	PDF_ROW: 'PDF_ROW',
});

export default DragAndDropTypes;
