import { toast } from 'react-toastify';
import * as QuotationsApi from 'api/quotationsApi';
import download from 'downloadjs';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Quotations redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific quotation
	FETCH_QUOTATION_REQUEST: '@QUOTATIONS/FETCH_REQUEST',
	FETCH_QUOTATION_SUCCESS: '@QUOTATIONS/FETCH_SUCCESS',
	FETCH_QUOTATION_FAILURE: '@QUOTATIONS/FETCH_FAILURE',

	// Fetch all for quotations
	FETCH_ALL_FOR_QUOTATION_REQUEST: '@QUOTATIONS/FETCH_ALL_FOR_REQUEST',
	FETCH_ALL_FOR_QUOTATION_SUCCESS: '@QUOTATIONS/FETCH_ALL_FOR_SUCCESS',
	FETCH_ALL_FOR_QUOTATION_FAILURE: '@QUOTATIONS/FETCH_ALL_FOR_FAILURE',

	// Fetch a list of quotations
	FETCH_QUOTATION_LIST_REQUEST: '@QUOTATIONS/FETCH_LIST_REQUEST',
	FETCH_QUOTATION_LIST_SUCCESS: '@QUOTATIONS/FETCH_LIST_SUCCESS',
	FETCH_QUOTATION_LIST_FAILURE: '@QUOTATIONS/FETCH_LIST_FAILURE',

	// Add a new quotation to the database
	ADD_QUOTATION_REQUEST: '@QUOTATIONS/ADD_REQUEST',
	ADD_QUOTATION_SUCCESS: '@QUOTATIONS/ADD_SUCCESS',
	ADD_QUOTATION_FAILURE: '@QUOTATIONS/ADD_FAILURE',

	// Update an existing quotation from the database
	UPDATE_QUOTATION_REQUEST: '@QUOTATIONS/UPDATE_REQUEST',
	UPDATE_QUOTATION_SUCCESS: '@QUOTATIONS/UPDATE_SUCCESS',
	UPDATE_QUOTATION_FAILURE: '@QUOTATIONS/UPDATE_FAILURE',

	// Remove an existing quotation from the database
	REMOVE_QUOTATION_REQUEST: '@QUOTATIONS/REMOVE_REQUEST',
	REMOVE_QUOTATION_SUCCESS: '@QUOTATIONS/REMOVE_SUCCESS',
	REMOVE_QUOTATION_FAILURE: '@QUOTATIONS/REMOVE_FAILURE',

	// Archive an existing quotation from the database
	ARCHIVE_QUOTATION_REQUEST: '@QUOTATIONS/ARCHIVE_REQUEST',
	ARCHIVE_QUOTATION_SUCCESS: '@QUOTATIONS/ARCHIVE_SUCCESS',
	ARCHIVE_QUOTATION_FAILURE: '@QUOTATIONS/ARCHIVE_FAILURE',

	// Restore an existing quotation from the database
	RESTORE_QUOTATION_REQUEST: '@QUOTATIONS/RESTORE_REQUEST',
	RESTORE_QUOTATION_SUCCESS: '@QUOTATIONS/RESTORE_SUCCESS',
	RESTORE_QUOTATION_FAILURE: '@QUOTATIONS/RESTORE_FAILURE',

	// Mark an existing quotation from the database as favorite
	FAVORITE_QUOTATION_REQUEST: '@QUOTATIONS/FAVORITE_REQUEST',
	FAVORITE_QUOTATION_SUCCESS: '@QUOTATIONS/FAVORITE_SUCCESS',
	FAVORITE_QUOTATION_FAILURE: '@QUOTATIONS/FAVORITE_FAILURE',

	// Cancel the favorite of an existing quotation from the database
	UNFAVORITE_QUOTATION_REQUEST: '@QUOTATIONS/UNFAVORITE_REQUEST',
	UNFAVORITE_QUOTATION_SUCCESS: '@QUOTATIONS/UNFAVORITE_SUCCESS',
	UNFAVORITE_QUOTATION_FAILURE: '@QUOTATIONS/UNFAVORITE_FAILURE',

	// Edit an existing quotation from the database
	EDIT_QUOTATION_REQUEST: '@QUOTATIONS/EDIT_REQUEST',
	EDIT_QUOTATION_SUCCESS: '@QUOTATIONS/EDIT_SUCCESS',
	EDIT_QUOTQTION_FAILURE: '@QUOTATIONS/EDIT_FAILURE',

	// Export quotations as a file
	EXPORT_QUOTATION_REQUEST: '@QUOTATIONS/EXPORT_REQUEST',
	EXPORT_QUOTATION_SUCCESS: '@QUOTATIONS/EXPORT_SUCCESS',
	EXPORT_QUOTATION_FAILURE: '@QUOTATIONS/EXPORT_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Quotation list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchQuotationListRequest
 * @description Action triggered anytime a quotation list fetching call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const fetchQuotationListRequest = () => ({ type: ActionTypes.FETCH_QUOTATION_LIST_REQUEST });

/**
 * @function
 * @name fetchQuotationListSuccess
 * @description Action triggered as a result to a successful quotation list fetching API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} quotations	The list of retrieved quotations.
 * @param {number} totalCount	The total amount of quotations available in the database for the current user.
 *
 * @returns {object}
 */
const fetchQuotationListSuccess = ({ quotations, totalCount }) => ({
	type: ActionTypes.FETCH_QUOTATION_LIST_SUCCESS,
	payload: { quotations, totalCount },
});

/**
 * @function
 * @name fetchQuotationListFailure
 * @description Action triggered as a result to a failed quotation list fetching API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchQuotationListFailure = (error) => ({
	type: ActionTypes.FETCH_QUOTATION_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Fetching all for quotation actions ///////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAllForQuotationRequest
 * @description Action triggered anytime a quotation "all for" fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchAllForQuotationRequest = () => ({ type: ActionTypes.FETCH_ALL_FOR_QUOTATION_REQUEST });

/**
 * @function
 * @name fetchAllForQuotationSuccess
 * @description Action triggered as a result to a successful quotation "all for" fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} allForForm	The object including all relevant information.
 *
 * @returns {object}
 */
const fetchAllForQuotationSuccess = ({ allForForm }) => ({
	type: ActionTypes.FETCH_ALL_FOR_QUOTATION_SUCCESS,
	payload: { allForForm },
});

/**
 * @function
 * @name fetchAllForQuotationFailure
 * @description Action triggered as a result to a failed quotation "all for" fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAllForQuotationFailure = (error) => ({
	type: ActionTypes.FETCH_ALL_FOR_QUOTATION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific quotation fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchQuotationRequest
 * @description Action triggered anytime a quotation fetching call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const fetchQuotationRequest = () => ({ type: ActionTypes.FETCH_QUOTATION_REQUEST });

/**
 * @function
 * @name fetchQuotationSuccess
 * @description Action triggered as a result to a successful quotation fetching API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} quotation	The retrieved quotation.
 *
 * @returns {object}
 */
const fetchQuotationSuccess = ({ quotation }) => ({
	type: ActionTypes.FETCH_QUOTATION_SUCCESS,
	payload: { quotation },
});

/**
 * @function
 * @name fetchQuotationFailure
 * @description Action triggered as a result to a failed quotation fetching API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchQuotationFailure = (error) => ({
	type: ActionTypes.FETCH_QUOTATION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Quotation creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addQuotationRequest
 * @description Action triggered anytime a quotation creation call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const addQuotationRequest = () => ({ type: ActionTypes.ADD_QUOTATION_REQUEST });

/**
 * @function
 * @name addQuotationSuccess
 * @description Action triggered as a result to a successful quotation creation API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} quotation	The created quotation object.
 *
 * @returns {object}
 */
const addQuotationSuccess = ({ quotation }) => ({
	type: ActionTypes.ADD_QUOTATION_SUCCESS,
	payload: { quotation },
});

/**
 * @function
 * @name addQuotationFailure
 * @description Action triggered as a result to a failed quotation creation API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addQuotationFailure = (error) => ({
	type: ActionTypes.ADD_QUOTATION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Quotation update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateQuotationRequest
 * @description Action triggered anytime a quotation update call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const updateQuotationRequest = () => ({ type: ActionTypes.UPDATE_QUOTATION_REQUEST });

/**
 * @function
 * @name updateQuotationSuccess
 * @description Action triggered as a result to a successful quotation update API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} quotation	The updated quotation object.
 *
 * @returns {object}
 */
const updateQuotationSuccess = ({ quotation }) => ({
	type: ActionTypes.UPDATE_QUOTATION_SUCCESS,
	payload: { quotation },
});

/**
 * @function
 * @name updateQuotationFailure
 * @description Action triggered as a result to a failed quotation update API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateQuotationFailure = (error) => ({
	type: ActionTypes.UPDATE_QUOTATION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Quotation removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeQuotationRequest
 * @description Action triggered anytime a quotation deletion call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const removeQuotationRequest = () => ({ type: ActionTypes.REMOVE_QUOTATION_REQUEST });

/**
 * @function
 * @name removeQuotationSuccess
 * @description Action triggered as a result to a successful quotation deletion API call.
 *
 * @author Roland Margelidon
 *
 * @param { string } deletedQuotationId	The removed quotation object id.
 *
 * @returns {object}
 */
const removeQuotationSuccess = ({ deletedQuotationId }) => ({
	type: ActionTypes.REMOVE_QUOTATION_SUCCESS,
	payload: { deletedQuotationId },
});

/**
 * @function
 * @name removeQuotationFailure
 * @description Action triggered as a result to a failed quotation deletion API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeQuotationFailure = (error) => ({
	type: ActionTypes.REMOVE_QUOTATION_FAILURE,
	payload: { error },
});
// //////////////////////////////////////////////////////// //
// //////////////// Quotation archiving actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveQuotationRequest
 * @description Action triggered anytime an quotation archiving call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const archiveQuotationRequest = () => ({ type: ActionTypes.ARCHIVE_QUOTATION_REQUEST });

/**
 * @function
 * @name archiveQuotationSuccess
 * @description	Action triggered as a result to a successful quotation archiving API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} quotation		The updated quotation object.
 *
 * @returns {object}
 */
const archiveQuotationSuccess = ({ quotation }) => ({
	type: ActionTypes.ARCHIVE_QUOTATION_SUCCESS,
	payload: { quotation },
});

/**
 * @function
 * @name archiveQuotationFailure
 * @description Action triggered as a result to a failed quotation archiving API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveQuotationFailure = (error) => ({
	type: ActionTypes.ARCHIVE_QUOTATION_FAILURE,
	payload: { error },
});

// /////////////////////////////////////////////////////////// //
// /////////////// Quotation restoring actions /////////////// //
// /////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreQuotationRequest
 * @description Action triggered anytime an quotation restoring call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const restoreQuotationRequest = () => ({ type: ActionTypes.RESTORE_QUOTATION_REQUEST });

/**
 * @function
 * @name restoreQuotationSuccess
 * @description	Action triggered as a result to a successful quotation restoring API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} quotation		The updated quotation object.
 *
 * @returns {object}
 */
const restoreQuotationSuccess = ({ quotation }) => ({
	type: ActionTypes.RESTORE_QUOTATION_SUCCESS,
	payload: { quotation },
});

/**
 * @function
 * @name restoreQuotationFailure
 * @description Action triggered as a result to a failed quotation restoring API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreQuotationFailure = (error) => ({
	type: ActionTypes.RESTORE_QUOTATION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// /////////////////// Quotation favorite actions ///////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name favoriteQuotationRequest
 * @description Action triggered anytime a quotation favorite call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const favoriteQuotationRequest = () => ({ type: ActionTypes.FAVORITE_QUOTATION_REQUEST });

/**
 * @function
 * @name favoriteQuotationSuccess
 * @description	Action triggered as a result to a successful quotation favorite API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const favoriteQuotationSuccess = () => ({ type: ActionTypes.FAVORITE_QUOTATION_SUCCESS });

/**
 * @function
 * @name favoriteQuotationFailure
 * @description Action triggered as a result to a failed quotation favorite API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error		The exception sent back from the API.
 *
 * @returns {object}
 */
const favoriteQuotationFailure = (error) => ({
	type: ActionTypes.FAVORITE_QUOTATION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// ///////////////// Quotation unfavorite actions ///////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name unfavoriteQuotationRequest
 * @description Action triggered anytime a quotation unfavorite call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const unfavoriteQuotationRequest = () => ({ type: ActionTypes.UNFAVORITE_QUOTATION_REQUEST });

/**
 * @function
 * @name unfavoriteQuotationSuccess
 * @description	Action triggered as a result to a successful quotation unfavorite API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const unfavoriteQuotationSuccess = () => ({ type: ActionTypes.UNFAVORITE_QUOTATION_SUCCESS });

/**
 * @function
 * @name unfavoriteQuotationFailure
 * @description Action triggered as a result to a failed quotation unfavorite API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error		The exception sent back from the API.
 *
 * @returns {object}
 */
const unfavoriteQuotationFailure = (error) => ({
	type: ActionTypes.UNFAVORITE_QUOTATION_FAILURE,
	payload: { error },
});

// ///////////////////////////////////////////////////////// //
// //////////////// Quotation edition actions ////////////// //
// ///////////////////////////////////////////////////////// //

/**
 * @function
 * @name editQuotationRequest
 * @description Action triggered anytime an quotation edition call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const editQuotationRequest = () => ({ type: ActionTypes.EDIT_QUOTATION_REQUEST });

/**
 * @function
 * @name editQuotationSuccess
 * @description Action triggered as a result to a successful quotation edition API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} enhancedQuotation The updated quotation object.
 *
 * @returns {object}
 */
const editQuotationSuccess = ({ enhancedQuotation }) => ({
	type: ActionTypes.EDIT_QUOTATION_SUCCESS,
	payload: { enhancedQuotation },
});

/**
 * @function
 * @name editQuotationFailure
 * @description Action triggered as a result to a failed quotation edition API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const editQuotationFailure = (error) => ({
	type: ActionTypes.EDIT_QUOTATION_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Quotation exporting actions /////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name exportQuotationRequest
 * @description Action triggered anytime a quotation exporting call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const exportQuotationRequest = () => ({ type: ActionTypes.EXPORT_QUOTATION_REQUEST });

/**
 * @function
 * @name exportQuotationSuccess
 * @description Action triggered as a result to a successful quotation exporting API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const exportQuotationSuccess = () => ({ type: ActionTypes.EXPORT_QUOTATION_SUCCESS });

/**
 * @function
 * @name exportQuotationFailure
 * @description Action triggered as a result to a failed quotation exporting API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const exportQuotationFailure = (error) => ({
	type: ActionTypes.EXPORT_QUOTATION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchQuotationList
 * @description Method used to update the quotation list.
 *
 * @author Roland Margelidon
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchQuotationList = (params) => (dispatch) => {
	dispatch(fetchQuotationListRequest());

	return QuotationsApi.fetchQuotations(params)
		.then(({ quotations, totalCount }) => dispatch(fetchQuotationListSuccess({ quotations, totalCount })))
		.catch((error) => dispatch(fetchQuotationListFailure(error)));
};

/**
 * @function
 * @name fetchAllForQuotation
 * @description Method used to load the quotation "all for" information.
 *
 * @author Romaric Barthe
 *
 * @param {string|null} id	The id of the quotation.
 */
export const fetchAllForQuotation = (id) => (dispatch) => {
	dispatch(fetchAllForQuotationRequest());

	return QuotationsApi.fetchAllForQuotationForm({ quotationId: id })
		.then((allForForm) => dispatch(fetchAllForQuotationSuccess({ allForForm })))
		.catch((error) => dispatch(fetchAllForQuotationFailure(error)));
};

/**
 * @function
 * @name fetchQuotation
 * @description Method used to fetch the latest version of a specific quotation.
 *
 * @author Roland Margelidon
 *
 * @param {string} quotationId	The id of the quotation we want to retrieve.
 */
export const fetchQuotation = (quotationId) => (dispatch) => {
	dispatch(fetchQuotationRequest());

	return QuotationsApi.fetchQuotationById(quotationId)
		.then(({ quotation }) => dispatch(fetchQuotationSuccess({ quotation })))
		.catch((error) => dispatch(fetchQuotationFailure(error)));
};

/**
 * @function
 * @name addQuotation
 * @description Method used to add a new quotation instance to the database.
 *
 * @author Roland Margelidon
 *
 * @param {object} quotationData	The data to create the new quotation from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addQuotation = (quotationData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addQuotationRequest());

	return QuotationsApi.createQuotation(quotationData)
		.then(({ quotation }) => {
			toast.success(i18next.t('quotation.creation.toasts.success', { name: quotation.project.name }));
			dispatch(addQuotationSuccess({ quotation }));
			redirectOnSuccess(onSuccessRoute);

			return quotation;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('quotation.creation.toasts.conflict'));
				dispatch(addQuotationFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('quotation.creation.toasts.error'));
				dispatch(addQuotationFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateQuotation
 * @description Method used to update an existing quotation instance from the database.
 *
 * @author Roland Margelidon
 *
 * @param {object} quotationData	The object to update the quotation with.
 * @param {string} quotationId		The id of the quotation we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateQuotation = (quotationData, quotationId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateQuotationRequest());

	return QuotationsApi.updateQuotation(quotationData, quotationId)
		.then(({ quotation }) => {
			dispatch(updateQuotationSuccess({ quotation }));
			toast.success(i18next.t('quotation.edition.toasts.success', { name: quotation.project.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('quotation.edition.toasts.conflict'));
				dispatch(updateQuotationFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('quotation.edition.toasts.error'));
				dispatch(updateQuotationFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeQuotation
 * @description Method used to remove an existing quotation instance from the database.
 *
 * @author Roland Margelidon
 * @author Audrey Clerc
 * @author Timothée Simon-Franza
 *
 * @param {object} quotation				The quotation we want to remove from the database.
 * @param {string} quotation.id				The id of the quotation we want to remove from the database.
 * @param {object} quotation.project		The project linked to the quotation we want to remove from the database.
 * @param {string} quotation.project.name	The name of the project linked to the quotation we want to remove from the database.
 */
export const removeQuotation = ({ id, project: { name: projectName } }) => (dispatch) => {
	dispatch(removeQuotationRequest());

	return QuotationsApi.deleteQuotation(id)
		.then(({ deletedQuotationId }) => {
			dispatch(removeQuotationSuccess({ deletedQuotationId }));
			toast.success(i18next.t('quotation.deletion.toasts.success', { name: projectName }));
		})
		.catch((error) => {
			dispatch(removeQuotationFailure(error));
			toast.error(i18next.t('quotation.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveQuotation
 * @description	Method used to archive an existing quotation instance from the database.
 *
 * @author Audrey Clerc
 * @author Timothée Simon-Franza
 *
 * @param {object} quotation				The quotation we want to archive.
 * @param {string} quotation.id				The id of the quotation we want to archive.
 * @param {object} quotation.project		The project linked to the quotation we want to archive.
 * @param {string} quotation.project.name	The name of the project linked to the quotation we want to archive.
 */
export const archiveQuotation = ({ id, project: { name: projectName } }) => (dispatch) => {
	dispatch(archiveQuotationRequest());

	return QuotationsApi.updateQuotation({ archived: true }, id)
		.then(({ quotation }) => {
			dispatch(archiveQuotationSuccess({ quotation }));
			toast.success(i18next.t('quotation.archiving.toasts.success', { name: projectName }));
		})
		.catch((error) => {
			dispatch(archiveQuotationFailure(error));
			toast.error(i18next.t('quotation.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreQuotation
 * @description	Method used to restore an existing quotation instance from the database.
 *
 * @author Audrey Clerc
 * @author Timothée Simon-Franza
 *
 * @param {object} quotation				The quotation we want to restore.
 * @param {string} quotation.id				The id of the quotation we want to restore.
 * @param {object} quotation.project		The project linked to the quotation we want to restore.
 * @param {string} quotation.project.name	The name of the project linked to the quotation we want to restore.
 */
export const restoreQuotation = ({ id, project: { name: projectName } }) => (dispatch) => {
	dispatch(restoreQuotationRequest());

	return QuotationsApi.updateQuotation({ archived: false }, id)
		.then(({ quotation }) => {
			dispatch(restoreQuotationSuccess({ quotation }));
			toast.success(i18next.t('quotation.restoring.toasts.success', { name: projectName }));
		})
		.catch((error) => {
			dispatch(restoreQuotationFailure(error));
			toast.error(i18next.t('quotation.restoring.toasts.error'));
		});
};

/**
 * @function
 * @name favoriteQuotation
 * @description	Method used to mark an existing quotation instance from the database as favorite.
 *
 * @author Romaric Barthe
 *
 * @param {object} quotation				The quotation we want to mark as favorite.
 * @param {string} quotation.id				The id of the quotation we want to mark as favorite.
 * @param {string} quotation.project		The number of the quotation we want to mark as favorite.
 */
export const favoriteQuotation = ({ id, project }) => (dispatch) => {
	dispatch(favoriteQuotationRequest());

	return QuotationsApi.updateQuotation({ favorite: true }, id)
		.then(() => {
			dispatch(favoriteQuotationSuccess());
			toast.success(i18next.t('quotation.edition.toasts.success', { name: project.name }));
		})
		.catch((error) => {
			dispatch(favoriteQuotationFailure(error));
			toast.error(i18next.t('quotation.edition.toasts.error'));
		});
};

/**
 * @function
 * @name unfavoriteQuotation
 * @description	Method used to remove an existing quotation instance from the database as favorite.
 *
 * @author Romaric Barthe
 *
 * @param {object} quotation				The quotation we want to remove from favorite.
 * @param {string} quotation.id				The id of the quotation we want to remove from favorite.
 * @param {string} quotation.project		The number of the quotation we want to remove from favorite.
 */
export const unfavoriteQuotation = ({ id, project }) => (dispatch) => {
	dispatch(unfavoriteQuotationRequest());

	return QuotationsApi.updateQuotation({ favorite: false }, id)
		.then(() => {
			dispatch(unfavoriteQuotationSuccess());
			toast.success(i18next.t('quotation.edition.toasts.success', { name: project.name }));
		})
		.catch((error) => {
			dispatch(unfavoriteQuotationFailure(error));
			toast.error(i18next.t('quotation.edition.toasts.error'));
		});
};

/**
 * @function
 * @name editQuotation
 * @description Method used to edit an quotation.
 *
 * @author Romaric Barthe
 *
 * @param {string} quotationId		The id of the quotation we want to edit.
 * @param {string} editionDate		The edition date of the quotation.
 */
export const editQuotation = (quotationId, editionDate) => (dispatch) => {
	dispatch(editQuotationRequest());

	return QuotationsApi.editQuotation(quotationId, editionDate)
		.then(({ quotation, number, date }) => {
			const enhancedQuotation = { ...quotation, number, generatedPdf: { pdfGenerationDate: date } };
			dispatch(editQuotationSuccess({ enhancedQuotation }));
		})
		.catch((error) => {
			toast.error(i18next.t('quotation.edition.toasts.error'));
			dispatch(editQuotationFailure(error));
		});
};

/**
 * @function
 * @name exportQuotations
 * @description Method used to export multiple quotation instances from the database.
 *
 * @author Romaric Barthe
 *
 * @param {Object}	data				The data to export the quotations.
 * @param {Array}	data.partnerIds		The ids of the quotations to export.
 * @param {string}	data.format			The file format.
 * @param {string}	data.restriction	The scope of the data to export (all, filtered or current selection).
 * @param {string}	data.separator		The separator used in the CSV file.
 *
 * @todo Adapt params when backend will change
 */
export const exportQuotations = (data) => (dispatch) => {
	dispatch(exportQuotationRequest());

	return QuotationsApi.exportQuotations({
		ids: data.partnerIds ?? [],
		format: data.format ?? 'xlsx',
		restriction: data.restriction ?? 'all',
		separator: data.separator ?? 'comma',
		...data,
	})
		.then(({ blob, fileName, fileType }) => {
			dispatch(exportQuotationSuccess());
			toast.success(i18next.t('template.export.toasts.success'));

			download(blob, fileName, fileType);
		})
		.catch((error) => {
			dispatch(exportQuotationFailure(error));
			toast.error(i18next.t('template.export.toasts.error'));
		});
};
