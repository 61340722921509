import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuotationColumnHeader, QuotationTypes } from 'constants/quotationEnums';
import { convertStringToFloat } from 'lib/shared/conversion';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import QuotationActionBar from './quotationCommon/QuotationActionBar';
import QuotationTitle from './quotationCommon/QuotationTitle';
import { getLinesForVisualisation, quotationColumnsHeaders as getColumnHeader } from './functions';

/**
 * @name QuotationVisualisation
 * @description A form used to edit an existing quotation's report.
 *
 * @author Romaric Barthe
 *
 * @param {object}		quotation		The quotation object to update information from.
 */
const QuotationVisualisation = ({ quotation }) => {
	const { t } = useTranslation();
	const columnsHeaders = useMemo(() => getColumnHeader(quotation.quotationType), [quotation.quotationType]);

	const rows = useMemo(() => getLinesForVisualisation(quotation), [quotation]);
	const columns = useMemo(() => (quotation.stakeholders
		? Object.keys(quotation.stakeholders).map((id) => quotation.stakeholders[id]).sort((a, b) => a.column - b.column)
		: undefined), [quotation.stakeholders]);

	const colSpan = columnsHeaders.filter((elem) => elem.show && elem.label !== QuotationColumnHeader[quotation.quotationType].ACTIONS).length;

	return (
		<div className="visualisation">
			<QuotationTitle quotation={quotation} />

			<QuotationActionBar quotation={quotation} />

			<div className="contents">
				<table>
					<thead className={quotation.quotationType === QuotationTypes.ARCHI ? 'archi' : 'standard'}>
						{/* Header for the archi quotation */
							quotation.quotationType === QuotationTypes.ARCHI && columns && (
								<>
									<tr>
										<th
											colSpan={colSpan}
											className="right"
										>
											{t('quotation.headers.sector')}

										</th>
										{
											columns.map((col) => (
												<th
													key={col.sector.name + col.colNumber}
													colSpan={colSpan}
													className="right"
												>
													{col.sector.name}
												</th>
											))
										}
									</tr>
									<tr>
										<th
											colSpan={colSpan}
											className="right"
										>
											{t('quotation.headers.partner')}

										</th>
										{
											columns.map((col) => (
												<th
													key={col.partner.name + col.colNumber}
													colSpan={colSpan}
													className="right"
												>
													{col.partner.name}
												</th>
											))
										}
									</tr>
									<tr>
										<th
											colSpan={colSpan}
											className="right"
										>
											{t('quotation.headers.partner_amount')}

										</th>
										{
											columns.map((col) => (
												<th
													key={col.partner.name + col.colNumber}
													colSpan={colSpan}
													className="right"
												>
													{formatNumber(col.partnerProjectAmount.toFixed(2), { symbol: t(`currency.${quotation.currency?.name}`) })}
												</th>
											))
										}

									</tr>
									<tr>
										<th
											colSpan={colSpan}
											className="right"
										>
											{t('quotation.headers.partner_ratio')}

										</th>
										{
											columns.map((col) => (
												<th
													key={col.partner.name + col.colNumber}
													colSpan={colSpan}
													className="right"
												>
													{formatNumber(col.partnerRatio.toFixed(2), { symbol: '%' })}
												</th>
											))
										}

									</tr>
									<tr>
										<th
											colSpan={colSpan}
											className="right"
										>
											{t('quotation.headers.discount')}

										</th>
										{
											columns.map((col) => (
												<th
													key={col.partner.name + col.colNumber}
													colSpan={colSpan}
													className="right"
												>
													{col.partnerDiscount ? formatNumber(col.partnerDiscount.toFixed(2), { symbol: '%' }) : '-'}
												</th>
											))
										}

									</tr>
								</>
							)
						}
						<tr>
							{columnsHeaders.filter(
								(elem) => elem.show
									&& elem.label !== QuotationColumnHeader[quotation.quotationType].ACTIONS
							).map((header) => {
								switch (header.label) {
									case QuotationColumnHeader[quotation.quotationType].UNITARY_COST:
									case QuotationColumnHeader[quotation.quotationType].QUANTITY:
									case QuotationColumnHeader[quotation.quotationType].TOTAL:
										return (
											<th key={header.label} className={quotation.quotationType === QuotationTypes.ARCHI ? 'archi-right title' : 'right title'}>
												{t(`quotation.headers.${header.label}`)}
											</th>
										);
									default:
										return (
											<th key={header.label} className={quotation.quotationType === QuotationTypes.ARCHI ? 'archi title' : ' title'}>
												{t(`quotation.headers.${header.label}`)}
											</th>
										);
								}
							})}
							{/* Total for the archi quotation */
								quotation.quotationType === QuotationTypes.ARCHI && (
									columns.map((col) => (
										<th key={col.colNumber} className="archi-right title" colSpan="2">
											{formatNumber((
												convertStringToFloat(col.partnerProjectAmount)
												* (convertStringToFloat(col.partnerRatio) / 100)
												* (col.partnerDiscount ? 1 - convertStringToFloat(col.partnerDiscount) / 100 : 1)
											).toFixed(2), { symbol: t(`currency.${quotation.currency?.name}`) })}
										</th>
									))
								)
							}
						</tr>
					</thead>

					<tbody>
						{rows.filter((r) => !r.isExtra).map((row) => (
							<tr key={`tr.${row.rowId}`}>

								{columnsHeaders.filter(
									(elem) => elem.show
									&& elem.label !== QuotationColumnHeader[quotation.quotationType].ACTIONS
								).map((header) => {
									switch (header.label) {
										case QuotationColumnHeader[quotation.quotationType].DESIGNATION:
											return (<td key={`td.${header.label}.${row.rowId}`}>{row.designation}</td>);
										case QuotationColumnHeader[quotation.quotationType].OFFER:
											return (<td key={`td.${header.label}.${row.rowId}`}>{row.offer}</td>);
										case QuotationColumnHeader[quotation.quotationType].TOTAL:
											// eslint-disable-next-line no-case-declarations
											let totalRow = 0;
											if (quotation.quotationType === QuotationTypes.STANDARD) {
												totalRow = row.quantity && row.unitaryCost && (
													convertStringToFloat(row.quantity) * convertStringToFloat(row.unitaryCost))
													* (row.discount ? 1 - ((convertStringToFloat(row.discount) ?? 0) / 100) : 1);
											}

											return (
												<td key={`td.${header.label}.${row.rowId}`} className="subtotal">
													{((row.quantity && row.unitaryCost) || row.baseAmount)
														? formatNumber(totalRow.toFixed(2), { symbol: t(`currency.${quotation.currency?.name}`) })
														: ''}
												</td>
											);
										case QuotationColumnHeader[quotation.quotationType].QUANTITY:
											return (
												<td key={`td.${header.label}.${row.rowId}`} className="number">
													{formatNumber(row.quantity)}
												</td>
											);
										case QuotationColumnHeader[quotation.quotationType].UNITARY_COST:
											return (
												<td key={`td.${header.label}.${row.rowId}`} className="number">
													{formatNumber(row.unitaryCost, { symbol: t(`currency.${quotation.currency.name}`) })}
												</td>
											);
										case QuotationColumnHeader[quotation.quotationType].DISCOUNT:
											return (
												<td key={`td.${header.label}.${row.rowId}`} className="number">
													{row.discount !== 0 ? formatNumber(row.discount, { symbol: '%' }) : ''}
												</td>
											);
										case QuotationColumnHeader[quotation.quotationType].VAT_RATE:
											return (
												<td key={`td.${header.label}.${row.rowId}`} className="number">
													{formatNumber(row.vatRate, { symbol: '%' })}
												</td>
											);
										default:
											return (<td key={`td.${header.label}.${row.rowId}`}> </td>);
									}
								})}

								{/* Ratio for the archi quotation */
									quotation.quotationType === QuotationTypes.ARCHI && (
										columns.map((col) => (
											<>
												<td key={`tdpct.${col.id}`} className="number">
													{row[col.id]?.rate ? formatNumber(convertStringToFloat(row[col.id].rate).toFixed(2), { symbol: '%' }) : ''}
												</td>
												<td key={`tdamt.${col.id}`} className="number">
													{row[col.id]?.base && row[col.id]?.rate && row[col.id]?.rate !== 0
														? formatNumber(convertStringToFloat(
															row[col.id].base * (row[col.id].rate / 100)
														).toFixed(2), { symbol: t(`currency.${quotation.currency.name}`) })
														: ''}
												</td>
											</>
										))
									)
								}

							</tr>
						))}
					</tbody>

					<tfoot>
						{/* Total for the standard quotation */
							quotation.quotationType === QuotationTypes.STANDARD && (
								<tr className="standard">
									{columnsHeaders.filter(
										(elem) => elem.show
											&& elem.label !== QuotationColumnHeader[quotation.quotationType].ACTIONS
									).map((header) => {
										switch (header.label) {
											case QuotationColumnHeader[quotation.quotationType].TOTAL:
												return (
													<td key={`total.${header.label}`} className="total">
														{formatNumber(quotation.grossTotal, { symbol: t(`currency.${quotation.currency.name}`) })}
													</td>
												);
											default:
												return (<td key={`total.${header.label}`}> </td>);
										}
									})}
								</tr>
							)
						}

						{rows.filter((r) => r.isExtra).length > 0 && (
							<tr className="extra-header">
								<td
									colSpan={columns.length * 2
										+ columnsHeaders.filter((elem) => elem.show && elem.label !== QuotationColumnHeader[quotation.quotationType].ACTIONS).length}
								>
									{t('quotation.headers.extra')}
								</td>
							</tr>
						)}

						{rows.filter((r) => r.isExtra).map((row) => (
							<tr key={`tr.${row.rowId}`} className="extra-detail">

								{columnsHeaders.filter(
									(elem) => elem.show
									&& elem.label !== QuotationColumnHeader[quotation.quotationType].ACTIONS
								).map((header) => {
									switch (header.label) {
										case QuotationColumnHeader[quotation.quotationType].DESIGNATION:
											return (<td key={`td.${header.label}.${row.rowId}`}>{row.description}</td>);
										case QuotationColumnHeader[quotation.quotationType].OFFER:
											return (<td key={`td.${header.label}.${row.rowId}`}>{row.offer}</td>);
										default:
											return ('');
									}
								})}

								{/* Ratio for the archi quotation (extra) */
									quotation.quotationType === QuotationTypes.ARCHI && (
										columns.map((col) => (
											<>
												<td key={`tdpct.${col.id}`} className="number">
													{row[col.id]?.rate ? formatNumber(convertStringToFloat(row[col.id]?.rate).toFixed(2), { symbol: '%' }) : ''}
												</td>
												<td key={`tdamt.${col.colNumber}`} className="number">
													{row[col.id]?.base && row[col.id]?.rate && row[col.id]?.rate !== 0
														? formatNumber(convertStringToFloat(
															row[col.id].base * (row[col.id].rate / 100)
														).toFixed(2), { symbol: t(`currency.${quotation.currency.name}`) })
														: ''}
												</td>
											</>
										))
									)
								}

							</tr>
						))}
					</tfoot>
				</table>
			</div>
		</div>
	);
};

QuotationVisualisation.propTypes = {
	quotation: PropTypes.shape({
		archived: PropTypes.bool,
		currency: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		description: PropTypes.string,
		discountTotal: PropTypes.number,
		grossTotal: PropTypes.number,
		grossTotalWithDiscount: PropTypes.number,
		id: PropTypes.string.isRequired,
		netTotal: PropTypes.number,
		number: PropTypes.number,
		quotationType: PropTypes.string,
		reference: PropTypes.string,
		rows: PropTypes.shape({
			id: PropTypes.string,
			offer: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
			vatRate: PropTypes.shape({
				id: PropTypes.string,
				rate: PropTypes.number,
			}),
		}).isRequired,
		seller: PropTypes.shape({
			id: PropTypes.string.isRequired,
			username: PropTypes.string.isRequired,
		}).isRequired,
		stakeholders: PropTypes.shape({
			id: PropTypes.string,
			partner: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
			sector: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
		}),
		structure: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		vatTotal: PropTypes.number,
	}).isRequired,
};

export default QuotationVisualisation;
