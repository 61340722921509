import { toast } from 'react-toastify';
import * as AccessRightsAPI from 'api/accessRightsApi';
import { redirectOnLogin } from 'lib/shared/redirectionHelper';

import { BreadcrumbsActions, updateBreadcrumbs } from './globals';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the access rights redux state.
 * @type {object}
 */
export const ActionTypes = {
	FETCH_ACCESS_RIGHTS_REQUEST: '@ACCESS_RIGHTS/FETCH_REQUEST',
	FETCH_ACCESS_RIGHTS_SUCCESS: '@ACCESS_RIGHTS/FETCH_SUCCESS',
	FETCH_ACCESS_RIGHTS_FAILURE: '@ACCESS_RIGHTS/FETCH_FAILURE',
};

/**
 * @function
 * @name fetchAccessRightsRequest
 * @description Action triggered anytime a access rights fetching call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchAccessRightsRequest = () => ({ type: ActionTypes.FETCH_ACCESS_RIGHTS_REQUEST });

/**
 * @function
 * @name fetchAccessRightsSuccess
 * @description Action triggered as a result to a successful access rights fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} accessRights  The retrieved accessRights object.
 *
 * @returns {object}
 */
const fetchAccessRightsSuccess = ({ accessRights }) => ({
	type: ActionTypes.FETCH_ACCESS_RIGHTS_SUCCESS,
	payload: { accessRights },
});

/**
 * @function
 * @name fetchAccessRightsFailure
 * @description Action triggered as a result to a failed access rights fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAccessRightsFailure = (error) => ({
	type: ActionTypes.FETCH_ACCESS_RIGHTS_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name getAccessRights
 * @description Method used to get the access rights associated with the current user.
 *
 * @author Yann Hodiesne
 *
 * @param {func}	onSuccessAction		The action to dispatch if everything goes well.
 * @param {string}	[onSuccessToast]	The text to display inside of a success toast if everything goes well.
 * @param {string}	[onSuccessRoute]	The route to redirect to if everything goes well.
 * @param {func}	[onFailureAction]	The action to dispatch if an error occurs, it can take the error as its first parameter.
 * @param {string}	[onFailureToast]	The text to display inside of an error toast if an error occurs.
 */
export const getAccessRights = (onSuccessAction, onSuccessToast, onSuccessRoute, onFailureAction, onFailureToast) => (dispatch) => {
	dispatch(fetchAccessRightsRequest());

	return AccessRightsAPI.getAccessRights()
		.then(({ accessRights }) => {
			dispatch(fetchAccessRightsSuccess({ accessRights }));

			if (onSuccessRoute) {
				// Tell the redirect helper to reset the breadcrumbs after logging in
				// Used to avoid bugs with the root breadcrumb resolver
				redirectOnLogin(onSuccessRoute, (location) => dispatch(updateBreadcrumbs(BreadcrumbsActions.RESET, location)));
			}

			if (onSuccessAction) {
				dispatch(onSuccessAction());
			}

			if (onSuccessToast) {
				toast.success(onSuccessToast);
			}
		})
		.catch((error) => {
			dispatch(fetchAccessRightsFailure(error));

			if (onFailureAction) {
				dispatch(onFailureAction(error));
			}

			if (onFailureToast) {
				toast.error(onFailureToast);
			}
		});
};
