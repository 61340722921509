import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import * as AuthenticationAPI from 'api/authenticationApi';
import * as UsersApi from 'api/usersApi';
import i18next from 'i18next';
import { showApiErrorToast } from 'lib/shared/errorsHelper';
import { redirectOnFailure, redirectOnLogin, redirectOnSuccess } from 'lib/shared/redirectionHelper';
import session from 'lib/shared/session';
import routes from 'routes';

import { getAccessRights } from './accessRights';
import { BreadcrumbsActions, updateBreadcrumbs } from './globals';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the users redux state.
 * @type {object}
 */
export const ActionTypes = {
	LOGIN_REQUEST: '@USERS/LOGIN_REQUEST',
	LOGIN_SUCCESS: '@USERS/LOGIN_SUCCESS',
	LOGIN_FAILURE: '@USERS/LOGIN_FAILURE',

	LOGIN_TOKEN_REQUEST: '@USERS/LOGIN_TOKEN_REQUEST',
	LOGIN_TOKEN_SUCCESS: '@USERS/LOGIN_TOKEN_SUCCESS',
	LOGIN_TOKEN_FAILURE: '@USERS/LOGIN_TOKEN_FAILURE',

	LOGOUT_REQUEST: '@USERS/LOGOUT_REQUEST',
	LOGOUT_SUCCESS: '@USERS/LOGOUT_SUCCESS',

	FORGOTTEN_PASSWORD_REQUEST: '@USERS/FORGOTTEN_PASSWORD_REQUEST',
	FORGOTTEN_PASSWORD_SUCCESS: '@USERS/FORGOTTEN_PASSWORD_SUCCESS',
	FORGOTTEN_PASSWORD_FAILURE: '@USERS/FORGOTTEN_PASSWORD_FAILURE',

	DEFINE_PASSWORD_REQUEST: '@USERS/DEFINE_PASSWORD_REQUEST',
	DEFINE_PASSWORD_SUCCESS: '@USERS/DEFINE_PASSWORD_SUCCESS',
	DEFINE_PASSWORD_FAILURE: '@USERS/DEFINE_PASSWORD_FAILURE',

	// Fetch a specific user
	FETCH_USER_REQUEST: '@USERS/FETCH_REQUEST',
	FETCH_USER_SUCCESS: '@USERS/FETCH_SUCCESS',
	FETCH_USER_FAILURE: '@USERS/FETCH_FAILURE',

	// Fetch all for users
	FETCH_ALL_FOR_USER_REQUEST: '@USERS/FETCH_ALL_FOR_REQUEST',
	FETCH_ALL_FOR_USER_SUCCESS: '@USERS/FETCH_ALL_FOR_SUCCESS',
	FETCH_ALL_FOR_USER_FAILURE: '@USERS/FETCH_ALL_FOR_FAILURE',

	// Fetch a list of users
	FETCH_USER_LIST_REQUEST: '@USERS/FETCH_LIST_REQUEST',
	FETCH_USER_LIST_SUCCESS: '@USERS/FETCH_LIST_SUCCESS',
	FETCH_USER_LIST_FAILURE: '@USERS/FETCH_LIST_FAILURE',

	// Add a new user to the database
	ADD_USER_REQUEST: '@USERS/ADD_REQUEST',
	ADD_USER_SUCCESS: '@USERS/ADD_SUCCESS',
	ADD_USER_FAILURE: '@USERS/ADD_FAILURE',

	// Update an existing user from the database
	UPDATE_USER_REQUEST: '@USERS/UPDATE_REQUEST',
	UPDATE_USER_SUCCESS: '@USERS/UPDATE_SUCCESS',
	UPDATE_USER_FAILURE: '@USERS/UPDATE_FAILURE',

	// Remove an existing user from the database
	REMOVE_USER_REQUEST: '@USERS/REMOVE_REQUEST',
	REMOVE_USER_SUCCESS: '@USERS/REMOVE_SUCCESS',
	REMOVE_USER_FAILURE: '@USERS/REMOVE_FAILURE',

	// Archive an existing user from the database
	ARCHIVE_USER_REQUEST: '@USERS/ARCHIVE_REQUEST',
	ARCHIVE_USER_SUCCESS: '@USERS/ARCHIVE_SUCCESS',
	ARCHIVE_USER_FAILURE: '@USERS/ARCHIVE_FAILURE',

	// Restore an existing user from the database
	RESTORE_USER_REQUEST: '@USERS/RESTORE_REQUEST',
	RESTORE_USER_SUCCESS: '@USERS/RESTORE_SUCCESS',
	RESTORE_USER_FAILURE: '@USERS/RESTORE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// /////////////////// User login actions ///////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name loginRequest
 * @description Action triggered anytime a login call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const loginRequest = () => ({ type: ActionTypes.LOGIN_REQUEST });

/**
 * @function
 * @name loginSuccess
 * @description Action triggered as a result to a successful login API call.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {string} token	The authentication token generated by the server.
 * @param {object} user		The retrieved user object.
 *
 * @returns {object}
 */
const loginSuccess = ({ token, user }) => ({
	type: ActionTypes.LOGIN_SUCCESS,
	payload: { token, user },
});

/**
  * @function
  * @name loginFailure
  * @description Action triggered as a result to a failed login API call.
  *
  * @author Timothée Simon-Franza
  *
  * @param {object} error	The exception sent back from the API.
  *
  * @returns {object}
  */
const loginFailure = (error) => ({
	type: ActionTypes.LOGIN_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////// User login with token actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name loginWithTokenRequest
 * @description Action triggered anytime a login call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const loginWithTokenRequest = () => ({ type: ActionTypes.LOGIN_TOKEN_REQUEST });

/**
 * @function
 * @name loginWithTokenSuccess
 * @description Action triggered as a result to a successful login API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} user	The retrieved user object.
 *
 * @returns {object}
 */
const loginWithTokenSuccess = ({ user }) => ({
	type: ActionTypes.LOGIN_TOKEN_SUCCESS,
	payload: { user },
});

/**
 * @function
 * @name loginWithTokenFailure
 * @description Action triggered as a result to a failed login API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const loginWithTokenFailure = (error) => ({
	type: ActionTypes.LOGIN_TOKEN_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////////////// User logout actions ///////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name logoutRequest
 * @description Action triggered anytime a logout call is made.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const logoutRequest = () => ({ type: ActionTypes.LOGOUT_REQUEST });

/**
 * @function
 * @name logoutSuccess
 * @description Action triggered as a result to a successful logout.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const logoutSuccess = () => ({ type: ActionTypes.LOGOUT_SUCCESS });

// //////////////////////////////////////////////////////// //
// /////////////// Forgotten password actions ///////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name forgottenPasswordRequest
 * @description Action triggered anytime a forgotten password call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const forgottenPasswordRequest = () => ({ type: ActionTypes.FORGOTTEN_PASSWORD_REQUEST });

/**
 * @function
 * @name forgottenPasswordSuccess
 * @description Action triggered as a result to a successful forgotten password API call.
 *
 * @author Yann Hodiesne
 *
 * @param {string} email	The email associated to the user account.
 *
 * @returns {object}
 */
const forgottenPasswordSuccess = ({ email }) => ({
	type: ActionTypes.FORGOTTEN_PASSWORD_SUCCESS,
	payload: { email },
});

/**
 * @function
 * @name forgottenPasswordFailure
 * @description Action triggered as a result to a failed login API call.
 *
 * @author Yann Hodiesne
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const forgottenPasswordFailure = (error) => ({
	type: ActionTypes.FORGOTTEN_PASSWORD_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////// User password definition actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name definePasswordRequest
 * @description Action triggered anytime a password definition call is made.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const definePasswordRequest = () => ({ type: ActionTypes.DEFINE_PASSWORD_REQUEST });

/**
 * @function
 * @name definePasswordSuccess
 * @description Action triggered as a result to a successful password definition API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} user	The user for which we defined the password.
 *
 * @returns {object}
 */
const definePasswordSuccess = ({ user }) => ({
	type: ActionTypes.DEFINE_PASSWORD_SUCCESS,
	payload: { user },
});

/**
 * @function
 * @name definePasswordFailure
 * @description Action triggered as a result to a failed password definition API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const definePasswordFailure = (error) => ({
	type: ActionTypes.DEFINE_PASSWORD_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////// User list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchUserListRequest
 * @description Action triggered anytime a user list fetching call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const fetchUserListRequest = () => ({ type: ActionTypes.FETCH_USER_LIST_REQUEST });

/**
 * @function
 * @name fetchUserListSuccess
 * @description Action triggered as a result to a successful user list fetching API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} users   		The list of retrieved users.
 * @param {number} totalCount	The total amount of users available in the database.
 *
 * @returns {object}
 */
const fetchUserListSuccess = ({ users, totalCount }) => ({
	type: ActionTypes.FETCH_USER_LIST_SUCCESS,
	payload: { users, totalCount },
});

/**
 * @function
 * @name fetchUserListFailure
 * @description Action triggered as a result to a failed user list fetching API call.
 *
 * @author Roland Margelidon
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchUserListFailure = (error) => ({
	type: ActionTypes.FETCH_USER_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////// Fetching all for users actions /////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAllForUserRequest
 * @description Action triggered anytime a user fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchAllForUserRequest = () => ({ type: ActionTypes.FETCH_ALL_FOR_USER_REQUEST });

/**
 * @function
 * @name fetchAllForUserSuccess
 * @description Action triggered as a result to a successful user fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} allForForm The retrieved user.
 *
 * @returns {object}
 */
const fetchAllForUserSuccess = ({ allForForm }) => ({
	type: ActionTypes.FETCH_ALL_FOR_USER_SUCCESS,
	payload: { allForForm },
});

/**
 * @function
 * @name fetchAllForUserFailure
 * @description Action triggered as a result to a failed user fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAllForUserFailure = (error) => ({
	type: ActionTypes.FETCH_ALL_FOR_USER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific user fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchUserRequest
 * @description Action triggered anytime a user fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchUserRequest = () => ({ type: ActionTypes.FETCH_USER_REQUEST });

/**
 * @function
 * @name fetchUserSuccess
 * @description Action triggered as a result to a successful user fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} user  The retrieved user.
 *
 * @returns {object}
 */
const fetchUserSuccess = ({ user }) => ({
	type: ActionTypes.FETCH_USER_SUCCESS,
	payload: { user },
});

/**
 * @function
 * @name fetchUserFailure
 * @description Action triggered as a result to a failed user fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchUserFailure = (error) => ({
	type: ActionTypes.FETCH_USER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// User creation actions //////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addUserRequest
 * @description Action triggered anytime a user creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addUserRequest = () => ({ type: ActionTypes.ADD_USER_REQUEST });

/**
 * @function
 * @name addUserSuccess
 * @description Action triggered as a result to a successful user creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} user  The created user object.
 *
 * @returns {object}
 */
const addUserSuccess = ({ user }) => ({
	type: ActionTypes.ADD_USER_SUCCESS,
	payload: { user },
});

/**
 * @function
 * @name addUserFailure
 * @description Action triggered as a result to a failed user creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const addUserFailure = (error) => ({
	type: ActionTypes.ADD_USER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ////////////////// User update actions ///////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateUserRequest
 * @description Action triggered anytime a user update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateUserRequest = () => ({ type: ActionTypes.UPDATE_USER_REQUEST });

/**
 * @function
 * @name updateUserSuccess
 * @description Action triggered as a result to a successful user update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} user	The updated user object.
 *
 * @returns {object}
 */
const updateUserSuccess = ({ user }) => ({
	type: ActionTypes.UPDATE_USER_SUCCESS,
	payload: { user },
});

/**
 * @function
 * @name updateUserFailure
 * @description Action triggered as a result to a failed user update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateUserFailure = (error) => ({
	type: ActionTypes.UPDATE_USER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// User removal actions ///////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeUserRequest
 * @description Action triggered anytime a user deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeUserRequest = () => ({ type: ActionTypes.REMOVE_USER_REQUEST });

/**
 * @function
 * @name removeUserSuccess
 * @description Action triggered as a result to a successful user deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {string} deletedUserId	The removed user id.
 *
 * @returns {object}
 */
const removeUserSuccess = ({ deletedUserId }) => ({
	type: ActionTypes.REMOVE_USER_SUCCESS,
	payload: { deletedUserId },
});

/**
 * @function
 * @name removeUserFailure
 * @description Action triggered as a result to a failed user deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeUserFailure = (error) => ({
	type: ActionTypes.REMOVE_USER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// User archiving actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveUserRequest
 * @description Action triggered anytime an user archiving call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveUserRequest = () => ({ type: ActionTypes.ARCHIVE_USER_REQUEST });

/**
 * @function
 * @name archiveUserSuccess
 * @description	Action triggered as a result to a successful user archiving API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveUserSuccess = () => ({
	type: ActionTypes.ARCHIVE_USER_SUCCESS,
});

/**
 * @function
 * @name archiveUserFailure
 * @description Action triggered as a result to a failed user archiving API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveUserFailure = (error) => ({
	type: ActionTypes.ARCHIVE_USER_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// ///////////////// User restoring actions ///////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreUserRequest
 * @description Action triggered anytime an user restoring call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreUserRequest = () => ({ type: ActionTypes.RESTORE_USER_REQUEST });

/**
 * @function
 * @name restoreUserSuccess
 * @description	Action triggered as a result to a successful user restoring API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreUserSuccess = () => ({
	type: ActionTypes.RESTORE_USER_SUCCESS,
});

/**
 * @function
 * @name restoreUserFailure
 * @description Action triggered as a result to a failed user restoring API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreUserFailure = (error) => ({
	type: ActionTypes.RESTORE_USER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////////// Helper functions ////////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name identifySentryUser
 * @description Informs Sentry about the currently logged in user.
 *
 * @author Yann Hodiesne
 *
 * @param {object} user	The currently logged in user, as retrieved from the API.
 */
const identifySentryUser = (user) => {
	Sentry.setUser({
		id: user.id,
		username: user.username,
		email: user.contact?.emails?.[0] ?? undefined,
	});

	Sentry.setTag('superadmin', user.superadmin);

	if (user.superadmin) {
		return;
	}

	Sentry.setContext('company', {
		id: user.company?.id,
		name: user.company?.name,
		currency: user.company?.mainPartner?.currency?.name,
	});
};

/**
 * @function
 * @name clearSentryUser
 * @description Informs Sentry the current user has logged out.
 *
 * @author Yann Hodiesne
 */
const clearSentryUser = () => {
	Sentry.setUser(null);
	Sentry.setTag('superadmin', null);
	Sentry.setContext('company', null);
};

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name login
 * @description Method used to login the user and retrieve its data along with an authentication token.
 *
 * @author Timothée Simon-Franza
 *
 * If the logged in user is not a superadmin, it will trigger an access rights fetch
 * @see {@link getAccessRights} for further information.
 *
 * If the api call is successful, the token is stored into the localstorage.
 * If the access rights API call is unsuccessful, the current user session is destroyed.
 *
 * @param {object} credentials			The credentials to use to log into the application.
 * @param {string} credentials.username	The username to send to the api call.
 * @param {string} credentials.password	The password to send to the api call.
 *
 * @returns {Promise}
 */
export const login = ({ username, password }) => (dispatch) => {
	dispatch(loginRequest());

	return AuthenticationAPI.tryLogin(username, password)
		.then(({ token, user }) => {
			identifySentryUser(user);
			session.set(token);

			// If the user is a superadmin, we cannot fetch its accessRights
			if (user.superadmin) {
				// Tell the redirect helper to reset the breadcrumbs after logging in
				// Used to avoid bugs with the root breadcrumb resolver
				redirectOnLogin(routes.superAdmin.default, (location) => dispatch(updateBreadcrumbs(BreadcrumbsActions.RESET, location)));

				dispatch(loginSuccess({ token, user }));
				toast.success(i18next.t('login.toasts.success', { username: user.username }));

				return;
			}

			dispatch(getAccessRights(
				// Success action
				() => loginSuccess({ token, user }),
				// Success toast
				i18next.t('login.toasts.success', { username: user.username }),
				// Success redirection
				routes.app.default,
				// Failure action
				loginFailure,
				// Failure toast
				i18next.t('login.toasts.internal_error')
			));
		})
		.catch((error) => {
			dispatch(loginFailure(error));
			toast.error(i18next.t('login.toasts.error'));
		});
};

/**
 * @function
 * @name loginWithToken
 * @description Method used to login the user using an already known token and retrieve its data.
 *
 * @author Yann Hodiesne
 *
 * If the logged in user is not a superadmin, it will trigger an access rights fetch
 * @see {@link getAccessRights} for further information.
 *
 * @returns {Promise}
 */
export const loginWithToken = () => (dispatch) => {
	dispatch(loginWithTokenRequest());

	return AuthenticationAPI.checkToken()
		.then((user) => {
			identifySentryUser(user);

			// If the user is a superadmin, we cannot fetch its accessRights
			if (user.superadmin) {
				dispatch(loginWithTokenSuccess({ user }));

				return Promise.resolve();
			}

			return dispatch(getAccessRights(
				// Success action
				() => loginWithTokenSuccess({ user })
			));
		})
		.catch((error) => {
			dispatch(loginWithTokenFailure(error));

			if (session.exists()) {
				session.remove();
				toast.error(i18next.t('logout.toasts.success'));
				redirectOnFailure(routes.auth.login);
			}
		});
};

/**
 * @function
 * @name logout
 * @description Method used to logout the user from the application.
 *
 * @author Yann Hodiesne
 * @author Timothée Simon-Franza
 *
 * @param {string} [onSuccessRoute = null]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const logout = (onSuccessRoute = null) => (dispatch) => {
	dispatch(logoutRequest());
	session.remove();
	clearSentryUser();
	dispatch(logoutSuccess());
	toast.info(i18next.t('logout.toasts.success'));
	redirectOnSuccess(onSuccessRoute);
};

/**
 * @function
 * @name forgotPassword
 * @description Method used to send a forgotten password request.
 *
 * @author Romaric Barthe
 * @author Yann Hodiesne
 * @author Timothée Simon-Franza
 *
 * @param {string} recoveryData			The email address and company root to send to the api call.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const forgotPassword = (recoveryData, onSuccessRoute = null) => (dispatch) => {
	dispatch(forgottenPasswordRequest());

	return AuthenticationAPI.forgottenPassword(recoveryData)
		.then(() => {
			dispatch(forgottenPasswordSuccess(recoveryData));
			toast.success(i18next.t('forgot_password.toasts.success', recoveryData));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch((error) => {
			toast.error(i18next.t('forgot_password.toasts.error'));
			dispatch(forgottenPasswordFailure(error));
		});
};

/**
 * @function
 * @name definePassword
 * @description Method used to set a password to the user identified by the token parameter.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {string} passwordToken	An identification token linked to a user from the database.
 * @param {string} password			The password to set to the user.
 * @param {string} checkPassword	A check value for the password field.
 *
 * @returns {Promise}
 */
export const definePassword = ({ passwordToken, password, checkPassword }) => (dispatch) => {
	dispatch(definePasswordRequest());

	return AuthenticationAPI.definePassword(passwordToken, password, checkPassword)
		.then(({ token, user }) => {
			identifySentryUser(user);
			dispatch(definePasswordSuccess({ user }));
			toast.info(i18next.t('define_password.toasts.success', { username: user.username }));

			session.set(token);

			// If the user is a superadmin, we cannot fetch its accessRights
			if (user.superadmin) {
				// Tell the redirect helper to reset the breadcrumbs after logging in
				// Used to avoid bugs with the root breadcrumb resolver
				redirectOnLogin(routes.superAdmin.default, (location) => dispatch(updateBreadcrumbs(BreadcrumbsActions.RESET, location)));

				return;
			}

			dispatch(getAccessRights(
				// Success action
				null,
				// Success toast
				null,
				// Success redirection
				routes.app.default,
				// Failure action
				null,
				// Failure toast
				i18next.t('login.toasts.internal_error')
			));
		})
		.catch((error) => {
			dispatch(definePasswordFailure(error));

			if (error.status === 409) {
				showApiErrorToast(error, 'define_password.toasts.error');
			} else {
				toast.error(i18next.t('define_password.toasts.error'));
			}
		});
};

/**
 * @function
 * @name fetchUserList
 * @description Method used to update the user list.
 *
 * @author Roland Margelidon
 *
 * @param {Object} params	The sorting and filter criterias to provide the API call.
 *
 * @returns {Promise}
 */
export const fetchUserList = (params) => (dispatch) => {
	dispatch(fetchUserListRequest());

	return UsersApi.fetchUsers(params)
		.then(({ users, totalCount }) => dispatch(fetchUserListSuccess({ users, totalCount })))
		.catch((error) => dispatch(fetchUserListFailure(error)));
};

/**
 * @function
 * @name fetchUser
 * @description Method used to fetch the latest version of a specific user.
 *
 * @author Romaric Barthe
 *
 * @param {string} userId	The id of the user we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchUser = (userId) => (dispatch) => {
	dispatch(fetchUserRequest());

	return UsersApi.fetchUserById(userId)
		.then(({ user }) => dispatch(fetchUserSuccess({ user })))
		.catch((error) => dispatch(fetchUserFailure(error)));
};

/**
 * @function
 * @name fetchAllForUser
 * @description Method used to load the contact "all for" information.
 *
 * @author Romaric Barthe
 *
 * @param {string|null} id	The id of the user.
 */
export const fetchAllForUser = (id) => (dispatch) => {
	dispatch(fetchAllForUserRequest());

	return UsersApi.fetchAllForUserForm({ userId: id })
		.then((allForForm) => dispatch(fetchAllForUserSuccess({ allForForm })))
		.catch((error) => dispatch(fetchAllForUserFailure(error)));
};

/**
 * @function
 * @name addUser
 * @description Method used to add a new user instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} userData 			The data to create the new user from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const addUser = (userData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addUserRequest());

	return UsersApi.createUser(userData)
		.then(({ user }) => {
			toast.success(i18next.t('user.creation.toasts.success', { username: user.username, email: user.contact.emails[0] }));
			dispatch(addUserSuccess({ user }));
			redirectOnSuccess(onSuccessRoute);

			return user;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('user.creation.toasts.conflict'));
				dispatch(addUserFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('user.creation.toasts.error'));
				dispatch(addUserFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateUser
 * @description Method used to update an existing user instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} userData  			The object to update the user with.
 * @param {string} userId    			The id of the user we want to update.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const updateUser = (userData, userId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateUserRequest());

	return UsersApi.updateUser(userData, userId)
		.then(({ user }) => {
			dispatch(updateUserSuccess({ user }));
			toast.success(i18next.t('user.edition.toasts.success', { username: user.username }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('user.edition.toasts.conflict'));
				dispatch(updateUserFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('user.edition.toasts.error'));
				dispatch(updateUserFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeUser
 * @description Method used to remove an existing user instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} user				The user we want to remove from the database.
 * @param {string} user.id			The id of the user we want to remove from the database.
 * @param {string} user.username	The username of the user we want to remove from the database.
 *
 * @returns {Promise}
 */
export const removeUser = ({ id, username }) => (dispatch) => {
	dispatch(removeUserRequest());

	return UsersApi.deleteUser(id)
		.then(({ deletedUserId }) => {
			dispatch(removeUserSuccess({ deletedUserId }));
			toast.success(i18next.t('user.deletion.toasts.success', { username }));
		})
		.catch((error) => {
			dispatch(removeUserFailure(error));
			toast.error(i18next.t('user.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveUser
 * @description	Method used to archive an existing user instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} user				The user we want to archive.
 * @param {string} user.id			The id of the user we want to archive.
 * @param {string} user.username	The username of the user we want to archive.
 *
 * @returns {Promise}
 */
export const archiveUser = ({ id, username }) => (dispatch) => {
	dispatch(archiveUserRequest());

	return UsersApi.updateUser({ archived: true }, id)
		.then(() => {
			dispatch(archiveUserSuccess());
			toast.success(i18next.t('user.archiving.toasts.success', { username }));
		})
		.catch((error) => {
			dispatch(archiveUserFailure(error));
			toast.error(i18next.t('user.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreUser
 * @description	Method used to restore an archived user instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} user				The user we want to restore.
 * @param {string} user.id			The id of the user we want to restore.
 * @param {string} user.username	The username of the user we want to restore.
 *
 * @returns {Promise}
 */
export const restoreUser = ({ id, username }) => (dispatch) => {
	dispatch(restoreUserRequest());

	return UsersApi.updateUser({ archived: false }, id)
		.then(() => {
			dispatch(restoreUserSuccess());
			toast.success(i18next.t('user.restoring.toasts.success', { username }));
		})
		.catch((error) => {
			dispatch(restoreUserFailure(error));
			toast.error(i18next.t('user.restoring.toasts.error'));
		});
};
