import { TEXT } from '../../../../constants/ElementTypes';
import ElementType from '../ElementType';

/**
 * @name TextTypes
 * @description Displays a list of element types inside the "text fields" tab.
 *
 * @author Florian Fornazaric
 */
const TextTypes = () => (
	TEXT.map((item) => (
		<ElementType key={item.type} elementType={item} />
	))
);

export default TextTypes;
