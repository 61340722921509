import { combineReducers } from 'redux';
import { ActionTypes as UserActionTypes } from 'redux/actions/users';

import contacts from './contacts/contacts';
import contactsList from './contacts/contactsList';
import contactsProfileDisplay from './contacts/contactsProfileDisplay';
import contactsSelect from './contacts/contactsSelect';
import contactsSelectWithMailsUser from './contacts/contactsSelectWithMailsUser';
import expenses from './expenses/expenses';
import expensesList from './expenses/expensesList';
import expensesSelect from './expenses/expensesSelect';
import histories from './histories/histories';
import invoices from './invoices/invoices';
import invoicesList from './invoices/invoicesList';
import invoicesSelect from './invoices/invoicesSelect';
import offers from './offers/offers';
import offersList from './offers/offersList';
import offersSelect from './offers/offersSelect';
import partners from './partners/partners';
import partnersList from './partners/partnersList';
import partnersListWithTargets from './partners/partnersListWithTargets';
import partnersSelect from './partners/partnersSelect';
import partnersSelectWithSectors from './partners/partnersSelectWithSectors';
import projects from './projects/projects';
import projectsList from './projects/projectsList';
import projectsSelect from './projects/projectsSelect';
import quotations from './quotations/quotations';
import quotationsList from './quotations/quotationsList';
import quotationsSelect from './quotations/quotationsSelect';
import sectors from './sectors/sectors';
import sectorsList from './sectors/sectorsList';
import sectorsSelect from './sectors/sectorsSelect';
import targetings from './targetings/targetings';
import targetingsList from './targetings/targetingsList';
import targetingsSelect from './targetings/targetingsSelect';
import targetPartners from './targetPartners/targetPartners';
import targetPartnersList from './targetPartners/targetPartnersList';
import targetPartnersSelect from './targetPartners/targetPartnersSelect';
import targets from './targets/targets';
import targetsList from './targets/targetsList';
import targetsSelect from './targets/targetsSelect';
import accessDefinitions from './accessDefinitions';
import accessRights from './accessRights';
import accounts from './accounts';
import applications from './applications';
import companies from './companies';
import currencies from './currencies';
import dashboard from './dashboard';
import globals from './globals';
import mediaManager from './mediaManager';
import numberFormats from './numberFormats';
import paymentDelays from './paymentDelays';
import registrations from './registrations';
import registrationTypes from './registrationTypes';
import relationTypes from './relationTypes';
import sectorTypes from './sectorTypes';
import statuses from './statuses';
import statusObjects from './statusObjects';
import tags from './tags';
import templateExports from './templateExports';
import templates from './templates';
import textObjects from './textObjects';
import userPreferences from './userPreferences';
import users from './users';
import vatRates from './vatRates';

const reducers = combineReducers({
	accessDefinitions,
	accessRights,
	accounts,
	applications,
	companies,
	contacts,
	contactsList,
	contactsProfileDisplay,
	contactsSelect,
	contactsSelectWithMailsUser,
	currencies,
	dashboard,
	expenses,
	expensesList,
	expensesSelect,
	histories,
	invoices,
	invoicesSelect,
	invoicesList,
	quotationsList,
	mediaManager,
	globals,
	numberFormats,
	offers,
	offersList,
	offersSelect,
	partners,
	partnersList,
	partnersListWithTargets,
	partnersSelect,
	partnersSelectWithSectors,
	paymentDelays,
	projects,
	projectsList,
	projectsSelect,
	quotations,
	quotationsSelect,
	registrations,
	registrationTypes,
	relationTypes,
	sectors,
	sectorsList,
	sectorsSelect,
	sectorTypes,
	statuses,
	statusObjects,
	tags,
	targets,
	targetsList,
	targetsSelect,
	targetings,
	targetingsList,
	targetingsSelect,
	targetPartners,
	targetPartnersList,
	targetPartnersSelect,
	templates,
	templateExports,
	textObjects,
	users,
	userPreferences,
	vatRates,
});

/**
 * @name rootReducer
 * @description The main redux reducer of the application. Resets to the initial state when receiving a logout request action.
 *
 * @author Timothée Simon-Franza
 */
const rootReducer = (state, action) => {
	if (action.type === UserActionTypes.LOGOUT_REQUEST) {
		return reducers(undefined, action);
	}

	return reducers(state, action);
};

export {
	reducers,
	rootReducer,
};
