import { useTranslation } from 'react-i18next';

/**
 * @name FormatHeader
 * @description A component to edit the format of a template's header
 *
 * @author Florian Fornazaric
 */
const FormatHeader = ({ ...otherProps }) => {
	const { t } = useTranslation();

	return (
		<div className="header" {...otherProps}>
			<div className="title">
				{t('template.pdf.format.header')}
			</div>
		</div>
	);
};

export default FormatHeader;
