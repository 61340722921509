import { useCallback } from 'react';
import { Archive, Edit2, Eye, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { archiveUser, removeUser, restoreUser } from 'redux/actions/users';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the UserListPage table. (archive, delete and edit user)
 *
 * @author Romaric Barthe
 *
 * @param {object}		row						The object containing the data to interact with.
 * @param {boolean}		row.original.enabled	The enabled status of the user.
 * @param {string}		row.original.id			The id of the user.
 * @param {string}		row.original.username	The name of the user.
 * @param {function}	fetchData				The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { enabled, id, username } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingArchiveModal, toggle: toggleArchiveModal } = useModal();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditUser = useAccessRight(AccessRights.humanResources.users.enhancedRights.CREATE_USER);
	const canDeleteUser = useAccessRight(AccessRights.humanResources.users.enhancedRights.DELETE_USER);

	const currentUser = useCurrentConnectedUserSelector();
	const isNotCurrent = id !== currentUser?.id;

	const [archiveButtonRef, archiveTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onArchiveModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the archive button.
	 *
	 * @author Romaric Barthe
	 */
	const onArchiveModalConfirmationButtonClick = useCallback(async () => {
		toggleArchiveModal();

		if (enabled) {
			await dispatch(archiveUser({ id, username }));
		} else {
			await dispatch(restoreUser({ id, username }));
		}

		fetchData();
	}, [toggleArchiveModal, enabled, fetchData, dispatch, id, username]);

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Romaric Barthe
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeUser({ id, username }));
		fetchData();
	}, [toggleDeleteModal, dispatch, id, username, fetchData]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onEditPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.humanResources.users.userEdition, { id }));
	}, [id]);

	return (
		<>
			<Button className="icon-only" onClick={onEditPageRedirectionButtonClick} ref={editButtonRef}>
				{canEditUser ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditUser ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			{canEditUser && isNotCurrent && (
				<>
					<Button className="icon-only" onClick={toggleArchiveModal} ref={archiveButtonRef}>
						<Archive />
					</Button>
					<Tooltip {...archiveTooltipProps}>
						{t('actions.archived')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingArchiveModal}
						message={!enabled
							? t('user.restoring.confirmation_modal.content')
							: t('user.archiving.confirmation_modal.content')}
						confirm={onArchiveModalConfirmationButtonClick}
						cancel={toggleArchiveModal}
						confirmText={!enabled
							? t('user.restoring.confirmation_modal.confirm')
							: t('user.archiving.confirmation_modal.confirm')}
						cancelText={!enabled
							? t('user.restoring.confirmation_modal.cancel')
							: t('user.archiving.confirmation_modal.cancel')}
						titleText={!enabled
							? t('user.restoring.confirmation_modal.title')
							: t('user.archiving.confirmation_modal.title')}
					/>
				</>
			)}
			{canDeleteUser && isNotCurrent && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('user.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('user.deletion.confirmation_modal.confirm')}
						cancelText={t('user.deletion.confirmation_modal.cancel')}
						titleText={t('user.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			enabled: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
			username: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
