import { memo, useCallback, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isDev } from 'lib/shared/environmentHelper';
import PropTypes from 'prop-types';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import optimizationChecker from './debug/optimizationChecker';
import stateChecker from './debug/stateChecker';
import { padNumber } from './functions/internals';
import ensureStateConsistency from './functions/internals/ensureStateConsistency';
import reducer from './reducer/reducer';
import { EditorContent, EditorHeaderViewer } from './components';
import EditorContext from './EditorContext';
import { getTitleNumbers } from './functions';

import 'styles/components/_templateEditor.scss';

/**
 * @name EditorViewer
 * @description A multi-directional drag and drop document editor
 *
 * @author Romaric Barthe
 *
 * @param {object}		initialData						The data to display inside the template
 * @param {boolean}		isCompanyTemplate				Set to true if the template belongs to the current company
 * @param {boolean}		isSuperadmin					Set to true if the current user is a superadmin
 * @param {function}	saveAsCallback					Function to call when the template has to be duplicated
 * @param {object}		templateMetadata				The template's metadata
 * @param {string}		templateMetadata.description	Template's description
 * @param {string}		templateMetadata.type			Template's type
 * @param {string}		templateMetadata.name			Template's name
 * @param {string}		templateMetadata.id				Template's id
 */
const EditorViewer = ({
	initialData,
	isCompanyTemplate,
	isSuperadmin,
	saveAsCallback,
	templateMetadata,
}) => {
	const { t } = useTranslation();
	const initialState = useMemo(() => {
		const state = ensureStateConsistency(initialData);

		return ({
			...state,
			templateDescription: templateMetadata.description,
			templateName: templateMetadata.name,
			isExporting: true,
		});
	}, [initialData, templateMetadata.description, templateMetadata.name]);

	const [{
		elements,
		entities,
		header,
		footer,
		margins,
		isExporting,
		pages,
		pageNumberingStartIndex,
		selectedElements,
		templateDescription,
		templateName,
	}, dispatch] = useReducer(
		isDev() ? stateChecker(optimizationChecker(reducer)) : reducer,
		initialState
	);

	const saveDuplicate = useCallback(async () => {
		if (templateName === undefined || templateName === '') {
			toast.error(t('template.view.toasts.missingname'));

			return;
		}

		/* Creation of the timestamp YYYYMMDDHHMMSS to be added at the end of the duplicated template */
		const dateTimestamp = new Date();
		const dateString = ` ${dateTimestamp.getFullYear().toString()}${padNumber(dateTimestamp.getMonth() + 1, 2)}${padNumber(dateTimestamp.getDate(), 2)}`
			+ `${padNumber(dateTimestamp.getHours(), 2)}${padNumber(dateTimestamp.getMinutes(), 2)}${padNumber(dateTimestamp.getSeconds(), 2)}`;

		/* If the template was already duplicated (hence, having a timestamp), the former timestamp
		 * is cut and replaced by a new one. If it's a first duplicate, name and timestamp are combined */
		let duplicatedTemplateName = templateName + dateString;
		if (templateName.match(/\d{4}(1[0-2]|0[1-9])(3[01]|[12][0-9]|0[1-9])(2[0-4]|1[0-9]|0[1-9])([0-5][0-9])([0-5][0-9])$/)) {
			duplicatedTemplateName = templateName.substring(0, templateName.length - 15) + dateString;
		}

		const templateData = {
			description: templateDescription,
			name: duplicatedTemplateName,
			type: templateMetadata.type,
			content: {
				elements,
				entities,
				pages,
				header,
				footer,
				margins,
				pageNumberingStartIndex,
			},
		};

		const newTemplate = await saveAsCallback(templateData);

		if (newTemplate) {
			if (!isSuperadmin) {
				history.push(generatePath(routes.settings.templates.templatePdfEdition, { id: newTemplate.id }));
			} else {
				history.push(generatePath(routes.superAdmin.templatePdfEdition, { id: newTemplate.id }));
			}
		}
	}, [elements, entities, footer, header, isSuperadmin, margins, pageNumberingStartIndex, pages, saveAsCallback, t, templateDescription, templateMetadata.type, templateName]);

	const editTemplate = useCallback(() => {
		if (!isSuperadmin) {
			history.push(generatePath(routes.settings.templates.templatePdfEdition, { id: templateMetadata.id }));
		} else {
			history.push(generatePath(routes.superAdmin.templatePdfEdition, { id: templateMetadata.id }));
		}
	}, [isSuperadmin, templateMetadata.id]);

	const titleNumbers = useMemo(() => getTitleNumbers(elements), [elements]);

	const editorContext = useMemo(() => ({
		dispatch,
		editTemplate,
		elements,
		entities,
		isCompanyTemplate,
		saveDuplicate,
		pages,
		selectedElements,
		isExporting,
		templateDescription,
		pageNumberingStartIndex,
		templateId: templateMetadata.id,
		templateName,
		templateType: templateMetadata.type,
		header,
		footer,
		margins,
		titleNumbers,
		isSuperadmin,
	}), [editTemplate, elements, entities, isCompanyTemplate, saveDuplicate, pages, selectedElements, isExporting, pageNumberingStartIndex,
		templateDescription, templateMetadata.id, templateMetadata.type, templateName, header, footer, margins, titleNumbers, isSuperadmin]);

	return (
		<EditorContext.Provider value={editorContext}>
			<div className="template-editor">
				<EditorContent />
				<aside>
					<EditorHeaderViewer />
				</aside>
			</div>
		</EditorContext.Provider>
	);
};

EditorViewer.propTypes = {
	initialData: PropTypes.object,
	isCompanyTemplate: PropTypes.bool.isRequired,
	isSuperadmin: PropTypes.bool.isRequired,
	saveAsCallback: PropTypes.func.isRequired,
	templateMetadata: PropTypes.shape({
		description: PropTypes.string,
		name: PropTypes.string.isRequired,
		type: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
	}).isRequired,
};

EditorViewer.defaultProps = {
	initialData: {},
};

export default memo(EditorViewer);
