import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { expenseColumnHeader } from 'constants/expenseEnums';
import PropTypes from 'prop-types';

import DynamicFormContext from '../shared/forms/internals/DynamicFormContext';

import ExpenseGridRow from './ExpenseGridRow';

/**
 * @name ExpenseGrid
 * @description A form used to edit an existing expense's information.
 *
 * @author Romaric Barthe
 *
 * @param {array}	accounts				The list of accounts.
 * @param {array}	columnsHeadersToDisplay	The array of all possible header.
 * @param {array}	currencies				The list of currencies.
 * @param {string}	currency				The currency selected in the header.
 * @param {string}	currencyName			The ISO name of the currency in the expense.
 * @param {string}	currencySymbol			The symbol of the currency used in the expense.
 * @param {object}	defaultValues			The default values of the expense.
 * @param {bool}	isLoading				Whether the list are still loading.
 * @param {array}	projects				The list of projects.
 * @param {bool}	readOnly				Whether the expense is read-only.
 * @param {object}	rows					The rows of the expense.
 * @param {array}	vatRates				The list of vatRates.
 * @param {func}	updateRow				The method to add/ move/ delete/ duplicate a line.
 * @param {func}	setTotals				The method to set the total of the expenses.
 * @param {func}	duplicateRowId			If set, the id of the row that is duplicated.
 * @param {func}	originalRowData			If set, the data of the row that is duplicated.
 */
const ExpenseGrid = ({
	accounts,
	columnsHeadersToDisplay,
	currencies,
	currencyName,
	currencySymbol,
	defaultValues,
	isLoading,
	projects,
	readOnly,
	rows,
	vatRates,
	// functions
	updateRow,
	setTotals,
	// duplicate logic
	duplicateRowId,
	originalRowData,
}) => {
	const { t } = useTranslation();

	const rowItems = useMemo(() => Math.max(...rows.map((row) => row?.line)), [rows]);

	const { disabled: formDisabled } = useContext(DynamicFormContext);

	return (
		<table className="grid">
			<thead>
				<tr className="single">
					{columnsHeadersToDisplay.filter((elem) => elem.show).map((header) => {
						switch (header.label) {
							case expenseColumnHeader.ACTIONS:
								return (
									readOnly ? null : (
										<th key={header.label}>
											{t(`expense.headers.${header.label}`)}
										</th>
									)
								);
							case expenseColumnHeader.LOCAL_NET_COST:
							case expenseColumnHeader.NET_COST:
							case expenseColumnHeader.TOTAL:
								return (
									<th key={header.label} className="cell-right">
										{t(`expense.headers.${header.label}`)}
									</th>
								);
							default:
								return (
									<th key={header.label} className="cell-left">
										{t(`expense.headers.${header.label}`)}
									</th>
								);
						}
					})}
				</tr>
			</thead>

			<tbody>
				{rows.map((row) => (
					<ExpenseGridRow
						accounts={accounts}
						columnsHeadersToDisplay={columnsHeadersToDisplay}
						currencies={currencies}
						currencyName={currencyName}
						currencySymbol={currencySymbol}
						defaultValues={defaultValues}
						formDisabled={formDisabled}
						isLoading={isLoading}
						key={`tr.${row.id}`}
						projects={projects}
						readOnly={readOnly}
						row={row}
						rowItems={rowItems}
						vatRates={vatRates}
						// functions
						updateRow={updateRow}
						setTotals={setTotals}
						// duplicate logic
						originalRowData={row.id === duplicateRowId ? originalRowData : undefined}
					/>
				))}
			</tbody>
		</table>
	);
};

ExpenseGrid.propTypes = {
	accounts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	currencies: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	columnsHeadersToDisplay: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			show: PropTypes.bool,
		}),
	).isRequired,
	currencyName: PropTypes.string,
	currencySymbol: PropTypes.string,
	defaultValues: PropTypes.object,
	isLoading: PropTypes.bool,
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	readOnly: PropTypes.bool,
	rows: PropTypes.arrayOf(
		PropTypes.shape({
			optionAccount: PropTypes.bool,
			optionDate: PropTypes.bool,
			optionLocalCurrency: PropTypes.bool,
			optionProject: PropTypes.bool,
		}),
	).isRequired,
	vatRates: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			rate: PropTypes.number.isRequired,
		}),
	).isRequired,
	// functions
	updateRow: PropTypes.func.isRequired,
	setTotals: PropTypes.func.isRequired,
	// duplicate logic
	duplicateRowId: PropTypes.string,
	originalRowData: PropTypes.object,
};

ExpenseGrid.defaultProps = {
	currencyName: '',
	currencySymbol: '',
	defaultValues: undefined,
	isLoading: true,
	readOnly: false,
	duplicateRowId: undefined,
	originalRowData: undefined,
};

export default ExpenseGrid;
