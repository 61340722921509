import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useTargetingsListLoadingSelector
 * @description A selector to retrieve the loading state from the targetingsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useTargetingsListLoadingSelector = () => {
	const loading = useSelector((state) => state.targetingsList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useTargetingsForListSelector
 * @description A selector to retrieve the targetingsList list from the targeting redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTargetingsForListSelector = () => {
	const targetingsList = useSelector((state) => (state.targetingsList?.items ?? []), shallowEqual);

	return targetingsList;
};

/**
 * @function
 * @name useTargetingsListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the targetingsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useTargetingsListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.targetingsList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useTargetingsForListSelector,
	useTargetingsListLoadingSelector,
	useTargetingsListTotalCountSelector,
};
