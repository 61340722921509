import { PageOrientations } from '../constants/PagesOrientations';

export const ActionTypes = Object.freeze({
	ADD_ENTITY: 'ADD_ENTITY',
	ADD_TO_SELECTION: 'ADD_TO_SELECTION',
	APPEND_ELEMENT: 'APPEND_ELEMENT',
	APPEND_PAGE: 'APPEND_PAGE',
	APPEND_ROW: 'APPEND_ROW',
	CLEAR_SELECTION: 'CLEAR_SELECTION',
	DECREMENT_ELEMENTS_IN_HEADER: 'DECREMENT_ELEMENTS_IN_HEADER',
	DUPLICATE_ELEMENT: 'DUPLICATE_ELEMENT',
	DUPLICATE_ELEMENTS: 'DUPLICATE_ELEMENTS',
	INCREMENT_ELEMENTS_IN_HEADER: 'INCREMENT_ELEMENTS_IN_HEADER',
	INSERT_ELEMENT: 'INSERT_ELEMENT',
	INSERT_ROW: 'INSERT_ROW',
	LINK_ENTITY: 'LINK_ENTITY',
	MOVE_ELEMENT: 'MOVE_ELEMENT',
	MOVE_ELEMENT_BY_ID: 'MOVE_ELEMENT_BY_ID',
	MOVE_ROW: 'MOVE_ROW',
	MOVE_ROW_BY_ID: 'MOVE_ROW_BY_ID',
	REMOVE_ELEMENTS: 'REMOVE_ELEMENTS',
	REMOVE_ENTITY: 'REMOVE_ENTITY',
	REMOVE_FROM_SELECTION: 'REMOVE_FROM_SELECTION',
	REMOVE_PAGE: 'REMOVE_PAGE',
	REMOVE_ROW: 'REMOVE_ROW',
	RESET_DIRTY_FLAG: 'RESET_DIRTY_FLAG',
	RESET_MOVING_ELEMENT: 'RESET_MOVING_ELEMENT',
	SET_MOVING_ELEMENT: 'SET_MOVING_ELEMENT',
	SET_SELECTION: 'SET_SELECTION',
	UPDATE_DESCRIPTION: 'UPDATE_DESCRIPTION',
	UPDATE_EDITION_DATE: 'UPDATE_EDITION_DATE',
	UPDATE_ELEMENT: 'UPDATE_ELEMENT',
	UPDATE_ENTITY: 'UPDATE_ENTITY',
	UPDATE_HEADER: 'UPDATE_HEADER',
	UPDATE_NAME: 'UPDATE_NAME',
	UPDATE_PAGE: 'UPDATE_PAGE',
	UPDATE_FOOTER: 'UPDATE_FOOTER',
	INCREMENT_ELEMENTS_IN_FOOTER: 'INCREMENT_ELEMENTS_IN_FOOTER',
	DECREMENT_ELEMENTS_IN_FOOTER: 'DECREMENT_ELEMENTS_IN_FOOTER',
	UPDATE_MARGINS: 'UPDATE_MARGINS',
	UPDATE_ROW: 'UPDATE_ROW',
	UPDATE_PAGE_NUMBERING_START_INDEX: 'UPDATE_PAGE_NUMBERING_START_INDEX',
});

/**
 * @function
 * @name addEntity
 * @description Action to dispatch to add a new empty entity
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
export const addEntity = () => ({
	type: ActionTypes.ADD_ENTITY,
	payload: {},
});

/**
 * @function
 * @name addToSelection
 * @description Action to dispatch to add an element identified by the provided id to the current selection
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string}	id	The identifier of the element to add to the selection
 *
 * @returns {object}
 */
export const addToSelection = (id) => ({
	type: ActionTypes.ADD_TO_SELECTION,
	payload: {
		id,
	},
});

/**
 * @function
 * @name appendElement
 * @description Action to dispatch to append an element inside a new row
 *
 * @author Yann Hodiesne
 *
 * @param {object}	elementType		The element type used to populate the new row
 *
 * @returns {object}
 */
export const appendElement = (elementType) => ({
	type: ActionTypes.APPEND_ELEMENT,
	payload: {
		elementType,
	},
});

/**
 * @function
 * @name appendPage
 * @description Action to dispatch to append a new page
 *
 * @author Florian Fornazaric
 *
 * @param {string}	orientation		The orientation of the page to add (portrait, landscape)
 *
 * @returns {object}
 */
export const appendPage = (orientation = PageOrientations.PORTRAIT) => ({
	type: ActionTypes.APPEND_PAGE,
	payload: {
		orientation,
	},
});

/**
 * @function
 * @name appendRow
 * @description Action to dispatch to append an empty row at the end of the template
 *
 * @author Yann Hodiesne
 *
 * @param {array}	elements	The elements used to populate the new row
 *
 * @returns {object}
 */
export const appendRow = (...elements) => ({
	type: ActionTypes.APPEND_ROW,
	payload: {
		elements,
	},
});

/**
 * @function
 * @name clearSelection
 * @description Action to dispatch to clear the current selection
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
export const clearSelection = () => ({
	type: ActionTypes.CLEAR_SELECTION,
	payload: {},
});

/**
 * @function
 * @name decrementElementsInHeader
 * @description Action to dispatch to decrement the number of elements in the header
 *
 * @author Florian Fornazaric
 */
export const decrementElementsInHeader = () => ({
	type: ActionTypes.DECREMENT_ELEMENTS_IN_HEADER,
});

/**
 * @function
 * @name duplicateElements
 * @description Action to duplicate elements
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string[]}	ids		Array of identifiers of items we want to duplicate
 *
 * @returns {object}
 */
export const duplicateElements = (ids) => ({
	type: ActionTypes.DUPLICATE_ELEMENTS,
	payload: {
		ids,
	},
});

/**
 * @function
 * @name incrementElementsInHeader
 * @description Action to dispatch to increment the number of elements in the header
 *
 * @author Florian Fornazaric
 */
export const incrementElementsInHeader = () => ({
	type: ActionTypes.INCREMENT_ELEMENTS_IN_HEADER,
});

/**
 * @function
 * @name insertElement
 * @description Action to dispatch to insert a new element at the given index inside the given row
 *
 * @author Yann Hodiesne
 *
 * @param {object}		element		The element type to insert
 * @param {number}		rowIndex	The index of the row we want to insert the element into
 * @param {number}		index		The index where to insert the new element
 * @param {boolean}		isInHeader	Whether the element is in the header or not
 * @param {boolean}		fromFooter	Whether the element is added from the footer or not (used so the element doesn't get added to the footer randomly)
 *
 * @returns {object}
 */
export const insertElement = (element, rowIndex, index = 0, isInHeader = false, fromFooter = true) => ({
	type: ActionTypes.INSERT_ELEMENT,
	payload: {
		element,
		rowIndex,
		index,
		isInHeader,
		fromFooter,
	},
});

/**
 * @function
 * @name insertRow
 * @description Action to dispatch to insert a new row at the given index
 *
 * @author Yann Hodiesne
 *
 * @param {number}	index		The index where to insert the new row
 * @param {array}	elements	The elements to insert inside the new row
 *
 * @returns {object}
 */
export const insertRow = (index, ...elements) => ({
	type: ActionTypes.INSERT_ROW,
	payload: {
		index,
		elements,
	},
});

/**
 * @function
 * @name linkEntity
 * @description Action to dispatch to link a template entity to the provided application entity, populating the template content with the entity's data
 *
 * Note: this action must be used in export mode.
 *
 * @author Yann Hodiesne
 *
 * @param {string} id				The identifier of the template entity to link
 * @param {object} linkedEntity		The application entity to link to
 * @param {string} linkedEntity.id	The identifier of the linkedEntity to link to
 *
 * @returns {object}
 */
export const linkEntity = (id, linkedEntity) => ({
	type: ActionTypes.LINK_ENTITY,
	payload: {
		id,
		linkedEntity,
	},
});

/**
 * @function
 * @name moveElement
 * @description Action to dispatch to move an element from the given source index to the given destination index
 *
 * @author Yann Hodiesne
 *
 * @param {number}	sourceRowIndex		The source row index
 * @param {number}	sourceIndex			The index of the source element inside of the provided source row
 * @param {number}	destinationRowIndex	The destination row index
 * @param {number}	destinationIndex	The destination index inside of the provided destination row
 *
 * @returns {object}
 */
export const moveElement = (sourceRowIndex, sourceIndex, destinationRowIndex, destinationIndex) => ({
	type: ActionTypes.MOVE_ELEMENT,
	payload: {
		sourceRowIndex,
		sourceIndex,
		destinationRowIndex,
		destinationIndex,
	},
});

/**
 * @function
 * @name moveElementById
 * @description Action to dispatch to move an element from the given source indices to the given destination indices
 *
 * @author Yann Hodiesne
 *
 * @param {string}	sourceId			The identifier of the element to move
 * @param {number}	destinationRowIndex	The destination row index
 * @param {number}	destinationIndex	The destination index inside of the provided destination row
 *
 * @returns {object}
 */
export const moveElementById = (sourceId, destinationRowIndex, destinationIndex) => ({
	type: ActionTypes.MOVE_ELEMENT_BY_ID,
	payload: {
		sourceId,
		destinationRowIndex,
		destinationIndex,
	},
});

/**
 * @function
 * @name moveRow
 * @description Action to dispatch to move a row from the provided source index to the provided destination index
 *
 * @author Yann Hodiesne
 *
 * @param {number} sourceIndex		The source index
 * @param {number} destinationIndex	The destination index
 *
 * @returns {object}
 */
export const moveRow = (sourceIndex, destinationIndex) => ({
	type: ActionTypes.MOVE_ROW,
	payload: {
		sourceIndex,
		destinationIndex,
	},
});

/**
 * @function
 * @name moveRowById
 * @description Action to dispatch to move a row to the provided destination index
 *
 * @author Yann Hodiesne
 *
 * @param {number} sourceId			The identifier of the row to move
 * @param {number} destinationIndex	The destination index
 *
 * @returns {object}
 */
export const moveRowById = (sourceId, destinationIndex) => ({
	type: ActionTypes.MOVE_ROW_BY_ID,
	payload: {
		sourceId,
		destinationIndex,
	},
});

/**
 * @function
 * @name removeElements
 * @description Action to dispatch to remove multiple elements from the editor.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string[]}	ids		An array of ids of elements to remove.
 *
 * @returns {object}
 */
export const removeElements = (ids) => ({
	type: ActionTypes.REMOVE_ELEMENTS,
	payload: {
		ids,
	},
});

/**
 * @function
 * @name removeEntity
 * @description Action to dispatch to remove an existing entity
 *
 * @author Yann Hodiesne
 *
 * @param {string}	id		The identifier of the entity to remove
 *
 * @returns {object}
 */
export const removeEntity = (id) => ({
	type: ActionTypes.REMOVE_ENTITY,
	payload: {
		id,
	},
});

/**
 * @function
 * @name removeFromSelection
 * @description Action to dispatch to remove an element identified by the provided id from the current selection
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string}	id	The identifier of the element to unselect
 *
 * @returns {object}
 */
export const removeFromSelection = (id) => ({
	type: ActionTypes.REMOVE_FROM_SELECTION,
	payload: {
		id,
	},
});

/**
 * @function
 * @name removePage
 * @description Action to dispatch to remove a page at the given index
 *
 * @author Florian Fornazaric
 *
 * @param {number}	index	The index of the page to remove
 *
 * @returns {object}
 */
export const removePage = (index) => ({
	type: ActionTypes.REMOVE_PAGE,
	payload: {
		index,
	},
});

/**
 * @function
 * @name removeRow
 * @description Action to dispatch to remove the row at the given index
 *
 * @author Yann Hodiesne
 *
 * @param {number}	index	The index of the row to remove
 *
 * @returns {object}
 */
export const removeRow = (index) => ({
	type: ActionTypes.REMOVE_ROW,
	payload: {
		index,
	},
});

/**
 * @function
 * @name resetDirtyFlag
 * @description Action to dispatch to reset the dirty flag
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
export const resetDirtyFlag = () => ({
	type: ActionTypes.RESET_DIRTY_FLAG,
});

/**
 * @function
 * @name resetMovingElement
 * @description Action to dispatch to reset the moving element
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
export const resetMovingElement = () => ({
	type: ActionTypes.RESET_MOVING_ELEMENT,
});

/**
 * @function
 * @name setMovingElement
 * @description Action to dispatch to set the moving element
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
export const setMovingElement = (id) => ({
	type: ActionTypes.SET_MOVING_ELEMENT,
	payload: {
		id,
	},
});

/**
 * @function
 * @name setSelection
 * @description Action to dispatch to set the current selection
 *
 * @author Yann Hodiesne
 * @author Matthieu Schaerlinger
 *
 * @param {string[]}	ids		The identifiers of the elements to select (if empty, the selection is cleared)
 *
 * @returns {object}
 */
export const setSelection = (ids) => ({
	type: ActionTypes.SET_SELECTION,
	payload: {
		ids,
	},
});

/**
 * @function
 * @name updateDescription
 * @description Action to dispatch to update the template's description
 *
 * @author Florian Fornazaric
 *
 * @param {string}	description		The new description
 *
 * @returns {object}
 */
export const updateDescription = (description) => ({
	type: ActionTypes.UPDATE_DESCRIPTION,
	payload: {
		description,
	},
});

/**
 * @function
 * @name updateEditionDate
 * @description Action to update the template's edition date
 *
 * @author Romaric Barthe
 *
 * @param {string}	editionDate	The new edition date of the link entity
 *
 * @returns {object}
 */
export const updateEditionDate = (editionDate) => ({
	type: ActionTypes.UPDATE_EDITION_DATE,
	payload: {
		editionDate,
	},
});

/**
 * @function
 * @name updateElement
 * @description Action to dispatch to update an element identified by the provided id
 *
 * @author Yann Hodiesne
 *
 * @param {string}	id			The identifier of the element to update
 * @param {object}	content		The new content to store inside the element
 *
 * @returns {object}
 */
export const updateElement = (id, content) => ({
	type: ActionTypes.UPDATE_ELEMENT,
	payload: {
		id,
		content,
	},
});

/**
 * @function
 * @name updateEntity
 * @description Action to dispatch to update an entity identified by the provided id
 *
 * @author Yann Hodiesne
 *
 * @param {string}	id			The identifier of the entity to update
 * @param {object}	content		The new content to store inside the entity
 *
 * @returns {object}
 */
export const updateEntity = (id, content) => ({
	type: ActionTypes.UPDATE_ENTITY,
	payload: {
		id,
		content,
	},
});

/**
 * @function
 * @name updateHeader
 * @description Action to dispatch to update a header
 *
 * @author Florian Fornazaric
 *
 * @param {object}	content		The new content to store inside the header
 */
export const updateHeader = (content) => ({
	type: ActionTypes.UPDATE_HEADER,
	payload: {
		content,
	},
});

/**
 * @function
 * @name updateName
 * @description Action to update the template's name
 *
 * @author Florian Fornazaric
 *
 * @param {string}	name	The new name
 *
 * @returns {object}
 */
export const updateName = (name) => ({
	type: ActionTypes.UPDATE_NAME,
	payload: {
		name,
	},
});

/**
 * @function
 * @name updatePage
 * @description Action to dispatch to update a page at the given index
 *
 * @author Florian Fornazaric
 *
 * @param {number}	index		The index of the page to update
 * @param {object}	content		The new content to store inside the page
 *
 * @returns {object}
 */
export const updatePage = (index, content) => ({
	type: ActionTypes.UPDATE_PAGE,
	payload: {
		index,
		content,
	},
});

/**
 * @function
 * @name updateRow
 * @description Action to dispatch to update a row at the given index
 *
 * @author Florian Fornazaric
 *
 * @param {number}	index		The index of the row to update
 * @param {object}	content		The new content to store inside the row
 *
 * @returns {object}
 */
export const updateRow = (index, content) => ({
	type: ActionTypes.UPDATE_ROW,
	payload: {
		index,
		content,
	},
});

/**
 * @function
 * @name updateFooter
 * @description Action to dispatch to update a footer
 *
 * @author Florian Fornazaric
 *
 * @param {object}
 */
export const updateFooter = (content) => ({
	type: ActionTypes.UPDATE_FOOTER,
	payload: {
		content,
	},
});

/**
 * @function
 * @name incrementElementsInFooter
 * @description Action to dispatch to increment the number of elements in the footer
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
export const incrementElementsInFooter = () => ({
	type: ActionTypes.INCREMENT_ELEMENTS_IN_FOOTER,
});

/**
 * @function
 * @name decrementElementsInFooter
 * @description Action to dispatch to decrement the number of elements in the footer
 *
 * @author Florian Fornazaric
 *
 * @returns {object}
 */
export const decrementElementsInFooter = () => ({
	type: ActionTypes.DECREMENT_ELEMENTS_IN_FOOTER,
});

/**
 * @function
 * @name updateMargins
 * @description Action to dispatch to update the margins of the template
 *
 * @author Florian Fornazaric
 *
 * @param {object} content	The new content to store inside the margins
 *
 * @returns {object}
 */
export const updateMargins = (content) => ({
	type: ActionTypes.UPDATE_MARGINS,
	payload: {
		content,
	},
});

/**
 * @function
 * @name updatePageNumberingStartIndex
 * @description Action to dispatch to update the page numbering start index
 *
 * @author Florian Fornazaric
 *
 * @param {number} index	The new page numbering start index
 *
 * @returns {object}
 */
export const updatePageNumberingStartIndex = (index) => ({
	type: ActionTypes.UPDATE_PAGE_NUMBERING_START_INDEX,
	payload: {
		index,
	},
});
