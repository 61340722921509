import PropTypes from 'prop-types';

/**
 * @name Error
 * @description A component used to displayed the validation error of an input.
 *
 * @author Timothée Simon-Franza
 *
 * @param {node} children	The error message to display.
 */
const Error = ({ children }) => (
	<span className="error-message">{children}</span>
);

Error.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.node,
	]).isRequired,
};

export default Error;
