import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

/**
 * @name ModalFooter
 * @description A footer to contain the buttons of the modal
 *
 * @author Audrey Clerc
 *
 * @param {node}	children		The content to place inside the modal footer.
*/
const ModalFooter = ({ children }) => (
	<div className="styled-modal-footer">
		{
			Children.map(children, (child) => cloneElement(child, { ...child.props }))
		}
	</div>
);

ModalFooter.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ModalFooter;
