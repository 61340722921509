import _ from 'lodash';

import { ActionTypes } from '../actions/accessRights';

const initialState = {
	items: undefined,
	isLoading: false,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_ACCESS_RIGHTS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_ACCESS_RIGHTS_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_ACCESS_RIGHTS_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: _.keyBy(payload.accessRights, 'name'),
			};
		default:
			return state;
	}
};
