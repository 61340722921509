import { forwardRef } from 'react';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

/**
 * @name TextInput
 * @description A custom text input component
 *
 * @author Yann Hodiesne
 *
 * @param {bool} disabled Whether the input is disabled.
 */
const TextInput = forwardRef(
	({ disabled, ...otherProps }, ref) => (
		<input ref={useForwardedRef(ref)} type="text" disabled={disabled} {...otherProps} />
	)
);

TextInput.displayName = 'TextInput';

TextInput.propTypes = {
	disabled: PropTypes.bool,
};

TextInput.defaultProps = {
	disabled: false,
};

export default TextInput;
