import { get, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchUserPreferences
 * @description Retrieves a list of all preferences of the current user session from the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {Promise}
 */
export const fetchUserPreferences = () => get('/api/user-preferences');

/**
 * @function
 * @name updateUserPreference
 * @description Updates the current user's existing preference with the given name.
 *
 * @author Yann Hodiesne
 *
 * @param {string}  listName     The list to update user preferences for.
 * @param {array}   columns      The new user preferences to give to the API.
 *
 * @returns {Promise}
 */
export const updateUserPreference = (listName, columns) => put('/api/user-preferences', { listName, columns });
