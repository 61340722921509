import update from 'immutability-helper';

/**
 * @function
 * @name removeRow
 * @description Removes an existing row at the given index
 *
 * @author Yann Hodiesne
 *
 * @param {object} state			The current state
 * @param {number} payload.index	The index of the row to remove
 *
 * @returns {object} The updated state value
 */
const removeRow = (state, { index }) => {
	const { elements } = state;

	const result = {
		...state,
		elements: update(elements, {
			$splice: [
				[index, 1],
			],
		}),
		isDirty: true,
	};

	return result;
};

export default removeRow;
