import * as SectorTypesApi from 'api/sectorTypesApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the SectorTypes redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a list of sector types
	FETCH_SECTOR_TYPE_LIST_REQUEST: '@SECTOR_TYPES/FETCH_LIST_REQUEST',
	FETCH_SECTOR_TYPE_LIST_SUCCESS: '@SECTOR_TYPES/FETCH_LIST_SUCCESS',
	FETCH_SECTOR_TYPE_LIST_FAILURE: '@SECTOR_TYPES/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// //////////// SectorType list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchSectorTypeListRequest
 * @description Action triggered anytime a sector type list call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchSectorTypeListRequest = () => ({ type: ActionTypes.FETCH_SECTOR_TYPE_LIST_REQUEST });

/**
 * @function
 * @name fetchSectorTypeListSuccess
 * @description Action triggered as a result to a successful sector type list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} sectorTypes	 The list of retrieved sector types.
 * @param {number} totalCount	 The total amount of sector types available in the database to the current user.
 *
 * @returns {object}
 */
const fetchSectorTypeListSuccess = ({ sectorTypes, totalCount }) => ({
	type: ActionTypes.FETCH_SECTOR_TYPE_LIST_SUCCESS,
	payload: { sectorTypes, totalCount },
});

/**
 * @function
 * @name fetchSectorTypeListFailure
 * @description Action triggered as a result to a failed sector type list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchSectorTypeListFailure = (error) => ({
	type: ActionTypes.FETCH_SECTOR_TYPE_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////////// Exported action creatiors ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchSectorTypeList
 * @description Method used to update the sector type list.
 *
 * @author Audrey Clerc
 */
export const fetchSectorTypeList = () => (dispatch) => {
	dispatch(fetchSectorTypeListRequest());

	return SectorTypesApi.fetchSectorTypes()
		.then(({ sectorTypes, totalCount }) => {
			dispatch(fetchSectorTypeListSuccess({ sectorTypes, totalCount }));
		})
		.catch((error) => dispatch(fetchSectorTypeListFailure(error)));
};
