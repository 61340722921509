import { useMemo } from 'react';
import { useOnClickOutside } from 'lib/hooks';
import PropTypes from 'prop-types';

/**
 * @name Dropdown
 * @description Dropdown shown when right clicking or opening a menu
 *
 * @author Yann Hodiesne
 *
 * @param {bool}	isOpen			Boolean telling if the dropdown is shown to the user
 * @param {func}	handleClose		Callback called to close the dropdown
 * @param {number}	[anchorPoint.x]	Where the dropdown is anchored to the closest relative parent on the X axis
 * @param {number}	[anchorPoint.y]	Where the dropdown is anchored to the closest relative parent on the Y axis
 */
const Dropdown = ({ anchorPoint, isOpen, handleClose, children, additionalStyle }) => {
	const contextRef = useOnClickOutside(handleClose);

	const style = useMemo(() => {
		if (anchorPoint) {
			return {
				top: anchorPoint.y,
				left: anchorPoint.x,
				right: 'initial',
			};
		}

		return {};
	}, [anchorPoint]);

	return (
		<div ref={contextRef} className={`dropdown${isOpen ? ' open' : ''}`} style={{ ...style, ...additionalStyle }}>
			{children}
		</div>
	);
};

Dropdown.propTypes = {
	anchorPoint: PropTypes.shape({
		x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
		y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	}),
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node).isRequired,
		PropTypes.node.isRequired,
	]).isRequired,
	additionalStyle: PropTypes.object,
};

Dropdown.defaultProps = {
	anchorPoint: undefined,
	additionalStyle: {},
};

export default Dropdown;
