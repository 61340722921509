import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchTemplates
 * @description Retrieves a list of templates from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchTemplates = (params) => post('/api/templates', params);

/**
 * @function
 * @name fetchTemplateById
 * @description Retrieves an existing template from the API, using the templateId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} templateId      The id of the template we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTemplateById = (templateId) => get(`/api/template/${templateId}`);

/**
 * @function
 * @name createTemplate
 * @description Creates a new template into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} templateData    The data to create the template from.
 *
 * @returns {Promise}
 */
export const createTemplate = (templateData) => post('/api/templates/create', templateData);

/**
 * @function
 * @name updateTemplate
 * @description Updates an existing template from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} templateData    The data to update the template with.
 * @param {string} templateId      The id to identify the template with.
 *
 * @returns {Promise}
 */
export const updateTemplate = (templateData, templateId) => put(`/api/template/${templateId}`, templateData);

/**
 * @function
 * @name deleteTemplate
 * @description Removes an existing template from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} templateId      The id of the template we want to remove.
 *
 * @returns {Promise}
 */
export const deleteTemplate = (templateId) => del(`/api/template/${templateId}`);
