import { useCallback, useMemo } from 'react';
import { Edit2, Eye, Mail, Phone, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { removePartner } from 'redux/actions/partners/partners';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the PartnerListPage table. (mail, phone, delete and edit partner)
 *
 * @author Timothée Simon-Franza
 * @author Matthieu Schaerlinger
 * @author Audrey Clerc
 *
 * @param {object}		row						The object containing the data to interact with.
 * @param {string}		row.original.id			The id of the partner.
 * @param {string[]}	row.original.emails		The emails of the partner.
 * @param {string}		row.original.name		The name of the partner.
 * @param {object[]}	row.original.phones		The phone numbers of the partner.
 * @param {function}	fetchData				The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { id, emails, name, phones } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const [emailButtonRef, emailTooltipProps] = useTooltip();
	const [phoneButtonRef, phoneTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();

	const canEditPartner = useAccessRight(AccessRights.commonModules.partners.enhancedRights.CREATE_PARTNER);
	const canDeletePartner = useAccessRight(AccessRights.commonModules.partners.enhancedRights.DELETE_PARTNER);

	const phoneNumber = useMemo(() => {
		const filteredPhones = (phones ?? []).filter(({ type }) => type === 'phone');

		return filteredPhones.length === 0 ? undefined : filteredPhones[0].number;
	}, [phones]);

	/**
	 * @function
	 * @name onEmailButtonClick
	 * @description Method triggered as a result to an onClick event from the email button.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onEmailButtonClick = useCallback(() => {
		window.open(`mailto:${emails[0]}`, '_blank');
	}, [emails]);

	/**
	 * @function
	 * @name onPhoneButtonClick
	 * @description Method triggered as a result to an onClick event from the phone button.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onPhoneButtonClick = useCallback(() => {
		window.open(`tel:${phoneNumber}`, '_self');
	}, [phoneNumber]);

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Audrey Clerc
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removePartner({ id, name }));
		fetchData();
	}, [dispatch, fetchData, id, name, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Audrey Clerc
	 */
	const onEditPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.commonModules.partners.partnerEdition, { id }));
	}, [id]);

	return (
		<>
			<Button ref={editButtonRef} className="icon-only" onClick={onEditPageRedirectionButtonClick}>
				{canEditPartner ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditPartner ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			<Button ref={emailButtonRef} className="icon-only" onClick={onEmailButtonClick} disabled={emails.length === 0}>
				<Mail />
			</Button>
			<Tooltip {...emailTooltipProps} disabled={emails.length === 0}>
				{t('actions.mail')}
			</Tooltip>
			<Button ref={phoneButtonRef} className="icon-only" onClick={onPhoneButtonClick} disabled={phoneNumber === undefined}>
				<Phone />
			</Button>
			<Tooltip {...phoneTooltipProps} disabled={phoneNumber === undefined}>
				{t('actions.phone')}
			</Tooltip>
			{canDeletePartner && (
				<>
					<Button ref={deleteButtonRef} className="icon-only" onClick={toggleDeleteModal}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('partner.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('partner.deletion.confirmation_modal.confirm')}
						cancelText={t('partner.deletion.confirmation_modal.cancel')}
						titleText={t('partner.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
			emails: PropTypes.arrayOf(PropTypes.string),
			name: PropTypes.string.isRequired,
			phones: PropTypes.arrayOf(PropTypes.shape({
				number: PropTypes.string.isRequired,
				type: PropTypes.string.isRequired,
			})),
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
