import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchTextObjectList } from 'redux/actions/textObjects';
import { useTextObjectListSelector, useTextObjectTotalCountSelector } from 'redux/selectors/textObjects';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import { ActionsCell } from 'components/textObjects/textObjectList';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name TextObjectListPage
 * @description A list of all text objects accessible from the settings app in sub part text objects.
 *
 * From this page, the user is able to view, interact and search for text objects.
 *
 * @author Romaric Barthe
 */
const TextObjectListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const textObjects = useTextObjectListSelector();
	const totalCount = useTextObjectTotalCountSelector();

	const canCreateTextObject = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);

	const headers = useMemo(() => [
		{ id: 'name', accessor: 'name', Header: t('text_object.fields.name'), pinColumn: true, sorting: 'textObject.name' },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('text_object.headers.actions') },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('text_object.fields.archived.yes') : t('text_object.fields.archived.no')),
			Header: t('text_object.fields.archived.header'),
			sorting: 'textObject.archived',
		},
		{ id: 'text', accessor: 'text', Header: t('text_object.fields.text'), sorting: 'textObject.text' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('text_object.fields.creation_date'), sorting: 'textObject.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('text_object.fields.update_date'), sorting: 'textObject.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('text_object.fields.creation_name'), sorting: 'textObject.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('text_object.fields.update_name'), sorting: 'textObject.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchTextObjectList(params)));
	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('textObject');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.settings.textObjects.textObjectCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateTextObject && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('text_object.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={textObjects}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="textObject"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('text_object.pages.list')} {...modalProps} />
		</div>
	);
};

export default TextObjectListPage;
