import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUsersLoadingSelector } from 'redux/selectors/users';
import routes from 'routes';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name SignInForm
 * @description Form used to authentify the user.
 *
 * @author Timothée Simon-Franza
 * @author Audrey Clerc
 * @author Yann Hodiesne
 *
 * @param {func} onSubmit The method to trigger whenever the form is submitted.
 */
const SignInForm = ({ onSubmit }) => {
	const { t } = useTranslation();

	const isLoading = useUsersLoadingSelector();

	const handleSubmit = useCallback((formData) => {
		if (!isLoading) {
			onSubmit(formData);
		}
	}, [isLoading, onSubmit]);

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm
			onSubmit={handleSubmit}
			blockNavigation={false}
			{...formProps}
		>
			<TextInput
				hint={t('login.inputs.username.hint')}
				label={t('login.inputs.username.label')}
				name="username"
				placeholder={t('login.inputs.username.placeholder')}
				rules={{
					required: Validators.isRequired(t('login.inputs.username.validation_errors.required')),
				}}
				type="text"
			/>
			<TextInput
				label={t('login.inputs.password.label')}
				name="password"
				rules={{
					required: Validators.isRequired(t('login.inputs.password.validation_errors.required')),
				}}
				type="password"
			/>
			<Link to={routes.auth.forgottenPassword}>{t('login.links.forgotten_password')}</Link>
			<Button className="primary" type="submit" isLoading={isLoading} {...buttonProps}>{t('login.action')}</Button>
		</DynamicForm>
	);
};

SignInForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default SignInForm;
