import PropTypes from 'prop-types';

import styles from 'styles/components/status/_statusTag.module.scss';

/**
 * @name StatusTagSkeleton
 * @description The skeleton of the StatusTag component.
 *
 * @author Timothée Simon-Franza
 */
const StatusTagSkeleton = () => (
	<div className={styles['status-tag--skeleton']}>
		<div className={styles['status-dot-color']} style={{ backgroundColor: 'initial' }} />
	</div>
);

/**
 * @name StatusTag
 * @description A component used to display a status tag.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} label					The label of the status.
 * @param {string} [dotColor = '#000000']	The color of the status.
 */
const StatusTag = ({ label, dotColor }) => (
	<div className={styles['status-tag']}>
		<div className={styles['status-dot-color']} style={{ backgroundColor: dotColor }} />
		<span>{label}</span>
	</div>
);

StatusTag.propTypes = {
	label: PropTypes.string.isRequired,
	dotColor: PropTypes.string,
};

StatusTag.defaultProps = {
	dotColor: '#000000',
};

export {
	StatusTag,
	StatusTagSkeleton,
};
