import update from 'immutability-helper';

/**
 * @function
 * @name updateName
 * @description Updates template's description with provided value
 *
 * @author Florian Fornazaric
 *
 * @param {object} state			The current state
 * @param {string} payload.name		The new template's description
 *
 * @returns {object} The updated state value
 */
const updateName = (state, { name }) => {
	const result = update(state, {
		templateName: {
			$set: name,
		},
		isDirty: {
			$set: true,
		},
	});

	return result;
};

export default updateName;
