import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentExpenseSelector
 * @description A selector to retrieve the current expense from the expense redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the current expense to wait for
 *
 * @returns {object}
 */
const useCurrentExpenseSelector = (id) => {
	const expense = useSelector((state) => (state.expenses?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (expense?.id === id ? expense : null), [id, expense]);

	return result;
};

/**
 * @function
 * @name useExpenseLoadingSelector
 * @description A selector to retrieve the loading state from the expense redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useExpenseLoadingSelector = () => {
	const loading = useSelector((state) => state.expenses?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useExpenseListSelector
 * @description A selector to retrieve the expense list from the expense redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useExpenseListSelector = () => {
	const expenses = useSelector((state) => (state.expenses?.items ?? []), shallowEqual);

	return expenses;
};

/**
 * @function
 * @name useExpenseTotalCountSelector
 * @description A selector to retrieve the totalCount value from the expense redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useExpenseTotalCountSelector = () => useSelector((state) => (state.expenses?.totalCount ?? undefined), shallowEqual);

/**
 * @function
 * @name useAllForExpenseSelector
 * @description A selector to retrieve all relevant list for expense's forms from the quotation redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAllForExpenseSelector = () => {
	const allForForm = useSelector((state) => (state.expenses?.allForForm ?? []), shallowEqual);

	return allForForm;
};

export {
	useAllForExpenseSelector,
	useCurrentExpenseSelector,
	useExpenseListSelector,
	useExpenseLoadingSelector,
	useExpenseTotalCountSelector,
};
