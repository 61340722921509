import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchTargetings
 * @description Retrieves a list of targetings from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchTargetings = (params) => post('/api/targetings', params);

/**
 * @function
 * @name fetchTargetingById
 * @description Retrieves an existing targeting from the API, using the targetingId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} targetingId      The id of the targeting we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTargetingById = (targetingId) => get(`/api/targetings/${targetingId}`);

/**
 * @function
 * @name createTargeting
 * @description Creates a new targeting into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetingData    The data to create the targeting from.
 *
 * @returns {Promise}
 */
export const createTargeting = (targetingData) => post('/api/targetings/create', targetingData);

/**
 * @function
 * @name updateTargeting
 * @description Updates an existing targeting from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetingData    The data to update the targeting with.
 * @param {string} targetingId      The id to identify the targeting with.
 *
 * @returns {Promise}
 */
export const updateTargeting = (targetingData, targetingId) => put(`/api/targetings/${targetingId}`, targetingData);

/**
 * @function
 * @name deleteTargeting
 * @description Removes an existing targeting from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} targetingId      The id of the targeting we want to remove.
 *
 * @returns {Promise}
 */
export const deleteTargeting = (targetingId) => del(`/api/targetings/${targetingId}`);

/**
 * @function
 * @name exportTargetingStats
 * @description Export targeting stats from the database.
 *
 * @author Roland Margelidon
 *
 * @returns {Promise}
 */
export const exportTargetingStats = (targetingId) => get(`/api/targetings/${targetingId}/export`, 'attachment');

/**
 * @function
 * @name fetchTargetingStats
 * @description Fetch targeting stats from the database.
 *
 * @author Roland Margelidon
 *
 * @returns {Promise}
 */
export const fetchTargetingStats = (targetingId) => get(`/api/targetings/${targetingId}/stats`);
