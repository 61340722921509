import * as TargetingsApi from 'api/targetingsApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Targetings redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of targetings
	FETCH_TARGETING_FOR_LIST_REQUEST: '@TARGETINGS_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_TARGETING_FOR_LIST_SUCCESS: '@TARGETINGS_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_TARGETING_FOR_LIST_FAILURE: '@TARGETINGS_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Targeting list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetingsForListRequest
 * @description Action triggered anytime a targetingsList list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetingsForListRequest = () => ({ type: ActionTypes.FETCH_TARGETING_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchTargetingsForListSuccess
 * @description Action triggered as a result to a successful targetingsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	targetingsList	The list of retrieved targetings.
 * @param {number}	totalCount		The total amount of targetings available in the database to the current user.
 *
 * @returns {object}
 */
const fetchTargetingsForListSuccess = ({ targetingsList, totalCount }) => ({
	type: ActionTypes.FETCH_TARGETING_FOR_LIST_SUCCESS,
	payload: { targetingsList, totalCount },
});

/**
 * @function
 * @name fetchTargetingsForListFailure
 * @description Action triggered as a result to a failed targetingsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetingsForListFailure = (error) => ({
	type: ActionTypes.FETCH_TARGETING_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetingsForList
 * @description Method used to update the targetingsList list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchTargetingsForList = (filters) => (dispatch) => {
	dispatch(fetchTargetingsForListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forList',
	};

	return TargetingsApi.fetchTargetings(newFilters)
		.then(({ targetingsList, totalCount }) => dispatch(fetchTargetingsForListSuccess({ targetingsList, totalCount })))
		.catch((error) => dispatch(fetchTargetingsForListFailure(error)));
};
