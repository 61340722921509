import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { formatAccountParent } from 'lib/accounts/formatAccountData';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchAccountList } from 'redux/actions/accounts';
import { useAccountListSelector, useAccountTotalCountSelector } from 'redux/selectors/accounts';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import ActionsCell from 'components/accounts/accountList/ActionsCell';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name AccountListPage
 * @description A list of all accounts accessible from the Invoices app in sub part accounts.
 *
 * From this page, the user is able to view, interact and search for accounts.
 *
 * @author Romaric Barthe
 */
const AccountListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const accounts = useAccountListSelector();
	const totalCount = useAccountTotalCountSelector();

	const canCreateAccount = useAccessRight(AccessRights.accounting.accounts.enhancedRights.CREATE_ACCOUNT);

	const headers = useMemo(() => [
		{ id: 'name', accessor: (row) => (`#${row.name}`), Header: t('account.fields.name'), sorting: 'account.name' },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('account.fields.archived.yes') : t('account.fields.archived.no')),
			Header: t('account.fields.archived.header'),
			sorting: 'account.archived',
		},
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('account.headers.actions') },
		{ accessor: 'description', Header: t('account.fields.description'), sorting: 'account.description' },
		{ id: 'parent', accessor: (row) => formatAccountParent(row), Header: t('account.fields.parent'), sorting: 'account.parent' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('account.fields.creation_date'), sorting: 'account.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('account.fields.update_date'), sorting: 'account.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('account.fields.creation_name'), sorting: 'account.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('account.fields.update_name'), sorting: 'account.updatedBy' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchAccountList(params)));
	const [newButtonRef, newTooltipProps] = useTooltip();
	const { tableProps, modalProps } = useTableUserPreferences('account');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.accounting.accounts.accountCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateAccount && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('account.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={accounts}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="account"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('account.pages.list')} {...modalProps} />
		</div>
	);
};

export default AccountListPage;
