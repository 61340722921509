import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { useForwardedRef } from 'lib/hooks';
import { collidingWithMask } from 'lib/shared/maskHelper';
import { spliceString } from 'lib/shared/stringHelper';
import PropTypes from 'prop-types';

import TextInput from './TextInput';

/**
 * @constant
 * @name notAlphanumericPattern
 * @description A negative alphanumeric validation pattern (to check if a string contains no alphanumeric characters)
 * @type {RegExp}
 */
const notAlphanumericPattern = /[^a-z\d]/i;

/**
 * @constant
 * @name alphanumericPattern
 * @description A alphanumeric validation pattern (to check if a string contains only alphanumeric characters)
 * @type {RegExp}
 */
const alphanumericPattern = /[a-z\d]/i;

/**
 * @name MaskedInput
 * @description A masked input component
 *
 * @author Florian Fornazaric
 *
 * @param {string}	mask			The mask of the input
 * @param {Regexp}	maskRegex		The regex used to validate against the mask
 * @param {string}	value			The value of the input
 * @param {string}	[placeholder] 	The placeholder of the input
 * @param {func}	[onBlur]		Called when the onBlur function of the input is called
 * @param {func}	[onChange]		Called when the onChange function of the input is called
 */
const MaskedInput = forwardRef(({ mask, maskRegex, placeholder, value, onBlur, onChange, ...otherProps }, ref) => {
	const inputRef = useForwardedRef(ref);

	/**
	 * Workaround for an oddity in JavaScript's RegExp implementation
	 * c.f. https://scribe.rip/codesnips/js-careful-when-reusing-regex-636b92c6bf07
	 */
	const getGlobalNotAlphanumericPattern = useCallback(() => new RegExp(notAlphanumericPattern, 'gi'), []);

	const maskSeparatorsArray = useMemo(() => mask.match(getGlobalNotAlphanumericPattern(), ''), [getGlobalNotAlphanumericPattern, mask]);

	const applyInputRegex = useCallback(() => {
		const { selectionStart, selectionEnd } = inputRef.current;

		// Allows to separate the data in an array using the regex
		const currentValue = inputRef.current.value
			.replace(getGlobalNotAlphanumericPattern(), '')
			.match(maskRegex);

		let finalValue = '';

		// We go through each element of the previous array to format the output data with the given separators
		for (let i = 1; i < currentValue.length; i++) {
			finalValue += !currentValue[i + 1] ? currentValue[i] : `${currentValue[i]}${maskSeparatorsArray[i - 1]}`;
		}

		inputRef.current.value = finalValue;

		inputRef.current.selectionStart = selectionStart;
		inputRef.current.selectionEnd = selectionEnd;
	}, [getGlobalNotAlphanumericPattern, inputRef, maskRegex, maskSeparatorsArray]);

	const lastOnChangeValue = useRef(value);

	const handleChange = useCallback(() => {
		if (lastOnChangeValue.current !== inputRef.current.value) {
			applyInputRegex();
			lastOnChangeValue.current = inputRef.current.value;
			// If the input matches the mask's length we test if the data is valid or not
			onChange?.(inputRef.current.value, inputRef.current.value.length === mask.length);
		}
	}, [applyInputRegex, inputRef, onChange, mask.length]);

	// Handles every key presses that will change the value of the masked input
	const handleKeyDown = useCallback((event) => {
		// Cursor that allows us to know where the user is/should be currently typing
		let cursorPosition = inputRef.current.selectionStart;

		// Allows us to know if the user has multiple characters selected
		const selectionEndIndex = inputRef.current.selectionEnd;
		const selectionLength = inputRef.current.selectionEnd - cursorPosition;

		// When the user presses a alphanumerical key, removing combination of keys and special keys
		if (!event.ctrlKey && event.key.length === 1 && alphanumericPattern.test(event.key)) {
			event.preventDefault();

			// If the cursor collides with the mask, we move it forward by 1
			if (collidingWithMask(mask, cursorPosition, getGlobalNotAlphanumericPattern())) {
				cursorPosition++;
			}

			// If the user is typing at the end of the TextInput we simply add the character at the end
			if (inputRef.current.value.length < mask.length && inputRef.current.value.length === cursorPosition) {
				inputRef.current.value += event.key;
			// If the user is typing in the middle of the TextInput, we replace the following character by what he typed
			} else {
				inputRef.current.value = spliceString(inputRef.current.value, cursorPosition, 1, event.key);
			}

			// We apply the regex now, so that the cursor is at the right position
			applyInputRegex();
			// When the value is changed, we move the user cursor by 1 forward
			inputRef.current.selectionStart = cursorPosition + 1;
			inputRef.current.selectionEnd = cursorPosition + 1;
		// When the user presses backspace
		} else if (event.key === 'Backspace') {
			event.preventDefault();
			// Allows us to replace the character we want to delete with the one corresponding in the mask
			if (inputRef.current.value.length > 0) {
				// if the user has a multiple characters selection or not
				if (selectionLength === 0 && cursorPosition !== 0) {
					// We move the cursor back to where we want the character to be deleted
					cursorPosition--;

					// If the cursor is colliding with the mask, we move it back one more time
					if (collidingWithMask(mask, cursorPosition, getGlobalNotAlphanumericPattern())) {
						cursorPosition--;
					}

					// Replaces the current character by an empty char if we are at the end of the input value
					// or by the masked value if we are in the middle of it
					const replacementChar = selectionEndIndex === inputRef.current.value.length ? '' : mask.charAt(cursorPosition);
					inputRef.current.value = spliceString(inputRef.current.value, cursorPosition, 1, replacementChar);

				// If the user is currently selecting multiple characters we replace the selected characters by the mask's values
				} else {
					let replacementString = '';
					if (selectionEndIndex !== inputRef.current.value.length) {
						replacementString = mask.substring(cursorPosition, selectionEndIndex);
					}
					inputRef.current.value = spliceString(inputRef.current.value, cursorPosition, selectionLength, replacementString);
				}

				// We force the cursor of the user to be moved to where the deleted characted was
				inputRef.current.selectionStart = cursorPosition;
				inputRef.current.selectionEnd = cursorPosition;
			}
		} else if (event.key === 'Delete') {
			event.preventDefault();
			// If the cursor is colliding with the mask, increase it's position by one to delete non mask character
			if (collidingWithMask(mask, cursorPosition, getGlobalNotAlphanumericPattern())) {
				cursorPosition++;
			}

			if (selectionLength === 0 && cursorPosition !== inputRef.current.value.length) {
				// Removal of one character
				inputRef.current.value = spliceString(inputRef.current.value, cursorPosition, 1, '');
			} else {
				// Removal of the entire selection
				inputRef.current.value = spliceString(inputRef.current.value, cursorPosition, selectionLength, '');
			}

			// We force the cursor of the user to be moved
			inputRef.current.selectionStart = cursorPosition;
			inputRef.current.selectionEnd = cursorPosition;
		}

		handleChange();
	}, [inputRef, handleChange, mask, getGlobalNotAlphanumericPattern, applyInputRegex]);

	const handleBlur = useCallback((e) => {
		handleChange();

		onBlur?.(e);
	}, [handleChange, onBlur]);

	const oldValue = useRef(value);

	useEffect(() => {
		if (oldValue.current !== value) {
			oldValue.current = value;
			inputRef.current.value = value;
			handleChange();
		}
	}, [inputRef, handleChange, value]);

	return (
		<TextInput
			{...otherProps}
			ref={inputRef}
			onKeyDown={handleKeyDown}
			onChange={handleChange}
			onBlur={handleBlur}
			placeholder={placeholder}
			type="text"
			defaultValue={value}
		/>
	);
});

MaskedInput.displayName = 'MaskedInput';

MaskedInput.propTypes = {
	value: PropTypes.string,
	mask: PropTypes.string.isRequired,
	maskRegex: PropTypes.instanceOf(RegExp).isRequired,
	placeholder: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
};

MaskedInput.defaultProps = {
	value: undefined,
	placeholder: '',
	onBlur: undefined,
	onChange: undefined,
};

export default MaskedInput;
