import { useCallback, useMemo } from 'react';
import { Sliders, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { removeTargetPartner } from 'redux/actions/targetPartners/targetPartners';
import { fetchTarget } from 'redux/actions/targets/targets';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the TargetPartnerListPage. (remove).
 *
 * @author Romaric Barthe
 *
 * @param {func}	fetchData					The method to call when we need to refresh the parent data list.
 * @param {object}	row							The object containing the data to interact with.
 * @param {string}	row.original.id				The id of the object to interact with.
 * @param {object}	row.original.partner		The partner's object from the object to interact with.
 * @param {object}	row.original.target			The target's object from the object to interact with.
 * @param {object}	row.original.targeting		The targeting's object from the object to interact with.
 * @param {func}	onClickCallback				The targeting's object from the object to interact with.
 */
const ActionsCell = ({ row: { original: { id, partner, target, targeting } }, fetchData, onClickCallback }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { isShowing: isShowingDeletionModal, toggle: toggleDeletionModal } = useModal();

	const canDeleteTargeting = useAccessRight(AccessRights.operations.targetings.enhancedRights.DELETE_TARGETING);
	const canEditTargeting = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING);
	const isArchived = useMemo(() => targeting.archived ?? false, [targeting]);

	const [openTabButtonRef, openTabTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onDeletionModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Romaric Barthe
	 */
	const onDeletionModalConfirmationButtonClick = useCallback(async () => {
		toggleDeletionModal();

		await dispatch(removeTargetPartner({ id, partner: partner.name, target: target.name }));
		fetchData();
		dispatch(fetchTarget(target.id));
	}, [dispatch, fetchData, id, partner, target, toggleDeletionModal]);

	return (
		<>
			{canEditTargeting && !isArchived && (
				<>
					<Button className="icon-only" onClick={() => onClickCallback(id)} ref={openTabButtonRef}>
						<Sliders className="primary" />
					</Button>
					<Tooltip {...openTabTooltipProps}>
						{t('actions.tab')}
					</Tooltip>
				</>
			)}
			{canDeleteTargeting && !isArchived && (
				<>
					<Button className="icon-only" onClick={toggleDeletionModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeletionModal}
						message={t('targeting.target.target_partner.deletion.confirmation_modal.content')}
						confirm={onDeletionModalConfirmationButtonClick}
						cancel={toggleDeletionModal}
						confirmText={t('targeting.target.target_partner.deletion.confirmation_modal.confirm')}
						cancelText={t('targeting.target.target_partner.deletion.confirmation_modal.cancel')}
						titleText={t('targeting.target.target_partner.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
			partner: PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			}).isRequired,
			target: PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			}).isRequired,
			targeting: PropTypes.shape({
				id: PropTypes.string.isRequired,
				archived: PropTypes.bool.isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
	onClickCallback: PropTypes.func.isRequired,
};

export default ActionsCell;
