import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchSectors
 * @description Retrieves a list of sectors from the API, specifying filters if present.
 *
 * @author Audrey Clerc
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchSectors = (params) => post('/api/sectors', params);

/**
 * @function
 * @name fetchSectorById
 * @description Retrieves an existing sector from the API, using the sectorId in parameters.
 *
 * @author Audrey Clerc
 *
 * @param {string} sectorId	The id of the sector we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchSectorById = (sectorId) => get(`/api/sector/${sectorId}`);

/**
 * @function
 * @name createSector
 * @description Creates a new sector into the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} sectorData	The data to create the sector from.
 *
 * @returns {Promise}
 */
export const createSector = (sectorData) => post('/api/sectors/create', sectorData);

/**
 * @function
 * @name updateSector
 * @description Updates an existing sector from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} sectorData	The data to update the sector with.
 * @param {string} sectorId		The id to identify the sector with.
 *
 * @returns {Promise}
 */
export const updateSector = (sectorData, sectorId) => put(`/api/sector/${sectorId}`, sectorData);

/**
 * @function
 * @name deleteSector
 * @description Removes an existing sector from the database.
 *
 * @author Audrey Clerc
 *
 * @param {string} sectorId	The id of the sector we want to remove.
 *
 * @returns {Promise}
 */
export const deleteSector = (sectorId) => del(`/api/sector/${sectorId}`);
