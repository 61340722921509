import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTableUserPreferences } from 'lib/hooks';
import { formatPartnerParent } from 'lib/partners/formatPartnerData';
import PropTypes from 'prop-types';
import { addPartner } from 'redux/actions/partners/partners';
import { fetchPartnersForListWithTargets } from 'redux/actions/partners/partnersListWithTargets';
import { fetchTarget } from 'redux/actions/targets/targets';
import {
	usePartnersForListWithTargetsSelector,
	usePartnersListWithTargetsLoadingSelector,
	usePartnersListWithTargetsTotalCountSelector,
} from 'redux/selectors/partners/partnersListWithTargets';

import { ContactsCell, SectorsCell } from 'components/partners/partnerList';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { PromptModal, useModal } from 'components/shared/modal';
import { ActionsCell, TargetingsCell, TargetsCell } from 'components/targetings/targetPartners/targetAddPartnerList';
import { TargetPartner } from 'components/targetings/targets/targetTabs';

import { AccessRights, useAccessRight } from '../../../lib/shared/accessRights';

/**
 *
 * @param {*} param0
 * @returns
 */
const TargetAddPartnerModalContent = ({ target, onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const partners = usePartnersForListWithTargetsSelector();
	const totalCount = usePartnersListWithTargetsTotalCountSelector();
	const isPartnerLoading = usePartnersListWithTargetsLoadingSelector();

	const defaultValues = useMemo(() => ({
		...target,
	}), [target]);

	const { isShowing: isPartnersModalShowing, toggle: togglePartnersModal } = useModal();
	const canEditTarget = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING) && !target?.targeting?.archived;

	const { tableProps, modalProps } = useTableUserPreferences('partner');
	const { formProps, buttonProps } = useSubmitButton();

	const headers = useMemo(() => [
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('partner.headers.actions') },
		{ accessor: 'name', Header: t('partner.fields.name') },
		{ id: 'targeting', Cell: TargetingsCell, Header: t('targeting.target.target_partner.fields.targeting'), sorting: 'partner.targeting.project.name' },
		{ id: 'target', Cell: TargetsCell, Header: t('targeting.target.target_partner.fields.target'), sorting: 'partner.target.name' },
		{ id: 'parent', accessor: (row) => formatPartnerParent(row), Header: t('partner.fields.parent'), sorting: 'partner.parent' },
		{ id: 'sectors', Header: t('partner.fields.sector'), Cell: SectorsCell },
		{ accessor: 'turnover', Header: t('partner.fields.turnover'), sorting: 'partner.turnover' },
		{ accessor: 'workforce', Header: t('partner.fields.workforce'), sorting: 'partner.workforce' },
		{ id: 'contacts', Header: t('partner.fields.contact'), Cell: ContactsCell },
		{ accessor: 'city', Header: t('partner.fields.city'), sorting: 'partner.city' },
		{ accessor: 'country', Header: t('partner.fields.country'), sorting: 'partner.country' },
		{ accessor: 'activityDescription', Header: t('partner.fields.activity_description'), sorting: 'partner.activityDescription' },
	], [t]);

	// calculation of change in target Partner
	const partnersAlreadySelectedId = useMemo(() => (target ? target?.targetPartners.map((targetPartner) => targetPartner.partner.id) : []), [target]);
	const [allSelectedTargetPartnersIds, setAllSelectedTargetPartnersIds] = useState(partnersAlreadySelectedId);
	const [selectedTargetPartnersToAdd, setSelectedTargetPartnersToAdd] = useState([]);
	const [selectedTargetPartnersToRemove, setSelectedTargetPartnersToRemove] = useState([]);
	const selectedTargetPartnersIds = useMemo(() => [...partnersAlreadySelectedId, ...selectedTargetPartnersToAdd].filter((id) => !selectedTargetPartnersToRemove.includes(id)),
		[partnersAlreadySelectedId, selectedTargetPartnersToAdd, selectedTargetPartnersToRemove]);
	const partnersIndices = useMemo(() => partners.map(
		(partner, index) => (selectedTargetPartnersIds?.includes(partner.id) ? index : null)
	).filter((partnerIndex) => partnerIndex !== null),
	[partners, selectedTargetPartnersIds]);

	const selectedTargetPartnersChanged = useCallback((pSelectedTargetPartners) => {
		const pSelectedTargetPartnersIds = pSelectedTargetPartners.map((partner) => partner.id); // liste des éléments cochés sur la page en question
		setAllSelectedTargetPartnersIds(pSelectedTargetPartnersIds);
	}, []);

	// pointers to prevent max depth exceeded in useEffect
	const partnersPointer = useRef(partners);
	partnersPointer.current = partners;
	const partnersAlreadySelectedIdPointer = useRef(partnersAlreadySelectedId);
	partnersAlreadySelectedIdPointer.current = partnersAlreadySelectedId;
	const selectedTargetPartnersToAddPointer = useRef(selectedTargetPartnersToAdd);
	selectedTargetPartnersToAddPointer.current = selectedTargetPartnersToAdd;
	const selectedTargetPartnersToRemovePointer = useRef(selectedTargetPartnersToRemove);
	selectedTargetPartnersToRemovePointer.current = selectedTargetPartnersToRemove;

	useEffect(() => {
		const partnersId = partnersPointer.current.map((partner) => partner.id); // liste partner du back avec page / filtre
		const partnersToAdd = allSelectedTargetPartnersIds.filter((partner) => !partnersAlreadySelectedIdPointer.current.includes(partner))
			.filter((partnerToAdd) => !selectedTargetPartnersToAddPointer.current.includes(partnerToAdd) && partnersId.includes(partnerToAdd));
		setSelectedTargetPartnersToAdd((value) => [...value, ...partnersToAdd]);

		const partnersToRemove = partnersAlreadySelectedIdPointer.current.filter((partner) => !allSelectedTargetPartnersIds.includes(partner))
			.filter((partnerToRemove) => !selectedTargetPartnersToRemovePointer.current.includes(partnerToRemove) && partnersId.includes(partnerToRemove));
		setSelectedTargetPartnersToRemove((value) => [...value, ...partnersToRemove]);

		const partnersNoMoreToAdd = selectedTargetPartnersToAddPointer.current.filter((partner) => partnersId.includes(partner))
			.filter((partner) => !allSelectedTargetPartnersIds.includes(partner));
		setSelectedTargetPartnersToAdd((value) => value.filter((partner) => !partnersNoMoreToAdd.includes(partner)));

		const partnersNoMoreToDel = selectedTargetPartnersToRemovePointer.current.filter((partner) => partnersId.includes(partner))
			.filter((partner) => allSelectedTargetPartnersIds.includes(partner));
		setSelectedTargetPartnersToRemove((value) => value.filter((partner) => !partnersNoMoreToDel.includes(partner)));
	}, [allSelectedTargetPartnersIds]);

	// submit with modal
	const handleSubmit = useCallback((formData, isInModalAddPartner = false) => {
		const submitData = {
			...formData,
			partners: selectedTargetPartnersIds,
			phases: target.phases?.map(({ id }) => (id ?? '')) ?? [],
			responsibles: target.responsibles?.map(({ id }) => (id ?? '')) ?? [],
		};
		onSubmit(submitData, isInModalAddPartner);
		dispatch(fetchPartnersForListWithTargets());
	}, [onSubmit, dispatch, selectedTargetPartnersIds, target]);

	const handlePartnerSubmit = useCallback((formData) => {
		togglePartnersModal();
		dispatch(addPartner(formData))
			.then((newPartner) => {
				if (newPartner) {
					const targetId = target.id;

					setSelectedTargetPartnersToAdd((value) => [...value, newPartner.id]);
					dispatch(fetchTarget(targetId));
					dispatch(fetchPartnersForListWithTargets());
				}
			});
	}, [dispatch, target, togglePartnersModal]);

	const { isShowing: isShowingSubmitModal, toggle: toggleSubmitModal } = useModal();
	const formDataModal = useRef();

	/**
	 * @function
	 * @name onSubmitModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Audrey Clerc
	 */
	const onSubmitModalConfirmationButtonClick = useCallback(async () => {
		toggleSubmitModal();

		handleSubmit(formDataModal.current);
	}, [toggleSubmitModal, handleSubmit]);
	const toggleModal = useCallback((formData) => {
		formDataModal.current = formData;
		toggleSubmitModal();
	}, [toggleSubmitModal]);

	return (
		<DynamicForm
			onSubmit={toggleModal}
			defaultValues={defaultValues}
			disabled={!canEditTarget}
			{...formProps}
		>
			<TargetPartner
				togglePartnersModal={togglePartnersModal}
				isPartnersModalShowing={isPartnersModalShowing}
				handlePartnerSubmit={handlePartnerSubmit}
				headers={headers}
				partners={partners}
				totalCount={totalCount}
				selectedTargetPartnersChanged={selectedTargetPartnersChanged}
				partnersIndices={partnersIndices}
				isPartnerLoading={isPartnerLoading}
				tableProps={tableProps}
				modalProps={modalProps}
			/>
			<Button className="primary" style={{ marginTop: '15px' }} type="submit" {...buttonProps}>
				{canEditTarget ? t('targeting.target.edition.action') : t('targeting.target.edition.close')}
			</Button>
			<PromptModal
				isShowing={isShowingSubmitModal}
				message={t('targeting.target.target_partner.submit.confirmation_modal.content',
					{ count: selectedTargetPartnersToAdd.length, countRemove: selectedTargetPartnersToRemove.length })}
				confirm={onSubmitModalConfirmationButtonClick}
				cancel={toggleSubmitModal}
				confirmText={t('targeting.target.target_partner.submit.confirmation_modal.confirm')}
				cancelText={t('targeting.target.target_partner.submit.confirmation_modal.cancel')}
				titleText={t('targeting.target.target_partner.submit.confirmation_modal.title')}
			/>
		</DynamicForm>
	);
};

TargetAddPartnerModalContent.propTypes = {
	target: PropTypes.shape({
		id: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		targetPartners: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				partner: PropTypes.shape({
					id: PropTypes.string.isRequired,
				}),
			}),
		),
		phases: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
			}),
		),
		responsibles: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
			}),
		),
		targeting: PropTypes.shape({
			id: PropTypes.string.isRequired,
			archived: PropTypes.bool,
		}),
	}),
	onSubmit: PropTypes.func.isRequired,
};

TargetAddPartnerModalContent.defaultProps = {
	target: {},
};
export default TargetAddPartnerModalContent;
