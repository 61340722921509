import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const tooltipStyle = {
	position: 'absolute', marginTop: '-2.2rem',
};

/**
 * @name Tooltip
 * @description A tooltip to render using useTooltip
 *
 * @author Audrey Clerc
 * @author Yann Hodiesne
 *
 * @param {node}	children	The content to render inside the tooltip
 * @param {boolean}	isOn		Whether the tooltip should be visible
 * @param {object}	coords		The coordinates provided by useTooltip
 * @param {bool}	disabled	Whether the tooltip should appear disabled or not
 */
const Tooltip = ({ children, isOn, coords, disabled }) => createPortal(
	isOn && (
		<div
			style={{ ...tooltipStyle, ...coords }}
		>
			<div className={`portaltooltip ${disabled ? 'disabled' : ''}`}>
				{children}
			</div>
		</div>
	),
	document.body
);

Tooltip.propTypes = {
	children: PropTypes.node.isRequired,
	coords: PropTypes.shape({
		left: PropTypes.number,
		top: PropTypes.number,
	}).isRequired,
	disabled: PropTypes.bool,
};

export default Tooltip;
