import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchVatRate, updateVatRate } from 'redux/actions/vatRates';
import { useCurrentVatRateSelector } from 'redux/selectors/vatRates';
import routes from 'routes';

import { VatRateEditionForm } from 'components/vatRates';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name VatRateEditionPage
 * @description A page used to edit an existing vat rate.
 *
 * @author Romaric Barthe
 */
const VatRateEditionPage = () => {
	const { id: vatRateId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const vatRate = useCurrentVatRateSelector(vatRateId);

	const canEditVatRate = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING) && !vatRate?.archived;

	useEffect(() => {
		dispatch(fetchVatRate(vatRateId));
	}, [dispatch, vatRateId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The updated values from VatRateEditionForm.
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditVatRate) {
			dispatch(updateVatRate(formData, vatRateId, routes.accounting.parameters.vatRates.list));
		} else {
			redirectOnSuccess(routes.accounting.parameters.vatRates.list);
		}
	}, [canEditVatRate, dispatch, vatRateId]);

	return (
		vatRate
			? <VatRateEditionForm onSubmit={handleFormSubmit} vatRate={vatRate} />
			: <p>{t('vat_rate.edition.loading')}</p>
	);
};

export default VatRateEditionPage;
