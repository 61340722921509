import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchInvoice, updateInvoice } from 'redux/actions/invoices/invoices';
import { useCurrentInvoiceSelector } from 'redux/selectors/invoices/invoices';

import { InvoiceEditionForm } from 'components/invoices';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';
import routes from '../../routes';

/**
 * @name InvoiceEditionPage
 * @description A page used to edit an existing invoice.
 *
 * @author Romaric Barthe
 * @author Matthieu Schaerlinger
 *
 */
const InvoiceEditionPage = () => {
	const { id: invoiceId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const invoice = useCurrentInvoiceSelector(invoiceId);

	const canEditInvoice = useAccessRight(AccessRights.accounting.invoices.enhancedRights.CREATE_INVOICE) && !invoice?.archived;

	useEffect(() => {
		dispatch(fetchInvoice(invoiceId));
	}, [dispatch, invoiceId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Matthieu Schaerlinger
	 *
	 * @param {object} formData	The form data submitted by the user.
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditInvoice) {
			dispatch(updateInvoice(formData, invoiceId));
		} else {
			redirectOnSuccess(routes.accounting.invoices.list);
		}
	}, [canEditInvoice, dispatch, invoiceId]);

	return (
		invoice
			? (
				<InvoiceEditionForm invoice={invoice} onSubmit={handleFormSubmit} />
			)
			: <p>{t('invoice.edition.loading')}</p>
	);
};

InvoiceEditionPage.defaultProps = {
	invoice: undefined,
};

export default InvoiceEditionPage;
