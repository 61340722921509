import * as PartnersApi from 'api/partnersApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Partners redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of partners
	FETCH_PARTNER_SELECT_WITH_SECTORS_LIST_REQUEST: '@PARTNERS_SELECT_WITH_SECTORS/FETCH_LIST_REQUEST',
	FETCH_PARTNER_SELECT_WITH_SECTORS_LIST_SUCCESS: '@PARTNERS_SELECT_WITH_SECTORS/FETCH_LIST_SUCCESS',
	FETCH_PARTNER_SELECT_WITH_SECTORS_LIST_FAILURE: '@PARTNERS_SELECT_WITH_SECTORS/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Partner list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnersSelectWithSectorsListRequest
 * @description Action triggered anytime a partnersSelectWithSectors list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchPartnersSelectWithSectorsListRequest = () => ({ type: ActionTypes.FETCH_PARTNER_SELECT_WITH_SECTORS_LIST_REQUEST });

/**
 * @function
 * @name fetchPartnersSelectWithSectorsListSuccess
 * @description Action triggered as a result to a successful partnersSelectWithSectors list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	partnersSelectWithSectors	The list of retrieved partners.
 * @param {number}	totalCount					The total amount of partners available in the database to the current user.
 *
 * @returns {object}
 */
const fetchPartnersSelectWithSectorsListSuccess = ({ partnersSelectWithSectors, totalCount }) => ({
	type: ActionTypes.FETCH_PARTNER_SELECT_WITH_SECTORS_LIST_SUCCESS,
	payload: { partnersSelectWithSectors, totalCount },
});

/**
 * @function
 * @name fetchPartnersSelectWithSectorsListFailure
 * @description Action triggered as a result to a failed partnersSelectWithSectors list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchPartnersSelectWithSectorsListFailure = (error) => ({
	type: ActionTypes.FETCH_PARTNER_SELECT_WITH_SECTORS_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnersSelectWithSectorsList
 * @description Method used to update the partnersSelectWithSectors list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchPartnersSelectWithSectorsList = (filters) => (dispatch) => {
	dispatch(fetchPartnersSelectWithSectorsListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forSelectWithSectors',
	};

	return PartnersApi.fetchPartners(newFilters)
		.then(({ partnersSelectWithSectors, totalCount }) => dispatch(fetchPartnersSelectWithSectorsListSuccess({ partnersSelectWithSectors, totalCount })))
		.catch((error) => dispatch(fetchPartnersSelectWithSectorsListFailure(error)));
};
