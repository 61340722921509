import PropTypes from 'prop-types';
/**
 * @name Hint
 * @description A component used to displayed the hint message of an input.
 *
 * @author Timothée Simon-Franza
 *
 * @param {node} children	The error message to display.
 */
const Hint = ({ children }) => (
	<span className="hint">
		{children}
	</span>
);

Hint.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.node,
	]).isRequired,
};

export default Hint;
