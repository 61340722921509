import * as ContactsApi from 'api/contactsApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the ContactsSelect redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a list of contacts
	FETCH_CONTACT_SELECT_LIST_REQUEST: '@CONTACTS_SELECT/FETCH_LIST_REQUEST',
	FETCH_CONTACT_SELECT_LIST_SUCCESS: '@CONTACTS_SELECT/FETCH_LIST_SUCCESS',
	FETCH_CONTACT_SELECT_LIST_FAILURE: '@CONTACTS_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Contact list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchContactsSelectListRequest
 * @description Action triggered anytime a contactsSelect list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchContactsSelectListRequest = () => ({ type: ActionTypes.FETCH_CONTACT_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchContactsSelectListSuccess
 * @description Action triggered as a result to a successful contactsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} contactsSelect		The list of retrieved contacts.
 * @param {number} totalCount			The total amount of contacts available in the database for the current user.
 *
 * @returns {object}
 */
const fetchContactsSelectListSuccess = ({ contactsSelect, totalCount }) => ({
	type: ActionTypes.FETCH_CONTACT_SELECT_LIST_SUCCESS,
	payload: { contactsSelect, totalCount },
});

/**
 * @function
 * @name fetchContactsSelectListFailure
 * @description Action triggered as a result to a failed contactsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchContactsSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_CONTACT_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchContactsSelectList
 * @description Method used to fetch the contactsSelect list.
 *
 * @author Romaric Barthe
 * @author Yann Hodiesne
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchContactsSelectList = (params) => (dispatch) => {
	dispatch(fetchContactsSelectListRequest());

	const newFilters = {
		...params,
		infoLevel: 'forSelect',
	};

	return ContactsApi.fetchContacts(newFilters)
		.then(({ contactsSelect, totalCount }) => dispatch(fetchContactsSelectListSuccess({ contactsSelect, totalCount })))
		.catch((error) => dispatch(fetchContactsSelectListFailure(error)));
};
