import { formatDateTime } from 'lib/shared/format';
import moment from 'moment';

/**
 * @function
 * @name formatExpenseName
 * @description Concatenate number format values to provide a full name for the expense.
 *
 * @author Romaric Barthe
 *
 * @param {object} expense  		The expense to be named.
 * @param {object} numberFormat   	The number format.
 * @param {func} t   				The translation function.
 *
 * @returns {string}
 */
export const formatExpenseName = (expense, numberFormat, t) => {
	if (!expense) {
		return undefined;
	}

	if (!expense.editionNumber || (!expense?.generatedPdf && !expense?.generatedPdf?.pdfGenerationDate)) {
		return t('invoice.creation.from_not_edited');
	}

	if (!numberFormat) {
		return (expense.editionNumber ?? '').toString();
	}

	const editionDate = expense.generatedPdf?.pdfGenerationDate;
	const monthString = numberFormat.monthPrefix ? `${moment(editionDate).format('MM')}-` : '';
	const yearString = numberFormat.yearPrefix ? `${moment(editionDate).format('YYYY')}-` : '';
	let numberString = (expense.editionNumber ?? '').toString();
	if (numberFormat.digitNumber && numberString.length < numberFormat.digitNumber) {
		numberString = numberString.padStart(numberFormat.digitNumber, '0');
	}

	return `${numberFormat.rootName}-${yearString}${monthString}${numberString}`;
};

/**
 * @function
 * @name formatExpenseNameList
 * @description Concatenate number format values to provide a full name and reference for the expense.
 *
 * @author Romaric Barthe
 *
 * @param {object} expense  		The expense to be named.
 * @param {object} numberFormat   	The number format.
 * @param {func} t   				The translation function.
 *
 * @returns {string}
 */
export const formatExpenseNameList = (expense, numberFormat, t) => {
	if (!expense) {
		return undefined;
	}

	return formatExpenseName(expense, numberFormat, t)
		.concat(' [').concat(expense.user?.username).concat('] > ')
		.concat(formatDateTime(expense.updatedAt));
};

/**
 * @function
 * @name formatStructureName
 * @description Returns the provided expense's structure's name if there is one.
 *
 * @author Romaric Barthe
 *
 * @param {object} expense		The expense serialised.
 *
 * @returns {string}
 */
export const formatStructureName = (expense) => ((expense.structure?.name) ?? '');
