/**
 * @function
 * @name getCurrentEnvironment
 * @description Gets the current environment on which the application is running
 *
 * @author Yann Hodiesne
 *
 * @returns {string} The current environment name.
 */
export const getCurrentEnvironment = () => process.env.REACT_APP_ENVIRONMENT;

/**
 * @function
 * @name isProduction
 * @description A helper method used to determine whether the application is running in production.
 *
 * @author Yann Hodiesne
 *
 * @returns {boolean} True if the application is running in production.
 */
export const isProduction = () => getCurrentEnvironment() === 'production';

/**
 * @function
 * @name isDev
 * @description A helper method used to determine whether the application is running in development mode.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @returns {boolean} False if the application is running in production mode, true otherwise.
 */
export const isDev = () => !isProduction();

/**
 * @function
 * @name isLocalDev
 * @description A helper method used to determine whether the application is running in a local development environment.
 *
 * @author Yann Hodiesne
 *
 * @returns {boolean} True if the application is running inside a local development environment.
 */
export const isLocalDev = () => isDev() && getCurrentEnvironment() === 'local';
