import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, Select } from 'components/shared/inputs';

import EditorContext from '../../../EditorContext';
import { updateFooter } from '../../../reducer/actions';

/**
 * @name ExcludeFooterFromPages
 * @description Component to exclude the footer from selected pages of the template
 *
 * @author Florian Fornazaric
 */
const ExcludeFooterFromPages = () => {
	const { t } = useTranslation();
	const { footer, pages, dispatch } = useContext(EditorContext);

	const [selectedValues, setSelectedValues] = useState(footer.excludeFromPages.map((pageId) => (
		{
			label: `Page ${pages.indexOf(pages.find((p) => p.id === pageId)) + 1}`,
			value: pageId,
		}
	)));

	const options = useMemo(() => pages.map((p, pageIndex) => ({ label: `Page ${pageIndex + 1}`, value: p.id })), [pages]);

	const handleSelectChange = useCallback((value) => {
		dispatch(updateFooter({ excludeFromPages: value.map((v) => v.value) }));
	}, [dispatch]);

	const handleDisabledChange = useCallback(() => {
		dispatch(updateFooter({ disabled: !footer.disabled }));
	}, [dispatch, footer.disabled]);

	// Allows us to update the selected values when the pages change
	useEffect(() => {
		setSelectedValues(footer.excludeFromPages.map((pageId) => (
			{
				label: `Page ${pages.indexOf(pages.find((p) => p.id === pageId)) + 1}`,
				value: pageId,
			}
		)));
	}, [footer.excludeFromPages, pages]);

	// Allows us to remove from the excludeFromPages list pages that have been deleted
	useEffect(() => {
		footer.excludeFromPages.forEach((pageId) => {
			if (!pages.some((p) => p.id === pageId)) {
				dispatch(updateFooter({ excludeFromPages: footer.excludeFromPages.filter((pId) => pId !== pageId) }));
			}
		});
	}, [dispatch, footer.excludeFromPages, pages, pages.length]);

	return (
		<div className="tab-options-list">
			<div className="input-wrapper checkbox-wrapper">
				<Checkbox id="remove-footer-from-all" checked={footer.disabled} onChange={handleDisabledChange} className="checkbox" />
				<label htmlFor="remove-footer-from-all">
					{t('template.pdf.format.footer_disabled')}
				</label>
			</div>

			<div>
				<label htmlFor="remove-footer-from-pages">
					{t('template.pdf.format.footer_exclude_from_pages')}
				</label>
				<Select id="remove-footer-from-pages" isMulti options={options} value={selectedValues} onChange={handleSelectChange} />
			</div>
		</div>
	);
};

export default ExcludeFooterFromPages;
