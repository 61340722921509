import update from 'immutability-helper';

import { findPositionById } from './internals';

/**
 * @function
 * @name addToSelection
 * @description Adds an element identified by the provided id to the selectedElements array
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} state		The current state
 * @param {object} payload		The payload of the action
 * @param {string} payload.id	The identifier of the element to select
 *
 * @returns {object} The updated state value
 */
const addToSelection = (state, { id }) => {
	if (!state?.selectedElements) {
		return state;
	}

	const { elements } = state;

	const position = findPositionById(elements, id);
	if (position === null) {
		return state;
	}

	const source = elements[position.rowIndex].children[position.elementIndex];

	if (state.selectedElements.includes(source)) {
		return state;
	}

	return update(state, {
		selectedElements: { $push: [source] },
	});
};

export default addToSelection;
