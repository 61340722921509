import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { paymentDelayScales, paymentDelayTerms } from './functions';

/**
 * @name PaymentDelayCreationForm
 * @description A form used to create a new payment delay.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const PaymentDelayCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();

	const scales = useMemo(() => paymentDelayScales(t, 'payment_delay.creation.inputs.scale.options'), [t]);
	const terms = useMemo(() => paymentDelayTerms(t, 'payment_delay.creation.inputs.term.options'), [t]);

	const { formProps, buttonProps } = useSubmitButton();

	/**
	 * @function
	 * @name handleSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Matthieu Schaerlinger
	 *
	 * @param {object} formData
	 */
	const handleSubmit = useCallback((formData) => {
		onSubmit({
			...formData,
			number: parseInt(formData.number, 10),
		});
	}, [onSubmit]);

	return (
		<DynamicForm onSubmit={handleSubmit} {...formProps}>
			<TextInput
				name="number"
				label={t('payment_delay.creation.inputs.number.label')}
				type="number"
				placeholder={t('payment_delay.creation.inputs.number.placeholder')}
				rules={{
					required: Validators.isRequired(t('payment_delay.creation.inputs.number.validation_errors.required')),
				}}
			/>

			<Select
				label={t('payment_delay.creation.inputs.scale.label')}
				name="scale"
				options={scales}
				labelKey="label"
				valueKey="value"
				rules={{
					required: Validators.isRequired(t('payment_delay.creation.inputs.scale.validation_errors.required')),
				}}
			/>

			<Select
				label={t('payment_delay.creation.inputs.term.label')}
				name="term"
				options={terms}
				labelKey="label"
				valueKey="value"
				rules={{
					required: Validators.isRequired(t('payment_delay.creation.inputs.term.validation_errors.required')),
				}}
			/>

			<Button className="primary" type="submit" {...buttonProps}>{t('payment_delay.creation.action')}</Button>
		</DynamicForm>
	);
};

PaymentDelayCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default PaymentDelayCreationForm;
