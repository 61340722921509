import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import shouldMatchRegex from 'lib/shared/formInputValidation/compareToRegex';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import PasswordRequirements from './PasswordRequirementFeedback';

/**
 * @name DefinePasswordForm
 * @description Form used to set an initial password for a user.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {func} onSubmit	The method to trigger whenever the form is submitted.
 */
const DefinePasswordForm = ({ onSubmit }) => {
	const { t } = useTranslation();
	const [passwordRequirements, setPasswordRequirement] = useState({
		atLeastOneLetter: false,
		atLeastOneNumber: false,
		atLeastOneSpecialChar: false,
		length: false,
	});

	const [hasPasswordInputBeenBlurred, setHasPasswordInputBeenBlurred] = useState(false);

	/**
	 * @function
	 * @name onChange
	 * @description Checks the password input's value to assert password requirements are met.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onChange = useCallback((value) => {
		setPasswordRequirement({
			atLeastOneLetter: /[a-zA-Z]/.test(value),
			atLeastOneNumber: /[0-9]/.test(value),
			atLeastOneSpecialChar: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value),
			length: value.trim().length > 6,
		});
	}, []);

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm
			onSubmit={onSubmit}
			blockNavigation={false}
			{...formProps}
		>
			<TextInput
				hint={t('define_password.inputs.password.hint')}
				label={t('define_password.inputs.password.label')}
				name="password"
				onBlur={() => setHasPasswordInputBeenBlurred(true)}
				onChange={onChange}
				rules={{
					required: Validators.isRequired(' '),
					atLeastOneLetter: shouldMatchRegex(/[a-zA-Z]/, ' '),
					atLeastOneNumber: shouldMatchRegex(/[0-9]/, ' '),
					atLeastOneSpecialChar: shouldMatchRegex(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/, ' '),
					length: Validators.hasMinLength(7, ' '),
				}}
				type="password"
			/>
			<div className="password-definition-requirements">
				<PasswordRequirements
					checked={passwordRequirements.atLeastOneLetter}
					id="password-contains-letter"
					hasInputBeenBlurred={hasPasswordInputBeenBlurred}
					translationKey="define_password.inputs.password_requirements.at_least_one_letter"
				/>
				<PasswordRequirements
					checked={passwordRequirements.atLeastOneNumber}
					id="password-contains-number"
					hasInputBeenBlurred={hasPasswordInputBeenBlurred}
					translationKey="define_password.inputs.password_requirements.at_least_one_number"
				/>
				<PasswordRequirements
					checked={passwordRequirements.atLeastOneSpecialChar}
					id="password-contains-special"
					hasInputBeenBlurred={hasPasswordInputBeenBlurred}
					translationKey="define_password.inputs.password_requirements.at_least_one_special"
				/>
				<PasswordRequirements
					checked={passwordRequirements.length}
					id="password-length"
					hasInputBeenBlurred={hasPasswordInputBeenBlurred}
					translationKey="define_password.inputs.password_requirements.length"
				/>
			</div>
			<TextInput
				label={t('define_password.inputs.check_password.label')}
				name="checkPassword"
				rules={{
					required: Validators.isRequired(t('define_password.inputs.check_password.validation_errors.required')),
				}}
				type="password"
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('define_password.action')}</Button>
		</DynamicForm>
	);
};

DefinePasswordForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default DefinePasswordForm;
