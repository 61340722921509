import { useEffect } from 'react';
import { ensurePluginOrder, makePropGetter, useGetLatest } from 'react-table';

const pluginName = 'usePaginationFooter';

/**
 * @name defaultGetPaginationFooterProps
 * @description Returns the props needed by the pagination footer to operate
 *
 * @author Yann Hodiesne
 *
 * @param {object} props	 the props defined by the other getters in the hooks chain
 * @param {object} instance	 the current table instance
 */
const defaultGetPaginationFooterProps = (props, { instance }) => [
	props,
	{
		pageIndex: instance.state.pageIndex,
		pageCount: instance.pageCount,
		rowsPerPage: instance.rowsPerPage,
		canGoToPreviousPage: instance.canPreviousPage,
		canGoToNextPage: instance.canNextPage,
		gotoPage: instance.gotoPage,
		previousPage: instance.previousPage,
		nextPage: instance.nextPage,
		setRowsPerPage: instance.setRowsPerPage,
		selectionEnabled: instance.selectionEnabled,
		selectedRowsCount: instance.selectedFlatRows.length,
	},
];

/**
 * @name useInstance
 * @description The plugin initialization hook, called when the useTable hook is called on the table component
 *
 * @author Yann Hodiesne
 *
 * @param {object} instance : the current table instance, returned by the useTable hook
 */
const useInstance = (instance) => {
	const {
		plugins,
		getHooks,
		state: { pageIndex },
		pageCount,
		gotoPage,
	} = instance;

	ensurePluginOrder(plugins,
		['usePagination', 'useRowSelectColumn'],
		pluginName);

	// Listen for changes in pages count
	useEffect(() => {
		// If the rows per page count just got higher, we do not want to display page 200 out of 10
		if (pageIndex >= pageCount) {
			gotoPage(pageCount - 1);
		}
	}, [pageCount, pageIndex, gotoPage]);

	const getInstance = useGetLatest(instance);

	const getPaginationFooterProps = makePropGetter(
		getHooks().getPaginationFooterProps,
		{ instance: getInstance() },
	);

	Object.assign(instance, {
		getPaginationFooterProps,
	});
};

/**
 * @name usePaginationFooter
 * @description A react-table plugin to add a footer with pagination controls
 *
 * @author Yann Hodiesne
 *
 * @param {object} hooks	 contains the internal react-table events on which we can add our hooks
 */
const usePaginationFooter = (hooks) => {
	// eslint-disable-next-line no-param-reassign
	hooks.getPaginationFooterProps = [defaultGetPaginationFooterProps];
	hooks.useInstance.push(useInstance);
};

usePaginationFooter.pluginName = pluginName;

export default usePaginationFooter;
