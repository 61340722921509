import { forwardRef, useCallback, useId, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

import CommonNumberInput from 'components/shared/inputs/NumberInput';

import useDynamicFormInput from '../useDynamicFormInput';
import CustomValidators from '../validators';

import { floatPattern } from './constants/validationPatterns';
import { Error, Hint, Label } from './DynamicFormInput';

/**
 * @name NumberInput
 * @description A number input component to be used inside a dynamic form.
 *
 * @author Matthieu Schaerlinger
 * @author Yann Hodiesne
 *
 * @param {string}		name						The input's name.
 * @param {string}		label						The string to display as a label for the wrapped input.
 * @param {string}		[hint]						A hint message to display below the input (if no error is displayed).
 * @param {string}		[placeholder]				The value to display as a placeholder in the input.
 * @param {boolean}		[leadingIcon=false]			Whether the icon should be placed on the left side of the input or not.
 * @param {array}		[rules={}]					The input validation rules.
 * @param {string}		[icon]						The input's icon, can be undefined, any currency or percentage.
 * @param {number}		[min=undefined]				The minimum value.
 * @param {number}		[max=undefined]				The maximum value.
 * @param {number}		[step=1]					The step value.
 * @param {number}		[decimalScale=0]			The number of decimal digits to display.
 * @param {boolean}		[fixedDecimalScale=false]	Whether to show trailing zeros or not.
 * @param {boolean}		[allowNull=false]			Whether the input is initialized empty.
 */
const NumberInput = forwardRef(({
	name,
	label,
	hint,
	placeholder,
	leadingIcon,
	icon,
	rules,
	min,
	max,
	step,
	decimalScale,
	fixedDecimalScale,
	allowNull,
	className,
	...props
}, ref) => {
	const resolvedRef = useForwardedRef(ref);
	const { t } = useTranslation();

	const resolvedRules = useMemo(() => ({
		...rules,
		...(min !== undefined ? { minValue: CustomValidators.minValue(min, t('form.number_input.min_value', { value: min })) } : {}),
		...(max !== undefined ? { maxValue: CustomValidators.maxValue(max, t('form.number_input.max_value', { value: max })) } : {}),
		isNumber: (value) => ((value && !floatPattern.test(value)) ? t('form.number_input.invalid_number', { value }) : undefined),
	}), [max, min, rules, t]);

	const currentValue = useRef();

	const getValue = useCallback(() => currentValue.current, []);

	const {
		defaultValue,
		isDisabled,
		isInvalid,
		isOptional,
		isReadOnly,
		validationError,
		enableValidationOnChange,
		onChangeHandler,
	} = useDynamicFormInput(name, getValue, resolvedRules, props);

	const [value, setValue] = useState(() => {
		const parsedValue = parseFloat(String(defaultValue));

		if (allowNull) {
			return Number.isNaN(parsedValue) ? null : parsedValue;
		}

		return Number.isNaN(parsedValue) ? 0 : parsedValue;
	});

	currentValue.current = value;

	const onChange = useCallback(({ target: { value: newValue } }) => {
		const parsedValue = parseFloat(String(newValue));
		let result;

		if (allowNull) {
			result = Number.isNaN(parsedValue) ? null : parsedValue;
		} else {
			result = Number.isNaN(parsedValue) ? 0 : parsedValue;
		}

		setValue(result);
		currentValue.current = result;

		onChangeHandler();
	}, [allowNull, onChangeHandler, currentValue]);

	useImperativeHandle(resolvedRef, () => ({
		setValue: (newValue) => onChange({ target: { value: newValue } }),
	}), [onChange]);

	const id = useId();

	return (
		<div className={`${icon ? 'icon-' : ''}input-wrapper
			${isDisabled ? ' disabled' : ''}
			${icon && leadingIcon ? ' leading-icon' : ''}
			${className ?? ''}`}
		>
			<Label disabled={isDisabled} inputId={id} isInvalid={isInvalid}>
				{label}
				{isOptional && ` (${t('form.optional')})`}
			</Label>
			<CommonNumberInput
				id={id}
				name={name}
				value={value}
				disabled={isDisabled}
				readOnly={isReadOnly}
				icon={icon}
				placeholder={placeholder}
				aria-invalid={isInvalid}
				min={min}
				max={max}
				step={step}
				decimalScale={decimalScale}
				fixedDecimalScale={fixedDecimalScale}
				allowNull={allowNull}
				onChange={onChange}
				onBlur={enableValidationOnChange}
			/>
			{isInvalid && <Error>{validationError ?? ''}</Error>}
			{!isInvalid && <Hint>{hint ?? ''}</Hint>}
		</div>
	);
});

NumberInput.propTypes = {
	allowNull: PropTypes.bool,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	hint: PropTypes.string,
	placeholder: PropTypes.string,
	leadingIcon: PropTypes.bool,
	icon: PropTypes.string,
	rules: PropTypes.object,
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	decimalScale: PropTypes.number,
	fixedDecimalScale: PropTypes.bool,
	className: PropTypes.string,
};

NumberInput.defaultProps = {
	allowNull: false,
	hint: '',
	leadingIcon: false,
	icon: undefined,
	placeholder: '',
	rules: {},
	step: 1,
	decimalScale: 0,
	fixedDecimalScale: false,
	min: undefined,
	max: undefined,
	className: undefined,
};

NumberInput.displayName = 'NumberInput';

export default NumberInput;
