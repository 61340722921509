import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTargeting, fetchTargetingStats } from 'redux/actions/targetings/targetings';
import { addTarget } from 'redux/actions/targets/targets';
import { useCurrentTargetingSelector, useCurrentTargetingStatsSelector } from 'redux/selectors/targetings/targetings';
import routes from 'routes';

import TargetingHeader from 'components/targetings/TargetingHeader';
import { TargetCreationForm } from 'components/targetings/targets';

import 'styles/pages/targetingDetailsPages.scss';

/**
 * @name TargetCreationPage
 * @description A page used to create a new target.
 *
 * @author Romaric Barthe
 *
 */
const TargetCreationPage = () => {
	const dispatch = useDispatch();
	const { targetingId } = useParams();
	const targeting = useCurrentTargetingSelector(targetingId);
	const targetingStats = useCurrentTargetingStatsSelector();

	useEffect(() => {
		dispatch(fetchTargeting(targetingId));
		dispatch(fetchTargetingStats(targetingId));
	}, [dispatch, targetingId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		const targetData = {
			...formData,
			targetingId,
		};
		dispatch(addTarget(targetData, routes.operations.targetings.targets.list));
	}, [dispatch, targetingId]);

	return (
		<div className="targeting-details-page">
			<div className="targeting-details-targeting-description">
				<TargetingHeader targeting={targeting} targetingStats={targetingStats} />
			</div>
			<TargetCreationForm onSubmit={handleFormSubmit} />
		</div>
	);
};

export default TargetCreationPage;
