import PropTypes from 'prop-types';

import { IndeterminateCheckbox } from 'components/shared/inputs';

/**
 * @name RowSelectionCell
 * @description A custom cell component wired to the row selection mechanism
 *
 * @author Yann Hodiesne
 *
 * @param {object} row  the managed row instance of the current line in the table
 */
const RowSelectionCell = ({ row }) => (
	<IndeterminateCheckbox data-testid={`table-row-selection-${row.id}-checkbox`} {...row.getToggleRowSelectedProps()} />
);

RowSelectionCell.propTypes = {
	row: PropTypes.shape({
		id: PropTypes.string.isRequired,
		getToggleRowSelectedProps: PropTypes.func.isRequired,
	}).isRequired,
};

export default RowSelectionCell;
