import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchQuotation } from 'redux/actions/quotations/quotations';
import { useCurrentQuotationSelector } from 'redux/selectors/quotations/quotations';

import { QuotationVisualisation } from 'components/quotations';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name QuotationVisualisationPage
 * @description A page used to edit an existing quotation.
 *
 * @author Romaric Barthe
 *
 */
const QuotationVisualisationPage = () => {
	const { id: quotationId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const quotation = useCurrentQuotationSelector(quotationId);
	const canViewQuotation = useAccessRight(AccessRights.humanResources.VIEW);

	useEffect(() => {
		dispatch(fetchQuotation(quotationId));
	}, [dispatch, quotationId]);

	return (quotation && canViewQuotation)
		? <QuotationVisualisation quotation={quotation} />
		: <p>{t('quotation.edition.loading')}</p>;
};

QuotationVisualisationPage.defaultProps = {
	quotation: undefined,
};

export default QuotationVisualisationPage;
