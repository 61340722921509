import { useCallback, useEffect, useMemo, useState } from 'react';
import { Download, Filter, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Environments } from 'constants/environmentEnums';
import { extractOptionsEnums } from 'constants/exportEnums';
import { formatExpenseName, formatStructureName } from 'lib/expenses/formatExpenseData';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { adaptToBackendFilters } from 'lib/shared/collectionsUtils';
import { formatCreatedAt, formatCreatedBy, formatCurrencyName, formatNumber, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchExpensesForList } from 'redux/actions/expenses/expensesList';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { useExpensesForListSelector, useExpensesListTotalCountSelector } from 'redux/selectors/expenses/expensesList';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import ActionsCell from 'components/expenses/expenseList/ActionsCell';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { DataListExtractPanel, useDataExtract } from 'components/shared/list/DataListExtracts';
import { DataListFilterPanel, useDataFilter } from 'components/shared/list/DataListFilters';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name ExpenseListPage
 * @description A list of all expenses accessible from the expenses app.
 *
 * From this page, the user is able to view, interact and search for expenses.
 *
 * @author Romaric Barthe
 */
const ExpenseListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const expenses = useExpensesForListSelector();
	const totalCount = useExpensesListTotalCountSelector();

	const numberFormat = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.EXPENSE)[0];
	const expensesWithFormattedName = useMemo(() => (expenses.map((expense) => ({
		...expense,
		name: formatExpenseName(expense, numberFormat, t),
		parent: expense.reversedInvoice ? formatExpenseName(expense.reversedExpense, numberFormat, t) : '',
	}))), [expenses, numberFormat, t]);

	const canCreateExpense = useAccessRight(AccessRights.humanResources.expenses.enhancedRights.CREATE_EXPENSE);

	const headers = useMemo(() => [
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('expense.headers.actions') },
		{ id: 'name', accessor: 'name', Header: t('expense.fields.number'), sorting: 'expense.editionNumber' },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('expense.fields.archived.yes') : t('expense.fields.archived.no')),
			Header: t('expense.fields.archived.header'),
			sorting: 'expense.archived',
		},
		{ id: 'user', accessor: (row) => row.user.username, Header: t('expense.fields.user'), sorting: 'user.username' },
		{ id: 'currency', accessor: (row) => formatCurrencyName(row), Header: t('expense.fields.currency'), sorting: 'currency.name' },
		{ id: 'comment', accessor: 'comment', Header: t('expense.fields.comment'), sorting: 'expense.comment' },
		{ id: 'reference', accessor: 'reference', Header: t('expense.fields.reference'), sorting: 'expense.reference' },
		{ id: 'reversedExpense', accessor: 'parent', Header: t('expense.fields.reversedExpense'), sorting: 'reversedExpense.editionNumber' },
		{
			id: 'netTotal',
			accessor: (row) => formatNumber(row.netTotal, { symbol: row.currency.name }),
			Header: t('expense.fields.netTotal'),
			sorting: 'expense.netTotal',
		},
		{ id: 'structure', accessor: (row) => formatStructureName(row), Header: t('expense.fields.structure'), sorting: 'structure.name' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('expense.fields.creation_date'), sorting: 'expense.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('expense.fields.update_date'), sorting: 'expense.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('expense.fields.creation_name'), sorting: 'createdBy.username' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('expense.fields.update_name'), sorting: 'updatedBy.username' },
	], [t]);

	const columns = useMemo(() => [
		{ accessor: 'editionNumber', label: t('expense.fields.number'), type: 'text' },
		{ accessor: 'netTotal', label: t('expense.fields.netTotal'), type: 'text' },
		{ accessor: 'grossTotal', label: t('expense.fields.grossTotal'), type: 'text' },
		{ accessor: 'vatTotal', label: t('expense.fields.vatTotal'), type: 'text' },
		{ accessor: 'reversedExpense', label: t('expense.fields.reversedExpense'), type: 'entity' },
		{ accessor: 'user', label: t('expense.fields.user'), type: 'entity' },
		{ accessor: 'comment', label: t('expense.fields.comment'), type: 'text' },
		{ accessor: 'reference', label: t('expense.fields.reference'), type: 'text' },
		{ accessor: 'currency', label: t('expense.fields.currency'), type: 'text' },
		{ accessor: 'createdBy', label: t('partner.fields.creation_name'), type: 'text' },
		{ accessor: 'createdAt', label: t('partner.fields.creation_date'), type: 'date' },
		{ accessor: 'updatedBy', label: t('partner.fields.update_name'), type: 'text' },
		{ accessor: 'updatedAt', label: t('partner.fields.update_date'), type: 'date' },
	], [t]);

	const [filters, setFilters] = useState({});
	const [params, setParams] = useState({});
	const [fetchData, searchRef] = useSearch((param) => {
		setParams(param);
		dispatch(fetchExpensesForList({ ...filters, ...param }));
	});

	useEffect(() => {
		dispatch(fetchNumberFormatList());
	}, [dispatch]);

	const [extractButtonRef, extractTooltipProps] = useTooltip();
	const [filterButtonRef, filterTooltipProps] = useTooltip();
	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('expense');

	const { isExtractPanelDisplayed, toggleExtractPanel } = useDataExtract();
	const { isFilterPanelDisplayed, toggleFilterPanel } = useDataFilter();

	const applyFilter = useCallback((filter) => {
		const newFilters = adaptToBackendFilters(filter);

		setFilters(newFilters);
		dispatch(fetchExpensesForList({ filters: newFilters, ...params }));
	}, [dispatch, params]);

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.humanResources.expenses.expenseCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateExpense && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('expense.links.create')}
						</Tooltip>
					</>
				)}
				<Button className="icon-only" onClick={toggleFilterPanel} ref={filterButtonRef}>
					<Filter />
				</Button>
				<Tooltip {...filterTooltipProps}>
					{t('components.table.filters.actions.filter')}
				</Tooltip>
				<Button className="icon-only" onClick={toggleExtractPanel} ref={extractButtonRef}>
					<Download />
				</Button>
				<Tooltip {...extractTooltipProps}>
					{t('template.export.action')}
				</Tooltip>
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={expensesWithFormattedName}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="expense"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('expense.pages.list')} {...modalProps} />
			<DataListFilterPanel
				applyFilterCallBack={applyFilter}
				columns={columns}
				open={isFilterPanelDisplayed}
				onCloseButtonClick={toggleFilterPanel}
			/>
			<DataListExtractPanel
				entity={extractOptionsEnums.export.dataTypes.EXPENSES}
				open={isExtractPanelDisplayed}
				onCloseButtonClick={toggleExtractPanel}
			/>
		</div>
	);
};

export default ExpenseListPage;
