import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { ColorPicker, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';
import { TagColorPreviewComponent } from 'components/shared/previewComponents';

/**
 * @name StatusCreationForm
 * @description A form used to create a new status.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const StatusCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();

	const { formProps, buttonProps } = useSubmitButton();

	/**
	 * @function
	 * @name handleSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleSubmit = useCallback((formData) => {
		onSubmit(formData);
	}, [onSubmit]);

	return (
		<DynamicForm onSubmit={handleSubmit} {...formProps}>
			<TextInput
				name="name"
				label={t('status.creation.inputs.name.label')}
				type="text"
				placeholder={t('status.creation.inputs.name.placeholder')}
				rules={{
					required: Validators.isRequired(t('status.creation.inputs.name.validation_errors.required')),
				}}
			/>
			<TextArea
				name="description"
				label={t('status.creation.inputs.description.label')}
			/>
			<ColorPicker
				name="defaultColor"
				label={t('status.creation.inputs.defaultColor.label')}
				type="circle"
				previewComponent={TagColorPreviewComponent}
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('status.creation.action')}</Button>
		</DynamicForm>
	);
};

StatusCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default StatusCreationForm;
