import update from 'immutability-helper';

/**
 * @function
 * @name removePage
 * @description Removes an existing page by its index
 *
 * @author Florian Fornazaric
 *
 * @param {object} state				The current state
 * @param {number} payload.index		The index of the page to remove
 *
 * @returns {object} The updated state value
 */
const removePage = (state, { index }) => {
	const { pages } = state;

	const result = {
		...state,
		pages: update(pages, {
			$splice: [
				[index, 1],
			],
		}),
		isDirty: true,
	};

	return result;
};

export default removePage;
