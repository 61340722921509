import { generatePath } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as TargetPartnersApi from 'api/targetPartnersApi';
import download from 'downloadjs';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the target partners redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific target partner
	FETCH_TARGET_PARTNER_REQUEST: '@TARGET_PARTNERS/FETCH_REQUEST',
	FETCH_TARGET_PARTNER_SUCCESS: '@TARGET_PARTNERS/FETCH_SUCCESS',
	FETCH_TARGET_PARTNER_FAILURE: '@TARGET_PARTNERS/FETCH_FAILURE',

	// Fetch a list of target partners
	FETCH_TARGET_PARTNER_LIST_REQUEST: '@TARGET_PARTNERS/FETCH_LIST_REQUEST',
	FETCH_TARGET_PARTNER_LIST_SUCCESS: '@TARGET_PARTNERS/FETCH_LIST_SUCCESS',
	FETCH_TARGET_PARTNER_LIST_FAILURE: '@TARGET_PARTNERS/FETCH_LIST_FAILURE',

	// Add a new target partner to the database
	ADD_TARGET_PARTNER_REQUEST: '@TARGET_PARTNERS/ADD_REQUEST',
	ADD_TARGET_PARTNER_SUCCESS: '@TARGET_PARTNERS/ADD_SUCCESS',
	ADD_TARGET_PARTNER_FAILURE: '@TARGET_PARTNERS/ADD_FAILURE',

	// Update an existing target partner from the database
	UPDATE_TARGET_PARTNER_REQUEST: '@TARGET_PARTNERS/UPDATE_REQUEST',
	UPDATE_TARGET_PARTNER_SUCCESS: '@TARGET_PARTNERS/UPDATE_SUCCESS',
	UPDATE_TARGET_PARTNER_FAILURE: '@TARGET_PARTNERS/UPDATE_FAILURE',

	// Remove an existing target partner from the database
	REMOVE_TARGET_PARTNER_REQUEST: '@TARGET_PARTNERS/REMOVE_REQUEST',
	REMOVE_TARGET_PARTNER_SUCCESS: '@TARGET_PARTNERS/REMOVE_SUCCESS',
	REMOVE_TARGET_PARTNER_FAILURE: '@TARGET_PARTNERS/REMOVE_FAILURE',

	// Export target partners as a file
	EXPORT_TARGET_PARTNER_REQUEST: '@TARGET_PARTNERS/EXPORT_REQUEST',
	EXPORT_TARGET_PARTNER_SUCCESS: '@TARGET_PARTNERS/EXPORT_SUCCESS',
	EXPORT_TARGET_PARTNER_FAILURE: '@TARGET_PARTNERS/EXPORT_FAILURE',
};

// ////////////////////////////////////////////////////////////// //
// ///////////// Target partner list fetching actions //////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetPartnerListRequest
 * @description Action triggered anytime a target partner list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetPartnerListRequest = () => ({ type: ActionTypes.FETCH_TARGET_PARTNER_LIST_REQUEST });

/**
 * @function
 * @name fetchTargetPartnerListSuccess
 * @description Action triggered as a result to a successful target partner list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetPartners	The list of retrieved target partners.
 * @param {number} totalCount		The total amount of target partners available in the database for the current user.
 *
 * @returns {object}
 */
const fetchTargetPartnerListSuccess = ({ targetPartners, totalCount }) => ({
	type: ActionTypes.FETCH_TARGET_PARTNER_LIST_SUCCESS,
	payload: { targetPartners, totalCount },
});

/**
 * @function
 * @name fetchTargetPartnerListFailure
 * @description Action triggered as a result to a failed target partner list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetPartnerListFailure = (error) => ({
	type: ActionTypes.FETCH_TARGET_PARTNER_LIST_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// /////////// Specific target partner fetching actions ////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetPartnerRequest
 * @description Action triggered anytime a target partner fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetPartnerRequest = () => ({ type: ActionTypes.FETCH_TARGET_PARTNER_REQUEST });

/**
 * @function
 * @name fetchTargetPartnerSuccess
 * @description Action triggered as a result to a successful target partner fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetPartner The retrieved target partner.
 *
 * @returns {object}
 */
const fetchTargetPartnerSuccess = ({ targetPartner }) => ({
	type: ActionTypes.FETCH_TARGET_PARTNER_SUCCESS,
	payload: { targetPartner },
});

/**
 * @function
 * @name fetchTargetPartnerFailure
 * @description Action triggered as a result to a failed target partner fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetPartnerFailure = (error) => ({
	type: ActionTypes.FETCH_TARGET_PARTNER_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Target partner creation actions ////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name addTargetPartnerRequest
 * @description Action triggered anytime a target partner creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addTargetPartnerRequest = () => ({ type: ActionTypes.ADD_TARGET_PARTNER_REQUEST });

/**
 * @function
 * @name addTargetPartnerSuccess
 * @description Action triggered as a result to a successful target partner creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetPartner The created target partner object.
 *
 * @returns {object}
 */
const addTargetPartnerSuccess = ({ targetPartner }) => ({
	type: ActionTypes.ADD_TARGET_PARTNER_SUCCESS,
	payload: { targetPartner },
});

/**
 * @function
 * @name addTargetPartnerFailure
 * @description Action triggered as a result to a failed target partner creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const addTargetPartnerFailure = (error) => ({
	type: ActionTypes.ADD_TARGET_PARTNER_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// ///////////////// Target partner update actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateTargetPartnerRequest
 * @description Action triggered anytime a target partner update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateTargetPartnerRequest = () => ({ type: ActionTypes.UPDATE_TARGET_PARTNER_REQUEST });

/**
 * @function
 * @name updateTargetPartnerSuccess
 * @description Action triggered as a result to a successful target partner update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetPartner The updated target partner object.
 *
 * @returns {object}
 */
const updateTargetPartnerSuccess = ({ targetPartner }) => ({
	type: ActionTypes.UPDATE_TARGET_PARTNER_SUCCESS,
	payload: { targetPartner },
});

/**
 * @function
 * @name updateTargetPartnerFailure
 * @description Action triggered as a result to a failed target partner update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const updateTargetPartnerFailure = (error) => ({
	type: ActionTypes.UPDATE_TARGET_PARTNER_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Target partner removal actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeTargetPartnerRequest
 * @description Action triggered anytime a target partner deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeTargetPartnerRequest = () => ({ type: ActionTypes.REMOVE_TARGET_PARTNER_REQUEST });

/**
 * @function
 * @name removeTargetPartnerSuccess
 * @description Action triggered as a result to a successful target partner deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {string} deletedTargetPartnerId The removed targetPartner object id.
 *
 * @returns {object}
 */
const removeTargetPartnerSuccess = ({ deletedTargetPartnerId }) => ({
	type: ActionTypes.REMOVE_TARGET_PARTNER_SUCCESS,
	payload: { deletedTargetPartnerId },
});

/**
 * @function
 * @name removeTargetPartnerFailure
 * @description Action triggered as a result to a failed target partner deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const removeTargetPartnerFailure = (error) => ({
	type: ActionTypes.REMOVE_TARGET_PARTNER_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Target partner exporting actions //////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name exportTargetPartnerRequest
 * @description Action triggered anytime a target partner exporting call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const exportTargetPartnerRequest = () => ({ type: ActionTypes.EXPORT_TARGET_PARTNER_REQUEST });

/**
 * @function
 * @name exportTargetPartnerSuccess
 * @description Action triggered as a result to a successful target partner exporting API call.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const exportTargetPartnerSuccess = () => ({ type: ActionTypes.EXPORT_TARGET_PARTNER_SUCCESS });

/**
 * @function
 * @name exportTargetPartnerFailure
 * @description Action triggered as a result to a failed target partner exporting API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const exportTargetPartnerFailure = (error) => ({
	type: ActionTypes.EXPORT_TARGET_PARTNER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetPartnerList
 * @description Method used to update the target partner list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params			The parameters used to match user filters.
 * @param {string} [targetingId]	The id of the targeting linked with the target partners.
 * @param {string} [targetId]		The id of the target linked with the target partners.
 *
 * @returns {Promise}
 */
export const fetchTargetPartnerList = (params, targetingId = null, targetId = null) => (dispatch) => {
	dispatch(fetchTargetPartnerListRequest());
	const requestParams = {
		...params,
		filters: {
			...(targetingId ? { conditions: [{ column: 'targetingId', criterion: 'is', value: targetingId }] } : {}),
			...(targetId ? { conditions: [{ column: 'targetId', criterion: 'is', value: targetId }] } : {}),
		},
	};

	return TargetPartnersApi.fetchTargetPartners(requestParams)
		.then(({ targetPartners, totalCount }) => dispatch(fetchTargetPartnerListSuccess({ targetPartners, totalCount })))
		.catch((error) => dispatch(fetchTargetPartnerListFailure(error)));
};

/**
 * @function
 * @name fetchTargetPartner
 * @description Method used to fetch the latest version of a specific target partner.
 *
 * @author Romaric Barthe
 *
 * @param {string} targetPartnerId		The id of the target partner we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTargetPartner = (targetPartnerId) => (dispatch) => {
	dispatch(fetchTargetPartnerRequest());

	return TargetPartnersApi.fetchTargetPartnerById(targetPartnerId)
		.then(({ targetPartner }) => dispatch(fetchTargetPartnerSuccess({ targetPartner })))
		.catch((error) => dispatch(fetchTargetPartnerFailure(error)));
};

/**
 * @function
 * @name addTargetPartner
 * @description Method used to add a new target partner instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetPartnerData	The data to create the new target partner from.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const addTargetPartner = (targetPartnerData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addTargetPartnerRequest());

	return TargetPartnersApi.createTargetPartner(targetPartnerData)
		.then(({ targetPartner }) => {
			dispatch(addTargetPartnerSuccess({ targetPartner }));
			toast.success(i18next.t('targeting.target.target_partner.creation.toasts.success', { partner: targetPartner?.partner?.name, target: targetPartner?.target?.name }));
			if (onSuccessRoute) {
				redirectOnSuccess(generatePath(onSuccessRoute, { targetingId: targetPartner.targeting?.id, targetId: targetPartner.target?.id }));
			}

			return targetPartner;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('targeting.target.target_partner.creation.toasts.conflict'));
				dispatch(addTargetPartnerFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('targeting.target.target_partner.creation.toasts.error'));
				dispatch(addTargetPartnerFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateTargetPartner
 * @description Method used to update an existing target partner instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetPartnerData	The object to update the target partner with.
 * @param {string} targetPartnerId		The id of the target partner we want to update.
 * @param {string} [onSuccessRoute]		The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 *
 * @returns {Promise}
 */
export const updateTargetPartner = (targetPartnerData, targetPartnerId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateTargetPartnerRequest());

	return TargetPartnersApi.updateTargetPartner(targetPartnerData, targetPartnerId)
		.then(({ targetPartner }) => {
			dispatch(updateTargetPartnerSuccess({ targetPartner }));
			toast.success(i18next.t('targeting.target.target_partner.edition.toasts.success', { partner: targetPartner?.partner?.name, target: targetPartner?.target?.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('targeting.target.target_partner.edition.toasts.conflict'));
				dispatch(updateTargetPartnerFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('targeting.target.target_partner.edition.toasts.error'));
				dispatch(updateTargetPartnerFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeTargetPartner
 * @description Method used to remove an existing target partner instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetPartner				The target partner we want to remove.
 * @param {string} targetPartner.id				The id of the target partner we want to remove.
 * @param {string} targetPartner.partner		The partner of the target partner we want to remove.
 * @param {string} targetPartner.target			The target of the target partner we want to remove.
 *
 * @returns {Promise}
 */
export const removeTargetPartner = ({ id, partner, target }) => (dispatch) => {
	dispatch(removeTargetPartnerRequest());

	return TargetPartnersApi.deleteTargetPartner(id)
		.then(({ deletedTargetPartnerId }) => {
			dispatch(removeTargetPartnerSuccess({ deletedTargetPartnerId }));
			toast.success(i18next.t('targeting.target.target_partner.deletion.toasts.success', { partner, target }));
		})
		.catch((error) => {
			dispatch(removeTargetPartnerFailure(error));
			toast.error(i18next.t('targeting.target.target_partner.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name exportTargetPartners
 * @description Method used to export multiple target partner instances from the database.
 *
 * @author Audrey Clerc
 *
 * @param {Object}	data				The data to export the target partners.
 * @param {Array}	data.partnerIds		The ids of the target partners to export.
 * @param {string}	data.format			The file format.
 * @param {string}	data.restriction	The scope of the data to export (all, filtered or current selection).
 * @param {string}	data.separator		The separator used in the CSV file.
 *
 * @todo Adapt params when backend will change
 */
export const exportTargetPartners = (targetId, data) => (dispatch) => {
	dispatch(exportTargetPartnerRequest());

	// TODO: adapt these parameters along with backend changes
	return TargetPartnersApi.exportTargetPartners(targetId, {
		ids: data.selectedElementsIds ?? [],
		format: data.format ?? 'xlsx',
		restriction: data.restriction ?? 'all',
		separator: data.separator ?? 'comma',
		...data,
	})
		.then(({ blob, fileName, fileType }) => {
			dispatch(exportTargetPartnerSuccess());
			toast.success(i18next.t('template.export.toasts.success'));

			download(blob, fileName, fileType);
		})
		.catch((error) => {
			dispatch(exportTargetPartnerFailure(error));
			toast.error(i18next.t('template.export.toasts.error'));
		});
};
