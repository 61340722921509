import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import _ from 'lodash';
import PropTypes from 'prop-types';

import { DateInput, HiddenInput, NumberInput, Select, TextArea } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name QuotationHeader
 * @description A form used to edit an existing quotation's information.
 *
 * @author Romaric Barthe
 *
 * @param {string}	creationType				If set, the type of creation.
 * @param {object}	currencies					The list of available currencies.
 * @param {string}	currencyName				The currency of the quotation.
 * @param {object}	defaultValues				The initialisation values.
 * @param {bool}	isLoading					True is the data are still not fully loaded.
 * @param {object}	structures					The list of available companies and subsidiaries.
 * @param {object}	projectCreationModal		The modal to create a project on the fly.
 * @param {object}	projects					The list of available projects.
 * @param {string}	quotationParent				If set, the quotation linked to this one.
 * @param {object}	quotationTypes				The list of possible quotation types.
 * @param {string}	quotationTypeSelected		The selected type.
 * @param {object}	userCreationModal			The modal to create a user on the fly.
 * @param {object}	users						The list of available collaborators.
 * @param {object}	vatRateCreationModal		The modal to create a vat rate on the fly.
 * @param {object}	vatRates					The list of available vat rates.
 * @param {func}	setCurrency					The method to set the quotation currency.
 * @param {func}	setGlobalVatRate			The method to set the global vat rate.
 * @param {func}	setProjectAmount			The method to set the project amount.
 * @param {func}	setQuotationTypeSelected	The method to set the quotationType.
 */
const QuotationHeader = ({
	creationType,
	currencies,
	currencyName,
	isLoading,
	structures,
	projectCreationModal,
	projects,
	quotationParent,
	quotationTypes,
	quotationTypeSelected,
	userCreationModal,
	users,
	vatRateCreationModal,
	vatRates,
	// functions
	setCurrency,
	setGlobalVatRate,
	setProjectAmount,
	setQuotationTypeSelected,
}) => {
	const { t } = useTranslation();

	const descriptionRef = useRef();
	const projectRef = useRef();

	const handleProjectChange = useCallback((selectedProject) => {
		const projectDescription = projects.filter((p) => p.id === selectedProject);
		if (projectDescription.length !== 1) {
			return;
		}
		descriptionRef.current.setValue(projectDescription[0].description);
	}, [projects]);

	return (
		<>
			<HiddenInput
				name="creationType"
				value={creationType}
			/>
			<HiddenInput
				name="quotationParent"
				value={quotationParent}
			/>
			<Select
				ref={projectRef}
				isLoading={isLoading}
				label={t('quotation.edition.inputs.project.label')}
				labelKey="name"
				name="project"
				onChange={handleProjectChange}
				options={projects}
				placeholder={t('quotation.edition.inputs.project.placeholder')}
				rules={{
					required: Validators.isRequired(t('quotation.edition.inputs.project.validation_errors.required')),
				}}
				valueKey="id"
				{...projectCreationModal}
			/>
			<TextArea
				ref={descriptionRef}
				label={t('quotation.edition.inputs.description.label')}
				name="description"
				type="text"
			/>
			<Select
				isLoading={isLoading}
				label={t('quotation.edition.inputs.currency.label')}
				labelKey="name"
				name="currency"
				onChange={setCurrency}
				options={currencies}
				placeholder={t('quotation.edition.inputs.currency.placeholder')}
				rules={{
					required: Validators.isRequired(t('quotation.edition.inputs.currency.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<Select
				isLoading={isLoading}
				label={t('quotation.edition.inputs.quotation_type.label')}
				labelKey="label"
				name="quotationType"
				onChange={setQuotationTypeSelected}
				options={quotationTypes}
				placeholder={t('quotation.edition.inputs.project.placeholder')}
				rules={{
					required: Validators.isRequired(t('quotation.edition.inputs.quotation_type.validation_errors.required')),
				}}
				valueKey="id"
			/>
			{quotationTypeSelected === 'archi' && (
				<>
					<NumberInput
						decimalScale={2}
						fixedDecimalScale
						icon={currencyName}
						label={t('quotation.edition.inputs.project_amount.label')}
						name="projectAmount"
						onChange={setProjectAmount}
						placeholder={t('quotation.edition.inputs.project_amount.placeholder')}
						rules={{
							required: Validators.isRequired(t('quotation.edition.inputs.project_amount.validation_errors.required')),
						}}
					/>
					<Select
						isLoading={isLoading}
						label={t('quotation.edition.inputs.vat_rate.label')}
						labelKey="rate"
						name="vatRate"
						onChange={setGlobalVatRate}
						options={vatRates}
						placeholder={t('quotation.edition.inputs.vat_rate.placeholder')}
						rules={{
							required: Validators.isRequired(t('quotation.edition.inputs.vat_rate.validation_errors.required')),
						}}
						valueKey="id"
						{...vatRateCreationModal}
					/>
				</>
			)}
			<Select
				label={t('quotation.edition.inputs.structure.label')}
				labelKey="name"
				isLoading={isLoading}
				name="structure"
				options={structures}
				placeholder={t('quotation.edition.inputs.structure.placeholder')}
				valueKey="id"
			/>
			<Select
				isLoading={isLoading}
				label={t('quotation.edition.inputs.seller.label')}
				labelKey="username"
				name="seller"
				options={users}
				placeholder={t('quotation.edition.inputs.seller.placeholder')}
				rules={{
					required: Validators.isRequired(t('quotation.edition.inputs.seller.validation_errors.required')),
				}}
				valueKey="id"
				{...userCreationModal}
			/>
			<DateInput
				label={t('quotation.edition.inputs.validity_date.label')}
				name="validityDate"
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
		</>
	);
};

QuotationHeader.propTypes = {
	creationType: PropTypes.string,
	currencies: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	currencyName: PropTypes.string,
	isLoading: PropTypes.bool,
	projectCreationModal: PropTypes.object.isRequired,
	projects: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	quotationParent: PropTypes.string,
	quotationTypes: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	quotationTypeSelected: PropTypes.string,
	structures: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	userCreationModal: PropTypes.object.isRequired,
	users: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	vatRateCreationModal: PropTypes.object.isRequired,
	vatRates: PropTypes.arrayOf(
		PropTypes.object,
	).isRequired,
	// functions
	setCurrency: PropTypes.func.isRequired,
	setGlobalVatRate: PropTypes.func.isRequired,
	setProjectAmount: PropTypes.func.isRequired,
	setQuotationTypeSelected: PropTypes.func.isRequired,
};

QuotationHeader.defaultProps = {
	creationType: undefined,
	currencyName: '',
	isLoading: true,
	quotationTypeSelected: undefined,
	quotationParent: undefined,
};

export default QuotationHeader;
