import { useCallback } from 'react';
import { Edit2, Eye, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { removeAccessDefinition } from 'redux/actions/accessDefinitions';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the AccessDefinitionListPage table. (delete and edit access definition)
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object}		row					The object containing the data to interact with.
 * @param {string}		row.original.id		The id of the access definition.
 * @param {string}		row.original.name	The name of the access definition.
 * @param {function}	fetchData			The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { id, name } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditAccessDefinition = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);
	const canDeleteAccessDefinition = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.DELETE_SETTING);

	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Matthieu Schaerlinger
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();
		await dispatch(removeAccessDefinition({ id, name }));
		fetchData();
	}, [dispatch, fetchData, id, name, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the edit button.
	 *
	 * @author Matthieu Schaerlinger
	 */
	const onEditPageRedirectionButtonClick = useCallback(
		() => history.push(generatePath(routes.humanResources.accessDefinitions.accessDefinitionEdition, { id })),
		[id]
	);

	return (
		<>
			<Button className="icon-only" onClick={onEditPageRedirectionButtonClick} ref={editButtonRef}>
				{canEditAccessDefinition ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditAccessDefinition ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			{canDeleteAccessDefinition && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('access_definition.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('access_definition.deletion.confirmation_modal.confirm')}
						cancelText={t('access_definition.deletion.confirmation_modal.cancel')}
						titleText={t('access_definition.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
