import { useCallback, useEffect, useMemo, useState } from 'react';
import { Filter, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Environments } from 'constants/environmentEnums';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import {
	formatProjectName,
	formatQuotationName,
	formatSellerName,
	formatStructureName,
	formatVatRate,
} from 'lib/quotations/formatQuotationData';
import { adaptToBackendFilters } from 'lib/shared/collectionsUtils';
import { formatCreatedAt, formatCreatedBy, formatCurrencyName, formatDateTime, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { fetchQuotationsForList } from 'redux/actions/quotations/quotationsList';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';
import { useQuotationsForListSelector, useQuotationsListTotalCountSelector } from 'redux/selectors/quotations/quotationsList';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import ActionsCell from 'components/quotations/quotationList/ActionsCell';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { useDataFilter } from 'components/shared/list/DataListFilters';
import DataListFilterPanel from 'components/shared/list/DataListFilters/DataListFilterPanel';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name QuotationListPage
 * @description A list of all quotations accessible from the quotation app.
 *
 * From this page, the user is able to view, interact and search for quotations.
 *
 * @author Roland Margelidon
 *
 */
const QuotationListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const quotationsList = useQuotationsForListSelector();
	const totalCount = useQuotationsListTotalCountSelector();

	const numberFormat = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.QUOTATION)[0];

	const quotations = useMemo(() => (quotationsList.map((quotation) => ({
		...quotation,
		name: formatQuotationName(quotation, numberFormat),
		parent: quotation.quotationParent ? formatQuotationName(quotation, numberFormat) : '',
	}))), [quotationsList, numberFormat]);

	const canCreateQuotation = useAccessRight(AccessRights.sales.quotations.enhancedRights.CREATE_QUOTATION);

	const headers = useMemo(() => [
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('quotation.headers.actions'), width: 216 },
		{ id: 'project', accessor: (row) => formatProjectName(row), Header: t('quotation.fields.project'), pinColumn: true, sorting: 'project.name' },
		{ id: 'name', accessor: 'name', Header: t('quotation.fields.number'), pinColumn: true, sorting: 'quotation.number' },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('quotation.fields.archived.yes') : t('quotation.fields.archived.no')),
			Header: t('quotation.fields.archived.header'),
			sorting: 'quotation.archived',
		},
		{
			id: 'favorite',
			accessor: (row) => (row.favorite ? t('quotation.fields.favorite.yes') : t('quotation.fields.favorite.no')),
			Header: t('quotation.fields.favorite.header'),
			sorting: 'quotation.favorite',
		},
		{ id: 'description', accessor: 'description', Header: t('quotation.fields.description'), sorting: 'quotation.description' },
		{ id: 'quotationType', accessor: 'quotationType', Header: t('quotation.fields.quotation_type'), sorting: 'quotation.quotationType' },
		{ id: 'seller', accessor: (row) => formatSellerName(row), Header: t('quotation.fields.seller'), sorting: 'quotation.seller' },
		{ id: 'currency', accessor: (row) => formatCurrencyName(row), Header: t('quotation.fields.currency'), sorting: 'currency.name' },
		{ id: 'vatRate', accessor: (row) => formatVatRate(row), Header: t('quotation.fields.vat_rate'), sorting: 'vatRate.rate' },
		{ id: 'quotationParent', accessor: 'parent', Header: t('quotation.fields.quotation_parent'), sorting: 'quotationParent.number' },
		{ id: 'projectAmount', accessor: 'projectAmount', Header: t('quotation.fields.project_amount'), sorting: 'quotation.projectAmount' },
		{ id: 'validityDate', accessor: (row) => formatDateTime(row), Header: t('quotation.fields.validity_date'), sorting: 'quotation.validityDate' },
		{ id: 'structure', accessor: (row) => formatStructureName(row), Header: t('quotation.fields.structure'), sorting: 'structure.name' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('quotation.fields.creation_date'), sorting: 'quotation.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('quotation.fields.update_date'), sorting: 'quotation.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('quotation.fields.creation_name'), sorting: 'createdBy.username' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('quotation.fields.update_name'), sorting: 'updatedBy.username' },
	], [t]);

	useEffect(() => {
		dispatch(fetchNumberFormatList());
	}, [dispatch]);

	const columns = useMemo(() => [
		{ accessor: 'number', label: t('quotation.fields.number'), type: 'text' },
		{ accessor: 'description', label: t('quotation.fields.description'), type: 'text' },
		{ accessor: 'project', label: t('quotation.fields.project'), type: 'entity' },
		{ accessor: 'client', label: t('quotation.fields.client'), type: 'entity' },
		{ accessor: 'projectAmount', label: t('quotation.fields.project_amount'), type: 'text' },
		{ accessor: 'vatRate', label: t('quotation.fields.vat_rate'), type: 'entity' },
		{ accessor: 'currency', label: t('quotation.fields.currency'), type: 'entity' },
		{ accessor: 'grossTotal', label: t('quotation.fields.gross_total'), type: 'number' },
		{ accessor: 'netTotal', label: t('quotation.fields.net_total'), type: 'number' },
		{ accessor: 'vatTotal', label: t('quotation.fields.vat_total'), type: 'number' },
		{ accessor: 'createdBy', label: t('quotation.fields.creation_name'), type: 'text' },
		{ accessor: 'createdAt', label: t('quotation.fields.creation_date'), type: 'date' },
		{ accessor: 'updatedBy', label: t('quotation.fields.update_name'), type: 'text' },
		{ accessor: 'updatedAt', label: t('quotation.fields.update_date'), type: 'date' },
	], [t]);

	const [filters, setFilters] = useState({});
	const [params, setParams] = useState({});
	const [fetchData, searchRef] = useSearch((param) => {
		setParams(param);
		dispatch(fetchQuotationsForList({ ...filters, ...param }));
	});

	const [filterButtonRef, filterTooltipProps] = useTooltip();
	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('quotation');

	const { isFilterPanelDisplayed, toggleFilterPanel } = useDataFilter();

	const applyFilter = useCallback((filter) => {
		const newFilters = adaptToBackendFilters(filter);

		setFilters(newFilters);
		dispatch(fetchQuotationsForList({ filters: newFilters, ...params }));
	}, [dispatch, params]);

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.sales.quotations.quotationPreCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateQuotation && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('quotation.links.create')}
						</Tooltip>
					</>
				)}
				<Button className="icon-only" onClick={toggleFilterPanel} ref={filterButtonRef}>
					<Filter />
				</Button>
				<Tooltip {...filterTooltipProps}>
					{t('components.table.filters.actions.filter')}
				</Tooltip>
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={quotations}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="quotation"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('quotation.pages.list')} {...modalProps} />
			<DataListFilterPanel
				applyFilterCallBack={applyFilter}
				columns={columns}
				open={isFilterPanelDisplayed}
				onCloseButtonClick={toggleFilterPanel}
			/>
		</div>
	);
};

export default QuotationListPage;
