import { ActionTypes } from '../actions/users';

const initialState = {
	currentItem: null,
	currentUser: undefined,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.LOGIN_REQUEST:
		case ActionTypes.LOGIN_TOKEN_REQUEST:
		case ActionTypes.LOGOUT_REQUEST:
		case ActionTypes.FORGOTTEN_PASSWORD_REQUEST:
		case ActionTypes.DEFINE_PASSWORD_REQUEST:
		case ActionTypes.FETCH_USER_LIST_REQUEST:
		case ActionTypes.FETCH_ALL_FOR_USER_REQUEST:
		case ActionTypes.ADD_USER_REQUEST:
		case ActionTypes.UPDATE_USER_REQUEST:
		case ActionTypes.REMOVE_USER_REQUEST:
		case ActionTypes.ARCHIVE_USER_REQUEST:
		case ActionTypes.RESTORE_USER_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_USER_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.LOGIN_FAILURE:
		case ActionTypes.LOGIN_TOKEN_FAILURE:
		case ActionTypes.LOGOUT_FAILURE:
		case ActionTypes.FORGOTTEN_PASSWORD_SUCCESS:
		case ActionTypes.FORGOTTEN_PASSWORD_FAILURE:
		case ActionTypes.DEFINE_PASSWORD_FAILURE:
		case ActionTypes.FETCH_USER_LIST_FAILURE:
		case ActionTypes.FETCH_ALL_FOR_USER_FAILURE:
		case ActionTypes.FETCH_USER_FAILURE:
		case ActionTypes.ADD_USER_FAILURE:
		case ActionTypes.UPDATE_USER_FAILURE:
		case ActionTypes.REMOVE_USER_FAILURE:
		case ActionTypes.ARCHIVE_USER_FAILURE:
		case ActionTypes.RESTORE_USER_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.LOGIN_SUCCESS:
		case ActionTypes.LOGIN_TOKEN_SUCCESS:
		case ActionTypes.DEFINE_PASSWORD_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentUser: payload.user,
			};
		case ActionTypes.LOGOUT_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentUser: undefined,
			};
		case ActionTypes.ADD_USER_SUCCESS:
		case ActionTypes.UPDATE_USER_SUCCESS:
		case ActionTypes.REMOVE_USER_SUCCESS:
		case ActionTypes.ARCHIVE_USER_SUCCESS:
		case ActionTypes.RESTORE_USER_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_USER_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.users,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_ALL_FOR_USER_SUCCESS:
			return {
				...state,
				isLoading: false,
				allForForm: payload.allForForm,
			};
		case ActionTypes.FETCH_USER_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.user,
			};
		default:
			return state;
	}
};
