import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/buttons';
import { TextInput } from 'components/shared/inputs';

import EditorContext from '../../EditorContext';

/**
 * @name EditorHeaderViewer
 * @description The editorViewer's header, containing the title and actions buttons
 *
 * @author Romaric Barthe
 */
const EditorHeaderViewer = () => {
	const { t } = useTranslation();

	const { editTemplate, isCompanyTemplate, saveDuplicate, templateDescription, templateName } = useContext(EditorContext);

	return (
		<div className="editor-header">
			<div className="template-information">
				<TextInput
					className="seamless"
					name="name"
					placeholder={t('template.pdf.editor.header.name')}
					value={templateName ?? ''}
					readOnly
				/>
				<TextInput
					className="seamless"
					name="description"
					placeholder={t('template.pdf.editor.header.description')}
					value={templateDescription ?? ''}
					readOnly
				/>
			</div>
			<div className="actions">
				<Button onClick={saveDuplicate} className="primary">
					{t('template.pdf.editor.actions.duplicate')}
				</Button>
				{isCompanyTemplate && (
					<Button onClick={editTemplate} className="primary-outline">
						{t('template.pdf.editor.actions.update')}
					</Button>
				)}
			</div>
		</div>
	);
};

export default memo(EditorHeaderViewer);
