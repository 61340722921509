import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatProjectClientData } from 'lib/projects/formatProjectData';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * @name ProjectTitle
 * @description A component to display the project's titles.
 *
 * @author Romaric Barthe
 *
 * @param {object}	currentUser					The current user connected to the application.
 * @param {object}	project						The partner.
 * @param {string}	projectClient				The description of the activity of the partner.
 * @param {object}	projectClientInvoice		The legal representant {contact} of the partner.
 * @param {string}	projectDescription			The name of the partner.
 * @param {string}	projectName					The website of the partner.
 */
const ProjectTitle = ({
	currentUser,
	project,
	projectClient,
	projectClientInvoice,
	projectDescription,
	projectName,
}) => {
	const { t } = useTranslation();

	// To display "live" the last update (if there are some update)
	const objUpdated = useMemo(() => {
		if (project?.description !== projectDescription
			|| project?.name !== projectName
			|| formatProjectClientData(project).name !== projectClient
			|| formatProjectClientData(project).invoicing_name !== projectClientInvoice
		) {
			return { updatedBy: currentUser, updatedAt: moment() };
		}

		return project;
	}, [currentUser, project, projectClient, projectClientInvoice, projectDescription, projectName]);

	return (
		<div className="data-header">
			<div className="identity">
				<h3>{projectName}</h3>
				<h4>{projectClient}</h4>
				{projectClientInvoice !== projectClient && (
					<h5>{`${t('project.headers.invoiced_to')} ${projectClientInvoice}`}</h5>
				)}
				<p>{projectDescription}</p>
			</div>
			<div className="metadata">
				<p>{`${t('project.fields.creation_date')}: ${formatCreatedAt(project)}`}</p>
				<p>{`${t('project.fields.creation_name')}: ${formatCreatedBy(project)}`}</p>
				<p>{`${t('project.fields.update_date')}: ${formatUpdatedAt(objUpdated)}`}</p>
				<p>{`${t('project.fields.update_name')}: ${formatUpdatedBy(objUpdated)}`}</p>
			</div>
		</div>
	);
};

ProjectTitle.propTypes = {
	currentUser: PropTypes.shape({
		id: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
	}).isRequired,
	project: PropTypes.object.isRequired,
	projectClient: PropTypes.string,
	projectClientInvoice: PropTypes.string,
	projectDescription: PropTypes.string,
	projectName: PropTypes.string,
};

ProjectTitle.defaultProps = {
	projectClient: '',
	projectClientInvoice: '',
	projectDescription: '',
	projectName: '',
};

export default ProjectTitle;
