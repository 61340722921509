import _ from 'lodash';

import { ActionTypes } from '../actions/companies';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_COMPANY_LIST_REQUEST:
		case ActionTypes.ADD_COMPANY_REQUEST:
		case ActionTypes.ENABLE_COMPANY_REQUEST:
		case ActionTypes.UPDATE_COMPANY_REQUEST:
		case ActionTypes.REMOVE_COMPANY_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_COMPANY_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_COMPANY_LIST_FAILURE:
		case ActionTypes.FETCH_COMPANY_FAILURE:
		case ActionTypes.ADD_COMPANY_FAILURE:
		case ActionTypes.ENABLE_COMPANY_FAILURE:
		case ActionTypes.UPDATE_COMPANY_FAILURE:
		case ActionTypes.REMOVE_COMPANY_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_COMPANY_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.companies],
				totalCount: payload.totalCount,
			};
		case ActionTypes.ENABLE_COMPANY_SUCCESS:
			return {
				...state,
				items: _.map(state.items, (company) => (company.id === payload.company.id ? payload.company : company)),
				isLoading: false,
			};
		case ActionTypes.FETCH_COMPANY_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.company,
			};
		case ActionTypes.ADD_COMPANY_SUCCESS:
		case ActionTypes.UPDATE_COMPANY_SUCCESS:
		case ActionTypes.REMOVE_COMPANY_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		default:
			return state;
	}
};
