/* eslint-disable import/prefer-default-export */
/**
 * @function
 * @name concatUserEmails
 * @description concat email values with a "," as separator.
 *
 * @author Romaric Barthe
 *
 * @param {object}	user				The user to concat the emails from.
 * @param {object}	user.contact		The contact linked to the user.
 * @param {array} 	user.contact.emails	The emails to concat.
 *
 * @returns {array}
 */
export const concatUserEmails = (user) => user.contact.emails?.sort().join(', ');
