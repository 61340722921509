/**
 * @name TimesheetEditionPage
 * @description A page used to edit an existing timesheet.
 *
 * @author Romaric Barthe
 *
 */
const TimesheetEditionPage = () => null;

export default TimesheetEditionPage;
