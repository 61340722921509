import { Children, cloneElement, useCallback, useEffect, useState } from 'react';
import { HelpCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';

import Tab from './Tab';

import styles from 'styles/layout/_tabLayout.module.scss';

/**
 * @name TabList
 * @description A tab list component used to wrap the Tab components.
 *
 * @author Timothée Simon-Franza
 *
 * @param {Tab[]}	children				The array of tab elements to display inside this list.
 * @param {string}	[defaultSelectedTabId]	The id of the tab to select by default.
 * @param {func}	[onTabSelectionChanged] The method to fire when the tab selection changes.
 * @param {func}	[onTabClosed] 			The method to fire when the tab is closed.
 * @returns
 */
const TabList = ({ children, defaultSelectedTabId, onTabSelectionChanged, onTabClosed, haveKeyNavigation }) => {
	Children.forEach(children, (child) => {
		if (child !== null && child !== undefined && child !== false && child.type.name !== Tab.name) {
			throw new Error('TabList can only contain Tab components.');
		}
	});
	const { t } = useTranslation();

	const [selectedTab, setSelectedTab] = useState(defaultSelectedTabId ?? null);

	/**
	 * @function
	 * @name onTabClick
	 * @description Handles the click event on a tab.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @param {string} tabId The id of the tab that was clicked.
	 */
	const onTabClick = useCallback((tabId) => {
		if (selectedTab !== tabId) {
			setSelectedTab(tabId);
			onTabSelectionChanged(tabId);
		}
	}, [onTabSelectionChanged, selectedTab]);

	// Used to update the current tab visual cue when the user triggers a tab change from outside
	// this component (eg: from a data-table).
	useEffect(() => {
		onTabClick(defaultSelectedTabId);
	}, [defaultSelectedTabId, onTabClick]);

	return (
		<div role="tablist" className={styles['tab-list']} style={{ display: 'flex' }}>
			{Children.map(children, (child) => cloneElement(child, {
				...child.props,
				onClick: () => onTabClick(child.props.tabId),
				selected: selectedTab === child.props.tabId,
				onClose: () => onTabClosed(child.props.tabId),
			}))}
			{haveKeyNavigation && (
			<div style={{ margin: 'auto 10px' }}>
				<ReactTooltip id="help" place="top" type="dark" effect="solid">
					<ReactMarkdown>
						{t('targeting.target.target_partner.details.help')}
					</ReactMarkdown>
				</ReactTooltip>
				<HelpCircle style={{ color: 'var(--clr-primary-500)', width: '15px' }} data-tooltip-id="help" />
			</div>
			)}
		</div>
	);
};

TabList.propTypes = {
	children: PropTypes.arrayOf(Tab).isRequired,
	defaultSelectedTabId: PropTypes.string,
	onTabSelectionChanged: PropTypes.func,
	onTabClosed: PropTypes.func,
	haveKeyNavigation: PropTypes.bool,
};

TabList.defaultProps = {
	onTabSelectionChanged: () => {},
	onTabClosed: () => {},
	defaultSelectedTabId: undefined,
	haveKeyNavigation: false,
};

export default TabList;
