import findPositionById from './internals/findPositionById';
import moveElement from './moveElement';

/**
 * @function
 * @name moveElementById
 * @description Moves an element to the provided destination index, and selects the moved element
 *
 * @author Yann Hodiesne
 *
 * @param {object}	state						The current state
 * @param {string}	payload.sourceId			The identifier of the element to move
 * @param {number}	payload.destinationRowIndex	The destination row index
 * @param {number}	payload.destinationIndex	The destination index inside of the provided destination row
 *
 * @returns {object} The updated state value
 */
const moveElementById = (state, { sourceId, destinationRowIndex, destinationIndex }) => {
	const { elements } = state;

	if (
		// If the destination is the same element as the source
		sourceId === elements[destinationRowIndex]?.children[destinationIndex]?.id
		|| ( // Or the destination is the end of the row and the last item is the samae element as the source
			destinationIndex >= elements[destinationRowIndex]?.children.length
			&& sourceId === elements[destinationRowIndex]?.children[destinationIndex - 1]?.id
		)
	) {
		// Do nothing, avoid a useless re-render
		return state;
	}

	const sourcePosition = findPositionById(elements, sourceId);

	if (sourcePosition === null) {
		return state;
	}

	return moveElement(state, {
		sourceRowIndex: sourcePosition.rowIndex,
		sourceIndex: sourcePosition.elementIndex,
		destinationRowIndex,
		destinationIndex,
	});
};

export default moveElementById;
