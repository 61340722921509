import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useNumberFormatsLoadingSelector } from 'redux/selectors/numberFormats';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Checkbox, NumberInput, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import { useUnusedEnvironmentTypes } from './function';

/**
 * @name NumberFormatEditionForm
 * @description A form used to update an existing number format.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 * @author Matthieu Schaerlinger
 *
 * @param {object}	numberFormat	The number format object to edit information from.
 * @param {func}	onSubmit	    The method to trigger upon form submission.
 */
const NumberFormatEditionForm = ({ onSubmit, numberFormat }) => {
	const { t } = useTranslation();

	const defaultValues = useMemo(() => ({
		...numberFormat,
	}), [numberFormat]);

	const { formProps, buttonProps } = useSubmitButton();

	const getNotUsedEnvironments = useUnusedEnvironmentTypes(numberFormat);
	const areNumberFormatsLoading = useNumberFormatsLoadingSelector();

	const canEditNumberFormat = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);

	return (
		<DynamicForm
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			disabled={!canEditNumberFormat}
			{...formProps}
		>
			<Select
				isLoading={areNumberFormatsLoading}
				label={t('number_format.edition.inputs.environment.label')}
				labelKey="label"
				name="environment"
				options={getNotUsedEnvironments}
				placeholder={t('number_format.edition.inputs.environment.placeholder')}
				rules={{
					required: Validators.isRequired(t('number_format.edition.inputs.environment.validation_errors.required')),
				}}
				valueKey="value"
			/>
			<TextInput
				label={t('number_format.edition.inputs.root_name.label')}
				name="rootName"
				rules={{
					required: Validators.isRequired(t('number_format.edition.inputs.root_name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextInput
				label={t('number_format.edition.inputs.starting_number.label')}
				name="startingNumber"
				rules={{
					required: Validators.isRequired(t('number_format.edition.inputs.starting_number.validation_errors.required')),
				}}
				step="1"
				type="number"
			/>
			<NumberInput
				label={t('number_format.edition.inputs.digit_number.label')}
				name="digitNumber"
				min={0}
				max={20}
			/>
			<Checkbox
				key="yearPrefix"
				name="yearPrefix"
				label={t('number_format.edition.inputs.year_prefix.label')}
			/>
			<Checkbox
				key="monthPrefix"
				name="monthPrefix"
				label={t('number_format.edition.inputs.month_prefix.label')}
			/>
			<Button className="primary" type="submit" {...buttonProps}>
				{canEditNumberFormat ? t('number_format.edition.action') : t('number_format.edition.close')}
			</Button>
		</DynamicForm>
	);
};

NumberFormatEditionForm.propTypes = {
	numberFormat: PropTypes.shape({
		digitNumber: PropTypes.number,
		environment: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		monthPrefix: PropTypes.bool,
		rootName: PropTypes.string.isRequired,
		startingNumber: PropTypes.number.isRequired,
		yearPrefix: PropTypes.bool,
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default NumberFormatEditionForm;
