import update from 'immutability-helper';

/**
 * @function
 * @name updateDescription
 * @description Updates template's description with provided value
 *
 * @author Florian Fornazaric
 *
 * @param {object} state					The current state
 * @param {string} payload.description		The new template's description
 *
 * @returns {object} The updated state value
 */
const updateDescription = (state, { description }) => {
	const result = update(state, {
		templateDescription: {
			$set: description,
		},
		isDirty: {
			$set: true,
		},
	});

	return result;
};

export default updateDescription;
