import update from 'immutability-helper';

/**
 * @function
 * @name updatePageNumberingStartIndex
 * @description Updates template's page numbering start index with provided value
 *
 * @author Florian Fornazaric
 *
 * @param {object} state			The current state
 * @param {string} payload.index	The new page numbering start index
 *
 * @returns {object} The updated state value
 */
const updatePageNumberingStartIndex = (state, { index }) => {
	const result = update(state, {
		pageNumberingStartIndex: {
			$set: index,
		},
	});

	return result;
};

export default updatePageNumberingStartIndex;
