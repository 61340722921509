/**
 * @function
 * @name createElementType
 * @description Helper to create elements types on the fly
 *
 * @author Yann Hodiesne
 *
 * @param {string}		type					The element type identifier
 * @param {function}	component				The component to instantiate when an element is created using this type
 * @param {string}		name					The name of the element type
 * @param {string}		description				The description of the element type
 * @param {object}		icon					The icon component of the element type
 * @param {array}		basicCapabilities		The element basic capabilities, defining its properties and user settings
 * @param {array}		advancedCapabilities	The element advanced capabilities, defining its properties and user settings
 * @param {object}		[defaultContent]		The default children to provide to the element, defaults to undefined
 * @param {object}		[defaultSize]			The default size of the element, defaults to { x: '150px', y: '150px' }
 * @param {boolean}		[inline]				The ability of this element type to exist inside a row, defaults to true
 * @param {object}		[others]				The other properties to store inside an element of this type
 *
 * @returns {object} A new element type according to provided params
 */
const createElementType = (
	type,
	component,
	name,
	description,
	icon,
	basicCapabilities,
	advancedCapabilities,
	defaultContent = undefined,
	defaultSize = { x: '150px', y: '150px' },
	inline = true,
	others = {}
) => Object.freeze({
	type,
	// Everything stored under meta will not be passed down to the instantiated element
	meta: Object.freeze({
		component,
		name,
		description,
		icon,
		defaultContent,
		inline,
		defaultSize,
		capabilities: Object.freeze({
			basic: basicCapabilities,
			advanced: advancedCapabilities,
		}),
	}),
	...others,
});

export default createElementType;
