import * as ContactsApi from 'api/contactsApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the ContactsWithMailsUser redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a list of contacts
	FETCH_CONTACT_SELECT_WITH_MAIL_USER_LIST_REQUEST: '@CONTACTS_SELECT_WITH_MAIL_USER/FETCH_LIST_REQUEST',
	FETCH_CONTACT_SELECT_WITH_MAIL_USER_LIST_SUCCESS: '@CONTACTS_SELECT_WITH_MAIL_USER/FETCH_LIST_SUCCESS',
	FETCH_CONTACT_SELECT_WITH_MAIL_USER_LIST_FAILURE: '@CONTACTS_SELECT_WITH_MAIL_USER/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Contact list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchContactsSelectWithMailsUserListRequest
 * @description Action triggered anytime a contactsSelectWithMailsUser list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchContactsSelectWithMailsUserListRequest = () => ({ type: ActionTypes.FETCH_CONTACT_SELECT_WITH_MAIL_USER_LIST_REQUEST });

/**
 * @function
 * @name fetchContactsSelectWithMailsUserListSuccess
 * @description Action triggered as a result to a successful contactsSelectWithMailsUser list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} contactsSelectWithMailsUser		The list of retrieved contacts.
 * @param {number} totalCount						The total amount of contacts available in the database for the current user.
 *
 * @returns {object}
 */
const fetchContactsSelectWithMailsUserListSuccess = ({ contactsSelectWithMailsUser, totalCount }) => ({
	type: ActionTypes.FETCH_CONTACT_SELECT_WITH_MAIL_USER_LIST_SUCCESS,
	payload: { contactsSelectWithMailsUser, totalCount },
});

/**
 * @function
 * @name fetchContactsSelectWithMailsUserListFailure
 * @description Action triggered as a result to a failed contactsSelectWithMailsUser list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchContactsSelectWithMailsUserListFailure = (error) => ({
	type: ActionTypes.FETCH_CONTACT_SELECT_WITH_MAIL_USER_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchContactsSelectWithMailsUserList
 * @description Method used to fetch the contactsSelectWithMailsUser list.
 *
 * @author Romaric Barthe
 * @author Yann Hodiesne
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchContactsSelectWithMailsUserList = (params) => (dispatch) => {
	dispatch(fetchContactsSelectWithMailsUserListRequest());

	const newFilters = {
		...params,
		infoLevel: 'forSelectWithMailsUser',
	};

	return ContactsApi.fetchContacts(newFilters)
		.then(({ contactsSelectWithMailsUser, totalCount }) => dispatch(fetchContactsSelectWithMailsUserListSuccess({ contactsSelectWithMailsUser, totalCount })))
		.catch((error) => dispatch(fetchContactsSelectWithMailsUserListFailure(error)));
};
