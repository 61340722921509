import { toast } from 'react-toastify';
import * as ContactsApi from 'api/contactsApi';
import download from 'downloadjs';
import i18next from 'i18next';
import { formatContactName } from 'lib/contacts/formatContactData';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Contacts redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific contact
	FETCH_CONTACT_REQUEST: '@CONTACTS/FETCH_REQUEST',
	FETCH_CONTACT_SUCCESS: '@CONTACTS/FETCH_SUCCESS',
	FETCH_CONTACT_FAILURE: '@CONTACTS/FETCH_FAILURE',

	// Fetch all for contacts
	FETCH_ALL_FOR_CONTACT_REQUEST: '@CONTACTS/FETCH_ALL_FOR_REQUEST',
	FETCH_ALL_FOR_CONTACT_SUCCESS: '@CONTACTS/FETCH_ALL_FOR_SUCCESS',
	FETCH_ALL_FOR_CONTACT_FAILURE: '@CONTACTS/FETCH_ALL_FOR_FAILURE',

	// Fetch a list of contacts
	FETCH_CONTACT_LIST_REQUEST: '@CONTACTS/FETCH_LIST_REQUEST',
	FETCH_CONTACT_LIST_SUCCESS: '@CONTACTS/FETCH_LIST_SUCCESS',
	FETCH_CONTACT_LIST_FAILURE: '@CONTACTS/FETCH_LIST_FAILURE',

	// Add a new contact to the database
	ADD_CONTACT_REQUEST: '@CONTACTS/ADD_REQUEST',
	ADD_CONTACT_SUCCESS: '@CONTACTS/ADD_SUCCESS',
	ADD_CONTACT_FAILURE: '@CONTACTS/ADD_FAILURE',

	// Update an existing contact from the database
	UPDATE_CONTACT_REQUEST: '@CONTACTS/UPDATE_REQUEST',
	UPDATE_CONTACT_SUCCESS: '@CONTACTS/UPDATE_SUCCESS',
	UPDATE_CONTACT_FAILURE: '@CONTACTS/UPDATE_FAILURE',

	// Remove an existing contact from the database
	REMOVE_CONTACT_REQUEST: '@CONTACTS/REMOVE_REQUEST',
	REMOVE_CONTACT_SUCCESS: '@CONTACTS/REMOVE_SUCCESS',
	REMOVE_CONTACT_FAILURE: '@CONTACTS/REMOVE_FAILURE',

	// Export contacts as a file
	EXPORT_CONTACT_REQUEST: '@CONTACTS/EXPORT_REQUEST',
	EXPORT_CONTACT_SUCCESS: '@CONTACTS/EXPORT_SUCCESS',
	EXPORT_CONTACT_FAILURE: '@CONTACTS/EXPORT_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Contact list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchContactListRequest
 * @description Action triggered anytime a contact list fetching call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const fetchContactListRequest = () => ({ type: ActionTypes.FETCH_CONTACT_LIST_REQUEST });

/**
 * @function
 * @name fetchContactListSuccess
 * @description Action triggered as a result to a successful contact list fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contacts		The list of retrieved contacts.
 * @param {number} totalCount	The total amount of contacts available in the database for the current user.
 *
 * @returns {object}
 */
const fetchContactListSuccess = ({ contacts, totalCount }) => ({
	type: ActionTypes.FETCH_CONTACT_LIST_SUCCESS,
	payload: { contacts, totalCount },
});

/**
 * @function
 * @name fetchContactListFailure
 * @description Action triggered as a result to a failed contact list fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchContactListFailure = (error) => ({
	type: ActionTypes.FETCH_CONTACT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Fetching all for contacts actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAllForContactRequest
 * @description Action triggered anytime an contact fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchAllForContactRequest = () => ({ type: ActionTypes.FETCH_ALL_FOR_CONTACT_REQUEST });

/**
 * @function
 * @name fetchAllForContactSuccess
 * @description Action triggered as a result to a successful contact fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} allForForm The retrieved contact.
 *
 * @returns {object}
 */
const fetchAllForContactSuccess = ({ allForForm }) => ({
	type: ActionTypes.FETCH_ALL_FOR_CONTACT_SUCCESS,
	payload: { allForForm },
});

/**
 * @function
 * @name fetchAllForContactFailure
 * @description Action triggered as a result to a failed contact fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAllForContactFailure = (error) => ({
	type: ActionTypes.FETCH_ALL_FOR_CONTACT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific contact fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchContactRequest
 * @description Action triggered anytime a contact fetching call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const fetchContactRequest = () => ({ type: ActionTypes.FETCH_CONTACT_REQUEST });

/**
 * @function
 * @name fetchContactSuccess
 * @description Action triggered as a result to a successful contact fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contact The retrieved contact.
 *
 * @returns {object}
 */
const fetchContactSuccess = ({ contact }) => ({
	type: ActionTypes.FETCH_CONTACT_SUCCESS,
	payload: { contact },
});

/**
 * @function
 * @name fetchContactFailure
 * @description Action triggered as a result to a failed contact fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchContactFailure = (error) => ({
	type: ActionTypes.FETCH_CONTACT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Contact creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addContactRequest
 * @description Action triggered anytime a contact creation call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const addContactRequest = () => ({ type: ActionTypes.ADD_CONTACT_REQUEST });

/**
 * @function
 * @name addContactSuccess
 * @description Action triggered as a result to a successful contact creation API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contact The created contact object.
 *
 * @returns {object}
 */
const addContactSuccess = ({ contact }) => ({
	type: ActionTypes.ADD_CONTACT_SUCCESS,
	payload: { contact },
});

/**
 * @function
 * @name addContactFailure
 * @description Action triggered as a result to a failed contact creation API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const addContactFailure = (error) => ({
	type: ActionTypes.ADD_CONTACT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Contact update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateContactRequest
 * @description Action triggered anytime a contact update call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const updateContactRequest = () => ({ type: ActionTypes.UPDATE_CONTACT_REQUEST });

/**
 * @function
 * @name updateContactSuccess
 * @description Action triggered as a result to a successful contact update API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contact The updated contact object.
 *
 * @returns {object}
 */
const updateContactSuccess = ({ contact }) => ({
	type: ActionTypes.UPDATE_CONTACT_SUCCESS,
	payload: { contact },
});

/**
 * @function
 * @name updateContactFailure
 * @description Action triggered as a result to a failed contact update API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const updateContactFailure = (error) => ({
	type: ActionTypes.UPDATE_CONTACT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Contact removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeContactRequest
 * @description Action triggered anytime a contact deletion call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const removeContactRequest = () => ({ type: ActionTypes.REMOVE_CONTACT_REQUEST });

/**
 * @function
 * @name removeContactSuccess
 * @description Action triggered as a result to a successful contact deletion API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contact The removed contact object.
 *
 * @returns {object}
 */
const removeContactSuccess = ({ deletedContactId }) => ({
	type: ActionTypes.REMOVE_CONTACT_SUCCESS,
	payload: { deletedContactId },
});

/**
 * @function
 * @name removeContactFailure
 * @description Action triggered as a result to a failed contact deletion API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const removeContactFailure = (error) => ({
	type: ActionTypes.REMOVE_CONTACT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Contact export actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name exportContactRequest
 * @description Action triggered anytime a contact exporting call is made to the API.
 *
 * @author Roland Margelidon
 *
 * @returns {object}
 */
const exportContactRequest = () => ({ type: ActionTypes.EXPORT_CONTACT_REQUEST });

/**
  * @function
  * @name exportContactSuccess
  * @description Action triggered as a result to a successful contact exporting API call.
  *
  * @author Roland Margelidon
  *
  * @returns {object}
  */
const exportContactSuccess = () => ({ type: ActionTypes.EXPORT_CONTACT_SUCCESS });

/**
  * @function
  * @name exportContactFailure
  * @description Action triggered as a result to a failed contact exporting API call.
  *
  * @author Roland Margelidon
  *
  * @param {object} error	The exception sent back from the API.
  *
  * @returns {object}
  */
const exportContactFailure = (error) => ({
	type: ActionTypes.EXPORT_CONTACT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchContactList
 * @description Method used to update the contact list.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchContactList = (params) => (dispatch) => {
	dispatch(fetchContactListRequest());

	return ContactsApi.fetchContacts(params)
		.then(({ contacts, totalCount }) => dispatch(fetchContactListSuccess({ contacts, totalCount })))
		.catch((error) => dispatch(fetchContactListFailure(error)));
};

/**
 * @function
 * @name fetchContact
 * @description Method used to fetch the latest version of a specific contact.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} contactId	The id of the contact we want to retrieve.
 */
export const fetchContact = (contactId) => (dispatch) => {
	dispatch(fetchContactRequest());

	return ContactsApi.fetchContactById(contactId)
		.then(({ contact }) => dispatch(fetchContactSuccess({ contact })))
		.catch((error) => dispatch(fetchContactFailure(error)));
};

/**
 * @function
 * @name fetchAllForContact
 * @description Method used to load the contact "all for" information.
 *
 * @author Romaric Barthe
 *
 * @param {bool|null} inSuperAdmin	Wether we are in superadmin environment.
 */
export const fetchAllForContact = (inSuperAdmin) => (dispatch) => {
	dispatch(fetchAllForContactRequest());

	return ContactsApi.fetchAllForContactForm({ inSuperAdmin })
		.then((allForForm) => dispatch(fetchAllForContactSuccess({ allForForm })))
		.catch((error) => dispatch(fetchAllForContactFailure(error)));
};

/**
 * @function
 * @name addContact
 * @description Method used to add a new contact instance to the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contactData		The data to create the new contact from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addContact = (contactData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addContactRequest());

	return ContactsApi.createContact(contactData)
		.then(({ contact }) => {
			toast.success(i18next.t('contact.creation.toasts.success', { name: formatContactName(contact) }));
			dispatch(addContactSuccess({ contact }));
			redirectOnSuccess(onSuccessRoute);

			return contact;
		})
		.catch((error) => {
			toast.error(i18next.t('contact.creation.toasts.error'));
			dispatch(addContactFailure(error));
		});
};

/**
 * @function
 * @name updateContact
 * @description Method used to update an existing contact instance from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contactData		The object to update the contact with.
 * @param {string} contactId		The id of the contact we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateContact = (contactData, contactId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateContactRequest());

	return ContactsApi.updateContact(contactData, contactId)
		.then(({ contact }) => {
			dispatch(updateContactSuccess({ contact }));
			toast.success(i18next.t('contact.edition.toasts.success', { name: formatContactName(contact) }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch((error) => {
			toast.error(i18next.t('contact.edition.toasts.error'));
			dispatch(updateContactFailure(error));
		});
};

/**
 * @function
 * @name removeContact
 * @description Method used to remove an existing contact instance from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contact				The contact we want to remove from the database.
 * @param {string} contact.id			The id of the contact we want to remove from the database.
 * @param {string} contact.firstName	The first name of the contact we want to remove from the database.
 * @param {string} contact.lastName		The last name of the contact we want to remove from the database.
*/
export const removeContact = ({ id, firstName, lastName }) => (dispatch) => {
	dispatch(removeContactRequest());

	return ContactsApi.deleteContact(id)
		.then(({ deletedContactId }) => {
			dispatch(removeContactSuccess({ deletedContactId }));
			toast.success(i18next.t('contact.deletion.toasts.success', { name: formatContactName({ firstName, lastName }) }));
		})
		.catch((error) => {
			dispatch(removeContactFailure(error));
			toast.error(i18next.t('contact.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name exportContacts
 * @description Method used to export multiple contact instances from the database.
 *
 * @author Roland Margelidon
 *
 * @param {Object}	data				The data to export the contacts.
 * @param {Array}	data.contactIds		The ids of the contacts to export.
 * @param {string}	data.format			The file format.
 * @param {string}	data.restriction	The scope of the data to export (all, filtered or current selection).
 * @param {string}	data.separator		The separator used in the CSV file.
 *
 * @todo Adapt params when backend will change
 */
export const exportContacts = (data) => (dispatch) => {
	dispatch(exportContactRequest());

	// TODO: adapt these parameters along with backend changes
	return ContactsApi.exportContacts({
		ids: data.contactIds ?? [],
		format: data.format ?? 'xlsx',
		restriction: data.restriction ?? 'all',
		separator: data.separator ?? 'comma',
		...data,
	})
		.then(({ blob, fileName, fileType }) => {
			dispatch(exportContactSuccess());
			toast.success(i18next.t('template.export.toasts.success'));

			download(blob, fileName, fileType);
		})
		.catch((error) => {
			dispatch(exportContactFailure(error));
			toast.error(i18next.t('template.export.toasts.error'));
		});
};
