import { useCallback } from 'react';
import { Edit2, Eye, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { removeExpense } from 'redux/actions/expenses/expenses';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the ExpenseListPage table. (delete, edit and view exports for expense)
 *
 * @author Romaric Barthe
 *
 * @param {object}		row								The object containing the data to interact with.
 * @param {boolean}		row.original.archived			The boolean flagging whether the expense is archived or not.
 * @param {number}		row.original.editionNumber		The edition number of the expense.
 * @param {string}		row.original.id					The id of the expense.
 * @param {function}	fetchData						The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { archived, editionNumber, id } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditExpense = useAccessRight(AccessRights.humanResources.expenses.enhancedRights.CREATE_EXPENSE);
	const canDeleteExpense = useAccessRight(AccessRights.humanResources.expenses.enhancedRights.DELETE_EXPENSE);

	const [viewButtonRef, viewTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Romaric Barthe
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeExpense({ id }));
		fetchData();
	}, [dispatch, fetchData, id, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onEditButtonClick = useCallback(() => {
		history.push(generatePath(routes.humanResources.expenses.expenseEdition, { id }));
	}, [id]);

	/**
	 * @function
	 * @name onVisualiseButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onVisualiseButtonClick = useCallback(() => {
		history.push(generatePath(routes.humanResources.expenses.expenseVisualisation, { id }));
	}, [id]);

	return (
		<>
			{canEditExpense && !archived && !editionNumber && (
				<>
					<Button className="icon-only" onClick={onEditButtonClick} ref={editButtonRef}>
						<Edit2 className="primary" />
					</Button>
					<Tooltip {...editTooltipProps}>
						{t('actions.edit')}
					</Tooltip>
				</>
			)}
			<Button className="icon-only" onClick={onVisualiseButtonClick} ref={viewButtonRef}>
				<Eye />
			</Button>
			<Tooltip {...viewTooltipProps}>
				{t('actions.visualisation')}
			</Tooltip>
			{canDeleteExpense && !archived && !editionNumber && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('expense.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('expense.deletion.confirmation_modal.confirm')}
						cancelText={t('expense.deletion.confirmation_modal.cancel')}
						titleText={t('expense.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			archived: PropTypes.bool,
			editionNumber: PropTypes.number,
			id: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
