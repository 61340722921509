import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessRights } from 'lib/shared/accessRights';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useAccessRightsListSelector } from 'redux/selectors/accessRights';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { Module } from './accessDefinitionComponents';

/**
 * @name AccessDefinitionCreationForm
 * @description A form used to create a new access definition.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const AccessDefinitionCreationForm = ({ onSubmit }) => {
	const { t } = useTranslation();

	const userAccessRights = useAccessRightsListSelector();
	const modules = Object.keys(AccessRights);

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const handleSubmit = useCallback((formData) => {
		const { name, ...accessRights } = formData;

		const params = {
			name,
			// map rights for backend keeping only keys when right is checked
			accessRights: _.keys(_.pickBy(accessRights)),
		};

		onSubmit(params);
	}, [onSubmit]);

	return (
		<DynamicForm
			ref={formRef}
			onSubmit={handleSubmit}
			{...formProps}
		>
			<TextInput
				label={t('access_definition.creation.inputs.name.label')}
				name="name"
				placeholder={t('access_definition.creation.inputs.name.placeholder')}
				rules={{
					required: Validators.isRequired(t('access_definition.creation.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>

			{modules.map((module) => (
				<Module
					key={module}
					module={module}
					userAccessRights={userAccessRights}
				/>
			))}

			<Button className="primary" type="submit" {...buttonProps}>{t('access_definition.creation.action')}</Button>
		</DynamicForm>
	);
};

AccessDefinitionCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default AccessDefinitionCreationForm;
