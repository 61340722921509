import { useTranslation } from 'react-i18next';
import { formatRegistrationName } from 'lib/registrations/formatRegistrationData';
import PropTypes from 'prop-types';

/**
 * @name RegistrationsCell
 * @description Custom cell for the partnerListPage table displaying a partner's registration.
 *
 * @author Romaric Barthe
 */
const RegistrationsCell = ({ row: { original: { registrations } } }) => {
	const { t } = useTranslation();

	return (
		<ul className="tag-list">
			{registrations.map((registration) => (
				<li key={registration.id}>
					<span className="tag">
						{formatRegistrationName(registration, t)}
					</span>
				</li>
			))}
		</ul>
	);
};

RegistrationsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			registrations: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				type: PropTypes.shape({
					id: PropTypes.string,
					name: PropTypes.string,
				}),
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default RegistrationsCell;
