// Usage : hero, page titles
const canon = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '3rem',
	fontWeight: 300,
	letterSpacing: 0,
	lineHeight: '4em',
};

// Usage : section headers
const trafalgar = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '2rem',
	fontWeight: 300,
	letterSpacing: 0,
	lineHeight: '2.5em',
};

// Usage : primary headlines
const paragon = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '1.778rem',
	fontWeight: 400,
	letterSpacing: 0,
	lineHeight: '2.25em',
};

// Usage : sub headers
const doublePica = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '1.444rem',
	fontWeight: 400,
	letterSpacing: 0,
	lineHeight: '2em',
};

// Usage : headline titles or subtitles
const greatPrimer = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '1.222rem',
	fontWeight: 400,
	letterSpacing: 0,
	lineHeight: '1.635em',
};

// Usage : body copy (duh)
const bodyCopy = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '1rem',
	fontWeight: 400,
	letterSpacing: 0,
	lineHeight: '1.5em',
};

// Usage : index links, titles and headlines
const pica = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '1rem',
	fontWeight: 400,
	letterSpacing: 0.16,
	lineHeight: '1.5em',
};

// Usage : secondary body copy, image captions, inline links
const longPrimer = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '.889rem',
	fontWeight: 400,
	letterSpacing: 0,
	lineHeight: '1.375em',
};

// Usage: time stamps, bylines, input labels, input helper texts
const brevier = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '.778rem',
	fontWeight: 400,
	letterSpacing: 0.32,
	lineHeight: '1.125em',
};

// Usage: small header capitals, tags, badges
const minion = {
	fontFamily: 'IBM Plex Sans, Helvetica Neue, Arial, sans-serif',
	fontSize: '.778rem',
	fontWeight: 400,
	letterSpacing: 0.32,
	lineHeight: '1.125em',
	textTransform: 'uppercase',
};

export {
	bodyCopy,
	brevier,
	canon,
	doublePica,
	greatPrimer,
	longPrimer,
	minion,
	paragon,
	pica,
	trafalgar,
};
