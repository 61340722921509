import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentCompanySelector
 * @description A selector to retrieve the current company from the companies redux state
 *
 * @author Yann Hodiesne
 *
 * @param {string} id The identifier of the current company to wait for
 *
 * @returns {object}
 */
const useCurrentCompanySelector = (id) => {
	const company = useSelector((state) => (state.companies?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (company?.id === id ? company : null), [id, company]);

	return result;
};

/**
 * @function
 * @name useCompaniesLoadingSelector
 * @description A selector to retrieve the loading state from the companies redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {boolean}
 */
const useCompaniesLoadingSelector = () => {
	const loading = useSelector((state) => state.companies?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useCompanyListSelector
 * @description A selector to retrieve the company list from the companies redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {Array}
 */
const useCompanyListSelector = () => {
	const companies = useSelector((state) => (state.companies?.items ?? []), shallowEqual);

	return companies;
};

/**
 * @function
 * @name useCompanyTotalCountSelector
 * @description A selector to retrieve the totalCount value from the companies redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {number|undefined}
 */
const useCompanyTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.companies?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useCompaniesLoadingSelector,
	useCompanyListSelector,
	useCompanyTotalCountSelector,
	useCurrentCompanySelector,
};
