/**
 * @enum {string}
 * @readonly
 *
 * @name PartnerTabs
 * @description The differents tabs used in the form
 *
 * @author Romaric Barthe
 */
const PartnerTabs = Object.freeze({
	IDENTITY: 'identity',
	ADDRESS: 'address',
	PHONE_MAIL: 'phone_mail',
	CONTACTS: 'linked_contacts',
	REGISTRATION: 'registration',
	KPI: 'kpi',
});

/**
 * @enum {string}
 * @readonly
 *
 * @name phoneEnum
 * @description The differents types of phones numbers
 * @author Audrey Clerc
 */
const PhoneTypes = {
	MOBILE: 'mobile',
	PHONE: 'phone',
	FAX: 'fax',
	OFFICE: 'office',
	OTHER: 'other',
};

export {
	PartnerTabs,
	PhoneTypes,
};
