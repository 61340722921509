import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { addContact, fetchContact, updateContact } from 'redux/actions/contacts/contacts';
import { fetchContactsProfileDisplayList } from 'redux/actions/contacts/contactsProfileDisplay';
import { addHistory, fetchHistory, fetchHistoryList, updateHistory } from 'redux/actions/histories/histories';
import { fetchPartner, updatePartner } from 'redux/actions/partners/partners';
import { fetchTargetingStats } from 'redux/actions/targetings/targetings';
import { fetchTargetPartner } from 'redux/actions/targetPartners/targetPartners';
import { fetchTargetPartnersForList } from 'redux/actions/targetPartners/targetPartnersList';
import { fetchTargetStats } from 'redux/actions/targets/targets';
import { useCurrentContactSelector } from 'redux/selectors/contacts/contacts';
import { useContactsProfileDisplayListSelector } from 'redux/selectors/contacts/contactsProfileDisplay';
import { useCurrentHistorySelector, useHistoryListForTargetPartnerSelector, useHistoryLoadingSelector } from 'redux/selectors/histories/histories';
import { useCurrentPartnerSelector } from 'redux/selectors/partners/partners';
import { useCurrentTargetPartnerSelector, useTargetPartnerLoadingSelector } from 'redux/selectors/targetPartners/targetPartners';

import { ContactCreationForm, ContactEditionForm } from 'components/contacts';
import { HistoryCreationForm, HistoryCreationStatusForm, HistoryEditionForm } from 'components/histories';
import { PartnerEditionForm } from 'components/partners';
import { Modal, useModal } from 'components/shared/modal';

import TargetPartnerContactList from './targetPartnerContactList/TargetPartnerContactList';
import TargetPartnerHistoryList from './targetPartnerHistoryList/TargetPartnerHistoryList';
import TargetPartnerStatusEvolution from './targetPartnerStatusEvolution/TargetPartnerStatusEvolution';
import TargetPartnerSummary from './TargetPartnerSummary';

/**
 * @name TargetPartnerDetails
 * @description A UI component used to display the details of a target partner.
 * Used in the TargetDetailsPage compenent's tabPanels.
 *
 * @author Timothée Simon-Franza
 *
 * @param {bool} [shouldRefreshData = false]	Flag used to trigger a data refresh.
 * @param {Object} targetPartnerId				The id of the target partner to display the details of.
 */
const TargetPartnerDetails = ({ shouldRefreshData, targetPartnerId, targetingId }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const targetPartner = useCurrentTargetPartnerSelector(targetPartnerId);
	const targetId = targetPartner ? targetPartner.targetId : null;

	// Without the use of a state for the data fetch pending information, the skeleton version of
	// the components would get displayed every time a creation or edition modal gets opened and
	// closed, as the forms they contain would trigger the isLoading selector update by fetching data.
	const [isDataFetchPending, setIsDataFetchPending] = useState(true);

	const isTargetPartnerFetchPending = useTargetPartnerLoadingSelector();
	const isHistoryFetchPending = useHistoryLoadingSelector();

	const partner = useCurrentPartnerSelector(targetPartner?.partner.id);
	const allContacts = useContactsProfileDisplayListSelector();
	const histories = useHistoryListForTargetPartnerSelector(targetPartner?.id);
	const contacts = useMemo(() => allContacts.filter((contact) => contact.partners.find(({ id }) => id === partner?.id)), [allContacts, partner?.id]);

	const [currentlyEditedHistoryId, setCurrentlyEditedHistoryId] = useState(null);
	const currentlyEditedHistory = useCurrentHistorySelector(currentlyEditedHistoryId);

	const [currentlyEditedContactId, setCurrentlyEditedContactId] = useState(null);
	const currentlyEditedContact = useCurrentContactSelector(currentlyEditedContactId);

	const statusEvolutionList = useMemo(() => histories
		.filter(({ statusObject }) => statusObject)
		.sort((historyA, historyB) => historyA.date > historyB.date)
		.map(({ date, statusObject }) => ({ date, ...statusObject })), [histories]);

	const { isShowing: isHistoryCreationModalShowing, toggle: toggleHistoryCreationModal } = useModal();
	const { isShowing: isHistoryEditionModalShowing, toggle: toggleHistoryEditionModal } = useModal();
	const { isShowing: isPartnerEditionModalShowing, toggle: togglePartnerEditionModal } = useModal();
	const { isShowing: isContactCreationModalShowing, toggle: toggleContactCreationModal } = useModal();
	const { isShowing: isContactEditionModalShowing, toggle: toggleContactEditionModal } = useModal();
	const { isShowing: isStatusCreationModalShowing, toggle: toggleStatusCreationModal } = useModal();

	/**
	 * @function
	 * @name onHistoryCreationFormSubmit
	 * @description Callback function to call when the history creation form is submitted.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onHistoryCreationFormSubmit = useCallback(async (formData) => {
		toggleHistoryCreationModal();

		await dispatch(addHistory(formData));
		dispatch(fetchHistoryList(
			{
				rowsPerPage: 0,
				filters: {
					conditions: [
						{ column: 'targetPartnersId', criterion: 'is', value: targetPartner?.id },
						{ column: 'partnersId', criterion: 'is', value: targetPartner?.partner?.id },
					],
				},
			}
		));
		dispatch(fetchTargetPartnersForList({
			rowsPerPage: 0,
			filters: {
				conditions: [{ column: 'targetId', criterion: 'is', value: targetId }],
			},
		}));
	}, [dispatch, targetPartner?.id, targetPartner?.partner?.id, targetId, toggleHistoryCreationModal]);

	/**
	 * @function
	 * @name onPartnerEditionFormSubmit
	 * @description Callback function to call when the partner edition form is submitted.
	 */
	const onPartnerEditionFormSubmit = useCallback(async (formData) => {
		togglePartnerEditionModal();

		await dispatch(updatePartner(formData, formData.id));
		dispatch(fetchPartner(targetPartner?.partner?.id));

		if (formData.contacts.length !== partner?.contacts.length) {
			dispatch(fetchContactsProfileDisplayList({
				rowsPerPage: 0,
				filters: {
					conditions: [{ column: 'partnersId', criterion: 'is', value: targetPartner?.partner?.id }],
				},
			}));
		}
	}, [dispatch, partner?.contacts.length, targetPartner?.partner?.id, togglePartnerEditionModal]);

	/**
	 * @function
	 * @name onHistoryEditionButtonClick
	 * @description Callback function to call when the history edition button is clicked.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {number} historyId The id of the history to edit.
	 */
	const onHistoryEditionButtonClick = useCallback(async (historyId) => {
		try {
			setCurrentlyEditedHistoryId(historyId);
			dispatch(fetchHistory(historyId));
			toggleHistoryEditionModal();
		} catch {
			if (isHistoryEditionModalShowing) {
				toggleHistoryEditionModal();
			}
			toast.error(t('targeting.target.target_partner.error_history'));
		}
	}, [dispatch, isHistoryEditionModalShowing, t, toggleHistoryEditionModal]);

	/**
	 * @function
	 * @name onHistoryEditionFormSubmit
	 * @description Callback function to call when the history edition form is submitted.
	 */
	const onHistoryEditionFormSubmit = useCallback(async (formData) => {
		toggleHistoryEditionModal();

		await dispatch(updateHistory(formData, formData.id));
		dispatch(fetchHistoryList({
			rowsPerPage: 0,
			filters: {
				conditions: [
					{ column: 'targetPartnersId', criterion: 'is', value: targetPartner?.id },
					{ column: 'partnersId', criterion: 'is', value: targetPartner?.partner?.id },
				],
			},
		}));
		dispatch(fetchTargetPartnersForList({
			rowsPerPage: 0,
			filters: {
				conditions: [{ column: 'targetId', criterion: 'is', value: targetId }],
			},
		}));
	}, [dispatch, targetId, targetPartner?.id, targetPartner?.partner?.id, toggleHistoryEditionModal]);

	/**
	 * @function
	 * @name onStatusCreationFormSubmit
	 * @description Callback function to call when the status creation form is submitted.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onStatusCreationFormSubmit = useCallback(async (formData) => {
		toggleStatusCreationModal();
		const submitData = {
			...formData,
			targetPartners: JSON.parse(formData.targetPartners)[0],
		};

		await dispatch(addHistory(submitData));
		dispatch(fetchHistoryList({
			rowsPerPage: 0,
			filters: {
				conditions: [
					{ column: 'targetPartnersId', criterion: 'is', value: targetPartner?.id },
					{ column: 'partnersId', criterion: 'is', value: targetPartner?.partner?.id },
				],
			},
		}));
		dispatch(fetchTargetPartnersForList({
			rowsPerPage: 0,
			filters: {
				conditions: [{ column: 'targetId', criterion: 'is', value: targetId }],
			},
		}));
		dispatch(fetchTargetStats(targetId));
		dispatch(fetchTargetingStats(targetingId));
	}, [dispatch, targetId, targetPartner?.id, targetPartner?.partner?.id, targetingId, toggleStatusCreationModal]);

	/**
	 * @function
	 * @name onContactCreationFormSubmit
	 * @description Callback function to call when the contact creation form is submitted.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onContactCreationFormSubmit = useCallback(async (formData) => {
		toggleHistoryCreationModal();

		await dispatch(addContact(formData));
		dispatch(fetchPartner(targetPartner?.partner?.id));
		dispatch(fetchContactsProfileDisplayList({
			rowsPerPage: 0,
			filters: {
				conditions: [{ column: 'partnersId', criterion: 'is', value: targetPartner?.partner?.id }],
			},
		}));
	}, [dispatch, targetPartner?.partner?.id, toggleHistoryCreationModal]);

	/**
	 * @function
	 * @name onContactEditionButtonClick
	 * @description Callback function to call when the contact edition button is clicked.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @param {string} contactId The id of the contact to edit.
	 */
	const onContactEditionButtonClick = useCallback(async (contactId) => {
		try {
			setCurrentlyEditedContactId(contactId);
			dispatch(fetchContact(contactId));
			toggleContactEditionModal();
		} catch {
			if (isContactEditionModalShowing) {
				toggleContactEditionModal();
			}
			toast.error(t('targeting.target.target_partner.error_contact'));
		}
	}, [dispatch, isContactEditionModalShowing, t, toggleContactEditionModal]);

	/**
	 * @function
	 * @name onContactEditionFormSubmit
	 * @description Callback function to call when the contact edition form is submitted.
	 */
	const onContactEditionFormSubmit = useCallback(async (formData) => {
		toggleContactEditionModal();

		await dispatch(updateContact(formData, formData.id));
		dispatch(fetchPartner(targetPartner?.partner?.id));
		dispatch(fetchContactsProfileDisplayList({
			rowsPerPage: 0,
			filters: {
				conditions: [{ column: 'partnersId', criterion: 'is', value: targetPartner?.partner?.id }],
			},
		}));
	}, [dispatch, targetPartner?.partner?.id, toggleContactEditionModal]);

	useEffect(() => {
		if (targetPartner) { // Performance improvement : the data being fetched requires the targetPartner to be set.
			setIsDataFetchPending(true);
			Promise.all([
				dispatch(fetchPartner(targetPartner?.partner?.id)),
				dispatch(fetchHistoryList({
					rowsPerPage: 0,
					filters: {
						conditions: [
							{ column: 'targetPartnersId', criterion: 'is', value: targetPartner?.id },
							{ column: 'partnersId', criterion: 'is', value: targetPartner?.partner?.id },
						],
					},
				})),
				dispatch(fetchContactsProfileDisplayList({
					rowsPerPage: 0,
					filters: {
						conditions: [{ column: 'partnersId', criterion: 'is', value: targetPartner?.partner?.id }],
					},
				})),
			]).then(() => {
				setIsDataFetchPending(false);
			});
		}
	}, [dispatch, targetPartner]);

	useEffect(() => {
		if (shouldRefreshData) {
			setIsDataFetchPending(true);
			dispatch(fetchTargetPartner(targetPartnerId));
		}
	}, [dispatch, shouldRefreshData, targetPartnerId]);

	return (
		<>
			<TargetPartnerStatusEvolution
				isDataFetchPending={isDataFetchPending}
				statusObjects={statusEvolutionList}
				onCreateStatusButtonClick={toggleStatusCreationModal}
			/>
			<TargetPartnerSummary
				isDataFetchPending={isDataFetchPending}
				partner={partner}
				onPartnerEditionButtonClick={togglePartnerEditionModal}
			/>
			<TargetPartnerHistoryList
				histories={histories}
				isDataFetchPending={isDataFetchPending}
				onCreateHistoryButtonClick={toggleHistoryCreationModal}
				onEditHistoryButtonClick={onHistoryEditionButtonClick}
				targetPartnerId={targetPartnerId}
			/>
			<TargetPartnerContactList
				contacts={contacts}
				isDataFetchPending={isDataFetchPending}
				onCreateContactButtonClick={toggleContactCreationModal}
				onEditContactButtonClick={onContactEditionButtonClick}
			/>
			<Modal isShowing={isStatusCreationModalShowing} title={t('targeting.target.target_partner.update_status')} onClose={toggleStatusCreationModal}>
				{!isTargetPartnerFetchPending && !isHistoryFetchPending && targetPartner && (
					/* After creating a new history, the creationForm has to be cleaned and closed: isLoading is doing this work */
					<HistoryCreationStatusForm
						onSubmit={onStatusCreationFormSubmit}
						targetPartnersIds={[targetPartnerId]}
						targetingId={targetingId}
					/>
				)}
			</Modal>
			<Modal isShowing={isPartnerEditionModalShowing} title={t('targeting.target.target_partner.update_partner')} onClose={togglePartnerEditionModal}>
				{targetPartner && partner && (
					/* After creating a new history, the creationForm has to be cleaned and closed: isLoading is doing this work */
					<PartnerEditionForm onSubmit={onPartnerEditionFormSubmit} partner={partner} />
				)}
			</Modal>

			<Modal
				isShowing={isHistoryCreationModalShowing}
				title={t('targeting.target.target_partner.history_modal_title', { partner: targetPartner?.partner?.name })}
				onClose={toggleHistoryCreationModal}
			>
				{!isTargetPartnerFetchPending && !isHistoryFetchPending && targetPartner && (
				/* After creating a new history, the creationForm has to be cleaned and closed: isLoading is doing this work */
				<HistoryCreationForm onSubmit={onHistoryCreationFormSubmit} targetPartner={targetPartner} />
				)}
			</Modal>
			<Modal
				isShowing={isHistoryEditionModalShowing}
				title={t('targeting.target.target_partner.history_modal_title', { partner: targetPartner?.partner?.name })}
				onClose={toggleHistoryEditionModal}
			>
				{!isTargetPartnerFetchPending && !isHistoryFetchPending && currentlyEditedHistory && targetPartner && (
					/* After creating a new history, the creationForm has to be cleaned and closed: isLoading is doing this work */
					<HistoryEditionForm onSubmit={onHistoryEditionFormSubmit} history={currentlyEditedHistory} targetPartner={targetPartner} editTargetPartnerHistory />
				)}
			</Modal>
			<Modal isShowing={isContactCreationModalShowing} title={t('targeting.target.target_partner.create_contact')} onClose={toggleContactCreationModal}>
				{!isTargetPartnerFetchPending && targetPartner && (
					/* After creating a new contact, the creationForm has to be cleaned and closed: isLoading is doing this work */
					<ContactCreationForm onSubmit={(onContactCreationFormSubmit)} targetPartner={targetPartner} />
				)}
			</Modal>
			<Modal isShowing={isContactEditionModalShowing} title={t('targeting.target.target_partner.edit_contact')} onClose={toggleContactEditionModal}>
				{!isTargetPartnerFetchPending && currentlyEditedContact && (
					/* After editing a contact, the editionForm has to be cleaned and closed: isLoading is doing this work */
					<ContactEditionForm onSubmit={onContactEditionFormSubmit} contact={currentlyEditedContact} />
				)}
			</Modal>
		</>
	);
};

TargetPartnerDetails.propTypes = {
	shouldRefreshData: PropTypes.bool,
	targetPartnerId: PropTypes.string.isRequired,
	targetingId: PropTypes.string.isRequired,
};

TargetPartnerDetails.defaultProps = {
	shouldRefreshData: false,
};

export default TargetPartnerDetails;
