import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BarChart2, ChevronDown, Filter, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Environments } from 'constants/environmentEnums';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import {
	formatInvoiceName,
	formatPaymentDelay,
	formatProjectName,
	formatStructureName,
} from 'lib/invoices/formatInvoiceData';
import { adaptToBackendFilters } from 'lib/shared/collectionsUtils';
import { formatCreatedAt, formatCreatedBy, formatCurrencyName, formatNumber, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { addHistoriesWithInvoices, fetchHistoryList } from 'redux/actions/histories/histories';
import { fetchInvoicesStats } from 'redux/actions/invoices/invoices';
import { fetchInvoicesForList } from 'redux/actions/invoices/invoicesList';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { useHistoryLoadingSelector } from 'redux/selectors/histories/histories';
import { useInvoicesStatsSelector } from 'redux/selectors/invoices/invoices';
import { useInvoicesForListSelector, useInvoicesListLoadingSelector, useInvoicesListTotalCountSelector } from 'redux/selectors/invoices/invoicesList';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { HistoryCreationStatusForm } from 'components/histories';
import ActionsCell from 'components/invoices/invoiceList/ActionsCell';
import { Button } from 'components/shared/buttons';
import StatusCell from 'components/shared/cells/StatusCell';
import { Dropdown } from 'components/shared/dropdown';
import { SearchInput } from 'components/shared/inputs';
import { useDataFilter } from 'components/shared/list/DataListFilters';
import DataListFilterPanel from 'components/shared/list/DataListFilters/DataListFilterPanel';
import { Modal, TableUserPreferencesModal, useModal } from 'components/shared/modal';
import StatusesViewer from 'components/shared/statuses/StatusesViewer';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';
import styles from 'styles/components/targets/_targetPartnerList.module.scss';

/**
 * @name InvoiceListPage
 * @description A list of all invoices accessible from the invoices app.
 *
 * From this page, the user is able to view, interact and search for invoices.
 *
 * @author Romaric Barthe
 * @author Matthieu Schaerlinger
 */
const InvoiceListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const invoicesList = useInvoicesForListSelector();
	const totalCount = useInvoicesListTotalCountSelector();
	const statuses = useInvoicesStatsSelector();
	const numberFormat = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.INVOICE)[0];

	const invoices = useMemo(() => (invoicesList.map((invoice) => ({
		...invoice,
		name: formatInvoiceName(invoice, numberFormat, t),
		parent: invoice.reversedInvoice ? formatInvoiceName(invoice.reversedInvoice, numberFormat, t) : '',
	}))), [invoicesList, numberFormat, t]);

	const canCreateInvoice = useAccessRight(AccessRights.accounting.invoices.enhancedRights.CREATE_INVOICE);

	const isInvoiceFetchPending = useInvoicesListLoadingSelector();
	const isHistoryFetchPending = useHistoryLoadingSelector();

	const headers = useMemo(() => [
		{ id: 'name', accessor: 'name', Header: t('invoice.fields.number'), pinColumn: true, sorting: 'invoice.editionNumber' },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('invoice.headers.actions') },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('invoice.fields.archived.yes') : t('invoice.fields.archived.no')),
			Header: t('invoice.fields.archived.header'),
			sorting: 'invoice.archived',
		},
		{ id: 'project', accessor: (row) => formatProjectName(row), Header: t('invoice.fields.project'), sorting: 'project.name' },
		{ id: 'status', Cell: StatusCell, Header: t('targeting.target.target_partner.fields.status'), sorting: 'status.name' },
		{
			id: 'method',
			accessor: (row) => t(`invoice.fields.method.${row.method}`),
			Header: t('invoice.fields.method.header'),
			sorting: 'invoice.method',
		},
		{ id: 'paymentDelay', accessor: (row) => formatPaymentDelay(row), Header: t('invoice.fields.payment_delay.header'), sorting: 'paymentDelay.number' },
		{
			id: 'withoutVat',
			accessor: (row) => t(`invoice.fields.without_vat.${row.withoutVat ? 'yes' : 'no'}`),
			Header: t('invoice.fields.without_vat.header'),
			sorting: 'invoice.withoutVat',
		},
		{ id: 'currency', accessor: (row) => formatCurrencyName(row), Header: t('invoice.fields.currency'), sorting: 'currency.name' },
		{ id: 'comment', accessor: 'comment', Header: t('invoice.fields.comment'), sorting: 'invoice.comment' },
		{ id: 'discount', accessor: (row) => formatNumber(row.discount, { nbDecimalMin: 2, symbol: '%' }), Header: t('invoice.fields.discount'), sorting: 'invoice.discount' },
		{ id: 'reference', accessor: 'reference', Header: t('invoice.fields.reference'), sorting: 'invoice.reference' },
		{ id: 'reversedInvoice', accessor: 'parent', Header: t('invoice.fields.reversedInvoice'), sorting: 'reversedInvoice.editionNumber' },
		{ id: 'structure', accessor: (row) => formatStructureName(row), Header: t('invoice.fields.structure'), sorting: 'structure.name' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('invoice.fields.creation_date'), sorting: 'invoice.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('invoice.fields.update_date'), sorting: 'invoice.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('invoice.fields.creation_name'), sorting: 'createdBy.username' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('invoice.fields.update_name'), sorting: 'updatedBy.username' },
	], [t]);

	useEffect(() => {
		dispatch(fetchNumberFormatList());
		dispatch(fetchInvoicesStats());
	}, [dispatch]);

	const columns = useMemo(() => [
		{ accessor: 'editionNumber', label: t('invoice.fields.number'), type: 'text' },
		{ accessor: 'comment', label: t('invoice.fields.comment'), type: 'text' },
		{ accessor: 'project', label: t('invoice.fields.project'), type: 'entity' },
		{ accessor: 'client', label: t('invoice.fields.client'), type: 'entity' },
		{ accessor: 'currency', label: t('invoice.fields.currency'), type: 'entity' },
		{ accessor: 'grossTotal', label: t('invoice.fields.gross_total'), type: 'number' },
		{ accessor: 'netTotal', label: t('invoice.fields.net_total'), type: 'number' },
		{ accessor: 'vatTotal', label: t('invoice.fields.vat_total'), type: 'number' },
		{ accessor: 'createdBy', label: t('invoice.fields.creation_name'), type: 'text' },
		{ accessor: 'createdAt', label: t('invoice.fields.creation_date'), type: 'date' },
		{ accessor: 'updatedBy', label: t('invoice.fields.update_name'), type: 'text' },
		{ accessor: 'updatedAt', label: t('invoice.fields.update_date'), type: 'date' },
	], [t]);

	const [filters, setFilters] = useState({});
	const [params, setParams] = useState({});
	const [fetchData, searchRef] = useSearch((param) => {
		setParams(param);
		dispatch(fetchInvoicesForList({ ...filters, ...param }));
	});

	const [chartButtonRef, chartTooltipProps] = useTooltip();
	const [filterButtonRef, filterTooltipProps] = useTooltip();
	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('invoice');
	const { isFilterPanelDisplayed, toggleFilterPanel } = useDataFilter();
	const { isShowing: isStatusCreationModalShowing, toggle: toggleStatusCreationModal } = useModal();

	const [selectedInvoicesIds, setSelectedInvoicesIds] = useState([]);

	const invoicesIndices = useMemo(() => invoices.map(
		(invoice, index) => (selectedInvoicesIds?.includes(invoice.id) ? index : null)
	).filter((partnerIndex) => partnerIndex !== null),
	[invoices, selectedInvoicesIds]);

	const invoicesPointer = useRef(invoices);
	invoicesPointer.current = invoices;
	const selectedInvoicesIdsPointer = useRef(selectedInvoicesIds);
	selectedInvoicesIdsPointer.current = selectedInvoicesIds;

	const selectedInvoicesIdsChanged = useCallback((pSelectedInvoices) => {
		const invoicesIds = invoicesPointer.current.map((invoice) => invoice.id);
		const pSelectedInvoicesIds = pSelectedInvoices.map((invoice) => invoice.id);

		const invoicesToAddIds = pSelectedInvoicesIds.filter(
			(invoiceToAddId) => !selectedInvoicesIdsPointer.current.includes(invoiceToAddId)
			&& invoicesIds.includes(invoiceToAddId)
		);

		const invoicesNoMoreToAddIds = selectedInvoicesIdsPointer.current.filter((invoice) => invoicesIds.includes(invoice))
			.filter((invoice) => !pSelectedInvoicesIds.includes(invoice));

		setSelectedInvoicesIds((value) => [...value.filter((invoice) => !invoicesNoMoreToAddIds.includes(invoice)), ...invoicesToAddIds]);
	}, []);

	/**
	 * @function
	 * @name onStatusCreationFormSubmit
	 * @description Callback function to call when the status creation form is submitted.
	 *
	 * @author Romaric Barthe
	 */
	const onStatusCreationFormSubmit = useCallback(async (formData) => {
		toggleStatusCreationModal();
		const submitData = {
			...formData,
			invoices: JSON.parse(formData.invoices),
		};

		await dispatch(addHistoriesWithInvoices(submitData));
		dispatch(fetchHistoryList({ rowsPerPage: 0, filters: { conditions: [{ column: 'invoicesId', criterion: 'is', value: invoices[0]?.id }] } }));
		dispatch(fetchInvoicesForList(formData));
	}, [dispatch, invoices, toggleStatusCreationModal]);

	// Handle the dropdown toggle
	const [showDropdown, setShowDropdown] = useState(false);

	const toggleDropdown = useCallback(() => {
		setShowDropdown((value) => !value);
	}, []);

	const closeDropdown = useCallback(() => {
		setShowDropdown(false);
	}, []);

	const applyFilter = useCallback((filter) => {
		const newFilters = adaptToBackendFilters(filter);

		setFilters(newFilters);
		dispatch(fetchInvoicesForList({ filters: newFilters, ...params }));
	}, [dispatch, params]);

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.accounting.invoices.invoicePreCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateInvoice && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('invoice.links.create')}
						</Tooltip>
					</>
				)}
				<Button className="icon-only" onClick={toggleFilterPanel} ref={filterButtonRef}>
					<Filter />
				</Button>
				<Tooltip {...filterTooltipProps}>
					{t('components.table.filters.actions.filter')}
				</Tooltip>
				<div>
					<Button onClick={toggleDropdown} disabled={selectedInvoicesIds.length === 0} ref={chartButtonRef}>
						<BarChart2 />
						<ChevronDown />
					</Button>
					<Tooltip {...chartTooltipProps} disabled={selectedInvoicesIds.length === 0}>
						{t('invoice.actions.title', { count: selectedInvoicesIds.length })}
					</Tooltip>
					<Dropdown
						anchorPoint={{ x: 'initial', y: 'initial' }}
						isOpen={showDropdown}
						handleClose={closeDropdown}
						additionalStyle={{ padding: 0 }}
					>
						<Button
							className={styles['dropdown-list-item']}
							onClick={toggleStatusCreationModal}
						>
							{t('invoice.actions.status')}
						</Button>
					</Dropdown>
				</div>

				<div className="header">
					<StatusesViewer statuses={statuses} />
				</div>

				<Modal isShowing={isStatusCreationModalShowing} title={t('invoice.actions.status')} onClose={toggleStatusCreationModal}>
					{!isInvoiceFetchPending && !isHistoryFetchPending && selectedInvoicesIds && (
						/* After creating a new history, the creationForm has to be cleaned and closed: isLoading is doing this work */
						<HistoryCreationStatusForm
							onSubmit={onStatusCreationFormSubmit}
							invoicesIds={selectedInvoicesIds}
						/>
					)}
				</Modal>
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={invoices}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="invoice"
				selectionChanged={selectedInvoicesIdsChanged}
				selectedRowsIndices={invoicesIndices}
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('invoice.pages.list')} {...modalProps} />
			<DataListFilterPanel
				applyFilterCallBack={applyFilter}
				columns={columns}
				open={isFilterPanelDisplayed}
				onCloseButtonClick={toggleFilterPanel}
			/>
		</div>
	);
};

export default InvoiceListPage;
