import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchInvoice } from 'redux/actions/invoices/invoices';
import { useCurrentInvoiceSelector } from 'redux/selectors/invoices/invoices';

import { InvoiceVisualisation } from 'components/invoices';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name InvoiceVisualisationPage
 * @description A page used to edit an existing invoice.
 *
 * @author Romaric Barthe
 *
 */
const InvoiceVisualisationPage = () => {
	const { id: invoiceId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const invoice = useCurrentInvoiceSelector(invoiceId);
	const canViewInvoice = useAccessRight(AccessRights.humanResources.VIEW);

	useEffect(() => {
		dispatch(fetchInvoice(invoiceId));
	}, [dispatch, invoiceId]);

	return (invoice && canViewInvoice)
		? <InvoiceVisualisation invoice={invoice} />
		: <p>{t('invoice.edition.loading')}</p>;
};

InvoiceVisualisationPage.defaultProps = {
	invoice: undefined,
};

export default InvoiceVisualisationPage;
