/* eslint-disable import/prefer-default-export */
/**
 * @function
 * @name collidingWithMask
 * @description Check if the provided index is colliding with the provided mask
 *
 * @author Florian Fornazaric
 *
 * @param {string} mask	 The mask to check against
 * @param {number} index The index to check
 * @param {Regexp} regex The regex to use to check the mask
 *
 * @returns {boolean} true if the index is colliding with the mask, false otherwise
 */
export const collidingWithMask = (mask, index, regex) => regex.test(mask.charAt(index));
