import update from 'immutability-helper';

/**
 * @function
 * @name moveRow
 * @description Moves a row from the given source index to the given destination index
 *
 * @author Yann Hodiesne
 *
 * @param {object} state					The current state
 * @param {number} payload.sourceIndex		The source index
 * @param {number} payload.destinationIndex	The destination index
 *
 * @returns {object} The updated state value
 */
const moveRow = (state, { sourceIndex, destinationIndex }) => {
	const { header, footer, elements, movingElement } = state;
	if (!state.elements[sourceIndex]) {
		return state;
	}

	let newHeader = header;
	let newFooter = footer;
	let finalDestinationIndex = destinationIndex;

	// When moving, we store inside the source index and the element being moved
	let newMovingElement = movingElement;
	if (!movingElement) {
		newMovingElement = {
			element: state.elements[sourceIndex],
			sourceIndex,
		};
	}

	// If an element is being dragged inside or outside the header, we update he number of rows of the header
	if (header?.numberOfElements > destinationIndex && header?.numberOfElements <= sourceIndex) {
		newHeader = update(
			header, {
				numberOfElements: {
					$set: header.numberOfElements + 1,
				},
			},
		);
	} else if (header?.numberOfElements <= destinationIndex && header?.numberOfElements > sourceIndex) {
		finalDestinationIndex++;
		newHeader = update(
			header, {
				numberOfElements: {
					$set: header.numberOfElements - 1,
				},
			},
		);
	}

	// If an element is being dragged inside or outside the footer, we update he number of rows of the footer
	if (elements.length - (footer?.numberOfElements || 0) <= destinationIndex
		&& (footer?.numberOfElements || 0) !== 0
		&& elements.length - (footer?.numberOfElements ?? 0) > sourceIndex) {
		newFooter = update(
			footer, {
				numberOfElements: {
					$set: footer.numberOfElements + 1,
				},
			},
		);
	} else if (elements.length - (footer?.numberOfElements || 0) > destinationIndex && elements.length - (footer?.numberOfElements || 0) <= sourceIndex) {
		finalDestinationIndex++;
		newFooter = update(
			footer, {
				numberOfElements: {
					$set: footer.numberOfElements - 1,
				},
			},
		);
	}

	return {
		...state,
		header: newHeader,
		footer: newFooter,
		movingElement: newMovingElement,
		elements: update(state.elements, {
			$splice: [
				[sourceIndex, 1],
				[finalDestinationIndex, 0, state.elements[sourceIndex]],
			],
		}),
		isDirty: true,
	};
};

export default moveRow;
