import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchRelationType, updateRelationType } from 'redux/actions/relationTypes';
import { useCurrentRelationTypeSelector } from 'redux/selectors/relationTypes';
import routes from 'routes';

import { RelationTypeEditionForm } from 'components/relationTypes';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name RelationTypeEditionPage
 * @description A page used to edit an existing relation Type.
 *
 * @author Audrey Clerc
 */
const RelationTypeEditionPage = () => {
	const { id: relationTypeId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const relationType = useCurrentRelationTypeSelector(relationTypeId);

	const canEditRelationType = useAccessRight(AccessRights.commonModules.relationTypes.enhancedRights.CREATE_RELATION_TYPE) && !relationType?.archived;

	useEffect(() => {
		dispatch(fetchRelationType(relationTypeId));
	}, [dispatch, relationTypeId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditRelationType) {
			dispatch(updateRelationType(formData, relationTypeId, routes.commonModules.relationTypes.list));
		} else {
			redirectOnSuccess(routes.commonModules.relationTypes.list);
		}
	}, [canEditRelationType, dispatch, relationTypeId]);

	return (
		relationType
			? <RelationTypeEditionForm relationType={relationType} onSubmit={handleFormSubmit} />
			: <p>{t('relation_type.edition.loading')}</p>
	);
};

export default RelationTypeEditionPage;
