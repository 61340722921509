import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { InvoiceMethods } from 'constants/invoiceEnums';
import update from 'immutability-helper';
import { getCurrencyName } from 'lib/currencies/formatCurrencyData';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { fetchAllForInvoice, fetchInvoice } from 'redux/actions/invoices/invoices';
import { addPaymentDelay } from 'redux/actions/paymentDelays';
import { addProject } from 'redux/actions/projects/projects';
import { useAllForInvoiceSelector, useInvoiceLoadingSelector } from 'redux/selectors/invoices/invoices';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';
import { v4 as uuid } from 'uuid';

import { PaymentDelayCreationForm } from 'components/paymentDelays';
import { ProjectCreationForm } from 'components/projects';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Collapsible } from 'components/shared/layout';
import { sumOneObjectPropertyInArray } from 'components/shared/utils/functions';
import TemplateListSelect from 'components/templates/TemplateListSelect';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import {
	getInvoiceMethodsAsSelectOptions,
	getRowTotals,
	invoiceColumnsHeaders as getColumnsHeaders,
} from './functions';
import InvoiceGrid from './InvoiceGrid';
import InvoiceHeader from './InvoiceHeader';
import InvoiceTitle from './InvoiceTitle';
import InvoiceTotalsTable from './InvoiceTotalsTable';

/**
 * @name InvoiceEditionForm
 * @description A form used to edit an existing invoice's information.
 *
 * @author Romaric Barthe
 *
 * @param {function}	onSubmit	The method to trigger upon form submission.
 * @param {object}		invoice		The invoice object to update information from.
 */
const InvoiceEditionForm = ({ onSubmit, invoice }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForInvoiceForm = useAllForInvoiceSelector();
	const accounts = useMemo(() => allForInvoiceForm?.unarchivedAccounts ?? [], [allForInvoiceForm]);
	const companies = useMemo(() => allForInvoiceForm?.companies ?? [], [allForInvoiceForm]);
	const currencies = useMemo(() => allForInvoiceForm?.currencies ?? [], [allForInvoiceForm]);
	const lastEditionDate = useMemo(() => allForInvoiceForm?.lastEditionDate ?? '', [allForInvoiceForm]);
	const numberFormat = useMemo(() => allForInvoiceForm?.numberFormat, [allForInvoiceForm]);
	const offers = useMemo(() => allForInvoiceForm?.unarchivedOffers ?? [], [allForInvoiceForm]);
	const paymentDelays = useMemo(() => allForInvoiceForm?.unarchivedPaymentDelays ?? [], [allForInvoiceForm]);
	const projects = useMemo(() => allForInvoiceForm?.unarchivedProjects ?? [], [allForInvoiceForm]);
	const templates = useMemo(() => allForInvoiceForm?.unarchivedTemplates, [allForInvoiceForm]);
	const vatRates = useMemo(() => allForInvoiceForm?.unarchivedVatRates ?? [], [allForInvoiceForm]);

	const isLoading = useInvoiceLoadingSelector();

	const canEditInvoice = useAccessRight(AccessRights.accounting.invoices.enhancedRights.CREATE_INVOICE) && !invoice?.archived;
	const lastEditionDateFormatted = useMemo(() => new Date(new Date(lastEditionDate).setHours(0, 0, 0, 0)), [lastEditionDate]);

	const [currency, setCurrency] = useState();
	const [selectedMethod, setSelectedMethod] = useState();

	const currencyName = useMemo(() => getCurrencyName(currencies, currency), [currencies, currency]);
	const currencySymbol = useMemo(() => ((currencyName && currencyName !== '') ? t(`currency.${currencyName}`) : ''), [currencyName, t]);

	const [rows, setRows] = useState([]);
	const sortedRows = useMemo(() => rows.sort((a, b) => a.line - b.line), [rows]);

	const [duplicateRowId, setDuplicateRowId] = useState();
	const [originalRowData, setOriginalRowData] = useState();

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const [discountOnTotal, setDiscountOnTotal] = useState(0);
	const [grossTotal, setGrossTotal] = useState(0);
	const [grossTotalWithDiscount, setGrossTotalWithDiscount] = useState(0);
	const [vatTotal, setVatTotal] = useState(0);
	const [netTotal, setNetTotal] = useState(0);
	const [rowsTotals, setRowsTotals] = useState([]);

	const favoriteVatRate = useMemo(() => {
		if (allForInvoiceForm?.unarchivedVatRates) {
			const favoriteVatRateArray = allForInvoiceForm.unarchivedVatRates.filter((vat) => vat.favorite);

			return favoriteVatRateArray.length === 1 ? favoriteVatRateArray[0] : {};
		}

		return {};
	}, [allForInvoiceForm]);

	// !!Only for Header: Props to pass down to the select input so that it will display ancreation modal when clicking on the "+" icon.
	const paymentDelayCreationModal = useFormModal(PaymentDelayCreationForm, t('invoice.edition.inputs.payment_delay.creation'), fetchAllForInvoice, addPaymentDelay);
	const projectCreationModal = useFormModal(ProjectCreationForm, t('invoice.edition.inputs.project.creation'), fetchAllForInvoice, addProject);

	/**
	 * @function
	 * @name setTotals
	 * @description A function to refresh Total variables.
	 *
	 * @author Romaric Barthe
	 */
	const setTotals = useCallback(() => {
		const formData = formRef.current?.getFormValues();
		setRowsTotals(getRowTotals(formData?.rows, vatRates, formData?.discount));
	}, [formRef, vatRates]);

	/**
	 * @function
	 * @name updateRow
	 * @description A function to add/ move/ delete/ duplicate a row.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	rowId			The id of the row triggering the action.
	 * @param {number}	lineNb			The number of the row triggering the action.
	 * @param {number}	direction		The direction if move: positive is for down, negative is for up.
	 * @param {string}	action			The possible action: 'add', 'move', 'duplicate', 'select'.
	 */
	const updateRow = useCallback((action, rowId = undefined, lineNb = undefined, direction = undefined) => {
		if (action === 'add') {
			const newId = uuid();
			const lines = rows.map((row) => row?.line) ?? [];
			const newRowNumber = _.isEmpty(lines) ? 1 : Math.max(...lines) + 1;
			setRows([...rows, { id: newId, line: newRowNumber, vatRate: favoriteVatRate?.id }]);
		} else {
			if (rows.findIndex((row) => row.id === rowId) === undefined) {
				throw new Error('An issue occured with the current lines. Please refresh the page.');
			}
			if (action === 'move') {
				const rowIndexToMoveInDirection = rows.findIndex((row) => row.id === rowId);
				const rowIndexToMoveInOtherDirection = rows.findIndex((row) => row.line === lineNb + direction);
				setRows(update(rows, {
					[rowIndexToMoveInDirection]: {
						line: { $set: lineNb + direction },
					},
					[rowIndexToMoveInOtherDirection]: {
						line: { $set: lineNb },
					},
				}));
			}
			if (action === 'delete') {
				const rowIndexToRemove = rows.findIndex((row) => row.id === rowId);
				setRows(update(rows, {
					$splice: [[rowIndexToRemove, 1]],
					$apply: (newRows) => newRows.sort((a, b) => a.line - b.line).map((r, i) => update(r, { line: { $set: i + 1 } })),
				}));
				setRowsTotals(update(rowsTotals, {
					$apply: (newRows) => newRows.filter((row) => row.id !== rowId),
				}));
			}
			if (action === 'duplicate') {
				const newId = uuid();
				setRows(update(rows, {
					$apply: (newRows) => newRows.map((r) => (r.line > lineNb ? update(r, { line: { $set: r.line + 1 } }) : r)),
					$push: [{
						id: newId,
						line: lineNb + 1,
					}],
				}));
				const formData = formRef.current.getFormValues();
				setOriginalRowData(formData.rows[rowId]);
				setDuplicateRowId(newId);
			}
		}
	}, [favoriteVatRate?.id, formRef, rows, rowsTotals]);

	// Fetch data
	useEffect(() => {
		dispatch(fetchAllForInvoice());
	}, [dispatch]);

	// Set default values
	const defaultValues = useMemo(() => {
		const flatRows = Object.fromEntries(update(invoice.rows, {
			$apply: (newRows) => Object.entries(newRows).map((row) => update(row, {
				$apply: (newRow) => update(newRow, {
					1: {
						account: { $set: newRow[1].account?.id },
						alreadyInvoiced: { $set: newRow[1].alreadyInvoiced },
						baseAmount: { $set: newRow[1].baseAmount },
						currentlyInvoiced: { $set: newRow[1].currentlyInvoiced },
						designation: { $set: newRow[1].designation || '' },
						discount: { $set: newRow[1].discount },
						offer: { $set: newRow[1].offer?.id },
						price: { $set: newRow[1].price },
						quantity: { $set: newRow[1].quantity },
						vatRate: { $set: newRow[1].vatRate?.id },
					},
				}),
			})),
		}));

		return {
			...invoice,
			rows: flatRows,
			currency: invoice.currency?.id,
			discountOnTotal: invoice.discountOnTotal ?? 0,
			grossTotalWithDiscount: invoice.grossTotalWithDiscount ?? invoice.grossTotal,
			paymentDelay: invoice.paymentDelay?.id,
			project: invoice.project?.id,
			reversedInvoice: invoice.reversedInvoice?.id,
			seller: invoice.seller?.id,
			structure: invoice.structure?.id,
		};
	}, [invoice]);

	const methods = useMemo(() => getInvoiceMethodsAsSelectOptions().map((method) => ({ id: method.id, label: t(method.label) })), [t]);
	const columnsHeaders = useMemo(() => getColumnsHeaders(selectedMethod), [selectedMethod]);

	// Set state's variables
	useEffect(() => {
		setCurrency(defaultValues?.currency);
		setDiscountOnTotal(defaultValues?.discountOnTotal);
		setGrossTotal(defaultValues?.grossTotal);
		setGrossTotalWithDiscount(defaultValues?.grossTotalWithDiscount);
		setNetTotal(defaultValues?.netTotal);
		setSelectedMethod(defaultValues?.method);
		setVatTotal(defaultValues?.vatTotal);
		setRows(defaultValues?.rows ? Object.values(defaultValues.rows) : []);
		setRowsTotals(getRowTotals(defaultValues?.rows, vatRates, defaultValues?.discount));
	}, [defaultValues, vatRates]);

	// Refresh totals
	useEffect(() => {
		setGrossTotal(sumOneObjectPropertyInArray(rowsTotals, 'gt'));
		setDiscountOnTotal(sumOneObjectPropertyInArray(rowsTotals, 'dt'));
		setGrossTotalWithDiscount(sumOneObjectPropertyInArray(rowsTotals, 'gtd'));
		setVatTotal(sumOneObjectPropertyInArray(rowsTotals, 'vt'));
		setNetTotal(sumOneObjectPropertyInArray(rowsTotals, 'nt'));
	}, [rowsTotals]);

	/**
	 * @function
	 * @name linkToTemplate
	 * @description A function to submit the form.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object}	templateId	The id of the template to be linked with.
	 * @param {object}	editionDate	The edition date chosen.
	 */
	const linkToTemplate = useCallback((templateId, editionDate) => {
		const formattedEditionDate = moment(editionDate).format('YYYYMMDD');
		const formattedLastEditionDate = moment(lastEditionDate).format('YYYYMMDD');
		dispatch(fetchInvoice(invoice?.id));
		history.push(generatePath(routes.settings.templates.templatePdfExportFromApp, {
			id: templateId,
			entityId: invoice?.id,
			editionDate: formattedEditionDate,
			lastEditionDate: formattedLastEditionDate,
		}));
	}, [dispatch, invoice?.id, lastEditionDate]);

	return (
		<DynamicForm
			ref={formRef}
			cleanFormData
			defaultValues={defaultValues}
			disabled={!canEditInvoice}
			onSubmit={onSubmit}
			{...formProps}
		>

			<InvoiceTitle invoice={invoice} numberFormat={numberFormat} />

			<Collapsible titleKey="invoice.edition.collapsed.general_informations" collapsed>
				<InvoiceHeader
					currencies={currencies}
					defaultValues={defaultValues}
					isLoading={isLoading}
					methods={methods}
					projects={projects}
					paymentDelayCreationModal={paymentDelayCreationModal}
					paymentDelays={paymentDelays}
					projectCreationModal={projectCreationModal}
					sourceInvoiceId={defaultValues?.reversedInvoice?.id}
					structures={companies}
					setMethodSelected={setSelectedMethod}
					setCurrency={setCurrency}
					setTotals={setTotals}
				/>
			</Collapsible>

			<InvoiceTotalsTable
				currencySymbol={currencySymbol}
				discountOnTotal={discountOnTotal}
				grossTotal={grossTotal}
				grossTotalWithDiscount={grossTotalWithDiscount}
				netTotal={netTotal}
				vatTotal={vatTotal}
			/>

			<Collapsible titleKey="invoice.edition.collapsed.grid">
				<InvoiceGrid
					accounts={accounts}
					columnsHeaders={columnsHeaders}
					currencyName={currencyName}
					currencySymbol={currencySymbol}
					defaultValues={defaultValues}
					isLoading={isLoading}
					offers={offers}
					rows={sortedRows}
					vatRates={vatRates}
					// functions
					updateRow={updateRow}
					setTotals={setTotals}
					// duplicate logic
					duplicateRowId={duplicateRowId}
					originalRowData={originalRowData}
				/>

				{!invoice?.archived && canEditInvoice && (
					<Button className="secondary" type="button" onClick={() => updateRow('add')}>
						<PlusCircle />
						{t('invoice.creation.grid.add_row')}
					</Button>
				)}
			</Collapsible>

			<div className="edition-actions">
				{templates && (
					<TemplateListSelect onChangeCallback={linkToTemplate} lastEditionDate={lastEditionDateFormatted} templates={templates} />
				)}
				<Button className="primary" type="submit" {...buttonProps}>
					{canEditInvoice ? t('invoice.edition.action') : t('invoice.edition.close')}
				</Button>
			</div>
		</DynamicForm>
	);
};

InvoiceEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	invoice: PropTypes.shape({
		archived: PropTypes.bool,
		alreadyInvoiced: PropTypes.number,
		baseAmount: PropTypes.number,
		currentlyInvoiced: PropTypes.number,
		editionNumber: PropTypes.number,
		comment: PropTypes.string,
		currency: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
		discount: PropTypes.number,
		discountOnTotal: PropTypes.number,
		id: PropTypes.string.isRequired,
		grossTotal: PropTypes.number,
		grossTotalWithDiscount: PropTypes.number,
		method: PropTypes.oneOf(
			Object.values(InvoiceMethods)
		).isRequired,
		netTotal: PropTypes.number,
		paymentDelay: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		project: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		reference: PropTypes.string,
		reversedInvoice: PropTypes.shape({
			id: PropTypes.string,
			editionNumber: PropTypes.number,
		}),
		rows: PropTypes.shape({
			id: PropTypes.string,
			offer: PropTypes.shape({
				id: PropTypes.string,
				name: PropTypes.string,
			}),
			vatRate: PropTypes.shape({
				id: PropTypes.string,
				rate: PropTypes.number,
			}),
		}).isRequired,
		seller: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		structure: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		vatTotal: PropTypes.number,
	}).isRequired,
};

export default InvoiceEditionForm;
