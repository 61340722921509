import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { fetchUserPreferences, updateUserPreference } from 'redux/actions/userPreferences';

export const isFetching = {
	current: false,
};

/**
 * @function
 * @name useUserPreference
 * @description A custom redux hook, providing tools to fetch and update the given user preference
 *
 * @author Yann Hodiesne
 *
 * Note: if the given user preference does not exist in the state and no default value is provided,
 * this hook will return an empty array as the first item in the returned array.
 *
 * @example const [userPreference, setUserPreference] = useUserPreference('partner');
 *
 * @param {string}			name			The user preference name.
 * @param {Array<string>}	[defaultValue]	The default value to return if the requested user preference does not exist, default to [].
 *
 * @returns {Array}
 */
export default (name, defaultValue) => {
	const dispatch = useDispatch();

	// Ensures the component using this hook is re-rendered when the given user preference changes inside the state
	const internalUserPreference = useSelector((state) => state.userPreferences.items[name]);

	// Check if the user preference is not undefined, and try to use defaultValue or an empty array if it is
	const userPreference = useMemo(() => internalUserPreference ?? defaultValue ?? [], [defaultValue, internalUserPreference]);

	// Determines if we should trigger a fetch
	const shouldFetch = useSelector((state) => !state.userPreferences.hasBeenFetched && !state.userPreferences.isLoading);

	const userPreferencePointer = useRef();
	userPreferencePointer.current = userPreference;

	/**
	 * @name setUserPreference
	 * @description Updates the value stored inside this user preference
	 *
	 * @author Yann Hodiesne
	 *
	 * Note: If the provided parameter is equal to the current value, the API call is skipped.
	 *
	 * @param {array} columns	The new value to apply to this user preference
	 */
	const setUserPreference = useCallback((columns) => {
		// If the provided value is equal to the current value, skip the roundtrip with the API
		if (!_.isEqual(columns, userPreferencePointer.current)) {
			dispatch(updateUserPreference(name, columns));
		}
	}, [dispatch, name]);

	useEffect(() => {
		if (shouldFetch && !isFetching.current) {
			isFetching.current = true;
			dispatch(fetchUserPreferences());
		}
	}, [dispatch, shouldFetch]);

	useEffect(() => {
		if (internalUserPreference) {
			isFetching.current = false;
		}
	}, [internalUserPreference]);

	return [userPreference, setUserPreference];
};
