import { Image } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { MediaManager, useMediaManager } from 'components/mediaManager';
import { Button } from 'components/shared/buttons';

/**
 * @name ImageSelectionButton
 * @description A custom capability displaying a button which opens the media manager to select an image
 *
 * @author Yann Hodiesne
 *
 * @param {string}		value		The id of the currently selected image
 * @param {function}	setValue	A function used to update the selected image id
 * @param {boolean}		readOnly	Whether the user should be able to edit the value
 */
const ImageSelectionButton = ({ value, setValue, readOnly }) => {
	const { t } = useTranslation();
	const { isShowing, toggle } = useMediaManager();

	return (
		<div className="image-selection-button">
			<div>
				{t('template.pdf.capabilities.image_selection.label')}
			</div>
			<Button className="icon-prefix" onClick={toggle} disabled={readOnly}>
				<Image />
				{t('template.pdf.capabilities.image_selection.description')}
			</Button>
			<MediaManager isShowing={isShowing} toggle={toggle} selectedValue={value} onImageSelected={setValue} />
		</div>
	);
};

ImageSelectionButton.propTypes = {
	value: PropTypes.string,
	setValue: PropTypes.func.isRequired,
	readOnly: PropTypes.bool.isRequired,
};

ImageSelectionButton.defaultProps = {
	value: null,
};

export default ImageSelectionButton;
