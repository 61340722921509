import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchTargets
 * @description Retrieves a list of targets from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchTargets = (params) => post('/api/targets', params);

/**
 * @function
 * @name fetchTargetById
 * @description Retrieves an existing target from the API, using the targetId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} targetId      The id of the target we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTargetById = (targetId) => get(`/api/targets/${targetId}`);

/**
 * @function
 * @name createTarget
 * @description Creates a new target into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetData    The data to create the target from.
 *
 * @returns {Promise}
 */
export const createTarget = (targetData) => post('/api/targets/create', targetData);

/**
 * @function
 * @name updateTarget
 * @description Updates an existing target from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetData    The data to update the target with.
 * @param {string} targetId      The id to identify the target with.
 *
 * @returns {Promise}
 */
export const updateTarget = (targetData, targetId) => put(`/api/targets/${targetId}`, targetData);

/**
 * @function
 * @name deleteTarget
 * @description Removes an existing target from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} targetId      The id of the target we want to remove.
 *
 * @returns {Promise}
 */
export const deleteTarget = (targetId) => del(`/api/targets/${targetId}`);

/**
 * @function
 * @name importTargetPartnersForTarget
 * @description Updates an existing target from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} targetData    The data to update the target with.
 * @param {string} targetId      The id to identify the target with.
 *
 * @returns {Promise}
 */
export const importTargetPartnersForTarget = (targetData, targetId) => post(`/api/targets/${targetId}/import`, targetData);

/**
 * @function
 * @name fetchTargetStats
 * @description Fetch target stats from the database.
 *
 * @author Roland Margelidon
 *
 * @returns {Promise}
 */
export const fetchTargetStats = (targetId) => get(`/api/targets/${targetId}/stats`);

/**
 * @function
 * @name exportTargets
 * @description Export existing targets from the database.
 *
 * @author Audrey Clerc
 *
 * @param {Object} data		The data to export the targets.
 *
 * @returns {Promise}
 */
export const exportTargets = (data) => post('/api/target/export', data, 'attachment');
