import * as TargetingsApi from 'api/targetingsApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Targetings redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of targetings
	FETCH_TARGETING_SELECT_LIST_REQUEST: '@TARGETINGS_SELECT/FETCH_LIST_REQUEST',
	FETCH_TARGETING_SELECT_LIST_SUCCESS: '@TARGETINGS_SELECT/FETCH_LIST_SUCCESS',
	FETCH_TARGETING_SELECT_LIST_FAILURE: '@TARGETINGS_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Targeting list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetingsSelectListRequest
 * @description Action triggered anytime a targetingsSelect list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetingsSelectListRequest = () => ({ type: ActionTypes.FETCH_TARGETING_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchTargetingsSelectListSuccess
 * @description Action triggered as a result to a successful targetingsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	targetingsSelect	The list of retrieved targetings.
 * @param {number}	totalCount		The total amount of targetings available in the database to the current user.
 *
 * @returns {object}
 */
const fetchTargetingsSelectListSuccess = ({ targetingsSelect, totalCount }) => ({
	type: ActionTypes.FETCH_TARGETING_SELECT_LIST_SUCCESS,
	payload: { targetingsSelect, totalCount },
});

/**
 * @function
 * @name fetchTargetingsSelectListFailure
 * @description Action triggered as a result to a failed targetingsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetingsSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_TARGETING_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetingsSelectList
 * @description Method used to update the targetingsSelect list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchTargetingsSelectList = (filters) => (dispatch) => {
	dispatch(fetchTargetingsSelectListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forSelect',
	};

	return TargetingsApi.fetchTargetings(newFilters)
		.then(({ targetingsSelect, totalCount }) => dispatch(fetchTargetingsSelectListSuccess({ targetingsSelect, totalCount })))
		.catch((error) => dispatch(fetchTargetingsSelectListFailure(error)));
};
