import moveRow from './moveRow';

/**
 * @function
 * @name moveRowById
 * @description Moves a row to the provided destination index
 *
 * @author Yann Hodiesne
 *
 * @param {object}	state						The current state
 * @param {string}	payload.sourceId			The identifier of the row to move
 * @param {number}	payload.destinationIndex	The destination index
 *
 * @returns {object} The updated state value
 */
const moveRowById = (state, { sourceId, destinationIndex }) => {
	const { elements } = state;

	if (sourceId === elements[destinationIndex].id) {
		return state;
	}

	const sourceIndex = elements.findIndex((element) => element.id === sourceId);

	if (sourceIndex === null) {
		return state;
	}

	return moveRow(state, {
		sourceIndex,
		destinationIndex,
	});
};

export default moveRowById;
