import { useTranslation } from 'react-i18next';
import { formatExpenseName } from 'lib/expenses/formatExpenseData';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import PropTypes from 'prop-types';

/**
 * @name ExpenseTitle
 * @description A component to display the expense's title.
 *
 * @author Romaric Barthe
 *
 * @param {object}	expense			The expense.
 */
const ExpenseTitle = ({ expense, numberFormat }) => {
	const { t } = useTranslation();

	return (
		<div className="data-header">
			<div className="identity">
				<h3>{formatExpenseName(expense, numberFormat, t)}</h3>
				<h4>{expense.user?.username}</h4>
				{expense.reference && expense.reference !== '' && (<h5>{expense.reference}</h5>)}
				{expense.comment && expense.comment !== '' && <p>{expense.comment}</p>}
			</div>
			<div className="metadata">
				<p>{`${t('expense.fields.creation_date')}: ${formatCreatedAt(expense)}`}</p>
				<p>{`${t('expense.fields.creation_name')}: ${formatCreatedBy(expense)}`}</p>
				<p>{`${t('expense.fields.update_date')}: ${formatUpdatedAt(expense)}`}</p>
				<p>{`${t('expense.fields.update_name')}: ${formatUpdatedBy(expense)}`}</p>
			</div>
		</div>
	);
};

ExpenseTitle.propTypes = {
	expense: PropTypes.object.isRequired,
	numberFormat: PropTypes.object,
};

ExpenseTitle.defaultProps = {
	numberFormat: undefined,
};

export default ExpenseTitle;
