import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchTemplateExports
 * @description Retrieves a list of template exports from the API, specifying filters if present.
 *
 * @author Yann Hodiesne
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchTemplateExports = (params) => post('/api/template-exports', params);

/**
 * @function
 * @name fetchTemplateExportById
 * @description Retrieves an existing template export from the API, using the templateId in parameters.
 *
 * @author Yann Hodiesne
 *
 * @param {string} templateId      The id of the template we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchTemplateExportById = (templateExportId) => get(`/api/template-export/${templateExportId}`);

/**
 * @function
 * @name createTemplateExport
 * @description Creates a new template export into the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} templateData    The data to create the template export from.
 *
 * @returns {Promise}
 */
export const createTemplateExport = (templateExportData) => post('/api/template-exports/create', templateExportData);

/**
 * @function
 * @name updateTemplateExport
 * @description Updates an existing template export from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {object} templateExportData    The data to update the template export with.
 * @param {string} templateExportId      The id to identify the template export with.
 *
 * @returns {Promise}
 */
export const updateTemplateExport = (templateExportData, templateExportId) => put(`/api/template-export/${templateExportId}`, templateExportData);

/**
 * @function
 * @name deleteTemplateExport
 * @description Removes an existing template export from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {string} templateExportId      The id of the template export we want to remove.
 *
 * @returns {Promise}
 */
export const deleteTemplateExport = (templateExportId) => del(`/api/template-export/${templateExportId}`);
