import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

import { TargetPartnerHistoryListItem, TargetPartnerHistoryListItemSkeleton } from './TargetPartnerHistoryListItem';

/**
 * @name TargetPartnerHistoryList
 * @description A UI component used to display a list of histories in the TargetPartnerDetails component.
 *
 * @author Timothée Simon-Franza
 *
 * @param {Object[]}	[histories = []]			The list of histories to display.
 * @param {bool}		isDataFetchPending			Whether the data is being fetched.
 * @param {func}		onCreateHistoryButtonClick	The callback to call when the create history button is clicked.
 * @param {func}		onEditHistoryButtonClick	The callback to call when the edit history button is clicked.
 */
const TargetPartnerHistoryList = ({ histories, isDataFetchPending, onCreateHistoryButtonClick, onEditHistoryButtonClick, targetPartnerId }) => {
	const { t } = useTranslation();

	const sortedHistories = useMemo(() => (histories
		.filter(({ note }) => note)
		.sort((a, b) => b.date - a.date)
	), [histories]);

	return (
		<section className="histories">
			<Button onClick={onCreateHistoryButtonClick} disabled={isDataFetchPending}>{t('targeting.target.target_partner.add_history')}</Button>
			<ul>
				{isDataFetchPending && (
					[...Array(3).keys()].map((index) => (<TargetPartnerHistoryListItemSkeleton key={index} />))
				)}

				{!isDataFetchPending && !sortedHistories.length && (
					<p>{t('pages.operations.targetings.targets.target_partners.details.histories.no_entry')}</p>
				)}

				{!isDataFetchPending && sortedHistories.length > 0 && (
					sortedHistories.map((history) => (
						<TargetPartnerHistoryListItem key={history.id} {...history} onEditClick={() => onEditHistoryButtonClick(history.id)} targetPartnerId={targetPartnerId} />))
				)}
			</ul>
		</section>
	);
};

TargetPartnerHistoryList.propTypes = {
	histories: PropTypes.arrayOf(
		PropTypes.shape({
			..._.omit(TargetPartnerHistoryListItem.propTypes, 'onEditClick'),
		})
	),
	isDataFetchPending: PropTypes.bool.isRequired,
	onCreateHistoryButtonClick: PropTypes.func.isRequired,
	onEditHistoryButtonClick: PropTypes.func.isRequired,
	targetPartnerId: PropTypes.string.isRequired,
};

TargetPartnerHistoryList.defaultProps = {
	histories: [],
};

export default TargetPartnerHistoryList;
