import { useTranslation } from 'react-i18next';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import { HiddenInput } from 'components/shared/forms/inputs';

/**
 * @name InvoiceTotalsTable
 * @description A form used to display the invoice totals as an overview.
 *
 * @author Romaric Barthe
 *
 * @param {string}	currencySymbol				The currency symbol used in the invoice.
 * @param {number}	discountOnTotal				The invoice total discount.
 * @param {number}	netTotal					The total invoiced with VAT.
 * @param {number}	grossTotalWithDiscount		The total invoiced after discount without VAT.
 * @param {number}	grossTotal					The total invoicedbefore any discount without VAT.
 * @param {number}	totalVat					The total VAT invoiced.
 */
const InvoiceTotalsTable = ({
	currencySymbol,
	discountOnTotal,
	grossTotal,
	grossTotalWithDiscount,
	netTotal,
	vatTotal,
}) => {
	const { t } = useTranslation();

	return (
		<table className="total-overview">
			<thead>
				<tr>
					<th>{t('invoice.headers.total_without_vat')}</th>
					{discountOnTotal !== 0 && (
						<>
							<th>{t('invoice.headers.total_discount')}</th>
							<th>{t('invoice.headers.total_discounted')}</th>
						</>
					)}
					<th>{t('invoice.headers.total_vat')}</th>
					<th>{t('invoice.headers.total_with_vat')}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						{formatNumber(grossTotal, { symbol: currencySymbol })}
						<HiddenInput
							name="grossTotal"
							value={grossTotal}
						/>
					</td>
					{discountOnTotal !== 0 && (
						<>
							<td>
								{formatNumber(discountOnTotal, { symbol: currencySymbol })}
								<HiddenInput
									name="discountOnTotal"
									value={discountOnTotal}
								/>
							</td>
							<td>
								{formatNumber(grossTotalWithDiscount, { symbol: currencySymbol })}
								<HiddenInput
									name="grossTotalWithDiscount"
									value={grossTotalWithDiscount}
								/>
							</td>
						</>
					)}
					<td>
						{formatNumber(vatTotal, { symbol: currencySymbol })}
						<HiddenInput
							name="vatTotal"
							value={vatTotal}
						/>
					</td>
					<td>
						{formatNumber(netTotal, { symbol: currencySymbol })}
						<HiddenInput
							name="netTotal"
							value={netTotal}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

InvoiceTotalsTable.propTypes = {
	discountOnTotal: PropTypes.number.isRequired,
	grossTotalWithDiscount: PropTypes.number.isRequired,
	netTotal: PropTypes.number.isRequired,
	grossTotal: PropTypes.number.isRequired,
	vatTotal: PropTypes.number,
	currencySymbol: PropTypes.string,
};

InvoiceTotalsTable.defaultProps = {
	currencySymbol: '',
	vatTotal: 0,
};

export default InvoiceTotalsTable;
