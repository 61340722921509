import { useMemo } from 'react';
import { DollarSign, Edit, Link, Users } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import styles from 'styles/components/targets/_targetPartnerPartnerSummary.module.scss';

/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

/**
 * @name TargetPartnerSummarySkeleton
 * @description The skeleton for the TargetPartnerSummary component.
 *
 * @author Timothée Simon-Franza
 */
const TargetPartnerSummarySkeleton = () => (
	<section className={`summary ${styles['partner-summary--skeleton']}`}>
		<h3 role="presentation" />
		<div>
			<a role="presentation" disabled>
				<Link />
				<span />
			</a>
			<span>
				<Users />
				<span />
			</span>
			<span>
				<DollarSign />
				<span />
			</span>
		</div>
	</section>
);
/* eslint-enable jsx-a11y/heading-has-content */
/* eslint-enable jsx-a11y/anchor-is-valid */
/* eslint-enable jsx-a11y/anchor-has-content */

/**
 * @function
 * @name formatPartnerData
 * @description Formats the provided partner's data.
 *
 * @author Timothée Simon-Franza
 *
 * @param {Object} [partner] The partner to format the data for.
 *
 * @returns {Object}
 */
const formatPartnerData = (partner) => {
	if (!partner) {
		return {};
	}

	return ({
		name: partner?.name,
		turnover: partner?.turnover !== '' ? partner.turnover : undefined,
		website: partner?.website !== '' ? partner.website : undefined,
		workforce: partner?.workforce !== '' ? partner.workforce : undefined,
	});
};

/**
 * @name TargetPartnerSummary
 * @description Displays the summary of the selected partner.
 *
 * @author Timothée Simon-Franza
 *
 * @param {bool}	isDataFetchPending			Whether the data is being fetched.
 * @param {func}	onPartnerEditionButtonClick	Callback to be executed when the user clicks on the partner edition button.
 * @param {object}	[partner]					The partner we want to display the information of.
 */
const TargetPartnerSummary = ({ isDataFetchPending, onPartnerEditionButtonClick, partner }) => {
	const { t } = useTranslation();

	const { name, turnover, website, workforce } = useMemo(() => formatPartnerData(partner), [partner]);
	const [editButtonRef, editTooltipProps] = useTooltip();

	const fixedWebsiteUrl = useMemo(() => (
		website?.startsWith('http') ? website : `https://${website}`
	), [website]);

	return (
		<>
			{isDataFetchPending && (
				<TargetPartnerSummarySkeleton />
			)}
			{!isDataFetchPending && (
				<section className={`summary ${styles['partner-summary']}`}>
					<h3>{name}</h3>
					<Button className={`icon-only ${styles['edit-button']}`} ref={editButtonRef} onClick={onPartnerEditionButtonClick}>
						<Edit />
					</Button>
					<Tooltip {...editTooltipProps}>
						{t('actions.edit')}
					</Tooltip>
					<div>
						<a href={fixedWebsiteUrl} disabled={!website} target="_blank" rel="noopener noreferrer">
							{
								// eslint-disable-next-line jsx-a11y/anchor-is-valid
								<Link />
							}
							{website ?? t('targeting.target.target_partner.details.no_website')}
						</a>
						<span>
							<Users />
							{workforce ?? t('targeting.target.target_partner.details.no_workforce')}
						</span>
						<span>
							<DollarSign />
							{turnover ?? t('targeting.target.target_partner.details.no_turnover')}
						</span>
					</div>
				</section>
			)}
		</>
	);
};

TargetPartnerSummary.propTypes = {
	isDataFetchPending: PropTypes.bool.isRequired,
	onPartnerEditionButtonClick: PropTypes.func.isRequired,
	partner: PropTypes.shape({
		name: PropTypes.string.isRequired,
		turnover: PropTypes.number,
		website: PropTypes.string,
		workforce: PropTypes.number,
	}),
};

TargetPartnerSummary.defaultProps = {
	partner: undefined,
};

export default TargetPartnerSummary;
