import _ from 'lodash';
import moment from 'moment';

/**
 * @name formatDate
 * @description Format a date to a string
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string}		dateStr		The date to format.
 * @param {string}		formatStr	The format to use.
 * @param {function}	t			A translation function.
 *
 * @returns {string} The formatted date
 *
 * @todo Replace by a better date formatting system
 * @see https://trello.com/c/ACIxH17L/551-as-a-user-i-want-to-be-able-to-change-the-date-format-of-the-datepicker-component
 */
export const formatDate = (dateStr, formatStr, t) => {
	if (!dateStr) {
		return t('template.pdf.editor.elements.date.errors.date');
	}

	if (!formatStr) {
		return t('template.pdf.editor.elements.date.errors.format');
	}

	const date = new Date(dateStr);

	return _.reduce({
		day: date.getDate().toString().padStart(2, '0'),
		month: (date.getMonth() + 1).toString().padStart(2, '0'),
		'year(4)': date.getFullYear().toString(),
		'year(2)': (date.getFullYear() % 100).toString(),
		'hour(24)': date.getHours().toString().padStart(2, '0'),
		'hour(12)': (date.getHours() % 12).toString().padStart(2, '0'),
		minute: date.getMinutes().toString().padStart(2, '0'),
		second: date.getSeconds().toString().padStart(2, '0'),
		ampm: date.getHours() >= 12 ? 'PM' : 'AM',
	}, (result, val, key) => result.replace(key, val), formatStr);
};

/**
 * @name formatPlaceholderDate
 * @description Format a date as a placeholder string
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string}		formatStr	The format to use.
 * @param {function}	t			A translation function.
 *
 * @returns {string} The formatted date
 *
 * @todo Replace by a better date formatting system
 * @see https://trello.com/c/ACIxH17L/551-as-a-user-i-want-to-be-able-to-change-the-date-format-of-the-datepicker-component
 */
export const formatPlaceholderDate = (formatStr, t) => {
	if (!formatStr) {
		return t('template.pdf.editor.elements.date.errors.format');
	}

	return _.reduce({
		day: t('template.pdf.capabilities.date_format.placeholder.day'),
		month: t('template.pdf.capabilities.date_format.placeholder.month'),
		'year(4)': t('template.pdf.capabilities.date_format.placeholder.year').repeat(2),
		'year(2)': t('template.pdf.capabilities.date_format.placeholder.year'),
		'hour(24)': t('template.pdf.capabilities.date_format.placeholder.hour'),
		'hour(12)': t('template.pdf.capabilities.date_format.placeholder.hour'),
		minute: t('template.pdf.capabilities.date_format.placeholder.minute'),
		second: t('template.pdf.capabilities.date_format.placeholder.second'),
		ampm: t('template.pdf.capabilities.date_format.placeholder.am_pm'),
	}, (result, val, key) => result.replace(key, val), formatStr);
};

/**
 * @name isDateValid
 * @description Check if a date is valid
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string}	dateStr		The date to check
 *
 * @returns {boolean} True if the date is valid, false otherwise
 */
export const isDateValid = (dateStr) => {
	if (!dateStr) {
		return false;
	}

	const date = new Date(dateStr);

	// Date parsing failed
	if (Number.isNaN(date.getTime())) {
		return false;
	}

	// Moment performs complex checks (e.g. invalid/overflowing values)
	return moment(date).isValid();
};
