import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAccount, updateAccount } from 'redux/actions/accounts';
import { useCurrentAccountSelector } from 'redux/selectors/accounts';
import routes from 'routes';

import { AccountEditionForm } from 'components/accounts';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name AccountEditionPage
 * @description A page used to edit an existing account.
 *
 * @author Romaric Barthe
 */
const AccountEditionPage = () => {
	const { id: accountId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const account = useCurrentAccountSelector(accountId);

	const canEditAccount = useAccessRight(AccessRights.accounting.accounts.enhancedRights.CREATE_ACCOUNT) && !account?.archived;

	useEffect(() => {
		dispatch(fetchAccount(accountId));
	}, [dispatch, accountId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData		The updated values from AccountEditionForm.
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditAccount) {
			dispatch(updateAccount(formData, accountId, routes.accounting.accounts.list));
		} else {
			redirectOnSuccess(routes.accounting.accounts.list);
		}
	}, [accountId, canEditAccount, dispatch]);

	return (
		account
			? (
				<AccountEditionForm
					account={account}
					onSubmit={handleFormSubmit}
				/>
			) : <p>{t('account.edition.loading')}</p>
	);
};

export default AccountEditionPage;
