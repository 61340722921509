import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchStatusObjects
 * @description Retrieves a list of status objects from the API, specifying filters if present.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchStatusObjects = (params) => post('/api/status-objects', params);

/**
 * @function
 * @name fetchStatusObjectById
 * @description Retrieves an existing status object from the API, using the statusObjectId in parameters.
 *
 * @author Romaric Barthe
 *
 * @param {string} statusObjectId	The id of the status object we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchStatusObjectById = (statusObjectId) => get(`/api/status-objects/${statusObjectId}`);

/**
 * @function
 * @name createStatusObject
 * @description Creates a new status object into the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObjectData	The data to create the status object from.
 *
 * @returns {Promise}
 */
export const createStatusObject = (statusObjectData) => post('/api/status-objects/create', statusObjectData);

/**
 * @function
 * @name updateStatusObject
 * @description Updates an existing status object from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} statusObjectData 	The data to update the status object with.
 * @param {string} statusObjectId   	The id to identify the status object with.
 *
 * @returns {Promise}
 */
export const updateStatusObject = (statusObjectData, statusObjectId) => put(`/api/status-objects/${statusObjectId}`, statusObjectData);

/**
 * @function
 * @name deleteStatusObject
 * @description Removes an existing status object from the database.
 *
 * @author Romaric Barthe
 *
 * @param {string} statusObjectId	The id of the status object we want to remove.
 *
 * @returns {Promise}
 */
export const deleteStatusObject = (statusObjectId) => del(`/api/status-objects/${statusObjectId}`);
