import update from 'immutability-helper';

/**
 * @function
 * @name updateFooter
 * @description Updates the content of the footer
 *
 * @author Florian Fornazaric
 *
 * @param {object} state					The current state
 * @param {object} payload.content			The new content to store inside the footer
 *
 * @returns {object} The updated state value
 */
const updateFooter = (state, { content }) => {
	const { footer } = state;

	const source = footer;
	const newFooter = update(footer, {
		$set: {
			...source,
			...content,
		},
	});

	return {
		...state,
		footer: newFooter,
	};
};

export default updateFooter;
