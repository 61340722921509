/**
 * @function
 * @name createEntityType
 * @description Helper to create entity types on the fly
 *
 * @author Yann Hodiesne
 *
 * @param {string}	type				The element type identifier
 * @param {string}	name				The name of the element type
 * @param {object}	icon				The icon object of the element type
 * @param {array}	fields				The fields/properties available on this element
 * @param {bool}	[canBeIssued=false]	Whether the element type can be issued on export
 *
 * @returns {object} A new entity type according to provided params
 */
const createEntityType = (
	type,
	name,
	icon,
	fields,
	canBeIssued = false,
) => Object.freeze({
	type,
	name,
	icon,
	fields,
	canBeIssued,
});

export default createEntityType;
