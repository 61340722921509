import { useEffect } from 'react';
import { usePDF } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

/**
 * @name PdfBlobRenderer
 * @description Renders a PDF into a blob and gives it to the provided callback
 *
 * Note: this components does not render anything
 *
 * @author Yann Hodiesne
 * @author Florian Fornazaric
 *
 * @param {node}	document		The document to render
 * @param {func}	getBlobCallback	The callback to call when the blob is ready
 */
const PdfBlobRenderer = ({ document, getBlobCallback }) => {
	const [pdf] = usePDF({ document });

	useEffect(() => {
		if (!pdf.loading && !!pdf.blob) {
			getBlobCallback(pdf.blob);
		}
	}, [getBlobCallback, pdf, pdf.blob, pdf.loading]);

	return null;
};

PdfBlobRenderer.propTypes = {
	document: PropTypes.node.isRequired,
	getBlobCallback: PropTypes.func.isRequired,
};

export default PdfBlobRenderer;
