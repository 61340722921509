import { Briefcase, Slash, User } from 'react-feather';
import conf from 'conf';
import { formatContactName, formatContactPosition } from 'lib/contacts/formatContactData';
import { formatLegalRepresentantNameWithTitle, formatPartnerParent, getRegistration } from 'lib/partners/formatPartnerData';
import { formatPaymentDelayName } from 'lib/paymentDelays/formatPaymentDelayData';
import { formatProjectClientData } from 'lib/projects/formatProjectData';
import { concatPostcodeAndCity, formatDate, formatNumber } from 'lib/shared/format';

import { SvgIcon } from 'components/shared/utils';

import createEntityField from '../functions/internals/createEntityField';
import createEntityType from '../functions/internals/createEntityType';
import {
	formatExpenseForTemplates,
	formatExpenseTotalsForTemplates,
	formatInvoiceAdvancementStatusForTemplates,
	formatInvoiceForTemplates,
	formatInvoiceTotalsForTemplates,
	formatQuotationColumnsForTemplates,
	formatQuotationForTemplates,
	formatQuotationTotalsForTemplates,
} from '../functions/internals/format';

import {
	ExpenseDefaultValues,
	ExpenseTotalsDefaultValues,
	InvoiceAdvancementStatusDefaultValues,
	InvoiceDefaultValues,
	InvoiceTotalsDefaultValues,
	QuotationColumnsDefaultValues,
	QuotationDefaultValues,
	QuotationTotalsDefaultValues,
} from './DefaultTableContent';
import EntityFieldTypes from './EntityFieldTypes';
import {
	ExpenseRowsTableFields,
	ExpenseTotalsTableFields,
	InvoiceAdvancementStatusTableFields,
	InvoiceAdvancementTableFields,
	InvoiceTotalsTableFields,
	QuotationArchitectColumnFields,
	QuotationArchitectTableFields,
	QuotationTotalsTableFields,
} from './TableFields';

/**
 * @constant
 * @name EntityTypes
 * @description An object containing all the entity types handled by the editor along with their display names
 *
 * Note: when adding an entity type, remember to wire it to the {@link EntitiesList} logic
 *
 * @author Yann Hodiesne
 */
const EntityTypes = Object.freeze({
	NONE: createEntityType('NONE', 'template.pdf.entity_type.none', Slash, []),
	CONTACT: createEntityType('CONTACT', 'contact.name', User, [
		createEntityField('contact_firstName', 'contact.fields.first_name', EntityFieldTypes.STRING, 'firstName'),
		createEntityField('contact_lastName', 'contact.fields.last_name', EntityFieldTypes.STRING, 'lastName'),
		createEntityField('contact_fullName', 'contact.fields.full_name', EntityFieldTypes.STRING, (contact) => formatContactName(contact)),
		createEntityField('contact_address', 'contact.fields.address', EntityFieldTypes.STRING, 'address'),
		createEntityField('contact_postCode', 'contact.fields.post_code', EntityFieldTypes.STRING, 'postCode'),
		createEntityField('contact_city', 'contact.fields.city', EntityFieldTypes.STRING, 'city'),
		createEntityField('contact_postCode_city', 'contact.fields.post_code_city', EntityFieldTypes.STRING, (contact) => concatPostcodeAndCity(contact)),
		createEntityField('contact_country', 'contact.fields.country', EntityFieldTypes.STRING, 'country'),
		createEntityField('contact_email', 'contact.fields.emails', EntityFieldTypes.STRING, (contact) => contact.emails[0]),
		createEntityField('contact_phone', 'contact.fields.phones', EntityFieldTypes.STRING, (contact) => contact.phones[0]?.number ?? ''),
	]),
	PARTNER: createEntityType('PARTNER', 'partner.name', Briefcase, [
		createEntityField('partner_name', 'partner.fields.name', EntityFieldTypes.STRING, 'name'),
		createEntityField('partner_activityDescription', 'partner.fields.activity_description', EntityFieldTypes.STRING, 'activityDescription'),
		createEntityField('partner_address', 'partner.fields.address', EntityFieldTypes.STRING, 'address'),
		createEntityField('partner_postCode', 'partner.fields.post_code', EntityFieldTypes.STRING, 'postCode'),
		createEntityField('partner_city', 'partner.fields.city', EntityFieldTypes.STRING, 'city'),
		createEntityField('partner_postCode_city', 'partner.fields.post_code_city', EntityFieldTypes.STRING, (partner) => concatPostcodeAndCity(partner)),
		createEntityField('partner_country', 'partner.fields.country', EntityFieldTypes.STRING, 'country'),
		createEntityField('partner_legal_representant', 'partner.fields.legal_representant', EntityFieldTypes.STRING, (partner) => formatLegalRepresentantNameWithTitle(partner)),
		createEntityField('partner_email', 'partner.fields.emails', EntityFieldTypes.STRING, (partner) => partner.emails[0]),
		createEntityField('partner_phone', 'partner.fields.phones', EntityFieldTypes.STRING, (partner) => partner.phones[0]?.number ?? ''),
		createEntityField(
			'partner_legal_representant_function',
			'partner.fields.legal_representant_function',
			EntityFieldTypes.STRING,
			(partner) => (partner.legalRepresentant ? formatContactPosition(partner.legalRepresentant) : ''),
		),
		createEntityField('partner_parent', 'partner.fields.parent', EntityFieldTypes.STRING, (partner) => formatPartnerParent(partner)),
		createEntityField('partner_registration_ape', 'partner.fields.registration_ape', EntityFieldTypes.STRING, (partner) => getRegistration(partner, 'Ape')),
		createEntityField('partner_registration_rcs', 'partner.fields.registration_rcs', EntityFieldTypes.STRING, (partner) => getRegistration(partner, 'Rcs')),
		createEntityField('partner_registration_siret', 'partner.fields.registration_siret', EntityFieldTypes.STRING, (partner) => getRegistration(partner, 'Siret')),
		createEntityField('partner_registration_vat', 'partner.fields.registration_vat', EntityFieldTypes.STRING, (partner) => getRegistration(partner, 'Vat')),
	]),
	INVOICE: createEntityType('INVOICE', 'invoice.name', () => SvgIcon({ path: `${conf.iconsPath}app_invoices.svg` }), [
		// invoice main info
		createEntityField('invoice_fullname', 'invoice.fields.fullname', EntityFieldTypes.STRING, 'fullname'),
		createEntityField('invoice_edition_date', 'invoice.fields.edition_date', EntityFieldTypes.STRING, (invoice) => formatDate(invoice.editionDate)),
		createEntityField('invoice_comment', 'invoice.fields.comment', EntityFieldTypes.STRING, 'comment'),
		createEntityField('invoice_reference', 'invoice.fields.reference', EntityFieldTypes.STRING, 'reference'),
		createEntityField('invoice_payment_delay', 'invoice.fields.payment_delay.header', EntityFieldTypes.STRING, (invoice) => formatPaymentDelayName(invoice.paymentDelay)),

		// Linked project info
		createEntityField('invoice_project', 'invoice.fields.project', EntityFieldTypes.STRING, (invoice) => invoice.project.name),
		createEntityField('invoice_project_description', 'invoice.fields.project_description', EntityFieldTypes.STRING, (invoice) => invoice.project.description),
		createEntityField('invoice_project_address', 'invoice.fields.project_address', EntityFieldTypes.STRING, (invoice) => invoice.project.address),
		createEntityField('invoice_project_postCode', 'invoice.fields.project_post_code', EntityFieldTypes.STRING, (invoice) => invoice.project.postCode),
		createEntityField('invoice_project_city', 'invoice.fields.project_city', EntityFieldTypes.STRING, (invoice) => invoice.project.city),
		createEntityField('invoice_project_postCode_city', 'invoice.fields.project_post_code_city', EntityFieldTypes.STRING, (invoice) => concatPostcodeAndCity(invoice.project)),
		createEntityField('invoice_project_country', 'invoice.fields.project_country', EntityFieldTypes.STRING, (invoice) => invoice.project.country),

		// Linked partner/ contact info via project
		createEntityField('invoice_client', 'invoice.fields.client', EntityFieldTypes.STRING, (invoice) => formatProjectClientData(invoice.project).invoicing_name),
		createEntityField(
			'invoice_client_legal_representant',
			'invoice.fields.client_legal_representant',
			EntityFieldTypes.STRING,
			(invoice) => formatProjectClientData(invoice.project).invoicing_legalRepresentant,
		),
		createEntityField(
			'invoice_client_legal_representant_position',
			'invoice.fields.client_legal_representant_position',
			EntityFieldTypes.STRING,
			(invoice) => formatProjectClientData(invoice.project).invoicing_legalRepresentantPosition,
		),
		createEntityField(
			'invoice_client_address', 'invoice.fields.client_address', EntityFieldTypes.STRING, (invoice) => formatProjectClientData(invoice.project).invoicing_address
		),
		createEntityField(
			'invoice_client_post_code', 'invoice.fields.client_post_code', EntityFieldTypes.STRING, (invoice) => formatProjectClientData(invoice.project).invoicing_postCode
		),
		createEntityField('invoice_client_city', 'invoice.fields.client_city', EntityFieldTypes.STRING, (invoice) => formatProjectClientData(invoice.project).invoicing_city),
		createEntityField(
			'invoice_client_post_code_city',
			'invoice.fields.client_post_code_city',
			EntityFieldTypes.STRING,
			(invoice) => concatPostcodeAndCity(formatProjectClientData(invoice.project).projectInvoicedClient),
		),
		createEntityField(
			'invoice_client_country', 'invoice.fields.client_country', EntityFieldTypes.STRING, (invoice) => formatProjectClientData(invoice.project).invoicing_country
		),
		createEntityField('invoice_client_email', 'invoice.fields.client_email', EntityFieldTypes.STRING, (invoice) => formatProjectClientData(invoice.project).invoicing_email),
		createEntityField('invoice_client_phone', 'invoice.fields.client_phone', EntityFieldTypes.STRING, (invoice) => formatProjectClientData(invoice.project).invoicing_phone),
		createEntityField(
			'invoice_client_registration_ape',
			'invoice.fields.client_registration_ape',
			EntityFieldTypes.STRING,
			(invoice) => getRegistration(formatProjectClientData(invoice.project).projectInvoicedClient, 'Ape'),
		),
		createEntityField(
			'invoice_client_registration_rcs',
			'invoice.fields.client_registration_rcs',
			EntityFieldTypes.STRING,
			(invoice) => getRegistration(formatProjectClientData(invoice.project).projectInvoicedClient, 'Rcs'),
		),
		createEntityField(
			'invoice_client_registration_siret',
			'invoice.fields.client_registration_siret',
			EntityFieldTypes.STRING,
			(invoice) => getRegistration(formatProjectClientData(invoice.project).projectInvoicedClient, 'Siret'),
		),
		createEntityField(
			'invoice_client_registration_vat',
			'invoice.fields.client_registration_vat',
			EntityFieldTypes.STRING,
			(invoice) => getRegistration(formatProjectClientData(invoice.project).projectInvoicedClient, 'Vat'),
		),

		// Invoice rows and totals
		createEntityField('invoice_rows',
			'invoice.fields.rows',
			EntityFieldTypes.ARRAY,
			undefined,
			InvoiceAdvancementTableFields,
			InvoiceDefaultValues,
			(entity) => formatInvoiceForTemplates(entity)),
		createEntityField('invoice_totals',
			'invoice.fields.totals',
			EntityFieldTypes.ARRAY,
			undefined,
			InvoiceTotalsTableFields,
			InvoiceTotalsDefaultValues,
			(entity) => formatInvoiceTotalsForTemplates(entity)),
		createEntityField('quotation_advancement_status',
			'invoice.fields.advancement_status',
			EntityFieldTypes.ARRAY,
			undefined,
			InvoiceAdvancementStatusTableFields,
			InvoiceAdvancementStatusDefaultValues,
			(entity) => formatInvoiceAdvancementStatusForTemplates(entity)),
	], true),
	QUOTATION: createEntityType('QUOTATION', 'quotation.name', () => SvgIcon({ path: `${conf.iconsPath}app_quotations.svg` }), [
		// Quotation main info
		createEntityField('quotation_fullname', 'quotation.fields.fullname', EntityFieldTypes.STRING, 'fullname'),
		createEntityField('quotation_edition_date', 'quotation.fields.edition_date', EntityFieldTypes.STRING, (quotation) => formatDate(quotation.editionDate)),
		createEntityField('quotation_validityDate', 'quotation.fields.validity_date', EntityFieldTypes.DATE, (quotation) => formatDate(quotation.validityDate)),
		createEntityField('quotation_description', 'quotation.fields.description', EntityFieldTypes.DATE, 'description'),
		createEntityField('quotation_project_amount', 'quotation.fields.project_amount', EntityFieldTypes.STRING, (quotation) => formatNumber(quotation.projectAmount)),
		createEntityField('quotation_currency', 'quotation.fields.currency', EntityFieldTypes.STRING, (quotation) => quotation.currency.name),

		// Linked project info
		createEntityField('quotation_project', 'quotation.fields.project', EntityFieldTypes.STRING, (quotation) => quotation.project.name),
		createEntityField('quotation_project_description', 'quotation.fields.project_description', EntityFieldTypes.STRING, (quotation) => quotation.project.description),
		createEntityField('quotation_project_address', 'quotation.fields.project_address', EntityFieldTypes.STRING, (quotation) => quotation.project.address),
		createEntityField('quotation_project_postCode', 'quotation.fields.project_post_code', EntityFieldTypes.STRING, (quotation) => quotation.project.postCode),
		createEntityField('quotation_project_city', 'quotation.fields.project_city', EntityFieldTypes.STRING, (quotation) => quotation.project.city),
		createEntityField(
			'quotation_project_postCode_city', 'quotation.fields.project_post_code_city', EntityFieldTypes.STRING, (quotation) => concatPostcodeAndCity(quotation.project)
		),
		createEntityField('quotation_project_country', 'quotation.fields.project_country', EntityFieldTypes.STRING, (quotation) => quotation.project.country),

		// Linked partner/ contact info via project
		createEntityField('quotation_client', 'quotation.fields.client', EntityFieldTypes.STRING, (quotation) => formatProjectClientData(quotation.project).name),
		createEntityField(
			'quotation_client_legal_representant',
			'quotation.fields.client_legal_representant',
			EntityFieldTypes.STRING,
			(quotation) => formatProjectClientData(quotation.project).legalRepresentant,
		),
		createEntityField(
			'quotation_client_legal_representant_postion',
			'quotation.fields.client_legal_representant_position',
			EntityFieldTypes.STRING,
			(quotation) => formatProjectClientData(quotation.project).legalRepresentantPosition,
		),
		createEntityField(
			'quotation_client_address', 'quotation.fields.client_address', EntityFieldTypes.STRING, (quotation) => formatProjectClientData(quotation.project).address
		),
		createEntityField(
			'quotation_client_post_code', 'quotation.fields.client_post_code', EntityFieldTypes.STRING, (quotation) => formatProjectClientData(quotation.project).postCode
		),
		createEntityField('quotation_client_city', 'quotation.fields.client_city', EntityFieldTypes.STRING, (quotation) => formatProjectClientData(quotation.project).city),
		createEntityField(
			'quotation_client_post_code_city',
			'quotation.fields.client_post_code_city',
			EntityFieldTypes.STRING,
			(quotation) => concatPostcodeAndCity(formatProjectClientData(quotation.project)),
		),
		createEntityField(
			'quotation_client_country', 'quotation.fields.client_country', EntityFieldTypes.STRING, (quotation) => formatProjectClientData(quotation.project).country
		),
		createEntityField('quotation_client_email', 'quotation.fields.client_email', EntityFieldTypes.STRING, (quotation) => formatProjectClientData(quotation.project).email),
		createEntityField('quotation_client_phone', 'quotation.fields.client_phone', EntityFieldTypes.STRING, (quotation) => formatProjectClientData(quotation.project).phone),
		createEntityField(
			'quotation_client_registration_ape',
			'quotation.fields.client_registration_ape',
			EntityFieldTypes.STRING,
			(quotation) => getRegistration(formatProjectClientData(quotation.project).projectClient, 'Ape'),
		),
		createEntityField(
			'quotation_client_registration_rcs',
			'quotation.fields.client_registration_rcs',
			EntityFieldTypes.STRING,
			(quotation) => getRegistration(formatProjectClientData(quotation.project).projectClient, 'Rcs'),
		),
		createEntityField(
			'quotation_client_registration_siret',
			'quotation.fields.client_registration_siret',
			EntityFieldTypes.STRING,
			(quotation) => getRegistration(formatProjectClientData(quotation.project).projectClient, 'Siret'),
		),
		createEntityField(
			'quotation_client_registration_vat',
			'quotation.fields.client_registration_vat',
			EntityFieldTypes.STRING,
			(quotation) => getRegistration(formatProjectClientData(quotation.project).projectClient, 'Vat'),
		),

		// Quotation rows and totals
		createEntityField('quotation_columns',
			'quotation.fields.columns',
			EntityFieldTypes.ARRAY,
			undefined,
			QuotationArchitectColumnFields,
			QuotationColumnsDefaultValues,
			(entity) => formatQuotationColumnsForTemplates(entity)),
		createEntityField('quotation_rows',
			'quotation.fields.rows',
			EntityFieldTypes.ARRAY,
			undefined,
			QuotationArchitectTableFields,
			QuotationDefaultValues,
			(entity) => formatQuotationForTemplates(entity)),
		createEntityField('quotation_totals',
			'quotation.fields.totals',
			EntityFieldTypes.ARRAY,
			undefined,
			QuotationTotalsTableFields,
			QuotationTotalsDefaultValues,
			(entity) => formatQuotationTotalsForTemplates(entity)),
	], true),
	EXPENSE: createEntityType('EXPENSE', 'expense.name', () => SvgIcon({ path: `${conf.iconsPath}app_human_resources.svg` }), [
		// Expense main info
		createEntityField('expense_fullname', 'expense.fields.fullname', EntityFieldTypes.STRING, 'fullname'),
		createEntityField('expense_net_total', 'expense.fields.net_total', EntityFieldTypes.STRING, 'netTotal'),
		createEntityField('expense_edition_date', 'expense.fields.edition_date', EntityFieldTypes.STRING, (expense) => formatDate(expense.editionDate)),

		// Expense user/ contact info
		createEntityField('expense_user', 'expense.fields.user', EntityFieldTypes.STRING, (expense) => expense.user.username),
		createEntityField('expense_contact_firstName', 'expense.fields.user_first_name', EntityFieldTypes.STRING, (expense) => expense.user.contact.firstName),
		createEntityField('expense_contact_lastName', 'expense.fields.user_last_name', EntityFieldTypes.STRING, (expense) => expense.user.contact.lastName),
		createEntityField('expense_contact_fullName', 'expense.fields.user_full_name', EntityFieldTypes.STRING, (expense) => formatContactName(expense.user.contact)),
		createEntityField('expense_contact_position', 'expense.fields.user_position', EntityFieldTypes.STRING, (expense) => expense.user.contact.position?.name),

		// Expense linked info
		createEntityField('expense_structure', 'expense.fields.structure', EntityFieldTypes.STRING, (expense) => expense.structure?.name),
		createEntityField('expense_structure_desc', 'expense.fields.structure_description', EntityFieldTypes.STRING, (expense) => expense.structure?.activityDescription),
		createEntityField('expense_currency', 'expense.fields.currency', EntityFieldTypes.STRING, (expense) => expense.currency?.name),

		// Expense rows and totals
		createEntityField('expense_rows',
			'expense.fields.rows',
			EntityFieldTypes.ARRAY,
			undefined,
			ExpenseRowsTableFields,
			ExpenseDefaultValues,
			(entity) => formatExpenseForTemplates(entity)),
		createEntityField('expense_totals',
			'expense.fields.totals',
			EntityFieldTypes.ARRAY,
			undefined,
			ExpenseTotalsTableFields,
			ExpenseTotalsDefaultValues,
			(entity) => formatExpenseTotalsForTemplates(entity)),
	], true),
});

/**
 * @constant
 * @name IssuableEntityTypes
 * @description An array containing the IDs of all entity types that can be issued on export
 *
 * @author Yann Hodiesne
 */
export const IssuableEntityTypes = Object.freeze(Object.values(EntityTypes).filter(({ canBeIssued }) => canBeIssued).map(({ type }) => type));

export default EntityTypes;
