import { ActionTypes } from '../../actions/sectors/sectors';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_SECTOR_LIST_REQUEST:
		case ActionTypes.ADD_SECTOR_REQUEST:
		case ActionTypes.UPDATE_SECTOR_REQUEST:
		case ActionTypes.REMOVE_SECTOR_REQUEST:
		case ActionTypes.ARCHIVE_SECTOR_REQUEST:
		case ActionTypes.RESTORE_SECTOR_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_SECTOR_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_SECTOR_LIST_FAILURE:
		case ActionTypes.FETCH_SECTOR_FAILURE:
		case ActionTypes.ADD_SECTOR_FAILURE:
		case ActionTypes.UPDATE_SECTOR_FAILURE:
		case ActionTypes.REMOVE_SECTOR_FAILURE:
		case ActionTypes.ARCHIVE_SECTOR_FAILURE:
		case ActionTypes.RESTORE_SECTOR_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_SECTOR_SUCCESS:
		case ActionTypes.UPDATE_SECTOR_SUCCESS:
		case ActionTypes.REMOVE_SECTOR_SUCCESS:
		case ActionTypes.ARCHIVE_SECTOR_SUCCESS:
		case ActionTypes.RESTORE_SECTOR_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_SECTOR_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: payload.sectors,
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_SECTOR_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.sector,
			};
		default:
			return state;
	}
};
