import { useCallback, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { formatContactName } from 'lib/contacts/formatContactData';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatDate, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchProjectsForList } from 'redux/actions/projects/projectsList';
import { useProjectsForListSelector, useProjectsListTotalCountSelector } from 'redux/selectors/projects/projectsList';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ActionsCell, CollaboratorsCell, ResponsiblesCell } from 'components/projects/projectList';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name ProjectListPage
 * @description A page displaying the list of all projects.
 *
 * From this page, the user is able to view, create and edit any project.
 *
 * @author Marin Catel-Guihomat
 *
 */
const ProjectListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const projects = useProjectsForListSelector();
	const totalCount = useProjectsListTotalCountSelector();

	const canCreateProject = useAccessRight(AccessRights.operations.projects.enhancedRights.CREATE_PROJECT);

	const headers = useMemo(() => [
		{ accessor: 'name', Header: t('projects.fields.name'), pinColumn: true, sorting: 'project.name' },
		{ id: 'actions', Header: '', Cell: ActionsCell, name: t('projects.fields.actions'), pinColumn: true },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('projects.fields.archived.yes') : t('projects.fields.archived.no')),
			Header: t('projects.fields.archived.header'),
			sorting: 'project.archived',
		},
		{ accessor: 'description', Header: t('projects.fields.description'), sorting: 'project.description' },
		{ id: 'from', accessor: (row) => formatDate(row.from), Header: t('projects.fields.begin_date'), sorting: 'project.from' },
		{ id: 'to', accessor: (row) => formatDate(row.to), Header: t('projects.fields.end_date'), sorting: 'project.to' },
		{ id: 'on', accessor: (row) => formatDate(row.on), Header: t('projects.fields.at_date'), sorting: 'project.on' },
		{
			id: 'contact',
			accessor: (row) => (formatContactName(row.contact ?? {})),
			Header: t('projects.fields.contact'),
			sorting: 'project.contact',
		},
		{ accessor: 'partner.name', Header: t('projects.fields.partner'), sorting: 'project.partner' },
		{ id: 'responsibles', Header: t('projects.fields.responsibles'), Cell: ResponsiblesCell, sorting: 'project.responsibles' },
		{ id: 'collaborators', Header: t('projects.fields.collaborators'), Cell: CollaboratorsCell, sorting: 'project.collaborators' },
		{ accessor: 'address', Header: t('projects.fields.address'), sorting: 'project.address' },
		{ accessor: 'postCode', Header: t('projects.fields.post_code'), sorting: 'project.postCode' },
		{ accessor: 'city', Header: t('projects.fields.city'), sorting: 'project.city' },
		{ accessor: 'country', Header: t('projects.fields.country'), sorting: 'project.country' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('projects.fields.creation_name'), sorting: 'project.createdBy' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('projects.fields.creation_date'), sorting: 'project.createdAt' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('projects.fields.update_name'), sorting: 'project.updatedBy' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('projects.fields.update_date'), sorting: 'project.updatedAt' },
	], [t]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchProjectsForList(params)));

	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('project');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.operations.projects.projectCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateProject && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('projects.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				disableSelection
				headers={headers}
				rows={projects}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="projects"
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('projects.pages.list')} {...modalProps} />
		</div>
	);
};

export default ProjectListPage;
