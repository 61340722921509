import { useCallback, useMemo, useRef } from 'react';

/**
 * @name useAnchor
 * @description A custom hook capable providing an Anchor component and a function to trigger a scroll to the given Anchor's position
 *
 * @author Yann Hodiesne
 *
 * @param {bool} [instant]  Determines if the scroll should be smooth (default) or instantaneous.
 *
 * @returns {Array}
 */
export default (instant, block = 'start') => {
	// Ref used to keep track of the anchor
	const anchorRef = useRef();

	// The anchor itself, to be integrated inside the rendered page
	const anchor = useMemo(() => <span ref={anchorRef} />, [anchorRef]);

	// The function triggering the scroll to the anchor
	const scrollTo = useCallback(() => {
		anchorRef.current.scrollIntoView({ behavior: instant ? 'instant' : 'smooth', block });
	}, [block, instant]);

	return [anchor, scrollTo];
};
