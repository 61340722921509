import { memo, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/buttons';
import { removeElements } from 'components/templates/pdf/editor/reducer/actions';

import EditorContext from '../../../EditorContext';
import { TabHeader, TabPanel } from '../tabLayout';

import { AdvancedProperties, BasicProperties } from './tabs';

/**
 * @constant
 * @name AvailableTabs
 * @description An enumeration of the tabs available to the user when the underlying component is shown.
 *
 * @author Yann Hodiesne
 */
const AvailableTabs = Object.freeze({
	BASIC: 'BASIC',
	ADVANCED: 'ADVANCED',
});

/**
 * @name ElementProperties
 * @description Displays the selected element's properties and lets the user change them at will.
 *
 * @author Yann Hodiesne
 */
const ElementProperties = () => {
	const { t } = useTranslation();

	const [selectedTab, setSelectedTab] = useState(AvailableTabs.BASIC);
	const editorContext = useContext(EditorContext);
	const { dispatch, selectedElements } = editorContext;

	const selectedElement = selectedElements[0];

	/**
	 * @function
	 * @name removeSelectedElement
	 * @description Removes the selected element from the editor.
	 *
	 * @author Timothée Simon-Franza
	 */
	const removeSelectedElement = useCallback(() => {
		dispatch(removeElements([selectedElement.id]));
	}, [dispatch, selectedElement.id]);

	return (
		<>
			<div role="tablist">
				<TabHeader
					id="basic-properties-tab"
					linkedPanelId="basic-properties-panel"
					selected={selectedTab === AvailableTabs.BASIC}
					onClick={() => setSelectedTab(AvailableTabs.BASIC)}
				>
					{t('template.pdf.editor.toolbox.element_properties.basic')}
				</TabHeader>
				<TabHeader
					id="advanced-properties-tab"
					linkedPanelId="advanced-properties-panel"
					selected={selectedTab === AvailableTabs.ADVANCED}
					onClick={() => setSelectedTab(AvailableTabs.ADVANCED)}
				>
					{t('template.pdf.editor.toolbox.element_properties.advanced')}
				</TabHeader>
			</div>
			<TabPanel
				id="basic-properties-panel"
				linkedTabId="basic-properties-tab"
				hidden={selectedTab !== AvailableTabs.BASIC}
			>
				<BasicProperties />
			</TabPanel>
			<TabPanel
				id="advanced-properties-panel"
				linkedTabId="advanced-properties-tab"
				hidden={selectedTab !== AvailableTabs.ADVANCED}
			>
				<AdvancedProperties />
			</TabPanel>
			<Button className="danger-outlined" onClick={removeSelectedElement}>
				{t('template.pdf.editor.actions.remove_element.button_text')}
			</Button>
		</>
	);
};

export default memo(ElementProperties);
