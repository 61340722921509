import PropTypes from 'prop-types';

import { IndeterminateCheckbox } from 'components/shared/inputs';

/**
 * @name HeaderSelectionCell
 * @description A custom header component wired to the row selection mechanism
 *
 * @author Yann Hodiesne
 *
 * @param {func} getToggleAllPageRowsSelectedProps  returns the managed props to give to the checkbox
 */
const HeaderSelectionCell = ({ getToggleAllPageRowsSelectedProps }) => (
	<IndeterminateCheckbox data-testid="table-header-selection-checkbox" {...getToggleAllPageRowsSelectedProps()} />
);

HeaderSelectionCell.propTypes = {
	getToggleAllPageRowsSelectedProps: PropTypes.func.isRequired,
};

export default HeaderSelectionCell;
