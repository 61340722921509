import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addAccessDefinition } from 'redux/actions/accessDefinitions';
import routes from 'routes';

import { AccessDefinitionCreationForm } from 'components/accessDefinitions';

/**
 * @name AccessDefinitionCreationPage
 * @description A page used to create an access definition.
 *
 * @author Matthieu Schaerlinger
 * @author Roland Margelidon
 *
 */
const AccessDefinitionCreationPage = () => {
	const dispatch = useDispatch();

	const handleFormSubmit = useCallback((formData) => {
		dispatch(addAccessDefinition(formData, routes.humanResources.accessDefinitions.list));
	}, [dispatch]);

	return (
		<AccessDefinitionCreationForm onSubmit={handleFormSubmit} />
	);
};

AccessDefinitionCreationPage.propTypes = {};

export default AccessDefinitionCreationPage;
