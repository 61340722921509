import { toast } from 'react-toastify';
import * as CompaniesApi from 'api/companiesApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Companies redux state.
 * @type {object}
 */
export const ActionTypes = {
	FETCH_COMPANY_REQUEST: '@COMPANIES/FETCH_REQUEST',
	FETCH_COMPANY_SUCCESS: '@COMPANIES/FETCH_SUCCESS',
	FETCH_COMPANY_FAILURE: '@COMPANIES/FETCH_FAILURE',

	FETCH_COMPANY_LIST_REQUEST: '@COMPANIES/FETCH_LIST_REQUEST',
	FETCH_COMPANY_LIST_SUCCESS: '@COMPANIES/FETCH_LIST_SUCCESS',
	FETCH_COMPANY_LIST_FAILURE: '@COMPANIES/FETCH_LIST_FAILURE',

	ADD_COMPANY_REQUEST: '@COMPANIES/ADD_REQUEST',
	ADD_COMPANY_SUCCESS: '@COMPANIES/ADD_SUCCESS',
	ADD_COMPANY_FAILURE: '@COMPANIES/ADD_FAILURE',

	ENABLE_COMPANY_REQUEST: '@COMPANIES/ENABLE_REQUEST',
	ENABLE_COMPANY_SUCCESS: '@COMPANIES/ENABLE_SUCCESS',
	ENABLE_COMPANY_FAILURE: '@COMPANIES/ENABLE_FAILURE',

	UPDATE_COMPANY_REQUEST: '@COMPANIES/UPDATE_REQUEST',
	UPDATE_COMPANY_SUCCESS: '@COMPANIES/UPDATE_SUCCESS',
	UPDATE_COMPANY_FAILURE: '@COMPANIES/UPDATE_FAILURE',

	REMOVE_COMPANY_REQUEST: '@COMPANIES/REMOVE_REQUEST',
	REMOVE_COMPANY_SUCCESS: '@COMPANIES/REMOVE_SUCCESS',
	REMOVE_COMPANY_FAILURE: '@COMPANIES/REMOVE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Company list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchCompanyListRequest
 * @description Action triggered anytime a company list call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const fetchCompanyListRequest = () => ({ type: ActionTypes.FETCH_COMPANY_LIST_REQUEST });

/**
 * @function
 * @name fetchCompanyListSuccess
 * @description Action triggered as a result to a successful company list fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} companies	 The list of retrieved companies.
 * @param {number} totalCount	 The total amount of companies available in the database to the current user.
 *
 * @returns {object}
 */
const fetchCompanyListSuccess = ({ companies, totalCount }) => ({
	type: ActionTypes.FETCH_COMPANY_LIST_SUCCESS,
	payload: { companies, totalCount },
});

/**
 * @function
 * @name fetchCompanyListFailure
 * @description Action triggered as a result to a failed company list fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchCompanyListFailure = (error) => ({
	type: ActionTypes.FETCH_COMPANY_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific company fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchCompanyRequest
 * @description Action triggered anytime a company fetching call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const fetchCompanyRequest = () => ({ type: ActionTypes.FETCH_COMPANY_REQUEST });

/**
 * @function
 * @name fetchCompanySuccess
 * @description Action triggered as a result to a successful company fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} company  The retrieved company object.
 *
 * @returns {object}
 */
const fetchCompanySuccess = ({ company }) => ({
	type: ActionTypes.FETCH_COMPANY_SUCCESS,
	payload: { company },
});

/**
 * @function
 * @name fetchCompanyFailure
 * @description Action triggered as a result to a failed company fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchCompanyFailure = (error) => ({
	type: ActionTypes.FETCH_COMPANY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Company creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addCompanyRequest
 * @description Action triggered anytime a company creation call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const addCompanyRequest = () => ({ type: ActionTypes.ADD_COMPANY_REQUEST });

/**
 * @function
 * @name addCompanySuccess
 * @description Action triggered as a result to a successful company creation API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} company  The created company object.
 *
 * @returns {object}
 */
const addCompanySuccess = ({ company }) => ({
	type: ActionTypes.ADD_COMPANY_SUCCESS,
	payload: { company },
});

/**
 * @function
 * @name addCompanyFailure
 * @description Action triggered as a result to a failed company creation API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error  The exception sent back from the API call.
 *
 * @returns {object}
 */
const addCompanyFailure = (error) => ({
	type: ActionTypes.ADD_COMPANY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Company enable actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name enableCompanyRequest
 * @description Action triggered anytime a company enable call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const enableCompanyRequest = () => ({ type: ActionTypes.ENABLE_COMPANY_REQUEST });

/**
 * @function
 * @name enableCompanySuccess
 * @description Action triggered as a result to a successful company enable API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} company  The enabled company object.
 *
 * @returns {object}
 */
const enableCompanySuccess = ({ company }) => ({
	type: ActionTypes.ENABLE_COMPANY_SUCCESS,
	payload: { company },
});

/**
 * @function
 * @name enableCompanyFailure
 * @description Action triggered as a result to a failed company update API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error  The exception sent back from the API call.
 *
 * @returns {object}
 */
const enableCompanyFailure = (error) => ({
	type: ActionTypes.ENABLE_COMPANY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Company update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateCompanyRequest
 * @description Action triggered anytime a company update call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const updateCompanyRequest = () => ({ type: ActionTypes.UPDATE_COMPANY_REQUEST });

/**
 * @function
 * @name updateCompanySuccess
 * @description Action triggered as a result to a successful company update API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} company  The updated company object.
 *
 * @returns {object}
 */
const updateCompanySuccess = ({ company }) => ({
	type: ActionTypes.UPDATE_COMPANY_SUCCESS,
	payload: { company },
});

/**
 * @function
 * @name updateCompanyFailure
 * @description Action triggered as a result to a failed company update API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error  The exception sent back from the API call.
 *
 * @returns {object}
 */
const updateCompanyFailure = (error) => ({
	type: ActionTypes.UPDATE_COMPANY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Company removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeCompanyRequest
 * @description Action triggered anytime a company removal call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const removeCompanyRequest = () => ({ type: ActionTypes.REMOVE_COMPANY_REQUEST });

/**
 * @function
 * @name removeCompanySuccess
 * @description Action triggered as a result to a successful company removal API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} company  The removed company object.
 *
 * @returns {object}
 */
const removeCompanySuccess = ({ company }) => ({
	type: ActionTypes.REMOVE_COMPANY_SUCCESS,
	payload: { company },
});

/**
 * @function
 * @name removeCompanyFailure
 * @description Action triggered as a result to a failed company removal API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error  The exception sent back from the API call.
 *
 * @returns {object}
 */
const removeCompanyFailure = (error) => ({
	type: ActionTypes.REMOVE_COMPANY_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchCompanyList
 * @description Method used to update the company list.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} params  The parameters used to match user filters
 */
export const fetchCompanyList = (params) => (dispatch) => {
	dispatch(fetchCompanyListRequest());

	return CompaniesApi.fetchCompanies(params)
		.then(({ companies, totalCount }) => dispatch(fetchCompanyListSuccess({ companies, totalCount })))
		.catch((error) => dispatch(fetchCompanyListFailure(error)));
};

/**
 * @function
 * @name fetchCompany
 * @description Method used to fetch the latest version of a specific company object.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} companyId  The id of the company object we want to retrieve the latest version of.
 */
export const fetchCompany = (companyId) => (dispatch) => {
	dispatch(fetchCompanyRequest());

	return CompaniesApi.fetchCompanyById(companyId)
		.then(({ company }) => dispatch(fetchCompanySuccess({ company })))
		.catch((error) => dispatch(fetchCompanyFailure(error)));
};

/**
 * @function
 * @name addCompany
 * @description Method used to add a new company instance to the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} companyData 		 The data to create the new company from.
 * @param {string} [onSuccessRoute]	 The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addCompany = (companyData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addCompanyRequest());

	return CompaniesApi.createCompany(companyData)
		.then(({ company }) => {
			toast.success(i18next.t('super_admin.company.creation.toasts.success', { name: company.name }));
			dispatch(addCompanySuccess({ company }));
			redirectOnSuccess(onSuccessRoute);

			return company;
		})
		.catch((error) => {
			toast.error(i18next.t('super_admin.company.creation.toasts.error'));
			dispatch(addCompanyFailure(error));
		});
};

/**
 * @function
 * @name enableCompany
 * @description Enables a company using its id, and grants access to the contact, partner and project applications to the company owner.
 *
 * @author Timothée Simon-Franza
 *
 * @author Timothée Simon-Franza
 *
 * @param {string}	companyId	 this Id of the company we wish to enable.
 * @param {bool}	enabled		 if set to true, the company will be enabled, disabled otherwise.
 */
export const enableCompany = ({ enabled }, companyId) => (dispatch) => {
	dispatch(enableCompanyRequest());

	return CompaniesApi.enableCompany({ enabled }, companyId)
		.then(({ company }) => {
			toast.success(i18next.t(`super_admin.company.${enabled ? 'enable' : 'removal'}.toasts.success`, { name: company.name }));
			dispatch(enableCompanySuccess({ company }));
		})
		.catch((error) => {
			toast.error(i18next.t('super_admin.company.enable.toasts.error'));
			dispatch(enableCompanyFailure(error));
		});
};

/**
 * @function
 * @name batchEnableCompanies
 * @description Enables all companies inside the companies array in parameter using the enabledCompany method.
 *
 * @author Timothée Simon-Franza
 *
 * @param {Array} companies  An array of all companies to enable.
 */
export const batchEnableCompanies = (companies) => (dispatch) => {
	companies.forEach(({ enabled, id }) => {
		dispatch(enableCompany({ enabled: !enabled }, id));
	});
};

/**
 * @function
 * @name updateCompany
 * @description Method used to update an existing company instance from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} companyData		 The data to update the company with.
 * @param {string} companyId		 The id of the company we're trying to update.
 * @param {string} [onSuccessRoute]	 The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateCompany = (companyData, companyId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateCompanyRequest());

	return CompaniesApi.updateCompany(companyData, companyId)
		.then(({ company }) => {
			toast.success(i18next.t('super_admin.company.update.toasts.success', { name: company.name }));
			dispatch(updateCompanySuccess({ company }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch((error) => {
			toast.error(i18next.t('super_admin.company.update.toasts.error'));
			dispatch(updateCompanyFailure(error));
		});
};

/**
 * @function
 * @name removeCompany
 * @description Method used to deactivate an existing company instance from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} company		The company we want to remove.
 * @param {string} company.id	The id of the company we want to remove.
 */
export const removeCompany = ({ id }) => (dispatch) => {
	dispatch(removeCompanyRequest());

	return CompaniesApi.removeCompany(id)
		.then(({ company }) => {
			toast.success(i18next.t('super_admin.company.removal.toasts.success', { name: company.name }));
			dispatch(removeCompanySuccess({ company }));
		})
		.catch((error) => {
			toast.error(i18next.t('super_admin.company.removal.toasts.error'));
			dispatch(removeCompanyFailure(error));
		});
};
