import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchCompany } from 'redux/actions/companies';
import { updateContact } from 'redux/actions/contacts/contacts';
import { updatePartner } from 'redux/actions/partners/partners';
import { useCurrentCompanySelector } from 'redux/selectors/companies';

import { ContactEditionForm } from 'components/contacts';
import { PartnerEditionForm } from 'components/partners';
import { TabPanel } from 'components/shared/layout/tabs';
import { Canon } from 'components/shared/Typography';
import { CompanyActivationToggle } from 'components/superadmin';
import { TabHeader } from 'components/templates/pdf/editor/components/toolbox/tabLayout';

import CompanyApplicationManagementTab from './CompanyEditionPage/CompanyApplicationManagementTab';

/**
 * @name CompanyEditionPage
 * @description	A page used to update a given company.
 *
 * @author Yann Hodiesne
 * @author Audrey Clerc
 *
 * @requires NPM:react-redux
 * @requires redux/actions/companies:fetchCompany
 * @requires /routes:keys
 *
 * @param {array}	applications					An array of all available applications.
 * @param {string}	companyId						The id of the company we want to update.
 * @param {object}	company							The company corresponding to the companyId parameter.
 * @param {array}	currencies 						An array of all available currencies.
 */
const CompanyEditionPage = () => {
	const { id: companyId } = useParams();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const company = useCurrentCompanySelector(companyId);

	useEffect(() => {
		dispatch(fetchCompany(companyId));
	}, [companyId, dispatch]);

	const [selectedTabId, setSelectedTabId] = useState('contact-panel');

	const onTabSelectionChanged = useCallback((tabId) => {
		setSelectedTabId(tabId);
	}, []);

	const handleFormSubmit = useCallback((formId, formData) => {
		if (formId === 'contact') {
			dispatch(updateContact(formData, company.companyOwner.contact.id));
		} else if (formId === 'partner') {
			dispatch(updatePartner(formData, company.mainPartner.id));
		}
	}, [company?.companyOwner?.contact?.id, company?.mainPartner?.id, dispatch]);

	return (
		<>
			<Canon as="h1" lineHeight="initial" mb=".1em">{company?.name}</Canon>
			{company ? <CompanyActivationToggle companyId={company?.id} enabled={company?.enabled} label={t('super_admin.company.fields.enabled')} /> : ''}
			<div className="partnervisualisation">
				<div className="format-content">
					<div role="tablist">
						<TabHeader
							linkedPanelId="contact-panel"
							id="contact"
							selected={selectedTabId === 'contact-panel'}
							onClick={() => onTabSelectionChanged('contact-panel')}
						>
							{t('super_admin.company.tabs.contact')}
						</TabHeader>
						<TabHeader
							linkedPanelId="partner-panel"
							id="partner"
							selected={selectedTabId === 'partner-panel'}
							onClick={() => onTabSelectionChanged('partner-panel')}
						>
							{t('super_admin.company.tabs.partner')}
						</TabHeader>
						<TabHeader
							linkedPanelId="application-panel"
							id="application"
							selected={selectedTabId === 'application-panel'}
							onClick={() => onTabSelectionChanged('application-panel')}
						>
							{t('super_admin.company.tabs.applications')}
						</TabHeader>
					</div>
					<TabPanel tabId="contact" tabPanelId="contact-panel" hidden={selectedTabId !== 'contact-panel'}>
						{
							company?.companyOwner?.contact
								? (
									<ContactEditionForm
										contact={company?.companyOwner?.contact}
										onSubmit={(formData) => handleFormSubmit('contact', formData)}
										inSuperAdmin
									/>
								) : <p>{t('contact.edition.loading')}</p>
						}
					</TabPanel>
					<TabPanel tabId="partner" tabPanelId="partner-panel" hidden={selectedTabId !== 'partner-panel'}>
						{
							company?.mainPartner
								? (
									<PartnerEditionForm
										partner={company?.mainPartner}
										onSubmit={(formData) => handleFormSubmit('partner', formData)}
										inSuperAdmin
									/>
								) : <p>{t('contact.edition.loading')}</p>
						}
					</TabPanel>
					<TabPanel tabId="application" tabPanelId="application-panel" hidden={selectedTabId !== 'application-panel'}>
						<CompanyApplicationManagementTab
							companyId={company?.id ?? ''}
							companyApplications={company?.applications ?? []}
						/>
					</TabPanel>
				</div>
			</div>
		</>
	);
};

export default CompanyEditionPage;
