import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addRelationType } from 'redux/actions/relationTypes';
import routes from 'routes';

import { RelationTypeCreationForm } from 'components/relationTypes';

/**
 * @name RelationTypeCreationPage
 * @description A page used to create a new relation type.
 *
 * @author Audrey Clerc
 *
 * @param {func}	dispatchCreateRelationType	A dispatched redux action creator used to create a relation type in the backend's database.
 */
const RelationTypeCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addRelationType(formData, routes.commonModules.relationTypes.list));
	}, [dispatch]);

	return (
		<RelationTypeCreationForm onSubmit={handleFormSubmit} />
	);
};

export default RelationTypeCreationPage;
