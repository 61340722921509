import { memo, useMemo } from 'react';
import { useDragLayer } from 'react-dnd';

import { DragAndDropTypes } from '../constants';

import { ElementTypeDragPreview } from './toolbox/elements';

/**
 * @name EditorDragLayer
 * @description The layer component in charge of displaying the drag and drop preview
 *
 * @author Yann Hodiesne
 */
const EditorDragLayer = () => {
	const { itemType, isDragging, item, currentOffset } = useDragLayer((monitor) => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	}));

	const itemPreview = useMemo(() => {
		switch (itemType) {
			case DragAndDropTypes.PDF_ELEMENT_TYPE:
				return <ElementTypeDragPreview elementType={item} />;
			default:
				return null;
		}
	}, [item, itemType]);

	const transform = `translate(${currentOffset?.x ?? 0}px, ${currentOffset?.y ?? 0}px)`;

	const containerStyles = {
		transform,
		WebkitTransform: transform,
	};

	if (!isDragging || itemPreview === null) {
		return null;
	}

	return (
		<div className="drag-layer">
			<div style={containerStyles}>
				{itemPreview}
			</div>
		</div>
	);
};

export default memo(EditorDragLayer);
