import { forwardRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

/**
 * @name FileInput
 * @description A custom file input component
 *
 * @author Yann Hodiesne
 */
const FileInput = forwardRef(({ placeholder, onChange, ...props }, ref) => {
	const resolvedRef = useForwardedRef(ref);

	const { t } = useTranslation();

	const [fileName, setFileName] = useState(null);

	const onChangeHandler = useCallback((e) => {
		const { files } = e.target;

		if (files && files[0]) {
			setFileName(files[0].name);
		} else {
			setFileName(null);
		}

		onChange(e);
	}, [onChange]);

	return (
		<label className="file-input">
			<button type="button" onClick={() => resolvedRef.current.click()}>
				{t('components.file_input.browse')}
			</button>
			{fileName ?? placeholder ?? t('components.file_input.no_file_selected')}
			<input {...props} ref={resolvedRef} type="file" onChange={onChangeHandler} />
		</label>
	);
});

FileInput.displayName = 'FileInput';

FileInput.propTypes = {
	placeholder: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

FileInput.defaultProps = {
	placeholder: undefined,
};

export default FileInput;
