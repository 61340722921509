import EntityTypes from '../constants/EntityTypes';

import updateElement from './updateElement';
import updateEntity from './updateEntity';

/**
 * @function
 * @name linkEntity
 * @description Links a template entity to the provided application entity, populating the template content with the entity's data.
 *
 * Note: this action must be used in export mode.
 *
 * @author Yann Hodiesne
 *
 * @param {object} state					The current state
 * @param {string} payload.id				The identifier of the template entity to link
 * @param {object} payload.linkedEntity		The application entity to link to
 * @param {string} payload.linkedEntity.id	The identifier of the application entity to link to
 *
 * @returns {object} The updated state value
 */
const linkEntity = (state, { id, linkedEntity }) => {
	const { elements, entities } = state;

	const templateEntity = entities.find((entity) => entity.id === id);

	// We retrieve every table element that is linked to the template entity
	const linkedTableElements = elements.map(
		(row) => row.children.filter((element) => element.tableLinkedEntity === id)
	).flat();

	let result = state;

	const entityFieldArrayCache = {};

	linkedTableElements.forEach((element) => {
		if (element.tableLinkedProperty) {
			if (!entityFieldArrayCache[element.tableLinkedProperty]) {
				// We retrieve the key of the property the current element is linked to then we use
				const property = EntityTypes[templateEntity.type].fields.find(({ id: propertyId }) => propertyId === element.tableLinkedProperty);
				entityFieldArrayCache[element.tableLinkedProperty] = property.format(linkedEntity);
			}

			// We replace the element's placeholder value with the real data retrieved from the linkedEntity
			result = updateElement(result, { id: element.id, content: { content: entityFieldArrayCache[element.tableLinkedProperty] } });
		}
	});

	const linkedElements = elements
		// Retrieve all elements with "link to an entity" checked and a filled linkedEntity field
		.map((row) => row.children.filter((element) => !!element.linkable && !!element.linkedEntity)).flat()
		// Filter the array to keep only the elements linked to the current entity
		.filter(({ linkedEntity: entityId }) => entityId === id);

	const entityFieldValuesCache = {};

	linkedElements.forEach((element) => {
		// If the targeted value hasn't been retrieved yet, we retrieve it and store it inside the entityFieldValuesCache object
		if (!entityFieldValuesCache[element.linkedProperty]) {
			// We retrieve the key of the property the current element is linked to
			const property = EntityTypes[templateEntity.type].fields.find(({ id: propertyId }) => propertyId === element.linkedProperty);
			entityFieldValuesCache[element.linkedProperty] = property.accessor(linkedEntity) ?? '';
		}

		// We replace the element's placeholder value with the real data retrieved from the linkedEntity
		result = updateElement(result, { id: element.id, content: { content: entityFieldValuesCache[element.linkedProperty] } });
	});

	// We store the link between the template entity and the linked entity inside the template entity
	return updateEntity(result, { id, content: { linkedEntity: linkedEntity.id } });
};

export default linkEntity;
