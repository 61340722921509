import { useCallback, useEffect, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { fetchTemplateList } from 'redux/actions/templates';
import { useTemplateListSelector, useTemplateTotalCountSelector } from 'redux/selectors/templates';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import ActionsCell from 'components/templates/templateList/ActionsCell';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name TemplateListPage
 * @description A list of all templates accessible from the template app.
 *
 * From this page, the user is able to view, interact and search for templates.
 *
 * @author Romaric Barthe
 *
 */
const TemplateListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const templates = useTemplateListSelector();
	const totalCount = useTemplateTotalCountSelector();
	const user = useCurrentConnectedUserSelector();

	const canCreateTemplate = useAccessRight(AccessRights.commonSettings.templates.enhancedRights.CREATE_TEMPLATE);

	const headers = useMemo(() => [
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('template.headers.actions') },
		{ accessor: 'name', Header: t('template.fields.name'), pinColumn: true, sorting: 'template.name' },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('template.fields.archived.yes') : t('template.fields.archived.no')),
			Header: t('template.fields.archived.header'),
			sorting: 'template.archived',
		},
		{ id: 'description', accessor: (row) => row.description ?? '', Header: t('template.fields.description'), sorting: 'template.description' },
		{ id: 'type', accessor: (row) => row.type ?? '', Header: t('template.fields.type'), sorting: 'template.type' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('template.fields.creation_date'), sorting: 'template.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('template.fields.update_date'), sorting: 'template.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('template.fields.creation_name'), sorting: 'template.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('template.fields.update_name'), sorting: 'template.updatedBy' },
	], [t]);

	useEffect(() => {
		dispatch(fetchNumberFormatList());
	}, [dispatch]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchTemplateList(params)));

	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('template');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => {
		history.push(generatePath(user.superadmin ? routes.superAdmin.templatePreCreation : routes.settings.templates.templatePreCreation));
	}, [user.superadmin]);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateTemplate && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('template.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={templates}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="template"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('template.pages.list')} {...modalProps} />
		</div>
	);
};

export default TemplateListPage;
