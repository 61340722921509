/* eslint-disable react/button-has-type */
import { forwardRef, useCallback, useMemo } from 'react';
import { Loader } from 'react-feather';
import useForwardedRef from 'lib/hooks/useForwardedRef';
import PropTypes from 'prop-types';

import GenericButton from './GenericButton';

/**
 * @name Button
 * @description A button component.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*}		children				The children to display inside the button.
 * @param {string}	[className=undefined]	The css class names to pass to the HTML element.
 * @param {bool}	[disabled=false]		Whether the button should be disabled.
 * @param {bool}	[isLoading=false]		When set to true, the button will display a loading indicator and ignore clicks.
 * @param {string}	[name=undefined]		Used when we want the button to have a value inside our form.
 * @param {func}	onClick					The method to trigger on click.
 * @param {string}	type					The HTML button type to set to our button (either 'button', 'submit' or 'reset')
 * @param {string}	value					Used when we want the button to have a value inside our form.
 */
const Button = forwardRef(({ children, className, disabled, isLoading, name, onClick, type, value, ...otherProps }, ref) => {
	const resolvedRef = useForwardedRef(ref);

	const handleClick = useCallback((e) => {
		if (!isLoading) {
			onClick(e);
		}
	}, [isLoading, onClick]);

	const processedClassName = useMemo(() => (
		`${className}${isLoading && !disabled ? ' loading' : ''}`
	), [className, disabled, isLoading]);

	return (
		<GenericButton
			{...otherProps}
			className={processedClassName}
			disabled={disabled}
			name={name}
			onClick={handleClick}
			type={type}
			value={value}
			ref={resolvedRef}
		>
			<Loader className="loading-icon" />
			{children}
		</GenericButton>
	);
});

Button.displayName = 'Button';

Button.propTypes = {
	...GenericButton.propTypes,
	isLoading: PropTypes.bool,
};

Button.defaultProps = {
	...GenericButton.defaultProps,
	isLoading: false,
};

export default Button;
