import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneTypes } from 'constants/partnerEnum';

import { FieldArray, SelectInput, TextInput } from 'components/shared/forms/inputs';
import { emailPattern } from 'components/shared/forms/inputs/constants/validationPatterns';
import Validators from 'components/shared/forms/validators';

/**
 * @name PhoneMail
 * @description A form used to group the phone and e-mails fields.
 *
 * @author Romaric Barthe
 */
const PhoneMail = () => {
	const { t } = useTranslation();

	const phoneTypesList = useMemo(() => Object.values(PhoneTypes).map(
		(value) => ({ id: value, label: t(`contact.creation.inputs.phone_type.${value}`) })
	), [t]);

	return (
		<>
			<FieldArray
				label={t('partner.edition.inputs.email.label')}
				name="emails"
				placeholder={t('partner.edition.inputs.email.placeholder')}
				rules={{
					pattern: Validators.matchPattern(emailPattern, t('partner.edition.inputs.email.validation_errors.pattern')),
				}}
				type="email"
				component={TextInput}
				appendButtonTranslationKey="partner.edition.inputs.email.append"
			/>

			{/* @TODO: add a validator : it should not be possible to enter a number without a type */}
			<FieldArray
				label={t('partner.edition.inputs.phone.label')}
				name="phones"
				inputType="tel"
				component={SelectInput}
				options={phoneTypesList}
				labelKey="label"
				valueKey="id"
				selectName="type"
				inputName="number"
				appendButtonTranslationKey="partner.edition.inputs.phone.append"
			/>
		</>
	);
};

export default PhoneMail;
