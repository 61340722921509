import { formatDateTime } from 'lib/shared/format';
import moment from 'moment';

/**
 * @function
 * @name formatQuotationName
 * @description Concatenate number format values to provide a full name for the quotation.
 *
 * @author Romaric Barthe
 *
 * @param {object} quotation  		The quotation to be named.
 * @param {object} numberFormat   	The number format.
 *
 * @returns {string}
 */
export const formatQuotationName = (quotation, numberFormat) => {
	if (!quotation) {
		return undefined;
	}

	if (!numberFormat) {
		return (quotation?.number ?? '').toString();
	}

	const monthString = numberFormat.monthPrefix ? `${moment(quotation.createdAt).format('MM')}-` : '';
	const yearString = numberFormat.yearPrefix ? `${moment(quotation.createdAt).format('YYYY')}-` : '';
	let numberString = (quotation?.number ?? '').toString();
	if (numberFormat.digitNumber && numberString.length < numberFormat.digitNumber) {
		numberString = numberString.padStart(numberFormat.digitNumber, '0');
	}

	return `${numberFormat.rootName}-${yearString}${monthString}${numberString}`;
};

/**
 * @function
 * @name formatQuotationNameList
 * @description Concatenate number format values to provide a full name and reference for the quotation.
 *
 * @author Romaric Barthe
 *
 * @param {object} quotation  		The quotation to be named.
 * @param {object} numberFormat   	The number format.
 *
 * @returns {string}
 */
export const formatQuotationNameList = (quotation, numberFormat) => formatQuotationName(quotation, numberFormat)
	.concat(' [').concat(quotation.project?.name).concat('] > ')
	.concat(formatDateTime(quotation.updatedAt));

/**
* @function
* @name formatProjectName
* @description Returns the provided quotation's project's name if there is one.
*
* @author Romaric Barthe
*
* @param {object} quotation		The quotation serialised.
*
* @returns {string}
*/
export const formatProjectName = (quotation) => ((quotation.project?.name) ?? '');

/**
* @function
* @name formatStructureName
* @description Returns the provided quotation's structure's name if there is one.
*
* @author Romaric Barthe
*
* @param {object} quotation		The quotation serialised.
*
* @returns {string}
*/
export const formatStructureName = (quotation) => ((quotation.structure?.name) ?? '');

/**
* @function
* @name formatSellerName
* @description Returns the provided quotation's seller's name if there is one.
*
* @author Romaric Barthe
*
* @param {object} quotation		The quotation serialised.
*
* @returns {string}
*/
export const formatSellerName = (quotation) => ((quotation.seller?.username) ?? '');

/**
* @function
* @name formatVatRate
* @description Returns the provided quotation's vatRate if there is one.
*
* @author Romaric Barthe
*
* @param {object} quotation		The quotation serialised.
*
* @returns {string}
*/
export const formatVatRate = (quotation) => (quotation.vatRate ? `${quotation.vatRate.rate} %` : '');
