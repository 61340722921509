import { useTranslation } from 'react-i18next';

import { TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 *
 * @param {*} param0
 * @returns
 */
const TargetIdentifier = () => {
	const { t } = useTranslation();

	return (
		<>
			<TextInput
				label={t('targeting.target.creation.inputs.name.label')}
				name="name"
				rules={{
					required: Validators.isRequired(t('targeting.target.creation.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextArea
				label={t('targeting.target.creation.inputs.description.label')}
				name="description"
				type="text"
			/>
		</>
	);
};

export default TargetIdentifier;
