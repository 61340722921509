import isRequired from './isRequired';

/**
 * @function
 * @name matchPattern
 * @description A validation method used to check if the field value match the provided regexp.
 *
 * @author Yann Hodiesne
 *
 * @param {string|number}	value	The input's current value.
 * @param {string}			message	The message to return if the validation fails.
 *
 * @returns {string}
 */
const matchPattern = (pattern, message = 'pattern') => (value) => {
	// Ensure there is a value to check
	if (isRequired(false)(value) === false) {
		return '';
	}

	const trimmedValue = value?.trim();

	return trimmedValue?.match(pattern) ? '' : message;
};

export default matchPattern;
