import { useTranslation } from 'react-i18next';
import { formatNumber } from 'lib/shared/format';
import PropTypes from 'prop-types';

import { HiddenInput } from 'components/shared/forms/inputs';

/**
 * @name ExpenseTotalsTable
 * @description A form used to display the expense totals as an overview.
 *
 * @author Romaric Barthe
 *
 * @param {string}	currencySymbol				The currency symbol used in the expense.
 * @param {number}	totalExpensesWithVat		The total expensed with VAT.
 * @param {number}	totalExpensesWithoutVat		The total expensed without VAT.
 * @param {number}	totalVat					The total VAT expensed.
 */
const ExpenseTotalsTable = ({
	currencySymbol,
	totalExpensesWithVat,
	totalExpensesWithoutVat,
	totalVat,
}) => {
	const { t } = useTranslation();

	return (
		<table className="total-overview">
			<thead>
				<tr>
					<th>{t('expense.headers.total_without_vat')}</th>
					<th>{t('expense.headers.total_vat')}</th>
					<th>{t('expense.headers.total_with_vat')}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						{formatNumber(totalExpensesWithoutVat, { symbol: currencySymbol })}
						<HiddenInput
							name="grossTotal"
							value={totalExpensesWithoutVat}
						/>
					</td>
					<td>
						{formatNumber(totalVat, { symbol: currencySymbol })}
						<HiddenInput
							name="vatTotal"
							value={totalVat}
						/>
					</td>
					<td>
						{formatNumber(totalExpensesWithVat, { symbol: currencySymbol })}
						<HiddenInput
							name="netTotal"
							value={totalExpensesWithVat}
						/>
					</td>
				</tr>
			</tbody>
		</table>
	);
};

ExpenseTotalsTable.propTypes = {
	currencySymbol: PropTypes.string,
	totalExpensesWithVat: PropTypes.number.isRequired,
	totalExpensesWithoutVat: PropTypes.number.isRequired,
	totalVat: PropTypes.number,
};

ExpenseTotalsTable.defaultProps = {
	currencySymbol: '',
	totalVat: 0,
};

export default ExpenseTotalsTable;
