import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

/**
 * @name SvgIcon
 * @description Display a SVG icon with a dynamic path
 *
 * @author Yann Hodiesne
 *
 * @param {string} path  The path to the SVG file to load at runtime
 *
 * Note: the given path must begin with a slash if you want to load from a static folder, for example `/assets/svg/icon.svg`
 */
const SvgIcon = ({ path, ...otherProps }) => <ReactSVG src={path} {...otherProps} />;

SvgIcon.propTypes = {
	path: PropTypes.string.isRequired,
};

export default SvgIcon;
