import { DATE } from '../../../../constants/ElementTypes';
import ElementType from '../ElementType';

/**
 * @name DateTypes
 * @description Displays a list of element types inside the "date fields" tab.
 *
 * @author Florian Fornazaric
 */
const DateTypes = () => (
	DATE.map((item) => (
		<ElementType key={item.type} elementType={item} />
	))
);

export default DateTypes;
