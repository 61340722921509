import { useState } from 'react';
import { actions, ensurePluginOrder } from 'react-table';

import { HeaderSelectionCell, RowSelectionCell } from '../components';

const pluginName = 'useRowSelectColumn';

/**
 * Column definition for the selection mechanism
 * Contains HeaderSelectionCell and RowSelectionCell
 */
const selectionColumn = {
	id: 'dynamic-table-selection',
	Header: HeaderSelectionCell,
	Cell: RowSelectionCell,
	headerCellClassName: 'header-selection-cell',
	cellClassName: 'row-selection-cell',
	width: 50,
	sticky: 'left',
};

/**
 * @name addSelectionColumn
 * @description Add the selection column to the table, if selection in enabled
 *
 * @author Yann Hodiesne
 *
 * @param {object}	columns				 the user-defined columns for the table
 * @param {bool}	disableSelection	 an option used to disable the selection mechanism
 */
const addSelectionColumn = (columns, { instance: { disableSelection } }) => {
	if (disableSelection === true) {
		return columns;
	}

	return [
		// We add a first column for the rows selection
		selectionColumn,
		...columns,
	];
};

/**
 * @name getToggleRowsSelectedProps
 * @description Setup the props needed for multiple rows selection (Shift + click)
 *
 * @author Yann Hodiesne
 *
 * @param {array}	props		 a list of objects to merge to get the final props
 * @param {object}	instance	 the current table instance
 * @param {object}	row			 the current managed row
 */
const getToggleRowsSelectedProps = (props, { instance, row }) => {
	const { rows, prepareRow, dispatch, state: { lastSelection } } = instance;

	/**
	 * @name onClick
	 * @description Handles the multiple rows selection logic, must be generated for every single row
	 *
	 * @author Yann Hodiesne
	 *
	 * @param {bool} shift		 determines if Shift is pressed
	 * @param {bool} checked	 determines if the checkbox is now checked
	 */
	const onClick = ({ shiftKey: shift, target: { checked } }) => {
		// If Shift is pressed and another row has been selected/unselected before
		if (shift && lastSelection !== null && lastSelection.id !== row.id) {
			// Determine the direction in which we iterate to select/unselect multiple rows
			if (lastSelection.index < row.index) {
				for (let i = lastSelection.index; i < row.index; i++) {
					// We prepare the row before interacting, to ensure it is already managed
					prepareRow(rows[i]);
					rows[i].toggleRowSelected(checked);
				}
			} else {
				for (let i = row.index; i <= lastSelection.index; i++) {
					// We prepare the row before interacting, to ensure it is already managed
					prepareRow(rows[i]);
					rows[i].toggleRowSelected(checked);
				}
			}
		} else {
			// If multiple selection has not been triggered, we update lastSelection
			dispatch({ type: actions.setLastSelection, id: row.id });
		}
	};

	// Returns the received props, plus our onClick handler
	return [
		props,
		{ onClick },
	];
};

/**
 * Custom reducer actions
 */
actions.setLastSelection = 'LAST_SELECTION_SET';

/**
 * @name reducer
 * @description Handles changes for the custom actions in the table's state
 *
 * @author Yann Hodiesne
 *
 * @param {object} state			 the new state, can already be modified by other reducers
 * @param {object} action			 the dispatched action
 * @param {object} previousState	 the previous state, unmodified by other reducers
 * @param {object} instance			 the current table instance
 */
const reducer = (state, action, previousState, instance) => {
	if (action.type === actions.init) {
		return {
			lastSelection: null,
			...state,
		};
	}

	if (action.type === actions.setLastSelection) {
		const { id } = action;
		const { rows } = instance;

		return {
			...state,
			lastSelection: rows[id],
		};
	}

	return state;
};

/**
 * @name useInstance
 * @description The plugin initialization hook, called when the useTable hook is called on the table component
 *
 * @author Yann Hodiesne
 *
 * @param {object} instance  the current table instance, returned by the useTable hook
 */
const useInstance = (instance) => {
	const { plugins, disableSelection } = instance;

	ensurePluginOrder(plugins,
		['useRowSelect'],
		pluginName);

	const [selectionEnabled] = useState(!disableSelection);

	Object.assign(instance, {
		selectionEnabled,
	});
};

/**
 * @name useRowSelectColumn
 * @description A react-table plugin to add a selection column with Shift-clicking support
 *
 * @author Yann Hodiesne
 *
 * @param {object} hooks	 contains the internal react-table events on which we can add our hooks
 */
const useRowSelectColumn = (hooks) => {
	hooks.stateReducers.push(reducer);
	hooks.useInstance.push(useInstance);
	hooks.getToggleRowSelectedProps.push(getToggleRowsSelectedProps);
	hooks.visibleColumns.push(addSelectionColumn);
};

useRowSelectColumn.pluginName = pluginName;

export default useRowSelectColumn;
