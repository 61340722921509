import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchContacts
 * @description Retrieves a list of contacts from the API, specifying filters if present.
 *
 * @author Timothée Simon-Franza
 * @author Yann Hodiesne
 *
 * @param {object} params : The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchContacts = (params) => post('/api/contacts', params);

/**
 * @function
 * @name fetchContactById
 * @description Retrieves an existing contact from the API, using the contactId in parameters.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} contactId  The id of the contact we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchContactById = (contactId) => get(`/api/contacts/${contactId}`);

/**
 * @function
 * @name createContact
 * @description Creates a new contact into the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contactData The data to create the contact from.
 *
 * @returns {Promise}
 */
export const createContact = (contactData) => post('/api/contacts/create', contactData);

/**
 * @function
 * @name updateContact
 * @description Updates an existing contact from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} contactData   The data to update the contact with.
 * @param {string} contactId     The id to identify the contact with.
 *
 * @returns {Promise}
 */
export const updateContact = (contactData, contactId) => put(`/api/contacts/${contactId}`, contactData);

/**
 * @function
 * @name deleteContact
 * @description Removes an existing contact from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} contactId  The id of the contact we want to remove.
 *
 * @returns {Promise}
 */
export const deleteContact = (contactId) => del(`/api/contacts/${contactId}`);

/**
 * @function
 * @name exportContacts
 * @description Export existing contacts from the database.
 *
 * @author Roland Margelidon
 *
 * @param {Object} data		The data to export the contacts.
 *
 * @returns {Promise}
 */
export const exportContacts = (data) => post('/api/contacts/export', data, 'attachment');

/**
 * @function
 * @name fetchAllForContactForm
 * @description Retrieves all information for contact's form from the API:
 * - partners [{ id, name }]
 * - titles [{ id, name }]
 * - relationTypes [{ id, name }]
 * - positions [{ id, name }]
 *
 * @author Romaric Barthe.
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchAllForContactForm = (params) => post('/api/contact/form', params);
