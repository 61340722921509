import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatContactNameWithTitle } from 'lib/contacts/formatContactData';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * @name ContactTitle
 * @description A component to display the contact's titles.
 *
 * @author Romaric Barthe
 *
 * @param {object}	contact			The contact.
 */
const ContactTitle = ({
	contact,
	contactFullname,
	contactPosition,
	currentUser,
}) => {
	const { t } = useTranslation();

	// To display "live" the last update (if there are some update)
	const objUpdated = useMemo(() => {
		if (formatContactNameWithTitle(contact) !== contactFullname
			|| contact?.position?.name !== contactPosition
		) {
			return { updatedBy: currentUser, updatedAt: moment() };
		}

		return contact;
	}, [contact, contactFullname, contactPosition, currentUser]);

	return (
		<div className="data-header">
			<div className="identity">
				<h3>{contactFullname}</h3>
				<h5>{contactPosition}</h5>
			</div>
			<div className="metadata">
				<p>{`${t('contact.fields.creation_date')}: ${formatCreatedAt(contact)}`}</p>
				<p>{`${t('contact.fields.creation_name')}: ${formatCreatedBy(contact)}`}</p>
				<p>{`${t('contact.fields.update_date')}: ${formatUpdatedAt(objUpdated)}`}</p>
				<p>{`${t('contact.fields.update_name')}: ${formatUpdatedBy(objUpdated)}`}</p>
			</div>
		</div>
	);
};

ContactTitle.propTypes = {
	contact: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		classification: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		position: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	}).isRequired,
	contactFullname: PropTypes.string,
	contactPosition: PropTypes.string,
	currentUser: PropTypes.shape({
		id: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
	}).isRequired,
};

ContactTitle.defaultProps = {
	contactFullname: '',
	contactPosition: '',
};

export default ContactTitle;
