import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { Dropdown } from 'components/shared/dropdown';
import { PromptModal, useModal } from 'components/shared/modal';

import { PageOrientations } from '../../../constants/PagesOrientations';
import EditorContext from '../../../EditorContext';
import {
	decrementElementsInFooter,
	decrementElementsInHeader,
	duplicateElements,
	incrementElementsInFooter,
	incrementElementsInHeader,
	insertRow,
	removeElements,
	removeRow,
	setSelection,
	updatePage,
} from '../../../reducer/actions';

/**
 * @name ContextMenu
 * @description Context menu shown when right-clicking an element or a row in the editor
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 * @author Matthieu Schaerlinger
 *
 * @param {string}		[elementId=undefined]	The id of the selected element, if there is any
 * @param {number}		[rowIndex=undefined]	The index of the selected row
 * @param {number}		pageIndex				The index of the selected page
 * @param {object}		anchorPoint				Where the context menu is anchored
 * @param {number}		anchorPoint.x			Where the context menu is anchored on the X axis
 * @param {number}		anchorPoint.y			Where the context menu is anchored on the Y axis
 * @param {boolean}		isShowing				Boolean telling if the context menu is shown
 * @param {function}	handleClose				Callback called to close the context menu
 */
const ContextMenu = ({ elementId, rowIndex, pageIndex, anchorPoint, isShowing, handleClose }) => {
	const { t } = useTranslation();
	const { dispatch, elements, header, footer, pages, selectedElements } = useContext(EditorContext);

	const { isShowing: isModalOpen, toggle: toggleModal } = useModal();

	const [hasSingleSelection, hasMultiSelection] = useMemo(() => [selectedElements.length === 1, selectedElements > 1], [selectedElements]);

	const handleDuplicateClick = useCallback((event) => {
		event.stopPropagation();
		dispatch(duplicateElements(selectedElements.map((element) => element.id)));
		handleClose();
	}, [dispatch, handleClose, selectedElements]);

	const handleDeleteClick = useCallback((event) => {
		event.stopPropagation();
		dispatch(removeElements(selectedElements.map((element) => element.id)));
		handleClose();
	}, [dispatch, selectedElements, handleClose]);

	const handleInsertRowAboveClick = useCallback((event) => {
		event.stopPropagation();

		// When inserting a row inside the header, we need to increment the number of elements inside the header
		if (rowIndex < header.numberOfElements) {
			dispatch(incrementElementsInHeader());
		} else if (rowIndex >= elements.length - footer.numberOfElements && footer.numberOfElements > 0) {
			dispatch(incrementElementsInFooter());
		}

		dispatch(insertRow(rowIndex));
		handleClose();
	}, [dispatch, elements.length, footer.numberOfElements, handleClose, header.numberOfElements, rowIndex]);

	const handleInsertRowBelowClick = useCallback((event) => {
		event.stopPropagation();

		// When inserting a row inside the header, we need to increment the number of elements inside the header
		if (rowIndex < header.numberOfElements) {
			dispatch(incrementElementsInHeader());
		} else if (rowIndex >= elements.length - footer.numberOfElements && footer.numberOfElements > 0) {
			dispatch(incrementElementsInFooter());
		}

		dispatch(insertRow(rowIndex + 1));
		handleClose();
	}, [dispatch, elements.length, footer.numberOfElements, handleClose, header.numberOfElements, rowIndex]);

	const confirmRowRemoval = useCallback(() => {
		// When removing a row from the header, we need to decrement the number of elements inside the header
		if (rowIndex < header.numberOfElements) {
			dispatch(decrementElementsInHeader());
		} else if (rowIndex >= elements.length - footer.numberOfElements && footer.numberOfElements > 0) {
			dispatch(decrementElementsInFooter());
		}

		dispatch(setSelection([]));

		dispatch(removeRow(rowIndex));
	}, [dispatch, elements.length, footer.numberOfElements, header.numberOfElements, rowIndex]);

	const handleRemoveRow = useCallback((event) => {
		event.stopPropagation();
		if (elements[rowIndex].children.length > 0) {
			toggleModal();
			handleClose();
		} else {
			confirmRowRemoval();
		}
	}, [confirmRowRemoval, elements, handleClose, rowIndex, toggleModal]);

	const handlePageOrientation = useCallback(() => {
		if (pages[pageIndex].orientation === PageOrientations.PORTRAIT) {
			dispatch(updatePage(pageIndex, { orientation: PageOrientations.LANDSCAPE }));
		} else {
			dispatch(updatePage(pageIndex, { orientation: PageOrientations.PORTRAIT }));
		}
		handleClose();
	}, [dispatch, handleClose, pageIndex, pages]);

	return (
		<>
			<Dropdown anchorPoint={anchorPoint} isOpen={isShowing} handleClose={handleClose}>
				<>
					{(elementId || hasMultiSelection) && (
						<>
							<Button className="subtle" onClick={handleDuplicateClick}>
								{hasSingleSelection
									? t('template.pdf.editor.components.body.content.duplicate')
									: t('template.pdf.editor.components.body.content.duplicate_multiple', { count: selectedElements.length })}
							</Button>
							<Button className="subtle" onClick={handleDeleteClick}>
								{hasSingleSelection
									? t('template.pdf.editor.components.body.content.delete')
									: t('template.pdf.editor.components.body.content.delete_multiple', { count: selectedElements.length })}
							</Button>
						</>
					)}
					{rowIndex !== undefined && (
						<>
							<Button className="subtle" onClick={handleInsertRowAboveClick}>
								{t('template.pdf.editor.components.body.content.insert_row_above')}
							</Button>
							<Button className="subtle" onClick={handleInsertRowBelowClick}>
								{t('template.pdf.editor.components.body.content.insert_row_below')}
							</Button>
							<Button className="subtle" onClick={handleRemoveRow}>
								{t('template.pdf.editor.components.body.content.remove_row')}
							</Button>
						</>
					)}
				</>

				<Button className="subtle" onClick={handlePageOrientation}>
					{t('template.pdf.editor.components.body.content.change_page_orientation')}
				</Button>
			</Dropdown>
			<PromptModal
				isShowing={isModalOpen}
				message={t('template.pdf.editor.components.body.content.remove_row_modal.content')}
				confirm={confirmRowRemoval}
				cancel={toggleModal}
				confirmText={t('template.pdf.editor.components.body.content.remove_row_modal.confirm')}
				cancelText={t('template.pdf.editor.components.body.content.remove_row_modal.cancel')}
				titleText={t('template.pdf.editor.components.body.content.remove_row_modal.title')}
			/>
		</>
	);
};

ContextMenu.propTypes = {
	elementId: PropTypes.string,
	rowIndex: PropTypes.number,
	pageIndex: PropTypes.number.isRequired,
	anchorPoint: PropTypes.shape({
		x: PropTypes.number.isRequired,
		y: PropTypes.number.isRequired,
	}).isRequired,
	isShowing: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
};

ContextMenu.defaultProps = {
	elementId: undefined,
	rowIndex: undefined,
};

export default ContextMenu;
