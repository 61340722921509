import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addOffer } from 'redux/actions/offers/offers';
import routes from 'routes';

import { OfferCreationForm } from 'components/offers';

/**
 * @name OfferCreationPage
 * @description A page used to create a new offer.
 *
 * @author Audrey Clerc
 * @author Timothée Simon-Franza
 */
const OfferCreationPage = () => {
	const dispatch = useDispatch();

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Audrey Clerc
	 *
	 * @param {object} formData  The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		dispatch(addOffer(formData, routes.sales.offers.list));
	}, [dispatch]);

	return (
		<OfferCreationForm onSubmit={handleFormSubmit} />
	);
};

export default OfferCreationPage;
