import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchUser, updateUser } from 'redux/actions/users';
import { useCurrentUserSelector } from 'redux/selectors/users';
import routes from 'routes';

import { UserEditionForm } from 'components/users';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name UserEditionPage
 * @description A page used to edit an existing user.
 *
 * @author Romaric Barthe
*/
const UserEditionPage = () => {
	const { id: userId } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const user = useCurrentUserSelector(userId);

	const canEditUser = useAccessRight(AccessRights.humanResources.users.enhancedRights.CREATE_USER);

	useEffect(() => {
		dispatch(fetchUser(userId));
	}, [dispatch, userId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditUser) {
			dispatch(updateUser(formData, userId, routes.humanResources.users.list));
		} else {
			redirectOnSuccess(routes.humanResources.users.list);
		}
	}, [canEditUser, dispatch, userId]);

	return (
		user
			? <UserEditionForm user={user} onSubmit={handleFormSubmit} />
			: <p>{t('user.edition.loading')}</p>
	);
};

export default UserEditionPage;
