import { useCallback, useMemo, useRef, useState } from 'react';
import { Download, Filter, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { extractOptionsEnums } from 'constants/exportEnums';
import { formatContactName } from 'lib/contacts/formatContactData';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { adaptToBackendFilters } from 'lib/shared/collectionsUtils';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchContactsForList } from 'redux/actions/contacts/contactsList';
import { useContactsForListSelector, useContactsListTotalCountSelector } from 'redux/selectors/contacts/contactsList';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { ActionsCell, PartnersCell, RelationTypesCell } from 'components/contacts/contactList';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { DataListExtractPanel, useDataExtract } from 'components/shared/list/DataListExtracts';
import { DataListFilterPanel, useDataFilter } from 'components/shared/list/DataListFilters';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name ContactListPage
 * @description A list of all contacts accessible from the contacts app.
 *
 * From this page, the user is able to view, interact and search for contacts.
 *
 * @author Yann Hodiesne
 * @author Timothée Simon-Franza
 * @author Romaric Barthe
 */
const ContactListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const contacts = useContactsForListSelector();
	const totalCount = useContactsListTotalCountSelector();

	const canCreateContact = useAccessRight(AccessRights.commonModules.contacts.enhancedRights.CREATE_CONTACT);

	const headers = useMemo(() => [
		{ id: 'name', accessor: (row) => formatContactName(row), Header: t('contact.fields.name'), pinColumn: true, sorting: 'contact.lastName' },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('contact.headers.actions') },
		{ id: 'partners', Header: t('contact.fields.partners'), Cell: PartnersCell },
		{ id: 'relationTypes', Header: t('contact.fields.relation_types'), Cell: RelationTypesCell },
		{ accessor: 'address', Header: t('contact.fields.address'), sorting: 'contact.address' },
		{ accessor: 'postCode', Header: t('contact.fields.post_code'), sorting: 'contact.postCode' },
		{ accessor: 'city', Header: t('contact.fields.city'), sorting: 'contact.city' },
		{ accessor: 'country', Header: t('contact.fields.country'), sorting: 'contact.country' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('contact.fields.creation_name'), sorting: 'contact.createdBy' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('contact.fields.creation_date'), sorting: 'contact.createdAt' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('contact.fields.update_name'), sorting: 'contact.updatedBy' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('contact.fields.update_date'), sorting: 'contact.updatedAt' },
	], [t]);

	const columns = useMemo(() => [
		{ accessor: 'firstName', label: t('contact.fields.first_name'), type: 'text' },
		{ accessor: 'lastName', label: t('contact.fields.last_name'), type: 'text' },
		{ accessor: 'partners', label: t('contact.fields.partners'), type: 'entity' },
		{ accessor: 'relationTypes', label: t('contact.fields.relation_types'), type: 'entity' },
		{ accessor: 'address', label: t('contact.fields.address'), type: 'text' },
		{ accessor: 'postCode', label: t('contact.fields.post_code'), type: 'text' },
		{ accessor: 'city', label: t('contact.fields.city'), type: 'text' },
		{ accessor: 'country', label: t('contact.fields.country'), type: 'text' },
		{ accessor: 'createdBy', label: t('partner.fields.creation_name'), type: 'text' },
		{ accessor: 'createdAt', label: t('partner.fields.creation_date'), type: 'date' },
		{ accessor: 'updatedBy', label: t('partner.fields.update_name'), type: 'text' },
		{ accessor: 'updatedAt', label: t('partner.fields.update_date'), type: 'date' },
	], [t]);

	const [filters, setFilters] = useState({});
	const [params, setParams] = useState({});
	const [fetchData, searchRef] = useSearch((param) => {
		setParams(param);
		dispatch(fetchContactsForList({ ...filters, ...param }));
	});

	const [extractButtonRef, extractTooltipProps] = useTooltip();
	const [filterButtonRef, filterTooltipProps] = useTooltip();
	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('contact');

	const { isExtractPanelDisplayed, toggleExtractPanel } = useDataExtract();
	const { isFilterPanelDisplayed, toggleFilterPanel } = useDataFilter();

	const applyFilter = useCallback((filter) => {
		const newFilters = adaptToBackendFilters(filter);

		setFilters(newFilters);
		dispatch(fetchContactsForList({ filters: newFilters, ...params }));
	}, [dispatch, params]);

	const [selectedContactsIds, setSelectedContactsIds] = useState([]);
	const contactsIndices = useMemo(() => contacts.map(
		(contact, index) => (selectedContactsIds?.includes(contact.id) ? index : null)
	).filter((contactIndex) => contactIndex !== null),
	[contacts, selectedContactsIds]);

	const contactsPointer = useRef(contacts);
	contactsPointer.current = contacts;
	const selectedContactsIdsPointer = useRef(selectedContactsIds);
	selectedContactsIdsPointer.current = selectedContactsIds;

	const selectedContactsIdsChanged = useCallback((pSelectedContacts) => {
		const contactsId = contactsPointer.current.map((contact) => contact.id);
		const pSelectedContactsIds = pSelectedContacts.map((contact) => contact.id);

		const contactsToAddIds = pSelectedContactsIds.filter(
			(contactToAddId) => !selectedContactsIdsPointer.current.includes(contactToAddId)
			&& contactsId.includes(contactToAddId)
		);
		const contactsNoMoreToAddIds = selectedContactsIdsPointer.current.filter((contactId) => contactsId.includes(contactId))
			.filter((contactId) => !pSelectedContactsIds.includes(contactId));

		setSelectedContactsIds((value) => [...value.filter((contactId) => !contactsNoMoreToAddIds.includes(contactId)), ...contactsToAddIds]);
	}, []);

	/**
	 *
	 * @name onClickFilterPanel
	 * @description When opening the filter panel, close the extract panel if needed
	 */
	const onClickFilterPanel = () => {
		if (!isFilterPanelDisplayed && isExtractPanelDisplayed) {
			toggleExtractPanel();
		}
		toggleFilterPanel();
	};

	/**
	 *
	 * @name onClickExtractPanel
	 * @description When opening the extract panel, close the filter panel if needed
	 */
	const onClickExtractPanel = () => {
		if (!isExtractPanelDisplayed && isFilterPanelDisplayed) {
			toggleFilterPanel();
		}
		toggleExtractPanel();
	};

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.commonModules.contacts.contactCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateContact && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('contact.links.create')}
						</Tooltip>
					</>
				)}
				<Button className="icon-only" onClick={onClickFilterPanel} ref={filterButtonRef}>
					<Filter />
				</Button>
				<Tooltip {...filterTooltipProps}>
					{t('components.table.filters.actions.filter')}
				</Tooltip>
				<Button className="icon-only" onClick={onClickExtractPanel} ref={extractButtonRef}>
					<Download />
				</Button>
				<Tooltip {...extractTooltipProps}>
					{t('template.export.action')}
				</Tooltip>
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={contacts}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="contact"
				selectionChanged={selectedContactsIdsChanged}
				selectedRowsIndices={contactsIndices}
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('contact.pages.list')} {...modalProps} />
			<DataListFilterPanel
				applyFilterCallBack={applyFilter}
				columns={columns}
				open={isFilterPanelDisplayed}
				onCloseButtonClick={toggleFilterPanel}
			/>
			<DataListExtractPanel
				entity={extractOptionsEnums.export.dataTypes.CONTACTS}
				open={isExtractPanelDisplayed}
				onCloseButtonClick={toggleExtractPanel}
				selectedElementsIds={selectedContactsIds}
				filters={filters}
			/>
		</div>
	);
};

export default ContactListPage;
