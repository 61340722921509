import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useContactsProfileDisplayLoadingSelector
 * @description A selector to retrieve the loading state from the contactsProfileDisplay redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useContactsProfileDisplayLoadingSelector = () => {
	const loading = useSelector((state) => state.contactsProfileDisplay?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useContactsProfileDisplayListSelector
 * @description A selector to retrieve the contactsProfileDisplay list from the contactsProfileDisplay redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useContactsProfileDisplayListSelector = () => {
	const contacts = useSelector((state) => (state.contactsProfileDisplay?.items ?? []), shallowEqual);

	return contacts;
};

/**
 * @function
 * @name useContactsProfileDisplayTotalCountSelector
 * @description A selector to retrieve the totalCount value from the contactsProfileDisplay redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useContactsProfileDisplayTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.contactsProfileDisplay?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useContactsProfileDisplayListSelector,
	useContactsProfileDisplayLoadingSelector,
	useContactsProfileDisplayTotalCountSelector,
};
