import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useModal } from 'components/shared/modal';

import useUserPreference from './useUserPreference';

/**
 * @function
 * @name useTableUserPreferences
 * @description A custom hook providing data and tools to manage the user preferences for a DynamicTable component
 *
 * @author Yann Hodiesne
 *
 * @param {string}	name	The table name, matching the user preference to use internally.
 *
 * @returns {Array}
 */
export default (name) => {
	const { t } = useTranslation();

	// User preferences
	const [headersOrder, setHeadersOrder] = useUserPreference(name);
	const [pinnedHeaders, setPinnedHeaders] = useUserPreference(`${name}-pinned`);
	const [hiddenHeaders, setHiddenHeaders] = useUserPreference(`${name}-hidden`);

	// Modal parameters
	const { isShowing, toggle } = useModal();

	/**
	 * @name confirm
	 * @description Closes the modal and apply changes given in the parameters
	 *
	 * @author Yann Hodiesne
	 *
	 * Note: every parameter can be undefined to skip it and keep its current value
	 *
	 * @param {array}	order	The new columns order to apply, can be undefined to skip
	 * @param {array}	pinned	The new pinned columns to apply, can be undefined to skip
	 * @param {array}	hidden	The new hidden columns to apply, can be undefined to skip
	 */
	const confirm = useCallback((order, pinned, hidden) => {
		if (order !== undefined) {
			setHeadersOrder(order);
		}

		if (pinned !== undefined) {
			setPinnedHeaders(pinned);
		}

		if (hidden !== undefined) {
			setHiddenHeaders(hidden);
		}

		toggle();

		toast.success(t('components.table_user_preferences.toasts.success'));
	}, [toggle, setHeadersOrder, setPinnedHeaders, setHiddenHeaders, t]);

	// DynamicTable props
	const tableProps = useMemo(() => ({
		headersOrder,
		pinnedHeaders,
		hiddenHeaders,
		showUserPreferencesModal: toggle,
	}), [headersOrder, pinnedHeaders, hiddenHeaders, toggle]);

	// TableUserPreferencesModal props
	const modalProps = useMemo(() => ({
		isShowing,
		confirm,
		cancel: toggle,
		headersOrder,
		pinnedHeaders,
		hiddenHeaders,
	}), [
		isShowing,
		confirm,
		toggle,
		headersOrder,
		pinnedHeaders,
		hiddenHeaders,
	]);

	return {
		tableProps,
		modalProps,
	};
};
