import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useForwardedRef } from 'lib/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';

import TextInput from './TextInput';

/**
 * @name SearchInput
 * @description A custom search input component with debouncing support
 *
 * @author Yann Hodiesne
 * @author Florian Fornazaric
 *
 * @param {bool}	[disabled=false]			Whether the input is disabled.
 * @param {func}	onSearch					Callback to execute when a search is requested
 * @param {string}	[defaultValue='']			Default value of the input
 * @param {number}	[debouncingDelay=500]		Delay after which the search request will be triggered, defaults to 500
 * @param {bool}	[disableDebouncing=false]	Disables the debouncing of the search request, defaults to false
 */
const SearchInput = forwardRef(
	({ disabled, onSearch, defaultValue, debouncingDelay, disableDebouncing, ...props }, ref) => {
		const { t } = useTranslation();

		const resolvedRef = useForwardedRef(ref);
		const latestSearch = useRef(defaultValue);

		// Triggers the search query on the onSearch prop
		const search = useCallback((value) => {
			latestSearch.current = value;
			onSearch(value);
		}, [onSearch]);

		// Triggers the search only when the user is finished typing with a set delay
		const debouncedSearch = useMemo(() => _.debounce(search, debouncingDelay), [debouncingDelay, search]);

		// Reacts to any user event requesting a search (onChange, onKeyDown and onBlur)
		const triggerSearch = useCallback((event) => {
			const value = resolvedRef.current.value.trim();

			// If the current value is already the latest value requested to the server, ignores it
			if (latestSearch.current !== value) {
				if (event.type === 'blur' || event.key === 'Enter') {
					// If the input lost focus or the user pressed enter, we trigger an instant search
					debouncedSearch.cancel();
					search(value);
				} else if (disableDebouncing) {
					// If debouncing is disabled, trigger an instant search
					search(value);
				} else {
					// Debounces the search request to wait till the user finished typing before sending the request
					debouncedSearch(value);
				}
			}
		}, [resolvedRef, disableDebouncing, debouncedSearch, search]);

		const lastDefaultValueRef = useRef('');

		useEffect(() => {
			if (lastDefaultValueRef.current !== defaultValue?.trim()) {
				lastDefaultValueRef.current = defaultValue?.trim();
				search(defaultValue?.trim());
			}
		}, [defaultValue, search]);

		// Cancels debouncing when we leave the current page
		useEffect(() => debouncedSearch.cancel, [debouncedSearch, debouncedSearch.cancel]);

		return (
			<div className={`icon-input-wrapper leading-icon ${disabled ? 'disabled' : ''}`}>
				<TextInput
					ref={resolvedRef}
					disabled={disabled}
					placeholder={t('components.search.placeholder')}
					defaultValue={defaultValue}
					onKeyDown={triggerSearch}
					onBlur={triggerSearch}
					onChange={triggerSearch}
					{...props}
				/>
				<Search />
			</div>
		);
	}
);

SearchInput.displayName = 'SearchInput';

SearchInput.propTypes = {
	...TextInput.propTypes,
	onSearch: PropTypes.func.isRequired,
	defaultValue: PropTypes.string,
	disableDebouncing: PropTypes.bool,
	debouncingDelay: PropTypes.number,
};

SearchInput.defaultProps = {
	...TextInput.defaultProps,
	defaultValue: '',
	disableDebouncing: false,
	debouncingDelay: 500,
};

export default SearchInput;
