import { useCallback, useState } from 'react';
import { Minus, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from '../buttons';

/**
 * @name StatusesViewer
 * @description A component to display the statuses of the current project.
 *
 * @author Florian Fornazaric
 *
 * @param {object}	statuses			The statuses with count, name and color.
 */
const StatusesViewer = ({ statuses }) => {
	const { t } = useTranslation();
	const [showNonInDashboard, setShowNonInDashboard] = useState(false);

	const toggleShowNonInDashboard = useCallback(() => {
		setShowNonInDashboard((value) => !value);
	}, []);

	return (
		<div className="statistics">
			{statuses && statuses.filter((status) => status.inDashboard).map((stat) => (
				<div key={stat.status_name || 'backlog'} className="stat-pill">
					<div className="container">
						<span className="color-dot" style={{ backgroundColor: stat.color }} />
						<span className="stat-label">{`${stat.count} ${stat.status_name || t('status.backlog')}`}</span>
					</div>
				</div>
			))}
			{showNonInDashboard && statuses && statuses.filter((status) => !status.inDashboard).map((stat) => (
				<div key={stat.status_name || 'backlog'} className="stat-pill">
					<div className="container">
						<span className="color-dot" style={{ backgroundColor: stat.color }} />
						<span className="stat-label">{`${stat.count} ${stat.status_name || t('status.backlog')}`}</span>
					</div>
				</div>
			))}
			<Button className={`stat-pill action ${showNonInDashboard ? 'open' : ''}`} onClick={toggleShowNonInDashboard}>
				<div className="container">
					{showNonInDashboard ? (
						<>
							<span className="stat-label">
								{t('button.show_less')}
							</span>
							<Minus />
						</>
					) : (
						<>
							<span className="stat-label">
								{t('button.show_more')}
							</span>
							<Plus />
						</>
					)}
				</div>
			</Button>
		</div>
	);
};

StatusesViewer.propTypes = {
	statuses: PropTypes.arrayOf(PropTypes.shape({
		status_name: PropTypes.string,
		color: PropTypes.string,
		count: PropTypes.string,
		name: PropTypes.string,
		inDashboard: PropTypes.bool,
	})),
};

StatusesViewer.defaultProps = {
	statuses: [],
};

export default StatusesViewer;
