import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ContactTabs } from 'constants/contactEnum';
import { formatContactNameWithTitle } from 'lib/contacts/formatContactData';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { fetchAllForContact } from 'redux/actions/contacts/contacts';
import { addRelationType } from 'redux/actions/relationTypes';
import { addTag } from 'redux/actions/tags';
import { useAllForContactSelector, useContactsLoadingSelector } from 'redux/selectors/contacts/contacts';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

import { ContactPositionTagCreationForm, ContactTitleTagCreationForm } from 'components/contacts/contactTags';
import { RelationTypeCreationForm } from 'components/relationTypes';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Tab, TabList, TabPanel } from 'components/shared/layout/tabs';

import { Address, ContactIdentifier, PhoneMail } from './contactTabs';
import ContactTitle from './ContactTitle';

/**
 * @name ContactEditionForm
 * @description A form used to edit an existing contact's information.
 *
 * @author Romaric Barthe
 *
 * @param {object}		contact			The contact object to update information from.
 * @param {function}	onSubmit		The method to trigger upon form submission.
 * @param {boolean}		[inSuperAdmin]	Whether the form is being displayed inside the super-admin panel or not, default to false.
 */
const ContactEditionForm = ({ contact, onSubmit, inSuperAdmin }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForContactForm = useAllForContactSelector();
	const currentUser = useCurrentConnectedUserSelector();
	const positions = useMemo(() => allForContactForm?.positions ?? [], [allForContactForm]);
	const titles = useMemo(() => allForContactForm?.titles ?? [], [allForContactForm]);
	const partners = useMemo(() => allForContactForm?.partners ?? [], [allForContactForm]);
	const relationTypes = useMemo(() => allForContactForm?.relationTypes ?? [], [allForContactForm]);

	const isLoading = useContactsLoadingSelector();

	const canEditContact = useAccessRight(AccessRights.commonModules.contacts.enhancedRights.CREATE_CONTACT);

	const [contactFullname, setContactFullname] = useState();
	const [contactPosition, setContactPosition] = useState();

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const tabsToDisplay = useMemo(() => {
		const tabs = [];
		Object.entries(ContactTabs).forEach(([, value]) => {
			tabs.push(value);
		});

		return tabs;
	}, []);

	const relationTypeCreationModal = useFormModal(RelationTypeCreationForm, t('pages.common_modules.partners.relation_types.edition'), fetchAllForContact, addRelationType);
	const titleCreationModal = useFormModal(ContactTitleTagCreationForm, t('pages.common_modules.contacts.tag.creation.title'), fetchAllForContact, addTag);
	const positionCreationModal = useFormModal(ContactPositionTagCreationForm, t('pages.common_modules.contacts.tag.creation.position'), fetchAllForContact, addTag);

	const [selectedContactTabId, setSelectedContactTabId] = useState('identity-tab');

	/**
	 * @function
	 * @name setTitles
	 * @description A function to refresh Title variables.
	 *
	 * @author Romaric Barthe
	 */
	const setTitles = useCallback(() => {
		const formData = formRef.current?.getFormValues();

		// Take back object from the id
		const position = positions.filter((f) => (f.id === (formData?.position ?? undefined)).length === 1)
			? positions.filter((f) => (f.id === formData.position))[0]
			: undefined;
		const title = titles.filter((f) => (f.id === (formData?.title ?? undefined)).length === 1)
			? titles.filter((f) => (f.id === formData.title))[0]
			: undefined;
		const resolvedFormData = { ...formData, title, position };

		setContactFullname(formatContactNameWithTitle(resolvedFormData));
		setContactPosition(resolvedFormData?.position?.name);
	}, [formRef, positions, titles]);

	/**
	 * @function
	 * @name onTabSelectionChanged
	 * @description A function to switch between tabs.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	tabId		The id of the tab triggering the action.
	 */
	const onTabSelectionChanged = useCallback((tabId) => {
		setSelectedContactTabId(tabId);
	}, []);

	useEffect(() => {
		if (!inSuperAdmin) {
			dispatch(fetchAllForContact());
		}
	}, [dispatch, inSuperAdmin]);

	const defaultValues = useMemo(() => ({
		...contact,
		position: contact.position?.id ?? '',
		title: contact.title?.id ?? '',
		partners: contact.partners?.map(({ id }) => (id ?? '')) ?? [],
		relationTypes: contact.relationTypes?.map(({ id }) => (id ?? '')) ?? [],
	}), [contact]);

	// Set state's variables
	useEffect(() => {
		// Take back object from the id
		const position = positions.filter((f) => (f.id === (defaultValues?.position ?? undefined)).length === 1)
			? positions.filter((f) => (f.id === defaultValues.position))[0]
			: undefined;
		const title = titles.filter((f) => (f.id === (defaultValues?.title ?? undefined)).length === 1)
			? titles.filter((f) => (f.id === defaultValues.title))[0]
			: undefined;
		const resolvedDefault = { ...defaultValues, title, position };

		setContactFullname(formatContactNameWithTitle(resolvedDefault));
		setContactPosition(resolvedDefault?.position?.name);
	}, [defaultValues, positions, titles]);

	const handleSubmit = useCallback((formData) => {
		// The backend responds with 400 Bad Request if we send an empty phone line, but we can't make phones required
		const result = {
			...formData,
			phones: formData.phones?.filter(({ type, number }) => type && number),
		};

		onSubmit(result);
	}, [onSubmit]);

	return (
		<DynamicForm
			ref={formRef}
			onSubmit={handleSubmit}
			cleanFormData
			defaultValues={defaultValues}
			disabled={!canEditContact}
			{...formProps}
		>
			{!inSuperAdmin && (
				<ContactTitle
					contact={contact}
					contactFullname={contactFullname}
					contactPosition={contactPosition}
					currentUser={currentUser}
				/>
			)}

			<TabList
				defaultSelectedTabId={selectedContactTabId}
				onTabSelectionChanged={onTabSelectionChanged}
			>
				{tabsToDisplay.map((tabId) => (
					<Tab key={tabId} tabId={`${tabId}-tab`} tabPanelId={`${tabId}-tabpanel`} dontDisplayCloseButton>
						{t(`contact.fields.${tabId}`)}
					</Tab>
				))}
			</TabList>

			<TabPanel
				tabId="identity-tab"
				tabPanelId="identity-tabpanel"
				hidden={selectedContactTabId !== 'identity-tab'}
			>
				<ContactIdentifier
					inSuperAdmin={inSuperAdmin}
					isLoading={isLoading}
					partners={partners}
					positionCreationModal={positionCreationModal}
					positions={positions}
					relationTypeCreationModal={relationTypeCreationModal}
					relationTypes={relationTypes}
					titleCreationModal={titleCreationModal}
					titles={titles}
					setTitles={setTitles}
				/>
			</TabPanel>

			<TabPanel
				tabId="address-tab"
				tabPanelId="address-tabpanel"
				hidden={selectedContactTabId !== 'address-tab'}
			>
				<Address />
			</TabPanel>

			<TabPanel
				tabId="phone_mail-tab"
				tabPanelId="phone_mail-tabpanel"
				hidden={selectedContactTabId !== 'phone_mail-tab'}
			>
				<PhoneMail />
			</TabPanel>

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditContact ? t('contact.edition.action') : t('contact.edition.close')}
			</Button>
		</DynamicForm>
	);
};

ContactEditionForm.propTypes = {
	contact: PropTypes.shape({
		firstName: PropTypes.string.isRequired,
		lastName: PropTypes.string.isRequired,
		emails: PropTypes.arrayOf(PropTypes.string).isRequired,
		phones: PropTypes.arrayOf(PropTypes.shape({
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			type: PropTypes.string.isRequired,
		})),
		address: PropTypes.string,
		postCode: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		partners: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		})),
		position: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
		relationTypes: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		})),
		title: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
	inSuperAdmin: PropTypes.bool,
};

ContactEditionForm.defaultProps = {
	inSuperAdmin: false,
};

export default ContactEditionForm;
