import { useCallback, useState } from 'react';
import { Download, Edit2, Eye, List, Maximize, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { exportTargetingStats, removeTargeting } from 'redux/actions/targetings/targetings';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the TargetingListPage table. (archive, delete, edit and view targeting)
 *
 * @author Romaric Barthe
 *
 * @param {object}		row							The object containing the data to interact with.
 * @param {boolean}		row.original.archived		The archived status of the targeting.
 * @param {string}		row.original.id				The id of the targeting.
 * @param {string}		row.original.project.name	The targeting's project's name.
 * @param {function}	fetchData					The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { archived, id, project: { name } } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();
	const [isLoading, setIsLoading] = useState(false);

	const canEditProject = useAccessRight(AccessRights.operations.projects.enhancedRights.CREATE_PROJECT);
	const canDeleteProject = useAccessRight(AccessRights.operations.projects.enhancedRights.DELETE_PROJECT);

	const [downloadButtonRef, downloadTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	const [targetButtonRef, targetTooltipProps] = useTooltip();
	const [partnerButtonRef, partnerTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onDeletionModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Romaric Barthe
	 */
	const onDeletionModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeTargeting({ id, name }));
		fetchData();
	}, [dispatch, fetchData, id, name, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onEditButtonClick = useCallback(() => {
		history.push(generatePath(routes.operations.targetings.targetingEdition, { id }));
	}, [id]);

	/**
	 * @function
	 * @name onViewButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onViewButtonClick = useCallback(() => {
		history.push(generatePath(routes.operations.targetings.targets.list, { targetingId: id }));
	}, [id]);

	/**
	 * @function
	 * @name onViewTargetPartnersButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Grégoire Wartner
	 */
	const onViewTargetPartnersButtonClick = useCallback(() => {
		history.push(generatePath(routes.operations.targetings.targetPartners.list, { targetingId: id }));
	}, [id]);

	/**
	 * @function
	 * @name onExportButtonClick
	 * @description Method triggered as a result to an onClick event from the export button.
	 *
	 * @author Roland Margelidon
	 */
	const onExportButtonClick = useCallback(async () => {
		setIsLoading(true);
		await dispatch(exportTargetingStats(id));
		setIsLoading(false);
	}, [dispatch, id]);

	return (
		<>
			<Button className="icon-only" onClick={onViewButtonClick} ref={targetButtonRef}>
				<Maximize className="primary" />
			</Button>
			<Button className="icon-only" onClick={onViewTargetPartnersButtonClick} ref={partnerButtonRef}>
				<List />
			</Button>
			<Tooltip {...partnerTooltipProps}>
				{t('actions.partner')}
			</Tooltip>
			<Tooltip {...targetTooltipProps}>
				{t('actions.target')}
			</Tooltip>
			<Button className="icon-only" onClick={onEditButtonClick} ref={editButtonRef}>
				{canEditProject && !archived ? <Edit2 /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditProject && !archived ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			<Button className="icon-only" onClick={onExportButtonClick} isLoading={isLoading} ref={downloadButtonRef}>
				<Download />
			</Button>
			<Tooltip {...downloadTooltipProps}>
				{t('actions.export')}
			</Tooltip>
			{canDeleteProject && !archived && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('targeting.deletion.confirmation_modal.content')}
						confirm={onDeletionModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('targeting.deletion.confirmation_modal.confirm')}
						cancelText={t('targeting.deletion.confirmation_modal.cancel')}
						titleText={t('targeting.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			archived: PropTypes.bool,
			id: PropTypes.string.isRequired,
			project: PropTypes.shape({
				name: PropTypes.string.isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
