import { useCallback, useMemo, useState } from 'react';
import { Download, Edit } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { downloadS3File } from 'lib/shared/fileHelper';
import PropTypes from 'prop-types';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the TemplateExportListPage
 *
 * @author Yann Hodiesne
 *
 * @param {object}	row		The object containing the data to interact with.
 */
const ActionsCell = ({ row: { original: { id, generatedPdf: { pdf } } } }) => {
	const canDownload = useMemo(() => pdf && pdf.presignedUrl, [pdf]);
	const [isDownloading, setIsDownloading] = useState(false);
	const { t } = useTranslation();

	const [downloadButtonRef, downloadTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();

	const onDownloadButtonClick = useCallback(async () => {
		if (!isDownloading) {
			setIsDownloading(true);
			await downloadS3File(pdf, 'pdf');
			setIsDownloading(false);
		}
	}, [isDownloading, pdf]);

	const onOpenButtonClick = useCallback(() => {
		history.push(generatePath(routes.settings.templates.reexport, { id }));
	}, [id]);

	return (
		<>
			<Button className="icon-only" onClick={onDownloadButtonClick} disabled={!canDownload} isLoading={isDownloading} ref={downloadButtonRef}>
				<Download />
			</Button>
			<Tooltip {...downloadTooltipProps}>
				{t('actions.export')}
			</Tooltip>
			<Button className="icon-only" onClick={onOpenButtonClick} ref={editButtonRef}>
				<Edit />
			</Button>
			<Tooltip {...editTooltipProps}>
				{t('actions.edit')}
			</Tooltip>
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
			generatedPdf: PropTypes.shape({
				pdf: PropTypes.shape({
					presignedUrl: PropTypes.string.isRequired,
					clientFileName: PropTypes.string.isRequired,
				}),
			}),
		}).isRequired,
	}).isRequired,
};

export default ActionsCell;
