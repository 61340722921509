import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select, TextInput } from 'components/shared/forms/inputs';
import TextArea from 'components/shared/forms/inputs/TextArea';
import Validators from 'components/shared/forms/validators';

import {
	templateModes as getTemplateModes,
	templateTypes as getTemplateTypes,
} from './functions';

/**
 * @name TemplatePreCreationForm
 * @description A form used to create a new template.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit        The method to trigger upon form submission.
 */
const TemplatePreCreationForm = ({ onSubmit, isTemplateImport }) => {
	const [templateTypeSelected, setTemplateTypeSelected] = useState();

	const { t } = useTranslation();

	const templateTypes = useMemo(() => getTemplateTypes(t), [t]);
	const templateModes = useMemo(() => getTemplateModes(t), [t]);

	const { formProps, buttonProps } = useSubmitButton();

	const defaultValues = useMemo(() => {
		if (isTemplateImport) {
			return ({
				templateType: 'excel',
				mode: 'import',
			});
		}

		return ({});
	}, [isTemplateImport]);

	return (
		<DynamicForm onSubmit={onSubmit} defaultValues={defaultValues} {...formProps}>
			<TextInput
				label={t('template.creation.inputs.name.label')}
				name="templateName"
				rules={{
					required: Validators.isRequired(t('template.creation.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			{!isTemplateImport && (
			<div>
				<Select
					label={t('template.creation.inputs.type.label')}
					labelKey="label"
					name="templateType"
					onChange={setTemplateTypeSelected}
					options={templateTypes}
					rules={{
						required: Validators.isRequired(t('template.creation.inputs.type.validation_errors.required')),
					}}
					valueKey="id"
				/>
				{(templateTypeSelected === 'excel') && (
					<Select
						label={t('template.creation.inputs.template_mode.label')}
						labelKey="label"
						name="mode"
						options={templateModes}
						rules={{
							required: Validators.isRequired(t('template.creation.inputs.template_mode.validation_errors.required')),
						}}
						valueKey="id"
					/>
				)}
				<TextArea
					label={t('template.creation.inputs.description.label')}
					name="templateDescription"
					type="text"
				/>
			</div>
			)}
			<Button className="primary" type="submit" {...buttonProps}>{t('template.creation.action')}</Button>
		</DynamicForm>
	);
};

TemplatePreCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	isTemplateImport: PropTypes.bool,
};

TemplatePreCreationForm.defaultProps = {
	isTemplateImport: false,
};

export default TemplatePreCreationForm;
