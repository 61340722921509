import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeLanguage } from 'i18next';
import { formatContactNameWithTitle } from 'lib/contacts/formatContactData';
import PropTypes from 'prop-types';
import { fetchAllForUser } from 'redux/actions/users';
import { useAllForUserSelector, useCurrentConnectedUserSelector, useUsersLoadingSelector } from 'redux/selectors/users';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { HiddenInput, Select, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { locales } from './function';

/**
 * @name UserPreferencesForm
 * @description A form used to edit a new user.
 *
 * @author Romaric Barthe
 *
 * @param {function}	onSubmit	The method to trigger upon form submission.
 * @param {object}		user		The user object to edit information from.
 */
const UserPreferencesForm = ({ onSubmit, user }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const allForUserForm = useAllForUserSelector();
	const companyRoot = useMemo(() => allForUserForm?.root, [allForUserForm]);
	const contacts = useMemo(() => allForUserForm?.contacts ?? [], [allForUserForm]);
	const currentUser = useCurrentConnectedUserSelector();

	const isLoading = useUsersLoadingSelector();

	const contactsWithEmailOnly = useMemo(() => (contacts
		.filter((contact) => contact.emails && contact.emails.length > 0)
	), [contacts]);

	const selectedContact = useMemo(() => contactsWithEmailOnly.filter((contact) => contact.id === user.contact.id)[0], [contactsWithEmailOnly, user.contact.id]);
	const mailsOfSelectedContactOptions = useMemo(() => (selectedContact && selectedContact.emails && selectedContact.emails.length === 1
		? selectedContact.emails.map((mail) => ({ email: mail })) : []
	), [selectedContact]);

	const canEditUser = currentUser.id === user.id;

	const getLocales = useMemo(() => locales(t), [t]);

	const onChangeLanguage = useCallback((value) => { changeLanguage(value); }, []);

	const defaultValues = useMemo(() => ({
		...user,
		contact: user.contact.id ?? '',
		username: user.username.includes('-') ? user.username.split('-')[1] : user.username,
		email: user.contact.emails && user.contact.emails.length >= 1 ? user.contact.emails[0] : undefined,
	}), [user]);

	const { formProps, buttonProps } = useSubmitButton();

	const companyRef = useRef();

	useEffect(() => {
		dispatch(fetchAllForUser());
	}, [dispatch]);

	useEffect(() => { companyRef.current.setValue(`${companyRoot}-`); }, [companyRoot]);

	return (
		<DynamicForm
			cleanFormData
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			disabled={!canEditUser}
			{...formProps}
		>
			<h3>{(selectedContact && formatContactNameWithTitle(selectedContact)) || ''}</h3>
			<HiddenInput
				name="contact"
				value={user.contact.id}
			/>
			<HiddenInput
				name="enabled"
				value
			/>

			<div className="user-username">
				<TextInput
					ref={companyRef}
					label={t('user.creation.inputs.root.label')}
					name="root"
					type="text"
					rules={{
						required: Validators.isRequired(t('user.creation.inputs.username.validation_errors.required')),
					}}
					disabled
				/>
				<TextInput
					label={t('user.edition.inputs.username.label')}
					name="username"
					rules={{
						minLength: Validators.hasMinLength(6, t('user.edition.inputs.username.validation_errors.length')),
						required: Validators.isRequired(t('user.edition.inputs.username.validation_errors.required')),
					}}
					type="text"
				/>
			</div>

			<Select
				isLoading={isLoading}
				label={t('user.creation.inputs.email.label')}
				labelKey="email"
				name="email"
				options={mailsOfSelectedContactOptions}
				rules={{
					required: Validators.isRequired(t('user.edition.inputs.email.validation_errors.required')),
				}}
				valueKey="email"
			/>

			<Select
				label={t('user.edition.inputs.locale.label')}
				labelKey="label"
				name="locale"
				onChange={onChangeLanguage}
				options={getLocales}
				rules={{
					required: Validators.isRequired(t('user.edition.inputs.locale.validation_errors.required')),
				}}
				valueKey="value"
			/>

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditUser ? t('user.edition.action') : t('user.edition.close')}
			</Button>
		</DynamicForm>
	);
};

UserPreferencesForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	user: PropTypes.shape({
		accessDefinitions: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
		})).isRequired,
		companyOwner: PropTypes.bool.isRequired,
		contact: PropTypes.shape({
			id: PropTypes.string.isRequired,
			position: PropTypes.shape({
				name: PropTypes.string,
			}),
			emails: PropTypes.arrayOf(PropTypes.string).isRequired,
		}).isRequired,
		enabled: PropTypes.bool.isRequired,
		id: PropTypes.string.isRequired,
		locale: PropTypes.string.isRequired,
		username: PropTypes.string.isRequired,
	}).isRequired,
};

export default UserPreferencesForm;
