/**
 * @function
 * @name isRequired
 * @description A validation method used to check if the field is set.
 *
 * @author Yann Hodiesne
 *
 * @param {string|number}	value	The input's current value.
 * @param {string}			message	The message to return if the validation fails.
 *
 * @returns {string}
 */
const isRequired = (message = 'required') => (value) => {
	const trimmedValue = value?.trim ? value.trim() : value;

	// edge cases
	if (trimmedValue === 0) {
		return '';
	}

	return trimmedValue ? '' : message;
};

export default isRequired;
