import { memo, useCallback, useContext, useMemo } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';

import EditorContext from '../../EditorContext';
import { getElementType } from '../../functions/internals';
import { clearSelection } from '../../reducer/actions';

import ElementTypes from './elements/ElementTypes';
import { ElementProperties } from './properties';

const ContentTypes = Object.freeze({
	ELEMENT_TYPES: 'ELEMENT_TYPES',
	ELEMENT_PROPERTIES: 'ELEMENT_PROPERTIES',
});

/**
 * @name EditorToolbox
 * @description The editor's toolbox, containing its user interactions
 *
 * @author Yann Hodiesne
 */
const EditorToolbox = () => {
	const { t } = useTranslation();

	const { dispatch, selectedElements } = useContext(EditorContext);

	// contentType determines which content type to display inside the toolbox
	// selectedElementType is used when an element is selected inside the editor, to display its type inside the header
	const [contentType, selectedElementType] = useMemo(() => {
		if (selectedElements.length === 1) {
			return [ContentTypes.ELEMENT_PROPERTIES, getElementType(selectedElements[0])];
		}

		return [ContentTypes.ELEMENT_TYPES, null];
	}, [selectedElements]);

	// Handle unselecting when clicking on the arrow button
	const handleArrowClick = useCallback(() => dispatch(clearSelection()), [dispatch]);

	return (
		<div className="toolbox">
			<div className={`header ${contentType === ContentTypes.ELEMENT_PROPERTIES ? 'properties' : ''}`}>
				<div className="bar">
					{contentType === ContentTypes.ELEMENT_TYPES && (
						<SearchInput onSearch={() => {}} height="30px" width="300px" border="1px solid #B7BCC7" readOnly />
					)}
					{contentType === ContentTypes.ELEMENT_PROPERTIES && (
						<div className="element-properties">
							<div className="icon">
								<selectedElementType.meta.icon size={18} />
							</div>
							<p className="name">
								{t(selectedElementType.meta.name)}
							</p>
						</div>
					)}
				</div>
				<Button className="icon-only arrow-button" onClick={handleArrowClick}>
					{contentType === ContentTypes.ELEMENT_TYPES ? <ChevronRight /> : <ChevronLeft />}
				</Button>
			</div>
			{contentType === ContentTypes.ELEMENT_TYPES && <ElementTypes />}
			{contentType === ContentTypes.ELEMENT_PROPERTIES && <ElementProperties />}
		</div>
	);
};

export default memo(EditorToolbox);
