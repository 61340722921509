import { useCallback } from 'react';
import { Archive, Edit2, Eye, Star, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { archivePaymentDelay, favoritePaymentDelay, removePaymentDelay, restorePaymentDelay } from 'redux/actions/paymentDelays';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the PaymentDelayListPage table. (archive, favorite, delete, edit payment delay)
 *
 * @author Matthieu Schaerlinger
 * @author Timothée Simon-Franza
 *
 * @param {object}		row						The row from which we retrieve the data to interact with.
 * @param {boolean}		row.original.archived	The archived status of the payment delay.
 * @param {boolean}		row.original.favorite	The favorite status of the payment delay.
 * @param {string}		row.original.id			The id of the payment delay.
 * @param {number}		row.original.number		The number of the payment delay.
 * @param {string}		row.original.scale		The scale of the payment delay.
 * @param {string}		row.original.term		The term of the payment delay.
 * @param {function}	fetchData				The method to trigger if we need to refresh the parent table's data.
 */
const ActionsCell = ({ row: { original: { archived, favorite, id, number, scale, term } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingArchiveModal, toggle: toggleArchiveModal } = useModal();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditPaymentDelay = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);
	const canDeletePaymentDelay = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.DELETE_SETTING);

	const [archiveButtonRef, archiveTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	const [favoriteButtonRef, favoriteTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onArchiveModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the archive/restore button.
	 *
	 * @author Matthieu Schaerlinger
	 */
	const onArchiveModalConfirmationButtonClick = useCallback(async () => {
		toggleArchiveModal();

		if (archived) {
			await dispatch(restorePaymentDelay({ id, number, scale, term }));
		} else {
			await dispatch(archivePaymentDelay({ id, number, scale, term }));
		}

		fetchData();
	}, [archived, dispatch, fetchData, id, number, scale, term, toggleArchiveModal]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Matthieu Schaerlinger
	 */
	const onEditPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.accounting.parameters.paymentDelays.paymentDelayEdition, { id }));
	}, [id]);

	/**
	 * @function
	 * @name onFavoriteButtonClick
	 * @description Method triggered as a result to an onClick event from the favorite button.
	 *
	 * @author Matthieu Schaerlinger
	 */
	const onFavoriteButtonClick = useCallback(async () => {
		await dispatch(favoritePaymentDelay({ id, number, scale, term }));
		fetchData();
	}, [dispatch, fetchData, id, number, scale, term]);

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Matthieu Schaerlinger
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removePaymentDelay({ id, number, scale, term }));
		fetchData();
	}, [dispatch, fetchData, id, number, scale, term, toggleDeleteModal]);

	return (
		<>
			<Button className="icon-only" onClick={onEditPageRedirectionButtonClick} ref={editButtonRef}>
				{canEditPaymentDelay && !archived ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditPaymentDelay && !archived ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			{canEditPaymentDelay && (
				<>
					<Button className="icon-only" onClick={toggleArchiveModal} ref={archiveButtonRef}>
						<Archive />
					</Button>
					<Tooltip {...archiveTooltipProps}>
						{t('actions.archived')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingArchiveModal}
						message={t(`payment_delay.${archived ? 'restoring' : 'archiving'}.confirmation_modal.content`)}
						confirm={onArchiveModalConfirmationButtonClick}
						cancel={toggleArchiveModal}
						confirmText={t(`payment_delay.${archived ? 'restoring' : 'archiving'}.confirmation_modal.confirm`)}
						cancelText={t(`payment_delay.${archived ? 'restoring' : 'archiving'}.confirmation_modal.cancel`)}
						titleText={t(`payment_delay.${archived ? 'restoring' : 'archiving'}.confirmation_modal.title`)}
					/>
				</>
			)}
			{canEditPaymentDelay && !archived && (
				<>
					<Button className="icon-only" onClick={onFavoriteButtonClick} ref={favoriteButtonRef}>
						<Star className={favorite ? 'orange' : ''} />
					</Button>
					<Tooltip {...favoriteTooltipProps}>
						{t('actions.favorite')}
					</Tooltip>
				</>
			)}
			{canDeletePaymentDelay && !archived && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('payment_delay.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('payment_delay.deletion.confirmation_modal.confirm')}
						cancelText={t('payment_delay.deletion.confirmation_modal.cancel')}
						titleText={t('payment_delay.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			archived: PropTypes.bool,
			favorite: PropTypes.bool.isRequired,
			id: PropTypes.string.isRequired,
			number: PropTypes.number.isRequired,
			scale: PropTypes.string.isRequired,
			term: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
