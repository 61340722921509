import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useRegistrationTypesLoadingSelector
 * @description A selector to retrieve the loading state from the RegistrationType redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useRegistrationTypesLoadingSelector = () => {
	const loading = useSelector((state) => state.registrationTypes?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useRegistrationTypeListSelector
 * @description A selector to retrieve the access definitions list from the access definition redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useRegistrationTypeListSelector = () => {
	const registrationTypes = useSelector((state) => (state.registrationTypes?.items ?? []), shallowEqual);

	return registrationTypes;
};

/**
 * @function
 * @name useRegistrationTypeTotalCountSelector
 * @description A selector to retrieve the totalCount value from the access definition redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useRegistrationTypeTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.registrationTypes?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useRegistrationTypeListSelector,
	useRegistrationTypesLoadingSelector,
	useRegistrationTypeTotalCountSelector,
};
