// @TODO: remove this eslint exception once we have multiple exports.
/* eslint-disable import/prefer-default-export */

/**
 * @function
 * @name formatAccountParent
 * @description Format the account's parent field to display the parent name if it exists.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} account					The account to format the parent field of.
 * @param {object} [account.parent]			The parent account to retrieve the name from.
 * @param {string} [account.parent.name]	The parent account's name to retrieve.
 *
 * @returns {string}
 */
export const formatAccountParent = (account) => (account?.parent?.name ?? '');
