import conf from 'conf';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
	border,
	color,
	flexbox,
	layout,
	position,
	space,
} from 'styled-system';
import { kale, tuna } from 'theme/colors';
import { applicationCard, applicationCardHeader, applicationLogoWrapper } from 'theme/pages/superadmin/companyEditionPage/applicationManagementForm';

import { ToggleSwitch } from 'components/shared/forms/inputs';
import { GreatPrimer, LongPrimer } from 'components/shared/Typography';
import { SvgIcon } from 'components/shared/utils';

const StyledCard = styled('div')(
	border,
	color,
	layout,
	position,
	space,
	{
		boxSizing: 'border-box',
		filter: (({ enabled }) => (enabled ? `drop-shadow(0 0 0.2rem ${tuna.lighter2})` : `drop-shadow(0 0 0.2rem ${tuna.lighter2}) grayscale(1)`)),
		transition: 'filter 0.2s ease-out',

		'&:hover': {
			filter: (({ enabled }) => (enabled ? `drop-shadow(0 0 0.3rem ${tuna.default})` : `drop-shadow(0 0 0.3rem ${tuna.default}) grayscale(1)`)),
		},
	},
);

const StyledApplicationLogoWrapper = styled('div')(
	{
		boxSizing: 'border-box',

		// @TODO: remove fill color.
		'*': {
			fill: kale.default,
		},
	},
	border,
	layout,
	space,
);

const StyledCardHeader = styled('div')(
	{},
	layout,
	flexbox,
);

const StyledCardBody = styled('div')(
	{
		marginTop: '16px',

		h2: {
			margin: 'auto',
			marginBottom: '8px',
			textTransform: 'capitalize',
		},
	},
);

/**
 * @name ApplicationCard
 * @description A card component used to enable or disable access to an application for the current company.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string}	id			 The application's id.
 * @param {bool}	[enabled]	 Whether the application is enabled for the current company.
 * @param {string}	name		 The application's name.
 * @param {bool}	active		 Whether the application is active. If it isn't, it cannot be enabled for any company.
 * @param {string}	description	 The application's description.
 * @param {string}	iconPath	 Path for the application icon's svg file.
 * @param {func}	onToggle	 Method to call whenever the application is toggled on or off.
 */
const ApplicationCard = ({ id, enabled, name, active, description, iconPath, onToggle }) => (
	<StyledCard enabled={enabled} {...applicationCard}>
		<StyledCardHeader {...applicationCardHeader}>
			<StyledApplicationLogoWrapper {...applicationLogoWrapper}>
				<SvgIcon path={`${conf.iconsPath}${iconPath}.svg`} />
			</StyledApplicationLogoWrapper>
			<ToggleSwitch id={id} checked={enabled} disabled={!active} name={name} onChange={() => onToggle(id)} />
		</StyledCardHeader>
		<StyledCardBody>
			<GreatPrimer as="h2" fontWeight={600}>{name}</GreatPrimer>
			<LongPrimer>{description}</LongPrimer>
		</StyledCardBody>
	</StyledCard>
);

ApplicationCard.propTypes = {
	id: PropTypes.string.isRequired,
	enabled: PropTypes.bool,
	name: PropTypes.string.isRequired,
	active: PropTypes.bool.isRequired,
	description: PropTypes.string.isRequired,
	iconPath: PropTypes.string.isRequired,
	onToggle: PropTypes.func.isRequired,
};

ApplicationCard.defaultProps = {
	enabled: false,
};

export default ApplicationCard;
