import update from 'immutability-helper';

/**
 * @function
 * @name resetDirtyFlag
 * @description Resets the dirty flag to false
 *
 * @author Yann Hodiesne
 *
 * @param {object} state					The current state
 *
 * @returns {object} The updated state value
 */
const resetDirtyFlag = (state) => {
	const result = update(state, {
		isDirty: {
			$set: false,
		},
	});

	return result;
};

export default resetDirtyFlag;
