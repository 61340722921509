import { ActionTypes } from '../../actions/contacts/contacts';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_CONTACT_LIST_REQUEST:
		case ActionTypes.FETCH_ALL_FOR_CONTACT_REQUEST:
		case ActionTypes.ADD_CONTACT_REQUEST:
		case ActionTypes.EXPORT_CONTACT_REQUEST:
		case ActionTypes.UPDATE_CONTACT_REQUEST:
		case ActionTypes.REMOVE_CONTACT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_CONTACT_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_CONTACT_LIST_FAILURE:
		case ActionTypes.FETCH_ALL_FOR_CONTACT_FAILURE:
		case ActionTypes.FETCH_CONTACT_FAILURE:
		case ActionTypes.ADD_CONTACT_FAILURE:
		case ActionTypes.EXPORT_CONTACT_FAILURE:
		case ActionTypes.UPDATE_CONTACT_FAILURE:
		case ActionTypes.REMOVE_CONTACT_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.ADD_CONTACT_SUCCESS:
		case ActionTypes.EXPORT_CONTACT_SUCCESS:
		case ActionTypes.UPDATE_CONTACT_SUCCESS:
		case ActionTypes.REMOVE_CONTACT_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_CONTACT_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.contacts],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_CONTACT_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.contact,
			};
		case ActionTypes.FETCH_ALL_FOR_CONTACT_SUCCESS:
			return {
				...state,
				isLoading: false,
				allForForm: payload.allForForm,
			};
		default:
			return state;
	}
};
