import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Environments } from 'constants/environmentEnums';

/**
 * @function
 * @name useCurrentStatusObjectSelector
 * @description A selector to retrieve the current status object from the status object redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the current status object to wait for
 *
 * @returns {object | null}
 */
const useCurrentStatusObjectSelector = (id) => {
	const statusObject = useSelector((state) => (state.statusObjects?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (statusObject?.id === id ? statusObject : null), [id, statusObject]);

	return result;
};

/**
 * @function
 * @name useStatusObjectListSelector
 * @description A selector to retrieve the status object list from the status object redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useStatusObjectListSelector = () => {
	const statusObjects = useSelector((state) => (state.statusObjects?.items ?? []), shallowEqual);

	return statusObjects;
};

/**
 * @function
 * @name useStatusObjectListByEnvironmentSelector
 * @description A selector to retrieve the status object list from the status object redux state, filtered by their environment field.
 *
 * If no environment is provided, the list will be returned as is.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} [environment] The environment to filter by. @see Environments
 *
 * @return {Array}
 *
 * @throws {Error} If the environment is not an item from the Environments enum.
 */
const useStatusObjectListByEnvironmentSelector = (environment) => {
	if (environment && !Object.values(Environments).includes(environment)) {
		throw new Error(`Invalid environment: ${environment}`);
	}

	const statusObjects = useStatusObjectListSelector();

	const result = useMemo(() => (
		environment
			? statusObjects.filter((statusObject) => statusObject.environment === environment)
			: statusObjects
	), [environment, statusObjects]);

	return result;
};

/**
 * @function
 * @name useStatusObjectTotalCountSelector
 * @description A selector to retrieve the totalCount value from the status object redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useStatusObjectTotalCountSelector = () => useSelector((state) => (state.statusObjects?.totalCount ?? undefined), shallowEqual);

/**
 * @function
 * @name useStatusObjectLoadingSelector
 * @description A selector to retrieve the loading state from the status object redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useStatusObjectLoadingSelector = () => useSelector((state) => state.statusObjects?.isLoading ?? false);

export {
	useCurrentStatusObjectSelector,
	useStatusObjectListByEnvironmentSelector,
	useStatusObjectListSelector,
	useStatusObjectLoadingSelector,
	useStatusObjectTotalCountSelector,
};
