import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PartnerTabs } from 'constants/partnerEnum';
import { formatRegistrationName } from 'lib/registrations/formatRegistrationData';
import { formatSectorName } from 'lib/sectors/formatSectorData';
import PropTypes from 'prop-types';
import { addContact } from 'redux/actions/contacts/contacts';
import { fetchAllForPartner } from 'redux/actions/partners/partners';
import { addRegistration } from 'redux/actions/registrations';
import { addRelationType } from 'redux/actions/relationTypes';
import { addSector } from 'redux/actions/sectors/sectors';
import { useAllForPartnerSelector, usePartnersLoadingSelector } from 'redux/selectors/partners/partners';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

import { ContactCreationForm } from 'components/contacts';
import { RegistrationCreationForm } from 'components/registrations';
import { RelationTypeCreationForm } from 'components/relationTypes';
import { SectorCreationForm } from 'components/sectors';
import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Tab, TabList, TabPanel } from 'components/shared/layout/tabs';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import { Address, PartnerIdentifier, PartnerKPI, PartnerLinkedContacts, PartnerRegistration, PhoneMail } from './partnerTabs';
import PartnerTitle from './PartnerTitle';

/**
 * @name PartnerEditionForm
 * @description A form used to update an existing partner.
 *
 * @author Romaric Barthe
 *
 * @param {function}	onSubmit				The method to trigger upon form submission.
 * @param {object}		partner					The partner object to edit information from.
 * @param {boolean}		[inSuperAdmin=false]	Whether the form is being displayed inside the super-admin panel or not, default to false.
 */
const PartnerEditionForm = ({ onSubmit, partner, inSuperAdmin }) => {
	const dispatch = useDispatch();

	const { t } = useTranslation();

	const allForPartnerForm = useAllForPartnerSelector();
	const contacts = useMemo(() => allForPartnerForm?.contacts ?? [], [allForPartnerForm]);
	const currencies = useMemo(() => allForPartnerForm?.currencies ?? [], [allForPartnerForm]);
	const currentUser = useCurrentConnectedUserSelector();
	const parents = useMemo(() => allForPartnerForm?.partners ?? [], [allForPartnerForm]);
	const registrations = useMemo(() => allForPartnerForm?.registrations ?? [], [allForPartnerForm]);
	const relationTypes = useMemo(() => allForPartnerForm?.relationTypes ?? [], [allForPartnerForm]);
	const sectors = useMemo(() => allForPartnerForm?.sectors ?? [], [allForPartnerForm]);

	const isLoading = usePartnersLoadingSelector();

	const canEditParter = useAccessRight(AccessRights.commonModules.partners.enhancedRights.CREATE_PARTNER);

	const [partnerActivity, setPartnerActivity] = useState();
	const [partnerLegalRepresentant, setPartnerLegalRepresentant] = useState();
	const [partnerName, setPartnerName] = useState();
	const [partnerWebsite, setPartnerWebsite] = useState();

	const { formProps: { ref: formRef, ...formProps }, buttonProps } = useSubmitButton();

	const registrationsSelectOptions = useMemo(() => (registrations.map((registration) => (
		{ id: registration.id, fullname: formatRegistrationName(registration, t) }
	))), [registrations, t]);

	const sectorsSelectOptions = useMemo(() => sectors
		.sort((a, b) => a.name > b.name || a.type.name > b.type.name)
		.map((sector) => ({ id: sector.id, fullname: formatSectorName(sector) })),
	[sectors]);

	const tabsToDisplay = useMemo(() => {
		const tabs = [];
		Object.entries(PartnerTabs).forEach(([, value]) => {
			tabs.push(value);
		});

		if (inSuperAdmin) {
			return tabs.filter((tab) => tab !== PartnerTabs.CONTACTS && tab !== PartnerTabs.KPI && tab !== PartnerTabs.REGISTRATION);
		}

		return tabs;
	}, [inSuperAdmin]);

	const contactCreationModal = useFormModal(ContactCreationForm, t('pages.common_modules.contacts.creation'), fetchAllForPartner, addContact);
	const registrationCreationModal = useFormModal(RegistrationCreationForm, t('pages.common_modules.registrations.creation'), fetchAllForPartner, addRegistration);
	const relationTypeCreationModal = useFormModal(RelationTypeCreationForm, t('pages.common_modules.relation_types.creation'), fetchAllForPartner, addRelationType);
	const sectorCreationModal = useFormModal(SectorCreationForm, t('pages.common_modules.sectors.creation'), fetchAllForPartner, addSector);

	const [selectedPartnerTabId, setSelectedPartnerTabId] = useState('identity-tab');

	/**
	 * @function
	 * @name setTitles
	 * @description A function to refresh Title variables.
	 *
	 * @author Romaric Barthe
	 */
	const setTitles = useCallback(() => {
		const formData = formRef.current?.getFormValues();
		setPartnerActivity(formData?.activityDescription);
		setPartnerLegalRepresentant(contacts.filter((c) => (c.id === (formData?.legalRepresentant ?? undefined)).length === 1)
			? contacts.filter((c) => (c.id === formData.legalRepresentant))[0]
			: undefined);
		setPartnerName(formData?.name);
		setPartnerWebsite(formData?.website);
	}, [contacts, formRef]);

	/**
	 * @function
	 * @name onTabSelectionChanged
	 * @description A function to switch between tabs.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {string}	tabId		The id of the tab triggering the action.
	 */
	const onTabSelectionChanged = useCallback((tabId) => {
		setSelectedPartnerTabId(tabId);
	}, []);

	useEffect(() => {
		dispatch(fetchAllForPartner(inSuperAdmin));
	}, [dispatch, inSuperAdmin]);

	const defaultValues = useMemo(() => ({
		...partner,
		currency: partner.currency?.id,
		legalRepresentant: partner.legalRepresentant?.id,
		parent: partner.parent?.id ?? undefined,
		contacts: partner.contacts?.map(({ id }) => (id ?? '')) ?? [],
		relationTypes: partner.relationTypes?.map(({ id }) => (id ?? '')) ?? [],
		registrations: partner.registrations?.map(({ id }) => (id ?? '')) ?? [],
		sectors: partner.sectors?.map(({ id }) => (id ?? '')) ?? [],
	}), [partner]);

	// Set state's variables
	useEffect(() => {
		setPartnerActivity(defaultValues?.activityDescription);
		setPartnerLegalRepresentant(contacts.filter((c) => (c.id === (defaultValues?.legalRepresentant ?? undefined))).length === 1
			? contacts.filter((c) => (c.id === defaultValues?.legalRepresentant))[0]
			: undefined);
		setPartnerName(defaultValues?.name);
		setPartnerWebsite(defaultValues?.website);
	}, [contacts, defaultValues]);

	const handleSubmit = useCallback((formData) => {
		// The backend responds with 400 Bad Request if we send an empty phone line, but we can't make phones required
		const result = {
			...formData,
			phones: formData.phones?.filter(({ type, number }) => type && number),
		};

		onSubmit(result);
	}, [onSubmit]);

	return (
		<DynamicForm
			ref={formRef}
			onSubmit={handleSubmit}
			cleanFormData
			defaultValues={defaultValues}
			disabled={!canEditParter}
			{...formProps}
		>
			{!inSuperAdmin && (
				<PartnerTitle
					currentUser={currentUser}
					partner={partner}
					partnerActivity={partnerActivity}
					partnerLegalRepresentant={partnerLegalRepresentant}
					partnerName={partnerName}
					partnerWebsite={partnerWebsite}
				/>
			)}

			<TabList
				defaultSelectedTabId={selectedPartnerTabId}
				onTabSelectionChanged={onTabSelectionChanged}
			>
				{tabsToDisplay.map((tabId) => (
					<Tab key={tabId} tabId={`${tabId}-tab`} tabPanelId={`${tabId}-tabpanel`} dontDisplayCloseButton>
						{t(`partners.fields.${tabId}`)}
					</Tab>
				))}
			</TabList>

			<TabPanel
				tabId="identity-tab"
				tabPanelId="identity-tabpanel"
				hidden={selectedPartnerTabId !== 'identity-tab'}
			>
				<PartnerIdentifier
					currencies={currencies}
					inSuperAdmin={inSuperAdmin}
					isLoading={isLoading}
					parents={parents}
					setTitles={setTitles}
				/>
			</TabPanel>

			<TabPanel
				tabId="address-tab"
				tabPanelId="address-tabpanel"
				hidden={selectedPartnerTabId !== 'address-tab'}
			>
				<Address />
			</TabPanel>

			<TabPanel
				tabId="phone_mail-tab"
				tabPanelId="phone_mail-tabpanel"
				hidden={selectedPartnerTabId !== 'phone_mail-tab'}
			>
				<PhoneMail />
			</TabPanel>

			{!inSuperAdmin && (
				<TabPanel
					tabId="linked_contacts-tab"
					tabPanelId="linked_contacts-tabpanel"
					hidden={selectedPartnerTabId !== 'linked_contacts-tab'}
				>
					<PartnerLinkedContacts
						contactCreationModal={contactCreationModal}
						contacts={contacts}
						isLoading={isLoading}
						linkedContacts={partner?.contacts}
						setTitles={setTitles}
					/>
				</TabPanel>
			)}

			{!inSuperAdmin && (
				<TabPanel
					tabId="registration-tab"
					tabPanelId="registration-tabpanel"
					hidden={selectedPartnerTabId !== 'registration-tab'}
				>
					<PartnerRegistration
						isLoading={isLoading}
						registrations={registrationsSelectOptions}
						registrationCreationModal={registrationCreationModal}
						relationTypes={relationTypes}
						relationTypeCreationModal={relationTypeCreationModal}
						sectors={sectorsSelectOptions}
						sectorCreationModal={sectorCreationModal}
					/>
				</TabPanel>
			)}

			{!inSuperAdmin && (
				<TabPanel
					tabId="kpi-tab"
					tabPanelId="kpi-tabpanel"
					hidden={selectedPartnerTabId !== 'kpi-tab'}
				>
					<PartnerKPI />
				</TabPanel>
			)}

			<Button className="primary" type="submit" {...buttonProps}>
				{canEditParter ? t('partner.edition.action') : t('partner.edition.close')}
			</Button>
		</DynamicForm>
	);
};

PartnerEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	partner: PropTypes.shape({
		name: PropTypes.string.isRequired,
		activityDescription: PropTypes.string,
		emails: PropTypes.arrayOf(PropTypes.string),
		id: PropTypes.string.isRequired,
		phones: PropTypes.arrayOf(PropTypes.shape({
			number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			type: PropTypes.string.isRequired,
		})),
		address: PropTypes.string,
		parent: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
		postCode: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		website: PropTypes.string,
		currency: PropTypes.shape({
			id: PropTypes.string.isRequired,
			description: PropTypes.string,
			name: PropTypes.string.isRequired,
		}),
		legalRepresentant: PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string,
			lastName: PropTypes.string.isRequired,
		}),
		contacts: PropTypes.arrayOf(PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string,
			lastName: PropTypes.string.isRequired,
		})),
		relationTypes: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
		})),
		registrations: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
			type: PropTypes.shape({
				name: PropTypes.string.isRequired,
			}).isRequired,
		})),
		sectors: PropTypes.arrayOf(PropTypes.shape({
			code: PropTypes.string,
			name: PropTypes.string.isRequired,
			type: PropTypes.shape({
				name: PropTypes.string.isRequired,
			}).isRequired,
		})),
	}).isRequired,
	inSuperAdmin: PropTypes.bool,
};

PartnerEditionForm.defaultProps = {
	inSuperAdmin: false,
};

export default PartnerEditionForm;
