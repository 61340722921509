import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTextObject, updateTextObject } from 'redux/actions/textObjects';
import { useCurrentTextObjectSelector } from 'redux/selectors/textObjects';
import routes from 'routes';

import { TextObjectEditionForm } from 'components/textObjects';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name TextObjectEditionPage
 * @description A page used to edit an existing text object.
 *
 * @author Romaric Barthe
 */
const TextObjectEditionPage = () => {
	const { id: textObjectId } = useParams();
	const dispatch = useDispatch();

	const textObject = useCurrentTextObjectSelector(textObjectId);

	const canEditTextObject = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING) && !textObject?.archived;

	useEffect(() => {
		dispatch(fetchTextObject(textObjectId));
	}, [dispatch, textObjectId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditTextObject) {
			dispatch(updateTextObject(formData, textObjectId, routes.settings.textObjects.list));
		} else {
			redirectOnSuccess(routes.settings.textObjects.list);
		}
	}, [canEditTextObject, dispatch, textObjectId]);

	return (
		textObject
			? <TextObjectEditionForm textObject={textObject} onSubmit={handleFormSubmit} />
			: <p>Loading text object information</p>
	);
};

export default TextObjectEditionPage;
