/* eslint-disable import/prefer-default-export */
/**
 * @function
 * @name spliceString
 * @description Allows us to replace/insert characters into a string
 *
 * @author Florian Fornazaric
 *
 * @param {string} string	String to replace
 * @param {number} index	Index from where we start the replacement
 * @param {number} length 	Number of char to replace
 * @param {string} value	String to insert
 *
 * @returns {string} String with replaced characters
 */
export const spliceString = (string, index, length, value) => string.slice(0, index) + value + string.slice(index + length);
