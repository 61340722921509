/**
 * @constant
 * @name DefaultSizes
 * @description An object containing all the default sizes used by the editor
 *
 * @author Florian Fornazaric
 */
const DefaultSizes = Object.freeze({
	ROW_HEIGHT: 14,
	FONT_SIZE: 12,

	/**
	 * @description The default font size of the six levels of titles
	 *
	 * @note These sizes are calculated using the font sizes in src/styles/bases/_typography.scss
	 */
	HEADERS_FONT_SIZE: [18, 16, 13, 12, 11, 11],
	HEADER_MAX_HEIGHT: 164,
	HEADER_MIN_HEIGHT: 18,
	FOOTER_MAX_HEIGHT: 164,
	FOOTER_MIN_HEIGHT: 18,
});

export default DefaultSizes;
