import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import { ExpenseCreationTypes } from 'constants/expenseEnums';
import { formatExpenseName } from 'lib/expenses/formatExpenseData';
import PropTypes from 'prop-types';
import { fetchExpensesSelectList } from 'redux/actions/expenses/expensesSelect';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { useExpensesSelectListSelector, useExpensesSelectLoadingSelector } from 'redux/selectors/expenses/expensesSelect';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { getExpenseCreationTypes } from './functions';

/**
 * @name ExpensePreCreationForm
 * @description A form used to create a new expense.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const ExpensePreCreationForm = ({ onSubmit }) => {
	const [expenseCreationTypeSelected, setExpenseCreationTypeSelected] = useState(null);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const expensesList = useExpensesSelectListSelector();
	const expenses = useMemo(() => expensesList.sort((a, b) => a.updatedAt < b.updatedAt), [expensesList]);
	const areExpensesLoading = useExpensesSelectLoadingSelector();

	const numberFormat = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.EXPENSE)[0];
	const expensesWithFormattedName = useMemo(() => (expenses.map((expense) => ({
		...expense,
		name: formatExpenseName(expense, numberFormat, t),
	}))), [expenses, numberFormat, t]);

	useEffect(() => {
		dispatch(fetchExpensesSelectList({ rowsPerPage: 0 }));
		dispatch(fetchNumberFormatList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const expenseCreationTypesList = useMemo(() => getExpenseCreationTypes().map(
		(expenseCreationType) => ({ id: expenseCreationType.id, label: t(expenseCreationType.label) })
	), [t]);

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm
			onSubmit={onSubmit}
			{...formProps}
		>
			<Select
				label={t('expense.creation.creation_type.label')}
				labelKey="label"
				name="creationType"
				onChange={setExpenseCreationTypeSelected}
				options={expenseCreationTypesList}
				rules={{
					required: Validators.isRequired(t('expense.creation.creation_type.validation_errors.required')),
				}}
				valueKey="id"
			/>
			{(expenseCreationTypeSelected === ExpenseCreationTypes.FROM_EXPENSE || expenseCreationTypeSelected === ExpenseCreationTypes.REVERT) && (
				<Select
					label={t('expense.creation.creation_type.types.expense.label')}
					labelKey="name"
					name="sourceExpenseId"
					options={expensesWithFormattedName}
					rules={{
						required: Validators.isRequired(t('expense.creation.creation_type.types.expense.validation_errors.required')),
					}}
					valueKey="id"
					isLoading={areExpensesLoading}
				/>
			)}

			<Button className="primary" type="submit" {...buttonProps}>{t('expense.precreation.action')}</Button>
		</DynamicForm>
	);
};

ExpensePreCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default ExpensePreCreationForm;
