import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Select } from 'components/shared/forms/inputs';

/**
 * @name ProjectRights
 * @description A form used to group the rights fields.
 *
 * @author Romaric Barthe
 */
const ProjectRights = ({
	isLoading,
	userProps,
	userSelectOptions,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<Select
				label={t('projects.edition.inputs.responsibles.label')}
				placeholder={t('projects.edition.inputs.responsibles.placeholder')}
				name="responsibles"
				options={userSelectOptions}
				labelKey="label"
				valueKey="value"
				isMulti
				isLoading={isLoading}
				{...userProps}
			/>
			<Select
				label={t('projects.edition.inputs.collaborators.label')}
				placeholder={t('projects.edition.inputs.collaborators.placeholder')}
				name="collaborators"
				options={userSelectOptions}
				labelKey="label"
				valueKey="value"
				isMulti
				isLoading={isLoading}
				{...userProps}
			/>
		</>
	);
};

ProjectRights.propTypes = {
	isLoading: PropTypes.bool,
	userProps: PropTypes.object,
	userSelectOptions: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string,
			username: PropTypes.string,
		})
	),
};

ProjectRights.defaultProps = {
	isLoading: true,
	userProps: undefined,
	userSelectOptions: {},
};

export default ProjectRights;
