import { useEffect, useRef } from 'react';

/**
 * @name useResizeObserver
 * @description Hook to observe changes in the size of an component (here observedRef)
 * When it changes the onSizeChanged callback function is called
 * ResizeObserver is a vanilla JS API that monitors the size of an element and triggers a callback when the size changes
 *
 * Note: This function is a hook, as such it must be called inside a react component
 *
 * @author Florian Fornazaric
 *
 * @param {function} 	onSizeChanged	The callback function called when the size changes
 * @param {bool}		isActive		Whether the ResizeObserver is connected or not
 * @param {object} 		[observedRef]	The ref of the component to observe
 *
 * @returns {object} The ref of the component to observe
 */
const useResizeObserver = (onSizeChanged, isActive, observedRef) => {
	const defaultRef = useRef();
	const resolvedRef = observedRef ?? defaultRef;

	const resizeObserverRef = useRef();
	const onSizeChangedPointer = useRef(onSizeChanged);
	onSizeChangedPointer.current = onSizeChanged;

	useEffect(() => {
		resizeObserverRef.current = new ResizeObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.target === resolvedRef.current) {
					onSizeChangedPointer.current();
				}
			});
		});
	}, [onSizeChangedPointer, resolvedRef]);

	useEffect(() => {
		if (isActive) {
			resizeObserverRef.current.observe(resolvedRef.current);
		} else {
			resizeObserverRef.current.disconnect();
		}

		return () => {
			if (isActive) {
				resizeObserverRef.current.disconnect();
			}
		};
	}, [isActive, resolvedRef]);

	return resolvedRef;
};

export default useResizeObserver;
