import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
	AreaChart,
	Block,
	Card,
	ColGrid,
	Divider,
	Flex,
	Metric,
	SelectBox,
	SelectBoxItem,
	Text,
	Title,
} from '@tremor/react';
import conf from 'conf';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { formatNumber } from 'lib/shared/format';
import moment from 'moment';
import { fetchCurrencyList } from 'redux/actions/currencies';
import { fetchDashboard } from 'redux/actions/dashboard';
import { useCurrenciesLoadingSelector, useCurrencyListSelector } from 'redux/selectors/currencies';
import { useDashboardValues } from 'redux/selectors/dashboard';
import { useCurrentConnectedUserSelector } from 'redux/selectors/users';

import { SvgIcon } from 'components/shared/utils';

/**
 * @name HomePage
 * @description The home page of the application.
 *
 * Reference for CSS classNames and Tremor components: https://www.tremor.so/docs/getting-started/demo-dashboard
 *
 * @author Yann Hodiesne
 */
const HomePage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchDashboard());
		dispatch(fetchCurrencyList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const values = useDashboardValues();
	const currencies = useCurrencyListSelector();
	const isCurrenciesLoading = useCurrenciesLoadingSelector();
	const currentUser = useCurrentConnectedUserSelector();

	const defaultCurrencyId = useMemo(() => currentUser?.company?.mainPartner?.currency?.id, [currentUser]);

	const [selectedCurrencyId, setSelectedCurrencyId] = useState(defaultCurrencyId);

	const selectedCurrencyName = useMemo(() => currencies.find((x) => x.id === selectedCurrencyId)?.name, [currencies, selectedCurrencyId]);
	const currencySymbol = useMemo(() => ((selectedCurrencyName && selectedCurrencyName !== '') ? t(`currency.${selectedCurrencyName}`) : ''), [selectedCurrencyName, t]);

	const showInvoice = useAccessRight(AccessRights.accounting.invoices.VIEW);
	const showQuotation = useAccessRight(AccessRights.sales.quotations.VIEW);
	const showExpenseReports = useAccessRight(AccessRights.humanResources.expenses.VIEW);
	const showTargeting = useAccessRight(AccessRights.operations.targetings.VIEW);
	const showContacts = useAccessRight(AccessRights.commonModules.contacts.VIEW);
	const showPartners = useAccessRight(AccessRights.commonModules.partners.VIEW);
	const showSectors = useAccessRight(AccessRights.commonModules.sectors.VIEW);
	const showProjects = useAccessRight(AccessRights.operations.projects.VIEW);
	const showOffers = useAccessRight(AccessRights.sales.offers.VIEW);
	const showHistory = useAccessRight(AccessRights.sales.crm.VIEW);

	// Check if we are not displaying an empty data volume card
	const showDataVolume = useMemo(
		() => showContacts || showPartners || showSectors || showProjects || showOffers || showHistory,
		[showContacts, showHistory, showOffers, showPartners, showProjects, showSectors]
	);

	const greetings = useMemo(() => {
		if (!currentUser) {
			return t('dashboard.loading');
		}

		if (currentUser.contact?.firstName && currentUser.contact?.lastName) {
			return t('dashboard.greetings_full_name', { firstName: currentUser.contact.firstName, lastName: currentUser.contact.lastName });
		}

		return t('dashboard.greetings_username', { username: currentUser.username });
	}, [currentUser, t]);

	const currentYear = useMemo(() => new Date().getFullYear(), []);
	const lastYear = useMemo(() => currentYear - 1, [currentYear]);

	const formatAmount = useCallback((amount) => formatNumber(amount, { symbol: currencySymbol }), [currencySymbol]);

	const mapByMonthToGraphProps = useCallback((byMonth, accessor, format) => {
		const currentYearCategory = currentYear.toString();
		const lastYearCategory = lastYear.toString();
		const dataKey = 'date';

		const data = moment.monthsShort().map((monthName) => {
			const currentMonthNumber = moment().month(monthName).month() + 1;

			const currentYearRow = byMonth?.find((row) => parseInt(row.year, 10) === currentYear
				&& parseInt(row.month, 10) === currentMonthNumber
				&& row.currency === selectedCurrencyId) ?? {};

			const lastYearRow = byMonth?.find((row) => parseInt(row.year, 10) === lastYear
				&& parseInt(row.month, 10) === currentMonthNumber
				&& row.currency === selectedCurrencyId) ?? {};

			return {
				[dataKey]: monthName,
				[lastYearCategory]: parseFloat(lastYearRow?.[accessor] ?? 0),
				[currentYearCategory]: parseFloat(currentYearRow?.[accessor] ?? 0),
			};
		});

		const minValue = Math.floor(
			(Math.min(...data.map((row) => row[currentYearCategory]), ...data.map((row) => row[lastYearCategory])) - 100) / 100
		) * 100;

		const maxValue = Math.ceil(
			(Math.max(...data.map((row) => row[currentYearCategory]), ...data.map((row) => row[lastYearCategory])) + 100) / 100
		) * 100;

		return {
			data,
			categories: [currentYearCategory, lastYearCategory],
			dataKey,
			colors: ['green', 'blue'],
			minValue: minValue > 0 ? minValue : 0,
			maxValue,
			valueFormatter: format || undefined,
		};
	}, [currentYear, lastYear, selectedCurrencyId]);

	// Quotation
	const quotationValues = useMemo(() => {
		const currentYearValues = values?.quotations?.byYear?.find((row) => parseInt(row.year, 10) === currentYear && row.currency === selectedCurrencyId);
		const lastYearValues = values?.quotations?.byYear?.find((row) => parseInt(row.year, 10) === lastYear && row.currency === selectedCurrencyId);

		return {
			totalCurrentYear: parseFloat(currentYearValues?.amount ?? 0),
			totalLastYear: parseFloat(lastYearValues?.amount ?? 0),
			averageCurrentYear: parseFloat(currentYearValues?.average ?? 0),
			averageLastYear: parseFloat(lastYearValues?.average ?? 0),
			graphProps: mapByMonthToGraphProps(values?.quotations?.byMonth, 'amount', formatAmount),
		};
	}, [currentYear, selectedCurrencyId, formatAmount, lastYear, mapByMonthToGraphProps, values?.quotations?.byMonth, values?.quotations?.byYear]);

	// Invoice
	const invoiceValues = useMemo(() => {
		const currentYearValues = values?.invoices?.byYear?.find((row) => parseInt(row.year, 10) === currentYear && row.currency === selectedCurrencyId);
		const lastYearValues = values?.invoices?.byYear?.find((row) => parseInt(row.year, 10) === lastYear && row.currency === selectedCurrencyId);

		return {
			totalCurrentYear: parseFloat(currentYearValues?.amount ?? 0),
			totalLastYear: parseFloat(lastYearValues?.amount ?? 0),
			averageCurrentYear: parseFloat(currentYearValues?.average ?? 0),
			averageLastYear: parseFloat(lastYearValues?.average ?? 0),
			graphProps: mapByMonthToGraphProps(values?.invoices?.byMonth, 'amount', formatAmount),
		};
	}, [currentYear, selectedCurrencyId, formatAmount, lastYear, mapByMonthToGraphProps, values?.invoices?.byMonth, values?.invoices?.byYear]);

	// Expense
	const expenseValues = useMemo(() => {
		const currentYearValues = values?.expenses?.byYear?.find((row) => parseInt(row.year, 10) === currentYear && row.currency === selectedCurrencyId);
		const lastYearValues = values?.expenses?.byYear?.find((row) => parseInt(row.year, 10) === lastYear && row.currency === selectedCurrencyId);

		return {
			totalCurrentYear: parseFloat(currentYearValues?.amount ?? 0),
			totalLastYear: parseFloat(lastYearValues?.amount ?? 0),
			averageCurrentYear: parseFloat(currentYearValues?.average ?? 0),
			averageLastYear: parseFloat(lastYearValues?.average ?? 0),
			graphProps: mapByMonthToGraphProps(values?.expenses?.byMonth, 'amount', formatAmount),
		};
	}, [currentYear, selectedCurrencyId, formatAmount, lastYear, mapByMonthToGraphProps, values?.expenses?.byMonth, values?.expenses?.byYear]);

	// Targeting
	const targetingValues = useMemo(() => {
		const currentYearValues = values?.targetings?.byYear?.find((row) => parseInt(row.year, 10) === currentYear && row.currency === selectedCurrencyId);
		const lastYearValues = values?.targetings?.byYear?.find((row) => parseInt(row.year, 10) === lastYear && row.currency === selectedCurrencyId);

		return {
			count: parseInt(currentYearValues?.count ?? 0, 10),
			targetPartners: parseInt(lastYearValues?.targetPartners ?? 0, 10),
		};
	}, [currentYear, selectedCurrencyId, lastYear, values?.targetings?.byYear]);

	// History
	const historyValues = useMemo(() => ({
		graphProps: mapByMonthToGraphProps(values?.crm?.byMonth, 'count'),
	}), [mapByMonthToGraphProps, values?.crm?.byMonth]);

	// Data volume
	const dataVolumeValues = useMemo(() => ({
		contactsCount: parseInt(values?.contacts?.count ?? 0, 10),
		contactsRgpdCount: parseInt(values?.contacts?.rgpdCount ?? 0, 10),
		partnersCount: parseInt(values?.partners?.count ?? 0, 10),
		sectorsCount: parseInt(values?.sectors?.count ?? 0, 10),
		projectsCount: parseInt(values?.projects?.count ?? 0, 10),
		offersCount: parseInt(values?.offers?.count ?? 0, 10),
		crmCount12Months: parseInt(values?.crm?.countOneYear ?? 0, 10),
		crmCountOneMonth: parseInt(values?.crm?.countOneMonth ?? 0, 10),
	}), [
		values?.contacts?.count,
		values?.contacts?.rgpdCount,
		values?.crm?.countOneMonth,
		values?.crm?.countOneYear,
		values?.offers?.count,
		values?.partners?.count,
		values?.projects?.count,
		values?.sectors?.count,
	]);

	const usedCurrencies = useMemo(() => {
		// eslint-disable-next-line require-jsdoc
		const getCurrencies = (valuesArray) => [...new Set(valuesArray?.map((row) => row.currency)?.filter(Boolean) ?? [])];

		return [...new Set([
			...getCurrencies(values?.quotations?.byYear),
			...getCurrencies(values?.quotations?.byMonth),
			...getCurrencies(values?.invoices?.byYear),
			...getCurrencies(values?.invoices?.byMonth),
			...getCurrencies(values?.expenses?.byYear),
			...getCurrencies(values?.expenses?.byMonth),
		])];
	}, [values?.expenses?.byMonth, values?.expenses?.byYear, values?.invoices?.byMonth, values?.invoices?.byYear, values?.quotations?.byMonth, values?.quotations?.byYear]);

	return (
		<div className="dashboard p-6 sm:p-10">
			<h3>
				{greetings}
			</h3>

			{!isCurrenciesLoading && usedCurrencies.length > 1 && (
				<SelectBox
					value={selectedCurrencyId}
					onValueChange={setSelectedCurrencyId}
					placeholder={t('dashboard.loading')}
				>
					{usedCurrencies.map((currency) => (
						<SelectBoxItem key={currency} value={currency} text={currencies.find((x) => x.id === currency)?.name} />
					))}
				</SelectBox>
			)}

			<ColGrid numColsMd={1} numColsLg={2} gapX="gap-x-6" gapY="gap-y-6" marginTop="mt-4">
				{showQuotation && (quotationValues.totalCurrentYear !== 0 || quotationValues.totalLastYear !== 0) && (
					<Card>
						<div className="tr-h-full tr-grid" style={{ alignContent: 'space-between' }}>
							<div className="tr-flex tr-w-full tr-items-center tr-mt-0">
								<span className="tr-inline-flex tr-flex-shrink-0 tr-items-center tr-pr-2.5 tr-pt-1.5 tr-pb-1.5">
									<SvgIcon
										className="card-icon tr-h-8 tr-w-8"
										path={`${conf.iconsPath}app_quotations.svg`}
									/>
								</span>
								<Title>{t('dashboard.quotation.title')}</Title>
							</div>
							<div className="tr-mt-3" />
							<Flex alignItems="items-start">
								<Block>
									<Text>{t('dashboard.quotation.total_current_year')}</Text>
									<Metric>{formatAmount(quotationValues.totalCurrentYear)}</Metric>
								</Block>
								<Block>
									<Text>{t('dashboard.quotation.total_last_year')}</Text>
									<Metric>{formatAmount(quotationValues.totalLastYear)}</Metric>
								</Block>
							</Flex>
							<Flex alignItems="items-start" marginTop="mt-3">
								<Block>
									<Text>{t('dashboard.quotation.average_current_year')}</Text>
									<Metric>{formatAmount(quotationValues.averageCurrentYear)}</Metric>
								</Block>
								<Block>
									<Text>{t('dashboard.quotation.average_last_year')}</Text>
									<Metric>{formatAmount(quotationValues.averageLastYear)}</Metric>
								</Block>
							</Flex>
							<AreaChart
								{...quotationValues.graphProps}
								height="h-72"
								marginTop="mt-4"
							/>
						</div>
					</Card>
				)}

				{showInvoice && (invoiceValues.totalCurrentYear !== 0 || invoiceValues.totalLastYear !== 0) && (
					<Card>
						<div className="tr-h-full tr-grid" style={{ alignContent: 'space-between' }}>
							<div className="tr-flex tr-w-full tr-items-center tr-mt-0">
								<span className="tr-inline-flex tr-flex-shrink-0 tr-items-center tr-pr-2.5 tr-pt-1.5 tr-pb-1.5">
									<SvgIcon
										className="card-icon tr-h-8 tr-w-8"
										path={`${conf.iconsPath}app_invoices.svg`}
									/>
								</span>
								<Title>{t('dashboard.invoice.title')}</Title>
							</div>
							<div className="tr-mt-3" />
							<Flex alignItems="items-start">
								<Block>
									<Text>{t('dashboard.invoice.total_current_year')}</Text>
									<Metric>{formatAmount(invoiceValues.totalCurrentYear)}</Metric>
								</Block>
								<Block>
									<Text>{t('dashboard.invoice.total_last_year')}</Text>
									<Metric>{formatAmount(invoiceValues.totalLastYear)}</Metric>
								</Block>
							</Flex>
							<Flex alignItems="items-start" marginTop="mt-3">
								<Block>
									<Text>{t('dashboard.invoice.average_current_year')}</Text>
									<Metric>{formatAmount(invoiceValues.averageCurrentYear)}</Metric>
								</Block>
								<Block>
									<Text>{t('dashboard.invoice.average_last_year')}</Text>
									<Metric>{formatAmount(invoiceValues.averageLastYear)}</Metric>
								</Block>
							</Flex>
							<AreaChart
								{...invoiceValues.graphProps}
								height="h-72"
								marginTop="mt-4"
							/>
						</div>
					</Card>
				)}

				{showExpenseReports && (expenseValues.averageCurrentYear !== 0 || expenseValues.averageLastYear !== 0) && (
					<Card>
						<div className="tr-h-full tr-grid" style={{ alignContent: 'space-between' }}>
							<div className="tr-flex tr-w-full tr-items-center tr-mt-0">
								<span className="tr-inline-flex tr-flex-shrink-0 tr-items-center tr-pr-2.5 tr-pt-1.5 tr-pb-1.5">
									<SvgIcon
										className="card-icon tr-h-8 tr-w-8"
										path={`${conf.iconsPath}app_expenses.svg`}
									/>
								</span>
								<Title>{t('dashboard.expense.title')}</Title>
							</div>
							<div className="tr-mt-3" />
							<Flex alignItems="items-start">
								<Block>
									<Text>{t('dashboard.expense.average_current_year')}</Text>
									<Metric>{formatAmount(expenseValues.averageCurrentYear)}</Metric>
								</Block>
								<Block>
									<Text>{t('dashboard.expense.average_last_year')}</Text>
									<Metric>{formatAmount(expenseValues.averageLastYear)}</Metric>
								</Block>
							</Flex>
							<AreaChart
								{...expenseValues.graphProps}
								height="h-72"
								marginTop="mt-4"
							/>
						</div>
					</Card>
				)}

				{showTargeting && (targetingValues.count !== 0 || targetingValues.targetPartners !== 0) && (
					<Card>
						<div className="tr-h-full tr-grid" style={{ alignContent: 'space-between' }}>
							<div className="tr-flex tr-w-full tr-items-center tr-mt-0">
								<span className="tr-inline-flex tr-flex-shrink-0 tr-items-center tr-pr-2.5 tr-pt-1.5 tr-pb-1.5">
									<SvgIcon
										className="card-icon tr-h-8 tr-w-8"
										path={`${conf.iconsPath}app_targetings.svg`}
									/>
								</span>
								<Title>{t('dashboard.targeting.title')}</Title>
							</div>
							<div className="tr-mt-3" />
							<Flex alignItems="items-start">
								<Block>
									<Text>{t('dashboard.targeting.count')}</Text>
									<Metric>{targetingValues.count}</Metric>
								</Block>
								<Block>
									<Text>{t('dashboard.targeting.target_partners_count')}</Text>
									<Metric>{targetingValues.targetPartners}</Metric>
								</Block>
							</Flex>
							{showHistory && (
								<AreaChart
									{...historyValues.graphProps}
									height="h-72"
									marginTop="mt-4"
								/>
							)}
						</div>
					</Card>
				)}

				{showDataVolume && (
					<Card>
						<div className="tr-flex tr-w-full tr-items-center tr-mt-0">
							<span className="tr-inline-flex tr-flex-shrink-0 tr-items-center tr-pr-2.5 tr-pt-1.5 tr-pb-1.5">
								<SvgIcon
									className="card-icon tr-h-8 tr-w-8"
									path={`${conf.iconsPath}app_common_settings.svg`}
								/>
							</span>
							<Title>{t('dashboard.data_volume.title')}</Title>
						</div>
						<div className="tr-mt-6 tr-grid" style={{ alignContent: 'space-between' }}>
							{[
								showContacts && (dataVolumeValues.contactsCount !== 0 || dataVolumeValues.contactsRgpdCount !== 0) && (
									<Flex alignItems="items-start">
										<Block>
											<Text>{t('dashboard.data_volume.contacts')}</Text>
											<Metric>{dataVolumeValues.contactsCount}</Metric>
										</Block>
										<Block>
											<Text>{t('dashboard.data_volume.contacts_rgpd')}</Text>
											<Metric>{dataVolumeValues.contactsRgpdCount}</Metric>
										</Block>
									</Flex>
								),
								showPartners && (dataVolumeValues.partnersCount !== 0 || dataVolumeValues.sectorsCount !== 0) && (
									<Flex alignItems="items-start" marginTop="mt-3">
										<Block>
											<Text>{t('dashboard.data_volume.partners')}</Text>
											<Metric>{dataVolumeValues.partnersCount}</Metric>
										</Block>
										<Block>
											<Text>{t('dashboard.data_volume.sectors')}</Text>
											<Metric>{dataVolumeValues.sectorsCount}</Metric>
										</Block>
									</Flex>
								),
								showProjects && (dataVolumeValues.projectsCount !== 0 || dataVolumeValues.offersCount !== 0) && (
									<Flex alignItems="items-start" marginTop="mt-3">
										<Block>
											<Text>{t('dashboard.data_volume.projects')}</Text>
											<Metric>{dataVolumeValues.projectsCount}</Metric>
										</Block>
										<Block>
											<Text>{t('dashboard.data_volume.offers')}</Text>
											<Metric>{dataVolumeValues.offersCount}</Metric>
										</Block>
									</Flex>
								),
								showHistory && (dataVolumeValues.crmCount12Months !== 0 || dataVolumeValues.crmCountOneMonth !== 0) && (
									<Flex alignItems="items-start" marginTop="mt-3">
										<Block>
											<Text>{t('dashboard.data_volume.history_12_months')}</Text>
											<Metric>{dataVolumeValues.crmCount12Months}</Metric>
										</Block>
										<Block>
											<Text>{t('dashboard.data_volume.history_one_month')}</Text>
											<Metric>{dataVolumeValues.crmCountOneMonth}</Metric>
										</Block>
									</Flex>
								),
							].filter(Boolean).map((row, index) => (
								// Safe to disable here because this list will NEVER be reordered while staying on this page
								// eslint-disable-next-line react/no-array-index-key
								<Fragment key={index}>
									{index > 0 && <Divider />}
									{row}
								</Fragment>
							))}
						</div>
					</Card>
				)}
			</ColGrid>
		</div>
	);
};

export default HomePage;
