import { useEffect, useRef } from 'react';
import _ from 'lodash';

/**
 * @name useForwardedRef
 * @description A custom hook ensuring the provided forwarded ref is a valid ref object
 *
 * @author Yann Hodiesne
 *
 * @param {object|func} ref	The forwarded ref given by React.forwardRef
 *
 * @returns {object} A valid ref object
 */
const useForwardedRef = (ref) => {
	const innerRef = useRef();

	// This useEffect must run at every render, as such it does not have a dependency array
	useEffect(() => {
		if (!ref) {
			return;
		}

		if (_.isFunction(ref)) {
			ref(innerRef.current);
		} else {
			// eslint-disable-next-line no-param-reassign
			ref.current = innerRef.current;
		}
	});

	return innerRef;
};

export default useForwardedRef;
