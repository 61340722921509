import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentStatusSelector
 * @description A selector to retrieve the current status from the status redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the current status to wait for
 *
 * @returns {object | null}
 */
const useCurrentStatusSelector = (id) => {
	const status = useSelector((state) => (state.statuses?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (status?.id === id ? status : null), [id, status]);

	return result;
};

/**
 * @function
 * @name useStatusListSelector
 * @description A selector to retrieve the status list from the status redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useStatusListSelector = () => {
	const statuses = useSelector((state) => (state.statuses?.items ?? []), shallowEqual);

	return statuses;
};

/**
 * @function
 * @name useStatusActiveListSelector
 * @description A selector to retrieve all non-archived statuses from the status redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useStatusActiveListSelector = () => {
	const statuses = useStatusListSelector();

	const activeStatuses = useMemo(() => statuses.filter((status) => !status.archived), [statuses]);

	return activeStatuses;
};

/**
 * @function
 * @name useStatusTotalCountSelector
 * @description A selector to retrieve the totalCount value from the status redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useStatusTotalCountSelector = () => useSelector((state) => (state.statuses?.totalCount ?? undefined), shallowEqual);

/**
 * @function
 * @name useStatusLoadingSelector
 * @description A selector to retrieve the loading state from the status redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useStatusLoadingSelector = () => useSelector((state) => state.statuses?.isLoading ?? false);

export {
	useCurrentStatusSelector,
	useStatusActiveListSelector,
	useStatusListSelector,
	useStatusLoadingSelector,
	useStatusTotalCountSelector,
};
