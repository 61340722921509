import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/**
 * @name ElementTypeDragPreview
 * @description The preview component displayed when an element type is being dragged around
 *
 * @author Yann Hodiesne
 *
 * @param {object} elementType	The element type to display, from the constants inside {@link constants/ElementTypes}
 */
const ElementTypeDragPreview = ({ elementType }) => {
	const { t } = useTranslation();

	return (
		<div className="element-type drag-preview">
			<div className="handle" />
			<div className="icon">
				<elementType.meta.icon size={24} />
			</div>
			<p className="title">
				{t(elementType.meta.name)}
			</p>
			<p className="description">
				{t(elementType.meta.description)}
			</p>
		</div>
	);
};

ElementTypeDragPreview.propTypes = {
	elementType: PropTypes.shape({
		meta: PropTypes.shape({
			name: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
};

export default memo(ElementTypeDragPreview);
