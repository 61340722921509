import * as UserPreferencesApi from 'api/userPreferencesApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the user preferences redux state.
 * @type {object}
 *
 * @author Yann Hodiesne
 */
export const ActionTypes = {
	FETCH_USER_PREFERENCES_REQUEST: '@USER_PREFERENCES/FETCH_USER_PREFERENCES_REQUEST',
	FETCH_USER_PREFERENCES_SUCCESS: '@USER_PREFERENCES/FETCH_USER_PREFERENCES_SUCCESS',
	FETCH_USER_PREFERENCES_FAILURE: '@USER_PREFERENCES/FETCH_USER_PREFERENCES_FAILURE',

	UPDATE_USER_PREFERENCE_REQUEST: '@USER_PREFERENCES/UPDATE_USER_PREFERENCE_REQUEST',
	UPDATE_USER_PREFERENCE_SUCCESS: '@USER_PREFERENCES/UPDATE_USER_PREFERENCE_SUCCESS',
	UPDATE_USER_PREFERENCE_FAILURE: '@USER_PREFERENCES/UPDATE_USER_PREFERENCE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// /////////// User preferences fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchUserPreferencesRequest
 * @description Action triggered anytime a user preferences fetching call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchUserPreferencesRequest = () => ({ type: ActionTypes.FETCH_USER_PREFERENCES_REQUEST });

/**
 * @function
 * @name fetchUserPreferencesSuccess
 * @description Action triggered as a result to a successful user preferences fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchUserPreferencesSuccess = ({ userPreferences }) => ({
	type: ActionTypes.FETCH_USER_PREFERENCES_SUCCESS,
	payload: { userPreferences },
});

/**
 * @function
 * @name fetchUserPreferencesFailure
 * @description Action triggered as a result to a failed user preferences fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchUserPreferencesFailure = (error) => ({
	type: ActionTypes.FETCH_USER_PREFERENCES_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////// User preferences update actions /////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateUserPreferencesRequest
 * @description Action triggered anytime a user preference update call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const updateUserPreferenceRequest = () => ({ type: ActionTypes.UPDATE_USER_PREFERENCE_REQUEST });

/**
 * @function
 * @name updateUserPreferencesSuccess
 * @description Action triggered as a result to a successful user preference update API call.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const updateUserPreferenceSuccess = ({ userPreference }) => ({
	type: ActionTypes.UPDATE_USER_PREFERENCE_SUCCESS,
	payload: { userPreference },
});

/**
 * @function
 * @name updateUserPreferencesFailure
 * @description Action triggered as a result to a failed user preference update API call.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const updateUserPreferenceFailure = (error) => ({
	type: ActionTypes.UPDATE_USER_PREFERENCE_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchUserPreferences
 * @description Method used to retrieve the user preferences from the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
export const fetchUserPreferences = () => (dispatch) => {
	dispatch(fetchUserPreferencesRequest());

	return UserPreferencesApi.fetchUserPreferences()
		.then(({ userPreferences }) => dispatch(fetchUserPreferencesSuccess({ userPreferences })))
		.catch((error) => dispatch(fetchUserPreferencesFailure(error)));
};

/**
 * @function
 * @name updateUserPreference
 * @description Method used to update an existing user preference instance from the database.
 *
 * @author Yann Hodiesne
 *
 * @param {string}	name	The name of the user preference to update.
 * @param {array}	columns	The content of the user preference to update.
 */
export const updateUserPreference = (name, columns) => (dispatch) => {
	dispatch(updateUserPreferenceRequest());

	return UserPreferencesApi.updateUserPreference(name, columns)
		.then(({ userPreference }) => dispatch(updateUserPreferenceSuccess({ userPreference })))
		.catch((error) => dispatch(updateUserPreferenceFailure(error)));
};
