import { useCallback } from 'react';
import { AlignCenter, AlignJustify, AlignLeft, AlignRight, Bold, Italic, Minus, Underline } from 'react-feather';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Button } from 'components/shared/buttons';
import { ColorPicker, NumberInput } from 'components/shared/inputs';
import SquareColorPreviewComponent from 'components/shared/previewComponents/SquareColorPreviewComponent';

/**
 * @name FormatCapability
 * @description A date capability, displayed inside the editor's toolbox
 *
 * @author Florian Fornazaric
 *
 * @param {string}		[value]			The current value of the capability (current date by default)
 * @param {function}	setValue		A callback updating the value associated to this capability
 * @param {boolean}		readOnly		Whether the capability can be edited by the user
 * @param {string}		fontSizeOnly	Whether it's only fontSize or not
*/
const FormatCapability = ({ value, setValue, fontSizeOnly }) => {
	const inputId = uuid(); // used to tie the label to the input.
	const { t } = useTranslation();

	const formatClickHandler = useCallback((e) => {
		e.stopPropagation();

		switch (e.currentTarget.id) {
			case 'ITALIC':
				setValue({ ...value, italic: !value.italic });
				break;
			case 'BOLD':
				setValue({ ...value, bold: !value.bold });
				break;
			case 'UNDERLINE':
				setValue({ ...value, underline: !value.underline });
				break;
			case 'LINETHROUGH':
				setValue({ ...value, lineThrough: !value.lineThrough });
				break;
			default:
				break;
		}
	}, [setValue, value]);

	const handleFontColorChange = useCallback((color) => {
		setValue({ ...value, fontColor: color });
	}, [setValue, value]);

	const handleFontSizeChange = useCallback((e) => {
		setValue({ ...value, fontSize: e.target.value });
	}, [setValue, value]);

	const alignmentButtonClickHandler = useCallback((e) => {
		e.stopPropagation();

		switch (e.currentTarget.id) {
			case 'START':
				setValue({ ...value, alignment: 'start' });
				break;
			case 'CENTER':
				setValue({ ...value, alignment: 'center' });
				break;
			case 'END':
				setValue({ ...value, alignment: 'end' });
				break;
			case 'JUSTIFY':
				setValue({ ...value, alignment: 'justify' });
				break;
			default:
				break;
		}
	}, [setValue, value]);

	return (
		<>
			<label htmlFor={inputId}>
				{t('template.pdf.capabilities.style.label')}
			</label>
			<div id={inputId} className="font-options-container">
				<NumberInput
					className="font-size-input"
					min={0}
					max={124}
					value={value.fontSize}
					onChange={handleFontSizeChange}
					decimalScale={1}
				/>
				{!fontSizeOnly
					? (
						<div className="format-options">
							<ColorPicker
								defaultValue="#000"
								value={`${value.fontColor ? value.fontColor : '#000'}`}
								type="chrome"
								onChange={handleFontColorChange}
								previewComponent={SquareColorPreviewComponent}
							/>
						</div>
					) : <div />}
				<div className="input-wrapper">
					<select
						disabled
						aria-readonly
						className="font-family-select"
					>
						<option>IBM Plex Sans</option>
					</select>
				</div>
			</div>
			<div className="format-options-container">

				{!fontSizeOnly
					? (
						<>
							<div className="format-options">
								<div className="format-buttons-container">
									<Button
										type="button"
										id="ITALIC"
										onClick={formatClickHandler}
										className={`format-button ${value.italic ? ' enabled' : ''}`}
									>
										<Italic />
									</Button>
									<Button
										type="button"
										id="BOLD"
										onClick={formatClickHandler}
										className={`format-button ${value.bold ? ' enabled' : ''}`}
									>
										<Bold strokeWidth={3} />
									</Button>
									<Button
										type="button"
										id="UNDERLINE"
										onClick={formatClickHandler}
										className={`format-button ${value.underline ? ' enabled' : ''}`}
									>
										<Underline />
									</Button>
									<Button
										type="button"
										id="LINETHROUGH"
										onClick={formatClickHandler}
										className={`format-button ${value.lineThrough ? ' enabled' : ''}`}
									>
										<Minus />
									</Button>
								</div>
							</div>
							<div className="format-options">
								<div className="format-buttons-container">
									<Button
										type="button"
										id="START"
										onClick={alignmentButtonClickHandler}
										className={`format-button ${value.alignment === 'start' ? ' enabled' : ''}`}
									>
										<AlignLeft />
									</Button>
									<Button
										type="button"
										id="CENTER"
										onClick={alignmentButtonClickHandler}
										className={`format-button ${value.alignment === 'center' ? ' enabled' : ''}`}
									>
										<AlignCenter />
									</Button>
									<Button
										type="button"
										id="END"
										onClick={alignmentButtonClickHandler}
										className={`format-button ${value.alignment === 'end' ? ' enabled' : ''}`}
									>
										<AlignRight />
									</Button>
									<Button
										type="button"
										id="JUSTIFY"
										onClick={alignmentButtonClickHandler}
										className={`format-button ${value.alignment === 'justify' ? ' enabled' : ''}`}
									>
										<AlignJustify />
									</Button>
								</div>
							</div>

						</>
					) : null}
			</div>
		</>
	);
};

FormatCapability.propTypes = {
	setValue: PropTypes.func.isRequired,
	value: PropTypes.object,
	fontSizeOnly: PropTypes.bool,
};

FormatCapability.defaultProps = {
	value: {},
	fontSizeOnly: false,
};

export default FormatCapability;
