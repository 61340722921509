import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchProjects
 * @description Retrieves a list of all projects from the API, specifying filters if present.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} params  The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchProjects = (params) => post('/api/projects', params);

/**
 * @function
 * @name fetchProjectById
 * @description Retrieves an existing project from the API, using the projectId in parameters.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {string} projectId  The id of the project we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchProjectById = (projectId) => get(`/api/project/${projectId}`);

/**
 * @function
 * @name createProject
 * @description Creates a new project into the database.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} projectData  The data to create the project from.
 *
 * @returns {Promise}
 */
export const createProject = (projectData) => post('/api/projects/create', projectData);

/**
 * @function
 * @name updateProject
 * @description Updates an existing project from the database.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {object} projectData   The data to update the project with.
 * @param {string} projectId     The id of the project we wish to update.
 *
 * @returns {Promise}
 */
export const updateProject = (projectData, projectId) => put(`/api/project/${projectId}`, projectData);

/**
 * @function
 * @name removeProject
 * @description Removes an existing project from the database.
 *
 * @author Marin Catel-Guihomat
 *
 * @param {string} projectId  The id of the project we want to remove.
 *
 * @returns {Promise}
 */
export const deleteProject = (projectId) => del(`/api/project/${projectId}`);

/**
 * @function
 * @name fetchAllForProjectForm
 * @description Retrieves all information for project's form from the API.
 * - contacts [{ id, firstName, lastName }]
 * - enabledUsers [{ id, username, contact: { id, firstName, lastName } }]
 * - numberFormat [{ startingNumber, digitNumber, yearPrefix, monthPrefix, rootName, environment }]
 * - partners [{ id, name }]
 * - [expenses]
 * - [invoices]
 * - [quotations]
 *
 * @author Romaric Barthe.
 *
 * @param {object} params           The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchAllForProjectForm = (params) => post('/api/project/form', params);
