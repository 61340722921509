import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccessRights } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';

import { Checkbox } from 'components/shared/forms/inputs';

import 	SubModule from './SubModule';

/**
 * @name Module
 * @description A form used to edit access rights.
 *
 * @author Romaric Barthe
 *
 * @param {object}	defaultValues		The loaded access rights on initialisation.
 * @param {string}	module				The current module.
 * @param {object}	userAccessRights	The allocated rights (from the back).
 */
const Module = ({
	defaultValues,
	module,
	userAccessRights,
}) => {
	const { t } = useTranslation();

	const moduleAccess = useMemo(() => AccessRights[module].VIEW, [module]);
	const subModules = useMemo(() => Object.keys(AccessRights[module]).filter((nv) => nv !== 'VIEW'), [module]);

	const moduleRef = useRef();

	const [isModuleChecked, setIsModuleChecked] = useState(false);

	/**
	 * @function
	 * @name onChangeModule
	 * @description A callback method used to switch between administrator and standard.
	 *
	 * @author Romaric Barthe
	 */
	const onChangeModule = useCallback((checked) => {
		setIsModuleChecked(checked);
	}, []);

	useEffect(() => {
		moduleRef.current.setValue(isModuleChecked);
	}, [isModuleChecked]);

	useEffect(() => {
		if (defaultValues && defaultValues[moduleAccess]) {
			moduleRef.current.setValue(true);
		}
	}, [defaultValues, moduleAccess]);

	return (
		<>
			{moduleAccess && ( // For security in case AccessRights are not properly build
				<div className="module">
					<Checkbox
						ref={moduleRef}
						label={t(`access_definition.creation.inputs.${moduleAccess}.label`)}
						name={moduleAccess}
						labelFirst={false}
						onChange={onChangeModule}
					/>
				</div>
			)}
			{isModuleChecked && subModules.map((subModule) => (
				<SubModule
					key={subModule}
					defaultValues={defaultValues}
					module={module}
					subModule={subModule}
					userAccessRights={userAccessRights}
				/>
			))}
		</>
	);
};

Module.propTypes = {
	defaultValues: PropTypes.object,
	module: PropTypes.string.isRequired,
	userAccessRights: PropTypes.object,
};

Module.defaultProps = {
	defaultValues: undefined,
	userAccessRights: undefined,
};

export default Module;
