import { useTranslation } from 'react-i18next';

import { TextArea, TextInput } from 'components/shared/forms/inputs';

/**
 * @name Address
 * @description A form used to group rhe address fields.
 *
 * @author Romaric Barthe
 */
const Address = () => {
	const { t } = useTranslation();

	return (
		<>
			<TextArea
				label={t('projects.edition.inputs.address.label')}
				name="address"
				placeholder={t('projects.edition.inputs.address.placeholder')}
				type="text"
			/>
			<span className="table">
				<span className="inputcell">
					<TextInput
						label={t('projects.edition.inputs.postCode.label')}
						name="postCode"
						placeholder={t('projects.edition.inputs.postCode.placeholder')}
						type="text"
					/>
				</span>
				<span className="inputcell">
					<TextInput
						label={t('projects.edition.inputs.city.label')}
						name="city"
						placeholder={t('projects.edition.inputs.city.placeholder')}
						type="text"
					/>
				</span>
				<span className="inputcell">
					<TextInput
						label={t('projects.edition.inputs.country.label')}
						name="country"
						placeholder={t('projects.edition.inputs.country.placeholder')}
						type="text"
					/>
				</span>
			</span>
		</>
	);
};

export default Address;
