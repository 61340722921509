import { ActionTypes } from '../../actions/projects/projects';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_PROJECT_LIST_REQUEST:
		case ActionTypes.FETCH_ALL_FOR_PROJECT_REQUEST:
		case ActionTypes.ADD_PROJECT_REQUEST:
		case ActionTypes.UPDATE_PROJECT_REQUEST:
		case ActionTypes.REMOVE_PROJECT_REQUEST:
		case ActionTypes.ARCHIVE_PROJECT_REQUEST:
		case ActionTypes.RESTORE_PROJECT_REQUEST:
		case ActionTypes.DEACTIVATE_PROJECT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_PROJECT_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_PROJECT_LIST_FAILURE:
		case ActionTypes.FETCH_ALL_FOR_PROJECT_FAILURE:
		case ActionTypes.FETCH_PROJECT_FAILURE:
		case ActionTypes.ADD_PROJECT_FAILURE:
		case ActionTypes.UPDATE_PROJECT_FAILURE:
		case ActionTypes.REMOVE_PROJECT_FAILURE:
		case ActionTypes.ARCHIVE_PROJECT_FAILURE:
		case ActionTypes.RESTORE_PROJECT_FAILURE:
		case ActionTypes.DEACTIVATE_PROJECT_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_PROJECT_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.projects],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_PROJECT_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.project,
			};
		case ActionTypes.ADD_PROJECT_SUCCESS:
		case ActionTypes.UPDATE_PROJECT_SUCCESS:
		case ActionTypes.REMOVE_PROJECT_SUCCESS:
		case ActionTypes.ARCHIVE_PROJECT_SUCCESS:
		case ActionTypes.RESTORE_PROJECT_SUCCESS:
		case ActionTypes.DEACTIVATE_PROJECT_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_ALL_FOR_PROJECT_SUCCESS:
			return {
				...state,
				isLoading: false,
				allForForm: payload.allForForm,
			};
		default:
			return state;
	}
};
