import { forwardRef, useCallback, useId, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

import { ColorPicker as ColorPickerInput } from 'components/shared/inputs';

import useDynamicFormInput from '../useDynamicFormInput';

import { Error, Label } from './DynamicFormInput';

/**
 * @name ColorPicker
 * @description A color picker component to be used inside a dynamic form.
 * @extends FormControlWrapper
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {string}	name				The input's name.
 * @param {string}	label				The label for the input.
 * @param {string}	[type=circle]		The color picker's type (circle or chrome).
 * @param {func}  	[previewComponent]  The component to use as a preview for the color.
 * @param {object}	[rules={}]			The validation rules to apply to this input.
 * @param {bool}	[allowNull=false]	Whether the component should be allowed to be empty.
 */
const ColorPicker = forwardRef(({ name, label, type, previewComponent, rules, allowNull, className, ...props }, ref) => {
	const resolvedRef = useForwardedRef(ref);
	const { t } = useTranslation();

	const currentColorValue = useRef();

	const getValue = useCallback(() => currentColorValue.current, []);

	const {
		defaultValue,
		isDisabled,
		isInvalid,
		isOptional,
		isReadOnly,
		validationError,
		enableValidationOnChange,
		onChangeHandler,
	} = useDynamicFormInput(name, getValue, rules, props);

	const [value, setValue] = useState(() => defaultValue ?? (allowNull ? null : '#E5E8EA'));

	currentColorValue.current = value;

	const onChange = useCallback((color) => {
		setValue(color);
		currentColorValue.current = color;

		enableValidationOnChange();
		onChangeHandler();
	}, [enableValidationOnChange, onChangeHandler]);

	useImperativeHandle(resolvedRef, () => ({
		setValue: (newValue) => onChange(newValue),
	}));

	const id = useId();

	return (
		<div className={`input-wrapper${isDisabled ? ' disabled' : ''}${type === 'hidden' ? ' hidden' : ''}${className ?? ''}`}>
			<Label disabled={isDisabled} inputId={id} isInvalid={isInvalid}>
				{label}
				{isOptional && ` (${t('form.optional')})`}
			</Label>
			<ColorPickerInput
				id={id}
				disabled={isDisabled}
				readOnly={isReadOnly}
				value={value}
				type={type}
				onChange={onChange}
				previewComponent={previewComponent}
			/>
			{isInvalid && <Error>{validationError ?? ''}</Error>}
		</div>
	);
});

ColorPicker.propTypes = {
	allowNull: PropTypes.bool,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	rules: PropTypes.object,
	type: PropTypes.oneOf(['circle', 'chrome']),
	previewComponent: PropTypes.object,
	className: PropTypes.string,
};

ColorPicker.defaultProps = {
	allowNull: false,
	rules: {},
	type: 'circle',
	previewComponent: undefined,
	className: undefined,
};

ColorPicker.displayName = 'ColorPicker';

export default ColorPicker;
