import { forwardRef, memo, useCallback } from 'react';
import { useForwardedRef } from 'lib/hooks';
import PropTypes from 'prop-types';

/**
 * @name ToggleSwitch
 * @description A toggle switch component.
 *
 * @author Timothée Simon-Franza
 *
 * Note: this component is NOT auto-wired to air-react-forms.
 *
 * @param {string}	id			 The id to give the input.
 * @param {bool}	[checked]	 Whether the input should be checked or not.
 * @param {bool}	[disabled]	 Whether the input should be disabled.
 * @param {string}	name		 The name to give the input.
 * @param {func}	[onChange]	 Method to trigger on checkbox change.
 */
const ToggleSwitch = forwardRef(({ id, checked, disabled, name, onChange }, ref) => {
	const resolvedRef = useForwardedRef(ref);

	const handleChange = useCallback(() => {
		onChange(resolvedRef.current.checked);
	}, [resolvedRef, onChange]);

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
		<label htmlFor={id} className="toggle-switch" tabIndex={0} aria-label="toggle switch">
			<input
				ref={resolvedRef}
				type="checkbox"
				id={id}
				checked={checked}
				onChange={handleChange}
				name={name}
				disabled={disabled}
			/>
			<span />
		</label>
	);
});

ToggleSwitch.displayName = 'ToggleSwitch';

ToggleSwitch.propTypes = {
	id: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
};

ToggleSwitch.defaultProps = {
	checked: false,
	disabled: false,
	onChange: () => {},
};

export default memo(ToggleSwitch);
