import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useContactsListLoadingSelector
 * @description A selector to retrieve the loading state from the contactsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useContactsListLoadingSelector = () => {
	const loading = useSelector((state) => state.contactsList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useContactsForListSelector
 * @description A selector to retrieve the contactsList list from the contactsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useContactsForListSelector = () => {
	const contacts = useSelector((state) => (state.contactsList?.items ?? []), shallowEqual);

	return contacts;
};

/**
 * @function
 * @name useContactsListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the contactsList redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useContactsListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.contactsList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useContactsForListSelector,
	useContactsListLoadingSelector,
	useContactsListTotalCountSelector,
};
