import { useCallback, useEffect, useMemo } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { formatProjectDescription, formatProjectName } from 'lib/targetings/formatTargetingData';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { fetchTargetingsForList } from 'redux/actions/targetings/targetingsList';
import { useTargetingsForListSelector, useTargetingsListTotalCountSelector } from 'redux/selectors/targetings/targetingsList';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';
import ActionsCell from 'components/targetings/targetingList/ActionsCell';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name TargetingListPage
 * @description A list of all targetings accessible from the targeting app.
 *
 * From this page, the user is able to view, interact and search for targetings.
 *
 * @author Romaric Barthe
 *
 */
const TargetingListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const targetings = useTargetingsForListSelector();
	const totalCount = useTargetingsListTotalCountSelector();

	const canCreateTargeting = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING);

	const headers = useMemo(() => [
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('targeting.headers.actions') },
		{ id: 'project', accessor: (row) => formatProjectName(row), Header: t('targeting.fields.project'), pinColumn: true, sorting: 'project.name' },
		{ id: 'description', accessor: (row) => formatProjectDescription(row), Header: t('targeting.fields.project_description'), sorting: 'project.description' },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('targeting.fields.archived.yes') : t('targeting.fields.archived.no')),
			Header: t('targeting.fields.archived.header'),
			sorting: 'targeting.archived',
		},
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('targeting.fields.creation_date'), sorting: 'targeting.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('targeting.fields.update_date'), sorting: 'targeting.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('targeting.fields.creation_name'), sorting: 'targeting.createdBy' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('targeting.fields.update_name'), sorting: 'targeting.updatedBy' },
	], [t]);

	useEffect(() => {
		dispatch(fetchNumberFormatList());
	}, [dispatch]);

	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchTargetingsForList(params)));

	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('targeting');

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.operations.targetings.targetingCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateTargeting && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('targeting.links.create')}
						</Tooltip>
					</>
				)}
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={targetings}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="targeting"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('targeting.pages.list')} {...modalProps} />
		</div>
	);
};

export default TargetingListPage;
