import { useCallback, useEffect, useRef } from 'react';

/**
 * @name useOnClickOutside
 * @description A custom hook calling the provided callback whenever the user clicks outside of the referenced element
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {func}	callback	The function to call whenever the event has been triggered, should be stored inside a useCallback
 * @param {object}	[ref]		A ref to the component on which we should apply the event, if not provided, a ref will be returned and must be used
 */
export default (callback, ref) => {
	const defaultRef = useRef();
	const resolvedRef = ref || defaultRef;

	/**
	 * Alert if clicked on outside of element
	 */
	const handleClickOutside = useCallback((event) => {
		if (resolvedRef.current && !resolvedRef.current.contains(event.target)) {
			callback();
		}
	}, [callback, resolvedRef]);

	useEffect(() => {
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClickOutside]);

	return resolvedRef;
};
