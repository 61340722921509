import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentVatRateSelector
 * @description A selector to retrieve the current vat rate from the vatRate redux state
 *
 * @author Yann Hodiesne
 *
 * @param {string} id The identifier of the current vat rate to wait for
 *
 * @returns {object}
 */
const useCurrentVatRateSelector = (id) => {
	const vatRate = useSelector((state) => (state.vatRates?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (vatRate?.id === id ? vatRate : null), [id, vatRate]);

	return result;
};

/**
 * @function
 * @name useVatRatesLoadingSelector
 * @description A selector to retrieve the loading state from the vatRate redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {boolean}
 */
const useVatRatesLoadingSelector = () => {
	const loading = useSelector((state) => state.vatRates?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useVatRateListSelector
 * @description A selector to retrieve the vatRate list from the vatRate redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {Array}
 */
const useVatRateListSelector = () => {
	const vatRates = useSelector((state) => (state.vatRates?.items ?? []), shallowEqual);

	return vatRates;
};

/**
 * @function
 * @name useVatRateTotalCountSelector
 * @description A selector to retrieve the totalCount value from the vatRate redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {number|undefined}
 */
const useVatRateTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.vatRates?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useVatRateActiveListSelector
 * @description A selector to retrieve all non-archived vatRates from the vatRate redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {Array}
 */
const useVatRateActiveListSelector = () => {
	const vatRates = useVatRateListSelector();

	const result = useMemo(() => vatRates.filter(({ archived }) => !archived), [vatRates]);

	return result;
};

/**
 * @function
 * @name useFavoriteVatRateSelector
 * @description A selector to retrieve the favorite varRate from the vatRate redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useFavoriteVatRateSelector = () => {
	const vatRates = useVatRateListSelector();

	const result = useMemo(() => vatRates.filter(({ favorite }) => favorite), [vatRates]);

	return result.length > 0 ? result[0] : undefined;
};

export {
	useCurrentVatRateSelector,
	useFavoriteVatRateSelector,
	useVatRateActiveListSelector,
	useVatRateListSelector,
	useVatRatesLoadingSelector,
	useVatRateTotalCountSelector,
};
