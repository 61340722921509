import { del, get, post, put } from 'lib/shared/http';

/**
 * @function
 * @name fetchHistories
 * @description Retrieves a list of histories from the API, specifying filters if present.
 *
 * @author Audrey Clerc
 *
 * @param {object} params The filter criterias, as given by the Table component.
 *
 * @returns {Promise}
 */
export const fetchHistories = (params) => post('/api/histories', params);

/**
 * @function
 * @name fetchHistoryById
 * @description Retrieves an existing history from the API, using the historyId in parameters.
 *
 * @author Audrey Clerc
 *
 * @param {string} historyId The id of the history we want to retrieve.
 *
 * @returns {Promise}
 */
export const fetchHistoryById = (historyId) => get(`/api/history/${historyId}`);

/**
 * @function
 * @name createHistory
 * @description Creates a new history into the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} historyData The data to create the history from.
 *
 * @returns {Promise}
 */
export const createHistory = (historyData) => post('/api/history/create', historyData);

/**
 * @function
 * @name updateHistory
 * @description Updates an existing history from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} historyData  The data to update the history with.
 * @param {string} historyId    The id to identify the history with.
 *
 * @returns {Promise}
 */
export const updateHistory = (historyData, historyId) => put(`/api/history/${historyId}`, historyData);

/**
 * @function
 * @name deleteHistory
 * @description Removes an existing history from the database.
 *
 * @author Audrey Clerc
 *
 * @param {string} historyId The id of the history we want to remove.
 *
 * @returns {Promise}
 */
export const deleteHistory = (historyId) => del(`/api/history/${historyId}`);
