import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useExpensesSelectLoadingSelector
 * @description A selector to retrieve the loading state from the expensesSelect redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useExpensesSelectLoadingSelector = () => {
	const loading = useSelector((state) => state.expensesSelect?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useExpensesSelectListSelector
 * @description A selector to retrieve the expensesSelect list from the expense redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useExpensesSelectListSelector = () => {
	const expensesSelect = useSelector((state) => (state.expensesSelect?.items ?? []), shallowEqual);

	return expensesSelect;
};

/**
 * @function
 * @name useExpensesSelectTotalCountSelector
 * @description A selector to retrieve the totalCount value from the expensesSelect redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useExpensesSelectTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.expensesSelect?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useExpensesSelectListSelector,
	useExpensesSelectLoadingSelector,
	useExpensesSelectTotalCountSelector,
};
