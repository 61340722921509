import PropTypes from 'prop-types';
import {
	bodyCopy,
	brevier,
	canon,
	doublePica,
	greatPrimer,
	longPrimer,
	pica,
} from 'theme/textStyles';

import DynamicTextComponent from './DynamicTextComponent';

/**
 * @name Canon
 * @description Text component to be used for hero or page title, for example.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*} props
 */
const Canon = ({ children, ...props }) => (
	<DynamicTextComponent {...canon} {...props}>
		{children}
	</DynamicTextComponent>
);

/**
 * @name DoublePica
 * @description Text component to be used as sub header, for example.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*} props
 */
const DoublePica = ({ children, ...props }) => (
	<DynamicTextComponent {...doublePica} {...props}>
		{children}
	</DynamicTextComponent>
);

/**
 * @name GreatPrimer
 * @description Text component to be used as headline title or subtitle, for example.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*} props
 */
const GreatPrimer = ({ children, ...props }) => (
	<DynamicTextComponent {...greatPrimer} {...props}>
		{children}
	</DynamicTextComponent>
);

/**
 * @name BodyCopy
 * @description Text component to be used as body copy.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*} props
 */
const BodyCopy = ({ children, ...props }) => (
	<DynamicTextComponent {...bodyCopy} {...props}>
		{children}
	</DynamicTextComponent>
);

/**
 * @name Pica
 * @description Text component to be used as links, titles or headlines, for example.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*} props
 */
const Pica = ({ children, ...props }) => (
	<DynamicTextComponent {...pica} {...props}>
		{children}
	</DynamicTextComponent>
);

/**
 * @name LongPrimer
 * @description Text component to be used as secondary body copy, image captions or inline links, for example.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*} props
 */
const LongPrimer = ({ children, ...props }) => (
	<DynamicTextComponent {...longPrimer} {...props}>
		{children}
	</DynamicTextComponent>
);

/**
 * @name Brevier
 * @description Text component to be used as timestamps, bylines, input labels and helper text, for example.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*} props
 */
const Brevier = ({ children, ...props }) => (
	<DynamicTextComponent {...brevier} {...props}>
		{children}
	</DynamicTextComponent>
);

Canon.propTypes = { children: PropTypes.node };
Canon.defaultProps = { children: undefined };

GreatPrimer.propTypes = { children: PropTypes.node.isRequired };

DoublePica.propTypes = { children: PropTypes.node.isRequired };

BodyCopy.propTypes = { children: PropTypes.node.isRequired };

Pica.propTypes = { children: PropTypes.node.isRequired };

LongPrimer.propTypes = { children: PropTypes.node.isRequired };

Brevier.propTypes = { children: PropTypes.node.isRequired };

export {
	BodyCopy,
	Brevier,
	Canon,
	DoublePica,
	GreatPrimer,
	LongPrimer,
	Pica,
};
