import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { addAccount, fetchAccountList } from 'redux/actions/accounts';
import { useAccountActiveListSelector, useAccountsLoadingSelector } from 'redux/selectors/accounts';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useFormModal, useSubmitButton } from 'components/shared/forms';
import { Select, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import AccountCreationForm from './AccountCreationForm';

/**
 * @name AccountEditionForm
 * @description A form used to update an existing account.
 *
 * @author Romaric Barthe
 *
 * @param {object}		account		The account object to edit information from.
 * @param {function}	onSubmit	The method to trigger upon form submission.
 */
const AccountEditionForm = ({ account, onSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchAccountList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const parents = useAccountActiveListSelector() || [];
	const areParentsLoading = useAccountsLoadingSelector();

	const canEditAccount = useAccessRight(AccessRights.accounting.accounts.enhancedRights.CREATE_ACCOUNT) && !account?.archived;

	const { formProps, buttonProps } = useSubmitButton();
	// Props to pass down to the select input so that it will display an account creation modal when clicking on the "+" icon.
	const accountCreationModal = useFormModal(AccountCreationForm, t('pages.accounting.accounts.creation'), fetchAccountList, addAccount);

	const defaultValues = useMemo(() => ({
		...account,
		parent: account.parent?.id ?? undefined,
	}), [account]);

	return (
		<DynamicForm
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			disabled={!canEditAccount}
			{...formProps}
		>
			<TextInput
				label={t('account.edition.inputs.name.label')}
				name="name"
				placeholder={t('account.edition.inputs.name.placeholder')}
				rules={{
					required: Validators.isRequired(t('account.edition.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextArea
				label={t('account.edition.inputs.description.label')}
				name="description"
				placeholder={t('account.edition.inputs.description.placeholder')}
			/>
			<Select
				allowNull
				label={t('account.edition.inputs.parent.label')}
				labelKey="name"
				name="parent"
				options={parents}
				valueKey="id"
				isLoading={areParentsLoading}
				{...accountCreationModal}
			/>
			<Button className="primary" type="submit" {...buttonProps}>
				{canEditAccount ? t('account.edition.action') : t('account.edition.close')}
			</Button>
		</DynamicForm>
	);
};

AccountEditionForm.propTypes = {
	account: PropTypes.shape({
		archived: PropTypes.bool,
		description: PropTypes.string,
		name: PropTypes.string.isRequired,
		parent: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
	}).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default AccountEditionForm;
