import { toast } from 'react-toastify';
import * as TextObjectsApi from 'api/textObjectsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the text objects redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific text object
	FETCH_TEXT_OBJECT_REQUEST: '@TEXT_OBJECTS/FETCH_REQUEST',
	FETCH_TEXT_OBJECT_SUCCESS: '@TEXT_OBJECTS/FETCH_SUCCESS',
	FETCH_TEXT_OBJECT_FAILURE: '@TEXT_OBJECTS/FETCH_FAILURE',

	// Fetch a list of text objects
	FETCH_TEXT_OBJECT_LIST_REQUEST: '@TEXT_OBJECTS/FETCH_LIST_REQUEST',
	FETCH_TEXT_OBJECT_LIST_SUCCESS: '@TEXT_OBJECTS/FETCH_LIST_SUCCESS',
	FETCH_TEXT_OBJECT_LIST_FAILURE: '@TEXT_OBJECTS/FETCH_LIST_FAILURE',

	// Add a new text object to the database
	ADD_TEXT_OBJECT_REQUEST: '@TEXT_OBJECTS/ADD_REQUEST',
	ADD_TEXT_OBJECT_SUCCESS: '@TEXT_OBJECTS/ADD_SUCCESS',
	ADD_TEXT_OBJECT_FAILURE: '@TEXT_OBJECTS/ADD_FAILURE',

	// Update an existing text object from the database
	UPDATE_TEXT_OBJECT_REQUEST: '@TEXT_OBJECTS/UPDATE_REQUEST',
	UPDATE_TEXT_OBJECT_SUCCESS: '@TEXT_OBJECTS/UPDATE_SUCCESS',
	UPDATE_TEXT_OBJECT_FAILURE: '@TEXT_OBJECTS/UPDATE_FAILURE',

	// Remove an existing text object from the database
	REMOVE_TEXT_OBJECT_REQUEST: '@TEXT_OBJECTS/REMOVE_REQUEST',
	REMOVE_TEXT_OBJECT_SUCCESS: '@TEXT_OBJECTS/REMOVE_SUCCESS',
	REMOVE_TEXT_OBJECT_FAILURE: '@TEXT_OBJECTS/REMOVE_FAILURE',

	// Archive an existing text object from the database
	ARCHIVE_TEXT_OBJECT_REQUEST: '@TEXT_OBJECTS/ARCHIVE_REQUEST',
	ARCHIVE_TEXT_OBJECT_SUCCESS: '@TEXT_OBJECTS/ARCHIVE_SUCCESS',
	ARCHIVE_TEXT_OBJECT_FAILURE: '@TEXT_OBJECTS/ARCHIVE_FAILURE',

	// Restore an existing text object from the database
	RESTORE_TEXT_OBJECT_REQUEST: '@TEXT_OBJECTS/RESTORE_REQUEST',
	RESTORE_TEXT_OBJECT_SUCCESS: '@TEXT_OBJECTS/RESTORE_SUCCESS',
	RESTORE_TEXT_OBJECT_FAILURE: '@TEXT_OBJECTS/RESTORE_FAILURE',
};

// ////////////////////////////////////////////////////////////// //
// ///////////// Text object list fetching actions //////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTextObjectListRequest
 * @description Action triggered anytime a text object list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTextObjectListRequest = () => ({ type: ActionTypes.FETCH_TEXT_OBJECT_LIST_REQUEST });

/**
 * @function
 * @name fetchTextObjectListSuccess
 * @description Action triggered as a result to a successful text object list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObjects	The list of retrieved text objects.
 * @param {number} totalCount		The total amount of text objects available in the database for the current user.
 *
 * @returns {object}
 */
const fetchTextObjectListSuccess = ({ textObjects, totalCount }) => ({
	type: ActionTypes.FETCH_TEXT_OBJECT_LIST_SUCCESS,
	payload: { textObjects, totalCount },
});

/**
 * @function
 * @name fetchTextObjectListFailure
 * @description Action triggered as a result to a failed text object list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTextObjectListFailure = (error) => ({
	type: ActionTypes.FETCH_TEXT_OBJECT_LIST_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// /////////// Specific text object fetching actions ////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTextObjectRequest
 * @description Action triggered anytime a text object fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTextObjectRequest = () => ({ type: ActionTypes.FETCH_TEXT_OBJECT_REQUEST });

/**
 * @function
 * @name fetchTextObjectSuccess
 * @description Action triggered as a result to a successful text object fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObject The retrieved text object.
 *
 * @returns {object}
 */
const fetchTextObjectSuccess = ({ textObject }) => ({
	type: ActionTypes.FETCH_TEXT_OBJECT_SUCCESS,
	payload: { textObject },
});

/**
 * @function
 * @name fetchTextObjectFailure
 * @description Action triggered as a result to a failed text object fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTextObjectFailure = (error) => ({
	type: ActionTypes.FETCH_TEXT_OBJECT_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Text object creation actions ////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name addTextObjectRequest
 * @description Action triggered anytime a text object creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addTextObjectRequest = () => ({ type: ActionTypes.ADD_TEXT_OBJECT_REQUEST });

/**
 * @function
 * @name addTextObjectSuccess
 * @description Action triggered as a result to a successful text object creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObject The created text object object.
 *
 * @returns {object}
 */
const addTextObjectSuccess = ({ textObject }) => ({
	type: ActionTypes.ADD_TEXT_OBJECT_SUCCESS,
	payload: { textObject },
});

/**
 * @function
 * @name addTextObjectFailure
 * @description Action triggered as a result to a failed text object creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const addTextObjectFailure = (error) => ({
	type: ActionTypes.ADD_TEXT_OBJECT_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// ///////////////// Text object update actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateTextObjectRequest
 * @description Action triggered anytime a text object update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateTextObjectRequest = () => ({ type: ActionTypes.UPDATE_TEXT_OBJECT_REQUEST });

/**
 * @function
 * @name updateTextObjectSuccess
 * @description Action triggered as a result to a successful text object update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObject The updated text object object.
 *
 * @returns {object}
 */
const updateTextObjectSuccess = ({ textObject }) => ({
	type: ActionTypes.UPDATE_TEXT_OBJECT_SUCCESS,
	payload: { textObject },
});

/**
 * @function
 * @name updateTextObjectFailure
 * @description Action triggered as a result to a failed text object update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const updateTextObjectFailure = (error) => ({
	type: ActionTypes.UPDATE_TEXT_OBJECT_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////// //
// //////////////// Text object removal actions /////////////// //
// ////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeTextObjectRequest
 * @description Action triggered anytime a text object deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeTextObjectRequest = () => ({ type: ActionTypes.REMOVE_TEXT_OBJECT_REQUEST });

/**
 * @function
 * @name removeTextObjectSuccess
 * @description Action triggered as a result to a successful text object deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObject The removed text object object.
 *
 * @returns {object}
 */
const removeTextObjectSuccess = ({ deletedTextObjectId }) => ({
	type: ActionTypes.REMOVE_TEXT_OBJECT_SUCCESS,
	payload: { deletedTextObjectId },
});

/**
 * @function
 * @name removeTextObjectFailure
 * @description Action triggered as a result to a failed text object deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const removeTextObjectFailure = (error) => ({
	type: ActionTypes.REMOVE_TEXT_OBJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// //////////////// Text object archiving actions /////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveTextObjectRequest
 * @description Action triggered anytime an text object archiving call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveTextObjectRequest = () => ({ type: ActionTypes.ARCHIVE_TEXT_OBJECT_REQUEST });

/**
 * @function
 * @name archiveTextObjectSuccess
 * @description	Action triggered as a result to a successful text object archiving API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveTextObjectSuccess = () => ({
	type: ActionTypes.ARCHIVE_TEXT_OBJECT_SUCCESS,
});

/**
 * @function
 * @name archiveTextObjectFailure
 * @description Action triggered as a result to a failed text object archiving API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error  The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveTextObjectFailure = (error) => ({
	type: ActionTypes.ARCHIVE_TEXT_OBJECT_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////////// //
// ///////////////// Text object restoring actions //////////////// //
// ////////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreTextObjectRequest
 * @description Action triggered anytime an text object restoring call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreTextObjectRequest = () => ({ type: ActionTypes.RESTORE_TEXT_OBJECT_REQUEST });

/**
 * @function
 * @name restoreTextObjectSuccess
 * @description	Action triggered as a result to a successful text object restoring API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreTextObjectSuccess = () => ({
	type: ActionTypes.RESTORE_TEXT_OBJECT_SUCCESS,
});

/**
 * @function
 * @name restoreTextObjectFailure
 * @description Action triggered as a result to a failed text objects restoring API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreTextObjectFailure = (error) => ({
	type: ActionTypes.RESTORE_TEXT_OBJECT_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTextObjectList
 * @description Method used to update the text object list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchTextObjectList = (params) => (dispatch) => {
	dispatch(fetchTextObjectListRequest());

	return TextObjectsApi.fetchTextObjects(params)
		.then(({ textObjects, totalCount }) => dispatch(fetchTextObjectListSuccess({ textObjects, totalCount })))
		.catch((error) => dispatch(fetchTextObjectListFailure(error)));
};

/**
 * @function
 * @name fetchTextObject
 * @description Method used to fetch the latest version of a specific text object.
 *
 * @author Romaric Barthe
 *
 * @param {string} textObjectId	The id of the text object we want to retrieve.
 */
export const fetchTextObject = (textObjectId) => (dispatch) => {
	dispatch(fetchTextObjectRequest());

	return TextObjectsApi.fetchTextObjectById(textObjectId)
		.then(({ textObject }) => dispatch(fetchTextObjectSuccess({ textObject })))
		.catch((error) => dispatch(fetchTextObjectFailure(error)));
};

/**
 * @function
 * @name addTextObject
 * @description Method used to add a new text object instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObjectData		The data to create the new text object from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addTextObject = (textObjectData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addTextObjectRequest());

	return TextObjectsApi.createTextObject(textObjectData)
		.then(({ textObject }) => {
			toast.success(i18next.t('text_object.creation.toasts.success', { name: textObject.name }));
			dispatch(addTextObjectSuccess({ textObject }));
			redirectOnSuccess(onSuccessRoute);

			return textObject;
		})
		.catch((error) => {
			toast.error(i18next.t('text_object.creation.toasts.error'));
			dispatch(addTextObjectFailure(error));
		});
};

/**
 * @function
 * @name updateTextObject
 * @description Method used to update an existing text object instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObjectData	The object to update the text object with.
 * @param {string} textObjectId	The id of the text object we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateTextObject = (textObjectData, textObjectId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateTextObjectRequest());

	return TextObjectsApi.updateTextObject(textObjectData, textObjectId)
		.then(({ textObject }) => {
			dispatch(updateTextObjectSuccess({ textObject }));
			toast.success(i18next.t('text_object.edition.toasts.success', { name: textObject.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch((error) => {
			toast.error(i18next.t('text_object.edition.toasts.error'));
			dispatch(updateTextObjectFailure(error));
		});
};

/**
 * @function
 * @name removeTextObject
 * @description Method used to remove an existing text object instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObject			The text object we want to remove from the database.
 * @param {string} textObject.id		The id of the text object we want to remove from the database.
 * @param {string} textObject.name		The name of the text object we want to remove from the database.
 */
export const removeTextObject = ({ id, name }) => (dispatch) => {
	dispatch(removeTextObjectRequest());

	return TextObjectsApi.deleteTextObject(id)
		.then(({ deletedTextObjectId }) => {
			dispatch(removeTextObjectSuccess({ deletedTextObjectId }));
			toast.success(i18next.t('text_object.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeTextObjectFailure(error));
			toast.error(i18next.t('text_object.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveTextObject
 * @description	Method used to archive an existing text object instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObject			The text object we want to archive.
 * @param {string} textObject.id		The id of the text object we want to archive.
 * @param {string} textObject.name		The name of the text object we want to archive.
 */
export const archiveTextObject = ({ id, name }) => (dispatch) => {
	dispatch(archiveTextObjectRequest());

	return TextObjectsApi.updateTextObject({ archived: true }, id)
		.then(() => {
			dispatch(archiveTextObjectSuccess());
			toast.success(i18next.t('text_object.archiving.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(archiveTextObjectFailure(error));
			toast.error(i18next.t('text_object.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreTextObject
 * @description	Method used to restore an existing text object instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} textObject			The text object we want to restore.
 * @param {string} textObject.id		The id of the text object we want to restore.
 * @param {string} textObject.name		The name of the text object we want to restore.
 */
export const restoreTextObject = ({ id, name }) => (dispatch) => {
	dispatch(restoreTextObjectRequest());

	return TextObjectsApi.updateTextObject({ archived: false }, id)
		.then(() => {
			dispatch(restoreTextObjectSuccess());
			toast.success(i18next.t('text_object.restoring.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(restoreTextObjectFailure(error));
			toast.error(i18next.t('text_object.restoring.toasts.error'));
		});
};
