import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import StatusesViewer from 'components/shared/statuses/StatusesViewer';

/**
 * @name TargetingHeader
 * @description A component to display the targeting's information.
 *
 * @author Romaric Barthe
 *
 * @param {object}	targeting			The targeting.
 * @param {object}	targetingStats		The targeting stats.
 */
const TargetingHeader = ({ targeting, targetingStats }) => {
	const { t } = useTranslation();

	return (
		<div className="target-header">
			<div className="identity">
				<h4>{targeting?.project?.name}</h4>
				<p>{targeting?.project?.description}</p>
			</div>
			<div className="metadata">
				<h5>{t('targeting.target.headers.target_partner', { count: targeting?.targetPartners.length })}</h5>
				<StatusesViewer statuses={targetingStats} />
			</div>
		</div>
	);
};

TargetingHeader.propTypes = {
	targeting: PropTypes.object,
	targetingStats: PropTypes.arrayOf(PropTypes.object),
};

TargetingHeader.defaultProps = {
	targeting: undefined,
	targetingStats: undefined,
};

export default TargetingHeader;
