import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router-dom';
import { linkedEntity } from 'constants/importEnums';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import { fetchTargeting } from 'redux/actions/targetings/targetings';
import { fetchTarget, fetchTargetStats, importTargetPartnersForTarget } from 'redux/actions/targets/targets';
import { useCurrentTargetingSelector } from 'redux/selectors/targetings/targetings';
import { useCurrentTargetSelector, useCurrentTargetStatsSelector } from 'redux/selectors/targets/targets';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import ImportForm from 'components/shared/etl/ImportForm';
import TargetHeader from 'components/targetings/targets/TargetHeader';

import 'styles/pages/targetingDetailsPages.scss';

/**
 * @name ImportTargetPartnersPage
 * @description A page used to edit an existing target.
 *
 * @author Romaric Barthe
 */
const ImportTargetPartnersPage = () => {
	const { t } = useTranslation();
	const { targetId, targetingId } = useParams();
	const dispatch = useDispatch();
	const target = useCurrentTargetSelector(targetId);
	const targetStats = useCurrentTargetStatsSelector();
	const targeting = useCurrentTargetingSelector(targetingId);

	const canEditTarget = useAccessRight(AccessRights.operations.targetings.enhancedRights.CREATE_TARGETING) && !targeting?.archived;
	const canCreateContact = useAccessRight(AccessRights.commonModules.contacts.enhancedRights.CREATE_CONTACT);
	const canCreatePartner = useAccessRight(AccessRights.commonModules.partners.enhancedRights.CREATE_PARTNER);

	const bundledUserPermissions = useMemo(() => ({
		canCreateContact,
		canCreatePartner,
		canEditlinkedEntity: canEditTarget,
	}), [canCreateContact, canCreatePartner, canEditTarget]);

	useEffect(() => {
		dispatch(fetchTarget(targetId));
		dispatch(fetchTargetStats(targetId));
		dispatch(fetchTargeting(targetingId));
	}, [dispatch, targetId, targetingId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (!canEditTarget) {
			history.push(generatePath(routes.operations.targetings.targets.list, { targetingId }));
		} else {
			const targetData = {
				...formData,
				targetingId,
			};

			dispatch(importTargetPartnersForTarget(
				targetData,
				targetId,
				generatePath(routes.operations.targetings.targets.targetPartners.targetPartnerEdition, { targetId, targetingId }),
			));
		}
	}, [canEditTarget, targetingId, dispatch, targetId]);

	return target && targetingId && targetId
		? (
			<div className="targetPartner-details-page grid-container" style={{ display: 'grid', rowGap: '120px' }}>
				<div className="targetPartner-details-header grid-item">
					<TargetHeader targetingId={targetingId} targetId={targetId} targetStats={targetStats} />
				</div>
				<div className="grid-item">
					<ImportForm
						bundledUserPermissions={bundledUserPermissions}
						linkedEntity={linkedEntity.LINKED_TARGET}
						linkedObject={target}
						onSubmit={handleFormSubmit}
					/>
				</div>
			</div>
		)
		: <p>{t('targeting.target.edition.loading')}</p>;
};

export default ImportTargetPartnersPage;
