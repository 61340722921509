import { useCallback, useRef, useState } from 'react';
import { Edit2, Eye, Trash2, Upload } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { removeFile, updateFile } from 'redux/actions/mediaManager';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import ImagePreviewModal from 'components/shared/modal/ImagePreviewModal';
import TextInputModal from 'components/shared/modal/TextInputModal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { readFileToBase64 } from '../utils';

/**
 * @name ActionsCell
 * @description Custom actions cell for the MediaListPage table. (delete, edit and view exports for medias)
 *
 * @author Yann Hodiesne
 *
 * @param {object}		row								The object containing the data to interact with.
 * @param {string}		row.original.id					The id of the file.
 * @param {string}		row.original.name				The name of the file.
 * @param {string}		row.original.image.presignedUrl	The URL of the file.
 * @param {function}	fetchData						The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: file }, fetchData }) => {
	const { id, name, image: { presignedUrl } } = file;

	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();
	const { isShowing: isShowingRenameModal, toggle: toggleRenameModal } = useModal();
	const { isShowing: isShowingPreviewModal, toggle: togglePreviewModal } = useModal();

	const [isReplacingFile, setIsReplacingFile] = useState(false);
	const fileInputRef = useRef();

	const canDelete = useAccessRight(AccessRights.commonSettings.mediaManager.enhancedRights.DELETE_MEDIA_MANAGER);
	const canEdit = useAccessRight(AccessRights.commonSettings.mediaManager.enhancedRights.EDIT_MEDIA_MANAGER);

	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();
	const [viewButtonRef, viewTooltipProps] = useTooltip();
	const [uploadButtonRef, uploadTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name confirmDelete
	 * @description Method triggered as a result to an onClick event from the delete modal confirm button.
	 *
	 * @author Yann Hodiesne
	 */
	const confirmDelete = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeFile(file));
		fetchData();
	}, [dispatch, fetchData, file, toggleDeleteModal]);

	/**
	 * @function
	 * @name confirmRename
	 * @description Method triggered as a result to an onClick event from the rename modal confirm button.
	 *
	 * @author Yann Hodiesne
	 */
	const confirmRename = useCallback(async (newName) => {
		toggleRenameModal();

		await dispatch(updateFile({ ...file, name: newName }, id));
		fetchData();
	}, [dispatch, fetchData, file, id, toggleRenameModal]);

	/**
	 * @function
	 * @name onReplaceButtonClick
	 * @description Method triggered as a result to an onClick event from the replace button.
	 *
	 * @author Yann Hodiesne
	 */
	const onReplaceButtonClick = useCallback(() => {
		fileInputRef.current.click();
	}, []);

	/**
	 * @function
	 * @name onFileSelected
	 * @description Loads the selected file into memory and replaces the current file on the API.
	 *
	 * @author Yann Hodiesne
	 */
	const onFileSelected = useCallback(async (e) => {
		if (e.target.files.length === 0) {
			return;
		}

		setIsReplacingFile(true);

		const newFile = e.target.files[0];
		const fileContent = await readFileToBase64(newFile);

		await dispatch(updateFile({
			...file,
			image: fileContent,
			filename: newFile.name,
		}, id));

		fetchData();
		setIsReplacingFile(false);
	}, [dispatch, fetchData, file, id]);

	/**
	 * @function
	 * @name onPreviewButtonClick
	 * @description Method triggered as a result to an onClick event from the preview button.
	 *
	 * @author Yann Hodiesne
	 */
	const onPreviewButtonClick = useCallback(() => {
		togglePreviewModal();
	}, [togglePreviewModal]);

	return (
		<>
			{canEdit && (
				<>
					<Button className="icon-only" onClick={toggleRenameModal} ref={editButtonRef}>
						<Edit2 className="primary" />
					</Button>
					<Tooltip {...editTooltipProps}>
						{t('actions.edit')}
					</Tooltip>
					<TextInputModal
						isShowing={isShowingRenameModal}
						titleText={t('media_manager.modal.labels.edit_modal')}
						confirm={confirmRename}
						cancel={toggleRenameModal}
						defaultValue={name}
					/>
				</>
			)}
			{canEdit && (
				<>
					<Button className="icon-only" isLoading={isReplacingFile} onClick={onReplaceButtonClick} ref={uploadButtonRef}>
						<Upload />
					</Button>
					<Tooltip {...uploadTooltipProps}>
						{t('actions.upload')}
					</Tooltip>
					<input ref={fileInputRef} type="file" className="mm-file-input" accept=".png,.jpg,.jpeg,image/jpeg,image/png" onChange={onFileSelected} />
				</>
			)}
			<Button className="icon-only" onClick={onPreviewButtonClick} ref={viewButtonRef}>
				<Eye />
			</Button>
			<Tooltip {...viewTooltipProps}>
				{t('actions.visualisation')}
			</Tooltip>
			<ImagePreviewModal isShowing={isShowingPreviewModal} close={togglePreviewModal} imageUrl={presignedUrl} />
			{canDelete && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('media_manager.modal.actions.confirm_remove')}
						confirm={confirmDelete}
						cancel={toggleDeleteModal}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			image: PropTypes.shape({
				presignedUrl: PropTypes.string.isRequired,
			}),
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
