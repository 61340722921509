/* eslint-disable import/prefer-default-export */
import moment from 'moment';

/**
 * @function
 * @name formatNumberFormatsPreview
 * @description Concatenates rootName - monthPrefix - yearPrefix - digitNumber startingNumber to see a preview.
 *
 * @author Audrey Clerc
 *
 * @param {integer} digitNumber		The number of digit that should be display.
 * @param {string}	rootName		The prefix of the preview (ROOT-).
 * @param {boolean} monthPrefix		Include the month in the preview (MM-)
 * @param {boolean} yearPrefix		Include the year in the preview (YYYY-)
 * @param {integer} startingNumber	The number to start the numerotation with
 * @param {date}	createdAt		The date used in the preview
 *
 * @returns {string}
 */
export const formatNumberFormatsPreview = ({ digitNumber, rootName, monthPrefix, yearPrefix, startingNumber, date }) => {
	const monthString = monthPrefix ? `${moment(date).format('MM')}-` : '';
	const yearString = yearPrefix ? `${moment(date).format('YYYY')}-` : '';
	let startingNumberString = startingNumber.toString();

	if (digitNumber && startingNumberString.length < digitNumber) {
		startingNumberString = startingNumberString.padStart(digitNumber, '0');
	}

	return `${rootName}-${yearString}${monthString}${startingNumberString}`;
};

/**
 * @function
 * @name formatWorkforce
 * @description Format workforce number to separate thousands
 *
 * @author Audrey Clerc
 *
 * @param {number} workforce The workforce number
 *
 * @returns {string}
 */
export const formatWorkforce = (workforce) => (
	workforce ? Intl.NumberFormat('fr-FR').format(workforce) : null
);

/**
 * @function
 * @name formatWorkforceSlice
 * @description Format workforce slice number to separate thousands
 *
 * @author Audrey Clerc
 *
 * @param {string} workforceSlice The workforceSlice number
 *
 * @returns {string}
 */
export const formatWorkforceSlice = (workforceSlice) => {
	const slices = workforceSlice?.split('-');

	return workforceSlice ? formatWorkforce(slices[0]).concat(' - ') + formatWorkforce(slices[1]) : null;
};

/**
 * @function
 * @name formatTurnover
 * @description Format turnover number to separate thousands
 *
 * @author Audrey Clerc
 *
 * @param {number} turnover The turnover number
 * @param {string} currency The currency used
 *
 * @returns {string}
 */
export const formatTurnover = (turnover, currency) => (
	turnover ? Intl.NumberFormat('fr-FR', { style: 'currency', currency: currency.name, trailingZeroDisplay: 'stripIfInteger' }).format(turnover) : null
);

/**
 * @function
 * @name formatTurnoverSlice
 * @description Format turnover slice number to separate thousands
 *
 * @author Audrey Clerc
 *
 * @param {string} turnoverSlice The turnoverSlice number
 * @param {string} currency The currency used
 *
 * @returns {string}
 */
export const formatTurnoverSlice = (turnoverSlice, currency) => {
	const slices = turnoverSlice?.split(' - ');

	return turnoverSlice ? Intl.NumberFormat('fr-FR').format(slices[0]).concat(' - ') + formatTurnover(slices[1], currency) : null;
};
