import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { CapabilityType } from 'components/templates/pdf/editor/constants/Capabilities';
import { updateElement } from 'components/templates/pdf/editor/reducer/actions';

import CapabilitiesEnum from '../../../../constants/Capabilities';
import EditorContext from '../../../../EditorContext';
import { getElementType } from '../../../../functions/internals';

import BooleanCapability from './BooleanCapability';
import ComponentCapability from './ComponentCapability';
import DateCapability from './DateCapability';
import FormatCapability from './FormatCapability';
import OptionsCapability from './OptionsCapability';
import TextCapability from './TextCapability';
import TextObjectCapability from './TextObjectCapability';

/**
 * @name Capabilities
 * @description Displays and let the user interact with the capabilities of the selected element.
 *
 * @author Yann Hodiesne
 *
 * @param {string}	category	Either "basic" or "advanced", to tell the component to render the basic or the advanced capabilities
 */
const Capabilities = ({ category }) => {
	const editorContext = useContext(EditorContext);
	const { dispatch, isExporting, selectedElements } = editorContext;
	const { t } = useTranslation();

	const selectedElement = useMemo(() => selectedElements[0], [selectedElements]);

	// Get the selected element's capabilities
	const capabilities = useMemo(() => {
		if (!selectedElement) {
			return [];
		}

		const allCapabilities = getElementType(selectedElement).meta.capabilities[category]
			.map((name) => CapabilitiesEnum[name]);

		// During export, only display dynamic capabilities
		return isExporting
			? allCapabilities.filter((capability) => capability.dynamic(selectedElement))
			: allCapabilities;
	}, [category, isExporting, selectedElement]);

	// Updates a single property inside the selected element
	const handleChange = useCallback((property, value) => {
		dispatch(updateElement(selectedElement.id, { [property]: value }));
	}, [dispatch, selectedElement.id]);

	// Updates the selected element by merging its content with the provided one
	const updateSelectedElement = useCallback((content) => {
		dispatch(updateElement(selectedElement.id, content));
	}, [dispatch, selectedElement.id]);

	// Manages a queue of requested elements updates, to avoid requesting an update while rendering the capabilities
	const [updatesQueue, setUpdatesQueue] = useState([]);

	// Flushes the updates queue
	useEffect(() => {
		if (updatesQueue.length !== 0) {
			updatesQueue.forEach((content) => updateSelectedElement(content));

			setUpdatesQueue([]);
		}
	}, [updateSelectedElement, updatesQueue]);

	// Add an update request to the updates queue
	const registerUpdateRequest = useCallback((content) => {
		if (updatesQueue.length === 0) {
			setUpdatesQueue((value) => update(value, {
				$push: [content],
			}));
		}
	}, [updatesQueue.length]);

	const renderCapability = useCallback((capability) => {
		if (capability.setters) {
			capability.setters.forEach(({ property, matcher, setter }) => {
				if (!matcher(selectedElement)) {
					return;
				}

				const result = setter({ element: selectedElement, editorContext, t });

				if (!_.isEqual(result, selectedElement[property])) {
					registerUpdateRequest({
						[property]: result,
					});
				}
			});
		}

		switch (capability.type) {
			case CapabilityType.TEXT:
				return (
					<TextCapability
						label={capability.label}
						value={selectedElement[capability.property]}
						setValue={(value) => handleChange(capability.property, value)}
						readOnly={capability.readOnly(selectedElement)}
						{...capability.props}
					/>
				);
			case CapabilityType.BOOLEAN:
				return (
					<BooleanCapability
						label={capability.label}
						value={selectedElement[capability.property]}
						setValue={(value) => handleChange(capability.property, value)}
						readOnly={capability.readOnly(selectedElement)}
						{...capability.props}
					/>
				);
			case CapabilityType.OPTIONS:
				// eslint-disable-next-line no-case-declarations
				const options = capability.options({ element: selectedElement, editorContext, updateElement: registerUpdateRequest, t });

				return (
					<OptionsCapability
						label={capability.label}
						value={selectedElement[capability.property]}
						setValue={(value) => handleChange(capability.property, value)}
						options={options}
						readOnly={capability.readOnly(selectedElement)}
						{...capability.props}
					/>
				);
			case CapabilityType.DATE:
				return (
					<DateCapability
						label={capability.label}
						value={selectedElement[capability.property]}
						setValue={(value) => handleChange(capability.property, value ?? new Date().toISOString())}
						readOnly={capability.readOnly(selectedElement)}
						{...capability.props}
					/>
				);
			case CapabilityType.COMPONENT:
				return (
					<ComponentCapability
						label={capability.label}
						value={selectedElement[capability.property]}
						setValue={(value) => handleChange(capability.property, value)}
						component={capability.component}
						readOnly={capability.readOnly(selectedElement)}
						{...capability.props}
					/>
				);
			case CapabilityType.TEXT_OBJECT:
				return (
					<TextObjectCapability
						label={capability.label}
						value={selectedElement[capability.property]}
						setValue={(value) => handleChange(capability.property, value)}
						readOnly={capability.readOnly(selectedElement)}
					/>
				);
			case CapabilityType.FORMAT:
				return (
					<FormatCapability
						label={capability.label}
						value={selectedElement[capability.property]}
						setValue={(value) => handleChange(capability.property, value)}
						readOnly={capability.readOnly(selectedElement)}
						fontSizeOnly={capability.fontSizeOnly}
						{...capability.props}
					/>
				);
			default:
				throw new Error('Given element capability is not supported');
		}
	}, [editorContext, handleChange, registerUpdateRequest, selectedElement, t]);

	return (
		<div className="capabilities">
			{capabilities.flatMap((capability) => {
				/**
				* @name collectCapabilities
				* @description Recursively collects nested capabilities if their triggers matches
				*
				* @author Yann Hodiesne
				*/
				const collectCapabilities = (cap, result = []) => {
					result.push(cap);

					cap.triggers.forEach((trigger) => {
						if (trigger.matcher(selectedElement[cap.property])) {
							trigger.capabilities.forEach((x) => {
								collectCapabilities(x, result);
							});
						}
					});

					return result;
				};

				const children = collectCapabilities(capability);

				return children.map((resolvedCapability) => (
					<div key={resolvedCapability.property}>
						{renderCapability(resolvedCapability)}
					</div>
				));
			})}
		</div>
	);
};

Capabilities.propTypes = {
	category: PropTypes.oneOf([
		'basic',
		'advanced',
	]).isRequired,
};

export default Capabilities;
