import * as ProjectsApi from 'api/projectsApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Projects redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of projects
	FETCH_PROJECT_FOR_LIST_REQUEST: '@PROJECTS_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_PROJECT_FOR_LIST_SUCCESS: '@PROJECTS_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_PROJECT_FOR_LIST_FAILURE: '@PROJECTS_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Project list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchProjectsForListRequest
 * @description Action triggered anytime a projectsList list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchProjectsForListRequest = () => ({ type: ActionTypes.FETCH_PROJECT_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchProjectsForListSuccess
 * @description Action triggered as a result to a successful projectsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	projectsList	The list of retrieved projects.
 * @param {number}	totalCount		The total amount of projects available in the database to the current user.
 *
 * @returns {object}
 */
const fetchProjectsForListSuccess = ({ projectsList, totalCount }) => ({
	type: ActionTypes.FETCH_PROJECT_FOR_LIST_SUCCESS,
	payload: { projectsList, totalCount },
});

/**
 * @function
 * @name fetchProjectsForListFailure
 * @description Action triggered as a result to a failed projectsList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchProjectsForListFailure = (error) => ({
	type: ActionTypes.FETCH_PROJECT_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchProjectsForList
 * @description Method used to update the projectsList list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchProjectsForList = (filters) => (dispatch) => {
	dispatch(fetchProjectsForListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forList',
	};

	return ProjectsApi.fetchProjects(newFilters)
		.then(({ projectsList, totalCount }) => dispatch(fetchProjectsForListSuccess({ projectsList, totalCount })))
		.catch((error) => dispatch(fetchProjectsForListFailure(error)));
};
