import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { X } from 'react-feather';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

/**
 * @name Modal
 * @description A generic modal usable inside any page
 *
 * @author Yann Hodiesne
 *
 * @param {node}	children		The components to render inside the modal
 * @param {bool}	isShowing		Determines if the modal is currently being shown to the user
 * @param {string}	title			The title of the modal, displayed inside its header
 * @param {func}	[onClose]		Method used to close the modal on user's request
 * @param {string}	[className]		Classes to apply to the modal
 */
const Modal = ({ children, isShowing, title, onClose, className, additionalStyle }) => {
	const modalRef = useRef();

	if (!isShowing) {
		return null;
	}

	return ReactDOM.createPortal(
		<div className={`modal${className ? ` ${className}` : ''}`}>
			<div className="modal-wrapper" role="dialog" aria-labelledby="dialog-label" aria-modal="true">
				<div ref={modalRef} className="modal-content" style={additionalStyle}>
					<div className="modal-header">
						<h4 id="dialog-label">{title}</h4>
						{onClose && (
							<Button data-testid="modal-close" className="icon-only" onClick={onClose}>
								<X />
							</Button>
						)}
					</div>
					{children}
				</div>
			</div>
		</div>,
		document.body
	);
};

Modal.propTypes = {
	children: PropTypes.node,
	isShowing: PropTypes.bool.isRequired,
	title: PropTypes.string,
	onClose: PropTypes.func,
	className: PropTypes.string,
	additionalStyle: PropTypes.object,
};

Modal.defaultProps = {
	children: null,
	onClose: null,
	className: undefined,
	title: undefined,
	additionalStyle: undefined,
};

export default Modal;
