/**
 * @function
 * @description Concatenate sector and type values to provide a full name for the sector.
 * @name formatSectorName
 *
 * @author Romaric Barthe
 *
 * @param {object} sector  			The sector to be named.
 * @param {Object} sector.type		The sector type to extract the name from.
 * @param {string} sector.type.name	The sector type name to extract.
 *
 * @returns {string}
 */
export const formatSectorName = (sector) => ((sector.type && sector.type.name !== 'CUSTOM') ? (sector.type.name).concat(' :: ').concat(sector.name) : sector.name);

/**
 * @function
 * @name formatSectorTypeName
 * @description Extracts the name of a sector's type for presentation purposes.
 *
 * @author Timothée Simon-Franza
 *
 * @param {Object} sector 			The sector to extract the type name from.
 * @param {Object} sector.type		The sector type to extract the name from.
 * @param {string} sector.type.name	The sector type name to extract.
 *
 * @returns {string | undefined}
 */
export const formatSectorTypeName = (sector) => (sector.type?.name);
