import { useCallback, useMemo, useState } from 'react';
import { Filter, Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { adaptToBackendFilters } from 'lib/shared/collectionsUtils';
import { formatCreatedAt, formatCreatedBy, formatDateTime, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchOffersForList } from 'redux/actions/offers/offersList';
import { useOffersForListSelector, useOffersListTotalCountSelector } from 'redux/selectors/offers/offersList';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import ActionsCell from 'components/offers/offerList/ActionsCell';
import { Button } from 'components/shared/buttons';
import { SearchInput } from 'components/shared/inputs';
import { useDataFilter } from 'components/shared/list/DataListFilters';
import DataListFilterPanel from 'components/shared/list/DataListFilters/DataListFilterPanel';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

import 'styles/pages/dataTablePages.scss';

/**
 * @name OfferListPage
 * @description A list of all offers accessible from the offers app.
 *
 * From this page, the user is able to view, interact and search for offers.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 */
const OfferListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const offers = useOffersForListSelector();
	const totalCount = useOffersListTotalCountSelector();

	const canCreateOffer = useAccessRight(AccessRights.sales.offers.enhancedRights.CREATE_OFFER);

	const headers = useMemo(() => [
		{ accessor: 'code', Header: t('offer.fields.code'), pinColumn: true, sorting: 'offer.code' },
		{
			id: 'archived',
			accessor: (row) => (row.archived ? t('offer.fields.archived.yes') : t('offer.fields.archived.no')),
			Header: t('offer.fields.archived.header'),
			sorting: 'offer.archived',
		},
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('offer.headers.actions') },
		{ accessor: 'name', Header: t('offer.fields.name'), pinColumn: true, sorting: 'offer.name' },
		{ accessor: 'description', Header: t('offer.fields.description'), sorting: 'offer.description' },
		{ accessor: 'price', Header: t('offer.fields.price'), sorting: 'offer.price' },
		{ id: 'currency', accessor: (row) => row.currency?.name ?? '', Header: t('offer.fields.currency'), sorting: 'offer.currency' },
		{ id: 'validFrom', accessor: (row) => formatDateTime(row.validFrom), Header: t('offer.fields.begin_date'), sorting: 'offer.validFrom' },
		{ id: 'validTo', accessor: (row) => formatDateTime(row.validTo), Header: t('offer.fields.end_date'), sorting: 'offer.validTo' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('offer.fields.creation_name'), sorting: 'offer.createdBy' },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('offer.fields.creation_date'), sorting: 'offer.createdAt' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('offer.fields.update_name'), sorting: 'offer.updatedBy' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('offer.fields.update_date'), sorting: 'offer.updatedAt' },
	], [t]);

	const columns = useMemo(() => [
		{ accessor: 'name', label: t('offer.fields.name'), type: 'text' },
		{ accessor: 'code', label: t('offer.fields.code'), type: 'entity' },
		{ accessor: 'description', label: t('offer.fields.description'), type: 'entity' },
		{ accessor: 'price', label: t('offer.fields.price'), type: 'entity' },
		{ accessor: 'currency', label: t('offer.fields.currency'), type: 'text' },
		{ accessor: 'validFrom', label: t('offer.fields.begin_date'), type: 'date' },
		{ accessor: 'validTo', label: t('offer.fields.end_date'), type: 'date' },
		{ accessor: 'createdAt', label: t('offer.fields.creation_date'), type: 'date' },
		{ accessor: 'createdBy', label: t('offer.fields.creation_name'), type: 'text' },
		{ accessor: 'updatedBy', label: t('offer.fields.update_name'), type: 'text' },
		{ accessor: 'updatedAt', label: t('offer.fields.update_date'), type: 'date' },
	], [t]);

	const [filters, setFilters] = useState({});
	const [params, setParams] = useState({});
	const [fetchData, searchRef] = useSearch((param) => {
		setParams(param);
		dispatch(fetchOffersForList({ ...filters, ...param }));
	});

	const [filterButtonRef, filterTooltipProps] = useTooltip();
	const [newButtonRef, newTooltipProps] = useTooltip();

	const { tableProps, modalProps } = useTableUserPreferences('offer');

	const { isFilterPanelDisplayed, toggleFilterPanel } = useDataFilter();

	const applyFilter = useCallback((filter) => {
		const newFilters = adaptToBackendFilters(filter);

		setFilters(newFilters);
		dispatch(fetchOffersForList({ filters: newFilters, ...params }));
	}, [dispatch, params]);

	/**
	 * @function
	 * @name onCreateButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onCreateButtonClick = useCallback(() => { history.push(generatePath(routes.sales.offers.offerCreation)); }, []);

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
				{canCreateOffer && (
					<>
						<Button className="icon-only-primary" onClick={onCreateButtonClick} ref={newButtonRef}>
							<Plus />
						</Button>
						<Tooltip {...newTooltipProps}>
							{t('offer.links.create')}
						</Tooltip>
					</>
				)}
				<Button className="icon-only" onClick={toggleFilterPanel} ref={filterButtonRef}>
					<Filter />
				</Button>
				<Tooltip {...filterTooltipProps}>
					{t('components.table.filters.actions.filter')}
				</Tooltip>
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={offers}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="offer"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('offer.pages.list')} {...modalProps} />
			<DataListFilterPanel
				applyFilterCallBack={applyFilter}
				columns={columns}
				open={isFilterPanelDisplayed}
				onCloseButtonClick={toggleFilterPanel}
			/>
		</div>
	);
};

export default OfferListPage;
