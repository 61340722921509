import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name usePartnersSelectLoadingSelector
 * @description A selector to retrieve the loading state from the partnersSelect redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const usePartnersSelectLoadingSelector = () => {
	const loading = useSelector((state) => state.partnersSelect?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name usePartnersSelectListSelector
 * @description A selector to retrieve the partnersSelect list from the partner redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const usePartnersSelectListSelector = () => {
	const partnersSelect = useSelector((state) => (state.partnersSelect?.items ?? []), shallowEqual);

	return partnersSelect;
};

/**
 * @function
 * @name usePartnersSelectTotalCountSelector
 * @description A selector to retrieve the totalCount value from the partnersSelect redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const usePartnersSelectTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.partnersSelect?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	usePartnersSelectListSelector,
	usePartnersSelectLoadingSelector,
	usePartnersSelectTotalCountSelector,
};
