import { useCallback, useContext, useEffect, useMemo } from 'react';
import { HelpCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { Select } from 'components/shared/inputs';
import EditorContext from 'components/templates/pdf/editor/EditorContext';

/**
 * @name OptionsCapability
 * @description An options capability, displayed inside the editor's toolbox
 *
 * @author Yann Hodiesne
 *
 * @param {string}		value						The current value of the capability
 * @param {string}		label						The label translation key to display
 * @param {function}	setValue					A callback updating the value associated to this capability
 * @param {array}		options						An array of available values for the capability
 * @param {boolean}		[readOnly]					Whether the capability can be edited by the user, defaults to false
 * @param {function}	[onAddClick]				A callback to execute when the + button has been clicked, defaults to undefined
 * @param {boolean}		[disableOptionsTranslation]	Whether the provided options needs to be translated or not, defaults to false
 */
const OptionsCapability = ({ value, label, setValue, options, onAddClick, readOnly, disableOptionsTranslation, ...otherProps }) => {
	const { dispatch } = useContext(EditorContext);
	const { t } = useTranslation();
	const inputId = uuid(); // used to tie the label to the input.

	const translatedOptions = useMemo(() => {
		if (disableOptionsTranslation) {
			return options;
		}

		return options.map((option) => ({ ...option, label: t(option.label) }));
	}, [disableOptionsTranslation, options, t]);

	const selectedOption = useMemo(() => translatedOptions.find((option) => option.value === value) ?? null, [translatedOptions, value]);

	// If the current value does not exist anymore in the available options, we set the current value to null
	useEffect(() => {
		if (value !== null && selectedOption === null) {
			setValue(null);
		}
	}, [selectedOption, setValue, value]);

	const onAddClickHandler = useCallback(() => {
		onAddClick(dispatch);
	}, [dispatch, onAddClick]);

	const select = useCallback(() => (
		<Select
			inputId={inputId}
			options={translatedOptions}
			value={selectedOption}
			openMenuOnFocus
			onChange={({ value: newValue }) => setValue(newValue)}
			isDisabled={readOnly}
			onAddClick={onAddClick ? onAddClickHandler : undefined}
			{...otherProps}
		/>
	), [inputId, onAddClick, onAddClickHandler, otherProps, readOnly, selectedOption, setValue, translatedOptions]);

	return (
		<div className="input-wrapper options-capability">
			<label htmlFor={inputId}>
				{t(label)}
			</label>
			{selectedOption?.info ? (/* Display Select with a tooltip containing the option's info */
				<div className="info-wrapper">
					<div>{select()}</div>
					<HelpCircle data-tooltip-id="optionCapability" data-tooltip-content={t(selectedOption.info)} />
				</div>
			)
				: select() /* Display the Select only */}
			<ReactTooltip id="optionCapability" place="left" effect="solid" />
		</div>
	);
};

OptionsCapability.propTypes = {
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.bool,
		PropTypes.number,
	]),
	label: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	options: PropTypes.arrayOf(PropTypes.exact({
		info: PropTypes.string,
		label: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired,
	})).isRequired,
	readOnly: PropTypes.bool,
	onAddClick: PropTypes.func,
	disableOptionsTranslation: PropTypes.bool,
};

OptionsCapability.defaultProps = {
	value: null,
	readOnly: false,
	onAddClick: undefined,
	disableOptionsTranslation: false,
};

export default OptionsCapability;
