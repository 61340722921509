import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import StyledTag from 'components/shared/styledElements/StyledTag';

/**
 * @name EntitiesHeader
 * @description Header containing the title and entities count
 *
 * @author Yann Hodiesne
 */
const EntitiesHeader = ({ count, ...otherProps }) => {
	const { t } = useTranslation();

	return (
		<div className="header" {...otherProps}>
			<div className="title">
				{t('template.pdf.entities.header')}
			</div>
			<StyledTag color="#8C8C8C">
				<div className="details">
					{t('template.pdf.entities.header_count_suffix', { count })}
				</div>
			</StyledTag>
		</div>
	);
};

EntitiesHeader.propTypes = {
	count: PropTypes.number.isRequired,
};

export default EntitiesHeader;
