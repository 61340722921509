import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchTemplateExport } from 'redux/actions/templateExports';
import { useCurrentTemplateExportSelector } from 'redux/selectors/templateExports';

import { TemplateExport } from 'components/templates/pdf/editor';

/**
 * @name TemplatePdfReexportPage
 * @description Page shown when a user wants to reexport a PDF document based on an existing export
 *
 * @author Yann Hodiesne
 */
const TemplatePdfReexportPage = () => {
	const { t } = useTranslation();
	const { id: templateExportId } = useParams();
	const dispatch = useDispatch();

	const templateExport = useCurrentTemplateExportSelector(templateExportId);

	const content = useMemo(() => (templateExport?.templateContent ? JSON.parse(templateExport.templateContent) : null), [templateExport?.templateContent]);

	useEffect(() => {
		dispatch(fetchTemplateExport(templateExportId));
	}, [dispatch, templateExportId]);

	return (
		templateExport
			? (
				<TemplateExport
					initialData={content}
					templateMetadata={{ description: templateExport.templateDescription, name: templateExport.templateName, type: templateExport.templateType }}
					linkedEntities={templateExport.linkedEntities}
					fromExport
				/>
			)
			: <p>{t('template.export.loading_template')}</p>
	);
};

export default TemplatePdfReexportPage;
