import { useCallback, useMemo, useRef } from 'react';

/**
 * @name useSubmitButton
 * @description A custom hook providing props linking a DynamicForm with its submit button
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const useSubmitButton = () => {
	const formRef = useRef();
	const onSubmitClick = useCallback(() => formRef.current.triggerSubmit(), []);

	const formProps = useMemo(() => ({
		ref: formRef,
	}), []);

	const buttonProps = useMemo(() => ({
		onClick: onSubmitClick,
		type: 'submit',
	}), [onSubmitClick]);

	return { formProps, buttonProps };
};

export default useSubmitButton;
