import { useMemo } from 'react';
import { historyChannels } from 'constants/historyEnums';
import { formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import PropTypes from 'prop-types';

/**
 * @name HistoryCell
 * @description Custom cell for the targetPartnerList table displaying a targeting's history.
 *
 * @author Audrey Clerc
 */
const HistoryCell = ({ row: { original: { histories } } }) => {
	const historiesFiltered = useMemo(() => histories.filter((history) => history.channel !== (historyChannels.HISTORY_CHANNEL_STATUS || historyChannels.HISTORY_CHANNEL_WORKFLOW)),
		[histories]);

	const mostRecentHistory = useMemo(() => {
		if (historiesFiltered.length > 0) {
			return historiesFiltered.reduce((prev, current) => {
				if (prev.updatedAt > current.updatedAt) {
					return prev;
				}

				return current;
			});
		}

		return {};
	}, [historiesFiltered]);

	return (
		<ul className="tag-list">
			<span>
				{mostRecentHistory !== {} ? formatUpdatedAt(mostRecentHistory) : ''}
			</span>
			<span>
				{mostRecentHistory !== {} ? formatUpdatedBy(mostRecentHistory) : ''}
			</span>
		</ul>
	);
};

HistoryCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			histories: PropTypes.arrayOf(PropTypes.shape({
				channel: PropTypes.string.isRequired,
				updatedAt: PropTypes.string.isRequired,
				updatedBy: PropTypes.shape({
					username: PropTypes.string,
				}),
			})).isRequired,
		}).isRequired,
	}).isRequired,
};
export default HistoryCell;
