import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useQuotationsListLoadingSelector
 * @description A selector to retrieve the loading state from the quotationsList redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {boolean}
 */
const useQuotationsListLoadingSelector = () => {
	const loading = useSelector((state) => state.quotationsList?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useQuotationsForListSelector
 * @description A selector to retrieve the quotationsList list from the quotation redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {Array}
 */
const useQuotationsForListSelector = () => {
	const quotationsList = useSelector((state) => (state.quotationsList?.items ?? []), shallowEqual);

	return quotationsList;
};

/**
 * @function
 * @name useQuotationsListTotalCountSelector
 * @description A selector to retrieve the totalCount value from the quotationsList redux state.
 *
 * @author Florian Fornazaric
 *
 * @returns {number|undefined}
 */
const useQuotationsListTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.quotationsList?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

export {
	useQuotationsForListSelector,
	useQuotationsListLoadingSelector,
	useQuotationsListTotalCountSelector,
};
