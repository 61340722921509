import getCapabilities from './getCapabilities';

/**
 * @function
 * @name extractCapabilitiesProperties
 * @description Recursively extracts the provided element's capability-related properties by main capability.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} element				The element to extract capabilities properties from.
 * @param {object} [nestedCapabilites]	The capabilities to process in a recursive call.
 *
 * @returns {object}
 */
const extractCapabilitiesProperties = (element, nestedCapabilities = undefined) => {
	const capabilities = nestedCapabilities ?? getCapabilities(element);
	const result = {};

	capabilities.forEach((capability) => {
		result[capability.name] = {
			[capability.property]: element[capability.property],
		};

		capability.triggers?.forEach((trigger) => {
			result[capability.name] = {
				...result[capability.name],
				...Object.entries(extractCapabilitiesProperties(element, trigger.capabilities))
					.reduce((acc, [, value]) => ({ ...acc, ...value }), {}),
			};
		});
	});

	return result;
};

export default extractCapabilitiesProperties;
