import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { ChromePicker, CirclePicker } from 'react-color';
import { useForwardedRef, useOnClickOutside } from 'lib/hooks';
import PropTypes from 'prop-types';

/**
 * @name ColorPicker
 * @description A color picker.
 *
 * @author Florian Fornazaric
 *
 * @param {string}	[value] 			The current color value.
 * @param {string}	[defaultValue] 		The default color value.
 * @param {bool}	[disabled] 			Whether the color picker is disabled.
 * @param {func}	[previewComponent] 	A component to render in the preview.
 * @param {func}	[onChange] 			A callback to trigger when the color changes.
 * @param {string}	[type] 				The type of color picker to use.
 */
const ColorPicker = forwardRef(({ defaultValue, value, previewComponent, onChange, disabled, type, ...otherProps }, ref) => {
	const [color, setColor] = useState(value || defaultValue);
	const [isOpen, setIsOpen] = useState(false);

	const resolvedColorPickerRef = useForwardedRef(ref);

	const previewComponentRef = useRef();

	const handleChange = useCallback((eColor) => {
		setColor(eColor.hex);
		onChange?.(eColor.hex);
	}, [onChange]);

	const handleOpen = useCallback(() => {
		if (!disabled) {
			setIsOpen((v) => !v);
		}
	}, [disabled]);

	const handleClickedOutside = useCallback(() => {
		if (isOpen) {
			setIsOpen(false);
		}
	}, [isOpen]);

	useEffect(() => {
		if (value) {
			setColor(value);
		}
	}, [value]);

	useOnClickOutside(handleClickedOutside, resolvedColorPickerRef);

	const renderColorPicker = useCallback(() => {
		switch (type) {
			case 'circle':
				return (
					<CirclePicker
						color={color}
						colors={['#f44336',
							'#e91e63',
							'#9c27b0',
							'#673ab7',
							'#3f51b5',
							'#2196f3',
							'#03a9f4',
							'#00bcd4',
							'#009688',
							'#4caf50',
							'#8bc34a',
							'#cddc39',
							'#E5E8EA',
							'#ffc107',
							'#ff9800',
							'#ff5722',
							'#795548',
							'#607d8b']}
						onChange={handleChange}
					/>
				);
			case 'chrome':
				return <ChromePicker color={color} onChange={handleChange} disableAlpha />;
			default:
				return null;
		}
	}, [color, handleChange, type]);

	const CustomComponent = previewComponent;

	return (
		<div ref={resolvedColorPickerRef} className="color-picker-wrapper" type={type} {...otherProps}>
			{previewComponent ? (
				<CustomComponent
					ref={previewComponentRef}
					style={{ backgroundColor: color, cursor: disabled ? 'not-allowed' : 'pointer' }}
					onClick={handleOpen}
				>
					{color}
				</CustomComponent>
			) : null}

			<div className={`color-picker${isOpen ? ' open' : ''}`} style={{ top: previewComponentRef.current?.offsetHeight }}>
				{renderColorPicker()}
			</div>
		</div>
	);
});

ColorPicker.displayName = 'ColorPicker';

ColorPicker.propTypes = {
	value: PropTypes.string,
	defaultValue: PropTypes.string,
	previewComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	type: PropTypes.oneOf(['circle', 'chrome']),
};

ColorPicker.defaultProps = {
	value: undefined,
	previewComponent: undefined,
	defaultValue: undefined,
	onChange: () => {},
	disabled: false,
	type: 'circle',
};

export default ColorPicker;
