import { useCallback } from 'react';
import { Archive, Edit2, Eye, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { archiveStatus, removeStatus, restoreStatus } from 'redux/actions/statuses';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the StatusListPage table. (archive, delete and edit status)
 *
 * @author Romaric Barthe
 *
 * @param {object}		row							The row from which we retrieve the data to interact with.
 * @param {boolean}		row.original.archived		The archived status of the status.
 * @param {string}		row.original.id				The id of the status.
 * @param {string}		row.original.name			The name of the status.
 * @param {string}		row.original.description	The description of the status.
 * @param {function}	fetchData					The method to trigger if we need to refresh the parent table's data.
 */
const ActionsCell = ({ row: { original: { archived, id, name, description } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingArchiveModal, toggle: toggleArchiveModal } = useModal();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditStatus = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.CREATE_SETTING);
	const canDeleteStatus = useAccessRight(AccessRights.commonSettings.settings.enhancedRights.DELETE_SETTING);

	const [archiveButtonRef, archiveTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();

	/**
	 * @function
	 * @name onArchiveModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the archive/restore button.
	 *
	 * @author Romaric Barthe
	 */
	const onArchiveModalConfirmationButtonClick = useCallback(async () => {
		toggleArchiveModal();

		if (archived) {
			await dispatch(restoreStatus({ id, name, description }));
		} else {
			await dispatch(archiveStatus({ id, name, description }));
		}

		fetchData();
	}, [archived, dispatch, fetchData, id, name, description, toggleArchiveModal]);

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered when the deletion modal's confirmation button is clicked..
	 *
	 * @author Romaric Barthe
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeStatus({ id, name, description }));
		fetchData();
	}, [dispatch, fetchData, id, name, description, toggleDeleteModal]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Romaric Barthe
	 */
	const onEditPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.settings.statuses.statusEdition, { id }));
	}, [id]);

	return (
		<>
			<Button className="icon-only" onClick={onEditPageRedirectionButtonClick} ref={editButtonRef}>
				{canEditStatus && !archived ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditStatus && !archived ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			{canEditStatus && (
				<>
					<Button className="icon-only" onClick={toggleArchiveModal} ref={archiveButtonRef}>
						<Archive />
					</Button>
					<Tooltip {...archiveTooltipProps}>
						{t('actions.archived')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingArchiveModal}
						message={(archived
							? t('status.enabling.confirmation_modal.content')
							: t('status.disabling.confirmation_modal.content')
						)}
						confirm={onArchiveModalConfirmationButtonClick}
						cancel={toggleArchiveModal}
						confirmText={(archived
							? t('status.enabling.confirmation_modal.confirm')
							: t('status.disabling.confirmation_modal.confirm')
						)}
						cancelText={(archived
							? t('status.enabling.confirmation_modal.cancel')
							: t('status.disabling.confirmation_modal.cancel')
						)}
						titleText={(archived
							? t('status.enabling.confirmation_modal.title')
							: t('status.disabling.confirmation_modal.title')
						)}
					/>
				</>
			)}
			{canDeleteStatus && !archived && (
				<>
					<Button className="icon-only" onClick={toggleDeleteModal} ref={deleteButtonRef}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('status.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('status.deletion.confirmation_modal.confirm')}
						cancelText={t('status.deletion.confirmation_modal.cancel')}
						titleText={t('status.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			archived: PropTypes.bool,
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			description: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

export default ActionsCell;
