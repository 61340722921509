import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

/**
 * @name TabPanel
 * @description A tab panel component to be used in conjunction with the Tab component.
 *
 * @author Timothée Simon-Franza
 *
 * @param {node}	children		The content to place inside the tab panel.
 * @param {string}	tabId			The id of the tab controlling this tab panel.
 * @param {string}	tabPanelId		The id of this tab panel.
 * @param {bool}	[hidden=false]	Whether this tab panel should be hidden.
 */
const TabPanel = ({ children, tabPanelId, tabId, hidden, additionalStyle, ...otherProps }) => (
	<div
		role="tabpanel"
		id={tabPanelId}
		aria-labelledby={tabId}
		hidden={hidden}
		aria-hidden={hidden}
		style={additionalStyle}
	>
		{Children.map(children, (child) => cloneElement(child,
			{
				...child.props,
				...otherProps,
				shouldRefreshData: !hidden,
			}))}
	</div>
);

TabPanel.propTypes = {
	children: PropTypes.node.isRequired,
	tabPanelId: PropTypes.string.isRequired,
	tabId: PropTypes.string.isRequired,
	hidden: PropTypes.bool,
	additionalStyle: PropTypes.object,
};

TabPanel.defaultProps = {
	hidden: false,
	additionalStyle: undefined,
};

export default TabPanel;
