import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchCurrencyList } from 'redux/actions/currencies';
import { useCurrenciesLoadingSelector, useCurrencyListSelector } from 'redux/selectors/currencies';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { DateInput, NumberInput, Select, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';

/**
 * @name OfferEditionForm
 * @description A form used to edit an existing offer's information.
 *
 * @author Audrey Clerc
 * @author Romaric Barthe
 *
 * @param {object}	offer		The offer object to update information from.
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const OfferEditionForm = ({ onSubmit, offer }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchCurrencyList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const currencies = useCurrencyListSelector();
	const areCurrenciesLoading = useCurrenciesLoadingSelector();

	const [validFrom, setValidFrom] = useState(new Date(offer.validFrom));
	const [currency, setCurrency] = useState(offer.currency?.id);
	const currencyName = useMemo(() => (currencies.length > 0 ? currencies.filter((c) => c.id === currency)[0].name : ''), [currencies, currency]);

	const canEditOffer = useAccessRight(AccessRights.sales.offers.enhancedRights.CREATE_OFFER) && !offer?.archived;

	const { formProps, buttonProps } = useSubmitButton();

	const defaultValues = useMemo(() => ({
		...offer,
		currency: offer.currency?.id,
	}), [offer]);

	return (
		<DynamicForm
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			disabled={!canEditOffer}
			{...formProps}
		>
			<TextInput
				label={t('offer.edition.inputs.code.label')}
				name="code"
				type="text"
			/>
			<TextInput
				label={t('offer.edition.inputs.name.label')}
				name="name"
				placeholder={t('offer.edition.inputs.name.placeholder')}
				rules={{
					required: Validators.isRequired(t('offer.edition.inputs.name.validation_errors.required')),
				}}
				type="text"
			/>
			<TextArea
				label={t('offer.edition.inputs.description.label')}
				placeholder={t('offer.edition.inputs.description.placeholder')}
				name="description"
			/>
			<DateInput
				label={t('offer.edition.inputs.valid_from.label')}
				name="validFrom"
				onChange={setValidFrom}
				required
				rules={{
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
			<NumberInput
				allowNull
				className="number-input"
				decimalScale={2}
				fixedDecimalScale
				icon={currencyName}
				label={t('offer.creation.inputs.price.label')}
				name="price"
				step={0.01}
			/>
			<Select
				isLoading={areCurrenciesLoading}
				label={t('offer.edition.inputs.currency.label')}
				labelKey="name"
				placeholder={t('offer.edition.inputs.currency.placeholder')}
				name="currency"
				onChange={setCurrency}
				options={currencies}
				rules={{
					required: Validators.isRequired(t('offer.edition.inputs.currency.validation_errors.required')),
				}}
				valueKey="id"
			/>
			<DateInput
				allowNull
				label={t('offer.edition.inputs.valid_until.label')}
				name="validTo"
				rules={{
					minDate: Validators.minDate(validFrom, t('components.date_picker.format'), t('offer.edition.inputs.date.validation_errors.minDate')),
					pattern: Validators.isDate(t('components.date_picker.format'), t('components.date_picker.incorrect_value')),
				}}
			/>
			<Button className="primary" type="submit" {...buttonProps}>
				{canEditOffer ? t('offer.edition.action') : t('offer.edition.close')}
			</Button>
		</DynamicForm>
	);
};

OfferEditionForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	offer: PropTypes.shape({
		archived: PropTypes.bool,
		code: PropTypes.string,
		name: PropTypes.string.isRequired,
		description: PropTypes.string,
		validFrom: PropTypes.string.isRequired,
		price: PropTypes.number,
		currency: PropTypes.shape({
			id: PropTypes.string.isRequired,
			description: PropTypes.string,
			name: PropTypes.string.isRequired,
		}).isRequired,
		validTo: PropTypes.string,
	}).isRequired,
};

export default OfferEditionForm;
