import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchRegistration, updateRegistration } from 'redux/actions/registrations';
import { useCurrentRegistrationSelector } from 'redux/selectors/registrations';
import routes from 'routes';

import { RegistrationEditionForm } from 'components/registrations';

import { AccessRights, useAccessRight } from '../../lib/shared/accessRights';
import { redirectOnSuccess } from '../../lib/shared/redirectionHelper';

/**
 * @name RegistrationEditionPage
 * @description A page used to edit an existing registration.
 *
 * @author Romaric Barthe
 */
const RegistrationEditionPage = () => {
	const { id: registrationId } = useParams();
	const dispatch = useDispatch();

	const registration = useCurrentRegistrationSelector(registrationId);

	const canEditRegistration = useAccessRight(AccessRights.commonModules.sectors.enhancedRights.CREATE_SECTOR) && !registration?.archived;

	useEffect(() => {
		dispatch(fetchRegistration(registrationId));
	}, [dispatch, registrationId]);

	/**
	 * @function
	 * @name handleFormSubmit
	 * @description Formats the submitted form data in an object that can be understood by the API.
	 *
	 * @author Romaric Barthe
	 *
	 * @param {object} formData	The data from the submitted form
	 */
	const handleFormSubmit = useCallback((formData) => {
		if (canEditRegistration) {
			dispatch(updateRegistration(formData, registrationId, routes.commonModules.registrations.list));
		} else {
			redirectOnSuccess(routes.commonModules.registrations.list);
		}
	}, [canEditRegistration, dispatch, registrationId]);

	return (
		registration
			? <RegistrationEditionForm registration={registration} onSubmit={handleFormSubmit} />
			: <p>Loading registration information</p>
	);
};

export default RegistrationEditionPage;
