import { toast } from 'react-toastify';
import * as AccessDefinitionsApi from 'api/accessDefinitionsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Access definitions redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific access definition
	FETCH_ACCESS_DEFINITION_REQUEST: '@ACCESS_DEFINITIONS/FETCH_REQUEST',
	FETCH_ACCESS_DEFINITION_SUCCESS: '@ACCESS_DEFINITIONS/FETCH_SUCCESS',
	FETCH_ACCESS_DEFINITION_FAILURE: '@ACCESS_DEFINITIONS/FETCH_FAILURE',

	// Fetch a list of access definitions
	FETCH_ACCESS_DEFINITION_LIST_REQUEST: '@ACCESS_DEFINITIONS/FETCH_LIST_REQUEST',
	FETCH_ACCESS_DEFINITION_LIST_SUCCESS: '@ACCESS_DEFINITIONS/FETCH_LIST_SUCCESS',
	FETCH_ACCESS_DEFINITION_LIST_FAILURE: '@ACCESS_DEFINITIONS/FETCH_LIST_FAILURE',

	// Add a new access definition to the database
	ADD_ACCESS_DEFINITION_REQUEST: '@ACCESS_DEFINITIONS/ADD_REQUEST',
	ADD_ACCESS_DEFINITION_SUCCESS: '@ACCESS_DEFINITIONS/ADD_SUCCESS',
	ADD_ACCESS_DEFINITION_FAILURE: '@ACCESS_DEFINITIONS/ADD_FAILURE',

	// Update an existing access definition from the database
	UPDATE_ACCESS_DEFINITION_REQUEST: '@ACCESS_DEFINITIONS/UPDATE_REQUEST',
	UPDATE_ACCESS_DEFINITION_SUCCESS: '@ACCESS_DEFINITIONS/UPDATE_SUCCESS',
	UPDATE_ACCESS_DEFINITION_FAILURE: '@ACCESS_DEFINITIONS/UPDATE_FAILURE',

	// Remove an existing access definition from the database
	REMOVE_ACCESS_DEFINITION_REQUEST: '@ACCESS_DEFINITIONS/REMOVE_REQUEST',
	REMOVE_ACCESS_DEFINITION_SUCCESS: '@ACCESS_DEFINITIONS/REMOVE_SUCCESS',
	REMOVE_ACCESS_DEFINITION_FAILURE: '@ACCESS_DEFINITIONS/REMOVE_FAILURE',
};

// ////////////////////////////////////////////////////////////////// //
// ///////////// Access definition list fetching actions //////////// //
// ////////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAccessDefinitionListRequest
 * @description Action triggered anytime an access definition list fetching call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const fetchAccessDefinitionListRequest = () => ({ type: ActionTypes.FETCH_ACCESS_DEFINITION_LIST_REQUEST });

/**
 * @function
 * @name fetchAccessDefinitionListSuccess
 * @description Action triggered as a result to a successful access definition list fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} accessDefinitions    The list of retrieved access definitions.
 * @param {number} totalCount           The total amount of access definitions available in the database for the current user.
 *
 * @returns {object}
 */
const fetchAccessDefinitionListSuccess = ({ accessDefinitions, totalCount }) => ({
	type: ActionTypes.FETCH_ACCESS_DEFINITION_LIST_SUCCESS,
	payload: { accessDefinitions, totalCount },
});

/**
 * @function
 * @name fetchAccessDefinitionListFailure
 * @description Action triggered as a result to a failed access definition list fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAccessDefinitionListFailure = (error) => ({
	type: ActionTypes.FETCH_ACCESS_DEFINITION_LIST_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////////// //
// /////////// Specific access definition fetching actions ////////// //
// ////////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAccessDefinitionRequest
 * @description Action triggered anytime an access definition fetching call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const fetchAccessDefinitionRequest = () => ({ type: ActionTypes.FETCH_ACCESS_DEFINITION_REQUEST });

/**
 * @function
 * @name fetchAccessDefinitionSuccess
 * @description Action triggered as a result to a successful access definition fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} accessDefinition     The retrieved access definition.
 *
 * @returns {object}
 */
const fetchAccessDefinitionSuccess = ({ accessDefinition }) => ({
	type: ActionTypes.FETCH_ACCESS_DEFINITION_SUCCESS,
	payload: { accessDefinition },
});

/**
 * @function
 * @name fetchAccessDefinitionFailure
 * @description Action triggered as a result to a failed access definition fetching API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAccessDefinitionFailure = (error) => ({
	type: ActionTypes.FETCH_ACCESS_DEFINITION_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////////// //
// //////////////// Access definition creation actions ////////////// //
// ////////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name addAccessDefinitionRequest
 * @description Action triggered anytime an access definition creation call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const addAccessDefinitionRequest = () => ({ type: ActionTypes.ADD_ACCESS_DEFINITION_REQUEST });

/**
 * @function
 * @name addAccessDefinitionSuccess
 * @description Action triggered as a result to a successful access definition creation API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} accessDefinition     The created access definition object.
 *
 * @returns {object}
 */
const addAccessDefinitionSuccess = ({ accessDefinition }) => ({
	type: ActionTypes.ADD_ACCESS_DEFINITION_SUCCESS,
	payload: { accessDefinition },
});

/**
 * @function
 * @name addAccessDefinitionFailure
 * @description Action triggered as a result to a failed access definition creation API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const addAccessDefinitionFailure = (error) => ({
	type: ActionTypes.ADD_ACCESS_DEFINITION_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////////// //
// ///////////////// Access definition update actions /////////////// //
// ////////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateAccessDefinitionRequest
 * @description Action triggered anytime an access definition update call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const updateAccessDefinitionRequest = () => ({ type: ActionTypes.UPDATE_ACCESS_DEFINITION_REQUEST });

/**
 * @function
 * @name updateAccessDefinitionSuccess
 * @description Action triggered as a result to a successful access definition update API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} accessDefinition     The updated access definition object.
 *
 * @returns {object}
 */
const updateAccessDefinitionSuccess = ({ accessDefinition }) => ({
	type: ActionTypes.UPDATE_ACCESS_DEFINITION_SUCCESS,
	payload: { accessDefinition },
});

/**
 * @function
 * @name updateAccessDefinitionFailure
 * @description Action triggered as a result to a failed access definition update API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const updateAccessDefinitionFailure = (error) => ({
	type: ActionTypes.UPDATE_ACCESS_DEFINITION_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////////////// //
// //////////////// Access definition removal actions /////////////// //
// ////////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeAccessDefinitionRequest
 * @description Action triggered anytime an access definition deletion call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const removeAccessDefinitionRequest = () => ({ type: ActionTypes.REMOVE_ACCESS_DEFINITION_REQUEST });

/**
 * @function
 * @name removeAccessDefinitionSuccess
 * @description Action triggered as a result to a successful access definition deletion API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} deletedAccessDefinitionId    The removed access definition object.
 *
 * @returns {object}
 */
const removeAccessDefinitionSuccess = ({ deletedAccessDefinitionId }) => ({
	type: ActionTypes.REMOVE_ACCESS_DEFINITION_SUCCESS,
	payload: { deletedAccessDefinitionId },
});

/**
 * @function
 * @name removeAccessDefinitionFailure
 * @description Action triggered as a result to a failed access definition deletion API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error    The exception sent back from the API.
 *
 * @returns {object}
 */
const removeAccessDefinitionFailure = (error) => ({
	type: ActionTypes.REMOVE_ACCESS_DEFINITION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAccessDefinitionList
 * @description Method used to update the access definition list.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} params   The parameters used to match user filters.
 */
export const fetchAccessDefinitionList = (params) => (dispatch) => {
	dispatch(fetchAccessDefinitionListRequest());

	return AccessDefinitionsApi.fetchAccessDefinitions(params)
		.then(({ accessDefinitions, totalCount }) => dispatch(fetchAccessDefinitionListSuccess({ accessDefinitions, totalCount })))
		.catch((error) => dispatch(fetchAccessDefinitionListFailure(error)));
};

/**
 * @function
 * @name fetchAccessDefinition
 * @description Method used to fetch the latest version of a specific access definition.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {string} accessDefinitionId   The id of the access definition we want to retrieve.
 */
export const fetchAccessDefinition = (accessDefinitionId) => (dispatch) => {
	dispatch(fetchAccessDefinitionRequest());

	return AccessDefinitionsApi.fetchAccessDefinitionById(accessDefinitionId)
		.then(({ accessDefinition }) => dispatch(fetchAccessDefinitionSuccess({ accessDefinition })))
		.catch((error) => dispatch(fetchAccessDefinitionFailure(error)));
};

/**
 * @function
 * @name addAccessDefinition
 * @description Method used to add a new access definition instance to the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} accessDefinitionData     The data to create the new access definition from.
 * @param {string} [onSuccessRoute]         The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addAccessDefinition = (accessDefinitionData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addAccessDefinitionRequest());

	return AccessDefinitionsApi.createAccessDefinition(accessDefinitionData)
		.then(({ accessDefinition }) => {
			toast.success(i18next.t('access_definition.creation.toasts.success', { name: `${accessDefinition.name}` }));
			dispatch(addAccessDefinitionSuccess({ accessDefinition }));
			redirectOnSuccess(onSuccessRoute);

			return accessDefinition;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('access_definition.creation.toasts.conflict'));
				dispatch(addAccessDefinitionFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('access_definition.creation.toasts.error'));
				dispatch(addAccessDefinitionFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateAccessDefinition
 * @description Method used to update an existing access definition instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} accessDefinitionData     The object to update the access definition with.
 * @param {string} accessDefinitionId       The id of the access definition we want to update.
 * @param {string} [onSuccessRoute]         The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateAccessDefinition = (accessDefinitionData, accessDefinitionId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateAccessDefinitionRequest());

	return AccessDefinitionsApi.updateAccessDefinition(accessDefinitionData, accessDefinitionId)
		.then(({ accessDefinition }) => {
			dispatch(updateAccessDefinitionSuccess({ accessDefinition }));
			toast.success(i18next.t('access_definition.edition.toasts.success', { name: `${accessDefinition.name}` }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('access_definition.edition.toasts.conflict'));
				dispatch(updateAccessDefinitionFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('access_definition.edition.toasts.error'));
				dispatch(updateAccessDefinitionFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeAccessDefinition
 * @description Method used to remove an existing access definition instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} accessDefinition			The access definition we want to remove from the database.
 * @param {string} accessDefinition.id		The id of the access definition we want to remove.
 * @param {string} accessDefinition.name	The name of the access definition we want to remove from the database (used in the success toast).
 */
export const removeAccessDefinition = ({ id, name }) => (dispatch) => {
	dispatch(removeAccessDefinitionRequest());

	return AccessDefinitionsApi.deleteAccessDefinition(id)
		.then(({ deletedAccessDefinitionId }) => {
			dispatch(removeAccessDefinitionSuccess({ deletedAccessDefinitionId }));
			toast.success(i18next.t('access_definition.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeAccessDefinitionFailure(error));
			toast.error(i18next.t('access_definition.deletion.toasts.error'));
		});
};
