import { DefaultTableFields } from '../../constants/DefaultTableContent';
import TableEntityTypes from '../../constants/TableEntityTypes';
import {
	ExpenseRowsTableFields,
	ExpenseTotalsTableFields,
	InvoiceAdvancementStatusTableFields,
	InvoiceAdvancementTableFields,
	InvoiceTotalsTableFields,
	InvoiceUnitaryTableFields,
	QuotationArchitectColumnFields,
	QuotationArchitectTableFields,
	QuotationStandardTableFields,
	QuotationTotalsTableFields,
} from '../../constants/TableFields';

/**
 * @function
 * @name getTableHeaders
 * @description Resolves and returns the headers associated to the provided element
 *
 * @author Florian Fornazaric
 *
 * @param {object} element  The element to resolve the type for
 *
 * @returns {object} The element type associated with the provided element
 */
const getTableHeaders = (element) => {
	switch (element.content?.data.type) {
		case TableEntityTypes.EXPENSE_ROWS:
			return ExpenseRowsTableFields;
		case TableEntityTypes.EXPENSE_TOTALS:
			return ExpenseTotalsTableFields;
		case TableEntityTypes.QUOTATION_ARCHITECT:
			return QuotationArchitectTableFields;
		case TableEntityTypes.QUOTATION_STANDARD:
			return QuotationStandardTableFields;
		case TableEntityTypes.QUOTATION_COLUMNS:
			return QuotationArchitectColumnFields;
		case TableEntityTypes.QUOTATION_TOTALS:
			return QuotationTotalsTableFields;
		case TableEntityTypes.INVOICE_ADVANCEMENT:
			return InvoiceAdvancementTableFields;
		case TableEntityTypes.INVOICE_UNITARY:
			return InvoiceUnitaryTableFields;
		case TableEntityTypes.INVOICE_TOTALS:
			return InvoiceTotalsTableFields;
		case TableEntityTypes.DEFAULT:
			return DefaultTableFields;
		case TableEntityTypes.INVOICE_ADVANCEMENT_STATUS:
			return InvoiceAdvancementStatusTableFields;
		default:
			return [];
	}
};

export default getTableHeaders;
