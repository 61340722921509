import moment from 'moment';

/**
 * @function
 * @name validateDate
 * @description A validation method used to check if the field value is a valid date.
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {string} pattern	The date pattern used to validate the current date.
 * @param {string} message	The message to return if the validation fails.
 */
export const validateDate = (pattern, message) => (value) => (moment(value, pattern, true).isValid() ? '' : message);

/**
 * @function
 * @name minDate
 * @description A validation method used to check if the date inside the field value is after the minimum allowed date
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {string} minimumDate 	The minimum date allowed in the input field the ISO 8601 format
 * @param {string} pattern		The date pattern used to validate the current date.
 * @param {string} message		The message to return if the validation fails.
 */
export const minDate = (minimumDate, pattern, message) => (value) => {
	const date = moment(value, pattern, true);

	if (!date.isValid()) {
		return '';
	}

	return date >= moment(minimumDate) ? '' : message;
};

/**
 * @function
 * @name maxDate
 * @description A validation method used to check if the date inside the field value is before the maximum allowed date
 *
 * @author Florian Fornazaric
 * @author Yann Hodiesne
 *
 * @param {string} maximumDate 	The maximum date allowed in the input field using the ISO 8601 format
 * @param {string} pattern		The date pattern used to validate the current date.
 * @param {string} message		The message to return if the validation fails.
 */
export const maxDate = (maximumDate, pattern, message) => (value) => {
	const date = moment(value, pattern, true);

	if (!date.isValid()) {
		return '';
	}

	return date <= moment(maximumDate) ? '' : message;
};

/**
 * @function
 * @name isDate
 * @description A validation method used to check if the date inside the field value is indeed a proper date
 *
 * @author Romaric Barthe
 *
 * @param {string} pattern		The date pattern used to validate the current date.
 * @param {string} message		The message to return if the validation fails.
 */
export const isDate = (pattern, message) => (value) => {
	if (value === undefined) {
		return '';
	}

	const date = moment(value, pattern, true);

	if (!date.isValid()) {
		return message;
	}

	return '';
};
