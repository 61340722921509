import { useTranslation } from 'react-i18next';
import { ExpenseCreationTypes } from 'constants/expenseEnums';
import PropTypes from 'prop-types';

import { HiddenInput, Select, TextArea, TextInput } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

/**
 * @name ExpenseHeader
 * @description A form used to edit an existing expense's information.
 *
 * @author Romaric Barthe
 *
 * @param {array}	companies					The list of the group's companies.
 * @param {array}	currencies					The list of the currencies.
 * @param {string}	[creationType=undefined]	If set, the type of creation.
 * @param {bool}	isLoading					Whether data are still loading.
 * @param {bool}	readOnly					Is the expense to be in read only or not.
 * @param {string}	[sourceExpenseId=undefined]	If set, the id of the expense to be reversed.
 * @param {object}	userCreationModal			The modal to create a user on the fly.
 * @param {func}	setCurrency					The method to set the expense currency.
 */
const ExpenseHeader = ({
	companies,
	currencies,
	creationType,
	isLoading,
	readOnly,
	sourceExpenseId,
	userCreationModal,
	users,
	// functions
	setCurrency,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<HiddenInput
				name="creationType"
				value={creationType}
			/>
			<HiddenInput
				name="reversedExpense"
				value={creationType === ExpenseCreationTypes.REVERT ? sourceExpenseId : ''}
			/>
			<Select
				label={t('expense.edition.inputs.user.label')}
				labelKey="username"
				name="user"
				options={users}
				rules={{
					required: Validators.isRequired(t('expense.edition.inputs.user.validation_errors.required')),
				}}
				valueKey="id"
				isLoading={isLoading}
				readOnly={readOnly}
				{...userCreationModal}
			/>
			<TextArea
				label={t('expense.edition.inputs.comment.label')}
				name="comment"
				type="text"
				readOnly={readOnly}
			/>
			<Select
				label={t('expense.edition.inputs.currency.label')}
				labelKey="name"
				name="currency"
				options={currencies}
				onChange={setCurrency}
				rules={{
					required: Validators.isRequired(t('expense.edition.inputs.currency.validation_errors.required')),
				}}
				valueKey="id"
				isLoading={isLoading}
				readOnly={readOnly}
			/>
			<Select
				label={t('expense.edition.inputs.structure.label')}
				name="structure"
				options={companies}
				labelKey="name"
				valueKey="id"
				isLoading={isLoading}
				rules={{
					required: Validators.isRequired(t('expense.edition.inputs.structure.validation_errors.required')),
				}}
				readOnly={readOnly}
			/>
			<TextInput
				name="reference"
				label={t('expense.edition.inputs.reference.label')}
				readOnly={readOnly}
			/>
		</>
	);
};

ExpenseHeader.propTypes = {
	companies: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	creationType: PropTypes.oneOf(
		Object.values(ExpenseCreationTypes)
	),
	currencies: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	).isRequired,
	isLoading: PropTypes.bool,
	readOnly: PropTypes.bool,
	sourceExpenseId: PropTypes.string,
	userCreationModal: PropTypes.object.isRequired,
	users: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			username: PropTypes.string.isRequired,
		}),
	).isRequired,
	setCurrency: PropTypes.func.isRequired,
};

ExpenseHeader.defaultProps = {
	creationType: undefined,
	isLoading: true,
	readOnly: false,
	sourceExpenseId: '',
};

export default ExpenseHeader;
