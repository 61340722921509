import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Environments } from 'constants/environmentEnums';
import { InvoiceCreationTypes } from 'constants/invoiceEnums';
import { formatInvoiceNameList } from 'lib/invoices/formatInvoiceData';
import { formatQuotationNameList } from 'lib/quotations/formatQuotationData';
import PropTypes from 'prop-types';
import { fetchInvoicesSelectList } from 'redux/actions/invoices/invoicesSelect';
import { fetchNumberFormatList } from 'redux/actions/numberFormats';
import { fetchQuotationsSelectList } from 'redux/actions/quotations/quotationsSelect';
import { useInvoiceLoadingSelector } from 'redux/selectors/invoices/invoices';
import { useInvoicesSelectListSelector } from 'redux/selectors/invoices/invoicesSelect';
import { useNumberFormatListSelector } from 'redux/selectors/numberFormats';
import { useQuotationLoadingSelector } from 'redux/selectors/quotations/quotations';
import { useQuotationsSelectListSelector } from 'redux/selectors/quotations/quotationsSelect';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { Select } from 'components/shared/forms/inputs';
import Validators from 'components/shared/forms/validators';

import { getInvoiceCreationTypes } from './functions';

/**
 * @name InvoicePreCreationForm
 * @description A form used to create a new invoice.
 *
 * @author Romaric Barthe
 *
 * @param {func}	onSubmit	The method to trigger upon form submission.
 */
const InvoicePreCreationForm = ({ onSubmit }) => {
	const [invoiceCreationTypeSelected, setInvoiceCreationTypeSelected] = useState(null);

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const numberFormatQuotation = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.QUOTATION)[0];
	const numberFormatInvoice = useNumberFormatListSelector().filter((elem) => elem.environment === Environments.INVOICE)[0];
	const invoicesList = useInvoicesSelectListSelector();
	const quotationsList = useQuotationsSelectListSelector();
	const invoices = useMemo(() => invoicesList.map((invoice) => ({
		...invoice,
		fullname: formatInvoiceNameList(invoice, numberFormatInvoice, t),
	})).sort((a, b) => a.updatedAt < b.updatedAt), [invoicesList, numberFormatInvoice, t]);
	const quotations = useMemo(() => quotationsList.map((quotation) => ({
		...quotation,
		fullname: formatQuotationNameList(quotation, numberFormatQuotation),
	})).sort((a, b) => a.updatedAt < b.updatedAt).sort((a, b) => a.favorite < b.favorite), [numberFormatQuotation, quotationsList]);
	const areInvoicesLoading = useInvoiceLoadingSelector();
	const areQuotationsLoading = useQuotationLoadingSelector();

	useEffect(() => {
		dispatch(fetchInvoicesSelectList({ rowsPerPage: 0 }));
		dispatch(fetchNumberFormatList());
		dispatch(fetchQuotationsSelectList({ rowsPerPage: 0 }));
	}, [dispatch]);

	const invoiceCreationTypesList = useMemo(() => getInvoiceCreationTypes().map(
		(invoiceCreationType) => ({ id: invoiceCreationType.id, label: t(invoiceCreationType.label) })
	), [t]);

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm
			onSubmit={onSubmit}
			{...formProps}
		>
			<Select
				label={t('invoice.creation.creation_type.label')}
				labelKey="label"
				name="creationType"
				onChange={setInvoiceCreationTypeSelected}
				options={invoiceCreationTypesList}
				rules={{
					required: Validators.isRequired(t('invoice.creation.creation_type.validation_errors.required')),
				}}
				valueKey="id"
			/>
			{(invoiceCreationTypeSelected === InvoiceCreationTypes.FROM_INVOICE || invoiceCreationTypeSelected === InvoiceCreationTypes.REVERT) && (
				<Select
					label={t('invoice.creation.creation_type.types.invoice.label')}
					labelKey="fullname"
					name="sourceInvoiceId"
					options={invoices}
					rules={{
						required: Validators.isRequired(t('invoice.creation.creation_type.types.invoice.validation_errors.required')),
					}}
					valueKey="id"
					isLoading={areInvoicesLoading}
				/>
			)}
			{(invoiceCreationTypeSelected === InvoiceCreationTypes.FROM_QUOTATION) && (
				<Select
					label={t('invoice.creation.creation_type.types.quotation.label')}
					labelKey="fullname"
					name="sourceQuotationId"
					options={quotations}
					rules={{
						required: Validators.isRequired(t('invoice.creation.creation_type.types.quotation.validation_errors.required')),
					}}
					valueKey="id"
					isLoading={areQuotationsLoading}
				/>
			)}

			<Button className="primary" type="submit" {...buttonProps}>{t('invoice.precreation.action')}</Button>
		</DynamicForm>
	);
};

InvoicePreCreationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default InvoicePreCreationForm;
