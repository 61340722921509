import { toast } from 'react-toastify';
import * as PartnersApi from 'api/partnersApi';
import download from 'downloadjs';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Partners redux state
 * @type {object}
 *
 * @author Timothée Simon-Franza
 */
export const ActionTypes = {
	// Fetch a specific partner
	FETCH_PARTNER_REQUEST: '@PARTNERS/FETCH_REQUEST',
	FETCH_PARTNER_SUCCESS: '@PARTNERS/FETCH_SUCCESS',
	FETCH_PARTNER_FAILURE: '@PARTNERS/FETCH_FAILURE',

	// Fetch all for partners
	FETCH_ALL_FOR_PARTNER_REQUEST: '@PARTNERS/FETCH_ALL_FOR_REQUEST',
	FETCH_ALL_FOR_PARTNER_SUCCESS: '@PARTNERS/FETCH_ALL_FOR_SUCCESS',
	FETCH_ALL_FOR_PARTNER_FAILURE: '@PARTNERS/FETCH_ALL_FOR_FAILURE',

	// Fetch a list of partners
	FETCH_PARTNER_LIST_REQUEST: '@PARTNERS/FETCH_LIST_REQUEST',
	FETCH_PARTNER_LIST_SUCCESS: '@PARTNERS/FETCH_LIST_SUCCESS',
	FETCH_PARTNER_LIST_FAILURE: '@PARTNERS/FETCH_LIST_FAILURE',

	// Add a new partner to the database
	ADD_PARTNER_REQUEST: '@PARTNERS/ADD_REQUEST',
	ADD_PARTNER_SUCCESS: '@PARTNERS/ADD_SUCCESS',
	ADD_PARTNER_FAILURE: '@PARTNERS/ADD_FAILURE',

	// Update an existing partner from the database
	UPDATE_PARTNER_REQUEST: '@PARTNERS/UPDATE_REQUEST',
	UPDATE_PARTNER_SUCCESS: '@PARTNERS/UPDATE_SUCCESS',
	UPDATE_PARTNER_FAILURE: '@PARTNERS/UPDATE_FAILURE',

	// Remove a partner from the database
	REMOVE_PARTNER_REQUEST: '@PARTNERS/REMOVE_REQUEST',
	REMOVE_PARTNER_SUCCESS: '@PARTNERS/REMOVE_SUCCESS',
	REMOVE_PARTNER_FAILURE: '@PARTNERS/REMOVE_FAILURE',

	// Export partners as a file
	EXPORT_PARTNER_REQUEST: '@PARTNERS/EXPORT_REQUEST',
	EXPORT_PARTNER_SUCCESS: '@PARTNERS/EXPORT_SUCCESS',
	EXPORT_PARTNER_FAILURE: '@PARTNERS/EXPORT_FAILURE',
};

// //////////////////////////////////////////////////////// //
// /////////// Specific partner fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnerRequest
 * @description Action triggered anytime a partner fetching call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const fetchPartnerRequest = () => ({ type: ActionTypes.FETCH_PARTNER_REQUEST });

/**
 * @function
 * @name fetchPartnerSuccess
 * @description Action triggered as a result to a successful partner fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} partner	The retrieved partner object.
 *
 * @returns {object}
 */
const fetchPartnerSuccess = ({ partner }) => ({
	type: ActionTypes.FETCH_PARTNER_SUCCESS,
	payload: { partner },
});

/**
 * @function
 * @name fetchPartnerFailure
 * @description Action triggered as a result to a failed partner fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchPartnerFailure = (error) => ({
	type: ActionTypes.FETCH_PARTNER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Fetching all for partners actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchAllForPartnerRequest
 * @description Action triggered anytime an partner fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchAllForPartnerRequest = () => ({ type: ActionTypes.FETCH_ALL_FOR_PARTNER_REQUEST });

/**
 * @function
 * @name fetchAllForPartnerSuccess
 * @description Action triggered as a result to a successful partner fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} allForForm The retrieved partner.
 *
 * @returns {object}
 */
const fetchAllForPartnerSuccess = ({ allForForm }) => ({
	type: ActionTypes.FETCH_ALL_FOR_PARTNER_SUCCESS,
	payload: { allForForm },
});

/**
 * @function
 * @name fetchAllForPartnerFailure
 * @description Action triggered as a result to a failed partner fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchAllForPartnerFailure = (error) => ({
	type: ActionTypes.FETCH_ALL_FOR_PARTNER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////// Partner list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnerListRequest
 * @description Action triggered anytime a partner list fetching call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const fetchPartnerListRequest = () => ({ type: ActionTypes.FETCH_PARTNER_LIST_REQUEST });

/**
 * @function
 * @name fetchPartnerListSuccess
 * @description Action triggered as a result to a successful partner list fetching API call.
 *
 * @param {object}	partners	The list of retrieved partners.
 * @param {number}	totalCount	The total amount of partners available in the database to the current user.
 *
 * @returns {object}
 */
const fetchPartnerListSuccess = ({ partners, totalCount }) => ({
	type: ActionTypes.FETCH_PARTNER_LIST_SUCCESS,
	payload: { partners, totalCount },
});

/**
 * @function
 * @name fetchPartnerListFailure
 * @description Action triggered as a result to a failed partner list fetching API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchPartnerListFailure = (error) => ({
	type: ActionTypes.FETCH_PARTNER_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Partner creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addPartnerRequest
 * @description Action triggered anytime a partner creation call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const addPartnerRequest = () => ({ type: ActionTypes.ADD_PARTNER_REQUEST });

/**
 * @function
 * @name addPartnerSuccess
 * @description Action triggered as a result to a successful partner creation API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} partner	The created partner object.
 *
 * @returns {object}
 */
const addPartnerSuccess = ({ partner }) => ({
	type: ActionTypes.ADD_PARTNER_SUCCESS,
	payload: { partner },
});

/**
 * @function
 * @name addPartnerFailure
 * @description Action triggered as a result to a failed partner creation API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {error} error		The exception sent back from the API.
 *
 * @returns {object}
 */
const addPartnerFailure = (error) => ({
	type: ActionTypes.ADD_PARTNER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Partner update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updatePartnerRequest
 * @description Action triggered anytime a partner update call is made to the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {object}
 */
const updatePartnerRequest = () => ({ type: ActionTypes.UPDATE_PARTNER_REQUEST });

/**
 * @function
 * @name updatePartnerSuccess
 * @description Action triggered as a result to a successful partner update API call.
 *
 * @param {object} partner	The updated partner object
 *
 * @returns {object}
 */
const updatePartnerSuccess = ({ partner }) => ({
	type: ActionTypes.UPDATE_PARTNER_SUCCESS,
	payload: { partner },
});

/**
 * @function
 * @name updatePartnerFailure
 * @description Action triggered as a result to a failed partner update API call.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updatePartnerFailure = (error) => ({
	type: ActionTypes.UPDATE_PARTNER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Partner removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removePartnerRequest
 * @description Action triggered anytime a partner deletion call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const removePartnerRequest = () => ({ type: ActionTypes.REMOVE_PARTNER_REQUEST });

/**
 * @function
 * @name removePartnerSuccess
 * @description Action triggered as a result to a successful partner deletion API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} partner	The removed partner object.
 *
 * @returns {object}
 */
const removePartnerSuccess = ({ deletedPartnerId }) => ({
	type: ActionTypes.REMOVE_PARTNER_SUCCESS,
	payload: { deletedPartnerId },
});

/**
 * @function
 * @name removePartnerFailure
 * @description Action triggered as a result to a failed partner deletion API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removePartnerFailure = (error) => ({
	type: ActionTypes.REMOVE_PARTNER_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Partner exporting actions /////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name exportPartnerRequest
 * @description Action triggered anytime a partner exporting call is made to the API.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const exportPartnerRequest = () => ({ type: ActionTypes.EXPORT_PARTNER_REQUEST });

/**
 * @function
 * @name exportPartnerSuccess
 * @description Action triggered as a result to a successful partner exporting API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {object}
 */
const exportPartnerSuccess = () => ({ type: ActionTypes.EXPORT_PARTNER_SUCCESS });

/**
 * @function
 * @name exportPartnerFailure
 * @description Action triggered as a result to a failed partner exporting API call.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const exportPartnerFailure = (error) => ({
	type: ActionTypes.EXPORT_PARTNER_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnerList
 * @description Method used to update the partners list.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchPartnerList = (filters) => (dispatch) => {
	dispatch(fetchPartnerListRequest());

	return PartnersApi.fetchPartners(filters)
		.then(({ partners, totalCount }) => dispatch(fetchPartnerListSuccess({ partners, totalCount })))
		.catch((error) => dispatch(fetchPartnerListFailure(error)));
};

/**
 * @function
 * @name fetchPartner
 * @description Method used to fetch the latest version of a specific partner.
 *
 * @author Timothée Simon-Franza
 *
 * @param {string} partnerId	The id of the partner we want to retrieve.
 */
export const fetchPartner = (partnerId) => (dispatch) => {
	dispatch(fetchPartnerRequest());

	return PartnersApi.fetchPartnerById(partnerId)
		.then(({ partner }) => dispatch(fetchPartnerSuccess({ partner })))
		.catch((error) => dispatch(fetchPartnerFailure(error)));
};

/**
 * @function
 * @name fetchAllForPartner
 * @description Method used to load the partner "all for" information.
 *
 * @author Romaric Barthe
 *
 * @param {bool|null} inSuperAdmin	Wether we are in superadmin environment.
 */
export const fetchAllForPartner = (inSuperAdmin) => (dispatch) => {
	dispatch(fetchAllForPartnerRequest());

	return PartnersApi.fetchAllForPartnerForm({ inSuperAdmin })
		.then((allForForm) => dispatch(fetchAllForPartnerSuccess({ allForForm })))
		.catch((error) => dispatch(fetchAllForPartnerFailure(error)));
};

/**
 * @function
 * @name addPartner
 * @description Method used to add a new partner instance to the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} partnerData		The data to create the new partner from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addPartner = (partnerData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addPartnerRequest());

	return PartnersApi.createPartner(partnerData)
		.then(({ partner }) => {
			toast.success(i18next.t('partner.creation.toasts.success', { name: partner.name }));
			dispatch(addPartnerSuccess({ partner }));
			redirectOnSuccess(onSuccessRoute);

			return partner;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('partner.creation.toasts.conflict'));
				dispatch(addPartnerFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('partner.creation.toasts.error'));
				dispatch(addPartnerFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updatePartner
 * @description Method used to update an existing partner instance from the database.
 *
 * @author Timothée Simon-Franza
 *
 * @param {object} partnerData		Data to update the partner with.
 * @param {string} partnerId		Id of the partner instance we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updatePartner = (partnerData, partnerId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updatePartnerRequest());

	return PartnersApi.updatePartner(partnerData, partnerId)
		.then(({ partner }) => {
			dispatch(updatePartnerSuccess({ partner }));
			toast.success(i18next.t('partner.edition.toasts.success', { name: partner.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('partner.edition.toasts.conflict'));
				dispatch(updatePartnerFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('partner.edition.toasts.error'));
				dispatch(updatePartnerFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removePartner
 * @description Method used to remove an existing partner instance from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object} partner		The partner we want to remove from the database.
 * @param {string} partner.id	The id of the partner we want to remove from the database.
 * @param {string} partner.name	The name of the partner we want to remove from the database.
*/
export const removePartner = ({ id, name }) => (dispatch) => {
	dispatch(removePartnerRequest());

	return PartnersApi.deletePartner(id)
		.then(({ deletedPartnerId }) => {
			dispatch(removePartnerSuccess({ deletedPartnerId }));
			toast.success(i18next.t('partner.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removePartnerFailure(error));
			toast.error(i18next.t('partner.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name exportPartners
 * @description Method used to export multiple partner instances from the database.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {Object}	data				The data to export the partners.
 * @param {Array}	data.partnerIds		The ids of the partners to export.
 * @param {string}	data.format			The file format.
 * @param {string}	data.restriction	The scope of the data to export (all, filtered or current selection).
 * @param {string}	data.separator		The separator used in the CSV file.
 *
 * @todo Adapt params when backend will change
 */
export const exportPartners = (data) => (dispatch) => {
	dispatch(exportPartnerRequest());

	// TODO: adapt these parameters along with backend changes
	return PartnersApi.exportPartners({
		ids: data.partnerIds ?? [],
		format: data.format ?? 'xlsx',
		restriction: data.restriction ?? 'all',
		separator: data.separator ?? 'comma',
		...data,
	})
		.then(({ blob, fileName, fileType }) => {
			dispatch(exportPartnerSuccess());
			toast.success(i18next.t('template.export.toasts.success'));

			download(blob, fileName, fileType);
		})
		.catch((error) => {
			dispatch(exportPartnerFailure(error));
			toast.error(i18next.t('template.export.toasts.error'));
		});
};
