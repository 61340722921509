import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentSectorSelector
 * @description A selector to retrieve the current sector from the sector redux state
 *
 * @author Audrey Clerc
 *
 * @param {string} id The identifier of the current sector to wait for
 *
 * @returns {object}
 */
const useCurrentSectorSelector = (id) => {
	const sector = useSelector((state) => (state.sectors?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (sector?.id === id ? sector : null), [id, sector]);

	return result;
};

/**
 * @function
 * @name useSectorsLoadingSelector
 * @description A selector to retrieve the loading state from the sector redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {boolean}
 */
const useSectorsLoadingSelector = () => {
	const loading = useSelector((state) => state.sectors?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useSectorListSelector
 * @description A selector to retrieve the sector list from the sector redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {Array}
 */
const useSectorListSelector = () => {
	const sectors = useSelector((state) => (state.sectors?.items ?? []), shallowEqual);

	return sectors;
};

/**
 * @function
 * @name useSectorTotalCountSelector
 * @description A selector to retrieve the totalCount value from the sector redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {number|undefined}
 */
const useSectorTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.sectors?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useSectorActiveListSelector
 * @description A selector to retrieve all non-archived sectors from the sector redux state.
 *
 * @author Yann Hodiesne
 *
 * @returns {Array}
 */
const useSectorActiveListSelector = () => {
	const sectors = useSectorListSelector();

	const result = useMemo(() => sectors.filter(({ archived }) => !archived), [sectors]);

	return result;
};

export {
	useCurrentSectorSelector,
	useSectorActiveListSelector,
	useSectorListSelector,
	useSectorsLoadingSelector,
	useSectorTotalCountSelector,
};
