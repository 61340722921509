import * as ProjectsApi from 'api/projectsApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Projects redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of projects
	FETCH_PROJECT_SELECT_LIST_REQUEST: '@PROJECTS_SELECT/FETCH_LIST_REQUEST',
	FETCH_PROJECT_SELECT_LIST_SUCCESS: '@PROJECTS_SELECT/FETCH_LIST_SUCCESS',
	FETCH_PROJECT_SELECT_LIST_FAILURE: '@PROJECTS_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Project list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchProjectsSelectListRequest
 * @description Action triggered anytime a projectsSelect list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchProjectsSelectListRequest = () => ({ type: ActionTypes.FETCH_PROJECT_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchProjectsSelectListSuccess
 * @description Action triggered as a result to a successful projectsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	projectsSelect	The list of retrieved projects.
 * @param {number}	totalCount		The total amount of projects available in the database to the current user.
 *
 * @returns {object}
 */
const fetchProjectsSelectListSuccess = ({ projectsSelect, totalCount }) => ({
	type: ActionTypes.FETCH_PROJECT_SELECT_LIST_SUCCESS,
	payload: { projectsSelect, totalCount },
});

/**
 * @function
 * @name fetchProjectsSelectListFailure
 * @description Action triggered as a result to a failed projectsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchProjectsSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_PROJECT_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchProjectsSelectList
 * @description Method used to update the projectsSelect list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchProjectsSelectList = (filters) => (dispatch) => {
	dispatch(fetchProjectsSelectListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forSelect',
	};

	return ProjectsApi.fetchProjects(newFilters)
		.then(({ projectsSelect, totalCount }) => dispatch(fetchProjectsSelectListSuccess({ projectsSelect, totalCount })))
		.catch((error) => dispatch(fetchProjectsSelectListFailure(error)));
};
