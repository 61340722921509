import { toast } from 'react-toastify';
import * as RegistrationsApi from 'api/registrationsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Registrations redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific registration
	FETCH_REGISTRATION_REQUEST: '@REGISTRATIONS/FETCH_REQUEST',
	FETCH_REGISTRATION_SUCCESS: '@REGISTRATIONS/FETCH_SUCCESS',
	FETCH_REGISTRATION_FAILURE: '@REGISTRATIONS/FETCH_FAILURE',

	// Fetch a list of registrations
	FETCH_REGISTRATION_LIST_REQUEST: '@REGISTRATIONS/FETCH_LIST_REQUEST',
	FETCH_REGISTRATION_LIST_SUCCESS: '@REGISTRATIONS/FETCH_LIST_SUCCESS',
	FETCH_REGISTRATION_LIST_FAILURE: '@REGISTRATIONS/FETCH_LIST_FAILURE',

	// Add a new registration to the database
	ADD_REGISTRATION_REQUEST: '@REGISTRATIONS/ADD_REQUEST',
	ADD_REGISTRATION_SUCCESS: '@REGISTRATIONS/ADD_SUCCESS',
	ADD_REGISTRATION_FAILURE: '@REGISTRATIONS/ADD_FAILURE',

	// Update an existing registration from the database
	UPDATE_REGISTRATION_REQUEST: '@REGISTRATIONS/UPDATE_REQUEST',
	UPDATE_REGISTRATION_SUCCESS: '@REGISTRATIONS/UPDATE_SUCCESS',
	UPDATE_REGISTRATION_FAILURE: '@REGISTRATIONS/UPDATE_FAILURE',

	// Remove an existing registration from the database
	REMOVE_REGISTRATION_REQUEST: '@REGISTRATIONS/REMOVE_REQUEST',
	REMOVE_REGISTRATION_SUCCESS: '@REGISTRATIONS/REMOVE_SUCCESS',
	REMOVE_REGISTRATION_FAILURE: '@REGISTRATIONS/REMOVE_FAILURE',

	// Archive a registration from the database
	ARCHIVE_REGISTRATION_REQUEST: '@REGISTRATIONS/ARCHIVE_REQUEST',
	ARCHIVE_REGISTRATION_SUCCESS: '@REGISTRATIONS/ARCHIVE_SUCCESS',
	ARCHIVE_REGISTRATION_FAILURE: '@REGISTRATIONS/ARCHIVE_FAILURE',

	// Restore a registration from the database
	RESTORE_REGISTRATION_REQUEST: '@REGISTRATIONS/RESTORE_REQUEST',
	RESTORE_REGISTRATION_SUCCESS: '@REGISTRATIONS/RESTORE_SUCCESS',
	RESTORE_REGISTRATION_FAILURE: '@REGISTRATIONS/RESTORE_FAILURE',
};

// ///////////////////////////////////////////////////////////// //
// ///////////// Registration list fetching actions //////////// //
// ///////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchRegistrationListRequest
 * @description Action triggered anytime a registration list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchRegistrationListRequest = () => ({ type: ActionTypes.FETCH_REGISTRATION_LIST_REQUEST });

/**
 * @function
 * @name fetchRegistrationListSuccess
 * @description Action triggered as a result to a successful registration list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} registrations		The list of retrieved registrations.
 * @param {number} totalCount	The total amount of registrations available in the database for the current user.
 *
 * @returns {object}
 */
const fetchRegistrationListSuccess = ({ registrations, totalCount }) => ({
	type: ActionTypes.FETCH_REGISTRATION_LIST_SUCCESS,
	payload: { registrations, totalCount },
});

/**
 * @function
 * @name fetchRegistrationListFailure
 * @description Action triggered as a result to a failed registration list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchRegistrationListFailure = (error) => ({
	type: ActionTypes.FETCH_REGISTRATION_LIST_FAILURE,
	payload: { error },
});

// ///////////////////////////////////////////////////////////// //
// /////////// Specific registration fetching actions ////////// //
// ///////////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchRegistrationRequest
 * @description Action triggered anytime a registration fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchRegistrationRequest = () => ({ type: ActionTypes.FETCH_REGISTRATION_REQUEST });

/**
 * @function
 * @name fetchRegistrationSuccess
 * @description Action triggered as a result to a successful registration fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} registration	The retrieved registration.
 *
 * @returns {object}
 */
const fetchRegistrationSuccess = ({ registration }) => ({
	type: ActionTypes.FETCH_REGISTRATION_SUCCESS,
	payload: { registration },
});

/**
 * @function
 * @name fetchRegistrationFailure
 * @description Action triggered as a result to a failed registration fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchRegistrationFailure = (error) => ({
	type: ActionTypes.FETCH_REGISTRATION_FAILURE,
	payload: { error },
});

// ///////////////////////////////////////////////////////////// //
// //////////////// Registration creation actions ////////////// //
// ///////////////////////////////////////////////////////////// //

/**
 * @function
 * @name addRegistrationRequest
 * @description Action triggered anytime a registration creation call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const addRegistrationRequest = () => ({ type: ActionTypes.ADD_REGISTRATION_REQUEST });

/**
 * @function
 * @name addRegistrationSuccess
 * @description Action triggered as a result to a successful registration creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} registration	The created registration object.
 *
 * @returns {object}
 */
const addRegistrationSuccess = ({ registration }) => ({
	type: ActionTypes.ADD_REGISTRATION_SUCCESS,
	payload: { registration },
});

/**
 * @function
 * @name addRegistrationFailure
 * @description Action triggered as a result to a failed registration creation API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addRegistrationFailure = (error) => ({
	type: ActionTypes.ADD_REGISTRATION_FAILURE,
	payload: { error },
});

// ///////////////////////////////////////////////////////////// //
// ///////////////// Registration update actions /////////////// //
// ///////////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateRegistrationRequest
 * @description Action triggered anytime a registration update call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const updateRegistrationRequest = () => ({ type: ActionTypes.UPDATE_REGISTRATION_REQUEST });

/**
 * @function
 * @name updateRegistrationSuccess
 * @description Action triggered as a result to a successful registration update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} registration	The updated registration object.
 *
 * @returns {object}
 */
const updateRegistrationSuccess = ({ registration }) => ({
	type: ActionTypes.UPDATE_REGISTRATION_SUCCESS,
	payload: { registration },
});

/**
 * @function
 * @name updateRegistrationFailure
 * @description Action triggered as a result to a failed registration update API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateRegistrationFailure = (error) => ({
	type: ActionTypes.UPDATE_REGISTRATION_FAILURE,
	payload: { error },
});

// ///////////////////////////////////////////////////////////// //
// //////////////// Registration removal actions /////////////// //
// ///////////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeRegistrationRequest
 * @description Action triggered anytime a registration deletion call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const removeRegistrationRequest = () => ({ type: ActionTypes.REMOVE_REGISTRATION_REQUEST });

/**
 * @function
 * @name removeRegistrationSuccess
 * @description Action triggered as a result to a successful registration deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {string} deletedRegistrationId	The removed registration object id.
 *
 * @returns {object}
 */
const removeRegistrationSuccess = ({ deletedRegistrationId }) => ({
	type: ActionTypes.REMOVE_REGISTRATION_SUCCESS,
	payload: { deletedRegistrationId },
});

/**
 * @function
 * @name removeRegistrationFailure
 * @description Action triggered as a result to a failed registration deletion API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeRegistrationFailure = (error) => ({
	type: ActionTypes.REMOVE_REGISTRATION_FAILURE,
	payload: { error },
});

// /////////////////////////////////////////////////////////////// //
// //////////////// Registration archiving actions /////////////// //
// /////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveRegistrationRequest
 * @description Action triggered anytime a registration archiving call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveRegistrationRequest = () => ({ type: ActionTypes.ARCHIVE_REGISTRATION_REQUEST });

/**
 * @function
 * @name archiveRegistrationSuccess
 * @description Action triggered as a result to a successful registration archiving API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const archiveRegistrationSuccess = () => ({
	type: ActionTypes.ARCHIVE_REGISTRATION_SUCCESS,
});

/**
 * @function
 * @name archiveRegistrationFailure
 * @description Action triggered as a result to a failed registration archiving API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveRegistrationFailure = (error) => ({
	type: ActionTypes.ARCHIVE_REGISTRATION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////////////// //
// //////////////// Registration restoring actions //////////////// //
// //////////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreRegistrationRequest
 * @description Action triggered anytime a registration restoring call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreRegistrationRequest = () => ({ type: ActionTypes.RESTORE_REGISTRATION_REQUEST });

/**
 * @function
 * @name restoreRegistrationSuccess
 * @description Action triggered as a result to a successful registration restoring API call.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const restoreRegistrationSuccess = () => ({
	type: ActionTypes.RESTORE_REGISTRATION_SUCCESS,
});

/**
 * @function
 * @name restoreRegistrationFailure
 * @description Action triggered as a result to a failed registration restoring API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreRegistrationFailure = (error) => ({
	type: ActionTypes.RESTORE_REGISTRATION_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchRegistrationList
 * @description Method used to update the registration list.
 *
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchRegistrationList = (params) => (dispatch) => {
	dispatch(fetchRegistrationListRequest());

	return RegistrationsApi.fetchRegistrations(params)
		.then(({ registrations, totalCount }) => dispatch(fetchRegistrationListSuccess({ registrations, totalCount })))
		.catch((error) => dispatch(fetchRegistrationListFailure(error)));
};

/**
 * @function
 * @name fetchRegistration
 * @description Method used to fetch the latest version of a specific registration.
 *
 * @author Romaric Barthe
 *
 * @param {string} registrationId		The id of the registration we want to retrieve.
 */
export const fetchRegistration = (registrationId) => (dispatch) => {
	dispatch(fetchRegistrationRequest());

	return RegistrationsApi.fetchRegistrationById(registrationId)
		.then(({ registration }) => dispatch(fetchRegistrationSuccess({ registration })))
		.catch((error) => dispatch(fetchRegistrationFailure(error)));
};

/**
 * @function
 * @name addRegistration
 * @description Method used to add a new registration instance to the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} registrationData		The data to create the new registration from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addRegistration = (registrationData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addRegistrationRequest());

	return RegistrationsApi.createRegistration(registrationData)
		.then(({ registration }) => {
			toast.success(i18next.t('registration.creation.toasts.success', { name: `${registration.name}` }));
			dispatch(addRegistrationSuccess({ registration }));
			redirectOnSuccess(onSuccessRoute);

			return registration;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('registration.creation.toasts.conflict'));
				dispatch(addRegistrationFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('registration.creation.toasts.error'));
				dispatch(addRegistrationFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateRegistration
 * @description Method used to update an existing registration instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} registrationData		The object to update the registration with.
 * @param {string} registrationId			The id of the registration we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateRegistration = (registrationData, registrationId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateRegistrationRequest());

	return RegistrationsApi.updateRegistration(registrationData, registrationId)
		.then(({ registration }) => {
			dispatch(updateRegistrationSuccess({ registration }));
			toast.success(i18next.t('registration.edition.toasts.success', { name: registration.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('registration.edition.toasts.conflict'));
				dispatch(updateRegistrationFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('registration.edition.toasts.error'));
				dispatch(updateRegistrationFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeRegistration
 * @description Method used to remove an existing registration instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} registration		Tge registration we want to remove from the database.
 * @param {string} registration.id	The id of the registration we want to remove from the database.
 * @param {string} registration.name	The name of the registration we want to remove from the database.
 */
export const removeRegistration = ({ id, name }) => (dispatch) => {
	dispatch(removeRegistrationRequest());

	return RegistrationsApi.deleteRegistration(id)
		.then(({ deletedRegistrationId }) => {
			dispatch(removeRegistrationSuccess({ deletedRegistrationId }));
			toast.success(i18next.t('registration.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeRegistrationFailure(error));
			toast.error(i18next.t('registration.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveRegistration
 * @description Method used to archive an existing registration instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} registration		The registration we want to archive.
 * @param {string} registration.id	The id of the registration we want to archive.
 * @param {string} registration.name	The name of the registration we want to archive.
 */
export const archiveRegistration = ({ id, name }) => (dispatch) => {
	dispatch(archiveRegistrationRequest());

	return RegistrationsApi.updateRegistration({ archived: true }, id)
		.then(() => {
			dispatch(archiveRegistrationSuccess());
			toast.success(i18next.t('registration.archiving.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(archiveRegistrationFailure(error));
			toast.error(i18next.t('registration.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreRegistration
 * @description Method used to restore an archived registration instance from the database.
 *
 * @author Romaric Barthe
 *
 * @param {object} registration		The registration we want to restore.
 * @param {string} registration.id	The id of the registration we want to restore.
 * @param {string} registration.name	The name of the registration we want to restore.
 */
export const restoreRegistration = ({ id, name }) => (dispatch) => {
	dispatch(restoreRegistrationRequest());

	return RegistrationsApi.updateRegistration({ archived: false }, id)
		.then(() => {
			dispatch(restoreRegistrationSuccess());
			toast.success(i18next.t('registration.restoring.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(restoreRegistrationFailure(error));
			toast.error(i18next.t('registration.restoring.toasts.error'));
		});
};
