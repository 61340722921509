import { useMemo } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import StaticText from './StaticText';

const css = {
	color: 'gray',
};

/**
 * @name LoremIpsum
 * @description A readonly lorem ipsum PDF element
 *
 * @author Yann Hodiesne
 * @author Florian Fornazaric
 *
 * @param {number}	[aspectRatio = 1]	The aspect ratio of the PDF editor
 * @param {object}	style				The style of the component
 * @param {boolean}	editing				True if the element is being edited
 * @param {boolean}	resizable			True if the element is being resized
 */
const LoremIpsum = (({ aspectRatio, style, editing, resizable, ...props }) => {
	const value = useMemo(() => 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. '
	+ 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
	+ 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. '
	+ 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.', []);

	return (
		<textarea
			{...props}
			className={`seamless${editing || resizable ? ' editing' : ''}`}
			style={{ ...css, ...style }}
			value={value}
			readOnly
		/>
	);
});

LoremIpsum.propTypes = {
	aspectRatio: PropTypes.number,
	style: PropTypes.object,
	editing: PropTypes.bool,
	resizable: PropTypes.bool,
};

LoremIpsum.defaultProps = {
	aspectRatio: 1,
	style: {},
	editing: false,
	resizable: false,
};

const styles = StyleSheet.create(css);

LoremIpsum.PdfElement = ({ style, ...otherprops }) => (
	<StaticText.PdfElement style={{ ...styles, ...style }} {...otherprops}>
		Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
		Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
		Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
		Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
	</StaticText.PdfElement>
);

LoremIpsum.PdfElement.propTypes = {
	style: PropTypes.object,
};

LoremIpsum.PdfElement.defaultProps = {
	style: undefined,
};

LoremIpsum.PdfElement.displayName = 'LoremIpsum';

export default LoremIpsum;
