import { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { isDev } from 'lib/shared/environmentHelper';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { BreadcrumbsActions, updateBreadcrumbs } from 'redux/actions/globals';

import { PromptModal, useModal } from 'components/shared/modal';

/**
 * @function
 * @name initializationError
 * @description Errors out if the app requests a prompt before initializing RouterProvider
 *
 * @author Yann Hodiesne
 */
const initializationError = () => {
	throw new Error('A prompt has been triggered before RouterProvider\'s initialization');
};

/**
 * @name globals
 * @description A globals holder needed for communication from RouterProvider to its history's options
 *
 * Note: if you think this is ugly, so do I; we can blame history's developer together
 *
 * @author Yann Hodiesne
 */
const globals = {
	triggerPrompt: initializationError,
};

/**
 * @name history
 * @description The global history object, handling the navigation for the whole application
 *
 * @author Yann Hodiesne
 */
export const history = createBrowserHistory({
	// getUserConfirmation is called when history wants to display a prompt, with the given message and a callback to confirm/cancel the navigation
	getUserConfirmation: (message, callback) => {
		// This function references globals, as such it will always call the latest triggerPrompt stored inside it by RouterProvider
		globals.triggerPrompt(message, callback);
	},
});

/**
 * @name RouterProvider
 * @description A component providing a Router from react-router, with the ability to export its history and block navigation
 *
 * @author Yann Hodiesne
 *
 * @param {*}		children					 The children to render inside the Router
 * @param {func}	dispatchUpdateBreadcrumbs	 Dispatch an update to the current breadcrumbs
 * @param {func}	t							 A translation method provided by the withTranslation HoC
 */
const RouterProvider = ({ children, dispatchUpdateBreadcrumbs, t }) => {
	const { isShowing, toggle: togglePrompt } = useModal();

	// Store the text message provided by the navigation block
	const [blockMessage, setBlockMessage] = useState('');
	// Store the navigation block callback
	// This is a function returning another function, to escape React's lazy-loading of its state when we feed it with a function
	// blockCallback will effectively contain () => {}
	const [blockCallback, setBlockCallback] = useState(() => () => {});

	// Generate a function receiving the arguments needed for a navigation block
	const triggerConfirmationModal = useCallback((message, callback) => {
		// Store the message and the callback
		setBlockMessage(message);
		setBlockCallback(() => callback);
		// Toggle the prompt to display it to the user
		togglePrompt();
	}, [togglePrompt]);

	// Listen to every location changes and add the current location as a first breadcrumb
	useEffect(() => {
		const destroy = history.listen((location, action) => dispatchUpdateBreadcrumbs(action, location));

		// Attach to the browser's back button to reset the breadcrumbs
		window.onpopstate = () => dispatchUpdateBreadcrumbs(BreadcrumbsActions.RESET, { pathname: document.location.pathname });

		// Add the current location as a first breadcrumb
		// We use RESET instead of PUSH to avoid weirdness with live-reload in a development environment
		dispatchUpdateBreadcrumbs(BreadcrumbsActions.RESET, history.location);

		return destroy;
	}, [dispatchUpdateBreadcrumbs]);

	// Register the triggerPrompt function into the globals for access from the history's initialization
	useEffect(() => {
		// If we are in a development environment and triggerPrompt exists in the globals, we fucked something up
		if (isDev() && globals.triggerPrompt !== initializationError) {
			throw new Error('RouterProvider cannot be used more than once in the render tree');
		}

		// Register the new triggerPrompt inside globals
		globals.triggerPrompt = triggerConfirmationModal;

		// Unregister triggerPrompt and restore the initial value on unmounting
		return () => {
			globals.triggerPrompt = initializationError;
		};
	}, [triggerConfirmationModal]);

	// Function called when the user clicks on "yes, I want to navigate away"
	const confirm = useCallback(() => {
		// Tell history we want to navigate away
		blockCallback(true);
		// Hide the prompt
		togglePrompt();
		// Clean up the navigation block arguments
		setBlockCallback(() => () => {});
		setBlockMessage('');
	}, [blockCallback, togglePrompt]);

	// Function called when the user clicks on "no, I want to stay on this page"
	const cancel = useCallback(() => {
		// Tell history we want to stay where we are
		blockCallback(false);
		// Hide the prompt
		togglePrompt();
		// Clean up the navigation block arguments
		setBlockCallback(() => () => {});
		setBlockMessage('');
	}, [blockCallback, togglePrompt]);

	// Store the router inside a memo to avoid unnecessary rendering of the routes tree
	const router = useMemo(() => (
		<Router history={history}>
			{children}
		</Router>
	), [children]);

	return (
		<>
			{router}
			<PromptModal
				isShowing={isShowing}
				message={blockMessage}
				confirm={confirm}
				cancel={cancel}
				confirmText={t('components.block_prompt.confirm')}
				cancelText={t('components.block_prompt.cancel')}
			/>
		</>
	);
};

const mapDispatchToProps = {
	dispatchUpdateBreadcrumbs: updateBreadcrumbs,
};

RouterProvider.propTypes = {
	children: PropTypes.node.isRequired,
	dispatchUpdateBreadcrumbs: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
};

export default compose(withTranslation(), connect(null, mapDispatchToProps))(RouterProvider);
