import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentTargetSelector
 * @description A selector to retrieve the current target from the target redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the current target to wait for
 *
 * @returns {object | null}
 */
const useCurrentTargetSelector = (id) => {
	const target = useSelector((state) => (state.targets?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (target?.id === id ? target : null), [id, target]);

	return result;
};

/**
 * @function
 * @name useTargetLoadingSelector
 * @description A selector to retrieve the loading state from the target redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useTargetLoadingSelector = () => {
	const loading = useSelector((state) => state.targets?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useTargetListSelector
 * @description A selector to retrieve the target list from the target redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useTargetListSelector = () => {
	const targets = useSelector((state) => (state.targets?.items ?? []), shallowEqual);

	return targets;
};

/**
 * @function
 * @name useTargetTotalCountSelector
 * @description A selector to retrieve the totalCount value from the target redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {number|undefined}
 */
const useTargetTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.targets?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useCurrentTargetingStatsSelector
 * @description A selector to retrieve the current targeting stats from the targeting redux state
 *
 * @author Roland Margelidon
 *
 * @returns {object | null}
 */
const useCurrentTargetStatsSelector = () => {
	const targetStats = useSelector((state) => (state.targets?.currentItemStats ?? null), shallowEqual);

	const result = useMemo(() => (targetStats || null), [targetStats]);

	return result;
};

export {
	useCurrentTargetSelector,
	useCurrentTargetStatsSelector,
	useTargetListSelector,
	useTargetLoadingSelector,
	useTargetTotalCountSelector,
};
