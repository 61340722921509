import * as TargetPartnersApi from 'api/targetPartnersApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the TargetPartners redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of targetPartners
	FETCH_TARGET_PARTNER_FOR_LIST_REQUEST: '@TARGET_PARTNERS_FOR_LIST/FETCH_LIST_REQUEST',
	FETCH_TARGET_PARTNER_FOR_LIST_SUCCESS: '@TARGET_PARTNERS_FOR_LIST/FETCH_LIST_SUCCESS',
	FETCH_TARGET_PARTNER_FOR_LIST_FAILURE: '@TARGET_PARTNERS_FOR_LIST/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ////////// TargetPartner list fetching actions ///////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetPartnersForListRequest
 * @description Action triggered anytime a targetPartnersList list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchTargetPartnersForListRequest = () => ({ type: ActionTypes.FETCH_TARGET_PARTNER_FOR_LIST_REQUEST });

/**
 * @function
 * @name fetchTargetPartnersForListSuccess
 * @description Action triggered as a result to a successful targetPartnersList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	targetPartnersList	The list of retrieved targetPartners.
 * @param {number}	totalCount			The total amount of targetPartners available in the database to the current user.
 *
 * @returns {object}
 */
const fetchTargetPartnersForListSuccess = ({ targetPartnersList, totalCount }) => ({
	type: ActionTypes.FETCH_TARGET_PARTNER_FOR_LIST_SUCCESS,
	payload: { targetPartnersList, totalCount },
});

/**
 * @function
 * @name fetchTargetPartnersForListFailure
 * @description Action triggered as a result to a failed targetPartnersList list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchTargetPartnersForListFailure = (error) => ({
	type: ActionTypes.FETCH_TARGET_PARTNER_FOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchTargetPartnersForList
 * @description Method used to update the targetPartnersList list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchTargetPartnersForList = (filters) => (dispatch) => {
	dispatch(fetchTargetPartnersForListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forList',
	};

	return TargetPartnersApi.fetchTargetPartners(newFilters)
		.then(({ targetPartnersList, totalCount }) => dispatch(fetchTargetPartnersForListSuccess({ targetPartnersList, totalCount })))
		.catch((error) => dispatch(fetchTargetPartnersForListFailure(error)));
};
