import { toast } from 'react-toastify';
import * as SectorsApi from 'api/sectorsApi';
import i18next from 'i18next';
import { redirectOnSuccess } from 'lib/shared/redirectionHelper';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the Sectors redux state.
 * @type {object}
 */
export const ActionTypes = {
	// Fetch a specific sector
	FETCH_SECTOR_REQUEST: '@SECTORS/FETCH_REQUEST',
	FETCH_SECTOR_SUCCESS: '@SECTORS/FETCH_SUCCESS',
	FETCH_SECTOR_FAILURE: '@SECTORS/FETCH_FAILURE',

	// Fetch a list of sectors
	FETCH_SECTOR_LIST_REQUEST: '@SECTORS/FETCH_LIST_REQUEST',
	FETCH_SECTOR_LIST_SUCCESS: '@SECTORS/FETCH_LIST_SUCCESS',
	FETCH_SECTOR_LIST_FAILURE: '@SECTORS/FETCH_LIST_FAILURE',

	// Add a new sector to the database
	ADD_SECTOR_REQUEST: '@SECTORS/ADD_REQUEST',
	ADD_SECTOR_SUCCESS: '@SECTORS/ADD_SUCCESS',
	ADD_SECTOR_FAILURE: '@SECTORS/ADD_FAILURE',

	// Update an existing sector from the database
	UPDATE_SECTOR_REQUEST: '@SECTORS/UPDATE_REQUEST',
	UPDATE_SECTOR_SUCCESS: '@SECTORS/UPDATE_SUCCESS',
	UPDATE_SECTOR_FAILURE: '@SECTORS/UPDATE_FAILURE',

	// Remove an existing sector from the database
	REMOVE_SECTOR_REQUEST: '@SECTORS/REMOVE_REQUEST',
	REMOVE_SECTOR_SUCCESS: '@SECTORS/REMOVE_SUCCESS',
	REMOVE_SECTOR_FAILURE: '@SECTORS/REMOVE_FAILURE',

	// Archive a sector from the database
	ARCHIVE_SECTOR_REQUEST: '@SECTORS/ARCHIVE_REQUEST',
	ARCHIVE_SECTOR_SUCCESS: '@SECTORS/ARCHIVE_SUCCESS',
	ARCHIVE_SECTOR_FAILURE: '@SECTORS/ARCHIVE_FAILURE',

	// Restore a sector from the database
	RESTORE_SECTOR_REQUEST: '@SECTORS/RESTORE_REQUEST',
	RESTORE_SECTOR_SUCCESS: '@SECTORS/RESTORE_SUCCESS',
	RESTORE_SECTOR_FAILURE: '@SECTORS/RESTORE_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Sector list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchSectorListRequest
 * @description Action triggered anytime a sector list fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchSectorListRequest = () => ({ type: ActionTypes.FETCH_SECTOR_LIST_REQUEST });

/**
 * @function
 * @name fetchSectorListSuccess
 * @description Action triggered as a result to a successful sector list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} sectors		The list of retrieved sectors.
 * @param {number} totalCount	The total amount of sectors available in the database for the current user.
 *
 * @returns {object}
 */
const fetchSectorListSuccess = ({ sectors, totalCount }) => ({
	type: ActionTypes.FETCH_SECTOR_LIST_SUCCESS,
	payload: { sectors, totalCount },
});

/**
 * @function
 * @name fetchSectorListFailure
 * @description Action triggered as a result to a failed sector list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchSectorListFailure = (error) => ({
	type: ActionTypes.FETCH_SECTOR_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// /////////// Specific sector fetching actions ////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchSectorRequest
 * @description Action triggered anytime a sector fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchSectorRequest = () => ({ type: ActionTypes.FETCH_SECTOR_REQUEST });

/**
 * @function
 * @name fetchSectorSuccess
 * @description Action triggered as a result to a successful sector fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} sector	The retrieved sector.
 *
 * @returns {object}
 */
const fetchSectorSuccess = ({ sector }) => ({
	type: ActionTypes.FETCH_SECTOR_SUCCESS,
	payload: { sector },
});

/**
 * @function
 * @name fetchSectorFailure
 * @description Action triggered as a result to a failed sector fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchSectorFailure = (error) => ({
	type: ActionTypes.FETCH_SECTOR_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Sector creation actions ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name addSectorRequest
 * @description Action triggered anytime a sector creation call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const addSectorRequest = () => ({ type: ActionTypes.ADD_SECTOR_REQUEST });

/**
 * @function
 * @name addSectorSuccess
 * @description Action triggered as a result to a successful sector creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} sector	The created sector object.
 *
 * @returns {object}
 */
const addSectorSuccess = ({ sector }) => ({
	type: ActionTypes.ADD_SECTOR_SUCCESS,
	payload: { sector },
});

/**
 * @function
 * @name addSectorFailure
 * @description Action triggered as a result to a failed sector creation API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const addSectorFailure = (error) => ({
	type: ActionTypes.ADD_SECTOR_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// ///////////////// Sector update actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name updateSectorRequest
 * @description Action triggered anytime a sector update call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const updateSectorRequest = () => ({ type: ActionTypes.UPDATE_SECTOR_REQUEST });

/**
 * @function
 * @name updateSectorSuccess
 * @description Action triggered as a result to a successful sector update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} sector	The updated sector object.
 *
 * @returns {object}
 */
const updateSectorSuccess = ({ sector }) => ({
	type: ActionTypes.UPDATE_SECTOR_SUCCESS,
	payload: { sector },
});

/**
 * @function
 * @name updateSectorFailure
 * @description Action triggered as a result to a failed sector update API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const updateSectorFailure = (error) => ({
	type: ActionTypes.UPDATE_SECTOR_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Sector removal actions /////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name removeSectorRequest
 * @description Action triggered anytime a sector deletion call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const removeSectorRequest = () => ({ type: ActionTypes.REMOVE_SECTOR_REQUEST });

/**
 * @function
 * @name removeSectorSuccess
 * @description Action triggered as a result to a successful sector deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {string} deletedSectorId	The removed sector object id.
 *
 * @returns {object}
 */
const removeSectorSuccess = ({ deletedSectorId }) => ({
	type: ActionTypes.REMOVE_SECTOR_SUCCESS,
	payload: { deletedSectorId },
});

/**
 * @function
 * @name removeSectorFailure
 * @description Action triggered as a result to a failed sector deletion API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const removeSectorFailure = (error) => ({
	type: ActionTypes.REMOVE_SECTOR_FAILURE,
	payload: { error },
});

// /////////////////////////////////////////////////////////// //
// //////////////// Sector archiving actions /////////////// //
// /////////////////////////////////////////////////////////// //

/**
 * @function
 * @name archiveSectorRequest
 * @description Action triggered anytime a sector archiving call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const archiveSectorRequest = () => ({ type: ActionTypes.ARCHIVE_SECTOR_REQUEST });

/**
 * @function
 * @name archiveSectorSuccess
 * @description Action triggered as a result to a successful sector archiving API call.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const archiveSectorSuccess = () => ({
	type: ActionTypes.ARCHIVE_SECTOR_SUCCESS,
});

/**
 * @function
 * @name archiveSectorFailure
 * @description Action triggered as a result to a failed sector archiving API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const archiveSectorFailure = (error) => ({
	type: ActionTypes.ARCHIVE_SECTOR_FAILURE,
	payload: { error },
});

// ////////////////////////////////////////////////////////// //
// //////////////// Sector restoring actions //////////////// //
// ////////////////////////////////////////////////////////// //

/**
 * @function
 * @name restoreSectorRequest
 * @description Action triggered anytime a sector restoring call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const restoreSectorRequest = () => ({ type: ActionTypes.RESTORE_SECTOR_REQUEST });

/**
 * @function
 * @name restoreSectorSuccess
 * @description Action triggered as a result to a successful sector restoring API call.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const restoreSectorSuccess = () => ({
	type: ActionTypes.RESTORE_SECTOR_SUCCESS,
});

/**
 * @function
 * @name restoreSectorFailure
 * @description Action triggered as a result to a failed sector restoring API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const restoreSectorFailure = (error) => ({
	type: ActionTypes.RESTORE_SECTOR_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchSectorList
 * @description Method used to update the sector list.
 *
 * @author Audrey Clerc
 *
 * @param {object} params	The parameters used to match user filters.
 */
export const fetchSectorList = (params) => (dispatch) => {
	dispatch(fetchSectorListRequest());

	return SectorsApi.fetchSectors(params)
		.then(({ sectors, totalCount }) => dispatch(fetchSectorListSuccess({ sectors, totalCount })))
		.catch((error) => dispatch(fetchSectorListFailure(error)));
};

/**
 * @function
 * @name fetchSector
 * @description Method used to fetch the latest version of a specific sector.
 *
 * @author Audrey Clerc
 *
 * @param {string} sectorId		The id of the sector we want to retrieve.
 */
export const fetchSector = (sectorId) => (dispatch) => {
	dispatch(fetchSectorRequest());

	return SectorsApi.fetchSectorById(sectorId)
		.then(({ sector }) => dispatch(fetchSectorSuccess({ sector })))
		.catch((error) => dispatch(fetchSectorFailure(error)));
};

/**
 * @function
 * @name addSector
 * @description Method used to add a new sector instance to the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} sectorData		The data to create the new sector from.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const addSector = (sectorData, onSuccessRoute = null) => (dispatch) => {
	dispatch(addSectorRequest());

	return SectorsApi.createSector(sectorData)
		.then(({ sector }) => {
			toast.success(i18next.t('sector.creation.toasts.success', { name: `${sector.name}` }));
			dispatch(addSectorSuccess({ sector }));
			redirectOnSuccess(onSuccessRoute);

			return sector;
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('sector.creation.toasts.conflict'));
				dispatch(addSectorFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('sector.creation.toasts.error'));
				dispatch(addSectorFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name updateSector
 * @description Method used to update an existing sector instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} sectorData		The object to update the sector with.
 * @param {string} sectorId			The id of the sector we want to update.
 * @param {string} [onSuccessRoute]	The url to redirect the user to upon successful completion. Should be imported from the {@Link routes/keys.js} file.
 */
export const updateSector = (sectorData, sectorId, onSuccessRoute = null) => (dispatch) => {
	dispatch(updateSectorRequest());

	return SectorsApi.updateSector(sectorData, sectorId)
		.then(({ sector }) => {
			dispatch(updateSectorSuccess({ sector }));
			toast.success(i18next.t('sector.edition.toasts.success', { name: sector.name }));
			redirectOnSuccess(onSuccessRoute);
		})
		.catch(({ status, message }) => {
			if (status === 409) {
				toast.error(i18next.t('sector.edition.toasts.conflict'));
				dispatch(updateSectorFailure({ code: 'conflict' }));
			} else {
				toast.error(i18next.t('sector.edition.toasts.error'));
				dispatch(updateSectorFailure({ status, message }));
			}
		});
};

/**
 * @function
 * @name removeSector
 * @description Method used to remove an existing sector instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} sector		Tge sector we want to remove from the database.
 * @param {string} sector.id	The id of the sector we want to remove from the database.
 * @param {string} sector.name	The name of the sector we want to remove from the database.
 */
export const removeSector = ({ id, name }) => (dispatch) => {
	dispatch(removeSectorRequest());

	return SectorsApi.deleteSector(id)
		.then(({ deletedSectorId }) => {
			dispatch(removeSectorSuccess({ deletedSectorId }));
			toast.success(i18next.t('sector.deletion.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(removeSectorFailure(error));
			toast.error(i18next.t('sector.deletion.toasts.error'));
		});
};

/**
 * @function
 * @name archiveSector
 * @description Method used to archive an existing sector instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} sector		The sector we want to archive.
 * @param {string} sector.id	The id of the sector we want to archive.
 * @param {string} sector.name	The name of the sector we want to archive.
 */
export const archiveSector = ({ id, name }) => (dispatch) => {
	dispatch(archiveSectorRequest());

	return SectorsApi.updateSector({ archived: true }, id)
		.then(() => {
			dispatch(archiveSectorSuccess());
			toast.success(i18next.t('sector.archiving.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(archiveSectorFailure(error));
			toast.error(i18next.t('sector.archiving.toasts.error'));
		});
};

/**
 * @function
 * @name restoreSector
 * @description Method used to restore an archived sector instance from the database.
 *
 * @author Audrey Clerc
 *
 * @param {object} sector		The sector we want to restore.
 * @param {string} sector.id	The id of the sector we want to restore.
 * @param {string} sector.name	The name of the sector we want to restore.
 */
export const restoreSector = ({ id, name }) => (dispatch) => {
	dispatch(restoreSectorRequest());

	return SectorsApi.updateSector({ archived: false }, id)
		.then(() => {
			dispatch(restoreSectorSuccess());
			toast.success(i18next.t('sector.restoring.toasts.success', { name }));
		})
		.catch((error) => {
			dispatch(restoreSectorFailure(error));
			toast.error(i18next.t('sector.restoring.toasts.error'));
		});
};
