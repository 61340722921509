import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import _ from 'lodash';

/**
 * @function
 * @name useCurrentInvoiceSelector
 * @description A selector to retrieve the current invoice from the invoice redux state
 *
 * @author Yann Hodiesne
 *
 * @param {string} id The identifier of the current invoice to wait for
 *
 * @returns {object}
 */
const useCurrentInvoiceSelector = (id) => {
	const invoice = useSelector((state) => (state.invoices?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (invoice?.id === id ? invoice : null), [id, invoice]);

	return result;
};

/**
 * @function
 * @name useInvoiceLoadingSelector
 * @description A selector to retrieve the loading state from the invoice redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {boolean}
 */
const useInvoiceLoadingSelector = () => {
	const loading = useSelector((state) => state.invoices?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useInvoiceListSelector
 * @description A selector to retrieve the invoice list from the invoice redux state.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {Array}
 */
const useInvoiceListSelector = () => {
	const invoices = useSelector((state) => (state.invoices?.items ?? []), shallowEqual);

	return invoices;
};

/**
 * @function
 * @name useAllForInvoiceSelector
 * @description A selector to retrieve the invoice information from the invoice redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAllForInvoiceSelector = () => {
	const allForForm = useSelector((state) => (state.invoices?.allForForm ?? []), shallowEqual);

	return allForForm;
};

/**
 * @function
 * @name useInvoiceTotalCountSelector
 * @description A selector to retrieve the totalCount value from the invoice redux state.
 *
 * @author Matthieu Schaerlinger
 *
 * @returns {number|undefined}
 */
const useInvoiceTotalCountSelector = () => useSelector((state) => (state.invoices?.totalCount ?? undefined), shallowEqual);

/**
 * @function
 * @name useInvoiceSelector
 * @description A selector to retrieve the invoice from the invoice redux state
 *
 * @author Romaric Barthe
 *
 * @param {string} id The identifier of the invoice to wait for
 *
 * @returns {object}
 */
const useInvoiceSelector = (id) => {
	const invoice = useSelector((state) => (state.invoices?.items ?? null), shallowEqual);

	const result = useMemo(() => (!_.isEmpty(invoice?.filter((elem) => elem.id === id))
		? invoice.filter((elem) => elem.id === id)[0]
		: null
	), [id, invoice]);

	return result;
};

/**
 * @function
 * @name useInvoicesStatsSelector
 * @description A selector to retrieve the current targeting stats from the targeting redux state
 *
 * @author Roland Margelidon
 *
 * @returns {object | null}
 */
const useInvoicesStatsSelector = () => {
	const invoicesStats = useSelector((state) => (state.invoices?.stats), shallowEqual);

	const result = useMemo(() => (invoicesStats), [invoicesStats]);

	return result;
};

/**
 * @function
 * @name useInvoicesDashboardValues
 * @description A selector to retrieve the dashboard values from the dashboard redux state
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const useInvoicesDashboardValues = () => useSelector((state) => (state.invoices?.dashboard ?? {}), shallowEqual);

export {
	useAllForInvoiceSelector,
	useCurrentInvoiceSelector,
	useInvoiceListSelector,
	useInvoiceLoadingSelector,
	useInvoicesDashboardValues,
	useInvoiceSelector,
	useInvoicesStatsSelector,
	useInvoiceTotalCountSelector,
};
