import PropTypes from 'prop-types';

/**
 * @name TableActionsRow
 * @description A styled div component used to display action buttons or inputs linked to a table.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*} children
 */
const TableActionsRow = ({ children }) => (
	<div className="data-table-global-actions">
		{children}
	</div>
);

TableActionsRow.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.elementType,
		PropTypes.node,
	]).isRequired,
};

export default TableActionsRow;
