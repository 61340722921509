import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

/**
 * @typedef {[import('react').MutableRefObject<undefined>, object]} useTooltipResult
 */

/**
 * @function
 * @name useTooltip
 * @description A companion hook to display tooltips anywhere using a single ref
 *
 * @author Audrey Clerc
 * @author Yann Hodiesne
 *
 * @returns {useTooltipResult}
 */
const useTooltip = () => {
	const elementRef = useRef();

	const [isOn, setOn] = useState(false); // toggles dropdown visibility
	const [coords, setCoords] = useState({}); // takes current button coordinates

	const tooltipProps = useMemo(() => ({
		isOn,
		coords,
	}), [coords, isOn]);

	/**
	 * @function
	 * @name updateTooltipCoords
	 * @description Update the coordinates of the tooltip when mooving the mouse over the button
	 *
	 * @author Audrey Clerc
	 *
 	 * @param {HTMLElement}	button 	The button which coordinates we want
	*/
	const updateTooltipCoords = useCallback((button) => {
		if (!isOn) {
			const rect = button.getBoundingClientRect();

			setCoords({
				left: rect.x + rect.width / 2, // add half the width of the button for centering
				top: rect.y + window.scrollY, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
			});
		}
	}, [isOn]);

	// Adds onMouseOver and onMouseOut to the element
	useEffect(() => {
		if (elementRef && elementRef.current) {
			// eslint-disable-next-line require-jsdoc
			const mouseOver = (e) => {
				updateTooltipCoords(e.currentTarget);
				setOn(true);
			};

			// eslint-disable-next-line require-jsdoc
			const mouseOut = () => setOn(false);

			elementRef.current.addEventListener('mouseover', mouseOver);
			elementRef.current.addEventListener('mouseout', mouseOut);

			const currentRef = elementRef.current;

			return () => {
				currentRef.removeEventListener('mouseover', mouseOver);
				currentRef.removeEventListener('mouseout', mouseOut);
			};
		}

		return () => {};
	}, [updateTooltipCoords]);

	return [
		elementRef,
		tooltipProps,
	];
};

export default useTooltip;
