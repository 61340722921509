// @TODO: remove eslint rule disabling once we have added new CRUD requests.
/* eslint-disable import/prefer-default-export */
import { get } from 'lib/shared/http';

/**
 * @function
 * @name fetchApplications
 * @description Retrieves a list of all applications.
 *
 * @author Timothée Simon-Franza
 */
export const fetchApplications = () => get('/api/applications');
