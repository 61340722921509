import update from 'immutability-helper';

import { createDuplicateElement, createRow } from './internals';

/**
 * @function
 * @name duplicateElements
 * @description Duplicates elements and selects them.
 *
 * @author Matthieu Schaerlinger
 *
 * @param {object}		state			The current state.
 * @param {object}		payload			The payload of the action.
 * @param {string[]}	payload.ids		The identifiers of the elements to duplicate.
 *
 * @returns {object} The updated state value
 */
const duplicateElements = (state, { ids }) => {
	// Return original if empty source array
	if (!ids || !ids.length) {
		return state;
	}

	// Calculate the new rows and final position by iterating over the rows
	const result = state.elements.reduce(({ newRows, lastIndex }, row, i) => {
		const rowElementsToDuplicate = row.children.filter((element) => ids.includes(element.id));

		// No elements to duplicate on this row
		if (rowElementsToDuplicate.length === 0) {
			return { newRows, lastIndex };
		}

		// Add new row with duplicated elements
		newRows.push(createRow(...rowElementsToDuplicate.map((element) => createDuplicateElement(element))));

		// Update lastIndex to current row index
		return { newRows, lastIndex: i };
	}, { newRows: [], lastIndex: 0 });

	const { newRows, lastIndex } = result;

	// Return original if no new rows
	if (newRows.length === 0) {
		return state;
	}

	return {
		...state,
		elements: update(state.elements, {
			$splice: [
				[lastIndex + 1, 0, ...newRows], // Add all new rows after the last selected element
			],
		}),
		selectedElements: newRows.flatMap((row) => row.children),
		isDirty: true,
	};
};

export default duplicateElements;
