import { useContext } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

import EditorContext from '../../EditorContext';

import StaticText from './StaticText';

const css = {
	padding: '0px',
	minWidth: '2em',
	minHeight: '1.5em',
};

/**
 * @name SetText
 * @description A readonly text PDF element
 *
 * @author Yann Hodiesne
 * @author Florian Fornazaric
 *
 * @param {number}	[aspectRatio = 1]	The aspect ratio of the PDF editor
 * @param {object}	style				The style of the component
 * @param {object}	element				The element to edit
 * @param {boolean}	editing				True if the element is being edited
 * @param {boolean}	resizable			True if the element is being resized
 */
const SetText = (({ aspectRatio, style, element, editing, resizable, ...props }) => {
	const { isExporting } = useContext(EditorContext);

	return (
		<textarea
			{...props}
			className={`seamless${(editing || resizable) && !isExporting ? ' editing' : ''}`}
			style={{ ...css, ...style }}
			value={element.content}
			readOnly
		/>
	);
});

SetText.propTypes = {
	aspectRatio: PropTypes.number.isRequired,
	style: PropTypes.object,
	element: PropTypes.object,
	editing: PropTypes.bool,
	resizable: PropTypes.bool,
};

SetText.defaultProps = {
	style: {},
	editing: false,
	element: undefined,
	resizable: false,
};

const styles = StyleSheet.create(css);

SetText.PdfElement = ({ style, ...otherprops }) => (
	<StaticText.PdfElement style={{ ...styles, ...style }} {...otherprops} />
);

SetText.PdfElement.propTypes = {
	style: PropTypes.object,
};

SetText.PdfElement.defaultProps = {
	style: undefined,
};

SetText.PdfElement.displayName = 'SetText';

export default SetText;
