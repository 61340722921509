import Capabilities from '../../constants/Capabilities';

import getElementType from './getElementType';

/**
 * @function
 * @name getCapabilities
 * @description Resolves and returns the capabilities associated to the provided element
 *
 * @author Yann Hodiesne
 *
 * @param {object} element  The element to resolve the capabilities for
 *
 * @returns {array} The capabilities associated with the provided element
 */
const getCapabilities = (element) => {
	const elementType = getElementType(element);

	return [...elementType.meta.capabilities.basic, ...elementType.meta.capabilities.advanced].map((capability) => Capabilities[capability]);
};

export default getCapabilities;
