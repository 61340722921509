import { ActionTypes } from '../../actions/expenses/expenses';

const initialState = {
	currentItem: null,
	isLoading: false,
	items: [],
	totalCount: 0,
};

export default (state = initialState, { type, payload } = {}) => {
	switch (type) {
		case ActionTypes.FETCH_EXPENSE_LIST_REQUEST:
		case ActionTypes.FETCH_ALL_FOR_EXPENSE_REQUEST:
		case ActionTypes.ADD_EXPENSE_REQUEST:
		case ActionTypes.EXPORT_EXPENSE_REQUEST:
		case ActionTypes.UPDATE_EXPENSE_REQUEST:
		case ActionTypes.REMOVE_EXPENSE_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case ActionTypes.FETCH_EXPENSE_REQUEST:
		case ActionTypes.EDIT_EXPENSE_REQUEST:
			return {
				...state,
				isLoading: true,
				currentItem: null,
			};
		case ActionTypes.FETCH_EXPENSE_LIST_FAILURE:
		case ActionTypes.FETCH_ALL_FOR_EXPENSE_FAILURE:
		case ActionTypes.FETCH_EXPENSE_FAILURE:
		case ActionTypes.FETCH_GENERATED_PDF_EXPENSE_FAILURE:
		case ActionTypes.ADD_EXPENSE_FAILURE:
		case ActionTypes.EDIT_EXPENSE_FAILURE:
		case ActionTypes.EXPORT_EXPENSE_FAILURE:
		case ActionTypes.UPDATE_EXPENSE_FAILURE:
		case ActionTypes.REMOVE_EXPENSE_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_EXPENSE_LIST_SUCCESS:
			return {
				...state,
				isLoading: false,
				items: [...payload.expenses],
				totalCount: payload.totalCount,
			};
		case ActionTypes.FETCH_EXPENSE_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.expense,
			};
		case ActionTypes.EDIT_EXPENSE_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.enhancedExpense,
			};
		case ActionTypes.ADD_EXPENSE_SUCCESS:
		case ActionTypes.EXPORT_EXPENSE_SUCCESS:
		case ActionTypes.UPDATE_EXPENSE_SUCCESS:
		case ActionTypes.REMOVE_EXPENSE_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case ActionTypes.FETCH_ALL_FOR_EXPENSE_SUCCESS:
			return {
				...state,
				isLoading: false,
				allForForm: payload.allForForm,
			};
		case ActionTypes.FETCH_GENERATED_PDF_EXPENSE_SUCCESS:
			return {
				...state,
				isLoading: false,
				currentItem: payload.generatedPdf,
			};
		default:
			return state;
	}
};
