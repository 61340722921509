import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';

import { TargetPartnerStatusEvolutionItem, TargetPartnerStatusEvolutionItemSkeleton } from './TargetPartnerStatusEvolutionItem';

/**
 * @name TargetPartnerStatusEvolution
 * @description A component used to display the targetPartner's status evolution in chronological order.
 *
 * @author Timothée Simon-Franza
 *
 * @param {bool}		isDataFetchPending			Indicates whether the data is currently being fetched.
 * @param {func}		onCreateStatusButtonClick	Callback function to be called when the create status button is clicked.
 * @param {Object[]}	[statusObjects = []]		The targetPartner's statusObjects.
 */
const TargetPartnerStatusEvolution = ({ isDataFetchPending, onCreateStatusButtonClick, statusObjects }) => {
	const { t } = useTranslation();

	const formattedStatuses = useMemo(() => (
		statusObjects.map((statusObject) => ({
			id: statusObject.id,
			name: statusObject.status.name,
			date: statusObject.date,
			color: statusObject.color ?? statusObject.status.defaultColor,
		})).sort((statusA, statusB) => statusA.date > statusB.date)
	), [statusObjects]);

	return (
		<section className="statuses">
			<ul>
				{isDataFetchPending && (
					[...Array(3).keys()].map((index) => (<TargetPartnerStatusEvolutionItemSkeleton key={index} />))
				)}
				{!isDataFetchPending && !formattedStatuses.length && (
					<>
						<p>{t('pages.operations.targetings.targets.target_partners.details.statuses.no_entry')}</p>
						<Button onClick={onCreateStatusButtonClick} disabled={isDataFetchPending}>
							{t('targeting.target.target_partner.update_status')}
						</Button>
					</>
				)}
				{!isDataFetchPending && formattedStatuses.length > 0 && (
					<>
						{formattedStatuses.map(({ id, name, color, date }) => (
							<TargetPartnerStatusEvolutionItem key={`${id}-${date}`} name={name} color={color} date={date} />
						))}
						<Button onClick={onCreateStatusButtonClick} disabled={isDataFetchPending}>
							{t('targeting.target.target_partner.update_status')}
						</Button>
					</>
				)}
			</ul>
		</section>
	);
};

TargetPartnerStatusEvolution.propTypes = {
	isDataFetchPending: PropTypes.bool.isRequired,
	onCreateStatusButtonClick: PropTypes.func.isRequired,
	statusObjects: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			color: PropTypes.string,
			date: PropTypes.string.isRequired,
			status: PropTypes.shape({
				defaultColor: PropTypes.string,
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			}).isRequired,
		})
	),
};

TargetPartnerStatusEvolution.defaultProps = {
	statusObjects: [],
};

export default TargetPartnerStatusEvolution;
