import { coconut, tuna } from 'theme/colors';

const applicationCard = {
	position: 'relative',
	height: '224px',
	width: '300px',
	borderRadius: '8px',
	padding: '24px',
	bg: coconut.default,
};

const applicationLogoWrapper = {
	height: '64px',
	width: '64px',
	borderRadius: '50%',
	padding: '8px',
	border: `solid 1px ${tuna.lighter2}`,
};

const applicationCardHeader = {
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
};

const applicationManagementForm = {
	gridTemplateColumns: `repeat(auto-fit, ${applicationCard.width})`,
	gridAutoColumns: 'auto',
	gridAutoRows: 'auto',
	gridAutoFlow: 'row dense',
	gridColumnGap: '1em',
	gridRowGap: '1em',
	justifyContent: 'left',
	marginTop: '1em',
};

export {
	applicationCard,
	applicationCardHeader,
	applicationLogoWrapper,
	applicationManagementForm,
};
