import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

/**
 * @function
 * @name useCurrentProjectSelector
 * @description A selector to retrieve the current project from the project redux state
 *
 * @author Yann Hodiesne
 *
 * @param {string} id The identifier of the current project to wait for
 *
 * @returns {object}
 */
const useCurrentProjectSelector = (id) => {
	const project = useSelector((state) => (state.projects?.currentItem ?? null), shallowEqual);

	const result = useMemo(() => (project?.id === id ? project : null), [id, project]);

	return result;
};

/**
 * @function
 * @name useProjectsLoadingSelector
 * @description A selector to retrieve the loading state from the project redux state.
 *
 * @author Audrey Clerc
 * @author Roland Margelidon
 *
 * @returns {boolean}
 */
const useProjectsLoadingSelector = () => {
	const loading = useSelector((state) => state.projects?.isLoading ?? false);

	return loading;
};

/**
 * @function
 * @name useProjectListSelector
 * @description A selector to retrieve the project list from the project redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {Array}
 */
const useProjectListSelector = () => {
	const projects = useSelector((state) => (state.projects?.items ?? []), shallowEqual);

	return projects;
};

/**
 * @function
 * @name useProjectTotalCountSelector
 * @description A selector to retrieve the totalCount value from the project redux state.
 *
 * @author Audrey Clerc
 *
 * @returns {number|undefined}
 */
const useProjectTotalCountSelector = () => {
	const totalCount = useSelector((state) => (state.projects?.totalCount ?? undefined), shallowEqual);

	return totalCount;
};

/**
 * @function
 * @name useProjectActiveListSelector
 * @description A selector to retrieve all non-archived projects from the project redux state.
 *
 * @author Roland Margelidon
 *
 * @returns {Array}
 */
const useProjectActiveListSelector = () => {
	const projects = useProjectListSelector();

	const activeProjects = useMemo(() => projects.filter((project) => !project.archived), [projects]);

	return activeProjects;
};

/**
 * @function
 * @name useAllForProjectSelector
 * @description A selector to retrieve all relevant list for project's forms from the project redux state.
 *
 * @author Romaric Barthe
 *
 * @returns {Array}
 */
const useAllForProjectSelector = () => {
	const allForForm = useSelector((state) => (state.projects?.allForForm ?? []), shallowEqual);

	return allForForm;
};

export {
	useAllForProjectSelector,
	useCurrentProjectSelector,
	useProjectActiveListSelector,
	useProjectListSelector,
	useProjectsLoadingSelector,
	useProjectTotalCountSelector,
};
