import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearch, useTableUserPreferences } from 'lib/hooks';
import { formatCreatedAt, formatCreatedBy, formatUpdatedAt, formatUpdatedBy } from 'lib/shared/format';
import { fetchMedias } from 'redux/actions/mediaManager';
import { useMediasSelector, useMediasTotalCountSelector } from 'redux/selectors/mediaManager';

import ActionsCell from 'components/mediaManager/mediaList/ActionsCell';
import { SearchInput } from 'components/shared/inputs';
import { TableUserPreferencesModal } from 'components/shared/modal';
import { DynamicTable, TableActionsRow } from 'components/shared/tables';

import 'styles/pages/dataTablePages.scss';
import 'styles/pages/mediaListPage.scss';

/**
 * @name MediaListPage
 * @description A list of all medias accessible from the media manager.
 *
 * From this page, the user is able to view, interact and search for medias.
 *
 * @author Yann Hodiesne
 */
const MediaListPage = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { files } = useMediasSelector();
	const totalCount = useMediasTotalCountSelector();

	const headers = useMemo(() => [
		{ id: 'name', accessor: 'name', Header: t('media_manager.fields.file.name'), pinColumn: true, sorting: 'media_manager_file.name' },
		{ id: 'actions', Header: '', pinColumn: true, Cell: ActionsCell, name: t('media_manager.fields.file.actions') },
		{ id: 'createdAt', accessor: (row) => formatCreatedAt(row), Header: t('media_manager.fields.file.created_at'), sorting: 'media_manager_file.createdAt' },
		{ id: 'updatedAt', accessor: (row) => formatUpdatedAt(row), Header: t('media_manager.fields.file.updated_at'), sorting: 'media_manager_file.updatedAt' },
		{ id: 'createdBy', accessor: (row) => formatCreatedBy(row), Header: t('media_manager.fields.file.created_by'), sorting: 'createdBy.username' },
		{ id: 'updatedBy', accessor: (row) => formatUpdatedBy(row), Header: t('media_manager.fields.file.updated_by'), sorting: 'updatedBy.username' },
	], [t]);

	const { tableProps, modalProps } = useTableUserPreferences('media-manager-list');
	const [fetchData, searchRef] = useSearch((params) => dispatch(fetchMedias(params)));

	return (
		<div className="table-page">
			<TableActionsRow>
				<SearchInput ref={searchRef} onSearch={fetchData} />
			</TableActionsRow>
			<DynamicTable
				headers={headers}
				rows={files}
				rowsCount={totalCount}
				rowHeight={80}
				fetchData={fetchData}
				defaultSortingPrefix="media_manager_file"
				disableSelection
				{...tableProps}
			/>
			<TableUserPreferencesModal headers={headers} tableName={t('media_manager.pages.list')} {...modalProps} />
		</div>
	);
};

export default MediaListPage;
