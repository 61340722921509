/* eslint-disable import/prefer-default-export */
import { get } from 'lib/shared/http';

/**
 * @function
 * @name fetchCurrencies
 * @description Retrieves a list of currencies from the API.
 *
 * @author Timothée Simon-Franza
 *
 * @returns {Promise}
 */
export const fetchCurrencies = () => get('/api/currencies');
