/* eslint-disable react/button-has-type */
import { forwardRef } from 'react';
import useForwardedRef from 'lib/hooks/useForwardedRef';
import PropTypes from 'prop-types';

/**
 * @name Button
 * @description A button component.
 *
 * @author Timothée Simon-Franza
 *
 * @param {*}		children				The children to display inside the button.
 * @param {string}	[className=undefined]	The css class names to pass to the HTML element.
 * @param {bool}	[disabled=false]		Whether the button should be disabled.
 * @param {string}	[name=undefined]		Used when we want the button to have a value inside our form.
 * @param {func}	onClick					The method to trigger on click.
 * @param {string}	type					The HTML button type to set to our button (either 'button', 'submit' or 'reset')
 * @param {string}	value					Used when we want the button to have a value inside our form.
 */
const GenericButton = forwardRef(({ children, className, disabled, name, onClick, type, value, ...otherProps }, ref) => {
	const resolvedRef = useForwardedRef(ref);

	return (
		<button
			{...otherProps}
			className={className}
			disabled={disabled}
			name={name}
			onClick={onClick}
			type={type}
			value={value}
			ref={resolvedRef}
		>
			{children}
		</button>
	);
});

GenericButton.displayName = 'GenericButton';

GenericButton.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	name: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	type: PropTypes.oneOf(['button', 'reset', 'submit']),
	value: PropTypes.string,
};

GenericButton.defaultProps = {
	className: undefined,
	disabled: false,
	name: undefined,
	type: 'button',
	value: undefined,
};

export default GenericButton;
