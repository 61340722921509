import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Button } from 'components/shared/buttons';
import { DynamicForm, useSubmitButton } from 'components/shared/forms';
import { TextInput } from 'components/shared/forms/inputs';
import { emailPattern } from 'components/shared/forms/inputs/constants/validationPatterns';
import Validators from 'components/shared/forms/validators';

/**
 * @name ForgotPasswordForm
 * @description Form used to request an email to reset the password.
 *
 * @author Romaric Barthe
 * @author Yann Hodiesne
 *
 * @param {func} onSubmit The method to trigger whenever the form is submitted.
 */
const ForgotPasswordForm = ({ onSubmit }) => {
	const { t } = useTranslation();

	const { formProps, buttonProps } = useSubmitButton();

	return (
		<DynamicForm
			onSubmit={onSubmit}
			blockNavigation={false}
			{...formProps}
		>
			<TextInput
				label={t('forgot_password.inputs.email.label')}
				name="email"
				placeholder={t('forgot_password.inputs.email.placeholder')}
				rules={{
					required: Validators.isRequired(t('forgot_password.inputs.email.validation_errors.required')),
					pattern: Validators.matchPattern(emailPattern, t('forgot_password.inputs.email.validation_errors.pattern')),
				}}
				type="email"
			/>
			<TextInput
				label={t('forgot_password.inputs.company.label')}
				name="company"
				rules={{
					required: Validators.isRequired(t('forgot_password.inputs.company.validation_errors.required')),
				}}
				type="text"
			/>
			<Button className="primary" type="submit" {...buttonProps}>{t('forgot_password.action')}</Button>
		</DynamicForm>
	);
};

ForgotPasswordForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
