import { useCallback, useMemo } from 'react';
import { Edit2, Eye, Mail, Phone, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AccessRights, useAccessRight } from 'lib/shared/accessRights';
import PropTypes from 'prop-types';
import { removeContact } from 'redux/actions/contacts/contacts';
import routes from 'routes';
import { history } from 'routes/components/RouterProvider';

import { Button } from 'components/shared/buttons';
import { PromptModal, useModal } from 'components/shared/modal';
import { Tooltip, useTooltip } from 'components/shared/tooltips';

/**
 * @name ActionsCell
 * @description Custom actions cell for the ContactListPage table. (email, phone, delete and edit contact)
 *
 * @author Yann Hodiesne
 * @author Matthieu Schaerlinger
 *
 * @param {object}		row						The object containing the data to interact with.
 * @param {string}		row.original.id			The id of the contact.
 * @param {string[]}	row.original.emails		The emails of the contact.
 * @param {string}		row.original.firstName	The first name of the contact.
 * @param {string}		row.original.lastName	The last name of the contact.
 * @param {object[]}	row.original.phones		The phone numbers of the contact.
 * @param {function}	fetchData				The method to call when we need to refresh the parent data list.
 */
const ActionsCell = ({ row: { original: { id, emails, firstName, lastName, phones, user } }, fetchData }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();

	const canEditContact = useAccessRight(AccessRights.commonModules.contacts.enhancedRights.CREATE_CONTACT);
	const canDeleteContact = useAccessRight(AccessRights.commonModules.contacts.enhancedRights.DELETE_CONTACT);

	const [emailButtonRef, emailTooltipProps] = useTooltip();
	const [phoneButtonRef, phoneTooltipProps] = useTooltip();
	const [deleteButtonRef, deleteTooltipProps] = useTooltip();
	const [editButtonRef, editTooltipProps] = useTooltip();

	/**
	 * @var
	 * @name phoneNumber
	 * @description The first phone number with the "phone" type, if any.
	 *
	 * @author Timothée Simon-Franza
	 *
	 * @returns {string|undefined}
	 */
	const phoneNumber = useMemo(() => {
		const filteredPhones = (phones ?? []).filter(({ type }) => type === 'phone');

		return filteredPhones[0]?.number;
	}, [phones]);

	/**
	 * @function
	 * @name onEmailButtonClick
	 * @description Method triggered as a result to an onClick event from the email button.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onEmailButtonClick = useCallback(() => {
		window.open(`mailto:${emails[0]}`, '_blank');
	}, [emails]);

	/**
	 * @function
	 * @name onPhoneButtonClick
	 * @description Method triggered as a result to an onClick event from the phone button.
	 *
	 * @author Timothée Simon-Franza
	 */
	const onPhoneButtonClick = useCallback(() => {
		window.open(`tel:${phoneNumber}`, '_self');
	}, [phoneNumber]);

	/**
	 * @function
	 * @name onDeleteModalConfirmationButtonClick
	 * @description Method triggered as a result to an onClick event from the delete button.
	 *
	 * @author Matthieu Schaerlinger
	 */
	const onDeleteModalConfirmationButtonClick = useCallback(async () => {
		toggleDeleteModal();

		await dispatch(removeContact({ id, firstName, lastName }));
		fetchData();
	}, [toggleDeleteModal, dispatch, id, firstName, lastName, fetchData]);

	/**
	 * @function
	 * @name onEditPageRedirectionButtonClick
	 * @description Method triggered as a result to an onClick event from the redirection button.
	 *
	 * @author Yann Hodiesne
	 */
	const onEditPageRedirectionButtonClick = useCallback(() => {
		history.push(generatePath(routes.commonModules.contacts.contactEdition, { id }));
	}, [id]);

	return (
		<>
			<Button ref={editButtonRef} className="icon-only" onClick={onEditPageRedirectionButtonClick}>
				{canEditContact ? <Edit2 className="primary" /> : <Eye />}
			</Button>
			<Tooltip {...editTooltipProps}>
				{canEditContact ? t('actions.edit') : t('actions.visualisation')}
			</Tooltip>
			<Button
				ref={emailButtonRef}
				className="icon-only"
				onClick={onEmailButtonClick}
				disabled={emails.length === 0}
			>
				<Mail />
			</Button>
			<Tooltip {...emailTooltipProps} disabled={emails.length === 0}>
				{t('actions.mail')}
			</Tooltip>
			<Button ref={phoneButtonRef} className="icon-only" onClick={onPhoneButtonClick} disabled={phoneNumber === undefined}>
				<Phone />
			</Button>
			<Tooltip {...phoneTooltipProps} disabled={phoneNumber === undefined}>
				{t('actions.phone')}
			</Tooltip>
			{canDeleteContact && (
				<>
					<Button ref={deleteButtonRef} className="icon-only" onClick={toggleDeleteModal} disabled={user}>
						<Trash2 className="red" />
					</Button>
					<Tooltip {...deleteTooltipProps} disabled={user}>
						{t('actions.delete')}
					</Tooltip>
					<PromptModal
						isShowing={isShowingDeleteModal}
						message={t('contact.deletion.confirmation_modal.content')}
						confirm={onDeleteModalConfirmationButtonClick}
						cancel={toggleDeleteModal}
						confirmText={t('contact.deletion.confirmation_modal.confirm')}
						cancelText={t('contact.deletion.confirmation_modal.cancel')}
						titleText={t('contact.deletion.confirmation_modal.title')}
					/>
				</>
			)}
		</>
	);
};

ActionsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			id: PropTypes.string.isRequired,
			emails: PropTypes.arrayOf(PropTypes.string).isRequired,
			phones: PropTypes.arrayOf(PropTypes.shape({
				number: PropTypes.string.isRequired,
				type: PropTypes.string.isRequired,
			})).isRequired,
			firstName: PropTypes.string,
			lastName: PropTypes.string.isRequired,
			user: PropTypes.bool.isRequired,
		}).isRequired,
	}).isRequired,
	fetchData: PropTypes.func.isRequired,
};

ActionsCell.defaultPropTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			firstName: '',
		}),
	}),
};

export default ActionsCell;
