/**
 * @constant
 * @type {Object}
 * @name extractOptionsEnums
 * @description An object containing the different extraction options.
 *
 * @author Romaric Barthe
 */
const extractOptionsEnums = Object.freeze({
	export: {
		dataTypes: {
			PARTNERS: 'partners',
			CONTACTS: 'contacts',
			EXPENSES: 'expenses',
			TARGET_PARTNERS: 'target_partners',
			TARGETS: 'targets',
		},
		formats: {
			CSV: 'csv',
			XLSX: 'xlsx',
		},
		separators: {
			COMMA: 'comma',
			SEMICOLON: 'semicolon',
			TAB: 'tabulation',
		},
	},
});

/**
 * @constant
 * @type {Object}
 * @name extractOperatorEnum
 * @description An object containing the different extraction operators.
 *
 * @author Romaric Barthe
 */
const extractOperatorEnum = Object.freeze({
	IF: 'if',
	AND: 'and',
	OR: 'or',
});

/**
 * @constant
 * @type {Object}
 * @name extractCriteriaTypeEnum
 * @description An object containing the different extraction criteria types.
 *
 * @author Romaric Barthe
 */
const extractCriteriaTypeEnum = Object.freeze({
	STARTS_WITH: 'starts_with',
	ENDS_WITH: 'ends_with',
	CONTAINS: 'contains',
	NOT_CONTAINS: 'not_contains',
	IS: 'is',
	IS_NOT: 'is_not',
	GREATHER_THAN: 'greater_than',
	GREATHER_THAN_OR_EQUAL: 'greater_than_or_equal',
	LESS_THAN: 'less_than',
	LESS_THAN_OR_EQUAL: 'less_than_or_equal',
	EMPTY: 'empty',
	NOT_EMPTY: 'not_empty',
});

export {
	extractCriteriaTypeEnum,
	extractOperatorEnum,
	extractOptionsEnums,
};
