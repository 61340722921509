import { formatContactName } from 'lib/contacts/formatContactData';
import PropTypes from 'prop-types';

/**
 * @name ContactsCell
 * @description Custom cell for the partnerListPage table displaying a partner's contacts.
 *
 * @author Romaric Barthe
 */
const ContactsCell = ({ row: { original: { contacts } } }) => (
	<ul className="tag-list">
		{contacts.map(({ id, firstName, lastName }) => (
			<li key={id}>
				<span className="tag">
					{formatContactName({ firstName, lastName })}
				</span>
			</li>
		))}
	</ul>
);

ContactsCell.propTypes = {
	row: PropTypes.shape({
		original: PropTypes.shape({
			contacts: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.string.isRequired,
				lastName: PropTypes.string.isRequired,
				firstName: PropTypes.string,
			})).isRequired,
		}).isRequired,
	}).isRequired,
};

export default ContactsCell;
