import * as DashboardApi from 'api/dashboardApi';

/**
 * @constant
 * @name ActionTypes
 * @description The various action types used to interact with the dashboard redux state.
 * @type {object}
 *
 * @author Yann Hodiesne
 */
export const ActionTypes = {
	FETCH_DASHBOARD_REQUEST: '@DASHBOARD/FETCH_DASHBOARD_REQUEST',
	FETCH_DASHBOARD_SUCCESS: '@DASHBOARD/FETCH_DASHBOARD_SUCCESS',
	FETCH_DASHBOARD_FAILURE: '@DASHBOARD/FETCH_DASHBOARD_FAILURE',
};

// //////////////////////////////////////////////////////// //
// /////////////// Dashboard fetching actions ///////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchDashboardRequest
 * @description Action triggered anytime a dashboard fetching call is made to the API.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchDashboardRequest = () => ({ type: ActionTypes.FETCH_DASHBOARD_REQUEST });

/**
 * @function
 * @name fetchDashboardSuccess
 * @description Action triggered as a result to a successful dashboard fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchDashboardSuccess = ({ dashboard }) => ({
	type: ActionTypes.FETCH_DASHBOARD_SUCCESS,
	payload: { dashboard },
});

/**
 * @function
 * @name fetchDashboardFailure
 * @description Action triggered as a result to a failed dashboard fetching API call.
 *
 * @author Yann Hodiesne
 *
 * @returns {object}
 */
const fetchDashboardFailure = (error) => ({
	type: ActionTypes.FETCH_DASHBOARD_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchDashboard
 * @description Method used to retrieve the dashboard from the API.
 *
 * @author Yann Hodiesne
 * @author Romaric Barthe
 *
 * @param {object} params	The parameters used to match user filters
 *
 * @returns {object}
 */
export const fetchDashboard = (params) => (dispatch) => {
	dispatch(fetchDashboardRequest());

	return DashboardApi.fetchDashboard(params)
		.then((dashboard) => dispatch(fetchDashboardSuccess({ dashboard })))
		.catch((error) => dispatch(fetchDashboardFailure(error)));
};
