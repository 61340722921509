import { Capabilities } from '../components';

/**
 * @name AdvancedProperties
 * @description Displays and let the user edit the advanced properties of the selected element.
 *
 * @author Yann Hodiesne
 */
const AdvancedProperties = () => <Capabilities category="advanced" />;

export default AdvancedProperties;
