import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';

import DynamicFormContext from './internals/DynamicFormContext';

/**
 * @typedef {object}	useDynamicFormInputResult
 * @property {*}		defaultValue				The default value of this input
 * @property {function}	validate					Validates this input against the provided rules, and updates isInvalid and validationError accordingly
 * @property {boolean}	isDisabled					Whether this input is to appear and interact like it is disabled
 * @property {boolean}	isInvalid					Whether this input is valid according to the provided rules
 * @property {boolean}	isOptional					Whether this input is required or not
 * @property {boolean}	isReadOnly					Whether this input is to appear and interact like it is read-only
 * @property {string}	validationError				The validation error, if the input is invalid
 * @property {function}	enableValidationOnChange	Enables the validation updates whenever the user changes the input value (must be used with onChangeHandler)
 * @property {function}	onChangeHandler				The onChange handler to give to the input to update its validation status
 */

/**
 * @name useDynamicFormInput
 * @description A custom hook linking a custom input to a dynamic form
 *
 * @author Yann Hodiesne
 *
 * @param {string}		name		The name of the input, which is also the path where its value will be stored inside of the resulting object
 * @param {function}	getValue	The function called to get the current value of the input
 * @param {object}		[rules]		The rules to apply to validate the current input
 * @param {object}		[props={}]	The props to merge with the form state, like disabled, readOnly and onChange
 *
 * @returns {useDynamicFormInputResult}
 */
const useDynamicFormInput = (name, getValue, rules = {}, props = {}) => {
	const { defaultValues, disabled: formDisabled, readOnly: formReadOnly, registerInput, setDirty } = useContext(DynamicFormContext);

	const [displayValidation, setDisplayValidation] = useState();
	const [isInvalid, setIsInvalid] = useState(false);
	const [validationError, setValidationError] = useState();
	const [isDirty, setIsDirty] = useState(false);

	const defaultValue = useMemo(() => _.get(defaultValues, name), [defaultValues, name]);

	const wasDirty = useRef(false);

	useEffect(() => {
		if (wasDirty.current !== isDirty) {
			wasDirty.current = isDirty;
			setDirty(name, isDirty);
		}

		return () => {
			if (isDirty && wasDirty.current) {
				setDirty(name, false);
			}
		};
	}, [defaultValue, getValue, isDirty, name, setDirty]);

	const validate = useCallback(() => {
		const currentValue = getValue();

		const validationErrors = Object.values(rules).map((rule) => rule(currentValue)).filter(Boolean);

		if (validationErrors.length !== 0) {
			setIsInvalid(true);
			setValidationError(validationErrors[0]);

			return false;
		}

		setDisplayValidation(true);
		setIsInvalid(false);
		setValidationError(undefined);

		return true;
	}, [getValue, rules]);

	useEffect(() => registerInput(name, getValue, validate), [getValue, name, registerInput, validate]);

	const isDisabled = useMemo(() => props?.disabled || formDisabled, [props?.disabled, formDisabled]);
	const isReadOnly = useMemo(() => props?.readOnly || formReadOnly, [props?.readOnly, formReadOnly]);
	const isOptional = useMemo(() => !_.has(rules, 'required'), [rules]);

	const enableValidationOnChange = useCallback(() => {
		setDisplayValidation(true);
		validate();
	}, [validate]);

	const onChangeHandler = useCallback(() => {
		const value = getValue();

		props?.onChange?.(value);

		setIsDirty(!_.isEqual(defaultValue, value));

		if (displayValidation) {
			validate();
		}
	}, [props, getValue, defaultValue, displayValidation, validate]);

	return {
		defaultValue,
		validate,
		isDisabled,
		isInvalid,
		isOptional,
		isReadOnly,
		validationError,
		enableValidationOnChange,
		onChangeHandler,
	};
};

export default useDynamicFormInput;
