import * as PartnersApi from 'api/partnersApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Partners redux state
 * @type {object}
 *
 * @author Audrey Clerc
 */
export const ActionTypes = {
	// Fetch a list of partners
	FETCH_PARTNER_FOR_LIST_WITH_TARGETS_REQUEST: '@PARTNERS_FOR_LIST_WITH_TARGETS/FETCH_LIST_REQUEST',
	FETCH_PARTNER_FOR_LIST_WITH_TARGETS_SUCCESS: '@PARTNERS_FOR_LIST_WITH_TARGETS/FETCH_LIST_SUCCESS',
	FETCH_PARTNER_FOR_LIST_WITH_TARGETS_FAILURE: '@PARTNERS_FOR_LIST_WITH_TARGETS/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Partner list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnersForListWithTargetsRequest
 * @description Action triggered anytime a partnersListWithTargets list fetching call is made to the API.
 *
 * @author Audrey Clerc
 *
 * @returns {object}
 */
const fetchPartnersForListWithTargetsRequest = () => ({ type: ActionTypes.FETCH_PARTNER_FOR_LIST_WITH_TARGETS_REQUEST });

/**
 * @function
 * @name fetchPartnersForListWithTargetsSuccess
 * @description Action triggered as a result to a successful partnersListWithTargets list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object}	partnersListWithTargets	The list of retrieved partners.
 * @param {number}	totalCount		The total amount of partners available in the database to the current user.
 *
 * @returns {object}
 */
const fetchPartnersForListWithTargetsSuccess = ({ partnersListWithTargets, totalCount }) => ({
	type: ActionTypes.FETCH_PARTNER_FOR_LIST_WITH_TARGETS_SUCCESS,
	payload: { partnersListWithTargets, totalCount },
});

/**
 * @function
 * @name fetchPartnersForListWithTargetsFailure
 * @description Action triggered as a result to a failed partnersListWithTargets list fetching API call.
 *
 * @author Audrey Clerc
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchPartnersForListWithTargetsFailure = (error) => ({
	type: ActionTypes.FETCH_PARTNER_FOR_LIST_WITH_TARGETS_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchPartnersForListWithTargets
 * @description Method used to update the partnersListWithTargets list.
 *
 * @author Audrey Clerc
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchPartnersForListWithTargets = (filters) => (dispatch) => {
	dispatch(fetchPartnersForListWithTargetsRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forListWithTargets',
	};

	return PartnersApi.fetchPartners(newFilters)
		.then(({ partnersListWithTargets, totalCount }) => dispatch(fetchPartnersForListWithTargetsSuccess({ partnersListWithTargets, totalCount })))
		.catch((error) => dispatch(fetchPartnersForListWithTargetsFailure(error)));
};
