import * as SectorsApi from 'api/sectorsApi';

/**
 * @constant
 * @name ActionTypes
 * @description THe various action types used to interact with the Sectors redux state
 * @type {object}
 *
 * @author Romaric Barthe
 */
export const ActionTypes = {
	// Fetch a list of sectors
	FETCH_SECTOR_SELECT_LIST_REQUEST: '@SECTORS_SELECT/FETCH_LIST_REQUEST',
	FETCH_SECTOR_SELECT_LIST_SUCCESS: '@SECTORS_SELECT/FETCH_LIST_SUCCESS',
	FETCH_SECTOR_SELECT_LIST_FAILURE: '@SECTORS_SELECT/FETCH_LIST_FAILURE',
};

// //////////////////////////////////////////////////////// //
// ///////////// Sector list fetching actions //////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchSectorsSelectListRequest
 * @description Action triggered anytime a sectorsSelect list fetching call is made to the API.
 *
 * @author Romaric Barthe
 *
 * @returns {object}
 */
const fetchSectorsSelectListRequest = () => ({ type: ActionTypes.FETCH_SECTOR_SELECT_LIST_REQUEST });

/**
 * @function
 * @name fetchSectorsSelectListSuccess
 * @description Action triggered as a result to a successful sectorsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object}	sectorsSelect	The list of retrieved sectors.
 * @param {number}	totalCount		The total amount of sectors available in the database to the current user.
 *
 * @returns {object}
 */
const fetchSectorsSelectListSuccess = ({ sectorsSelect, totalCount }) => ({
	type: ActionTypes.FETCH_SECTOR_SELECT_LIST_SUCCESS,
	payload: { sectorsSelect, totalCount },
});

/**
 * @function
 * @name fetchSectorsSelectListFailure
 * @description Action triggered as a result to a failed sectorsSelect list fetching API call.
 *
 * @author Romaric Barthe
 *
 * @param {object} error	The exception sent back from the API.
 *
 * @returns {object}
 */
const fetchSectorsSelectListFailure = (error) => ({
	type: ActionTypes.FETCH_SECTOR_SELECT_LIST_FAILURE,
	payload: { error },
});

// //////////////////////////////////////////////////////// //
// //////////////// Exported action creators ////////////// //
// //////////////////////////////////////////////////////// //

/**
 * @function
 * @name fetchSectorsSelectList
 * @description Method used to update the sectorsSelect list.
 *
 * @author Romaric Barthe
 *
 * @param {object} filters	The filtering criterias.
 */
export const fetchSectorsSelectList = (filters) => (dispatch) => {
	dispatch(fetchSectorsSelectListRequest());

	const newFilters = {
		...filters,
		infoLevel: 'forSelect',
	};

	return SectorsApi.fetchSectors(newFilters)
		.then(({ sectorsSelect, totalCount }) => dispatch(fetchSectorsSelectListSuccess({ sectorsSelect, totalCount })))
		.catch((error) => dispatch(fetchSectorsSelectListFailure(error)));
};
